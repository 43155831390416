import React, { useMemo, useState } from "react";
import useAdvanceSearch from "store/advance-search/service-hook";
import useAdvanceSearchFilter from "../hooks/useAdvanceSearchFilter";
import { AS_REDUCER } from "store/advance-search/reducer";
import { useAdvanceSearchContext } from "../hooks/useAdvanceSearchContext";
import { ADVANCE_SEARCH_COMPONENTS } from "../data";
import LabListing from "pages/labs/listing/LabListing";

const AdvanceSearchLab = () => {
  const { gridType, getFiltersViaType, filters, removeKeys, keyword } =
    useAdvanceSearchContext();
  const { searchLabs } = useAdvanceSearch();
  const [page, setPage] = useState(1);
  const labFilters = useMemo(() => {
    const data = getFiltersViaType(ADVANCE_SEARCH_COMPONENTS.LABS);
    const formatted = {
      ...data,
      ...(data?.lab_type ? { type: data?.lab_type } : {}),
    };
    return removeKeys(formatted, ["lab_type"]);
  }, [filters]);

  const { data } = useAdvanceSearchFilter(
    ADVANCE_SEARCH_COMPONENTS.LABS,
    keyword,
    {
      ...labFilters,
      page,
    },
    searchLabs,
    AS_REDUCER.setLabs,
    false,
    0
  );

  return (
    <LabListing
      browseMode={0}
      labs={data?.data || []}
      gridType={gridType}
      noPagination={!Boolean(data?.meta ?? false)}
      page={data?.meta?.current_page ?? 1}
      setPage={setPage}
      pageCount={data?.meta?.total_pages ?? 1}
      loading={data?.loading}
    />
  );
};

export default AdvanceSearchLab;
