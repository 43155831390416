import {
  Close,
  Facebook,
  LinkedIn,
  Mail,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";
import { Icon, IconButton, Skeleton } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import ModalLayout from "components/Modal";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAssociation from "store/association";

const ShareModal = ({ open, onClose, component, slug, noModal }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { share } = useAssociation();
  const { t } = useTranslation("common");
  useEffect(() => {
    handleFetchDetails();
  }, []);

  const handleFetchDetails = async () => {
    try {
      setLoading(true);
      const resourceType =
        component === COMPONENT_TYPES.RESOURCE ? "resource-module" : component;
      const res = await share(slug, resourceType);
      setData(res);
    } finally {
      setLoading(false);
    }
  };

  if (!open || !slug) return null;

  if (noModal) {
    return <ShareContent data={data} loading={loading} t={t} />;
  }

  return (
    <ModalLayout
      open={open}
      onClose={onClose}
      width="min(90vw,600px)"
      hideCloseIcon
      top="10%"
    >
      <div className="mt-2 mb-6 mx-4">
        <div className="flex items-center justify-between">
          <h2>{t("Share")}</h2>
          <IconButton onClick={onClose}>
            <Close sx={{ fontSize: 30, color: "black" }} />
          </IconButton>
        </div>
        <ShareContent data={data} loading={loading} t={t} />
      </div>
    </ModalLayout>
  );
};

export default ShareModal;

const ShareContent = ({ data, loading, t = (d) => d }) => {
  const icons = {
    facebook: Facebook,
    whatsapp: WhatsApp,
    linkedin: LinkedIn,
    twitter: Twitter,
    email: Mail,
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(data[4]?.url);
    toast.success(t("URL copied!"));
  };

  return loading ? (
    <Skeleton
      variant="rectangular"
      height="200px"
      width="100%"
      className="mt-4"
    />
  ) : Array.isArray(data) ? (
    <>
      <div className="flex items-center justify-around mx-auto my-6">
        {[...data]?.slice(0, 4)?.map(({ type, url }, index) => (
          <IconButton key={index} onClick={() => window.open(url)}>
            <Icon
              sx={{ fontSize: 80, color: "black" }}
              component={icons[type?.toLowerCase()]}
            />
          </IconButton>
        ))}
      </div>
      <div className="flex items-center my-2">
        <Button classes="mr-1" bordered onClick={copyToClipboard}>
          {t("Copy URL")}
        </Button>
        <Input width="100%" classes="flex-1" disabled value={data[4]?.url} />
      </div>
    </>
  ) : null;
};
