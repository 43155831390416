import React from "react";
import useAdvanceSearchFilter from "../../../hooks/useAdvanceSearchFilter";
import { AS_REDUCER } from "../../../../../store/advance-search/reducer";
import useAdvanceSearch from "../../../../../store/advance-search/service-hook";
import { useAdvanceSearchContext } from "../../../hooks/useAdvanceSearchContext";
import QuickSearchListing from "../../QuickSearch";
import { ADVANCE_SEARCH_COMPONENTS } from "../../../data";

const QuickSearchChallengePath = ({ t = (d) => d }) => {
  const { quickKeyword } = useAdvanceSearchContext();
  const { searchChallengePaths } = useAdvanceSearch();
  const { data } = useAdvanceSearchFilter(
    ADVANCE_SEARCH_COMPONENTS.CHALLENGE_PATHS,
    quickKeyword,
    null,
    searchChallengePaths,
    AS_REDUCER.setChallengePaths,
    true
  );

  return (
        <QuickSearchListing
          data={[...(data?.data ?? [])].slice(0, 3) ?? []}
          component={ADVANCE_SEARCH_COMPONENTS.CHALLENGE_PATHS}
          componentName={"Challenge Paths"}
          loading={data?.loading}
          t={t}
        />
  );
};

export default QuickSearchChallengePath;
