import { Grid } from "@mui/material";
import React from "react";
import Card from "components/Cards/Card";
import useRedirect from "helpers/hooks/useRedirect";
import { account_types } from "../../auth-data";

const AccountType = ({ onClick, t = (d) => d }) => {
  const { navigateLogin } = useRedirect();
  return (
    <Grid
      container
      className="mx-auto mt-10vh h-auto items-center justify-center"
      spacing={2}
    >
      {account_types.map(({ id, title, icon }) => (
        <Grid item xs={12} md={4} lg={3} key={id}>
          <Card
            classes="text-center cursor-pointer column-center py-10 mx-2--mob"
            onClick={() => onClick(id)}
            hoverable
          >
            <img src={icon} alt={title} className="h-100--mob h-200" />
            <h3 className="mt-6">{t(title)}</h3>
          </Card>
        </Grid>
      ))}
      <p className="w-full text-center mt-10 mb-4 fs-18 fw-500">
        {t("Already have an account?")}
        <span
          className="c-primary fw-600 cursor-pointer link ml-1"
          onClick={navigateLogin}
        >
          {t("Login")}
        </span>
      </p>
    </Grid>
  );
};

export default AccountType;
