import { Alarm } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import Button from "components/Button";
import Divider from "components/Divider";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useRedirect from "helpers/hooks/useRedirect";
import { COLORS } from "helpers/utils/constants";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useLabDashboard from "store/dashboard/lab";
import useOrgDashboard from "store/dashboard/org";
import useUserDashboard from "store/dashboard/user";

const getColor = (date) => {
  const now = moment();
  const diff = moment(date).diff(now, "hours");

  if (diff <= 24) return COLORS.ERROR;
  if (diff <= 72) return COLORS.ORANGE;
  return COLORS.DARK_GRAY;
};

const formatDate = (date) => {
  const now = moment();
  const dueDate = moment(date);
  const isToday = dueDate.isSame(now, "day");

  if (isToday) return `Due today at ${dueDate.format("h:mm A")}`;
  return `${dueDate.format("MMM D, YYYY [at] h:mm A")}`;
};

const ChallengeDeadlinesCard = ({ type }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { navigateExplore, navigateChallenge } = useRedirect();
  const [joinedDate, setJoinedDate] = useState();
  const { t } = useLocalizedTranslation();
  const useDashboard =
    type === "lab"
      ? useLabDashboard
      : type === "org"
      ? useOrgDashboard
      : useUserDashboard;

  const { upcomingChallengeDeadlines } = useDashboard();
  useEffect(() => {
    fetchChallengeDeadlines();
  }, [type]);

  const fetchChallengeDeadlines = async () => {
    setLoading(true);
    const res = await upcomingChallengeDeadlines();
    setJoinedDate(res?.joined_date);
    setList(res?.list || []);
    setLoading(false);
  };

  return (
    <div className="px-4 mb-4--mob">
      <h3>{t("Upcoming Challenge Deadlines")}</h3>
      {loading ? (
        <ChallengeDeadlinesCardSkeleton count={type === "user" ? 3 : 5} />
      ) : !list?.length ? (
        <div
          className={`flex-column items-center justify-center ${
            type === "user" ? "mt-6" : "mt-12"
          }`}
        >
          <Alarm className="c-primary w-100 h-100" />
          <p className="my-3 c-gray">
            {t("Track deadlines for your in-progress Challenges here!")}
          </p>
          <Button
            onClick={navigateExplore}
            classes={type === "user" ? "mt-0" : "mt-6"}
            width="120px"
          >
            {t("Explore")}
          </Button>
        </div>
      ) : (
        <>
          {/* <p className="my-2 fs-14">
            You joined PreprLabs on{" "}
            {moment(new Date(joinedDate)).format("MMM D, YYYY")}, here is your
            challenge journey.
          </p> */}
          <div className="mt-5">
            {list?.slice(0, type === "user" ? 3 : 5).map((d, i) => {
              const color = getColor(d?.deadline);
              return (
                <>
                  <div className="flex-center mt-1" key={i}>
                    <Alarm sx={{ color }} fontSize="large" />
                    <div className="ml-2">
                      <p
                        className="link fs-14"
                        onClick={() => navigateChallenge(d?.slug)}
                      >
                        {d?.title}
                      </p>
                      <p style={{ color }} className="fs-13 tracking-tight">
                        {" "}
                        {`${t("Due on")} ${formatDate(d?.deadline)}`}
                      </p>
                    </div>
                  </div>
                  {i !== (type === "user" ? 2 : 4) && (
                    <Divider classes="w-100p my-2" />
                  )}
                </>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ChallengeDeadlinesCard;

export const ChallengeDeadlinesCardSkeleton = ({ count }) => {
  return (
    <div className="px-2 mb-4--mob mt-4">
      <Skeleton variant="text" width={400} height={10} className="mb-2" />
      <div className="mt-3">
        {[...Array(count ?? 3)].map((_, i) => (
          <div key={i} className="flex-center mt-5">
            <Skeleton variant="circular" width={30} height={30} />
            <div className="ml-2">
              <Skeleton variant="text" width={300} height={10} />
              <Skeleton variant="text" width={150} height={10} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
