import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from "react";
import { Delete } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { toast } from "react-toastify";

// FileItem Component
const FileItem = ({ file, onRemove }) => (
  <Box className="flex items-center mb-1 py-1 px-2  rounded-100 fs-12 c-gray">
    <p className="maxw-100 tracking-tight text-truncate">
      {file.name || "file"}
    </p>
    <Delete
      className="ml-1 cursor-pointer c-error"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onRemove();
      }}
      fontSize="small"
      color="black"
    />
  </Box>
);

// FileButton Component
const FileButton = forwardRef(
  (
    {
      children,
      onChange = Function.prototype,
      value,
      error,
      accept,
      multi,
      disabled,
      prevValue = "",
      classes,
      max = 2,
      right = false,
      emptyDescription = null,
    },
    ref
  ) => {
    const { t } = useLocalizedTranslation();
    const inputRef = useRef();
    const [maxSize, _setMaxSize] = useState(max * 1024 * 1024);

    const handleFileUpload = () => {
      if (!disabled) {
        inputRef.current.click();
      }
    };

    // Expose handleFileUpload to parent components
    useImperativeHandle(ref, () => ({
      triggerUpload: handleFileUpload,
    }));

    const handleChange = (e) => {
      if (e.target.files[0].size > maxSize) {
        const fileSize = maxSize / (1024 * 1024);
        toast.error(
          t("File size should be less than ${fileSize} MB!", "common", {
            fileSize,
          })
        );
        return;
      }
      onChange(multi ? Array.from(e.target.files) : e.target.files[0]);
      e.target.value = "";
    };

    return (
      <div
        data-testid="file-button"
        className="flex items-center"
        onClick={handleFileUpload}
      >
        <div className={`${classes}`}>
          <div className={`${right && "flex-center"}`}>
            {!value && !prevValue && emptyDescription ? (
              <p className="c-title fs-14 ml-1">{t(emptyDescription)}</p>
            ) : null}
            <div className="flex-center">
              {!value && prevValue && (
                <div className="flex items-center border py-1 px-2 bg-light-yellow rounded-100 fs-12 c-gray">
                  {prevValue}
                </div>
              )}
              {value &&
                (multi ? (
                  <Box className="flex flex-col flex-wrap gap-10 c-success fs-14">
                    {value.map((file, index) => (
                      <FileItem
                        key={index}
                        file={file}
                        onRemove={() => {
                          onChange(value.filter((_, i) => i !== index));
                          inputRef.current.value = "";
                        }}
                        inputRef={inputRef}
                      />
                    ))}
                  </Box>
                ) : (
                  <FileItem
                    file={value}
                    onRemove={() => {
                      onChange();
                      inputRef.current.value = "";
                    }}
                    inputRef={inputRef}
                  />
                ))}
            </div>
            {children}
          </div>
          {error && <p className="fs-13 pt-2 c-error fw-500">{t(error)}</p>}
        </div>
        <input
          disabled={disabled}
          multiple={!!multi}
          style={{ display: "none" }}
          hidden
          onChange={handleChange}
          type="file"
          ref={inputRef}
          accept={accept || "*"}
        />
      </div>
    );
  }
);

export default FileButton;
