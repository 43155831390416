import React from 'react'
import ListingContainer from "components/UI/organisms/ListingContainer";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import MainProductCard from 'components/Cards/MainProductCard';
import { COMPONENT_TYPES } from "helpers/utils/constants";
import Button from 'components/Button';
import { isTrue } from 'helpers/utils/validators';
import { Delete, AccessAlarm, HistoryRounded } from '@mui/icons-material';
import moment from 'moment';

const SubmissionList = ({
        submissions, 
        loading = false,
        page = 1,
        setPage,
        onDelete = (slug) => {}, // Function passed from parent
        onSubmit = (slug, status) => {}, // Function passed from parent
        unSubmit = (slug) => {} // Function passed from parent
    }) => {
      const { t } = useLocalizedTranslation();

      return (
        <div> 
            <ListingContainer
               classes="mt-3"
               isEmpty={!submissions?.list?.length}
               emptyText={t("No submissions yet!")}
               loading={loading}
               spinLoader
               page={page}
               setPage={setPage}
               count={submissions?.total_pages}
             >
               {submissions?.list?.map((d, i) => {
                 const { slug, title, description, media, is_submitted, members, updated_at } = d;
                 const teamMembers = members?.map((d) => d?.full_name);
                 const relativeTime = `Updated ${moment(updated_at).fromNow()}`;

                 return (
                    <MainProductCard
                        key={i}
                        classes="my-1 ml-4 w-100p"
                        title={title}
                        rightAction={
                          <div className='flex items-center'>
                            <Button onClick={() => onDelete(slug)} bordered color={'red'} classes={'mr-2 h-40 w-150'}>
                              <Delete /> {t("Delete")}
                            </Button>
                            {isTrue(is_submitted) ? 
                              <Button onClick={() => unSubmit(slug, false)} classes={'w-150 h-40'} color={'green'}>{t("Unsubmit")}</Button> : 
                              <Button onClick={() => onSubmit(slug, true)} classes={'w-150 h-40'}>
                                {t("Submit")}
                              </Button>
                            }
                        </div>
                        }
                        subTitle={<div><b>Team Members:</b> {teamMembers}</div>}
                        id={slug}
                        description={description}
                        image={media}
                        noSave
                        iconsAndValuesList={[
                          {
                            icon: HistoryRounded,
                            value: relativeTime,
                          },
                          {
                            icon: AccessAlarm,
                            value: `Submitted ${moment(updated_at).format('MMM D, YYYY HH:mmA')}`,
                          }
                        ]}
                        type={COMPONENT_TYPES.PROJECT}
                    />
                 );
               })}
             </ListingContainer>
        </div>
    );
}

export default SubmissionList;
