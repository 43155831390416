import Button from "components/Button";
import Card from "components/Cards/Card";
import EditCreateHeaderCard from "components/Cards/EditCreateHeaderCard";
import InfoCard from "components/Cards/InfoCard";
import useRedirect from "helpers/hooks/useRedirect";
import { INFO_TYPES } from "helpers/utils/constants";
import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import ProjectPitch from "./ProjectPitch";
import ProjectFiles from "./ProjectFiles";
import AdditionalInformation from "./AdditionalInformation";
import { additional_information_schema, schema } from "./data";
import useForms from "helpers/hooks/useForms";
import ProjectDetails from "./ProjectDetails";
import useProjects from "store/projects/service-hook";
import {
  deleteValues,
  keyVal,
  payloadBool,
  payloadFormatArray,
  payloadKeyVals,
} from "helpers/utils/utilities";
import { validateExternalLinks } from "components/UI/molecules/SocialLinks";
import { useParams, useLocation } from "react-router-dom";
import { isTrue } from "helpers/utils/validators";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import useChallenges from "store/challenges/service-hook";

import AlertMessage from "components/UI/atoms/AlertMessage";
import { toast } from "react-toastify";
import useSettings from "store/settings/service-hook";
const CREATE_PROJECT_STEPS = [
  "Overview",
  "Details",
  "Gallery & Files",
  "Additional Info",
];

const init_data = {
  is_view_enabled: false,
  is_download_enabled: false,
};

const AddEditProject = () => {
  const { t } = useLocalizedTranslation();
  const [step, setStep] = useState(0);
  const [requirements, setRequirements] = useState([]);
  const [project, setProject] = useState();
  const [pitch, setPitch] = useState();
  const [links, setLinks] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const editMode = Boolean(id);
  const [initLoading, setInitLoading] = useState(false);
  const [editPitch, setEditPitch] = useState(false);
  const [challenge, setChallenge] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const { fetchChallengeSelectList } = useChallenges();
  const { generatePresignedUrls } = useSettings();

  const {
    navigateBack,
    navigateProject,
    navigateChallenge,
    navigateEditProject,
  } = useRedirect();
  const {
    create,
    edit,
    initRequirements,
    addPitch,
    fileUpload,
    addExternalLinks,
    getRequirements,
    view,
    deleteMedia,
    addAdditionalInfo,
  } = useProjects();
  const { data, onChange, errors, validate, setData, dirty } = useForms(
    schema,
    init_data
  );
  const {
    data: additionalData,
    onChange: onChangeAdditionalData,
    dirty: dirtyAdditionalData,
    setData: setAdditionalData,
  } = useForms(additional_information_schema);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const challengeId = searchParams.get("challenge_id");
  const [prevExternalLinks, setPrevExternalLinks] = useState({
    external_links: [],
    external_link_ids: [],
  });

  useEffect(() => {
    if (editMode && id) {
      handleFetchDetail();
    }
  }, [id, editMode]);

  useEffect(() => {
    if (!challengeId) return;
    setInitLoading(true);
    getChallenge();
  }, [challengeId]);

  useEffect(() => {
    if (data?.challenge_id) fetchRequirements(data?.challenge_id?.key);
  }, [data?.challenge_id?.key]);

  useEffect(() => {
    if (step === 0 || !project?.slug) return;
    fetchRequirements(project?.slug);
  }, [step]);

  const handleFetchDetail = async () => {
    setInitLoading(true);
    const res = await view(id);
    if (res) {
      setProject(res);
      const {
        title,
        description,
        privacy,
        challenge_details,
        is_view_enabled,
        is_download_enabled,
        media,
        videos,
        docs,
        audios,
        images,
        additional_info,
        lab_details,
        media_type,
      } = res;

      const mediaFiles = [...videos, ...docs, ...audios, ...images];

      setData({
        title,
        description,
        privacy: isTrue(privacy) ? "private" : "public",
        challenge_id: keyVal(challenge_details?.title, challenge_details?.uuid),
        active_labs: lab_details?.uuid
          ? keyVal(lab_details?.title, lab_details?.uuid)
          : undefined,
        is_view_enabled: isTrue(is_view_enabled),
        is_download_enabled: isTrue(is_download_enabled),
        cover_media: media,
        media_type: media_type,
      });
      if (additional_info) {
        let addData = {};
        additional_information_schema.forEach((item) => {
          if (additional_info[item.name])
            addData[item.name] = {
              key: additional_info[item.name],
              value: additional_info[item.name.split("_")[0]],
            };
        });
        setAdditionalData(addData);
      }
      setPitch({
        challenge_pitch: res?.project_pitch,
        challenge_task: res?.project_task,
        id: res?.challenge_details?.template_id,
      });
      setLinks(
        res?.external_links?.length
          ? res?.external_links?.map(({ link_id, title, link }) => ({
              url: link,
              type: keyVal(title, link_id),
            }))
          : []
      );
      setPrevExternalLinks(
        res?.external_links?.length
          ? {
              external_links: payloadFormatArray(res?.external_links, "link"),
              external_link_ids: payloadFormatArray(
                res?.external_links,
                "link_id"
              ),
            }
          : {
              external_links: [],
              external_link_ids: [],
            }
      );
      setUploadedFiles(mediaFiles);
      setInitLoading(false);
    }
  };

  const fetchRequirements = async (uuid) => {
    const res =
      step === 0 && !editMode
        ? await initRequirements(uuid)
        : await getRequirements(step === 0 ? id : uuid);
    setRequirements(res || []);
  };
  const onCancel = () => {
    if (!editMode && step === 1) {
      navigateEditProject(project?.slug);
      setStep(0);
    } else if (step === 0) navigateBack();
    else setStep((prev) => prev - 1);
  };

  function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => item === arr2[index]);
  }

  function isPrevExternalLinksEqual(
    currentExternalLinks,
    previousExternalLinks
  ) {
    const areLinksEqual = arraysEqual(
      currentExternalLinks.external_links,
      previousExternalLinks.external_links
    );
    const areIdsEqual = arraysEqual(
      currentExternalLinks.external_link_ids,
      previousExternalLinks.external_link_ids
    );
    return areLinksEqual && areIdsEqual;
  }

  function isIframeString(inputString) {
    return /<iframe/i.test(inputString);
  }

  const handleSubmit = async (action) => {
    let payload;
    let res;
    switch (step) {
      case 0:
        const _errors = await validate(data);
        if (_errors.size) return;
        if (editMode && !project?.slug) return;
        if (editMode && !dirty) {
          setStep(1);
          return;
        }
        payload = {
          ...data,
          is_download_enabled: payloadBool(data?.is_download_enabled),
          is_view_enabled: payloadBool(data?.is_view_enabled),
          challenge_id: data?.challenge_id?.key,
          lab_id: data?.active_labs?.key,
          media_type: data.iframe_code
            ? "embedded"
            : data?.cover_media
            ? "image"
            : "none",
          cover_media: data.iframe_code
            ? data?.iframe_code
            : data?.cover_media ?? "",
        };

        deleteValues(payload, ["active_labs", "iframe_code"]);
        if (
          editMode &&
          ((typeof payload.cover_media === "string" &&
            !isIframeString(payload.cover_media)) ||
            (!editMode && !payload.cover_media))
        )
          deleteValues(payload, ["cover_media"]);
        if (!payload.lab_id) deleteValues(payload, ["lab_id"]);
        setLoading(action);
        res = editMode
          ? await edit(project?.slug, payload)
          : await create(payload);
        setLoading(false);
        if (res?.slug) {
          if (action === "saveExit") {
            navigateProject(res?.slug);
          } else {
            setProject(res);
            setPitch({
              challenge_pitch: res?.project_pitch,
              challenge_task: res?.project_task,
              id: res?.challenge_details?.template_id,
            });
            setStep(1);
          }
        }
        break;
      case 1:
        if (!project) {
          setStep(0);
          return;
        }
        if (!editPitch) {
          setStep(2);
          return;
        }
        payload = {
          template_id: pitch?.id,
          pitch_id: payloadFormatArray(pitch?.challenge_pitch, "id"),
          pitch_answer: payloadFormatArray(
            pitch?.challenge_pitch,
            "answer"
          )?.map((d) => (d ? d?.toString("html") : "")),
          task_id: payloadFormatArray(pitch?.challenge_task, "id"),
          task_answer: payloadFormatArray(pitch?.challenge_task, "answer").map(
            (d) => (d === "yes" || d === "no" ? d : payloadBool(d))
          ),
        };
        setLoading(action);
        res = await addPitch(project?.slug, payload);
        setLoading(false);
        if (res?.slug) {
          if (action === "saveExit") {
            navigateProject(res?.slug);
          } else {
            setProject(res);
            setStep(2);
          }
        }
        break;
      case 2:
        if (!project?.slug) return;
        if (!files?.length && !links?.length) {
          setStep(3);
          return;
        }
        let res1;
        const linksErrors = validateExternalLinks(links, t);
        if (linksErrors) return;
        payload = {
          external_links: payloadFormatArray(links, "url"),
          external_link_ids: payloadFormatArray(links, "type"),
        };
        payloadKeyVals(payload, ["external_link_ids"]);
        if (!isPrevExternalLinksEqual(prevExternalLinks, payload)) {
          setLoading(action);
          res1 = await addExternalLinks(project?.slug, payload);
          setLoading(false);
          setPrevExternalLinks({
            external_links: payloadFormatArray(res1, "link"),
            external_link_ids: payloadFormatArray(res1, "link_id"),
          });
        } else {
          res1 = true;
        }
        try {
          setLoading(action);
          const filesToUpload = await generatePresignedUrls(
            files?.filter((d) => !uploadedFileIds?.includes(d?.id)),
            "project_file"
          );
          if (filesToUpload?.length) {
            payload = {
              file_upload: filesToUpload?.map((d) => d?.response.path),
              filename: filesToUpload?.map((d) => d?.response.filename),
            };
            res = await fileUpload(project?.slug, payload);
            setUploadedFileIds(files?.map((d) => d?.id));
          } else {
            res = true;
          }
        } catch (error) {
          setLoading(false);
        } finally {
          setLoading(false);
        }

        if (res && res1) {
          setStep(3);
        }
        break;
      case 3:
        if (!dirtyAdditionalData) {
          navigateProject(project?.slug);
          return;
        }
        let additionalPayload = {};
        Object.entries(additionalData).forEach(([key, value]) => {
          additionalPayload[key] = value?.key;
        });
        await addAdditionalInfo(project.slug, additionalPayload);
        navigateProject(project?.slug);
        break;
      default:
        break;
    }
  };

  const getInfoCardType = (requirements) => {
    const statuses = requirements.map((req) => req.status);

    if (statuses.every((status) => status === "completed")) {
      return INFO_TYPES.SUCCESS;
    } else if (statuses.includes("completed") && statuses.includes("pending")) {
      return INFO_TYPES.WARNING;
    } else if (statuses.every((status) => status === "pending")) {
      return INFO_TYPES.ERROR;
    }
    return INFO_TYPES.ERROR;
  };

  const LOCKED_SECTIONS = {
    0: ["Description"],
    1: ["Project Pitch Tasks", "Project Pitch Summary"],
    2: ["Media Files", "External Links"],
  };

  useEffect(() => {
    if (!challenge?.length) return;
    setData((preValue) => {
      return {
        ...preValue,
        challenge_id:
          challenge.length > 0
            ? keyVal(challenge[0]?.title, challenge[0]?.uuid)
            : keyVal(challengeId, challengeId),
      };
    });
  }, [challenge?.length]);

  const getChallenge = async () => {
    const res = await fetchChallengeSelectList({
      active: true,
      challenge_uuid: challengeId,
    });
    if (!res?.length) {
      toast.error(
        t(
          "You cannot create a project under this challenge. Either you already have a project under it, or the challenge is not currently active!"
        )
      );
      navigateBack();
      return;
    }
    setChallenge(res);
    setInitLoading(false);
  };

  return (
    <DashboardLayout loading={initLoading}>
      <EditCreateHeaderCard
        current={step}
        length={CREATE_PROJECT_STEPS.length}
        subtitles={CREATE_PROJECT_STEPS}
        title={`${editMode ? "EDIT" : "CREATE YOUR"} PROJECT`}
        classes="mt-header"
        numbered
        rounded
      >
        {(step !== 0 || editMode) && project?.challenge_details?.slug && (
          <div
            className="flex text-left items-center mt-2 w-90p cursor-pointer"
            onClick={() => navigateChallenge(project?.challenge_details?.slug)}
          >
            <AccessTimeIcon sx={{ color: "#FFBC3F", marginRight: "1px" }} />
            <p className="mr-1 fs-16">
              {t("Challenge", "common")}:{" "}
              <span className="c-primary link fs-16">
                {project?.challenge_details?.title}
              </span>
            </p>
            <OpenInNewIcon className="c-primary" />
          </div>
        )}
        {requirements?.length > 0 && (
          <InfoCard
            title={
              requirements?.every((req) => req.status === "completed")
                ? t("Project is meeting the challenge requirements.")
                : t("Project does not meet challenge requirements.")
            }
            type={getInfoCardType(requirements)}
            list={requirements?.map((d) => ({
              title: d["Requirement Title"],
              status: d.status,
            }))}
            hasObjects
            classes="w-90p mt-3"
          />
        )}
        {step !== 3 && (
          <AlertMessage
            severity="warning"
            title={t("The following sections will be locked after submission:")}
            description={(LOCKED_SECTIONS[step] || []).map((section) => (
              <li key={section}>{t(section)}</li>
            ))}
            classes="text-left mt-3 w-90p"
          />
        )}
      </EditCreateHeaderCard>
      <Card classes="my-5 container" width="min(1300px,90%)" centered>
        {step === 0 && (
          <ProjectDetails
            data={data}
            onChange={onChange}
            errors={errors}
            editMode={editMode}
            t={t}
          />
        )}

        {step === 1 && (
          <ProjectPitch
            templateId={project?.challenge_details?.template_id}
            pitchNTasks={pitch}
            setPitchNTasks={setPitch}
            templateTitle={project?.challenge_details?.template_title}
            editPitch={editPitch}
            setEditPitch={setEditPitch}
            challenge_details={project?.challenge_details}
            t={t}
          />
        )}
        {step === 2 && (
          <ProjectFiles
            setFiles={setFiles}
            files={files}
            links={links}
            setLinks={setLinks}
            editMode={editMode}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            onDelete={deleteMedia}
            slug={id}
            t={t}
          />
        )}
        {step === 3 && (
          <AdditionalInformation
            data={additionalData}
            onChange={onChangeAdditionalData}
            t={t}
          />
        )}
        <div className="flex justify-between items-center mt-8 mb-8 w-90p">
          {step === 0 ? (
            <Button height={45} width={150} bordered onClick={onCancel}>
              {t("Cancel")}
            </Button>
          ) : (
            <Button height={45} width={150} bordered onClick={onCancel}>
              {t("Back")}
            </Button>
          )}
          {editMode && step === CREATE_PROJECT_STEPS.length - 1 && (
            <Button
              onClick={() => handleSubmit("saveExit")}
              loading={loading === "saveExit"}
              height={45}
              width={150}
              color="green"
              disabled={step === 0 && !dirty}
            >
              {editMode ? t("Save & Exit") : t("Submit")}
            </Button>
          )}
          {(!editMode || step !== CREATE_PROJECT_STEPS.length - 1) && (
            <Button
              loading={loading === "continue"}
              height={45}
              width={150}
              onClick={() => handleSubmit("continue")}
              color="blue"
              disabled={editPitch && !pitch}
            >
              {step === CREATE_PROJECT_STEPS.length - 1
                ? t("Create Project")
                : t("Continue")}
            </Button>
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default AddEditProject;
