import ImageCard from "components/Cards/ImageCard";
import MainPageLayout from "pages/layouts/MainPage";
import React, { useEffect, useState } from "react";
import Button from "components/Button";
import MainTabs from "components/UI/organisms/MainTabs";
import ChallengeOverview from "./ChallengeOverview";
import Discussions from "components/UI/organisms/Discussions";
import ChallengeSubmissions from "./ChallengeSubmissions";
import PublicDescription from "components/UI/organisms/PublicDescription";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import Card from "components/Cards/Card";
import useRedirect from "helpers/hooks/useRedirect";
import useChallenges from "store/challenges/service-hook";
import useOrganization from "store/organization/service-hook";
import LikeShareSaveBtns from "components/UI/atoms/LikeShareSaveBtns";
import useAuth from "store/auth/service-hook";
import { isTrue } from "helpers/utils/validators";
import Members from "components/UI/molecules/Members";
import { useParams, useLocation } from "react-router-dom";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import MiniOnboarding from "components/UI/organisms/MiniOnboarding";
import useOnboarding from "store/onboarding/service-hook";
import RelatedContent from "pages/associateListings/RelatedContent";
import { ENDPOINTS } from "store/api/endpoints";
import TimeLine from "components/UI/atoms/TimeLine";
import { NotLoggedInTabs } from "pages/organization";
import ShareModal from "components/UI/organisms/ShareModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import AssociateResourceListing from "pages/associateListings/AssociateResourceListing";
import ChalllengeManageOptions from "./ChalllengeManageOptions";
import useAssociation from "store/association";

const challenge_tabs = [
  "Overview",
  "Resources",
  "Discussion",
  "Projects",
  "Members",
];

const ChallengeMainPage = ({
  challenge,
  isPrivate,
  setChallenge,
  isPreviewMode,
}) => {
  const { t } = useLocalizedTranslation();
  const { search } = useLocation();
  const initialTab = Number(new URLSearchParams(search).get("tab")) || 0;
  const [tab, setTab] = useState(initialTab);
  const [completeOnboarding, setCompleteOnboarding] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const { fetchAssociation } = useAssociation();

  const { navigateCreateProject, navigateManageChallenges, navigateProject } =
    useRedirect();
  const { completeChallengeMiniOnboarding } = useOnboarding();
  const {
    clone,
    like,
    unLike,
    favorite,
    unFavorite,
    addTemplate,
    removeTemplate,
    deleteChallenge,
  } = useChallenges();

  const { organizationId } = useOrganization();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const {
    state: { isLoggedIn, user },
  } = useAuth();
  const { id } = useParams();

  useEffect(() => {
    if (isLoggedIn && !isTrue(user?.is_challenge_onboarding))
      setShowOnboarding(true);
  }, []);

  const handleCloneChallenge = () => {
    if (!challenge || !organizationId) return;
    clone(challenge?.slug, organizationId);
  };

  const doUndoAction = async (property, doAction, undoAction) => {
    const slug = challenge?.slug;
    if (!slug || !isLoggedIn) return;
    const did = isTrue(challenge[property]);
    if (did) {
      await undoAction(slug);
    } else {
      await doAction(slug);
    }
    challenge[property] = did ? "no" : "yes";
    setChallenge({ ...challenge });
  };

  const handleLikeUnlike = () => doUndoAction("liked", like, unLike);
  const handleFavoriteUnFav = () =>
    doUndoAction("favourite", favorite, unFavorite);

  const handleCloseOnboarding = () => {
    if (completeOnboarding) completeChallengeMiniOnboarding();
    setShowOnboarding(false);
  };

  const [modifiedIframe, setModifiedIframe] = useState("");

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(challenge.media, "text/html");
    const iframe = doc.querySelector("iframe");
    if (iframe) {
      iframe.removeAttribute("width");
      iframe.removeAttribute("height");
      iframe.removeAttribute("frameborder");
      iframe.setAttribute(
        "style",
        "width: 100%; height: 100%; position: absolute; top: 0; left: 0; border: none;"
      );
    }
    const modifiedHTML = iframe ? iframe.outerHTML : challenge.media;
    setModifiedIframe(modifiedHTML);
  }, [challenge.media]);

  const showChallengeButton = (active) =>
    Boolean(
      active === "draft" ||
        active === "in_progress" ||
        active === "submitted" ||
        active === "late_submitted" ||
        active === "yes"
    );

  const addIntoTemplates = async () => {
    if (isTrue(challenge?.is_pre_build)) {
      const res = await removeTemplate(challenge?.slug);
      if (!res?.error) {
        setChallenge((prev) => ({ ...prev, is_pre_build: "no" }));
      }
    } else {
      const res = await addTemplate(challenge?.slug);
      if (!res?.error)
        setChallenge((prev) => ({ ...prev, is_pre_build: "yes" }));
    }
  };

  const onDeleteChallengePath = async () => {
    await deleteChallenge(challenge.slug);
    navigateManageChallenges();
  };

  useEffect(() => {
    fetchAssociationLists();
  }, []);

  const fetchAssociationLists = async () => {
    const [labs = []] =
      (await fetchAssociation(
        COMPONENT_TYPES.CHALLENGE,
        id,
        COMPONENT_TYPES.LAB
      )) || [];

    const [lab_programs = []] =
      (await fetchAssociation(
        COMPONENT_TYPES.CHALLENGE,
        id,
        COMPONENT_TYPES.LAB_PROGRAMS
      )) || [];

    setChallenge((prev) => ({
      ...prev,
      labs,
      lab_programs,
    }));
  };

  return (
    <>
      <div className="flex-reverse container mt-header">
        {isPrivate && !isPreviewMode && (
          <ChalllengeManageOptions
            slug={challenge?.slug}
            addIntoTemplates={addIntoTemplates}
            handleCloneChallenge={handleCloneChallenge}
            data={challenge}
            onDelete={setDeleteConfirmationOpen}
            t={t}
            user={user}
          />
        )}
      </div>
      <MainPageLayout classes="my-3" firstLgGridSize={4} secondLgGridSize={8}>
        <div className="left relative">
          {challenge.media_type === "embedded" ? (
            <div className="h-230">
              <div
                className="h-230 relative"
                dangerouslySetInnerHTML={{ __html: modifiedIframe }}
              />
            </div>
          ) : (
            <ImageCard
              image={challenge?.media}
              height="250px"
              type={COMPONENT_TYPES.CHALLENGE}
              title={challenge?.title}
            />
          )}

          {!isPreviewMode &&
            isLoggedIn &&
            showChallengeButton(challenge?.is_active) && (
              <div className="flex items-center">
                {challenge.project && (
                  <Button
                    width="50%"
                    classes="mt-2 mr-2"
                    height="40px"
                    onClick={() => navigateProject(challenge?.project?.slug)}
                  >
                    {t("View Project")}
                  </Button>
                )}
                <StartChallengeButton
                  challenge={challenge}
                  classes={`mt-2 ${!challenge.project && "fs-22 fw-600"}`}
                  height="40px"
                  onClick={() => navigateCreateProject(challenge?.id)}
                  t={t}
                />
              </div>
            )}
          <PublicDescription
            component={COMPONENT_TYPES.CHALLENGE}
            data={challenge}
            showProgress={isTrue(challenge?.joined)}
            hideHostedBy
            hideDescription
            hideSocialLinks={true}
            isPrivate={isPrivate}
            hideChallengeRequirementIcon={true}
          >
            <TimeLine challenge={challenge} />
            <LikeShareSaveBtns
              onLike={handleLikeUnlike}
              isLiked={isTrue(challenge?.liked)}
              onSave={handleFavoriteUnFav}
              isSaved={isTrue(challenge?.favourite)}
              onShare={() => setShareOpen(true)}
              hideLike={true}
              disabled={challenge.status === "draft"}
            />
          </PublicDescription>
        </div>
        <div className="right">
          <MainTabs tabs={challenge_tabs} current={tab} setCurrent={setTab} />
          {isLoggedIn ? (
            <>
              {tab === 0 && <ChallengeOverview challenge={challenge} t={t} />}
              {tab === 1 && (
                <AssociateResourceListing
                  showSwitch
                  parent={COMPONENT_TYPES.CHALLENGE}
                  slug={challenge.slug}
                />
              )}
              {tab === 2 && (
                <Card classes="mt-4">
                  <Discussions
                    component={COMPONENT_TYPES.CHALLENGE}
                    slug={id}
                  />{" "}
                </Card>
              )}
              {tab === 3 && <ChallengeSubmissions challenge={challenge} />}
              {tab === 4 && <Members component={COMPONENT_TYPES.CHALLENGE} />}
            </>
          ) : (
            <NotLoggedInTabs title="Login to view more details" />
          )}
          <RelatedContent
            component={ENDPOINTS.CHALLENGE}
            title={t("More Challenges")}
            classes="mt-4"
            slug={challenge?.slug}
          />
        </div>
      </MainPageLayout>
      <MiniOnboarding
        open={showOnboarding}
        onClose={handleCloseOnboarding}
        data={challenge}
        component={COMPONENT_TYPES.CHALLENGE}
        setCompleted={setCompleteOnboarding}
      />
      <ShareModal
        open={shareOpen}
        onClose={() => setShareOpen(false)}
        component={COMPONENT_TYPES.CHALLENGE}
        slug={challenge?.slug}
      />
      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        desc={`Are you sure you want to delete this Challenge?`}
        okayButtonTitle={`Delete Challenge`}
        onOkay={() => onDeleteChallengePath()}
      />
    </>
  );
};

export default ChallengeMainPage;

export const StartChallengeButton = ({
  challenge,
  height = "40px",
  classes,
  hide,
  onClick,
  t = (d) => d,
  width,
  disabled,
  isSequential,
}) => {
  return (
    <Button
      color={
        challenge?.is_active === "in_progress" && !isSequential
          ? "gray"
          : "green"
      }
      width={width ?? `${challenge?.project ? "50%" : "100%"}`}
      classes={classes}
      height={ !isSequential && challenge?.is_active === "yes" ? "50px" : height}
      onClick={onClick}
      disabled={
        disabled ||
        challenge.status === "draft" ||
        (challenge?.is_active === "in_progress" && !isSequential) ||
        challenge?.is_active === "submitted" ||
        challenge?.is_active === "late_submitted"
      }
      hide={hide}
    >
      {t(
        challenge?.is_active === "yes"
          ? "Create Project"
          : challenge?.is_active === "submitted"
          ? "Submitted"
          : challenge?.is_active === "in_progress"
          ? isSequential
            ? "View Project"
            : "In Progress"
          : challenge?.is_active === "late_submitted"
          ? "Late Submitted"
          : "N/A"
      )}
    </Button>
  );
};
