import { render, waitFor } from "@testing-library/react";
import {
    mockRequestGet,
  } from "../../mocks/request";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { initStore } from "../../mocks/store";
import { REDUCER_TYPES } from "../../index";
import userEvent from "@testing-library/user-event";
import { ENDPOINTS } from "store/api/endpoints";
import useLabReport from "./service-hooks";




let getSpy;


let challengeId = "advanced-technology-development-center-atdc";


const headers = {
    Authorization: "Bearer 1",
};

const store = initStore({
    [REDUCER_TYPES.AUTH]: {
      language: "en",
      token: 1,
      user: { id: 1, email: "test@gmail.com" },
    },
    [REDUCER_TYPES.MASTER]: {},
  });



const ReportsLabServicesMock = () => {
    const {
        labReportDetails,
        labChallenges,
        labMembers,
        labChallengePaths,
        labResourceModules,
        labResourceCollections,
        labResourceGroups,
        labMemberProgress,
        labProgramsMemberProgress,
        labEngagements,
        labAchievements,
        labMemberActivity,
        downloadLabReport
    } = useLabReport();
  
    const BUTTON_LINKS = [
      { name: "labMemberProgress", onClick: () => labMemberProgress(challengeId) },
      { name: "labProgramsMemberProgress", onClick: () => labProgramsMemberProgress(challengeId) },
      { name: "labReportDetails", onClick: () => labReportDetails(challengeId) },
      { name: "labChallenges", onClick: () => labChallenges(challengeId,1,10,'') },
      { name: "labChallengePaths", onClick: () => labChallengePaths(challengeId,1,10,'') },
      { name: "labResourceModules", onClick: () => labResourceModules(challengeId,1,10,'') },
      { name: "labResourceGroups", onClick: () => labResourceGroups(challengeId,1,10,'') },
      { name: "labResourceCollections", onClick: () => labResourceCollections(challengeId,1,10,'') },
      { name: "labEngagements", onClick: () => labEngagements(challengeId) },
      { name: "labMembers", onClick: () => labMembers(challengeId,1) },
      { name: "labAchievements", onClick: () => labAchievements(challengeId,1) },
      { name: "labMemberActivity", onClick: () => labMemberActivity(challengeId) },
      { name: "downloadLabReport", onClick: () => downloadLabReport(challengeId) },
    ];
  
    return (
      <div>
        {BUTTON_LINKS.map((item) => (
          <button data-testid={item.name} key={item.name} onClick={item.onClick}>
            Call {item.name}
          </button>
        ))}
      </div>
    );
  };

  let screen;


describe("Reports Lab services", () => {
    beforeEach(() => {
      screen = render(
        <Provider store={store}>
          <BrowserRouter>
            <ReportsLabServicesMock />
          </BrowserRouter>
        </Provider>
      );
      getSpy = mockRequestGet();
    });
  
    test("labMemberProgress", async () => {
      const button = screen.getByTestId("labMemberProgress");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.LAB}/${challengeId}/${ENDPOINTS.MEMBER_PROGRESS}?language=en`,
          {},
          headers,
          expect.any(Function)
        )
      );
    });
    test("labProgramsMemberProgress", async () => {
        const button = screen.getByTestId("labProgramsMemberProgress");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.LAB_PROGRAM}/${challengeId}/${ENDPOINTS.MEMBER_PROGRESS}?language=en`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("labReportDetails", async () => {
        const button = screen.getByTestId("labReportDetails");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.LAB}/${challengeId}?language=en`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("labChallenges", async () => {
        const button = screen.getByTestId("labChallenges");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.LAB}/${challengeId}/${ENDPOINTS.CHALLENGES}?language=en&keyword=&page=1&per_page=10`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });
    
      test("labChallengePaths", async () => {
        const button = screen.getByTestId("labChallengePaths");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.LAB}/${challengeId}/${ENDPOINTS.CHALLENGE_PATHS}?language=en&keyword=&page=1&per_page=10`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("labResourceModules", async () => {
        const button = screen.getByTestId("labResourceModules");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.LAB}/${challengeId}/${ENDPOINTS.RESOURCE_MODULES}?language=en&keyword=&page=1&per_page=10`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("labResourceGroups", async () => {
        const button = screen.getByTestId("labResourceGroups");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.LAB}/${challengeId}/${ENDPOINTS.RESOURCE_GROUPS}?language=en&keyword=&page=1&per_page=10`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("labResourceCollections", async () => {
        const button = screen.getByTestId("labResourceCollections");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.LAB}/${challengeId}/${ENDPOINTS.RESOURCE_COLLECTIONS}?language=en&keyword=&page=1&per_page=10`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("labEngagements", async () => {
        const button = screen.getByTestId("labEngagements");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.LAB}/${challengeId}/${ENDPOINTS.ENGAGEMENT}?language=en`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("labMembers", async () => {
        const button = screen.getByTestId("labMembers");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.LAB}/${challengeId}/${ENDPOINTS.MEMBERS}?language=en&page=1`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("labAchievements", async () => {
        const button = screen.getByTestId("labAchievements");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.LAB}/${challengeId}/${ENDPOINTS.ACHIEVEMENTS}?language=en&page=1`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });


    

      test("labMemberActivity", async () => {
        const button = screen.getByTestId("labMemberActivity");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.LAB}/${challengeId}/${ENDPOINTS.MEMBER_ACTIVITY}?language=en`,
            {},
            headers,
            expect.any(Function),
            

          )
        );
      });
      
})