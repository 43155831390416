import {useSelector} from "react-redux";
import {REDUCER_TYPES} from "../index";
import REQUEST, {CONTENT_TYPES} from "../api";
import {ENDPOINTS} from "../api/endpoints";
import {resolveResponse, serializeObject} from "../api/utils";
import {useState} from "react";

const useUnified = () => {
    const {language, token} = useSelector((state) => state[REDUCER_TYPES.AUTH]);
    const [loadingIntegrations, setLoadingIntegration] = useState(false)
    const [loadingEmployees, setLoadingEmployees] = useState(false)
    const [invitingMembers, setInvitingMembers] = useState(false)

    const headers = {
        Authorization: "Bearer " + token,
    };

    /**
     *
     * @param params
     * @returns {Promise<*|*[]>}
     */
    const listIntegrations = async (params = {}) => {
        const client = new REQUEST();
        setLoadingIntegration(true)
        const response = await client.get(
            `${ENDPOINTS.MANAGE}/${
                ENDPOINTS.UNIFIED
            }/${ENDPOINTS.UNIFIED_INTEGRATIONS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            Function.prototype,
            Function.prototype,
            true
        );
        setLoadingIntegration(false)
        return response?.data?.data || [];
    };

    /**
     *
     * @param params
     * @returns {Promise<*|*[]>}
     */
    const listEmployees = async (params = {}) => {
        const client = new REQUEST();
        setLoadingEmployees(true)
        const response = await client.get(
            `${ENDPOINTS.MANAGE}/${
                ENDPOINTS.UNIFIED
            }/${ENDPOINTS.UNIFIED_LIST_EMPLOYEES}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            Function.prototype
        );
        setLoadingEmployees(false)
        return response?.data?.data || [];
    };

    const inviteMembers = async (payload,params) => {
        const body = {
            ...payload,
            language,
        };

        const onSuccess = (res) =>
            resolveResponse(
                res,
                null,
                "Members invited successfully."
            );

        const client = new REQUEST();
        setInvitingMembers(true)
        const res = await client.post(
            `${ENDPOINTS.MANAGE}/${
                ENDPOINTS.UNIFIED
            }/${ENDPOINTS.UNIFIED_INVITE_MEMBER}?language=${language}&${serializeObject(params)}`,
            body,
            {
                ...headers,
                "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
            },
            onSuccess
        );

        setInvitingMembers(false)

        if (res?.data?.data) return res?.data?.data;
        return res;
    };

    return {
        listIntegrations,
        loadingIntegrations,
        listEmployees,
        loadingEmployees,
        invitingMembers,
        inviteMembers
    }
}

export default useUnified;
