import Button from "components/Button";
import useRedirect from "helpers/hooks/useRedirect";
import ListingContainer from "components/UI/organisms/ListingContainer";
import logo from "assets/svg/logo.svg";
import { Work } from "@mui/icons-material";
import HelpIcon from "@mui/icons-material/Help";
import jobDefaultImage from "assets/png/job-default.png";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import MainProductCard from "components/Cards/MainProductCard";
import { Box, Icon, LinearProgress, Grid } from "@mui/material";
import { challenge } from "assets/svg/green";
import { lab } from "assets/svg/blue";
import { resource } from "assets/svg/purple";
import { skills as skillImg } from "assets/svg/yellow";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const RelatedJobs = ({ loading, jobs = [] }) => {
  const { navigateCareers } = useRedirect();
  const { t } = useLocalizedTranslation();

  return (
    <>
      <ListingContainer
        loading={loading}
        emptyText={t("No related jobs found!")}
        isEmpty={!jobs?.length}
        noPagination
        classes="mt-4"
      >
        {jobs.map((job, i) => {
          return (
            <Grid item key={job.id} xs={12}>
              <MainProductCard
                classes="mb-3"
                id={job?.id}
                title={<h4 className={{ color: "#498cce" }}>{job.title}</h4>}
                description={job?.description || "N/A"}
                image={jobDefaultImage}
                type={COMPONENT_TYPES.JOB}
                imgAbsoluteContent={
                  <Box className="flex flex-column items-center justify-between h-full w-full">
                    <div className={`flex justify-between w-full c-white px-1`}>
                      <img src={logo} height={18} alt="logo" />
                      <div className="fs-10 flex mt-1">
                        <p>JOB</p>
                        <Icon component={Work} sx={{ fontSize: 13 }} />
                      </div>
                    </div>
                    <div className="h-40 text-center w-full flex items-center justify-center bg-gray">
                      <h2 className="uppercase fs-18 tracking-tight text-center mx-auto text-truncate maxw-90p">
                        {job?.title}
                      </h2>
                    </div>
                    <p className="text-center fs-10 mb-1 c-white">
                      CO-LEARN. CO-LAB. CO-SOLVE.®
                    </p>
                  </Box>
                }
                iconsAndValuesList={[
                  {
                    img: challenge,
                    value: `${job?.related_challenges || "0"} ${t(
                      "Related Challenges"
                    )}`,
                  },
                  {
                    img: lab,
                    value: `${job?.related_labs || "0"} ${t("Related Labs")}`,
                  },
                  {
                    img: resource,
                    value: `${job?.related_resources || "0"} ${t(
                      "Related Resources"
                    )}`,
                  },

                  {
                    img: skillImg,
                    value: `${job?.skills?.length || "0"} ${t(
                      "Related Skills"
                    )}`,
                  },
                ]}
                content={
                  <div className="flex mt-2 items-center">
                    <h4 className="mr-2 flex items-center text-nowrap flex-1">
                      {t("Your Skill Match")} <HelpIcon className="mx-2" />
                    </h4>
                    <LinearProgress
                      variant="determinate"
                      value={job?.skills_percentage || 0}
                      className="w-full h-5 rounded-50 mx-1"
                    />
                    <p className="fs-12 mr-2">{job?.skills_percentage || 0}%</p>
                  </div>
                }
                noSave
              />
            </Grid>
          );
        })}
      </ListingContainer>
      {jobs?.length ? (
        <div className="flex justify-center items-center my-2">
          <Button bordered width={180} height={40} onClick={navigateCareers}>
            {t("Explore More Jobs")}
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default RelatedJobs;
