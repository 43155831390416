import { Settings } from "@mui/icons-material";
import MainPageLayout from "pages/layouts/MainPage";
import DashboardLayout from "pages/layouts/dashboard";
import React from "react";
import useAuth from "store/auth/service-hook";
import BrowseOptionsMenu from "../molecules/BrowseOptionsMenu";
import FilterCard from "./FilterCard";
import { FILTER_TYPES } from "helpers/hooks/useFilters";
import PageTitleCard from "../molecules/PageTitleCard";
import ListingPageHeader from "./ListingPageHeader";
import { ROLES } from "helpers/utils/constants";

const BrowseWrapper = ({
  browseOptions,
  browseMode,
  setBrowseMode,
  gridType,
  setGridType,
  sort,
  setSort,
  search,
  setSearch,
  children,
  manageNavigate = Function.prototype,
  noType,
  noAction,
  noFilters,
  isBrowsePublic,
  hidePageTitle,
  listingTitle,
  filters,
  sortOptions,
  noHeaderFooter,
}) => {
  const {
    state: { isLoggedIn, user },
  } = useAuth();
  const isNormalUserView = user?.roles === ROLES.USER;
  
  return (
    <DashboardLayout noHeaderFooter={noHeaderFooter}>
      {!hidePageTitle && (
        <PageTitleCard
          title={browseOptions[browseMode].pageTitle}
          actionTitle="Manage"
          onClickAction={manageNavigate}
          actionIcon={Settings}
          noAction={!isLoggedIn || noAction || isNormalUserView}
        />
      )}
      <MainPageLayout classes="mt-5 mb-6">
        <div className="left">
          {(isBrowsePublic || isLoggedIn) && (
            <BrowseOptionsMenu
              options={browseOptions.map((d) => d.title)}
              onChange={setBrowseMode}
              current={browseMode}
              classes="mb-4"
            />
          )}
          {!noFilters && (
            <FilterCard
              title="Advanced Search"
              types={
                !filters
                  ? [
                      !noType && FILTER_TYPES.COMPONENT_TYPE,
                      isLoggedIn && FILTER_TYPES.SOCIAL_TYPE,
                      FILTER_TYPES.DURATION,
                      FILTER_TYPES.LEVEL,
                      FILTER_TYPES.SKILLS,
                      FILTER_TYPES.PRIVACY,
                      FILTER_TYPES.CATEGORY,
                      FILTER_TYPES.JOBS,
                    ]
                  : filters
              }
            />
          )}
        </div>
        <div className={`right`}>
          <ListingPageHeader
            gridType={gridType}
            setGridType={setGridType}
            search={search}
            setSearch={setSearch}
            setSort={setSort}
            sort={sort}
            title={listingTitle}
            sortOptions={sortOptions}
          />
          {children}
        </div>
      </MainPageLayout>
    </DashboardLayout>
  );
};

export default BrowseWrapper;
