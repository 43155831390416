import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import { mockRequestGet, mockRequestPost } from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useSkills from "./service-hook";
import { BrowserRouter } from "react-router-dom";

let getSpy;
let postSpy;

const headers = {
  Authorization: "Bearer 1",
};

let skillsid = "1";

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
});

const SkillsServicesMock = () => {
  const { list, view, mySkills, add, pinUnpin } = useSkills();

  const BUTTON_LINKS = [
    { name: "list", onClick: () => list({}) },
    { name: "view", onClick: () => view(skillsid) },
    { name: "mySkills", onClick: () => mySkills({}) },
    { name: "add", onClick: () => add(skillsid) },
    { name: "pinUnpin", onClick: () => pinUnpin(skillsid) },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Skills services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <SkillsServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
  });

  test("list", async () => {
    const button = screen.getByTestId("list");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.SKILLS}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("view", async () => {
    const button = screen.getByTestId("view");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.SKILLS}/${skillsid}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("mySkills", async () => {
    const button = screen.getByTestId("mySkills");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.SKILLS}/${ENDPOINTS.MY_SKILLS}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("add", async () => {
    const button = screen.getByTestId("add");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.SKILLS}/${ENDPOINTS.ADD}?language=en`,
        { skill_id: "1", language: "en" },
        headers,
        expect.any(Function)
      )
    );
  });

  test("pinUnpin", async () => {
    const button = screen.getByTestId("pinUnpin");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.SKILLS}/${ENDPOINTS.PINNED}?language=en`,
        { skill_id: "1", language: "en", pinned: "yes" },
        headers,
        expect.any(Function)
      )
    );
  });
});
