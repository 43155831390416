import { keyValueFormat } from "helpers/utils/utilities";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { serializeObject } from "store/api/utils";

const useLeaderboard = () => {
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  const headers = {
    Authorization: "Bearer " + token,
  };

  const list = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.LEADERBOARD}?language=${language}&${serializeObject(
        filters
      )}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      const { list, total_pages, total_count } = res?.data?.data;
      return [list, total_pages, total_count];
    }
    return [];
  };

  const labList = async (slug, filters = {}) => {
    if (!slug) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.LEADERBOARD}/${slug}/${
        ENDPOINTS.LAB
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      const { list, total_pages, total_count } = res?.data?.data;
      return [list, total_pages, total_count];
    }
    return [];
  };

  const getOrgs = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.LEADERBOARD}/get-organization-list?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return keyValueFormat(res?.data?.data || []);
    }
    return [];
  };

  const getLabs = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.LEADERBOARD}/get-lab-list?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return keyValueFormat(res?.data?.data || []);
    }
    return [];
  };

  const getChallenges = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.LEADERBOARD}/get-challenge-list?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return keyValueFormat(res?.data?.data || []);
    }
    return [];
  };

  const getProjects = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.LEADERBOARD}/get-project-list?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return keyValueFormat(res?.data?.data || []);
    }
    return [];
  };

  const getLabChallenges = async (filters = {}) => {
    if (!filters?.slug) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.LEADERBOARD}/${filters.slug}/lab-challenge-list?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return keyValueFormat(res?.data?.data || []);
    }
    return [];
  };

  return {
    list,
    labList,
    getOrgs,
    getLabs,
    getChallenges,
    getProjects,
    getLabChallenges
  };
};

export default useLeaderboard;
