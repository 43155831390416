import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import {useTranslation} from "react-i18next";
import {isGoogleCaptchaDisabled} from "../../helpers/utils/utilities";

const RecaptchaV2 = (props) => {

    /**
     * COMPONENT PROPS
     */
    const {onChange, captchaRef, error} = props
    const DISABLE_RECAPTCHA = isGoogleCaptchaDisabled()

    /**
     * HOOKS
     */
    const {t} = useTranslation()

    return (
        <>
            {
                !DISABLE_RECAPTCHA ?
                    <div className={'flex items-center w-full flex-column'}>
                        <ReCAPTCHA
                            ref={captchaRef ?? null}
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                            onChange={onChange}
                            type={'image'}
                            size={'normal'}
                        />
                        {error ? <div>
                            <div className="mt-1 c-error fs-14--noMob">{t('Please verify the captcha.')}</div>
                        </div> : ""}
                    </div> : ''
            }
        </>
    )
}

export default RecaptchaV2
