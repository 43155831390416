import { KeyboardArrowRight } from "@mui/icons-material";
import { Divider } from "@mui/material";
import Button from "components/Button";
import ModalLayout from "components/Modal";
import GetAddonsButton from "components/UI/molecules/GetAddonsButton";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import React from "react";

const RadeemLabPopup = ({
  lab = {},
  open,
  handleRadeemLab,
  onClose,
  loading,
  showSuccess,
  plan,
  radeemSlug,
  t = (d) => d,
}) => {
  const { navigateMemberManagement, navigateUpgradePlan } = useRedirect();

  return (
    <ModalLayout open={open} width="min(95%,700px)" onClose={onClose}>
      {lab === "all_credits_exhausted" ? (
        <div className="flex-column h-350 justify-between items-center">
          <div className="flex-column items-center justify-center flex-1  text-center">
            <h2>{t("You have used all your Pre-built Lab credits!")}</h2>
            <p className="mt-3 w-90p mx-auto">
              {t(
                "Click to choose to purchase more credits or upgrade your existing plan. If you do not have permission to change your plan, please contact your organization administrator."
              )}
            </p>
          </div>
          <div className="flex justify-around w-100p mb-10">
            <GetAddonsButton buttonTitle={t("Purchase Credits")} />
            <Button color="green" onClick={navigateUpgradePlan}>
              {t("Upgrade Your Plan")}
            </Button>
          </div>
        </div>
      ) : lab?.radeemSuccess || showSuccess ? (
        <div className="flex-column h-350 justify-between items-center">
          <div className="flex-column items-center justify-center flex-1  text-center">
            <h1>{t("Lab Redeemed Successfully!")}</h1>
            <p className="mt-3 w-90p mx-auto">
              {t(
                "You are now able to edit Lab details or invite members to your Lab."
              )}
            </p>
          </div>

          <Button
            classes="mb-10"
            color="green"
            height="45px"
            onClick={() =>
              navigateMemberManagement(COMPONENT_TYPES.LAB, radeemSlug)
            }
          >
            {t("Invite Members")}
          </Button>
        </div>
      ) : (
        <div className="px-5 px-2--mob py-2">
          <h2>{t("Redeem Summary")}</h2>
          <Divider className="my-4 w-100p" />
          <div className="flex-colOnMob justify-between">
            <h4>{lab?.title}</h4>
            <h4>1 {t("Lab credit")}</h4>
          </div>
          <div
            className={`flex-column ${
              Boolean(
                !lab?.challenge_count &&
                  !lab?.challenge_path_count &&
                  !lab?.resource_module_count &&
                  !lab?.resource_collection_count &&
                  !lab?.resource_group_count
              )
                ? "hidden"
                : ""
            }`}
          >
            <h4 className="my-1">
              <b>{t("Includes")}:</b>
            </h4>
            {[
              Number(lab?.challenge_count) > 0 &&
                `${lab?.challenge_count || 0} ${t("Challenges")}`,
              Number(lab?.challenge_path_count) > 0 &&
                `${lab?.challenge_path_count || 0} ${t("Challenge Paths")}`,
              Number(lab?.resource_module_count) > 0 &&
                `${lab?.resource_module_count || 0} ${t("Resource Modules")}`,
              Number(lab?.resource_collection_count) > 0 &&
                `${lab?.resource_collection_count || 0} ${t(
                  "Resource Collections"
                )}`,
              Number(lab?.resource_group_count) > 0 &&
                `${lab?.resource_group_count || 0} ${t("Resource Groups")}`,
            ]
              .filter(Boolean)
              .map((text, i) => (
                <div className="flex items-center" key={i}>
                  <KeyboardArrowRight fontSize="small" />
                  <p className="fs-12 ml-1">{text}</p>
                </div>
              ))}
          </div>
          <Divider className="my-4 w-100p" />
          <div className="flex-column items-right fw-500">
            <div className="flex">
              <p>{t("Total Credit Used")}:</p>
              <p className="w-100 text-right">{plan?.pre_build_lab_count}</p>
            </div>
            <div className="flex">
              <p>{t("Remaining Credits")}:</p>
              <p className="w-100 text-right">
                {plan?.pre_build_lab_limit === "Unlimited"
                  ? "Unlimited"
                  : isNaN(plan?.pre_build_lab_limit) &&
                    isNaN(plan?.pre_build_lab_count)
                  ? "0"
                  : Number(plan?.pre_build_lab_limit) -
                    Number(plan?.pre_build_lab_count)}
              </p>
            </div>
          </div>
          <Button
            width="100%"
            loading={loading}
            classes="my-2"
            onClick={handleRadeemLab}
          >
            {t("Redeem")}
          </Button>
        </div>
      )}
    </ModalLayout>
  );
};

export default RadeemLabPopup;
