import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import MainPageLayout from "pages/layouts/MainPage";
import FilterCard from "components/UI/organisms/FilterCard";
import ListingPageHeader from "components/UI/organisms/ListingPageHeader";
import useListingHeader from "helpers/hooks/useListingHeader";

import useLabMarketplace from "store/lab-marketplace/service-hook";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import RadeemLabPopup from "./components/RadeemLabPopup";
import PlanDetails from "components/UI/molecules/PlanDetails";
import PageTitleCard from "components/UI/molecules/PageTitleCard";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import LabMarketplaceListing from "./components/LabMarketplaceListing";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import { SORT } from "helpers/utils/constants";
import { TRUE } from "sass";

export const MAX_CREDITS = 3;

const LabMarketplace = () => {
  const { gridType, setGridType, search, setSearch, setSort, sort } =
    useListingHeader();
  const { list, redeem } = useLabMarketplace();
  const [labs, setLabs] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { selected } = useFilters();
  const [radeemModalOpen, setRadeemModalOpen] = useState(false);
  const [redeemLoading, setRedeemLoading] = useState(false);
  const { user } = useSelector((state) => state[REDUCER_TYPES.AUTH]);
  const { t } = useLocalizedTranslation();
  const [plan, setPlan] = useState(null);

  useEffect(() => {
    fetchList();
  }, [search, selected, page, sort]);

  useEffect(() => {
    if (Boolean(search) || sort !== "creation_date") setPage(1);
  }, [search, sort]);

  const fetchList = async () => {
    setLoading(true);

    const filters = {
      search: search,
      sort_by: sort,
      page: page,
      ...selected,
    };
    if (filters?.marketplace_org?.length) {
      filters.organization_ids = filters.marketplace_org;
      delete filters.marketplace_org;
    }
    if (filters?.redeem_status) {
      filters.status = filters?.redeem_status;
      delete filters.redeem_status;
    }
    const res = await list(filters);
    if (res) {
      const { list, current_page, total_pages } = res;
      setPage(total_pages < current_page ? total_pages : current_page || 1);
      setPages(total_pages || 1);
      setLabs(list || []);
    }
    setLoading(false);
  };
  const openModalWithLab = (lab) => {
    if (!plan) return;
    if (
      !plan?.pre_build_lab_limit ||
      ((plan?.pre_build_lab_limit?.toString() || "")?.toLowerCase() !==
        "unlimited" &&
        plan?.pre_build_lab_count === plan?.pre_build_lab_limit)
    ) {
      setRadeemModalOpen("all_credits_exhausted");
    } else {
      setRadeemModalOpen(lab);
    }
  };
  const handleRadeemLab = async () => {
    const labId = radeemModalOpen?.slug;
    setRedeemLoading(true);
    const res = await redeem(labId, user?.organization_details?.id);
    if (res?.data?.data) {
      setLabs((prev) =>
        prev.map((d) => (d.slug === labId ? { ...d, is_redeemed: "yes" } : d))
      );
      setRadeemModalOpen({
        ...radeemModalOpen,
        radeemSuccess: true,
        radeemSlug: res?.data?.data?.slug,
      });
      setPlan((prev) => ({
        ...prev,
        pre_build_lab_count: !prev?.pre_build_lab_count
          ? 1
          : !isNaN(prev?.pre_build_lab_count)
          ? Number(prev?.pre_build_lab_count) + 1
          : prev?.pre_build_lab_count,
      }));
    }
    setRedeemLoading(false);
  };

  const handleSelectPlan = () => {
    const instance = window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_STIE,
      publishableKey: process.env.REACT_APP_CHARGEBEE_KEY,
    });
    let cbPortal = instance.createChargebeePortal();

    cbPortal.open({
      close() {
        window.location.reload();
      },
    });
  };

  const handleModalClose = () => setRadeemModalOpen(false);

  return (
    <DashboardLayout>
      <PageTitleCard
        actionTitle="Purchase Credits"
        onClickAction={handleSelectPlan}
        title="Lab Marketplace"
        actionColor="yellow"
      >
        <PlanDetails component="lab" setMyPlan={setPlan} />
      </PageTitleCard>
      <MainPageLayout classes="my-6">
        <div className="left">
          <FilterCard
            title="Advanced Search"
            types={[
              FILTER_TYPES.REDEEM_STATUS,
              FILTER_TYPES.DURATION,
              FILTER_TYPES.LEVEL,
              FILTER_TYPES.MARKETPLACE_ORGS,
              FILTER_TYPES.SKILLS,
            ]}
          />
        </div>
        <div className="right">
          <ListingPageHeader
            gridType={gridType}
            setGridType={setGridType}
            search={search}
            setSearch={setSearch}
            setSort={setSort}
            sort={sort}
            sortOptions={SORT.map((d) => ({
              ...d,
              value: d?.key === "creation_date" ? "Recently Added" : d?.value,
            }))}
          />
          <LabMarketplaceListing
            labs={labs}
            loading={loading}
            page={page}
            pages={pages}
            setPage={setPage}
            handleRadeem={openModalWithLab}
            gridType={gridType}
          />
        </div>
      </MainPageLayout>
      <RadeemLabPopup
        handleRadeemLab={handleRadeemLab}
        open={!!radeemModalOpen}
        onClose={handleModalClose}
        lab={radeemModalOpen}
        loading={redeemLoading}
        radeemSlug={radeemModalOpen?.radeemSlug}
        plan={plan}
        t={t}
      />
    </DashboardLayout>
  );
};

export default LabMarketplace;
