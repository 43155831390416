import { Checkbox, Divider } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import Select from "components/Input/Select";
import React, { useState } from "react";
import ModalLayout from ".";
import Card from "components/Cards/Card";

const cart_items = [
  {
    title: "Preset Lab",
    includes: [
      "Preset Lab for your Org",
      "50 licenses",
      "1000 PreprLabs points",
    ],
    price: 250,
    preprPoints: 200,
  },
  {
    title: "CARE Challenge",
    includes: ["CARE Challenge"],
    price: 150,
    preprPoints: 200,
  },
];

const CheckoutModal = ({ open, onClose }) => {
  const [step, setStep] = useState(0);

  return (
    <ModalLayout
      data-testid="checkout-modal"
      open={open}
      onClose={onClose}
      width="min(98%,500px)"
    >
      <Card centered classes="checkout__right">
        <h3>{step === 0 ? "Order Summary" : "Checkout"}</h3>
        {step === 0 && (
          <>
            {cart_items.map((item, i) => (
              <>
                <Divider className="mt-1 mb-2" />
                <div key={i} className="flex justify-between fs-14 mt-2">
                  <div>
                    <p className="fw-500">{item?.title}</p>
                    <p className="ml-4">Includes:</p>
                    {item.includes.map((d,i) => (
                      <p key={i} className="ml-4">{d}</p>
                    ))}
                  </div>
                  <div>
                    <p className="fw-500 text-right">${item.price}</p>
                    <p style={{ fontStyle: "italic" }}>
                      Or {item.preprPoints} prepr points
                    </p>
                  </div>
                </div>
              </>
            ))}
            <div className="text-right">
              <p className="c-success fs-16 fw-500">
                Points Used: <span className="w-100 ml-2"> -$500</span>
              </p>
              <p className="c-error fs-18 fw-500">
                Order Total: <span className="w-100 ml-6"> $0</span>
              </p>
            </div>
            <Divider className="mt-1 mb-2" />
          </>
        )}
        {step === 1 && (
          <>
            <Divider className="my-2" />
            <p>
              <b>Card</b>
            </p>
            <Input
              placeholder="1234 1234 1234 1234"
              title="Card Number"
              height={30}
              width="100%"
              gray
            />
            <div className="flex items-center mt-1">
              <Input
                placeholder="MM/YY"
                title="Expiry"
                gray
                height={30}
                width={200}
              />
              <Input
                placeholder="CVC"
                title="CVC"
                gray
                classes="ml-2 flex-1"
                height={30}
                width="100%"
              />
            </div>
            <div className="flex items-center justify-between mt-1">
              <Select
                title="Country"
                values={["Canada", "India"]}
                placeholder="Canada"
                value="Canada"
                width={200}
                height={30}
                gray
              />
              <Input
                placeholder="MST 1T4"
                title="Postal Code"
                gray
                classes="flex-1 ml-2"
                height={30}
                width="100%"
              />
            </div>
            <div className="text-right flex items-center justify-end mt-2">
              <p className="mr-1">Save Payments details</p>
              <Checkbox />
            </div>
            <Divider className="my-1" />
            <p>
              <b>Prepr Lab Points</b>
            </p>
            <div className="flex items-center justify-between">
              <p>Balance: 13,204 points</p>
              <p>
                Use points <Checkbox />
              </p>
            </div>
            <Divider className="my-1" />
            <p>
              <b>Code</b>
            </p>
            <div className="flex items-center mt-2">
              <Button height={35} gray width="100%" bordered>
                Use a Code
              </Button>
              <Button height={35} bordered classes="ml-2">
                Apply
              </Button>
            </div>
            <div className="result-box success w-90p mt-2 px-2 py-1">
              <p className="fs-14">Code Applied! Unlocks CARE Challenge</p>
            </div>
            <div className="result-box success w-90p mt-2 px-2 py-1">
              <p className="fs-14">Code Applied! Unlocks Preset Lab</p>
            </div>
            <Divider className="my-2" />
          </>
        )}
        <div className="flex justify-end mt-4">
          <Button onClick={() => step === 0 && setStep(1)} color="yellow">
            {step === 0 ? "Continue" : "Place Order"}
          </Button>
        </div>
      </Card>
    </ModalLayout>
  );
};

export default CheckoutModal;
