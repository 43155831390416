import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { serializeObject } from "store/api/utils";
import ACTIONS from "./action-types";
import { REDUCER } from "./reducer";

const useNotifications = () => {
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);
  const dispatch = useDispatch();

  const headers = {
    Authorization: "Bearer " + token,
  };

 
  const fetchNotifications = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${
        ENDPOINTS.NOTIFICATIONS
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      const { list, total_pages,total_count, per_page, unread_notifications_count, unread_notifications } = res?.data?.data;
      dispatch(
        REDUCER[ACTIONS.NOTIFICATION_COUNT]({
          count: list?.filter(notification => !notification?.read).length,
        })
      );

      return [list, total_pages, total_count, per_page, unread_notifications_count, unread_notifications];
    }
    return [];
  };

  const readNotifications = async (payload) => {
    const client = new REQUEST();
    const res = await client.post(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.NOTIFICATIONS}/${ENDPOINTS.MARK_AS_READ}`,
        payload,
        headers,
        Function.prototype
    );
    if (res?.data?.data) {
        return res?.data?.data;
    }
}

const deleteNotifications = async (id) => {
    const client = new REQUEST();
    const res = await client.delete(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.NOTIFICATIONS}/${id}`,
        headers,
        Function.prototype
    );
    if (res?.data?.data) {
        return res?.data?.data;
    }
}

  return {
    fetchNotifications,
    readNotifications,
    deleteNotifications
  
  };
};

export default useNotifications;
