import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { resolveResponse } from "store/api/utils";
import { REDUCER } from "./reducer";
import ACTIONS from "./action-types";
import { serializeObject } from "store/api/utils";
import axios from "axios";
import { toast } from "react-toastify";

const useSettings = () => {
  const { language, token, user } = useSelector(
    (state) => state[REDUCER_TYPES.AUTH]
  );
  const { settings } = useSelector((state) => state[REDUCER_TYPES.SETTINGS]);

  const dispatch = useDispatch();

  const headers = {
    Authorization: "Bearer " + token,
  };
  //VIEW LOGGED IN USER DETAILS API
  const details = async () => {
    if (!!settings) return settings;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.USER}/${ENDPOINTS.LOGGED_IN}/${ENDPOINTS.DETAILS}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      dispatch(REDUCER[ACTIONS.SETTINGS](res?.data?.data));
      return res?.data?.data;
    }
  };

  //UPDATE ACCOUNT SETTINGS API
  const updateAccount = async (payload) => {
    if (!payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.SETTINGS}/${ENDPOINTS.ACCOUNT}/${ENDPOINTS.UPDATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //UPDATE PRIVACY SETTINGS API
  const updatePrivacy = async (payload) => {
    if (!payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.SETTINGS}/${ENDPOINTS.PRIVACY}/${ENDPOINTS.UPDATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) {
      dispatch(REDUCER[ACTIONS.SETTINGS](res?.data?.data));
      return res?.data?.data;
    }
  };

  //UPDATE NOTIFICATION SETTINGS API
  const updateNotifications = async (payload) => {
    if (!payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.SETTINGS}/${ENDPOINTS.NOTIFICATION}/${ENDPOINTS.UPDATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) {
      dispatch(REDUCER[ACTIONS.SETTINGS](res?.data?.data));
      return res?.data?.data;
    }
  };

  //UPDATE PASSWORD SETTINGS API
  const updatePassword = async (payload) => {
    if (!payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.SETTINGS}/${ENDPOINTS.PASSWORD}/${ENDPOINTS.UPDATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //REMOVE IMAGE API
  const removeImage = async () => {
    const client = new REQUEST();
    const res = await client.delete(
      `${ENDPOINTS.SETTINGS}/${ENDPOINTS.IMAGE}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      resolveResponse
    );
    if (res?.data?.data) {
      dispatch(REDUCER[ACTIONS.SETTINGS](res?.data?.data));
      return res?.data?.data;
    }
  };

  //DEACTIVATE ACCOUNT API
  const deactivateAccount = async () => {
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.SETTINGS}/${ENDPOINTS.ACCOUNT}/${ENDPOINTS.DEACTIVATE}?language=${language}`,
      {},
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //ACIVATE-DEAVTIVATE SSO INTEGRATION SETTINGS
  const ssoAuthSetting = async (payload) => {
    if (!payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.SETTINGS}/${ENDPOINTS.AUTHENTICATION}/${ENDPOINTS.SSO}?language=${language}`,
      body,
      headers,
      resolveResponse
    );
    if (res?.data?.data) {
      dispatch(REDUCER[ACTIONS.SETTINGS](res?.data?.data));
      return res?.data?.data;
    }
  };


  const preSignedUrlScorm = async (params = {}) => {
      const onSuccess = (res) => resolveResponse(res, null, null, null, true);
      const client = new REQUEST();
      const res = await client.get(
        `setting/scorm/presigned-url?language=${language}&${serializeObject(params)}`,
        {},
        headers,
        onSuccess
      );
      if (res?.data?.data) {
        return res?.data?.data;
      }
  };

  const preSignedURL = async (params = {}) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `setting/s3-presigned-url?language=${language}&${serializeObject(params)}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const handleFileUpload = async (url, payload) => {
    try {
      const res = await axios.put(url, payload, {
        headers: {
          "Content-Type": payload.type, // this depends on the file type
        },
      });
      return res;
    } catch (error) {
      toast.error("Failed to upload file. Please try again.");
      console.error(error);
    }
  };


  const generatePresignedUrls = async (filesToUpload, component_type) => {
    try {
      const uploadPromises = filesToUpload.map(async (file) => {
        const res = await preSignedURL({
          filename: file.name,
          content_type: file.type,
          component_type: component_type,
        });

        if (res?.url) {
          await handleFileUpload(res.url, file.data);
          return { ...file, response: res };
        }
        return null;
      });

      const uploadedFiles = await Promise.all(uploadPromises);
      return uploadedFiles.filter((file) => file !== null);
    } catch (error) {
      return [];
    }
  };
  
  return {
    details,
    updateAccount,
    updatePrivacy,
    updateNotifications,
    updatePassword,
    removeImage,
    deactivateAccount,
    ssoAuthSetting,
    preSignedUrlScorm,
    preSignedURL,
    handleFileUpload,
    generatePresignedUrls
  };
  
};

export default useSettings;
