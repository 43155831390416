import { Grid } from "@mui/material";
import ModalLayout from "components/Modal";
import DataTable from "pages/report/components/Datatable";
import React, { useEffect, useState } from "react";
import SecureImage from "components/UI/atoms/SecureImage";
import useChallengeReport from "store/reports/challenges/service-hooks";
import ListingContainer from "components/UI/organisms/ListingContainer";
import userIcon from "assets/svg/gradient/user.svg";

function ProjectAssessmentDetailsModal({ open, onClose, details, slug }) {
  const { challengeAssessmentProjectDetails } = useChallengeReport();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  const fetchAssessmentDetails = async () => {
    try {
      setIsLoading(true);
      if (slug && open) {
        const response = await challengeAssessmentProjectDetails(
          slug,
          details?.slug
        );
        if (response) {
          setData(response);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssessmentDetails();
  }, [slug, details?.slug]);

  return (
    <ModalLayout open={open} onClose={onClose} width="min(1000px,95vw)">
      <div className="mx-6 my-5">
        <h4>
          Project Assessment Details:{" "}
          <span className="c-primary">{data?.title || "N/A"}</span>
        </h4>
        <div className="mt-2">
          <div className="flex fs-15 mb-1">
            <label className="bold mr-1">Score:</label>
            <p className="c-primary">{data?.score || "N/A"}</p>
          </div>
          <div className="flex fs-15 mb-1">
            <label className="bold mr-1">Team Members:</label>
            <p className="c-primary">
              {Array.isArray(data?.team_members)
                ? data?.team_members?.map((m) => m?.full_name)?.join(", ")
                : "N/A"}
            </p>
          </div>
          <div className="flex fs-15 mb-1">
            <label className="bold mr-1">Achievement awarded:</label>
            <p className="c-primary">{data?.achievement || "N/A"}</p>
          </div>
        </div>
        <ListingContainer
          noPagination={true}
          loading={isLoading}
          spinLoader={true}
          classes="mx-1"
        >
          <Grid container>
            {data?.users?.map((user, index) => (
              <Grid item xs={12} className="mt-4" key={index}>
                <h4>Assessment Details</h4>
                <Grid
                  container
                  spacing={2}
                  className="mt-2 w-full"
                  justifyContent="center"
                >
                  <Grid item xs={12} md={2} className="text-center">
                    <div className="flex justify-center">
                      <div className="w-80 rounded-30 h-80 flex justify-center items-center">
                        <SecureImage
                          src={user?.profile || ""}
                          alt="profile"
                          className="w-full h-full rounded-20 object-cover"
                          placeholder={userIcon}
                        />
                      </div>
                    </div>
                    <label className="bold fs-15">Assessor:</label>
                    <p className="fs-15">{user?.full_name || "N/A"}</p>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <div className="flex fs-15">
                      <label className="bold mr-1">Assessed Score:</label>
                      <p className="c-primary">
                        {user?.overall_score || "N/A"}/
                        {user?.outof_score || "N/A"}
                      </p>
                    </div>
                    <div className="mt-1">
                      <p className="fs-15">
                        <label className="bold">Comments: </label>
                        {user?.criteria_comment?.length
                          ? user?.criteria_comment
                          : "N/A"}
                      </p>
                    </div>
                  </Grid>
                </Grid>
                <div className="mt-2">
                  <DataTable
                    data={user?.assessments || []}
                    loading={false}
                    page={1}
                    perPage={15}
                    noFirstColCenter
                    columns={[
                      {
                        title: "Criteria",
                        dataIndex: "criteria",
                        render(item) {
                          return <p>{item || "N/A"}</p>;
                        },
                      },
                      {
                        title: "Weight",
                        dataIndex: "weight",
                        render(item) {
                          return <p>{item ? `${item}%` : "N/A"}</p>;
                        },
                      },
                      {
                        title: "Score",
                        dataIndex: "score",
                        render(item, _, row) {
                          return (
                            <p>
                              {row?.score_received || "N/A"}/{item || "N/A"}
                            </p>
                          );
                        },
                      },
                      {
                        title: "Comment",
                        dataIndex: "comment",
                        render(item) {
                          return <p>{item || "N/A"}</p>;
                        },
                      },
                    ]}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </ListingContainer>
      </div>
    </ModalLayout>
  );
}

export default ProjectAssessmentDetailsModal;
