import React, { useEffect, useState } from "react";
import OrgListing from "./OrgListing";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import useRedirect from "helpers/hooks/useRedirect";
import useOrganization from "store/organization/service-hook";
import BrowseWrapper from "components/UI/organisms/BrowseWrapper";
import useListingHeader from "helpers/hooks/useListingHeader";
import {useLocalizedTranslation} from "../../../helpers/hooks/useLocalizedTranslation";

const BROWSE_OPTIONS = [
  {
    id: 0,
    title: "Browse",
    pageTitle: "Browse Organizations",
  },
  {
    id: 1,
    title: "My Organizations",
    pageTitle: "Browse Organizations",
  },
  {
    id: 2,
    title: "Pending Invitations",
    pageTitle: "Browse Organizations",
  },
  {
    id: 3,
    title: "Saved",
    pageTitle: "Browse Organizations",
  },
];

const BrowseOrganizations = () => {
  const [organizations, setOrganizations] = useState([]);
  const [pendingInviteCount, setPendingInviteCount] = useState(0);
  const [browseOptions, setBrowseOptions] = useState(BROWSE_OPTIONS);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [browseMode, setBrowseMode] = useState(0);
  const { navigateManageOrgs } = useRedirect();
  const { getPublicOrgList } = useOrganization();
  const { selected } = useFilters();
  const state = useListingHeader();
  const { t } = useLocalizedTranslation("common");


  useEffect(() => {
    const abortController = new AbortController();
    fetchOrganizations(abortController);
    return () => abortController.abort();
  }, [selected, state.sort, page, state.search, browseMode]);

  const fetchOrganizations = async (abortController) => {
    setLoading(true);
    const filters = {
      ...selected,
      sort_by: state.sort,
      search: state.search,
      page,
    };
    if (selected?.organization_type)
      filters.category = selected?.organization_type;
    if (browseMode === 1) {
      filters["owner"] = "my";
    } else if (browseMode === 2) {
      filters["owner"] = "pending";
    } else if (browseMode === 3) {
      filters["social_type"] = "favourites";
    }

    const [list, count, pending_invite] = await getPublicOrgList(filters);

    if (!abortController.signal.aborted) {
      setOrganizations(list || []);
      setPageCount(count);
      setLoading(false);
      if (pending_invite !== pendingInviteCount) {
        setTimeout(() => {
          setPendingInviteCount(pending_invite);
          setBrowseOptions((prev) =>
              prev.map((d) => ({
                ...d,
                title:
                    d?.id === 2 && Boolean(pending_invite)
                        ? `${t(d?.title)} (${pending_invite})`
                        : d?.title,
              }))
          );
        }, 1000);
      }
    }
  };

  return (
    <BrowseWrapper
      browseMode={browseMode}
      browseOptions={browseOptions}
      setBrowseMode={setBrowseMode}
      manageNavigate={navigateManageOrgs}
      {...state}
      filters={[
        FILTER_TYPES.ORGANIZATION_TYPE,
        FILTER_TYPES.VERIFICATION_STATUS,
        FILTER_TYPES.COMPANY_SIZE,
      ]}
      noAction
    >
      <OrgListing
        gridType={state.gridType}
        list={organizations}
        setList={setOrganizations}
        loading={loading}
        page={page}
        setPage={setPage}
        pageCount={pageCount}
        browseMode={browseMode}
      />
    </BrowseWrapper>
  );
};

export default BrowseOrganizations;
