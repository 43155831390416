import React, { useEffect, useState } from "react";
import useLeaderboard from "store/leaderboard/service-hook";
import LeaderBoardTable from "components/UI/organisms/LeaderBoardTable";
import useAuth from "store/auth/service-hook";
import { getLeaderboardJSXCols } from "pages/leaderBoard/data";
import useRedirect from "helpers/hooks/useRedirect";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const LeaderBoardCard = () => {
  const { list } = useLeaderboard();
  const [items, setItems] = useState();
  const [totalCount, setTotalCount] = useState();
  const [pages, setPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { navigateLeaderboard } = useRedirect();
  const { t } = useLocalizedTranslation("achievements");
  const {
    state: { user },
  } = useAuth();

  useEffect(() => {
    fetchList();
  }, [page]);

  const fetchList = async () => {
    setLoading(true);
    const [_list, _pages, _count] = await list({
      page: page,
      list_type: "dashboard",
    });
    setItems(_list || []);
    setPages(_pages || 1);
    setTotalCount(_count || 0);
    setLoading(false);
  };

  const filterColsByDashboardType = (cols) => {
    const getTypeByLocation = () => {
      switch (window.location.pathname) {
        case "/dashboard":
          return "user";
        case "/dashboard/lab":
          return "lab";
        case "/dashboard/org":
          return "org";
        default:
          return "user";
      }
    };
    const type = getTypeByLocation();
    if (type === "lab" || type === "org") return cols.slice(0, 3);
    return cols;
  };

  return (
    <div className="px-4">
      <div className="flex justify-between mb-3 mt-1">
        <h3>{t("Leaderboard")}</h3>
        <h4 onClick={navigateLeaderboard} className="underline link">
          {t("View Full Leaderboard")}
        </h4>
      </div>
      <LeaderBoardTable
        username={user?.username}
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        pageCount={pages}
        rows={items}
        columns={filterColsByDashboardType(
          getLeaderboardJSXCols((v) => t(v, "achievements"))
        )}
        loading={loading}
        isDashboard
      />
    </div>
  );
};

export default LeaderBoardCard;
