import { Grid } from "@mui/material";
import ModalLayout from "components/Modal";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import preprai from "../../../assets/png/ai/prepr-ai.png";
import useRedirect from "helpers/hooks/useRedirect";

const ProjectAssessmentAIModal = ({ open, onClose, project }) => {
  const { t } = useLocalizedTranslation();
  const { navigateChallenge } = useRedirect();

  const totalScores = project?.assessment_data?.assessment_scoring_data
    ? project.assessment_data.assessment_scoring_data.reduce(
        (acc, item) => {
          acc.totalScore += item.score || 0;
          acc.totalScoreReceived += item.score_received || 0;
          return acc;
        },
        { totalScore: 0, totalScoreReceived: 0 }
      )
    : { totalScore: 0, totalScoreReceived: 0 };

  const { totalScore, totalScoreReceived } = totalScores;

  const memberUsernames = project?.members?.map((member, index) => (
    <span key={member.user_name} className="link">
      {member.user_name}
      {index < member.length - 1 && ", "}
    </span>
  ));

  const criteriaData = project?.assessment_data.assessment_scoring_data?.map(
    (item) => ({
      criteria: item.title,
      weight: `${item.weight}%`,
      score: `${item.score_received || 0}/${item.score}`,
      comment: item.comment || "No comment provided",
    })
  );

  return (
    <ModalLayout open={open} width="max(75%)" onClose={onClose}>
      <div className="px-4 py-4">
        <h2>
          {t("Project Assessment Details")}:{" "}
          <span
            className="link"
            onClick={() =>
              navigateChallenge(project.challenge_details.slug, false)
            }
          >
            {project.challenge_details?.title}
          </span>
        </h2>
        <div className="mt-3 fs-15">
          <p>
            <b>{t("Score")}: </b>
            {totalScoreReceived}/{totalScore}
          </p>
          <p>
            <b>{t("Team Members")}: </b>
            {memberUsernames}
          </p>
          <p>
            <b>{t("Achievement Awarded")}: </b>
            <span className="link">
              {project.challenge_achievement?.achievement_name}
            </span>
          </p>
        </div>
        <h3 className="mt-6">{t("Assessment Details")}</h3>
        <Grid container mt={2}>
          <Grid
            item
            md={3}
            justifyContent="center"
            textAlign="center"
            alignItems="center"
          >
            <img src={preprai} alt="Prepr AI" className="ai-image" />
          </Grid>
          <Grid item md={9} alignItems="center">
            <p>
              <b>{t("Assessed Score")}: </b>
              {totalScoreReceived}/{totalScore}
            </p>
            <p>
              <b>{t("Comments")}: </b>
              {project.assessment_data.assessment_over_all_comment}
            </p>
          </Grid>
        </Grid>
        <Grid container className="fs-14 opacity-50 mb-1" mt={3}>
          <Grid item xs={3}>
            <p>{t("Critiria")}</p>
          </Grid>
          <Grid item xs={2}>
            <p>{t("Weight")}</p>
          </Grid>
          <Grid item xs={2}>
            <p>{t("Score")}</p>
          </Grid>
          <Grid item xs={5}>
            <p>{t("Comments")}</p>
          </Grid>
        </Grid>
        {criteriaData?.map((data, index) => (
          <AssessmentRow
            key={index}
            criteria={data.criteria}
            weight={data.weight}
            score={data.score}
            comment={data.comment}
          />
        ))}
      </div>
    </ModalLayout>
  );
};
export default ProjectAssessmentAIModal;

const AssessmentRow = ({ criteria, weight, score, comment }) => {
  return (
    <>
      <hr />
      <Grid container py={2} className="fs-15">
        <Grid item xs={8} sm={3} className="fw-500 pr-2 fs-14">
          {criteria}
        </Grid>
        <Grid item xs={4} sm={2} pl={1}>
          {weight}
        </Grid>
        <Grid item xs={12} sm={2}>
          {score}
        </Grid>
        <Grid item xs={12} sm={5}>
          {comment}
        </Grid>
      </Grid>
    </>
  );
};
