import useAdvanceSearch from "./service-hook";
import {initStore} from "../mocks/store";
import {REDUCER_TYPES} from "../index";
import {ADVANCE_SEARCH_COMPONENTS} from "../../pages/advanceSearch/data";
import {render, waitFor} from "@testing-library/react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {mockRequestGet} from "../mocks/request";
import {ENDPOINTS} from "../api/endpoints";
import userEvent from "@testing-library/user-event";

let getSpy;
let screen;

const headers = {
    Authorization: "Bearer 1",
};

const store = initStore({
    [REDUCER_TYPES.ADVANCE_SEARCH]: {
        [ADVANCE_SEARCH_COMPONENTS.LABS]: {},
        [ADVANCE_SEARCH_COMPONENTS.LAB_PROGRAMS]: {},
        [ADVANCE_SEARCH_COMPONENTS.LAB_MARKET_PLACE]: {},
        [ADVANCE_SEARCH_COMPONENTS.CHALLENGES]: {},
        [ADVANCE_SEARCH_COMPONENTS.CHALLENGE_TEMPLATES]: {},
        [ADVANCE_SEARCH_COMPONENTS.CHALLENGE_PATHS]: {},
        [ADVANCE_SEARCH_COMPONENTS.RESOURCE_MODULES]: {},
        [ADVANCE_SEARCH_COMPONENTS.RESOURCE_COLLECTION]: {},
        [ADVANCE_SEARCH_COMPONENTS.RESOURCE_GROUPS]: {},
        [ADVANCE_SEARCH_COMPONENTS.PROJECTS]: {},
        [ADVANCE_SEARCH_COMPONENTS.ORGANIZATIONS]: {}
    },
    [REDUCER_TYPES.AUTH]: {
        language: 'en',
        token: 1
    }
});


const AdvanceSearchServiceMock = () => {
    /**
     * ADVANCE SEARCH HOOK
     */
    const {
        searchChallengePaths,
        searchChallenges,
        searchChallengeTemplates,
        searchLabMarketplace,
        searchLabPrograms,
        searchLabs,
        searchOrganizations,
        searchProjects,
        searchResourceCollection,
        searchResourceGroups,
        searchResourceModules,
        searchUsers
    } = useAdvanceSearch()


    const servicesArray = [
        {
            onClick: searchChallengePaths,
            description: 'Advance Search for Challenge Paths',
            name: 'search_challenge_paths'
        },
        {
            onClick: searchChallenges,
            description: 'Advance Search for Challenges',
            name: 'search_challenges'
        },
        {
            onClick: searchChallengeTemplates,
            description: 'Advance Search for Challenge Templates',
            name: 'search_challenge_templates'
        },
        {
            onClick: searchLabMarketplace,
            description: 'Advance Search for Lab Marketplace',
            name: 'search_lab_marketplace'
        },
        {
            onClick: searchLabPrograms,
            description: 'Advance Search for Lab Programs',
            name: 'search_lab_programs'
        },
        {
            onClick: searchLabs,
            description: 'Advance Search for Labs',
            name: 'search_labs'
        },
        {
            onClick: searchOrganizations,
            description: 'Advance Search for Organizations',
            name: 'search_organizations'
        },
        {
            onClick: searchProjects,
            description: 'Advance Search for Projects',
            name: 'search_projects'
        },
        {
            onClick: searchResourceCollection,
            description: 'Advance Search for Resource Collection',
            name: 'search_resource_collection'
        },
        {
            onClick: searchResourceGroups,
            description: 'Advance Search for Resource Groups',
            name: 'search_resource_groups'
        },
        {
            onClick: searchResourceModules,
            description: 'Advance Search for Resource Modules',
            name: 'search_resource_modules'
        },
        {
            onClick: searchUsers,
            description: 'Advance Search for Users',
            name: 'search_users'
        }]

    return <>
        <div>
            {servicesArray.map((item) => (
                <button data-testid={item.name} key={item.name} onClick={() => item.onClick('test')}>
                    Call {item.description}
                </button>
            ))}
        </div>
    </>
}

/**
 * TEST START
 */
describe("Advance search Services", () => {
    beforeEach(() => {
        screen = render(
            <Provider store={store}>
                <BrowserRouter>
                    <AdvanceSearchServiceMock/>
                </BrowserRouter>
            </Provider>
        );
        getSpy = mockRequestGet();
    });

    const testArray = [
        {
            description: 'Advance Search for Challenge Paths',
            button_id: 'search_challenge_paths',
            endPoint: `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.CHALLENGE_PATHS}?language=en&keyword=test`
        },
        {
            description: 'Advance Search for Challenges',
            button_id: 'search_challenges',
            endPoint: `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.CHALLENGES}?language=en&keyword=test`
        },
        {
            description: 'Advance Search for Challenge Templates',
            button_id: 'search_challenge_templates',
            endPoint: `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.CHALLENGE_TEMPLATES}?language=en&keyword=test`
        },
        {
            description: 'Advance Search for Lab Marketplace',
            button_id: 'search_lab_marketplace',
            endPoint: `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.LAB_MARKETPLACES}?language=en&keyword=test`
        },
        {
            description: 'Advance Search for Lab Programs',
            button_id: 'search_lab_programs',
            endPoint: `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.LAB_PROGRAMS}?language=en&keyword=test`
        },
        {
            description: 'Advance Search for Labs',
            button_id: 'search_labs',
            endPoint: `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.LABS}?language=en&keyword=test`
        },
        {
            description: 'Advance Search for Organizations',
            button_id: 'search_organizations',
            endPoint: `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.ORGANIZATIONS}?language=en&keyword=test`
        },
        {
            description: 'Advance Search for Projects',
            button_id: 'search_projects',
            endPoint: `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.ADVANCE_SEARCH_PROJECTS}?language=en&keyword=test`
        },
        {
            description: 'Advance Search for Resource Collection',
            button_id: 'search_resource_collection',
            endPoint: `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.RESOURCE_COLLECTIONS}?language=en&keyword=test`
        },
        {
            description: 'Advance Search for Resource Groups',
            button_id: 'search_resource_groups',
            endPoint: `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.RESOURCE_GROUPS}?language=en&keyword=test`
        },
        {
            description: 'Advance Search for Resource Modules',
            button_id: 'search_resource_modules',
            endPoint: `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.RESOURCE_MODULES}?language=en&keyword=test`
        },
        {
            description: 'Advance Search for Users',
            button_id: 'search_users',
            endPoint: `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.USERS}?language=en&keyword=test`
        }]

    /**
     * BULK TEST
     */
    testArray.forEach(testItem => {

        test(testItem?.description, async () => { //eslint-disable-line
            const button = screen.getByTestId(testItem.button_id);
            userEvent.click(button);
            await waitFor(() =>
                expect(getSpy).toHaveBeenCalledWith(
                    testItem?.endPoint,
                    {},
                    headers,
                    expect.any(Function)
                )
            );
        })
    })
})