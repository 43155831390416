import React from "react";
import Button from "components/Button";
import { Icon } from "@mui/material";



const EmptyText = ({children, classes = "",isButtonPresent = false,text,onClick,icon,buttonLabel}) => {

    if(isButtonPresent){

        return (
            <div className="mb-10 flex-column items-center gap-20">
                <EmptyText classes="c-gray fs-14">{text || children}</EmptyText>
                <Button onClick={onClick}>
                    <div className="flex items-center">
                    <Icon component={icon} fontSize="small" sx={{ fontSize: 14 }} />
                    <p className="ml-1">{buttonLabel}</p>
                    </div>
                </Button>
            </div>
            )

    }
    return(
        <h3 className={`text-center my-14 items-center mx-auto c-gray fs-14 ${classes}`}>{children}</h3>
    );
}

export default EmptyText;