import AuthLayout from "pages/layouts/AuthLayout";
import DashboardLayout from "pages/layouts/dashboard";
import "../organization.scss";
import React, { useState } from "react";
import GettingStartedCard from "components/Cards/GettingStartedCard";
import Button from "components/Button";
import {
  challenges_list,
  getting_started_steps,
  labs_list,
  projects_list,
} from "../forms/data";
import AddPrebuitLabsModal from "./addPrebuiltLabs";
import SubscriptionPlans from "./subscription";
import { Grid } from "@mui/material";
import useRedirect from "helpers/hooks/useRedirect";
import DashboardCard from "pages/dashboards/components/DashboardCard";

const GettingStarted = () => {
  const [modal, setModal] = useState({
    open: false,
  });
  const { navigateCreateLab, navigateEditOrg, navigateCreateChallenge } = useRedirect();

  const handleGettingStartedClick = (btn_label) => {
    // eslint-disable-next-line default-case
    switch (btn_label) {
      case "Add Prebuilt Labs": {
        setModal({ open: "labs" });
        return;
      }
      case "Choose Plan": {
        setModal({ open: "subscription" });
        return;
      }
      case "Complete Profile": {
        navigateEditOrg()
        return;
      }
    }
  };
  const handleNavigate = (i) => {
    if (i === 1) navigateCreateLab();
    if (i === 2) navigateCreateChallenge();
  };

  const onModalClose = () => {
    setModal({ open: false });
  };

  return (
    <DashboardLayout>
      <AuthLayout sliderHidden={true} containerClasses="py-14" autoHeight>
        <div className="gettingStartedDiv__org pt-header">
          <h1 className="my-4">Getting Started</h1>
          <p className="mt-3">
            Finish setting up your organization and explore <br /> PreprLabs!
          </p>
          <h2 className="mt-6">1. Finish organization setup.</h2>
          <Grid container spacing={2} className="my-3">
            {getting_started_steps[0].map(
              ({ title, description, button_label }) => (
                <Grid key={title} item xs={12} md={4} className="mt-2">
                  <GettingStartedCard>
                    <h2 className="mt-4">{title}</h2>
                    <p className="fs-14 mt-3 mx-2 maxh-50">{description}</p>
                    <Button onClick={() => handleGettingStartedClick(button_label)} classes="mt-5 rounded-50 py-2 px-1 w-50p">{button_label}</Button>
                  </GettingStartedCard>
                </Grid>
              )
            )}
          </Grid>

          <h2 className="mt-6">2. Ready? Try these tasks!</h2>
          <Grid container spacing={2} className="my-3">
            {getting_started_steps[1].map(
              ({ title, description, button_label }, i) => (
                <Grid key={i} item xs={12} md={4} className="mt-2">
                  <GettingStartedCard>
                    <h2 className="mt-4">{title}</h2>
                    <p className="fs-14 mt-3 mx-2 maxh-50">{description}</p>
                    <Button onClick={() => handleNavigate(i)} classes="mt-5 max-w-100 rounded-50 py-2 px-1 w-50p">{button_label}</Button>
                  </GettingStartedCard>
                </Grid>
              )
            )}
          </Grid>
        </div>
      </AuthLayout>
      <div className="column-center">
        <div className="text-right w-80p mt-5 mb-3">
          <Button color="blue" classes="float-right">
            Customize
          </Button>
        </div>
        <div className="DashboardCards__grid">
          <DashboardCard
            tabs={["My Chalanges", "Invited", "Following"]}
            challenges
            list={challenges_list}
          ></DashboardCard>
          <DashboardCard
            tabs={["My Labs", "Invited", "Following"]}
            labs
            list={labs_list}
          ></DashboardCard>
          <DashboardCard
            tabs={["My Projects", "Invited", "Following"]}
            projects
            list={projects_list}
          ></DashboardCard>
        </div>
      </div>
      <AddPrebuitLabsModal
        open={modal?.open === "labs"}
        onClose={onModalClose}
      />
      <SubscriptionPlans
        open={modal?.open === "subscription"}
        onClose={onModalClose}
      />
    </DashboardLayout>
  );
};

export default GettingStarted;
