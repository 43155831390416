import EditCreateHeaderCard from "components/Cards/EditCreateHeaderCard";
import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useMemo, useState } from "react";
import CreateFormFooter from "components/UI/atoms/CreateFormFooter";
import useForms from "helpers/hooks/useForms";
import { schema, stepSchema } from "./data";
import { useParams } from "react-router-dom";
import {
  combineDateAndTime,
  deleteValues,
  keyVal,
  payloadBool,
  payloadFormatArray,
  payloadKeyVal,
  payloadKeyVals,
  payloadGetVal,
  payloadToArrayKeyValues,
  scrollToTop,
  urlToFile,
} from "helpers/utils/utilities";
import ChallengeDetails from "./challengeDetails";
import TimeLine from "./timeLine";
import SubmissionAndAcheivement from "./submissionAndAcheivement";
import {
  populateCampusConnectInitialValue,
  removeHiddenFieldValidationError,
} from "pages/labs/forms/helpers";
import ErrorModal from "./ErrorsModal";
import useChallenges from "store/challenges/service-hook";
import { isTrue } from "helpers/utils/validators";
import { externalLinkValidate } from "./challengeDetails/ExternalLink";
import { toast } from "react-toastify";
import styles from "../challenges.module.scss";
import useOrgDashboard from "store/dashboard/org";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import useAssociation from "store/association";
import { ENDPOINTS } from "store/api/endpoints";

const CREATE_CHALLENGE_STEPS = [
  "Challenge Details",
  "Submission & Achievements",
  "Timeline",
];

const INIT_DATA = {
  description_type: "text",
  timeline_type: "restricted",
  visibility: false,
  automatic_alert: "day",
  integrate_campus_connect: "job",
  campus_connect_story_media_type: "IMAGE",
  schedule_custom_notify: ["no"],
  assessment_type: "none",
  is_open: "no",
  privacy: "no",
  project_privacy: "no",
  is_notification_enabled: "no",
  flexible_date_duration: "days",
};

const AddEditChallenge = () => {
  const [step, setStep] = useState(0);
  // TODO: use useReducer
  const [loading, setLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const { id } = useParams();
  const editMode = !!id;
  const [initLoading, setInitLoading] = useState(Boolean(editMode));
  const { create, edit, fetchChallenge } = useChallenges();
  const [socialLinks, setSocialLinks] = useState([]);
  const [criteriaError, setCriteriaError] = useState("");
  const [taskAndQuestionError, setTaskAndQuestionError] = useState("");
  const [criteria, setCriteria] = useState([]);
  const [winnerAchievements, setWinnerAchievements] = useState([]);
  const [selectedLab, setSelectedLab] = useState([]);
  const [selectedResource, setSelectedResource] = useState([]);
  const [addedSponsers, setAddedSponsers] = useState([]);
  const [experts, setExperts] = useState([]);
  const [customDates, setCustomDates] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [externalLinkError, setExternalLinkError] = useState("");
  const [taskValues, setTaskValues] = useState([]);
  const [questionsValues, setQuestionsValues] = useState([]);
  const { getSubscriptionDetails } = useOrgDashboard();
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const { t } = useLocalizedTranslation();
  const { fetchAssociation } = useAssociation();
  const [scormLoading, setScormLoading] = useState(false);

  useEffect(() => {
    if (editMode) {
      handleFetchChallenge();
      schema.find((item) => item.name === "slug").disabled = false;
      schema.find((item) => item.name === "scorm_file").validator = false;
      schema.find((item) => item.name === "attachments").validator = false;
    } else {
      resetState();
    }
  }, [id]);

  const resetState = () => {
    setStep(0);
    setLoading(false);
    setDraftLoading(false);
    setSocialLinks([]);
    setCriteriaError("");
    setTaskAndQuestionError("");
    setCriteria([]);
    setWinnerAchievements([]);
    setSelectedLab([]);
    setSelectedResource([]);
    setAddedSponsers([]);
    setCustomDates([]);
    setIsErrorModalOpen(false);
    setExternalLinkError("");
    setTaskValues([]);
    setQuestionsValues([]);
    setData({ ...INIT_DATA }); // Use initialData to reset the form data
    setErrors(new Map()); // Reset form errors
    setExperts([]);
  };

  const validateCriteria = () => {
    let error;
    const totalWeight = criteria.reduce(
      (acc, criteria) => acc + parseFloat(criteria.weight || 0),
      0
    );
    if (criteria.length === 0) error = "No Criteria added.";
    else if (criteria.some((criteria) => !criteria.title || !criteria.score))
      error = "Criteria title/score cannot be blank.";
    else if (
      criteria.some(
        (criteria) => isNaN(criteria.weight) || isNaN(criteria.score)
      )
    )
      error = "Enter a valid number in Weight and Score.";
    else if (totalWeight !== 100) error = "Sum of weights should be 100%";
    return error;
  };

  const validateTaskAndQuestion = () => {
    let error;
    let taskError;
    let questionError;
    if (taskValues.length === 0 && questionsValues.length === 0) {
      error =
        "At least one pitch questions or task questions must be provided when you select custom tasks and questions.";
    }

    if (taskValues.length) {
      let emptyTask = taskValues.some((item) => !item.task);
      if (emptyTask) {
        taskError = "task value cannot be empty";
        error = taskError;
      }
    }

    if (questionsValues.length) {
      let emptyQuestion = questionsValues.some(
        (item) => !item.question || !item.prompt
      );
      if (emptyQuestion) {
        questionError = "Question title and prompt field cannot be empty";
        error = questionError;
      }
    }

    if (taskError && questionError) {
      error = `Task, Question title & prompt field cannot be empty`;
    }

    toast.error(error);
    return error;
  };

  const sliceSchema = (_schema) => stepSchema(step);

  const { data, errors, validate, onChange, setData, setErrors } = useForms(
    schema,
    INIT_DATA,
    sliceSchema
  );

  const stepValidate = (errors) => {
    if (step === 0) {
      if (data?.description_type === "text") {
        errors.delete("scorm_file");
      }
      if (data?.description_type === "scorm") {
        errors.delete("description");
      }
    }

    if (step === 1) {
      // campus connect
      removeHiddenFieldValidationError(errors, data);

      //assessment type
      const unWantedAssessmentField = [];
      if (data?.assessment_type === "none") {
        unWantedAssessmentField.push(
          "members_email",
          "guidelines",
          "attachments"
        );
      }

      if (["open", "ai"].includes(data?.assessment_type)) {
        unWantedAssessmentField.push("members_email");
      }
      unWantedAssessmentField.forEach((item) => errors.delete(item));
    }

    if (step === 2) {
      if (data?.timeline_type === "restricted") {
        errors.delete("flexible_date_number");
        errors.delete("flexible_date_duration");
        errors.delete("flexible_expire_deadline");
        errors.delete("automatic_alert");
      }

      if (data?.timeline_type === "flexible") {
        const startIndex = schema.findIndex(
          (item) => item.name === "start_date"
        );

        const endIndex =
          schema.findIndex(
            (item) => item.name === "submission_deadline_date_description"
          ) + 1;

        schema
          .slice(startIndex, endIndex)
          .forEach((item) => errors.delete(item.name));
      }
    }

    return errors;
  };

  const handleFetchChallenge = async () => {
    const res = await fetchChallenge(id);
    let {
      level,
      level_id,
      category_id,
      sponsors,
      category,
      duration_id,
      duration,
      skills,
      tags,
      skill_groups,
      skill_stacks,
      tag_groups,
      challenge_assessment = [],
      media,
      external_links,
      status,
      challenge_requirements,
      challenge_timelines,
      participation_achievement,
      challenge_template,
      challenge_assessment_criteria,
      incentive_achievement,
      campus_connect_opportunity,
      campus_connect_story,
      campus_connect_status,
      challenge_custom_timelines,
      expert_references = [],
      ...rest
    } = res ? { ...res } : {};
    if (!challenge_assessment) challenge_assessment = [{}];

    if (typeof challenge_assessment.attachments === "string") {
      challenge_assessment.attachments = await urlToFile(
        challenge_assessment.attachments,
        "attachment.webp",
        "image/webp"
      );
    }

    const customDates = challenge_custom_timelines?.map((item) => {
      const notify = item?.schedule_custom_notify === "no" ? false : true;
      const baseFields = {
        title: item?.custom_timelines_title ?? "",
        number: item?.custom_timelines_number ?? "",
        duration: item?.custom_timelines_duration ?? "",
        description: item?.custom_timelines_description ?? "",
        notify,
      };
      if (notify) {
        return {
          ...baseFields,
          custom_announcement_type:
            item?.schedule_custom_announcement?.custom_announcement_type ?? "",
          custom_announcement_title:
            item?.schedule_custom_announcement?.custom_announcement_title ?? "",
          custom_announcement_description:
            item?.schedule_custom_announcement
              ?.custom_announcement_description ?? "",
          custom_announcement_number:
            item?.schedule_custom_announcement?.custom_announcement_number ??
            "",
          custom_announcement_duration:
            item?.schedule_custom_announcement?.custom_announcement_duration ??
            "",
        };
      } else {
        return baseFields;
      }
    });

    const [labs = []] =
      (await fetchAssociation(
        COMPONENT_TYPES.CHALLENGE,
        id,
        COMPONENT_TYPES.LAB
      )) || [];

    const [lab_programs = []] =
      (await fetchAssociation(
        COMPONENT_TYPES.CHALLENGE,
        id,
        COMPONENT_TYPES.LAB_PROGRAMS
      )) || [];

    const [resource_modules = []] =
      (await fetchAssociation(
        COMPONENT_TYPES.CHALLENGE,
        id,
        ENDPOINTS.RESOURCE_MODULE
      )) || [];

    const [resource_collections = []] =
      (await fetchAssociation(
        COMPONENT_TYPES.CHALLENGE,
        id,
        COMPONENT_TYPES.RESOURCE_COLLECTION
      )) || [];

    const [resource_groups = []] =
      (await fetchAssociation(
        COMPONENT_TYPES.CHALLENGE,
        id,
        COMPONENT_TYPES.RESOURCE_GROUP
      )) || [];

    setData({
      ...rest,
      jobs: payloadToArrayKeyValues(rest.jobs),
      skills: payloadToArrayKeyValues(skills ?? []),
      tags: payloadToArrayKeyValues(tags ?? []),
      skill_groups: payloadToArrayKeyValues(skill_groups ?? []),
      skill_stacks: payloadToArrayKeyValues(skill_stacks ?? []),
      tag_groups: payloadToArrayKeyValues(tag_groups ?? []),
      host_id: [
        ...(sponsors.map((item) => ({
          key: item.id,
          value: item.title,
          data: item,
        })) ?? []),
      ],
      category_id: keyVal(category, category_id),
      level_id: level && keyVal(level, level_id),
      duration_id: duration && keyVal(duration, duration_id),
      cover_image: media,
      request_type: status === "published" ? "publish" : "draft",
      ...challenge_requirements,
      project_submission_requirement_ids: payloadToArrayKeyValues(
        challenge_requirements?.project_submission_requirement_ids
      ),
      allow_submit_project: isTrue(
        challenge_requirements?.allow_submit_project
      ),
      complete_education_program: isTrue(
        challenge_requirements?.complete_education_program
      ),
      complete_experience: isTrue(challenge_requirements?.complete_experience),
      requirement_program: isTrue(
        challenge_requirements?.project_submission_requirement_ids
          ?.requirement_program
      ),
      ...challenge_timelines,
      ...participation_achievement,
      ...challenge_assessment[0],
      assessment_type: challenge_assessment?.assessment_type ?? "none",
      guidelines: challenge_assessment?.guidelines,
      attachments: challenge_assessment?.attachments,
      members_email: challenge_assessment?.members || [],
      visibility: challenge_assessment?.visibility,
      self_assessment: isTrue(challenge_assessment?.self_assessment),
      template_id: keyVal(
        challenge_template?.template_title,
        challenge_template?.template_id
      ),
      resource_modules,
      ...populateCampusConnectInitialValue(
        campus_connect_opportunity,
        campus_connect_story,
        campus_connect_status
      ),
      start_date_time: challenge_timelines?.start_date,
      registration_deadline_time:
        challenge_timelines?.registration_deadline_date,
      submission_deadline_time: challenge_timelines?.submission_deadline_date,
      achievement_enabled:
        participation_achievement &&
        Object.keys(participation_achievement).length > 0,
    });

    setExperts(expert_references);
    setCustomDates([...customDates]);
    setSelectedLab([
      ...labs.filter(Boolean).map((item) => ({
        ...item,
        type: COMPONENT_TYPES.LAB,
        uuid: item?.id,
      })),
      ...lab_programs.filter(Boolean).map((item) => ({
        ...item,
        type: COMPONENT_TYPES.LAB_PROGRAMS,
        uuid: item?.id,
      })),
    ]);
    setSelectedResource([
      ...resource_modules.filter(Boolean).map((item) => ({
        ...item,
        type: COMPONENT_TYPES.RESOURCE,
        uuid: item?.id,
      })),
      ...resource_groups.filter(Boolean).map((item) => ({
        ...item,
        type: COMPONENT_TYPES.RESOURCE_GROUP,
        uuid: item?.id,
      })),
      ...resource_collections.filter(Boolean).map((item) => ({
        ...item,
        type: COMPONENT_TYPES.RESOURCE_COLLECTION,
        uuid: item?.id,
      })),
    ]);

    if (external_links?.length) {
      setSocialLinks([
        ...external_links.map((d) => ({
          type: keyVal(d?.title, d?.social_link_id),
          url: d?.social_media_link,
        })),
      ]);
    }
    if (challenge_assessment_criteria?.length) {
      setCriteria([
        ...challenge_assessment_criteria.map((d) => ({
          title: d?.assessment_title,
          description: d?.assessment_description,
          score: d?.assessment_score,
          weight: d?.assessment_weight,
        })),
      ]);
    }
    if (incentive_achievement?.length) {
      setWinnerAchievements(
        incentive_achievement.map(
          ({
            achievement_image,
            achievement_points,
            achievement_name,
            achievement_prize,
          }) => ({
            winner_achievement_image: achievement_image,
            winner_achievement_points: achievement_points,
            winner_achievement_name: achievement_name,
            winner_achievement_prize: achievement_prize,
          })
        )
      );
    }
    setInitLoading(false);
  };

  const onCancel = () => {
    if (step !== 0) {
      setStep(step - 1);
      setErrors(new Map());
    }
  };

  const onContinue = async () => {
    let error = stepValidate(await validate());
    if (error.size) {
      setIsErrorModalOpen(true);
      return;
    }

    if (step === 0) {
      error = externalLinkValidate(socialLinks, t);
      if (error) {
        setExternalLinkError(error);
        return;
      }
    }

    if (step === 1 && data.assessment_type !== "none") {
      error = validateCriteria();
      if (error) {
        setCriteriaError(error);
        return;
      }
    }

    if (step === 1 && data?.template_id?.key === "custom") {
      error = validateTaskAndQuestion();
      if (error) {
        setTaskAndQuestionError(error);
        return;
      }
    }
    if (step === 1 && !data.achievement_name && data?.achievement_enabled) {
      setErrors(
        (prevErrors) =>
          new Map(prevErrors.set("achievement_name", "This field is required"))
      );
      return;
    }

    if (step === 2) {
      const start_date = new Date(
        combineDateAndTime(data?.start_date, data?.start_date_time)
      );
      const registration_deadline = new Date(
        combineDateAndTime(
          data?.registration_deadline_date,
          data?.registration_deadline_time
        )
      );
      const submission_deadline = new Date(
        combineDateAndTime(
          data?.submission_deadline_date,
          data?.submission_deadline_time
        )
      );

      if (registration_deadline < start_date) {
        setErrors(
          (prevErrors) =>
            new Map(
              prevErrors.set(
                "registration_deadline_date",
                "Registration deadline must be after or equal to start date."
              )
            )
        );
        return;
      }

      if (submission_deadline < registration_deadline) {
        setErrors(
          (prevErrors) =>
            new Map(
              prevErrors.set(
                "submission_deadline_date",
                "Submission deadline must be after or equal to Registration Date."
              )
            )
        );
        return;
      }

      if (submission_deadline <= start_date) {
        setErrors(
            (prevErrors) =>
                new Map(
                    prevErrors.set(
                        "submission_deadline_date",
                        "Submission deadline must be after Start Date."
                    )
                )
        );
        return;
      }
    }

    if (step !== CREATE_CHALLENGE_STEPS.length - 1) {
      scrollToTop();
      setStep(step + 1);
    } else {
      handleEditCreate();
    }
  };

  const onSaveDraft = async () => {
    if (step === 0) {
      const requiredField = [
        "title",
        "is_open",
        "privacy",
        "project_privacy",
        "is_notification_enabled",
      ];
      let error = await validate();
      error.forEach((item, key) => {
        if (!requiredField.includes(key)) {
          error.delete(key);
        }
      });
      if (error.size) setIsErrorModalOpen(true);
      error = externalLinkValidate(socialLinks, t);
      if (error) {
        setExternalLinkError(error);
        return;
      }
    }
    handleEditCreate(true);
  };
  const handleEditCreate = async (isDraft = false) => {
    const salaryPaymentFrequencyMapping = { zero: 0, one: 1, two: 2, three: 3 };
    const winnerAchievementss = winnerAchievements.map((achievement) => {
      if (!achievement.winner_achievement_image) {
        return { ...achievement, winner_achievement_image: "" };
      }
      return { ...achievement };
    });

    const customsDateMapping = customDates.map((item) => {
      // Default values for the fields
      const baseFields = {
        title: item?.title ?? "",
        number: item?.number ?? "",
        duration: item?.duration ?? "",
        description: item?.description ?? "",
        notify: item?.notify === false ? "no" : "yes",
      };

      // Conditionally include custom announcement fields based on the value of notify
      if (baseFields.notify === "yes") {
        return {
          ...baseFields,
          custom_announcement_type: item?.custom_announcement_type ?? null,
          custom_announcement_title: item?.custom_announcement_title ?? null,
          custom_announcement_description:
            item?.custom_announcement_description ?? null,
          custom_announcement_duration:
            item?.custom_announcement_duration ?? null,
          custom_announcement_number: item?.custom_announcement_number ?? null,
        };
      } else {
        return baseFields;
      }
    });

    // const winnerAchievementss = winnerAchievements;
    const shouldDeleteWinnerAchievements = winnerAchievementss.length === 0;

    const payload = {
      ...data,
      automatic_alert: data?.automatic_alert ?? "day",
      request_type: isDraft ? "draft" : "publish",
      cover_banner_type: data?.iframe_code
        ? "embedded"
        : data?.cover_image instanceof File
        ? "image"
        : "none",
      cover_embedded: data?.iframe_code,
      labs: selectedLab
        .filter((item) => item.type === COMPONENT_TYPES.LAB)
        .map((item) => item.uuid),
      lab_programs: selectedLab
        .filter((item) => item.type === COMPONENT_TYPES.LAB_PROGRAMS)
        .map((item) => item.uuid),
      resource_modules: selectedResource
        .filter((item) => item.type === COMPONENT_TYPES.RESOURCE)
        .map((item) => item.uuid),
      resource_collections: selectedResource
        .filter((item) => item.type === COMPONENT_TYPES.RESOURCE_COLLECTION)
        .map((item) => item.uuid),
      resource_groups: selectedResource
        .filter((item) => item.type === COMPONENT_TYPES.RESOURCE_GROUP)
        .map((item) => item.uuid),
      assessment_title: payloadFormatArray(criteria, "title"),
      assessment_description: payloadFormatArray(criteria, "description"),
      assessment_score: payloadFormatArray(criteria, "score"),
      assessment_weight: payloadFormatArray(criteria, "weight"),
      winner_achievement_name: payloadFormatArray(
        winnerAchievementss,
        "winner_achievement_name"
      ).filter(Boolean),
      expert_references: payloadFormatArray(experts, "email"),

      winner_achievement_points: payloadFormatArray(
        winnerAchievementss,
        "winner_achievement_points"
      ).filter(Boolean),

      winner_achievement_image: payloadFormatArray(
        winnerAchievementss,
        "winner_achievement_image"
      ).filter((d) => !!d && typeof d !== "string"),

      members_email: payloadFormatArray(data?.members_email, "email"),
      visibility: data?.visibility ? "users" : "hidden",
      achievement_participation: "participation",
      achievement_prize: data?.achievement_prize,
      external_links: payloadFormatArray(socialLinks, "url"),
      external_link_ids: payloadFormatArray(socialLinks, "type"),
      allow_submit_project: payloadBool(data?.allow_submit_project),
      requirement_program: payloadBool(data?.requirement_program),
      complete_education_program: payloadBool(data?.complete_education_program),
      complete_experience: payloadBool(data?.complete_experience),
      is_auto_created: "no",
      is_ai_created: "no",
      winner_achievement_participation: winnerAchievementss.length
        ? Array(winnerAchievementss.length).fill("yes")
        : ["no"],
      winner_achievement_prize: payloadFormatArray(
        winnerAchievementss,
        "winner_achievement_prize"
      ).filter(Boolean),
      old_winner_achievement_image: payloadFormatArray(
        winnerAchievementss,
        "winner_achievement_image"
      ).filter(Boolean),

      ...(data?.timeline_type === "flexible" && {
        custom_timelines_title: customsDateMapping.map(
          (item) => item?.title ?? ""
        ),
        custom_timelines_number: customsDateMapping.map(
          (item) => item.number ?? ""
        ),
        custom_timelines_duration: customsDateMapping.map(
          (item) => item.duration ?? ""
        ),
        custom_timelines_description: customsDateMapping.map(
          (item) => item.description ?? ""
        ),
        schedule_custom_notify: customsDateMapping.map((item) => item.notify),
        custom_announcement_type: customsDateMapping.map(
          (item) => item.custom_announcement_type ?? ""
        ),
        custom_announcement_title: customsDateMapping.map(
          (item) => item.custom_announcement_title ?? ""
        ),
        custom_announcement_description: customsDateMapping.map(
          (item) => item.custom_announcement_description ?? ""
        ),
        custom_announcement_duration: customsDateMapping.map(
          (item) => item.custom_announcement_duration ?? ""
        ),
        custom_announcement_number: customsDateMapping.map(
          (item) => item.custom_announcement_number ?? ""
        ),
      }),
      integrate_campus_connect: data?.campus_connect
        ? data?.integrate_campus_connect
        : "no",
      campus_connect_salary_payment_frequency:
        salaryPaymentFrequencyMapping[
          data?.campus_connect_salary_payment_frequency
        ],
      campus_connect_has_image_file:
        data?.campus_connect_story_image instanceof File ? true : false,
      template_type: data?.template_id?.key === "custom" ? "new" : "existing",
      task_questions: taskValues.map((item) => item.task),
      pitch_questions: questionsValues.map((item) => item.question),
      pitch_questions_description: questionsValues.map((item) => item.prompt),
      start_date: combineDateAndTime(data?.start_date, data?.start_date_time),

      registration_deadline_date: combineDateAndTime(
        data?.registration_deadline_date,
        data?.registration_deadline_time
      ),
      submission_deadline_date: combineDateAndTime(
        data?.submission_deadline_date,
        data?.submission_deadline_time
      ),
      self_assessment: data.self_assessment ? 1 : 0,
      achievement_enabled: data.achievement_enabled ? "yes" : "no",
    };
    if (
      data.flexible_expire_deadline === undefined ||
      data.flexible_expire_deadline === null
    ) {
      delete payload["flexible_expire_deadline"];
    }
    payloadKeyVal(payload, [
      "level_id",
      "category_id",
      "duration_id",
      "template_id",
    ]);

    payloadKeyVals(payload, [
      "skills",
      "tags",
      "skill_groups",
      "skill_stacks",
      "project_submission_requirement_ids",
      "host_id",
      "external_link_ids",
      "jobs",
    ]);

    payloadGetVal(payload, ["campus_connect_schools"]);
    if (addedSponsers.length) {
      payload.host_id = [
        ...payload.host_id,
        ...addedSponsers.map((item) => item.id),
      ];
    }
    const deleteVals = [
      "template",
      "campus_connect",
      "tags",
      "start_date_time",
      "registration_deadline_time",
      "submission_deadline_time",
      "iframe_code",
      "scorm_file",
      "scorm",
      "scorm_url",
    ];
    if (data?.timeline_type === "flexible") {
      deleteVals.push(
        ...[
          "submission_deadline_date",
          "start_date",
          "registration_deadline_date",
        ]
      );
    }
    if (!data?.template_id?.key) {
      deleteVals.push("template_type");
    }
    if (!data?.skill_groups || !data?.skill_groups.length) {
      deleteVals.push("skill_groups");
    }
    if (!data?.skill_stacks || !data?.skill_stacks.length) {
      deleteVals.push("skill_stacks");
    }
    if (shouldDeleteWinnerAchievements) {
      deleteVals.push(
        "winner_achievement_name",
        "winner_achievement_points",
        "winner_achievement_prize"
      );
    }
    if (data?.host_id === undefined) {
      deleteVals.push("host_id");
    }

    if (data?.template_id?.key === "custom") {
      deleteVals.push("template_id");
    }

    if (data?.assessment_type === "none")
      deleteVals.push(
        "assessment_title",
        "assessment_description",
        "assessment_score",
        "assessment_weight"
      );
    if (data?.assessment_type !== "ai") {
      deleteVals.push("assessment_description");
    }

    if (editMode) {
      [
        "media",
        "status",
        "challenge_requirements",
        "id",
        "slug",
        "likes",
        "shares",
        "media_type",
        "liked",
        "member_count",
        "favourite",
        "category",
        "duration",
        "level",
        "user",
        "module_progress",
      ].forEach((d) => deleteVals.push(d));
      if (typeof data.cover_image === "string") {
        deleteVals.push("cover_image");
      }
      if (typeof data.achievement_image === "string") {
        deleteVals.push("achievement_image");
      }
      if (typeof data.attachments === "string") {
        deleteVals.push("attachments");
      }
    }
    // delete undefined or null
    for (const [key, value] of Object.entries(payload)) {
      if (payload[key] === undefined || payload[key] === null) {
        delete payload[key];
      }
    }
    deleteValues(payload, deleteVals);
    isDraft ? setDraftLoading(true) : setLoading(true);
    editMode ? await edit(id, payload) : await create(payload);
    isDraft ? setDraftLoading(false) : setLoading(false);
  };

  const isProjectAlreadyPublished = useMemo(() => {
    return data?.project_submitted?.length > 0 && editMode;
  }, [data?.project_submitted, editMode]);

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  const fetchPlanDetails = async () => {
    const res = await getSubscriptionDetails();
    if (res) {
      setSubscriptionDetails(res);
    }
  };

  return (
    <>
      <DashboardLayout loading={initLoading}>
        <div className={`${styles.challenge_form} form_wrapper my-10`}>
          <EditCreateHeaderCard
            current={step}
            length={CREATE_CHALLENGE_STEPS.length}
            subtitles={CREATE_CHALLENGE_STEPS}
            title={`${editMode ? "EDIT" : "CREATE A"} CHALLENGE`}
            classes="mt-header"
            numbered
            rounded
          />
          {step === 0 && (
            <ChallengeDetails
              data={data}
              errors={errors}
              onChange={onChange}
              step={step}
              socialLinks={socialLinks}
              setSocialLinks={setSocialLinks}
              selectedLab={selectedLab}
              setSelectedLab={setSelectedLab}
              selectedResource={selectedResource}
              setSelectedResource={setSelectedResource}
              addedSponsers={addedSponsers}
              setAddedSponsers={setAddedSponsers}
              setExternalLinkError={setExternalLinkError}
              externalLinkError={externalLinkError}
              isProjectAlreadyPublished={isProjectAlreadyPublished}
              setScormLoading={setScormLoading}
              t={t}
              experts={experts}
              setExperts={setExperts}
            />
          )}
          {step === 1 && (
            <SubmissionAndAcheivement
              data={data}
              errors={errors}
              onChange={onChange}
              step={step}
              criteriaError={criteriaError}
              setCriteriaError={setCriteriaError}
              taskAndQuestionError={taskAndQuestionError}
              setTaskAndQuestionError={setTaskAndQuestionError}
              criteria={criteria}
              setCriteria={setCriteria}
              winnerAchievements={winnerAchievements}
              setWinnerAchievements={setWinnerAchievements}
              taskValues={taskValues}
              setTaskValues={setTaskValues}
              questionsValues={questionsValues}
              setQuestionsValues={setQuestionsValues}
              isProjectAlreadyPublished={isProjectAlreadyPublished}
              subscriptionDetails={subscriptionDetails}
              t={t}
            />
          )}
          {step === 2 && (
            <TimeLine
              data={data}
              errors={errors}
              onChange={onChange}
              step={step}
              customDates={customDates}
              setCustomDates={setCustomDates}
              isProjectAlreadyPublished={isProjectAlreadyPublished}
              t={t}
            />
          )}
          <CreateFormFooter
            step={step}
            stepsLength={CREATE_CHALLENGE_STEPS.length}
            onCancel={onCancel}
            onOk={onContinue}
            onDraft={onSaveDraft}
            disabled={!!stepValidate(errors).size || scormLoading}
            loading={loading}
            draftLoading={draftLoading}
            editMode={editMode}
            displayDraftButton={true}
            saveBtnText="Publish Challenge"
          />
        </div>
      </DashboardLayout>
      <ErrorModal
        open={errors.size > 0 && isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        errors={errors}
      ></ErrorModal>
    </>
  );
};

export default AddEditChallenge;
