import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LabDashboard, { LAB_DASH_ITEMS } from "./LabDashboard";
import Organizationdashboard, { ORG_DASH_ITEMS } from "./Organizationdashboard";
import UserDashboard, { USER_DASH_ITEMS } from "./UserDashboard";
import UserDashboard2 from "./UserDashboard2";
import useUserDashboard from "store/dashboard/user";
import { isTrue } from "helpers/utils/validators";
import { payloadFormatArray } from "helpers/utils/utilities";
import DrawerContent from "./components/DrawerContent";
import { Box, Drawer, IconButton } from "@mui/material";
import useLabDashboard from "store/dashboard/lab";
import useOrgDashboard from "store/dashboard/org";
import { KeyboardDoubleArrowLeft } from "@mui/icons-material";
import CustomizeButtons from "./components/CustomizeButtons";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const Dashboards = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [layouts, setLayouts] = useState([]);
  const [items, setItems] = useState([]);
  const [inactives, setInActives] = useState([]);
  const [loading, setLoading] = useState();
  const location = useLocation();
  const [refreshCards, setRefreshCards] = useState(false);
  const [isCustomizeMode, setIsCustomizeMode] = useState(false);
  const useDashboard = location.pathname.includes("/dashboard/lab")
    ? useLabDashboard
    : location.pathname.includes("/dashboard/org")
    ? useOrgDashboard
    : useUserDashboard;
  const { layout, updateLayout } = useDashboard();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    fetchLayouts();
  }, [location?.pathname]);

  useEffect(() => {
    if (!layouts?.length && !refreshCards) return;
    layCards();
  }, [refreshCards]);

  const getDashConfigByType = () => {
    switch (location.pathname) {
      case "/dashboard":
        return USER_DASH_ITEMS;
      case "/dashboard/lab":
        return LAB_DASH_ITEMS;
      case "/dashboard/org":
        return ORG_DASH_ITEMS;
      default:
        return [];
    }
  };

  const layCards = () => {
const activeLayouts = (layouts ?? []).filter((layout) => isTrue(layout?.is_active));
    const inactiveLayouts = (layouts ?? []).filter(
      (layout) => !isTrue(layout?.is_active)
    );
    const configs = getDashConfigByType();
    const sortedItems = [];
    for (const i of activeLayouts) {
      sortedItems[i.position_index] = configs.find(
        (d) => d?.id === i?.card_type
      );
    }
    setItems([...sortedItems.filter(Boolean)]);
    setInActives([
      ...inactiveLayouts.map((d) => ({
        ...(configs.find((f) => f?.id === d?.card_type) || {}),
        ...d,
      })),
    ]);
    setRefreshCards(false);
  };

  const fetchLayouts = async () => {
    setLoading(true);
    const res = await layout();
    setLayouts(res);
    setLoading(false);
    setRefreshCards(true);
  };

  const setActiveById = (id) => {
    const lItem = layouts.find((d) => d?.card_type === id);
    if (lItem) lItem.is_active = "yes";
    const iItem = inactives.find((d) => d.id === id);
    iItem.is_active = "yes";
    setInActives([...inactives]);
    setItems((prev) => [...prev.filter((d) => d?.id !== id), iItem]);
    setLayouts([...layouts]);
  };

  const setInactiveById = (id) => {
    const lItem = layouts.find((d) => d?.card_type === id);
    if (lItem) lItem.is_active = "no";
    const aItem = items.find((d) => d.id === id);
    setItems((prev) => [...prev.filter((d) => d?.id !== id)]);
    setInActives((prev) => [
      ...prev.filter((d) => d?.id !== id),
      { ...aItem, is_active: "no" },
    ]);
    setLayouts([...layouts]);
  };

  const handleSaveDashConfig = async () => {
    let newLayout = [...layouts];
    items.forEach((d, i) => {
      const index = newLayout.findIndex((f) => f?.card_type === d?.id);
      if (index !== -1) {
        newLayout[index].position_index = i;
      }
    });
    setLoading(true);
    const payload = {
      card_type: payloadFormatArray(layouts, "card_type"),
      is_active: payloadFormatArray(layouts, "is_active"),
      position_index: payloadFormatArray(layouts, "position_index"),
    };
    await updateLayout(payload);
    setLoading(false);
    setIsCustomizeMode(false);
  };

  const onCustomize = () => {
    setIsCustomizeMode(true);
    setDrawerOpen((prev) => !prev);
  };

  const onCancelCustomize = () => {
    setIsCustomizeMode(false);
    setDrawerOpen(false);
    fetchLayouts();
  };

  return (
    <DashboardLayout loading={loading}>
      <div className="mt-header mb-5 container-big">
        <Box className="w-full px-5--noMob">
          <CustomizeButtons
            handleSaveDashConfig={handleSaveDashConfig}
            isCustomizeMode={isCustomizeMode}
            onCancelCustomize={onCancelCustomize}
            onCustomize={onCustomize}
            t={t}
          />
          <Routes>
            <Route
              path="/"
              element={
                <UserDashboard
                  items={items}
                  setItems={setItems}
                  setActiveById={setActiveById}
                  setInactiveById={setInactiveById}
                  isCustomizeMode={isCustomizeMode}
                />
              }
            />
            <Route
              path="/lab"
              element={
                <LabDashboard
                  items={items}
                  setItems={setItems}
                  setActiveById={setActiveById}
                  setInactiveById={setInactiveById}
                  isCustomizeMode={isCustomizeMode}
                />
              }
            />
            <Route
              path="/org"
              element={
                <Organizationdashboard
                  items={items}
                  setItems={setItems}
                  setActiveById={setActiveById}
                  setInactiveById={setInactiveById}
                  isCustomizeMode={isCustomizeMode}
                />
              }
            />
            <Route path="/2" element={<UserDashboard2 />} />
            <Route path="/*" element={<Navigate to="/error/404" />} />
          </Routes>
        </Box>
      </div>
      <Drawer
        open={isCustomizeMode && drawerOpen}
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        classes={{
          paper: "bg-gray",
          root: "bg-opaque",
        }}
      >
        <DrawerContent
          items={inactives}
          setActiveById={setActiveById}
          setDrawerOpen={setDrawerOpen}
        />
      </Drawer>
      <Drawer
        open={isCustomizeMode && !drawerOpen}
        anchor="right"
        classes={{
          paper: "bg-gray shadow overflow-hidden",
          root: `pt-header z-0 ${!isCustomizeMode && "hidden"}`,
        }}
        elevation={16}
        variant="permanent"
      >
        <Box className="h-screen w-40">
          <IconButton
            className="c-primary h-30 w-30 ml-1 mt-header"
            onClick={onCustomize}
          >
            <KeyboardDoubleArrowLeft />
          </IconButton>
        </Box>
      </Drawer>
    </DashboardLayout>
  );
};

export default Dashboards;
