import { createSlice } from "@reduxjs/toolkit";
import ACTIONS from "./action-types";

const initialState = {
    challenges: [],
};

const SLICE = createSlice({
    name: "challenge",
    initialState,
    reducers: {
        [ACTIONS.CHALLENGE_LIST]: (state, { payload }) => {
            state.challenges = payload;
        },
    },
});

export const REDUCER = SLICE.actions;

export default SLICE.reducer;