import { Grid, Typography } from "@mui/material";
import Button from "components/Button";
import SecureImage from "components/UI/atoms/SecureImage";
import useRedirect from "helpers/hooks/useRedirect";
import { getDefaultImageByType } from "components/Cards/MainProductCard";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import parse from "html-react-parser";

const FeatureCard = ({
  title,
  description,
  image,
  slug,
  type,
  buttonTitle,
}) => {
  const { navigateLab, navigateChallenge } = useRedirect();

  return (
    <Grid
      container
      my={4}
      className="bg-dark-blue px-6 flex items-center h-200--noMob py-3--mob c-white rounded-10 darkShadow"
    >
      <Grid item sm={7} xs={12} pr={0.5}>
        <Typography
          variant="h5"
          fontSize={18}
          fontWeight={600}
          lineHeight={1.2}
        >
          {title}
        </Typography>
        <Typography className="fs-13 my-2 description">
          {parse(description)}
        </Typography>
        <Button
          color="white"
          white
          classes="mt-3 fs-14"
          onClick={() =>
            type === COMPONENT_TYPES.LAB
              ? navigateLab(slug)
              : navigateChallenge(slug)
          }
        >
          {buttonTitle || "View"}
        </Button>
      </Grid>
      <Grid item sm={5} xs={12} className="flex items-center my-3--mob">
        <SecureImage
          classes="w-full h-160 border object-cover"
          containerClasses="w-full c-title"
          src={image}
          placeholder={getDefaultImageByType(type)}
          title={title}
        />
      </Grid>
    </Grid>
  );
};
export default FeatureCard;
