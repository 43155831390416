import { COMPONENT_TYPES } from "helpers/utils/constants";
import useRedirect from "./useRedirect";

const useBrowseOptions = (component) => {
  const {
    navigateManageResources,
    navigateManageResourceC,
    navigateManageResourceG,
  } = useRedirect();

  const modules = {
    [COMPONENT_TYPES.RESOURCE]: [
      {
        id: 1,
        title: "Resource Modules",
        pageTitle: "Manage Resources",
        onClick: navigateManageResources,
      },
      {
        id: 2,
        title: "Resource Collections",
        pageTitle: "Manage Resource Collections",
        onClick: navigateManageResourceC,
      },
      {
        id: 3,
        title: "Resource Groups",
        pageTitle: "Manage Resource Groups",
        onClick: navigateManageResourceG,
      },
    ],
  };
  return modules[component];
};

export default useBrowseOptions;
