import { document } from "assets/svg/blue";
import { tooltip } from "assets/svg/gray";
import { video } from "assets/svg/green";
import { image } from "assets/svg/yellow";
import React, { useState } from "react";
import { Grid, Box, Popper } from "@mui/material";
import Divider from "components/Divider";

export const ACCEPTED_FILES = [
  {
    icon: image,
    title: "Images",
    description: ".jpg, .jpeg, .webp , .png",
  },
  {
    icon: document,
    title: "Documents",
    description:
      ".pdf, .mp3, .doc, .docx, .xlsx, .xls, .pptx, .pptm, .odp, .ppt",
  },
  {
    icon: video,
    title: "Videos",
    description: ".mp4, .mov, .wmv, .avi, .webm, .mkv, .mpeg-2",
  },
];

const AcceptedFilesPopup = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  return (
    <>
      <img
        src={tooltip}
        alt="tooltip"
        className="cursor-pointer"
        onClick={handleClick}
        aria-describedby="popper"
      />
      <Popper
        sx={{ zIndex: 9999 }}
        open={open}
        placement="top"
        id="popper"
        anchorEl={anchorEl}
      >
        <Box className="border bg-white w-350 shadow px-4 py-2 rounded-5">
          <h4 className="mb-2">We accept the following files:</h4>
          <Grid container direction="column" spacing={0}>
            {ACCEPTED_FILES.map((item, index) => (
              <>
                <Grid
                  xs={12}
                  py={1}
                  item
                  container
                  alignItems="center"
                  rowSpacing={1}
                >
                  <Grid item xs={1}>
                    <img
                      src={item.icon}
                      alt={item.title}
                      className="w-19 h-25"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <p className="fs-12 pl-1" style={{ color: "#5B5D6B" }}>
                      {item.title}
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <p className="fs-12 pl-2" style={{ color: "#5B5D6B" }}>
                      {item.description}
                    </p>
                  </Grid>
                </Grid>
                {index < ACCEPTED_FILES.length - 1 && (
                  <Grid item xs={12}>
                    <Divider classes="mt-1 mb-1 w-full" />
                  </Grid>
                )}
              </>
            ))}
          </Grid>
        </Box>
      </Popper>
    </>
  );
};

export default AcceptedFilesPopup;
