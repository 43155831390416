import React from "react";
import { toast } from "react-toastify";
import { COMPONENT_TYPES, ROLES } from "./constants";
import { isTrue } from "./validators";
import defaultImage from "assets/png/placeholder.png";
import { labProgram, org } from "assets/svg/blue";
import { challenge, challengePath } from "assets/svg/green";
import { resourceCollection, resourceGroup } from "assets/svg/purple";
import { project, skills } from "assets/svg/yellow";
import moment from "moment";

export const createEvent = (name, value) => ({
  target: {
    name,
    value,
  },
});

export const snakeToCamel = (value) =>
  !value ? "N/A" : value?.toString()?.split("_")?.join(" ");

export const getBase64 = async (image, split) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const baseImage = fileReader.result;
      resolve(!split ? baseImage : baseImage?.split(",")[1]);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };

    fileReader.readAsDataURL(image);
  });
};

export const formatAddress = (address) => {
  if (!address) return "-";
  return address?.address_1
    ? address?.address_1 + ", "
    : "" + address?.city + ", " + address.country;
};

export const deleteValues = (obj = {}, values = []) => {
  values.forEach((d) => {
    delete obj[d];
  });
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const scrollToBottom = (ref) => {
  ref.current?.scrollIntoView({ behavior: "smooth" });
};

const getValue = (item) =>
  item?.name ||
  item?.title ||
  item?.value ||
  item?.id ||
  item?.uuid ||
  item?.label;

export const keyValueFormat = (list) => {
  return list
    ?.filter((item) => getValue(item) != null)
    .map((d) => ({
      value: d?.name || d?.title || d?.value,
      key: d?.id || d?.key || d?.uuid,
    }));
};

export const keyValueFormatWithData = (list) => {
  return list
    ?.filter((item) => getValue(item) != null)
    .map((d) => ({
      value: d?.name || d?.title || d?.value,
      key: d?.id || d?.key || d?.uuid,
      data: d,
    }));
};

export const loginToPreceed = () => {
  toast.error("Please Login to proceed!");
};

export const payloadFormatArray = (array = [], key) => array.map((d) => d[key]);
export const payloadBool = (val) => (Boolean(val) ? "yes" : "no");
export const payloadToArray = (value) =>
  !value
    ? []
    : Array.isArray(value)
    ? value
    : Object.keys(value).map((d) => (isNaN(d) ? d : +d));

export const payloadToArrayKeyValues = (obj) => {
  if (!obj || typeof obj !== "object") return [];
  return Object.entries(obj).map(([key, value]) => ({
    key,
    value,
  }));
};

export const getBaseUrl = () => {
  const isLocalhost = window.location.hostname === "localhost";
  return `https://${isLocalhost ? "dev.learnlab.ai" : window.location.host}/`;
};

export const keyVal = (value, key) => ({
  key: key,
  value: value?.toString() || "",
});

export const payloadKeyVals = (payload = {}, keys) => {
  for (const key of keys) {
    if (payload[key]) payload[key] = payload[key]?.map((d) => d?.key);
  }
};

export const payloadGetVal = (payload = {}, keys) => {
  for (const key of keys) {
    if (payload[key]) payload[key] = payload[key]?.map((d) => d?.value);
  }
};

export const payloadKeyVal = (payload = {}, keys) => {
  for (const key of keys) {
    payload[key] = payload[key]?.key;
  }
};

export const combineDateAndTime = (date, time) => {
  if (!date?.split(" ")[0]) {
    return undefined;
  }

  // if (!time?.split(" ")[1]) {
  //   return undefined;
  // }

  return `${date?.split(" ")[0]} ${
    time ? time?.split(" ")[1] : date?.split(" ")[1]
  }`;
};

export function numberFormatter(num, digits = 0) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast((item) => num >= item.value);
  return item
    ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol)
    : "0";
}

export const isValidUrl = (url) => {
  const urlRegex = /^(https?:\/\/)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+.*$/;
  return urlRegex.test(url);
};

export const urlToFile = async (url, filename, mimeType) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type: mimeType ?? blob.type });
};

export const isNormalUser = (user) =>
  user?.roles === ROLES.USER || !user?.roles;

export const isGoogleCaptchaDisabled = () =>
  isTrue(process.env.REACT_APP_DISABLE_GOOGLE_RECAPTCHA ?? "no");

export const hasFiltersApplied = (filters) => {
  return Boolean(
    filters &&
      typeof filters === "object" &&
      Object.values(filters).some((d) =>
        Array.isArray(d) ? Boolean(d?.length) : Boolean(d)
      )
  );
};

export const extractText = (children) => {
  return React.Children.toArray(children).reduce((text, child) => {
    if (typeof child === "string") {
      return text + child;
    }
    if (React.isValidElement(child)) {
      return text + extractText(child.props.children);
    }
    return text;
  }, "");
};

export const getDefaultIconByType = (type) => {
  let dImage = defaultImage;
  // eslint-disable-next-line default-case
  switch (type) {
    case COMPONENT_TYPES.ORGANIZATION:
      dImage = org;
      break;
    case COMPONENT_TYPES.CHALLENGE:
    case COMPONENT_TYPES.CHALLENGE_TEMPLATE:
      dImage = challenge;
      break;
    case COMPONENT_TYPES.LAB_PROGRAMS:
      dImage = labProgram;
      break;
    case COMPONENT_TYPES.RESOURCE:
      dImage = resourceGroup;
      break;
    case COMPONENT_TYPES.RESOURCE_COLLECTION:
    case COMPONENT_TYPES.RESOURCE_GROUP:
      dImage = resourceCollection;
      break;
    case COMPONENT_TYPES.CHALLENGE_PATH:
      dImage = challengePath;
      break;
    case COMPONENT_TYPES.LAB:
    case COMPONENT_TYPES.LAB_MARKETPLACE:
      dImage = labProgram;
      break;
    case COMPONENT_TYPES.SKILL:
      dImage = skills;
      break;
    case COMPONENT_TYPES.PROJECT:
      dImage = project;
      break;
  }
  return dImage;
};

export const formattedCount = (value) => {
  if (!value) {
    return 0;
  }
  const thplace = Math.floor(value / 1000);
  if (thplace > 0) {
    return `${thplace}k+`;
  }
  return value;
};

/**
 * Downloads a file from the given URL and saves it to the user's computer
 * with the given filename.
 *
 * @param {{ path: string, name: string }} options
 * @param {string} options.path - The URL of the file to download
 * @param {string} options.name - The desired filename for the downloaded file
 *
 * @throws {Error} If there is a network error, or if the response is not
 *   successful.
 */
export const downloadFile = async ({ path, name }) => {
  try {
    const response = await fetch(path);
    if (!response.ok) throw new Error("Network response was not ok");
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = name;

    // Append the anchor to the body and trigger a click to download
    document.body.appendChild(a);
    a.click();

    // Clean up the temporary anchor and URL object
    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(`Error downloading the file: ${name}`, error);
  }
};

export const extractChallengeDate = (data) => {
  if (!data?.challenge_timelines) return "N/A";

  const {
    timeline_type,
    flexible_date_duration,
    flexible_date_number,
    submission_deadline_date,
  } = data.challenge_timelines;
  let deadline;

  if (timeline_type === "flexible") {
    const joinedDate =
      data?.joined === "Yes"
        ? new Date(data.challenge_joined_date)
        : new Date();
    deadline = flexibleDateDuration(
      flexible_date_duration,
      flexible_date_number,
      joinedDate
    );
  } else {
    deadline = submission_deadline_date
      ? moment(submission_deadline_date).format("D MMM YYYY")
      : "N/A";
  }

  return deadline;
};

const flexibleDateDuration = (duration, number, baseDate = new Date()) => {
  let newDate = moment(baseDate);

  if (duration === "days") {
    newDate = newDate.add(number, "days");
  } else if (duration === "weeks") {
    newDate = newDate.add(number, "weeks");
  } else if (duration === "months") {
    newDate = newDate.add(number, "months");
  }

  return newDate.format("D MMM YYYY");
};

export function removeNullValues(data) {
  return data.map((obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => value !== null)
    );
  });
}

export const roleBasedPermissions = (role) => {
  switch (role) {
    case ROLES.SUPER_ADMIN:
    case ROLES.CUSTOMER_SUCCESS:
    case ROLES.DEVELOPER:
    case ROLES.ORGANIZATION_OWNER:
    case ROLES.ORGANIZATION_MANAGER:
      return {
        manage: "all",
        create: "all",
        dashboard: "all",
      };
    case ROLES.LAB_MANAGER:
      return {
        manage: [
          COMPONENT_TYPES.LAB,
          COMPONENT_TYPES.CHALLENGE,
          COMPONENT_TYPES.RESOURCE,
        ],
        create: [
          COMPONENT_TYPES.RESOURCE,
          COMPONENT_TYPES.RESOURCE_COLLECTION,
          COMPONENT_TYPES.RESOURCE_GROUP,
          COMPONENT_TYPES.CHALLENGE,
          COMPONENT_TYPES.CHALLENGE_PATH,
          COMPONENT_TYPES.LAB,
          COMPONENT_TYPES.LAB_PROGRAMS,
        ],
        dashboard: ["user", "lab"],
      };
    case ROLES.CHALLENGE_MANAGER:
      return {
        manage: [COMPONENT_TYPES.CHALLENGE],
        create: [COMPONENT_TYPES.CHALLENGE, COMPONENT_TYPES.CHALLENGE_PATH],
        dashboard: ["user"],
      };
    case ROLES.RESOURCE_MANAGER:
      return {
        manage: [COMPONENT_TYPES.RESOURCE],
        create: [
          COMPONENT_TYPES.RESOURCE,
          COMPONENT_TYPES.RESOURCE_COLLECTION,
          COMPONENT_TYPES.RESOURCE_GROUP,
        ],
        dashboard: ["user"],
      };
    default:
      return {
        manage: [],
        create: [],
        dashboard: ["user"],
      };
  }
};

export const getPrimaryRole = (roles) => {
  if (typeof roles === "string") return roles;
  let primaryRole;
  Object.values(ROLES).forEach((role) => {
    if (!primaryRole && roles.includes(role)) primaryRole = role;
  });
  return primaryRole;
};

export const filterOptionsByRole = (list, roles = [], type) => {
  if (!roles) return [];
  const role = getPrimaryRole(roles);
  if (!role) return [];
  const rolePermissions = roleBasedPermissions(role);
  const permissionsByType = rolePermissions[type];
  if (permissionsByType === "all") return list;
  else return list.filter((d) => permissionsByType.includes(d?.component));
};

export const filterCreateOptionsByRole = (list, roles = []) =>
  filterOptionsByRole(list, roles, "create");
export const filterManageOptionsByRole = (list, roles = []) =>
  filterOptionsByRole(list, roles, "manage");
export const filterDashboardOptionsByRole = (list, roles = []) =>
  filterOptionsByRole(list, roles, "dashboard");

/**
 * Converts an array of objects into a key-value array.
 * @param {array} data The source data.
 * @param {string} keyProperty The property to use as the key.
 * @param {string} valueProperty The property to use as the value.
 * @returns {array} An array of objects with key and value properties.
 */
export function convertToKeyValueArray(data, keyProperty, valueProperty) {
  return data.map((item) => ({
    key: item[keyProperty],
    value: item[valueProperty],
  }));
}

export const getCurrOrigin = () => window.location.origin;

export const addSpecialFilters = (filters = {}, selected = {}, char = "l") => {
  Object.keys(filters).forEach((key) => {
    if (key?.startsWith(`${char}_`)) delete filters[key];
  });
  Object.entries(selected).forEach(([key, value]) => {
    filters[key.replace(`${char}_`, "")] = value;
  });
};

export function serviceWorkerRegistration() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      const swUrl = "/service-worker.js";
      navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
          console.log("Service Worker registered:", registration);
        })
        .catch((error) => {
          console.log("Service Worker registration failed:", error);
        });
    });
  }
}

export const linkByComponent = (component, id, isPrivate) => {
  let link;
  switch (component) {
    case COMPONENT_TYPES.LAB:
      link = `/${COMPONENT_TYPES.LAB}/${id}`;
      break;
    case COMPONENT_TYPES.CHALLENGE:
      link = `/${COMPONENT_TYPES.CHALLENGE}/${id}`;
      break;
    case COMPONENT_TYPES.PROJECT:
      link = `/${COMPONENT_TYPES.PROJECT}/${id}`;
      break;
    case COMPONENT_TYPES.RESOURCE:
      link = `/${COMPONENT_TYPES.RESOURCE}/${id}`;
      break;
    case COMPONENT_TYPES.ORGANIZATION:
      link = `/${COMPONENT_TYPES.ORGANIZATION}/${id}`;
      break;
    case COMPONENT_TYPES.USER:
      link = `/${COMPONENT_TYPES.PROFILE}/${id}`;
      break;
    case COMPONENT_TYPES.CHALLENGE_PATH:
      link = `/${COMPONENT_TYPES.CHALLENGE_PATH}/${id}`;
      break;
    case COMPONENT_TYPES.LAB_PROGRAMS:
      link = `/${COMPONENT_TYPES.LAB_PROGRAMS}/${id}`;
      break;
    case COMPONENT_TYPES.RESOURCE_COLLECTION:
      link = `/${COMPONENT_TYPES.RESOURCE_COLLECTION}/${id}`;
      break;
    case COMPONENT_TYPES.RESOURCE_GROUP:
      link = `/${COMPONENT_TYPES.RESOURCE_GROUP}/${id}`;
      break;
    case COMPONENT_TYPES.SKILL:
      link = `/${COMPONENT_TYPES.SKILL}/${id}`;
      break;
    case COMPONENT_TYPES.JOB:
      link = `/${COMPONENT_TYPES.JOB}/${id}`;
      break;
    case COMPONENT_TYPES.LAB_MARKETPLACE:
      link = `/${COMPONENT_TYPES.LAB_MARKETPLACE}/${id}`;
      break;
    case COMPONENT_TYPES.CHALLENGE_TEMPLATE:
      link = `/${COMPONENT_TYPES.CHALLENGE_TEMPLATE}/${id}`;
      break;
    default:
      link = `/${window.location.pathname}`;
      break;
  }
  if (isPrivate) link = `${link}?private=true`;
  return link;
};
