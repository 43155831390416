import { Icon, IconButton, Menu, MenuItem } from "@mui/material";
import Button from "components/Button";
import React, { useState } from "react";
import MuiButton from "@mui/material/Button";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import Divider from "components/Divider";

const MenuButton = ({
  listWithTitleonClick = [],
  children,
  basic,
  icon,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useLocalizedTranslation("common", true);

  const handleClick = (e, onClick = Function.prototype) => {
    onClick(e);
    setAnchorEl(null);
  };

  return (
    <>
      {icon ? (
        <IconButton
          className="cursor-pointer h-25 w-25"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          {children}
        </IconButton>
      ) : !basic ? (
        <Button
          {...props}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          {children}
        </Button>
      ) : (
        <MuiButton
          className="c-black capitalize"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          {children}
        </MuiButton>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        disabled={props.disabled}
        sx={{
          paddingTop: 0,
          "& .MuiMenu-list": {
            paddingTop: 0,
          },
        }}
        {...props}
      >
        {listWithTitleonClick
          ?.filter(Boolean)
          .map(
            ({
              title,
              onClick,
              iconClasses,
              classes,
              divider,
              labelClasses,
              icon,
              hide,
              disabled,
            }) =>
              hide ? null : divider ? (
                <Divider classes="mb-0 mt-1" />
              ) : (
                <MenuItem
                  key={title}
                  onClick={(e) => handleClick(e, onClick)}
                  className={`opacity-80 pt-2 ${classes}`}
                  disabled={disabled}
                >
                  {icon && (
                    <Icon
                      component={icon}
                      fontSize="small"
                      className={`mr-1 ${iconClasses}`}
                    />
                  )}
                  <p className={`fs-14 ${labelClasses ?? ""}`}>
                    {t(title, "common")}
                  </p>
                </MenuItem>
              )
          )}
      </Menu>
    </>
  );
};

export default MenuButton;
