export const CANADA_PROVINCES = {
    "AB": {
        name: "Alberta",
        city: [
            'Calgary',
            'Edmonton',
            'Red Deer',
            'Lethbridge',
            'Airdrie',
            'Wood Buffalo',
            'St. Albert',
            'Grande Prairie',
            'Medicine Hat',
            'Spruce Grove',
            'Leduc',
            'Cochrane',
            'Okotoks',
            'Fort Saskatchewan',
            'Chestermere',
            'Beaumont',
            'Lloydminster',
            'Camrose',
            'Stony Plain',
            'Sylvan Lake',
            'Canmore',
            'Cold Lake',
            'Brooks',
            'Strathmore',
            'High River',
            'Lacombe',
            'Wetaskiwin',
            'Morinville',
            'Blackfalds',
            'Hinton',
            'Whitecourt',
            'Olds',
            'Taber',
            'Coaldale',
            'Edson',
            'Banff',
            'Drumheller',
            'Innisfail',
            'Drayton Valley',
            'Ponoka',
            'Peace River',
            'Slave Lake',
            'Rocky Mountain House',
            'Devon',
            'Wainwright',
            'Bonnyville',
            'Stettler',
            'St. Paul',
            'Vegreville',
            'Crowsnest Pass',
            'Redcliff',
            'Didsbury',
            'Westlock',
            'Jasper',
            'Barrhead',
            'Vermilion',
            'Carstairs',
            'Raymond',
            'Claresholm',
            'Pincher Creek',
            'Crossfield',
            'Cardston',
            'Grande Cache',
            'High level',
            'Penhold',
            'Gibbons',
            'Three Hills',
            'Fort Macleod',
            'Athabasca',
            'Coalhurst',
            'Sundre',
            'Grimshaw',
            'Black Diamond',
            'Sexsmith',
            'Rimbey',
            'High Prairie',
            'Turner Valley',
            'Hanna',
            'Beaverlodge',
            'Magrath',
            'Calmar',
            'Nanton',
            'Redwater',
            'Tofield',
            'Provost',
            'Bow Island',
            'Fox Creek',
            'Millet',
            'Picture Butte',
            'Vulcan',
            'Valleyview',
            'Lamont',
            'Wabasca',
            'Springbrook',
            'Wembley',
            'Bon Accord',
            'Elk Point',
            'Nobleford',
            'Two Hills',
            'Bruederheim',
            'Mayerthorpe',
            'Swan Hills',
            'Vauxhall',
            'Bowden',
            'Legal',
            'Bassano',
            'Manning',
            'Irricana',
            'Eckville',
            'Alberta Beach',
            'Duchess',
            'Viking',
            'Bentley',
            'Trochu',
            'Falher',
            'Onoway',
            'Oyen',
        ]
    },
    "BC": {
        name: "British Columbia",
        city: [
            'Vancouver',
            'Surrey',
            'Victoria',
            'Burnaby',
            'Richmond',
            'Kelowna',
            'Abbotsford',
            'Coquitlam',
            'Saanich',
            'White Rock',
            'Delta',
            'Nanaimo',
            'Kamloops',
            'Chilliwack',
            'Maple Ridge',
            'New Westminster',
            'Prince George',
            'Port Coquitlam',
            'North Vancouver',
            'Vernon',
            'Courtenay',
            'Langford Station',
            'West Vancouver',
            'Mission',
            'Campbell River',
            'Penticton',
            'East Kelowna',
            'Port Moody',
            'North Cowichan',
            'Langley',
            'Parksville',
            'Duncan',
            'Squamish',
            'Port Alberni',
            'Fort St. John',
            'Cranbrook',
            'Salmon Arm',
            'Pitt Meadows',
            'Colwood',
            'Oak Bay',
            'Esquimalt',
            'Central Saanich',
            'Lake Country',
            'Sooke',
            'Comox',
            'Terrace',
            'Powell River',
            'Trail',
            'Dawson Creek',
            'Sidney',
            'Prince Rupert',
            'North Saanich',
            'Quesnel',
            'Williams Lake',
            'Whistler',
            'Summerland',
            'View Royal',
            'Nelson',
            'Ladysmith',
            'Coldstream',
            'Sechelt',
            'Castlegar',
            'Gibsons',
            'Qualicum Beach',
            'Kitimat',
            'Kimberley',
            'Merritt',
            'Kent',
            'Hope',
            'Peachland',
            'Oliver',
            'Fernie',
            'Creston',
            'Northern Rockies',
            'Smithers',
            'Armstrong',
            'Spallumcheen',
            'Osoyoos',
            'Metchosin',
            'Westbank',
            'Cumberland',
            'Vanderhoof',
            'Bowen Island',
            'Grand Forks',
            'Port Hardy',
            'Sparwood',
            'Rossland',
            'Mackenzie',
            'Golden',
            'Fruitvale',
            'Invermere',
            'Ellison',
            'Lake Cowichan',
            'Cedar',
            'Enderby',
            'Houston',
            'Pemberton',
            'Errington',
            'Princeton',
            'Cowichan Bay',
            'Royston',
            'Elkford',
            'Highlands',
            'Sicamous',
            'Chase',
            'Tumbler Ridge',
            'Anmore',
            'Clearwater',
            'Lillooet',
            'Logan Lake',
            'Port McNeill',
            'Tofino',
            'Burns Lake',
            'Saltair',
            'Lumby',
            'One Hundred Mile House',
            'Ucluelet',
            'Chetwynd',
            'Harrison Hot Springs',
            'Nisga\'a',
            'Lakeview',
            'Keremeos',
            'Warfield',
            'Popkum',
            'Coombs',
            'Naramata',
            'Nakusp',
            'Fort St. James',
            'Hilliers',
            'Ashcroft',
            'Grindrod',
            'Windermere',
            'Gold River',
            'Dunsmuir',
            'Barriere',
            'Lions Bay',
            'Telkwa',
            'Ootischenia',
            'Taylor',
            'Sorrento',
            'Youbou',
            'Kaleden',
            'Salmo',
            'Valemount',
            'Hudson Hope',
        ]
    },
    "MB": {
        name: "Manitoba",
        city: [
            "Winnipeg",
            "Brandon",
            "Steinbach",
            "Springfield",
            "Hanover",
            "Winkler",
            "Portage La Prairie",
            "Thompson",
            "Tache",
            "St. Andrews",
            "St. Clements",
            "Selkirk",
            "Morden",
            "East St. Paul",
            "Stanley",
            "Macdonald",
            "Dauphin",
            "Rockwood",
            "Ritchot",
            "The Pas",
            "West St. Paul",
            "La Broquerie",
            "Niverville",
            "Brokenhead",
            "Stonewall",
            "Ste. Anne",
            "Flin Flon (Part)",
            "Neepawa",
            "Cornwallis",
            "Headingley",
            "Altona",
            "Swan River",
            "De Salaberry",
            "Lorette",
            "Killarney - Turtle Mountain",
            "Woodlands",
            "Bifrost-Riverton",
            "Cartier",
            "Hillsburg-Roblin-Shell River",
            "WestLake-Gladstone",
            "Mitchell",
            "Beausejour",
            "Lac du Bonnet",
            "Virden",
            "Morris",
            "Carman",
            "North Cypress-Langford",
            "Minnedosa",
            "Dufferin",
            "Kelsey",
            "Gimli",
            "West Interlake",
            "Prairie View",
            "Deloraine-Winchester",
            "Oakland-Wawanesa",
            "Brenda-Waskada",
            "Russell-Binscarth",
            "Ellice-Archie",
            "Souris-Glenwood",
            "Riverdale",
            "Pembina",
            "Wallace-Woodworth",
            "Lorne",
            "Yellowhead",
            "Swan Valley West",
            "Grey",
            "Gilbert Plains",
            "Norfolk-Treherne",
            "Emerson-Franklin",
            "Sifton",
            "Grassland",
            "Louise",
            "Ste. Rose",
            "Cartwright-Roblin",
            "Mossey River",
            "Lakeshore",
            "Riding Mountain West",
            "Clanwilliam-Erickson",
            "Glenboro-South Cypress",
            "North Norfolk",
            "Reinland",
            "Minitonas-Bowsman",
            "Carberry",
            "Armstrong",
            "Grunthal",
            "Piney",
            "Blumenort",
            "Fisher",
            "Wasagamack",
            "Whitehead",
            "Rosedale",
            "Stuartburn",
            "Oakview",
            "Harrison Park",
            "Boissevain",
            "Pinawa",
            "Pipestone",
            "Prairie Lakes",
            "St. Francois Xavier",
            "Grahamdale",
            "Reynolds",
            "St. Laurent",
            "Landmark",
            "Powerview-Pine Falls",
            "St-Pierre-Jolys",
            "Arborg",
            "Elton",
            "Rosser",
            "Montcalm",
            "Coldwell",
            "Alonsa",
            "Teulon",
            "Minto-Odanah",
            "Glenella-Lansdowne",
            "Two Borders",
            "Winnipeg Beach",
            "Victoria",
            "Roland",
            "Melita",
            "Argyle",
            "Hamiota",
            "Gillam",
            "Grand View",
            "McCreary",
            "Rossburn",
            "Ethelbert",
        ]
    },
    "NB": {
        name: "New Brunswick",
        city: [
            "Moncton",
            "Saint John",
            "Fredericton",
            "Dieppe",
            "Quispamsis",
            "Riverview",
            "Miramichi",
            "Edmundston",
            "Bathurst",
            "Oromocto",
            "Campbellton",
            "Shediac",
            "Beaubassin East / Beaubassin-est",
            "Douglas",
            "Sussex",
            "Tracadie",
            "Sackville",
            "Grand Falls",
            "Woodstock",
            "Burton",
            "Saint Marys",
            "Memramcook",
            "Grand Bay-Westfield",
            "Shippagan",
            "Coverdale",
            "Hanwell",
            "Saint Stephen",
            "Hampton",
            "Beresford",
            "Caraquet",
            "New Maryland",
            "Dundas",
            "Simonds",
            "Alnwick",
            "Studholm",
            "Bright",
            "Kingston",
            "Dalhousie",
            "Wellington",
            "Kingsclear",
            "Wakefield",
            "Cocagne",
            "Shippegan",
            "Lincoln",
            "Cap Pele",
            "Salisbury",
            "Buctouche",
            "Grand Manan",
            "Saint George",
            "Paquetville",
            "Minto",
            "Upper Miramichi",
            "Hardwicke",
            "Saint-Quentin",
            "Pennfield Ridge",
            "Northesk",
            "Kent",
            "Westfield Beach",
            "Allardville",
            "Saint-Charles",
            "Saint Mary",
            "Petit Rocher",
            "Eel River Crossing",
            "Manners Sutton",
            "Richibucto",
            "Saint-Louis",
            "Saint Andrews",
            "Maugerville",
            "Brighton",
            "Saint-Antoine",
            "Northampton",
            "Wicklow",
            "Neguac",
            "Balmoral",
            "Southesk",
            "Saint-Jacques",
            "Florenceville",
            "Perth",
            "Saint-Joseph",
            "Belledune",
            "Nauwigewauk",
            "Glenelg",
            "Saint David",
            "Springfield",
            "St. George",
            "Gordon",
            "Southampton",
            "Denmark",
            "Sussex Corner",
            "Petitcodiac",
            "Bas Caraquet",
            "Upham",
            "Cardwell",
            "Hillsborough",
            "Weldford",
            "Norton",
            "Charlo",
            "Richmond",
            "Saint-Leonard",
            "Lameque",
            "Musquash",
            "Queensbury",
            "New Bandon",
            "Peel",
            "Saint James",
            "Saint Martins",
            "Rogersville",
            "McAdam",
            "Newcastle",
            "Bertrand",
            "Saint-Andre",
            "Greenwich",
            "Chipman",
            "Noonan",
            "Atholville",
            "Durham",
            "Havelock",
            "Botsford",
            "Plaster Rock",
            "Wilmot",
            "Kedgwick",
            "Dorchester",
            "Rothesay",
        ]
    },
    "NL": {
        name: "Newfoundland and Labrador",
        city: [
            "St. John's",
            "Conception Bay South",
            "Paradise",
            "Mount Pearl Park",
            "Corner Brook",
            "Grand Falls",
            "Gander",
            "Labrador City",
            "Portugal Cove-St. Philip's",
            "Happy Valley",
            "Torbay",
            "Stephenville",
            "Bay Roberts",
            "Clarenville",
            "Carbonear",
            "Marystown",
            "Deer Lake",
            "Goulds",
            "Channel-Port aux Basques",
            "Pasadena",
            "Placentia",
            "Bonavista",
            "Lewisporte",
            "Bishops Falls",
            "Harbour Grace",
            "Springdale",
            "Botwood",
            "Spaniards Bay",
            "Holyrood",
            "Logy Bay-Middle Cove-Outer Cove",
            "Burin",
            "Grand Bank",
            "St. Anthony",
            "Fogo Island",
            "Twillingate",
            "New-Wes-Valley",
            "Wabana",
            "Glovertown",
            "Pouch Cove",
            "Kippens",
            "Wabush",
            "Trinity Bay North",
            "Victoria",
            "Flat Rock",
            "Stephenville Crossing",
            "Witless Bay",
            "Harbour Breton",
            "Massey Drive",
            "Bay Bulls",
            "Upper Island Cove",
            "Clarkes Beach",
            "Gambo",
            "Humbermouth",
            "Baie Verte",
            "Burgeo",
            "Irishtown-Summerside",
            "St. George's",
            "St. Lawrence",
            "St. Alban's",
            "Centreville-Wareham-Trinity",
            "Nain",
            "Harbour Main-Chapel's Cove-Lakeview",
            "Fortune",
            "Dildo",
        ]
    },
    "NS": {
        name: "Nova Scotia",
        city: [
            "Halifax",
            "Cape Breton",
            "Truro",
            "New Glasgow",
            "Inverness",
            "Kentville",
            "Chester",
            "Queens",
            "Amherst",
            "Bridgewater",
            "Church Point",
            "Argyle",
            "Yarmouth",
            "Barrington",
            "Antigonish",
            "Windsor",
            "Wolfville",
            "Stellarton",
            "Westville",
            "Port Hawkesbury",
            "Pictou",
            "Berwick",
            "Trenton",
            "Lunenburg",
            "Digby",
            "Middleton",
            "Shelburne",
            "Lantz",
            "Falmouth",
            "Stewiacke",
            "Parrsboro",
            "Oxford",
            "Centreville",
            "Wedgeport",
            "Mahone Bay",
        ]
    },
    "NT": {
        name: "Northwest Territories",
        city: [
            "Yellowknife",
            "Hay River",
            "Inuvik",
            "Fort Smith",
            "Behchoko",
            "Fort Simpson",
        ]
    },
    "NU": {
        name: "Nunavut",
        city: [
            "Iqaluit",
            "Rankin Inlet",
            "Arviat",
            "Baker Lake",
            "Cambridge Bay",
            "Igloolik",
            "Pond Inlet",
            "Pangnirtung",
            "Cape Dorset",
            "Gjoa Haven",
            "Repulse Bay",
            "Clyde River",
            "Taloyoak",
            "Kugluktuk",
        ]
    },
    "ON": {
        name: "Ontario",
        city: [
            "Toronto",
            "Ottawa",
            "Hamilton",
            "Mississauga",
            "Brampton",
            "Kitchener",
            "London",
            "Markham",
            "Oshawa",
            "Vaughan",
            "Windsor",
            "St. Catharines",
            "Oakville",
            "Richmond Hill",
            "Burlington",
            "Sudbury",
            "Barrie",
            "Guelph",
            "Whitby",
            "Cambridge",
            "Milton",
            "Ajax",
            "Waterloo",
            "Thunder Bay",
            "Brantford",
            "Chatham",
            "Clarington",
            "Pickering",
            "Niagara Falls",
            "Newmarket",
            "Peterborough",
            "Kawartha Lakes",
            "Caledon",
            "Belleville",
            "Sarnia",
            "Sault Ste. Marie",
            "Welland",
            "Halton Hills",
            "Aurora",
            "North Bay",
            "Stouffville",
            "Cornwall",
            "Georgina",
            "Woodstock",
            "Quinte West",
            "St. Thomas",
            "New Tecumseth",
            "Innisfil",
            "Bradford West Gwillimbury",
            "Timmins",
            "Lakeshore",
            "Brant",
            "Leamington",
            "East Gwillimbury",
            "Orangeville",
            "Orillia",
            "Stratford",
            "Fort Erie",
            "LaSalle",
            "Centre Wellington",
            "Grimsby",
            "King",
            "Woolwich",
            "Clarence-Rockland",
            "Midland",
            "Lincoln",
            "Wasaga Beach",
            "Collingwood",
            "Strathroy-Caradoc",
            "Thorold",
            "Amherstburg",
            "Tecumseh",
            "Essa",
            "Owen Sound",
            "Brockville",
            "Kingsville",
            "Springwater",
            "Scugog",
            "Uxbridge",
            "Wilmot",
            "Essex",
            "Oro-Medonte",
            "Cobourg",
            "South Frontenac",
            "Port Colborne",
            "Huntsville",
            "Russell",
            "Niagara-on-the-Lake",
            "Middlesex Centre",
            "Selwyn",
            "Tillsonburg",
            "Pelham",
            "Petawawa",
            "North Grenville",
            "Loyalist",
            "Port Hope",
            "Pembroke",
            "Bracebridge",
            "Greater Napanee",
            "Kenora",
            "Mississippi Mills",
            "St. Clair",
            "West Lincoln",
            "West Nipissing / Nipissing Ouest",
            "Clearview",
            "Thames Centre",
            "Carleton Place",
            "Guelph/Eramosa",
            "Central Elgin",
            "Saugeen Shores",
            "Ingersoll",
            "South Stormont",
            "Severn",
            "South Glengarry",
            "North Perth",
            "Trent Hills",
            "The Nation / La Nation",
            "West Grey",
            "Gravenhurst",
            "Perth East",
            "Wellington North",
            "Brighton",
            "Tiny",
            "Hawkesbury",
            "Brock",
            "Erin",
            "Kincardine",
            "Elliot Lake",
            "Arnprior",
            "North Dundas",
            "Wellesley",
            "Georgian Bluffs",
            "Norwich",
            "Meaford",
            "Adjala-Tosorontio",
            "Hamilton Township",
            "South Dundas",
            "Lambton Shores",
            "North Dumfries",
            "Mapleton",
            "Rideau Lakes",
            "North Glengarry",
            "South Huron",
            "Penetanguishene",
            "Tay",
            "Cavan Monaghan",
            "Temiskaming Shores",
            "Grey Highlands",
            "Alfred and Plantagenet",
            "Elizabethtown-Kitley",
            "Smiths Falls",
            "Ramara",
            "Leeds and the Thousand Islands",
            "Brockton",
            "Laurentian Valley",
            "Mono",
            "Malahide",
            "Huron East",
            "Beckwith",
            "Shelburne",
            "West Perth",
            "Champlain",
            "Minto",
            "South Bruce Peninsula",
            "Renfrew",
            "Plympton-Wyoming",
            "Kapuskasing",
            "Zorra",
            "Kirkland Lake",
            "Aylmer",
            "Puslinch",
            "Drummond/North Elmsley",
            "Hanover",
            "Dryden",
            "Fort Frances",
            "Goderich",
            "Stone Mills",
            "South-West Oxford",
            "Douro-Dummer",
            "McNab/Braeside",
            "Central Huron",
            "Blandford-Blenheim",
            "Bayham",
            "Augusta",
            "St. Marys",
            "Southgate",
            "Bluewater",
            "East Zorra-Tavistock",
            "Otonabee-South Monaghan",
            "Huron-Kinloss",
            "The Blue Mountains",
            "Whitewater Region",
            "Edwardsburgh/Cardinal",
            "Wainfleet",
            "North Stormont",
            "Alnwick/Haldimand",
            "Arran-Elderslie",
            "Parry Sound",
            "Muskoka Falls",
            "Perth",
            "Cramahe",
            "North Middlesex",
            "Dysart et al",
            "Hindon Hill",
            "Tweed",
            "Oliver Paipoonge",
            "Petrolia",
            "Southwest Middlesex",
            "Front of Yonge",
            "Tay Valley",
            "South Bruce",
            "Ashfield-Colborne-Wawanosh",
            "Trent Lakes",
            "Gananoque",
            "Lanark Highlands",
            "Cochrane",
            "Sioux Lookout",
            "Hearst",
            "Breslau",
            "Stirling-Rawdon",
            "Espanola",
            "West Elgin",
            "East Ferris",
            "North Huron",
            "Southwold",
            "Centre Hastings",
            "Lucan Biddulph",
            "Greenstone",
            "Tyendinaga",
            "Iroquois Falls",
            "Havelock-Belmont-Methuen",
            "Central Frontenac",
            "Seguin",
            "Madawaska Valley",
            "Deep River",
            "Asphodel-Norwood",
            "Red Lake",
            "Hastings Highlands",
            "Prescott",
            "Northern Bruce Peninsula",
            "Casselman",
            "Callander",
            "Amaranth",
            "Marmora and Lake",
            "Bancroft",
            "Howick",
            "Dutton/Dunwich",
            "Perth South",
            "Montague",
            "Warwick",
            "Bonnechere Valley",
            "Morris-Turnberry",
            "Mulmur",
            "Blind River",
            "Powassan",
            "Highlands East",
            "East Hawkesbury",
            "Marathon",
            "Shuniah",
            "Sables-Spanish Rivers",
            "Lake of Bays",
            "Merrickville",
            "Adelaide-Metcalfe",
            "Melancthon",
            "Laurentian Hills",
            "Grand Valley",
            "Admaston/Bromley",
            "North Algona Wilberforce",
            "Wawa",
            "Horton",
            "Enniskillen",
            "Atikokan",
            "Markstay",
            "Northeastern Manitoulin and the Islands",
            "McDougall",
            "French River / Riviere des Francais",
            "East Garafraxa",
            "Greater Madawaska",
            "Georgian Bay",
            "North Kawartha",
            "Perry",
            "Black River-Matheson",
            "Killaloe, Hagarty and Richards",
            "Alvinston",
            "Algonquin Highlands",
            "Addington Highlands",
            "Neebing",
            "Bonfield",
            "Central Manitoulin",
            "Madoc",
            "Mattawa",
            "Dawn-Euphemia",
            "Chapleau",
            "Manitouwadge",
            "Wellington",
            "Frontenac Islands",
            "Point Edward",
            "North Frontenac",
            "Komoka",
            "Deseronto",
            "Nipissing",
            "Huron Shores",
            "Nipigon",
            "Burford",
            "Terrace Bay",
            "Macdonald, Meredith and Aberdeen Additional",
            "Brudenell, Lyndoch and Raglan",
            "Moosonee",
            "Englehart",
            "Strong",
            "Lappe",
            "Armour",
            "Faraday",
            "Bayfield",
            "St.-Charles",
            "Emo",
            "Smooth Rock Falls",
            "Chisholm",
            "Thessalon",
            "Conestogo",
            "St. Joseph",
            "Moonbeam",
            "Claremont",
            "Ignace",
            "Armstrong",
            "Hillsburgh",
            "Sagamok",
            "Hensall",
            "Carling",
            "Laird",
            "Tara",
            "Cobalt",
            "South River",
            "McKellar",
            "South Algonquin",
            "Sioux Narrows-Nestor Falls",
            "Beachburg",
            "Schreiber",
            "Plantagenet",
            "Papineau-Cameron",
            "Assiginack",
            "Prince",
            "Athens",
            "Chatsworth",
            "Magnetawan",
        ]
    },
    "PE": {
        name: "Prince Edward Island",
        city: [
            "Charlottetown",
            "Summerside",
            "Stratford",
            "Cornwall",
            "Montague",
            "Kensington",
            "Miltonvale Park",
            "Alberton",
            "Souris",
            "Malpeque",
        ]
    },
    "QC": {
        name: "Québec",
        city: [
            "Montreal",
            "Quebec City",
            "Laval",
            "Gatineau",
            "Longueuil",
            "Sherbrooke",
            "Levis",
            "Saguenay",
            "Trois-Rivieres",
            "Terrebonne",
            "Saint-Jerome",
            "Saint-Jean-sur-Richelieu",
            "Brossard",
            "Repentigny",
            "Drummondville",
            "Chateauguay",
            "Granby",
            "Mirabel",
            "Blainville",
            "Lac-Brome",
            "Saint-Hyacinthe",
            "Beloeil",
            "Mascouche",
            "Shawinigan",
            "Joliette",
            "Rimouski",
            "Dollard-des-Ormeaux",
            "Victoriaville",
            "Saint-Eustache",
            "Vaudreuil-Dorion",
            "Salaberry-de-Valleyfield",
            "Rouyn-Noranda",
            "Boucherville",
            "Sorel-Tracy",
            "Cote-Saint-Luc",
            "Pointe-Claire",
            "Val-d'Or",
            "Chambly",
            "Alma",
            "Sainte-Julie",
            "Saint-Constant",
            "Magog",
            "Boisbriand",
            "Sainte-Therese",
            "La Prairie",
            "Saint-Bruno-de-Montarville",
            "Thetford Mines",
            "Sept-Iles",
            "Hudson",
            "Saint-Lin--Laurentides",
            "L'Assomption",
            "Candiac",
            "Saint-Lambert",
            "Saint-Lazare",
            "Varennes",
            "Mont-Royal",
            "Baie-Comeau",
            "Riviere-du-Loup",
            "Saint-Augustin-de-Desmaures",
            "Sainte-Marthe-sur-le-Lac",
            "Westmount",
            "Les Coteaux",
            "Kirkland",
            "Dorval",
            "Beaconsfield",
            "Mont-Saint-Hilaire",
            "Deux-Montagnes",
            "Saint-Colomban",
            "Sainte-Catherine",
            "Saint-Basile-le-Grand",
            "L'Ancienne-Lorette",
            "Saint-Charles-Borromee",
            "Cowansville",
            "Sainte-Anne-des-Plaines",
            "Gaspe",
            "Pincourt",
            "Mercier",
            "Lavaltrie",
            "Lachute",
            "Rosemere",
            "Matane",
            "Mont-Laurier",
            "Mistassini",
            "Beauharnois",
            "Becancour",
            "Sainte-Sophie",
            "Val-des-Monts",
            "Saint-Amable",
            "Sainte-Marie",
            "Amos",
            "Prevost",
            "Sainte-Adele",
            "Sainte-Agathe-des-Monts",
            "Les Iles-de-la-Madeleine",
            "Carignan",
            "L'Ile-Perrot",
            "Montmagny",
            "Cantley",
            "Notre-Dame-de-l'Ile-Perrot",
            "Bromont",
            "La Tuque",
            "Rawdon",
            "Saint-Felicien",
            "Roberval",
            "Bois-des-Filion",
            "Marieville",
            "Saint-Sauveur",
            "Stoneham-et-Tewkesbury",
            "Mont-Tremblant",
            "Saint-Zotique",
            "Saint-Raymond",
            "Lorraine",
            "Notre-Dame-des-Prairies",
            "Sainte-Julienne",
            "Donnacona",
            "L'Epiphanie",
            "Pont-Rouge",
            "Coaticook",
            "La Peche",
            "Otterburn Park",
            "Sainte-Brigitte-de-Laval",
            "Sainte-Catherine-de-la-Jacques-Cartier",
            "Farnham",
            "Delson",
            "La Malbaie",
            "Boischatel",
            "Beauport",
            "Saint-Hippolyte",
            "Old Chelsea",
            "Saint-Apollinaire",
            "Nicolet",
            "Contrecoeur",
            "La Sarre",
            "Chandler",
            "Acton Vale",
            "Saint-Philippe",
            "Rigaud",
            "Louiseville",
            "Chibougamau",
            "Coteau-du-Lac",
            "Saint-Remi",
            "Les Cedres",
            "Baie-Saint-Paul",
            "Brownsburg",
            "Asbestos",
            "Hampstead",
            "Saint-Joseph-du-Lac",
            "Plessisville",
            "Sainte-Anne-des-Monts",
            "Saint-Lambert-de-Lauzon",
            "Val-Shefford",
            "Port-Cartier",
            "Saint-Paul",
            "Shannon",
            "Saint-Honore",
            "Beauceville",
            "Beaupre",
            "Charlemagne",
            "Mont-Joli",
            "Pointe-Calumet",
            "Pontiac",
            "L'Ange-Gardien",
            "Saint-Felix-de-Valois",
            "McMasterville",
            "Saint-Calixte",
            "Lac-Megantic",
            "Saint-Henri",
            "Vercheres",
            "Richelieu",
            "Princeville",
            "Saint-Cesaire",
            "Val-David",
            "Notre-Dame-du-Mont-Carmel",
            "Sainte-Martine",
            "Saint-Roch-de-l'Achigan",
            "Saint-Pie",
            "Windsor",
            "Montreal-Ouest",
            "Temiscouata-sur-le-Lac",
            "Sainte-Anne-de-Bellevue",
            "Mont-Orford",
            "Saint-Germain-de-Grantham",
            "Saint-Cyrille-de-Wendover",
            "Chisasibi",
            "Chertsey",
            "Lanoraie",
            "Warwick",
            "Napierville",
            "Waterloo",
            "Saint-Joseph-de-Beauce",
            "Berthierville",
            "Riviere-Rouge",
            "Saint-Denis-de-Brompton",
            "Amqui",
            "Saint-Mathias-sur-Richelieu",
            "Saint-Boniface",
            "Chateau-Richer",
            "Montreal-Est",
            "Saint-Antonin",
            "Saint-Jean-de-Matha",
            "La Pocatiere",
            "Roxton Pond",
            "Saint-Etienne-des-Gres",
            "Saint-Donat",
            "Metabetchouan-Lac-a-la-Croix",
            "Maniwaki",
            "Danville",
            "Lac-Etchemin",
            "Saint-Jacques",
            "L'Islet-sur-Mer",
            "Carleton-sur-Mer",
            "Oka",
            "Morin-Heights",
            "Crabtree",
            "Saint-Tite",
            "New Richmond",
            "Baie-d'Urfe",
            "Saint-Andre-Avellin",
            "Saint-Ambroise-de-Kildare",
            "East Angus",
            "Saint-Adolphe-d'Howard",
            "Saint-Prosper",
            "Ormstown",
            "Saint-Agapit",
            "Saint-Ambroise",
            "Mistissini",
            "Saint-Faustin--Lac-Carre",
            "Saint-Pascal",
            "Dunham",
            "Havre-Saint-Pierre",
            "Saint-Anselme",
            "Trois-Pistoles",
            "Grande-Riviere",
            "Malartic",
            "Saint-Maurice",
            "Ascot Corner",
            "Fossambault-sur-le-Lac",
            "Sainte-Anne-des-Lacs",
            "Saint-Sulpice",
            "Saint-Alphonse-de-Granby",
            "Sainte-Claire",
            "Perce",
            "Saint-Jean-Port-Joli",
            "Saint-Andre-d'Argenteuil",
            "Saint-Come--Liniere",
            "Forestville",
            "Compton",
            "Richmond",
            "Saint-Gabriel-de-Valcartier",
            "Paspebiac",
            "Saint-Thomas",
            "Saint-Jean-Baptiste",
            "Portneuf",
            "Normandin",
            "Saint-Alphonse-Rodriguez",
            "Val-Morin",
            "Clermont",
            "Saint-Christophe-d'Arthabaska",
            "Mont-Saint-Gregoire",
            "Thurso",
            "Saint-Gabriel",
            "Saint-Liboire",
            "Degelis",
            "Saint-Alexis-des-Monts",
            "Cap-Saint-Ignace",
            "Saint-Anaclet-de-Lessard",
            "Stoke",
            "Cap Sante",
            "Saint-David-de-Falardeau",
            "Saint-Ferreol-les-Neiges",
            "Senneterre",
            "Saint-Mathieu-de-Beloeil",
            "Sainte-Marie-Madeleine",
            "Sainte-Melanie",
            "Saint-Paul-d'Abbotsford",
            "Saint-Michel",
            "Saint-Marc-des-Carrieres",
            "Stanstead",
            "Sainte-Anne-de-Beaupre",
            "Sainte-Luce",
            "Saint-Joseph-de-Sorel",
            "Ferme-Neuve",
            "Yamachiche",
            "Adstock",
            "Bonaventure",
            "Pohenegamook",
            "Saint-Isidore",
            "Sainte-Marguerite-du-Lac-Masson",
            "Saint-Prime",
            "Kuujjuaq",
            "Grenville-sur-la-Rouge",
            "Saint-Dominique",
            "Macamic",
            "Sainte-Anne-de-Sorel",
            "Rougemont",
            "Piedmont",
            "Lac-des-Ecorces",
            "Saint-Pamphile",
            "Bedford",
            "Weedon-Centre",
            "Lacolle",
            "Saint-Gabriel-de-Brandon",
            "Huntingdon",
            "Saint-Bruno",
            "Laurier-Station",
            "Saint-Anicet",
            "Cap-Chat",
            "Notre-Dame-de-Lourdes",
            "Ville-Marie",
            "Wickham",
            "Neuville",
            "Maria",
            "Saint-Chrysostome",
            "Saint-Damase",
            "Disraeli",
            "Saint-Alexandre",
            "Herbertville",
            "Sainte-Thecle",
            "Fermont",
            "La Presentation",
            "Sainte-Catherine-de-Hatley",
            "Saint-Basile",
            "Saint-Raphael",
            "Saint-Martin",
            "Causapscal",
            "Brigham",
            "Sainte-Victoire-de-Sorel",
            "Port-Daniel--Gascons",
            "Labelle",
            "Saint-Michel-des-Saints",
            "Saint-Victor",
            "Saint-Ephrem-de-Beauce",
            "Lery",
            "Temiscaming",
            "Sainte-Genevieve-de-Berthier",
            "Sainte-Madeleine",
            "Sainte-Croix",
            "Valcourt",
            "Saint-Mathieu",
            "Waterville",
            "Mansfield-et-Pontefract",
            "Saint-Denis",
            "Gore",
            "Saint-Gedeon-de-Beauce",
            "Saint-Leonard-d'Aston",
            "Fort-Coulonge",
            "Albanel",
            "Pessamit",
            "Maskinonge",
            "Saint-Charles-de-Bellechasse",
            "Hatley",
            "East Broughton",
            "Saint-Polycarpe",
            "Deschambault",
            "Wendake",
            "Saint-Come",
            "Waskaganish",
            "Lebel-sur-Quevillon",
            "Pierreville",
            "Saint-Gilles",
            "Saint-Bernard",
            "Sainte-Cecile-de-Milton",
            "Saint-Roch-de-Richelieu",
            "Saint-Nazaire",
            "Saint-Elzear",
            "Hinchinbrooke",
            "Saint-Francois-Xavier-de-Brompton",
            "Papineauville",
            "Saint-Ignace-de-Loyola",
            "Sainte-Anne-de-Sabrevois",
            "Sainte-Anne-de-la-Perade",
            "Saint-Damien-de-Buckland",
            "Saint-Ferdinand",
            "Saint-Fulgence",
            "Manouane",
            "Saint-Gervais",
            "Saint-Alexandre-de-Kamouraska",
            "Saint-Marc-sur-Richelieu",
            "Mandeville",
            "Caplan",
            "Saint-Damien",
            "Lac-Nominingue",
            "Obedjiwan",
            "Saint-Gedeon",
            "Kingsey Falls",
            "L'Ascension-de-Notre-Seigneur",
            "Barraute",
            "Saint-Liguori",
            "Saint-Patrice-de-Sherrington",
            "Saint-Esprit",
            "Mashteuiatsh",
            "Saint-Francois-du-Lac",
            "Vallee-Jonction",
            "Saint-Fabien",
            "Lac-Superieur",
            "Les Escoumins",
            "Terrasse-Vaudreuil",
            "Riviere-Beaudette",
            "Saint-Barthelemy",
            "Austin",
            "Saint-Paul-de-l'Ile-aux-Noix",
            "Saint-Cyprien-de-Napierville",
            "Deleage",
            "Potton",
            "Sainte-Beatrix",
            "Saint-Georges-de-Cacouna",
            "Sainte-Justine",
            "Saint-Valerien-de-Milton",
            "Saint-Cuthbert",
            "Saint-Blaise-sur-Richelieu",
            "Saint-Joseph-de-Coleraine",
            "Pointe-Lebel",
            "Grenville",
            "Saint-Michel-de-Bellechasse",
            "Sainte-Angele-de-Monnoir",
            "Champlain",
            "Sacre-Coeur-Saguenay",
            "Saint-Lucien",
            "Saint-Robert",
            "La Guadeloupe",
            "Sutton",
            "Saint-Placide",
            "Povungnituk",
            "Pointe-des-Cascades",
            "Chambord",
            "Dudswell",
            "Saint-Narcisse",
            "Waswanipi",
            "Inukjuak",
            "Saint-Zacharie",
            "Hemmingford",
            "Saint-Pierre-de-l'Ile-d'Orleans",
            "Saint-Clet",
            "Saint-Ours",
            "Sainte-Anne-de-la-Pocatiere",
            "Notre-Dame-du-Bon-Conseil",
            "Sainte-Clotilde",
            "Nouvelle",
            "Yamaska",
            "Saint-Antoine-de-Tilly",
            "Saint-Elie-de-Caxton",
            "Price",
            "Saint-Jacques-le-Mineur",
            "Val-Joli",
            "Saint-Antoine-sur-Richelieu",
            "Saint-Pacome",
            "Saint-Stanislas-de-Kostka",
            "Frontenac",
            "Sainte-Emelie-de-l'Energie",
            "Saint-Charles-sur-Richelieu",
            "Sainte-Helene-de-Bagot",
            "Franklin Centre",
            "Mille-Isles",
            "Lyster",
            "Sainte-Clotilde-de-Horton",
            "Saint-Benoit-Labre",
            "Maliotenam",
            "Chapais",
            "Saint-Honore-de-Shenley",
            "Cleveland",
            "Messines",
            "Saint-Laurent-de-l'Ile-d'Orleans",
            "Saint-Jean-de-Dieu",
            "Larouche",
            "Saint-Francois-de-la-Riviere-du-Sud",
            "Eeyou Istchee Baie-James",
            "Shawville",
            "Lambton",
            "Saint-Flavien",
            "Sainte-Marcelline-de-Kildare",
            "Riviere-Blanche",
            "Saint-Felix-de-Kingsey",
            "Sainte-Elisabeth",
            "Uashat",
            "Saint-Bernard-de-Lacolle",
            "Saint-Guillaume",
            "Venise-en-Quebec",
            "Saint-Paulin",
            "Saint-Albert",
            "Matagami",
            "Amherst",
            "Notre-Dame-du-Laus",
            "Saint-Tite-des-Caps",
            "Saint-Casimir",
            "Saint-Malachie",
            "Salluit",
            "Saint-Louis-de-Gonzague",
            "Saint-Urbain",
            "Tring-Jonction",
            "Saint-Joachim",
            "Saint-Theodore-d'Acton",
            "L' Isle-Verte",
            "Palmarolle",
            "Saint-Odilon-de-Cranbourne",
            "La Dore",
            "Lac-au-Saumon",
            "Wotton",
            "Wemindji",
            "Pointe-aux-Outardes",
            "Riviere-Heva",
            "Scott",
            "Godmanchester",
            "Saint-Simon",
            "Tingwick",
            "Saint-Aubert",
            "Saint-Mathieu-du-Parc",
            "Saint-Ubalde",
            "Berthier-sur-Mer",
            "Frampton",
            "Chute-aux-Outardes",
            "New Carlisle",
            "Saint-Majorique-de-Grantham",
            "Wentworth-Nord",
            "Sainte-Ursule",
            "Nantes",
            "Lac-aux-Sables",
            "Vaudreuil-sur-le-Lac",
            "Amulet",
            "L'Avenir",
            "Pointe-a-la-Croix",
            "Herouxville",
            "L'Isle-aux-Allumettes",
            "Sainte-Brigide-d'Iberville",
            "Les Eboulements",
            "Sainte-Barbe",
            "Saint-Louis-du-Ha! Ha!",
            "Ragueneau",
            "Saint-Edouard",
            "Riviere-Bleue",
            "Noyan",
            "Notre-Dame-du-Portage",
            "Saint-Hugues",
            "Sainte-Anne-du-Sault",
            "La Conception",
            "L'Isle-aux-Coudres",
            "Sainte-Lucie-des-Laurentides",
            "Saint-Alexis",
            "Roxton Falls",
            "Clarendon",
            "Saint-Ludger",
            "Racine",
            "Saint-Zenon",
            "Saint-Armand",
            "Saint-Edouard-de-Lotbiniere",
            "Saint-Arsene",
            "Listuguj",
            "Saint-Hubert-de-Riviere-du-Loup",
            "Saint-Jude",
            "La Minerve",
            "Trecesson",
            "Notre-Dame-des-Pins",
            "Saint-Alban",
            "Saint-Pierre-les-Becquets",
            "Labrecque",
            "Sainte-Henedine",
            "L'Anse-Saint-Jean",
            "Akwesasne",
            "Saint-Valere",
            "Saint-Norbert-d'Arthabaska",
            "Saint-Hilarion",
            "Saint-Modeste",
            "Saint-Simeon",
            "Saint-Barnabe",
            "Bury",
            "Lac-Bouchette",
            "Saint-Lazare-de-Bellechasse",
            "Saint-Michel-du-Squatec",
            "Saint-Joachim-de-Shefford",
            "Grand-Remous",
            "Saint-Gabriel-de-Rimouski",
            "Sainte-Marie-Salome",
            "Saint-Cyprien",
            "Tres-Saint-Sacrement",
            "Saints-Anges",
            "Saint-Urbain-Premier",
            "Sainte-Agathe-de-Lotbiniere",
            "Grande-Vallee",
            "Mont-Carmel",
            "Saint-Eugene",
            "Notre-Dame-des-Neiges",
            "Saint-Leon-de-Standon",
            "Sayabec",
            "Sainte-Sabine",
            "Saint-Maxime-du-Mont-Louis",
            "Blanc-Sablon",
            "Ayer's Cliff",
            "Les Mechins",
            "Sainte-Marguerite",
            "Saint-Claude",
            "Sainte-Jeanne-d'Arc",
            "Sainte-Felicite",
            "Girardville",
            "Saint-Bruno-de-Guigues",
            "Upton",
            "Saint-Narcisse-de-Beaurivage",
            "Plaisance",
            "Roxton-Sud",
            "Saint-Frederic",
            "Saint-Narcisse-de-Rimouski",
            "Saint-Patrice-de-Beaurivage",
            "Sainte-Marthe",
            "Notre-Dame-du-Nord",
            "Saint-Aime-des-Lacs",
            "Lac-Drolet",
            "Coleraine",
            "Saint-Bonaventure",
            "Saint-Wenceslas",
            "Sainte-Genevieve-de-Batiscan",
            "Saint-Justin",
            "Saint-Norbert",
            "Riviere-Ouelle",
            "Stukely-Sud",
            "Saint-Georges-de-Clarenceville",
            "Sainte-Therese-de-Gaspe",
            "Sainte-Petronille",
            "Desbiens",
            "La Macaza",
            "Saint-Vallier",
            "Bristol",
            "Saint-Sylvestre",
            "Saint-Stanislas",
            "Longue-Rive",
            "Saint-Leonard-de-Portneuf",
            "Brebeuf",
            "Baie-du-Febvre",
            "Durham-Sud",
            "Melbourne",
            "Hebertville",
            "Lorrainville",
            "Saint-Rene-de-Matane",
            "Eastman",
            "Wemotaci",
            "Cookshire",
            "Laurierville",
            "Ripon",
            "Henryville",
            "Gracefield",
            "Yamaska-Est",
            "Frelighsburg",
        ]
    },
    "SK": {
        name: "Saskatchewan",
        city: [
            "Saskatoon",
            "Regina",
            "Prince Albert",
            "Moose Jaw",
            "Lloydminster",
            "Swift Current",
            "Yorkton",
            "North Battleford",
            "Warman",
            "Weyburn",
            "Estevan",
            "Martensville",
            "Corman Park No. 344",
            "Melfort",
            "Humboldt",
            "Meadow Lake",
            "La Ronge",
            "Flin Flon",
            "White City",
            "Kindersley",
            "Melville",
            "Edenwold No. 158",
            "Nipawin",
            "Battleford",
            "Prince Albert No. 461",
            "Buckland No. 491",
            "Tisdale",
            "La Loche",
            "Vanscoy No. 345",
            "Pelican Narrows",
            "Pilot Butte",
            "Unity",
            "Meadow Lake No. 588",
            "Moosomin",
            "Esterhazy",
            "Rosetown",
            "Assiniboia",
            "Rosthern No. 403",
            "Outlook",
            "Canora",
            "Biggar",
            "Maple Creek",
            "Dundurn No. 314",
            "Britannia No. 502",
            "Rama",
            "Swift Current No. 137",
            "Blucher",
            "Lumsden No. 189",
            "Fort Qu'Appelle",
            "Indian Head",
            "Watrous",
            "Orkney No. 244",
            "Kamsack",
            "Lumsden",
            "Regina Beach",
            "Shaunavon",
            "Wynyard",
            "Dalmeny",
            "Balgonie",
            "Rosthern",
            "Shellbrook No. 493",
            "Carlyle",
            "Langham",
            "Hudson Bay",
            "Frenchman Butte",
            "Wilton No. 472",
            "Torch River No. 488",
            "Shellbrook",
            "Macklin",
            "Creighton",
            "Laird No. 404",
            "Canwood No. 494",
            "Spiritwood No. 496",
            "Oxbow",
            "Wadena",
            "Wilkie",
            "Ile-a-la-Crosse",
            "Estevan No. 5",
            "Lanigan",
            "South Qu'Appelle No. 157",
            "Mervin No. 499",
            "Osler",
            "Beaver River",
            "Moose Jaw No. 161",
            "Langenburg",
            "Maidstone",
            "Kipling",
            "Carnduff",
            "Foam Lake",
            "Hudson Bay No. 394",
            "Waldheim",
            "Buffalo Narrows",
            "Air Ronge",
            "Weyburn No. 67",
            "Grenfell",
            "St. Louis No. 431",
            "Pinehouse",
            "Preeceville",
            "Maple Creek No. 111",
            "Birch Hills",
            "Kerrobert",
            "Eston",
            "Kindersley No. 290",
            "Davidson",
            "Battle River No. 438",
            "Delisle",
            "Longlaketon No. 219",
            "Nipawin No. 487",
            "Duck Lake No. 463",
            "Gravelbourg",
            "Lajord No. 128",
        ]
    },
    "YT": {
        name: "Yukon",
        city: [
            "Whitehorse",
            "Dawson",
        ]
    }
}
