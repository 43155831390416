import Banner from "components/UI/molecules/Banner";
import DashboardLayout from "pages/layouts/dashboard";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Card from "components/Cards/Card";
import Button from "components/Button";
import ComparePlans from "./ComparePlans";
import { plansList } from "./data";
import useAuth from "store/auth/service-hook";
import useOrganization from "store/organization/service-hook";
import AddOns from "./AddOns";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { useTranslation } from "react-i18next";

const UpgradePlan = () => {
  const [activePlan, setActivePlan] = useState();
  const {
    state: { user },
  } = useAuth();
  const { planDetails } = useOrganization();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  const fetchPlanDetails = async () => {
    const res = await planDetails(user?.organization_details?.slug);
    setActivePlan(res?.plan);
  };

  const handleSelectPlan = () => {
    const instance = window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_STIE,
      publishableKey: process.env.REACT_APP_CHARGEBEE_KEY,
    });
    let cbPortal = instance.createChargebeePortal({
      email: user?.email,
    });

    cbPortal.open({
      close() {
        fetchPlanDetails();
      },
    });
  };

  return (
    <DashboardLayout emptyMessage="No organization Found!" show404OnError>
      <Banner classes="minh-300 minh-380--mob">
        <div className="container flex-column items-center text-center pt-2 mt-20  mt-18--mob">
          <h2 className="mx-auto text-center fs-32 fw-700 fs-24--mob">
            {t("Pick a plan that’s right for your Organization")}:
          </h2>
          <h1 className="fs-32 fw-700 mt-2 mb-3 fs-24--mob">
            {user?.organization_details?.title || "N/A"}
          </h1>
          <p className={`mx-auto fs-16 fs-13--mob mt-2 w-60p w-100p--mob`}>
            {t(
              "Whether you’re a small team or a large organization, Prepr has a subscription plan that fits your needs."
            )}
          </p>
        </div>
      </Banner>
      <div className="mt-12 container">
        <Grid container spacing={2} className="flex justify-center">
          {plansList?.map((plan, i) => (
            <Grid
              key={i}
              item
              xs={12}
              md={6}
              lg={3}
              xl={2}
              justifyContent="center"
              alignItems="center"
            >
              <PlanCard
                title={plan?.title}
                description={plan?.description}
                icon={plan?.icon}
                price={plan?.price}
                active={activePlan === plan?.id}
                isPopular={plan.isPopular}
                pricePerYearPerUser={plan?.pricePerYearPerUser}
                totalUsers={plan?.totalUsers}
                totalPrice={plan?.totalPrice}
                haveTrialPeriod={plan?.haveTrialPeriod}
                type={plan.type}
                handleActivePlan={handleSelectPlan}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <ComparePlans
        activePlan={activePlan}
        setActivePlan={handleSelectPlan}
        t={t}
      />
      <AddOns t={t} />
    </DashboardLayout>
  );
};

export default UpgradePlan;

export const PlanCard = ({
  title,
  description,
  pricePerYearPerUser,
  haveTrialPeriod,
  totalUsers,
  totalPrice,
  icon,
  handleActivePlan,
  active,
  isPopular,
  handleNext,
  type,
}) => {
  const { t } = useTranslation(["organization", "common"]);

  return (
    <Card
      classes={`h-400 w-full overflow-visible flex-column justify-between relative items-center py-5 text-center ${
        active && "border-primary"
      }`}
    >
      {isPopular && (
        <p
          style={{ top: "-12px" }}
          className="absolute bg-purple c-white w-full fs-13 fw-500 py-1 rounded-top-10"
        >
          {t("Most Popular")}
        </p>
      )}
      <div className="flex-center">
        <img src={icon} className="w-30 h-25 mr-1" alt={`icon of ${title}`} />
        <h3>{t(title, { ns: "common" })}</h3>
      </div>
      <div>
        <p
          className={`fw-700  ${
            type === "enterprise" ? "fs-17 mt-4" : "fs-30 mt-2"
          }`}
        >
          {type === "seed" || type === "enterprise" ? (
            t(pricePerYearPerUser)
          ) : (
            <>
              <span className="fs-12">$</span>
              {pricePerYearPerUser}
              <span className="fs-12">{t("/year/user")}</span>
            </>
          )}
        </p>
        <p className={`fs-12 ${type === "enterprise" ? "mt-4" : "mt-1"}`}>
          {type === "seed" ? (
            <>
              {t("includes")} <span className="fw-700 fs-16">{totalUsers}</span>{" "}
              {t("users")}
            </>
          ) : type === "enterprise" ? (
            <>
              <span className="fw-700 fs-16">{totalUsers}</span> {t("users")}
            </>
          ) : (
            <>
              {t("with purchase of")}{" "}
              <span className="fw-700 fs-16">{totalUsers}</span> {t("users")}
            </>
          )}
        </p>
      </div>
      <p className="c-gray fs-12">{totalPrice}</p>
      <p className={`${type !== "seed" && "c-primary fw-700"}  my-1 fs-12`}>
        {haveTrialPeriod
          ? t("14 days trial available")
          : type === "enterprise"
          ? t("Contact us for a free 14-day trial!")
          : " "}
      </p>
      <p className="fs-12 text-truncate-2" title={t(description)}>
        {t(description)}
      </p>
      <Button
        classes="fs-12 w-130 mt-3"
        bordered={!!active}
        color="blue"
        onClick={handleActivePlan}
        disabled={active}
      >
        {t(
          active
            ? "Current Plan"
            : type === "enterprise"
            ? "Contact Sales"
            : "Start Trial"
        )}
      </Button>
    </Card>
  );
};
