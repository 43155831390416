import { useState } from "react";
import DashboardLayout from "../../layouts/dashboard";
import OrganizationReportContextWrapper from "./container/OrganizationReportContext";
import { Tab, Tabs } from "@mui/material";
import OrganizationReportDetails from "./components/OrganizationReportDetails";
import OrganizationOverview from "./components/OrganizationOverview";
import OrganizationMembers from "./components/OrganizationMembers";
import "../index.scss";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const OrganizationReport = () => {
  const { t } = useLocalizedTranslation();
  const [activeTab, setActiveTab] = useState("overview");

  return (
    <>
      <DashboardLayout>
        <OrganizationReportContextWrapper>
          <div className="mt-header mb-5 container-big">
            <OrganizationReportDetails t={t} />
            <div className={"mb-8 mx-8 mt-3"}>
              <Tabs
                value={activeTab}
                onChange={(event, value) => {
                  setActiveTab(value);
                }}
              >
                <Tab label={t("Organization Details")} value="overview" />
                <Tab label={t("Organization Members")} value="members" />
              </Tabs>

              <div className={"mt-3"}>
                {/*COMPONENT OVERVIEW*/}
                {activeTab === "overview" ? <OrganizationOverview t={t} /> : ""}

                {/*LAB MEMBERS*/}
                {activeTab === "members" ? <OrganizationMembers t={t}/> : ""}
              </div>
            </div>
          </div>
        </OrganizationReportContextWrapper>
      </DashboardLayout>
    </>
  );
};

export default OrganizationReport;
