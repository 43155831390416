import { SCHEMA_TYPES, TYPE } from "helpers/utils/constants";
import { REQUIRED_VALIDATOR, TITLE_VALIDATOR } from "helpers/utils/validators";

export const schema = [
  {
    title: "Upload Image",
    name: "cover_image",
    type: SCHEMA_TYPES.FILE,
    description: "The image (png, jpg, or jpeg) needs to be at least 735 x 415 pixels.",
    dropboxProps: {
      width: "95%",
      fileType: "image",
      video_code: "iframe_code",
      height: "300px",
      dimensions: [735, 415],
    },
    lg: 12,
  },
  {
    title: "Title",
    name: "title",
    type: SCHEMA_TYPES.INPUT,
    lg: 12,
    validator: TITLE_VALIDATOR,
  },
  {
    title: "Description",
    name: "description",
    type: SCHEMA_TYPES.EDITOR,
    lg: 12,
    validator: REQUIRED_VALIDATOR,
    maxLength: 1000,
  },
  {
    title: "Privacy",
    name: "privacy",
    type: SCHEMA_TYPES.SELECT,
    validator: REQUIRED_VALIDATOR,
    options: [
      { value: "Public", key: "public" },
      { value: "Private", key: "private" },
    ],
  },
  {
    title: "Global Resources",
    name: "is_global",
    type: SCHEMA_TYPES.CHECKBOX,
    option: "Resource is public and visible for all users on the platform.",
  },
  {
    title: "Level",
    name: "level_id",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Duration",
    name: "duration_id",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Type",
    name: "type",
    type: SCHEMA_TYPES.SELECT,
    options: TYPE,
    multi: true,
  },
  {
    title: "Mode",
    name: "mode",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { value: "Team", key: "team" },
      { value: "Individual", key: "individual" },
    ],
    multi: true,
  },
  {
    title: "Resource Module Skills",
    name: "skills",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Skill Sets",
    name: "skill_stacks",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
  },
  {
    title: "Skill Groups",
    name: "skill_groups",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
  },

  {
    title: "File Upload",
    name: "files",
    type: SCHEMA_TYPES.UPPY,
    description: "Add images, videos, or files to your Resource Module.",
    description2: "File size limit: 150MB",
    descriptionClasses: "fs-14 c-gray",
    lg: 12,
    maxFileSize: 150 * 1024 * 1024, //150MB in Bytes
  },
  {
    title: "SCORM File Upload",
    name: "scorm_file_upload",
    type: SCHEMA_TYPES.FILE,
    description: "Add SCORM package to your Resource Module.",
    descriptionClasses: "fs-14 c-gray",
    dropboxProps: {
      width: "100%",
      height: "270px",
    },
    lg: 12,
    fileType: "zip",
    contentType: "scorm",
  },
];

export const getSchemaByStep = (step) => {
  switch (step) {
    case 0:
      return [0, schema.findIndex((d) => d.name === "files") + 1];
    case 1:
      return [
        schema.findIndex((d) => d.name === "files"),
        schema.findIndex((d) => d.name === "scorm_file_upload") + 1,
      ];
    default:
      return [0, 0];
  }
};

export const stepSchema = (step) => {
  return schema.slice(...getSchemaByStep(step));
};

export const audios = [
  {
    title: "Week7 Bounce",
    audio:
      "https://commondatastorage.googleapis.com/codeskulptor-assets/week7-bounce.m4a",
  },
  {
    title: "Week7 Bounce",
    audio:
      "https://commondatastorage.googleapis.com/codeskulptor-assets/week7-bounce.m4a",
  },
  {
    title: "Game Theme",
    audio:
      "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/theme_01.mp3",
  },
  {
    title: "Week7 Bounce",
    audio:
      "https://commondatastorage.googleapis.com/codeskulptor-assets/week7-bounce.m4a",
  },
];

export const videos = [
  {
    title: "Big Buck Bunny",
    video:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
  },
  {
    title: "Elephant Dream",
    video:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
  },
  {
    title: "For Bigger Blazes",
    video:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
  },
  {
    title: "Elephant Dream",
    video:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
  },
];

export const FILE_SCORM = "scorm";
