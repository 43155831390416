import React from "react";
import styles from "./input.module.scss";
import cx from "classnames";
import DatePicker from "react-date-picker";
import { CalendarMonth, Close } from "@mui/icons-material";
import { createEvent } from "helpers/utils/utilities";
import moment from "moment";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import i18n from "../../i18n";

const DateInput = ({
  value,
  placeholder = "MM-DD-YYYY",
  onChange,
  top,
  error,
  height,
  width,
  title,
  classes,
  required,
  disabled,
  disablePast,
  disableFuture,
  description,
  inputClasses = "",
  noWrapDecsription,
  name,
}) => {
  const { t } = useLocalizedTranslation();
  return (
    <div
      data-testid="date-input"
      style={{ marginTop: top }}
      className={cx(styles.inputContainer, classes)}
    >
      {!!title && (
        <p className={styles.title}>
          {t(title)}
          {!!required && <span>*</span>}
        </p>
      )}
      {!!description && (
        <p
          className={`${styles.description} ${
            noWrapDecsription ? "text-nowrap" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: t(description) }}
        />
      )}
      <DatePicker
        calendarClassName={styles.calenderBox}
        style={{ height: !!height && height, width: !!width && width }}
        className={`w-full ${inputClasses}`}
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        value={!!value && moment(value).isValid() ? new Date(value) : undefined}
        onChange={(e) =>
          onChange(
            createEvent(
              name,
              e ? moment(e).format("YYYY-MM-DD hh:mm:ss") : undefined
            )
          )
        }
        format="MM-d-y"
        minDate={disablePast && moment().toDate()}
        disabled={!!disabled}
        calendarIcon={<CalendarMonth />}
        clearIcon={<Close />}
        maxDate={disableFuture && moment().subtract(1, "days").toDate()}
        locale={i18n.language}
        //DISABLE KEYBOARD INPUT
        onKeyDown={(e) => e.preventDefault()}
      />
      {error && <p className={styles.error}>{t(error, "common")}</p>}
    </div>
  );
};

export default DateInput;
