import { SCHEMA_TYPES } from "helpers/utils/constants";
import {
  EDITOR_VALIDATOR,
  NUMBER_VALIDATOR,
  REQUIRED_VALIDATOR,
  TITLE_VALIDATOR,
} from "helpers/utils/validators";
import { CAMPUS_CONNECT_SCHEMA } from "pages/labs/forms/data";
import { achievement_schema } from "./submissionAndAcheivement/AddAchievementSection";
/**
 * 

 */
export const dummyList = [
  {
    uuid: "1234",
    title: "Sample 1",
    media: "https://prepr.org/wp-content/uploads/2021/10/diversity-lab.jpeg",
  },
  {
    uuid: "1235",
    title: "Sample 2",
    media: "https://prepr.org/wp-content/uploads/2021/10/diversity-lab.jpeg",
  },

  {
    uuid: "1236",
    title: "Sample 3",
    media: "https://prepr.org/wp-content/uploads/2021/10/diversity-lab.jpeg",
  },

  {
    uuid: "1237",
    title: "Sample 4",
    media: "https://prepr.org/wp-content/uploads/2021/10/diversity-lab.jpeg",
  },
];

export const schema = [
  {
    title: "Title",
    name: "title",
    type: SCHEMA_TYPES.INPUT,
    lg: 12,
    validator: TITLE_VALIDATOR,
  },
  {
    title: "Description",
    name: "description_type",
    type: SCHEMA_TYPES.RADIO,
    validator: REQUIRED_VALIDATOR,
    options: [
      { title: "Create With Text", key: "text" },
      { title: "Create with SCORM File", key: "scorm" },
    ],
    lg: 12,
  },
  {
    title: "",
    errorTitle: "Description",
    name: "description",
    type: SCHEMA_TYPES.EDITOR,
    lg: 12,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "",
    name: "scorm_file",
    type: SCHEMA_TYPES.FILE,
    description: "",
    dropboxProps: {
      width: "95%",
      fileType: "zip",
    },
    validator: REQUIRED_VALIDATOR,
    lg: 12,
    contentType: "scorm",
  },
  {
    title: "Duration",
    name: "duration_id",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Level",
    name: "level_id",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Challenge Skills",
    name: "skills",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
    validator: REQUIRED_VALIDATOR,
    lg: 6,
  },
  {
    title: "Challenge Status",
    name: "is_open",
    type: SCHEMA_TYPES.RADIO,
    options: [
      { title: "Closed for submission", key: "no" },
      { title: "Open for submission", key: "yes" },
    ],
    lg: 12,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Privacy of Challenge",
    name: "privacy",
    type: SCHEMA_TYPES.RADIO,
    options: [
      { value: "Public (Anyone can join)", key: "no" },
      { value: "Private (Only invited users can join)", key: "yes" },
    ],
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Privacy of submissions",
    name: "project_privacy",
    type: SCHEMA_TYPES.RADIO,
    options: [
      { title: "Public(viewable by anyone)", key: "no" },
      { title: "Private(viewable only by admin)", key: "yes" },
    ],
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Notify participants of Challenge updates",
    name: "is_notification_enabled",
    type: SCHEMA_TYPES.RADIO,
    options: [
      { title: "Do not send email", key: "no" },
      { title: "Send email", key: "yes" },
    ],
    lg: 12,
  },
  {
    title: "Search Sponsors on PreprLabs",
    name: "host_id",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
    lg: 12,
    hasIcon: true,
    placeholder: "Search for sponsors",
  },
  {
    title: "Upload Image",
    name: "cover_image",
    type: SCHEMA_TYPES.FILE,
    description:
      "The image(png, jpg, or jpeg) needs to be at least 625 x 355 pixels.",
    dropboxProps: {
      width: "95%",
      fileType: "image",
      height: "300px",
      video_code: "iframe_code",
      dimensions: [625, 355],
    },
    lg: 12,
  },
  {
    title: "Skill Groups",
    name: "skill_groups",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
    lg: 12,
  },
  {
    title: "Skill Stacks",
    name: "skill_stacks",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
    lg: 12,
  },

  {
    title: "Type",
    description: "Are you assessing, onboarding, engaging, or growing?",
    name: "type",
    type: SCHEMA_TYPES.SELECT,
    multi: true,
    placeholder: "Type",
    options: [
      { value: "Access", key: "assess" },
      { value: "Onboard", key: "onboard" },
      { value: "Engage", key: "engage" },
      { value: "Grow", key: "grow" },
    ],
  },
  {
    title: "Mode",
    description: "Will participants be independent or work in teams?",
    name: "mode",
    type: SCHEMA_TYPES.SELECT,
    multi: true,
    placeholder: "Mode",
    options: [
      { value: "Individual", key: "individual" },
      { value: "Teams", key: "team" },
    ],
  },
  {
    title: "Source Link",
    description:
      "Do you have a webpage or site you are associated with or like to promote?",
    name: "source_link",
    type: SCHEMA_TYPES.INPUT,
  },
  {
    title: "Category",
    description: "Who is this challenge for?",
    name: "category_id",
    type: SCHEMA_TYPES.MASTER,
    filters: {
      component: "challenge",
    },
  },
  {
    title: "Challenge Jobs",
    description: "What type of jobs is this Challenge covering?",
    name: "jobs",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
    lg: 6,
  },
  {
    title: "Challenge Slug",
    description: "Set the Challenge URL Slug",
    name: "slug",
    type: SCHEMA_TYPES.INPUT,
    disabled: true,
    lg: 6,
  },
  // {
  //   title: "Publish Status",
  //   name: "request_type",
  //   type: SCHEMA_TYPES.RADIO,
  //   options: [
  //     { title: "Publish on Platform", key: "publish" },
  //     { title: "Save as draft", key: "draft" },
  //   ],
  //   validator: REQUIRED_VALIDATOR,
  // },
  // {
  //   title: "Challenge Template Display",
  //   option: "Challenge is clonable by other organizations.",
  //   name: "template",
  //   type: SCHEMA_TYPES.CHECKBOX,
  // },
  // {
  //   title: "Challenge Tags",
  //   name: "tags",
  //   type: SCHEMA_TYPES.MASTER,
  //   multi: true,
  //   validator: REQUIRED_VALIDATOR,
  // },
  {
    name: "externalLinks",
  },
  {
    title: "Template",
    name: "template_id",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Assessment Type",
    name: "assessment_type",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { key: "none", value: "No Evaluation" },
      { key: "open", value: "Peer to Peer Evaluation" },
      { key: "closed", value: "Private Evaluation" },
      // { key: "ai", value: "AI" },
    ],
    lg: 12,
  },
  {
    title: "Allow self evaluation",
    name: "self_assessment",
    option: "User can evaluate their own projects",
    type: SCHEMA_TYPES.CHECKBOX,
  },
  {
    title: "Assign evaluators",
    name: "members_email",
    type: SCHEMA_TYPES.NETWORK_USERS,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Will the Assessment be viewable by users?",
    type: SCHEMA_TYPES.RADIO,
    name: "visibility",
    options: [
      { title: "Hidden", key: "hidden" },
      { title: "Users", key: "users" },
    ],
    lg: 12,
  },
  {
    title: "Guidelines",
    name: "guidelines",
    type: SCHEMA_TYPES.EDITOR,
    validator: EDITOR_VALIDATOR,
    lg: 12,
    height: 150,
  },
  {
    name: "attachments",
    type: SCHEMA_TYPES.FILE_BUTTON,
    title: "Upload File",
    validator: REQUIRED_VALIDATOR,
    right: true,
    emptyDescription: "No file chosen",
    lg: 12,
  },
  {
    name: "criteria",
  },
  {
    title: "Project Submission Requirements",
    description: "Set what needs to be submitted in a Project",
    name: "project_submission_requirement_ids",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
    multi: true,
    lg: 12,
  },
  {
    option: "Allow submission of previously submitted projects.",
    type: SCHEMA_TYPES.CHECKBOX,
    name: "allow_submit_project",
    lg: 12,
  },
  {
    option: "Completed education profile & program requirement.",
    type: SCHEMA_TYPES.CHECKBOX,
    name: "complete_education_program",
    lg: 12,
  },
  {
    option: "Completed experience profile & minimum # years of experience.",
    type: SCHEMA_TYPES.CHECKBOX,
    name: "complete_experience",
    lg: 12,
  },
  {
    title: "Enter Minimum Years of Experience",
    name: "min_experience",
    type: SCHEMA_TYPES.INPUT,
    inputProps: { type: "number" },
    lg: 12,
    // validator:REQUIRED_VALIDATOR
  },
  {
    title: "Particpiation Terms and Conditions",
    name: "agreement",
    type: SCHEMA_TYPES.EDITOR,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  // submission
  {
    title: "Maximum Number of Submissions",
    name: "max_project_submission",
    inputProps: { type: "number" },
    type: SCHEMA_TYPES.INPUT,
  },
  {
    title: "Maximum Number of Associated Projects",
    name: "max_project_associated",
    inputProps: { type: "number" },
    type: SCHEMA_TYPES.INPUT,
  },
  {
    title: "Minimum Number of Imported Badges",
    name: "min_imported_badges",
    inputProps: { type: "number" },
    type: SCHEMA_TYPES.INPUT,
  },
  {
    title: "Minimum Number of Achievement Trophies",
    name: "min_achievement_counts",
    inputProps: { type: "number" },
    type: SCHEMA_TYPES.INPUT,
  },
  {
    title: "Minimum Learning Rank",
    name: "min_rank",
    inputProps: { type: "number" },
    type: SCHEMA_TYPES.INPUT,
  },
  {
    title: "Minimum Points",
    name: "min_points",
    inputProps: { type: "number" },
    type: SCHEMA_TYPES.INPUT,
  },
  {
    title: "Additional Requirements",
    name: "additional_requirements",
    type: SCHEMA_TYPES.INPUT,
    lg: 12,
  },
  ...achievement_schema(),
  ...CAMPUS_CONNECT_SCHEMA,
  {
    title:
      "Schedule the Challenge by selecting start date/submission deadline or active duration",
    type: SCHEMA_TYPES.RADIO,
    options: [
      { value: "Restricted Dates", key: "restricted" },
      { value: "Flexible Dates", key: "flexible" },
    ],
    name: "timeline_type",
    lg: 12,
  },

  {
    title: "Start date",
    description: "The first day that users can join and start the Challenge",
    name: "start_date",
    type: SCHEMA_TYPES.DATE,
    validator: REQUIRED_VALIDATOR,
    disablePast: false,
    inputClasses: "maxw-250",
    noWrapDecsription: true,
    lg: 4,
  },
  {
    title: "Time",
    name: "start_date_time",
    description: "",
    type: SCHEMA_TYPES.TIME,
    validator: REQUIRED_VALIDATOR,
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 15,
    timeCaption: "Time",
    dateFormat: "h:mm aa",
  },
  {
    title: "Start date description",
    name: "start_date_description",
    type: SCHEMA_TYPES.EDITOR,
    lg: 12,
    maxLength: 100,
    height: 50,
    placeholder: "You can describe up to 50-100 characters.",
  },
  {
    title: "Registration deadline (Optional)",
    description:
      "The last day for users to join the Challenge by creating a project",
    name: "registration_deadline_date",
    type: SCHEMA_TYPES.DATE,
    disablePast: false,
    inputClasses: "maxw-250",
    noWrapDecsription: true,
    lg: 4,
  },
  {
    title: "Time",
    description: "",
    name: "registration_deadline_time",
    type: SCHEMA_TYPES.TIME,
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 15,
    timeCaption: "Time",
    dateFormat: "h:mm aa",
  },
  {
    title: "Registration deadline description",
    name: "registration_deadline_date_description",
    type: SCHEMA_TYPES.EDITOR,
    lg: 12,
    maxLength: 100,
    height: 50,
    placeholder: "You can describe up to 50-100 characters.",
  },
  {
    title: "Submission deadline",
    description:
      "The last day for users to submit their project to the Challenge",
    name: "submission_deadline_date",
    type: SCHEMA_TYPES.DATE,
    validator: REQUIRED_VALIDATOR,
    noWrapDecsription: true,
    disablePast: false,
    inputClasses: "maxw-250",
    lg: 4,
  },
  {
    title: "Time",
    name: "submission_deadline_time",
    type: SCHEMA_TYPES.TIME,
    validator: REQUIRED_VALIDATOR,
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 15,
    timeCaption: "Time",
    dateFormat: "h:mm aa",
  },
  {
    title: "Submission deadline description",
    name: "submission_deadline_date_description",
    type: SCHEMA_TYPES.EDITOR,
    lg: 12,
    maxLength: 100,
    height: 50,
    placeholder: "You can describe up to 50-100 characters.",
  },

  {
    title: "Active Duration",
    description:
      "Set how many days/weeks/months until the Challenge will expire",
    name: "flexible_date_number",
    type: SCHEMA_TYPES.INPUT,
    validator: NUMBER_VALIDATOR,
    noWrapDecsription: true,
    placeholder: "0",
    lg: 2.5,
  },
  {
    title: "",
    name: "flexible_date_duration",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { value: "Days", key: "days" },
      { value: "Weeks", key: "weeks" },
      { value: "Months", key: "months" },
    ],
    validator: REQUIRED_VALIDATOR,
    placeholder: "Days",
    lg: 2.5,
  },
  {
    title: "Automatic alert",
    name: "automatic_alert",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { value: "A week before submission deadline", key: "week" },
      { value: "A day before submission deadline", key: "day" },
    ],
  },
  {
    title: "Challenge deadline",
    description:
      "If you wish to leave this challenge open indefinitely, then leave this field blank",
    name: "flexible_expire_deadline",
    type: SCHEMA_TYPES.DATE,
  },
];

export const getSchemaByStep = (step) => {
  switch (step) {
    case 0:
      return [0, schema.findIndex((d) => d.name === "externalLinks") + 1];
    case 1:
      return [
        schema.findIndex((d) => d.name === "template_id"),
        schema.findIndex((d) =>
          d.name.includes("campus_connect_story_video_youtube_url")
        ) + 1,
      ];
    case 2:
      return [
        schema.findIndex(
          (d) => d.name === "campus_connect_story_video_youtube_url"
        ) + 1,
        schema.findIndex((d) => d.name === "flexible_expire_deadline") + 1,
      ];
    default:
      return [0, 0];
  }
};

export const stepSchema = (step) => {
  return schema.slice(...getSchemaByStep(step));
};
