import React from "react";
import yellowTag from "../../assets/svg/yellowTag.svg";
import { Grid } from "@mui/material";

const ProductCard = ({
  children,
  tag,
  isSelected,
  handleSelect,
  classes,
  width,
}) => {
  
  return (
    <Grid xs={12} sm={6} lg={4} item data-testid="product-card">
      <div
        onClick={handleSelect}
        sx={{ width: !!width && width }}
        className={`product__Card ${
          isSelected && "selected"
        }  rounded-10 shadow relative cursor-pointer py-3 mx-auto px-2 ${classes}`}
      >
        {tag && (
          <div className="z-20 tag absolute top-0 left-0 h-90">
            <img
              src={yellowTag}
              className="w-full h-full"
              alt="premium tag on prebuilt lab"
            />
            <p className="tag__text tracking-tight fs-15 fw-500">{tag}</p>
          </div>
        )}
        {children}
      </div>
    </Grid>
  );
};

export default ProductCard;
