import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import BrowseWrapper from "components/UI/organisms/BrowseWrapper";
import useListingHeader from "helpers/hooks/useListingHeader";
import useRedirect from "helpers/hooks/useRedirect";
import React, { useEffect, useState } from "react";
import ResourceListing from "pages/resources/ResourceModule/listing/ResourceListing";
import useResourceGroups from "store/resource-groups/service-hook";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const browse_options = [
  {
    id: 1,
    title: "All Resources Groups",
    pageTitle: "Browse Resources Groups",
  },
  {
    id: 2,
    title: "My Resource Groups",
    pageTitle: "Browse Your Modules Groups",
  },
  {
    id: 3,
    title: "Saved Resources Groups",
    pageTitle: "Browse Saved Resources Groups",
  },
];

const BrowseResourceGroups = () => {
  const state = useListingHeader();
  const [browseMode, setBrowseMode] = useState(0);
  const { fetchResourceGroups, fetchMyResourceGroups } = useResourceGroups();
  const [resources, setResources] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { selected } = useFilters();
  const { navigateManageResourceG } = useRedirect();
  const { t } = useLocalizedTranslation("common");

  useEffect(() => {
    const abortController = new AbortController();
    handleFetchResources(abortController);
    return () => abortController.abort();
  }, [page, selected, state.sort, state.search, browseMode]);

  const handleFetchResources = async (abortController) => {
    setLoading(true);
    const filters = {
      ...selected,
      page,
      sort_by: state.sort,
      search: state.search,
    };

    if (selected?.multi_type) filters.type = selected.multi_type;
    delete filters.multi_type;

    if (browseMode === 1) {
      const [list, count, totalCount] = await fetchMyResourceGroups({
        ...filters,
      });
      setResources(list || []);
      setPages(count || 0);
      setTotalCount(totalCount || 0);
      setLoading(false);
      return;
    }
    if (browseMode === 2) {
      filters.social_type = "favourites";
    }
    const [list, count, totalCount] = await fetchResourceGroups(filters);
    if (!abortController.signal.aborted) {
      setResources(list || []);
      setPages(count || 0);
      setTotalCount(totalCount || 0);
      setLoading(false);
    }
  };

  return (
    <BrowseWrapper
      {...state}
      noAction
      browseMode={browseMode}
      setBrowseMode={setBrowseMode}
      browseOptions={browse_options}
      manageNavigate={navigateManageResourceG}
      noType
      filters={[
        FILTER_TYPES.COMPONENT_TYPE,
        FILTER_TYPES.MULTI_TYPE,
        FILTER_TYPES.DURATION,
        FILTER_TYPES.LEVEL,
        FILTER_TYPES.SKILLS,
        FILTER_TYPES.RATING,
        FILTER_TYPES.PROGRESS,
      ]}
    >
      {totalCount > 0 && !loading && (
        <h3 className="mt-3 ml-1">
          {totalCount ?? 0} {t("Results", "common")}
        </h3>
      )}
      <ResourceListing
        browseMode={browseMode}
        gridType={state.gridType}
        resources={resources}
        setResources={setResources}
        loading={loading}
        handleDelete={() => {}}
        page={page}
        setPage={setPage}
        pageCount={pages}
        isGroup
        emptyMessage={'No result found'}
      />
    </BrowseWrapper>
  );
};

export default BrowseResourceGroups;
