import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {
  mockRequestGet,
  mockRequestPost,
  mockRequestDelete,
} from "../mocks/request";
import { dispatchMock, initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useProfile from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api";
import ACTIONS from "./action-types";

let getSpy;
let postSpy;
let deleteSpy;

let username = "test";
let usernameOne = "testOne";
let testProfileData = "1";
let image = "image.jpeg";

const headers = {
  Authorization: "Bearer 1",
};

const payload = {
  language: "en",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
  [REDUCER_TYPES.PROFILE]: { profile: null },
});

const dispatch = dispatchMock(store);

const ProfileServicesMock = () => {
  const {
    getProfile,
    addDetails,
    addEducation,
    addCertificate,
    addExperience,
    addSkills,
    addPatent,
    deleteEducation,
    deleteExperience,
    deleteCertificate,
    deletePatent,
    deleteSkills,
    fileUpload,
    addTags,
    deleteTags,
    sendFriendRequest,
    acceptRejectFriendRequest,
    addProfilePhoto,
    sendFollowRequest,
    getFriendsList,
    getFollowersList,
    pendingRequests,
    unfriend,
    unfollow,
    getProjects,
    getChallenges,
    updatePrivacy,
  } = useProfile();

  const BUTTON_LINKS = [
    { name: "getProfile", onClick: () => getProfile(usernameOne) },
    { name: "getMyProfile", onClick: () => getProfile(username) },
    { name: "addDetails", onClick: () => addDetails({}) },
    { name: "addEducation", onClick: () => addEducation([{}]) },
    { name: "addCertificate", onClick: () => addCertificate([{}]) },
    { name: "addExperience", onClick: () => addExperience([{}]) },
    {
      name: "addSkills",
      onClick: () => addSkills(testProfileData, testProfileData),
    },
    { name: "addPatent", onClick: () => addPatent([{}]) },
    {
      name: "deleteEducation",
      onClick: () => deleteEducation(testProfileData),
    },
    {
      name: "deleteExperience",
      onClick: () => deleteExperience(testProfileData),
    },
    {
      name: "deleteCertificate",
      onClick: () => deleteCertificate(testProfileData),
    },
    { name: "deletePatent", onClick: () => deletePatent(testProfileData) },
    { name: "deleteSkills", onClick: () => deleteSkills(testProfileData) },
    { name: "fileUpload", onClick: () => fileUpload(testProfileData) },
    { name: "addTags", onClick: () => addTags(testProfileData) },
    { name: "deleteTags", onClick: () => deleteTags(testProfileData) },
    {
      name: "sendFriendRequest",
      onClick: () => sendFriendRequest(testProfileData),
    },
    {
      name: "acceptFriendRequest",
      onClick: () => acceptRejectFriendRequest(testProfileData, false),
    },
    {
      name: "rejectFriendRequest",
      onClick: () => acceptRejectFriendRequest(testProfileData, true),
    },
    { name: "addProfilePhoto", onClick: () => addProfilePhoto(image) },
    {
      name: "sendFollowRequest",
      onClick: () => sendFollowRequest(testProfileData),
    },
    { name: "getFriendsList", onClick: () => getFriendsList() },
    { name: "getFollowersList", onClick: () => getFollowersList() },
    { name: "pendingFriendRequests", onClick: () => pendingRequests(false) },
    { name: "pendingFollowRequests", onClick: () => pendingRequests(true) },
    { name: "unfriend", onClick: () => unfriend(testProfileData) },
    { name: "unfollow", onClick: () => unfollow(testProfileData, true) },
    { name: "getProjects", onClick: () => getProjects(username) },
    { name: "getChallenges", onClick: () => getChallenges(username) },
    { name: "updatePrivacy", onClick: () => updatePrivacy(testProfileData) },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Profile services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <ProfileServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
    deleteSpy = mockRequestDelete();
  });

  test("getProfile", async () => {
    const button = screen.getByTestId("getProfile");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${usernameOne}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(0));
  });

  test("getMyProfile", async () => {
    const button = screen.getByTestId("getMyProfile");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${username}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.PROFILE}/${ACTIONS.PROFILE}`,
      })
    );
  });

  test("addDetails", async () => {
    const button = screen.getByTestId("addDetails");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.PERSONAL_DETAILS}/${ENDPOINTS.ADD}?language=en`,
        {
          ...payload,
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("addEducation", async () => {
    const button = screen.getByTestId("addEducation");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.EDUCATION}/${ENDPOINTS.ADD}?language=en`,
        {
          language: "en",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("addExperience", async () => {
    const button = screen.getByTestId("addExperience");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.EXPERIENCE}/${ENDPOINTS.ADD}?language=en`,
        {
          language: "en",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("addCertificate", async () => {
    const button = screen.getByTestId("addCertificate");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.CERTIFICATE}/${ENDPOINTS.ADD}?language=en`,
        {
          language: "en",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("addPatent", async () => {
    const button = screen.getByTestId("addPatent");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.PATENT}/${ENDPOINTS.ADD}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("addSkills", async () => {
    const button = screen.getByTestId("addSkills");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.SKILLS}/${ENDPOINTS.ADD}?language=en`,
        {
          language: "en",
          skill_id: ["1", "1"],
          pinned: [0, 1],
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("deleteEducation", async () => {
    const button = screen.getByTestId("deleteEducation");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.EDUCATION}/${testProfileData}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("deleteExperience", async () => {
    const button = screen.getByTestId("deleteExperience");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.EXPERIENCE}/${testProfileData}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("deleteCertificate", async () => {
    const button = screen.getByTestId("deleteCertificate");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.CERTIFICATE}/${testProfileData}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("deletePatent", async () => {
    const button = screen.getByTestId("deletePatent");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.PATENT}/${testProfileData}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("deleteSkills", async () => {
    const button = screen.getByTestId("deleteSkills");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.SKILLS}/${testProfileData}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("fileUpload", async () => {
    const button = screen.getByTestId("fileUpload");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.FILE}/${ENDPOINTS.UPLOAD}?language=en`,
        { file: "1" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("addTags", async () => {
    const button = screen.getByTestId("addTags");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.TAGS}/${ENDPOINTS.ADD}?language=en`,
        {
          language: "en",
          tag_id: testProfileData,
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("deleteTags", async () => {
    const button = screen.getByTestId("deleteTags");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.TAGS}/${testProfileData}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("sendFriendRequest", async () => {
    const button = screen.getByTestId("sendFriendRequest");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.REQUEST}/${ENDPOINTS.SEND}?language=en`,
        {
          language: "en",
          user_id: testProfileData,
        },
        headers,
        expect.any(Function)
      )
    );
  });

  test("acceptFriendRequest", async () => {
    const button = screen.getByTestId("acceptFriendRequest");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.REQUEST}/${ENDPOINTS.ACCEPT}?language=en`,
        {
          language: "en",
          user_id: testProfileData,
        },
        headers,
        expect.any(Function)
      )
    );
  });

  test("rejectFriendRequest", async () => {
    const button = screen.getByTestId("rejectFriendRequest");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.REQUEST}/${ENDPOINTS.REJECT}?language=en`,
        {
          language: "en",
          user_id: testProfileData,
        },
        headers,
        expect.any(Function)
      )
    );
  });

  test("addProfilePhoto", async () => {
    const button = screen.getByTestId("addProfilePhoto");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.IMAGE}?language=en`,
        {
          language: "en",
          profile_image: image,
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(2));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: {},
        type: `${ENDPOINTS.PROFILE}/${ACTIONS.PROFILE}`,
      })
    );
  });

  test("sendFollowRequest", async () => {
    const button = screen.getByTestId("sendFollowRequest");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.REQUEST}/${ENDPOINTS.FOLLOW}?language=en`,
        {
          language: "en",
          user_id: testProfileData,
        },
        headers,
        expect.any(Function)
      )
    );
  });

  test("getFriendsList", async () => {
    const button = screen.getByTestId("getFriendsList");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${username}/${ENDPOINTS.FRIENDS}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.PROFILE}/${ACTIONS.FRIENDS}`,
      })
    );
  });

  test("getFollowersList", async () => {
    const button = screen.getByTestId("getFollowersList");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${username}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.FOLLOW}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.PROFILE}/${ACTIONS.FOLLOWERS}`,
      })
    );
  });

  test("pendingFriendRequests", async () => {
    const button = screen.getByTestId("pendingFriendRequests");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${username}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.PENDING}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("pendingFollowRequests", async () => {
    const button = screen.getByTestId("pendingFollowRequests");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${username}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.PENDING}/${ENDPOINTS.FOLLOW}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("unfriend", async () => {
    const button = screen.getByTestId("unfriend");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.REQUEST}/${ENDPOINTS.UN_FRIEND}?language=en`,
        {
          language: "en",
          user_id: testProfileData,
        },
        headers,
        expect.any(Function)
      )
    );
  });

  test("unfollow", async () => {
    const button = screen.getByTestId("unfollow");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.REQUEST}/${ENDPOINTS.UN_FOLLOW}?language=en`,
        {
          language: "en",
          user_id: testProfileData,
        },
        headers,
        expect.any(Function)
      )
    );
  });

  test("getProjects", async () => {
    const button = screen.getByTestId("getProjects");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${username}/${ENDPOINTS.ADVANCE_SEARCH_PROJECTS}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(0));
  });

  test("getChallenges", async () => {
    const button = screen.getByTestId("getChallenges");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${username}/${ENDPOINTS.CHALLENGES}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(0));
  });

  test("updatePrivacy", async () => {
    const button = screen.getByTestId("updatePrivacy");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${testProfileData}/${ENDPOINTS.UPDATE_PRIVACY}?language=en`,
        { id: testProfileData, language: "en" },
        headers,
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(0));
  });
});
