import Card from "components/Cards/Card";
import EditCreateHeaderCard from "components/Cards/EditCreateHeaderCard";
import DashboardLayout from "pages/layouts/dashboard";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { schema, stepSchema } from "./data";
import useForms from "helpers/hooks/useForms";
import {
  deleteValues,
  keyVal,
  keyValueFormat,
  payloadFormatArray,
  payloadKeyVal,
  payloadKeyVals,
  payloadToArrayKeyValues,
} from "helpers/utils/utilities";
import CreateFormFooter from "components/UI/atoms/CreateFormFooter";
import useResourceCollections from "store/resource-collections/service-hook";
import ResourceCollectionDetails from "./ResourceCollectionDetails";
import useResources from "store/resources/service-hook";
import AssociatedListing from "components/UI/organisms/AssociatedListing";
import { toast } from "react-toastify";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { isTrue } from "helpers/utils/validators";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import useDebounce from "helpers/hooks/useDebounce";
import useAssociation from "store/association";
import { ENDPOINTS } from "store/api/endpoints";

const STEPS = ["Resource Collection Details", "Associated Resources"];

const CreateEditResourceCollection = () => {
  const { fetchAssociation } = useAssociation();
  const [resourceSearchText, setResourceSearchText] = useState("");
  const [fetchResourceLoading, setFetchResourceLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { id } = useParams();
  const editMode = !!id;
  const [loading, setLoading] = useState(false);
  const [experts, setExperts] = useState([]);
  const [resources, setResources] = useState({ all: [], selected: [] });
  const { fetchMyResourceCollection, edit, create } = useResourceCollections();
  const { fetchResourceSelectList } = useResources();
  const [initLoading, setInitLoading] = useState(Boolean(editMode));
  const sliceSchema = (_schema) => stepSchema(currentStep);
  const { t } = useLocalizedTranslation();

  const { data, errors, validate, onChange, setData } = useForms(
    schema,
    {},
    sliceSchema
  );

  useEffect(() => {
    if (editMode) handleFetchDetail();
    else {
      setData({});
      setCurrentStep(0);
      setExperts([]);
      setResources({ all: [], selected: [] });
    }
  }, [id, editMode]);

  useEffect(() => {
    handleFetchResources();
  }, [editMode]);

  const handleFetchResources = async (query) => {
    setFetchResourceLoading(true);
    const filters = query ? { search: query } : {};
    const results = await fetchResourceSelectList(false, filters);
    setResources({
      all: results,
      selected: [],
    });
    setFetchResourceLoading(false);
  };

  const debouncedSearchText = useDebounce(resourceSearchText, 300); // Adjust delay as needed
  useEffect(() => {
    handleFetchResources(debouncedSearchText);
  }, [debouncedSearchText]);

  const handleSearch = (searchText) => {
    setResourceSearchText(searchText);
  };

  const handleFetchDetail = async () => {
    const res = await fetchMyResourceCollection(id);
    if (res) {
      const {
        title,
        description,
        skills = {},
        skill_groups = {},
        skill_stacks = {},
        category_id,
        level_id,
        duration_id,
        id,
        privacy,
        cover_image,
        level,
        duration,
        category,
        organization_id,
        mode,
        type,
        media_type,
        expert_references = [],
      } = res;

      setData({
        title,
        description,
        skills: payloadToArrayKeyValues(skills),
        skill_groups: payloadToArrayKeyValues(skill_groups),
        skill_stacks: payloadToArrayKeyValues(skill_stacks),
        category_id: keyVal(category, category_id),
        level: keyVal(level, level_id),
        duration: keyVal(duration, duration_id),
        id,
        privacy: isTrue(privacy) ? "private" : "public",
        cover_image: media_type === "image" ? cover_image : null,
        organization_id: organization_id,
        mode,
        type,
        media_type,
        iframe_code: media_type === "embedded" ? cover_image : null,
      });
      setExperts(expert_references);
    }

    const [challengeModules] = await fetchAssociation(
      COMPONENT_TYPES.RESOURCE_COLLECTION,
      id,
      ENDPOINTS.CHALLENGE
    );

    const [labModules] = await fetchAssociation(
      COMPONENT_TYPES.RESOURCE_COLLECTION,
      id,
      ENDPOINTS.LAB
    );

    const [resourceModules] = await fetchAssociation(
      COMPONENT_TYPES.RESOURCE_COLLECTION,
      id,
      ENDPOINTS.RESOURCE_MODULE
    );
    setInitLoading(false);

    setData((val) => ({
      ...val,
      challenge_ids: keyValueFormat(challengeModules),
      lab_ids: keyValueFormat(labModules),
    }));

    setResources((val) => ({
      all: val.all,
      selected: [
        ...(resourceModules || []).map((item) => ({
          uuid: item.id,
          title: item.title,
          media: item.cover_image,
        })),
      ],
    }));
  };

  const handleEditCreate = async () => {
    setLoading(true);
    const payload = {
      ...data,
      resource_ids: resources?.selected.map((resource) => resource.uuid),
      media_type: data?.iframe_code ? "embedded" : "image",
      cover_image: data?.iframe_code ? data?.iframe_code : data?.cover_image,
      expert_references: payloadFormatArray(experts, "email"),
    };
    if (!payload?.resource_ids?.length) {
      toast.error(t("Please select at least one resource."));
      setLoading(false);
      return;
    }
    const deleteVals = ["iframe_code"];
    if (editMode) {
      if (
        typeof payload?.cover_image === "string" &&
        payload.media_type === "image"
      )
        deleteVals.push(...["cover_image", "media_type"]);
    }
    if (!payload.cover_image) deleteVals.push(...["cover_image", "media_type"]);
    payloadKeyVal(payload, ["level", "duration"]);
    payloadKeyVals(payload, [
      "lab_ids",
      "challenge_ids",
      "skills",
      "skill_stacks",
      "skill_groups",
    ]);
    if (!data.skill_groups || !data.skill_groups.length) {
      deleteVals.push("skill_groups");
    }
    if (!data.skill_stacks || !data.skill_stacks.length) {
      deleteVals.push("skill_stacks");
    }
    if (data?.iframe_code) {
      deleteVals.push("iframe_code");
    }
    deleteValues(payload, deleteVals);
    editMode ? await edit(id, payload) : await create(payload);
    setLoading(false);
  };
  const onCancel = () => currentStep === 1 && setCurrentStep(currentStep - 1);
  const onContinue = async () => {
    const _errors = await validate();
    if (_errors.size) return;
    if (currentStep === 1) {
      handleEditCreate();
      return;
    }
    setCurrentStep((c) => c + 1);
  };
  return (
    <DashboardLayout loading={initLoading}>
      <EditCreateHeaderCard
        current={currentStep}
        length={2}
        title={`${editMode ? "EDIT" : "CREATE A"} RESOURCE COLLECTION`}
        subtitles={STEPS}
        classes="mt-header"
      />
      <Card
        width="min(1300px,90%)"
        centered
        classes="mt-6 mb-4 mx-auto coulmn-center"
      >
        {currentStep === 0 && (
          <ResourceCollectionDetails
            data={data}
            errors={errors}
            onChange={onChange}
            step={currentStep}
            experts={experts}
            setExperts={setExperts}
          />
        )}
        {currentStep === 1 && (
          <>
            <h2 className="w-full text-center mb-8">
              {t("Associated Resources")}
            </h2>
            <AssociatedListing
              title={t("Browse Resources to add to your Resource Collection")}
              component="resources"
              tabs={["All Resource Modules"]}
              list={resources}
              setList={setResources}
              type={COMPONENT_TYPES.RESOURCE}
              onSearch={handleSearch} // Pass search handler
              isLoading={fetchResourceLoading}
            />
          </>
        )}
        <CreateFormFooter
          step={currentStep}
          stepsLength={STEPS?.length}
          onCancel={onCancel}
          disabled={!!errors.size}
          loading={loading}
          onOk={onContinue}
          editMode={editMode}
          backButtonColor="blue"
          okButtonColor="blue"
        />
      </Card>
    </DashboardLayout>
  );
};

export default CreateEditResourceCollection;
