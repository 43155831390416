import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { resolveResponse } from "store/api/utils";
import { serializeObject } from "store/api/utils";

const useAchievements = () => {
    const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

    const headers = {
        Authorization: "Bearer " + token,
    };
    //FETCHING LIST OF ACHIEVEMENTS
    const list = async (filters = {}) => {
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ACHIEVEMENT}?language=${language}&${serializeObject(filters)}`,
            {},
            headers,
            Function.prototype
        );
        if (res?.data?.data) {
            return res?.data?.data;
        }
    }

    //USER BASED ACHIVEVEMENTS LIST 
    const userBasedList = async (username, filters = {}) => {
        if (!username) return;
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ACHIEVEMENT}/${username}/${ENDPOINTS.LIST}?language=${language}&${serializeObject(filters)}`,
            {},
            headers,
            Function.prototype
        );
        if (res?.data?.data) {
            return res?.data?.data;
        }
    }

    //VIEW AN ACHIEVEMENT
    const view = async (certificate_id) => {
        if (!certificate_id) return;
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ACHIEVEMENT}/${certificate_id}?language=${language}`,
            {},
            headers,
            Function.prototype
        );
        if (res?.data?.data) {
            return res?.data?.data;
        }
    }

    //DOWNLOAD ACHIEVEMENT CERTIFICATE
    const download = async (certificate_id, format = "pdf") => {
        if (!certificate_id) return;
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ACHIEVEMENT}/${ENDPOINTS.DOWNLOAD}/prepr-${certificate_id}?language=${language}&format=${format}`,
            {},
            headers,
            Function.prototype
        );
        if (res?.data?.data) {
            return res?.data?.data;
        }
    }

    //SET ACHIEVEMENT AS FEATURED
    const setFeatured = async (id, remove = false) => {
        if (!id) return;
        const client = new REQUEST();
        const res = await client.post(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ACHIEVEMENT}/${id}/${ENDPOINTS.PIN}?language=${language}&is_featured=${remove ? "no" : "yes"}`,
            {},
            headers,
            resolveResponse
        );
        return res;
    }

    return {
        list,
        view,
        download,
        userBasedList,
        setFeatured
    }
}

export default useAchievements