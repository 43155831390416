import { COMPONENT_TYPES } from "helpers/utils/constants";
import { useNavigate } from "react-router-dom";

const useRedirect = () => {
  const navigate = useNavigate();

  //ORGS
  const navigateEditOrg = (slug, search = "") =>
    navigate(`/${COMPONENT_TYPES.ORGANIZATION}/update/${slug}${search}`);
  const navigateOrg = (id, isPrivate) =>
    navigate(
      `/${COMPONENT_TYPES.ORGANIZATION}/${id}${
        isPrivate ? "?private=true" : ""
      }`
    );
  const navigateManageOrgs = () =>
    navigate(`/manage/${COMPONENT_TYPES.ORGANIZATION}`);
  const navigateOrganizations = () =>
    navigate(`/${COMPONENT_TYPES.ORGANIZATION}`);
  const navigateOrgIntegrationSettings = (slug) =>
    navigate(`/${COMPONENT_TYPES.ORGANIZATION}/${slug}/integration-settings`);

  //LABS
  const navigateLab = (id, isPrivate) =>
    navigate(
      `/${COMPONENT_TYPES.LAB}/${id}${isPrivate ? "?private=true" : ""}`
    );
  const navigateManageLabs = () => navigate(`/manage/${COMPONENT_TYPES.LAB}`);
  const navigateCreateLab = () => navigate(`/${COMPONENT_TYPES.LAB}/create/`);
  const navigateCreateLabAI = () =>
    navigate(`/${COMPONENT_TYPES.LAB}/ai/create`);
  const navigateEditLab = (id) =>
    navigate(`/${COMPONENT_TYPES.LAB}/edit/${id}`);
  const navigateLabP = (id, isPrivate) =>
    navigate(
      `/${COMPONENT_TYPES.LAB_PROGRAMS}/${id}${
        isPrivate ? "?private=true" : ""
      }`
    );
  const navigateLabs = () => navigate(`/${COMPONENT_TYPES.LAB}`);
  const navigateLabPrograms = () =>
    navigate(`/${COMPONENT_TYPES.LAB_PROGRAMS}`);
  const navigateManageLabPrograms = () =>
    navigate(`/manage/${COMPONENT_TYPES.LAB_PROGRAMS}`);
  const navigateLabMarketplace = () =>
    navigate(`/${COMPONENT_TYPES.LAB_MARKETPLACE}`);
  const navigateLabMDetail = (id, isPrivate) =>
    navigate(
      `/${COMPONENT_TYPES.LAB_MARKETPLACE}/${id}${
        isPrivate ? "?private=true" : ""
      }`
    );
  const navigateChallengeTemplate = (id, source) =>
    navigate(
      `/${COMPONENT_TYPES.CHALLENGE_TEMPLATE}/${id}?source=${
        source || "marketplace"
      }`
    );

  //CHALLENGES
  const navigateChallenges = () => navigate(`/${COMPONENT_TYPES.CHALLENGE}`);
  const navigateChallengePaths = () => navigate("/challenge-path");
  const navigateChallenge = (
    id,
    isPrivate = false,
    tabIndex,
    isPreviewMode
  ) => {
    const params = new URLSearchParams();
    if (isPrivate) params.set("private", "true");
    if (tabIndex && tabIndex !== 0) params.set("tab", tabIndex);
    if (isPreviewMode) params.set("preview", "true");
    navigate(`/${COMPONENT_TYPES.CHALLENGE}/${id}?${params}`);
  };
  const navigateManageChallenges = () =>
    navigate(`/manage/${COMPONENT_TYPES.CHALLENGE}`);
  const navigateManageChallengePaths = () =>
    navigate(`/manage/${COMPONENT_TYPES.CHALLENGE_PATH}`);
  const navigateCreateChallenge = () =>
    navigate(`/${COMPONENT_TYPES.CHALLENGE}/create`);
  const navigateCreateChallengeAI = () =>
    navigate(`/${COMPONENT_TYPES.CHALLENGE}/ai/create`);
  const navigateEditChallenge = (id) =>
    navigate(`/${COMPONENT_TYPES.CHALLENGE}/edit/${id}`);
  const navigateAssessChallenge = (id) =>
    navigate(`/${COMPONENT_TYPES.CHALLENGE}/assessment/${id}`);
  const navigateCreateChallengePath = () =>
    navigate(`/${COMPONENT_TYPES.CHALLENGE_PATH}/create`);
  const navigateEditChallengePath = (id) =>
    navigate(`/${COMPONENT_TYPES.CHALLENGE_PATH}/edit/${id}`);
  const navigateAnnouncements = (id) => navigate(`/announcements/${id}`);
  const navigateWinnerSelection = (id) =>
    navigate(`/${COMPONENT_TYPES.CHALLENGE}/${id}/winner-selection`);

  //RESOURCES
  const navigateManageResources = () =>
    navigate(`/manage/${COMPONENT_TYPES.RESOURCE}`);
  const navigateResource = (id, isPrivate) =>
    navigate(
      `/${COMPONENT_TYPES.RESOURCE}/${id}${isPrivate ? "?private=true" : ""}`
    );
  const navigateResources = () => navigate(`/${COMPONENT_TYPES.RESOURCE}`);
  const navigateEditResource = (id, isGo1) =>
    navigate(
      `/${COMPONENT_TYPES.RESOURCE}/edit/${id}${isGo1 ? "?go1=true" : ""}`
    );
  const navigateCreateResourceModuleAI = () =>
    navigate("/resourceModule/ai/create");

  //RESOURCE COLLECTIONS
  const navigateManageResourceC = () =>
    navigate(`/manage/${COMPONENT_TYPES.RESOURCE_COLLECTION}`);
  const navigateResourceC = (id, isPrivate) =>
    navigate(
      `/${COMPONENT_TYPES.RESOURCE_COLLECTION}/${id}${
        isPrivate ? "?private=true" : ""
      }`
    );
  const navigateEditResourceC = (id) =>
    navigate(`/${COMPONENT_TYPES.RESOURCE_COLLECTION}/edit/${id}`);
  const navigateCreateResourceC = () =>
    navigate(`/${COMPONENT_TYPES.RESOURCE_COLLECTION}/create`);

  //RESOURCE GROUPS
  const navigateManageResourceG = () =>
    navigate(`/manage/${COMPONENT_TYPES.RESOURCE_GROUP}`);
  const navigateResourceG = (id, isPrivate) =>
    navigate(
      `/${COMPONENT_TYPES.RESOURCE_GROUP}/${id}${
        isPrivate ? "?private=true" : ""
      }`
    );
  const navigateEditResourceG = (id) =>
    navigate(`/${COMPONENT_TYPES.RESOURCE_GROUP}/edit/${id}`);
  const navigateCreateResourceG = () =>
    navigate(`/${COMPONENT_TYPES.RESOURCE_GROUP}/create`);

  //ACHIEVEMENTS
  const navigateAchievements = () => navigate("/achievements");
  const navigateAchievement = (id) => navigate(`/achievements/${id}`);

  //SKILLS
  const navigateSkill = (id) => navigate(`/${COMPONENT_TYPES.SKILL}/${id}`);

  //CHATS
  const navigateChats = (search = "") => navigate(`/chats${search}`);

  // PROFILE
  const navigateProfile = (id, tabIndex) => {
    const params = new URLSearchParams();
    if (tabIndex && tabIndex !== 0) params.set("tab", tabIndex);
    navigate(`/profile/${id}?${params}`);
  };
  const navigateSettings = () => navigate(`/settings`);
  const navigateAdvancedSearch = (term) => navigate(`/advanced-search/${term}`);
  const navigateChallengePath = (id, isPrivate) =>
    navigate(
      `/${COMPONENT_TYPES.CHALLENGE_PATH}/${id}${
        isPrivate ? "?private=true" : ""
      }`
    );
  const navigateBack = () => navigate(-1);
  const navigateLogin = () => navigate("/login");
  const navigateRegister = (queryString) =>
    navigate(`/register?${queryString}`);
  const navigateForgetPassword = () => navigate("/forgot-password");
  const navigateCollectUserData = () => navigate("/collect-user-data");
  const navigateCreateOrg = () => navigate("/organization/create");
  const navigateExplore = () => navigate("/explore");

  // PROJECTS
  const navigateProjects = (_e, filters = "") =>
    navigate(`/${COMPONENT_TYPES.PROJECT}${filters}`);
  const navigateCreateProject = (id) => {
    navigate(
      `/${COMPONENT_TYPES.PROJECT}/create${
        typeof id === "string" ? `?challenge_id=${id}` : ""
      }`
    );
  };
  const navigateEditProject = (id) =>
    navigate(`/${COMPONENT_TYPES.PROJECT}/edit/${id}`);
  const navigateProject = (id) => navigate(`/${COMPONENT_TYPES.PROJECT}/${id}`);
  const navigateChallengeProjectSubmission = (slug) =>
    navigate(`/${COMPONENT_TYPES.CHALLENGE}/${slug}/project-submissions`);
  //CREATE EDIT FORMS
  const navigateCreateResource = () =>
    navigate(`/${COMPONENT_TYPES.RESOURCE}/create`);
  const navigateCreateLabProgram = () =>
    navigate(`/${COMPONENT_TYPES.LAB_PROGRAMS}/create`);
  const navigateEditLP = (id) =>
    navigate(`/${COMPONENT_TYPES.LAB_PROGRAMS}/edit/${id}`);

  //MEMBER MANAGEMENT
  const navigateMemberManagement = (component, id) =>
    navigate(`/member-management/${component}/${id}`);

  // DASHBOARD
  const navigateDashboard = () => navigate(`/dashboard`);

  // LEADERSHIP
  const navigateLeaderboard = () => navigate(`/leaderboard`);

  //ERROR & MAINTENANCE
  const navigate404 = () => navigate("/error/404");

  //CAREERS
  const navigateCareers = () => navigate("/careers");
  const navigateJob = (id) => navigate(`/jobs/${id}`);

  // UPGRADE PLAN
  const navigateUpgradePlan = () => navigate("/upgrade-plan");

  // NOTIFICATIONS
  const navigateNotifications = () => navigate("/notifications");

  // TEAM MATCHING
  const navigateTeamMatching = () =>
    navigate(`/${COMPONENT_TYPES.TEAM_MATCHING}`);

  // TERMS AND PRIVACY POLICY
  const navigateTerms = () => navigate(`/${COMPONENT_TYPES.TERMS}`);
  const navigatePrivacyPolicy = () =>
    navigate(`/${COMPONENT_TYPES.PRIVACY_POLICY}`);

  // Reports
  const navigateLabReports = (id) =>
    navigate(`/${COMPONENT_TYPES.REPORT}/${COMPONENT_TYPES.LAB}/${id}`);
  const navigateOrganizationReports = (id) =>
    navigate(
      `/${COMPONENT_TYPES.REPORT}/${COMPONENT_TYPES.ORGANIZATION}/${id}`
    );
  const navigateChallengeReports = (id) =>
    navigate(`/${COMPONENT_TYPES.REPORT}/${COMPONENT_TYPES.CHALLENGE}/${id}`);

  return {
    navigateLab,
    navigateChallenge,
    navigateProject,
    navigateResource,
    navigateProfile,
    navigateOrg,
    navigateAdvancedSearch,
    navigateChallengePath,
    navigateCreateLabProgram,
    navigateBack,
    navigateEditOrg,
    navigateCreateLab,
    navigateCreateLabAI,
    navigateCreateChallenge,
    navigateCreateChallengeAI,
    navigateCreateResourceModuleAI,
    navigateCollectUserData,
    navigateLogin,
    navigateRegister,
    navigateForgetPassword,
    navigateCreateOrg,
    navigateCreateResource,
    navigateMemberManagement,
    navigate404,
    navigateManageLabs,
    navigateExplore,
    navigateEditLab,
    navigateManageOrgs,
    navigateLabP,
    navigateEditLP,
    navigateManageChallenges,
    navigateEditChallenge,
    navigateAssessChallenge,
    navigateChallenges,
    navigateCreateChallengePath,
    navigateEditChallengePath,
    navigateAnnouncements,
    navigateLabs,
    navigateLabPrograms,
    navigateManageLabPrograms,
    navigateManageChallengePaths,
    navigateWinnerSelection,
    navigateChallengePaths,
    navigateManageResources,
    navigateEditResource,
    navigateManageResourceC,
    navigateCreateResourceC,
    navigateEditResourceC,
    navigateResourceC,
    navigateManageResourceG,
    navigateCreateResourceG,
    navigateEditResourceG,
    navigateResourceG,
    navigateCreateProject,
    navigateAchievement,
    navigateAchievements,
    navigateSkill,
    navigateEditProject,
    navigateProjects,
    navigateDashboard,
    navigateLeaderboard,
    navigateLabMarketplace,
    navigateLabMDetail,
    navigateChallengeTemplate,
    navigateJob,
    navigateCareers,
    navigateUpgradePlan,
    navigateChats,
    navigateNotifications,
    navigateTeamMatching,
    navigateResources,
    navigateTerms,
    navigatePrivacyPolicy,
    navigateLabReports,
    navigateOrganizationReports,
    navigateChallengeReports,
    navigateOrganizations,
    navigateOrgIntegrationSettings,
    navigateSettings,
    navigateChallengeProjectSubmission,
  };
};

export default useRedirect;
