import React, { useEffect } from "react";
import { useState } from "react";
import WelcomePopup from "../components/WelcomePopup";
import useListingHeader from "helpers/hooks/useListingHeader";
import BrowseWrapper from "components/UI/organisms/BrowseWrapper";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import TeamsListing from "./TeamsListing";
import PendingRequestListing from "./PendingRequestListing";
import useProjects from "store/projects/service-hook";
import useAuth from "store/auth/service-hook";
import { loginToPreceed } from "helpers/utils/utilities";

const initialBrowseOptions = [
  {
    id: 1,
    title: `Browse`,
    pageTitle: "Team Matching",
    value: "browse",
  },
  {
    id: 2,
    title: "Pending Requests",
    pageTitle: "Team Matching",
    value: "pending",
  },
  {
    id: 3,
    title: "Matched",
    pageTitle: "Team Matching",
    value: "matched",
  },
];

const SORT_OPTIONS = [
  {
    value: "Name A to Z",
    key: "name-a-to-z",
  },
  {
    value: "Name Z to A",
    key: "name-z-to-a",
  },
  {
    value: "Created Date",
    key: "creation_date",
  },
  {
    value: "Relevance",
    key: "relevance",
  },
];

const BrowseTeams = ({ t = (d) => d }) => {
  const [welcomePopup, setWelcomePopup] = useState(false);
  const [browseMode, setBrowseMode] = useState(0);
  const state = useListingHeader(undefined, "due_date");
  const [list, setList] = useState();
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [completeOnboarding, setCompleteOnboarding] = useState(false);
  const [browseOptions, setBrowseOptions] = useState(initialBrowseOptions);
  const {
    teams,
    acceptDeclineJoinParticipateRequest,
    sendRequest,
    counts,
    teamMatchingProfileCheck,
  } = useProjects();
  const { selected } = useFilters();
  const {
    state: { user, isLoggedIn },
  } = useAuth();
  useEffect(() => {
    const abortController = new AbortController();
    fetchTeams(abortController);
    return () => abortController.abort();
  }, [browseMode, state.search, state.sort, page, selected]);

  useEffect(() => {
    if (!t) return;
    handleCounts();
  }, [t]);

  // useEffect(() => {
  //   if (isLoggedIn && !isTrue(user?.team_matching_profile_check))
  //     setWelcomePopup(true);
  // }, []);

  const handleCounts = async () => {
    const res = await counts();
    if (res)
      setBrowseOptions((prevOptions) =>
        prevOptions.map((option) => {
          if (option.id === 2) {
            return {
              ...option,
              title: `${t("Pending Requests")} (${res.pending_requests || 0})`,
            };
          }
          if (option.id === 3) {
            return {
              ...option,
              title: `${t("Matched")} (${res.matched_items || 0})`,
            };
          }
          return option;
        })
      );
  };

  const fetchTeams = async (abortController) => {
    if (!loading) setLoading(true);
    const type = browseOptions[browseMode]?.value || "browse";
    const { level_id, duration_id, skills, request_status, privacy } = selected;
    const filters = {
      search: state.search,
      sort_by_team: state.sort,
      page,
    };

    if (level_id) filters["level"] = level_id;
    if (duration_id) filters["duration"] = duration_id;
    if (skills) filters["skills"] = skills;
    if (request_status) filters["request_status"] = request_status;
    if (privacy) filters["privacy"] = privacy;

    const [_list, _total] = await teams(type, filters);
    if (!abortController.signal.aborted) {
      setList(_list || []);
      setPages(_total || 1);
      setLoading(false);
    }
  };

  const onCloseWelcomePopup = async () => {
    if (completeOnboarding) await teamMatchingProfileCheck();
    setWelcomePopup(false);
  };

  const handleJoinAcceptDecline = async (slug, email, isDecline) => {
    if (!isLoggedIn) {
      loginToPreceed();
      return;
    }
    await acceptDeclineJoinParticipateRequest(slug, email, Boolean(isDecline));
    const item = list.find((d) => d.slug === slug);
    item["invite_status"] = isDecline ? "declined" : "accepted";
    setList([...list]);
  };

  const handleSendRequest = async (slug) => {
    if (!isLoggedIn) {
      loginToPreceed();
      return;
    }
    await sendRequest(slug);
    const item = list.find((d) => d.slug === slug);
    item["request_send"] = "request_sent";
    setList([...list]);
  };

  return (
    <>
      <BrowseWrapper
        browseMode={browseMode}
        browseOptions={browseOptions}
        {...state}
        setBrowseMode={setBrowseMode}
        filters={[
          FILTER_TYPES.REQUEST_STATUS,
          FILTER_TYPES.DURATION,
          FILTER_TYPES.LEVEL,
          FILTER_TYPES.SKILLS,
        ]}
        noAction
        sortOptions={SORT_OPTIONS}
        noHeaderFooter
      >
        {browseMode === 1 ? (
          <PendingRequestListing
            gridType={state.gridType}
            loading={loading}
            page={page}
            total={pages}
            list={list}
            setPage={setPage}
            onAcceptDecline={handleJoinAcceptDecline}
            t={t}
          />
        ) : (
          <TeamsListing
            gridType={state.gridType}
            loading={loading}
            page={page}
            total={pages}
            list={list}
            setList={setList}
            onSendRequest={handleSendRequest}
            setPage={setPage}
            browseMode={browseMode}
            t={t}
          />
        )}
      </BrowseWrapper>
      <WelcomePopup
        onClose={onCloseWelcomePopup}
        setCompleted={setCompleteOnboarding}
        open={welcomePopup}
      />
    </>
  );
};

export default BrowseTeams;
