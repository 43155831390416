import React, { useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import ModalLayout from ".";
import { toast } from "react-toastify";
import { Stack } from "@mui/material";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

const ImageCropper = ({ image, open, onClose, onSubmit, aspect }) => {
  const [imgSrc, setImgSrc] = useState(null);
  const [crop, setCrop] = useState();
  const [croppedFile, setCroppedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const imageRef = useRef(null);
  const canvasRef = useRef(null);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (!image) return;
    const reader = new FileReader();
    reader.addEventListener("load", () => setImgSrc(reader.result));
    reader.readAsDataURL(image);
  }, [image]);

  const getCroppedImg = async (image, crop) => {
    const canvas = canvasRef.current;
    if (!crop || !canvas || !image) {
      return;
    }
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          toast.error("Cannot upload image. Try back later!");
          return;
        }
        const file = new File([blob], "cropped.jpg", { type: "image/jpeg" });
        resolve(file);
      }, "image/jpeg");
    });
  };

  const handleCropComplete = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageFile = await getCroppedImg(imageRef.current, crop);
      setCroppedFile(croppedImageFile);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!crop) await onSubmit(image);
    else await onSubmit(croppedFile);
    setLoading(false);
    onClose();
  };

  return (
    <ModalLayout
      data-testid="checkout-modal"
      open={open}
      onClose={onClose}
      width="min(98vw,600px)"
    >
      <h3 className="mb-1 opacity-80">{t("CROP YOUR IMAGE")}</h3>
      <ReactCrop
        crop={crop}
        onChange={(newCrop) => setCrop(newCrop)}
        onComplete={handleCropComplete}
        className="w-full"
        aspect={aspect}
      >
        {imgSrc && (
          <img
            src={imgSrc}
            ref={imageRef}
            alt="profile"
            className="w-full object-contain"
          />
        )}
      </ReactCrop>
      <canvas ref={canvasRef} style={{ display: "none" }} />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
        mb={1}
      >
        <Button bordered width="120px" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button classes="minw-120" loading={loading} onClick={handleSubmit}>
          {t("Save Image")}
        </Button>
      </Stack>
    </ModalLayout>
  );
};

export default ImageCropper;
