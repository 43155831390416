import { Grid } from "@mui/material";
import ComponentLists from "./ComponentLists";
import LabMemberProgress from "./LabMemberProgress";
import ProgressByResource from "../../components/ProgressByResource";
import useLabReport from "../../../../store/reports/lab/service-hooks";
import ProgressByChallenge from "../../components/ProgressByChallenge";
import LabEngagement from "./LabEngagement";
import useLabReportContext from "../hooks/useLabReportContext";
import LabMemberActivity from "./LabMemberActivity";
import AchievementDetails from "pages/report/components/AchievementDetails";
import EmptyText from "components/UI/atoms/EmptyText";
import EditIcon from "@mui/icons-material/Edit";
import useRedirect from "helpers/hooks/useRedirect";

const LabOverview = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const {
    labResourceModules,
    labChallengePaths,
    labChallenges,
    labResourceCollections,
    labResourceGroups,
    labAchievements,
  } = useLabReport();
  const { labSlug } = useLabReportContext();
  const { navigateEditLab } = useRedirect();

  return (
    <>
      <Grid container spacing={1} className={"ml-0"}>
        <Grid item xs={12} className={"pl-0 grid-lg"}>
          <ComponentLists t={t} />
          <ProgressByChallenge
            fetchChallenges={labChallenges}
            fetchChallengePaths={labChallengePaths}
            slug={labSlug}
            noDataUI={
              <EmptyText>
                {t(
                  "There is no Challenge or Challenge Path associated to this Lab, create now with 'Create' button from navigation bar"
                )}
              </EmptyText>
            }
            t={t}
          />
        </Grid>
        <Grid item xs={12} className={"grid-md"}>
          <AchievementDetails
            slug={labSlug}
            fetchAchievementDetails={labAchievements}
            noDataUi={
              <EmptyText
                buttonLabel={t("Edit", "common")}
                text={t(
                  "There is no achievement added to this Lab yet, go to Lab edit page to add it"
                )}
                icon={EditIcon}
                isButtonPresent
                onClick={() => navigateEditLab(labSlug)}
              />
            }
            t={t}
          />
          <LabMemberProgress t={t} />
          <ProgressByResource
            fetchResources={labResourceModules}
            fetchResourceCollections={labResourceCollections}
            fetchResourceGroups={labResourceGroups}
            slug={labSlug}
            noDataUI={
              <EmptyText>
                {t(
                  "There is no Resource associated to this Lab, create now with 'Create' button from navigation bar"
                )}
              </EmptyText>
            }
            t={t}
          />
        </Grid>
        <Grid item xs={12} className={"grid-md"}>
          <LabEngagement t={t} />
          <LabMemberActivity t={t} />
        </Grid>
      </Grid>
    </>
  );
};

export default LabOverview;
