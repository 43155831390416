import Card from "components/Cards/Card";
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import Button from "components/Button";
import { plansList } from "../components/upgradePlan/data";
import Divider from "components/Divider";

const OrganizationSubscription = ({
  slug,
  setActivePlan,
  activePlan,
  setStep,
  planDetails,
  t = (d) => d,
}) => {
  useEffect(() => {
    if (!slug) {
      setStep(0);
      return;
    }
    fetchPlanDetails();
  }, [slug]);

  const fetchPlanDetails = async () => {
    const res = await planDetails(slug);
    setActivePlan(res?.plan || "seed_plan_yearly");
  };
  const handleSelectPlan = () => {
    const instance = window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_STIE,
      publishableKey: process.env.REACT_APP_CHARGEBEE_KEY,
    });
    let cbPortal = instance.createChargebeePortal();

    cbPortal.open({
      close() {
        fetchPlanDetails();
      },
    });
  };

  const handleContactSales = () => {
    const anchor = document.createElement("a");
    anchor.href = "https://prepr.org/contact/sales/";
    anchor.target = "_blank"; 
    anchor.rel = "noopener noreferrer"; 
    anchor.click();
  }

  return (
    <div className="flex-column items-center justify-center my-8 mx-auto w-80p w-90p--mob">
      <div className="flex-column items-center text-center">
        <h2>{t("Choose A Plan For Your Organization")}</h2>
      </div>
      <Grid container spacing={2} className="flex justify-center mt-2">
        {plansList?.map((plan, i) => (
          <>
            <Grid key={i} item xs={12} className="flex justify-center">
              <PlanCard
                title={plan.title}
                description={plan.description}
                icon={plan.icon}
                price={plan.pricePerYearPerUser}
                active={activePlan === plan.id}
                id={plan?.id}
                isPopular={plan.isPopular}
                type={plan.type}
                totalUsers={plan?.totalUsers}
                Users={plan?.totalUsers}
                handleSelectPlan={handleSelectPlan}
                handleContactSales={handleContactSales}
                priceCustomization={plan?.price}
                trial={plan?.trial}
                totalPrice={plan?.totalPrice}
                t={t}
              />
            </Grid>
            {i < plansList.length - 1 && <Divider classes="w-full" />}
          </>
        ))}
      </Grid>
    </div>
  );
};

export default OrganizationSubscription;

export const PlanCard = ({
  title,
  description,
  price,
  type,
  icon,
  active,
  isPopular,
  handleSelectPlan,
  handleContactSales,
  totalUsers,
  priceCustomization,
  trial,
  totalPrice,
  t = (d) => d,
}) => {
  return (
    <Card
      classes={`w-full h-auto flex relative items-center py-5 justify-around text-center ${active && "border-primary"
        }`}
    >
      {isPopular && (
        <p className="absolute top-20 left-22 bg-purple c-white rounded-10 px-2 fs-12 py-1">
          {t("Most Popular")}
        </p>
      )}
      {type === "enterprise" && (
        <h2 className="text-center absolute top-0 py-2">
          {t("Want Customizations?")}
        </h2>
      )}
      <Grid container spacing={2}>
        <Grid item xs={3} className="flex items-center justify-center">
          <img src={icon} className="w-40 h-40 mr-2" alt={`icon of ${title}`} />
          <h3>{t(title, "common")}</h3>
        </Grid>
        <Grid item xs={3} className="flex-column items-left">
          <p
            className={`fw-700 ${type === "enterprise" ? "fs-17 mt-4" : "fs-30 mt-2"
              }`}
          >
            {type === "seed" ? (
              price
            ) : type === "enterprise" ? (
              priceCustomization
            ) : (
              <>
                <span className="fs-12">$</span>
                {price}
                <span className="fs-12">{t("/year/user")}</span>
              </>
            )}
          </p>
          <p className={`fs-12 ${type === "enterprise" ? "mt-4" : "mt-1"}`}>
            {type === "seed" ? (
              <>
                {t("includes")} <span className="fw-700 fs-16">{totalUsers}</span>{" "}
                {t("users")}
              </>
            ) : type === "enterprise" ? (
              <>
                <span className="fw-700 fs-16">{totalUsers}</span> {t("users")}
              </>
            ) : (
              <>
                {t("with purchase of")}{" "}
                <span className="fw-700 fs-16">{totalUsers}</span> {t("users")}
              </>
            )}
          </p>
          <p style={{ color: "#5B5D6B" }} className="mt-1">
            {totalPrice}
          </p>
        </Grid>
        <Grid item xs={3} className="flex-column items-left mt-6">
          <p className="fs-12 c-primary mb-1 fw-700">{t(trial)}</p>
          <p className="fs-12 c-black text-left">{t(description)}</p>
        </Grid>
        <Grid item xs={3} className="flex  items-center justify-center">
          <Button
            classes="fs-12 mt-1"
            bordered={!!active}
            color="blue"
            onClick={() => {
              if (type === 'enterprise') {
                handleContactSales("https://prepr.org/contact/sales/");
              } else {
                handleSelectPlan();
              }
            }}
            width="100px"
            disabled={active}
          >
            {t(active ? "Current Plan" : (type === 'enterprise' ? "Contact Sales" : "Start Trial"))}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};
