import {resolveResponse, serializeObject} from "../api/utils";
import REQUEST from "../api";
import {ENDPOINTS} from "../api/endpoints";
import {useSelector} from "react-redux";
import {REDUCER_TYPES} from "../index";

const useAdvanceSearch = () => {
    /**
     * REDUX STATE
     */
    const {language, token} = useSelector((state) => state[REDUCER_TYPES.AUTH]);

    /**
     * GLOBAL HEADERS
     * @type {{Authorization: string}}
     */
    const headers = {
        Authorization: "Bearer " + token,
    };


    const searchChallenges = async (keyword, filters = undefined) => {
        const params = {
            keyword,
            ...(filters ? filters : {})
        }

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.CHALLENGES}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    };

    const searchChallengePaths = async (keyword, filters = undefined) => {
        const params = {
            keyword,
            ...(filters ? filters : {})
        }

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.CHALLENGE_PATHS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    };

    const searchChallengeTemplates = async (keyword, filters = undefined) => {
        const params = {
            keyword,
            ...(filters ? filters : {})
        }

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.CHALLENGE_TEMPLATES}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    };

    const searchLabs = async (keyword, filters = undefined) => {
        const params = {
            keyword,
            ...(filters ? filters : {})
        }

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.LABS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    };

    const searchLabPrograms = async (keyword, filters = undefined) => {
        const params = {
            keyword,
            ...(filters ? filters : {})
        }

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.LAB_PROGRAMS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    };

    const searchLabMarketplace = async (keyword, filters = undefined) => {
        const params = {
            keyword,
            ...(filters ? filters : {})
        }

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.LAB_MARKETPLACES}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    };

    const searchResourceModules = async (keyword, filters = undefined) => {
        const params = {
            keyword,
            ...(filters ? filters : {})
        }

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.RESOURCE_MODULES}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    };


    const searchResourceGroups = async (keyword, filters = undefined) => {
        const params = {
            keyword,
            ...(filters ? filters : {})
        }

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.RESOURCE_GROUPS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    };


    const searchResourceCollection = async (keyword, filters = undefined) => {
        const params = {
            keyword,
            ...(filters ? filters : {})
        }

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.RESOURCE_COLLECTIONS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    };

    const searchProjects = async (keyword, filters = undefined) => {
        const params = {
            keyword,
            ...(filters ? filters : {})
        }

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.ADVANCE_SEARCH_PROJECTS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    };


    const searchUsers = async (keyword, filters = undefined) => {
        const params = {
            keyword,
            ...(filters ? filters : {})
        }

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.USERS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    };


    const searchOrganizations = async (keyword, filters = undefined) => {
        const params = {
            keyword,
            ...(filters ? filters : {})
        }

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.ADVANCE_SEARCH}/${ENDPOINTS.ORGANIZATIONS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    };

    return {
        searchResourceModules,
        searchChallengeTemplates,
        searchChallengePaths,
        searchChallenges,
        searchResourceCollection,
        searchResourceGroups,
        searchLabs,
        searchLabPrograms,
        searchLabMarketplace,
        searchUsers,
        searchProjects,
        searchOrganizations
    }
}


export default useAdvanceSearch
