import { render, waitFor, screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import { mockRequestGet, mockRequestPost } from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useUnified from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api";

let getSpy;
let postSpy;

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
    [REDUCER_TYPES.AUTH]: {
      language: "en",
      token: 1,
      user: { id: 1, email: "test@gmail.com", username: "test" },
    },
  });

  const UnifiedServicesMock = () => {
    const { listIntegrations,  listEmployees, inviteMembers } = useUnified();
  
    const BUTTON_LINKS = [
      { name: "listIntegrations", onClick: () => listIntegrations({}) },
      { name: "listEmployees", onClick: () => listEmployees({}) },
      { name: "inviteMembers", onClick: () => inviteMembers({}) },
    ];
  
    return (
      <div>
        {BUTTON_LINKS.map((item) => (
          <button data-testid={item.name} key={item.name} onClick={item.onClick}>
            Call {item.name}
          </button>
        ))}
      </div>
    );
  }; 
  
  describe("Unified services", () => {
    beforeEach(() => {
       render(
        <Provider store={store}>
          <BrowserRouter>
            < UnifiedServicesMock />
          </BrowserRouter>
        </Provider>
      );
      getSpy = mockRequestGet();
      postSpy = mockRequestPost();
    });
  
    test("listIntegrations", async () => {
      const button = screen.getByTestId("listIntegrations");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.MANAGE}/${ENDPOINTS.UNIFIED}/${ENDPOINTS.UNIFIED_INTEGRATIONS}?language=en&`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });
  
    test("listEmployees", async () => {
        const button = screen.getByTestId("listEmployees");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.UNIFIED}/${ENDPOINTS.UNIFIED_LIST_EMPLOYEES}?language=en&`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("inviteMembers", async () => {
        const button = screen.getByTestId("inviteMembers");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(postSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.UNIFIED}/${ENDPOINTS.UNIFIED_INVITE_MEMBER}?language=en&`,
            {language:"en"},
            {
                ...headers,
                "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
            },
            expect.any(Function)
          )
        );
      });  
  });  