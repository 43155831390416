import { AddBox, Delete } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import DropBox from "components/Input/DropBox";
import SecureImage from "components/UI/atoms/SecureImage";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import { createEvent } from "helpers/utils/utilities";
import React, { useMemo, useState } from "react";
import useMaster from "store/master/service-hook";
import { stepSchema } from "./data";
import { toast } from "react-toastify";

const Sponsers = ({
  step,
  data,
  errors,
  onChange,
  addedSponsers,
  setAddedSponsers,
  t = (d) => d,
}) => {
  const [newSponser, setNewSponser] = useState({});
  const [loading, setLoading] = useState(false);
  const { createSponser } = useMaster();
  const addedHosts = useMemo(() => {
    return [
      ...(data?.host_id?.map((item) => item?.data) ?? []),
      ...addedSponsers,
    ];
  }, [data?.host_id, addedSponsers]);

  const handleRemoveSponser = (id) => {
    if (addedSponsers.some((sponsor) => sponsor.id === id)) {
      const updatedSponsors = addedSponsers.filter(
        (sponsor) => sponsor.id !== id
      );
      setAddedSponsers(updatedSponsors);
    }
    const filteredSelectedOptions = data?.host_id?.filter(
      (option) => option?.key !== id
    );
    onChange(createEvent("host_id", filteredSelectedOptions));
  };

  const onChangeNewSponser = (e) => {
    const { name, value } = e.target;
    newSponser[name] = value;
    setNewSponser({ ...newSponser });
  };

  const handleAddSponser = async () => {
    const { title, link, image } = newSponser;
    if (!image || !title || !link) {
      toast.error(
        t("Please enter image, name and website link for the sponsors!")
      );
      return;
    }
    setLoading(true);
    const { data, error } = await createSponser(title, link, image);
    setLoading(false);
    if (!error) {
      setAddedSponsers([...addedSponsers, data?.data]);
      setNewSponser({ title: "", image: null, link: "" });
    }
  };

  return (
    <>
      <SchemaUIExtractor
        schema={stepSchema(step).slice(11, 12)}
        data={data}
        errors={errors}
        onChange={onChange}
      />
      <h3 className="mt-3">{t("Add New Sponsor")}</h3>
      <Grid container spacing={2} mt={1} mb={2}>
        <Grid xs={12} lg={3} item>
          <DropBox
            width="90%"
            classes=""
            file={newSponser?.image}
            setFile={(file) => onChangeNewSponser(createEvent("image", file))}
          />
        </Grid>
        <Grid xs={12} lg={9} item>
          <Input
            title="Website Link (Recommended)"
            name="link"
            width="100%"
            classes=" w-full py-2"
            value={newSponser?.link}
            onChange={onChangeNewSponser}
            t={t}
          />
          <Input
            title="Sponsor Name (Recommended)"
            name="title"
            width="100%"
            classes="w-full"
            value={newSponser?.title}
            onChange={onChangeNewSponser}
            t={t}
          />
          <Button
            loading={loading}
            width="150px"
            height="44px"
            classes="mt-3"
            onClick={handleAddSponser}
          >
            <AddBox />
            &nbsp;&nbsp;{t("Add Sponsor")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <h3 className="mt-3">{t("Added Sponsors")}</h3>
          {addedHosts.map((d) => (
            <div className="flex mt-2" key={d?.id}>
              <SecureImage
                src={d?.image}
                alt="sponser"
                height={100}
                width={220}
                classes="object-cover"
              />
              <div className="flex-1 ml-4">
                <p className="fw-600">{d?.title}</p>
                <p className="fs-14 opacity-60">{d?.link}</p>
                <Button
                  color="red"
                  bordered
                  height={18}
                  width={100}
                  classes="fs-14 mt-2"
                  onClick={() => handleRemoveSponser(d?.id)}
                >
                  <Delete fontSize="small" /> {t("Remove")}
                </Button>
              </div>
            </div>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default Sponsers;
