import React from "react";
import styles from "./button.module.scss";
import Spin from "components/Spin";
import { COLORS } from "helpers/utils/constants";
import { Button as Btn } from "@mui/material";

const Button = ({
  children,
  onClick,
  top,
  color,
  classes,
  height,
  width,
  bordered,
  gray,
  disabled,
  loading,
  hide,
  background,
  white,
}) => {
  const colors = {
    blue: COLORS.BLUE,
    orange: COLORS.ORANGE,
    green: COLORS.GREEN,
    red: COLORS.RED,
    gray: COLORS.GRAY,
    yellow: COLORS.YELLOW,
    white: COLORS.WHITE,
    blueOpaque: COLORS.BLUE_OPAQUE,
  };

  const btn_color = colors[color] || "#498cce";

  if (!!hide) return null;
  return (
    <Btn
      data-testid="button"
      style={{
        marginTop: top,
        backgroundColor: bordered
          ? background || "transparent"
          : disabled
          ? COLORS.GRAY
          : btn_color,
        height: !!height && height,
        width: !!width && width,
        border:
          !!bordered &&
          `1.5px solid ${
            disabled ? COLORS.GRAY : !gray ? btn_color : "lightgray"
          }`,
        color: !!white
          ? "#0A2B77"
          : !!gray
          ? "lightgray"
          : bordered
          ? disabled
            ? COLORS.GRAY
            : btn_color
          : "#ffffff",
        opacity: disabled && 0.8,
        pointerEvents: disabled && "none",
      }}
      className={`${styles.button} ${classes}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {!!loading ? (
        <Spin
          red={color === "red" && !!bordered}
          orange={color === "orange" && !!bordered}
          green={color === "green" && !!bordered}
          blue={!!bordered}
        />
      ) : (
        children
      )}
    </Btn>
  );
};

export default Button;
