import { SCHEMA_TYPES, TYPE } from "helpers/utils/constants";
import {
  NUMBER_VALIDATOR,
  REQUIRED_VALIDATOR,
  TITLE_VALIDATOR,
} from "helpers/utils/validators";
import {
  achievementSlice,
  achievement_schema,
} from "components/UI/molecules/AddAchievementSection";

export const create_lab_steps = [
  "Lab Details",
  "Achievements",
  "Associated Challenges",
  "Associated Resources",
  "Campus Connect",
  "Airmeet Integration",
];

export const LIVE_EVENT_SCHEMA = [
  {
    title: "Enable live event",
    label:
      "Enable this option and enter the event link. Your Lab participants are able to access the event directly from Lab detail page.",
    name: "is_live_event_enabled",
    type: SCHEMA_TYPES.SWITCH,
    lg: 12,
  },
  {
    title: "Live event url",
    name: "live_event_url",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
];

export const CAMPUS_CONNECT_SCHEMA = [
  {
    title: "Campus Connect Integration",
    label: "Enabled",
    name: "campus_connect",
    type: SCHEMA_TYPES.SWITCH,
    lg: 12,
  },
  {
    title: "How do you want to integrate this lab with Campus Connect?",
    name: "integrate_campus_connect",
    type: SCHEMA_TYPES.RADIO,
    options: [
      { title: "Post a job", key: "job" },
      { title: "Post a Story", key: "story" },
      { title: "Post Both", key: "both" },
    ],
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Job Title",
    name: "campus_connect_job_title",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Job Type",
    name: "campus_connect_job_type",
    type: SCHEMA_TYPES.SELECT,
    placeholder: "Select job type",
    options: [
      "Applied Research Project",
      "Apprenticeship",
      "Boot Camp",
      "Business Case",
      "Classroom Project",
      "Co-operative Education",
      "Community-Based Project",
      "Contract",
      "Entrepreneurship",
      "Field Placement",
      "Full-Time Job",
      "Hackathon",
      "Internship",
      "Mandatory Professional Practicum/Clinical Placement",
      "Micro-Placement",
      "Ontario Youth Apprenticeship Program (OYAP)",
      "Other",
      "Part-Time Job",
      "Project",
      "Seasonal",
      "Service Learning",
      "Start-Up",
      "Summer Job",
      "Virtual Internship",
      "Volunteer",
      "Work Experience",
    ].map((item) => ({ key: item, value: item })),
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Number Of Openings",
    name: "campus_connect_no_of_position",
    type: SCHEMA_TYPES.INPUT,
    validator: NUMBER_VALIDATOR,
    required: true,
  },
  {
    title: "Working Hours Per Week",
    name: "campus_connect_hours_per_week",
    type: SCHEMA_TYPES.INPUT,
    validator: NUMBER_VALIDATOR,
    required: true,
  },
  {
    title: "What’s the wage for this job?",
    name: "campus_connect_salary_amount",
    placeholder: "Enter wage in Canadian dollars",
    type: SCHEMA_TYPES.INPUT,
    validator: NUMBER_VALIDATOR,
    required: true,
  },
  {
    title: "Payroll Frequency",
    name: "campus_connect_salary_payment_frequency",
    placeholder: "Select payroll frequency",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { value: "Hourly", key: "zero" },
      { value: "Daily", key: "one" },
      { value: "Weekly", key: "two" },
      { value: "Monthly", key: "three" },
    ],
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Province",
    name: "campus_connect_province",
    placeholder: "Select job location",
    type: SCHEMA_TYPES.SELECT,
    options: [],
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "City",
    name: "campus_connect_city",
    placeholder: "Select City",
    type: SCHEMA_TYPES.SELECT,
    options: [],
    disabled: true,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Job Description",
    name: "campus_connect_description",
    type: SCHEMA_TYPES.EDITOR,
    placeholder: "Enter detailed job description here",
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Education level",
    placeholder: "Select education level requirement",
    name: "campus_connect_education_level",
    type: SCHEMA_TYPES.SELECT,
    options: [
      {
        value: "High School Diploma or Equivalent",
        key: "High School Diploma or Equivalent",
      },
      { value: "Diploma or Certificates", key: "Diploma or Certificates" },
      { value: "Bachelor's Degree", key: "Bachelor's Degree" },
      { value: "PhD", key: "PhD" },
      { value: "Other Professional Degree", key: "Other Professional Degree" },
    ],
  },
  {
    title: "Year of Study",
    name: "campus_connect_education_study_years",
    placeholder: "Select year of study requirement",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { value: "1st year", key: "1st year" },
      { value: "2nd year", key: "2nd year" },
      { value: "3rd year", key: "3rd year" },
      { value: "4th year", key: "4th year" },
      { value: "5th year", key: "5th year" },
      { value: "6+ year", key: "6+ year" },
    ],
  },
  {
    title: "Field of Study",
    placeholder: "Select field of study requirement",
    name: "campus_connect_education_subject_areas",
    type: SCHEMA_TYPES.SELECT,
    options: [
      {
        value: "Education, Recreational and Counselling Service",
        key: "Education, Recreational and Counselling Service",
      },
      { value: "Fine and Applied Arts", key: "Fine and Applied Arts" },
      {
        value: "Humanities and Related Fields",
        key: "Humanities and Related Fields",
      },
      {
        value: "Social Sciences and Related Fields",
        key: "Social Sciences and Related Fields",
      },
      {
        value: "Commerce, Management and Business Administration",
        key: "Commerce, Management and Business Administration",
      },
      {
        value: "Agricultural, Biological, Nutritional, and Food Science",
        key: "Agricultural, Biological, Nutritional, and Food Science",
      },
      {
        value: "Engineering and Applied Sciences",
        key: "Engineering and Applied Sciences",
      },
      {
        value: "Applied Science Technologies and Trades",
        key: "Applied Science Technologies and Trades",
      },
      {
        value: "Health Professions and Related Technologies",
        key: "Health Professions and Related Technologies",
      },
      {
        value: "Mathematics, Computer and Physical Sciences",
        key: "Mathematics, Computer and Physical Sciences",
      },
    ],
  },
  {
    title: "Qualification Details",
    name: "campus_connect_qualification",
    placeholder: "Enter detailed job qualification here",
    type: SCHEMA_TYPES.EDITOR,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Application Deadline",
    name: "campus_connect_deadline",
    type: SCHEMA_TYPES.DATE,
    validator: REQUIRED_VALIDATOR,
    disablePast: true,
  },
  {
    title: "Communication Preference",
    name: "campus_connect_preferred_response",
    placeholder: "Select how to communicate with applicants",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { value: "Via Employer Website", key: "Via Employer Website" },
      { value: "Via Email", key: "Via Email" },
    ],
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Email Address",
    name: "campus_connect_application_email",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Target Schools",
    name: "campus_connect_schools",
    placeholder: "Enter list of schools this job is visible to",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Application Instruction",
    name: "campus_connect_application_instructions",
    placeholder: "Enter detailed application instructions(optional)",
    type: SCHEMA_TYPES.EDITOR,
    lg: 12,
  },
  {
    title: "Story Title",
    name: "campus_connect_story_title",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Body Text",
    name: "campus_connect_story_body",
    type: SCHEMA_TYPES.TEXTBOX,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "",
    name: "campus_connect_story_media_type",
    type: SCHEMA_TYPES.RADIO,
    options: [
      { title: "Add Image", key: "IMAGE" },
      { title: "Add Video", key: "VIDEO" },
    ],
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Upload Banner Image",
    name: "campus_connect_story_image",
    type: SCHEMA_TYPES.FILE,
    description: "The dimension needs to be 735 x 415 pixels.",
    dropboxProps: {
      width: "95%",
      dimensions: [735, 415],
    },
    lg: 12,
  },
  {
    title: "Image Title",
    name: "campus_connect_story_image_title",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Image Description",
    name: "campus_connect_story_image_description",
    type: SCHEMA_TYPES.TEXTBOX,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Youtube URL",
    name: "campus_connect_story_video_youtube_url",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
];

export const CAMPUS_CONNECT_SCHEMA_V2 = [
  {
    title: "Campus Connect Integration",
    label: "Enabled",
    name: "campus_connect",
    type: SCHEMA_TYPES.SWITCH,
    lg: 12,
  },
  {
    name: "integrate_campus_connect",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { value: "No", key: "no" },
      { value: "Post a Opportunity", key: "job" },
      { value: "Post a Story", key: "story" },
      { value: "Post to Opportunity and Story", key: "both" },
    ],
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Job Title",
    name: "campus_connect_job_title",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Job Type",
    name: "campus_connect_job_type",
    type: SCHEMA_TYPES.SELECT,
    placeholder: "Select job type",
    options: [
      "Applied Research Project",
      "Apprenticeship",
      "Boot Camp",
      "Business Case",
      "Classroom Project",
      "Co-operative Education",
      "Community-Based Project",
      "Contract",
      "Entrepreneurship",
      "Field Placement",
      "Full-Time Job",
      "Hackathon",
      "Internship",
      "Mandatory Professional Practicum/Clinical Placement",
      "Micro-Placement",
      "Ontario Youth Apprenticeship Program (OYAP)",
      "Other",
      "Part-Time Job",
      "Project",
      "Seasonal",
      "Service Learning",
      "Start-Up",
      "Summer Job",
      "Virtual Internship",
      "Volunteer",
      "Work Experience",
    ].map((item) => ({ key: item, value: item })),
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Number Of Openings",
    name: "campus_connect_no_of_position",
    type: SCHEMA_TYPES.INPUT,
    validator: NUMBER_VALIDATOR,
  },
  {
    title: "Working Hours Per Week",
    name: "campus_connect_hours_per_week",
    type: SCHEMA_TYPES.INPUT,
    validator: NUMBER_VALIDATOR,
  },
  {
    title: "What’s the wage for this job?",
    name: "campus_connect_salary_amount",
    placeholder: "Enter wage in Canadian dollars",
    type: SCHEMA_TYPES.INPUT,
    validator: NUMBER_VALIDATOR,
  },
  {
    title: "Payroll Frequency",
    name: "campus_connect_salary_payment_frequency",
    placeholder: "Select payroll frequency",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { value: "Hourly", key: "zero" },
      { value: "Daily", key: "one" },
      { value: "Weekly", key: "two" },
      { value: "Monthly", key: "three" },
    ],
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Province",
    name: "campus_connect_province",
    placeholder: "Select job location",
    type: SCHEMA_TYPES.SELECT,
    options: [],
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "City",
    name: "campus_connect_city",
    placeholder: "Select City",
    type: SCHEMA_TYPES.SELECT,
    options: [],
    disabled: true,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Job Description",
    name: "campus_connect_description",
    type: SCHEMA_TYPES.TEXTBOX,
    placeholder: "Enter detailed job description here",
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Education level",
    placeholder: "Select education level requirement",
    name: "campus_connect_education_level",
    type: SCHEMA_TYPES.SELECT,
    options: [
      {
        value: "High School Diploma or Equivalent",
        key: "High School Diploma or Equivalent",
      },
      { value: "Diploma or Certificates", key: "Diploma or Certificates" },
      { value: "Bachelor's Degree", key: "Bachelor's Degree" },
      { value: "PhD", key: "PhD" },
      { value: "Other Professional Degree", key: "Other Professional Degree" },
    ],
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Year of Study",
    name: "campus_connect_education_study_years",
    placeholder: "Select year of study requirement",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { value: "1st year", key: "1st year" },
      { value: "2nd year", key: "2nd year" },
      { value: "3rd year", key: "3rd year" },
      { value: "4th year", key: "4th year" },
      { value: "5th year", key: "5th year" },
      { value: "6+ year", key: "6+ year" },
    ],
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Field of Study",
    placeholder: "Select field of study requirement",
    name: "campus_connect_education_subject_areas",
    type: SCHEMA_TYPES.SELECT,
    options: [
      {
        value: "Education, Recreational and Counselling Service",
        key: "Education, Recreational and Counselling Service",
      },
      { value: "Fine and Applied Arts", key: "Fine and Applied Arts" },
      {
        value: "Humanities and Related Fields",
        key: "Humanities and Related Fields",
      },
      {
        value: "Social Sciences and Related Fields",
        key: "Social Sciences and Related Fields",
      },
      {
        value: "Commerce, Management and Business Administration",
        key: "Commerce, Management and Business Administration",
      },
      {
        value: "Agricultural, Biological, Nutritional, and Food Science",
        key: "Agricultural, Biological, Nutritional, and Food Science",
      },
      {
        value: "Engineering and Applied Sciences",
        key: "Engineering and Applied Sciences",
      },
      {
        value: "Applied Science Technologies and Trades",
        key: "Applied Science Technologies and Trades",
      },
      {
        value: "Health Professions and Related Technologies",
        key: "Health Professions and Related Technologies",
      },
      {
        value: "Mathematics, Computer and Physical Sciences",
        key: "Mathematics, Computer and Physical Sciences",
      },
    ],
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Qualification Details",
    name: "campus_connect_qualification",
    placeholder: "Enter detailed job qualification here",
    type: SCHEMA_TYPES.TEXTBOX,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Application Deadline",
    name: "campus_connect_deadline",
    type: SCHEMA_TYPES.DATE,
    validator: REQUIRED_VALIDATOR,
    disablePast: true,
  },
  {
    title: "Communication Preference",
    name: "campus_connect_preferred_response",
    placeholder: "Select how to communicate with applicants",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { value: "Via Employer Website", key: "Via Employer Website" },
      { value: "Via Email", key: "Via Email" },
    ],
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Email Address",
    name: "campus_connect_application_email",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Target Schools",
    name: "campus_connect_schools",
    placeholder: "Enter list of schools this job is visible to",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Application Instruction",
    name: "campus_connect_application_instructions",
    placeholder: "Enter detailed application instructions(optional)",
    type: SCHEMA_TYPES.TEXTBOX,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Story Title",
    name: "campus_connect_story_title",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Body Text",
    name: "campus_connect_story_body",
    type: SCHEMA_TYPES.TEXTBOX,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "",
    name: "campus_connect_story_media_type",
    type: SCHEMA_TYPES.RADIO,
    options: [
      { title: "Add Image", key: "IMAGE" },
      { title: "Add Video", key: "VIDEO" },
    ],
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Upload Banner Image",
    name: "campus_connect_story_image",
    type: SCHEMA_TYPES.FILE,
    description: "The dimension needs to be 735 x 415 pixels.",
    dropboxProps: {
      width: "95%",
      dimensions: [735, 415],
    },
    lg: 12,
  },
  {
    title: "Image Title",
    name: "campus_connect_story_image_title",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Image Description",
    name: "campus_connect_story_image_description",
    type: SCHEMA_TYPES.TEXTBOX,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Youtube URL",
    name: "campus_connect_story_video_youtube_url",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
];

export const schema = [
  {
    name: "cover_image",
    type: SCHEMA_TYPES.FILE,
    title: "Upload Image",
    description: "The dimension needs to be 625 x 355 pixels",
    dropboxProps: {
      width: "95%",
      fileType: "image",
      video_code: "iframe_code",
      height: "300px",
      dimensions: [625, 355],
    },
    lg: 12,
  },
  {
    title: "Lab Title",
    name: "title",
    type: SCHEMA_TYPES.INPUT,
    lg: 12,
    validator: TITLE_VALIDATOR,
  },
  {
    title: "Description",
    errorTitle: "Description",
    name: "description",
    type: SCHEMA_TYPES.EDITOR,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Level",
    name: "level_id",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Duration",
    name: "duration_id",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Category",
    name: "category_id",
    type: SCHEMA_TYPES.MASTER,
    filters: {
      component: "lab",
    },
  },
  {
    title: "Mode",
    name: "mode",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { value: "Team", key: "team" },
      { value: "Individual", key: "individual" },
    ],
    multi: true,
  },
  {
    title: "Location",
    name: "location",
    type: SCHEMA_TYPES.GOOGLE_PLACES,
  },
  {
    title: "Lab Programs",
    name: "lab_programs",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
  },
  {
    title: "Type",
    name: "type",
    type: SCHEMA_TYPES.SELECT,
    options: TYPE,
    multi: true,
  },
  {
    title: "Lab Privacy",
    type: SCHEMA_TYPES.RADIO,
    options: [
      { title: "Public", key: "no" },
      { title: "Private", key: "yes" },
    ],
    name: "privacy",
  },
  {
    title: "Lab Skills",
    name: "skills",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
    multi: true,
  },
  {
    title: "Skill Sets",
    name: "skill_stacks",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
  },
  {
    title: "Skill Groups",
    name: "skill_groups",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
  },
  {
    name: "externalLinks",
  },
  {
    title: "Notify participants of Lab updates",
    name: "is_notification_enabled",
    type: SCHEMA_TYPES.RADIO,
    options: [
      { title: "Send email", key: "yes" },
      { title: "Do not send email", key: "no" },
    ],
    lg: 12,
  },
  ...achievement_schema(false, true, true),
  ...LIVE_EVENT_SCHEMA,
  ...CAMPUS_CONNECT_SCHEMA,
];
export const getSchemaByStep = (step) => {
  switch (step) {
    case 0:
      return [
        0,
        schema.findIndex((d) => d.name === "is_notification_enabled") + 1,
      ];
    case 1:
      return achievementSlice(schema);
    case 6:
      return [
        schema.findIndex((d) => d.name === "users"),
        schema.findIndex((d) => d.name === "unpaid") + 1,
      ];
    case 4:
      return [schema.findIndex((d) => d.name.includes("campus_connect"))];
    case 5:
      return [
        schema.findIndex((d) => d.name === "is_live_event_enabled"),
        schema.findIndex((d) => d.name === "live_event_url") + 1,
      ];
    default:
      return [0, 0];
  }
};

export const stepSchema = (step) => {
  return schema.slice(...getSchemaByStep(step));
};
