import React, { useEffect, useMemo, useState } from "react";
import { ChartColors, ComponentMappings } from "../../data";
import Card from "components/Cards/Card";
import { Grid } from "@mui/material";
import Select from "components/Input/Select";
import SearchableSelect from "components/Input/SearchableSelect";
import DoughnutChartCard from "pages/report/components/chart/DoughnutChartCard";
import { formattedCount } from "../../../../helpers/utils/utilities";
import useOrganizationReport from "store/reports/organization/service-hook";
import useLabReport from "store/reports/lab/service-hooks";
import EmptyText from "components/UI/atoms/EmptyText";
import useOrganizationReportContext from "../hooks/useOrganizationReportContext";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import Button from "components/Button";
import {REPORT_PER_PAGE} from "helpers/utils/constants";

const ProgressByLabProgram = ({ slug, t = (d) => d }) => {
  const { organizationLabs, organizationLabPrograms } = useOrganizationReport();
  const { organizationDetails } = useOrganizationReportContext();
  const { labMemberProgress, labProgramsMemberProgress } = useLabReport();
  const { navigateMemberManagement, navigateLabReports } = useRedirect();

  const [activeState, setActiveState] = useState(ComponentMappings?.all_labs);
  const [components] = useState([
    {
      value: t("Lab", "common"),
      key: ComponentMappings?.all_labs,
    },
    {
      value: t("Lab Program", "common"),
      key: ComponentMappings?.all_lab_programs,
    },
  ]);

  const [chartData, setChartData] = useState();
  const [loadingChallenges, setLoadingChallenges] = useState(true);
  const [loadingChartData, setLoadingChartData] = useState(false);
  const [activeLab, setActiveLab] = useState();
  const [activeLabSlug, setActiveLabSlug] = useState();

  /**
   *
   * @param search
   * @returns {Promise<*|*[]>}
   */
  const loadLabs = async (search = "") => {
    if (slug && activeState === ComponentMappings?.all_labs) {
      const response = await organizationLabs(slug, 1, REPORT_PER_PAGE, search);
      //console.log(response)
      if (response?.data?.length) {
        const first = response?.data[0];
        if (first && !activeLabSlug) {
          setActiveLab({
            key: first?.slug,
            value: first?.title,
          });
          setActiveLabSlug(first?.slug);
        }
      }
      setLoadingChallenges(false);
      return (
        response?.data?.map((item) => {
          return {
            id: item?.slug,
            title: item?.title,
          };
        }) ?? []
      );
    }
  };

  /**
   *
   * @param search
   * @returns {Promise<*|*[]>}
   */
  const loadChallengePaths = async (search = "") => {
    if (slug && activeState === ComponentMappings?.all_lab_programs) {
      const response = await organizationLabPrograms(slug, 1, 10 ,search);
      if (response?.data?.length) {
        const first = response?.data[0];
        if (first && !activeLabSlug) {
          setActiveLab({
            key: first?.slug,
            value: first?.title,
          });
          setActiveLabSlug(first?.slug);
        }
      }
      setLoadingChallenges(false);
      return (
        response?.data?.map((item) => {
          return {
            id: item?.slug,
            title: item?.title,
          };
        }) ?? []
      );
    }
  };

  /**
   *
   * @returns {Promise<void>}
   */
  const fetchChartData = async () => {
    const functionMap = {
      [ComponentMappings?.all_labs]: labMemberProgress,
      [ComponentMappings?.all_lab_programs]: labProgramsMemberProgress,
    };
    setLoadingChartData(true);
    if (activeLabSlug) {
      const response = await functionMap[activeState](activeLabSlug);
      if (response) {
        setChartData(response);
      }
    }
    setLoadingChartData(false);
  };

  /**
   * MEMORIZED DATA
   * @type {unknown}
   */
  const ChartData = useMemo(() => {
    if (chartData) {
      const labels = ["Complete", "In progress", "Not Started"];
      const statsData = [
        chartData?.completed ?? 0,
        chartData?.in_progress ?? 0,
        chartData?.not_started ?? 0,
      ];
      return {
        labels: labels,
        datasets: [
          {
            label: t("Member progress"),
            data: statsData,
            backgroundColor: ChartColors.labs,
          },
        ],
      };
    }
    return null;
  }, [chartData]);

  const legendData = useMemo(() => {
    return {
      data: [
        {
          color: ChartColors.labs[0],
          title: "Complete",
          count: chartData?.completed ?? 0,
        },
        {
          color: ChartColors.labs[1],
          title: "In Progress",
          count: chartData?.in_progress ?? 0,
        },
        {
          color: ChartColors.labs[2],
          title: "Not Started",
          count: chartData?.not_started ?? 0,
        },
        {
          title: "Total Members",
          count:
            (chartData?.completed ?? 0) +
            (chartData?.in_progress ?? 0) +
            (chartData?.not_started ?? 0),
        },
      ],
      total:
        (chartData?.completed ?? 0) +
        (chartData?.in_progress ?? 0) +
        (chartData?.not_started ?? 0),
    };
  }, [chartData]);

  /**
   * MOUNTED STATE
   */
  useEffect(() => {
    fetchChartData();
  }, [activeLabSlug]);

  /**
   * LOAD INITIAL CHALLENGES
   */
  useEffect(() => {
    loadLabs();
  }, []);
  return (
    <>
      <Card>
        <h4 className="bold fs-16 mb-4">
          {t("Progress by Lab / Lab Program")}
        </h4>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Select
              borderedIcon
              value={activeState}
              onChange={(e) => {
                setActiveState(e.target.value);
                setActiveLab(null);
                setActiveLabSlug(null);
                setChartData(null);
                setLoadingChallenges(true);
              }}
              keyValues={components}
              width={"100%"}
              height={40}
              t={t}
            />
          </Grid>
          <Grid item xs={7}>
            {ComponentMappings?.all_labs === activeState ? (
              <SearchableSelect
                key={activeLabSlug}
                width={"100%"}
                value={activeLab}
                placeholder={"Search for an option"}
                onChange={(event) => {
                  setActiveLab(event?.target?.value);
                  setActiveLabSlug(event?.target?.value?.key);
                }}
                fetch={[loadLabs, ""]}
                t={t}
              />
            ) : (
              ""
            )}
            {ComponentMappings?.all_lab_programs === activeState ? (
              <SearchableSelect
                width={"100%"}
                value={activeLab}
                placeholder={"Select option"}
                onChange={(event) => {
                  setActiveLab(event?.target?.value);
                  setActiveLabSlug(event?.target?.value?.key);
                }}
                fetch={[loadChallengePaths, ""]}
                t={t}
              />
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={12}>
            <DoughnutChartCard
              noCard
              chartId={"lab-member-progress"}
              data={ChartData ? ChartData : null}
              isLoading={loadingChartData || loadingChallenges}
              noDataUI={
                organizationDetails?.members_count > 0 ? (
                  <EmptyText
                    isButtonPresent={true}
                    buttonLabel="Manage Members"
                    icon={ManageAccountsIcon}
                    onClick={() =>
                      navigateMemberManagement(
                        COMPONENT_TYPES.ORGANIZATION,
                        slug
                      )
                    }
                  >
                    {t("There is no member in this Lab now")}
                  </EmptyText>
                ) : (
                  <EmptyText>
                    {t(
                      "There is no Lab associated to this Organization, create now with 'Create' button from navigation bar"
                    )}
                  </EmptyText>
                )
              }
              legend={
                <Grid container spacing={1}>
                  <Grid item xs={6}></Grid>
                  <ul
                    className={"flex flex-column w-full fs-12 pl-1"}
                    style={{ gap: "5px" }}
                  >
                    {legendData?.data?.map((item) => (
                      <li
                        key={item?.title}
                        className={"flex w-full items-center"}
                      >
                        <div className={"flex items-center"}>
                          <div
                            className={"w-15 h-15"}
                            style={{ backgroundColor: item?.color ?? "" }}
                          ></div>
                          <span className={"ml-2"}>
                            {item?.renderer
                              ? item?.renderer(t(item?.title, "common"))
                              : t(item?.title, "common")}
                          </span>
                        </div>
                        <span className={"ml-auto mr-2"}>
                          {formattedCount(item?.count)}
                        </span>
                      </li>
                    ))}
                  </ul>
                 
                 { activeState === ComponentMappings?.all_labs && <Grid item xs={12}>
                    <Button
                      onClick={() => navigateLabReports(activeLabSlug)}
                      width="100%"
                    >
                      {t("View Lab Report")}
                    </Button>
                  </Grid>}
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ProgressByLabProgram;
