import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import SecureImage from "../UI/atoms/SecureImage";
import Card from "./Card";

const UnifiedIntegrationCard = (props) => {
  /**
   * COMPONENT PROPS
   */
  const { integration, redirectUrl } = props;

  return (
    <div className={"prepr-unified-integration-card"}>
      {integration?.existing_connection ? (
        <Link
          className="link"
          to={`/${redirectUrl}?id=${integration?.connection_id}&state=${integration?.state}`}
        ></Link>
      ) : (
        // eslint-disable-next-line
        <a
          className="link"
          href={`${integration?.unified_login_url}&&auth_referrer=${window.location}&success_redirect=${window.location.origin}/${redirectUrl}`}
        ></a>
      )}
      {integration?.logo_url ? (
        <div>
          <SecureImage
            src={integration?.logo_url}
            alt={integration?.name}
            classes="logo"
          />
        </div>
      ) : (
        ""
      )}

      <div className="details">
        <h3 className={"title"}>{integration?.name}</h3>
        <div className="badge-container">
          {integration?.existing_connection ? (
            <div className="badges-success">ACTIVE</div>
          ) : (
            ""
          )}
          {integration?.categories
            ? integration?.categories?.map((category) => (
                <div className="badges" key={category}>
                  {category}
                </div>
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

export const UnifiedIntegrationCardSkeleton = () => {
  return (
    <Card classes="w-full">
      <div className="flex w-full">
        <Skeleton variant="rectangular" width={50} height={50} />
        <div className="flex-1 ml-1">
          <Skeleton sx={{ minWidth: "100%" }} variant="text" height={10} />
          <Skeleton sx={{ minWidth: "100%" }} variant="text" height={10} />
        </div>
      </div>
    </Card>
  );
};

export default UnifiedIntegrationCard;
