import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {
  mockRequestGet,
  mockRequestPost,
  mockRequestDelete,
} from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useProjects from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api";

let getSpy;
let postSpy;
let deleteSpy;

let projectId = "1";
let email = "1";
let uuid = "1";
let type = "1";

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com" },
  },
});

const ProjectsServicesMock = () => {
  const {
    list,
    view,
    create,
    edit,
    addPitch,
    fileUpload,
    getRequirements,
    addExternalLinks,
    addAdditionalInfo,
    deleteProject,
    submit,
    voteUnvote,
    likeUnlike,
    favUnfav,
    addAssessment,
    deleteAssessment,
    getAssessment,
    acceptDeclineJoinRequest,
    initRequirements,
    deleteMedia,
    getHistory,
    fetchActiveLabs,
    teams,
    sendRequest,
    teamMatchingProfileCheck,
    counts,
    pinUnpin,
  } = useProjects();

  const BUTTON_LINKS = [
    { name: "list", onClick: () => list(projectId, {}) },
    { name: "view", onClick: () => view(projectId) },
    { name: "create", onClick: () => create({}) },
    { name: "edit", onClick: () => edit(projectId, {}) },
    { name: "addPitch", onClick: () => addPitch(projectId, {}) },
    { name: "fileUpload", onClick: () => fileUpload(projectId, {}) },
    { name: "getRequirements", onClick: () => getRequirements(projectId) },
    {
      name: "addExternalLinks",
      onClick: () => addExternalLinks(projectId, {}),
    },
    {
      name: "addAdditionalInfo",
      onClick: () => addAdditionalInfo(projectId, {}),
    },
    { name: "deleteProject", onClick: () => deleteProject(projectId) },
    { name: "submit", onClick: () => submit(projectId, {}) },
    { name: "vote", onClick: () => voteUnvote(projectId, false) },
    { name: "unvote", onClick: () => voteUnvote(projectId, true) },
    { name: "like", onClick: () => likeUnlike(projectId, false) },
    { name: "pin", onClick: () => pinUnpin(projectId, false) },
    { name: "unpin", onClick: () => pinUnpin(projectId, true) },
    { name: "unlike", onClick: () => likeUnlike(projectId, true) },
    { name: "fav", onClick: () => favUnfav(projectId, false) },
    { name: "unfav", onClick: () => favUnfav(projectId, true) },
    { name: "addAssessment", onClick: () => addAssessment(projectId, {}) },
    { name: "deleteAssessment", onClick: () => deleteAssessment(projectId) },
    { name: "getAssessment", onClick: () => getAssessment(projectId) },
    {
      name: "accept",
      onClick: () => acceptDeclineJoinRequest(projectId, email, false),
    },
    {
      name: "decline",
      onClick: () => acceptDeclineJoinRequest(projectId, email, true),
    },
    { name: "initRequirements", onClick: () => initRequirements(uuid) },
    {
      name: "deleteMedia",
      onClick: () => deleteMedia(projectId, projectId, type),
    },
    { name: "getHistory", onClick: () => getHistory(projectId) },
    { name: "fetchActiveLabs", onClick: () => fetchActiveLabs({}) },
    { name: "teams", onClick: () => teams() },
    { name: "sendRequest", onClick: () => sendRequest(projectId) },
    {
      name: "teamMatchingProfileCheck",
      onClick: () => teamMatchingProfileCheck(),
    },
    { name: "counts", onClick: () => counts() },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Projects Services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <ProjectsServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
    deleteSpy = mockRequestDelete();
  });

  test("list", async () => {
    const button = screen.getByTestId("list");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}?type=${projectId}&language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("view", async () => {
    const button = screen.getByTestId("view");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("create", async () => {
    const button = screen.getByTestId("create");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${ENDPOINTS.CREATE}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("edit", async () => {
    const button = screen.getByTestId("edit");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.UPDATE}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("addPitch", async () => {
    const button = screen.getByTestId("addPitch");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.PITCH_TASK}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("fileUpload", async () => {
    const button = screen.getByTestId("fileUpload");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.FILE_UPLOAD}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("getRequirements", async () => {
    const button = screen.getByTestId("getRequirements");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.REQUIREMENTS}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("addExternalLinks", async () => {
    const button = screen.getByTestId("addExternalLinks");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.EXTERNAL_LINKS}?language=en`,
        { language: "en" },
        headers,
        expect.any(Function)
      )
    );
  });

  test("addAdditionalInfo", async () => {
    const button = screen.getByTestId("addAdditionalInfo");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.ADDITIONAL_INFO}?language=en`,
        { language: "en" },
        headers,
        expect.any(Function)
      )
    );
  });

  test("deleteProject", async () => {
    const button = screen.getByTestId("deleteProject");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("submit", async () => {
    const button = screen.getByTestId("submit");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.SUBMIT}?language=en`,
        {
          language: "en",
        },
        headers,
        expect.any(Function)
      )
    );
  });

  test("vote", async () => {
    const button = screen.getByTestId("vote");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.VOTE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("unvote", async () => {
    const button = screen.getByTestId("unvote");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.UNVOTE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("like", async () => {
    const button = screen.getByTestId("like");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.LIKE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("unlike", async () => {
    const button = screen.getByTestId("unlike");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.UNLIKE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("pin", async () => {
    const button = screen.getByTestId("pin");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.PIN}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("unpin", async () => {
    const button = screen.getByTestId("unpin");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.UNPIN}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("fav", async () => {
    const button = screen.getByTestId("fav");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.FAVOURITE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("unfav", async () => {
    const button = screen.getByTestId("unfav");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.UNFAVOURITE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("addAssessment", async () => {
    const button = screen.getByTestId("addAssessment");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.ASSESSMENT}/${ENDPOINTS.ADD}?language=en`,
        { language: "en" },
        headers,
        expect.any(Function)
      )
    );
  });

  test("deleteAssessment", async () => {
    const button = screen.getByTestId("deleteAssessment");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.ASSESSMENT}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("getAssessment", async () => {
    const button = screen.getByTestId("getAssessment");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.ASSESSMENT}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("accept", async () => {
    const button = screen.getByTestId("accept");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MEMBER_MANAGEMENT}/${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.PARTICIPANT_REQUEST}/${ENDPOINTS.ACCEPT}?language=en`,
        { email: "1" },
        headers,
        expect.any(Function)
      )
    );
  });

  test("decline", async () => {
    const button = screen.getByTestId("decline");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MEMBER_MANAGEMENT}/${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.PARTICIPANT_REQUEST}/${ENDPOINTS.DECLINE}?language=en`,
        { email: "1" },
        headers,
        expect.any(Function)
      )
    );
  });

  test("initRequirements", async () => {
    const button = screen.getByTestId("initRequirements");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE}/${uuid}/${ENDPOINTS.REQUIREMENTS}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("deleteMedia", async () => {
    const button = screen.getByTestId("deleteMedia");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.MEDIA}?language=en&media_id=${projectId}&type=${type}`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("getHistory", async () => {
    const button = screen.getByTestId("getHistory");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROJECTS}/${projectId}/${ENDPOINTS.HISTORY}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("fetchActiveLabs", async () => {
    const button = screen.getByTestId("fetchActiveLabs");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${ENDPOINTS.ACTIVE}/${ENDPOINTS.LIST}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("teams", async () => {
    const button = screen.getByTestId("teams");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.TEAM_MATCHING}/browse?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("sendRequest", async () => {
    const button = screen.getByTestId("sendRequest");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.TEAM_MATCHING}/${projectId}/${ENDPOINTS.SEND_REQUEST}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("teamMatchingProfileCheck", async () => {
    const button = screen.getByTestId("teamMatchingProfileCheck");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.TEAM_MATCHING}/${ENDPOINTS.PROFILE_CHECK}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("counts", async () => {
    const button = screen.getByTestId("counts");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.TEAM_MATCHING}/${ENDPOINTS.COUNT}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });
});
