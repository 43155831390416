import { Done } from "@mui/icons-material";
import Button from "components/Button";
import React from "react";

const CustomizeButtons = ({
  isCustomizeMode,
  onCancelCustomize,
  handleSaveDashConfig,
  onCustomize,
  t,
}) => {
  return (
    <div className="flex justify-end mt-4 mr-4">
      {!!isCustomizeMode ? (
        <>
          <Button
            color="red"
            bordered
            onClick={onCancelCustomize}
            classes="mr-2"
          >
            {t("Cancel Customization")}
          </Button>
          <Button
            color="green"
            onClick={handleSaveDashConfig}
          >
            <Done fontSize="small" className="mr-1" />
            {t("Save")}
          </Button>
        </>
      ) : (
        <Button onClick={onCustomize}>{t("Customize")}</Button>
      )}
    </div>
  );
};

export default CustomizeButtons;
