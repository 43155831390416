import { createSlice } from "@reduxjs/toolkit";
import ACTIONS from "./action-types";

const initialState = {
    profile: null,
    friends: [],
    followers: []
};

const SLICE = createSlice({
    name: "profile",
    initialState,
    reducers: {
        [ACTIONS.PROFILE]: (state, { payload }) => {
            state.profile = payload;
        },
        [ACTIONS.FRIENDS]: (state, { payload }) => {
            state.friends = payload;
        },
        [ACTIONS.FOLLOWERS]: (state, { payload }) => {
            state.followers = payload;
        },
    },
});

export const REDUCER = SLICE.actions;

export default SLICE.reducer;