import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import { mockRequestGet, mockRequestPost } from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useUserDashboard from "./user";
import { BrowserRouter } from "react-router-dom";

let getSpy;
let postSpy;

let type = "1";

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
});

const UserDashboardServicesMock = () => {
  const {
    myLatestAcievement,
    recommendations,
    myChallenges,
    myLabs,
    myResources,
    myProjects,
    progress,
    inboxFriendRequests,
    layout,
    leftOff,
    updateLayout,
    upcomingChallengeDeadlines,
  } = useUserDashboard();

  const BUTTON_LINKS = [
    { name: "myLatestAcievement", onClick: () => myLatestAcievement() },
    { name: "recommendations", onClick: () => recommendations(type) },
    { name: "myChallenges", onClick: () => myChallenges({}) },
    { name: "myLabs", onClick: () => myLabs({}) },
    { name: "myResources", onClick: () => myResources({}) },
    { name: "myProjects", onClick: () => myProjects({}) },
    { name: "progress", onClick: () => progress(type) },
    { name: "inboxFriendRequests", onClick: () => inboxFriendRequests(type) },
    { name: "layout", onClick: () => layout() },
    { name: "updateLayout", onClick: () => updateLayout({}) },
    { name: "leftOff", onClick: () => leftOff() },
    {
      name: "upcomingChallengeDeadlines",
      onClick: () => upcomingChallengeDeadlines(),
    },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("User Dashboard Services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <UserDashboardServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
  });

  test("myLatestAcievement", async () => {
    const button = screen.getByTestId("myLatestAcievement");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.MY_LATEST_ACHIEVEMENT}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("recommendations", async () => {
    const button = screen.getByTestId("recommendations");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.MY_RECOMMENDATIONS}?language=en&type=${type}`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("myResources", async () => {
    const button = screen.getByTestId("myResources");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.MY_RESOURCE_MODULES}?language=en&`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("myChallenges", async () => {
    const button = screen.getByTestId("myChallenges");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.MY_CHALLENGES}?language=en&`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("myLabs", async () => {
    const button = screen.getByTestId("myLabs");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.MY_LABS}?language=en&`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("myProjects", async () => {
    const button = screen.getByTestId("myProjects");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.MY_PROJECTS}?language=en&`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("progress", async () => {
    const button = screen.getByTestId("progress");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.MY_PROGRESS}?language=en&type=${type}`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("inboxFriendRequests", async () => {
    const button = screen.getByTestId("inboxFriendRequests");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.INBOX_FRIEND_REQUEST}?language=en&type=${type}`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("layout", async () => {
    const button = screen.getByTestId("layout");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.FETCH_LAYOUT}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("updateLayout", async () => {
    const button = screen.getByTestId("updateLayout");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.UPDATE_LAYOUT}?language=en&`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("leftOff", async () => {
    const button = screen.getByTestId("leftOff");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.LAST_VISITED_MODULE}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("upcomingChallengeDeadlines", async () => {
    const button = screen.getByTestId("upcomingChallengeDeadlines");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.UPCOMING_CHALLENGE_DEADLINES}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });
});
