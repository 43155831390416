import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { resolveResponse } from "store/api/utils";
import { serializeObject } from "store/api/utils";

const useSkills = () => {
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  const headers = {
    Authorization: "Bearer " + token,
  };

  //list of public skills
  const list = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${
        ENDPOINTS.SKILLS
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      const { list, total_pages } = res?.data?.data;
      return [list, total_pages];
    }
    return [];
  };

  //view public skills
  const view = async (id) => {
    if (!id) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.SKILLS}/${id}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  //view my skills
  const mySkills = async (filters = {}) => {
    if (!filters) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.SKILLS}/${
        ENDPOINTS.MY_SKILLS
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      const { list, total_count, per_page, current_page } = res?.data?.data;
      return [list, total_count, per_page, current_page];
    }
    return [];
  };

  const add = async (skillId) => {
    if (!skillId) return;
    const body = {
      skill_id: skillId,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.SKILLS}/${ENDPOINTS.ADD}?language=${language}`,
      body,
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  const pinUnpin = async (skillId, unpin = false) => {
    if (!skillId) return;
    const body = {
      skill_id: skillId,
      pinned: unpin ? "no" : "yes",
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.SKILLS}/${ENDPOINTS.PINNED}?language=${language}`,
      body,
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  return {
    list,
    view,
    mySkills,
    add,
    pinUnpin,
  };
};

export default useSkills;
