import { Close, PictureAsPdf } from "@mui/icons-material";
import React from "react";
import styles from "../ui.module.scss";

const PdfView = ({ classes, title }) => {
  return (
    <div className={`${styles.pdfView} ${classes}`} data-testid="pdf-view">
      <PictureAsPdf className="icon mt-4" />
      <p className="fs-12 opacity-70 text-center mt-3">{title}</p>
      <Close className="cross" />
    </div>
  );
};

export default PdfView;
