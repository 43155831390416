import Button from "components/Button";
import Card from "components/Cards/Card";
import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import ListingContainer from "components/UI/organisms/ListingContainer";
import MainProductCard, {
  getDefaultImageByType,
} from "components/Cards/MainProductCard";
import SecureImage from "components/UI/atoms/SecureImage";
import EmptyText from "components/UI/atoms/EmptyText";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import SearchAndSort from "./SearchAndSort";
import PinButton from "components/UI/atoms/PinButton";
import { isTrue } from "helpers/utils/validators";

const ProjectListing = ({
  itsMe,
  projects,
  page,
  setPage,
  isLoading,
  pages,
  search,
  setSearch,
  sort,
  setSort,
  pinUnpin = Function.prototype,
  t = (d) => d,
}) => {
  const { navigateCreateProject, navigateChallenge } = useRedirect();

  return (
    <Card classes="mt-4">
      <div className="my-2 flex items-center justify-between">
        <h2>{t("Projects")}</h2>
        {itsMe && (
          <Button bordered onClick={navigateCreateProject}>
            {t("Create Project")}
          </Button>
        )}
      </div>
      <SearchAndSort
        searchText={search}
        setSearchText={setSearch}
        sortOption={sort}
        setSortOption={setSort}
        t={t}
      />
      <ListingContainer
        classes="mt-4"
        isEmpty={!projects || !projects?.length}
        loading={isLoading}
        emptySmall
        page={page}
        count={pages}
        setPage={setPage}
        small
        emptyView={
          <EmptyText>
            {t(
              search
                ? "No result found, please try different keywords"
                : itsMe
                ? "Create new Projects to showcase here"
                : "This user has not submitted any Projects"
            )}
          </EmptyText>
        }
      >
        {projects?.map((project, i) => (
          <Grid item key={project?.id} xs={12}>
            <MainProductCard
              classes="mb-1"
              noSave
              small
              title={project?.title || "N/A"}
              description={project?.description}
              image={project?.media}
              type={COMPONENT_TYPES.PROJECT}
              id={project?.slug}
              noShadow
              rightAction={
                itsMe ? (
                  <PinButton
                    isPinned={isTrue(project?.is_project_pinned)}
                    onClick={() =>
                      pinUnpin(
                        project?.slug,
                        isTrue(project?.is_project_pinned)
                      )
                    }
                  />
                ) : null
              }
              content={
                <div className="flex">
                  <p className="fw-500 whitespace-nowrap">
                    {t("Associated Challenge")}:
                  </p>
                  <p
                    className="link ml-1"
                    onClick={() => navigateChallenge(project?.challenge?.slug)}
                  >
                    {project?.challenge?.title || "N/A"}
                  </p>
                </div>
              }
            />
          </Grid>
        ))}
      </ListingContainer>
    </Card>
  );
};

export default ProjectListing;

export const ItemCard = ({
  title,
  subTitle,
  media,
  bottomContent,
  description,
  component,
  slug,
  small,
}) => {
  const { navigateProject, navigateChallenge } = useRedirect();
  const width = useWindowWidth();

  const onTab = useMemo(() => width < 1000, []);

  const handleNavigate = () => {
    if (!component) return;
    switch (component) {
      case COMPONENT_TYPES.PROJECT:
        navigateProject(slug);
        break;
      case COMPONENT_TYPES.CHALLENGE:
        navigateChallenge(slug);
        break;
      default:
        break;
    }
  };
  return (
    <Grid
      container
      spacing={1}
      item
      xs={12}
      mt={1}
      className={onTab && "ml-2 shadow py-2"}
    >
      <Grid item xs={12} sm={onTab ? 12 : small ? 2.5 : 3.8}>
        <SecureImage
          src={media}
          placeholder={getDefaultImageByType(component)}
          title={title}
          classes={` w-full h-120
               ${
                 small
                   ? onTab
                     ? "h-120 pr-3 border-none"
                     : "h-120 border"
                   : onTab
                   ? "h-177"
                   : "h-177 border"
               } `}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={onTab ? 12 : small ? 9.5 : 8.2}
        className="flex-column"
      >
        <h3
          className="link mb-1 tracking-tight fw-500 capitalize text-truncate fs-20 fs-16--mob"
          onClick={handleNavigate}
        >
          {title}
        </h3>
        {subTitle && subTitle}
        <p
          className="description flex-wrap fs-14"
          dangerouslySetInnerHTML={{
            __html: description ?? "N/A",
          }}
        ></p>
        {bottomContent && bottomContent}
      </Grid>
    </Grid>
  );
};
