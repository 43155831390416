import { AddBoxOutlined, Mail, Upload } from "@mui/icons-material";
import { Radio, IconButton } from "@mui/material";
import Button from "components/Button";
import AutoComplete from "components/Input/AutoComplete";
import FileButton from "components/Input/FileButton";
import NetworkUsersSelect from "components/UI/molecules/NetworkUsersSelect";
import { payloadFormatArray } from "helpers/utils/utilities";
import { EMAIL_VALIDATOR,isTrue, Validate } from "helpers/utils/validators";
import { CriteriaRow } from "../../challenges/forms/PitchAndAssesment";
import React, { useEffect, useState } from "react";
import RichTextEditor from "react-rte";
import useChallenges from "store/challenges/service-hook";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useRedirect from "helpers/hooks/useRedirect";
import { handleCriteriaRowError } from "../../challenges/forms/PitchAndAssesment";
import Checkbox from "components/Input/Checkbox";

const evaluation_types = [
  {
    title: "No Evaluation",
    description: "There will be no project assessment for this challenge.",
  },
  {
    title: "Peer to Peer Evaluation",
    description: 
    "All users who participated in the challenge will be able to assess the projects.",
  },
  {
    title: "Private Evaluation",
    description: "Only assigned members will be able to access the projects.",
  },
];

const AssesmentGuidelines = ({
  assessment,
  id,
  setAssessment,
  isChallengeSubmitted,
}) => {
  const { t } = useLocalizedTranslation();
  const [option, setOption] = useState(0);
  const [users, setUsers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [text, setText] = useState(RichTextEditor.createEmptyValue());
  const [errors, setErrors] = useState(new Map());
  const [file, setFile] = useState(null);
  const [criteria, setCriteria] = useState([
    { title: "", description: "", score: "", weight: "" },
  ]);
  const { updateAssessment } = useChallenges();
  const [loading, setLoading] = useState(false);
  const { navigateChallenge, navigateBack } = useRedirect();
  const [selfEvaluation, setSelfEvaluation] = useState(false);
  const [visibility, setVisibility] = useState(null);

  useEffect(() => {
    if (!assessment) return;
    const { challenge_assessment, challenge_assessment_criteria } =
      assessment || {};
    setOption(
      challenge_assessment?.assessment_type === "closed"
        ? 2
        : challenge_assessment?.assessment_type === "open"
        ? 1
        : 0
    );
    if (challenge_assessment?.assessment_type === "closed") {
      let _users = [];
      let _emails = [];
      (challenge_assessment?.members || []).forEach((member) => {
        if (member?.id) {
          _users = [..._users, member];
        } else {
          _emails = [..._emails, member?.email];
        }
      });
      setUsers([..._users]);
      setEmails([..._emails]);
    }
    if (challenge_assessment?.guidelines)
      setText(
        RichTextEditor.createValueFromString(
          challenge_assessment?.guidelines || "",
          "html"
        )
      );
    if (challenge_assessment_criteria?.length) {
      setCriteria([
        ...challenge_assessment_criteria.map((d) => ({
          title: d?.assessment_title,
          description: d?.assessment_description,
          score: d?.assessment_score,
          weight: d?.assessment_weight,
        })),
      ]);
    }
    setSelfEvaluation(isTrue(challenge_assessment?.self_assessment));

    if(assessment?.challenge_assessment?.visibility){
      setVisibility(assessment?.challenge_assessment?.visibility);
    }
  }, [assessment]);

  const addCriteria = () => {
    if (isChallengeSubmitted) return;
    setCriteria((val) => [
      ...val,
      { title: "", description: "", score: "", weight: "" },
    ]);
  };

  const onChangeCriteria = (e, index) => {
    const { name, value } = e.target;
    const updatedCriteria = [...criteria];
    updatedCriteria[index][name] = value;
    setCriteria(updatedCriteria);
    errors.delete("criteria");
    setErrors(new Map(errors));
  };

  const deleteCriteria = (index) => {
    if (isChallengeSubmitted) return;
    criteria.splice(index, 1);
    setCriteria([...criteria]);
  };

  const handleOptionChange = (index) =>
    setOption((val) => (val === index ? null : index));

  const handleSelectEmail = async (email) => {
    if (!!email && !emails.includes(email)) {
      const emailValidationResult = await Validate(EMAIL_VALIDATOR, email);
      setEmails([...emails, email]);
      if (emailValidationResult !== false) {
        errors.set("email", emailValidationResult);
      } else {
        const updatedEmails = [...emails, email];
        const areAllEmailsValid = await validateAllEmails(updatedEmails);
        if (areAllEmailsValid) {
          errors.delete("email");
        }
      }
      setErrors(new Map(errors));
    }
  };

  const validateAllEmails = async (emailList) => {
    let areAllEmailsValid = true;
    await emailList.forEach(async (email) => {
      const result = await Validate(EMAIL_VALIDATOR, email);
      if (result !== false) {
        areAllEmailsValid = false;
      }
    });
    return areAllEmailsValid;
  };

  const handleRemoveEmail = (email) => {
    setEmails((prev) => {
      const newEmails = prev.filter((e) => e !== email);
      validateAllEmails(newEmails).then((areAllEmailsValid) => {
        if (areAllEmailsValid) {
          errors.delete("email");
          setErrors(new Map(errors));
        }
      });
      return newEmails;
    });
  };

  const handleAssessmentUpdate = async () => {
    if(option !== 0 && !handleCriteriaRowError(criteria)){
      return;
    }
    setLoading(true);
    const payload = {
      members_email: [...payloadFormatArray(users, "email"), ...emails],
      assessment_title: payloadFormatArray(criteria, "title"),
      assessment_description:
        option === 3
          ? []
          : payloadFormatArray(criteria, "description").map(
              (desc) => desc || ""
            ),
      assessment_score: payloadFormatArray(criteria, "score"),
      assessment_weight: payloadFormatArray(criteria, "weight"),
      assessment_type:
        option === 1
          ? "open"
          : option === 2
          ? "closed"
          : option === 3
          ? "ai"
          : "none",
      guidelines: text.toString("html"),
      visibility: visibility,
      self_assessment: selfEvaluation ? 1 : 0
    };
    if (file) payload["attachments"] = file;
    if (!payload?.visibility) delete payload["visibility"];
    const res = await updateAssessment(id, payload);
    if (res?.data?.data) {
      setAssessment(res?.data?.data || {});
      const slug = res?.data?.data?.slug;
      if (slug) navigateChallenge(slug, true);
    }
    setLoading(false);
  };

  const handleFileChange = (file) => {
    if (!file) return;
    const validTypes = ["application/rtf", "application/msword"];
    if (!validTypes.includes(file.type)) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const rtfContent = e.target.result;
      const htmlContent = rtfContent.toString();
      setText(RichTextEditor.createValueFromString(htmlContent, "html"));
    };
    reader.readAsText(file);
  };

  return (
    <>
      <div className={"mt-4"}>
        <div className={`${isChallengeSubmitted ? "opacity-50" : ""}`}>
          {evaluation_types.map(({ title, description }, i) => (
            <div key={title} className="flex items-center fs-14 flex-wrap">
              <Radio
                checked={option === i}
                onClick={() => handleOptionChange(i)}
                disabled={isChallengeSubmitted}
              />{" "}
              <p className="mr-2 w-160">{t(title)}</p>{" "}
              <p className="opacity-60">{t(description)}</p>
              {(option === 1 && title === "Peer to Peer Evaluation") && (
                  <div className="ml-6 fs-14 flex items-center">
                    <Checkbox
                      disabled={isChallengeSubmitted}
                      checked={selfEvaluation}
                      onChange={setSelfEvaluation}
                    />
                    <p className="mr-2 w-160">{t("Allow self evaluation")}</p>
                    <label className="opacity-60">{t("User can evaluate their own projects")}</label>
                  </div>
                )
              }
            </div>
          ))}
          {option === 0 && null}
          {option === 2 && (
            <>
              <h2 className="mt-6">{t("Search PreprLabs Network to Invite")}</h2>
              <NetworkUsersSelect
                disabled={isChallengeSubmitted}
                users={users}
                setUsers={setUsers}
              />
              {/* <h2 className="mt-6">{t("Invite via Email")}</h2>
              <AutoComplete
                prefix={Mail}
                keyValueOptions={emails}
                selectedOptions={emails}
                onSelectOption={handleSelectEmail}
                noOptions
                freeText
                disabled={isChallengeSubmitted}
                onRemoveChip={handleRemoveEmail}
                error={errors.get("email")}
              /> */}
              <div className="flex items-center mt-6">
                  <Button 
                    onClick={()=>{
                      setVisibility('users')
                    }}
                    color={visibility === 'users' ? 'blue': 'gray'}
                  >Assessment viewable by users</Button>
                 <Button 
                   onClick={()=>{
                      setVisibility('hidden')
                    }}
                   color={visibility === 'hidden' ? 'blue': 'gray'}
                   classes={'ml-6'}
                  >Assessment hidden from users</Button>
              </div>

            </>
          )}
          {option === 1 || option === 2 ? (
            <>
              <div className="flex justify-between items-start mt-6 mb-4">
                <div>
                  <h2>
                    {t("Assessment Guideline")} <span className="c-error">*</span>
                  </h2>
                  <p className="fs-12">
                    {t(
                      "Complete the evaluation guideline below, or attach a file(optional) to help evaluators understand your assessment criteria."
                    )}
                  </p>
                </div>
                <FileButton
                  onChange={setFile}
                  value={file}
                  disabled={isChallengeSubmitted}
                >
                  <Button height="40px" bordered>
                    <Upload /> {t("Upload Guideline")}
                  </Button>
                </FileButton>
              </div>
              <RichTextEditor
                editorClassName="minh-200"
                value={text}
                placeholder={t("type the message...")}
                onChange={setText}
                disabled={isChallengeSubmitted}
              />
              <h2 className="mt-6">
                {t("Assessment Criteria")} <span className="c-error">*</span>
              </h2>
              {Array.isArray(criteria) &&
                criteria.map((d, i) => (
                  <CriteriaRow
                    key={i}
                    criteria={d}
                    onChange={onChangeCriteria}
                    i={i}
                    onDelete={deleteCriteria}
                    isChallengeSubmitted={isChallengeSubmitted}
                    t={t}
                  />
                ))}
              <div className="w-90p h-40 mt-4">
                <AddBoxOutlined
                  onClick={addCriteria}
                  className="float-right c-primary cursor-pointer"
                />
              </div>
            </>
          ) : null}
          {option === 3 && (
            <>
              <div className="flex justify-between items-start mt-6 mb-4">
                <div>
                  <h2>
                    {t("Assessment Guideline")} <span className="c-error">*</span>
                  </h2>
                  <p className="fs-12">
                    {t(
                      "Complete the evaluation guideline below, or attach a file(optional) to help AI understand project assessment criteria."
                    )}
                  </p>
                </div>
                <FileButton accept=".xls,.xlsx,.csv" onChange={handleFileChange}>
                  <Button height="40px" bordered>
                    <Upload /> {t("Upload Guideline")}
                  </Button>
                </FileButton>
              </div>
              <RichTextEditor
                editorClassName="minh-200"
                value={text}
                placeholder="This challenge is about..."
                onChange={setText}
              />
              <h2 className="mt-6">
                {t("Assessment Criteria")} <span className="c-error">*</span>
              </h2>
              {Array.isArray(criteria) &&
                criteria.map((d, i) => (
                  <CriteriaRow
                    key={i}
                    criteria={d}
                    onChange={onChangeCriteria}
                    i={i}
                    onDelete={deleteCriteria}
                    isChallengeSubmitted={isChallengeSubmitted}
                    desc={true}
                    t={t}
                  />
                ))}
              <div className="w-90p h-40 mt-4">
                <IconButton disabled={isChallengeSubmitted} onClick={addCriteria}>
                  <AddBoxOutlined
                    onClick={addCriteria}
                    className="float-right c-primary cursor-pointer"
                    IconButton
                  />
                </IconButton>
              </div>
            </>
          )}
        </div>
        <div className="mt-8 mb-6 flex justify-between mx-2">
          <Button bordered width="100px" onClick={() => navigateBack()}>
            {t("Cancel")}
          </Button>
          <Button
            width={150}
            loading={loading}
            onClick={handleAssessmentUpdate}
            disabled={isChallengeSubmitted}
          >
            {t("Save Changes")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AssesmentGuidelines;
