import TextBox from "components/Input/Textbox";
import HrIntegrations from "./HrIntegrations";
import Input from "components/Input";
import { Grid } from "@mui/material";
import Button from "components/Button";
import { CSVLink } from "react-csv";
import FileButton from "components/Input/FileButton";
import NetworkUsersSelect from "components/UI/molecules/NetworkUsersSelect";
import Radio from "components/Input/Radio";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES, RECRUITING_STATUS } from "helpers/utils/constants";
import Select from "components/Input/Select";
import MasterSelect from "components/UI/organisms/MasterSelect";
import { getBaseUrl } from "helpers/utils/utilities";

const AddMembers = ({
  data,
  onChange,
  networkUsers,
  setNetworkUsers,
  csv,
  setCsv,
  sampleCsv,
  formErrors,
  setFormErrors,
  autoInvite,
  setAutoInvite,
  onSend,
  onCancel,
  loading,
  type,
  roles,
  hrIntegrationsEnabled,
  t = (d) => d,
  id,
}) => {
  const [invitationType, setInvitationType] = useState("by-email");
  const { navigateBack } = useRedirect();
  const autoInviteOptions = [
    { value: "Auto Add", key: "yes" },
    { value: "Ask For Permission", key: "no" },
  ];

  useEffect(() => {
    if (!!csv && !csv?.type?.includes("csv")) {
      formErrors.set("csv", t("File should be a valid CSV file!"));
    } else {
      formErrors.delete("csv");
    }
    setFormErrors(new Map(formErrors));
  }, [csv]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${getBaseUrl()}invitation-link/${type}/${id}`);
    toast.success(t("Invitation link copied!"));
  };
  const isButtonDisabled =
    !!formErrors?.size || (networkUsers?.length === 0 && !csv);

  return (
    <Grid container my={2}>
      <Grid item xs={12}>
        {type !== COMPONENT_TYPES.PROJECT ? (
          hrIntegrationsEnabled ? (
            <div className="mb-3 flex-center">
              <Button
                bordered={invitationType === "from-hr-integration"}
                color={invitationType === "by-email" ? "blueOpaque" : "gray"}
                width="200px"
                classes={invitationType === "by-email" && "c-primary"}
                onClick={() => setInvitationType("by-email")}
              >
                {t("From PreprLabs")}
              </Button>
              <Button
                width="200px"
                classes={
                  invitationType === "from-hr-integration" && "c-primary"
                }
                bordered={invitationType === "by-email"}
                color={
                  invitationType === "from-hr-integration"
                    ? "blueOpaque"
                    : "gray"
                }
                onClick={() => setInvitationType("from-hr-integration")}
              >
                {t("From HR Integrations")}
              </Button>
            </div>
          ) : null
        ) : (
          <div className="flex">
            <Select
              borderedIcon
              title="Recruiting Status"
              width="100%"
              onChange={onChange}
              height={40}
              name="recruiting_status"
              value={data["recruiting_status"]}
              keyValues={RECRUITING_STATUS}
              classes="w-40p w-50p--mob mr-2"
              t={(v) => t(v, { ns: "common" })}
            />
            <MasterSelect
              borderedIcon
              title="Required Skills"
              width="100%"
              value={data["skills"]}
              multi
              name="skills"
              onChange={onChange}
              height="100%"
              classes="w-40p w-50p--mob mr-2"
              t={t}
            />
          </div>
        )}
      </Grid>

      <Grid item xs={12}>
        <div className="mb-3 flex-center">
          <h3>{t("Invitation type")}:</h3>
          <Radio
            name={"auto_invite"}
            value={autoInvite}
            classes="ml-2"
            onChange={(event) => {
              setAutoInvite(event?.target?.value);
            }}
            options={autoInviteOptions}
            t={t}
          />
        </div>
      </Grid>

      {invitationType === "by-email" ? (
        <Grid item xs={12} lg={10}>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12} sm={12} md={8}>
              <div className="flex items-center gap-20">
                <NetworkUsersSelect
                  users={networkUsers}
                  setUsers={setNetworkUsers}
                  placeholder="Enter user email or user name"
                  classes={"flex-1"}
                  t={t}
                />
                {(type === COMPONENT_TYPES.PROJECT ||
                  type === COMPONENT_TYPES.ORGANIZATION) && (
                  <div className="relative w-25p">
                    {type === COMPONENT_TYPES.PROJECT && (
                      <p
                        className="absolute fw-500 fs-14"
                        style={{ top: "-25px" }}
                      >
                        {t("Project Permission")}
                      </p>
                    )}
                    <Select
                      borderedIcon
                      placeholder={"Select user role"}
                      width="100%"
                      height={40}
                      name="role"
                      onChange={onChange}
                      value={data["role"]}
                      values={roles.filter((d) => d !== "Team Leader")}
                      classes="w-100p  mr-2"
                      t={(v) => t(v, { ns: "common" })}
                    />
                  </div>
                )}
                <p>or</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} className="ml-4">
              <FileButton
                value={csv}
                onChange={setCsv}
                error={formErrors.get("csv")}
                classes="flex-column justify-end"
              >
                <Button>{t("Upload User List")}</Button>
              </FileButton>
              <CSVLink data={sampleCsv} filename="sample" className="link">
                <i className="fs-13 underline c-black cursor-pointer">
                  {t("Download Sample User List")}
                </i>
              </CSVLink>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={1} mt={1}>
            <Grid item xs={12} sm={11}>
              <Input
                title="Copy Invitation Link"
                disabled
                width="98%"
                classes="w-full"
                value={`${getBaseUrl()}invitation-link/${type}/${id}`}
                t={t}
              />
            </Grid>
            <Grid xs={12} sm={1} className="flex-center justify-end">
              <Button classes="mt-7" onClick={handleCopyLink}>
                {t("Copy URL")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {invitationType === "from-hr-integration" && (
        <HrIntegrations show={invitationType === "from-hr-integration"} t={t} />
      )}
      <Grid item xs={12} my={2}>
        {type !== COMPONENT_TYPES.PROJECT && (
          <>
            <h3 className="mt-2">{t("Customize Invitation Email")}</h3>
            <Input
              title={t("Title")}
              name="subject_line"
              onChange={onChange}
              classes="w-full mt-2"
              value={data?.subject_line}
              t={t}
            />
            <TextBox
              title={t("Message")}
              name="email_body"
              onChange={onChange}
              classes="w-full mt-2"
              value={data?.email_body}
              height={112}
              t={t}
            />
          </>
        )}
        <div className="flex w-full justify-between relative mt-4">
          <Button
            bordered
            width="170px"
            height="35px"
            onClick={() => navigateBack()}
          >
            {t("Cancel")}
          </Button>
          <Button
            width="170px"
            height="35px"
            onClick={onSend}
            disabled={isButtonDisabled}
            loading={loading}
          >
            {t("Send Invitation")}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default AddMembers;
