import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { resolveResponse } from "store/api/utils";
import ACTIONS from "./action-types";
import { REDUCER } from "./reducer";

const useOnboarding = () => {
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);
  const { startPage: startPageData } = useSelector(
    (state) => state[REDUCER_TYPES.ONBOARDING]
  );
  const dispatch = useDispatch();

  const headers = {
    Authorization: "Bearer " + token,
  };

  const resumeParser = async (resume) => {
    if (!resume) return;
    const body = {
      resume,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.RESUME}/${ENDPOINTS.UPLOAD}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  const completeOnboarding = async () => {
    const client = new REQUEST();
    await client.post(
      `${ENDPOINTS.USER}/${ENDPOINTS.COMPLETE_ONBOARDING}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
  };

  const completeOrgOnboarding = async (slug, payload = {}) => {
    if (!slug || !payload) return;
    const client = new REQUEST();
    const body = {
      language,
      ...payload,
    };
    const res = await client.post(
      `${ENDPOINTS.USER}/${ENDPOINTS.COMPLETE_ONBOARDING}/${slug}?language=${language}`,
      body,
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const addJobs = async (jobs = []) => {
    if (!jobs || !jobs?.length) return;
    const payload = {
      job_ids: jobs,
      language,
    };
    const client = new REQUEST();
    await client.post(
      `${ENDPOINTS.CAREER}/multiple/${ENDPOINTS.ADD}?language=${language}`,
      payload,
      headers,
      resolveResponse
    );
  };

  const startPage = async (currentLang = "en") => {
    if (startPageData && startPageData?.[currentLang])
      return startPageData?.[currentLang];
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.START_PAGE}?language=${
        currentLang.includes("fr") ? "fr-CA" : "en"
      }`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      dispatch(REDUCER[ACTIONS.START_PAGE]({ [currentLang]: res?.data?.data }));
      return res?.data?.data;
    }
  };

  const completeOrgMiniOnboarding = async () => {
    const client = new REQUEST();
    await client.post(
      `${ENDPOINTS.USER}/${ENDPOINTS.COMPLETE_MINI_ONBOARDING}/${ENDPOINTS.ORGANIZATION}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
  };

  const completeLabMiniOnboarding = async () => {
    const client = new REQUEST();
    await client.post(
      `${ENDPOINTS.USER}/${ENDPOINTS.COMPLETE_MINI_ONBOARDING}/${ENDPOINTS.LAB}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
  };

  const completeChallengeMiniOnboarding = async () => {
    const client = new REQUEST();
    await client.post(
      `${ENDPOINTS.USER}/${ENDPOINTS.COMPLETE_MINI_ONBOARDING}/${ENDPOINTS.CHALLENGE}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
  };

  return {
    resumeParser,
    completeOnboarding,
    completeOrgOnboarding,
    startPage,
    addJobs,
    completeChallengeMiniOnboarding,
    completeLabMiniOnboarding,
    completeOrgMiniOnboarding,
  };
};

export default useOnboarding;
