import {
  ACHIEVEMENT_TYPES,
  AUTHOR,
  AWARD_STATUS,
  CAREERS_FILTER,
  CAREERS_STATUS,
  CHALLENGE_PATH_FILTER,
  CONTENT_TYPES,
  generateRatings,
  GO1_DURATIONS,
  LAB_PROGRAMS_FILTER,
  LAB_REDEEM_STATUS,
  LANGUAGES,
  PLAN,
  PRIVACY_STATUS,
  PROGRESS,
  PROJECT_STATUS,
  REQUEST_STATUS,
  RESOURCE_FILTER,
  SOCIAL_ACTIVITY,
  SOCIAL_TYPE,
  STATUS,
  CHALLENGE_PATH_STATUS,
  TYPE,
  VERIFICATION_STATUS,
  COMPANY_SIZE,
  SOURCE,
  TEMPLATE_STATUS,
  LAB_SOURCE,
  RESOURCE_SOURCE,
  ACHIEVEMENT_LEVEL,
  ACHIEVEMENT_PLACEMENT,
  LAB_MARKETPLACE_STATUS,
  CHALLENGE_PATH_SOURCE,
} from "helpers/utils/constants";
import { keyValueFormat } from "helpers/utils/utilities";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useChallenges from "store/challenges/service-hook";
import useGO1 from "store/go1/service-hook";
import useLabs from "store/labs/service-hook";
import useLeaderboard from "store/leaderboard/service-hook";
import useMaster from "store/master/service-hook";
import useOrganization from "store/organization/service-hook";

export const FILTER_TYPES = {
  CATEGORY: "category",
  STATUS: "status",
  SKILLS: "skills",
  JOBS: "jobs",
  TYPES: "types",
  CUSTOM: "custom",
  PRIVACY: "privacy",
  SOCIAL_TYPE: "social_type",
  LEVEL: "level_id",
  DURATION: "duration_id",
  COMPONENT_TYPE: "component_type",
  TYPE: "type",
  AWARD_TYPE: "award_type",
  AWARD_STATUS: "award_status",
  ACHIEVEMENT_LEVEL: "achievement_level",
  ACHIEVEMENT_PLACEMENT: "achievement_placement",
  ORGANIZATION: "organization_id",
  REDEEM_STATUS: "redeem_status",
  PROJECT_STATUS: "project_status",
  CAREERS: "careers",
  GO1_TOPICS: "go1_topics",
  GO1_PROVIDERS: "go1_providers",
  GO1_DURATIONS: "go1_duration",
  LANGUAGE: "language",
  CONTENT_TYPE: "content_type",
  LABS: "lab_id",
  CHALLENGES: "challenge_id",
  REQUEST_STATUS: "request_status",
  SOCIAL_ACTIVITY: "social_activity",
  CAREERS_STATUS: "careers_status",
  VERIFICATION_STATUS: "is_verified",
  AUTHOR: "owner",
  PLAN: "plan",
  RATING: "rating",
  ORGANIZATION_TYPE: "organization_type",
  PROGRESS: "progress",
  COMPANY_SIZE: "total_employees",
  MULTI_TYPE: "multi_type",
  SOURCE: "source",
  TEMPLATE_STATUS: "template_status",
  MARKETPLAECE_STATUS: "m_template_status",
  CHALLENGE_PATH_STATUS: "challenge_path_status",
  LAB_SOURCE: "lab_source",
  RESOURCE_SOURCE: "resource_source",
  LEADERBOARD_ORGS: "l_organization_id",
  LEADERBOARD_LABS: "l_lab_id",
  LEADERBOARD_CHALLENGES: "l_challenge_id",
  LEADERBOARD_PROJECTS: "l_project_id",
  MARKETPLACE_ORGS: "marketplace_org",
  CHALLENGE_PATH_SOURCE: "challenge_path_source",
};

const getTypeByPathname = () => {
  const pathname = window.location.pathname;
  let filters;
  if (pathname.includes("lab")) {
    filters = LAB_PROGRAMS_FILTER;
  } else if (pathname.includes("challenge")) {
    filters = CHALLENGE_PATH_FILTER;
  } else if (pathname.includes("resource")) {
    filters = RESOURCE_FILTER;
  } else {
    filters = [];
  }
  return filters;
};

const getTitleByPathname = (choosenTitle) => {
  const pathname = window.location.pathname;
  let title = choosenTitle;
  switch (choosenTitle) {
    case "LEVEL":
      if (pathname.includes("team-matching")) title = "CHALLENGE LEVEL";
      break;
    case "DURATION":
      if (pathname.includes("team-matching")) title = "CHALLENGE DURATION";
      break;
    case "SKILLS":
      if (pathname.includes("team-matching"))
        title = "SKILLS TEAMS LOOKING FOR";
  }
  return title;
};

const useFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    fetchCategories,
    fetchSkills,
    fetchTypes,
    fetchLevel,
    fetchDurations,
    state,
    fetchJobs,
  } = useMaster();
  const { state: labState, fetchLabsSelectList } = useLabs();
  const { state: challengeState, fetchChallengeSelectList } = useChallenges();
  const { getOrganizationSelectList, getMarketplaceOrgList } =
    useOrganization();
  const { getOrgs, getLabs, getChallenges, getProjects } = useLeaderboard();
  const { state: go1State, topics, providers } = useGO1();
  const filters = searchParams.get("filters");
  const [selected, setSelected] = useState(JSON.parse(filters || "{}"));
  const setFilters = () => {
    if (!filters) return;
    if (filters) {
      try {
        setSelected(JSON.parse(filters) || {});
      } catch (_error) {
        setSelected({});
      }
    } else {
      setSelected({});
    }
  };

  useEffect(() => {
    setFilters();
  }, [filters]);

  const onChange = (e, isString, link) => {
    const { name, value, checked } = e.target;
    if (!!link) {
      navigate(link);
      return;
    }
    let newSelected = { ...selected };
    if (isString) {
      newSelected[name] = checked ? value : undefined;
    } else {
      if (!newSelected[name]) newSelected[name] = [];
      if (checked) {
        if (!newSelected[name]?.includes(value)) {
          newSelected[name].push(value);
        }
      } else {
        newSelected[name] = newSelected[name].filter((d) => d !== value);
      }
    }
    searchParams.set("filters", JSON.stringify({ ...newSelected }));
    setSearchParams(searchParams);
    setSelected({ ...newSelected });
  };

  const clearAll = () => {
    searchParams.delete("filters");
    setSearchParams(searchParams);
    setSelected({});
  };

  const getFilterValuesByType = (type) => {
    switch (type) {
      case FILTER_TYPES.CATEGORY:
      case FILTER_TYPES.ORGANIZATION_TYPE: {
        if (!state.categories?.length) fetchCategories();
        return [
          type === FILTER_TYPES.CATEGORY ? "CATEGORY" : "ORGANIZATION TYPE",
          keyValueFormat(state?.categories || []),
        ];
      }
      case FILTER_TYPES.SKILLS: {
        if (!state.skills?.length) fetchSkills();
        return [
          getTitleByPathname("SKILLS"),
          keyValueFormat(state.skills || []),
        ];
      }
      case FILTER_TYPES.JOBS: {
        if (!state.job_titles?.length) fetchJobs();
        return ["JOBS", keyValueFormat(state.job_titles || [])];
      }
      case FILTER_TYPES.TYPES: {
        if (!state.types?.length) fetchTypes();
        return ["TYPES", keyValueFormat(state.types || [])];
      }
      case FILTER_TYPES.STATUS: {
        return ["STATUS", STATUS, true];
      }
      case FILTER_TYPES.PRIVACY: {
        return ["PRIVACY", PRIVACY_STATUS, true];
      }
      case FILTER_TYPES.SOCIAL_TYPE: {
        return ["SOCIAL TYPE", SOCIAL_TYPE, true];
      }
      case FILTER_TYPES.LEVEL: {
        if (!state?.level?.length) fetchLevel();
        return [getTitleByPathname("LEVEL"), keyValueFormat(state.level)];
      }
      case FILTER_TYPES.DURATION: {
        if (!state?.duration?.length) fetchDurations();
        return [getTitleByPathname("DURATION"), keyValueFormat(state.duration)];
      }
      case FILTER_TYPES.COMPONENT_TYPE: {
        return ["COMPONENT", getTypeByPathname(), true];
      }
      case FILTER_TYPES.AWARD_TYPE: {
        return ["TYPE", ACHIEVEMENT_TYPES, false];
      }
      case FILTER_TYPES.AWARD_STATUS: {
        return ["STATUS", AWARD_STATUS, true];
      }
      case FILTER_TYPES.ACHIEVEMENT_LEVEL: {
        return ["LEVEL", ACHIEVEMENT_LEVEL, false];
      }
      case FILTER_TYPES.ACHIEVEMENT_PLACEMENT: {
        return ["PLACEMENT", ACHIEVEMENT_PLACEMENT, false];
      }
      case FILTER_TYPES.ORGANIZATION: {
        return ["ORGANIZATION", getOrganizationSelectList, false, true];
      }
      case FILTER_TYPES.LABS: {
        if (!labState.labs?.length) fetchLabsSelectList();
        return ["LABS", keyValueFormat(labState.labs)];
      }
      case FILTER_TYPES.CHALLENGES: {
        if (!challengeState.challenges?.length) fetchChallengeSelectList();
        return ["CHALLENGES", keyValueFormat(challengeState.challenges)];
      }
      case FILTER_TYPES.REDEEM_STATUS: {
        return ["STATUS", LAB_REDEEM_STATUS, true];
      }
      case FILTER_TYPES.PROJECT_STATUS: {
        return ["STATUS", PROJECT_STATUS, true];
      }
      case FILTER_TYPES.CAREERS: {
        return ["TYPE", CAREERS_FILTER, true];
      }
      case FILTER_TYPES.GO1_TOPICS: {
        if (!go1State.topics?.length) topics();
        return ["TOPICS", go1State.topics || []];
      }
      case FILTER_TYPES.GO1_PROVIDERS: {
        if (!go1State.providers?.length) providers();
        return ["PROVIDERS", go1State.providers || []];
      }
      case FILTER_TYPES.LANGUAGE: {
        return ["LANGUAGE", LANGUAGES, true];
      }
      case FILTER_TYPES.CONTENT_TYPE: {
        return ["CONTENT TYPE", CONTENT_TYPES, true];
      }
      case FILTER_TYPES.GO1_DURATIONS: {
        return ["DURATION", GO1_DURATIONS, true];
      }
      case FILTER_TYPES.REQUEST_STATUS: {
        return ["STATUS", REQUEST_STATUS, true];
      }
      case FILTER_TYPES.SOCIAL_ACTIVITY: {
        return ["SOCIAL TYPE", SOCIAL_ACTIVITY, true];
      }
      case FILTER_TYPES.CAREERS_STATUS: {
        return ["STATUS", CAREERS_STATUS, true];
      }
      case FILTER_TYPES.VERIFICATION_STATUS: {
        return ["VERIFICATION STATUS", VERIFICATION_STATUS, true];
      }
      case FILTER_TYPES.AUTHOR: {
        return ["AUTHOR", AUTHOR, true];
      }
      case FILTER_TYPES.PLAN: {
        return ["ORGANIZATION PLAN", PLAN, true];
      }
      case FILTER_TYPES.RATING: {
        return ["RATING", generateRatings(), true];
      }
      case FILTER_TYPES.TYPE: {
        return ["TYPE", TYPE, true];
      }
      case FILTER_TYPES.PROGRESS: {
        return ["PROGRESS", PROGRESS, true];
      }
      case FILTER_TYPES.COMPANY_SIZE: {
        return ["COMPANY SIZE", COMPANY_SIZE, true];
      }
      case FILTER_TYPES.MULTI_TYPE: {
        return ["TYPE", TYPE];
      }
      case FILTER_TYPES.SOURCE: {
        return ["SOURCE", SOURCE, true];
      }
      case FILTER_TYPES.TEMPLATE_STATUS: {
        return ["TEMPLATE STATUS", TEMPLATE_STATUS];
      }
      case FILTER_TYPES.MARKETPLAECE_STATUS: {
        return ["TEMPLATE STATUS", LAB_MARKETPLACE_STATUS];
      }
      case FILTER_TYPES.CHALLENGE_PATH_STATUS: {
        return ["STATUS", CHALLENGE_PATH_STATUS, true];
      }
      case FILTER_TYPES.LAB_SOURCE: {
        return ["SOURCE", LAB_SOURCE];
      }
      case FILTER_TYPES.RESOURCE_SOURCE: {
        return ["SOURCE", RESOURCE_SOURCE];
      }
      case FILTER_TYPES.LEADERBOARD_ORGS: {
        return ["JOINED ORGANIZATIONS", getOrgs, false, true];
      }
      case FILTER_TYPES.LEADERBOARD_LABS: {
        return ["JOINED LABS", getLabs, false, true];
      }
      case FILTER_TYPES.LEADERBOARD_CHALLENGES: {
        return ["JOINED CHALLENGES", getChallenges, false, true];
      }
      case FILTER_TYPES.LEADERBOARD_PROJECTS: {
        return ["JOINED PROJECTS", getProjects, false, true];
      }
      case FILTER_TYPES.MARKETPLACE_ORGS: {
        return ["ORGANIZATION", getMarketplaceOrgList, false, true];
      }
      case FILTER_TYPES.CHALLENGE_PATH_SOURCE: {
        return ["SOURCE", CHALLENGE_PATH_SOURCE, true];
      }
    }
  };

  return {
    selected,
    onChange,
    getFilterValuesByType,
    setSelected,
    clearAll,
  };
};

export default useFilters;
