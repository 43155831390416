import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import { mockRequestGet, mockRequestDelete, mockRequestPost } from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useResourceGroups from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api"

let getSpy;
let deleteSpy;
let postSpy;

let resourceGroupId = "1";

const headers = {
    Authorization: "Bearer 1",
};

const payload = {
    language: "en",
};

const store = initStore({
    [REDUCER_TYPES.AUTH]: {
        language: "en",
        token: 1,
        user: { id: 1, email: "test@gmail.com" },
    },
    [REDUCER_TYPES.MASTER]: {},
});

const ResourceGroupsServicesMock = () => {
   const {
    fetchMyResourceGroup,
        fetchMyResourceGroups,
        fetchResourceGroup,
        fetchResourceGroups,
        deleteResourceGroup,
        create, 
        edit
   } = useResourceGroups();

   const BUTTON_LINKS = [
    { name: "fetchMyResourceGroup", onClick: () => fetchMyResourceGroup(resourceGroupId) },
    { name: "fetchMyResourceGroups", onClick: () => fetchMyResourceGroups({}) },
    { name: "fetchResourceGroup", onClick: () => fetchResourceGroup(resourceGroupId) },
    { name: "fetchResourceGroups", onClick: () => fetchResourceGroups({}) },
    { name: "deleteResourceGroup", onClick: () => deleteResourceGroup(resourceGroupId) },
    { name: "create", onClick: () => create({}) },
    { name: "edit", onClick: () => edit(resourceGroupId, {}) },
];

return (
    <div>
        {BUTTON_LINKS.map((item) => (
            <button data-testid={item.name} key={item.name} onClick={item.onClick}>
                Call {item.name}
            </button>
        ))}
    </div>
);
}

let screen;

describe("Resource Groups services", () => {
    beforeEach(() => {
        screen = render(
            <Provider store={store}>
                <BrowserRouter>
                    <ResourceGroupsServicesMock />
                </BrowserRouter>
            </Provider>
        );
        getSpy = mockRequestGet();
        deleteSpy = mockRequestDelete();
        postSpy = mockRequestPost();
    });

    test("fetchMyResourceGroup", async () => {
        const button = screen.getByTestId("fetchMyResourceGroup");
        userEvent.click(button);

        await waitFor(() =>
            expect(getSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_GROUP}/${resourceGroupId}?language=en`,
                {},
                headers,
                expect.any(Function)
            )
        );
    });

    test("fetchMyResourceGroups", async () => {
        const button = screen.getByTestId("fetchMyResourceGroups");
        userEvent.click(button);

        await waitFor(() =>
            expect(getSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_GROUP}?language=en&`,
                {},
                {
                    ...headers,
                    "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
                },
                expect.any(Function)
            )
        );
    });

    test("fetchResourceGroup", async () => {
        const button = screen.getByTestId("fetchResourceGroup");
        userEvent.click(button);

        await waitFor(() =>
            expect(getSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_GROUP}/${resourceGroupId}?language=en`,
                {},
                headers,
                expect.any(Function)
            )
        );
    });

    test("fetchResourceGroups", async () => {
        const button = screen.getByTestId("fetchResourceGroups");
        userEvent.click(button);

        await waitFor(() =>
            expect(getSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_GROUP}?language=en&`,
                {},
                headers,
                expect.any(Function)
            )
        );
    });

    test("deleteResourceGroup", async () => {
        const button = screen.getByTestId("deleteResourceGroup");
        userEvent.click(button);

        await waitFor(() =>
            expect(deleteSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_GROUP}/${resourceGroupId}/${ENDPOINTS.DELETE}?language=en`,
                headers,
                expect.any(Function)
            )
        );
    });
    
    test("create", async () => {
        const button = screen.getByTestId("create");
        userEvent.click(button);

        await waitFor(() =>
            expect(postSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_GROUP}/${ENDPOINTS.CREATE}?language=en`,
                {
                    ...payload,
                    status: "publish",
                },
                {
                    ...headers,
                    "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
                },
                expect.any(Function)
            )
        );
    });

    test("edit", async () => {
        const button = screen.getByTestId("edit");
        userEvent.click(button);

        await waitFor(() =>
            expect(postSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_GROUP}/${resourceGroupId}/${ENDPOINTS.UPDATE}?language=en`,
                {
                    ...payload,
                    status: "publish",
                    _method: "put",
                    language: "en",
                },
                {
                    ...headers,
                    "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
                },
                expect.any(Function)
            )
        );
    });
});