import { useState } from "react";
import { COLORS, COMPONENT_TYPES } from "helpers/utils/constants";
import React from "react";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { Chip, Popover, Stack } from "@mui/material";
import SecureImage from "../atoms/SecureImage";
import goldCupTrophy from "assets/png/goldTrophy.png";
import ProgressTag from "../atoms/ProgressTag";
import useMaster from "store/master/service-hook";
import Spin from "components/Spin";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const AchievementsAndSkillsCard = ({
  data,
  hideSkillStacks,
  hideSkillGroups,
  component,
  hideProgress,
}) => {
  const [viewAll, setViewAll] = useState(false);
  const { fetchSkills } = useMaster();
  const { t } = useLocalizedTranslation("common", true);

  return (
    <div className="mt-4">
      {!hideProgress && (
        <div className="flex-center justify-between">
          <h3 className="fs-22">
            {t("Your")} {t(component)} {t("Progress")}
          </h3>
          <ProgressTag classes="ml-1" progress={data?.module_progress} />
        </div>
      )}
      <div
        className="px-2 py-2 mt-3 rounded-4"
        style={{
          border: `2px dashed ${COLORS.SUCCESS}`,
          backgroundColor: "#FAFFFB",
        }}
      >
        <h4 className="fs-13">
          {t("By completing the")} {t(component)}, {t("you will obtain")}:{" "}
        </h4>
        {(!!data?.achievement && (Boolean(data?.achievement?.achievement_name) ||
            data?.achievement?.length)) ||
        (!!data.participation_achievement && Boolean(data?.participation_achievement?.achievement_name)) ||
        (!!data.incentive_achievement && Boolean(data?.incentive_achievement?.achievement_name)) ? (
          <>
            <h4 className="mt-1 fs-13">{t("Achievements")}</h4>
            {Array.isArray(data?.achievement) && data?.achievement.length ? (
              data?.achievement.map((ach, index) => (
                <AchievementCard
                  key={index}
                  title={ach?.achievement_name}
                  subTitle={ach?.achievement_prize || ""}
                  points={ach?.achievement_points}
                  image={ach?.achievement_image}
                  t={t}
                />
              ))
            ) : (component.toLowerCase() === COMPONENT_TYPES.CHALLENGE && !!data.participation_achievement) ? (
                <AchievementCard
                    title={data?.participation_achievement?.achievement_name}
                    points={data?.participation_achievement?.achievement_points}
                    subTitle={
                        data?.participation_achievement?.achievement_prize || ""
                    }
                    image={data?.participation_achievement?.achievement_image}
                    t={t}
                />
            ) : (!data.participation_achievement?.length && !data.incentive_achievement?.length && !!data?.achievement) ? (
                <AchievementCard
                    title={data?.achievement?.achievement_name}
                    points={data?.achievement?.achievement_points}
                    image={data?.achievement?.achievement_image}
                    subTitle={data?.achievement?.achievement_prize || ""}
                    t={t}
                />
            ) : null}
            {component.toLowerCase() === COMPONENT_TYPES.CHALLENGE &&
            data?.incentive_achievement?.length
              ? data?.incentive_achievement?.map((d) => (
                  <AchievementCard
                    title={d?.achievement_name}
                    points={d?.achievement_points}
                    subTitle={d?.achievement_prize || ""}
                    image={d?.achievement_image}
                    t={t}
                    key={d?.achievement_id}
                  />
                ))
              : null}
          </>
        ) : null}

        <div className="flex-center justify-between my-1">
          <h4 className="fs-12">{t("Skills, Skill Sets & Skill Groups")}</h4>
          <p
            className="link fs-12 tracking-tight cursor-pointer text-nowrap"
            onClick={() => setViewAll((prev) => !prev)}
          >
            {t(viewAll ? "Hide All" : "View All")}
          </p>
        </div>
        <Stack gap={0.9} direction="row" flexWrap="wrap">
          {!hideSkillGroups &&
            Object.values(data?.skill_groups)?.length > 0 &&
            (Object.entries(data?.skill_groups) || []).map(([key, value]) => (
              <ExpandItem title={value} fetch={fetchSkills} id={key} />
            ))}
          {!hideSkillStacks &&
            Object.values(data?.skill_stacks)?.length > 0 &&
            (Object.entries(data?.skill_stacks) || []).map(([key, value]) => (
              <ExpandItem title={value} fetch={fetchSkills} id={key} isSet />
            ))}
          {viewAll &&
            Object.entries(data?.skills || {}).map((skill) => (
              <Chip
                sx={{
                  border: "1px solid #dadada",
                }}
                label={skill[1]}
                key={skill[0]}
                className="border fs-11 c-gray rounded-10 bg-gray cursor-pointer py-0 h-26"
              />
            ))}
        </Stack>
      </div>
    </div>
  );
};

export default AchievementsAndSkillsCard;

const ExpandItem = ({ title, id, isSet, fetch }) => {
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const handleFetch = async (e) => {
    if (expanded) {
      setExpanded(null);
      return;
    }
    setExpanded(e.currentTarget);
    setLoading(true);
    const res = await fetch({
      search: "",
      [isSet ? "skill_set_id" : "skill_group_id"]: id,
    });
    setSkills(res);
    setLoading(false);
  };

  return (
    <>
      <Chip
        label={
          <div
            style={{ color: !isSet ? COLORS.DARK_BLUE : COLORS.BLUE }}
            className="flex-center"
          >
            {title} <ArrowRight />
          </div>
        }
        sx={{
          border: "1px solid #dadada",
          "& .MuiChip-label": {
            pr: 0,
          },
        }}
        className="border fs-11 rounded-10 bg-gray cursor-pointer py-0 h-26"
        onClick={handleFetch}
      />
      <Popover
        open={Boolean(expanded)}
        anchorEl={expanded}
        onClose={() => setExpanded(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{
          paper: "minh-80 minw-100 maxw-150 pt-1 pb-2 shadow-none border",
        }}
      >
        {loading ? (
          <div className="mx-auto my-6 w-20">
            <Spin blue />
          </div>
        ) : !skills?.length ? (
          <p>No skills found!</p>
        ) : (
          skills?.map((skill) => (
            <p
              className="fs-12 px-2 mt-1"
              style={{
                lineHeight: 1.2,
                color: !isSet ? COLORS.DARK_BLUE : COLORS.BLUE,
              }}
            >
              {skill?.title}
            </p>
          ))
        )}
      </Popover>
    </>
  );
};

const AchievementCard = ({ image, title, subTitle, points, t = (d) => d }) => {
  return (
    <div data-testid="trophy-chip" className="py-2 mt-1 ml-2 flex items-center">
      <SecureImage
        placeholder={goldCupTrophy}
        src={image}
        classes="h-56 w-56 rounded-100 object-cover"
      />
      <div className="ml-2 w-70p">
        <p className="fw-600 fs-14 text-truncate" title={title}>
          {!!title ? title : "N/A"}
        </p>
        <p className="fs-12 text-truncate">{!!subTitle ? subTitle : ""}</p>
        <p className="fs-12 ">
          <b>{points ? `${points} ${t("Prepr Points")}` : ""}</b>
        </p>
      </div>
    </div>
  );
};
