import React from "react";
import { FilterComponentsList } from "../../data";
import SubFilter from "./SubFilter";
import { useAdvanceSearchContext } from "../../hooks/useAdvanceSearchContext";
import { CHALLENGE_PATH_STATUS } from "helpers/utils/constants";

const Status = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { status, setStatus, ActiveFilterComponents } =
    useAdvanceSearchContext();

  return (
    <SubFilter
      title={t("STATUS")}
      values={status}
      onChange={(checked, value) => {
        let temp = [...status];
        if (checked) {
          temp.push(value);
        } else {
          temp = temp.filter((item) => item !== value);
        }
        setStatus(temp);
      }}
      options={CHALLENGE_PATH_STATUS.map((d) => ({ ...d, label: d?.value }))}
      show={ActiveFilterComponents.includes(FilterComponentsList.status)}
      t={t}
    />
  );
};

export default Status;
