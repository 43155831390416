import { useEffect, useState } from "react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
const MAX_VIEWS = 5;
const SwipableView = ({
  children,
  slidesPerView,
  classes,
  small,
  noPagination,
  noLoop,
  navigation,
  xs,
  autoplay,
}) => {
  const [limitedChildren, setLimitedChildren] = useState([]);
  const [dummySlides, setDummySlides] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState(false);

  useEffect(() => {
    const pagOptions = noPagination ? false : { clickable: true };
    setPaginationOptions(pagOptions);

    const limitedChildrenArray = children.slice(0, slidesPerView * MAX_VIEWS);
    setLimitedChildren(limitedChildrenArray);

    const totalSlides = limitedChildrenArray.length;
    const slidesNeeded = slidesPerView - (totalSlides % slidesPerView);
    const dummySlidesArray = [];

    if (slidesNeeded !== slidesPerView) {
      for (let i = 0; i < slidesNeeded; i++) {
        dummySlidesArray.push(
          <SwiperSlide
            key={`dummy-${i}`}
            className="swiper-slide-dummy"
          ></SwiperSlide>
        );
      }
    }
    setDummySlides(dummySlidesArray);
  }, [children, slidesPerView, noPagination]);

  return (
    <Swiper
      loop={!noLoop}
      spaceBetween={10}
      slidesPerView={slidesPerView}
      slidesPerGroup={slidesPerView}
      navigation={navigation ?? true}
      pagination={paginationOptions}
      mousewheel={false}
      keyboard={true}
      autoplay={autoplay}
      modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
      className={`${classes} swiper ${small && "half"} ${xs && "xs"}`}
    >
      {limitedChildren.map((item, i) => (
        <SwiperSlide key={i}>{item}</SwiperSlide>
      ))}
      {dummySlides}
    </Swiper>
  );
};

export default SwipableView;
