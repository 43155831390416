import React from "react";
import useAdvanceSearchFilter from "../../../hooks/useAdvanceSearchFilter";
import { AS_REDUCER } from "../../../../../store/advance-search/reducer";
import useAdvanceSearch from "../../../../../store/advance-search/service-hook";
import { useAdvanceSearchContext } from "../../../hooks/useAdvanceSearchContext";
import QuickSearchListing from "../../QuickSearch";
import { ADVANCE_SEARCH_COMPONENTS } from "../../../data";

const QuickSearchResourceCollection = ({ t = (d) => d }) => {
  const { quickKeyword } = useAdvanceSearchContext();
  const { searchResourceCollection } = useAdvanceSearch();
  const { data } = useAdvanceSearchFilter(
    ADVANCE_SEARCH_COMPONENTS.RESOURCE_COLLECTION,
    quickKeyword,
    null,
    searchResourceCollection,
    AS_REDUCER.setResourceCollection,
    true
  );

  return (
    <QuickSearchListing
      data={data?.data?.slice(0, 3) ?? []}
      componentName={"Resource Collection"}
      loading={data?.loading}
      component={ADVANCE_SEARCH_COMPONENTS.RESOURCE_COLLECTION}
      t={t}
    />
  );
};

export default QuickSearchResourceCollection;
