export const ComponentMappings = {
    all_labs: "all_labs",
    all_lab_programs: "all_lab_programs",
    all_challenges: "all_challenges",
    all_challenge_paths: "all_challenge_paths",
    all_resources: "all_resources",
    all_resource_collections: "all_resource_collections",
    all_resource_groups: "all_resource_groups",
}

export const ActiveChartDurationList = {
    last_seven_day: 'last_7_days',
    last_four_weeks: 'last_4_weeks',
    last_six_months: 'last_6_months'
}


export const ChartColors = {
    "labs": ['rgb(10, 43, 119)',
        'rgb(39, 118, 214)',
        'rgb(134, 205, 253)'],
    "resources": ["#570AA4", "#AF5EE0", "#E3B7FF"],
    "challenges": ["#055E49", "#13A859", "#A3F9A9", "#D2D4DA", "#FFC2B5", "#FFC2B5"],
    "challenge_associated_project_status": ["#113D90", "#3698F9", "#D2D4DA", "#FF9990"],
    "challenge_associated_project_assessment": ["#FFBC3F", "#FFE399"],
    "member_activity": ["#3698F9", "#FFC2B5"]
}


export const AssessmentFilters = {
    all: 'all',
    assessed:'assessed',
    assessment_pending: 'pending'
}

