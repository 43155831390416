import { createSlice } from "@reduxjs/toolkit";
import ACTIONS from "./action-types";

const initialState = {
  topics: [],
  providers: [],
};

const SLICE = createSlice({
  name: "go1",
  initialState,
  reducers: {
    [ACTIONS.TOPICS]: (state, { payload }) => {
      state[ACTIONS.TOPICS] = payload;
    },
    [ACTIONS.PROVIDERS]: (state, { payload }) => {
      state[ACTIONS.PROVIDERS] = payload;
    },
  },
});

export const REDUCER = SLICE.actions;

export default SLICE.reducer;
