import Card from "components/Cards/Card";
import React, { useState } from "react";
import Button from "components/Button";
import ProjectPitch from "../forms/ProjectPitch";
import { payloadFormatArray } from "helpers/utils/utilities";
import useProjects from "store/projects/service-hook";
import useAuth from "store/auth/service-hook";
import { NotLoggedInTabs } from "pages/organization";
import { isTrue } from "helpers/utils/validators";

const ProjectOverview = ({
  project,
  setProject,
  pitchCompleted,
  taskCompleted,
  t = (d) => d,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [pitch, setPitch] = useState({
    challenge_pitch: project?.project_pitch,
    challenge_task: project?.project_task,
    id: project?.challenge_details?.template_id,
  });
  const [loading, setLoading] = useState(false);
  const { addPitch } = useProjects();
  const {
    state: { isLoggedIn },
  } = useAuth();
  const submitPitch = async () => {
    if(isEditMode) {
      let payload;
      let res;
      if (!pitch) return;
      payload = {
        template_id: pitch?.id,
        pitch_id: payloadFormatArray(pitch?.challenge_pitch, "id"),
        pitch_answer: payloadFormatArray(pitch?.challenge_pitch, "answer")?.map(
          (d) => (d ? d?.toString("html") : "")
        ),
        task_id: payloadFormatArray(pitch?.challenge_task, "id"),
        task_answer: payloadFormatArray(pitch?.challenge_task, "answer").map(
          (d) => d
        ),
      };
      setLoading(true);
      res = await addPitch(project?.slug, payload);
      setProject(res);
      setLoading(false);

      if (res) {
        setIsEditMode(false);
        setDirty(false);
      }
    } else {
      setIsEditMode(true);
    }
  };

  const disablePitch =
    project?.project_role === "assessor" ||
    project?.access_level === "viewer" ||
    !project?.access_level;
  return !isLoggedIn ? (
    <NotLoggedInTabs title="Login to view more details" />
  ) : (
    <Card classes="mt-5 pb-6 px-0">
      <div className="mb-4 mt-2 pb-2 px-4 flex justify-between w-full">
        <h2>{t("Project Details")}</h2>
        <Button
          loading={loading}
          hide={!project || !pitch || disablePitch}
          color={(isEditMode && !dirty) ? "gray" : "blue"}
          classes="minw-110"
          height={35}
          onClick={submitPitch}
          disabled={isTrue(project?.is_submitted) || (isEditMode && !dirty)}
        >
          {t(isEditMode ? "Save" : "Quick Edit")}
        </Button>
      </div>
      <ProjectPitch
        isDetailPage
        pitchNTasks={pitch}
        setPitchNTasks={(val) => {
          if (!dirty) setDirty(true);
          setPitch(val);
        }}
        disablePitch={disablePitch}
        pitchCompleted={pitchCompleted}
        taskCompleted={taskCompleted}
        challenge_details={project?.challenge_details}
        t={t}
        quickEdit={isEditMode}
      />
    </Card>
  );
};

export default ProjectOverview;
