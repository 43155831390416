import useLabReport from "../../../../store/reports/lab/service-hooks";
import { useEffect, useMemo, useState } from "react";
import DoughnutChartCard from "../../components/chart/DoughnutChartCard";
import useLabReportContext from "../hooks/useLabReportContext";
import EmptyText from "components/UI/atoms/EmptyText";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";

const LabMemberProgress = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { labMemberProgress } = useLabReport();
  const { labSlug } = useLabReportContext();
  const { navigateMemberManagement } = useRedirect();

  /**
   * COMPONENT STATE
   */
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  /**
   * MEMORIZED DATA
   * @type {unknown}
   */
  const chartData = useMemo(() => {
    if (data) {
      const labels = ["Completed", "In progress", "Not Started"].map(d=>t(d,"common"));
      const statsData = [
        data?.completed ?? 0,
        data?.in_progress ?? 0,
        data?.not_started ?? 0,
      ];
      return {
        labels: labels,
        datasets: [
          {
            label: t("Lab Member progress"),
            data: statsData,
            backgroundColor: [
              "rgb(10, 43, 119)",
              "rgb(39, 118, 214)",
              "rgb(134, 205, 253)",
            ],
          },
        ],
      };
    }
    return null;
  }, [data]);

  const legendData = useMemo(() => {
    return {
      data: [
        {
          color: "rgb(10, 43, 119)",
          title: t("Completed","common"),
          count: data?.completed ?? 0,
        },
        {
          color: "rgb(39, 118, 214)",
          title: t("In Progress","common"),
          count: data?.in_progress ?? 0,
        },
        {
          color: "rgb(134, 205, 253)",
          title: t("Not Started","common"),
          count: data?.not_started ?? 0,
        },
        {
          title: t("Total"),
          count:
            (data?.completed ?? 0) +
            (data?.in_progress ?? 0) +
            (data?.not_started ?? 0),
        },
      ],
      total:
        (data?.completed ?? 0) +
        (data?.in_progress ?? 0) +
        (data?.not_started ?? 0),
    };
  }, [data]);

  /**
   * METHODS
   * @returns {Promise<void>}
   */
  const fetchLabMemberProgress = async () => {
    setLoading(true);
    if (labSlug) {
      const response = await labMemberProgress(labSlug);
      if (response) {
        setData(response);
      }
    }
    setLoading(false);
  };

  /**
   * COMPONENT MOUNTED LIFECYCLE
   */
  useEffect(() => {
    fetchLabMemberProgress();
  }, []);

  return (
    <div className="mt-2">
      <DoughnutChartCard
        chartId={"lab-member-progress"}
        title={t("Lab Member progress")}
        data={chartData && legendData?.total ? chartData : null}
        isLoading={loading}
        noDataUI={
          <EmptyText
            isButtonPresent={true}
            onClick={() =>
              navigateMemberManagement(COMPONENT_TYPES.LAB, labSlug)
            }
            buttonLabel={t("Manage Members")}
            icon={ManageAccountsIcon}
          >
            {t("There is no member in this Lab now")}
          </EmptyText>
        }
        legend={
          <>
            <ul
              className={"flex flex-column w-full fs-12 p-0"}
              style={{ gap: "5px" }}
            >
              {legendData?.data.map((item) => (
                <li key={item?.title} className={"flex w-full items-center"}>
                  <div className={"flex items-center"}>
                    <div
                      className={"w-15 h-15"}
                      style={{ backgroundColor: item?.color ?? "" }}
                    ></div>
                    <span className={"ml-2"}>{item?.title}</span>
                  </div>
                  <span className={"ml-auto mr-2"}>{item?.count}</span>
                </li>
              ))}
            </ul>
          </>
        }
      />
    </div>
  );
};

export default LabMemberProgress;
