import React, { useEffect, useState } from "react";
import ListingContainer from "components/UI/organisms/ListingContainer";
import { Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { CheckCircle, Delete, Visibility } from "@mui/icons-material";
import { isTrue } from "helpers/utils/validators";
import ReactPlayer from "react-player";

const VideoList = ({
  videos = [],
  embeddedVideos,
  title,
  onDelete,
  classes,
  setAssetComplete = Function.prototype,
  readOnly,
  t = (d) => d,
}) => {
  const [hover, setHover] = useState(false);
  const [embeddedFile, setEmbeddedFile] = useState({});

  useEffect(() => {
    // Video is embedded
    const iframes = document.querySelectorAll(".iframe-container iframe");

    if (iframes?.length > 0) {
      let tempEmbeddedFile = {};
      iframes.forEach((iframe, index) => {
        tempEmbeddedFile = {
          ...tempEmbeddedFile,
          [embeddedVideos[index]?.id]: { src: iframe.src, title: iframe.title },
        };
      });
      setEmbeddedFile(tempEmbeddedFile);
    }
  }, []);

  const isEmbeddedTypeVideo = (video) => video?.type === "embedded_video";

  const allVideos = [...videos, ...embeddedVideos];
  return (
    <>
      {title && <h2 className="mb-2">{t(title)}</h2>}
      <ListingContainer
        noPagination
        emptyText={t("No videos found!")}
        isEmpty={!allVideos || !allVideos.length}
        classes={`minh-400 ${classes}`}
        emptySmall
      >
        <Grid
          container
          item
          xs={12}
          rowSpacing={2}
          columnSpacing={3}
          className="minh-400"
        >
          {allVideos?.map((video, i) => (
            <Grid
              item
              key={i}
              lg={12}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              className={isEmbeddedTypeVideo(video) ? "minh-400" : ""}
            >
              <div className="flex cursor-pointer">
                <h3
                  className={`mb-2 flex-center ${
                    isTrue(video?.completed) && "c-success"
                  }`}
                >
                  {isTrue(video?.completed) && (
                    <CheckCircle fontSize="small" className="mr-1 c-success" />
                  )}
                  {isEmbeddedTypeVideo(video)
                    ? embeddedFile[video?.id]?.title
                    : video?.title}
                </h3>
                {hover && !readOnly && (
                  <IconButton
                    onClick={() => onDelete(video, isEmbeddedTypeVideo(video) ? "embedded_video" : "videos" )}
                    className="ml-auto"
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                )}
              </div>
              {isEmbeddedTypeVideo(video) && (
                <div
                  className="iframe-container"
                  style={{ display: "none" }}
                  dangerouslySetInnerHTML={{ __html: video?.path }}
                ></div>
              )}
              <ReactPlayer
                controls={true}
                url={
                  isEmbeddedTypeVideo(video)
                    ? embeddedFile[video?.id]?.src
                    : video?.path
                }
                width="100%"
                onStart={() =>
                  setAssetComplete(
                    video?.id,
                    isEmbeddedTypeVideo(video) ? "embedded_video" : "videos"
                  )
                }
              />
            </Grid>
          ))}
        </Grid>
      </ListingContainer>
    </>
  );
};

export default VideoList;

export const VideoGrid = ({ videos, onDelete }) => {
  return (
    <ListingContainer
      isEmpty={!videos?.length}
      emptyText="No videos found!"
      columnSpacing={1}
      rowSpacing={1}
      noPagination
      small
      emptySmall={!videos?.length}
    >
      {videos?.map((video, i) => (
        <Grid
          key={i}
          item
          xs={12}
          md={6}
          lg={4}
          sx={{
            position: "relative",
            "& .imageOverlay": { visibility: "hidden" },
            "&:hover .imageOverlay": { visibility: "visible" },
          }}
          className="cursor-pointer"
        >
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0,
            }}
          >
            <video className="absolute top-0 left-0 w-full h-full object-cover">
              <source src={video?.path} />
            </video>

            <div
              className="imageOverlay flex justify-center w-full h-full items-center absolute top-0 left-0 cursor-pointer"
              style={{
                backgroundColor: "#222222b2",
                gap: "10px",
              }}
            >
              <IconButton
                sx={{ border: "1px solid #dadada50" }}
                onClick={() => window.open(video?.path)}
                className="opacity-100 h-30 w-30 c-white"
              >
                <Visibility fontSize="small" />
              </IconButton>

              <IconButton
                onClick={() => onDelete(video?.id, "videos")}
                sx={{ border: "1px solid #dadada50" }}
                className="opacity-100 h-30 w-30 border"
              >
                <Delete color="error" fontSize="small" />
              </IconButton>
            </div>
          </div>
        </Grid>
      ))}
    </ListingContainer>
  );
};
