import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import { mockRequestGet, mockRequestPost, mockRequestDelete } from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useLabMarketplace from "./service-hook";
import { BrowserRouter } from "react-router-dom";

let getSpy;
let postSpy;
let deleteSpy;

let labMarketPlaceId = "1";
let organizationId = "1";

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
});

const LabMarketplaceServicesMock = () => {
  const { list, add, show, redeem, remove } = useLabMarketplace();

  const BUTTON_LINKS = [
    { name: "list", onClick: () => list({}) },
    { name: "add", onClick: () => add(labMarketPlaceId) },
    { name: "show", onClick: () => show(labMarketPlaceId) },
    { name: "redeem", onClick: () => redeem(labMarketPlaceId, organizationId) },
    { name: "remove", onClick: () => remove(labMarketPlaceId) },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Lab Marketplace services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <LabMarketplaceServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
    deleteSpy = mockRequestDelete();
  });

  test("list", async () => {
    const button = screen.getByTestId("list");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_MARKETPLACE}?language=en&`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("add", async () => {
    const button = screen.getByTestId("add");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_MARKETPLACE}/${labMarketPlaceId}/${ENDPOINTS.ADD}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("show", async () => {
    const button = screen.getByTestId("show");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_MARKETPLACE}/${labMarketPlaceId}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("redeem", async () => {
    const button = screen.getByTestId("redeem");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_MARKETPLACE}/${labMarketPlaceId}/${ENDPOINTS.REDEEM}?language=en`,
        { 
          language :'en',
          organization_id: organizationId,
        },
        headers,
        expect.any(Function)
      )
    );
  });

  test("remove", async () => {
    const button = screen.getByTestId("remove");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_MARKETPLACE}/${labMarketPlaceId}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });
});
