import { SCHEMA_TYPES, TYPE } from "helpers/utils/constants";
import { REQUIRED_VALIDATOR, TITLE_VALIDATOR } from "helpers/utils/validators";

export const schema = [
  {
    title: "Upload Image",
    name: "cover_image",
    type: SCHEMA_TYPES.FILE,
    description: "The image (png, jpg, or jpeg) needs to be at least 735 x 415 pixels.",
    dropboxProps: {
      width: "95%",
      fileType: "image",
      video_code: "iframe_code",
      height: "300px",
      dimensions: [735, 415],
    },
    lg: 12,
  },
  {
    title: "Title",
    name: "title",
    type: SCHEMA_TYPES.INPUT,
    lg: 12,
    validator: TITLE_VALIDATOR,
  },
  {
    title: "Description",
    name: "description",
    type: SCHEMA_TYPES.EDITOR,
    lg: 12,
    validator: REQUIRED_VALIDATOR,
    maxLength: 1000,
  },
  {
    title: "Privacy",
    name: "privacy",
    type: SCHEMA_TYPES.SELECT,
    validator: REQUIRED_VALIDATOR,
    options: [
      { value: "Public", key: "public" },
      { value: "Private", key: "private" },
    ],
  },
  {
    title: "Level",
    name: "level",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Associated Lab",
    name: "lab_ids",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
    multi: true,
  },
  {
    title: "Associated Challenge",
    name: "challenge_ids",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
    multi: true,
  },
  {
    title: "Duration",
    name: "duration",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Type",
    name: "type",
    type: SCHEMA_TYPES.SELECT,
    options: TYPE,
    multi: true,
  },
  {
    title: "Mode",
    name: "mode",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { value: "Team", key: "team" },
      { value: "Individual", key: "individual" },
    ],
    multi: true,
  },
  {
    title: "Resource Skills",
    name: "skills",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Skill Sets",
    name: "skill_stacks",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
  },
  {
    title: "Skill Groups",
    name: "skill_groups",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
  },
];

export const getSchemaByStep = (step) => {
  switch (step) {
    case 0:
      return [0, schema.findIndex((d) => d.name === "skill_groups") + 1];
    case 1:
      return [];
    default:
      return [0, 0];
  }
};

export const stepSchema = (step) => {
  return schema.slice(...getSchemaByStep(step));
};
