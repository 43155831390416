import React, { useEffect, useState } from "react";
import styles from "../chats.module.scss";
import SecureImage from "components/UI/atoms/SecureImage";
import user from "assets/svg/blue/user.svg";
import { Delete, DoneAll } from "@mui/icons-material";
import { getIconForFile } from "pages/resources/ResourceModule/components/ResourceFiles";
import { Box, IconButton } from "@mui/material";
import useRedirect from "helpers/hooks/useRedirect";
import { StyledBadge } from "components/UI/atoms/OnlineAvatar";

const Message = ({
  message,
  isReceived,
  attachments,
  isGroup,
  sender,
  isSeen,
  onDelete,
  username,
  isOnline
}) => {
  const {navigateProfile} = useRedirect();
  return (
    <div>
      <div className={`${!isReceived ? " mr-1 flex-reverse" : "ml-1 flex"}`}>
        {(isGroup || !isGroup) && isReceived && (
          <SecureImage
            classes="w-30 h-30 rounded-50 mt-2"
            placeholder={user}
            src={sender?.profile_image}
            onClick={() => navigateProfile(username)}
          />          
        )}
        <div className={`flex-column maxw-60p ml-1`}>
          {isGroup && isReceived && (
            <p
              className="fs-13 fw-500 mt-2 ml-1"
              style={{ lineHeight: "10px" }}
            >
              {sender?.full_name}
            </p>
          )}
          {!!message && (
            <Box
              className="flex items-right"
              sx={{
                "& .deleteIcon": { visibility: "hidden" },
                "&:hover .deleteIcon": { visibility: "visible" },
              }}
            >
              {!isReceived && (
                <IconButton className="deleteIcon mr-1" onClick={onDelete}>
                  <Delete color="error" fontSize="small" />
                </IconButton>
              )}
              <div
                className={`${
                  styles.message
                } bg-lightgray mx-1 flex my-1 py-2 px-2 rounded-10 fs-14 fw-400 fs-12--tab ${
                  !isReceived && "bg-primary--opaque ml-auto"
                }`}
              >
                <p className=" ml-1 w-100p fs-13 break-word">{message}</p>
              </div>
              {isSeen && !isReceived && (
                <DoneAll className="c-success fs-15  mb-1" />
              )}
            </Box>
          )}
        </div>
      </div>
      <Box
        className={`flex-column ${
          !isReceived
            ? "items-right mr-2"
            : isGroup
            ? "items-left ml-9"
            : "items-left ml-2"
        }`}
      >
        {(attachments || []).map((d) => (
          <Box
            key={d}
            className="flex-center"
            sx={{
              "& .deleteAttachment": { visibility: "hidden" },
              "&:hover .deleteAttachment": { visibility: "visible" },
            }}
          >
            {!isReceived && (
              <IconButton className="deleteAttachment mr-1" onClick={onDelete}>
                <Delete fontSize="small" color="error" />
              </IconButton>
            )}
            <MessageAttachments fileUrl={d} isSeen={isSeen} isReceived={isReceived} />
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default Message;

const MessageAttachments = ({ fileUrl, isSeen, isReceived }) => {
  const [type, setType] = useState();

  useEffect(() => {
    const lastDotIndex = fileUrl?.lastIndexOf(".");
    let extension;
    if (lastDotIndex !== -1) {
      extension = fileUrl.substring(lastDotIndex + 1).toLowerCase();
    }
    switch (extension) {
      case "mp3":
      case "aac":
      case "ogg":
        setType("audio");
        break;
      default:
        setType("image");
    }
  }, []);

  if (!fileUrl) return null;

  return (
    <div className="flex items-right">
      {type === "audio" ? (
        <audio src={fileUrl} controls className="my-1" />
      ) : (
        <div
          className="rounded-5 shadow overflow-hidden relative h-120 w-120 my-1 cursor-pointer"
          onClick={() => window.open(fileUrl)}
        >
          <SecureImage
            src={fileUrl}
            placeholder={getIconForFile(fileUrl)}
            classes="object-cover h-full w-full"
          />
          <div
            className="absolute bottom-0 left-0 w-full h-18 z-9 c-white fs-11 fw-500 text-truncate text-center px-1"
            style={{ background: "#00000080" }}
          >
            {fileUrl?.split("/")?.reverse()[0]}
          </div>
        </div>
      )}
      {isSeen && !isReceived && (
        <DoneAll className="c-success fs-15 ml-1 mb-1" />
      )}
    </div>
  )
};
