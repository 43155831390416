import useRedirect from 'helpers/hooks/useRedirect';
import { useSelector } from 'react-redux';
import { REDUCER_TYPES } from 'store';
import REQUEST from 'store/api';
import { CONTENT_TYPES } from 'store/api';
import { ENDPOINTS } from 'store/api/endpoints';
import { resolveResponse } from 'store/api/utils';
import { serializeObject } from 'store/api/utils';

const useResourceCollections = () => {
    const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);
    const { navigateResourceC } = useRedirect();

    const headers = {
        Authorization: "Bearer " + token,
    };

    //FETCH PUBLIC RESOURCE COLLECTIONS
    const fetchResourceCollections = async (filters = {}) => {
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_COLLECTION
            }?language=${language}&${serializeObject(filters)}`,
            {},
            headers,
            onSuccess
        );
        if (res?.data?.data) {
            const { list, total_pages, total_count } = res?.data?.data;
            return [list, total_pages, total_count];
        }
        return [];
    };

    //FETCH RESOURCE COLLECTION PUBLIC DETAILS API
    const fetchResourceCollection = async (slug) => {
        if (!slug) return;
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_COLLECTION}/${slug}?language=${language}`,
            {},
            headers,
            onSuccess
        );
        if (res?.data?.data) {
            return res?.data?.data;
        }
    };

    //FETCH MANAGE RESOURCE COLLECTION LISTING
    const fetchMyResourceCollections = async (filters) => {
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_COLLECTION
            }?language=${language}&${serializeObject(filters)}`,
            {},
            {
                ...headers,
                "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
            },
            onSuccess
        );
        if (res?.data?.data) {
            const { list, total_pages, total_count } = res?.data?.data;
            return [list, total_pages, total_count];
        }
        return [];
    };

    //FETCH RESOURCE COLLECTION PRIVATE DETAILS API
    const fetchMyResourceCollection = async (slug) => {
        if (!slug) return;
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_COLLECTION}/${slug}?language=${language}`,
            {},
            headers,
            onSuccess
        );
        if (res?.data?.data) {
            return res?.data?.data;
        }
    };

    //DELETE RESOURCE COLLECTION API
    const deleteResourceCollection = async (slug) => {
        if (!slug) return;
        const onSuccess = (res) => resolveResponse(res);

        const client = new REQUEST();
        await client.delete(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_COLLECTION}/${slug}/${ENDPOINTS.DELETE}?language=${language}`,
            headers,
            onSuccess
        );
    };

    //CREATE RESOURCE COLLECTION API
    const create = async (payload) => {
        const body = {
            ...payload,
            status: "publish",
            language,
        };

        const onSuccess = (res) =>
            resolveResponse(
                res,
                (data) => navigateResourceC(data.slug, true),
                "Resource collection created successfully!"
            );

        const client = new REQUEST();
        const res = await client.post(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_COLLECTION}/${ENDPOINTS.CREATE}?language=${language}`,
            body,
            {
                ...headers,
                "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
            },
            onSuccess
        );
        if (res?.data?.data) return res?.data?.data;
        return res;
    };

    //EDIT RESOURCE COLLECTION API
    const edit = async (slug, payload) => {
        if (!slug || !payload) return;
        const body = {
            ...payload,
            status: "publish",
            _method: "put",
            language,
        };

        const onSuccess = (res) =>
            resolveResponse(
                res,
                (data) => navigateResourceC(data.slug, true),
                "Resource collection edited successfully!"
            );

        const client = new REQUEST();
        const res = await client.post(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_COLLECTION}/${slug}/${ENDPOINTS.UPDATE}?language=${language}`,
            body,
            {
                ...headers,
                "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
            },
            onSuccess
        );
        if (res?.data?.data) return res?.data?.data;
        return res;
    };

    return {
        fetchMyResourceCollection,
        fetchMyResourceCollections,
        fetchResourceCollection,
        fetchResourceCollections,
        deleteResourceCollection,
        create,
        edit
    }
}

export default useResourceCollections