import {
  Chip,
  FormControl,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  Pagination,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import Button from "components/Button";
import React, { useEffect, useState } from "react";
import "../dashboard.scss";
import AddBoxIcon from "@mui/icons-material/AddBox";
import useRedirect from "helpers/hooks/useRedirect";
import SecureImage from "components/UI/atoms/SecureImage";
import Divider from "components/Divider";
import { PLAN_NAMES } from "pages/organization/components/upgradePlan/data";
import Input from "components/Input";
import { org } from "assets/svg/blue";

const OrganizationToggle = ({
  openModal,
  onSave,
  onModalClose,
  current,
  organizations = [],
  hasCreateOrgPermissions,
  pages,
  count,
  page,
  setPage,
  search,
  setSearch,
  t = (d) => d,
}) => {
  const { navigateCreateOrg } = useRedirect();
  const [selected, setSelected] = useState(current);
  const [tempSearch, setTempSearch] = useState(search);

  useEffect(() => {
    setSelected(current);
  }, [current]);

  useEffect(() => {
    if (!tempSearch) setSearch("");
  }, [tempSearch]);

  useEffect(() => {
    if (!openModal) {
      setTempSearch("");
    }
  }, [openModal]);

  const onChange = (e) => {
    const id = e.target.value;
    if (!id) return;
    setSelected(organizations?.find((d) => d?.id === id));
  };

  return (
    <Popover
      open={!!openModal}
      anchorEl={openModal}
      onClose={onModalClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      className="mt-10"
      slotProps={{ paper: { sx: { borderRadius: "10px" } } }}
    >
      <div className="w-400 px-5 pt-4 pb-6">
        {(pages > 1 || search) && (
          <Input
            placeholder="Search for an Organization"
            height="30px"
            icon={<SearchOutlined style={{ color: "#5b5d6b" }} />}
            iconLeft
            fullWidth
            width="100%"
            value={tempSearch}
            onChange={(e) => setTempSearch(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") setSearch(tempSearch);
            }}
            t={t}
          />
        )}
        <FormControl className="w-full mt-2" onChange={onChange} size="small">
          <RadioGroup
            defaultValue={current?.id}
            name="radio-buttons-group"
            className="w-full"
          >
            {organizations?.map((item, i) => (
              <FormControlLabel
                key={i}
                className="mt-2 w-full"
                value={item?.id}
                control={<Radio size="small" />}
                checked={selected?.id === item?.id}
                label={
                  <div className="w-full flex justify-between items-center">
                    <div className="w-full flex items-center">
                      <div
                        className={`w-40 h-40 flex justify-center items-center border overflow-hidden rounded-30`}
                      >
                        {" "}
                        <SecureImage
                          src={item.profile_image}
                          placeholder={org}
                          classes="cursor-pointer object-contain rounded-100"
                          key={item?.title}
                          height={30}
                          width={30}
                          alt={item.title}
                          containerClasses="flex items-center justify-center"
                        />
                      </div>
                      <p
                        title={item.title}
                        className="mx-2 text-truncate w-170 tracking-tight fs-15"
                      >
                        {item.title}
                      </p>
                    </div>
                    {item?.plan_name && item?.plan_name !== "NA" && (
                      <Chip
                        variant="outlined"
                        label={t(PLAN_NAMES[item?.plan_name] || "Seed Plan")}
                        sx={{
                          "& .MuiChip-label": {
                            paddingX: "5px",
                          },
                        }}
                        className="mx-1 w-fit h-24 fs-11"
                      />
                    )}
                  </div>
                }
              />
            ))}
          </RadioGroup>
          <Divider classes="w-full mt-3 mb-1" />
          {pages > 1 && (
            <div className="flex justify-between items-center">
              <div>
                <span className="fs-14 fw-500 c-title">
                  {`${page} - ${pages}`}
                </span>
                <span className="fs-14 fw-500 c-gray ">{` ${t("of")} `}</span>
                <span className="fs-14 fw-500 c-title">{`${count}`}</span>
              </div>
              <Pagination
                count={pages}
                page={page}
                onChange={(e, val) => setPage(val)}
                sx={{
                  "& .MuiPaginationItem-root": {
                    margin: "0px",
                    padding: "0px",
                    height: "30px",
                    minWidth: "15px",
                    "&.Mui-selected:hover, &:hover": {
                      backgroundColor: "transparent",
                    },
                    "&.Mui-selected, &:active": {
                      backgroundColor: "transparent",
                    },
                  },
                  "& .MuiPaginationItem-page": {
                    margin: "0px",
                    padding: "0px",
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#8b8b8b",
                  },
                  "& .MuiPaginationItem-page.Mui-selected": {
                    color: "black",
                    backgroundColor: "transparent",
                  },
                  "& .MuiPaginationItem-icon": {
                    color: "#498cce",
                  },
                }}
              />
            </div>
          )}
          {hasCreateOrgPermissions && (
            <div
              className="flex mt-3 cursor-pointer fs-14 items-center"
              onClick={navigateCreateOrg}
            >
              <AddBoxIcon className="c-primary" />
              <p className="ml-1 c-primary fw-500">
                {" "}
                {t("Create new organization")}
              </p>
            </div>
          )}
          <div className="flex justify-between items-center mt-4">
            <Button onClick={onSave(selected)}>{t("Save")}</Button>
          </div>
        </FormControl>
      </div>
    </Popover>
  );
};

export default OrganizationToggle;
