import React from "react";
import Button from "components/Button";
import Input from "components/Input";
import DragWrapper from "components/DragNDrop/DragWrapper";
import { Menu } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid"; // Import UUID library
import styles from "../../../challenges.module.scss";

const CustomTaskForm = ({
  values,
  setValues,
  title,
  description,
  fields,
  setTaskAndQuestionError,
  rowHeight = { lg: 60, sm: 60 },
  buttonColor = "blue",
  buttonLabel = "Add Custom Task",
  disabled,
  t = (d) => d,
}) => {
  const onChangeTask = (e, uuid) => {
    const { name, value } = e.target;
    const updatedTasks = values.map((task) =>
      task.uuid === uuid ? { ...task, [name]: value } : task
    );
    setValues(updatedTasks);
    setTaskAndQuestionError(null);
  };

  const onDelete = (uuid) => {
    const updatedTasks = values.filter((task) => task.uuid !== uuid);
    setValues(updatedTasks);
  };

  const handleAddTask = () => {
    const newTask = {
      uuid: uuidv4(), // Generate a unique UUID for the new task
    };
    setValues([...values, newTask]);
  };

  return (
    <div className={`${styles.task_question__wrapper} w-full`}>
      <h3>{t(title)}</h3>
      <p>{t(description)} </p>
      {values.length > 0 && (
        <div className={`${styles.task_question__container}`}>
          <DragWrapper
            items={values}
            setItems={setValues}
            rowHeight={rowHeight}
            draggable
            isListing
            id="uuid"
          >
            {values.map((customTask) => (
              <div
                key={customTask.uuid}
                className="flex items-center w-full gap-10 py-5"
              >
                <Menu className="drag-handle" />
                <ChallengeTask
                  key={customTask.uuid} // Use unique key for React
                  customTask={customTask}
                  onChange={onChangeTask}
                  onDelete={onDelete}
                  fields={fields}
                  disabled={disabled}
                />
              </div>
            ))}
          </DragWrapper>
        </div>
      )}

      <Button
        onClick={handleAddTask}
        color={buttonColor}
        classes={"w-full my-2"}
        disabled={disabled}
      >
        {t(buttonLabel)}
      </Button>
    </div>
  );
};

export default CustomTaskForm;

const ChallengeTask = ({
  customTask,
  onChange,
  onDelete,
  fields,
  disabled,
}) => {
  return (
    <div className="flex-1 flex items-center gap-10 relative border-b">
      <div className="w-full">
        {fields &&
          fields.map((field, i) => (
            <div className="w-full block pb-2" key={field.name}>
              <Input
                title={`${field.title}`}
                required
                classes="w-full"
                width="99%"
                name={field.name}
                value={customTask[field.name]}
                onChange={(e) => onChange(e, customTask.uuid)}
                disabled={disabled}
              />
            </div>
          ))}
      </div>
      <button
        disabled={disabled}
        className={`${styles.task_question__btn_delete}`}
        onClick={() => onDelete(customTask.uuid)}
      >
        -
      </button>
    </div>
  );
};
