import React, { useCallback } from "react";
import QuickSearchResults from "../results";
import { ClickAwayListener, IconButton, Paper } from "@mui/material";
import lodash from "lodash";
import InputBase from "@mui/material/InputBase";
import { useAdvanceSearchContext } from "../../hooks/useAdvanceSearchContext";
import SearchIcon from "@mui/icons-material/Search";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const QuickSearchInput = () => {
  const { t } = useTranslation("common");
  const {
    quickKeyword,
    setQuickKeyword,
    searchOpen,
    setSearchOpen,
    setKeyword,
    setActiveMenu,
  } = useAdvanceSearchContext();
  const navigate = useNavigate();

  // Memoize debounced function
  const handleSearchChange = useCallback(
    lodash.debounce((value) => {
      setSearchOpen(true);
      setQuickKeyword(value.substring(0, 100));
    }, 1500),
    []
  );

  return (
    <ClickAwayListener onClickAway={() => setSearchOpen(false)}>
      <div className="advance-search-header-component">
        <div>
          <Paper
            sx={{
              p: "1px",
              display: "flex",
              alignItems: "center",
              boxShadow: "none",
              border: "1px solid #eee",
              height: "35px",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: 14 }}
              placeholder={t("Search")}
              inputProps={{ "aria-label": "search" }}
              onFocus={() => {
                if (quickKeyword.length) {
                  setSearchOpen(true);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && quickKeyword?.length) {
                  setKeyword(quickKeyword);
                  setActiveMenu("labs");
                  setSearchOpen(false);
                  navigate(`/advanced-search?keyword=${quickKeyword}`);
                }
              }}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
            <IconButton sx={{ p: "5px" }} aria-label="menu">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        {searchOpen && quickKeyword && (
          <div className="advance-search-header-component--popover">
            <QuickSearchResults t={t} />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default QuickSearchInput;
