import React, { useState, useEffect } from "react";
import ListingContainer from "../../../components/UI/organisms/ListingContainer";
import MainProductCard from "../../../components/Cards/MainProductCard";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import useChallenges from "store/challenges/service-hook";
import { useSearchParams } from "react-router-dom";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import Select from "components/Input/Select";
import ProgressTag from "components/UI/atoms/ProgressTag";
import { SUBMISSION_STATUS } from "helpers/utils/constants";
import Button from "components/Button";
import useRedirect from "helpers/hooks/useRedirect";
const ChallengeSubmissions = ({ challenge }) => {
  const { t } = useLocalizedTranslation();
  const { navigateCreateProject } = useRedirect();
  const [challengeList, setChallengeList] = useState([]);
  const { fetchPublicSubmissionList, fetchManageSubmissionList } =
    useChallenges();
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const isPrivate = params.get("private") === "true";
  const [sortBy, setSortBy] = useState("all");
  const [page, setPage] = useState(1);
  useEffect(() => {
    fetchData();
  }, [sortBy, page]);

  const fetchData = async () => {
    setLoading(true);
    const data = isPrivate
      ? await fetchManageSubmissionList(challenge?.slug, {
          page: page,
          status: sortBy,
        })
      : await fetchPublicSubmissionList(challenge?.slug, {
          page: page,
          status: sortBy,
        });
    setChallengeList(data || {});
    setLoading(false);
  };

  return (
    <>
      <div className="flex justify-between items-center mt-4">
        <h3 className="ml-1">{t("Projects", "common")}</h3>
        <Select
          name="sort"
          value={sortBy}
          width="150px"
          placeholder={t("Sort By", "common")}
          onChange={(e) => setSortBy(e.target.value)}
          keyValues={SUBMISSION_STATUS}
          t={t}
        />
      </div>
      <ListingContainer
        classes="mt-3"
        isEmpty={!challengeList?.list?.length}
        emptyText={t("No submissions yet!")}
        emptyView={
          <div className="flex flex-column text-center items-center my-20">
            <p className="my-4 text-center fs-18 fw-600 c-title w-70p">
              {t(
                "Kick things off by creating your Project Now! You can also browse new projects here once others join in"
              )}
            </p>
            <Button
              color="green"
              classes="fs-18 fw-600"
              onClick={() => navigateCreateProject(challenge?.id)}
            >
              {t("Create Project")}
            </Button>
          </div>
        }
        loading={loading}
        spinLoader
        page={page}
        setPage={setPage}
        count={challengeList?.total_pages}
      >
        {challengeList.list?.map((d, i) => {
          const { slug, title, description, media, project_status } = d;
          return (
            <>
              <MainProductCard
                key={i}
                classes="my-1 ml-4 w-100p"
                title={title}
                subTitle={challenge.title}
                rightAction={
                  <ProgressTag
                    classes="ml-1"
                    progress={{
                      status:
                        project_status === "In Progress"
                          ? "Ongoing"
                          : project_status,
                    }}
                  />
                }
                id={slug}
                description={description}
                image={media}
                noSave
                type={COMPONENT_TYPES.PROJECT}
              />
            </>
          );
        })}
      </ListingContainer>
    </>
  );
};

export default ChallengeSubmissions;
