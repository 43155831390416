import useChallengeReport from "../../../../store/reports/challenges/service-hooks";
import useChallengeReportContext from "../hooks/useChallengeReportContext";
import React, { useEffect, useMemo, useState } from "react";
import { ActiveChartDurationList, ChartColors } from "../../data";
import Select from "../../../../components/Input/Select";
import Card from "../../../../components/Cards/Card";
import { Grid } from "@mui/material";
import LineChartCard from "../../components/chart/LineChartCard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EmptyText from "components/UI/atoms/EmptyText";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import moment from "moment";
import { capitalize } from "lodash";

const ChallengeMemberActivity = ({ t = (d) => d }) => {
  const { challengeMemberActivity } = useChallengeReport();
  const { challengeSlug } = useChallengeReportContext();
  const { navigateMemberManagement } = useRedirect();

  const [chartData, setChartData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [activeChartDuration, setActiveChartDuration] = useState(
    ActiveChartDurationList.last_seven_day
  );
  const [durations] = useState([
    {
      value: t("Last 7 days"),
      key: ActiveChartDurationList?.last_seven_day,
    },
    {
      value: t("Last 4 weeks"),
      key: ActiveChartDurationList?.last_four_weeks,
    },
    {
      value: t("Last 6 months"),
      key: ActiveChartDurationList?.last_six_months,
    },
  ]);
  /**
   *
   * @returns {Promise<void>}
   */
  const fetchChartData = async () => {
    setIsLoading(true);
    if (challengeSlug) {
      const response = await challengeMemberActivity(challengeSlug);
      setChartData(response);
    }
    setIsLoading(false);
  };

  /**
   * COMPONENT MOUNTED
   */
  useEffect(() => {
    fetchChartData();
  }, []);

  const formattedChartData = useMemo(() => {
    return {
      [ActiveChartDurationList.last_four_weeks]: {
        datasets: [
          {
            backgroundColor: ChartColors.member_activity[0],
            borderColor: ChartColors.member_activity[0],
            fill: false,
            data: chartData?.last_4_weeks?.active?.map((item) => item?.value),
            id: "members_count",
            label: "Members",
          },
          {
            backgroundColor: ChartColors.member_activity[1],
            borderColor: ChartColors.member_activity[1],
            fill: false,
            data: chartData?.last_4_weeks?.inactive?.map((item) => item?.value),
            id: "members_count",
            label: "Members",
          },
        ],
        labels: chartData?.last_4_weeks?.active?.map((item) =>
          capitalize(moment(item?.label).format("MMM DD"))
        ),
      },
      [ActiveChartDurationList.last_seven_day]: {
        datasets: [
          {
            backgroundColor: ChartColors.member_activity[0],
            borderColor: ChartColors.member_activity[0],
            fill: false,
            data: chartData?.last_7_days?.active?.map((item) => item?.value),
            id: "members_count",
            label: "Members",
          },
          {
            backgroundColor: ChartColors.member_activity[1],
            borderColor: ChartColors.member_activity[1],
            fill: false,
            data: chartData?.last_7_days?.inactive?.map((item) => item?.value),
            id: "members_count",
            label: "Members",
          },
        ],
        labels: chartData?.last_7_days?.active?.map((item) =>
          capitalize(moment(item?.label).format("MMM DD"))
        ),
      },
      [ActiveChartDurationList.last_six_months]: {
        datasets: [
          {
            backgroundColor: ChartColors.member_activity[0],
            borderColor: ChartColors.member_activity[0],
            fill: false,
            data: chartData?.last_6_months?.active?.map((item) => item?.value),
            id: "members_count",
            label: "Members",
          },
          {
            backgroundColor: ChartColors.member_activity[1],
            borderColor: ChartColors.member_activity[1],
            fill: false,
            data: chartData?.last_6_months?.inactive?.map(
              (item) => item?.value
            ),
            id: "members_count",
            label: "Members",
          },
        ],
        labels: chartData?.last_6_months?.active?.map((item) => t(item?.label)),
      },
    };
  }, [chartData]);

  const legendData = useMemo(() => {
    return [
      {
        color: ChartColors.member_activity[0],
        title: "Active Users",
      },
      {
        color: ChartColors.member_activity[1],
        title: "In-active Users",
      },
    ];
  }, []);
  return (
    <>
      <Card classes={"mt-2"}>
        <div
          className="flex mb-4 items-center"
          style={{ justifyContent: "space-between" }}
        >
          <h4 className="bold fs-16">{t("Member Activity")}</h4>
          <div>
            <Select
              borderedIcon
              value={activeChartDuration}
              onChange={(e) => setActiveChartDuration(e.target.value)}
              keyValues={durations}
              width={"180px"}
              height={40}
              t={t}
            />
          </div>
        </div>
        <Grid container spacing={1} className={"mt-2"}>
          <LineChartCard
            noDataUI={
              <EmptyText
                buttonLabel={t("Manage Members")}
                text={t("There is no member in this Challenge now")}
                icon={ManageAccountsIcon}
                isButtonPresent
                onClick={() =>
                  navigateMemberManagement(
                    COMPONENT_TYPES.CHALLENGE,
                    challengeSlug
                  )
                }
              />
            }
            data={formattedChartData?.[activeChartDuration]}
            chartId={"org-member-7days"}
            isLoading={isLoading}
            noCard
            legendUi={
              <>
                <ul
                  className={" ml-auto flex fs-12 p-0 mr-2"}
                  style={{ gap: "2px", width: "60%" }}
                >
                  {legendData?.map((item) => (
                    <li
                      key={item?.title}
                      className={"flex w-full items-center"}
                    >
                      <div className={"flex items-center"}>
                        <div
                          className={"w-15 h-15"}
                          style={{ backgroundColor: item?.color ?? "" }}
                        ></div>
                        <span className={"ml-2"}>
                          {item?.renderer
                            ? item?.renderer(t(item?.title))
                            : t(item?.title)}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            }
          />
        </Grid>
      </Card>
    </>
  );
};

export default ChallengeMemberActivity;
