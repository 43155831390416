import React, { useState, useEffect } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const initialLayouts = { lg: [], md: [], sm: [] };
const LAYOUT_CONFIG = {
  dashboard: {
    HEIGHTS: { lg: 775, md: 430, sm: 300 },
    BREAKPOINTS: { lg: 1300, md: 500, sm: 0 },
    COLS: { lg: 3, md: 2, sm: 1 },
    rowHeight: 20,
    margin: [20, 20],
  },
  listing: {
    BREAKPOINTS: { lg: 1300, md: 500, sm: 0 },
    COLS: { lg: 1, md: 1, sm: 1 },
    margin: [0, 20],
    containerPadding: [0, 0],
  },
};

const DragWrapper = ({
  items,
  setItems,
  draggable,
  children,
  isListing,
  rowHeight,
  id = "uuid",
}) => {
  const [layouts, setLayouts] = useState(initialLayouts);
  const config = isListing
    ? LAYOUT_CONFIG["listing"]
    : LAYOUT_CONFIG["dashboard"];
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");

  useEffect(() => {
    const newLayouts = generateLayout();
    setLayouts(newLayouts);
  }, [items, currentBreakpoint]);

  const onBreakpointChange = (newBreakpoint) => {
    setCurrentBreakpoint(newBreakpoint);
  };

  const getRowHeight = () =>
    isListing
      ? currentBreakpoint === "sm"
        ? rowHeight.sm
        : rowHeight.lg
      : config.rowHeight;

  const calculateHeightInUnit = (size) =>
    (config.HEIGHTS?.[size || "md"] + config.rowHeight) /
    (config.rowHeight * 2);

  const generateLayout = () => {
    const layout = {};
    const generate = (l=[]) => {
      const maxWidth = 3;
      let x = 0;
      let y = 0;
      let list = [];
      for (const item of l) {
        if (x + item.w > maxWidth) {
          x = 0;
          y += 1;
        }
        list.push({
          ...item,
          x: x,
          y: y,
        });

        x += item.w;
      }
      return list;
    };
    for (const breakpoint in config.BREAKPOINTS) {
      layout[breakpoint] = [];
      const cols = config.COLS[breakpoint];
      items.forEach((item, i) => {
        const newHeight = isListing ? 1 : calculateHeightInUnit(item?.size);
        layout[breakpoint].push({
          x: i % cols,
          y: Math.floor(i / cols),
          w: item?.width ?? 1,
          h: newHeight,
          i: item?.[id],
        });
      });
      if(isListing) continue;
      layout[breakpoint] = generate(layout[breakpoint]);
    }
    return layout;
  };

  const onDragStop = (layout) => {
    const sortedLayout = [...layout].sort((a, b) => a.y - b.y || a.x - b.x);
    const newItemsOrder = sortedLayout.map((l) =>
      items.find((item) => item[id] === l.i)
    );
    setItems(newItemsOrder);
  };

  return (
    <ResponsiveReactGridLayout
      layouts={layouts}
      className="layout"
      cols={config.COLS}
      isResizable={false}
      margin={config.margin}
      rowHeight={getRowHeight()}
      isDraggable={draggable}
      breakpoints={config.BREAKPOINTS}
      onDragStop={onDragStop}
      useCSSTransforms={true}
      containerPadding={config.containerPadding}
      onBreakpointChange={onBreakpointChange}
      draggableHandle=".drag-handle" // Add this line to specify the handle
    >
      {children}
    </ResponsiveReactGridLayout>
  );
};
export default DragWrapper;
