import { Grid } from "@mui/material";
import MainProductCard from "components/Cards/MainProductCard";
import { organization_data } from "pages/organization/forms/data";
import React from "react";

const dummy_search_listing = [
  { id: 1, type: "org", rating: 2, likes: 999, share: 999, views: 999 },
  { id: 3, type: "user", subTitle: "User · @matthewalmeria" },
  {
    id: 2,
    type: "lab",
    subTitle: "Private Lab · Open until 12/03/2021  ",
    rating: 2,
    likes: 999,
    share: 999,
    views: 999,
  },
  { id: 4, type: "resource", rating: 2, likes: 999, share: 999, views: 999 },
  { id: 5, type: "resource", rating: 2, likes: 999, share: 999, views: 999 },
  {
    id: 6,
    type: "challenge",
    subTitle: "Private Challenge · Open until 12/03/2021  ",
    rating: 2,
    likes: 999,
    share: 999,
    views: 999,
  },
  {
    id: 7,
    type: "challenge",
    subTitle: "Private Challenge · Open until 12/03/2021  ",
    rating: 2,
    likes: 999,
    share: 999,
    views: 999,
  },
];

const SearchListing = ({ gridType }) => {
  return (
    <Grid container className="mt-5" columnSpacing={3}>
      {dummy_search_listing.map((d) => {
        const lab = organization_data?.labs[0];
        const { title, description } = lab;
        const subTitle = " · Organization Name";
        return (
          <Grid item key={d.id} xs={gridType === 1 ? 12 : 6}>
            <MainProductCard
              classes="mb-4"
              title={title}
              subTitle={d?.subTitle || subTitle}
              description={description}
              type={d.type}
              rating={d?.rating}
              noSave
              col={gridType === 1 ? false : true}
              likes={d.likes}
              share={d.share}
              views={d.views}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SearchListing;
