import useRedirect from "helpers/hooks/useRedirect";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { serializeObject } from "store/api/utils";
import { resolveResponse } from "store/api/utils";

const useChallengePaths = () => {

    const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);
    const { navigateChallengePath } = useRedirect();

    const headers = {
        Authorization: "Bearer " + token,
    };

    //FETCH PUBLIC CHALLENGE PATHS
    const fetchChallengePaths = async (filters = {}) => {
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE_PATH
            }?language=${language}&${serializeObject(filters)}`,
            {},
            headers,
            onSuccess
        );
        if (res?.data?.data) {
            const { list, total_pages } = res?.data?.data;
            return [list, total_pages];
        }
        return [];
    };

    //FETCH CHALLENGE PATH PUBLIC DETAILS API
    const fetchPublicChallengePath = async (slug) => {
        if (!slug) return;
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE_PATH}/${slug}?language=${language}`,
            {},
            headers,
            onSuccess
        );
        if (res?.data?.data) {
            return res?.data?.data;
        }
    };

    //FETCH MANAGE CHALLENGE PATH LISTING
    const fetchPrivateChallengePaths = async (filters) => {
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_PATH
            }?language=${language}&${serializeObject(filters)}`,
            {},
            {
                ...headers,
                "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
            },
            onSuccess
        );
        if (res?.data?.data) {
            const { list, total_pages } = res?.data?.data;
            return [list, total_pages];
        }
        return [];
    };

    //FETCH CHALLENGE PATH PRIVATE DETAILS API
    const fetchChallengePath = async (slug) => {
        if (!slug) return;
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_PATH}/${slug}?language=${language}`,
            {},
            headers,
            onSuccess
        );
        if (res?.data?.data) {
            return res?.data?.data;
        }
    };

    //DELETE CHALLENGE PATH API
    const deleteChallengePath = async (slug) => {
        if (!slug) return;
        const onSuccess = (res) => resolveResponse(res);

        const client = new REQUEST();
        await client.delete(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_PATH}/${slug}/${ENDPOINTS.DELETE}?language=${language}`,
            headers,
            onSuccess
        );
    };

    //CREATE CHALLENGE PATH API
    const create = async (payload) => {
        const body = {
            ...payload,
            language,
        };

        const onSuccess = (res) =>
            resolveResponse(
                res,
                (data) => navigateChallengePath(data?.slug, true),
                "Challenge path created successfully!"
            );

        const client = new REQUEST();
        const res = await client.post(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_PATH}/${ENDPOINTS.CREATE}?language=${language}`,
            body,
            {
                ...headers,
                "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
            },
            onSuccess
        );
        return res;
    };

    //EDIT CHALLENGE PATH API
    const edit = async (slug, payload) => {
        if (!slug || !payload) return;
        const body = {
            ...payload,
            language,
        };

        const onSuccess = (res) =>
            resolveResponse(
                res,
                () => navigateChallengePath(slug, true),
                "Challenge path edited successfully!"
            );

        const client = new REQUEST();
        const res = await client.post(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_PATH}/${slug}/${ENDPOINTS.UPDATE}?language=${language}`,
            body,
            {
                ...headers,
                "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
            },
            onSuccess
        );
        return res;
    };

    return {
        fetchChallengePaths,
        fetchChallengePath,
        deleteChallengePath,
        fetchPrivateChallengePaths,
        fetchPublicChallengePath,
        create,
        edit
    }

}

export default useChallengePaths