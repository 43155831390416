import React from "react";
import styles from "./divider.module.css";
import cx from "classnames";

const Divider = ({ text, classes }) => {
  return (
    <div data-testid="divider" className={cx('flex items-center justify-between', styles.divider, classes)}>
      <div className="border-t"></div>
      {!!text && <p className="c-title fw-500 my-0 mx-2">{text}</p>}
      <div className="border-t"></div>
    </div>
  );
};

export default Divider;
