import { useState, useEffect, useRef } from "react";

const useAudio = (src, onPlay = () => {}) => {
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef(new Audio(src));

  useEffect(() => {
    const audio = audioRef.current;

    const updateProgress = () => {
      if (audio) {
        setProgress((audio.currentTime / audio.duration) * 100 || 0);
      }
    };

    const resetAudio = () => {
      setProgress(0);
      setPlaying(false);
    };

    if (audio) {
      audio.addEventListener("timeupdate", updateProgress);
      audio.addEventListener("ended", resetAudio);
    }

    return () => {
      if (audio) {
        audio.removeEventListener("timeupdate", updateProgress);
        audio.removeEventListener("ended", resetAudio);
      }
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (audio) {
      if (playing) {
        audio.pause();
      } else {
        audio.play();
      }
      setPlaying(!playing);
      onPlay();
    }
  };

  const seek = (newProgress) => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = (newProgress / 100) * audio.duration;
      setProgress(newProgress);
    }
  };

  const forward = (seconds = 5) => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = Math.min(audio.currentTime + seconds, audio.duration);
    }
  };

  const backward = (seconds = 5) => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = Math.max(audio.currentTime - seconds, 0);
    }
  };

  return { audioRef, playing, progress, togglePlayPause, seek, forward, backward };
};

export default useAudio;
