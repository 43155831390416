import React, { useEffect, useState } from "react";
import RichTextEditor from "react-rte";
import styles from "./input.module.scss";
import { LockIcon } from "components/Statics";
import cx from "classnames";

const Editor = ({
  name,
  value,
  onChange,
  error,
  disabled,
  title,
  required,
  locked,
  t = (d) => d,
  ...rest
}) => {
  const [text, setText] = useState(RichTextEditor.createEmptyValue());
  const onTextChange = (e) => {
    setText(e);
    const content = e.toString("html");
    if (content === "<p><br></p>" && content.length === 13) {
      onChange(null);
    } else {
      onChange(e.toString("html"));
    }
  };

  useEffect(() => {
    if (!value) {
      setText(RichTextEditor.createEmptyValue());
    }
  }, [value]);

  // Set initial value
  useEffect(() => {
    if (value) {
      setText(RichTextEditor.createValueFromString(value, "html"));
    }
  }, []);

  return (
    <div data-testid="textbox" className={cx(styles.inputContainer)}>
      {!!title && (
        <p className={`${styles.title} flex items-center}`}>
          {t(title)}
          {!!required && <span>*</span>}
          {locked && <LockIcon />}
        </p>
      )}
      <RichTextEditor
        name={name}
        editorClassName="minh-100"
        className={error && "border-error"}
        editorStyle={{
          height: "100px",
        }}
        value={text}
        onChange={onTextChange}
        readOnly={disabled}
      />
      {error && <p className={styles.error}>{t(error, "common")}</p>}
    </div>
  );
};

export default Editor;
