import Card from "components/Cards/Card";
import ListingPageHeader from "components/UI/organisms/ListingPageHeader";
import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import useChallenges from "store/challenges/service-hook";
import { useParams } from "react-router-dom";
import SubmissionList from './SubmissionList';
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useProjects from "store/projects/service-hook";
import { SUBMISSION_STATUS } from "helpers/utils/constants";
import Button from "components/Button";

const ProjectSubmission = () => {
  const {
    deleteProject,
    submit,
  } = useProjects();
  const { t } = useLocalizedTranslation();
    const { slug } = useParams();
    const { fetchManageSubmissionList, unSubmitProject } = useChallenges();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState("all");
    const [search, setSearch] = useState("");
    const [submissions, setSubmissions] = useState([]);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [selectedSlug, setSelectedSlug] = useState(null);
    const [openSubmitConfirmationModal, setOpenSubmitConfirmationModal] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
      fetchData();
    }, [page, sortBy, search]);


    useEffect(() => {
      setPage(1);
    }, [search,sortBy]);


    const fetchData = async () => {
      setLoading(true);
      const data =  await fetchManageSubmissionList(slug, {
              page: page,
              status: sortBy,
              search
          })
        
      setSubmissions(data || {});
      setLoading(false);
    };

    const cancelExitConfirmationModal = () =>{
      setOpenConfirmationModal(false);
      setSelectedSlug(null);
      setOpenSubmitConfirmationModal(false);
    }

    const handleDeleteProject = async () => {
      await deleteProject(selectedSlug);
      setOpenConfirmationModal(false);
      fetchData();
      setSelectedSlug(null);
    };


    const handleProjectSubmit = async () => {
      let res;
      if(isSubmitting) {
      res = await submit(selectedSlug);
      }else {
        res = await unSubmitProject(selectedSlug);
      }
      cancelExitConfirmationModal();
      if (res) {
        fetchData();
      }
    };

  return (
    <>
      <DashboardLayout>
        <div>
            <div className="container mt-10 mb-10">
                <Card classes={"mb-3 mt-20"}>
                  <div className="flex justify-center relative">
                    <Button classes="absolute left-3" bordered onClick={() => window.history.back()}>{t("Cancel")}</Button>
                    <h2 className="text-center">{t('Project Submission')}</h2>
                  </div>
                </Card>
                <ListingPageHeader noGrid search={search} setSearch={setSearch} sort={sortBy} setSort={setSortBy} sortOptions={SUBMISSION_STATUS} />
                <Card classes={"my-3 px-2 py-2"}>
                    <SubmissionList 
                        submissions={submissions}
                        loading={loading}
                        onDelete={(slug) => {
                            setOpenConfirmationModal(true);
                            setSelectedSlug(slug);
                        }}
                        onSubmit ={(slug, status) => {
                           setOpenSubmitConfirmationModal(true);
                           setSelectedSlug(slug);
                           setSubmitting(status);
                        }}
                        unSubmit={(slug) => {
                            setOpenSubmitConfirmationModal(true);
                            setSelectedSlug(slug);
                        }}
                        page={page}
                        setPage={setPage}
                    />
                </Card>
            </div>
        </div>
    
      </DashboardLayout>

        {/* Delete Confirmation Modal */}
         <ConfirmationModal
          open={openConfirmationModal}
          onClose={cancelExitConfirmationModal}
          heading={t('Do you want to delete this project?')}
          desc={t(
            `It will be removed from database and you are not able to recover it.`
          )}
          okayButtonTitle={t(`Yes, Delete now`)}
          onOkay={handleDeleteProject}
        />

        {/* submit Confirmation Modal */}
        <ConfirmationModal
          open={openSubmitConfirmationModal}
          onClose={cancelExitConfirmationModal}
          heading={`Do you want to ${isSubmitting ? 'Submit' : 'Unsubmit'} this project?`}
          desc={t(
            `You can make changes to the Challenge when there is no project submission.`
          )}
          okayButtonTitle={t(
            `Yes, ${isSubmitting ? 'Submit' : 'Unsubmit'} now`
          )}
          onOkay={handleProjectSubmit}
        />

    
    </>
    
  );
};


export default ProjectSubmission;
