import React from "react";
import { Error } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const SubscriptionAlert = ({ plan }) => {
  const { t } = useTranslation("common");
  const checkLimits = (data, limitsArray) => {
    const limitsReached = limitsArray
      .filter((item) => {
        const limitKey = `${item.key}_limit`;
        const countKey = `${item.key}_count`;

        // Check if the count has reached the limit (ignore "UnLimited")
        return (
          data[limitKey] !== "Unlimited" &&
          data[countKey] >= parseInt(data[limitKey])
        );
      })
      .map((item) => t(item.name || item.key)); // Use `name` if available, otherwise fallback to `key`

    return limitsReached.length > 0
      ? `${limitsReached.join(", ")} ${t("limit")}.`
      : null;
  };

  const checkSubscriptionExpired = (plan) => {
    if (!plan.plan_end_date) return false; // If no end date, assume it's active
    const currentDate = new Date();
    const endDate = new Date(plan.plan_end_date);
    return currentDate > endDate;
  };

  const components = [
    { key: "lab", name: "Lab" },
    { key: "challenge", name: "Challenge" },
    { key: "resource_module", name: "Resource Module" },
  ];

  const entities = [
    { key: "user_invite", name: "User Invites" },
    { key: "manager", name: "Managers" },
  ];

  const resourceLimit = checkLimits(plan, components);
  const userManagerLimit = checkLimits(plan, entities);
  const isSubscriptionExpired = checkSubscriptionExpired(plan);

  return (
    <div
      className="container mt-20"
      style={{
        marginBottom: "-4rem",
      }}
    >
      {isSubscriptionExpired && (
        <ErrorCard
          title={`Your ${plan.plan_name} has expired.`}
          description={
            "Please upgrade your existing plan if you wish to continue to use it. If you do not have permission to change your plan, contact your organization administrator."
          }
        />
      )}
      {[resourceLimit, userManagerLimit]
        .filter(Boolean) // Only render cards for limits that are reached
        .map((limit, index) => (
          <ErrorCard
            key={index}
            title={t("You have reached your ${limit}", { limit: limit })}
            description={t(
              "Please upgrade your existing plan or purchase an add-on. If you do not have permission to change your plan, contact your organization administrator."
            )}
          />
        ))}
    </div>
  );
};

export default SubscriptionAlert;

const ErrorCard = ({ title, description }) => {
  return (
    <div className="border border-error rounded-10 bg-red--opaque py-3 mb-2 px-3">
      <div className="flex items-center">
        <Error className="c-error" />
        <div>
          <p className="ml-2 fw-600">{title}</p>
          {description && <p className="ml-2 fs-12">{description}</p>}
        </div>
      </div>
    </div>
  );
};
