import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { serializeObject, resolveResponse } from "store/api/utils";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

const useChats = () => {
  const { language, token, user } = useSelector(
    (state) => state[REDUCER_TYPES.AUTH]
  );

  const headers = {
    Authorization: "Bearer " + token,
  };

  //CHATS LISTING API
  const list = async (type = "inbox", filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.CHAT}/${
        ENDPOINTS.CONVERSATION
      }/${type}?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      const { list, total_pages, total_count } = res?.data?.data;
      return [list, total_pages, total_count];
    }
    return [];
  };

  //CREATE CHAT API
  const create = async (payload) => {
    if (!payload) return;
    const body = {
      ...payload,
      language,
      type: "message",
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${ENDPOINTS.CREATE}?language=${language}`,
      body,
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  const action = async (uuid, action, resolveResponse) => {
    if (!uuid || !action) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${uuid}/${action}?language=${language}`,
      {},
      headers,
      resolveResponse,
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //MARK CHAT AS SEEN API
  const markSeen = async (uuid) => {
    if (!uuid) return;
    const res = await action(uuid, "seen");
    return res;
  };

  //ARCHIVE CHAT API
  const archive = async (uuid) => {
    if (!uuid) return;
    const res = await action(uuid, "archive", resolveResponse);
    return res;
  };

  //UNARCHIVE CHAT API
  const unarchive = async (uuid) => {
    if (!uuid) return;
    const res = await action(uuid, "un-archive", resolveResponse);
    return res;
  };

  //DELETE CHAT API
  const deleteChat = async (uuid) => {
    if (!uuid) return;
    const res = await action(uuid, "delete", resolveResponse);
    return res;
  };

  const setOnlineOffline = async (isOffline) => {
    if (!user || !user?.id) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${ENDPOINTS.USER}/${
        user?.id
      }/${
        isOffline ? ENDPOINTS.OFFLINE : ENDPOINTS.ONLINE
      }?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) return res?.data?.data;
  };

  // SET ONLINE API
  const setOnline = async () => await setOnlineOffline();

  //SET OFFLINE API
  const setOffline = async () => await setOnlineOffline(true);

  //GET/VIEW CHAT API
  const getChat = async (uuid) => {
    if (!uuid) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${uuid}/${ENDPOINTS.MESSAGE}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return [];
  };

  //SEND MESSAGE API
  const sendMessage = async (uuid, payload) => {
    if (!payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${uuid}/${ENDPOINTS.MESSAGE}/${ENDPOINTS.CREATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      Function.prototype
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //DELETE MESSAGE API
  const deleteMessage = async (uuid) => {
    if (!uuid) return;
    const client = new REQUEST();
    const res = await client.delete(
      `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${ENDPOINTS.MESSAGE}/${uuid}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      resolveResponse
    );
    return res?.data;
  };

  const socket = () => {
    const wSocket = new Echo({
      broadcaster: "pusher",
      key: "preprwebsocket",
      cluster: "mt1",
      wsHost: "betaapis.learnlab.ai",
      wssPort: "6001",
      forceTLS: true,
      encrypted: false,
      authEndpoint:`${process.env.REACT_APP_CHAT_BASE_URL}${ENDPOINTS.SOCKET}`,
      bearerToken: token,
    });
    return wSocket;
  };

  return {
    list,
    create,
    markSeen,
    archive,
    unarchive,
    deleteChat,
    setOnline,
    setOffline,
    getChat,
    sendMessage,
    deleteMessage,
    socket,
  };
};

export default useChats;
