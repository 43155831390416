import React, { useEffect, useState } from "react";
import useRedirect from "helpers/hooks/useRedirect";
import BrowseWrapper from "components/UI/organisms/BrowseWrapper";
import useListingHeader from "helpers/hooks/useListingHeader";
import useSkills from "store/skills/service-hook";
import SkillListing from "./SkillsListing";
import useAuth from "store/auth/service-hook";

const browse_options = [
  {
    id: 1,
    title: `Browse Skills`,
    pageTitle: "Browse Skills",
    isPublic: true,
  },
  {
    id: 2,
    title: `Saved Skills`,
    pageTitle: "Your saved Skills",
  },
  {
    id: 3,
    title: "Pinned Skills",
    pageTitle: "Your pinned Skills",
  },
];

const SORT_OPTIONS = [
  {
    value: "Name A to Z",
    key: "name-a-to-z",
  },
  {
    value: "Name Z to A",
    key: "name-z-to-a",
  },
];

const BrowseSkills = () => {
  const [browseMode, setBrowseMode] = useState(0);
  const state = useListingHeader();
  const [skills, setSkills] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { navigateManageLabs } = useRedirect();
  const { list, mySkills } = useSkills();
  const {
    state: { isLoggedIn },
  } = useAuth();

  useEffect(() => {
    const abortController = new AbortController();
    handleFetchSkills(abortController);
    return () => abortController.abort();
  }, [page, state.sort, state.search, browseMode]);

  const handleFetchSkills = async (abortController) => {
    setLoading(true);
    const filters = {
      page,
      sort_by: state.sort,
      search: state.search,
    };
    if (browseMode === 2) {
      filters["pinned"] = "yes";
    }
    const [items = [], count = 0] =
      browseMode === 0 ? await list(filters) : await mySkills(filters);

    if (!abortController.signal.aborted) {
      setSkills(items || []);
      setPages(count || 0);
      setLoading(false);

      if (page > 1 && (!items || items.length === 0)) {
        setPage(prev => prev - 1);
      }
    }
  };

  return (
    <BrowseWrapper
      browseMode={browseMode}
      browseOptions={browse_options.filter((d) => !!isLoggedIn || d?.isPublic)}
      isBrowsePublic
      {...state}
      setBrowseMode={setBrowseMode}
      manageNavigate={navigateManageLabs}
      noAction
      noFilters
      sortOptions={SORT_OPTIONS}
    >
      <SkillListing
        browseMode={browseMode}
        gridType={state.gridType}
        skills={skills}
        loading={loading}
        pageCount={pages}
        page={page}
        setPage={setPage}
        setSkills={setSkills}
        manage={browseMode === 1 || browseMode === 2}
        isLoggedIn={isLoggedIn}
      />
    </BrowseWrapper>
  );
};

export default BrowseSkills;
