import { Chip, Container, Grid } from "@mui/material";
import React from "react";
import { INTEGRATIONS } from "../data";
import Button from "components/Button";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const IntegrationSettings = ({
  ssoIntegrations,
  hideTitle,
  toggle = Function.prototype,
}) => {
  const { t } = useLocalizedTranslation();

  return (
    <Container px={{ xs: 0, md: 2 }} my={2}>
      {!hideTitle && (
        <h3 className="mt-2">{t("Authentication Integrations")}</h3>
      )}
      <Grid
        container
        className="fs-14 opacity-50 fs-12--mob mb-1"
        mt={hideTitle ? 2 : 4}
      >
        <Grid pl={1} item xs={6}>
          <p>{t("Application Name")}</p>
        </Grid>
        <Grid pl={1} item xs={3}>
          <p>{t("Integration Status")}</p>
        </Grid>
        <Grid pl={1} item xs={3}>
          <p>{t("Interaction")}</p>
        </Grid>
      </Grid>
      {INTEGRATIONS.map(({ title, id, icon }, i) => {
        const isActive = ssoIntegrations[id] === "active";
        return (
          <Grid
            container
            key={i}
            py={1.5}
            className="border-t"
            alignItems="center"
          >
            <Grid item xs={8} sm={6}>
              <div className="flex items-center">
                <img src={icon} alt={title} />
                <p className="ml-2">{title}</p>
              </div>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Chip
                className={`h-25 py-1 ${
                  isActive ? "c-primary bg-primary-light" : ""
                }`}
                label={isActive ? t("Activated") : t("Not Active")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              sx={{
                margin: { xs: "10px auto" },
              }}
            >
              <Button
                classes={isActive && "border-none c-error underline"}
                bordered
                height="30px"
                onClick={toggle(id, isActive)}
              >
                {isActive ? t("Deactivate") : t("Activate")}
              </Button>
            </Grid>
          </Grid>
        );
      })}
    </Container>
  );
};

export default IntegrationSettings;
