import useRedirect from "helpers/hooks/useRedirect";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { resolveResponse, serializeObject } from "store/api/utils";
import { REDUCER } from "./reducer";
import ACTIONS from "./action-types";
import { useDispatch } from "react-redux";

const useGO1 = () => {
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);
  const state = useSelector((state) => state[REDUCER_TYPES.GO1]);
  const { navigateResource } = useRedirect();
  const dispatch = useDispatch();

  const headers = {
    Authorization: "Bearer " + token,
  };

  //GO1 TOPICS API
  const topics = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.GO1}/${ENDPOINTS.FILTER}/${ENDPOINTS.TOPICS}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      let data = res?.data?.data?.map((d) => ({
        key: d?.label,
        value: d?.label,
        metadata: d?.doc_count,
      }));
      dispatch(REDUCER[ACTIONS.TOPICS](data));
      return data;
    }
    return [];
  };

  //GO1 PROVIDERS API
  const providers = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.GO1}/${ENDPOINTS.FILTER}/${ENDPOINTS.PROVIDERS}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      let data = res?.data?.data?.map((d) => ({
        key: d?.key,
        value: d?.name,
        metadata: d?.doc_count,
      }));
      dispatch(REDUCER[ACTIONS.PROVIDERS](data));
      return data;
    }
    return [];
  };

  //GO1 RESOURCE LISTING API
  const list = async (filters = {}) => {
    const allFilters = { language, ...filters };
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.GO1}/${ENDPOINTS.RESOURCE}?${serializeObject(allFilters)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      const { list, total_pages, total_count, current_page } = res?.data?.data;
      return [list, total_pages, total_count, current_page];
    }
    return [];
  };

  //GO1 CREATE API
  const create = async (payload) => {
    if (!payload) return;
    const body = {
      go1_course: {
        ...payload,
        language,
      },
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.GO1}/${ENDPOINTS.RESOURCE}/${ENDPOINTS.CREATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.APPLICATION_JSON,
      },
      (res) =>
        resolveResponse(res, (data) => navigateResource(data?.slug, true))
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //GO1 PLAY API
  const play = async (slug) => {
    if (!slug) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.GO1}/${ENDPOINTS.RESOURCE}/${slug}/${ENDPOINTS.PLAY}?language=${language}`,
      {},
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  return {
    state,
    topics,
    providers,
    list,
    create,
    play,
  };
};

export default useGO1;
