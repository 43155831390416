import PageTitleCard from "../../../../components/UI/molecules/PageTitleCard";
import useLabReportContext from "../hooks/useLabReportContext";
import useRedirect from "../../../../helpers/hooks/useRedirect";
import React, { useMemo, useState } from "react";
import { isTrue } from "../../../../helpers/utils/validators";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ReactComponent as LevelIcon } from "assets/svg/blue/level.svg";
import { ReactComponent as UsersIcon } from "assets/svg/blue/users.svg";
import { ReactComponent as ChallengeIcon } from "assets/svg/green/challenge.svg";
import { ReactComponent as ResourceIcon } from "assets/svg/purple/resource.svg";
import { ReactComponent as ManageAccountIcon } from "assets/svg/blue/manage-accounts.svg";
import { ReactComponent as DeleteIcon } from "assets/svg/delete-forever.svg";
import { Grid } from "@mui/material";
import Button from "../../../../components/Button";
import MenuButton from "../../../../components/Button/MenuButton";
import { COMPONENT_TYPES } from "../../../../helpers/utils/constants";
import { Edit, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";
import useLabs from "../../../../store/labs/service-hook";
import useLabReport from "../../../../store/reports/lab/service-hooks";

const LabReportDetails = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { labDetails, labSlug } = useLabReportContext();
  const { navigateLab, navigateMemberManagement, navigateEditLab } =
    useRedirect();
  const navigate = useNavigate();
  const { deleteLab } = useLabs();
  const { downloadLabReport } = useLabReport();
  const [downloading, setDownloading] = useState(false);

  /**
   * COMPONENT STATE
   */
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [labToDelete, setLabToDelete] = useState(null);

  /**
   * METHODS
   */

  const openExitConfirmationModal = (slug) => {
    setLabToDelete(slug);
    setOpenConfirmationModal(true);
  };

  const cancelExitConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setLabToDelete(null);
  };

  const downloadReport = async () => {
    setDownloading(true);
    if (labSlug) await downloadLabReport(labSlug);
    setDownloading(false);
  };

  /**
   * COMPUTED PROPERTIES
   */
  const labStats = useMemo(() => {
    return [
      {
        icon: <VisibilityIcon className={"w-40 h-40"} color="info" />,
        title: isTrue(labDetails?.privacy) ? t("Private Lab") : t("Public Lab"),
        subtitle: t("Free"),
      },
      (labDetails?.level && labDetails?.duration) && {
        icon: <LevelIcon className={"w-40 h-40"} color="info" />,
        title: labDetails?.level?.title,
        subtitle: labDetails?.duration?.title,
      },
      {
        icon: <UsersIcon className={"w-40 h-40"} />,
        title: t("Members Joined"),
        subtitle: labDetails?.members_count ?? 0,
      },
      {
        icon: <ChallengeIcon className={"w-40 h-40"} />,
        title: t("Challenges", "common"),
        subtitle: labDetails?.challenges_count ?? 0,
      },
      {
        icon: <ResourceIcon className={"w-40 h-40"} />,
        title: t("Resources", "common"),
        subtitle: labDetails?.resource_modules_count ?? 0,
      },
    ].filter(Boolean);
  }, [labDetails]);

  return (
    <PageTitleCard noAction noContainer classes={"mx-8"}>
      <Grid
        container
        spacing={2}
        className="ml-0 w-full"
        style={{ justifyContent: "space-between", alignItem: "center" }}
      >
        <Grid item xs={12} md={2}>
          <Button bordered color={"blue"} onClick={() => navigate(-1)}>
            {t("Back", "common")}
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={"w-full"}>
            <h2 className={`fs-23 text-center`}>{t("Lab Report")}</h2>
            <p
              className={"c-primary bold text-center mt-2 fs-16 cursor-pointer"}
              onClick={() => navigateLab(labDetails?.slug)}
            >
              {labDetails?.title ?? ""}
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={2}>
          <div className="flex" style={{ gap: "2px" }}>
            <MenuButton
              listWithTitleonClick={[
                {
                  title: "Manage Members",
                  onClick: () =>
                    navigateMemberManagement(
                      COMPONENT_TYPES.LAB,
                      labDetails?.slug
                    ),
                  icon: ManageAccountIcon,
                },
                {
                  title: "Edit",
                  onClick: () => navigateEditLab(labDetails?.slug),
                  icon: Edit,
                },
                {
                  title: "Delete",
                  labelClasses: "text-danger",
                  onClick: () => openExitConfirmationModal(labDetails?.slug),
                  icon: DeleteIcon,
                },
              ]}
            >
              <Settings className="mr-1" fontSize="small" />{" "}
              {t("Manage", "common")}
            </MenuButton>

            <Button
              color={"blue"}
              loading={downloading}
              onClick={downloadReport}
            >
              {t("Export Report")}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ justifyContent: "space-between" }}
        className={"mt-4 mb-4 ml-0"}
      >
        {labStats?.map((item, index) => (
          <Grid item key={index} xs={12} md={2}>
            <div className="flex items-center" style={{ gap: "1em" }}>
              {item?.icon}
              <div>
                <h4 className={"fs-16 bold"}>{item?.title}</h4>
                <p className={"fs-16"}>{item?.subtitle}</p>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>

      <ConfirmationModal
        open={openConfirmationModal}
        onClose={cancelExitConfirmationModal}
        desc={t("Are you sure you want to delete this Lab?")}
        okayButtonTitle={t("Delete Lab")}
        onOkay={() => {
          deleteLab(labToDelete);
          cancelExitConfirmationModal();
        }}
      />
    </PageTitleCard>
  );
};

export default LabReportDetails;
