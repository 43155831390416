import useRedirect from 'helpers/hooks/useRedirect';
import { useSelector } from 'react-redux';
import { REDUCER_TYPES } from 'store';
import REQUEST from 'store/api';
import { CONTENT_TYPES } from 'store/api';
import { ENDPOINTS } from 'store/api/endpoints';
import { resolveResponse } from 'store/api/utils';
import { serializeObject } from 'store/api/utils';

const useResourceGroups = () => {
    const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);
    const { navigateResourceG } = useRedirect();

    const headers = {
        Authorization: "Bearer " + token,
    };

    //FETCH PUBLIC RESOURCE GROUPS
    const fetchResourceGroups = async (filters = {}) => {
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_GROUP
            }?language=${language}&${serializeObject(filters)}`,
            {},
            headers,
            onSuccess
        );
        if (res?.data?.data) {
            const { list, total_pages, total_count } = res?.data?.data;
            return [list, total_pages, total_count];
        }
        return [];
    };

    //FETCH RESOURCE GROUP PUBLIC DETAILS API
    const fetchResourceGroup = async (slug) => {
        if (!slug) return;
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_GROUP}/${slug}?language=${language}`,
            {},
            headers,
            onSuccess
        );
        if (res?.data?.data) {
            return res?.data?.data;
        }
    };

    //FETCH MANAGE RESOURCE GROUP LISTING
    const fetchMyResourceGroups = async (filters) => {
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_GROUP
            }?language=${language}&${serializeObject(filters)}`,
            {},
            {
                ...headers,
                "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
            },
            onSuccess
        );
        if (res?.data?.data) {
            const { list, total_pages, total_count } = res?.data?.data;
            return [list, total_pages, total_count];
        }
        return [];
    };

    //FETCH RESOURCE GROUP PRIVATE DETAILS API
    const fetchMyResourceGroup = async (slug) => {
        if (!slug) return;
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_GROUP}/${slug}?language=${language}`,
            {},
            headers,
            onSuccess
        );
        if (res?.data?.data) {
            return res?.data?.data;
        }
    };

    //DELETE RESOURCE GROUP API
    const deleteResourceGroup = async (slug) => {
        if (!slug) return;
        const onSuccess = (res) => resolveResponse(res);

        const client = new REQUEST();
        await client.delete(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_GROUP}/${slug}/${ENDPOINTS.DELETE}?language=${language}`,
            headers,
            onSuccess
        );
    };

    //CREATE RESOURCE GROUP API
    const create = async (payload) => {
        const body = {
            ...payload,
            status: "publish",
            language,
        };

        const onSuccess = (res) =>
            resolveResponse(
                res,
                (data) => navigateResourceG(data.slug, true),
                "Resource group created successfully!"
            );

        const client = new REQUEST();
        const res = await client.post(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_GROUP}/${ENDPOINTS.CREATE}?language=${language}`,
            body,
            {
                ...headers,
                "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
            },
            onSuccess
        );
        if (res?.data?.data) return res?.data?.data;
        return res;
    };

    //EDIT RESOURCE GROUP API
    const edit = async (slug, payload) => {
        if (!slug || !payload) return;
        const body = {
            ...payload,
            status: "publish",
            _method: "put",
            language,
        };

        const onSuccess = (res) =>
            resolveResponse(
                res,
                (data) => navigateResourceG(data.slug, true),
                "Resource group edited successfully!"
            );

        const client = new REQUEST();
        const res = await client.post(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_GROUP}/${slug}/${ENDPOINTS.UPDATE}?language=${language}`,
            body,
            {
                ...headers,
                "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
            },
            onSuccess
        );
        if (res?.data?.data) return res?.data?.data;
        return res;
    };

    return {
        fetchMyResourceGroup,
        fetchMyResourceGroups,
        fetchResourceGroup,
        fetchResourceGroups,
        deleteResourceGroup,
        create,
        edit
    }
}

export default useResourceGroups