import { Box, Grid, Skeleton } from "@mui/material"

const MainProductCard = ({isRowLoader, small}) => {
  return (
    isRowLoader ? (
        Array(9)
          .fill(1)
          .map((_d, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Box className="h-290 mx-auto py-3 px-2 flex-column items-center shadow bg-white rounded-10">
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={150}
                  className="rounded-5"
                />
                <div className="w-100p">
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                    className="mt-2"
                  />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "0.8rem" }}
                    className="mt-2"
                  />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "0.5rem" }}
                    className="w-50p mt-3"
                  />
                </div>
              </Box>
            </Grid>
          ))
      ) : (
        <Box className="w-full ml-3">
          {Array(small ? 6 : 4)
            .fill(1)
            .map((_d, i) => (
              <div
                className="w-full flex mb-3 shadow bg-white px-2 py-2 rounded-10"
                key={i}
              >
                <Skeleton
                  variant="rectangular"
                  width={!!small ? 190 : 300}
                  height={!!small ? 120 : 180}
                  className="rounded-5"
                />
                <div className="flex-1 ml-3 mr-1">
                  <Skeleton variant="text" />
                  <Skeleton variant="text" sx={{ fontSize: "0.5rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "0.5rem" }} />
                </div>
              </div>
            ))}
        </Box>
      )
    )
}
export default MainProductCard