import { Close } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import React from "react";
import styles from "./modal.module.scss";

const DialogBox = ({ open, onClose, width, children, light, classes }) => {
  return (
    <Dialog data-testid="dialog" open={open} onClose={onClose}>
      <div
        className={`${styles.dialog} ${classes}`}
        style={{ width: !!width && width, background: !!light && "#F3F7FC" }}
      >
        <Close
          color="disabled"
          onClick={onClose}
          className="absolute top-1 right-1 cursor-pointer"
        />
        {children}
      </div>
    </Dialog>
  );
};

export default DialogBox;
