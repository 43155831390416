import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {mockRequestGet, mockRequestPost, mockRequestDelete} from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useDiscussions from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api";

let getSpy;
let postSpy;
let deleteSpy;

let discussionId = "1";
let component = "1";
let commentId = "1";

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
});

const DiscussionsServicesMock = () => {
  const { get, add, deleteComment, like, dislike } = useDiscussions();

  const BUTTON_LINKS = [
    { name: "get", onClick: () => get(component, discussionId, {}) },
    { name: "add", onClick: () => add(component, discussionId, {}) },
    {
      name: "deleteComment",
      onClick: () => deleteComment(commentId, component, discussionId),
    },
    { name: "like", onClick: () => like(commentId, component, discussionId) },
    {
      name: "dislike",
      onClick: () => dislike(commentId, component, discussionId),
    },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Discussions Services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <DiscussionsServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
    deleteSpy = mockRequestDelete();
  });

  test("get", async () => {
    const button = screen.getByTestId("get");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DISCUSSIONS}/${component}/${discussionId}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("add", async () => {
    const button = screen.getByTestId("add");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DISCUSSIONS}/${component}/${discussionId}/${ENDPOINTS.COMMENT}/${ENDPOINTS.ADD}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("deleteComment", async () => {
    const button = screen.getByTestId("deleteComment");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DISCUSSIONS}/${component}/${discussionId}/${ENDPOINTS.COMMENT}/${commentId}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("like", async () => {
    const button = screen.getByTestId("like");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DISCUSSIONS}/${component}/${discussionId}/${ENDPOINTS.COMMENT}/${commentId}/${ENDPOINTS.LIKE}?language=en`,
        { language: "en", comment_id: "1" },
        headers,
        expect.any(Function)
      )
    );
  });

  test("dislike", async () => {
    const button = screen.getByTestId("dislike");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.DISCUSSIONS}/${component}/${discussionId}/${ENDPOINTS.COMMENT}/${commentId}/${ENDPOINTS.DISLIKE}?language=en`,
        { language: "en", comment_id: "1" },
        headers,
        expect.any(Function)
      )
    );
  });
});
