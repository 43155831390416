import React from "react";
import { FilterComponentsList } from "../../data";
import SubFilter from "./SubFilter";
import { useAdvanceSearchContext } from "../../hooks/useAdvanceSearchContext";
import { TYPE } from "helpers/utils/constants";

const LabType = ({ t = (d) => d }) => {
  const { labType, setLabType, ActiveFilterComponents } =
    useAdvanceSearchContext();

  return (
    <SubFilter
      title={t("TYPE")}
      values={labType}
      onChange={(checked, value) => {
        let temp = [...labType];
        if (checked) {
          temp.push(value);
        } else {
          temp = temp.filter((item) => item !== value);
        }
        setLabType(temp);
      }}
      options={TYPE.map((d) => ({ ...d, label: d?.value }))}
      show={ActiveFilterComponents.includes(FilterComponentsList.lab_type)}
      t={t}
    />
  );
};

export default LabType;
