import React from "react";
import styles from "./input.module.scss";
import cx from "classnames";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { LockIcon } from "components/Statics";
const TextBox = ({
  value,
  placeholder,
  onChange = Function.prototype,
  top,
  error,
  height,
  width,
  title,
  classes,
  required,
  icon,
  gray,
  name,
  maxLength,
  disabled,
  locked,
  textareaClasses,
}) => {
  const { t } = useLocalizedTranslation("common");
  return (
    <div
      data-testid="textbox"
      style={{ marginTop: top }}
      className={cx(styles.inputContainer, classes)}
    >
      {!!title && (
        <p className={`${styles.title} flex items-center}`}>
          {t(title)}
          {!!required && <span>*</span>}
          {locked && <LockIcon />}
        </p>
      )}
      <textarea
        style={{
          height: !!height && height,
          width: !!width && width,
          resize: "vertical",
        }}
        className={cx(
          styles.textarea,
          { [styles.gray]: gray },
          { [styles.error]: !!error },
          textareaClasses
        )}
        placeholder={t(placeholder)}
        value={value}
        name={name}
        onChange={onChange}
        maxLength={maxLength}
        disabled={disabled}
      />
      {error && <p className={styles.error}>{t(error, "common")}</p>}
      {maxLength && value?.length >= maxLength && (
        <p className={styles.helperText}>
          {t("You can only enter up to ${limit} characters", "common", {
            limit: maxLength,
          })}
        </p>
      )}
      <span className={`${!!icon ? styles.icon : " display-none"}`}>
        {!!icon && icon}
      </span>
    </div>
  );
};

export default TextBox;
