import ListingContainer from "../../../components/UI/organisms/ListingContainer";
import Card from "../../../components/Cards/Card";
import {Chip} from "@mui/material";
import MainProductCard from "../../../components/Cards/MainProductCard";
import { useTranslation } from "react-i18next";
const LabProjects = ({ list = [] }) => {
	const { t } = useTranslation("labs");
	return (
		<Card classes='my-4 px-6'>
			<h2 className="mb-2">{t("Projects")}</h2>
			<ListingContainer noPagination isEmpty={!list?.length} classes="minh-400" >
				{list?.map((d, i) => {
					const { title, subTitle, description, image, skills } = d;
					return (
						<MainProductCard
							key={i}
							classes="my-1 mx-4"
							title={title}
							subTitle={subTitle}
							description={description}
							image={image}
							noSave
							content={
								<div className="flex w-full mt-2">
									{skills?.map((title, i) => (
										<Chip
											key={i}
											label={title}
											variant="outlined"
											className="mr-2"
										/>
									))}
								</div>
							}
						/>
					);
				})}
			</ListingContainer>
		</Card>
	);
};

export default LabProjects;
