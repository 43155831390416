import { Grid } from "@mui/material";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import PageTitle from "components/UI/atoms/PageTitle";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import { stepSchema } from "./data";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import ExpertReferences from "components/UI/organisms/ExpertReferences";

const ResourceGroupDetails = ({
  data,
  errors,
  onChange,
  step,
  experts = [],
  setExperts = Function.prototype,
}) => {
  const { t } = useLocalizedTranslation();
  return (
    <Grid container px={{ xs: 0, md: 4 }}>
      <PageTitle>{t("Resource Group Details")}</PageTitle>
      <CreateFormSection>
        <SchemaUIExtractor
          schema={stepSchema(step).slice(0, 1)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
      </CreateFormSection>
      <CreateFormSection title="Resource Group Information">
        <SchemaUIExtractor
          schema={stepSchema(step).slice(1, 9)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
        <h3 className="mt-4">{t("Skills & Tags")}</h3>
        <SchemaUIExtractor
          schema={stepSchema(step).slice(9, 12)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
        <h3 className="mt-4">{t("Add Experts")}</h3>
        <p>{t("Feature experts for your resource group")}</p>
        <ExpertReferences experts={experts} setExperts={setExperts} />
      </CreateFormSection>
    </Grid>
  );
};

export default ResourceGroupDetails;
