import { Grid, Skeleton } from "@mui/material";
import Card from "components/Cards/Card";

const ExploreProductCard = () => {
    return (
        <Card classes="flex-column items-stretch px-2 py-2 mb-6 overflowX-hidden">
            <div className="mx-auto w-full mb-2 h-160 overflow-hidden imageContainer mr-3">
                <Skeleton variant="rectangular" width="100%" height="100%" />
            </div>

            <Skeleton variant="text" width="80%" height={30} />
            <Skeleton variant="text" width="60%" height={20} style={{ marginBottom: 16 }} />

            <Grid container>
                {Array.from(new Array(4)).map((_, i) => (
                    <Grid key={i} item xs={6} className="flex items-center tracking-tight mb-1">
                        <Skeleton variant="circular" width={20} height={20} />
                        <Skeleton variant="text" width="70%" height={20} className="ml-2" />
                    </Grid>
                ))}
            </Grid>

            <div className="flex mt-2 mb-1 flex-wrap">
                <Skeleton variant="rectangular" width="60%" height={24} style={{ marginRight: 8, borderRadius: 8 }} />
            </div>
        </Card>
    );
}
export default ExploreProductCard