import Card from "components/Cards/Card";
import Text from "components/UI/atoms/NewLineText";
import React from "react";
import ScormDetailPage from "components/UI/organisms/ScormDetailPage";
const Title = ({ children }) => <h3 className="mt-3">{children}</h3>;

const ChallengeOverview = ({ challenge = {}, t = (d) => d }) => {
  const {
    title,
    skills = {},
    category,
    duration,
    agreement,
    description,
    challenge_template,
    projectPitchSummary,
    challenge_requirements,
    description_type,
    scorm_url,
  } = challenge;

  return (
    <Card classes="mt-4 pb-6">
      {description_type === "scorm" ? (
        <ScormDetailPage scorm_url={scorm_url} />
      ) : (
        <div>
          <h2 className="mt-1">{t("Challenge Overview")}</h2>
          {title && (
            <>
              <Title>{t("Introduction")}</Title>
              <Text>{title}</Text>
            </>
          )}
          {skills && (
            <>
              <Title>{t("Skills")}:</Title>
              <Text>{Object.values(skills).join(", ")}</Text>
            </>
          )}
          {category && (
            <>
              <Title>{t("Category")}:</Title>
              <Text>{category}</Text>
            </>
          )}
          {duration && (
            <>
              <Title>{t("Time Estimate")}:</Title>
              <Text>{duration}</Text>
            </>
          )}
          {agreement && (
            <>
              <Title>{t("Agreement")}</Title>
              <div dangerouslySetInnerHTML={{ __html: agreement }} />
            </>
          )}
          {description && (
            <>
              <Title>{t("Description")}</Title>
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
            </>
          )}
          <Title>{t("Good Luck!")}</Title>
        </div>
      )}
    </Card>
  );
};

export default ChallengeOverview;
