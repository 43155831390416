import { useState, useEffect } from "react";
import useOrganization from "store/organization/service-hook";
import useRedirect from "helpers/hooks/useRedirect";
import { scrollToTop } from "helpers/utils/utilities";
import ModalLayout from "components/Modal";
import useChallenges from "store/challenges/service-hook";
import { Select, MenuItem, InputLabel, FormControl, OutlinedInput, TextField } from '@mui/material';
import Button from "components/Button";
import { aiBackground1 } from "assets/svg/ai";
import { aiLoading } from "assets/gif/ai";
import { challenge as challengeImage } from "assets/svg/green";
import "components/AI/ai.scss";
import { toast } from 'react-toastify';
import { calendar, chart } from "assets/svg/blue";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Chip } from "@mui/material";
import AIText from "components/AI/AIText";

const ChallengeBuilder = ({ open, onClose }) => {
  const { aiCreateChallengesFromResourcesPreview, aiCreateChallenge } = useChallenges();
  const { organizationId } = useOrganization();
  const { navigateChallenge } = useRedirect();

  const [step, setStep] = useState(0);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmationModalDescription, setConfirmationModalDescription] = useState("Are you sure?");

  const [loading, setLoading] = useState(false);
  const [challenges, setChallenges] = useState([]);
  const [resourceModules, setResourceModules] = useState([]);
  const [challengesState, setChallengesState] = useState([]);
  const [challengeExpanded, setChallengeExpanded] = useState('challengePanel0');
  const [challengeSelected, setChallengeSelected] = useState([]);
  const [challengeChosen, setChallengeChosen] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState('');

  const createChallengesPreviews = async () => {
    setLoading(true);

    try {
      const payload = {
        resource_modules: resourceModules,
        additional_information: additionalInfo,
        is_ai_created: true,
        organization_id: organizationId,
      };

      const res = await aiCreateChallengesFromResourcesPreview(payload);
      if (res?.data) {
        setChallenges(Object.values(res?.data?.data));
        setStep(step + 1);
      }

    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in challenge generating API call: ", e);
    } finally {
      scrollToTop();
      setLoading(false);
    }
  };

  const handleResourceModuleSelection = (event) => {
    const value = event.target.value;
    setResourceModules(typeof value === 'string' ? value.split(',') : value);
  };

  const handleAdditionalInfoChange = (event) => {
    setAdditionalInfo(event.target.value);
  };

  const handleFinalCreate = async () => {
    setLoading(true);
    let createdChallenge = null;
    let resourceModuleUUIDs = [];

    try {
      if (challengeChosen) {
        try {
          resourceModuleUUIDs.push(...resourceModules);
          const challengePayload = {
            ...challengeChosen,
            organization_id: organizationId,
            is_ai_created: true,
            resource_modules: resourceModuleUUIDs,
          };

          createdChallenge = await aiCreateChallenge(challengePayload);
          createdChallenge = createdChallenge?.data?.data;

          if (createdChallenge) {
            navigateChallenge(createdChallenge.slug, true);
            toast.success("Success");
          } else {
            toast.error("Something went wrong!");
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Failed to create challenge: ", error);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Unexpected error in handleFinalCreate:", e);
    } finally {
      setLoading(false);
    }
  };

  // Handle backing in steps
  const onCancel = () => {
    if (step === 1) {
      setConfirmationModalDescription("You will lose all the current Generated Challenges! Are you sure?");
      setOpenConfirmationModal(true);
    }
  };

  const confirmConfirmationModal = () => {
    if (confirmationModalDescription === "You will lose all the current Generated Challenges! Are you sure?") {
      if (step === 1) {
        setChallenges([]);
        setChallengesState([]);
        setChallengeSelected([]);
        setChallengeExpanded('challengePanel0');
        setChallengeChosen(null);
        setStep(step - 1);
      }
    }
    setOpenConfirmationModal(false);
  };

  useEffect(() => {
    setChallengesState(challenges?.map(challenge => ({
      ...challenge,
    })));
  }, [challenges]);

  useEffect(() => {
    if (!challengeChosen) {
      setNumChallengesToShow(4);
      setLoadMoreChallengesClickCount(0);
    }
  }, [step]);

  useEffect(() => {
    setChallengeChosen(challengesState[challengeSelected]);
  }, [challengesState[challengeSelected]]);

  const [numChallengesToShow, setNumChallengesToShow] = useState(4);
  const [loadMoreChallengesClickCount, setLoadMoreChallengesClickCount] = useState(0);

  const handleLoadMoreChallenges = () => {
    setNumChallengesToShow(numChallengesToShow + 2);
    setLoadMoreChallengesClickCount(loadMoreChallengesClickCount + 1);
  };

  const handleChallengePanelChange = (panel) => (event, isExpanded) => {
    setChallengeExpanded(isExpanded ? panel : false);
  };

  return (
    <ModalLayout open={open} onClose={onClose} isWide className="ai-scoped">
      <div className="ai-scoped">
        <ConfirmationModal
          open={openConfirmationModal}
          onClose={() => { setOpenConfirmationModal(false) }}
          desc={confirmationModalDescription}
          okayButtonTitle="Confirm"
          onOkay={confirmConfirmationModal}
        />
        <h2 className="mt-1 mb-3">{step === 0 ? "Select Resources" : "Select Challenge"}</h2>
        {step === 0 && (
          <>
            <FormControl fullWidth className='my-3'>
              <InputLabel id="resource-module-select-label">Resource Modules</InputLabel>
              <Select
                labelId="resource-module-select-label"
                multiple
                value={resourceModules}
                onChange={handleResourceModuleSelection}
                input={<OutlinedInput label="Resource Modules" />}
                renderValue={(selected) => selected.join(', ')}
              >
                {/* Dummy UUIDs */}
                <MenuItem value="NzlkxuSYO2">UUID 1</MenuItem>
                <MenuItem value="bk03XyLsha">UUID 2</MenuItem>
                <MenuItem value="RWKeYLjhSx">UUID 3</MenuItem>
                <MenuItem value="yEmjZSzTDP">UUID 4</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              className="mb-5"
              label="Additional Information"
              multiline
              rows={2}
              value={additionalInfo}
              onChange={handleAdditionalInfoChange}
            />
            <div className={`intro-box ${loading ? 'flashing' : ''}`}>
              <p className="intro-text">{loading ? "Generating your Challenges... (it may take up to 2 minutes)" : "Our Challenge Builder AI can help you generate Challenges from Resource Modules!"}</p>
              <img width="60%" src={aiBackground1} alt="" />
            </div>
          </>
        )}
        {step === 1 && (
          loading ?
            <div className='intro-box'>
              <p className="intro-text">Generating your Challenges... (it may take up to 2 minutes)</p>
              <img width="100%" src={aiLoading} alt="" />
            </div> :
            <div className="w-full">
              {challengesState?.slice(0, numChallengesToShow).map((challenge, index) => (
                <Accordion
                  key={index}
                  expanded={challengeExpanded === `challengePanel${index}`}
                  onChange={handleChallengePanelChange(`challengePanel${index}`)}
                  className="challenge-panel relative"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`challengePanel${index}bh-content`}
                    id={`challengePanel${index}bh-header`}
                  >
                    <Typography className="panel-main">
                      <div className="image-and-title">
                        <img className="panel-icon" src={challengeImage} alt="" />
                        <b className="mx-2">{challenge?.challengeTitle}</b>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <Button
                    onClick={() => setChallengeSelected(index)}
                    color={challengeSelected === index ? 'green' : 'blue'}
                    bordered
                    classes="panel-button sm-panel-button"
                    height={35}
                  >
                    {challengeSelected === index ? (<><CheckCircleIcon className="icon-margin" /> Selected</>) : 'Select'}
                  </Button>
                  <AccordionDetails>
                    <div className="image-and-title">
                      <img className="desc-icon" src={chart} alt="" />
                      <Typography dangerouslySetInnerHTML={{ __html: challenge?.level }} />
                      <img className="desc-icon ml-4" src={calendar} alt="" />
                      <Typography dangerouslySetInnerHTML={{ __html: challenge?.duration }} />
                    </div>
                    <br />
                    <AIText className="mb-1">Description</AIText>
                    <Typography dangerouslySetInnerHTML={{ __html: challenge?.challengeDescription }} />
                    <br />
                    <AIText className="mb-1">Skills</AIText>
                    {challenge?.skill_titles?.map((skillTitle) => (
                      <Chip
                        key={skillTitle}
                        label={skillTitle}
                        className="py-2 mr-1 mb-1 fs-12"
                        sx={{ height: "26px" }}
                      />
                    ))}
                  </AccordionDetails>
                  <Button
                    onClick={() => setChallengeSelected(index)}
                    color={challengeSelected === index ? 'green' : 'blue'}
                    bordered
                    classes="panel-button md-panel-button"
                    height={35}
                  >
                    {challengeSelected === index ? (<><CheckCircleIcon className="icon-margin" /> Selected</>) : 'Select'}
                  </Button>
                </Accordion>
              ))}
              <br />
              {challengesState.length > numChallengesToShow && loadMoreChallengesClickCount < 3 && (
                <AIText className="text-right">Don't think these are good fit? <span className="no-good-fit" onClick={handleLoadMoreChallenges}>Load More</span></AIText>
              )}
            </div>
        )}
        <div className="flex">
          <Button
            onClick={onCancel}
            disabled={step === 0}
          >
            Back
          </Button>
          <Button
            onClick={step === 0 ? createChallengesPreviews : handleFinalCreate}
            disabled={loading || (step === 0 && resourceModules.length === 0)}
            classes="ml-3"
          >
            {step === 0 ? "Generate Challenges" : "Create"}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ChallengeBuilder;
