import { Skeleton } from "@mui/material";
import Card from "components/Cards/Card";

const ExploreTipsCard = () => {
    return (
        <Card classes="shadow flex-column items-center h-100p overflow-hidden">
            <Skeleton variant="rectangular" width="100%" height={150} className="rounded-5"/>
            <Skeleton variant="text" width="60%" height={24} style={{ marginTop: '1rem' }} />
            <Skeleton variant="text" width="80%" height={20} style={{ margin: '0.5rem 0' }} />
            <Skeleton variant="text" width="75%" height={20} style={{ margin: '0.5rem 0' }} />
            <Skeleton variant="text" width="70%" height={20} style={{ margin: '0.5rem 0' }} />
            <Skeleton variant="rectangular" width={120} height={40} style={{ borderRadius: '25px' }} />
        </Card>
    );
}
export default ExploreTipsCard