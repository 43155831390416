import React from "react";
import { Button } from "@mui/material";
import Card from "components/Cards/Card";
import Components from "./Components";
import PrivacyFilter from "./Privacy";
import LevelFilter from "./Level";
import DurationFilter from "./Duration";
import CategoryFilter from "./Category";
import StatusFilter from "./Status";
import JobFilter from "./Jobs";
import SkillFilter from "./Skill";
import ProjectStatusFilter from "./ProjectStatus";
import LabTypeFilter from "./LabType";
import { useAdvanceSearchContext } from "../../hooks/useAdvanceSearchContext";

const Filters = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { clearFilters } = useAdvanceSearchContext();

  return (
    <>
      <Card data-testid="filter-card" classes="p-0">
        <div className="flex-center justify-between">
          <h3 className="mx-2 my-3">{t("Filters")}</h3>
          <Button
            color="error"
            className="mr-1"
            variant="text"
            sx={{ textTransform: "none" }}
            onClick={clearFilters}
          >
            {t("Clear All")}
          </Button>
        </div>
        {/*COMPONENT FILTER*/}
        <Components t={t} />
        {/*PRIVACY FILTER*/}
        <PrivacyFilter t={t} />
        {/*STATUS FILTER*/}
        <StatusFilter t={t} />
        {/*PROJECT STATUS*/}
        <ProjectStatusFilter t={t} />
        {/*LAB TYPE*/}
        <LabTypeFilter t={t} />
        {/*CATEGORY FILTER*/}
        <CategoryFilter t={t} />
        {/*LEVEL FILTER*/}
        <LevelFilter t={t} />
        {/*DURATION FILTER*/}
        <DurationFilter t={t} />
        {/*JOB FILTER*/}
        <JobFilter t={t} />
        {/*SKILL FILTER*/}
        <SkillFilter t={t} />
      </Card>
    </>
  );
};

export default Filters;
