import AuthLayout from "../layouts/AuthLayout";
import LineProgress from "../../components/Progress/LineProgress";
import { useEffect, useState } from "react";
import Button from "components/Button";
import { CheckboxGroup } from "components/Input/Checkbox";
import MasterSelect from "components/UI/organisms/MasterSelect";
import { Grid } from "@mui/material";
import { plansList } from "pages/organization/components/upgradePlan/data";
import { PlanCard } from "pages/organization/components/upgradePlan";
import Input from "components/Input";
import ModalLayout from "components/Modal";
import useOnboarding from "store/onboarding/service-hook";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import { toast } from "react-toastify";
import useOrganization from "store/organization/service-hook";
import { useNavigate } from "react-router-dom";
import ComparePlans from "pages/organization/components/upgradePlan/ComparePlans";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useRedirect from "helpers/hooks/useRedirect";
import { getCurrOrigin } from "helpers/utils/utilities";
import i18next from "i18next";
import { ONBOARDING_VIDEO } from "helpers/utils/constants";
const steps = ["Onboarding Video", "Questionnaire", "Pick Plans", "Details"];

const org_types = [
  { title: "Assess", value: "assess", description: "potential applicants" },
  { title: "Onboard", value: "onboard", description: "new people" },
  { title: "Engage", value: "engage", description: "current people" },
  { title: "Grow", value: "grow", description: "your organization" },
];

const OrganizationOnboarding = () => {
  const [step, setStep] = useState(0);
  const [subStep, setSubStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { completeOrgOnboarding } = useOnboarding();
  const [type, setType] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [activePlan, setActivePlan] = useState();
  const [showComparePlans, setShowComparePlans] = useState(false);
  const [dynamicFeatures, setDynamicFeatures] = useState([]);
  const { planDetails } = useOrganization();
  const { t } = useLocalizedTranslation("organization");
  const { navigateExplore } = useRedirect();

  const {
    user: { organization_details, email },
  } = useSelector((state) => state[REDUCER_TYPES.AUTH]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  const fetchPlanDetails = async () => {
    const res = await planDetails(organization_details?.slug);
    setActivePlan(res?.plan);
  };

  const handleSelectPlan = () => {
    const instance = window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_STIE,
      publishableKey: process.env.REACT_APP_CHARGEBEE_KEY,
    });
    let cbPortal = instance.createChargebeePortal({
      email: email,
    });

    cbPortal.open({
      close() {
        fetchPlanDetails();
      },
    });
  };
  const handleNext = async () => {
    if (step === 1) {
      if (subStep === 0) {
        if (type.length === 0) {
          toast.error("Please select at least one organizational objective!");
          return;
        }
        setSubStep(1);
      } else {
        if (!data?.business_challenge_tacklings) {
          toast.error("Please select one of the business challenges!");
          return;
        }
        setStep(2);
        setSubStep(0);
      }
      return;
    }
    if (step === 3) {
      const requiredFields = [
        {
          field: "total_employees",
          message: "Please enter the total number of employees!",
        },
        { field: "category", message: "Please select an organization type!" },
        {
          field: "website",
          message: "Please enter your organization's website URL!",
        },
      ];

      const errors = requiredFields
        .filter(({ field }) => !data?.[field])
        .map(({ message }) => message);

      if (errors.length > 0) {
        errors.forEach((error) => toast.error(error));
        return;
      }

      setLoading(true);
      const res = await completeOrgOnboarding(organization_details?.slug, {
        type,
        ...data,
        category: data?.category?.key,
        business_challenge_tacklings: data?.business_challenge_tacklings?.key,
        website: `https://preprlabs.org/${data?.website.replace(/\s+/g, "-")}`,
      });
      if (res) setIsModalOpen(true);
      setLoading(false);
      return;
    }
    setStep(step + 1);
  };

  const handleBack = () => {
    if (step === 0) {
      navigate("/explore", { replace: true });
      return;
    }
    let newStep =
      step === 2
        ? 1
        : step === 1 && subStep === 1
        ? step
        : step === 1 && subStep === 0
        ? 0
        : step - 1;
    let newSubStep = step === 2 ? 1 : subStep === 1 ? 0 : subStep;

    setStep(newStep);
    setSubStep(newSubStep);
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const toggleComparePlans = () => {
    setShowComparePlans((prev) => !prev);
  };

  return (
    <AuthLayout sliderHidden>
      <div className="w-95p">
        <LineProgress
          length={steps.length}
          curr={step}
          classes="mt-10"
          numbered
          rounded
        />
        <div className="flex justify-center mt-1 text-center">
          {steps.map((title, index) => (
            <p
              className="fs-11 fw-500 tracking-tight opacity-70 w-100 mx-2"
              key={index}
            >
              {t(title)}
            </p>
          ))}
        </div>
        {(step === 1 || step === 3) && (
          <div className="auth-card px-5 w-90p mx-auto mt-10">
            <div className="flex-1 w-100p">
              {step === 1 && (
                <>
                  {subStep === 0 && (
                    <>
                      <h2 className="mt-5 fw-700 fs-20--mob text-center">
                        {t("How can we serve you?")}
                      </h2>
                      <p className="my-3 fs-13 mx-auto w-80p text-center">
                        {t("What are your organizational objectives?")} <br />
                        {t("Select all that apply.")}
                      </p>
                      <div className="flex-column my-8">
                        <CheckboxGroup
                          name="type"
                          options={org_types?.map(
                            ({ title, description, value }) => ({
                              title: (
                                <>
                                  <b className="mr-1">{t(title)}</b>{" "}
                                  {t(description)}
                                </>
                              ),
                              value,
                            })
                          )}
                          onChange={setType}
                          multiSelect
                          value={type}
                          size="small"
                        />
                      </div>
                    </>
                  )}

                  {subStep === 1 && (
                    <div className="py-2 px-2">
                      <h2 className="mt-5 text-center">
                        {t("What Business Challenge Are You Tackling?")}
                      </h2>
                      <p className="my-3 fs-13 mx-auto w-80p text-center c-title">
                        {t("Select one from the dropdown menu.")}
                      </p>
                      <MasterSelect
                        name="business_challenge_tacklings"
                        classes="w-full mt-2"
                        width="99%"
                        placeholder="Please select one option"
                        onChange={onChange}
                        value={data?.business_challenge_tacklings}
                        t={t}
                      />
                    </div>
                  )}
                </>
              )}

              {step === 3 && (
                <>
                  <h2 className="mt-5 fw-700 fs-20--mob text-center">
                    {t("Organization Details")}
                  </h2>
                  <Input
                    title="Organization Name"
                    classes="w-full mt-5 fw-700 c-title"
                    width="100%"
                    type="text"
                    required
                    value={organization_details?.title}
                    name="name"
                    disabled
                    t={t}
                  />
                  <Input
                    title="Number of Employees"
                    classes="w-full mt-3 fw-700 c-title"
                    width="100%"
                    type="number"
                    required
                    onChange={onChange}
                    value={data?.total_employees}
                    name="total_employees"
                    t={t}
                  />
                  <MasterSelect
                    name="category"
                    title="Organization Type"
                    required
                    classes="w-full mt-3 c-title"
                    width="100%"
                    onChange={onChange}
                    value={data?.category}
                    t={t}
                  />
                  <Input
                    title="Custom URL"
                    classes="w-full mt-3 fw-700 c-title"
                    width="100%"
                    type="url"
                    required
                    onChange={onChange}
                    value={data?.website}
                    name="website"
                    preInput={`${getCurrOrigin()}/`}
                    t={t}
                  />
                </>
              )}
            </div>
            <div className="flex w-100p w-70p--mob mt-3 py-3 justify-between">
              <Button onClick={handleBack} bordered>
                {t(step === 0 || step === 2 ? "I'll do it later" : "Back")}
              </Button>
              <Button loading={loading} onClick={handleNext}>
                {t(
                  step !== 0 && step !== 2
                    ? "Next"
                    : step === 1 && subStep === 0
                    ? "Next"
                    : "Get Started Now"
                )}
              </Button>
            </div>
          </div>
        )}

        <div className="w-80p mx-auto">
          {step === 0 && (
            <div className="w-100p mt-8 mx-auto">
              <h2 className="fs-20--mob text-center">
                {t("Set Up Your Organization to Get Started")}
              </h2>
              <div className="mt-6">
                <video
                  className="w-100p h-full"
                  style={{ maxHeight: "60vh" }}
                  controls
                >
                  <source src={ONBOARDING_VIDEO[i18next.language]} />
                </video>
              </div>
            </div>
          )}
          {step === 2 && (
            <div>
              <h2
                className="mt-8 fs-20--mob text-center fw-700"
                style={{ color: "#101223" }}
              >
                {t("Pick a plan that's right for your organization")}
              </h2>
              <p className="my-3 fs-16 mx-auto w-80p w-90p--mob text-center">
                {t(
                  "Start your 14-day trial now on any of our premium plans or stay on seed plan for free. No credit card information needed."
                )}
              </p>
              <Grid
                container
                columnSpacing={1}
                rowSpacing={2}
                className="flex justify-center mt-8"
              >
                {plansList.map((plan, i) => (
                  <Grid
                    key={i}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2.4}
                    className="flex justify-center"
                  >
                    <PlanCard
                      title={plan.title}
                      description={plan.description}
                      icon={plan.icon}
                      price={plan.price}
                      pricePerYearPerUser={plan?.pricePerYearPerUser}
                      totalPrice={plan?.totalPrice}
                      haveTrialPeriod={plan?.haveTrialPeriod}
                      active={activePlan === plan?.id}
                      isPopular={plan.isPopular}
                      type={plan.type}
                      totalUsers={plan.totalUsers}
                      handleActivePlan={handleSelectPlan}
                    />
                  </Grid>
                ))}
                <p
                  className="link text-center fs-13 underline py-1 c-primary"
                  onClick={toggleComparePlans}
                >
                  {t(showComparePlans ? "Hide Plan Details" : "Compare Plan")}
                </p>
                {showComparePlans && (
                  <ComparePlans
                    activePlan={activePlan}
                    planFeatures={dynamicFeatures}
                    setActivePlan={handleSelectPlan}
                    t={t}
                  />
                )}
              </Grid>
            </div>
          )}

          {step === 0 || step === 2 ? (
            <div className="flex  mt-3 py-3 justify-between">
              <Button
                onClick={handleBack}
                bordered
                classes="bg-white transform-none"
              >
                {t(step === 0 ? "I'll do it later" : "Back")}
              </Button>
              <Button onClick={handleNext}>
                {t(step !== 0 ? "Next" : "Get Started Now")}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      {isModalOpen && (
        <UserOnboardingPopUp
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            navigateExplore();
          }}
          slug={organization_details?.slug}
          t={t}
        />
      )}
    </AuthLayout>
  );
};

export default OrganizationOnboarding;

export const UserOnboardingPopUp = ({
  isOpen,
  onClose,
  slug,
  t = (d) => d,
}) => {
  const navigate = useNavigate();

  return (
    <ModalLayout
      width="min(98vw,650px)"
      wrapperClasses="rounded-10 px-10 py-10"
      open={isOpen}
      onClose={onClose}
    >
      <div className="text-center py-3">
        <h2 className="text-center fs-24">
          {t("Organization Created Successfully")}
        </h2>
        <p className="mt-4">
          {t(
            "Invite members to your Organization or redeem pre-built Labs included in your plan in our Marketplace, which would help you quickly create Labs."
          )}
        </p>
        <div className="mt-10 pt-3 flex-colOnMob justify-between">
          <Button
            height="41px"
            classes="minw-160"
            onClick={() =>
              navigate(
                `/member-management/organization/${slug}?onboardingCompleted=true`,
                { replace: true }
              )
            }
          >
            {t("Invite Members")}
          </Button>
          <Button
            height="41px"
            onClick={() =>
              navigate(`/organization/update/${slug}`, { replace: true })
            }
            classes="mx-2 minw-200"
          >
            {t("Complete Organization Profile")}
          </Button>
          <Button
            height="41px"
            classes="minw-180"
            onClick={() => navigate("/lab-marketplace", { replace: true })}
          >
            {t("Browse Lab Marketplace")}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
