import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  networkMembers: [],
};

const SLICE = createSlice({
  name: "members",
  initialState,
  reducers: {}
});

export const REDUCER = SLICE.actions;

export default SLICE.reducer;
