import {
  achievementSlice,
  achievement_schema,
} from "components/UI/molecules/AddAchievementSection";
import { SCHEMA_TYPES, TYPE } from "helpers/utils/constants";
import { REQUIRED_VALIDATOR, TITLE_VALIDATOR } from "helpers/utils/validators";

export const schema = [
  {
    name: "media",
    type: SCHEMA_TYPES.FILE,
    title: "Upload Image",
    description: "The dimension needs to be 625 x 355 pixels",
    dropboxProps: {
      width: "95%",
      fileType : "image",
      video_code:"iframe_code",
      height: "300px",
      dimensions:[625,355]
    },
    lg: 12,
  },
  {
    title: "Lab program Title",
    name: "title",
    type: SCHEMA_TYPES.INPUT,
    lg: 12,
    validator: TITLE_VALIDATOR,
  },
  {
    title: "Description",
    name: "description",
    type: SCHEMA_TYPES.EDITOR,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Level",
    name: "level_id",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Duration",
    name: "duration_id",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Category",
    name: "category_id",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
    filters: {
      component: "lab",
    },
  },
  {
    title: "Mode",
    name: "mode",
    type: SCHEMA_TYPES.SELECT,
    options: [
      { value: "Team", key: "team" },
      { value: "Individual", key: "individual" },
    ],
    validator: REQUIRED_VALIDATOR,
    multi: true,
  },
  {
    title: "Type",
    name: "type",
    type: SCHEMA_TYPES.SELECT,
    options: TYPE,
    validator: REQUIRED_VALIDATOR,
    multi: true,
  },
  {
    title: "Privacy",
    type: SCHEMA_TYPES.RADIO,
    options: [
      { title: "Public", key: "no" },
      { title: "Private", key: "yes" },
    ],
    name: "privacy",
  },
  {
    title: "Lab program Skills",
    name: "skills",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Skill Sets",
    name: "skill_stacks",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
  },
  {
    title: "Skill Groups",
    name: "skill_groups",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
  },
  ...achievement_schema(true),
  {
    title: "Enable Achievement",
    label: "Enabled",
    name: "is_achievement_enabled",
    type: SCHEMA_TYPES.SWITCH,
    lg: 12,
  },
];

export const getSchemaByStep = (step) => {
  switch (step) {
    case 0:
      return [0, schema.findIndex((d) => d.name === "skill_groups") + 1];
    case 1:
      return [];
    case 2:
      return achievementSlice(schema);
    default:
      return [0, 0];
  }
};

export const stepSchema = (step) => {
  return schema.slice(...getSchemaByStep(step));
};
