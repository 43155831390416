import { Mail, Search } from "@mui/icons-material";
import { Container, Radio } from "@mui/material";
import Input from "components/Input";
import TextBox from "components/Input/Textbox";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import React from "react";
import PageTitle from "components/UI/atoms/PageTitle";
import ThirdPartyInvitaionSection from "components/UI/molecules/ThirdPartyInvitaionSection";
import ChipSelect from "components/Input/AutoComplete";
import { createEvent } from "helpers/utils/utilities";

const UserInvitation = ({ data, onChange }) => {
  const onSelectUser = (user) => {
    if (!!user && !!data?.users && !data.users.includes(user)) {
      const newUsers = data.users || [];
      newUsers.push(user);
      onChange(createEvent("users", newUsers));
    }
  };

  const onRemoveUser = (user) => {
    const filteredUsers = data?.users?.filter((d) => d !== user);
    onChange(createEvent("users", filteredUsers));
  };

  return (
    <Container md={12} lg={10} disableGutters>
      <PageTitle>User Invitation</PageTitle>
      <CreateFormSection classes="mt-4">
        <h4 className="mt-3">Search PreprLabs Network</h4>
        <Input
          classes="mt-1 w-full mx-0"
          width="93%"
          icon={<Search style={{ color: "black" }} />}
          iconLeft
        />
        <h4 className="mt-3">Invite via Email</h4>
        <ChipSelect
          prefix={<Mail className="c-gray mt-1" />}
          data={data?.users || []}
          suggestions={data?.users || []}
          classes="input w-100p "
          selectOnSpace
          renderMenuItem={(user) => <p>{user}</p>}
          onRemoveChip={onRemoveUser}
          onClickMenuItem={onSelectUser}
          expandable
        />
        <h4 className="mt-3">Invitation Message (Optional)</h4>
        <p className="mt-2 fs-14">Subject</p>
        <Input classes="mt-1 w-full mx-0" width="95%" />
        <p className="fs-14 mt-2">Description</p>
        <TextBox classes="mt-1 w-full mx-0" width="95%" height={200} />
        <h4 className="mt-2">Invitation Type</h4>
        <div className="flex items-center">
          <Radio /> <span className="fs-14">Auto-add users</span>
        </div>
        <div className="flex items-center mb-2">
          <Radio /> <span className="fs-14">Ask user for permission</span>
        </div>
      </CreateFormSection>
      <ThirdPartyInvitaionSection />
    </Container>
  );
};

export default UserInvitation;
