import { Grid } from "@mui/material";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import PageTitle from "components/UI/atoms/PageTitle";
import React from "react";
import { stepSchema, schema } from "./data";
import Card from "components/Cards/Card";
import Switch from "components/Input/Switch";

const AddAcheivement = ({ data, errors, onChange, disabled, t = (d) => d }) => {
  return (
    <>
      <Grid
        className={disabled ? "opacity-50" : ""}
        container
        px={{ xs: 0, md: 4 }}
      >
        <PageTitle>{t("Achievements")}</PageTitle>
        <Card classes={"w-full my-4"}>
          <div>
            <h3>{t("Enable Achievement")}</h3>
            <Switch
              name={schema[schema.length - 1].name}
              value={disabled ? false : data[schema[schema.length - 1].name]}
              error={errors.get(schema[schema.length - 1].name)}
              onChange={onChange}
              label={
                <span className="fs-12">
                  {t("Enable users to get trophies")}
                </span>
              }
              disabled={disabled}
            />
          </div>
        </Card>
        {data?.is_achievement_enabled && (
          <Card classes={"w-full"}>
            <h3 className="mt-4">{t("Add an Achievement")}</h3>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} lg={6}>
                <SchemaUIExtractor
                  schema={stepSchema(2).slice(0, 1)}
                  data={data}
                  errors={errors}
                  onChange={onChange}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <SchemaUIExtractor
                  schema={stepSchema(2).slice(1, 3)}
                  data={data}
                  errors={errors}
                  onChange={onChange}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Card>
        )}
      </Grid>
    </>
  );
};

export default AddAcheivement;
