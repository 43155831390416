import { Grid } from "@mui/material";
import PageTitleCard from "../../../../components/UI/molecules/PageTitleCard";
import useRedirect from "../../../../helpers/hooks/useRedirect";
import useOrganizationReportContext from "../hooks/useOrganizationReportContext";
import React, { useMemo, useState } from "react";
import { ReactComponent as UsersIcon } from "assets/svg/blue/users.svg";
import { ReactComponent as ChallengeIcon } from "assets/svg/green/challenge.svg";
import { ReactComponent as ResourceIcon } from "assets/svg/purple/resource.svg";
import { ReactComponent as LabIcon } from "assets/svg/blue/lab.svg";
import AddressIcon from "@mui/icons-material/Language";
import moment from "moment";
import Button from "../../../../components/Button";
import MenuButton from "../../../../components/Button/MenuButton";
import { Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { manageOrgMenuItems } from "../../../organization/forms/data";
import useOrganization from "../../../../store/organization/service-hook";
import useOrganizationReport from "../../../../store/reports/organization/service-hook";
import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";

const OrganizationReportDetails = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { organizationDetails, organizationSlug } =
    useOrganizationReportContext();
  const { deleteOrganization } = useOrganization();
  const {
    navigateOrg,
    navigateEditOrg,
    navigateOrgIntegrationSettings,
    navigateMemberManagement,
    navigateManageOrgs,
    navigateOrganizationReports,
  } = useRedirect();
  const navigate = useNavigate();
  const { downloadOrganizationReport } = useOrganizationReport();

  /**
   * COMPONENT STATE
   */
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [orgToDelete, setOrgToDelete] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const downloadReport = async () => {
    setDownloading(true);
    if (organizationSlug) await downloadOrganizationReport(organizationSlug);
    setDownloading(false);
  };

  const handleDelete = async (slug) => {
    await deleteOrganization(slug);
    navigateManageOrgs();
  };

  const openExitConfirmationModal = (slug) => {
    setOrgToDelete(slug);
    setOpenConfirmationModal(true);
  };

  const cancelExitConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setOrgToDelete(null);
  };

  /**
   * COMPUTED PROPERTIES
   */
  const orgStat = useMemo(() => {
    return [
      {
        icon: <AddressIcon className={"w-50 h-50"} color="info" />,
        title: `${t("Created")} ${
          organizationDetails?.created_at
            ? moment(organizationDetails?.created_at).format("MMM Do,YYYY")
            : ""
        }`,
        subtitle: organizationDetails?.address?.[0]
          ? `${organizationDetails?.address?.[0]?.city}, ${organizationDetails?.address?.[0]?.state}` ??
            "-"
          : t("Location Unknown"),
      },

      {
        icon: <UsersIcon className={"w-40 h-40"} />,
        title: t("Members Joined"),
        subtitle: organizationDetails?.members_count ?? 0,
      },
      {
        icon: <ChallengeIcon className={"w-40 h-40"} />,
        title: t("Challenges", "common"),
        subtitle: organizationDetails?.challenges_count ?? 0,
      },
      {
        icon: <LabIcon className={"w-40 h-40"} />,
        title: t("Labs", "common"),
        subtitle: organizationDetails?.labs_count ?? 0,
      },
      {
        icon: <ResourceIcon className={"w-40 h-40"} />,
        title: t("Resources", "common"),
        subtitle: organizationDetails?.resource_modules_count ?? 0,
      },
    ];
  }, [organizationDetails]);

  return (
    <>
      <PageTitleCard noAction noContainer classes={"mx-8"}>
        <Grid
          container
          spacing={2}
          className="ml-0 w-full"
          style={{ justifyContent: "space-between", alignItem: "center" }}
        >
          <Grid item xs={12} md={2}>
            <Button bordered color={"blue"} onClick={() => navigate(-1)}>
              {t("Back", "common")}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={"w-full"}>
              <h2 className={`fs-23 text-center`}>
                {t("Organization Report")}
              </h2>
              <p
                className={
                  "c-primary bold text-center mt-2 fs-16 cursor-pointer"
                }
                onClick={() => navigateOrg(organizationDetails?.slug)}
              >
                {organizationDetails?.title ?? ""}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <div className="flex" style={{ gap: "2px" }}>
              <MenuButton
                listWithTitleonClick={manageOrgMenuItems(
                  organizationDetails,
                  navigateOrganizationReports,
                  navigateMemberManagement,
                  navigateEditOrg,
                  navigateOrgIntegrationSettings,
                  openExitConfirmationModal
                )}
              >
                <Settings className="mr-1" fontSize="small" />{" "}
                {t("Manage", "common")}
              </MenuButton>

              <Button
                color={"blue"}
                loading={downloading}
                onClick={downloadReport}
              >
                {t("Export Report")}
              </Button>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          style={{ justifyContent: "space-around" }}
          className={"mt-4 mb-4"}
        >
          {orgStat?.map((item, index) => (
            <Grid item key={index} xs={12} md={2}>
              <div className="flex items-center" style={{ gap: "1em" }}>
                {item?.icon}
                <div>
                  <h4 className={"fs-15 bold"}>{item?.title}</h4>
                  <p className={"fs-16"} title={item?.tooltip}>
                    {item?.subtitle}
                  </p>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        <ConfirmationModal
          open={openConfirmationModal}
          onClose={cancelExitConfirmationModal}
          desc={t("Are you sure you want to delete this Organization?")}
          okayButtonTitle={t("Delete Organization")}
          onOkay={() => {
            handleDelete(orgToDelete);
            cancelExitConfirmationModal();
          }}
        />
      </PageTitleCard>
    </>
  );
};

export default OrganizationReportDetails;
