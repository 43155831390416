import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {
  mockRequestDelete,
  mockRequestGet,
  mockRequestPost,
} from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useChallenges from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api";

let getSpy;
let postSpy;
let deleteSpy;

let challengeId = "1";
let organization_id = "1";
let announcementId = "1";

const headers = {
  Authorization: "Bearer 1",
};

const payload = {
  language: "en",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com" },
  },
  [REDUCER_TYPES.MASTER]: {},
});

const ChallengeServicesMock = () => {
  const {
    fetchChallenges,
    fetchPublicChallenge,
    favorite,
    unFavorite,
    like,
    unLike,
    share,
    checkSlug,
    checkTitle,
    fetchPrivateChallenges,
    fetchChallenge,
    deleteChallenge,
    create,
    edit,
    fetchAssessment,
    updateAssessment,
    clone,
    fetchChallengeSelectList,
    fetchAnnouncements,
    createAnnouncement,
    deleteAnnouncement,
    fetchTemplates,
    addTemplate,
    removeTemplate,
    redeemTemplate,
    showTemplate,
    aiCreateChallengesPreview,
    aiCreateChallenge,
    aiCreateChallengesFromResourcesPreview,
    acceptChallenge,
    declineChallenge,
    fetchPublicSubmissionList,
    fetchManageSubmissionList,
  } = useChallenges();

  const BUTTON_LINKS = [
    { name: "fetchChallenges", onClick: () => fetchChallenges({}) },
    {
      name: "fetchPublicChallenge",
      onClick: () => fetchPublicChallenge(challengeId),
    },
    { name: "favorite", onClick: () => favorite(challengeId) },
    { name: "unFavorite", onClick: () => unFavorite(challengeId) },
    { name: "like", onClick: () => like(challengeId) },
    { name: "unLike", onClick: () => unLike(challengeId) },
    { name: "share", onClick: () => share(challengeId) },
    { name: "checkSlug", onClick: () => checkSlug(challengeId) },
    { name: "checkTitle", onClick: () => checkTitle(challengeId) },
    {
      name: "fetchPrivateChallenges",
      onClick: () => fetchPrivateChallenges({}),
    },
    { name: "fetchChallenge", onClick: () => fetchChallenge(challengeId) },
    { name: "deleteChallenge", onClick: () => deleteChallenge(challengeId) },
    { name: "create", onClick: () => create({}) },
    { name: "edit", onClick: () => edit(challengeId, {}) },
    { name: "fetchAssessment", onClick: () => fetchAssessment(challengeId) },
    {
      name: "updateAssessment",
      onClick: () => updateAssessment(challengeId, {}),
    },
    { name: "clone", onClick: () => clone(challengeId, organization_id) },
    {
      name: "fetchChallengeSelectList",
      onClick: () => fetchChallengeSelectList({ organization_id }),
    },
    {
      name: "fetchAnnouncements",
      onClick: () => fetchAnnouncements(challengeId),
    },
    {
      name: "createAnnouncement",
      onClick: () => createAnnouncement(challengeId, {}),
    },
    {
      name: "deleteAnnouncement",
      onClick: () => deleteAnnouncement(challengeId, announcementId),
    },
    { name: "fetchTemplates", onClick: () => fetchTemplates({}) },
    { name: "addTemplate", onClick: () => addTemplate(challengeId) },
    { name: "removeTemplate", onClick: () => removeTemplate(challengeId) },
    {
      name: "redeemTemplate",
      onClick: () => redeemTemplate(challengeId),
    },
    { name: "showTemplate", onClick: () => showTemplate(challengeId) },
    {
      name: "aiCreateChallengesPreview",
      onClick: () => aiCreateChallengesPreview({}),
    },
    { name: "aiCreateChallenge", onClick: () => aiCreateChallenge({}) },
    {
      name: "aiCreateChallengesFromResourcesPreview",
      onClick: () => aiCreateChallengesFromResourcesPreview({}),
    },
    { name: "acceptChallenge", onClick: () => acceptChallenge(challengeId) },
    { name: "declineChallenge", onClick: () => declineChallenge(challengeId) },
    { name: "fetchPublicSubmissionList", onClick: () => fetchPublicSubmissionList(challengeId,{}) },
    { name: "fetchManageSubmissionList", onClick: () => fetchManageSubmissionList(challengeId,{}) },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};
let screen;

describe("Challenges services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <ChallengeServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
    deleteSpy = mockRequestDelete();
  });

  test("fetchChallenges", async () => {
    const button = screen.getByTestId("fetchChallenges");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("fetchPublicChallenge", async () => {
    const button = screen.getByTestId("fetchPublicChallenge");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE}/${challengeId}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("favorite", async () => {
    const button = screen.getByTestId("favorite");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.FAVOURITE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("unFavorite", async () => {
    const button = screen.getByTestId("unFavorite");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.UNFAVOURITE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("like", async () => {
    const button = screen.getByTestId("like");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.LIKE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("unLike", async () => {
    const button = screen.getByTestId("unLike");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.UNLIKE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("share", async () => {
    const button = screen.getByTestId("share");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.SHARE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("checkSlug", async () => {
    const button = screen.getByTestId("checkSlug");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${ENDPOINTS.CHECK_SLUG}/${challengeId}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("checkTitle", async () => {
    const button = screen.getByTestId("checkTitle");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${ENDPOINTS.CHECK_TITLE}/${challengeId}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("fetchPrivateChallenges", async () => {
    const button = screen.getByTestId("fetchPrivateChallenges");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}?language=en&`,
        {},
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("fetchChallenge", async () => {
    const button = screen.getByTestId("fetchChallenge");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${challengeId}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("deleteChallenge", async () => {
    const button = screen.getByTestId("deleteChallenge");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("create", async () => {
    const button = screen.getByTestId("create");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${ENDPOINTS.CREATE}?language=en`,
        {
          ...payload,
          language: "en",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("edit", async () => {
    const button = screen.getByTestId("edit");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.UPDATE}?language=en`,
        {
          ...payload,
          language: "en",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("fetchAssessment", async () => {
    const button = screen.getByTestId("fetchAssessment");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${ENDPOINTS.ASSESSMENT}/${challengeId}?language=en`,
        {},
        headers,
        expect.any(Function)
      );
    });
  });

  test("updateAssessment", async () => {
    const button = screen.getByTestId("updateAssessment");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.ASSESSMENT}/${ENDPOINTS.UPDATE}?language=en`,
        {
          ...payload,
          language: "en",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      );
    });
  });

  test("clone", async () => {
    const button = screen.getByTestId("clone");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.CLONE}?language=en`,
        {
          organization_id: organization_id,
          language: "en",
        },
        headers,
        expect.any(Function)
      );
    });
  });

  test("fetchChallengeSelectList", async () => {
    const button = screen.getByTestId("fetchChallengeSelectList");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${ENDPOINTS.GET_LIST}?language=en&organization_id=1`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      );
    });
  });

  test("fetchAnnouncements", async () => {
    const button = screen.getByTestId("fetchAnnouncements");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.ANNOUNCEMENT}/${ENDPOINTS.LIST}?language=en&`,
        {},
        headers,
        expect.any(Function)
      );
    });
  });

  test("createAnnouncement", async () => {
    const button = screen.getByTestId("createAnnouncement");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.ANNOUNCEMENT}/${ENDPOINTS.CREATE}?language=en`,
        {
          ...payload,
          language: "en",
        },
        headers,
        expect.any(Function)
      );
    });
  });

  test("deleteAnnouncement", async () => {
    const button = screen.getByTestId("deleteAnnouncement");
    userEvent.click(button);

    await waitFor(() => {
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.ANNOUNCEMENT}/${ENDPOINTS.DELETE}?language=en&announcement_id=1`,
        headers,
        expect.any(Function)
      );
    });
  });

  test("fetchTemplates", async () => {
    const button = screen.getByTestId("fetchTemplates");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_TEMPLATE}?language=en&`,
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        headers,
        expect.any(Function)
      );
    });
  });

  test("addTemplate", async () => {
    const button = screen.getByTestId("addTemplate");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_TEMPLATE}/${challengeId}/${ENDPOINTS.ADD}?language=en`,
        {},
        headers,
        expect.any(Function)
      );
    });
  });

  test("removeTemplate", async () => {
    const button = screen.getByTestId("removeTemplate");
    userEvent.click(button);

    await waitFor(() => {
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_TEMPLATE}/${challengeId}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      );
    });
  });

  test("redeemTemplate", async () => {
    const button = screen.getByTestId("redeemTemplate");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_TEMPLATE}/${challengeId}/${ENDPOINTS.REDEEM}?language=en`,
        {
          language: "en",
        },
        headers,
        expect.any(Function)
      );
    });
  });

  test("showTemplate", async () => {
    const button = screen.getByTestId("showTemplate");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_TEMPLATE}/${challengeId}?language=en`,
        {},
        headers,
        expect.any(Function)
      );
    });
  });

  test("aiCreateChallengesPreview", async () => {
    const button = screen.getByTestId("aiCreateChallengesPreview");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}/${ENDPOINTS.PREVIEW}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": "application/json",
        },
        expect.any(Function)
      );
    });
  });

  test("aiCreateChallenge", async () => {
    const button = screen.getByTestId("aiCreateChallenge");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": "application/json",
        },
        expect.any(Function)
      );
    });
  });

  test("aiCreateChallengesFromResourcesPreview", async () => {
    const button = screen.getByTestId("aiCreateChallengesFromResourcesPreview");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}/${ENDPOINTS.RESOURCE}/${ENDPOINTS.PREVIEW}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": "application/json",
        },
        expect.any(Function)
      );
    });
  });

  test("acceptChallenge", async () => {
    const button = screen.getByTestId("acceptChallenge");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.INVITATION_MANAGEMENT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.REQUEST}/${ENDPOINTS.ACCEPT}?language=en`,
        { email: "test@gmail.com" },
        headers,
        expect.any(Function)
      );
    });
  });

  test("declineChallenge", async () => {
    const button = screen.getByTestId("declineChallenge");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.INVITATION_MANAGEMENT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.REQUEST}/${ENDPOINTS.DECLINE}?language=en`,
        { email: "test@gmail.com" },
        headers,
        expect.any(Function)
      );
    });
  });

  test("fetchPublicSubmissionList", async () => {
    const button = screen.getByTestId("fetchPublicSubmissionList");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.SUBMISSION}?language=en&`,
        {},
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      );
    });
  });

  test("fetchManageSubmissionList", async () => {
    const button = screen.getByTestId("fetchManageSubmissionList");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.SUBMISSION}?language=en&`,
        {},
        headers,
        expect.any(Function)
      );
    });
  });
});
