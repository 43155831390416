import React, { useEffect, useState } from "react";
import LabListing from "./LabListing";
import { lab, labProgram } from "assets/svg/blue";
import { COLORS, COMPONENT_TYPES, ROLES } from "helpers/utils/constants";
import NuxLayout from "components/UI/molecules/NuxLayout";
import useRedirect from "helpers/hooks/useRedirect";
import useLabs from "store/labs/service-hook";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import ManageWrapper from "components/UI/organisms/ManageWrapper";
import useListingHeader from "helpers/hooks/useListingHeader";
import CreationOptionsModal from "components/Modal/CreateOptionsModal";
import { edit, marketplace } from "assets/svg/white";
import { openai } from "assets/svg/green";
import { addSpecialFilters, hasFiltersApplied } from "helpers/utils/utilities";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";

export const browse_options = [
  { id: 1, title: "Labs", pageTitle: "Manage Labs" },
  { id: 2, title: "Lab Programs", pageTitle: "Manage Lab Programs" },
];

export const getLabNUX = (onClick) => ({
  0: {
    title: "You have no Labs to manage. Let’s create your first Lab!",
    nuxCardData: {
      title: "Create Your First Lab",
      icon: lab,
      bgColor: COLORS.DARK_BLUE,
      onClick,
    },
  },
  1: {
    title: "You have no Lab programs. Lets create your first Lab program ",
    nuxCardData: {
      title: "Create Your First Lab Program",
      icon: labProgram,
      bgColor: COLORS.DARK_BLUE,
      onClick,
    },
  },
});

export const useLabOptions = () => {
  const { navigateCreateLabAI, navigateCreateLab, navigateLabMarketplace } =
    useRedirect();
  return [
    {
      id: "maretplace",
      icon: marketplace,
      title: "From Lab Marketplace",
      description:
        "Redeem a Lab from our Marketplace to get started right away.",
      color: COLORS.BLUE,
      onClick: navigateLabMarketplace,
    },
    {
      id: "ai",
      icon: openai,
      title: "From Lab Builder AI",
      description:
        "Start your Lab creation journey in only a few steps with the help of AI.",
      color: COLORS.GREEN,
      onClick: () => navigateCreateLabAI(),
    },
    {
      id: "scratch",
      icon: edit,
      title: "From Scratch",
      description: "Want to proceed without a template or AI?",
      color: COLORS.YELLOW,
      closeOnClick: true,
      onClick: () => navigateCreateLab(),
    },
  ];
};

const ManageLabs = () => {
  const state = useListingHeader();
  const { navigateManageLabPrograms } = useRedirect();
  const [labs, setLabs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(0);
  const { user } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  const [page, setPage] = useState(1);
  const { fetchLabs, deleteLab } = useLabs();
  const { selected } = useFilters();
  const [modal, setModal] = useState(null);
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    handleFetchLabs();
  }, [page, selected, state.sort, state.search]);

  const handleFetchLabs = async () => {
    setLoading(true);
    const filters = {
      ...selected,
      page,
      sort_by: state.sort,
    };
    addSpecialFilters(filters, selected, "m");
    if (state.search) {
      filters.search = state.search;
    }
    if (filters.lab_source) {
      filters.source = selected.lab_source;
      delete filters.lab_source;
    }
    if (selected?.privacy)
      filters.privacy = selected?.privacy === "public" ? "no" : "yes";
    if (selected?.multi_type) filters.type = selected.multi_type;
    delete filters.multi_type;
    const [list, count] = await fetchLabs({ ...filters });
    setLabs(list || []);
    if (page > count) setPage(count);
    setPages(count || 0);
    setLoading(false);
  };

  const handleDeleteLab = async (slug) => {
    setLoading(true);
    await deleteLab(slug);
    setLabs((val) => val.filter((d) => d.slug !== slug));
    setLoading(false);
  };

  const handleModalOpen = () => setModal("create");
  const handleModalClose = () => setModal(null);
  const labOptions = useLabOptions();
  const noSearchValue = !state.search || state.search.trim() === "";
  const NEW_USER_DATA = getLabNUX(() => setModal("create"));

  const isSuperAdmin =
    user?.roles === ROLES.SUPER_ADMIN ||
    user?.roles === ROLES.CUSTOMER_SUCCESS ||
    user?.roles === ROLES.DEVELOPER;

  return (
    <>
      <ManageWrapper
        {...state}
        browseMode={0}
        setBrowseMode={(val) => val === 1 && navigateManageLabPrograms()}
        browseOptions={browse_options}
        createActionTitle="Create"
        navigateCreate={handleModalOpen}
        component={COMPONENT_TYPES.LAB}
        createOptions={labOptions}
        filters={[
          FILTER_TYPES.MULTI_TYPE,
          FILTER_TYPES.DURATION,
          FILTER_TYPES.LEVEL,
          FILTER_TYPES.PRIVACY,
          FILTER_TYPES.LAB_SOURCE,
          isSuperAdmin && FILTER_TYPES.MARKETPLAECE_STATUS,
        ].filter(Boolean)}
      >
        {!hasFiltersApplied(selected) &&
        noSearchValue &&
        !labs?.length &&
        !loading ? (
          <NuxLayout browseMode={0} newUserData={NEW_USER_DATA} />
        ) : (
          <>
            <LabListing
              browseMode={0}
              gridType={state.gridType}
              labs={labs}
              loading={loading}
              page={page}
              pageCount={pages}
              setPage={setPage}
              handleDelete={handleDeleteLab}
              manage
              setLabs={setLabs}
            />
          </>
        )}
      </ManageWrapper>
      {modal === "create" ? (
        <CreationOptionsModal
          open={modal}
          title={t("How Do You Want To Create A New Lab?")}
          onClose={handleModalClose}
          options={labOptions}
        />
      ) : null}
    </>
  );
};

export default ManageLabs;
