import DashboardLayout from 'pages/layouts/dashboard'
import React, { useEffect, useState } from 'react'
import ChallengePathMainPage from './components/ChallengePathMainPage'
import useChallengePaths from 'store/challenge-paths/service-hook';
import { useParams, useSearchParams } from 'react-router-dom';
import useAuth from "store/auth/service-hook";

const ChallengePaths = () => {
  const { fetchPublicChallengePath, fetchChallengePath } = useChallengePaths();
  const [challengePath, setChallengePath] = useState();
  const { id } = useParams();
  const [params, setSearchParams] = useSearchParams();
  const isPrivateView = params.get("private") === "true";

    const {
      state: { isLoggedIn },
    } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      if (params.has("private")) {
        params.delete("private");
        setSearchParams(params);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (id) handleFetchDetail();
  }, [id]);

  const handleFetchDetail = async () => {
    const res = (isPrivateView && isLoggedIn)
      ? await fetchChallengePath(id)
      : await fetchPublicChallengePath(id);
    if (!res) {
      setChallengePath({ error: true });
      return;
    }
    setChallengePath(res);
  };

  return (
    <DashboardLayout
      loading={!challengePath}
      isEmpty={!!challengePath?.error}
      emptyMessage="Not able to access the challenge path!">
      <ChallengePathMainPage challengePath={challengePath} isPrivate={isPrivateView} setChallengePath={setChallengePath} />
    </DashboardLayout>
  )
}

export default ChallengePaths