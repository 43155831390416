import React, {createContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useOrganizationReport from "../../../../store/reports/organization/service-hook";
import {ComponentMappings} from "../../data";
import {Box} from "@mui/system";
import Spin from "../../../../components/Spin";

/**
 *
 * @type {React.Context<{}>}
 */
export const OrganizationReportContext = createContext({})


const OrganizationReportContextWrapper = ({children}) => {
    /**
     * HOOKS
     */
    const {id} = useParams()
    const {organizationReportDetails} = useOrganizationReport()
    const [t] = useTranslation()

    /**
     * COMPONENT STATE
     */
    const [organizationDetails, setOrganizationDetails] = useState()
    const [loadingOrganizationReport, setLoadingOrganizationReport] = useState(false)
    const [activeComponent, setActiveComponent] = useState('all_challenges')
    const [components] = useState([
        {
            value: t("All Labs"),
            key: ComponentMappings.all_labs
        },
        {
            value: t("All Lab Programs"),
            key: ComponentMappings.all_lab_programs
        },
        {
            value: t("All Challenges"),
            key: ComponentMappings.all_challenges
        },
        {
            value: t("All Challenge Paths"),
            key: ComponentMappings.all_challenge_paths
        },
        {
            value: t("All Resources"),
            key: ComponentMappings.all_resources
        },
        {
            value: t("All Resource Collections"),
            key: ComponentMappings.all_resource_collections
        },
        {
            value: t("All Resource Groups"),
            key: ComponentMappings.all_resource_groups
        }
    ])

    /**
     * METHODS
     */
    const fetchOrganizationReport = async () => {
        setLoadingOrganizationReport(true)
        if (id) {
            const response = await organizationReportDetails(id)
            if (response) {
                setOrganizationDetails(response)
            }
        }
        setLoadingOrganizationReport(false)
    }

    /**
     * COMPONENT MOUNTED
     */
    useEffect(() => {
        fetchOrganizationReport()
    }, [])

    return <>
        <OrganizationReportContext.Provider value={{
            organizationDetails,
            organizationSlug: id,
            components,
            activeComponent: activeComponent,
            setActiveComponent: setActiveComponent,
        }}>
            {
                loadingOrganizationReport ?
                    (
                        <Box className="h-screen w-screen flex items-center justify-center">
                            <Spin big blue/>
                        </Box>
                    ) : ''
            }
            {!loadingOrganizationReport && organizationDetails ?
                <section className={'prepr-report-module'}>{children}</section> :
                <Box className="h-screen w-screen flex items-center justify-center">
                    <p>Failed to fetch organization.</p>
                </Box>
            }
        </OrganizationReportContext.Provider>
    </>
}

export default OrganizationReportContextWrapper
