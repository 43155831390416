import React, { useEffect, useState } from "react";
import { LOGIN_FORM_SCHEMA, login_response_data } from "../auth-data";
import logo from "../../../assets/svg/logo.svg";
import Divider from "../../../components/Divider";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import AuthLayout from "../../layouts/AuthLayout";
import useAuth from "store/auth/service-hook";
import useRedirect from "helpers/hooks/useRedirect";
import { useAuth0 } from "@auth0/auth0-react";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import useForms from "helpers/hooks/useForms";
import PassowordInput from "components/UI/atoms/PassowordInput";
import { useParams, useSearchParams } from "react-router-dom";
import { getBaseUrl } from "helpers/utils/utilities";
import {
  EMAIL_VALIDATOR,
  PASSWORD_VALIDATOR,
  REQUIRED_VALIDATOR,
  isTrue,
} from "helpers/utils/validators";
import SecureImage from "components/UI/atoms/SecureImage";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import { SCHEMA_TYPES } from "helpers/utils/constants";
import PasswordGroup from "components/UI/molecules/PasswordGroup";
import { Stack } from "@mui/material";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const getSSOFromSub = (sub) => {
  if (sub.includes("google")) return "google";
  if (sub.includes("linkedin")) return "linkedin";
  if (sub.includes("windowslive")) return "microsoft";
  if (sub.includes("apple")) return "apple";
  if (sub.includes("magnet")) return "magnet";
};

const register_steps = [
  {
    title: "First Name",
    placeholder: "Enter First Name",
    name: "first_name",
    validator: REQUIRED_VALIDATOR,
    type: SCHEMA_TYPES.INPUT,
  },
  {
    title: "Last Name",
    placeholder: "Enter Last Name",
    name: "last_name",
    validator: REQUIRED_VALIDATOR,
    type: SCHEMA_TYPES.INPUT,
  },
  {
    title: "Email Address",
    placeholder: "Enter Email",
    name: "email",
    lg: 12,
    validator: EMAIL_VALIDATOR,
    type: SCHEMA_TYPES.INPUT,
  },
  {
    title: "Username",
    placeholder: "Enter Username",
    name: "username",
    validator: REQUIRED_VALIDATOR,
    type: SCHEMA_TYPES.INPUT,
    lg: 12,
  },
  {
    title: "Password",
    name: "password",
    validator: PASSWORD_VALIDATOR,
    optinalValidatorKey: "password_confirmation",
  },
  {
    title: "Confirm Password",
    placeholder: "Confirm Password",
    name: "password_confirmation",
    validator: REQUIRED_VALIDATOR,
  },
];

const CustomLogin = () => {
  const { data } = login_response_data;
  const width = useWindowWidth();
  const {
    navigateRegister,
    navigateLogin,
    navigateForgetPassword,
    navigateExplore,
    navigatePrivacyPolicy,
    navigateTerms,
  } = useRedirect();
  const { t } = useLocalizedTranslation();
  const [params] = useSearchParams();
  const [loading, setloading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const { customUrl } = useParams();
  const [customData, setcustomData] = useState();
  const [isRegister, setIsRegister] = useState(false);
  const {
    data: regData,
    errors: regErrors,
    onChange: regOnChange,
    validate: regValidate,
  } = useForms(register_steps);

  const { login, state, checkEmail, ssoLogin, getCustomization, register } =
    useAuth();
  const { loginWithRedirect, user, isAuthenticated, getAccessTokenSilently } =
    useAuth0();
  // eslint-disable-next-line no-unused-vars
  const code = params.get("code");
  const {
    data: formData,
    errors,
    onChange,
    validate,
  } = useForms(LOGIN_FORM_SCHEMA);

  useEffect(() => {
    async function checkLoginStatus() {
      if (state.isLoggedIn) navigateExplore();
    }

    checkLoginStatus();
  }, []);

  useEffect(() => {
    if (!customUrl) return;
    handleFetchCustomization();
  }, []);

  const handleFetchCustomization = async () => {
    setInitLoading(true);
    const res = await getCustomization(customUrl);
    if (res && isTrue(res?.enable_custom_login_and_registration))
      setcustomData(res);
    else navigateLogin();
    setInitLoading(false);
  };
  useEffect(() => {
    if (isAuthenticated && !!user) {
      handleSSOLoginRegister();
    }
  }, [isAuthenticated]);

  const handleSSOLoginRegister = async () => {
    const email = user?.email;
    const { error } = await checkEmail(email);
    delete user.picture;
    if (!error) {
      navigateRegister(`sso=true`);
    } else {
      const token = await getAccessTokenSilently();
      const payload = {
        email: user?.email,
        sub: user?.sub,
        sso_type: getSSOFromSub(user?.sub),
        access_token: token,
      };
      await ssoLogin(payload);
    }
  };

  const loginWithSSO = async (connection) => {
    if (!connection) return;
    if (connection === "magnet") {
      const baseUrl = getBaseUrl();
      const redirectUri = encodeURIComponent(baseUrl + "login/magnet/callback");
      const scope = encodeURIComponent(
        "basic connect employment education lms"
      );
      const url = `https://magnet.dev.whoplusyou.com/oauth/authorize/?client_id=109&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`;
      window.location.replace(url);
      return;
    }
    await loginWithRedirect({
      authorizationParams: {
        connection: connection,
        redirectUri: window.location.origin,
      },
    });
  };

  const loginHandler = async () => {
    const newErrors = await validate();
    if (newErrors.size) return;
    setloading(true);
    const { email, password } = formData;
    const body = { email, password };
    await login(body);
    setloading(false);
  };

  const handleRegister = async () => {
    const newErrors = await regValidate();
    if (newErrors.size) return;
    setloading(true);
    await register({
      ...regData,
      register_type: "custom",
      organization_slug: customData?.slug,
      sso_registration: "no",
    });
    setloading(false);
  };

  return (
    <AuthLayout sliderHidden={width < 1000} customDetails={customData} loading={initLoading}>
      <div className="auth-card scroll">
        <SecureImage
          src={
            customData && isTrue(customData?.use_main_org_logo)
              ? customData?.profile_image
              : customData?.custom_logo_image ?? logo
          }
          alt={logo}
          placeholder={logo}
          className={`${isRegister ? "mt-2" : "mt-4"} h-70--mob`}
        />
        <h1 className={`${isRegister ? "my-2" : "my-4"} fs-26`}>
          {t(isRegister ? "Create Account" : "Sign In")}
        </h1>
        {!isRegister ? (
          <div className="flex-column items-center">
            {data?.input.map(({ title, placeholder, name, type }, index) => (
              <>
                {type === "email" ? (
                  <Input
                    placeholder={placeholder}
                    title={title}
                    key={name}
                    name={name}
                    top={index * 5}
                    error={errors.get(name)}
                    value={formData[name]}
                    classes="w-full mt-2"
                    type={type}
                    onChange={onChange}
                    t={t}
                  />
                ) : (
                  <PassowordInput
                    title={title}
                    key={name}
                    name={name}
                    top={index * 5}
                    error={errors.get(name)}
                    value={formData[name]}
                    classes="w-full mt-2"
                    onChange={onChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") loginHandler();
                    }}
                    t={t}
                  />
                )}
              </>
            ))}
            <p
              className="link w-full mt-1 fs-14"
              onClick={navigateForgetPassword}
            >
              {t("Forgot Password")}?
            </p>
            <Button
              color="orange"
              top={40}
              height="35px"
              onClick={loginHandler}
              loading={loading}
              classes="minw-90"
            >
              {t(data?.submit_button?.title)}
            </Button>
          </div>
        ) : (
          <div className="w-90p">
            <SchemaUIExtractor
              data={regData}
              schema={register_steps}
              onChange={regOnChange}
              errors={regErrors}
              mb={0}
              mt={0}
              hideAsterisk
            />
            <PasswordGroup
              data={regData}
              errors={regErrors}
              onChange={regOnChange}
              hideAsterisk
            />
            <p className="fs-13 mt-2 c-gray">
              {t("By registering, you agree to the Prepr")}{" "}
              <span className="link" onClick={navigateTerms}>
                {t("Terms of use")}
              </span>
              ,{" "}
              <span className="link" onClick={navigatePrivacyPolicy}>
                {t("Privacy policy")}
              </span>{" "}
              {t("and")}{" "}
              <span className="link" onClick={navigatePrivacyPolicy}>
                {t("cookie policy")}
              </span>
            </p>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Button
                height="35px"
                width="100px"
                onClick={handleRegister}
                loading={loading}
                classes="mx-auto mt-3"
              >
                {t("Create")}
              </Button>
            </Stack>
          </div>
        )}
        <Divider
          text={t(isRegister ? "OR REGISTER WITH" : "OR LOG IN WITH")}
          classes="mt-6 mb-3 tracking-tight maxw-70p"
        />
        <div className="flex items-center mt-3">
          {data?.sso.map(({ title, icon, url, connection }, index) => (
            <img
              src={require("../../../" + icon)}
              className="mx-2 cursor-pointer"
              key={title}
              height={40}
              onClick={() => loginWithSSO(connection)}
              alt={`social icon of ${title}`}
            />
          ))}
        </div>
        <div className="text-center my-5 flex fw-500 fs-14">
          <p className="m-0">
            {t(
              isRegister ? "Already have an account?" : "Create a new account?"
            )}
          </p>
          <p
            onClick={() => setIsRegister((prev) => !prev)}
            className="link ml-1"
          >
            {t(isRegister ? "Sign In" : "Register here")}
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};

export default CustomLogin;
