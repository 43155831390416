import React from "react";
import DashboardCard from "./components/DashboardCard";
import DragWrapper from "components/DragNDrop/DragWrapper";

const DashboardWrapper = ({
  items,
  setItems,
  isCustomizeMode,
  setInactiveById,
}) => {
  return (
    <DragWrapper items={items} setItems={setItems} draggable={isCustomizeMode}>
      {items.filter(Boolean).map((item) => (
        <div className="drag-handle" key={item?.uuid || item?.id}>
          <DashboardCard
            tabs={item?.tabs || []}
            component={item?.component}
            rows={item?.rows || []}
            columns={item?.columns || []}
            title={item?.title}
            isCustomizeMode={isCustomizeMode}
            id={item?.id}
            setInactiveById={setInactiveById}
          >
            {item?.children}
          </DashboardCard>
        </div>
      ))}
    </DragWrapper>
  );
};

export default DashboardWrapper;
