import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { resolveResponse } from "store/api/utils";
import { serializeObject } from "store/api/utils";

const useProjects = () => {
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  const headers = {
    Authorization: "Bearer " + token,
  };
  //PROJECT LISTING API
  const list = async (type = "my", filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${
        ENDPOINTS.PROJECTS
      }?type=${type}&language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      const { list, total_pages, pending_assessments, pending_invites } =
        res?.data?.data;
      return [list, total_pages, pending_assessments, pending_invites];
    }
    return [];
  };

  //PROJECT VIEW DETAIL PAGE API
  const view = async (id) => {
    if (!id) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PROJECTS}/${id}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  //CREATE PROJECT API
  const create = async (payload) => {
    if (!payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROJECTS}/${ENDPOINTS.CREATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //EDIT PROJECT API
  const edit = async (slug, payload) => {
    if (!slug || !payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROJECTS}/${slug}/${ENDPOINTS.UPDATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  // ADD PITCH TO PROJECT API
  const addPitch = async (slug, payload) => {
    if (!slug || !payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROJECTS}/${slug}/${ENDPOINTS.PITCH_TASK}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //UPLOAD FILES API
  const fileUpload = async (slug, payload) => {
    if (!slug || !payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROJECTS}/${slug}/${ENDPOINTS.FILE_UPLOAD}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //VIEW REQUIREMENTS API
  const getRequirements = async (id) => {
    if (!id) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PROJECTS}/${id}/${ENDPOINTS.REQUIREMENTS}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  //VIEW HISTORY OF PROJECT
  const getHistory = async (id) => {
    if (!id) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PROJECTS}/${id}/${ENDPOINTS.HISTORY}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  //ADD EXTERNAL LINKS API
  const addExternalLinks = async (slug, payload) => {
    if (!slug || !payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROJECTS}/${slug}/${ENDPOINTS.EXTERNAL_LINKS}?language=${language}`,
      body,
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //ADD ADDITIONAL INFORMATION API
  const addAdditionalInfo = async (slug, payload) => {
    if (!slug || !payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROJECTS}/${slug}/${ENDPOINTS.ADDITIONAL_INFO}?language=${language}`,
      body,
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //DELETE PROJECT API
  const deleteProject = async (slug) => {
    if (!slug) return;
    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.PROJECTS}/${slug}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      resolveResponse
    );
  };

  //SUBMIT PROJECT API
  const submit = async (slug, payload) => {
    if (!slug) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROJECTS}/${slug}/${ENDPOINTS.SUBMIT}?language=${language}`,
      body,
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //VOTE & UNVOTE PROJECT API
  const voteUnvote = async (slug, unvote = false) => {
    if (!slug) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROJECTS}/${slug}/${
        unvote ? ENDPOINTS.UNVOTE : ENDPOINTS.VOTE
      }?language=${language}`,
      {},
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //LIKE & UNLIKE PROJECT API
  const likeUnlike = async (slug, unlike = false) => {
    if (!slug) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROJECTS}/${slug}/${
        unlike ? ENDPOINTS.UNLIKE : ENDPOINTS.LIKE
      }?language=${language}`,
      {},
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //FAVORITE & UNFAVORITE PROJECT API
  const favUnfav = async (slug, unfav = false) => {
    if (!slug) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROJECTS}/${slug}/${
        !unfav ? ENDPOINTS.FAVOURITE : ENDPOINTS.UNFAVOURITE
      }?language=${language}`,
      {},
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //PIN & UNPIN PROJECT API
  const pinUnpin = async (slug, unpin = false) => {
    if (!slug) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROJECTS}/${slug}/${
        unpin ? ENDPOINTS.UNPIN : ENDPOINTS.PIN
      }?language=${language}`,
      {},
      headers,
      resolveResponse
    );
    return res?.data?.data;
  };

  //ADD ASSESSMENT PROJECT API
  const addAssessment = async (slug, payload) => {
    if (!slug || !payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROJECTS}/${slug}/${ENDPOINTS.ASSESSMENT}/${ENDPOINTS.ADD}?language=${language}`,
      body,
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  //VIEW PROJECT ASSESSMENT API
  const getAssessment = async (slug) => {
    if (!slug) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PROJECTS}/${slug}/${ENDPOINTS.ASSESSMENT}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) return res?.data?.data;
  };

  const deleteAssessment = async (slug) => {
    if (!slug) return;
    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.PROJECTS}/${slug}/${ENDPOINTS.ASSESSMENT}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      resolveResponse
    );
  };

  //ACCEPT & DECLINE JOIN REQUEST API
  const acceptDeclineJoinRequest = async (slug, email, isDecline) => {
    if (!slug || !email) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MEMBER_MANAGEMENT}/${ENDPOINTS.PROJECTS}/${slug}/${
        ENDPOINTS.PARTICIPANT_REQUEST
      }/${
        isDecline ? ENDPOINTS.DECLINE : ENDPOINTS.ACCEPT
      }?language=${language}`,
      { email },
      headers,
      Function.prototype
    );
    if (res?.data?.data) return res?.data?.data;
  };

  const sendRequest = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const response = await client.get(
      `${ENDPOINTS.TEAM_MATCHING}/${slug}/${ENDPOINTS.SEND_REQUEST}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return response;
  };

  //VIEW CHALLENGE REQUIREMENTS
  const initRequirements = async (uuid) => {
    if (!uuid) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE}/${uuid}/${ENDPOINTS.REQUIREMENTS}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  //DELETE MEDIA
  const deleteMedia = async (slug, id, type) => {
    if (!slug || !id) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.delete(
      `${ENDPOINTS.PROJECTS}/${slug}/${ENDPOINTS.MEDIA}?language=${language}&media_id=${id}&type=${type}`,
      headers,
      onSuccess
    );
    return Boolean(res?.data?.success);
  };

  //GET ACTIVE LABS
  const fetchActiveLabs = async (filters = {}) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${ENDPOINTS.ACTIVE}/${
        ENDPOINTS.LIST
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data?.length) {
      return res?.data?.data;
    }
    return [];
  };

  // LIST TEAMS
  const teams = async (type = "browse", filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${
        ENDPOINTS.TEAM_MATCHING
      }/${type}?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      const { list, total_pages } = res?.data?.data;
      return [list, total_pages];
    }
    return [];
  };

  // TEAM MATCHING
  const teamMatchingProfileCheck = async () => {
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.TEAM_MATCHING}/${ENDPOINTS.PROFILE_CHECK}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) return res?.data?.data;
  };

  // COUNTS FOR TEAM MATCHING
  const counts = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.TEAM_MATCHING}/${ENDPOINTS.COUNT}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) return res?.data?.data;

    return [];
  };

  //ACCEPT & DECLINE PARTICIPATES REQUEST API
  const acceptDeclineJoinParticipateRequest = async (
    slug,
    email,
    isDecline
  ) => {
    if (!slug || !email) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MEMBER_MANAGEMENT}/${ENDPOINTS.PROJECTS}/${slug}/${
        ENDPOINTS.REQUEST
      }/${
        isDecline ? ENDPOINTS.DECLINE : ENDPOINTS.ACCEPT
      }?language=${language}`,
      { email },
      headers,
      Function.prototype
    );
    if (res?.data?.data) return res?.data?.data;
  };

  return {
    list,
    view,
    create,
    edit,
    addPitch,
    fileUpload,
    getRequirements,
    addExternalLinks,
    addAdditionalInfo,
    deleteProject,
    submit,
    voteUnvote,
    likeUnlike,
    favUnfav,
    addAssessment,
    getAssessment,
    deleteAssessment,
    acceptDeclineJoinRequest,
    initRequirements,
    deleteMedia,
    getHistory,
    fetchActiveLabs,
    teams,
    sendRequest,
    teamMatchingProfileCheck,
    counts,
    acceptDeclineJoinParticipateRequest,
    pinUnpin,
  };
};

export default useProjects;
