import DashboardLayout from "pages/layouts/dashboard";
import React from "react";
import MemberManagementMainPage from "./containers/MemberManagementMainPage";

const MemberManagement = () => {
  return (
    <DashboardLayout>
      <MemberManagementMainPage />
    </DashboardLayout>
  );
};

export default MemberManagement;
