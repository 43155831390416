import React from "react";
import parse from "html-react-parser";

const IframePreview = ({ media, height, placeholder, title }) => {
  const [isValidIframe, setIsValidIframe] = React.useState(false);

  const cleanMediaContent = (media = "") => {
    if (!media) return "";
    const iframeStart = media.indexOf("<iframe");
    if (iframeStart !== -1) {
      return media.substring(iframeStart);
    }
    return media;
  };

  // Function to modify iframe height and width
  const adjustIframeAttributes = (htmlContent, fixedHeight = "250px") => {
    return htmlContent.replace(
      /<iframe([^>]+)>/g,
      (match, iframeAttributes) => {
        // Match the iframe tag and adjust its height and width attributes
        const updatedIframe = iframeAttributes
          .replace(/height="(\d+)"/, `height="${fixedHeight}"`) // Replace height with fixed value
          .replace(/width="(\d+)"/, `width="100%"`); // Replace width with 100% (or adjust as needed)

        return `<iframe${updatedIframe}>`;
      }
    );
  };

  // Validate if media contains a valid iframe
  const validateIframe = (htmlContent) => {
    const iframeRegex = /<iframe\s+[^>]*src="([^"]+)"[^>]*><\/iframe>/;
    return iframeRegex.test(htmlContent);
  };

  // Adjust iframe attributes and validate iframe
  const adjustedMedia = cleanMediaContent(media);
  const adjustedIframe = adjustIframeAttributes(adjustedMedia, height);

  React.useEffect(() => {
    setIsValidIframe(validateIframe(adjustedMedia));
  }, [media]);

  return (
    <div className="w-full relative" style={{ height }}>
      {/* Render the adjusted iframe only if it's valid */}
      {isValidIframe ? (
        parse(adjustedIframe)
      ) : (
        <img
          src={media}
          alt="media"
          height={height}
          width="100%"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = placeholder;
          }}
        />
      )}
      {!isValidIframe && title && (
        <div className="absolute flex items-center w-100p h-full top-0 left-0 justify-center">
          <h3
            className={`uppercase tracking-tight text-center mx-auto text-truncate maxw-90p fw-600 fs-16 mb-1"`}
          >
            {title}
          </h3>
        </div>
      )}
    </div>
  );
};

export default IframePreview;
