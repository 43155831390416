import React from "react";
import useAdvanceSearchFilter from "../../../hooks/useAdvanceSearchFilter";
import { AS_REDUCER } from "../../../../../store/advance-search/reducer";
import useAdvanceSearch from "../../../../../store/advance-search/service-hook";
import { useAdvanceSearchContext } from "../../../hooks/useAdvanceSearchContext";
import QuickSearchListing from "../../QuickSearch";
import { ADVANCE_SEARCH_COMPONENTS } from "../../../data";

const QuickSearchChallenge = ({ t = (d) => d }) => {
  const { quickKeyword } = useAdvanceSearchContext();
  const { searchChallenges } = useAdvanceSearch();
  const { data } = useAdvanceSearchFilter(
    ADVANCE_SEARCH_COMPONENTS.CHALLENGES,
    quickKeyword,
    null,
    searchChallenges,
    AS_REDUCER.setChallenges,
    true
  );

  return (
    <QuickSearchListing
      data={data?.data?.slice(0, 3) ?? []}
      component={ADVANCE_SEARCH_COMPONENTS.CHALLENGES}
      componentName={"Challenges"}
      loading={data?.loading}
      t={t}
    />
  );
};

export default QuickSearchChallenge;
