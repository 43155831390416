import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import { mockRequestGet } from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useLeaderboard from "./service-hook";
import { BrowserRouter } from "react-router-dom";

let getSpy;

let leaderBoardId = "1"; 

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
});

const LeaderboardServicesMock = () => {
  const { list, labList } = useLeaderboard();

  const BUTTON_LINKS = [
    { name: "list", onClick: () => list({}) },
    { name: "labList", onClick: () => labList(leaderBoardId, {}) },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};
let screen;

describe("Leaderboard services", () => {
    beforeEach(() => {
      screen = render(
        <Provider store={store}>
          <BrowserRouter>
            <LeaderboardServicesMock />
          </BrowserRouter>
        </Provider>
      );
      getSpy = mockRequestGet();
    });
  
    test("list", async () => {
      const button = screen.getByTestId("list");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.LEADERBOARD}?language=en&`,
          {},
          headers,
          expect.any(Function)
        )
      );
    });

    test("labList", async () => {
        const button = screen.getByTestId("labList");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.LEADERBOARD}/${leaderBoardId}/${ENDPOINTS.LAB}?language=en&`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });
  
  });
