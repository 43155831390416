import React, { useCallback, useEffect, useState } from "react";
import classes from "../../../achievements/achievements.module.scss";
import { ExpandMore, KeyboardArrowRight } from "@mui/icons-material";
import { Checkbox, FormControlLabel } from "@mui/material";
import SearchableSelect from "../../../../components/Input/SearchableSelect";

const SubFilter = (props) => {
  /**
   * COMPONENT PROPS
   */
  const {
    fetchData,
    select,
    valueKey,
    labelKey,
    onChange,
    options,
    show,
    title,
    values,
    t = (d) => d,
  } = props;

  /**
   * COMPONENT STATE
   */
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  /**
   * METHODS
   * @type {(function(*): void)|*}
   */
  const setFormattedData = useCallback(
    (options) => {
      setData(
        options.map((data) => {
          return {
            label: data?.[labelKey] ?? data?.label ?? "",
            value: data?.[valueKey] ?? data?.value ?? "",
          };
        })
      );
    },
    [setData]
  );

  /**
   * INITIALIZATION
   */
  const init = async () => {
    if (options && options?.length) {
      setFormattedData(options);
    } else if (fetchData && !select) {
      setLoading(true);
      const responseData = await fetchData();
      if (responseData && responseData?.length) {
        setFormattedData(responseData);
      }
      setLoading(false);
    }
  };

  /**
   * COMPONENT MOUNTED FUNCTION
   */
  useEffect(() => {
    init();
  }, [options]);

  return (
    <>
      {show ? (
        <div className={classes.filterBox}>
          <p
            className={`${classes["filterBox--title"]} cursor-pointer`}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {!open ? (
              <KeyboardArrowRight className="cursor-pointer" />
            ) : (
              <ExpandMore className="cursor-pointer" />
            )}
            {title}
          </p>
          {open && !loading ? (
            <div className={`${classes["filterBox--content"]} flex-column`}>
              {select ? (
                <SearchableSelect
                  width={"100%"}
                  value={values}
                  multi
                  placeholder={"Select option"}
                  onChange={(value) => {
                    onChange(value);
                  }}
                  fetch={[fetchData, ""]}
                  t={t}
                />
              ) : (
                ""
              )}
              {!select && data && data.length
                ? data?.map((item) => (
                    <FormControlLabel
                      key={item?.value}
                      label={t(item?.label)}
                      classes={{
                        label: "fs-14",
                      }}
                      control={
                        <Checkbox
                          size="small"
                          checked={values.includes(item?.value)}
                          onChange={(event) => {
                            onChange(event?.target?.checked, item?.value);
                          }}
                        />
                      }
                    />
                  ))
                : ""}
            </div>
          ) : (
            <div className="my-2"></div>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SubFilter;
