import CreateFormSection from "components/UI/atoms/CreateFormSection";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";

import { stepSchema } from "../data";
import ChallengesTaskAndQuestionsListing from "./ChallengesTaskAndQuestionsListing";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const TaskAndChallenge = ({
  t = (d) => d,
  data,
  errors,
  onChange,
  step,
  taskValues,
  setTaskValues,
  questionsValues,
  setQuestionsValues,
  taskAndQuestionError,
  setTaskAndQuestionError,
  isProjectAlreadyPublished,
}) => {
  const updatedSchema = stepSchema(step)
    .slice(0, 1)
    .map((s) => {
      return {
        ...s,
        disabled: isProjectAlreadyPublished,
      };
    });

  return (
    <CreateFormSection
      classes={`w-full my-2 ${isProjectAlreadyPublished ? "opacity-50" : ""}`}
    >
      <h3 className="fs-22">{t("Challenge Tasks and Questions")}</h3>
      <p className="fs-15 opacity-60">
        {t("Set the Challenge template for Project submissions.")}
      </p>
      <SchemaUIExtractor
        schema={updatedSchema}
        data={data}
        errors={errors}
        onChange={onChange}
        template="task"
      />
      {data?.template_id && (
        <ChallengesTaskAndQuestionsListing
          templateId={data?.template_id?.key}
          taskValues={taskValues}
          setTaskValues={setTaskValues}
          questionsValues={questionsValues}
          setQuestionsValues={setQuestionsValues}
          taskAndQuestionError={taskAndQuestionError}
          setTaskAndQuestionError={setTaskAndQuestionError}
          isProjectAlreadyPublished={isProjectAlreadyPublished}
          t={t}
        />
      )}
    </CreateFormSection>
  );
};

export default TaskAndChallenge;
