import {resolveResponse, serializeObject} from "../../api/utils";
import REQUEST from "../../api";
import {ENDPOINTS} from "../../api/endpoints";
import {useSelector} from "react-redux";
import {REDUCER_TYPES} from "../../index";

const useOrganizationReport = () => {

    /**
     * REDUX STATE
     */
    const {language, token} = useSelector(
        (state) => state[REDUCER_TYPES.AUTH]
    );

    /**
     * HEADER
     * @type {{Authorization: string}}
     */
    const headers = {
        Authorization: "Bearer " + token,
    };

    /**
     *
     * @param slug
     * @returns {Promise<*>}
     */
    const organizationReportDetails = async (slug) => {
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${slug}/details?language=${language}`,
            {},
            headers,
            Function.prototype
        );
        if (res?.data?.data) {
            return res?.data?.data;
        }
    }

    /**
     *
     * @param slug
     * @param page
     * @param per_page
     * @param search
     * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
     */
    const organizationChallenges = async (slug, page, per_page,search = '') => {
        const params = {
            search, page, per_page
        }
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.CHALLENGES}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                search: res?.data?.data?.search,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    }

    /**
     *
     * @param slug
     * @param page
     * @param per_page
     * @param search
     * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
     */
    const organizationChallengePaths = async (slug, page, per_page , search = '') => {
        const params = {
            search, page, per_page
        }
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.CHALLENGE_PATHS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                search: res?.data?.data?.search,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    }

    /**
     *
     * @param slug
     * @param page
     * @param per_page
     * @param search
     * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
     */
    const organizationLabs = async (slug, page, per_page, search = '') => {
        const params = {
            search, page, per_page
        }
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.LABS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                search: res?.data?.data?.search,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    }

    /**
     *
     * @param slug
     * @param page
     * @param per_page
     * @param search
     * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
     */
    const organizationLabPrograms = async (slug, page, per_page, search = '') => {
        const params = {
            search, page, per_page
        }
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.LAB_PROGRAMS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                search: res?.data?.data?.search,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    }

    /**
     *
     * @param slug
     * @param page
     * @param per_page
     * @param search
     * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
     */
    const organizationResourceModules = async (slug, page, per_page, search = '') => {
        const params = {
            search, page, per_page
        }
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.RESOURCE_MODULES}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                search: res?.data?.data?.search,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    }

    /**
     *
     * @param slug
     * @param page
     * @param per_page
     * @param search
     * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
     */
    const organizationResourceGroup = async (slug, page, per_page, search = '') => {
        const params = {
            search, page, per_page
        }
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.RESOURCE_GROUPS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                search: res?.data?.data?.search,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    }

    /**
     *
     * @param slug
     * @param page
     * @param per_page
     * @param search
     * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
     */
    const organizationResourceCollection = async (slug, page, per_page, search = '') => {
        const params = {
            search, page, per_page
        }
        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.RESOURCE_COLLECTIONS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                search: res?.data?.data?.search,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }
    }

    /**
     *
     * @param slug
     * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
     */
    const organizationMemberProgress = async (slug) => {

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.MEMBER_PROGRESS}?language=${language}`,
            {},
            headers,
            onSuccess
        );

        if (res?.data?.data) {
            return res?.data?.data
        }

        return null
    }

    /**
     *
     * @param slug
     * @param page
     * @param search
     * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
     */
    const organizationMembers = async (slug, page, search = '') => {

        const params = {
            search, page
        }

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.ORGANIZATION_MEMBERS}?language=${language}&${serializeObject(params)}`,
            {},
            headers,
            onSuccess
        );

        return {
            data: res?.data?.data?.list ?? [],
            meta: res?.data?.data ? {
                total: res?.data?.data?.total_count,
                per_page: res?.data?.data?.per_page,
                current_page: res?.data?.data?.current_page,
                search: res?.data?.data?.search,
                total_pages: res?.data?.data?.total_pages,
            } : false
        }

    }

    const organizationEngagements = async (slug) => {

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.ENGAGEMENT}?language=${language}`,
            {},
            headers,
            onSuccess
        );

        if (res?.data?.data) {
            return res?.data?.data
        }

        return null
    }
    const organizationMemberActivity = async (slug) => {

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.MEMBER_ACTIVITY}?language=${language}`,
            {},
            headers,
            onSuccess
        );

        if (res?.data?.data) {
            return res?.data?.data
        }

        return null
    }

    const downloadOrganizationReport = async (slug) => {
        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.EXPORT}?language=${language}`,
            {},
            {
                ...headers,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            Function.prototype,
            Function.prototype,
            false,
            'blob'
        );
        if (res?.data) {
            const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${slug}.xlsx`;
            link.click();
        }
    }


    return {
        organizationMemberProgress,
        organizationReportDetails,
        organizationLabs,
        organizationLabPrograms,
        organizationChallenges,
        organizationChallengePaths,
        organizationResourceModules,
        organizationResourceGroup,
        organizationResourceCollection,
        organizationEngagements,
        organizationMembers,
        organizationMemberActivity,
        downloadOrganizationReport
    }
}

export default useOrganizationReport
