import Hotjar from '@hotjar/browser';

const HotjarComponent = () => {
  /**
  * COMPONENT MOUNTED
  */
  
  Hotjar.init(process.env.REACT_APP_HOTJAR_KEY, 6); //Hotjar version 6
};

export default HotjarComponent;
