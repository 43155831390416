import AuthLayout from "pages/layouts/AuthLayout";
import DashboardLayout from "pages/layouts/dashboard";
import React from "react";
import ErrrorInfoMainPage from "./components/ErrrorInfoMainPage";

const ErrorInfo = ({ type }) => {
  return (
    <DashboardLayout>
      <AuthLayout sliderHidden={true}>
        <ErrrorInfoMainPage type={type} />
      </AuthLayout>
    </DashboardLayout>
  );
};

export default ErrorInfo;
