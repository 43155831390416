import React from "react";
import SubFilter from "./SubFilter";
import { useAdvanceSearchContext } from "../../hooks/useAdvanceSearchContext";
import { FilterComponentsList } from "../../data";
import { PRIVACY_STATUS } from "helpers/utils/constants";

const PrivacyFilter = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { privacy, setPrivacy, ActiveFilterComponents } =
    useAdvanceSearchContext();

  return (
    <SubFilter
      title={t("PRIVACY")}
      values={privacy}
      onChange={(checked, value) => {
        let temp = [...privacy];
        if (checked) {
          temp.push(value);
        } else {
          temp = temp.filter((item) => item !== value);
        }
        setPrivacy(temp);
      }}
      options={PRIVACY_STATUS.map((d) => ({ ...d, label: d?.value }))}
      show={ActiveFilterComponents.includes(FilterComponentsList.privacy)}
      t={t}
    />
  );
};

export default PrivacyFilter;
