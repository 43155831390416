import { useState } from "react";

const useListingHeader = (initGridType, defaultSort) => {
  const [gridType, setGridType] = useState(initGridType || 1);
  const [sort, setSort] = useState(defaultSort || "creation_date");
  const [search, setSearch] = useState("");
  return {
    gridType,
    setGridType,
    sort,
    setSort,
    search,
    setSearch,
  };
};

export default useListingHeader;
