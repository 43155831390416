import Card from "components/Cards/Card";
import EmptyText from "components/UI/atoms/EmptyText";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { NotLoggedInTabs } from "pages/organization";
import ProjectListing from "pages/projects/listing/ProjectListing";
import React, { useEffect, useState } from "react";
import { ENDPOINTS } from "store/api/endpoints";
import useAssociation from "store/association";
import useAuth from "store/auth/service-hook";

const AssociateProjectListing = ({
  isPrivateView,
  slug,
  smallView,
  parent,
  emptyView,
}) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const {
    state: { isLoggedIn },
  } = useAuth();
  const { fetchAssociation } = useAssociation();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    handleFetchAssociation();
  }, [page]);

  const handleFetchAssociation = async () => {
    setLoading(true);
    const res = await fetchAssociation(parent, slug, ENDPOINTS.PROJECT, {
      page,
    });
    const [list, totalPages] = res;
    setProjects(list || []);
    setPages(totalPages || 1);
    setLoading(false);
  };

  return (
    <>
      {!isLoggedIn ? (
        <NotLoggedInTabs
          title={`Login to view projects in this ${parent
            .split("-")
            .join(" ")}`}
        />
      ) : (
        <ProjectListing
          projects={projects || []}
          loading={loading}
          gridType={1}
          setProjects={setProjects}
          manage={isPrivateView}
          smallView={smallView}
          page={page}
          setPage={setPage}
          pageCount={pages}
          emptyView={
            emptyView || (
              <Card classes="flex items-center justify-center w-full text-center ml-4">
                <EmptyText classes="c-gray w-70p opacity-80">
                  {t(
                    `There is no Project associated with this ${parent
                      .split("-")
                      .join(" ")} now, please check back later.`
                  )}
                </EmptyText>
              </Card>
            )
          }
        />
      )}
    </>
  );
};

export default AssociateProjectListing;
