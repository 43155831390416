import {useState} from 'react'

/**
 * USE ARRAY HOOK
 * @param array
 */
const useArrayObj = (array) => {
    /**
     * DEFAULT DATA STATE
     */
    const [data, setData] = useState(array)

    /**
     * REPLACE OBJECT FROM ARRAY
     * @param item
     * @param key
     */
    const update = (item, key) => {
        /**
         * OLD OBJECT INDEX
         */
        const oldObjectIndex = data.findIndex(
            (dataItem) => item[key] === dataItem[key]
        )

        /**
         * IF IS A VALID INDEX
         */
        if (!isNaN(oldObjectIndex)) {
            const updatedData = [...data]
            updatedData[oldObjectIndex] = item
            setData(updatedData)
        }
    }

    /**
     * REPLACE OBJECTS FROM ARRAY
     * @param items
     * @param key
     */
    const replaceObjects = (items, key) => {
        const updatedData = [...data]

        items.forEach((item) => {
            /**
             * OLD OBJECT INDEX
             */
            const oldObjectIndex = data.findIndex(
                (dataItem) => item[key] === dataItem[key]
            )

            /**
             * IF IS A VALID INDEX
             */
            if (!isNaN(oldObjectIndex)) {
                updatedData[oldObjectIndex] = item
            }
        })

        setData(updatedData)
    }


    /**
     * APPEND DATA TO THE ARRAY
     * @param item
     * @param key
     */
    const append = (item, key = undefined) => {
        if (key) {
            const existing = find(item[key], key)
            if (existing) {
                return;
            }
        }

        const updated = [...data, item]
        setData(updated)
    };

    /**
     * DELETE ITEM FROM ARRAY
     * @param deletedItem
     * @param key
     */
    const deleteItem = (deletedItem, key) => {
        const updated = [...data].filter(
            (item) => item[key] !== deletedItem[key]
        )
        setData(updated)
    }


    /**
     *
     * @param value
     * @param key
     * @param filter
     */
    const find = (
        value,
        key,
        filter = undefined
    ) => {
        if (filter) {
            return [...data].find(filter)
        }
        return [...data].find((item) => item[key] === value)
    }

    /**
     * MAKING ARRAY UNIQUE
     * @param newData
     * @param key
     */
    const appendMany = (newData, key) => {
        const hashMap = new Set();
        const uniqueArray = [...data, ...newData].filter(item => {
            const duplicate = hashMap.has(item[key]);
            hashMap.add(item[key]);
            return !duplicate;
        });
        setData(uniqueArray)
    }

    return {
        setData: appendMany,
        data,
        update,
        replaceObjects,
        find,
        deleteItem,
        append
    }
}

export default useArrayObj
