import React, { useMemo, useState } from "react";
import useAdvanceSearch from "store/advance-search/service-hook";
import useAdvanceSearchFilter from "../hooks/useAdvanceSearchFilter";
import { AS_REDUCER } from "store/advance-search/reducer";
import { useAdvanceSearchContext } from "../hooks/useAdvanceSearchContext";
import { ADVANCE_SEARCH_COMPONENTS } from "../data";
import LabListing from "pages/labs/listing/LabListing";

const AdvanceSearchLabPrograms = () => {
  const { gridType, keyword, getFiltersViaType, filters } =
    useAdvanceSearchContext();
  const { searchLabPrograms } = useAdvanceSearch();

  const [page, setPage] = useState(1);

  const Filters = useMemo(() => {
    return getFiltersViaType(ADVANCE_SEARCH_COMPONENTS.LAB_PROGRAMS);
  }, [filters]);

  const { data } = useAdvanceSearchFilter(
    ADVANCE_SEARCH_COMPONENTS.LAB_PROGRAMS,
    keyword,
    { page, ...Filters },
    searchLabPrograms,
    AS_REDUCER.setLabPrograms,
    false,
    0
  );

  return (
    <LabListing
      browseMode={0}
      labs={data?.data || []}
      gridType={gridType}
      noPagination={!Boolean(data?.meta ?? false)}
      page={data?.meta?.current_page ?? 1}
      setPage={setPage}
      pageCount={data?.meta?.total_pages ?? 1}
      loading={data?.loading}
      isLP
    />
  );
};

export default AdvanceSearchLabPrograms;
