import useWindowWidth from "helpers/hooks/useWindowWidth";
import React from "react";
import banner from "../../../assets/png/bg.png";

const Banner = ({ img, children, mobHeight, classes }) => {
  const width = useWindowWidth();
  const styles = {
    container: {
      height: width < 700 && mobHeight,
    },
  };

  return (
    <div
      data-testid="banner"
      style={styles.container}
      className={`banner-container relative pt-header ${classes}`}
    >
      <img
        src={img || banner}
        alt="banner"
        className="z-0"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = banner;
        }}
      />
      <div className="absolute top-0 w-full h-full z-10">{children}</div>
    </div>
  );
};

export default Banner;
