import PageTitleCard from "../../../../components/UI/molecules/PageTitleCard";
import useChallengeReportContext from "../hooks/useChallengeReportContext";
import useRedirect from "../../../../helpers/hooks/useRedirect";
import React, { useMemo, useState } from "react";
import { dateHasPassed, isTrue } from "../../../../helpers/utils/validators";
import { Grid } from "@mui/material";
import { ReactComponent as CalendarIcon } from "assets/svg/blue/calendar.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ReactComponent as LevelIcon } from "assets/svg/blue/level.svg";
import { ReactComponent as UsersIcon } from "assets/svg/blue/users.svg";
import { ReactComponent as SubmissionIcon } from "assets/svg/blue/submission.svg";
import { ReactComponent as SponsorIcon } from "assets/svg/blue/sponsor.svg";
import {
  Edit,
  Settings,
  Delete,
  ContentCopyOutlined,
  Extension,
  CheckCircle,
  Description,
  Announcement,
  Close,
} from "@mui/icons-material";

import { COMPONENT_TYPES, ROLES } from "helpers/utils/constants";
import MenuButton from "components/Button/MenuButton";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import useChallenges from "store/challenges/service-hook";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import useChallengeReport from "store/reports/challenges/service-hooks";
import useOrganization from "store/organization/service-hook";
import useAuth from "store/auth/service-hook";

const ChallengeReportDetails = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { challengeDetails, challengeSlug, addToTemplate } =
    useChallengeReportContext();

  const { deleteChallenge, clone } = useChallenges();
  const {
    navigateChallenge,
    navigateAnnouncements,
    navigateMemberManagement,
    navigateEditChallenge,
    navigateAssessChallenge,
  } = useRedirect();
  const navigate = useNavigate();
  const { downloadChallengeReport } = useChallengeReport();
  const [downloading, setDownloading] = useState(false);
  const { organizationId } = useOrganization();
  const {
    state: { user },
  } = useAuth();
  /**
   * COMPONENT STATE
   */
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [challengeToDelete, setChallengeToDelete] = useState(null);

  /**
   * METHODS
   */
  const openExitConfirmationModal = (slug) => {
    setChallengeToDelete(slug);
    setOpenConfirmationModal(true);
  };

  const cancelExitConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setChallengeToDelete(null);
  };
  const downloadReport = async () => {
    setDownloading(true);
    if (challengeSlug) await downloadChallengeReport(challengeSlug);
    setDownloading(false);
  };
  /**
   * COMPUTED PROPERTIES
   */
  const challengeStats = useMemo(() => {
    return [
      {
        icon: <CalendarIcon className={"w-40 h-40"} />,
        title: dateHasPassed(challengeDetails?.submission_date)
          ? t("Closed")
          : t("Open"),
        subtitle:
          challengeDetails?.flexible_date?.timeline_type === "restricted"
            ? (dateHasPassed(challengeDetails?.submission_date)
                ? t("At")
                : t("Until")
              ).concat(
                " ",
                challengeDetails?.submission_date.split("T", 1)[0] ?? ""
              )
            : `${t("For")} ${
                challengeDetails?.flexible_date?.flexible_date_number
              } ${
                challengeDetails?.flexible_date?.flexible_date_number > 1
                  ? challengeDetails?.flexible_date?.flexible_date_duration
                  : challengeDetails?.flexible_date?.flexible_date_duration.replace(
                      "s",
                      ""
                    )
              }`,
        tooltip: Date(challengeDetails?.submission_date).toString(),
      },
      {
        icon: <VisibilityIcon className={"w-40 h-40"} color="info" />,
        title: isTrue(challengeDetails?.privacy)
          ? t("Private Challenge")
          : t("Public Challenge"),
        subtitle: t("Free"),
      },
      (challengeDetails?.level || challengeDetails?.duration) && {
        icon: <LevelIcon className={"w-40 h-40"} />,
        title: challengeDetails?.level?.title,
        subtitle: challengeDetails?.duration?.title,
      },
      {
        icon: <UsersIcon className={"w-40 h-40"} />,
        title: t("Members Joined"),
        subtitle: challengeDetails?.members_count ?? 0,
      },
      {
        icon: <SubmissionIcon className={"w-40 h-40"} />,
        title: t("Project Submissions"),
        subtitle: challengeDetails?.submitted_projects ?? 0,
      },
      {
        icon: <SponsorIcon className={"w-40 h-40"} />,
        title: t("Sponsors"),
        subtitle: challengeDetails?.sponsors ?? 0,
      },
    ].filter(Boolean);
  }, [challengeDetails]);

  const handleCloneChallenge = (slug) => {
    if (!organizationId) return;
    clone(slug, organizationId);
  };

  return (
    <PageTitleCard noAction noContainer classes={"mx-8"}>
      <div>
        <Grid
          container
          spacing={2}
          className="ml-0 w-full"
          style={{ justifyContent: "space-between", alignItem: "center" }}
        >
          <Grid item xs={12} md={2}>
            <Button bordered color={"blue"} onClick={() => navigate(-1)}>
              {t("Back", "common")}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={"w-full"}>
              <h2 className={`fs-23 text-center`}>{t("Challenge Report")}</h2>
              <p
                className={
                  "c-primary bold text-center mt-2 fs-16 cursor-pointer"
                }
                onClick={() => navigateChallenge(challengeDetails?.slug)}
              >
                {challengeDetails?.title ?? ""}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <div className="flex" style={{ gap: "4px" }}>
              <MenuButton
                listWithTitleonClick={[
                  {
                    title: "Announcements",
                    icon: Announcement,
                    onClick: () => navigateAnnouncements(challengeSlug),
                    iconClasses: "c-primary",
                  },
                  {
                    title: "Project Submission",
                    icon: Description,
                    onClick: () => navigateChallenge(challengeSlug, false, 3),
                    iconClasses: "c-primary",
                  },
                  {
                    title: "Project Assessment",
                    icon: CheckCircle,
                    onClick: () => navigateAssessChallenge(challengeSlug),
                    iconClasses: "c-primary",
                  },
                  {
                    title: "Manage Members",
                    onClick: () =>
                      navigateMemberManagement(
                        COMPONENT_TYPES.CHALLENGE,
                        challengeSlug
                      ),
                    icon: Settings,
                    iconClasses: "c-primary",
                  },
                  {
                    divider: true,
                  },
                  {
                    title: "Edit",
                    onClick: () => navigateEditChallenge(challengeSlug),
                    icon: Edit,
                  },
                  {
                    title: "Duplicate",
                    onClick: () => handleCloneChallenge(challengeSlug),
                    icon: ContentCopyOutlined,
                  },

                  {
                    title: isTrue(challengeDetails?.is_pre_build)
                      ? "Remove from templates"
                      : "Add to templates",
                    onClick: () => addToTemplate(),
                    icon: isTrue(challengeDetails?.is_pre_build)
                      ? Close
                      : Extension,
                    iconClasses: isTrue(challengeDetails?.is_pre_build)
                      ? "c-error"
                      : "",
                    classes: isTrue(challengeDetails?.is_pre_build)
                      ? "c-error"
                      : "",
                    hide:
                      user?.roles !== ROLES.SUPER_ADMIN ||
                      user?.roles !== ROLES.CUSTOMER_SUCCESS ||
                      user?.roles !== ROLES.DEVELOPER ||
                      user?.roles !== ROLES.ORGANIZATION_OWNER,
                  },

                  {
                    title: "Delete",
                    onClick: () => openExitConfirmationModal(challengeSlug),
                    icon: Delete,
                    iconClasses: "c-error",
                    classes: "c-error",
                  },
                ]}
              >
                <Settings fontSize="small" /> {t("Manage", "common")}
              </MenuButton>
              <Button
                color={"blue"}
                loading={downloading}
                onClick={downloadReport}
              >
                {t("Export Report")}
              </Button>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          style={{ justifyContent: "space-between" }}
          className={"mt-4 mb-4 ml-0 w-full"}
        >
          {challengeStats?.map((item, index) => (
            <Grid item key={index} xs={12} md={2}>
              <div className="flex items-center" style={{ gap: "1em" }}>
                {item?.icon}
                <div>
                  <h4 className={"fs-14 bold"}>{item?.title}</h4>
                  <p className={"fs-14"} title={item?.tooltip}>
                    {item?.subtitle}
                  </p>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={cancelExitConfirmationModal}
        desc={t("Are you sure you want to delete this Challenge?")}
        okayButtonTitle={t("Delete Challenge")}
        onOkay={() => {
          deleteChallenge(challengeToDelete);
          cancelExitConfirmationModal();
        }}
      />
    </PageTitleCard>
  );
};

export default ChallengeReportDetails;
