import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { leaf4 } from "assets/svg/green";
import React from "react";
import Paginator from "../atoms/Paginator";
import cx from "classnames";
import Spin from "components/Spin";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import MainProductCard from "components/Loaders/MainProductCard";

export const NoDataView = ({ text, classes, small }) => {
  const { t } = useLocalizedTranslation("common");
  return (
    <Box
      className={cx(
        "flex flex-column items-center justify-center text-center mx-auto",
        small ? "minh-200" : "minh-400",
        classes
      )}
    >
      <img
        src={leaf4}
        height={small ? "120px" : "200px"}
        width="auto"
        alt="green leaf"
      />
      <p
        className={cx(
          small ? "mt-4" : "mt-6",
          " opacity-50 tracking-wide",
          small && "fs-14"
        )}
      >
        {t(text) || t("No data found!", "common")}
      </p>
    </Box>
  );
};

const ListingContainer = ({
  children,
  noPagination,
  loading,
  isEmpty,
  emptyText,
  page,
  setPage,
  count,
  classes,
  spinLoader,
  emptySmall,
  rowSpacing,
  rowLoader,
  small,
  columnSpacing,
  loader,
  emptyView,
}) => {
  const { t } = useLocalizedTranslation();
  return (
    <Grid
      container
      className={classes}
      columnSpacing={columnSpacing || 2}
      rowSpacing={rowSpacing}
    >
      {loading ? (
        loader ? (
          loader
        ) : spinLoader ? (
          <Box className="flex items-center justify-center w-full minh-400">
            <Spin big blue />
          </Box>
        ) : (
          <MainProductCard isRowLoader={rowLoader} small={small} />
        )
      ) : isEmpty ? (
        emptyView ? (
          emptyView
        ) : (
          <NoDataView text={t(emptyText)} small={Boolean(emptySmall)} />
        )
      ) : (
        <>
          {children}
          {!noPagination && (!count || count > 1) && (
            <Paginator page={page} setPage={setPage} count={count} />
          )}
        </>
      )}
    </Grid>
  );
};

export default ListingContainer;
