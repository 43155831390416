import { Box, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const ChipsPreview = ({ items = [], limit, maxWidth }) => {
  const { t } = useTranslation("common");

  return items.length > 0 ? (
    <Box>
      {items?.slice(0, limit)?.map((item, i) => (
        <Chip
          sx={{
            maxWidth: maxWidth || 100,
            ".MuiChip-label": {
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            },
          }}
          key={i}
          variant="filled"
          label={item?.title || item?.name || item}
          fontSize="small"
          className={`mr-1 mt-1 fs-12 h-24`}
        />
      ))}
      {items.length > limit && (
        <Chip
          variant="filled"
          label={`+${items.length - limit} ${t("more")}`}
          fontSize="small"
          className="mt-1 fs-12 h-24"
        />
      )}
    </Box>
  ) : null;
};

export default ChipsPreview;
