import React, { useState } from "react";
import OpenAI from "openai";
import styles from "./chatgpt.module.scss";
import { IconButton } from "@mui/material";
import { Close, Send } from "@mui/icons-material";
import cx from "classnames";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPEN_AI_APIKEY,
  dangerouslyAllowBrowser: true,
});

const ChatGptBox = () => {
  const [open, setOpen] = useState(false);
  const [chats, setChats] = useState([
    { role: "assistant", content: "Hey! How can I help you today?" },
  ]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChat = () => {
    const chat = { role: "user", content: message };
    chats.push(chat);
    setLoading(true);
    setMessage("");

    openai.chat.completions
      .create({
        model: "gpt-3.5-turbo",
        messages: [chat],
      })
      .then((result) => {
        chats.push(result.choices[0].message);
        setLoading(false);
        setChats([...chats]);
      });
  };

  return (
    <div className={cx(styles.chat, open ? styles.open : styles.close)}>
      <div
        className={cx(
          "px-2 py-3 flex items-center justify-between",
          styles.header
        )}
        onClick={() => !open && setOpen(true)}
      >
        <h3>Hey! I'm Chat GPT</h3>
        {open ? (
          <IconButton onClick={(e) => setOpen(false)}>
            <Close color="inherit" />
          </IconButton>
        ) : null}
      </div>
      <div className={styles.chats}>
        {chats.map(({ role, content }, index) => (
          <div
            key={index}
            className={cx(
              styles.message,
              "mt-2",
              role === "user" ? styles.right : styles.left
            )}
          >
            <p>{content}</p>
          </div>
        ))}
        {loading && (
          <div className={cx(styles.message, styles.left, "mt-3")}>
            <p className="py-1">
              <div className={styles.loading}>•••</div>
            </p>
          </div>
        )}
      </div>
      <div className={cx("flex items-center justify-between", styles.footer)}>
        <input
          className="ghost-input fs-18"
          placeholder="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              handleChat();
            }
          }}
        />
        <IconButton onClick={handleChat}>
          <Send color="primary" />
        </IconButton>
      </div>
    </div>
  );
};

export default ChatGptBox;
