import { Close } from '@mui/icons-material';
import { Modal } from '@mui/material';
import React from 'react';
import classes from './modal.module.scss';
import cx from 'classnames';

const ModalLayout = ({
	open,
	onClose,
	width,
	children,
	light,
	top,
	wrapperClasses,
	hideCloseIcon,
	crossIconClasses,
	isWide,
}) => {
	return (
		<Modal data-testid='modal' open={open} onClose={onClose} sx={{'& .modal_modal__GNHCf': isWide && { maxWidth: '90%'}}}>
			<div
				className={cx(classes.modal, wrapperClasses)}
				style={{
					width: !!width && width,
					background: !!light && '#F3F7FC',
					marginTop: !!top && top,
				}}
			>
				{!hideCloseIcon && (
					<Close
						color='disabled'
						onClick={onClose}
						className={`z-100 absolute cursor-pointer 
						top-1 right-1 ${crossIconClasses} 
						`}
					/>
				)}
				{children}
			</div>
		</Modal>
	);
};

export default ModalLayout;
