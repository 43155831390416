import {useEffect, useState} from "react";
import Datatable from "../../../components/Datatable";
import moment from "moment";
import useRedirect from "../../../../../helpers/hooks/useRedirect";
import {ReactComponent as RM} from "assets/svg/purple/resource.svg";
import useOrganizationReport from "store/reports/organization/service-hook";
import useOrganizationReportContext from "../../hooks/useOrganizationReportContext";
import EmptyText from "components/UI/atoms/EmptyText";

const ResourceModulesList = () => {
    /**
     * HOOKS
     */
    const {organizationResourceModules} = useOrganizationReport()
    const {organizationSlug} = useOrganizationReportContext()
    const {navigateResource} = useRedirect()

    /**
     * STATE
     */
    const [page, setPage] = useState(1)
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)

    /**
     *
     * @returns {Promise<void>}
     */
    const fetchData = async () => {
        setLoading(true)
        if (organizationSlug) {
            const response = await organizationResourceModules(organizationSlug, page, 10)
            if (response) {
                setData(response)
            }
        }
        setLoading(false)
    }

    /**
     * COMPONENT MOUNTED AND WATCHERS
     */
    useEffect(() => {
        fetchData()
    }, [page])

    return (
        <Datatable
            data={data?.data ?? []}
            loading={loading}
            page={data?.meta?.current_page ?? 1}
            perPage={data?.meta?.per_page ?? 15}
            totalPages={data?.meta?.total_pages ?? 1}
            totalCount={data?.meta?.total}
            onPageChange={(page) => setPage(page)}
            emptyText={<EmptyText>There are no Resource Modules associated to this Organization, create now with 'Create' button from navigation bar</EmptyText>}
            columns={[
                {
                    title: 'Component',
                    dataIndex: 'id',
                    render: () => {
                        return <><RM className={'w-20 h-20'}/></>
                    }
                },
                {
                    title: 'Title',
                    dataIndex: 'title',
                    render(item, _, row) {
                        return <p
                            className={'c-primary cursor-pointer text-nowrap overflow-hidden textOverflow-ellipsis maxw-75p'}
                            onClick={() => navigateResource(row?.slug)}>{item}</p>
                    }
                },
                {
                    title: 'Last updated',
                    dataIndex: 'updated_at',
                    render(item) {
                        return <>{item ? moment(item).fromNow() : '-'}</>
                    }
                }
            ]}
        />
    )
}

export default ResourceModulesList
