import React, { useEffect, useMemo, useState } from "react";
import useChallengeReportContext from "../hooks/useChallengeReportContext";
import useChallengeReport from "../../../../store/reports/challenges/service-hooks";
import Card from "../../../../components/Cards/Card";
import { ChartColors } from "../../data";
import { Grid } from "@mui/material";
import DoughnutChartCard from "../../components/chart/DoughnutChartCard";
import { formattedCount } from "../../../../helpers/utils/utilities";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EmptyText from "components/UI/atoms/EmptyText";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";

const ChallengeAssociatedProjects = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { challengeSlug } = useChallengeReportContext();
  const { challengeAssociatedProjectStats } = useChallengeReport();
  const { navigateMemberManagement } = useRedirect();

  /**
   * STATE
   */
  const [chartData, setChartData] = useState();
  const [loadingChartData, setLoadingChartData] = useState();

  const fetchChartData = async () => {
    setLoadingChartData(true);
    if (challengeSlug) {
      const response = await challengeAssociatedProjectStats(challengeSlug);
      if (response) {
        setChartData(response);
      }
    }
    setLoadingChartData(false);
  };

  /**
   * MEMORIZED
   */
  const formattedChartData = useMemo(() => {
    if (chartData) {
      const completionStatusLabel = [
        "Submitted",
        "In progress",
        "Late Submission",
        "Deadline Missed",
      ].map((d) => t(d));
      const statusStatsData = [
        chartData?.submitted_status?.submitted ?? 0,
        chartData?.submitted_status?.in_progress ?? 0,
        chartData?.submitted_status?.late_submission ?? 0,
        chartData?.submitted_status?.deadline_missed ?? 0,
      ];

      const assessmentStatsData = [
        chartData?.project_asssess_status?.assessed ?? 0,
        chartData?.project_asssess_status?.need_to_assess ?? 0,
      ];

      const assessmentLabel = ["Assessed", "Need to Assess"].map((d) => t(d));
      return {
        status: {
          labels: completionStatusLabel,
          datasets: [
            {
              label: t("Project Status"),
              data: statusStatsData,
              backgroundColor: ChartColors.challenge_associated_project_status,
            },
          ],
        },
        assessment: {
          labels: assessmentLabel,
          datasets: [
            {
              label: t("Project assessment"),
              data: assessmentStatsData,
              backgroundColor:
                ChartColors.challenge_associated_project_assessment,
            },
          ],
        },
      };
    }
    return null;
  }, [chartData]);

  const legendData = useMemo(() => {
    return {
      data_status: [
        {
          color: ChartColors.challenge_associated_project_status[0],
          title: t("Submitted"),
          count: chartData?.submitted_status?.submitted ?? 0,
        },
        {
          color: ChartColors.challenge_associated_project_status[1],
          title: t("In progress"),
          count: chartData?.submitted_status?.in_progress ?? 0,
        },
        {
          color: ChartColors.challenge_associated_project_status[2],
          title: t("Late Submission"),
          count: chartData?.submitted_status?.late_submission ?? 0,
        },
        {
          color: ChartColors.challenge_associated_project_status[3],
          title: t("Deadline Missed"),
          count: chartData?.submitted_status?.deadline_missed ?? 0,
        },
        {
          title: t("Total"),
          renderer: (data) => (
            <p style={{ fontWeight: "bold" }} className={"mt-1"}>
              {data}
            </p>
          ),
          count: chartData?.submitted_status?.total ?? 0,
        },
      ],
      data_assessment: [
        {
          color: ChartColors.challenge_associated_project_assessment[0],
          title: t("Assessed"),
          count: chartData?.project_asssess_status?.assessed ?? 0,
        },
        {
          color: ChartColors.challenge_associated_project_assessment[1],
          title: t("Need to Assess"),
          count: chartData?.project_asssess_status?.need_to_assess ?? 0,
        },
        {
          title: t("Total"),
          renderer: (data) => (
            <p style={{ fontWeight: "bold" }} className={"mt-1"}>
              {data}
            </p>
          ),
          count: chartData?.project_asssess_status?.total ?? 0,
        },
      ],
      total_status: chartData?.submitted_status?.total ?? 0,
      total_assessment: chartData?.project_asssess_status?.total ?? 0,
    };
  }, [chartData]);

  useEffect(() => {
    fetchChartData();
  }, [challengeSlug]);

  return (
    <>
      <Card title="Associated Projects" classes={"mt-2"}>
        <div className="flex mb-4 items-center justify-between">
          <h4 className="bold fs-16">{t("Associated Projects")}</h4>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DoughnutChartCard
              noCard
              chartId={"project-association-status-progress"}
              noDataUI={
                <EmptyText
                  buttonLabel={t("Manage Members")}
                  text={t(
                    "There is no project created for this Challenge, invite members and encourage them to start the challenge"
                  )}
                  icon={ManageAccountsIcon}
                  isButtonPresent
                  onClick={() =>
                    navigateMemberManagement(
                      COMPONENT_TYPES.CHALLENGE,
                      challengeSlug
                    )
                  }
                />
              }
              data={
                formattedChartData?.status && legendData?.total_status > 0
                  ? formattedChartData?.status
                  : null
              }
              isLoading={loadingChartData}
              legend={
                <>
                  <ul
                    className={"flex flex-column w-full fs-12 p-0"}
                    style={{ gap: "5px" }}
                  >
                    {legendData?.data_status?.map((item) => (
                      <li
                        key={item?.title}
                        className={"flex w-full items-center"}
                      >
                        <div className={"flex items-center"}>
                          <div
                            className={"w-15 h-15"}
                            style={{ backgroundColor: item?.color ?? "" }}
                          ></div>
                          <span className={"ml-2"}>
                            {item?.renderer
                              ? item?.renderer(item?.title)
                              : item?.title}
                          </span>
                        </div>
                        <span className={"ml-auto mr-2"}>
                          {formattedCount(item?.count)}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <DoughnutChartCard
              noCard
              noDataUI={""}
              chartId={"project-association-assessment-progress"}
              data={
                formattedChartData?.assessment &&
                legendData?.total_assessment > 0
                  ? formattedChartData?.assessment
                  : null
              }
              isLoading={loadingChartData}
              legend={
                <>
                  <ul
                    className={"flex flex-column w-full fs-12 p-0"}
                    style={{ gap: "5px" }}
                  >
                    {legendData?.data_assessment?.map((item) => (
                      <li
                        key={item?.title}
                        className={"flex w-full items-center"}
                      >
                        <div className={"flex items-center"}>
                          <div
                            className={"w-15 h-15"}
                            style={{ backgroundColor: item?.color ?? "" }}
                          ></div>
                          <span className={"ml-2"}>
                            {item?.renderer
                              ? item?.renderer(item?.title)
                              : item?.title}
                          </span>
                        </div>
                        <span className={"ml-auto mr-2"}>
                          {formattedCount(item?.count)}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              }
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ChallengeAssociatedProjects;
