import React, { useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import PageTitle from "components/UI/atoms/PageTitle";
import Card from "components/Cards/Card";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import { stepSchema } from "./data";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import MasterSelect from "components/UI/organisms/MasterSelect";
import Button from "components/Button";
import Input from "components/Input";
import { AddBox, Delete } from "@mui/icons-material";
import Select from "components/Input/Select";
import { EMBED_MEDIA_TYPES } from "helpers/utils/constants";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import Background from "assets/png/resource_module.png";
import ListingContainer from "components/UI/organisms/ListingContainer";
import Document from "components/media/Document";
import { VideoGrid } from "components/UI/organisms/VideoList";
import UploadScorm from "components/UI/molecules/UploadScorm";

const UploadResources = ({
  data,
  errors,
  onChange,
  step,
  embedMedia,
  setEmbedMedia,
  links,
  setLinks,
  editMode,
  onDelete,
  slug,
  scormDetails,
  deleteScormFile,
  resources,
  setScormLoading
}) => {
  const { t } = useLocalizedTranslation();
  const { files = [], audios = [], videos = [], documents = [] } = resources;
  const addEmbedMedia = () =>
    setEmbedMedia((val) => [...val, { type: null, path: "" }]);
  const addLink = () =>
    setLinks((val) => [...val, { social_link_id: "", title: "", path: "" }]);

  const onChangeEmbedMedia = (e, index) => {
    const { name, value } = e.target;
    embedMedia[index][name] = value;
    setEmbedMedia([...embedMedia]);
  };
  const onChangeLinks = (e, index) => {
    const { name, value } = e.target;
    const keys = {
      socialLinks: "social_link_id",
      title: "title",
      path: "path",
    };
    links[index][keys[name]] = value;
    setLinks([...links]);
  };


  return (
    <Grid container px={{ xs: 0, md: 4 }}>
      <PageTitle>{t("Upload Resources")}</PageTitle>
      <Grid container spacing={3} mt={1}>
        <Grid item md={6} xs={12}>
          <Card classes="px-9 pb-4">
            <SchemaUIExtractor
              schema={stepSchema(step).slice(0, 1)}
              data={data}
              errors={errors}
              onChange={onChange}
            />
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card classes="px-9">
            <UploadScorm 
              data={data} 
              errors={errors} 
              onChange={onChange} 
              schema={stepSchema(step).slice(1, 2)} 
              setScormLoading={setScormLoading} 
            />
          </Card>
        </Grid>
      </Grid>
      {!!editMode && scormDetails && (
        <CreateFormSection title="Uploaded Scorm File">
          <p className="c-gray fw-400 fs-14 mb-1">
            {t("Uploaded scorm file may be removed in this section.")}
          </p>
          <Box
            className="resource-course-card mt-4 w-100p mx-auto"
            sx={{
              background: `url(${Background})`,
            }}
          >
            <Box
              className="py-5 resource-course-card--overlay flex-center justify-center relative"
              sx={{
                "& .delete-icon": { visibility: "hidden" },
                "&:hover .delete-icon": { visibility: "visible" },
              }}
            >
              <h2 className="resource-course-card--overlay--title">
                {scormDetails?.title}
              </h2>
              <Box className="delete-icon flex justify-center absolute top-14">
                <IconButton onClick={deleteScormFile}>
                  <Delete className="c-error" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </CreateFormSection>
      )}
      {!!editMode && (files?.length > 0 || documents?.length > 0) && (
        <CreateFormSection title="Uploaded Files">
          <p className="c-gray fw-400 fs-14 mb-1">
            {t("Uploaded files may be removed in this section.")}
          </p>
          <ListingContainer rowSpacing={2} noPagination>
            {[
              ...(Array.isArray(files) ? files.map((file) => ({ ...file, type: "files" })) : []),
              ...(Array.isArray(documents) ? documents.map((doc) => ({ ...doc, type: "documents" })) : [])
            ].map((d) => (
              <Grid
                item
                xs={6}
                md={4}
                lg={3}
                key={d.id}  // Use unique 'id' instead of the index
                display="flex"
                justifyContent="center"
              >
                <Document
                  onDelete={() => onDelete(d?.id, d?.type)}
                  title={d?.title}
                  path={d?.path}
                />
              </Grid>
            ))}
          </ListingContainer>
        </CreateFormSection>
      )}

      {!!editMode && audios?.length > 0 && (
        <CreateFormSection title="Uploaded Audios">
          <p className="c-gray fw-400 fs-14 mb-1">
            {t("Uploaded audios may be removed in this section.")}
          </p>
          <ListingContainer rowSpacing={2} noPagination>
            {audios?.map((d, i) => (
              <Grid
                item
                xs={6}
                md={4}
                lg={3}
                key={i}
                display="flex"
                justifyContent="center"
              >
                <Document
                  onDelete={() => onDelete(d?.id, "audios")}
                  title={d?.title}
                  path={d?.path}
                />
              </Grid>
            ))}
          </ListingContainer>
        </CreateFormSection>
      )}

      {!!editMode && videos?.length > 0 && (
        <CreateFormSection title="Uploaded Videos">
          <p className="c-gray fw-400 fs-14 mb-1">
            {t("Uploaded videos may be removed in this section.")}
          </p>
          <VideoGrid videos={videos} onDelete={onDelete} />
        </CreateFormSection>
      )}

      <CreateFormSection title="Embed Media">
        <p className="c-gray fw-400 fs-14">
          {t("Please embed video, audio embedded code below")}
        </p>
        {embedMedia &&
          embedMedia?.map((mediaItem, index) => (
            <EmbededMedia
              key={index}
              mediaItem={mediaItem}
              index={index}
              onChangeEmbedMedia={onChangeEmbedMedia}
              setEmbedMedia={setEmbedMedia}
              slug={slug}
              onDelete={onDelete}
              t={t}
            />
          ))}
        <div className="flex justify-center w-full">
          <Button bordered classes="mt-4 mb-3" onClick={addEmbedMedia}>
            <AddBox className="mr-1" /> {t("Add")}
          </Button>
        </div>
      </CreateFormSection>
      <CreateFormSection title="Add Links">
        <p className="c-gray fw-400 fs-14">
          {" "}
          {t("You may add additional URLs or links to your SCORM package.")}
        </p>
        {links &&
          links?.map((socialLink, index) => (
            <Links
              key={index}
              socialLink={socialLink}
              onChangeLinks={onChangeLinks}
              setLinks={setLinks}
              slug={slug}
              onDelete={onDelete}
              index={index}
              t={t}
            />
          ))}
        <div className="flex justify-center w-full">
          <Button bordered classes="mt-4 mb-3" onClick={addLink}>
            <AddBox className="mr-1" /> {t("Add")}
          </Button>
        </div>
      </CreateFormSection>
    </Grid>
  );
};
export default UploadResources;

export const EmbededMedia = ({
  mediaItem,
  index,
  onChangeEmbedMedia,
  onDelete,
  setEmbedMedia,
  t = (d) => d,
}) => {
  const [loading, setLoading] = useState(false);
  const { type, path, id } = mediaItem;

  const deleteEmbedMedia = async () => {
    setLoading(true);
    await onDelete(id, type || "embedded", index);
    setLoading(false);
  };

  return (
    <Grid container spacing={2} mt={0.5} alignItems="flex-end">
      <Grid item xs={12} lg={5}>
        <Select
          name="type"
          title="Media Type"
          borderedIcon
          classes="w-full"
          width="99%"
          value={type}
          onChange={(e) => onChangeEmbedMedia(e, index)}
          keyValues={EMBED_MEDIA_TYPES}
          disabled={!!loading}
          t={t}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Input
          title="Embedded Code"
          classes="w-full"
          width="99%"
          name="path"
          value={path}
          onChange={(e) => onChangeEmbedMedia(e, index)}
          disabled={!!loading}
          t={t}
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <Button
          loading={loading}
          color="red"
          width="60px"
          height="40px"
          bordered
          onClick={deleteEmbedMedia}
        >
          <Delete />
        </Button>
      </Grid>
    </Grid>
  );
};
export const Links = ({
  socialLink,
  onChangeLinks,
  onDelete,
  index,
  t = (d) => d,
}) => {
  const [loading, setLoading] = useState(false);
  const { id, social_link_id, title, path } = socialLink;

  const deleteLink = async () => {
    setLoading(true);
    await onDelete(id, "links", index);
    setLoading(false);
  };
  return (
    <Grid key={index} container spacing={2} mt={0.5} alignItems="flex-end">
      <Grid item xs={12} lg={3}>
        <MasterSelect
          name="socialLinks"
          title="Link Type"
          borderedIcon
          classes="w-full"
          width="99%"
          value={social_link_id}
          disabled={loading}
          onChange={(e) => onChangeLinks(e, index)}
          t={t}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Input
          title="Link Title"
          classes="w-full"
          width="99%"
          name="title"
          value={title}
          disabled={loading}
          onChange={(e) => onChangeLinks(e, index)}
          t={t}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Input
          title="URL"
          classes="w-full"
          width="99%"
          name="path"
          value={path}
          disabled={loading}
          onChange={(e) => onChangeLinks(e, index)}
          t={t}
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <Button
          loading={loading}
          color="red"
          width="60px"
          height="40px"
          bordered
          onClick={deleteLink}
        >
          <Delete />
        </Button>
      </Grid>
    </Grid>
  );
};
