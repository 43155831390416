import React from "react";
import useAdvanceSearchFilter from "../../../hooks/useAdvanceSearchFilter";
import { AS_REDUCER } from "../../../../../store/advance-search/reducer";
import useAdvanceSearch from "../../../../../store/advance-search/service-hook";
import { useAdvanceSearchContext } from "../../../hooks/useAdvanceSearchContext";
import QuickSearchListing from "../../QuickSearch";

const QuickSearchUser = ({ t = (d) => d }) => {
  const { quickKeyword } = useAdvanceSearchContext();
  const { searchUsers } = useAdvanceSearch();
  const { data } = useAdvanceSearchFilter(
    "users",
    quickKeyword,
    null,
    searchUsers,
    AS_REDUCER.setUsers,
    true
  );

  return (
    <QuickSearchListing
      data={data?.data?.slice(0, 3) ?? []}
      componentName={"Users"}
      component={"users"}
      loading={data?.loading}
      titleKey={["full_name"]}
      t={t}
    />
  );
};

export default QuickSearchUser;
