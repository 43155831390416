import React from "react";
import Routes from "./Routes";
import "helpers/styles/overrides.scss";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "store";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { Helmet } from "react-helmet";
import NewRelic from "new-relic-agent-react";
import "./components/Cards/cards.scss";
import "./components/Input/input.module.scss";
import "./i18n";
import AdvanceSearchContextWrapper from "./pages/advanceSearch/container/AdvanceSearchContext";
import HotjarComponent from "./pages/hotjar";

function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <Provider store={store}>
        <AdvanceSearchContextWrapper>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <NewRelic
              licenseKey={process.env.REACT_APP_NEWRELIC_LICENCE_KEY}
              applicationID={process.env.REACT_APP_NEWRELIC_APPLICATION_ID}
            />
          </Helmet>
          <div className="app">
            <Routes />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            {/* <ChatGptBox /> */}
            <HotjarComponent />
          </div>
        </AdvanceSearchContextWrapper>
      </Provider>
    </Auth0Provider>
  );
}

export default App;
