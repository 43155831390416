import { AddBox, Delete } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import CreateFormSection from "../../../components/UI/atoms/CreateFormSection";
import SchemaUIExtractor from "../../../components/UI/organisms/SchemaUIExtractor";
import PageTitle from "../../../components/UI/atoms/PageTitle";
import { stepSchema } from "./data";
import PitchNTaskListing from "../../../components/UI/molecules/PitchNTaskListing";
import { useLocalizedTranslation } from "../../../helpers/hooks/useLocalizedTranslation";
import { toast } from "react-toastify";

const PitchAndAssesment = ({
  data,
  errors,
  onChange,
  step,
  criteria,
  setCriteria,
  criteriaError,
  setCriteriaError,
  isChallengeSubmitted,
}) => {
  const { t } = useLocalizedTranslation();

  const addCriteria = () => {
    setCriteria((val) => [...val, {}]);
  };

  const onChangeCriteria = (e, index) => {
    const { name, value } = e.target;
    criteria[index][name] = value;
    setCriteria([...criteria]);
    setCriteriaError(null);
  };

  const deleteCriteria = (index) => {
    criteria.splice(index, 1);
    setCriteria([...criteria]);
  };

  return (
    <Grid container px={{ xs: 0, md: 4 }}>
      <PageTitle>{t("Pitch & Assessment")}</PageTitle>
      <CreateFormSection title="Project Pitch Template" classes="w-full">
        <p>
          {t("Select a default pitch template for project submissions.")}
          <br />
          {t(
            "Participants will follow this template when pitching their projects."
          )}
        </p>
        <SchemaUIExtractor
          schema={stepSchema(step).slice(0, 1)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
        <PitchNTaskListing templateId={data?.template_id?.key} />
      </CreateFormSection>
      <CreateFormSection title="Assessment Type">
        <p>{t("Choose how project submissions will be assessed.")}</p>
        <SchemaUIExtractor
          schema={stepSchema(step).slice(1, 2)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
        {data.assessment_type === "closed" && (
          <SchemaUIExtractor
            schema={stepSchema(step).slice(2, 3)}
            data={data}
            errors={errors}
            onChange={onChange}
          />
        )}
        {data.assessment_type !== "none" && (
          <>
            <SchemaUIExtractor
              schema={stepSchema(step).slice(3, 4)}
              data={data}
              errors={errors}
              onChange={onChange}
            />
            <h3 className="mt-4">{t("Assessment Guidelines")}</h3>
            <p>
              {t(
                "Write or attach a file to help evaluators understand assessment criteria."
              )}
            </p>
            <SchemaUIExtractor
              schema={stepSchema(step).slice(4, 6)}
              data={data}
              errors={errors}
              onChange={onChange}
            />
          </>
        )}
      </CreateFormSection>
      {data.assessment_type !== "none" && (
        <CreateFormSection title="Criteria">
          <p>{t("Criteria Weight must total 100%")}</p>
          {criteria &&
            criteria.map((d, i) => (
              <CriteriaRow
                key={i}
                criteria={d}
                onChange={onChangeCriteria}
                i={i}
                onDelete={deleteCriteria}
                isChallengeSubmitted={isChallengeSubmitted}
              />
            ))}
          <div className="column-center mt-2">
            <Button onClick={addCriteria} bordered>
              <AddBox /> {t("Add Criteria")}
            </Button>
          </div>
          <p className="c-error mt-2 fs-14">{t(criteriaError)}</p>
        </CreateFormSection>
      )}
    </Grid>
  );
};

export default PitchAndAssesment;

export const handleCriteriaRowError = (criteria) => {
  if (criteria.length === 0) {
    toast.error("Add at least one assessment criteria row.");
    return false;
  }

  let totalWeight = 0;
  for (let i = 0; i < criteria.length; i++) {
    const { title, score, weight } = criteria[i];
    if (!title || !score || !weight) {
      toast.error("All fields (title, max score, weight) are mandatory.");
      return false;
    }
    if (isNaN(score) || isNaN(weight)) {
      toast.error("Max score and weight must be numeric.");
      return false;
    }
    totalWeight += parseFloat(weight);
  }

  if (totalWeight !== 100) {
    toast.error("Total weight of all criteria rows must be 100%.");
    return false;
  }

  return true;
};

export const CriteriaRow = ({
  i,
  criteria,
  onChange,
  onDelete,
  desc = false,
  isChallengeSubmitted,
  t = (d) => d,
}) => {
  return (
    <Grid container spacing={2} mt={1} mb={2} alignItems="center">
      <Grid item xs={12} md={desc ? 3 : 4}>
        <Input
          title={`${t("Criteria")} #${i + 1}`}
          required
          classes="w-full"
          width="99%"
          name="title"
          value={criteria.title}
          onChange={(e) => onChange(e, i)}
          disabled={isChallengeSubmitted}
          t={t}
        />
      </Grid>
      {desc && (
        <Grid item xs={12} md={3}>
          <Input
            title="Description"
            required
            name="description"
            classes="w-full"
            value={criteria.description}
            disabled={isChallengeSubmitted}
            onChange={(e) => onChange(e, i)}
            width="99%"
            t={t}
          />
        </Grid>
      )}
      <Grid item xs={12} md={desc ? 3 : 4}>
        <Input
          title="Max Score"
          required
          name="score"
          classes="w-full"
          value={criteria.score}
          onChange={(e) => onChange(e, i)}
          width="99%"
          disabled={isChallengeSubmitted}
          t={t}
        />
      </Grid>
      <Grid item xs={12} md={desc ? 2 : 3}>
        <Input
          title="Weight (percentage)"
          required
          name="weight"
          classes="w-full"
          value={criteria.weight}
          onChange={(e) => onChange(e, i)}
          width="99%"
          disabled={isChallengeSubmitted}
          t={t}
        />
      </Grid>

      <Grid item xs={12} md={1}>
        <Delete
          className="c-error cursor-pointer"
          onClick={() => onDelete(i)}
        />
      </Grid>
    </Grid>
  );
};
