import PasswordValidator from "components/PasswordValidator";
import React from "react";
import PassowordInput from "../atoms/PassowordInput";

const PasswordGroup = ({
  onChange,
  data = {},
  errors,
  classes = "",
  confirmPasswordTitle = "Confirm Password",
  hideAsterisk
}) => {
  return (
    <div className={`w-full ${classes}`}>
      <PassowordInput
        required
        title="Password"
        name="password"
        classes="w-full"
        key="password"
        error={errors.get("password")}
        value={data.password}
        onChange={onChange}
        hideAsterisk={hideAsterisk}
      />
      <PasswordValidator password={data.password} />
      <PassowordInput
        required
        title={confirmPasswordTitle}
        name="password_confirmation"
        classes="w-full"
        key="password_confirmation"
        placeholder="Confirm password"
        error={errors.get("password_confirmation")}
        value={data?.password_confirmation}
        onChange={onChange}
        hideAsterisk={hideAsterisk}
      />
    </div>
  );
};

export default PasswordGroup;
