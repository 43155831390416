import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const ReadMore = ({ limit = 5, text = "N/A", classes = "" }) => {
  const [show, setShow] = useState(false);
  const [fullView, setFullView] = useState(false);
  const [lineHeight, setLineHeight] = useState(15);
  const { t } = useLocalizedTranslation("common");

  useEffect(() => {
    const element = document.getElementById("read-more-text");
    const lineHeight = parseInt(window.getComputedStyle(element).lineHeight);
    const height = element.clientHeight;
    setLineHeight(lineHeight);
    setShow(Math.round(height / lineHeight) >= limit - 1);
  }, []);

  return (
    <>
      <Typography
        sx={{
          maxHeight: fullView ? "fit-content" : (limit + 1) * lineHeight,
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitLineClamp: fullView ? "unset" : limit,
          WebkitBoxOrient: "vertical",
          display: "-webkit-box",
        }}
        className={cx("fs-13", classes)}
        id="read-more-text"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      {show && (
        <p
          className="link text-right fs-12 mt-1"
          onClick={() => setFullView(!fullView)}
        >
          {t(fullView ? "READ LESS" : "READ MORE", "common")}
        </p>
      )}
    </>
  );
};

export default ReadMore;
