import { Add, Done, Edit, ManageAccounts,DeleteForever, Settings } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Button from "components/Button";
import MainProductCard from "components/Cards/MainProductCard";
import ListingContainer from "components/UI/organisms/ListingContainer";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import React from "react";
import GroupIcon from "@mui/icons-material/Group";
import EventNoteIcon from "@mui/icons-material/EventNote";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import UpdateIcon from "@mui/icons-material/Update";
import { isTrue } from "helpers/utils/validators";
import moment from "moment";
import ChipsPreview from "components/UI/atoms/ChipsPreview";
import useRedirect from "helpers/hooks/useRedirect";
import MenuButton from "components/Button/MenuButton";
import useProjects from "store/projects/service-hook";

const TeamsListing = ({
  gridType,
  loading,
  list,
  total,
  page,
  setPage,
  onSendRequest,
  browseMode,
  setList = Function.prototype,
  t = (d) => d,
}) => {
  const { navigateChallenge, navigateMemberManagement, navigateEditProject } =
    useRedirect();
  const { deleteProject } = useProjects();
  const handleDelete = async (slug) => {
    setList((prevList) => prevList.filter((d) => d?.slug !== slug));
    await deleteProject(slug);
  };

  return (
    <ListingContainer
      classes="mt-4"
      page={page}
      count={total}
      isEmpty={!list?.length}
      loading={loading}
      setPage={setPage}
    >
      {list?.map((d, i) => {
        const {
          title,
          description,
          privacy,
          media,
          slug,
          challenge_title,
          skills,
          request_send,
          challenge_details,
          is_joined,
        } = d;
        return (
          <Grid item key={i} xs={12} sm={6} lg={gridType === 1 ? 12 : 6}>
            <MainProductCard
              classes="mb-3"
              title={
                <p className="text-nowrap overflow-hidden maxw-400 textOverflow-ellipsis">
                  {title}
                </p>
              }
              subTitle={
                <div className="flex-center">
                  <div className="text-nowrap overflow-hidden maxw-480 textOverflow-ellipsis">
                    {t(privacy, "common")} {t("Project", "common")}{" "}
                    <span className="vertical-align-middle">&middot;</span>{" "}
                    {t("Challenge", "common")}:
                    <span
                      className="ml-1 link"
                      onClick={() =>
                        challenge_details?.slug &&
                        navigateChallenge(challenge_details?.slug)
                      }
                    >
                      {challenge_title || "N/A"}
                    </span>
                  </div>
                </div>
              }
              description={description}
              type={COMPONENT_TYPES.PROJECT}
              image={media}
              mediaType={d?.media_type}
              id={slug}
              col={gridType !== 1}
              iconsAndValuesList={[
                {
                  icon: EventNoteIcon,
                  value: challenge_details?.duration || "N/A",
                },
                {
                  icon: SignalCellularAltIcon,
                  value: challenge_details?.level || "N/A",
                },
                {
                  icon: UpdateIcon,
                  value: challenge_details?.due_date
                    ? `${t("Due on")} ${moment(challenge_details?.due_date).format(
                        "MMMM DD YYYY"
                      )}`
                    : "N/A",
                },

                {
                  icon: GroupIcon,
                  value: `${d?.member_count || 0} ${t("Members")}`,
                },
              ]}
              rightAction={
                browseMode === 2 && isTrue(is_joined) ? (
                  (d?.access_level === "team_leader" ||
                    d?.access_level === "editor") && (
                    <MenuButton
                      disabled={
                        challenge_details?.challenge_status === "closed"
                      }
                      gray={challenge_details?.challenge_status === "closed"}
                      bordered={
                        challenge_details?.challenge_status === "closed"
                      }
                      listWithTitleonClick={[
                        {
                          title: "Manage Members",
                          onClick: () =>
                            navigateMemberManagement(
                              COMPONENT_TYPES.PROJECT,
                              d?.slug
                            ),
                          icon: ManageAccounts,
                          iconClasses: "c-primary",
                          classes: "bg-gray",
                        },
                        {
                          divider: true,
                        },
                        {
                          title: "Edit",
                          onClick: () => navigateEditProject(d?.slug),
                          icon: Edit,
                        },
                        {
                          title: "Delete",
                          onClick: () => handleDelete(d?.slug),
                          icon: DeleteForever,
                          iconClasses: "c-error",
                          classes: "c-error",
                        },
                      ]}
                      classes="mr-1 fs-13"
                      height={30}
                      width={110}
                    >
                      <Settings className="mr-1" fontSize="small" />{" "}
                      {t("Manage", "common")}
                    </MenuButton>
                  )
                ) : request_send === "available" ? (
                  <Button
                    height={30}
                    bordered
                    classes="fs-13 mr-1 minw-170"
                    onClick={() => onSendRequest(d?.slug)}
                  >
                    <Add fontSize="small" />
                    {t("Request to join")}
                  </Button>
                ) : request_send === "joined" ? (
                  <Button
                    height={30}
                    bordered
                    color="green"
                    classes="fs-13 mr-1"
                  >
                    <Done className="mr-1" fontSize="small" /> {t("Joined")}
                  </Button>
                ) : (
                  <Button
                    height={30}
                    bordered
                    color="green"
                    classes="fs-13 mr-1 minw-150"
                  >
                    <Done className="mr-1" fontSize="small" /> {t("Request Sent")}
                  </Button>
                )
              }
              noSave={true}
              bottomContent={
                <div className="mt-2 text-truncate">
                  <ChipsPreview
                    items={Object.values(skills)}
                    limit={gridType === 2 ? 2 : 3}
                    maxWidth={130}
                  />
                </div>
              }
            />
          </Grid>
        );
      })}
    </ListingContainer>
  );
};

export default TeamsListing;
