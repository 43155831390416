import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import ChatsMainContainer from "./components/ChatsMainContainer";
import { useParams } from "react-router-dom";
import useChallenges from "store/challenges/service-hook";
import useChats from "store/chat/service-hook";
import useAuth from "store/auth/service-hook";
import { payloadBool } from "helpers/utils/utilities";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

let _socket;

const ChatsSection = ({ isAnnouncements }) => {
  const [title, setTitle] = useState();
  const [loading, setLoading] = useState(false);
  const [announcements, setAnnouncements] = useState();
  const [announcementsCounts, setAnnouncementCounts] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, pages: 1, count: 0 });
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState(0);
  const [chats, setChats] = useState();
  const { id } = useParams();
  const { fetchAnnouncements } = useChallenges();
  const { list, create, setOnline, setOffline, socket } = useChats();
  const [inboxCount, setInboxCount] = useState(0);
  const [archiveCount, setArchiveCount] = useState(0);
  const [booleanUpdateCurrent, setBooleanUpdateCurrent] = useState(false);
  const { t } = useLocalizedTranslation();
  const {
    state: { user },
  } = useAuth();

  const updateChats = (conversation, shouldAdd) => {
    setChats((prev) => {
      const exists = prev?.some((d) => d?.id === conversation?.uuid);
      if (shouldAdd) {
        return exists
          ? prev
          : [
              {
                ...conversation,
                id: conversation?.uuid,
                _id: conversation?.id,
              },
              ...prev,
            ];
      } else {
        return prev?.filter((m) => m?.id !== conversation?.uuid);
      }
    });
  };

  useEffect(() => {
    if (!user?.id) return;
    _socket = socket();
    _socket.private(`users.${user?.id}`).notification((data) => {
      const { type, conversation } = data;
      let args = [conversation];
      switch (type) {
        case "conversation.created":
          args = [...args, true];
          break;
        case "conversation.unarchived":
          args = [...args, tab === 0];
          break;
        case "conversation.archived":
          args = [...args, tab === 1];
          break;
        case "conversation.deleted":
          args = [...args, false];
          break;
        default:
          break;
      }
      updateChats(...args);
    });
    _socket
      .join("chat")
      .here((users) => users)
      .joining((user) => {
        updateOnlineStatus(user?.username, true);
      })
      .leaving((user) => {
        updateOnlineStatus(user?.username, false);
      });

    return () => _socket.disconnect();
  }, [user?._id]);

  useEffect(() => {
    setOnline();
    return () => setOffline();
  }, []);

  useEffect(() => {
    if (!id && isAnnouncements) return;
    handleFetch();
  }, [id, pagination.page, search, tab]);
  useEffect(() => {
    if (!id || isAnnouncements || search) return;
    const intervalId = setInterval(() => {
      handleFetch(true);
    }, 15000);
    return () => clearInterval(intervalId);
  }, [search]);

  const handleFetch = async (notLoading = false) => {
    if (!notLoading) setLoading(true);
    const filters = {
      page: pagination.page,
      search: search,
    };
    if (isAnnouncements) {
      filters["type"] = {
        0: "sent",
        1: "draft",
        2: "scheduled",
      }[tab];
      const {
        title,
        slug,
        challenge_announcement = [],
        draft_count = 0,
        scheduled_count = 0,
        total_pages = 1,
        sent_count = 0,
      } = await fetchAnnouncements(id, filters);
      if (slug) {
        setAnnouncements([...challenge_announcement]);
        setTitle(title);
        const announcement_counts = [sent_count, draft_count, scheduled_count];
        setAnnouncementCounts(announcement_counts);
        setPagination((prev) => ({
          ...prev,
          pages: total_pages,
          count: announcement_counts[tab],
        }));
      } else {
        setAnnouncements({ error: true });
      }
    } else {
      const [_list, _pages, total] = await list(
        tab === 0 ? "inbox" : "archive",
        filters
      );
      if (_list) {
        setChats(_list?.map((d) => ({ ...d, id: d?.uuid, _id: d?.id })) || []);
        setPagination((prev) => ({ ...prev, pages: _pages, count: total }));
        tab === 0 ? setInboxCount(total) : setArchiveCount(total);
      } else {
        setChats({ error: true });
      }
    }
    if (!notLoading) {
      setLoading(false);
      setBooleanUpdateCurrent(true);
    }
  };

  const handleCreateChatConversation = async (usernames) => {
    return await create({ usernames });
  };

  const updateOnlineStatus = (username, isOnline) => {
    setChats((prevChats) =>
      prevChats.map((chat) => {
        if (!chat?.members) return chat;

        const updatedMembers = chat.members.map((member) => {
          if (member.username === username) {
            return { ...member, isOnline: isOnline };
          }
          return member;
        });
        const anyOtherMemberOnline = updatedMembers.some(
          (member) => member.username !== user.username && member.isOnline
        );
        return {
          ...chat,
          members: updatedMembers,
          online: payloadBool(anyOtherMemberOnline),
        };
      })
    );
  };

  return (
    <DashboardLayout
      loading={isAnnouncements ? !announcements : !chats}
      isEmpty={isAnnouncements ? announcements?.error : chats?.error}
      emptyMessage={t(
        isAnnouncements ? "Cannot fetch announcements!" : "Cannot fetch chats!"
      )}
    >
      <ChatsMainContainer
        isAnnouncements={Boolean(isAnnouncements)}
        chats={isAnnouncements ? announcements : chats}
        title={title}
        id={id}
        setAnnouncements={setAnnouncements}
        createChat={handleCreateChatConversation}
        pages={pagination?.pages}
        page={pagination?.page}
        total={pagination?.count}
        setPage={(page) => setPagination((prev) => ({ ...prev, page }))}
        search={search}
        setSearch={setSearch}
        loading={loading}
        setChats={setChats}
        tab={tab}
        setTab={setTab}
        inboxCount={inboxCount}
        archiveCount={archiveCount}
        setInboxCount={setInboxCount}
        setArchiveCount={setArchiveCount}
        booleanUpdateCurrent={booleanUpdateCurrent}
        setBooleanUpdateCurrent={setBooleanUpdateCurrent}
        announcementsCounts={announcementsCounts}
        setAnnouncementCounts={setAnnouncementCounts}
        t={t}
      />
    </DashboardLayout>
  );
};

export default ChatsSection;
