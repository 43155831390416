import React from "react";
import trophy from "../../../assets/png/goldTrophy.png";
import SecureImage from "./SecureImage";
import styles from "../ui.module.scss";

const TrophyChip = ({ image, title, subTitle, points, t = (d) => d }) => {
  return (
    <div
      data-testid="trophy-chip"
      className={`${styles.trophyList__card} pl-2 py-2 mt-1 flex items-center`}
    >
      <SecureImage
        placeholder={trophy}
        src={image}
        classes="h-56 w-56 rounded-100 object-cover"
      />
      <div className="ml-4 w-70p">
        <p className="fw-600 fs-14 text-truncate" title={title}>
          {!!title ? title : subTitle || "N/A"}
        </p>
        <p className="fs-12 text-truncate">{!!subTitle ? subTitle : "N/A"}</p>
        <p className="fs-12 ">
          <b>
            {points} {t("Prepr Points")}
          </b>
        </p>
      </div>
    </div>
  );
};

export default TrophyChip;
