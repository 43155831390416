import React from "react";
import DashboardLayout from "..";
import { POLICY } from "pages/layouts/data";
import Card from "components/Cards/Card";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation("legal");
  const { title, subTitle, lastRevisedDate, overview, questions } = POLICY;
  return (
    <DashboardLayout>
      <div className="container mt-20">
        <Card centered classes="text-center h-150 flex-column justify-center">
          <h2>{t(title)}</h2>
          <p className="mt-2 c-gray">
            {t("Last revised:")} {t(lastRevisedDate)}
          </p>
        </Card>
        <Card classes="mt-4 px-6 fs-15 py-6">
          <h2 className=" mb-3 text-center">{t(subTitle)}</h2>
          <div>
            {overview.p.map((p) => (
              <p className="mt-4">{parse(t(p))}</p>
            ))}
            <p className="mt-4">{t(overview.list.description)}</p>
            {overview.list.items.map((li, i) => (
              <p>
                {i + 1}. {t(li)}
              </p>
            ))}
          </div>
          <div className="mt-8">
            {questions.map(({ question, answer }, i) => (
              <div key={i} className="mt-4">
                <p className="fw-600">
                  {i + 1}. {t(question)}
                </p>
                <p>{t(answer)}</p>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <p>{t("In writing Attn")}:</p>
            <p className="fw-700">Salar Chagpar</p>
            <p>{t("Privacy Information Officer")}</p>
            <p className="mt-4">{t("Address")}:</p>
            <p className="fw-700">PREPR FOUNDATION</p>
            <p>
              500 King St. W <br />
              Toronto, ON
              <br />
              N2G 1C4{" "}
            </p>
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
};

export default PrivacyPolicy;
