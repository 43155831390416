import React from "react";
import useMaster from "../../../../store/master/service-hook";
import { useAdvanceSearchContext } from "../../hooks/useAdvanceSearchContext";
import SubFilter from "./SubFilter";
import { FilterComponentsList } from "../../data";

const Job = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { fetchJobs } = useMaster();
  const { job, setJob, ActiveFilterComponents } = useAdvanceSearchContext();

  return (
    <SubFilter
      title={t("JOBS")}
      fetchData={fetchJobs}
      values={job}
      select
      onChange={(event) => {
        setJob(
          event?.target?.value && event.target?.value?.length > 0
            ? event.target?.value
            : []
        );
      }}
      valueKey={"id"}
      labelKey={"title"}
      show={ActiveFilterComponents.includes(FilterComponentsList.job)}
      t={t}
    />
  );
};

export default Job;
