import { toast } from "react-toastify";
import ACTIONS from "store/auth/action-types";
import { REDUCER } from "store/auth/reducer";
import { CONTENT_TYPES } from ".";
import i18n from "i18next";

export const resolveResponse = (
  resp,
  successCb = Function.prototype,
  successMsg = "",
  errorCb = Function.prototype,
  noToast,
  noSuccessToast
) => {
  const { data: resData } = resp;

  if (resData?.success) {
    const { data, message } = resData;
    !noSuccessToast && !noToast && toast.success(message || successMsg);
    if (successCb) successCb(data || resData);
  }
  if (!resData?.success) {
    const { message } = resp;
    const error = message?.split("_")?.join(" ");
    toast.error(error || resData?.message || "Something might went wrong!");
    if (errorCb) errorCb();
  }
};

export const getFormData = (body, type) => {
  let formData =
    type === CONTENT_TYPES.MULTIPART_FORM
      ? new FormData()
      : new URLSearchParams();
  Object.entries(body).forEach(([key, value]) => {
    if (
      Array.isArray(value) &&
      (key === "file_upload" ||
        key === "attachment" ||
        key === "winner_achievement_image")
    ) {
      value.forEach((file, index) => {
        formData.append(`${key}[${index}]`, file);
      });
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (typeof item === "object") {
          Object.entries(item).forEach(([objKey, objValue]) => {
            formData.append(`${key}[${index}][${objKey}]`, objValue);
          });
        } else {
          formData.append(`${key}[${index}]`, item);
        }
      });
    } else {
      formData.append(key, value);
    }
  });
  if (type === CONTENT_TYPES.URL_ENCODED) {
    return formData.toString();
  }
  return formData;
};

const PREPR_USER = "preprUser";
const PREPR_TOKEN = "preprToken";
const PREPR_USER_LANG = "preprUserLang";
const PREPR_LAST_LOC = "preprLastLocation";

export const setStorage = (data) => {
  localStorage.setItem(PREPR_USER, JSON.stringify(data));
};

const skipLangReload = () =>
  [
    "/terms",
    "/privacy-policy",
    "/login",
    "/register",
    "/forgot-password",
    "/verify-otp",
    "/user-onboarding",
    "/organization-onboarding",
  ].some((d) => window.location.pathname.includes(d));

export const setLangStorage = (lang, noRefresh) => {
  const updatedLang = lang?.includes("fr") ? "fr" : "en";
  i18n.changeLanguage(updatedLang);
  localStorage.setItem(PREPR_USER_LANG, lang ?? "en");
  if (noRefresh || skipLangReload()) return;
  setTimeout(() => window.location.reload(), 2000);
};

export const setToken = (token) => {
  localStorage.setItem(PREPR_TOKEN, token);
};

export const removeStorage = () => {
  localStorage.removeItem(PREPR_USER);
  localStorage.removeItem(PREPR_TOKEN);
};

const storeToken = localStorage.getItem(PREPR_TOKEN);
const storeUser = localStorage.getItem(PREPR_USER);

export const updateRoleInStorage = (orgData) => {
  if (!orgData || !storeUser) return;
  let updatedData = JSON.parse(storeUser);
  updatedData = {
    ...updatedData,
    organization_details: orgData,
    roles: orgData?.roles,
  };
  setStorage(updatedData);
};

export const resetAuth = (dispatch, state) => {
  const storeUserLang = localStorage.getItem(PREPR_USER_LANG);
  if (
    !state.token &&
    !state.user &&
    !!storeToken &&
    storeUser !== "undefined" &&
    !!storeUser
  ) {
    const updatedUser = JSON.parse(storeUser) || {};
    dispatch(
      REDUCER[ACTIONS.SET_USER]({
        token: storeToken,
        data: updatedUser,
      })
    );
    let updatedLang = storeUserLang ?? updatedUser?.preferred_language;
    if (!updatedLang) return;
    updatedLang = updatedLang.includes("fr") ? "fr" : "en";
    i18n.changeLanguage(updatedLang);
  } else if (!!storeUserLang) {
    const updatedLang = storeUserLang.includes("fr") ? "fr" : "en";
    i18n.changeLanguage(updatedLang);
  }
};

export const serializeObject = (obj, prefix = "") => {
  const params = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const serializedKey = prefix ? `${prefix}[${key}]` : key;

      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.push(`${serializedKey}[]=${encodeURIComponent(item)}`);
        });
      } else if (typeof value === "object") {
        params.push(serializeObject(value, serializedKey));
      } else {
        params.push(`${serializedKey}=${encodeURIComponent(value)}`);
      }
    }
  }

  return params.join("&");
};

export const isManageListing = () => {
  return (
    window.location.pathname.includes("/manage/") ||
    window.location.search.includes("private=true")
  );
};

export const arrayToObjectPayload = (array) => {
  if (!array || !Array.isArray(array)) return {};
  const payload = {};
  for (const key of Object.keys(array[0])) {
    const bodyItem = [];
    array.forEach((d) => {
      bodyItem.push(d[key]);
    });
    payload[key] = bodyItem;
  }
  return payload;
};

export const updateLastLocation = (location) => {
  if (location) {
    localStorage.setItem(PREPR_LAST_LOC, location);
  } else {
    localStorage.removeItem(PREPR_LAST_LOC);
  }
};

export const cachedLoginNavigate = () => {
  const currLocation = window.location.pathname;
  if (currLocation !== "/") updateLastLocation(currLocation);
  window.location.replace("/login");
};

export const getLastVisitedLocation = () =>
  localStorage.getItem(PREPR_LAST_LOC);
