import React from "react";
import Card from "./Card";
import parse from "html-react-parser";
import SecureImage from "components/UI/atoms/SecureImage";
import { getDefaultImageByType } from "./MainProductCard";

export const cleanMediaContent = (media = "") => {
  if (!media) return "";
  const iframeStart = media.indexOf("<iframe");
  if (iframeStart !== -1) {
    return media.substring(iframeStart);
  }
  return media;
};

// Function to modify iframe height and width
const adjustIframeAttributes = (htmlContent, fixedHeight = "250px") => {
  return htmlContent.replace(/<iframe([^>]+)>/g, (match, iframeAttributes) => {
    // Match the iframe tag and adjust its height and width attributes
    const updatedIframe = iframeAttributes
      .replace(/height="(\d+)"/, `height="${fixedHeight}"`) // Replace height with fixed value
      .replace(/width="(\d+)"/, `width="100%"`); // Replace width with 100% (or adjust as needed)

    return `<iframe${updatedIframe}>`;
  });
};

const ImageCard = ({
  image,
  height,
  width,
  classes,
  type,
  imgAbsoluteContent,
  isEmbedded,
  title,
  ...rest
}) => {
  // Clean and adjust iframe attributes
  const adjustedImage = isEmbedded && image ? adjustIframeAttributes(cleanMediaContent(image), height) : image;

  return (
    <Card
      classes={`${classes} overflow-hidden overflowY-hidden px-0 py-0 relative`}
      width={width}
      height={height || "250px"}
    >
      {isEmbedded && image ? (
        <div className="w-full relative" style={{ height }}>
          {/* Render the adjusted iframe */}
          {parse(adjustedImage)}
        </div>
      ) : (
        <SecureImage
          data-testid="image-card"
          src={image}
          placeholder={getDefaultImageByType(type)}
          alt={title}
          height="100%"
          width="100%"
          title={title ?? type}
          imgAbsoluteContent={imgAbsoluteContent}
          {...rest}
        />
      )}
    </Card>
  );
};

export default ImageCard;
