import React from "react";
import { Grid } from "@mui/material";
import PageTitle from "components/UI/atoms/PageTitle";
import Card from "components/Cards/Card";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import FileUploader from "components/FileUploader";
import SocialLinks from "components/UI/molecules/SocialLinks";
import ImageGrid from "components/media/ImageGrid";
import AcceptedFilesPopup from "components/UI/atoms/AcceptedFilesPopup";
import { LockIcon } from "components/Statics";

const ProjectFiles = ({
  setFiles,
  links,
  files,
  setLinks,
  editMode,
  onDelete,
  slug,
  uploadedFiles,
  setUploadedFiles,
  isDetailPage,
  t = (d) => d,
}) => {
  const deleteFile = async (id, type) => {
    const isDeleted = await onDelete(slug, id, type);
    if (isDeleted) {
      setUploadedFiles((prevFiles) => {
        const indexToDelete = prevFiles.findIndex((file) => file.id === id);
        const files = [...prevFiles];
        files.splice(indexToDelete, 1);
        return files;
      });
    }
  };

  return (
    <Grid container px={{ xs: 0, md: 4 }}>
      {!isDetailPage &&
        <PageTitle>{t("Files & Links")}</PageTitle>
      }
      <Grid container spacing={3} mt={1}>
        <Grid item md={12} className="w-full">
          <Card classes="px-9 pb-4">
            <div>
              <div className="flex items-center">
                <h3>{t("Media Files")}</h3>
                <LockIcon />
              </div>
              <div className="flex items-center">
                <p className="c-gray fs-14 mr-1">
                  {t(
                    "Add images, videos or files to your project. File size limit: 150MB."
                  )}
                </p>
                <AcceptedFilesPopup />
              </div>
              <FileUploader
                classes="mt-3"
                name="files"
                setFiles={setFiles}
                maxFileSize={150 * 1024 * 1024}
                files={files}
              />
            </div>
          </Card>
        </Grid>
      </Grid>
      {!!editMode && uploadedFiles.length > 0 && (
        <CreateFormSection title="Uploaded Files">
          <p className="c-gray fw-400 fs-14 mb-1">
            {t("Uploaded files may be removed in this section.")}
          </p>
          <ImageGrid
            files={uploadedFiles}
            perRow={4}
            imageHeight={200}
            onDelete={(file) => deleteFile(file?.id, file?.type)}
            allowFullView
          />
        </CreateFormSection>
      )}
      {!isDetailPage &&
        <CreateFormSection>
          <SocialLinks
            socialLinks={links}
            setSocialLinks={setLinks}
            description={t(
              "You may add additional links related to this Project."
            )}
            locked
          />
        </CreateFormSection>      
      }
    </Grid>
  );
};
export default ProjectFiles;
