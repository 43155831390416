import { Step, StepContent, StepLabel, Stepper } from '@mui/material'
import Button from 'components/Button';
import Card from 'components/Cards/Card'
import MainProductCard from 'components/Cards/MainProductCard';
import { COMPONENT_TYPES } from 'helpers/utils/constants';
import React, { useState } from 'react';
import { NoDataView } from 'components/UI/organisms/ListingContainer';

const ChallengePathChallenges = ({ challenges }) => {
    // eslint-disable-next-line no-unused-vars
    const [challengeStep, setChallengeStep] = useState(2);
    return (
        <Card classes="mt-4 px-6">
            {challenges.length !== 0 ?
                <Stepper activeStep={challengeStep} orientation="vertical" >
                    {challenges?.map((d, index) => {

                        const c_avail = index + 1 === challengeStep;
                        const is_completed = index + 1 < challengeStep;

                        return (<Step key={index} expanded={true}>
                            <StepLabel >
                                {null}
                            </StepLabel>
                            <StepContent>
                                <MainProductCard
                                    title={d?.title}
                                    subTitle="Prepr"
                                    type={COMPONENT_TYPES.CHALLENGE}
                                    image={d?.media}
                                    description={d?.description}
                                    noSave
                                    bottomAction={<Button color={c_avail ? "green" : "gray"} classes="mt-2" height={35}>{c_avail ? "Start Challenge" : (is_completed ? "Challenge Completed" : "Challenge Closed")}</Button>}
                                />
                            </StepContent>
                        </Step>)
                    })}
                </Stepper>
                :
                <NoDataView
                    classes="minh-400"
                    text="No challenges associated"
                />
            }
        </Card>
    )
}

export default ChallengePathChallenges