import {
  Delete,
  Visibility,
  VisibilityOff,
  Extension,
} from "@mui/icons-material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Chip, Grid } from "@mui/material";
import Button from "components/Button";
import MainProductCard from "components/Cards/MainProductCard";
import AcceptDeclineButtons from "components/UI/atoms/AcceptDeclineButtons";
import ListingContainer from "components/UI/organisms/ListingContainer";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import React, { useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventIcon from "@mui/icons-material/Event";
import TaskIcon from "@mui/icons-material/Task";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import useChallenges from "store/challenges/service-hook";

import { isTrue } from "helpers/utils/validators";
import { loginToPreceed } from "helpers/utils/utilities";
import useAuth from "store/auth/service-hook";
import moment from "moment";
import useRedirect from "helpers/hooks/useRedirect";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ChipsPreview from "components/UI/atoms/ChipsPreview";
import ProgressTag from "components/UI/atoms/ProgressTag";
import useOrganization from "store/organization/service-hook";
import { extractChallengeDate } from "helpers/utils/utilities";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import ChalllengeManageOptions from "../components/ChalllengeManageOptions";
import { challenge, challengePath } from "assets/svg/green";
import { StartChallengeButton } from "../components/ChallengeMainPage";

function removeDash(str) {
  return str.replace(/-/g, " ");
}

const ChallengeListing = ({
  browseMode,
  gridType,
  manage,
  challenges,
  setChallenges = Function.prototype,
  loading,
  pageCount = 1,
  page = 1,
  setPage = Function.prototype,
  handleDelete,
  isChallengePath,
  noSave,
  emptyMessage,
  noPagination,
  smallView,
  emptyView,
  isChallengePathDetail,
  isAssociation,
  isTemplate,
  isSequential = false,
  sequentialProgressIndex = 0,
}) => {
  const { t } = useLocalizedTranslation("challenge");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [challengeToDelete, setChallengeToDelete] = useState(null);
  const {
    clone,
    favorite,
    unFavorite,
    acceptChallenge,
    declineChallenge,
    addTemplate,
    removeTemplate,
  } = useChallenges();
  const {
    state: { isLoggedIn, user },
  } = useAuth();

  const openExitConfirmationModal = (slug) => {
    setChallengeToDelete(slug);
    setOpenConfirmationModal(true);
  };

  const cancelExitConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setChallengeToDelete(null);
  };

  const { organizationId } = useOrganization();

  const doUndoAction = async (slug, property, doAction, undoAction) => {
    if (!isLoggedIn) {
      loginToPreceed();
      return;
    }
    const itemIndex = challenges.findIndex((d) => d.slug === slug);
    if (itemIndex === -1) return;

    const item = { ...challenges[itemIndex] };
    const did = isTrue(item[property]);
    if (did) {
      await undoAction(slug, isChallengePath);
      item[property] = "no";
      if (property === "liked") {
        item.likes = Math.max(0, item.likes - 1);
      }
    } else {
      await doAction(slug, isChallengePath);
      item[property] = "yes";
      if (property === "liked") {
        item.likes = (item.likes || 0) + 1;
      }
    }
    const updatedList = [...challenges];
    updatedList[itemIndex] = item;
    setChallenges(updatedList);
  };

  const handleFavoriteUnFav = (slug) =>
    doUndoAction(slug, "favourite", favorite, unFavorite);

  const addIntoTemplates = async (challenge) => {
    if (isTrue(challenge?.is_pre_build)) {
      const res = await removeTemplate(challenge?.slug);
      if (res) {
        setChallenges(
          challenges.map((d, i) =>
            d?.slug === challenge?.slug ? { ...d, is_pre_build: "no" } : d
          )
        );
      }
    } else {
      const res = await addTemplate(challenge?.slug);
      if (res) {
        setChallenges(
          challenges.map((d, i) =>
            d?.slug === challenge?.slug ? { ...d, is_pre_build: "yes" } : d
          )
        );
      }
    }
  };

  const handleCloneChallenge = (challenge) => {
    if (!challenge || !organizationId) return;
    clone(challenge?.slug, organizationId);
  };

  const renderChip = (d) => {
    if (
      d?.hosted_by?.title &&
      !isChallengePath &&
      !isChallengePathDetail &&
      manage
    ) {
      return (
        <Chip
          className="fs-12 c-primary"
          sx={{ height: "20px" }}
          label={t(d?.source ?? "N/A")}
        />
      );
    }
    return null;
  };

  return (
    <>
      <ListingContainer
        loading={loading}
        isEmpty={!challenges?.length}
        count={pageCount}
        page={page}
        setPage={setPage}
        classes="mt-4"
        emptyText={
          !!emptyMessage
            ? emptyMessage
            : !manage && browseMode === 2
            ? t("No pending invitations!")
            : isChallengePath
            ? t("No Challenge Paths found!")
            : t("No Challenges found!")
        }
        noPagination={noPagination}
        emptyView={emptyView}
      >
        {isSequential && !isChallengePath ? (
          <Grid item xs={12} className="mb-3">
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {challenges?.map((d, i) => (
                <TimelineItem key={d?.slug} position="right">
                  <TimelineSeparator
                    className={`${i === 0 && "mt-16"} ${
                      i === challenges?.length - 1 && "mb-16"
                    }`}
                  >
                    {i !== 0 && <TimelineConnector />}
                    <TimelineDot className="h-20 w-20 rounded-100 text-center flex items-center justity-center fs-13">
                      <p>{6 * (page - 1) + i + 1}</p>
                    </TimelineDot>
                    {i !== challenges?.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{ minWidth: "100%", padding: "6px 0px 6px 16px" }}
                    className="w-full"
                  >
                    <ChallengeCardItem
                      addIntoTemplates={addIntoTemplates}
                      browseMode={browseMode}
                      d={d}
                      gridType={gridType}
                      handleCloneChallenge={handleCloneChallenge}
                      isChallengePath={isChallengePath}
                      isTemplate={isTemplate}
                      manage={manage}
                      openExitConfirmationModal={openExitConfirmationModal}
                      user={user}
                      key={d?.slug}
                      t={t}
                      isAssociation={isAssociation}
                      renderChip={renderChip}
                      acceptChallenge={acceptChallenge}
                      declineChallenge={declineChallenge}
                      smallView={smallView}
                      noSave={noSave}
                      handleFavoriteUnFav={handleFavoriteUnFav}
                      isChallengePathDetail={isChallengePathDetail}
                      classes="w-full"
                      isSequential
                      sequentiallyDisabled={
                        sequentialProgressIndex < 6 * (page - 1) + i
                      }
                    />
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
        ) : (
          challenges?.map((d) => (
            <Grid item xs={gridType === 2 ? 6 : 12} className="mb-3">
              <ChallengeCardItem
                addIntoTemplates={addIntoTemplates}
                browseMode={browseMode}
                d={d}
                gridType={gridType}
                handleCloneChallenge={handleCloneChallenge}
                isChallengePath={isChallengePath}
                isTemplate={isTemplate}
                manage={manage}
                openExitConfirmationModal={openExitConfirmationModal}
                user={user}
                key={d?.slug}
                t={t}
                isAssociation={isAssociation}
                renderChip={renderChip}
                acceptChallenge={acceptChallenge}
                declineChallenge={declineChallenge}
                smallView={smallView}
                noSave={noSave}
                handleFavoriteUnFav={handleFavoriteUnFav}
                isChallengePathDetail={isChallengePathDetail}
              />
            </Grid>
          ))
        )}
      </ListingContainer>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={cancelExitConfirmationModal}
        desc={t(
          `Are you sure you want to delete this ${
            isChallengePath ? "Challenge path" : "Challenge"
          }?`
        )}
        okayButtonTitle={t(
          `Delete ${isChallengePath ? "Challenge path" : "Challenge"}`
        )}
        onOkay={() => {
          handleDelete(challengeToDelete);
          cancelExitConfirmationModal();
        }}
      />
    </>
  );
};

export default ChallengeListing;

const ChallengeCardItem = ({
  d,
  gridType,
  isChallengePath,
  browseMode,
  manage,
  smallView,
  isTemplate,
  addIntoTemplates,
  handleCloneChallenge,
  openExitConfirmationModal,
  user,
  t,
  isAssociation,
  renderChip,
  acceptChallenge,
  declineChallenge,
  handleFavoriteUnFav,
  noSave,
  isChallengePathDetail,
  isSequential,
  classes = "",
  sequentiallyDisabled = false,
}) => {
  const { navigateChallenge, navigateCreateProject, navigateProject } =
    useRedirect();
  return (
    <MainProductCard
      classes={`h-full ${classes}`}
      title={
        <div className="flex items-center">
          {isAssociation && (
            <img
              className="w-20 mr-1 h-20"
              src={isChallengePath ? challengePath : challenge}
              alt="challenge"
            />
          )}
          <p
            className={`text-nowrap overflow-hidden ${
              gridType === 1 && "maxw-75p"
            } textOverflow-ellipsis`}
          >
            {d?.title}
          </p>
          {browseMode !== 2 && isTrue(d?.joined) && !manage && (
            <ProgressTag classes="ml-1" progress={d?.module_progress} />
          )}
        </div>
      }
      small={smallView}
      subTitle={
        <div className="flex items-center justify-between">
          <div className="flex items-center subtitle w-60p">
            <p className="mr-1 fs-16 fw-700 text-truncate">
              {d?.organization}{" "}
              {manage &&
                (d?.challenge_status
                  ? `- ${t(d?.challenge_status)} ${t("Challenge")}`
                  : "- N/A")}{" "}
              - {!!d?.category ? removeDash(d?.category) : "N/A"}
            </p>
          </div>
          {gridType === 1 && renderChip(d)}
        </div>
      }
      iconsAndValuesList={
        isSequential
          ? []
          : [
              !isTemplate && {
                icon: AccessTimeIcon,
                value: isChallengePath
                  ? `${t("Last Updated")} ${moment(d?.last_updated).format(
                      "D MMM YYYY"
                    )}`
                  : d.challenge_status === "Closed"
                  ? t("Closed")
                  : d.challenge_timelines?.timeline_type === "restricted" &&
                    moment(extractChallengeDate(d)).isSameOrBefore(
                      moment(),
                      "day"
                    )
                  ? t("Closed")
                  : `${t("Open Until")} ${extractChallengeDate(d)}`,
              },
              d?.duration && {
                icon: EventIcon,
                value: d?.duration ? d?.duration : t("Not Set"),
              },
              !isChallengePath &&
                !isTemplate && {
                  icon: TaskIcon,
                  value: `${d?.submissions_count || 0} ${t("submissions")}`,
                },
              {
                icon: SignalCellularAltIcon,
                value: d?.level ? d?.level?.split(" ")[0] : "N/A",
              },
              {
                icon: isTrue(d?.privacy) ? VisibilityOff : Visibility,
                value: t(isTrue(d?.privacy) ? "Private" : "Public", "common"),
              },
              isChallengePath && {
                icon: Extension,
                value: `${d?.challenge_count} ${t("Challenges")}`,
              },
            ]
      }
      description={
        isChallengePath
          ? d?.description
          : d?.description_type === "text"
          ? d?.description
          : t(
              "Challenge descriptions and requirements are displayed via SCORM files. Click on the challenge title to view the full description.",
              "challenge"
            )
      }
      col={gridType === 2}
      image={d?.media}
      mediaType={d?.media_type ?? "image"}
      id={d?.slug}
      type={
        isTemplate
          ? COMPONENT_TYPES.CHALLENGE_TEMPLATE
          : isChallengePath
          ? COMPONENT_TYPES.CHALLENGE_PATH
          : COMPONENT_TYPES.CHALLENGE
      }
      rightAction={
        !!manage ? (
          <ChalllengeManageOptions
            isChallengePath={isChallengePath}
            slug={d?.slug}
            addIntoTemplates={addIntoTemplates}
            data={d}
            handleCloneChallenge={handleCloneChallenge}
            onDelete={openExitConfirmationModal}
            t={t}
            user={user}
          />
        ) : (browseMode === 3 || (isChallengePath && browseMode === 2)) &&
          isTrue(d?.favourite) ? (
          <Button
            bordered
            color="red"
            height="30px"
            classes="fs-12"
            width="90px"
            onClick={() => handleFavoriteUnFav(d?.slug)}
          >
            <Delete fontSize="small" /> {t("Remove")}
          </Button>
        ) : null
      }
      noSave={
        noSave ||
        Boolean(manage) ||
        isTrue(d?.favourite) ||
        browseMode === 2 ||
        isChallengePathDetail
      }
      bottomContent={
        ((!manage && !isAssociation) || isSequential) && (
          <div className="flex justify-between my-1">
            <ChipsPreview
              items={Object.values(d?.skills || {})}
              limit={3}
              maxWidth={200}
            />
            {gridType !== 2 ? (
              !!manage ? null : browseMode === 1 && !isChallengePath ? (
                <div>
                  <Button
                    bordered
                    classes="fs-12 tracking-tight"
                    height="30px"
                    width="100%"
                    onClick={() => navigateChallenge(d?.slug, false, 3)}
                  >
                    {t("View Submitted Projects")}
                  </Button>
                </div>
              ) : browseMode === 2 && !isChallengePath ? (
                <AcceptDeclineButtons
                  onAccept={acceptChallenge}
                  onDecline={declineChallenge}
                  id={d?.slug}
                  joined={d?.joined}
                  classes={"flex-1 justify-end"}
                />
              ) : null
            ) : null}
            {(isChallengePathDetail || isSequential) && (
              <StartChallengeButton
                challenge={d}
                classes="fs-13 minw-120"
                height="25px"
                disabled={
                  sequentiallyDisabled ||
                  isTrue(d?.is_project_submitted) ||
                  d.status === "draft"
                }
                hide={!d?.is_active || d?.is_active === "no"}
                t={t}
                width="auto"
                onClick={() =>
                  d?.is_active === "in_progress"
                    ? navigateProject(d?.project?.slug)
                    : navigateCreateProject(d?.id)
                }
                isSequential
              />
            )}
          </div>
        )
      }
      colBottomContent={
        <div className="flex justify-between w-full">
          {!!manage ? null : browseMode === 1 ? (
            <div className="flex-1">
              <Button
                bordered
                classes="fs-12 tracking-tight"
                height="30px"
                width="100%"
                onClick={() => navigateChallenge(d?.slug, false, 3)}
              >
                {t("View Submitted Projects")}
              </Button>
            </div>
          ) : browseMode === 2 && !isChallengePath ? (
            <AcceptDeclineButtons
              onAccept={acceptChallenge}
              onDecline={declineChallenge}
              id={d?.slug}
              joined={d?.joined}
              classes={"flex-1 justify-end"}
            />
          ) : null}
          {renderChip(d)}
        </div>
      }
      onSave={() => handleFavoriteUnFav(d?.slug)}
    />
  );
};
