import { useSelector } from "react-redux";
import REQUEST from "store/api";
import { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { serializeObject } from "store/api/utils";
import { resolveResponse } from "store/api/utils";
import { REDUCER_TYPES } from "../index";
import { useMemo } from "react";
import useRedirect from "helpers/hooks/useRedirect";
import useAuth from "store/auth/service-hook";
import { keyValueFormat } from "helpers/utils/utilities";

const useOrganization = () => {
  const state = useSelector((state) => state[REDUCER_TYPES.ORGANIZATION]);
  const auth = useSelector((state) => state[REDUCER_TYPES.AUTH]);
  const { language, token, user } = useSelector(
    (state) => state[REDUCER_TYPES.AUTH]
  );
  const { languageChange } = useAuth();
  const { navigateOrg } = useRedirect();
  const headers = {
    Authorization: "Bearer " + token,
  };

  const organizationId = useMemo(() => {
    return auth?.user?.organization_details?.id;
  });

  const getOrganizationList = async (filters) => {
    const onSuccess = (resp) => resolveResponse(resp, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${
        ENDPOINTS.ORGANIZATION
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      onSuccess,
      Function.prototype,
      true
    );
    if (res.data?.data) {
      const { list, total_pages } = res.data?.data;
      return [list, total_pages];
    }
    return [];
  };

  const getOrganizationSelectList = async (search = "") => {
    const client = new REQUEST();

    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.GET_LIST}?language=${language}&search=${search}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return Array.isArray(res?.data?.data)
        ? keyValueFormat(res?.data?.data)
        : [];
    }
    return [];
  };

  const getMarketplaceOrgList = async (search = "") => {
    const client = new REQUEST();

    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_MARKETPLACE}/get-organization-list?language=${language}&search=${search}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      const orgs = res?.data?.data || {};
      const list = Object.entries(orgs).map(([key, value]) => ({ key, value }));
      return list;
    }
    return [];
  };

  const createOrganization = async (payload = {}) => {
    const body = {
      ...payload,
      user_id: user?.id,
      status: "publish",
    };

    const onSuccess = (res) =>
      resolveResponse(res, null, "Organization created successfully!");

    const client = new REQUEST();

    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.CREATE}?language=${payload.language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );

    if (language !== payload?.language) {
      await languageChange(payload.language, true);
    }

    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const customize = async (slug, payload = {}) => {
    const body = {
      ...payload,
      language,
    };

    const onSuccess = (res) =>
      resolveResponse(res, null, "Organization created successfully!");

    const client = new REQUEST();

    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.CUSTOMIZATION}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const getCustomization = async (slug) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.CUSTOMIZATION}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const deleteOrganization = async (slug) => {
    const onSuccess = (res) =>
      resolveResponse(res, null, "Organization deleted successfully!");

    const client = new REQUEST();

    const res = await client.delete(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      onSuccess
    );
    return res;
  };

  const updateOrganization = async (slug, payload = {}) => {
    const body = {
      ...payload,
      language,
      status: "publish",
      user_id: user?.id,
      _method: "PUT",
    };
    const onSuccess = (res) =>
      resolveResponse(res, null, "Organization updated successfully!");

    const client = new REQUEST();

    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.UPDATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const getOrganization = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) =>
      resolveResponse(res, Function.prototype, "", Function.prototype, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${slug}?language=${language}`,
      {},
      headers,
      onSuccess,
      null,
      true
    );
    if (res?.data?.data) return res?.data?.data;
  };

  const checkSlug = async (slug) => {
    if (!slug) return;
    const client = new REQUEST();
    const response = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.CHECK_SLUG}/${slug}?language=${language}`,
      {},
      headers,
      () => {},
      () => {},
      true
    );
    if (response?.data) return response?.data;
    return response;
  };

  //LISTING PUBLIC API
  const getPublicOrgList = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${
        ENDPOINTS.ORGANIZATION
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      () => {},
      () => {},
      true
    );
    if (res.data?.data) {
      const { list, total_pages, pending_invite } = res.data?.data;
      return [list, total_pages, pending_invite];
    }
    return [];
  };

  //VIEW PUBLIC API
  const viewPublicOrganization = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) =>
      resolveResponse(res, Function.prototype, "", Function.prototype, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${slug}?language=${language}`,
      {},
      headers,
      onSuccess,
      null,
      true
    );
    if (res?.data?.data) return res?.data?.data;
  };

  const follow = async (slug) => {
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.FOLLOW}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const unFollow = async (slug) => {
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.UNFOLLOW}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const like = async (slug) => {
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.LIKE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const unLike = async (slug) => {
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.UNLIKE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const favorite = async (slug) => {
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.FAVOURITE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const unFavorite = async (slug) => {
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.UNFAVOURITE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const planDetails = async (slug) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.SUBSCRIPTION_DETAILS}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const upgradePlan = async (slug, plan_name) => {
    if (!slug || !plan_name) return;
    const onSuccess = (res) =>
      resolveResponse(
        res,
        (data) => navigateOrg(data.slug, true),
        "Organization plan successfully upgraded!"
      );

    const client = new REQUEST();

    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${slug}/${ENDPOINTS.SELECT_PLAN}?language=${language}&plan_name=${plan_name}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const comparePlans = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.COMPARE_PLANS}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const checkTitle = async (title) => {
    if (!title) return;
    const client = new REQUEST();
    const response = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.CHECK_TITLE}/${title}?language=${language}`,
      {},
      headers,
      () => {},
      () => {},
      true
    );
    return response;
  };

  const acceptDeclineOrg = async (slug, isDecline = false) => {
    if (!slug || !user || !user?.email) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.INVITATION_MANAGEMENT}/${
        ENDPOINTS.ORGANIZATION
      }/${slug}/${ENDPOINTS.REQUEST}/${
        isDecline ? ENDPOINTS.DECLINE : ENDPOINTS.ACCEPT
      }?language=${language}`,
      { email: user?.email },
      headers,
      onSuccess
    );
    return res;
  };

  const hrIntegrationsSetting = async (payload = {}, slug) => {
    if (!payload || !slug) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${slug}/integrations?language=${language}`,
      body,
      headers,
      resolveResponse
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  return {
    state,
    organizationId,
    getOrganizationList,
    createOrganization,
    deleteOrganization,
    updateOrganization,
    getOrganization,
    checkSlug,
    getOrganizationSelectList,
    getPublicOrgList,
    viewPublicOrganization,
    follow,
    unFollow,
    like,
    unLike,
    favorite,
    unFavorite,
    planDetails,
    upgradePlan,
    customize,
    getCustomization,
    comparePlans,
    checkTitle,
    acceptDeclineOrg,
    hrIntegrationsSetting,
    getMarketplaceOrgList,
  };
};

export default useOrganization;
