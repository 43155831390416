import { createSlice } from "@reduxjs/toolkit";
import ACTIONS from "./action-types";

const initialState = {
  user: null,
  isLoggedIn: false,
  language: "en",
  organizations: [],
  token: undefined,
  organization: null,
  orgListResponse: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    [ACTIONS.LOGOUT]: (state) => {
      state.isLoggedIn = false;
    },
    [ACTIONS.REGISTER]: (state) => {
      state.isLoggedIn = false;
    },
    [ACTIONS.SET_USER]: (state, { payload }) => {
      state.isLoggedIn = true;
      state.user = payload.data || payload.user;
      state.language = payload?.data?.preferred_language ?? payload?.user?.preferred_language ?? "en";
      if (!state.token) state.token = payload.token?.token || payload.token;
    },
    [ACTIONS.SET_LANGUAGE]: (state, { payload }) => {
      state.language = payload;
      state.user = { ...state.user, preferred_language: payload };
    },
    [ACTIONS.SET_ORGANIZATION]: (state, { payload }) => {
      state.organization = payload;
    },
    [ACTIONS.ORG_LIST]: (state, { payload }) => {
      state.orgListResponse = payload;
    },
  },
});
export const REDUCER = authSlice.actions;

export default authSlice.reducer;
