import React, { useState, useEffect } from "react";
import AutoComplete from "components/Input/AutoComplete";
import { Avatar, Box } from "@mui/material";
import { user as userImg } from "../../../assets/svg/gradient/exports";
import useMembers from "store/member-management/service-hook";
import useAuth from "store/auth/service-hook";
let timeoutId;

const NetworkUsersSelect = ({
  users = [],
  setUsers,
  title,
  error,
  classes,
  required,
  hideSearchIcon,
  dataLabelAttr = "email",
  prefixText,
  dontSelectMe,
  placeholder,
  disabled = false,
  t = (d) => d,
}) => {
  const [networkMembers, setNetworkMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getNetworkMembers } = useMembers();
  const {
    state: { user },
  } = useAuth();

  const fetchNetworkUsers = async (query) => {
    const res = await getNetworkMembers(query);
    if (res) {
      setNetworkMembers(res);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNetworkUsers();
  }, []);

  const handleInputOnChange = (e) => {
    if (disabled) return; // Prevent changes if disabled
    setIsLoading(true);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fetchNetworkUsers(e.target.value), 1500);
  };

  const handleSelectUser = (newUser) => {
    if (disabled || !newUser[dataLabelAttr]) return; // Prevent selection if disabled
    if (dontSelectMe && newUser.username === user?.username) return;
    if (
      !!newUser &&
      !users?.some((user) => user?.username === newUser?.username)
    ) {
      setUsers([...users, newUser]);
    }
  };

  const handleRemoveUser = (user) => {
    if (disabled) return; // Prevent removal if disabled
    setUsers([...users.filter((e) => e !== user)]);
  };

  return (
    <div className={classes}>
      {!!title && (
        <p className="fs-14 fw-500 opacity-90 mb-1">
          {t(title)}
          {!!required && <span className="c-error"> *</span>}
        </p>
      )}
      <AutoComplete
        dataLabelAttr={dataLabelAttr || "email"}
        keyValueOptions={networkMembers}
        selectedOptions={users || []}
        loading={isLoading}
        onSelectOption={handleSelectUser}
        onRemoveChip={handleRemoveUser}
        onChange={handleInputOnChange}
        hideSearchIcon={hideSearchIcon}
        emptyText={t("No Users Found!")}
        error={error}
        prefixText={prefixText}
        disabled={disabled} // Pass the disabled prop to AutoComplete
        renderListItem={(networkUser) => (
          <Box
            className={`flex h-40 ${
              dontSelectMe && networkUser.username === user?.username
                ? "opacity-50 cursor-none"
                : ""
            }`}
          >
            <Box className="mr-2 flex items-center ">
              <Avatar
                src={networkUser?.profile_image || userImg}
                alt="profile"
                sx={{ height: 37, width: 37 }}
                className="border"
              >
                <img
                  className="object-cover"
                  height="37px"
                  width="37px"
                  src={userImg}
                  alt="profil pic of user"
                />
              </Avatar>
            </Box>
            <Box className="flex-column justify-center">
              <h3 className="fs-14">{networkUser.full_name}</h3>
              <p className="fs-10 opacity-50">{networkUser.email}</p>
            </Box>
          </Box>
        )}
        placeholder={t(placeholder)}
        freeText
      />
    </div>
  );
};

export default NetworkUsersSelect;
