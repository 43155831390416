import Card from "components/Cards/Card";
import React, { useEffect, useState } from "react";
import OverviewCard, { validateAddMembers } from "./OverviewCard";
import LoginCustomization from "./LoginCustomization";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import EditCreateHeaderCard from "components/Cards/EditCreateHeaderCard";
import DashboardLayout from "pages/layouts/dashboard";
import useForms from "helpers/hooks/useForms";
import {
  getInfoSchema,
  getLocationSchema,
  ORG_SCHEMA,
} from "pages/organization/forms/data";
import useOrganization from "store/organization/service-hook";
import { deleteValues, keyVal, payloadBool } from "helpers/utils/utilities";
import CreateFormFooter from "components/UI/atoms/CreateFormFooter";
import { isTrue, REQUIRED_VALIDATOR, Validate } from "helpers/utils/validators";
import {
  scrollToTop,
  payloadFormatArray,
} from "../../../helpers/utils/utilities";
import "../organization.scss";
import OrganizationSubscription from "./OrganizationSubscription";
import useRedirect from "helpers/hooks/useRedirect";
import { toast } from "react-toastify";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

let initData = {};

const STEPS = ["Overview", "Subscription", "Login Customization"];
const EditOrganization = () => {
  const [step, setStep] = useState(0);
  const [socialLinks, setSocialLinks] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const { slug } = useParams();
  const editMode = Boolean(!!slug);
  const [initLoading, setInitLoading] = useState(Boolean(editMode));
  const [dirty, setDirty] = useState(false);
  const [locked, setLocked] = useState("true");
  const { search } = useLocation();
  const { t } = useLocalizedTranslation();
  const initialStep = Number(new URLSearchParams(search).get("step")) || 0;
  const [customData, setCustomData] = useState({
    enable_custom_login_and_registration: false,
    use_main_org_logo: true,
    custom_logo_image: undefined,
    custom_hero_image: undefined,
    custom_background_color: null,
    custom_url: "",
  });
  const {
    createOrganization,
    updateOrganization,
    getOrganization,
    checkSlug,
    customize,
    getCustomization,
    planDetails,
  } = useOrganization();

  // eslint-disable-next-line no-unused-vars
  const [activePlan, setActivePlan] = useState("seed_plan_yearly");
  const { navigateOrg, navigateEditOrg } = useRedirect();
  const createSlug = searchParams.get("slug");

  const handleVanityValidate = async (value) => {
    if (step !== 0 || editMode) return false;
    const _error = await Validate(REQUIRED_VALIDATOR, value);
    if (_error) return _error;
    setLoading(true);
    const { success, message, error } = await checkSlug(value);
    setLoading(false);
    return !success || error ? message : false;
  };

  const slicer = (_schema) => {
    return [...getInfoSchema(handleVanityValidate), ...getLocationSchema()];
  };

  const { data, errors, validate, onChange, setData, setErrors } = useForms(
    ORG_SCHEMA(),
    initData,
    slicer
  );

  useEffect(() => {
    if (initialStep) setStep(initialStep);
  }, []);

  useEffect(() => {
    if (step !== 2 || (!createSlug && !slug)) return;
    handleSetCustomization();
  }, [step, createSlug]);

  useEffect(() => {
    if (editMode) handleFetchOrganization();
    else {
      setData(initData);
      setMembers([]);
      setSocialLinks([]);
    }
  }, [editMode]);

  const handleSetCustomization = async () => {
    const res = await planDetails(createSlug || slug);
    if (!res) {
      setStep(0);
    } else if (
      !!res?.plan &&
      res?.plan !== "seed_plan_yearly" &&
      !res?.plan?.includes("sprout")
    ) {
      setLocked(false);
      const customRes = await getCustomization(createSlug || slug);
      if (customRes) {
        setCustomData({
          enable_custom_login_and_registration: isTrue(
            customRes?.enable_custom_login_and_registration
          ),
          use_main_org_logo: isTrue(customRes?.use_main_org_logo),
          custom_logo_image: customRes?.custom_logo_image,
          custom_hero_image: customRes?.custom_hero_image,
          custom_background_color:
            customRes?.custom_background_color,
          profile_image: customRes?.profile_image,
          custom_url: customRes?.custom_url || "",
        });
      }
    } else {
      setLocked(res?.plan ?? "true");
    }
  };

  const handleFetchOrganization = async () => {
    if (step !== 0) return;
    const organization = await getOrganization(slug);
    if (organization) {
      setActivePlan(
        organization?.organization_details?.plan || "seed_plan_yearly"
      );
      const newData = {
        ...organization,
        ...(organization?.organization_address?.[0] || {}),
        categories: keyVal(organization?.category, organization?.category_id),
      };
      deleteValues(newData, [
        "organization_address",
        "category",
        "category_id",
        "organization_members",
        "external_links",
      ]);
      setData(newData);

      if (organization.organization_members?.length) {
        setMembers(
          organization.organization_members.map(
            ({ name, id, position, image }) => ({
              name,
              id,
              position,
              image,
            })
          )
        );
      }
      if (organization?.external_links?.length) {
        setSocialLinks(
          organization?.external_links.map(({ link_id, link, title }) => ({
            url: link,
            type: keyVal(title, link_id),
          })) || []
        );
      }
      setInitLoading(false);
    }
  };

  const handleBack = () => {
    if (!editMode && step === 1) {
      navigateEditOrg(data?.slug);
      setStep(0);
    } else setStep((prev) => prev - 1);
  };

  const handleSaveAndExit = () => handleContinue(false);
  const handleSave = async (isSubmit) => {
    const newErrors = await validate();
    if (newErrors.size) return;
    let body;

    const organization_address = {
      latitude: data.latitude,
      longitude: data.longitude,
    };

    getLocationSchema().forEach(({ name }) => {
      if (!!data[name]) organization_address[name] = data[name];
    });
    let toDelete;

    body = {
      ...data,
      total_employees: +data.total_employees,
      category: data.categories?.key,
      description: data.about,
      organization_members: members,
      organization_address: [organization_address],
      plan_name: activePlan,
      external_links: payloadFormatArray(socialLinks, "url"),
      external_link_ids: socialLinks.map((d) => d?.type?.key),
      custom_url: data?.custom_url || data?.slug,
      website:
        data?.website && !data?.website.toLowerCase().startsWith("https://")
          ? `https://${data?.website}`
          : data?.website,
    };
    if (!editMode) body.slug = data.vanity_slug;
    body.organization_members = body.organization_members.map((member) => {
      if (typeof member.image === "string") {
        delete member.image;
      }
      return member;
    });
    toDelete = editMode
      ? ["slug", "categories", "organization_details", "challenges", "labs"]
      : ["categories"];

    if (
      !body?.profile_image ||
      (editMode && typeof body.profile_image === "string")
    ) {
      toDelete.push("profile_image");
    }
    if (
      !body?.cover_image ||
      (editMode && typeof body.cover_image === "string")
    ) {
      toDelete.push("cover_image");
    }
    deleteValues(body, toDelete);
    getLocationSchema().forEach(({ name }) => {
      delete body[name];
    });
    setLoading(isSubmit ? "submit" : "save");
    let response;
    if (!editMode) {
      response = await createOrganization(body);
      searchParams.set("slug", response?.slug);
      setSearchParams(searchParams);
    } else {
      response = await updateOrganization(slug, body);
    }

    setCustomData((prev) => ({
      ...prev,
      profile_image: response?.profile_image,
    }));

    setLoading(false);
    return response;
  };
  const handleContinue = async (isSubmit) => {
    if (step === 1) {
      scrollToTop();
      isSubmit ? setStep(step + 1) : navigateOrg(data?.slug, true);
      return;
    }
    if (step === 2) {
      handleCustomLoginSubmit();
      return;
    }
    const newErrors = await validate();
    if (newErrors.size) return;
    if (step === 0) {
      if (validateAddMembers(members)) return;
      const res = await handleSave(isSubmit);
      if (res) {
        isSubmit ? setStep(step + 1) : navigateOrg(res?.slug, true);
      }
    }
  };

  const handleCustomLoginSubmit = async () => {
    if (
      Boolean(locked) ||
      (!customData?.enable_custom_login_and_registration &&
        !customData?.custom_url)
    ) {
      navigateOrg(createSlug || slug, true);
      return;
    }

    if (
      customData?.customData?.enable_custom_login_and_registration &&
      !customData?.custom_hero_image
    ) {
      toast.error("Please upload background image");
      return;
    }
    if (
      customData?.enable_custom_login_and_registration &&
      !customData?.use_main_org_logo &&
      !customData?.custom_logo_image
    ) {
      toast.error("Please upload logo image");
      return;
    }

    setLoading(true);
    const payload = {
      ...customData,
      enable_custom_login_and_registration: payloadBool(
        customData?.enable_custom_login_and_registration
      ),
      use_main_org_logo: payloadBool(customData?.use_main_org_logo),
      custom_hero_image: customData?.custom_hero_image,
      custom_logo_image: customData?.custom_logo_image,
    };
    if (typeof customData?.custom_hero_image === "string") {
      delete payload.custom_hero_image;
    }

    if (
      typeof customData?.custom_logo_image === "string" ||
      !customData?.custom_logo_image
    ) {
      delete payload.custom_logo_image;
    }
    delete payload.profile_image;
    const res = await customize(createSlug || slug, payload);
    setLoading(false);
    if (res) {
      navigateOrg(createSlug || slug, true);
    }
  };

  const handleChange = (e) => {
    if (!dirty) setDirty(true);
    const { name, value = {} } = e.target;
    onChange(e);
    if (name === "address_1") {
      setData((prevData) => ({ ...prevData, ...value }));
      setErrors((prevErrors) => {
        const newErrors = new Map(prevErrors);
        if (Object.keys(value).length) {
          [
            "address_1",
            "address_2",
            "city",
            "state",
            "country",
            "zip_code",
          ].forEach((field) => newErrors.delete(field));
        }
        return newErrors;
      });
    }
  };

  return (
    <DashboardLayout loading={initLoading}>
      <div className="editOrganization">
        <EditCreateHeaderCard
          classes="mt-header"
          current={step}
          length={STEPS.length}
          title={`${editMode ? "EDIT" : "CREATE"} YOUR ORGANIZATION`}
          subtitles={STEPS}
        />
        <Card centered classes="mt-10 mb-4" width="min(1300px,90%)">
          {step === 0 && (
            <OverviewCard
              data={data}
              errors={errors}
              onChange={handleChange}
              members={members}
              setMembers={setMembers}
              socialLinks={socialLinks}
              setSocialLinks={setSocialLinks}
              t={t}
            />
          )}
          {step === 1 && (
            <OrganizationSubscription
              activePlan={activePlan}
              setActivePlan={setActivePlan}
              slug={editMode ? slug : createSlug}
              setStep={setStep}
              planDetails={planDetails}
              t={t}
            />
          )}
          {step === 2 && (
            <LoginCustomization
              setStep={setStep}
              data={customData}
              setData={setCustomData}
              locked={locked}
              t={t}
            />
          )}
          <CreateFormFooter
            step={step}
            stepsLength={STEPS.length}
            editMode={editMode}
            onCancel={handleBack}
            onOk={handleContinue}
            disabled={step === 0 && Boolean(errors.size)}
            loading={loading === "submit"}
            backButtonColor="primary"
            middleButtonTitle="Save & Exit"
            displayMiddleButton={editMode}
            okButtonColor="primary"
            middleButtonLoading={editMode && loading === "save"}
            onMiddleButtonClick={handleSaveAndExit}
            hideOkButton={editMode && step === STEPS.length - 1}
            okButtonTitle={
              !editMode && step === STEPS.length - 1 && "Create Organization"
            }
          />
        </Card>
      </div>
    </DashboardLayout>
  );
};

export default EditOrganization;
