import DashboardLayout from "../layouts/dashboard";
import EditCreateHeaderCard from "../../components/Cards/EditCreateHeaderCard";
import React, {useEffect, useState} from "react";
import UnifiedImportMemberContextWrapper from "./container/UnifiedImportMemberContext";
import {useParams} from "react-router-dom";
import useRedirect from "../../helpers/hooks/useRedirect";
import {INVITATION_ALLOWED_TYPES} from "../../store/unified/constants";
import ImportContacts from "./components/ImportContacts";
import {Grid} from "@mui/material";
import ImportMessageForm from "./form/importMessageForm";

const UnifiedInviteMember = () => {

    /**
     * HOOKS
     */
    const {type} = useParams()
    const {navigate404} = useRedirect()

    /**
     * STATE
     */
    const [step, setStep] = useState(0)


    /**
     * REDIRECT FOR WRONG INVITATION TYPE
     */
    useEffect(() => {
        if (!INVITATION_ALLOWED_TYPES.includes(type)) {
            navigate404()
        }
    }, [])


    return (
        <UnifiedImportMemberContextWrapper>
            <DashboardLayout>
                <Grid container justifyContent={"center"}>
                    <Grid xs={12} md={8}>
                        <EditCreateHeaderCard
                            current={step}
                            length={2}
                            subtitles={["Import Contacts", "Customize Invitations"]}
                            title={""}
                            classes="mt-header"
                            numbered
                            rounded
                        >
                                <ImportContacts setStep={setStep} show={step === 0}/>
                                <ImportMessageForm setStep={setStep} show={step === 1}/>
                        </EditCreateHeaderCard>
                    </Grid>
                </Grid>

            </DashboardLayout>
        </UnifiedImportMemberContextWrapper>)
}

export default UnifiedInviteMember
