import Button from 'components/Button'
import ModalLayout from 'components/Modal'
import React, { useState } from 'react'
import ConfirmationSuccess from '../addPrebuiltLabs/ConfirmationSuccess'
import CheckoutPlan from './CheckoutPlan'
import SubscriptionPlansCard from './SubscriptionPlansCard'

const SubscriptionPlans = ({ open, onClose }) => {
    const [checkoutState, setCheckoutState] = useState(0);

    const handleContinue = () => {
        // eslint-disable-next-line default-case
        switch (checkoutState) {
            case 0: {
                setCheckoutState(1);
                return;
            }
            case 1: {
                setCheckoutState(2);
                return;
            }
            case 2: {
                setCheckoutState(0);
                onClose();
            }
        }
    }

    return (
        <ModalLayout
            open={open}
            onClose={onClose}
            light
            width="max(350px,70%)"
        >
            <h2 className='my-2 ml-2'>ENHANCE YOUR PREPRLABS EXPERIENCE</h2>
            {checkoutState === 0 && <SubscriptionPlansCard />}
            {checkoutState === 1 && <CheckoutPlan />}
            {checkoutState === 2 && <ConfirmationSuccess />}
            <div className={`flex items-center ${checkoutState === 2 ? "justify-center mt-4" : "justify-between"} pt-2 px-10 mb-2 footer__btns`}>
                {checkoutState !== 2 && <Button height={45} width={200} bordered onClick={onClose}>Cancel</Button>}
                <Button height={45} width={250} classes="ml-2" color={checkoutState === 1 ? "green" : "blue"} onClick={handleContinue}>{checkoutState === 0 ? "Continue" : (checkoutState === 1 ? "Confirm & Pay" : (checkoutState === 2 && "Okay"))}</Button>
            </div>
        </ModalLayout>
    )
}

export default SubscriptionPlans