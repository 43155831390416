import {
  Assignment,
  AutoGraph,
  BorderColor,
  Group,
  WorkHistory,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import Card from "components/Cards/Card";
import { createEvent } from "helpers/utils/utilities";
import React from "react";
import PageTitle from "components/UI/atoms/PageTitle";
import { useTranslation } from "react-i18next";

const iconstyle = { fontSize: 90, color: "#498CCE", marginTop: 10 };
const lab_types = [
  {
    title: "Assess Lab",
    id: "assess",
    description:
      "Use this tool to help filter applicants at the beginning or end of the interview process.",
    icon: <Assignment style={iconstyle} />,
  },
  {
    title: "Onboard Lab",
    id: "onboard",
    description:
      "It helps all leaders within your organization provide effective and results based orientation and onboarding for new  learning experience.",
    icon: <WorkHistory style={iconstyle} />,
  },
  {
    title: "Engage Lab",
    id: "engage",
    description:
      "The Engage solution can be customized to the needs across all levels of your organization. ",
    icon: <Group style={iconstyle} />,
  },
  {
    title: "Grow Lab",
    id: "grow",
    description:
      "Reward and recognize participants for solving innovation challenges that can help grow your business and retain top talent.",
    icon: <AutoGraph style={iconstyle} />,
  },
  {
    title: "Customize your lab",
    id: "na",
    description: "Decide later and start customizing your lab now.",
    icon: <BorderColor style={iconstyle} />,
  },
];

const LabTypes = ({ type, onChange }) => {

  const { t } = useTranslation("labs");

  const onLabSelect = (id) => {
    onChange(createEvent("type", id));
  };

  return (
    <Grid
      container
      px={{ xs: 0, md: 3 }}
      direction="column"
      alignItems="flex-start"
    >
      <PageTitle>{t("Select your Lab Type")}</PageTitle>
      <Grid
        container
        rowSpacing={4}
        px={{ xs: 0, md: 3 }}
        mt={3}
        justifyContent="center"
      >
        {lab_types.map((lab, index) => (
          <Grid item xs={12} md={6} key={index}>
            <LabTypeCard
              lab={lab}
              onClick={onLabSelect}
              id={lab.id}
              selected={type === lab.id}
              t={t}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default LabTypes;

const LabTypeCard = ({ lab, id, onClick, selected, t }) => {
  const { title, description, icon } = lab;
  return (
    <Card
      width="min(95%,300px)"
      centered
      classes={`minh-300 p-2 column-center text-center labtype__card ${selected && "selected"
        }`}
      onClick={() => onClick(id)}
    >
      {icon}
      <h2 className="mt-4 c-primary">{t(title)}</h2>
      <p className="fs-14 opacity-70 mt-4">{t(description)}</p>
    </Card>
  );
};
