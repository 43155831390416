import { useDispatch, useSelector } from "react-redux";
import { REDUCER_TYPES } from "../index";
import REQUEST from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import ACTIONS from "./action-types";
import { REDUCER } from "./reducer";
import { CONTENT_TYPES } from "store/api";
import { serializeObject } from "store/api/utils";

const BASE_URL = ENDPOINTS.COMMON_MASTER;

const useMaster = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state[REDUCER_TYPES.MASTER]);
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  const headers = {
    Authorization: "Bearer " + token,
  };

  const fetchValues = async (search = "", endpoint, action) => {
    const client = new REQUEST();
    const res = await client.get(
      `${BASE_URL}/${endpoint}?${
        typeof search === "object"
          ? serializeObject(search)
          : `search=${search}`
      }&language=${language}`,
      {},
      {},
      () => {},
      () => {},
      true
    );
    if (res.data) {
      dispatch(REDUCER[action](res.data.data));
      return res.data.data;
    }
  };

  const fetchSocialLinks = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.SOCIAL_LINK,
      ACTIONS.SOCIAL_LINKS
    );
    return res;
  };

  const fetchLabConditions = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.LAB_CONDITION,
      ACTIONS.LAB_CONDITIONS
    );
    return res;
  };

  const fetchPitchTemplates = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.PITCH_TEMPLATE,
      ACTIONS.PITCH_TEMPLATE
    );
    return res;
  };

  const fetchFlexibleDDs = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.FLEXIBLE_DATE_DURATION,
      ACTIONS.FLEXIBLE_DATE_DURATION
    );
    return res;
  };

  const fetchHosts = async (search = "") => {
    const res = await fetchValues(search, ENDPOINTS.HOST, ACTIONS.HOST);
    return res;
  };

  const fetchAchievementConditions = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.ACHIEVEMENT_CONDITION,
      ACTIONS.ACHEIVEMENT_CONDITIONS
    );
    return res;
  };

  const fetchProjectSubmissions = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.PROJECT_SUBMISSION,
      ACTIONS.PROJECT_SUBMISSION
    );
    return res;
  };

  const fetchSkillGroups = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.SKILL_GROUP,
      ACTIONS.SKILL_GROUPS
    );
    return res;
  };

  const fetchRanks = async (search = "") => {
    const res = await fetchValues(search, ENDPOINTS.RANKS, ACTIONS.RANKS);
    return res;
  };

  const fetchSkillSets = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.SKILL_SET,
      ACTIONS.SKILL_SETS
    );
    return res.filter((d) => Boolean(d?.title));
  };

  const fetchCategories = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.CATEGORIES,
      ACTIONS.CATEGORIES
    );
    return res;
  };

  const fetchSkills = async (search = "") => {
    const res = await fetchValues(search, ENDPOINTS.SKILLS, ACTIONS.SKILLS);
    return res;
  };

  const fetchTags = async (search = "") => {
    const res = await fetchValues(search, ENDPOINTS.TAGS, ACTIONS.TAGS);
    return res;
  };

  const fetchIndustries = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.INDUSTRIES,
      ACTIONS.INDUSTRIES
    );
    return res;
  };

  const fetchTypes = async (search = "") => {
    const res = await fetchValues(search, ENDPOINTS.TYPES, ACTIONS.TYPES);
    return res;
  };

  const fetchStages = async (search = "") => {
    const res = await fetchValues(search, ENDPOINTS.STAGES, ACTIONS.STAGES);
    return res;
  };

  const fetchVerticals = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.VERTICALS,
      ACTIONS.VERTICALS
    );
    return res;
  };

  const fetchProjectStatus = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.PROJECT_STATUS,
      ACTIONS.PROJECT_STATUS
    );
    return res;
  };

  const fetchSocialConnect = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.SOCIAL_CONNECT,
      ACTIONS.SOCIAL_CONNECT
    );
    return res;
  };

  const fetchDurations = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.DURATIONS,
      ACTIONS.DURATION
    );
    return res;
  };

  const fetchLevel = async (search = "") => {
    const res = await fetchValues(search, ENDPOINTS.LEVELS, ACTIONS.LEVEL);
    return res;
  };

  const fetchTagGroups = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.TAG_GROUPS,
      ACTIONS.TAG_GROUPS
    );
    return res;
  };

  const fetchChallengeRecipient = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.ANNOUNCEMENT_RECIPIENT,
      ACTIONS.ANNOUNCEMENT_RECIPIENT
    );
    return res;
  };

  const fetchChallengePitchTask = async (templateId, search) => {
    if (!templateId) return;
    const client = new REQUEST();
    const res = await client.get(
      `${BASE_URL}/${ENDPOINTS.CHALLENGE_PITCH_TASK}?search=${search}&template_id=${templateId}&language=${language}`
    );
    if (res.data) {
      return res.data.data;
    }
  };

  const createSponser = async (title, link, image) => {
    if (!title || !link || !image) return;
    const client = new REQUEST();
    const res = await client.post(
      `${BASE_URL}/${ENDPOINTS.CREATE_SPONSER}?&language=${language}`,
      { title, link, image },
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      }
    );
    return res;
  };

  const fetchJobs = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.JOB_TITLES,
      ACTIONS.JOB_TITLES
    );
    return res;
  };

  const fetchCampusConnectSchools = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CAMPUS_CONNECT}/${ENDPOINTS.SCHOOLS}?language=${language}`,
      {},
      {
        ...headers,
      }
    );

    return res?.data?.data;
  };

  const fetchBusinessChallengeTacklings = async (search = "") => {
    const res = await fetchValues(
      search,
      ENDPOINTS.BUSINESS_CHALLENGE_TACKLINGS,
      ACTIONS.BUSINESS_CHALLENGE_TACKLINGS
    );
    return res;
  };

  return {
    state,
    fetchSocialLinks,
    fetchLabConditions,
    fetchPitchTemplates,
    fetchFlexibleDDs,
    fetchHosts,
    fetchRanks,
    fetchAchievementConditions,
    fetchProjectSubmissions,
    fetchSkillGroups,
    fetchSkillSets,
    fetchCategories,
    fetchSkills,
    fetchTags,
    fetchIndustries,
    fetchTypes,
    fetchStages,
    fetchVerticals,
    fetchProjectStatus,
    fetchSocialConnect,
    fetchDurations,
    fetchLevel,
    fetchChallengePitchTask,
    createSponser,
    fetchTagGroups,
    fetchChallengeRecipient,
    fetchJobs,
    fetchCampusConnectSchools,
    fetchBusinessChallengeTacklings,
  };
};

export default useMaster;
