import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import { mockRequestGet, mockRequestPost } from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import UserOnboarding from "./service-hook";
import { CONTENT_TYPES } from "store/api";
import { BrowserRouter } from "react-router-dom";

let postSpy;
let getSpy;

let resume = "1";
let jobs = "1";
let orgId = "1";

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
  [REDUCER_TYPES.ONBOARDING]: {
    startPage: null,
  },
});

const OnboardingServicesMock = () => {
  const {
    resumeParser,
    completeOnboarding,
    addJobs,
    completeOrgOnboarding,
    startPage,
    completeChallengeMiniOnboarding,
    completeLabMiniOnboarding,
    completeOrgMiniOnboarding,
  } = UserOnboarding();

  const BUTTON_LINKS = [
    { name: "resumeParser", onClick: () => resumeParser(resume) },
    { name: "completeOnboarding", onClick: () => completeOnboarding() },
    { name: "addJobs", onClick: () => addJobs(jobs) },
    {
      name: "completeOrgOnboarding",
      onClick: () => completeOrgOnboarding(orgId, {}),
    },
    { name: "startPage", onClick: () => startPage() },
    {
      name: "completeChallengeMiniOnboarding",
      onClick: () => completeChallengeMiniOnboarding(),
    },
    {
      name: "completeLabMiniOnboarding",
      onClick: () => completeLabMiniOnboarding(),
    },
    {
      name: "completeOrgMiniOnboarding",
      onClick: () => completeOrgMiniOnboarding(),
    },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Onboarding services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <OnboardingServicesMock />
        </BrowserRouter>
      </Provider>
    );

    postSpy = mockRequestPost();
    getSpy = mockRequestGet();
  });

  test("resumeParser", async () => {
    const button = screen.getByTestId("resumeParser");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PROFILE}/${ENDPOINTS.RESUME}/${ENDPOINTS.UPLOAD}?language=en`,
        { language: "en", resume: "1" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("completeOnboarding", async () => {
    const button = screen.getByTestId("completeOnboarding");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.USER}/${ENDPOINTS.COMPLETE_ONBOARDING}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("addJobs", async () => {
    const button = screen.getByTestId("addJobs");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CAREER}/multiple/${ENDPOINTS.ADD}?language=en`,
        { language: "en", job_ids: "1" },
        headers,
        expect.any(Function)
      )
    );
  });

  test("completeOrgOnboarding", async () => {
    const button = screen.getByTestId("completeOrgOnboarding");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.USER}/${ENDPOINTS.COMPLETE_ONBOARDING}/${orgId}?language=en`,
        { language: "en" },
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("startPage", async () => {
    const button = screen.getByTestId("startPage");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.START_PAGE}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("completeChallengeMiniOnboarding", async () => {
    const button = screen.getByTestId("completeChallengeMiniOnboarding");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.USER}/${ENDPOINTS.COMPLETE_MINI_ONBOARDING}/${ENDPOINTS.CHALLENGE}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("completeLabMiniOnboarding", async () => {
    const button = screen.getByTestId("completeLabMiniOnboarding");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.USER}/${ENDPOINTS.COMPLETE_MINI_ONBOARDING}/${ENDPOINTS.LAB}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("completeOrgMiniOnboarding", async () => {
    const button = screen.getByTestId("completeOrgMiniOnboarding");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.USER}/${ENDPOINTS.COMPLETE_MINI_ONBOARDING}/${ENDPOINTS.ORGANIZATION}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });
});
