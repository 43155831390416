import { Upload } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import Checkbox, { CheckboxGroup } from "components/Input/Checkbox";
import DateInput from "components/Input/DateInput";
import DateTimeInput from "components/Input/DateTimeInput";
import DropBox from "components/Input/DropBox";
import FileButton from "components/Input/FileButton";
import Radio from "components/Input/Radio";
import Select from "components/Input/Select";
import TextBox from "components/Input/Textbox";
import { createEvent } from "helpers/utils/utilities";
import React from "react";
import NetworkUsersSelect from "../molecules/NetworkUsersSelect";
import GooglePlacesSelect from "./GooglePlacesSelect";
import MasterSelect from "./MasterSelect";
import FileUploader from "components/FileUploader";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import Switch from "components/Input/Switch";
import Editor from "components/Input/Editor";
import { LockIcon } from "components/Statics";
import {
  CHARACTER_VALIDATOR,
  EMAIL_VALIDATOR,
  NUMBER_VALIDATOR,
  PASSWORD_VALIDATOR,
  REQUIRED_VALIDATOR,
  TITLE_VALIDATOR,
} from "helpers/utils/validators";
import cx from "classnames";

export const isRequired = (validator) =>
  Boolean(
    validator === REQUIRED_VALIDATOR ||
      validator === CHARACTER_VALIDATOR ||
      validator === EMAIL_VALIDATOR ||
      validator === PASSWORD_VALIDATOR ||
      validator === NUMBER_VALIDATOR ||
      validator === TITLE_VALIDATOR
  );

const SchemaUIExtractor = ({
  schema,
  data,
  errors,
  onChange,
  getSelectValues = Function.prototype,
  getFilters = () => {},
  template,
  disabled,
  dataKey,
  classes,
  align,
  mb = 2,
  mt = 1,
  hideAsterisk,
}) => {
  const { t } = useLocalizedTranslation();

  return (
    <Grid
      className={classes}
      container
      spacing={2}
      mt={mt}
      mb={mb}
      alignItems={align}
    >
      {schema.map(
        ({
          title,
          name,
          type,
          preInput,
          validator,
          lg,
          description,
          option,
          options,
          dropboxProps = {},
          multi,
          image,
          imageBG,
          maxLength,
          label,
          placeholder,
          inputProps = {},
          ...rest
        }) => (
          <Grid key={name} item xs={12} lg={lg || 6}>
            {type === "input" ? (
              <Input
                title={title}
                required={isRequired(validator)}
                description={description}
                name={name}
                classes="w-full"
                preInput={preInput}
                width="99%"
                value={data[name] || ""}
                error={errors.get(name)}
                onChange={onChange}
                disabled={disabled}
                locked={rest.locked}
                {...inputProps}
                {...rest}
                hideAsterisk={hideAsterisk}
                t={t}
              />
            ) : type === "switch" ? (
              <Switch
                name={name}
                value={data[name]}
                label={label}
                error={errors.get(name)}
                onChange={onChange}
                title={title}
                t={t}
              />
            ) : type === "date" ? (
              <DateInput
                title={title}
                description={description}
                required={!!validator}
                name={name}
                classes="w-full"
                width="99%"
                value={data[name]}
                error={errors.get(name)}
                onChange={onChange}
                {...rest}
                t={t}
              />
            ) : type === "time" ? (
              <DateTimeInput
                title={title}
                description={description}
                required={!!validator}
                name={name}
                classes="w-full"
                width="99%"
                value={data[name]}
                error={errors.get(name)}
                onChange={onChange}
                {...rest}
                t={t}
              />
            ) : type === "textbox" ? (
              <TextBox
                title={title}
                required={!!validator}
                classes="w-full"
                name={name}
                width="99%"
                height={250}
                value={data[name] || ""}
                error={errors.get(name)}
                onChange={onChange}
                maxLength={maxLength}
                placeholder={placeholder}
                t={t}
                {...rest}
              />
            ) : type === "editor" ? (
              <Editor
                title={title}
                required={!!validator}
                classes="w-full"
                name={name}
                width="99%"
                value={data[name]}
                error={errors.get(name)}
                onChange={(val) => onChange(createEvent(name, val))}
                placeholder={placeholder}
                locked={rest.locked}
                t={t}
                {...rest}
              />
            ) : type === "select" ? (
              <Select
                title={title}
                description={description}
                required={!!validator}
                borderedIcon
                name={name}
                classes="w-full"
                width="99%"
                value={data[name]}
                keyValues={getSelectValues(name) || options || []}
                error={errors.get(name)}
                onChange={onChange}
                multi={multi}
                placeholder={placeholder}
                {...rest}
                t={t}
              />
            ) : type === "file" || type === "uppy" ? (
              <div className="editOrg__imageDropCard">
                <h3>
                  {t(title, "common")}{" "}
                  {isRequired(validator) && <span className="c-error">*</span>}
                </h3>
                <p className={cx(rest.descriptionClasses)}>
                  {t(description, "common")}
                </p>
                {rest?.description2 && (
                  <p className={cx(rest.descriptionClasses)}>
                    {t(rest?.description2, "common")}
                  </p>
                )}
                {type === "uppy" ? (
                  <FileUploader
                    classes="mt-3"
                    name={name}
                    files={data[name]}
                    setFiles={(val) => onChange(createEvent(name, val))}
                    {...rest}
                    t={t}
                  />
                ) : (
                  <>
                    <DropBox
                      classes="mt-3"
                      {...dropboxProps}
                      name={name}
                      file={data[name]}
                      iframeCode={data[name]}
                      setIframeCode={(val) =>
                        onChange(createEvent(dropboxProps?.video_code, val))
                      }
                      error={errors.get(name)}
                      setFile={(val) => onChange(createEvent(name, val))}
                      prevValue={dataKey ? data[dataKey] : null}
                      {...rest}
                    />
                  </>
                )}
              </div>
            ) : type === "radio" || type === "checkbox" ? (
              <>
                {title && (
                  <p className="fw-500">
                    {t(title)}{" "}
                    {!!validator && <span className="c-error">*</span>}
                    {rest.locked && <LockIcon />}
                  </p>
                )}
                {description && (
                  <p className="fw-500 fs-14 opacity-90">
                    {t(description)}{" "}
                    {!!validator && !title && (
                      <span className="c-error">*</span>
                    )}
                  </p>
                )}
                {type === "radio" ? (
                  <Radio
                    name={name}
                    error={errors.get(name)}
                    onChange={onChange}
                    options={options}
                    value={data[name]}
                    t={t}
                    {...rest}
                  />
                ) : options?.length ? (
                  <CheckboxGroup
                    name={name}
                    onChange={(val) => onChange(createEvent(name, val))}
                    options={options}
                    error={errors.get(name)}
                    value={data[name]}
                    t={t}
                    {...rest}
                  />
                ) : (
                  <Checkbox
                    name={name}
                    onChange={(val) => onChange(createEvent(name, val))}
                    option={option}
                    error={errors.get(name)}
                    checked={Boolean(data[name])}
                    image={image}
                    imageBG={imageBG}
                    t={t}
                    {...rest}
                  />
                )}
              </>
            ) : type === "master" ? (
              <MasterSelect
                title={title}
                description={description}
                required={!!validator}
                borderedIcon
                name={name}
                width="99%"
                value={data[name]}
                error={errors.get(name)}
                onChange={onChange}
                multi={multi}
                filters={getFilters(name)}
                template={template}
                placeholder={placeholder}
                {...rest}
                t={t}
              />
            ) : type === "google-places" ? (
              <GooglePlacesSelect
                title={title}
                required={!!validator}
                name={name}
                classes="w-full"
                width="99%"
                value={data[name]}
                error={errors.get(name)}
                setAddress={onChange}
              />
            ) : type === "file-button" ? (
              <FileButton
                error={errors.get(name)}
                onChange={(file) => onChange(createEvent(name, file))}
                value={data[name]}
                multi={multi}
                prevValue={dataKey ? data[dataKey] : ""}
                {...rest}
              >
                <Button disabled={rest?.disabled} bordered>
                  <Upload fontSize="small" />
                  &nbsp;&nbsp; {t(title)}{" "}
                  {!!validator && <span className="c-error"> *</span>}
                </Button>
              </FileButton>
            ) : (
              type === "network_users" && (
                <NetworkUsersSelect
                  title={title}
                  required={!!validator}
                  name={name}
                  classes="w-full"
                  users={data[name]}
                  error={errors.get(name)}
                  setUsers={(value) => onChange(createEvent(name, value))}
                  t={t}
                  {...rest}
                />
              )
            )}
          </Grid>
        )
      )}
    </Grid>
  );
};

export default SchemaUIExtractor;
