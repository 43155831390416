import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import useResourceCollections from "store/resource-collections/service-hook";
import ResourceCollectionMainPage from "./components/ResourceCollectionMainPage";
import { useParams, useSearchParams } from "react-router-dom";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const ResourceCollection = () => {
  const [resourceC, setResourceC] = useState(null);
  const { id } = useParams();
  const {
    fetchResourceCollection,
    fetchMyResourceCollection,
    deleteResourceCollection,
  } = useResourceCollections();
  const [params] = useSearchParams();
  const isPrivateView = params.get("private") === "true";
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    handleFetchDetail();
  }, []);

  const handleFetchDetail = async () => {
    const res = isPrivateView
      ? await fetchMyResourceCollection(id)
      : await fetchResourceCollection(id);
    if (!res) {
      setResourceC({ error: true });
      return;
    }
    setResourceC(res);
  };

  return (
    <DashboardLayout
      loading={!resourceC}
      isEmpty={!!resourceC?.error}
      emptyMessage={t("Not able to access the resource collection!")}
    >
      <ResourceCollectionMainPage
        resourceC={resourceC}
        isPrivate={isPrivateView}
        setResourceC={setResourceC}
        onDelete={deleteResourceCollection}
        t={t}
      />
    </DashboardLayout>
  );
};

export default ResourceCollection;
