import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import ResourceModuleMainPage from "./components/ResourceModuleMainPage";
import { useParams, useSearchParams } from "react-router-dom";
import useResources from "store/resources/service-hook";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useAuth from "store/auth/service-hook";

const ResourceModule = () => {
  const [resource, setResources] = useState(null);
  const { id } = useParams();
  const { fetchResource, fetchMyResource } = useResources();
  const [params, setParams] = useSearchParams();
  const isPrivateView = params.get("private") === "true";
  const { t } = useLocalizedTranslation();
  const {
    state: { user, isLoggedIn },
  } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      params.delete("private");
      setParams(params);
    }
    handleFetchDetail();
  }, [id]);

  const handleFetchDetail = async () => {
    const res = (isPrivateView && isLoggedIn) 
      ? await fetchMyResource(id)
      : await fetchResource(id);
    if (!res) {
      setResources({ error: true });
      return;
    }
    setResources(res);
  };

  return (
    <DashboardLayout
      loading={!resource}
      isEmpty={!!resource?.error}
      emptyMessage={t("Not able to access the resource!")}
    >
      <ResourceModuleMainPage
        resource={resource}
        isPrivate={isPrivateView}
        setResource={setResources}
        t={t}
        user={user}
        isLoggedIn={isLoggedIn}
      />
    </DashboardLayout>
  );
};

export default ResourceModule;
