import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import { plansList } from "./data";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import { formatFeatureValue } from "./ComparePlans";
import GetAddonsButton from "components/UI/molecules/GetAddonsButton";

const AddOns = ({ t }) => {
  const width = useWindowWidth();
  const onTab = useMemo(() => width < 900, []);

  return (
    <div className="my-12">
      <h1 className="text-center">
        {t("Tailor your plan with add-on features")}
      </h1>
      <Grid container className="flex justify-center mt-8">
        <Grid item xs={11} mb={2}>
          <div className="w-full flex justify-end">
            <GetAddonsButton />
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
        {[{}, ...plansList.slice(0, -1)].map((plan, i) => (
          <Grid
            key={i}
            item
            xs={onTab ? (i === 0 ? 4 : 1.5) : 2}
            className={`flex justify-center ${
              i !== 0 && "bg-white border-gray no-border-bottom"
            } ${i < plansList.length - 1 && "no-border-right"}`}
          >
            <div
              className={`flex-column  px-2 py-3 relative items-center justify-between w-100p ${
                onTab ? "minh-100" : "minh-60"
              }`}
            >
              {i > 0 &&
                (onTab ? (
                  <div className="flex items-center h-100p justify-center">
                    <h3 className="fs-14 text-center rotate-90 text-nowrap">
                      {t(plan?.title, "common")}
                    </h3>
                  </div>
                ) : (
                  <div className="flex-center">
                    <img
                      src={plan?.icon}
                      className="w-30 h-25 mr-1"
                      alt={`icon of ${plan?.title}`}
                    />
                    <h3>{t(plan?.title, "common")}</h3>
                  </div>
                ))}
            </div>
          </Grid>
        ))}
      </Grid>
      <Grid container className="flex justify-center">
        {[
          {
            title: "HR Integrations (Add On)",
            description:
              "Seamlessly invite team members from your existing HR system directly to PreprLabs.",
            seed: false,
            price: 499,
          },
          {
            title: "Career Explorer (Add On)",
            description:
              "Save favorite skills and jobs, and explore challenges or labs related to your interests.",
            seed: false,
            price: 999,
          },
          {
            title: "AI-Assisted Content Creation (Add On)",
            description:
              "Get intelligent recommendations and streamline your content creation process with the power of AI, making your work faster and more efficient!",
            seed: false,
            price: 1999,
          },
        ]?.map((feature, index) => (
          <Grid item xs={10} key={index}>
            <Grid container>
              <Grid
                item
                xs={onTab ? 4.8 : 2.4}
                className={`flex-column justify-center bg-gray px-2 h-80 border-gray no-border-right ${
                  index < 2 && "no-border-bottom"
                }`}
              >
                <h5
                  className="text-truncate fs-13--mob"
                  title={t(feature?.title)}
                >
                  {t(feature?.title)}
                </h5>
                <p
                  className="fs-12 c-gray text-truncate-2"
                  title={feature?.description}
                >
                  {t(feature?.description)}
                </p>
              </Grid>
              {["seed", "rest"].map((planType, i) => (
                <Grid
                  item
                  xs={onTab ? 1.8 : planType === "seed" ? 2.4 : 7.2}
                  key={i}
                  className={`border-gray ${i === 0 && "no-border-right"} ${
                    index < 2 && "no-border-bottom"
                  }  flex-center justify-center`}
                  sx={{ backgroundColor: "#FFFBEF" }}
                >
                  <p className="fw-600 fs-17 fs-12--mob text-center text-truncate px-1">
                    {planType === "seed" ? (
                      formatFeatureValue(false)
                    ) : (
                      <div className="flex-center mx-auto justify-center">
                        $<h2 className="fw-700 fs-34">{feature.price}</h2>
                        {t("/year")}
                      </div>
                    )}
                  </p>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default AddOns;
