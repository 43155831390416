import { Search } from "@mui/icons-material";
import { Grid, Chip, Box } from "@mui/material";
import ProductCard from "../../../components/Cards/ProductCard";
import Input from "../../../components/Input";
import FilterCard from "../../../components/UI/organisms/FilterCard";
import useFilters, { FILTER_TYPES } from "../../../helpers/hooks/useFilters";
import React, { useEffect } from "react";
import { useState } from "react";
import Button from "../../../components/Button";
import ModalLayout from "../../../components/Modal";
import useChallenges from "../../../store/challenges/service-hook";
import ListingContainer from "../../../components/UI/organisms/ListingContainer";
import useRedirect from "../../../helpers/hooks/useRedirect";
// import { MAX_CREDITS } from "pages/labs/LabMarketplace";
// import AlertMessage from "components/UI/atoms/AlertMessage";
import SecureImage from "../../../components/UI/atoms/SecureImage";
import { isTrue } from "../../../helpers/utils/validators";
import { useLocalizedTranslation } from "../../../helpers/hooks/useLocalizedTranslation";
import { challengePath } from "assets/png/placeholders";

const ChallengeTemplate = ({ open, onClose }) => {
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const { selected, setSelected } = useFilters();
  const { fetchTemplates, redeemTemplate } = useChallenges();
  const { t } = useLocalizedTranslation("common");

  const {
    navigateChallengeTemplate,
    navigateCreateChallengeAI,
    navigateCreateChallenge,
  } = useRedirect();

  useEffect(() => {
    handleLoadTemplates();
  }, [search, page, selected]);

  const handleLoadTemplates = async () => {
    setLoading(true);
    const filters = {
      search: search,
      page: page,
      ...selected,
    };
    const res = await fetchTemplates(filters);
    setTemplates(res?.list || []);
    setLoading(false);
    setPages(res?.total_pages || 1);
    setPage(res?.current_page || 1);
  };

  const handleRedeemTemplate = async (id, noToast, redirect) => {
    await redeemTemplate(id, Boolean(noToast), redirect);
    setTemplates((prev) =>
      prev.map((d) => ({
        ...d,
        is_redeemed: id === d?.slug ? "yes" : d?.is_redeemed,
      }))
    );
    onClose();
  };

  const handleSearchClick = () => setSearch(searchType);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchType(inputValue);
    if (inputValue === "") setSearch("");
  };

  const navigateDetail = (slug) => {
    navigateChallengeTemplate(slug, "templates");
    onClose();
  };

  return (
    <ModalLayout open={open} onClose={onClose} isWide wrapperClasses="pb-0">
      <h2 className="mt-1">{t("Select challenge template", "common")}</h2>
      <Grid
        container
        className="mt-1"
        spacing={1}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} md={6} lg={5} className="flex">
          <Input
            icon={<Search />}
            width="100%"
            iconLeft
            value={searchType}
            onChange={handleInputChange}
            placeholder={t("Find more templates", "common")}
          />
          <Button classes="ml-2" onClick={handleSearchClick}>
            {t("Search", "common")}
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        mt={2}
        spacing={1}
        className="overflow-hidden border-t pt-2"
        sx={{ height: { xs: "auto", md: "65vh" } }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          lg={3}
          pb={2}
          className="h-full overflowY-auto"
        >
          <Box sx={{ width: { xs: "100%", sm: "93%" } }}>
            <FilterCard
              title={t("Filters")}
              types={[
                FILTER_TYPES.SKILLS,
                FILTER_TYPES.CATEGORY,
                FILTER_TYPES.DURATION,
                FILTER_TYPES.LEVEL,
              ]}
              clearFilters={true}
              onClearPressed={() => setSelected({})}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          lg={9}
          pb={2}
          className="h-full border-l overflowY-auto"
        >
          {/* {selectedTemplates.length > MAX_CREDITS && (
            <AlertMessage
              title="You have used all your Lab credits."
              description="Please purchase more credits or upgrade your existing plan. If you do not have permission to change your plan, contact your organization administrator."
              severity="error"
              classes={"my-t mb-4"}
            />
          )} */}
          <ListingContainer
            rowLoader
            rowSpacing={2}
            isEmpty={!templates || !templates.length}
            loading={loading}
            count={pages}
            page={page}
            setPage={setPage}
            emptyText={t("No templates found", "common")}
          >
            {templates.map((d, i) => (
              <ProductCard classes={"h-full"} key={d?.slug}>
                <TemplateCardContent
                  hoveredCardIndex={hoveredCardIndex}
                  i={i}
                  navigateDetail={navigateDetail}
                  setHoveredCardIndex={setHoveredCardIndex}
                  template={d}
                  handleRedeem={handleRedeemTemplate}
                  setModalClose={onClose}
                />
              </ProductCard>
            ))}
          </ListingContainer>
        </Grid>
      </Grid>
      <Box
        className={`flex items-center flex-colOnMob justify-center px-1 py-2 bg-gray w-100p fw-500 items-center px-3  border-t`}
      >
        <span className="fs-14 my-1">
          {t("Don’t think the templates are good fit? Try", "common")}
        </span>
        <Button
          height="40px"
          onClick={navigateCreateChallengeAI}
          width="250px"
          classes="mx-4 px-2 my-1"
        >
          <span className="text-truncate fs-12--mob my-1">
            {t("Create From ChallengeBuilderAI", "common")}
          </span>
        </Button>
        <span className="text-center">{t("or", "common")}</span>
        <Button
          height="40px"
          onClick={navigateCreateChallenge}
          width="200px"
          classes="mx-4 my-1"
        >
          <span className="text-truncate fs-12--mob">
            {t("Create From Scratch", "common")}
          </span>
        </Button>
      </Box>
    </ModalLayout>
  );
};

export default ChallengeTemplate;

const TemplateCardContent = ({
  setHoveredCardIndex,
  hoveredCardIndex,
  i,
  navigateDetail,
  template,
  handleRedeem,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useLocalizedTranslation();

  const onClickUse = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    await handleRedeem(template?.slug, false, true);
    setLoading(false);
  };

  return (
    <div
      onMouseEnter={() => setHoveredCardIndex(i)}
      onMouseLeave={() => setHoveredCardIndex(null)}
      className="h-full"
    >
      <div className="relative h-150">
        {(hoveredCardIndex === i || loading) && (
          <div
            className="flex-column justify-center items-center w-100p h-100p absolute rounded-10 z-10"
            style={{ backgroundColor: "#222222b2", zIndex: 99 }}
          >
            <Button
              classes="bg-white c-primary w-130"
              onClick={() => navigateDetail(template?.slug)}
            >
              {t("Preview")}
            </Button>
            <Button
              disabled={isTrue(template?.is_redeemed)}
              loading={loading}
              onClick={onClickUse}
              classes="w-130 mt-4"
              color={isTrue(template?.is_redeemed) ? "green" : "blue"}
            >
              {isTrue(template?.is_redeemed)
                ? t("Redeemed")
                : t("Use Template")}
            </Button>
          </div>
        )}
        <SecureImage
          src={template?.media}
          placeholder={challengePath}
          className="h-150 w-100p rounded-5 bg-gray rounded-10"
          alt={template?.title}
          title={template?.title}
        />
      </div>
      <h4 className="text-truncate mt-1">
        {!!template.title ? template?.title : "N/A"}
      </h4>
      <p
        className={`fs-13 opacity-80 description maxw-100p overflow-hidden`}
        dangerouslySetInnerHTML={{ __html: template?.description ?? "N/A" }}
      ></p>
      <p className="fs-12 tracking-tight my-1 opacity-80 text-truncate">
        {t("Hosted By")}:{" "}
        <span className="fw-700">{template?.organization}</span>
      </p>
      <div className="fs-12 tracking-tight flex mt-1 opacity-80">
        <p className="mr-1 text-truncate" title={template?.duration}>
          {t("Duration")}: <span className="fw-700">{template?.duration}</span>
        </p>
        <p className="text-truncate" title={template?.level}>
          {t("Level")}: <span className="fw-700">{template?.level}</span>
        </p>
      </div>
      <div className="text-truncate">
        {Object.keys(template?.skills).length > 0 ? (
          Object.values(template?.skills).map((skill, index) => (
            <Chip
              key={index}
              label={skill}
              className="mt-1 mr-1"
              sx={{ height: 24, fontSize: "12px" }}
            />
          ))
        ) : (
          <Chip
            label="N/A"
            className="mt-1"
            sx={{ height: 24, fontSize: "12px" }}
          />
        )}
      </div>
    </div>
  );
};
