import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Pagination } from "@mui/material";
import { leaf4 } from "assets/svg/green";
import Spin from "components/Spin";
import { useTranslation } from "react-i18next";

const DataTable = ({
  columns,
  size,
  data,
  page,
  emptyMessage,
  totalPages,
  perPage,
  onPageChange,
  loading,
  emptyText,
  totalCount,
  noFirstColCenter,
}) => {
  const { t } = useTranslation("common");
  return (
    <>
      <TableContainer data-testid="table">
        <Table size={size ?? "small"}>
          <TableHead>
            <TableRow>
              {columns.map((col, i) => (
                <TableCell
                  key={i}
                  className={`text-nowrap ${
                    i === 0 && !noFirstColCenter ? "text-center" : null
                  }`}
                >
                  {col.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={12} className="flex h-300 text-center">
                  <Spin blue big />
                </TableCell>
              </TableRow>
            ) : data && data.length ? (
              data.map((item, i) => (
                <TableRow key={i}>
                  {columns.map((col, index) => {
                    return (
                      <TableCell className={"py-2"} key={index}>
                        {col.render
                          ? col.render(item[col.dataIndex], i, item)
                          : item[col.dataIndex] ?? "-"}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns?.length}
                  className="text-center h-300 flex items-center"
                >
                  {emptyText ? (
                    emptyText
                  ) : (
                    <>
                      <img src={leaf4} alt="leaf" height={100} />
                      <p>{emptyMessage ?? t("No Records found!")}</p>
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages && totalCount ? (
        <div className="flex items-center justify-between h-20 mx-4">
          <p className="fs-14 mt-2">
            <b>
              {page === 1 ? 1 : (page - 1) * 10 + 1} -{" "}
              {page === totalPages ? totalCount : perPage}
            </b>{" "}
            of <b>{totalCount}</b>
          </p>

          <Pagination
            className={"mt-3"}
            classes={{
              ul: "justify-end",
            }}
            size={"small"}
            count={totalPages}
            onChange={(e, newValue) => onPageChange(newValue)}
            page={page}
            perPage={perPage}
          />
        </div>
      ) : null}
    </>
  );
};

export default DataTable;
