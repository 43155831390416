import React from 'react';
import { CalendarMonth, Extension, Visibility } from '@mui/icons-material';
import { Avatar, Chip } from '@mui/material';
import Card from 'components/Cards/Card';
import IconText from 'components/UI/atoms/IconText';
import TrophyChip from 'components/UI/atoms/TrophyChip';
import smallLogo from '../../../assets/svg/small-logo.svg';

const LabLeftDescCard = () => {
    return (
        <Card classes="mt-2">
            <h2>Workplace Culture</h2>
            <IconText icon={<Visibility />} text="Public Lab" />
            <IconText icon={<CalendarMonth />} text="Last Active 5 days ago" />
            <IconText icon={<Extension />} text="Contains 6 Challenges" />
            <h3 className='mt-4'>Description</h3>
            <p className='w-80p fs-12 mt-2 ml-1'>Welcome to the PreprLabs Network! Use this Lab to learn how to navigate the platform and get the most out of your upskilling experience.</p>
            <h3 className='mt-4'>Achievements</h3>
            <TrophyChip title="Lab Trophy" subTitle="Lab Trophy" points={100} />
            <h3 className='mt-4'>Skills</h3>
            <div className='flex items-center flex-wrap'>
                {
                    ["Graphic Design", "Teamwork", "Graphic Design", "Teamwork"].map((title, i) =>
                        <Chip key={i} label={title} variant="outlined" className='mr-1 mt-1' />
                    )
                }
            </div>
            <h3 className='mt-2'>Tags</h3>
            <div className='flex items-center flex-wrap'>
                {
                    ["Prepr", "preprLabs"].map((title, i) =>
                        <Chip key={i} label={title} variant="outlined" className='mr-1 mt-1' />
                    )
                }
            </div>
            <h3 className='mt-4'>Hosted by</h3>
            <div className='flex mt-2'>
                <Avatar src={smallLogo} sx={{ height: 56, width: 56 }} />
                <div className='ml-2'>
                    <p><b>Prepr</b></p>
                    <p className='fs-12 opacity-90'>Prepr connects students and start-ups to employees and employers in order to build skills, create verifiable portfolios and tackle challenges. We do this by creating dedicated labs for individuals and teams to co-learn and co-lab together in order to co-solve organizational challenges.</p>

                    <p className='link fs-14 mt-3'> More events by Prepr</p>
                </div>
            </div>
        </Card>
    )
}

export default LabLeftDescCard