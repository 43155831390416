import { render, waitFor } from "@testing-library/react";
import useChallengeReport from "./service-hooks";
import {
    mockRequestGet,
  } from "../../mocks/request";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { initStore } from "../../mocks/store";
import { REDUCER_TYPES } from "../../index";
import userEvent from "@testing-library/user-event";
import { ENDPOINTS } from "store/api/endpoints";




let getSpy;


let challengeId = "no-poverty-challenge";
let projectId = "title-castle-rock"


const headers = {
    Authorization: "Bearer 1",
};

const store = initStore({
    [REDUCER_TYPES.AUTH]: {
      language: "en",
      token: 1,
      user: { id: 1, email: "test@gmail.com" },
    },
    [REDUCER_TYPES.MASTER]: {},
  });



const ReportsChallengesServicesMock = () => {
    const {
        challengeMemberProgress,
        challengePathMemberProgress,
        challengeReportDetails,
        challengeLabs,
        challengeLabPrograms,
        challengeResourceModules,
        challengeResourceGroup,
        challengeResourceCollection,
        challengeAssociatedProjectStats,
        challengeEngagement,
        challengeMembers,
        ChallengeAchievementDetails,
        ChallengeAssessmentReports,
        challengeAssessmentProjectDetails,
        challengeMemberActivity,
        downloadChallengeReport
    } = useChallengeReport();
  
    const BUTTON_LINKS = [
      { name: "challengeMemberProgress", onClick: () => challengeMemberProgress(challengeId) },
      { name: "challengePathMemberProgress", onClick: () => challengePathMemberProgress(challengeId) },
      { name: "challengeReportDetails", onClick: () => challengeReportDetails(challengeId) },
      { name: "challengeLabs", onClick: () => challengeLabs(challengeId,1,10,'') },
      { name: "challengeLabPrograms", onClick: () => challengeLabPrograms(challengeId,1,10,'') },
      { name: "challengeResourceModules", onClick: () => challengeResourceModules(challengeId,1,10,'') },
      { name: "challengeResourceGroup", onClick: () => challengeResourceGroup(challengeId,1,10,'') },
      { name: "challengeResourceCollection", onClick: () => challengeResourceCollection(challengeId,1,10,'') },
      { name: "challengeAssociatedProjectStats", onClick: () => challengeAssociatedProjectStats(challengeId) },
      { name: "challengeEngagement", onClick: () => challengeEngagement(challengeId) },
      { name: "challengeMembers", onClick: () => challengeMembers(challengeId,1) },
      { name: "ChallengeAchievementDetails", onClick: () => ChallengeAchievementDetails(challengeId,1) },
      { name: "ChallengeAssessmentReports", onClick: () => ChallengeAssessmentReports(challengeId,1,'assessed') },
      { name: "challengeAssessmentProjectDetails", onClick: () => challengeAssessmentProjectDetails(challengeId,projectId) },
      { name: "challengeMemberActivity", onClick: () => challengeMemberActivity(challengeId) },
      { name: "downloadChallengeReport", onClick: () => downloadChallengeReport(challengeId) },
    ];
  
    return (
      <div>
        {BUTTON_LINKS.map((item) => (
          <button data-testid={item.name} key={item.name} onClick={item.onClick}>
            Call {item.name}
          </button>
        ))}
      </div>
    );
  };

  let screen;


describe("Reports Challenges services", () => {
    beforeEach(() => {
      screen = render(
        <Provider store={store}>
          <BrowserRouter>
            <ReportsChallengesServicesMock />
          </BrowserRouter>
        </Provider>
      );
      getSpy = mockRequestGet();
    });
  
    test("challengeMemberProgress", async () => {
      const button = screen.getByTestId("challengeMemberProgress");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.MEMBER_PROGRESS}?language=en`,
          {},
          headers,
          expect.any(Function)
        )
      );
    });
    test("challengePathMemberProgress", async () => {
        const button = screen.getByTestId("challengePathMemberProgress");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE_PATH}/${challengeId}/${ENDPOINTS.MEMBER_PROGRESS}?language=en`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("challengeReportDetails", async () => {
        const button = screen.getByTestId("challengeReportDetails");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}?language=en`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("challengeLabs", async () => {
        const button = screen.getByTestId("challengeLabs");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.LABS}?language=en&keyword=&page=1&per_page=10`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });
    
      test("challengeLabPrograms", async () => {
        const button = screen.getByTestId("challengeLabPrograms");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.LAB_PROGRAMS}?language=en&keyword=&page=1&per_page=10`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("challengeResourceModules", async () => {
        const button = screen.getByTestId("challengeResourceModules");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.RESOURCE_MODULES}?language=en&keyword=&page=1&per_page=10`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("challengeResourceGroup", async () => {
        const button = screen.getByTestId("challengeResourceGroup");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.RESOURCE_GROUPS}?language=en&keyword=&page=1&per_page=10`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("challengeResourceCollection", async () => {
        const button = screen.getByTestId("challengeResourceCollection");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.RESOURCE_COLLECTIONS}?language=en&keyword=&page=1&per_page=10`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("challengeAssociatedProjectStats", async () => {
        const button = screen.getByTestId("challengeAssociatedProjectStats");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.ASSOCIATED_PROJECTS}?language=en`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("challengeEngagement", async () => {
        const button = screen.getByTestId("challengeEngagement");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.ENGAGEMENT}?language=en`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("challengeMembers", async () => {
        const button = screen.getByTestId("challengeMembers");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.MEMBERS}?language=en&page=1`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("ChallengeAchievementDetails", async () => {
        const button = screen.getByTestId("ChallengeAchievementDetails");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.ACHIEVEMENTS}?language=en&page=1`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("ChallengeAssessmentReports", async () => {
        const button = screen.getByTestId("ChallengeAssessmentReports");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.ASSESSMENTS}?language=en&assessment_type=assessed&page=1`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("challengeAssessmentProjectDetails", async () => {
        const button = screen.getByTestId("challengeAssessmentProjectDetails");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.ASSESSMENTS}/${projectId}?language=en`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("challengeMemberActivity", async () => {
        const button = screen.getByTestId("challengeMemberActivity");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${challengeId}/${ENDPOINTS.MEMBER_ACTIVITY}?language=en`,
            {},
            headers,
            expect.any(Function),
            

          )
        );
      });
      
})