import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { COLORS } from "helpers/utils/constants";

const Spin = ({ blue, red, green, orange, big, classes = "" }) => {
  return (
    <CircularProgress
      data-testid="spinner"
      style={{
        color: red
          ? COLORS.ERROR
          : green
          ? COLORS.GREEN
          : orange
          ? COLORS.ORANGE
          : blue
          ? COLORS.BLUE
          : COLORS.BG_GRAY,
      }}
      size={big ? 30 : 20}
      className={classes}
    />
  );
};

export default Spin;
