import { Grid, Switch } from "@mui/material";
import Input from "components/Input";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import React from "react";
import { stepSchema } from "./data";

const LabAvailability = ({ data, errors, onChange, step }) => {
  return (
    <Grid container px={{ xs: 0, md: 4 }}>
      <CreateFormSection classes="w-full">
        <SchemaUIExtractor
          data={data}
          schema={stepSchema(step)}
          errors={errors}
          getSelectValues={(name) => {
            switch (name) {
              default:
                return [
                  { value: "A", key: 1 },
                  { value: "B", key: 2 },
                ];
            }
          }}
          onChange={onChange}
        />
        <Grid container spacing={2} mt={0.5} mb={5}>
          <Grid container item direction="row" alignItems="center">
            <Switch defaultChecked={true} />
            <Grid item xs={10} lg={6}>
              <Input title="Price" required classes="w-full" width="99%" />
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Switch defaultChecked={true} />
            <Grid item xs={10} lg={6}>
              <Input
                title="Prep Points Cost"
                required
                classes="w-full"
                width="99%"
              />
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Switch defaultChecked={true} />
            <Grid item xs={10} lg={6}>
              <Input
                title="Custom Unlock Code"
                required
                classes="w-full"
                width="99%"
              />
            </Grid>
            <Grid item xs={10} lg={6} ml={7}>
              <Input required classes="w-full mt-3" width="99%" />
              <Input required classes="w-full mt-3" width="99%" />
            </Grid>
          </Grid>
        </Grid>
      </CreateFormSection>
    </Grid>
  );
};

export default LabAvailability;
