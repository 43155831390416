import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useMemo, useState } from "react";
import { startpageBanner, startPageMobileBanner } from "assets/png";
import { lab, user } from "assets/svg/blue";
import { user as defaultUser } from "assets/svg/gradient/exports";
import { org } from "assets/svg/yellow";
import useRedirect from "helpers/hooks/useRedirect";
import { Grid, Avatar, Chip, Stack, Skeleton } from "@mui/material";
import SwipableView from "components/UI/organisms/SwipableView";
import { COLORS } from "helpers/utils/constants";
import Button from "components/Button";
import SecureImage from "components/UI/atoms/SecureImage";
import Card from "components/Cards/Card";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import useOnboarding from "store/onboarding/service-hook";
import useAuth from "store/auth/service-hook";
import { STARTPAGE_BACKUP } from "../auth-data";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { isValidUrl } from "helpers/utils/utilities";

const StartPage = () => {
  const {
    navigateRegister,
    navigateLogin,
    navigateLab,
    navigateSkill,
    navigateExplore,
  } = useRedirect();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const width = useWindowWidth();
  const { startPage } = useOnboarding();
  const { t } = useTranslation("auth");

  const {
    state: { isLoggedIn },
  } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigateExplore();
      return;
    }
    fetchData();
  }, [i18next.language]);

  const fetchData = async () => {
    setLoading(true);
    const res = await startPage(i18next.language);
    setData(res || {});
    setLoading(false);
  };

  const slidesPerView = useMemo(
    () => (width > 1200 ? 3 : width > 600 ? 2 : 1),
    []
  );

  const onMobile = useMemo(() => width <= 1000, []);
  return (
    <DashboardLayout>
      {!onMobile ? (
        <div
          className="mt-12"
          style={{
            backgroundImage: `url(${startpageBanner})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "min(700px, 70vh)",
          }}
        >
          <div className="container pt-14">
            <h2 className="c-white maxw-480">
              {t(
                "Explore our variety of Labs and solve real life challenges with hands-on learning."
              )}
            </h2>
            <h4 className="mt-8 c-white">
              {t("Start your learning journey.")}
            </h4>
            <Button
              classes="bg-white mt-4 c-black fs-18 minw-300"
              onClick={() => navigateRegister()}
            >
              <img src={user} className="w-28 h-28 mr-2" alt="user" />
              {t("Create User Account")}
            </Button>
            <Button
              classes="bg-white mt-4 c-black mt-2 fs-18 minw-300"
              onClick={() => navigateRegister()}
            >
              <img src={org} className="w-28 h-28 mr-2" alt="organization" />
              {t("Register Organization")}
            </Button>

            <p className="mt-6" style={{ color: COLORS.WHITE }}>
              {t("Already have an account?")}
              <span
                className="link ml-2 fw-600 c-success"
                onClick={() => navigateLogin()}
              >
                {t("Login")}
              </span>
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full h-300">
            <img
              src={startPageMobileBanner}
              height="100%"
              width="100%"
              alt=""
              className="object-cover"
            />
          </div>
          <div className="bg-primary py-8">
            <div className="container">
              <h2 className="c-white">
                {t(
                  "Explore our variety of Labs and solve real life challenges with hands-on learning."
                )}
              </h2>
              <h4 className="mt-8 c-white">
                {t("Start your learning journey.")}
              </h4>
              <Button
                classes="bg-white mt-4 c-black fs-18"
                width="100%"
                onClick={() => navigateRegister()}
              >
                <img src={user} className="w-28 h-28 mr-4" alt="user" />
                {t("Create User Account")}
              </Button>
              <Button
                width="100%"
                classes="bg-white mt-4 c-black mt-2 fs-18"
                onClick={() => navigateRegister()}
              >
                <img src={org} className="w-28 h-28 mr-4" alt="organization" />
                {t("Register Organization")}
              </Button>

              <p className="mt-10" style={{ color: COLORS.WHITE }}>
                {t("Already have an account?")}
                <span
                  className="link ml-2 fw-600 c-success"
                  onClick={() => navigateLogin()}
                >
                  {t("Login")}
                </span>
              </p>
            </div>
          </div>
        </>
      )}

      <div className="container my-8">
        <h2 className="mt-4 text-center">{t("From the Prepr community")}</h2>
        {loading ? (
          <TestimonialsLoader />
        ) : onMobile ? (
          <SwipableView slidesPerView={1} classes="mt-6 maxw-400">
            {(data?.testimonials?.length
              ? data?.testimonials
              : STARTPAGE_BACKUP?.testimonials
            ).map((item, index) => (
              <div
                key={index}
                className="maxw-400 flex-column items-center justify-between py-2 px-4 mx-auto"
              >
                <Avatar
                  src={item.profile_image || defaultUser}
                  alt={item.full_name}
                  sx={{ margin: "0 auto", width: "120px", height: "120px" }}
                />
                <p className="text-center my-2 fs-14">
                  {item.description ??
                    STARTPAGE_BACKUP?.testimonials[index]?.description}
                </p>
                <p className="text-center my-2 fw-700">
                  {item.full_name ??
                    STARTPAGE_BACKUP?.testimonials[index]?.full_name}
                </p>
              </div>
            ))}
          </SwipableView>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            rowSpacing={2}
            className="w-80p mx-auto mt-4"
          >
            {(data?.testimonials?.length
              ? data?.testimonials
              : STARTPAGE_BACKUP?.testimonials
            ).map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                lg={4}
                key={index}
                className="flex justify-center"
              >
                <Card
                  noShadow
                  classes="w-290 flex-column items-center justify-between py-2 px-4"
                >
                  <Avatar
                    src={item.profile_image || defaultUser}
                    alt={item.full_name}
                    sx={{ margin: "0 auto", width: "120px", height: "120px" }}
                  />
                  <p className="text-center my-2 fs-14">
                    {item.description ??
                      STARTPAGE_BACKUP?.testimonials[index]?.description}
                  </p>
                  <p className="text-center my-2 fw-700">
                    {item.full_name ??
                      STARTPAGE_BACKUP?.testimonials[index]?.full_name}
                  </p>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>

      <div className="bg-white w-full my-12">
        <div className="container">
          <div className=" mx-auto w-60p w-90p--mob py-8">
            <h2 className="text-center">
              {t("Explore topics you are interested in")}
            </h2>
            {loading ? (
              <SkillsLoader />
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap={2}
                flexWrap="wrap"
                mt={4}
                mb={3}
              >
                {(data?.skills?.length
                  ? data?.skills
                  : STARTPAGE_BACKUP?.skills
                ).map((skill) => (
                  <Chip
                    label={skill.title}
                    key={skill.id}
                    className="bg-primary rounded-10 fw-500 py-2 px-3 c-white maxw-180 h-32 capitalize"
                    onClick={() => navigateSkill(skill.id)}
                  />
                ))}
              </Stack>
            )}
          </div>
        </div>
      </div>

      {data?.labs?.length ? (
        <div className="container mt-8 mb-16">
          <h2 className="text-center">
            {t("Choose a Lab that’s right for you")}
          </h2>
          <SwipableView
            slidesPerView={slidesPerView}
            classes="mt-6"
            autoplay
            delay={5000}
            loop
          >
            {data?.labs?.slice(0, 6)?.map((image, index) => (
              <div
                key={index}
                className="h-240 w-full rounded-5 overflow-hidden bg-white shadow px-2 py-2 cursor-pointer mb-5"
                onClick={() =>
                  image.slug
                    ? isValidUrl(image?.slug)
                      ? window.open(image?.slug)
                      : navigateLab(image.slug)
                    : null
                }
              >
                <SecureImage
                  src={image.media}
                  placeholder={lab}
                  alt="Lab"
                  className="h-full w-full rounded-5"
                />
              </div>
            ))}
          </SwipableView>
        </div>
      ) : null}
    </DashboardLayout>
  );
};

export default StartPage;

export const TestimonialsLoader = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      rowSpacing={2}
      className="w-80p mx-auto mt-4"
    >
      {Array.from({ length: 3 }).map((_, i) => (
        <Grid
          item
          xs={12}
          sm={6}
          lg={4}
          key={i}
          className="flex justify-center"
        >
          <Card
            noShadow
            classes="w-290 flex-column items-center justify-between py-4 px-4"
          >
            <Skeleton
              variant="circular"
              sx={{ margin: "0 auto", width: "120px", height: "120px" }}
            />
            <Skeleton
              variant="text"
              width="90%"
              height="10px"
              className="mt-4"
            />
            <Skeleton
              variant="text"
              width="90%"
              height="10px"
              className="mt-1"
            />
            <Skeleton
              variant="text"
              width="90%"
              height="10px"
              className="mt-1"
            />
            <Skeleton
              variant="text"
              width="50%"
              height="25px"
              className="mt-5"
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export const SkillsLoader = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      gap={2.5}
      flexWrap="wrap"
      mt={4}
      mb={3}
    >
      {Array.from({ length: 12 }).map((_, i) => (
        <Skeleton
          key={i}
          variant="rectangular"
          width={180}
          height={35}
          className="rounded-5"
        />
      ))}
    </Stack>
  );
};
