import ModalLayout from "components/Modal";
import React, { useCallback, useEffect, useState } from "react";
import { LEARNING_POINT_RULES } from "../data";
import { Grid } from "@mui/material";
import Card from "components/Cards/Card";
import { COLORS } from "helpers/utils/constants";
import { CheckCircle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useSettings from "store/settings/service-hook";

const LearningPointRules = ({ open, onClose }) => {
  const { t } = useTranslation("achievements");
  const [settings, setSettings] = useState(null);

  const { details } = useSettings();

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    const res = await details();
    setSettings(res?.sso_integrations || {});
  };

  const updateLearningPointRules = useCallback(() => {
    return Object.entries(LEARNING_POINT_RULES).map(([key, value], i) => ({
      key,
      value: value?.map((d) => ({
        ...d,
        showCheck: i === 0 || settings?.[d?.id] === "active",
      })),
    }));
  }, [settings]);

  return (
    <ModalLayout
      width="min(1000px, 90vw)"
      open={open}
      onClose={onClose}
      wrapperClasses="px-6 py-4"
    >
      <h2>{t("Learning Point Rules")}</h2>
      {updateLearningPointRules().map(({ key, value }, i) => (
        <>
          <h3 className="mt-3">{t(key)}</h3>
          <Grid container spacing={2} my={1} justifyContent="center">
            {value.map(({ points, description, icon, showCheck }) => (
              <Grid
                key={description}
                item
                xs={6}
                md={4}
                lg={2.4}
                sx={{ position: "relative" }}
              >
                <Card
                  centered
                  bgColor={i === 1 ? COLORS.LIGHT_YELLOW : COLORS.BLUE_OPAQUE}
                  noShadow
                  classes="flex-column overflow-hidden justify-center items-center w-90p h-150"
                >
                  {showCheck && (
                    <CheckCircle
                      className="c-success absolute fs-30"
                      sx={{ right: "-5px", top: "8px" }}
                    />
                  )}
                  <h4
                    className={`fs-15 ${
                      i === 1
                        ? "c-black"
                        : i === 2 && !showCheck
                        ? "c-gray"
                        : "c-primary"
                    }`}
                  >
                    +{points} {t("Points")}
                  </h4>
                  <img
                    height={40}
                    className="my-2"
                    src={icon}
                    alt={description}
                  />
                  <p
                    className={`fs-12 opacity-90 mt-1 text-center leading-tight ${
                      i === 1
                        ? "c-black"
                        : i === 2 && !showCheck
                        ? "c-gray"
                        : "c-primary"
                    }`}
                  >
                    {t(description)}
                  </p>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      ))}
    </ModalLayout>
  );
};

export default LearningPointRules;
