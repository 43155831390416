import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useLabReport from "../../../../store/reports/lab/service-hooks";
import { Box } from "@mui/system";
import Spin from "../../../../components/Spin";

/**
 *
 * @type {React.Context<{}>}
 */
export const LabReportContext = createContext({});

/**
 *
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const LabReportContextWrapper = ({ children }) => {
  /**
   * HOOKS
   */
  const { id } = useParams();
  const { labReportDetails } = useLabReport();

  /**
   * COMPONENT STATE
   */
  const [labDetails, setLabDetails] = useState();
  const [loadingLabReport, setLoadingLabReport] = useState(false);
  const [activeComponent, setActiveComponent] = useState("all_challenges");
  const [components] = useState([
    {
      value: "All Challenges",
      key: "all_challenges",
    },
    {
      value: "All Challenge Paths",
      key: "all_challenge_paths",
    },
    {
      value: "All Resources",
      key: "all_resources",
    },
    {
      value: "All Resource Collections",
      key: "all_resource_collections",
    },
    {
      value: "All Resource Groups",
      key: "all_resource_groups",
    },
  ]);

  /**
   * METHODS
   */
  const fetchLabReport = async () => {
    setLoadingLabReport(true);
    if (id) {
      const response = await labReportDetails(id);
      if (response) {
        setLabDetails(response);
      }
    }
    setLoadingLabReport(false);
  };

  /**
   * COMPONENT MOUNTED
   */
  useEffect(() => {
    fetchLabReport();
  }, []);

  return (
    <LabReportContext.Provider
      value={{
        labDetails: labDetails,
        labSlug: id,
        components,
        activeComponent: activeComponent,
        setActiveComponent: setActiveComponent,
      }}
    >
      {loadingLabReport ? (
        <Box className="h-screen w-screen flex items-center justify-center">
          <Spin big blue />
        </Box>
      ) : (
        ""
      )}
      {!loadingLabReport && labDetails ? (
        <section className={"prepr-report-module"}>{children}</section>
      ) : (
        <Box className="h-screen w-screen flex items-center justify-center">
          <p>Failed to fetch lab.</p>
        </Box>
      )}
    </LabReportContext.Provider>
  );
};

export default LabReportContextWrapper;
