import { EditNote } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import Card from "components/Cards/Card";
import React from "react";
import { PROFILE_TYPES } from "../index";
import ListingContainer from "components/UI/organisms/ListingContainer";
import ExperienceItem from "./ExperienceItem";
import useProfile from "store/profile/service-hook";
import EmptyText from "components/UI/atoms/EmptyText";
import Button from "components/Button";

const ProfileDetails = ({
  setModal,
  profile,
  setProfile,
  itsMe,
  t = (d) => d,
}) => {
  const {
    user_experiences,
    user_certificates,
    user_educations,
    user_patents,
    user_personal_files,
  } = profile;
  const { deleteExperience, deleteCertificate, deletePatent, deleteEducation } =
    useProfile();

  const handleDelete = (attr, id, method) => {
    if (id) {
      setProfile((prev) => ({
        ...prev,
        [attr]: prev[attr]?.filter((d) => d?.id !== id),
      }));
      method(id);
    }
  };

  const hasContent =
    user_experiences?.length ||
    user_certificates?.length ||
    user_educations?.length ||
    user_patents?.length ||
    user_personal_files?.length;

  const downloadFile = async (file) => {
    try {
      const response = await fetch(file.path);
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(`Error downloading the file: ${file.name}`, error);
    }
  };

  return (
    <>
      {!itsMe && !hasContent ? (
        <Card classes="mt-4">
          <EmptyText>
            {t("This user has not included any details on the profile")}
          </EmptyText>
        </Card>
      ) : (
        <>
          <Card classes="mt-4">
            {(itsMe || (user_experiences && user_experiences.length > 0)) && (
              <ProfileSection
                title={t("Experience")}
                onEdit={() => setModal(PROFILE_TYPES.EXPERIENCE)}
                itsMe={itsMe}
              >
                <ListingContainer
                  noPagination
                  isEmpty={!user_experiences || !user_experiences.length}
                  classes="mt-3"
                  emptyView={
                    <p className="px-4 c-gray pb-4">
                      {t("Add your experiences to showcase them here","common")}
                    </p>
                  }
                >
                  {user_experiences?.map((d) => (
                    <ExperienceItem
                      t={t}
                      key={d?.id}
                      title={d?.company}
                      subTitle={d?.position}
                      address={d?.address}
                      state={d?.state}
                      country={d?.country}
                      startDate={d?.start_date}
                      endDate={d?.end_date}
                      description={d?.description}
                      onDelete={() =>
                        handleDelete(
                          "user_experiences",
                          d?.id,
                          deleteExperience
                        )
                      }
                      itsMe={itsMe}
                    />
                  ))}
                </ListingContainer>
              </ProfileSection>
            )}
            {(itsMe || (user_educations && user_educations.length > 0)) && (
              <ProfileSection
                title={t("Education")}
                onEdit={() => setModal(PROFILE_TYPES.EDUCATION)}
                itsMe={itsMe}
              >
                <ListingContainer
                  noPagination
                  isEmpty={!user_educations || !user_educations.length}
                  emptySmall
                  classes="mt-3"
                  emptyView={
                    <p className="px-4 c-gray pb-4">
                      {t("Add educations you obtained so you can showcase them here","common")}
                    </p>
                  }
                >
                  <Grid container item xs={12} rowSpacing={1} columnSpacing={2}>
                    {user_educations?.map((d) => (
                      <ExperienceItem
                        t={t}
                        key={d?.id}
                        title={d?.university}
                        subTitle={d?.degree}
                        address={d?.address}
                        startDate={d?.start_date}
                        endDate={d?.end_date}
                        description={d?.description}
                        state={d?.state}
                        country={d?.country}
                        onDelete={() =>
                          handleDelete(
                            "user_educations",
                            d?.id,
                            deleteEducation
                          )
                        }
                        itsMe={itsMe}
                      />
                    ))}
                  </Grid>
                </ListingContainer>
              </ProfileSection>
            )}
            {(itsMe || (user_certificates && user_certificates.length > 0)) && (
              <ProfileSection
                title={t("Certifications")}
                onEdit={() => setModal(PROFILE_TYPES.CERTIFICATE)}
                itsMe={itsMe}
              >
                <ListingContainer
                  noPagination
                  isEmpty={!user_certificates || !user_certificates.length}
                  emptySmall
                  emptyView={
                    <p className="px-4 c-gray pb-4">
                      {t("Add certifications you obtained so you can showcase them here","common")}
                    </p>
                  }
                  classes="mt-3"
                >
                  <Grid container item xs={12} rowSpacing={1} columnSpacing={2}>
                    {user_certificates?.map((d) => (
                      <ExperienceItem
                        t={t}
                        key={d?.id}
                        title={d?.company}
                        subTitle={d?.name}
                        startDate={d?.start_date}
                        endDate={d?.end_date}
                        description={d?.description}
                        onDelete={() =>
                          handleDelete(
                            "user_certificates",
                            d?.id,
                            deleteCertificate
                          )
                        }
                        itsMe={itsMe}
                      />
                    ))}
                  </Grid>
                </ListingContainer>
              </ProfileSection>
            )}
            {(itsMe || (user_patents && user_patents.length > 0)) && (
              <ProfileSection
                title={t("Patents")}
                onEdit={() => setModal(PROFILE_TYPES.PATENT)}
                itsMe={itsMe}
              >
                <ListingContainer
                  noPagination
                  isEmpty={!user_patents || !user_patents.length}
                  emptySmall
                  classes="mt-3"
                  emptyView={
                    <p className="px-4 c-gray pb-4">
                      {t("Include your granted patents to feature them here","common")}
                    </p>
                  }
                >
                  <Grid container item xs={12} rowSpacing={1} columnSpacing={2}>
                    {user_patents?.map((d) => (
                      <ExperienceItem
                        t={t}
                        key={d?.id}
                        title={d?.company}
                        subTitle={d?.name}
                        patentDate={d?.patent_date}
                        description={d?.description}
                        onDelete={() =>
                          handleDelete("user_patents", d?.id, deletePatent)
                        }
                        itsMe={itsMe}
                      />
                    ))}
                  </Grid>
                </ListingContainer>
              </ProfileSection>
            )}
          </Card>

          <Card classes="mt-4">
            {(itsMe ||
              (user_personal_files && user_personal_files.length > 0)) && (
              <ProfileSection title={t("Files")}>
                <div className="flex justify-between">
                  <ListingContainer
                    noPagination
                    isEmpty={
                      !user_personal_files || !user_personal_files.length
                    }
                    emptySmall
                    classes="mt-3"
                    emptyView={
                      <p className="px-4 c-gray pb-4">
                        {t("Upload your files to display them here","common")}
                      </p>
                    }
                  >
                    {user_personal_files?.map((d) => (
                      <Grid key={d?.id} item xs={12}>
                        <Box
                          sx={{
                            "& .downloadFile": { visibility: "hidden" },
                            "&:hover .downloadFile": { visibility: "visible" },
                          }}
                          className="flex justify-between mt-1"
                        >
                          <h4 className="text-truncate link">{d?.name}</h4>
                          <Button
                            onClick={() => downloadFile(d)}
                            classes="downloadFile"
                            width="120px"
                            height="30px"
                            bordered
                          >
                            {t("Download")}
                          </Button>
                        </Box>
                      </Grid>
                    ))}
                  </ListingContainer>
                </div>
              </ProfileSection>
            )}
          </Card>
        </>
      )}
    </>
  );
};

export default ProfileDetails;

const ProfileSection = ({ title, onEdit, children, itsMe }) => {
  return (
    <div classes="mt-4">
      <div className="flex items-center justify-between">
        <h3 className="fs-22">{title}</h3>
        {itsMe && onEdit && (
          <IconButton onClick={onEdit}>
            <EditNote fontSize="medium" className="c-black" />
          </IconButton>
        )}
      </div>
      {children}
    </div>
  );
};
