import Paginator from "components/UI/atoms/Paginator";
import PageTitleCard from "components/UI/molecules/PageTitleCard";
import ListingPageHeader from "components/UI/organisms/ListingPageHeader";
import DashboardLayout from "pages/layouts/dashboard";
import React, { useState } from "react";
import ProjectListing from "../listing/ProjectListing";
import { useTranslation } from "react-i18next";

const ProjectSubmission = () => {
  const [gridType, setGridType] = useState(1);
  const { t } = useTranslation("challenge");

  return (
    <DashboardLayout>
      <PageTitleCard title={t("Project Submission")} noAction />
      <div className="container mt-6 mb-10">
        <ListingPageHeader gridType={gridType} setGridType={setGridType} />
        <ProjectListing gridType={gridType} isProjectSubmissions />
        <Paginator />
      </div>
    </DashboardLayout>
  );
};

export default ProjectSubmission;
