import { COMPONENT_TYPES } from "helpers/utils/constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { serializeObject } from "store/api/utils";
import { resolveResponse } from "store/api/utils";

const useMembers = () => {
  const { type, id } = useParams();

  const state = useSelector((state) => state[REDUCER_TYPES.MEMBERS]);
  const { language, token, user } = useSelector(
    (state) => state[REDUCER_TYPES.AUTH]
  );

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (!state.networkMembers?.length) getNetworkMembers();
  }, []);

  const getNetworkMembers = async (search = "") => {
    const client = new REQUEST();
    const response = await client.get(
      `${ENDPOINTS.USER}?language=${language}&search=${search}`,
      {},
      headers
    );
    if (response?.data?.data) {
      return response.data.data;
    }
  };

  const getMembers = async (slug, component, filters = {}) => {
    let memberComponent = component || type;
    let _id = slug || id;
    if (!memberComponent || !_id) return;

    const client = new REQUEST();
    const response = await client.get(
      `${type === COMPONENT_TYPES.PROJECT ? "" : ENDPOINTS.MANAGE + "/"}${
        ENDPOINTS.MEMBER_MANAGEMENT
      }/${memberComponent}/${_id}?language=${language}&${serializeObject(
        filters
      )}`,
      {},
      headers
    );
    return response?.data?.data;
  };

  const addMembers = async (payload, slug, component) => {
    let memberComponent = component || type;
    let _id = slug || id;
    if (!memberComponent || !_id || !payload) return;

    const onSuccess = (res) => resolveResponse(res);

    const body = {
      inviter_id: user?.id,
      email_status: "scheduled",
      ...payload,
    };

    const client = new REQUEST();
    const response = await client.post(
      `${type === COMPONENT_TYPES.PROJECT ? "" : ENDPOINTS.MANAGE + "/"}${
        ENDPOINTS.MEMBER_MANAGEMENT
      }/${memberComponent}/${_id}/${ENDPOINTS.CREATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    return response;
  };

  const magicLinkInvite = async (component, uuid) => {
    if (!component || !uuid) return;

    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.INVITATION_MANAGEMENT}/magic-link/${component}/${uuid}?language=${language}`,
      {},
      headers,
      resolveResponse
    );
    return response?.data;
  };

  const deleteMembers = async (emails, slug, component) => {
    let memberComponent = component || type;
    let _id = slug || id;
    if (!memberComponent || !_id || !emails) return;

    const onSuccess = (res) => resolveResponse(res);
    const body = {
      email: emails,
    };

    const url = `${
      type === COMPONENT_TYPES.PROJECT ? "" : ENDPOINTS.MANAGE + "/"
    }${ENDPOINTS.MEMBER_MANAGEMENT}/${memberComponent}/${_id}/${
      ENDPOINTS.DELETE
    }?language=${language}&${serializeObject(body)}`;
    const client = new REQUEST();
    const response = await client.delete(url, headers, onSuccess);
    return response;
  };

  const getRoles = async () => {
    const client = new REQUEST();
    const response = await client.get(
      `${type === COMPONENT_TYPES.PROJECT ? "" : ENDPOINTS.MANAGE + "/"}${
        ENDPOINTS.MEMBER_MANAGEMENT
      }/${type === COMPONENT_TYPES.PROJECT ? ENDPOINTS.PROJECTS + "/" : ""}${
        ENDPOINTS.GET_ROLES
      }?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    return response?.data?.data?.map((d) => d?.name) || [];
  };

  const downloadSampleCsv = async () => {
    const client = new REQUEST();
    const response = await client.get(
      `${type === COMPONENT_TYPES.PROJECT ? "" : ENDPOINTS.MANAGE + "/"}${
        ENDPOINTS.MEMBER_MANAGEMENT
      }/${type !== COMPONENT_TYPES.PROJECT ? "" : ENDPOINTS.PROJECTS + "/"}${
        ENDPOINTS.DOWNLOAD_SMAPLE
      }?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    return response?.data || "";
  };

  const changeRole = async (payload, slug, component) => {
    let memberComponent = component || type;
    let _id = slug || id;
    if (!memberComponent || !_id || !payload) return;

    const onSuccess = (res) => resolveResponse(res);

    const body = {
      ...payload,
      slug: _id,
      id: payload.uuid,
    };

    const client = new REQUEST();
    const response = await client.post(
      `${type === COMPONENT_TYPES.PROJECT ? "" : ENDPOINTS.MANAGE + "/"}${
        ENDPOINTS.MEMBER_MANAGEMENT
      }/${memberComponent}/${ENDPOINTS.CHANGE_ROLE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.APPLICATION_JSON,
      },
      onSuccess
    );
    return response;
  };

  const handleAcceptMembersJoinRequest = async (email) => {
    const body = {
      email: email,
    };
    const onSuccess = (res) => resolveResponse(res);

    const client = new REQUEST();
    const response = await client.post(
      `${type === ENDPOINTS.PROJECT ? "" : ENDPOINTS.MANAGE + "/"}${
        ENDPOINTS.MEMBER_MANAGEMENT
      }/${type}/${id}/${ENDPOINTS.REQUEST}/${
        ENDPOINTS.ACCEPT
      }?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.APPLICATION_JSON,
      },
      onSuccess
    );
    return response;
  };

  const deleteAllMembers = async (slug, component) => {
    let _id = slug || id;
    let memberComponent = component || type;
    const onSuccess = (res) => resolveResponse(res);
    const url = `${ENDPOINTS.MANAGE + "/"}${
      ENDPOINTS.MEMBER_MANAGEMENT
    }/${memberComponent}/${_id}/${
      ENDPOINTS.DELETE_ALL_MEMBERS
    }?language=${language}`;
    const client = new REQUEST();
    const response = await client.delete(url, headers, onSuccess);
    return response;
  };

  const approveAllPendingRequests = async (slug, component) => {
    let _id = slug || id;
    let memberComponent = component || type;

    const onSuccess = (res) => resolveResponse(res);

    const client = new REQUEST();
    const res = await client.put(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.MEMBER_MANAGEMENT}/${memberComponent}/${_id}/${ENDPOINTS.APPROVE_ALL_PENDING_REQUESTS}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  return {
    state,
    getNetworkMembers,
    getMembers,
    addMembers,
    deleteMembers,
    getRoles,
    changeRole,
    downloadSampleCsv,
    handleAcceptMembersJoinRequest,
    deleteAllMembers,
    approveAllPendingRequests,
    magicLinkInvite,
  };
};

export default useMembers;
