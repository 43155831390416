import {
  COMPONENT_TYPES,
  LANGUAGES,
  SCHEMA_TYPES,
} from "helpers/utils/constants";
import {
  LOCATION_VALIDATOR,
  NUMBER_VALIDATOR,
  REQUIRED_VALIDATOR,
  TITLE_VALIDATOR,
} from "helpers/utils/validators";
import {
  Edit,
  Delete,
  Settings,
  Assessment,
  ShoppingCart,
} from "@mui/icons-material";
import { getCurrOrigin } from "helpers/utils/utilities";

export const getting_started_steps = {
  0: [
    {
      title: "Prebuilt Labs",
      description:
        "Your organization needs Labs! We’ve set up a few prebuilt Labs to help you get started.",
      button_label: "Add Prebuilt Labs",
    },
    {
      title: "Enhanced Experience",
      description: "Choose a monthly plan that fits your organization’s needs.",
      button_label: "Choose Plan",
    },
    {
      title: "Organization Profile",
      description: "Finish setting up your organization and explore PreprLabs!",
      button_label: "Complete Profile",
    },
  ],
  1: [
    {
      title: "Getting Started",
      description: "Take the Getting Started Lab for Organization Managers!",
      button_label: "View",
    },
    {
      title: "Your First Lab",
      description:
        "Build a community around Labs and allow Lab members to collaborate, learn, and solve Challenges.",
      button_label: "Create Lab",
    },
    {
      title: "Your First Challenge",
      description:
        "Help your organization members build their skills and problem solving abilities through Challenges.",
      button_label: "Create Challenge",
    },
  ],
};

export const challenges_list = [
  {
    title: "Associated Challenge 1",
    description: "Beyond Covid-19 Challenge: Food Challenge Food Challenge",
    deadline: "Dec 30, 2021",
    active: true,
    canStartImmediately: false,
    members: 24,
    status: 0,
  },
  {
    title: "Associated Challenge 2",
    description: "Beyond Covid-19 Challenge: Food Challenge Food Challenge",
    deadline: "Dec 30, 2021",
    active: true,
    canStartImmediately: false,
    members: 36,
    status: 1,
  },
  {
    title: "Associated Challenge 3",
    description: "Beyond Covid-19 Challenge: Food Challenge Food Challenge",
    deadline: "Dec 30, 2021",
    active: true,
    canStartImmediately: true,
    members: 476,
    status: 2,
  },
  {
    title: "Associated Challenge 4",
    description: "Beyond Covid-19 Challenge: Food Challenge Food Challenge",
    deadline: "Jan 30, 2021",
    active: false,
    canStartImmediately: false,
    isSelected: true,
    members: 35,
    status: 0,
  },
  {
    title: "Associated Challenge 5",
    description: "Beyond Covid-19 Challenge: Food Challenge Food Challenge",
    deadline: "Jan 30, 2020",
    active: false,
    isArchived: true,
    members: 92,
    status: 1,
  },
];

export const labs_list = [
  {
    id: 1,
    title: "Associated Lab 1",
    description: "Beyond Covid-19 Lab: Food Lab Future of Food Lab",
    members: 76,
    active: true,
    canStartImmediately: false,
    status: 0,
  },
  {
    id: 2,
    title: "Associated Lab 2",
    description: "Beyond Covid-19 Lab: Food Lab Future of Food Lab",
    members: 99,
    active: true,
    canStartImmediately: false,
    status: 1,
  },
  {
    id: 3,
    title: "Associated Lab 3",
    description: "Beyond Covid-19 Lab: Food Lab Future of Food Lab",
    members: 123,
    active: true,
    canStartImmediately: false,
    status: 2,
  },
  {
    id: 4,
    title: "Associated Lab 4",
    description: "Beyond Covid-19 Lab: Food Lab Future of Food Lab",
    members: 348,
    active: false,
    canStartImmediately: true,
    status: 1,
  },
  {
    id: 5,
    title: "Associated Lab 5",
    description: "Beyond Covid-19 Lab: Food Lab Future of Food Lab",
    members: 549,
    active: true,
    canStartImmediately: false,
    status: 1,
  },
];

export const projects_list = [
  {
    title: "Project 1",
    description: "Beyond Covid-19 Challenge",
    tasks: ["Task 1", "Task 2", "Task 3"],
    deadline: "Jan 30, 2020",
    associated_challenges: "Beyond Covid-19: Future of Food Challenge",
    isSelected: false,
    status: 0,
  },
  {
    title: "Project 2",
    description: "Beyond Covid-19 Challenge",
    tasks: ["Task 1", "Task 2", "Task 3"],
    deadline: "Jan 31, 2020",
    associated_challenges: "None",
    isSelected: false,
    status: 1,
  },
  {
    title: "Project 3",
    description: "Beyond Covid-19 Challenge",
    tasks: ["Task 1", "Task 2", "Task 3"],
    deadline: "Feb 30, 2020",
    associated_challenges: "Beyond Covid-19: Future of Food Challenge",
    isSelected: false,
    status: 2,
  },
  {
    title: "Project 4",
    description: "Beyond Covid-19 Challenge",
    tasks: ["Task 1", "Task 2", "Task 3"],
    deadline: "Mar 30, 2020",
    associated_challenges: "None",
    isSelected: true,
    status: 1,
  },
  {
    title: "Project 5",
    description: "Beyond Covid-19 Challenge",
    tasks: ["Task 1", "Task 2", "Task 3"],
    deadline: "Jan 30, 2020",
    associated_challenges: "Beyond Covid-19: Future of Food Challenge",
    isSelected: false,
    status: 1,
  },
];

export const prebuilt_labs = [
  {
    name: "Lab Collection Name ",
    isPaid: true,
    cost: 199,
    seats: "unlimited seats",
  },
  {
    name: "Lab Collection Name ",
    isPaid: false,
    seats: "unlimited seats",
  },
  {
    name: "Lab Collection Name ",
    isPaid: false,
    seats: "unlimited seats",
  },
  {
    name: "Lab Collection Name ",
    isPaid: true,
    cost: 299,
    seats: "unlimited seats",
  },
  {
    name: "Lab Collection Name ",
    isPaid: true,
    cost: 199,
    seats: "unlimited seats",
  },
  {
    name: "Lab Collection Name ",
    isPaid: false,
    seats: "unlimited seats",
  },
  {
    name: "Lab Collection Name ",
    isPaid: false,
    seats: "unlimited seats",
  },
];

export const subscription_plans = [
  {
    id: 0,
    title: "Free",
    costPerMonth: 0,
    featureList: [
      "Feature List",
      "Feature List",
      "Feature List",
      "Feature List",
    ],
  },
  {
    id: 1,
    title: "Starter",
    costPerMonth: 99,
    featureList: [
      "Feature List",
      "Feature List",
      "Feature List",
      "Feature List",
    ],
  },
  {
    id: 2,
    title: "Business",
    costPerMonth: 129,
    featureList: [
      "Feature List",
      "Feature List",
      "Feature List",
      "Feature List",
    ],
  },
  {
    id: 3,
    title: "Pro",
    costPerMonth: 399,
    featureList: [
      "Feature List",
      "Feature List",
      "Feature List",
      "Feature List",
    ],
  },
];

export const plan_addons = [
  {
    id: 0,
    title: "Organization Manager",
    eachCost: 100,
  },
  {
    id: 1,
    title: "Organization User",
    eachCost: 15,
  },
  {
    id: 2,
    title: "Challenge",
    eachCost: 50,
  },
  {
    id: 3,
    title: "Challenge Path",
    eachCost: 50,
  },
  {
    id: 4,
    title: "Custom Lab",
    eachCost: 50,
  },
  {
    id: 5,
    title: "Lab Program",
    eachCost: 50,
  },
  {
    id: 6,
    title: "Resource Group",
    eachCost: 50,
  },
  {
    id: 7,
    title: "Resource Collection",
    eachCost: 50,
  },
  {
    id: 8,
    title: "Resource Module",
    eachCost: 50,
  },
];

export const organization_data = {
  handle: "@magnet",
  website: "https://www.ic.gc.ca/eic/site/152.nsf/eng/00013.html",
  title: "Magnet (Canada Digital Adoption Program) ",
  challenges: [],
  labs: [
    {
      title: "Sample",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed volutpat facilisis nisi, egestas massa scelerisque proin ut massa. Elit elit, cursus enim purus sit. Ac convallis aliqu...",
      isPrivate: false,
      likes: 999,
      share: 999,
      views: 999,
    },
  ],
  resources: [],
  employees: 40,
  location: "Toronto, ON, Canada, M5T 2T9",
  people: [
    {
      name: "Austin Hracs",
      role: "Director of Research & Development",
      image: "http://i.imgur.com/74sByqd.jpg",
    },
    {
      name: "Amy Di Palma",
      role: "Project Manager",
      image:
        "https://community.canvaslms.com/legacyfs/online/avatars/a130158_lattke-profile-pic-tall.png",
    },
  ],
};

export const ORG_SCHEMA = (vanityValidate) => [
  {
    name: "profile_image",
    type: SCHEMA_TYPES.FILE,
    title: "Upload Logo",
    description:
      "The logo needs to be at least 100 x 100 pixels. Acceptable format: png, jpg, jpeg, webp",
    description2: "Size limit: 5MB",
    descriptionClasses: "c-gray fs-14",
    lg: 4,
    dropboxProps: {
      fileType: "image",
      dimensions: [100, 100],
    },
  },
  {
    name: "cover_image",
    type: SCHEMA_TYPES.FILE,
    title: "Upload Banner Image",
    description:
      "The banner needs to be at least 1820 x 200 pixels. Acceptable format: png, jpg, jpeg, webp",
    description2: "Size limit: 5MB",
    descriptionClasses: "c-gray fs-14",
    dropboxProps: {
      width: 700,
      height: 350,
      classes: "res__banner mt-3",
      fileType: "image",
      dimensions: [1820, 200],
    },
    lg: 8,
  },
  {
    name: "title",
    validator: TITLE_VALIDATOR,
    type: SCHEMA_TYPES.INPUT,
    title: "Organization Name",
    limit: 50,
  },
  {
    name: "vanity_slug",
    validator: REQUIRED_VALIDATOR,
    type: SCHEMA_TYPES.INPUT,
    title: "Custom URL",
    preInput: getCurrOrigin() + "/",
    optinalValidatorFunc: vanityValidate,
    immediateValidation: true,
  },
  {
    name: "about",
    validator: REQUIRED_VALIDATOR,
    type: SCHEMA_TYPES.EDITOR,
    title: "Description",
    lg: 12,
  },
  {
    name: "language",
    validator: REQUIRED_VALIDATOR,
    type: SCHEMA_TYPES.SELECT,
    title: "Display Language",
    options: LANGUAGES,
    disabled: window.location.pathname.includes("/update/"),
  },
  {
    name: "website",
    validator: REQUIRED_VALIDATOR,
    type: SCHEMA_TYPES.INPUT,
    preInput: "https://",
    title: "Organization website",
  },
  {
    name: "categories",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
    title: "Organization Type",
  },
  {
    name: "total_employees",
    type: SCHEMA_TYPES.INPUT,
    validator: NUMBER_VALIDATOR,
    title: "Number of Employees",
  },

  //LOCATION SCHEMA
  {
    name: "address_1",
    type: SCHEMA_TYPES.GOOGLE_PLACES,
    validator: LOCATION_VALIDATOR,
    title: "Address 1",
  },
  {
    name: "address_2",
    type: SCHEMA_TYPES.INPUT,
    title: "Address 2",
  },
  {
    name: "state",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    title: "Province/State",
  },
  {
    name: "city",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    title: "City",
  },
  {
    name: "country",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    title: "Country",
  },
  {
    name: "zip_code",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    title: "Postal Code",
  },

  {
    name: "externalLinks",
  },
  //MEMBER SCHEMA
  {
    name: "name",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    title: "Name",
  },
  {
    name: "position",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    title: "Position",
  },
  {
    name: "image",
    type: SCHEMA_TYPES.FILE,
    title: "Upload Image",
  },
];

export const getOrgInfoSlice = () => [
  0,
  ORG_SCHEMA().findIndex((d) => d.name === "total_employees") + 1,
];

export const getLocationSlice = () => [
  ORG_SCHEMA().findIndex((d) => d.name === "address_1"),
  ORG_SCHEMA().findIndex((d) => d.name === "zip_code") + 1,
];

export const getExternalSlice = () => [
  ORG_SCHEMA().findIndex((d) => d.name === "externalLinks"),
];

export const getMemberSlice = () => [
  ORG_SCHEMA().findIndex((d) => d.name === "name"),
  ORG_SCHEMA().findIndex((d) => d.name === "image") + 1,
];

export const getInfoSchema = (vanityValidate) => {
  return ORG_SCHEMA(vanityValidate).slice(...getOrgInfoSlice());
};

export const getExternalSchema = () => {
  return ORG_SCHEMA().slice(...getExternalSlice());
};

export const getLocationSchema = () => {
  return ORG_SCHEMA().slice(...getLocationSlice());
};

export const getMemberSchema = () => {
  return ORG_SCHEMA().slice(...getMemberSlice());
};

export const manageOrgMenuItems = (
  organization,
  navigateOrganizationReports,
  navigateMemberManagement,
  navigateEditOrg,
  navigateOrgIntegrationSettings,
  handleDelete
) => [
  {
    title: "View Report",
    icon: Assessment,
    iconClasses: "c-primary",
    onClick: () => navigateOrganizationReports(organization?.slug),
  },
  {
    title: "Manage Members",
    onClick: () =>
      navigateMemberManagement(
        COMPONENT_TYPES.ORGANIZATION,
        organization?.slug
      ),
    icon: Settings,
    iconClasses: "c-primary",
  },
  {
    title: "View Subscription",
    onClick: () => navigateEditOrg(organization?.slug, "?step=1"),
    icon: ShoppingCart,
    iconClasses: "c-primary",
  },
  {
    title: "Integration Settings",
    icon: Settings,
    iconClasses: "c-primary",
    onClick: () => navigateOrgIntegrationSettings(organization?.slug),
  },
  {
    title: "Edit",
    onClick: () => navigateEditOrg(organization?.slug),
    icon: Edit,
  },
  {
    title: "Delete",
    onClick: () => handleDelete(organization?.slug),
    icon: Delete,
    iconClasses: "c-error",
  },
];
