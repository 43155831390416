import { ExpandMore, KeyboardArrowRight } from "@mui/icons-material";
import { Button, Checkbox } from "@mui/material";
import Card from "components/Cards/Card";
import React, { Fragment, useEffect, useState } from "react";
import classes from "pages/achievements/achievements.module.scss";
import cx from "classnames";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { useLocation } from "react-router-dom";
import Spin from "components/Spin";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";

const _types = [FILTER_TYPES.CATEGORY];

const FilterCard = ({
  title,
  types = _types,
  classes: styles,
  clearFilters,
  onClearPressed,
}) => {
  const [open, setOpen] = useState([]);
  const { selected, getFilterValuesByType, onChange, clearAll, setSelected } =
    useFilters();
  const { user } = useSelector((state) => state[REDUCER_TYPES.AUTH]);
  const { t } = useLocalizedTranslation("common");
  const location = useLocation();

  const handleClick = (key) => {
    const exists = open.includes(key);
    if (!exists || open.length === 0) {
      setOpen([...open, key]);
    } else {
      const newData = open.filter((d) => key !== d);
      setOpen(newData);
    }
  };

  useEffect(() => {
    let openKeys = [];
    for (const type of types) {
      // eslint-disable-next-line no-unused-vars
      const [_title, values] = getFilterValuesByType(type);
      const haveSelectedItem =
        (Array.isArray(values) &&
          values?.some(({ link }) => !!link && location?.pathname === link)) ||
        !!selected[type];
      if (haveSelectedItem) {
        if (!open.includes(type)) openKeys = [...openKeys, type];
      }
    }
    if (openKeys?.length) setOpen((val) => [...val, ...openKeys]);
  }, []);

  const onClearFilters = () => {
    clearAll();
    setOpen([]);
    setSelected({});
    onClearPressed();
  };

  return (
    <Card data-testid="filter-card" classes="p-0">
      <div className="flex-center justify-between">
        {title && <h3 className="mx-2 my-3">{t(title, "common")}</h3>}
        {clearFilters && (
          <Button
            color="error"
            className="mr-1"
            variant="text"
            sx={{ textTransform: "none" }}
            onClick={onClearFilters}
          >
            {t("Clear All", "common")}
          </Button>
        )}
      </div>
      {types
        .filter((d) => !!d)
        .map((type, index) => {
          const [title, values, isString, onDemand] =
            getFilterValuesByType(type);
          return (
            <Filter
              key={index}
              isOpen={open.includes(type)}
              t={t}
              title={title}
              isString={isString}
              values={values}
              onChange={onChange}
              handleClick={handleClick}
              type={type}
              selected={selected}
              location={location}
              styles={styles}
              onDemand={onDemand}
              user={user}
            />
          );
        })}
    </Card>
  );
};

export default FilterCard;

const Filter = ({
  isOpen,
  t = (d) => d,
  title,
  isString,
  onChange,
  handleClick,
  values,
  type,
  selected,
  location,
  styles,
  onDemand,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [valuesToShow, setValuesToShow] = useState(onDemand ? [] : values);
  const [shouldHideFilter, setShouldHideFilter] = useState(false);

  useEffect(() => {
    setShouldHideFilter(() => {
      switch (type) {
        case FILTER_TYPES.ORGANIZATION:
          if (user?.organization_details?.slug) return true;
          return false;
        default:
          return false;
      }
    });
  }, []);

  useEffect(() => {
    fetchValues();
  }, [isOpen]);

  useEffect(() => {
    if (!onDemand) setValuesToShow(values);
  }, [values]);

  const fetchValues = async () => {
    if (!onDemand || !values || typeof values !== "function" || !isOpen) return;
    setLoading(true);
    const res = await values();
    setValuesToShow([...res]);
    setLoading(false);
  };

  if(shouldHideFilter) return null;

  return (
    <div className={classes.filterBox} key={type}>
      <p
        className={classes["filterBox--title"]}
        onClick={() => handleClick(type)}
      >
        {!isOpen ? (
          <KeyboardArrowRight className="cursor-pointer" />
        ) : (
          <ExpandMore className="cursor-pointer" />
        )}
        {t(title, "common")}
      </p>
      {!isOpen ? (
        <div className="my-2"></div>
      ) : loading ? (
        <div className="h-150 flex items-center justify-center">
          <Spin blue />
        </div>
      ) : !valuesToShow?.length ? (
        <div className="h-150 flex items-center justify-center">
          <p className="fs-14 c-gray">{t("No data found!", "common")}</p>
        </div>
      ) : (
        <div className={classes["filterBox--content"]}>
          {(isExpanded || valuesToShow.some((d) => d?.isSubtitle)
            ? valuesToShow
            : valuesToShow?.slice(0, 5)
          )?.map(({ key, value, link, isSubtitle }, i) => (
            <Fragment key={i}>
              {isSubtitle ? (
                <p className="fs-14 mt-1 mb-1 fw-700 ml-2">
                  {t(value, "common")}
                </p>
              ) : (
                <div className="flex items-center">
                  <Checkbox
                    size="small"
                    checked={
                      !!link
                        ? location?.pathname === link
                        : !isString
                        ? selected[type]?.includes(key.toString())
                        : selected[type] === key?.toString()
                    }
                    onClick={(e) => onChange(e, Boolean(isString), link)}
                    name={type}
                    value={key}
                  />
                  <p className={cx("text-truncate capitalize fs-13", styles)}>
                    {typeof value === "string" ? t(value, "common") : value}
                  </p>
                </div>
              )}
            </Fragment>
          ))}
          {valuesToShow.length > 5 &&
            valuesToShow?.every((d) => !d?.isSubtitle) && (
              <Button
                onClick={() => setIsExpanded((val) => !val)}
                sx={{ textTransform: "none" }}
              >
                {t(isExpanded ? "Show Less" : "Show More", "common")}
              </Button>
            )}
        </div>
      )}
    </div>
  );
};
