import React, { useState, useEffect } from "react";
import Input from "components/Input";
import { Search } from "@mui/icons-material";
import { getDefaultImageByType } from "components/Cards/MainProductCard";
import { NoDataView } from "components/UI/organisms/ListingContainer";
import Button from "components/Button";
import useDebounce from "helpers/hooks/useDebounce";
import { lab } from "assets/svg/blue";
import styles from "../../challenges.module.scss";
import SecureImage from "components/UI/atoms/SecureImage";
import Spin from "components/Spin";
import { Box } from "@mui/material";

const AssociateList = ({
  id = "uuid",
  selected = [],
  setSelected,
  fetcher = Function.prototype,
  type,
  t = (d) => d,
}) => {
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(searchText, 500);

  const [associated, setAssociated] = useState([]);

  useEffect(() => {
    handleFetchAssociated(debouncedSearchTerm || null);
  }, [debouncedSearchTerm]);

  const handleFetchAssociated = async (searched) => {
    setLoading(true);
    const res = await fetcher({
      search: searched ? searched : null,
    });
    setLoading(false);
    setAssociated(addKeyToObjects(res, "type", type));
  };

  function addKeyToObjects(arr, key, value) {
    return arr.map((obj) => ({
      ...obj, // Spread the existing properties of the object
      [key]: value, // Add the new key-value pair
    }));
  }

  const handleSelect = (item) => {
    if (!selected.some((d) => d.uuid === item.uuid)) {
      const newSelected = [...selected, item];
      setSelected(newSelected);
    }
  };

  return (
    <>
      <Input
        classes="mt-4"
        width="100%"
        icon={<Search style={{ color: "black" }} />}
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        iconLeft
      />
      <div className="border rounded-4 mt-3">
        <div
          className={`${styles.associatedLabs__listContainer_new} flex flex-column gap-10`}
        >
          {loading ? (
            <Box className="h-full minh-500 flex-center justify-center">
              <Spin big green />
            </Box>
          ) : associated?.length ? (
            associated
              .filter(
                (item) => !selected.map((item) => item.uuid).includes(item.uuid)
              )
              .map(({ title, media, type, ...d }, i) => (
                <div key={i}>
                  <div className={`${styles.items} flex items-start`}>
                    <SecureImage
                      src={media}
                      classes="h-80 w-140 maxw-140 object-cover"
                      placeholder={getDefaultImageByType(type)}
                      title={title}
                      smaller
                    />
                    <div>
                      <div className={"flex items-center"}>
                        <img
                          className="mr-1"
                          alt=""
                          src={lab}
                          height={25}
                          width={25}
                        />{" "}
                        <h3 className="tracking-tight text-truncate maxw-350 fs-16 fw-600">
                          {title}
                        </h3>
                      </div>
                      <Button
                        bordered
                        classes="mt-2"
                        height="25px"
                        color="green"
                        onClick={() =>
                          handleSelect({ [id]: d[id], media, title, type })
                        }
                      >
                        {t("Add")}
                      </Button>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <NoDataView />
          )}
        </div>
      </div>
    </>
  );
};

export default AssociateList;
