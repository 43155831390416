import {
  PRIVACY_STATUS,
  SCHEMA_TYPES,
  TIMEZONES,
  VISIBILITY,
} from "helpers/utils/constants";
import { CHARACTER_VALIDATOR, EMAIL_VALIDATOR, PHONE_VALIDATOR, REQUIRED_VALIDATOR } from "helpers/utils/validators";
import msIcon from "assets/png/icons/ms-icon.png";
import googleIcon from "assets/png/icons/google-icon.png";
import appleIcon from "assets/png/icons/apple-icon.png";
import mIcon from "assets/png/icons/m-icon.png";

export const ACCOUNT_SCHEMA = [
  {
    title: "First Name",
    name: "first_name",
    type: SCHEMA_TYPES.INPUT,
    validator: CHARACTER_VALIDATOR,
  },
  {
    title: "Last Name",
    name: "last_name",
    type: SCHEMA_TYPES.INPUT,
    validator: CHARACTER_VALIDATOR,
  },
  {
    title: "Username",
    name: "username",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Email",
    name: "email",
    type: SCHEMA_TYPES.INPUT,
    validator: EMAIL_VALIDATOR,
  },
  {
    title: "Phone Number",
    name: "phone_number",
    type: SCHEMA_TYPES.INPUT,
    validator: PHONE_VALIDATOR,
  },
  {
    title: "Timezone",
    name: "preferred_timezone",
    options: TIMEZONES,
    type: SCHEMA_TYPES.SELECT,
  },
  {
    name: "preferred_language",
    validator: REQUIRED_VALIDATOR,
  },
  {
    name: "two_factor_verification",
  },
];

export const PRIVACY_DATA = [
  {
    title: "Profile Visibility",
    description:
      "Control who can see your profile information.If your profile is public, it is visible to anyone, whether they are signed in or not.",
    name: "profile_visibility",
    options: VISIBILITY,
    required: REQUIRED_VALIDATOR,
  },
  {
    title: "Project Visibility",
    description:
      "Do you want to make your project visible to everyone on PreprLabs? Any user can view, access, and download project files if your project is public.",
    name: "project_visibility",
    options: PRIVACY_STATUS,
    required: REQUIRED_VALIDATOR,
  },
  {
    title: "Friend Request",
    description: "Adjust who can send you friend requests (Any user, No one).",
    name: "friend_request_privacy",
    options: [
      {
        value: "No one",
        key: "private",
      },
      {
        value: "Any User",
        key: "public",
      },
    ],
    required: REQUIRED_VALIDATOR,
  },
];

const SUBSCRIPTION = [
  { value: "Subscribed", key: "subscribe" },
  { value: "Not Subscribed", key: "unsubscribe" },
];

const MONTHLY_WEEKLY = [
  { value: "Subscribed to monthly report", key: "monthly" },
  { value: "Subscribed to weekly report", key: "weekly" },
  { value: "Not Subscribed", key: "unsubscribe" },
];

export const NOTIFICATIONS_DATA = [
  {
    title: "All communication from PreprLabs",
    description:
      "You will receive email notifications on updates in challenges, labs and projects you joined.",
    name: "communication",
    options: SUBSCRIPTION,
  },
  {
    title: "PreprLabs Network Summary",
    description:
      "A report about your recent activity on the platform will be emailed to you.",
    name: "network_summary",
    options: MONTHLY_WEEKLY,
  },
  {
    title: "Challenge Summary",
    description:
      "A report about your recent activity in the Challenges you joined and updates in those Challenges will be emailed to you.",
    name: "challenge_summary",
    options: MONTHLY_WEEKLY,
  },
  {
    title: "Lab Summary",
    description:
      "A report about your recent activity in the Labs you joined and updates in those Labs will be emailed to you.",
    name: "lab_summary",
    options: MONTHLY_WEEKLY,
  },
  {
    title: "Challenge Recommendation",
    description:
      "Based on the skills you have verified on the platform, we will recommend new Challenges for you to tackle.",
    name: "challenge_recommendation",
    options: MONTHLY_WEEKLY,
  },
];

export const INTEGRATIONS = [
  {
    title: "Microsoft",
    id: "microsoft",
    icon: msIcon,
  },
//   {
//     title: "LinkedIn",
//     id: "linked-in",
//     icon: linkedinIcon,
//   },
  {
    title: "Google",
    id: "google",
    icon: googleIcon,
  },
  {
    title: "Apple",
    id: "apple",
    icon: appleIcon,
  },
  {
    title: "Magnet",
    id: "magnet",
    icon: mIcon,
  },
];
