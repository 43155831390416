import DashboardLayout from "pages/layouts/dashboard";
import React from "react";
import ExploreMainPage from "./components/ExploreMainPage";

const Explore = () => {
  
  return (
    <DashboardLayout isPrivate>
      <ExploreMainPage />
    </DashboardLayout>
  );
};

export default Explore;
