import { Grid } from "@mui/material";
import Button from "components/Button";
import ImageCard from "components/Cards/ImageCard";
import TimeLine from "components/UI/atoms/TimeLine";
import PageTitleCard from "components/UI/molecules/PageTitleCard";
import PlanDetails from "components/UI/molecules/PlanDetails";
import MainTabs from "components/UI/organisms/MainTabs";
import PublicDescription from "components/UI/organisms/PublicDescription";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { isTrue } from "helpers/utils/validators";
import AssociateResourceListing from "pages/associateListings/AssociateResourceListing";
import ChallengeOverview from "pages/challenges/components/ChallengeOverview";
import ChallengeTemplate from "pages/challenges/components/ChallengeTemplates";
import MainPageLayout from "pages/layouts/MainPage";
import DashboardLayout from "pages/layouts/dashboard";
import { NotLoggedInTabs } from "pages/organization";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { REDUCER_TYPES } from "store";
import useChallenges from "store/challenges/service-hook";

const ChallengeTemplateDetailView = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redeemLoading, setRedeemLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const { showTemplate, redeemTemplate } = useChallenges();
  const { t } = useTranslation("labs");
  const { navigateBack } = useRedirect();
  const [templatesModalOpen, setTemplatesModalOpen] = useState(false);
  const [params] = useSearchParams();
  const { isLoggedIn } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  const source = useMemo(() => params.get("source") ?? "marketplace", []);

  useEffect(() => {
    if (!id) return;
    handleFetchCTDetails();
  }, [id]);

  const handleFetchCTDetails = async () => {
    setLoading(true);
    const res = await showTemplate(id);
    setDetail(res);
    setLoading(false);
  };
  const handleSelectPlan = () => {
    const instance = window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_STIE,
      publishableKey: process.env.REACT_APP_CHARGEBEE_KEY,
    });
    let cbPortal = instance.createChargebeePortal();

    cbPortal.open({
      close() {
        window.location.reload();
      },
    });
  };

  const handleRedeemTemplate = async () => {
    setRedeemLoading(true);
    const res = await redeemTemplate(detail?.slug);
    if (res?.status === 200) {
      setDetail((prev) => ({ ...prev, is_redeemed: "yes" }));
    }
    setRedeemLoading(false);
  };

  return (
    <DashboardLayout
      loading={loading}
      isEmpty={!detail?.slug}
      emptyMessage="Challenge template cannot be fetched!"
    >
      {source === "marketplace" && (
        <PageTitleCard
          actionTitle="Purchase Credits"
          title="Lab Marketplace"
          actionColor="yellow"
          onClickAction={handleSelectPlan}
        >
          <PlanDetails component={COMPONENT_TYPES.LAB} />
        </PageTitleCard>
      )}
      <MainPageLayout
        classes={`my-3 ${source !== "marketplace" && "mt-header"}`}
        firstLgGridSize={4}
        secondLgGridSize={8}
      >
        <div className="left relative">
          <ImageCard
            image={detail?.media}
            height="250px"
            type={COMPONENT_TYPES.CHALLENGE_TEMPLATE}
            title={detail?.title}
            isEmbedded={detail?.media_type === "embedded"}
          />
          {source === "templates" ? (
            <Grid container columnSpacing={1} className="w-full mt-2">
              <Grid item xs={6}>
                <Button
                  bordered
                  classes="w-full h-35"
                  onClick={() => setTemplatesModalOpen(true)}
                >
                  Other Templates
                </Button>
              </Grid>
              <Grid xs={6} item>
                <Button
                  classes="w-full h-35"
                  disabled={isTrue(detail?.is_redeemed)}
                  color={isTrue(detail?.is_redeemed) ? "green" : "blue"}
                  onClick={handleRedeemTemplate}
                  loading={redeemLoading}
                >
                  {isTrue(detail?.is_redeemed) ? "Redeemed" : "Use Template"}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Button
              height="35px"
              onClick={navigateBack}
              classes="w-full my-2"
              bordered
            >
              {t("Back to Lab")}
            </Button>
          )}

          <PublicDescription
            component={COMPONENT_TYPES.CHALLENGE}
            data={detail}
            hideHostedBy
            hideSocialLinks
            hideChallengeRequirementIcon
            hideDescription
            hideDateValue
          >
            <TimeLine challenge={detail} />
          </PublicDescription>
        </div>
        <div className="right">
          <MainTabs
            tabs={["Challenge Overview", "Resources"]}
            current={tab}
            setCurrent={setTab}
          />
          {!isLoggedIn ? (
            <NotLoggedInTabs title="Login to view more details" />
          ) : (
            <>
              {tab === 0 && <ChallengeOverview challenge={detail} t={t} />}
              {tab === 1 && (
                <AssociateResourceListing
                  showSwitch
                  parent={COMPONENT_TYPES.CHALLENGE}
                  smallView
                  slug={detail?.slug}
                />
              )}
            </>
          )}
        </div>
      </MainPageLayout>
      {templatesModalOpen && (
        <ChallengeTemplate
          open={templatesModalOpen}
          onClose={() => setTemplatesModalOpen(null)}
        />
      )}
    </DashboardLayout>
  );
};

export default ChallengeTemplateDetailView;
