import {
  Assessment,
  DeleteForever,
  Edit,
  LocalMall,
  ManageAccounts,
  Settings,
} from "@mui/icons-material";
import { Icon } from "@mui/material";
import MenuButton from "components/Button/MenuButton";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { isTrue } from "helpers/utils/validators";
import React from "react";
import { useTranslation } from "react-i18next";

const LabManageOptions = ({
  classes = "",
  isLP,
  data,
  onDelete,
  icon,
  addToMarketplace = Function.prototype,
  isSuperAdmin,
}) => {
  const { t } = useTranslation("common");
  const {
    navigateLabReports,
    navigateMemberManagement,
    navigateLabMarketplace,
    navigateEditLP,
    navigateEditLab,
  } = useRedirect();
  return (
    <MenuButton
      listWithTitleonClick={[
        !isLP && {
          title: "View Report",
          icon: Assessment,
          iconClasses: "c-primary",
          classes: "bg-gray",
          onClick: () => navigateLabReports(data?.slug),
        },
        {
          title: "Manage Members",
          onClick: () =>
            navigateMemberManagement(
              isLP ? COMPONENT_TYPES.LAB_PROGRAMS : COMPONENT_TYPES.LAB,
              data?.slug
            ),
          icon: ManageAccounts,
          iconClasses: "c-primary",
        },
        !isLP &&
          (!isSuperAdmin
            ? {
                title: "Marketplace Setting",
                onClick: () => navigateLabMarketplace(),
                icon: LocalMall,
                iconClasses: "c-primary",
              }
            : {
                title: isTrue(data?.is_pre_build)
                  ? "Remove from Marketplace"
                  : "Add to Marketplace",
                onClick: () => addToMarketplace(data?.slug),
                icon: LocalMall,
                classes: isTrue(data?.is_pre_build) && "c-error",
                iconClasses: !isTrue(data?.is_pre_build) && "c-primary",
              }),
        {
          divider: true,
        },
        {
          title: "Edit",
          onClick: () =>
            isLP ? navigateEditLP(data?.slug) : navigateEditLab(data?.slug),
          icon: Edit,
        },
        {
          title: "Delete",
          onClick: () => onDelete(data?.slug),
          icon: DeleteForever,
          iconClasses: "c-error",
          classes: "c-error",
        },
      ].filter(Boolean)}
      classes={`fs-13 ${classes}`}
      height="30px"
      icon={Boolean(icon)}
    >
      <Icon component={icon || Settings} fontSize="small" />
      {!icon && t("Manage")}
    </MenuButton>
  );
};

export default LabManageOptions;
