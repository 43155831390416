import { ChevronLeft } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import React from "react";

const ReportHeaderLayout = ({
  classes,
  title,
  belowTitleContent,
  hideBackButton,
  rightContent,
  children,
}) => {
  const { t } = useLocalizedTranslation();
  return (
    <Card classes={classes}>
      <Grid container justifyContent="space-between" px={3}>
        <Grid item xs={12} md={9}>
          <div className="flex-1 text-center flex items-center">
            {!hideBackButton && (
              <Button bordered classes="mr-1">
                <ChevronLeft /> {t("Back", "common")}
              </Button>
            )}
            <div>
              <h2> {t(title)}</h2>
              {belowTitleContent}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={3} sx={{ marginTop: { xs: "10px", md: "0px" } }}>
          <div className="float-right">{rightContent}</div>
        </Grid>
      </Grid>
      {children}
    </Card>
  );
};

export default ReportHeaderLayout;
