import {
  Chip,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Tab,
  Tabs,
} from "@mui/material";
import TableComponent from "components/Table";
import { COLORS, COMPONENT_TYPES } from "helpers/utils/constants";
import React, { useEffect, useMemo, useState } from "react";
import Button from "components/Button";
import useRedirect from "helpers/hooks/useRedirect";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import {
  AccessTime,
  AddCircleOutline,
  CalendarMonth,
  ChatBubble,
  CheckBox,
  ChevronRight,
  MoreVert,
} from "@mui/icons-material";
import SecureImage from "components/UI/atoms/SecureImage";
import useUserDashboard from "store/dashboard/user";
import useOrgDashboard from "store/dashboard/org";
import useLabDashboard from "store/dashboard/lab";
import { org } from "assets/svg/yellow";
import { useLocation } from "react-router-dom";
import useAuth from "store/auth/service-hook";
import { extractChallengeDate, isNormalUser } from "helpers/utils/utilities";
import AcceptDeclineButtons from "components/UI/atoms/AcceptDeclineButtons";
import useChallenges from "store/challenges/service-hook";
import moment from "moment";
import MenuButton from "components/Button/MenuButton";
import { manageOrgMenuItems } from "pages/organization/forms/data";
import useOrganization from "store/organization/service-hook";
import useLabs from "store/labs/service-hook";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { useChallengeOptions } from "pages/challenges/listing/ManageChallenges";
import CreationOptionsModal from "components/Modal/CreateOptionsModal";
import { useLabOptions } from "pages/labs/listing/ManageLabs";
import { useResourceCreateOptions } from "pages/resources/ResourceModule/listing/Manage";
import LabManageOptions from "pages/labs/components/LabManageOptions";
import ChalllengeManageOptions from "pages/challenges/components/ChalllengeManageOptions";
import ResourceManageOptions from "pages/resources/ResourceModule/components/ResourceManageOptions";
import useResources from "store/resources/service-hook";

export const colorCombination = {
  [COMPONENT_TYPES.CHALLENGE]: COLORS.GREEN,
  [COMPONENT_TYPES.PROJECT]: COLORS.YELLOW,
  [COMPONENT_TYPES.LAB]: COLORS.BLUE,
  [COMPONENT_TYPES.ORGANIZATION]: COLORS.ORANGE,
};

const STATUS_TYPES = {
  NOT_STARTED: "not_started",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
};

const getTypeFilter = (component, value) => {
  switch (component) {
    case COMPONENT_TYPES.CHALLENGE:
    case COMPONENT_TYPES.LAB:
    case COMPONENT_TYPES.PROJECT:
      return {
        0: "my",
        1: "invites",
        2: "favourite",
      }[value];

    case COMPONENT_TYPES.RESOURCE:
      return {
        0: "my",
        1: "favourite",
      }[value];

    default:
      return null;
  }
};

const DashboardTable = ({ component, title, tabs }) => {
  const [page, setPage] = useState(0);
  const [value, setTab] = useState(0);
  const [type, setType] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [createModal, setCreateModal] = useState(null);
  const challengeCreateOptions = useChallengeOptions(setCreateModal);
  const labCreateOptions = useLabOptions();
  const resourceCreateOptions = useResourceCreateOptions(setCreateModal);
  const {
    state: { user },
  } = useAuth();
  const isNormalUserView = isNormalUser(user);
  const useDashboard =
    type === "lab"
      ? useLabDashboard
      : type === "org"
      ? useOrgDashboard
      : useUserDashboard;

  const { myChallenges, myProjects, myLabs, myResources, myOrganizations } =
    useDashboard();
  const { acceptChallenge, declineChallenge, deleteChallenge } =
    useChallenges();
  const { acceptLab, declineLab, deleteLab } = useLabs();
  const { deleteOrganization } = useOrganization();
  const { deleteResource } = useResources();
  const location = useLocation();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(1);
  const [pages, setPages] = useState(1);
  const {
    navigateCreateProject,
    navigateCreateOrg,
    navigateCreateLabProgram,
    navigateCreateChallengePath,
    navigateChallenge,
    navigateLab,
    navigateProject,
    navigateResource,
    navigateLabs,
    navigateChallenges,
    navigateProjects,
    navigateOrganizations,
    navigateResources,
    navigateOrg,
    navigateCreateResourceC,
    navigateCreateResourceG,
    navigateChats,
    navigateOrganizationReports,
    navigateMemberManagement,
    navigateEditOrg,
    navigateOrgIntegrationSettings,
  } = useRedirect();
  const width = useWindowWidth();
  const { t } = useLocalizedTranslation();

  const getTypeByLocation = () => {
    switch (location.pathname) {
      case "/dashboard":
        return "user";
      case "/dashboard/lab":
        return "lab";
      case "/dashboard/org":
        return "org";
      default:
        return "user";
    }
  };

  useEffect(() => {
    setType(getTypeByLocation());
  }, [location.pathname]);

  useEffect(() => {
    if (!type) return;
    fetchListing();
  }, [value, page, type]);

  const fetchListing = async () => {
    let fetch;
    let args = {};
    switch (component) {
      case COMPONENT_TYPES.CHALLENGE:
        fetch = myChallenges;
        break;
      case COMPONENT_TYPES.LAB:
        fetch = myLabs;
        break;
      case COMPONENT_TYPES.PROJECT:
        fetch = myProjects;
        if (type === "org" || type === "lab")
          args = { type: value === 1 ? "submissions" : "assessment" };

        break;
      case COMPONENT_TYPES.RESOURCE:
        fetch = myResources;
        break;
      case COMPONENT_TYPES.ORGANIZATION:
        fetch = myOrganizations;
        break;
      default:
        fetch = null;
    }
    if (fetch) {
      setLoading(true);
      args = {
        page: page + 1,
        type: getTypeFilter(component, value),
        ...args,
      };
      if (
        ((component === COMPONENT_TYPES.LAB ||
          component === COMPONENT_TYPES.CHALLENGE) &&
          (type === "lab" || type === "org")) ||
        (component === COMPONENT_TYPES.ORGANIZATION && type === "org")
      ) {
        delete args.type;
      }
      if (
        component === COMPONENT_TYPES.RESOURCE &&
        (type === "org" || type === "lab")
      ) {
        delete args.type;
      }
      const { list, total_count, total_pages } = (await fetch(args)) ?? {
        list: [],
        total_count: 0,
        total_pages: 0,
      };
      setList(list || []);
      setTotal(total_count);
      setPages(total_pages);
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleNavigate = (id) => () => {
    if (!component) return;
    switch (component) {
      case COMPONENT_TYPES.LAB:
        navigateLab(id);
        break;
      case COMPONENT_TYPES.CHALLENGE:
        navigateChallenge(id);
        break;
      case COMPONENT_TYPES.RESOURCE:
        navigateResource(id);
        break;
      case COMPONENT_TYPES.PROJECT:
        navigateProject(id);
        break;
      case COMPONENT_TYPES.ORGANIZATION:
        navigateOrg(id);
        break;
      default:
        break;
    }
  };

  const handleBrowseListingNavigate = () => {
    if (!component) return;
    switch (component) {
      case COMPONENT_TYPES.LAB:
        navigateLabs();
        break;
      case COMPONENT_TYPES.CHALLENGE:
        navigateChallenges();
        break;
      case COMPONENT_TYPES.RESOURCE:
        navigateResources();
        break;

      case COMPONENT_TYPES.PROJECT:
        navigateProjects();
        break;
      case COMPONENT_TYPES.ORGANIZATION:
        navigateOrganizations();
        break;
      default:
        break;
    }
  };

  const getDashboardJSXCols = () =>
    [
      component !== COMPONENT_TYPES.ORGANIZATION && {
        title: t("Status"),
        width: "3%",
        render: (rowData) => {
          return (
            <div className="flex justify-center">
              {rowData?.module_progress?.status === STATUS_TYPES.COMPLETED ? (
                <CheckBox className="c-success" fontSize="small" />
              ) : rowData?.module_progress?.status ===
                STATUS_TYPES.IN_PROGRESS ? (
                <AccessTime className="c-orange" fontSize="small" />
              ) : (
                <CalendarMonth className="c-primary" fontSize="small" />
              )}
            </div>
          );
        },
      },
      component === COMPONENT_TYPES.ORGANIZATION && {
        title: t("Logo"),
        width: "20%",
        render: (rowData) => {
          return (
            <div className="flex justify-center">
              <SecureImage
                src={rowData?.profile_image}
                placeholder={org}
                classes="h-30 w-30"
                small
              />
            </div>
          );
        },
      },
      {
        title: t("Title"),
        width:
          component === COMPONENT_TYPES.LAB
            ? "82%"
            : component === COMPONENT_TYPES.CHALLENGE
            ? "50%"
            : component === COMPONENT_TYPES.RESOURCE
            ? "70%"
            : component === COMPONENT_TYPES.ORGANIZATION
            ? "80%"
            : "22%",
        render: (rowData) => {
          return (
            <div>
              <p
                className="maxw-200 fs-14 fw-500 fs-12--mob ml-1 fw-400 text-truncate tracking-tight link"
                onClick={handleNavigate(rowData?.slug)}
              >
                {rowData?.title}
              </p>
              {component === COMPONENT_TYPES.ORGANIZATION ? (
                <div className="tracking-tight c-gray fs-12 ml-1">
                  <p
                    className="flex items-center cursor-pointer"
                    onClick={() =>
                      document
                        .getElementById(rowData.slug)
                        .classList.toggle("hidden")
                    }
                  >
                    Members <ChevronRight fontSize="small" />
                  </p>
                  <Collapse in={true} id={rowData?.slug} className="hidden">
                    <div>
                      {rowData?.organization_people?.length ? (
                        rowData?.organization_people.slice(0, 4)?.map((d) => (
                          <p className="flex-center mt-1">
                            <ChatBubble
                              className="c-success mr-1 cursor-pointer"
                              onClick={() => navigateChats()}
                              fontSize="small"
                            />{" "}
                            {d?.name}
                            {d?.role && <Chip
                              label={d?.role}
                              size="small"
                              className="h-18 fs-11 bg-primary c-white ml-1"
                            />}
                          </p>
                        ))
                      ) : (
                        <p>No Organization Member!</p>
                      )}
                      {rowData?.organization_people?.length > 4 && (
                        <p className="fs-12 c-gray">{t("and more")}...</p>
                      )}
                    </div>
                  </Collapse>
                </div>
              ) : null}
            </div>
          );
        },
      },
      component === COMPONENT_TYPES.ORGANIZATION && {
        title: "",
        width: "5%",
        render: (rowData) => {
          return hovered?.slug === rowData?.slug ? (
            <MenuButton
              icon
              listWithTitleonClick={manageOrgMenuItems(
                rowData,
                navigateOrganizationReports,
                navigateMemberManagement,
                navigateEditOrg,
                navigateOrgIntegrationSettings,
                () => deleteOrganization(rowData?.slug)
              )}
              classes="fs-12"
            >
              <MoreVert fontSize="small" />
            </MenuButton>
          ) : null;
        },
      },
      (component === COMPONENT_TYPES.CHALLENGE ||
        (type === "user" && component === COMPONENT_TYPES.PROJECT)) && {
        title: t("Deadline"),
        width: "25%",
        render: (rowData) => {
          return (
            <p className="flex c-gray fs-13 text-nowrap mr-4">
              {component === COMPONENT_TYPES.PROJECT
                ? moment(rowData?.challenge_details?.due_date).format(
                    "MMM D, YYYY"
                  )
                : extractChallengeDate(rowData)}
            </p>
          );
        },
      },
      component === COMPONENT_TYPES.PROJECT &&
        type !== "user" && {
          title: t("Creator"),
          width: "25%",
          render: (rowData) => {
            return value === 0 && hovered?.slug === rowData?.slug ? (
              <Button
                onClick={() => navigateProjects("", "?browseMode=4")}
                height="25px"
                width="50px"
                classes="fs-12 tracking-tight"
              >
                {t("Assess")}
              </Button>
            ) : (
              <p className="c-gray fs-13 mr-4">
                {rowData?.created_by?.full_name || "N/A"}
              </p>
            );
          },
        },
      component === COMPONENT_TYPES.LAB && {
        title: t("Members"),
        width: "15%",
        render: (rowData) => {
          return (
            <p className="fs-13 text-center">{rowData?.member_count ?? "0"}</p>
          );
        },
      },
      component !== COMPONENT_TYPES.PROJECT &&
        component !== COMPONENT_TYPES.RESOURCE && {
          width: "5%",
          render: (rowData) => {
            return (
              <div className="flex items-center">
                {rowData.active && (
                  <span
                    className="blue__dot bg-primary rounded-50"
                    style={{ width: "8px", height: "8px" }}
                  ></span>
                )}
              </div>
            );
          },
        },
      component === COMPONENT_TYPES.PROJECT && {
        title: t("Associated Challenge"),
        width: "30%",
        render: (rowData) => {
          return (
            <p className="fs-12 c-gray text-truncate-2 tracking-tight">
              {rowData?.challenge_details?.title ?? "N/A"}
            </p>
          );
        },
      },
      component === COMPONENT_TYPES.RESOURCE && {
        title: t("Saved By"),
        width: "25%",
        render: (rowData) => {
          return <p className="fs-13 ml-4 c-gray">{rowData?.likes ?? "0"}</p>;
        },
      },
      (component === COMPONENT_TYPES.LAB ||
        component === COMPONENT_TYPES.CHALLENGE ||
        component === COMPONENT_TYPES.RESOURCE) &&
        type !== "user" && {
          title: "",
          width: "5%",
          render: (rowData) => {
            return hovered?.slug === rowData?.slug ? (
              component === COMPONENT_TYPES.LAB ? (
                <LabManageOptions
                  data={rowData}
                  icon={MoreVert}
                  setOpenConfirmationModal={() => {}}
                  onDelete={deleteLab}
                />
              ) : component === COMPONENT_TYPES.CHALLENGE ? (
                <ChalllengeManageOptions
                  data={rowData}
                  slug={rowData?.slug}
                  t={t}
                  user={user}
                  icon={MoreVert}
                  onDelete={deleteChallenge}
                />
              ) : (
                <ResourceManageOptions
                  data={rowData}
                  onDelete={deleteResource}
                  icon={MoreVert}
                />
              )
            ) : null;
          },
        },
      (component === COMPONENT_TYPES.CHALLENGE ||
        component === COMPONENT_TYPES.LAB) &&
        value === 1 && {
          title: "",
          width: "20%",
          render: (rowData) => {
            return (
              <AcceptDeclineButtons
                onAccept={
                  component === COMPONENT_TYPES.LAB
                    ? acceptLab
                    : acceptChallenge
                }
                onDecline={
                  component === COMPONENT_TYPES.LAB
                    ? declineLab
                    : declineChallenge
                }
                id={rowData?.slug}
                small
                joined={rowData?.joined}
                classes="justify-end"
              />
            );
          },
        },
    ].filter(Boolean);

  const componentMenuList = useMemo(() => {
    const detailsMap = {
      [COMPONENT_TYPES.LAB]: {
        menuItems: [
          {
            title: "Create Lab",
            onClick: () => setCreateModal(COMPONENT_TYPES.LAB),
          },
          { title: "Create Lab Program", onClick: navigateCreateLabProgram },
        ],
        color: COLORS.BLUE,
      },
      [COMPONENT_TYPES.CHALLENGE]: {
        menuItems: [
          {
            title: "Create Challenge",
            onClick: () => setCreateModal(COMPONENT_TYPES.CHALLENGE),
          },
          {
            title: "Create Challenge Path",
            onClick: navigateCreateChallengePath,
          },
        ],
        color: COLORS.GREEN,
      },
      [COMPONENT_TYPES.ORGANIZATION]: {
        onClick: navigateCreateOrg,
        color: COLORS.ORANGE,
      },
      [COMPONENT_TYPES.PROJECT]: {
        onClick: navigateCreateProject,
        color: COLORS.ORANGE,
      },
      [COMPONENT_TYPES.RESOURCE]: {
        color: COLORS.PURPLE,
        menuItems: [
          {
            title: "Create Resource",
            onClick: () => setCreateModal(COMPONENT_TYPES.RESOURCE),
          },
          {
            title: "Create Resource Collection",
            onClick: navigateCreateResourceC,
          },
          {
            title: "Create Resource Group",
            onClick: navigateCreateResourceG,
          },
        ],
      },
    };

    return detailsMap[component] || [];
  }, [component]);

  const onMob = useMemo(() => width < 500, [width]);
  const { menuItems, color } = componentMenuList;

  const handleMenuOpen = (event) => {
    if (menuItems) {
      setAnchorEl(event.currentTarget);
    } else {
      componentMenuList.onClick();
    }
  };

  const handleMenuClose = () => setAnchorEl(null);

  return (
    <div className="px-4">
      {Boolean(createModal) ? (
        <CreationOptionsModal
          open={Boolean(createModal)}
          title={t("How Do You Want To Create A New ${component}?", "common", {
            component:
              createModal === COMPONENT_TYPES.RESOURCE
                ? t("Resource Module")
                : createModal === COMPONENT_TYPES.LAB
                ? t("Lab")
                : createModal === COMPONENT_TYPES.CHALLENGE
                ? t("Challenge")
                : "N/A",
          })}
          options={
            createModal === COMPONENT_TYPES.CHALLENGE
              ? challengeCreateOptions
              : createModal === COMPONENT_TYPES.LAB
              ? labCreateOptions
              : createModal === COMPONENT_TYPES.RESOURCE
              ? resourceCreateOptions
              : []
          }
          onClose={() => setCreateModal(null)}
        />
      ) : null}
      <div className="flex items-center justify-between ">
        {title ? <h2 className="text-nowrap">{t(title)}</h2> : null}
        <Tabs
          aria-label="dashboard tabs"
          value={value}
          onChange={handleChange}
          className="minh-30 ml-1"
          variant={onMob ? "scrollable" : "standard"}
          allowScrollButtonsMobile={onMob}
          sx={{
            "& .MuiTab-root": {
              color: COLORS.GRAY,
              marginRight: 1,
            },
            "& .MuiTabs-indicator": {
              backgroundColor: color,
              height: "4px",
            },
            "& .Mui-selected": {
              color: color,
            },
          }}
        >
          {tabs &&
            tabs.map((tab, i) => (
              <Tab
                key={i}
                value={i}
                label={t(tab)}
                sx={{
                  textTransform: "capitalize",
                }}
                className="fs-15 minh-30 fw-500 fs-12--mob py-0 px-0"
              />
            ))}
        </Tabs>
        {component !== COMPONENT_TYPES.RESOURCE}
        {(!isNormalUserView || component === COMPONENT_TYPES.PROJECT) && (
          <>
            {" "}
            <IconButton onClick={handleMenuOpen}>
              <AddCircleOutline sx={{ color }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {menuItems &&
                menuItems?.map(({ title, onClick }) => (
                  <MenuItem key={title} onClick={onClick}>
                    <p className="fs-14">{t(title)}</p>
                  </MenuItem>
                ))}
            </Menu>
          </>
        )}
      </div>
      <TableComponent
        paginator={false}
        rows={list}
        columns={getDashboardJSXCols()}
        minWidth="400"
        tableClasses="mt-1"
        titleCellClasses="px-1 fw-400 fs-13 tracking-tight c-gray"
        dense
        rowClasses="h-55 minh-55 maxh-55"
        rowCellClasses="px-0 tracking-tight"
        loading={loading}
        loadingContainerClasses="h-270"
        handleChangePage={handleChangePage}
        setHoverdRow={setHovered}
        emptyView={
          <div className="flex-column items-center">
            <p className="c-gray">{`${
              type === "user"
                ? value === 2
                  ? `${t("You have not saved any")} ${component}.`
                  : value === 1
                  ? t("You do not have any invitations yet.")
                  : `${t("You have not joined any")} ${component}`
                : component === COMPONENT_TYPES.PROJECT
                ? value === 1
                  ? `${t("You have not submitted any")} ${component}`
                  : `${t("You have not assessed")} ${component}`
                : `${t("You have not joined any")} ${component}.`
            }`}</p>
            <Button onClick={handleBrowseListingNavigate} classes="mt-3">
              {t("Explore")} {component}
            </Button>
          </div>
        }
        emptyViewClasses="h-270"
      />
      <div className="flex-center mt-2 justify-between w-100p pr-1">
        <p className="fw-500 fs-14 opacity-70 ml-2 mt-1">
          {total === 0
            ? "0 - 0 of 0"
            : `${page * 5 + 1} - ${Math.min((page + 1) * 5, total)} ${t(
                "of"
              )} ${total}`}
        </p>
        {total > 0 && (
          <Pagination
            count={pages}
            page={page + 1}
            onChange={(e, _val) => setPage(_val - 1)}
            sx={{
              ".MuiPaginationItem-root": {
                minWidth: "10px",
                height: "20px",
                margin: "0px",
              },
              ".MuiPaginationItem-page": {
                color: "black",
                "&.Mui-selected": {
                  color: "black",
                  fontWeight: "bold",
                  backgroundColor: "transparent",
                },
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardTable;
