import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useMembers from "store/member-management/service-hook";

const MagicLinkInvite = () => {
  const { id, component } = useParams();
  const { magicLinkInvite } = useMembers();

  useEffect(() => {
    handleInvite();
  }, []);

  const handleInvite = async () => {
    const res = await magicLinkInvite(component, id);
    if (res?.data?.slug) {
      window.location.replace(`/${component}/${res?.data.slug}`);
    } else {
      setTimeout(() => {
        window.location.replace("/explore");
      }, 2000);
    }
  };

  return (
    <DashboardLayout isPrivate loading>
      {null}
    </DashboardLayout>
  );
};

export default MagicLinkInvite;
