import useChallengeReportContext from "../hooks/useChallengeReportContext";
import useChallengeReport from "../../../../store/reports/challenges/service-hooks";
import { useEffect, useMemo, useState } from "react";
import EngagementsReport from "../../components/EngagementsReport";

const ChallengeEngagement = ({ t = (d) => d }) => {
  const { challengeSlug } = useChallengeReportContext();
  const { challengeEngagement } = useChallengeReport();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const fetchEngagement = async () => {
    setLoading(true);
    if (challengeSlug) {
      const response = await challengeEngagement(challengeSlug);
      if (response) {
        setData(response);
      }
    }
    setLoading(false);
  };

  const formattedData = useMemo(() => {
    return [
      {
        title: t("Views"),
        count: data?.views ?? 0,
      },
      {
        title: t("Discussion Posts"),
        count: data?.discussion_posts ?? 0,
      },
      {
        title: t("Saves"),
        count: data?.saves ?? 0,
      },
      {
        title: t("Share"),
        count: data?.share ?? 0,
      },
      {
        title: t("Saved & Started"),
        count: data?.saved_started ?? 0,
      },
      {
        title: t("Shared Started"),
        count: data?.shared_started ?? 0,
      },
      {
        title: t("Skills"),
        count: data?.skills_verified ?? 0,
      },
    ];
  }, [data]);

  /**
   * MOUNTED LIFECYCLE
   */
  useEffect(() => {
    fetchEngagement();
  }, []);

  return (
    <>
      <EngagementsReport
        data={formattedData}
        isLoading={loading}
        title={t("Challenge Engagements")}
      />
    </>
  );
};

export default ChallengeEngagement;
