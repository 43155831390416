import Card from "components/Cards/Card";
import React from "react";
import { zip, image, doc, csv, audio, video, txt } from "assets/png/file-types";
import ListingContainer from "components/UI/organisms/ListingContainer";
import Document from "components/media/Document";
import { Grid } from "@mui/material";
import { isTrue } from "helpers/utils/validators";
import { pdf } from "assets/svg/blue";

const getIconByEx = (ex) => {
  switch (ex) {
    case "doc":
    case "docx":
      return doc;
    case "png":
    case "svg":
    case "jpg":
    case "jpeg":
    case "webp":
    case "html":
      return image;
    case "mp3":
    case "aac":
    case "ogg":
      return audio;
    case "pdf":
      return pdf;
    case "zip":
    case "rar":
      return zip;
    case "csv":
    case "xls":
    case "ods":
    case "xlsx":
      return csv;
    case "mp4":
    case "mkv":
      return video;
    case "txt":
      return txt;
    default:
      return null;
  }
};

export const getIconForFile = (fileName) => {
  if (!fileName) return "";
  const lastDotIndex = fileName?.lastIndexOf(".");
  let extension = "";

  if (lastDotIndex !== -1) {
    extension = fileName.substring(lastDotIndex + 1).toLowerCase();
  }

  return getIconByEx(extension);
};

const ResourceFiles = ({
  files = [],
  setAssetComplete = Function.prototype,
  onDelete,
  isPrivate,
  t = (d) => d,
}) => {
  return (
    <Card classes="mt-5 pb-6">
      <ListingContainer
        isEmpty={!files?.length}
        rowSpacing={2}
        emptyText={t("No files or documents found!")}
        noPagination
        small
        emptySmall={!files?.length}
      >
        {files?.map((d, i) => (
          <Grid
            item
            xs={6}
            md={4}
            lg={3}
            key={i}
            display="flex"
            justifyContent="center"
          >
            <Document
              readOnly={!isPrivate}
              isCompleted={isTrue(d?.completed)}
              onDelete={() => onDelete(d?.id, d?.type)}
              title={d?.title}
              path={d?.path}
              onClick={() => setAssetComplete(d?.id, d?.type)}
            />
          </Grid>
        ))}
      </ListingContainer>
    </Card>
  );
};

export default ResourceFiles;
