import { Done } from "@mui/icons-material";
import { Grid, Skeleton } from "@mui/material";
import Button from "components/Button";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useRedirect from "helpers/hooks/useRedirect";
import { COLORS, COMPONENT_TYPES } from "helpers/utils/constants";
import React, { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { ENDPOINTS } from "store/api/endpoints";
import useUserDashboard from "store/dashboard/user";

const LeftOffCard = () => {
  const {
    navigateExplore,
    navigateLab,
    navigateChallenge,
    navigateResource,
    navigateProject,
    navigateResourceG,
    navigateResourceC,
    navigateLabP,
    navigateChallengePath,
  } = useRedirect();
  const { leftOff } = useUserDashboard();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    fetchLeftOff();
  }, []);

  const fetchLeftOff = async () => {
    setLoading(true);
    const res = await leftOff();
    setData(res);
    setLoading(false);
  };
  const handleNavigate = (id) => () => {
    if (!data?.component) return;
    switch (data?.component) {
      case COMPONENT_TYPES.LAB:
        navigateLab(id);
        break;
      case COMPONENT_TYPES.CHALLENGE:
        navigateChallenge(id);
        break;
      case ENDPOINTS.RESOURCE_MODULE:
        navigateResource(id);
        break;
      case ENDPOINTS.RESOURCE_COLLECTION:
        navigateResourceC(id);
        break;
      case ENDPOINTS.RESOURCE_GROUP:
        navigateResourceG(id);
        break;
      case ENDPOINTS.LAB_PROGRAM:
        navigateLabP(id);
        break;
      case ENDPOINTS.CHALLENGE_PATH:
        navigateChallengePath(id);
        break;
      case COMPONENT_TYPES.PROJECT:
        navigateProject(id);
        break;
      default:
        break;
    }
  };

  return (
    <div className="px-4 h-full">
      <h3>{t("Continue Where You Left Off")}</h3>
      {loading ? (
        <LeftOffCardSkeleton />
      ) : !!data ? (
        <>
          <p className="my-2 fs-14">
            {`We found that you were working on this ${data?.component} last time, do you want to
            continue working on it?`}
          </p>

          <Grid container display="flex" justifyContent="space-between" mt={3}>
            <Grid item sm={5} xs={12}>
              <div className="flex-center justify-center relative">
                <PieChart
                  data={[
                    {
                      value: Number(data?.module_progress?.percentage ?? 0),
                      color: COLORS.BLUE,
                    },
                    {
                      value:
                        100 - Number(data?.module_progress?.percentage ?? 0),
                      color: "#e0e0e0",
                    },
                  ]}
                  lineWidth={18}
                  startAngle={270}
                  className="h-150"
                />
                <div
                  className="absolute fw-700 fs-28"
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: COLORS.BLUE,
                  }}
                >
                  {data?.module_progress?.percentage ?? "0"}%
                </div>
              </div>
            </Grid>
            <Grid item sm={7} xs={12}>
              <h3 className="text-truncate-2">{data?.title}</h3>
              <p
                dangerouslySetInnerHTML={{ __html: data?.description || "-" }}
                className="mt-2 mb-3 text-truncate-2 fs-13 c-gray"
              ></p>
              <Button
                width="140px"
                height="40px"
                color={
                  data?.module_progress?.percentage === "100" ? "green" : "blue"
                }
                onClick={handleNavigate(data?.slug)}
              >
                {data?.module_progress?.percentage === "100" ? (
                  <>
                    {" "}
                    <Done fontSize="small" className="mr-1" /> Completed
                  </>
                ) : (
                  t("Continue")
                )}
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <div className="flex-column h-250 items-center justify-center">
          <p className="c-gray text-center tracking-tight">
            {t(
              "Resume your work right where you left off. If nothing’s in progress, start joining Challenges and Labs now."
            )}
          </p>
          <Button
            width="140px"
            height="40px"
            onClick={navigateExplore}
            classes="mt-3"
          >
            {t("Explore")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default LeftOffCard;

const LeftOffCardSkeleton = () => (
  <Grid item container justifyContent="space-between" mt={1} spacing={2}>
    <Grid item xs={12} mb={2}>
      <Skeleton variant="text" width="100%" height={10} />
      <Skeleton variant="text" width="50%" height={10} />
    </Grid>
    <Grid item sm={5} xs={12}>
      <div className="flex-center justify-center relative">
        <Skeleton variant="circular" width={160} height={160} />
      </div>
    </Grid>
    <Grid item sm={7} xs={12}>
      <Skeleton variant="text" width="50%" height={20} />
      <Skeleton variant="text" width="100%" height={10} className="mt-2" />
      <Skeleton variant="text" width="100%" height={10} className="mt-1" />
      <Skeleton variant="text" width="100%" height={10} className="mt-1" />
      <Skeleton variant="text" width="100%" height={10} className="mt-1" />
      <Skeleton
        variant="rectangular"
        width={150}
        height={30}
        className="mt-4 rounded-5"
      />
    </Grid>
  </Grid>
);
