import Button from "components/Button";
import Checkbox from "components/Input/Checkbox";
import ModalLayout from "components/Modal";
import useRedirect from "helpers/hooks/useRedirect";
import React from "react";
import useAuth from "store/auth/service-hook";

const WelcomePopup = ({ open, onClose, setCompleted = Function.prototype }) => {
  const { navigateProfile } = useRedirect();
  const {
    state: { user },
  } = useAuth();

  return (
    <ModalLayout
      open={open}
      onClose={onClose}
      width="min(600px,96vw)"
      top="20vh"
    >
      <div className="text-center w-80p mt-4 mb-8 fs-15 mx-auto">
        <h1 className="fs-32 fw-600 tracking-tight">
          Welcome to Team Matching
        </h1>
        <p className="mt-6">
          On this page you will be able to match yourself with other Prepr users
          who are currently searching for team members! <br /> <br /> Team
          Matching is most effective when your profile has been completed.
        </p>
      </div>
      <div className="flex-colOnMob items-center my-5 mx-4 justify-between">
        <div className="flex items-center text-nowrap">
          <Checkbox onChange={setCompleted} />
          <span className="fs-14 opacity-80">
            Don't show this message again
          </span>
        </div>
        <Button
          classes="mt-2--mob"
          onClick={() => navigateProfile(user?.username)}
        >
          Complete Profile
        </Button>
      </div>
    </ModalLayout>
  );
};

export default WelcomePopup;
