import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {
  mockRequestGet,
  mockRequestPost,
  mockRequestDelete,
} from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useCareers from "./service-hook";
import { BrowserRouter } from "react-router-dom";


let getSpy;
let postSpy;
let deleteSpy;

let careerId = "1";
let job_id = "1";

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
});

const CareerServicesMock = () => {
  const { relatedJobs, myJobs, getJob, addJob, pinJob, deleteJob } =
    useCareers();

  const BUTTON_LINKS = [
    { name: "relatedJobs", onClick: () => relatedJobs() },
    { name: "myJobs", onClick: () => myJobs() },
    { name: "getJob", onClick: () => getJob(careerId) },
    { name: "addJob", onClick: () => addJob(job_id) },
    { name: "pinJob", onClick: () => pinJob(job_id, false) },
    { name: "deleteJob", onClick: () => deleteJob(careerId) },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Career Services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <CareerServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
    deleteSpy = mockRequestDelete();
  });

  test("relatedJobs", async () => {
    const button = screen.getByTestId("relatedJobs");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CAREER}/${ENDPOINTS.RELATED}/${ENDPOINTS.JOBS}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("myJobs", async () => {
    const button = screen.getByTestId("myJobs");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CAREER}/${ENDPOINTS.JOBS}/${ENDPOINTS.MY}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("getJob", async () => {
    const button = screen.getByTestId("getJob");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CAREER}/${ENDPOINTS.JOBS}/${careerId}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("addJob", async () => {
    const button = screen.getByTestId("addJob");
    userEvent.click(button);
  
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CAREER}/${ENDPOINTS.ADD}?language=en`,
        { job_id: "1", language: "en" },
        headers,
        expect.any(Function)
      )
    );
  });
  
  test("pinJob", async () => {
    const button = screen.getByTestId("pinJob");
    userEvent.click(button);
  
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CAREER}/${ENDPOINTS.JOBS}/${ENDPOINTS.PIN}?language=en`,
        { job_id: "1", pinned: "yes", language: "en" },
        headers,
        expect.any(Function)
      )
    );
  });

  test("deleteJob", async () => {
    const button = screen.getByTestId("deleteJob");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CAREER}/${careerId}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });
});
