import NavTabs from "components/UI/molecules/NavTabs";
import DashboardLayout from "pages/layouts/dashboard";
import styles from "../achievements.module.scss";
import React, { useState, useRef, useEffect } from "react";
import { Avatar, Chip, Grid } from "@mui/material";
import logo from "assets/svg/logo.svg";
import Button from "components/Button";
import parse from "html-react-parser";
import MenuButton from "components/Button/MenuButton";
import goldCupTrophy from "assets/png/goldTrophy.png";
import { COLORS } from "helpers/utils/constants";
import { useParams } from "react-router-dom";
import useAchievements from "store/achievements/service-hook";
import moment from "moment";
import AchievementShareModal from "./AchievementShareModal";
import {
  acheivementTitle,
  getAchievementTypeKey,
  getAddionalDetailsList,
  getDetailsList,
  getDownloadOptions,
} from "../helpers";
import { toast } from "react-toastify";
import { getBaseUrl, isValidUrl } from "helpers/utils/utilities";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import Card from "components/Cards/Card";

const AchievementDetailView = () => {
  const { id } = useParams();
  const { view, setFeatured, download } = useAchievements();
  const [achievement, setAchievement] = useState();
  const [tab, setTab] = useState(0);
  const certificateRef = useRef(null);
  const [modal, setModal] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const baseUrl = getBaseUrl();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    if (!achievement) return;

    const title = document.createElement("title");
    title.textContent = achievement?.award_name;

    const description = document.createElement("meta");
    description.name = "description";
    description.content = achievement?.description;

    const ogTitle = document.createElement("meta");
    ogTitle.property = "og:title";
    ogTitle.textContent = achievement?.award_name;

    const ogDescription = document.createElement("meta");
    ogDescription.property = "og:description";
    ogDescription.content = t(
      "I'm really Excited to Share ,My Latest Achievement with you!"
    );

    const ogImage = document.createElement("meta");
    ogImage.property = "og:image";
    ogImage.content = achievement?.image || logo;

    const ogUrl = document.createElement("meta");
    ogUrl.property = "og:url";
    ogUrl.content = `${baseUrl}/achievements/${achievement?.id}`;

    document.head.appendChild(title);
    document.head.appendChild(description);
    document.head.appendChild(ogTitle);
    document.head.appendChild(ogDescription);
    document.head.appendChild(ogImage);
    document.head.appendChild(ogUrl);

    return () => {
      document.head.removeChild(title);
      document.head.removeChild(description);
      document.head.removeChild(ogTitle);
      document.head.removeChild(ogDescription);
      document.head.removeChild(ogImage);
      document.head.removeChild(ogUrl);
    };
  }, [achievement]);

  useEffect(() => {
    if (id) fetchAchievement();
  }, [id]);

  const fetchAchievement = async () => {
    const res = await view(id);
    setAchievement(res);
  };

  const handleDownload = async (id, format) => {
    setDownloading(true);
    const response = await fetch(await download(id, format));
    if (response.ok) {
      const achievementTitle = achievement?.award_name || t("achievement");
      const fileName = `${achievementTitle
        .replace(/\s+/g, "_")
        .toLowerCase()}.${format === "pdf" ? "pdf" : "png"}`;
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      toast.success("Certificate downloaded successfully!");
    } else {
      toast.error(
        `${t("Failed to download certificate")} ${format.toUpperCase()}.`
      );
    }
    setDownloading(false);
  };

  const handleSetFeatured = async (id, unset = false) => {
    await setFeatured(id, Boolean(unset));
    setAchievement((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        is_featured: unset ? "no" : prev.is_featured === "yes" ? "no" : "yes",
      };
    });
  };

  return (
    <DashboardLayout
      loading={!achievement}
      emptyMessage="Unable to fetch the achievement!"
      isEmpty={achievement?.error}
    >
      <div
        className={`${styles.achievement__box} mx-auto mt-20 mb-4`}
        style={{ minHeight: "80dvh" }}
      >
        <NavTabs
          value={tab}
          onChange={setTab}
          list={["Achievement", "Content", "Additional Data"]}
        />
        <div
          className={`${styles.certificate} bg-white px-4 py-4`}
          ref={certificateRef}
        >
          <div
            className={`${
              styles[`certificate--card-${tab}`]
            } flex-colOnMob items-center justify-around py-4 px-0`}
            style={{ border: "1px solid gray" }}
          >
            {tab === 0 && <Certificate achievement={achievement} t={t} />}
            {tab === 1 && (
              <CertificateDescription achievement={achievement} t={t} />
            )}
            {tab === 2 && <AdditionalDetails achievement={achievement} t={t} />}
          </div>
        </div>
        <div className="flex items-center justify-center mt-4">
          <MenuButton
            listWithTitleonClick={getDownloadOptions(id, handleDownload)}
            loading={downloading}
            width={120}
            disabled={downloading}
          >
            {t("Download")}
          </MenuButton>
          <Button classes="ml-1" onClick={() => setModal(true)} width={120}>
            {t("Share")}
          </Button>
        </div>
      </div>
      <AchievementShareModal
        achievement={achievement}
        open={!!modal}
        onClose={() => setModal(false)}
        setFeatured={handleSetFeatured}
        t={t}
      />
    </DashboardLayout>
  );
};

export default AchievementDetailView;

const Certificate = ({ achievement, t }) => {
  return (
    <div className="flex-column justify-center minh-500 w-100p">
      <div className="flex-colOnMob flex-1 justify-evenly items-center w-100p">
        <Avatar
          src={achievement?.image}
          className="w-220 h-220 bg-white"
          sx={{
            border: !achievement?.image ? `4px solid ${COLORS.BLUE}` : "none",
          }}
        >
          <img src={goldCupTrophy} alt="trophy" className="w-100p h-100p" />
        </Avatar>
        <div className={`${styles["certificate--card-0-Right"]} px-2`}>
          <p className="fs-20 text-left">
            {parse(
              acheivementTitle(
                achievement?.issue_to,
                achievement?.microcopy,
                achievement?.achievement_type,
                achievement?.project_title,
                achievement?.associated_data,
                t,
                achievement
              )
            )}
          </p>
          <div className="flex items-center mt-10 justify-end-start-mob">
            <p className="mr-4 w-100">{t("Issued by")}</p>
            <b>{achievement?.issue_by}</b>
          </div>
          <div className="flex items-center justify-end-start-mob mt-2">
            <p className="mr-4 w-100">{t("Date")}</p>
            <b>{moment(achievement?.issue_date).format("DD/MM/YYYY")}</b>
          </div>
        </div>
      </div>
      <div className="flex-reverse w-90p mt-2">
        <img
          src={
            !!achievement?.achievement_type === "imported"
              ? achievement?.image
              : logo
          }
          height={60}
          alt="logo"
        />
      </div>
    </div>
  );
};

const CertificateDescription = ({ achievement, t }) => {
  return (
    <div className="flex-column w-100p minh-500">
      <div className="flex-column flex-1 justify-center items-center w-100p">
        <div className="flex-column mt-6">
          <Avatar
            src={achievement?.image}
            className="w-150 h-150"
            sx={{
              border: !achievement?.image ? `4px solid ${COLORS.BLUE}` : "none",
            }}
          >
            <img src={goldCupTrophy} className="h-150 w-150" />
          </Avatar>
          <Chip
            label={t(
              getAchievementTypeKey(achievement?.achievement_type),
              "common"
            )}
            sx={{
              backgroundColor: "#83D0A4",
              fontSize: 14,
              color: "white",
              width: "fit-content",
            }}
            className="fw-500 mt-2 mx-auto"
          />
        </div>
        <div className="mt-4 mx-auto">
          {getDetailsList(achievement).map(({ title, value }, i) => (
            <div
              className="flex mt-3 ml-6 fs-14"
              style={{ lineHeight: "110%" }}
              key={i}
            >
              <p className="w-150">{t(title)}:</p>
              <p className="maxw-400 maxh-60 overflow-hidden pr-2">{value}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end w-full mt-2">
        <img
          src={
            !!achievement?.achievement_type === "imported"
              ? achievement?.image
              : logo
          }
          height={80}
          className="mr-4"
          alt="logo"
        />
      </div>
    </div>
  );
};

const AdditionalDetails = ({ achievement, t }) => {
  const [l1, l2] = getAddionalDetailsList(achievement);

  return (
    <div className="flex-column minh-500 w-100p">
      <img
        src={
          !!achievement?.achievement_type === "imported"
            ? achievement?.image
            : logo
        }
        height={60}
        className="mt-6"
        alt="logo"
      />
      <Grid
        container
        spacing={2}
        justifyContent="center"
        className="mt-10 px-10 px-1--mob"
      >
        {[l1, l2]
          .filter((d) => !!d)
          .map((list, i) => (
            <Grid item xs={12} sm={6} key={i}>
              <Card noShadow classes="w-full minh-150 border">
                {list.map(
                  (
                    {
                      title,
                      value,
                      isLink,
                      styles,
                      isAssociated,
                      link,
                      hidden,
                    },
                    i
                  ) => (
                    <div
                      key={i}
                      className={` ${hidden && "hidden"} flex items-center ${
                        isAssociated ? "justify-between" : ""
                      } ${(!l1 || !l2) && "px-2 py-2"}`}
                    >
                      {title && (
                        <p
                          className={`fs-14 ${
                            (!l1 || !l2) && "minw-200"
                          } ${styles}`}
                        >
                          {t(title)}:
                        </p>
                      )}
                      <p
                        onClick={() =>
                          isValidUrl(value)
                            ? window.open(value)
                            : window.open(link)
                        }
                        className={`fs-15 tracking-tight ${
                          (!l1 || !l2) && "c-gray ml-1"
                        } fw-400 ${styles} ${isLink && "link"}`}
                      >
                        {isValidUrl(value) ? value : t(value)}
                      </p>
                    </div>
                  )
                )}
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};
