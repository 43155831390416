import DashboardLayout from 'pages/layouts/dashboard'
import React from 'react'
import NotificationsMainPage from './components/NotificationsMainPage'

const Notifications = () => {
  return (
    <DashboardLayout>
       <NotificationsMainPage/> 
    </DashboardLayout>
  )
}

export default Notifications