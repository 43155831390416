import { AddBox, Delete } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Button from "components/Button";
import React from "react";
import SchemaUIExtractor from "../organisms/SchemaUIExtractor";
import { NUMBER_VALIDATOR, REQUIRED_VALIDATOR } from "helpers/utils/validators";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { SCHEMA_TYPES } from "helpers/utils/constants";
import { toast } from "react-toastify";

export const validateAchievements = (
  achievement,
  isRequired,
  showAchievementConditions,
  t = (d) => d
) => {
  if (
    !isRequired &&
    !achievement?.achievement_image &&
    !achievement?.achievement_name &&
    !achievement?.achievement_points &&
    (!showAchievementConditions || !achievement?.achievement_conditions?.length)
  ) {
    return false;
  }

  if (!achievement?.achievement_name) {
    toast.error(t("Please add achievement title!", "common"));
    return true;
  }

  if (!achievement?.achievement_image) {
    toast.error(t("Please add achievement image!", "common"));
    return true;
  }

  if (!achievement?.achievement_points) {
    toast.error(t("Please add achievement points!", "common"));
    return true;
  }
  if (
    showAchievementConditions &&
    !achievement?.achievement_conditions?.length
  ) {
    toast.error(t("Please add achievement conditions!", "common"));
    return true;
  }

  return false;
};

export const achievement_schema = (
  isRequired,
  isAchievementConditionRequired,
  isAchievementEnabled,
  disableAchievements
) =>
  [
    isAchievementEnabled && {
      title: "Enable Achievement",
      label: "Enabled",
      name: "is_achievement_enabled",
      type: SCHEMA_TYPES.SWITCH,
      lg: 12,
    },
    {
      title: "Achievement Image",
      name: "achievement_image",
      description: "The dimension needs to be 256 x 256 pixels",
      type: SCHEMA_TYPES.FILE,
      dropboxProps: {
        width: "98%",
        fileType: "image",
        dimensions: [256, 256],
      },
      lg: 12,
      validator: !!isRequired && REQUIRED_VALIDATOR,
      disabled: disableAchievements,
    },
    {
      title: "Achievement Name",
      name: "achievement_name",
      type: SCHEMA_TYPES.INPUT,
      lg: 12,
      validator: !!isRequired && REQUIRED_VALIDATOR,
      disabled: disableAchievements,
    },
    {
      title: "Points Earned",
      name: "achievement_points",
      type: SCHEMA_TYPES.INPUT,
      lg: 12,
      validator: !!isRequired && NUMBER_VALIDATOR,
      limit: 4,
      inputProps: {
        type: "number",
      },
      disabled: disableAchievements,
    },
    isAchievementConditionRequired && {
      title: "Achievement Conditions",
      name: "achievement_conditions",
      type: SCHEMA_TYPES.MASTER,
      lg: 12,
      validator: isRequired && REQUIRED_VALIDATOR,
      multi: true,
    },
  ].filter(Boolean);

export const winner_achievement_schema = [
  {
    title: "Achievement Image",
    name: "winner_achievement_image",
    description: "The dimension needs to be 256 x 256 pixels",
    type: "file",
    dropboxProps: {
      width: "98%",
      dimensions: [256, 256],
    },
    lg: 12,
  },
  {
    title: "Achievement Name",
    name: "winner_achievement_name",
    type: "input",
    lg: 12,
  },
  {
    title: "Points Earned",
    name: "winner_achievement_points",
    type: "input",
    lg: 12,
  },
];

export const achievementSlice = (schema = []) => [
  schema.findIndex((d) => d.name === "achievement_image"),
  schema.findIndex((d) => d.name === "achievement_points") + 1,
];

const AddAchievementSection = ({
  title,
  data,
  errors,
  onChange = Function.prototype,
  showWinnerAchievements,
  winnerAchievemnts = [],
  setWinnerAchievements = Function.prototype,
  showAchievementConditions,
  showAchievementEnabled,
  disabled,
}) => {
  const { t } = useLocalizedTranslation("common", true);
  const addWinnerAchievement = () => {
    if (winnerAchievemnts.length >= 5) return;
    setWinnerAchievements((val) => [...val, {}]);
  };

  const onChangeWinnerAch = (e, index) => {
    const { name, value } = e.target;
    winnerAchievemnts[index][name] = value;
    setWinnerAchievements([...winnerAchievemnts]);
  };

  const deleteWinnerAchievement = (index) => {
    winnerAchievemnts.splice(index, 1);
    setWinnerAchievements([...winnerAchievemnts]);
  };

  return (
    <>
      <h3 className="mt-4">{title || t("Add an Achievement")}</h3>
      <Achievement
        schema={achievement_schema(
          !disabled,
          Boolean(showAchievementConditions),
          Boolean(showAchievementEnabled),
          disabled
        )}
        onChange={onChange}
        data={data}
        errors={errors}
        showAchievementEnabled={showAchievementEnabled}
      />
      {winnerAchievemnts.map((a, i) => (
        <Achievement
          schema={winner_achievement_schema}
          key={i}
          onChange={(val) => onChangeWinnerAch(val, i)}
          data={a}
          errors={errors}
          isWinnerAchievement
          index={i}
          deleteWinnerAchievement={deleteWinnerAchievement}
        />
      ))}
      {showWinnerAchievements && (
        <Button classes="mx-auto my-4" bordered onClick={addWinnerAchievement}>
          <AddBox /> {t("Add Achievement")}
        </Button>
      )}
    </>
  );
};

export default AddAchievementSection;

const Achievement = ({
  schema,
  onChange,
  data,
  errors,
  isWinnerAchievement,
  index,
  deleteWinnerAchievement,
  showAchievementEnabled,
}) => {
  const { t } = useLocalizedTranslation();
  return (
    <Grid container columnSpacing={2} className="relative">
      <Grid item xs={12} lg={6}>
        <SchemaUIExtractor
          schema={
            showAchievementEnabled ? schema.slice(1, 2) : schema.slice(0, 1)
          }
          data={data}
          errors={errors}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <SchemaUIExtractor
          schema={
            showAchievementEnabled ? schema.slice(2, 5) : schema.slice(1, 4)
          }
          data={data}
          errors={errors}
          onChange={onChange}
        />
      </Grid>
      {isWinnerAchievement ? (
        <div className="absolute w-100p mb-3 bottom-0 flex justify-end items-right">
          <Button
            width="85px"
            height="26px"
            classes="fs-12 mr-1"
            color="red"
            bordered
            onClick={() => deleteWinnerAchievement(index)}
          >
            {" "}
            <Delete fontSize="small" />
            {t("Delete")}
          </Button>
        </div>
      ) : null}
    </Grid>
  );
};
