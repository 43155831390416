import { Container, Grid } from "@mui/material";
import PasswordValidator from "components/PasswordValidator";
import PassowordInput from "components/UI/atoms/PassowordInput";
import React from "react";

const ChangePassword = ({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  error,
  setError,
  t = (d) => d,
}) => {
  const handleChangePassword = (e) => {
    if (error) setError(false);
    setPassword(e.target.value);
  };

  return (
    <Container px={{ xs: 0, md: 2 }} my={2}>
      <h2>{t("Change Password")}</h2>
      <Grid container item xs={12} md={6} lg={5} my={3}>
        <Grid item xs={12}>
          <PassowordInput
            title="New Password"
            name="password"
            classes="w-full mt-2"
            value={password}
            required
            onChange={handleChangePassword}
            error={error}
            placeholder="Enter New Password"
            t={t}
          />
        </Grid>
        <Grid item xs={12} my={1}>
          <PasswordValidator password={password} />
        </Grid>
        <Grid item xs={12}>
          <PassowordInput
            title="Confirm Password"
            name="confirm_password"
            classes="w-full mt-2"
            required
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            t={t}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChangePassword;
