import { Autocomplete, TextField, Chip, Icon, Box } from "@mui/material";
import React from "react";
import { Search } from "@mui/icons-material";
import { leaf4 } from "assets/svg/green";
import Spin from "components/Spin";
import { COLORS } from "helpers/utils/constants";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const AutoComplete = ({
  keyValueOptions = [],
  prefix,
  renderListItem,
  onSelectOption,
  selectedOptions,
  dataLabelAttr,
  freeText,
  onRemoveChip,
  disableOptions = Function.prototype,
  noOptions,
  onChange = () => { },
  loading,
  emptyText,
  error,
  hideSearchIcon,
  prefixText,
  placeholder,
  disabled = false,
}) => {
  const { t } = useLocalizedTranslation();
  const hanldeKeyDown = (e) => {
    if (!freeText) return;
    if (e.key === "Enter") {
      onSelectOption(e.target.value);
    }
  };

  const handleInputOnBlur = (e) => {
    if (!freeText) return;
    if (e.target.value) {
      onSelectOption(e.target.value);
    }
  };

  return (
    <>

    <Autocomplete
      disabled={disabled}
      multiple
      options={noOptions ? [] : keyValueOptions}
      getOptionLabel={(option) =>
        dataLabelAttr ? option[dataLabelAttr] : option
      }
      disableClearable
      freeSolo={freeText}
      loading={loading}
      clearOnBlur={true}
      onKeyDown={hanldeKeyDown}
      onBlur={handleInputOnBlur}
      onInputChange={onChange}
      disableOptions={disableOptions}
      filterOptions={(options) => options}
      loadingText={
        <Box className="my-10 h-140 flex items-center justify-center w-full">
          <Spin big blue />
        </Box>
      }
      noOptionsText={
        keyValueOptions.length === 0 && (
          <Box className="my-10 w-full flex flex-column items-center justify-center text-center">
            <img src={leaf4} height="100px" width="auto" alt="green leaf" />
            <p className="mt-3 fs-14 opacity-30 tracking-wide">
              {emptyText || t("No data found!")}
            </p>
          </Box>
        )
      }
      renderInput={(params) => (
        <TextField
          helperText={
            error && (
              <p
                style={{ marginLeft: "-11px" }}
                className="c-error fw-500 fs-13"
              >
                {t(error)}
              </p>
            )
          }
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: null,
            startAdornment: (
              <>
                {prefixText ? (
                  <Box component="span" className="mr-1 fw-500">
                    {prefixText}
                  </Box>
                ) : !hideSearchIcon && (
                  <Icon className="c-gray" component={prefix || Search} />
                )}
                {selectedOptions?.map((option, index) => (
                  <Chip
                    key={index}
                    className="ml-1"
                    label={typeof option === "object" && dataLabelAttr ? option[dataLabelAttr] : option}
                    onDelete={() => onRemoveChip(option)}
                    sx={{
                      maxWidth: 150,
                      '.MuiChip-label': {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                ))}
              </>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ":
              {
                border: error ? `1px solid ${COLORS.RED}` : "1px solid #eee",
              },
            ".MuiAutocomplete-inputRoot": {
              padding: "4px 4px 4px 10px!important",
              background: error ? COLORS.RED_OPAQUE : "#fff",
            },
          }}
          placeholder={selectedOptions.length > 0  ? "" : placeholder}
        />
      )}
      renderOption={(props, option) => (
        <div
          component="li"
          {...props}
          onMouseDown={() => onSelectOption(option)}
        >
          {renderListItem(option)}
        </div>
      )}
    />
    </>
  );
};
export default AutoComplete;
