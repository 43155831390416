import { Grid } from "@mui/material";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import React from "react";
import { stepSchema } from "../data";
import Card from "components/Cards/Card";
import Associated from "./Associated";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import ToggleCard from "components/UI/molecules/ToggleCard";
import Sponsers from "../Sponsers";
import ExternalLink from "./ExternalLink";
import FormInformation from "components/UI/atoms/FormInformation";
import UploadScorm from "components/UI/molecules/UploadScorm";
import ExpertReferences from "components/UI/organisms/ExpertReferences";
const ChallengeDetails = ({
  data,
  onChange,
  errors,
  step,
  socialLinks,
  setSocialLinks,
  selectedResource,
  setSelectedResource,
  selectedLab,
  setSelectedLab,
  addedSponsers,
  setAddedSponsers,
  setExternalLinkError,
  externalLinkError,
  isProjectAlreadyPublished,
  setScormLoading,
  experts = [],
  setExperts = Function.prototype,
  t = (d) => d,
}) => {
  const names = ["title", "description", "description_type", "scorm_file"];
  const newSchema = stepSchema(step).map((step) => {
    if (names.includes(step.name)) {
      return {
        ...step,
        locked: isProjectAlreadyPublished,
        disabled: isProjectAlreadyPublished,
      };
    }
    return step;
  });

  return (
    <>
      <FormInformation
        header={t(
          isProjectAlreadyPublished
            ? "The following sections are locked due to submissions:"
            : "The following sections will be locked after the first submission:"
        )}
        lists={["Challenge Title", "Description"]}
        t={t}
      />

      <Grid container px={{ xs: 0, md: 4 }}>
        <Grid item xs={12}>
          <Card
            classes="my-5 container"
            width="min(1300px,90%)"
            centered
            type="secondary"
          >
            <h2 className="fw-600">{t("Required Information")}</h2>
            <div className="my-2">
              <h3>{t("Challenge Details")}</h3>
              <div
                className={`${isProjectAlreadyPublished ? "opacity-50" : ""}`}
              >
                <SchemaUIExtractor
                  schema={newSchema.slice(0, 1)}
                  data={data}
                  errors={errors}
                  onChange={onChange}
                />
              </div>
              <div
                className={`challenge_details__description ${
                  isProjectAlreadyPublished ? "opacity-50" : ""
                }`}
              >
                <SchemaUIExtractor
                  schema={newSchema.slice(1, 2)}
                  data={data}
                  errors={errors}
                  onChange={onChange}
                />
                {data?.description_type === "text" ? (
                  <SchemaUIExtractor
                    schema={newSchema.slice(2, 3)}
                    data={data}
                    errors={errors}
                    onChange={onChange}
                  />
                ) : (
                  <UploadScorm
                    data={data}
                    schema={newSchema.slice(3, 4)}
                    errors={errors}
                    onChange={onChange}
                    setScormLoading={setScormLoading}
                  />
                )}
              </div>
              <SchemaUIExtractor
                schema={newSchema.slice(4, 11)}
                data={data}
                errors={errors}
                onChange={onChange}
              />
            </div>
          </Card>
          <Card
            classes="my-5 container challenge_details__cover-banner"
            width="min(1300px,90%)"
            centered
            type="secondary"
          >
            <h2>{t("Cover Banner")}</h2>
            <div className="my-2">
              <SchemaUIExtractor
                schema={newSchema.slice(12, 13)}
                data={data}
                errors={errors}
                onChange={onChange}
              />
            </div>
          </Card>
          <Card
            classes="my-5 container"
            width="min(1300px,90%)"
            centered
            type="secondary"
          >
            <h3>{t("Associated Labs")}</h3>
            <Associated
              tabs={["Labs", "Lab Programs"]}
              type={COMPONENT_TYPES.LAB}
              selectedValue={selectedLab}
              setSelectedValue={setSelectedLab}
              organizationCheckboxLabel="Organization Labs only"
              t={t}
            />
          </Card>
          <Card
            classes="my-5 container"
            width="min(1300px,90%)"
            centered
            type="secondary"
          >
            <h2>{t("Associated Resources")}</h2>
            <Associated
              tabs={["Resource", "Collections", "Groups"]}
              type={COMPONENT_TYPES.RESOURCE}
              selectedValue={selectedResource}
              setSelectedValue={setSelectedResource}
              organizationCheckboxLabel="Organization Resources only"
              t={t}
            />
          </Card>
          {/* Advance Options */}
          <Card
            classes="my-5 py-6 container"
            width="min(1300px,90%)"
            centered
            type="secondary"
          >
            <h2 className="mb-4">{t("Advanced Options")}</h2>
            <ToggleCard
              title="Skill Sets & Tags"
              description="Add additional Skill Sets and Groups to help"
              t={t}
            >
              <div>
                <Grid container lg={12}>
                  <Grid item lg={6}>
                    <SchemaUIExtractor
                      schema={newSchema.slice(13, 14)}
                      data={data}
                      errors={errors}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <SchemaUIExtractor
                      schema={newSchema.slice(14, 15)}
                      data={data}
                      errors={errors}
                      onChange={onChange}
                    />
                  </Grid>
                </Grid>
              </div>
            </ToggleCard>
            <ToggleCard
              title="Sponsors"
              description="Add the sponsors of your challenge here"
              t={t}
            >
              <Sponsers
                data={data}
                errors={errors}
                onChange={onChange}
                step={step}
                addedSponsers={addedSponsers}
                setAddedSponsers={setAddedSponsers}
                t={t}
              />
            </ToggleCard>
            <ToggleCard
              title="Challenge Details"
              description="Additional options to customize your Challenge"
              t={t}
            >
              <div>
                <SchemaUIExtractor
                  schema={newSchema.slice(15, 21)}
                  data={data}
                  errors={errors}
                  onChange={onChange}
                />
                {/* <SchemaUIExtractor
                  schema={newSchema.slice(20)}
                  data={data}
                  errors={errors}
                  onChange={onChange}
                  disabled={!data?.slug}
                /> */}
                <ExternalLink
                  setSocialLinks={setSocialLinks}
                  socialLinks={socialLinks}
                  description={
                    "You may add additional links related to this lab."
                  }
                  setExternalLinkError={setExternalLinkError}
                  externalLinkError={externalLinkError}
                />
              </div>
            </ToggleCard>
            <ToggleCard
              title="Add Experts"
              description="Highlight the experts for your Challenge"
              t={t}
            >
              <ExpertReferences experts={experts} setExperts={setExperts} />
            </ToggleCard>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ChallengeDetails;
