import React, { useEffect, useMemo, useState } from "react";
import ModalLayout from "components/Modal";
import { Box, Tooltip } from "@mui/material";
import { COLORS } from "helpers/utils/constants";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import { Warning } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import useOrganization from "store/organization/service-hook";
import { isTrue } from "helpers/utils/validators";
import { useTranslation } from "react-i18next";

const CreateOptionsModal = ({ open, title, options, onClose, plan }) => {
  const [myPlan, setMyPlan] = useState(plan);
  const windowWidth = useWindowWidth();
  const onMobile = useMemo(() => windowWidth < 700, []);
  const { t } = useTranslation("common");

  const { user } = useSelector((state) => state[REDUCER_TYPES.AUTH]);
  const { planDetails } = useOrganization();

  useEffect(() => {
    if (!!myPlan) return;
    fetchPlanDetails();
  }, []);

  const fetchPlanDetails = async () => {
    const res = await planDetails(user?.organization_details?.slug);
    setMyPlan(res?.plan);
  };

  const isAIDisabled = (option) =>
    (option?.title?.toString()?.includes("Builder AI") ||
      option?.title?.toString()?.includes("From Resource Modules")) &&
    !isTrue(myPlan?.ai_module);

  return (
    <ModalLayout open={open} width="min(95%, 900px)" onClose={onClose}>
      <div className="flex-column items-center my-8">
        <h2 className={`opacity-90 mt-2 ${onMobile && "text-center"}`}>
          {title}
        </h2>
        <div className="flex-column mt-3" style={{ maxWidth: "700px" }}>
          {options?.map((option) => (
            <Box
              sx={{
                "&:hover": { backgroundColor: COLORS.BLUE_OPAQUE },
                opacity: !option.disabled && !isAIDisabled(option) ? 1 : 0.5,
                cursor:
                  !option.disabled & !isAIDisabled(option)
                    ? "pointer"
                    : "default",
              }}
              onClick={(e) => {
                if (option.disabled || isAIDisabled(option)) return;
                option?.onClick(e);
                if (option.closeOnClick) onClose();
              }}
              key={option?.id}
              className={`${
                onMobile ? "flex-column items-center" : "flex"
              } my-2 py-3 px-3 cursor-pointer rounded-10`}
            >
              <Box
                className={`flex items-center justify-center w-70 h-70 px-3 py-2 rounded-10`}
                sx={{ background: option?.color }}
              >
                <img src={option.icon} alt="icon" width="100%" />
              </Box>
              <Box
                className={`flex-column ${
                  onMobile && "items-center text-center  mt-2"
                } ml-4`}
              >
                <h3
                  className="opacity-80 relative"
                  style={{ width: "fit-content" }}
                >
                  {typeof option.title === "string"
                    ? t(option.title)
                    : option.title}
                  {(isAIDisabled(option) || option?.tooltip) && (
                    <Tooltip
                      title={t(
                        option?.tooltip ??
                          "Please purchase the AI Creation Add On to unlock this feature",
                          
                      )}
                      placement="top"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: 14,
                            bgcolor: COLORS.BLUE_OPAQUE,
                            "& .MuiTooltip-arrow": {
                              color: COLORS.BLUE_OPAQUE,
                            },
                            color: "#404252",
                          },
                        },
                      }}
                    >
                      <Warning
                        className="absolute"
                        sx={{
                          color: COLORS.YELLOW,
                          right: "-30px",
                          top: "2px",
                          opacity: 1,
                          zIndex: 9999,
                        }}
                      />
                    </Tooltip>
                  )}
                </h3>
                <p className="mt-1 opacity-50 tracking-tight">
                  {t(option.description)}
                </p>
              </Box>
            </Box>
          ))}
        </div>
      </div>
    </ModalLayout>
  );
};

export default CreateOptionsModal;
