import { ViewModule, ViewStream } from "@mui/icons-material";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import React from "react";

const GridSwitch = ({ gridType = 1, setGridType }) => {
  const windowWidth = useWindowWidth();
  const onMobile = windowWidth < 700;

  return (
    <div className="flex" data-testid="grid-switch">
      <ViewModule
        fontSize="large"
        className="cursor-pointer"
        color={gridType === 2 ? "primary" : ""}
        onClick={() => setGridType(2)}
      />
      <ViewStream
        fontSize="large"
        className="cursor-pointer"
        color={gridType === 1 ? "primary" : ""}
        onClick={() => !onMobile && setGridType(1)}
      />
    </div>
  );
};

export default GridSwitch;
