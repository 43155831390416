import { Grid, Box, Divider } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import ChatHeader from "./ChatHeader";
import Message from "./Message";
import SendMessageBox from "./SendMessageBox";
import styles from "../chats.module.scss";
import useChats from "store/chat/service-hook";
import { isTrue } from "helpers/utils/validators";
import Spin from "components/Spin";
import { scrollToBottom } from "helpers/utils/utilities";
import moment from "moment";
import useAuth from "store/auth/service-hook";

function groupMessages(messages) {
  const groupedMessages = {};
  const currentDate = moment();

  messages.forEach((message) => {
    const sentDate = moment(new Date(message.sent_at));
    let dateKey;

    if (sentDate.isSame(currentDate, "day")) {
      dateKey = sentDate.format("h:mm A");
    } else if (sentDate.isSame(currentDate.clone().subtract(1, "days"), "day")) {
      dateKey = "Yesterday";
    } else {
      dateKey = sentDate.format("DD MMMM");
    }

    if (!groupedMessages[dateKey]) groupedMessages[dateKey] = [];
    groupedMessages[dateKey].push(message);
  });

  return groupedMessages;
}


let _socket;

const ChatBox = ({
  current,
  data,
  create,
  setCurrent,
  setAllChats = Function.prototype,
  clearNew = Function.prototype,
  tab,
  isChatListEmpty,
  usersCount,
  isTab,
  setInboxCount = Function.prototype,
  setArchiveCount = Function.prototype,
  showUser,
  t = (d) => d,
}) => {
  const [users, setUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState([]);
  const {
    getChat,
    sendMessage,
    deleteChat,
    archive,
    unarchive,
    markSeen,
    deleteMessage,
    socket,
  } = useChats();
  const {
    state: { user },
  } = useAuth();
  const messagesEndRef = useRef(null);
  const isAnnouncement = data?.type === "announcement";

  useEffect(() => {
    if (!data?._id) return;
    _socket = socket();
    _socket
      .private(`message.conversation.${data?._id}`)
      .notification((notification) => {
        const { message, type } = notification;
        if (!!message && user?.email !== message?.sender?.email) {
          if (type === "new.message") {
            setChats((prev) =>
              prev?.some((d) => d?.uuid === message?.uuid)
                ? prev
                : [...prev, { ...message, is_sender: "no" }]
            );
          }
          if (type === "message.deleted") {
            setChats((prev) => prev.filter((m) => m.uuid !== message.uuid));
          }
        }
      });
    return () => _socket.disconnect();
  }, [data?._id]);

  useEffect(() => {
    if (current && current !== "new") fetchChat();
  }, [current]);
  useEffect(() => {
    if (showUser) {
      setUsers([showUser]);
    }
  }, [showUser]);

  useEffect(() => {
    scrollToBottom(messagesEndRef);
  }, [chats]);

  const fetchChat = async () => {
    setLoading("fetch");
    const { list } = await getChat(current);
    if (list) {
      setChats(list || []);
      markSeen(current);
    }
    setLoading(false);
  };

  const handleCreate = async () => {
    setLoading("send");
    let res;
    if (current === "new") {
      res = await create(users.map((d) => d?.username));
      setCurrent(res?.uuid);
      clearNew();
    }
    const sendMessageResponse = await sendMessage(
      current === "new" ? res?.uuid : current,
      {
        message,
        attachment: attachments,
      }
    );
    if (sendMessageResponse) {
      setChats((prev) => [...prev, sendMessageResponse]);
      setMessage("");
      setAttachments([]);
      setUsers([]);
      if (current === "new") {
        setAllChats((prev) => [
          {
            ...res,
            id: res?.uuid,
            last_message: sendMessageResponse,
            seen: "yes",
          },
          ...prev,
        ]);
        tab === 0
          ? setInboxCount((prev) => prev + 1)
          : setArchiveCount((prev) => prev + 1);
      }
    }
    setLoading(false);
  };

  const closeCurrentConversation = () =>
    setAllChats((prev) => {
      const index = prev.findIndex((d) => d?.id === current);
      if (index !== -1) prev.splice(index, 1);
      if (prev.length === 0) {
        setCurrent("new");
      } else {
        setCurrent(prev[0]?.id);
      }
      return prev;
    });

  const handleDeleteChat = async () => {
    setLoading("fetch");
    await deleteChat(current);
    setChats((prev) => prev.filter((d) => d.uuid !== current));
    tab === 0
      ? setInboxCount((prev) => prev - 1)
      : setArchiveCount((prev) => prev - 1);
    setLoading(false);
    closeCurrentConversation();
  };

  const handleArchiveChat = async () => {
    setLoading("fetch");
    tab === 1 ? await unarchive(current) : await archive(current);
    setChats((prev) => prev.filter((d) => d.uuid !== current));

    setInboxCount((prev) => (tab === 0 ? prev - 1 : prev + 1));
    setArchiveCount((prev) => (tab === 0 ? prev + 1 : prev - 1));

    setLoading(false);
    closeCurrentConversation();
  };

  const handleDeleteMessage = async (uuid) => {
    setChats((prev) => prev.filter((d) => d.uuid !== uuid));
    deleteMessage(uuid);
  };

  return (
    <Grid
      item
      xs={12}
      md={8}
      direction="column"
      className="relative items-stretch justify-between h-full"
    >
      {loading === "fetch" ? (
        <Box className="flex h-100p items-center justify-center">
          <Spin blue />
        </Box>
      ) : (
        <>
          <ChatHeader
            title={current === "new" ? t("New Message") : data?.name}
            isNew={current === "new"}
            users={users}
            setUsers={setUsers}
            onDelete={handleDeleteChat}
            onArchive={handleArchiveChat}
            isArchive={tab === 1}
            isTab={isTab}
            isOnline={!isAnnouncement && isTrue(data?.online)}
            isGroup={data?.type === "group"}
            t={t}
          />
          {current !== "new" && (
            <div className={`${styles.chats} overflowY-auto py-10 px-2`}>
              {Object.entries(groupMessages(chats || [])).map(
                ([key, value = []]) => (
                  <>
                    <div key={key} className="w-150 mx-auto text-center my-1">
                      <Divider className="c-gray fs-13">{key}</Divider>
                    </div>
                    {value?.map(
                      (
                        {
                          message,
                          sender,
                          seen_users,
                          attachments,
                          is_sender,
                          uuid,
                        },
                        i
                      ) => (
                        <Message
                          message={message}
                          isReceived={!isTrue(is_sender)}
                          key={uuid}
                          attachments={attachments}
                          isGroup={data?.type === "group"}
                          sender={sender}
                          seenUsers={seen_users}
                          onDelete={() => handleDeleteMessage(uuid)}
                          isSeen={seen_users?.length === usersCount}
                          username={sender?.username}
                        />
                      )
                    )}
                  </>
                )
              )}

              <div ref={messagesEndRef} />
            </div>
          )}

          {!isAnnouncement && (
            <SendMessageBox
              current={current}
              users={users}
              onSend={handleCreate}
              loading={loading}
              message={message}
              setMessage={setMessage}
              attachments={attachments}
              setAttachments={setAttachments}
              t={t}
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default ChatBox;
