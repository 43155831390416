import React from "react";
import cx from "classnames";
import styles from "./progress.module.scss";

const SimpleLine = ({
  points,
  totalPoints,
  description,
  width,
  classes,
  titles,
}) => {
  const percentageWidth = (points / totalPoints) * 100;

  return (
    <div
      data-testid="simple-line"
      style={{ width: width ? width : "100%" }}
      className={cx(classes)}
    >
      {!!titles && (
        <div className="flex justify-between fs-20 fw-500 opacity-80 w-full">
          <p>{titles[0]}</p>
          <p>{titles[1]}</p>
        </div>
      )}
      <div className={cx(styles.simpleLine)}>
        <div
          style={{ width: `${percentageWidth}%` }}
          className={cx(styles.bar, "h-full")}
        >
          <p className="text-nowrap">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default SimpleLine;
