import { Grid } from "@mui/material";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import SocialLinks from "components/UI/molecules/SocialLinks";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import React from "react";
import { stepSchema } from "./data";
import PageTitle from "components/UI/atoms/PageTitle";
import { useTranslation } from "react-i18next";
import ExpertReferences from "components/UI/organisms/ExpertReferences";

const LabDetails = ({
  data,
  errors,
  onChange,
  step,
  socialLinks,
  setSocialLinks,
  editMode,
  experts = [],
  setExperts = Function.prototype,
}) => {
  const { t } = useTranslation("labs");

  return (
    <Grid container px={{ xs: 0, md: 4 }}>
      <PageTitle>{t("Lab Details")}</PageTitle>
      <CreateFormSection>
        <SchemaUIExtractor
          schema={stepSchema(step).slice(0, 1)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
      </CreateFormSection>
      <CreateFormSection title="Lab Information">
        <SchemaUIExtractor
          schema={stepSchema(step).slice(1, 11)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
        <h3 className="mt-4">{t("Skills & Tags")}</h3>
        <SchemaUIExtractor
          schema={stepSchema(step).slice(11, 14)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
        <h3 className="mt-4">{t("Add Experts")}</h3>
        <p>{t("Feature experts for your lab")}</p>
        <ExpertReferences experts={experts} setExperts={setExperts} />
        <SocialLinks
          setSocialLinks={setSocialLinks}
          socialLinks={socialLinks}
          description="You may add additional links related to this lab."
        />
        {editMode && (
          <SchemaUIExtractor
            schema={stepSchema(step).slice(15, 16)}
            data={data}
            errors={errors}
            onChange={onChange}
          />
        )}
      </CreateFormSection>
    </Grid>
  );
};

export default LabDetails;
