import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import { mockRequestGet, mockRequestPost } from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useAchievements from "./service-hook";
import { BrowserRouter } from "react-router-dom";

let getSpy;
let postSpy;

let certificate_id = "1";
let username = "1";
let id = "1";
let format = "pdf";

const headers = {
  Authorization: "Bearer 1",
};
const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
});

const AchievementsServicesMock = () => {
  const { list, view, download, userBasedList, setFeatured } =
    useAchievements();

  const BUTTON_LINKS = [
    { name: "list", onClick: () => list({}) },
    { name: "view", onClick: () => view(certificate_id) },
    { name: "download", onClick: () => download(certificate_id, format) },
    { name: "userBasedList", onClick: () => userBasedList(username, {}) },
    { name: "setFeatured", onClick: () => setFeatured(id, false) },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Achievements services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <AchievementsServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
  });

  test("list", async () => {
    const button = screen.getByTestId("list");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ACHIEVEMENT}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("view", async () => {
    const button = screen.getByTestId("view");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ACHIEVEMENT}/${certificate_id}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("download", async () => {
    const button = screen.getByTestId("download");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ACHIEVEMENT}/${ENDPOINTS.DOWNLOAD}/prepr-${certificate_id}?language=en&format=${format}`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("userBasedList", async () => {
    const button = screen.getByTestId("userBasedList");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ACHIEVEMENT}/${username}/${ENDPOINTS.LIST}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("setFeatured", async () => {
    const button = screen.getByTestId("setFeatured");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ACHIEVEMENT}/${id}/${ENDPOINTS.PIN}?language=en&is_featured=yes`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });
});
