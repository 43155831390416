import { Box, Chip } from "@mui/material";
import Button from "../../Button";
import { user } from "../../../assets/svg/gradient/exports";
import SecureImage from "./SecureImage";
import IconText from "./IconText";
import { achievement } from "assets/svg/yellow";
import useRedirect from "helpers/hooks/useRedirect";

const UserCard = ({
  member,
  hideGiveAwardButton,
  onGiveAwardClick,
  hideRole,
  showRankAndMessageBox,
  t = (d) => d,
}) => {
  const { navigateChats, navigateProfile } = useRedirect();
  return (
    <>
      <Box className="flex">
        <Box className="flex items-center mr-2">
          <SecureImage
            src={member?.image ?? user}
            placeholder={user}
            height={70}
            width={70}
            alt={member.name}
          />
        </Box>
        <Box className="flex-column justify-center mb-1">
          <div className="flex w-165">
            <p
              className={`w-150 fs-14 fw-700 text-nowrap overflow-hidden maxw-95p textOverflow-ellipsis cursor-pointer`}
              onClick={() => member?.username && navigateProfile(member?.username)}
            >
              {(member.name?.trim() || member.email?.trim()) || "N/A"}
            </p>
          </div>
          {!hideRole && (
            <p className="fs-12 c-gray text-truncate maxw-180">
              {member.role || member.position || "N/A"}
            </p>
          )}
          {showRankAndMessageBox && (
            <div>
              <div className="flex-center">
                <Chip
                  className="tracking-tight mr-1 mt-1 h-25 c-primary fs-12 text-truncate w-120"
                  label={`${t("Learn Rank")} ${member?.learn_rank}`}
                />
                <div className="text-truncate w-60">
                  <IconText
                    icon={
                      <img
                        className="w-20 h-25"
                        src={achievement}
                        alt="Achievement Icon"
                      />
                    }
                    text={member?.achievement_count}
                  />
                </div>
              </div>
              <Button
                bordered
                height="15px"
                width="80px"
                classes="fs-12 mt-1 maxh-15"
                onClick={() => navigateChats(`?username=${member?.username}`)}
              >
                {t("Message")}
              </Button>
            </div>
          )}

          {!hideGiveAwardButton && (
            <Button
              classes="w-100 fs-13 fw-700 my-1 px-2 py-0 minh-20 "
              bordered
              onClick={onGiveAwardClick}
            >
              {t("Give Award")}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UserCard;
