import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import ProjectsMainPage from "./components/ProjectsMainPage";
import "./projects.scss";
import { useParams } from "react-router-dom";
import useProjects from "store/projects/service-hook";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const ProjectsSection = () => {
  const [project, setProject] = useState();
  const { id } = useParams();
  const { view } = useProjects();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    if (!id) return;
    handleFetchDetail();
  }, [id]);

  const handleFetchDetail = async () => {
    const res = await view(id);
    if (!res) {
      setProject({ error: true });
      return;
    }
    setProject(res);
  };

  return (
    <DashboardLayout
      loading={!project}
      isEmpty={!!project?.error}
      emptyMessage={t("Not able to access the project!")}
    >
      <ProjectsMainPage project={project} setProject={setProject} t={t} />
    </DashboardLayout>
  );
};

export default ProjectsSection;
