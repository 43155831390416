import React from "react";
import { Pagination } from "@mui/material";

const Paginator = ({
  count = 0,
  classes,
  left,
  right,
  page = 1,
  setPage = Function.prototype,
  leftContent = null,
  mt = 5,
}) => {
  if (!count) return null;

  return (
    <div
      className={`mt-${mt} w-full flex justify-${
        Boolean(leftContent)
          ? "between"
          : left
          ? "start"
          : right
          ? "end"
          : "center"
      } ${classes}`}
      data-testid="paginator"
    >
      {leftContent}
      <Pagination
        count={count}
        color="primary"
        page={page}
        onChange={(e, val) => setPage(val)}
        sx={{
          "& .MuiPaginationItem-root": {
            margin: "0px 10px",
            padding: "0px",
            height: "35px",
            minWidth: "15px",
            "& svg": {
              height: "40px",
            },
            "&.Mui-selected:hover, &:hover": {
              backgroundColor: "transparent",
            },
            "&.Mui-selected, &:active": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiPaginationItem-page": {
            margin: "1px",
            padding: "4px",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#8b8b8b",
          },
          "& .MuiPaginationItem-page.Mui-selected": {
            color: "black",
            backgroundColor: "transparent",
          },
          "& .MuiPaginationItem-icon": {
            color: "#498CCE",
            fontSize: "2.1rem",
          },
        }}
      />
    </div>
  );
};

export default Paginator;
