import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { useEffect, useState } from "react";
import i18next from "i18next";

export const useLocalizedTranslation = (defaultNS = "common", onlyCommon) => {
  const location = useLocation();
  const [namespace, setNamespace] = useState();

  const { t } = useTranslation(
    onlyCommon ? defaultNS : [namespace, defaultNS].filter(Boolean)
  );

  useEffect(() => {
    let _namespace;
    if (onlyCommon) return;
    // eslint-disable-next-line default-case
    switch (true) {
      case location.pathname.includes("/login"):
      case location.pathname.includes("/register"):
      case location.pathname.includes("/forgot-password"):
      case location.pathname === "/":
      case location.pathname.includes("/verify-otp"):
      case location.pathname.includes("/user-onboarding"):
      case location.pathname.includes("/organization-onboarding"):
        _namespace = "auth";
        break;
      case location.pathname.includes("/dashboard"):
        _namespace = "dashboard";
        break;
      case location.pathname.includes("/report/"):
        _namespace = "reports";
        break;
      case location.pathname.includes(`/${COMPONENT_TYPES.LAB}`):
      case location.pathname.includes(`/${COMPONENT_TYPES.LAB_PROGRAMS}`):
      case location.pathname.includes(`/lab-marketplace`):
        _namespace = "labs";
        break;
      case location.pathname.includes(`/${COMPONENT_TYPES.ORGANIZATION}`):
      case location.pathname.includes(`/upgrade-plan`):
        _namespace = "organization";
        break;
      case location.pathname.includes("/careers"):
      case location.pathname.includes("/jobs/"):
      case location.pathname.includes("/skill/"):
        _namespace = "careers";
        break;
      case location.pathname.includes(`/${COMPONENT_TYPES.SKILL}`):
        _namespace = "skills";
        break;
      case location.pathname.includes(`/${COMPONENT_TYPES.RESOURCE}`):
        _namespace = "resource";
        break;
      case location.pathname.includes("/explore"):
        _namespace = "explore";
        break;
      case location.pathname.includes(`/${COMPONENT_TYPES.CHALLENGE}`):
      case location.pathname.includes(`/${COMPONENT_TYPES.CHALLENGE_PATH}`):
        _namespace = "challenge";
        break;
      case location.pathname.includes(`/${COMPONENT_TYPES.PROJECT}`):
      case location.pathname.includes(`/${COMPONENT_TYPES.TEAM_MATCHING}`):
        _namespace = "project";
        break;
      case location.pathname.includes(`/${COMPONENT_TYPES.PROFILE}`):
      case location.pathname.includes("/settings"):
        _namespace = "profile";
        break;
      case location.pathname.includes(`/${COMPONENT_TYPES.ACHIEVEMENT}`):
      case location.pathname.includes(`/leaderboard`):
        _namespace = "achievements";
        break;
      case location.pathname.includes("/announcements"):
      case location.pathname.includes("/chats"):
        _namespace = "inbox";
        break;
    }
    setNamespace(_namespace);
  }, [i18next.language]);

  return {
    t: (children, ns, variables = {}) =>
      t ? t(children, { ns: ns ? ns : namespace, ...variables }) : children,
    namespace,
  };
};
