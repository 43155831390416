import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {
  mockRequestDelete,
  mockRequestGet,
  mockRequestPost,
} from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useLabs from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api";

let postSpy;
let getSpy;
let deleteSpy;

let labId = "1";
let organization_id = "1";
let id = "1";

const payload = {
  request_type: "publish",
  language: "en",
};
const headers = {
  Authorization: "Bearer 1",
  "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com" },
  },
  [REDUCER_TYPES.MASTER]: {},
  [REDUCER_TYPES.ORGANIZATION]: {
    organizations: [{ key: "Prepr", value: 1 }],
  },
});

const LabsServicesMock = () => {
  const {
    createLab,
    fetchLabs,
    fetchLab,
    deleteLab,
    join,
    unJoin,
    follow,
    unFollow,
    favorite,
    unFavorite,
    acceptLab,
    declineLab,
    fetchPublicLabs,
    fetchPublicLab,
    editLab,
    like,
    unLike,
    share,
    fetchLabsSelectList,
    clone,
    aiCreateLabsPreview,
    aiCreateLab,
    verifyLiveEvent,
    fetchLabEventSchedule,
    fetchEventUrl,
    sendLiveEventInvitation,
    getActivity
  } = useLabs();

  const BUTTON_LINKS = [
    { name: "createLab", onClick: () => createLab({}) },
    { name: "fetchLabs", onClick: () => fetchLabs(1) },
    { name: "fetchLab", onClick: () => fetchLab(labId) },
    { name: "deleteLab", onClick: () => deleteLab(labId) },
    { name: "follow", onClick: () => follow(labId) },
    { name: "unFollow", onClick: () => unFollow(labId) },
    { name: "join", onClick: () => join(labId) },
    { name: "unJoin", onClick: () => unJoin(labId) },
    { name: "favorite", onClick: () => favorite(labId) },
    { name: "unFavorite", onClick: () => unFavorite(labId) },
    { name: "acceptLab", onClick: () => acceptLab(labId) },
    { name: "declineLab", onClick: () => declineLab(labId) },
    { name: "fetchPublicLabs", onClick: () => fetchPublicLabs() },
    { name: "fetchPublicLab", onClick: () => fetchPublicLab(labId) },
    { name: "editLab", onClick: () => editLab(labId, {}) },
    { name: "like", onClick: () => like(labId, false) },
    { name: "unLike", onClick: () => unLike(labId, false) },
    { name: "share", onClick: () => share(labId, false) },
    {
      name: "fetchLabsSelectList",
      onClick: () => fetchLabsSelectList(""),
    },
    { name: "clone", onClick: () => clone(labId) },
    { name: "aiCreateLabsPreview", onClick: () => aiCreateLabsPreview({}) },
    { name: "aiCreateLab", onClick: () => aiCreateLab({}) },
    { name: "verifyLiveEvent", onClick: () => verifyLiveEvent({}) },
    { name: "fetchLabEventSchedule", onClick: () => fetchLabEventSchedule(labId) },
    { name: "fetchEventUrl", onClick: () => fetchEventUrl(labId) },
    { name: "sendLiveEventInvitation", onClick: () => sendLiveEventInvitation(labId) },
    { name: "getActivity", onClick: () => getActivity(id) },

  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("LAB services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <LabsServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
    deleteSpy = mockRequestDelete();
  });

  test("createLab", async () => {
    const button = screen.getByTestId("createLab");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${ENDPOINTS.CREATE}?language=en`,
        payload,
        headers,
        expect.any(Function)
      )
    );
  });

  test("fetchLabs", async () => {
    const button = screen.getByTestId("fetchLabs");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("fetchPublicLabs", async () => {
    const button = screen.getByTestId("fetchPublicLabs");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}?language=en&`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("fetchLab", async () => {
    const button = screen.getByTestId("fetchLab");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${labId}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("fetchPublicLab", async () => {
    const button = screen.getByTestId("fetchPublicLab");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${labId}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("editLab", async () => {
    const button = screen.getByTestId("editLab");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.UPDATE}?language=en`,
        {
          ...payload,
          request_type: "publish",
          _method: "PUT",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("deleteLab", async () => {
    const button = screen.getByTestId("deleteLab");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.DELETE}?language=en`,
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
  test("join", async () => {
    const button = screen.getByTestId("join");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.JOIN}?language=en`,
        null,
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
  test("unJoin", async () => {
    const button = screen.getByTestId("unJoin");
    userEvent.click(button);
    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.UNJOIN}?language=en`,
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
  test("follow", async () => {
    const button = screen.getByTestId("follow");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.FOLLOW}?language=en`,
        null,
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
  test("unFollow", async () => {
    const button = screen.getByTestId("unFollow");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.UNFOLLOW}?language=en`,
        null,
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
  test("favorite", async () => {
    const button = screen.getByTestId("favorite");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.FAVOURITE}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
  test("unFavorite", async () => {
    const button = screen.getByTestId("unFavorite");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.UNFAVOURITE}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });

  test("like", async () => {
    const button = screen.getByTestId("like");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.LIKE}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });

  test("unLike", async () => {
    const button = screen.getByTestId("unLike");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.UNLIKE}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });

  test("share", async () => {
    const button = screen.getByTestId("share");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.SHARE}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });

  test("acceptLab", async () => {
    const button = screen.getByTestId("acceptLab");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.INVITATION_MANAGEMENT}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.REQUEST}/${ENDPOINTS.ACCEPT}?language=en`,
        {
          email: "test@gmail.com",
        },
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
  test("declineLab", async () => {
    const button = screen.getByTestId("declineLab");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.INVITATION_MANAGEMENT}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.REQUEST}/${ENDPOINTS.DECLINE}?language=en`,
        {
          email: "test@gmail.com",
        },
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });

  test("fetchLabsSelectList", async () => {
    const button = screen.getByTestId("fetchLabsSelectList");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${ENDPOINTS.GET_LIST}?language=en&search=`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function),
        true
      );
    });
  });

  test("clone", async () => {
    const button = screen.getByTestId("clone");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.CLONE}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      );
    });
  });

  test("aiCreateLabsPreview", async () => {
    const button = screen.getByTestId("aiCreateLabsPreview");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}/${ENDPOINTS.PREVIEW}?language=en`,
        {language:"en"},
        {
            ...headers,
            "Content-Type": "application/json",
        },
        expect.any(Function)
      );
    });
  });

  test("aiCreateLab", async () => {
    const button = screen.getByTestId("aiCreateLab");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}?language=en`,
        {language:"en"},
        {
            ...headers,
            "Content-Type": "application/json",
        },
        expect.any(Function)
      );
    });
  });

  test("verifyLiveEvent", async () => {
    const button = screen.getByTestId("verifyLiveEvent");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.AIRMEET}/${ENDPOINTS.VERIFY_EVENT}?language=en`,
        {language:"en"},
        {
            ...headers,
            "Content-Type": "application/json",
        },
        expect.any(Function)
      );
    });
  });

  test("fetchLabEventSchedule", async () => {
    const button = screen.getByTestId("fetchLabEventSchedule");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.LIVE_EVENT_DETAILS}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      );
    });
  });

  test("fetchEventUrl", async () => {
    const button = screen.getByTestId("fetchEventUrl");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.LIVE_EVENT_URL}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      );
    });
  });

  test("sendLiveEventInvitation", async () => {
    const button = screen.getByTestId("sendLiveEventInvitation");
    userEvent.click(button);

    await waitFor(() => {
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${labId}/${ENDPOINTS.LIVE_EVENT_INVITATION}`,
        {language: "en"},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      );
    });
  });

  test("getActivity", async () => {
    const button = screen.getByTestId("getActivity");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${id}/${ENDPOINTS.HISTORY}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function), 
        true
      );
    });
  });
});
