import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {
  mockRequestGet,
  mockRequestPost,
  mockRequestDelete,
  mockRequestPut,
} from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useMembers from "./service-hook";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { CONTENT_TYPES } from "store/api";
import * as Params from "react-router-dom";

let postSpy;
let getSpy;
let deleteSpy;
let putSpy;

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1 },
  },
  [REDUCER_TYPES.MEMBERS]: {
    networkMembers: [],
  },
});

const payload = {};
const headers = {
  Authorization: "Bearer 1",
};

let email = "1";

jest.mock("react-router-dom");

const MembersServiceMock = () => {
  const {
    getMembers,
    addMembers,
    deleteMembers,
    getRoles,
    downloadSampleCsv,
    getNetworkMembers,
    changeRole,
    deleteAllMembers,
    approveAllPendingRequests,
    handleAcceptMembersJoinRequest,
  } = useMembers();

  const BUTTON_LINKS = [
    {
      name: "getMembers",
      onClick: () => getMembers("prepr", COMPONENT_TYPES.ORGANIZATION),
    },
    {
      name: "getMembersProject",
      onClick: () => getMembers("prepr", COMPONENT_TYPES.PROJECT),
    },
    {
      name: "addMembers",
      onClick: () => addMembers(payload, "prepr", COMPONENT_TYPES.ORGANIZATION),
    },
    {
      name: "addMembersProject",
      onClick: () => addMembers(payload, "prepr", COMPONENT_TYPES.PROJECT),
    },
    {
      name: "deleteMembers",
      onClick: () => deleteMembers([1], "prepr", COMPONENT_TYPES.ORGANIZATION),
    },
    {
      name: "deleteMembersProject",
      onClick: () => deleteMembers([1], "prepr", COMPONENT_TYPES.PROJECT),
    },
    { name: "getRoles", onClick: () => getRoles() },
    { name: "getRolesProject", onClick: () => getRoles() },
    { name: "downloadSampleCsv", onClick: () => downloadSampleCsv() },
    { name: "downloadSampleCsvProject", onClick: () => downloadSampleCsv() },
    { name: "getNetworkMembers", onClick: () => getNetworkMembers() },
    {
      name: "changeRole",
      onClick: () => changeRole(payload, "prepr", COMPONENT_TYPES.ORGANIZATION),
    },
    {
      name: "changeRoleProject",
      onClick: () => changeRole(payload, "prepr", COMPONENT_TYPES.PROJECT),
    },
    {
      name: "deleteAllMembers",
      onClick: () => deleteAllMembers("test", COMPONENT_TYPES.LAB),
    },
    {
      name: "approveAllPendingRequests",
      onClick: () => approveAllPendingRequests("test", COMPONENT_TYPES.LAB),
    },
    {
      name: "handleAcceptMembersJoinRequest",
      onClick: () => handleAcceptMembersJoinRequest(email),
    },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

const spyParams = (type) =>
  jest.spyOn(Params, "useParams").mockReturnValueOnce({
    type: type,
  });

const renderScreen = () => {
  screen = render(
    <Provider store={store}>
      <MembersServiceMock />
    </Provider>
  );
};

describe("organization services", () => {
  beforeEach(() => {
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
    deleteSpy = mockRequestDelete();
    putSpy = mockRequestPut();
  });

  test("getMembers", async () => {
    spyParams(COMPONENT_TYPES.ORGANIZATION);
    renderScreen();
    const button = screen.getByTestId("getMembers");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.MEMBER_MANAGEMENT}/${COMPONENT_TYPES.ORGANIZATION}/prepr?language=en&`,
        {},
        headers
      )
    );
  });

  test("getMembersProject", async () => {
    spyParams(COMPONENT_TYPES.PROJECT);
    renderScreen();
    const button = screen.getByTestId("getMembersProject");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MEMBER_MANAGEMENT}/${COMPONENT_TYPES.PROJECT}/prepr?language=en&`,
        {},
        headers
      )
    );
  });

  test("addMembers", async () => {
    spyParams(COMPONENT_TYPES.ORGANIZATION);
    renderScreen();
    const button = screen.getByTestId("addMembers");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.MEMBER_MANAGEMENT}/${COMPONENT_TYPES.ORGANIZATION}/prepr/${ENDPOINTS.CREATE}?language=en`,
        {
          inviter_id: 1,
          email_status: "scheduled",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("addMembersProject", async () => {
    spyParams(COMPONENT_TYPES.PROJECT);
    renderScreen();
    const button = screen.getByTestId("addMembersProject");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MEMBER_MANAGEMENT}/${COMPONENT_TYPES.PROJECT}/prepr/${ENDPOINTS.CREATE}?language=en`,
        {
          inviter_id: 1,
          email_status: "scheduled",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("deleteMembers", async () => {
    spyParams(COMPONENT_TYPES.ORGANIZATION);
    renderScreen();
    const button = screen.getByTestId("deleteMembers");
    userEvent.click(button);
    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.MEMBER_MANAGEMENT}/${COMPONENT_TYPES.ORGANIZATION}/prepr/${ENDPOINTS.DELETE}?language=en&email[]=1`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("deleteMembersProject", async () => {
    spyParams(COMPONENT_TYPES.PROJECT);
    renderScreen();
    const button = screen.getByTestId("deleteMembersProject");
    userEvent.click(button);
    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MEMBER_MANAGEMENT}/${COMPONENT_TYPES.PROJECT}/prepr/${ENDPOINTS.DELETE}?language=en&email[]=1`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("getRoles", async () => {
    spyParams(COMPONENT_TYPES.ORGANIZATION);
    renderScreen();
    const button = screen.getByTestId("getRoles");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.MEMBER_MANAGEMENT}/${ENDPOINTS.GET_ROLES}?language=en`,
        payload,
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("getRolesProject", async () => {
    spyParams(COMPONENT_TYPES.PROJECT);
    renderScreen();
    const button = screen.getByTestId("getRolesProject");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MEMBER_MANAGEMENT}/${ENDPOINTS.PROJECTS}/${ENDPOINTS.GET_ROLES}?language=en`,
        payload,
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("downloadSampleCsv", async () => {
    spyParams(COMPONENT_TYPES.ORGANIZATION);
    renderScreen();
    const button = screen.getByTestId("downloadSampleCsv");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.MEMBER_MANAGEMENT}/${ENDPOINTS.DOWNLOAD_SMAPLE}?language=en`,
        payload,
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("downloadSampleCsvProject", async () => {
    spyParams(COMPONENT_TYPES.PROJECT);
    renderScreen();
    const button = screen.getByTestId("downloadSampleCsvProject");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MEMBER_MANAGEMENT}/${ENDPOINTS.PROJECTS}/${ENDPOINTS.DOWNLOAD_SMAPLE}?language=en`,
        payload,
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("getNetworkMembers", async () => {
    spyParams(COMPONENT_TYPES.ORGANIZATION);
    renderScreen();
    const button = screen.getByTestId("getNetworkMembers");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.USER}?language=en&search=`,
        {},
        headers
      )
    );
  });

  test("changeRole", async () => {
    spyParams(COMPONENT_TYPES.ORGANIZATION);
    renderScreen();
    const button = screen.getByTestId("changeRole");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.MEMBER_MANAGEMENT}/${COMPONENT_TYPES.ORGANIZATION}/${ENDPOINTS.CHANGE_ROLE}?language=en`,
        {
          slug: "prepr",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.APPLICATION_JSON,
        },
        expect.any(Function)
      )
    );
  });

  test("changeRoleProject", async () => {
    spyParams(COMPONENT_TYPES.PROJECT);
    renderScreen();
    const button = screen.getByTestId("changeRoleProject");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MEMBER_MANAGEMENT}/${COMPONENT_TYPES.PROJECT}/${ENDPOINTS.CHANGE_ROLE}?language=en`,
        {
          slug: "prepr",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.APPLICATION_JSON,
        },
        expect.any(Function)
      )
    );
  });

  test("deleteAllMembers", async () => {
    spyParams(COMPONENT_TYPES.LAB);
    renderScreen();
    const button = screen.getByTestId("deleteAllMembers");
    userEvent.click(button);
    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.MEMBER_MANAGEMENT}/${COMPONENT_TYPES.LAB}/test/${ENDPOINTS.DELETE_ALL_MEMBERS}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("approveAllPendingRequests", async () => {
    spyParams(COMPONENT_TYPES.LAB);
    renderScreen();
    const button = screen.getByTestId("approveAllPendingRequests");
    userEvent.click(button);
    await waitFor(() =>
      expect(putSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.MEMBER_MANAGEMENT}/${COMPONENT_TYPES.LAB}/test/${ENDPOINTS.APPROVE_ALL_PENDING_REQUESTS}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("handleAcceptMembersJoinRequest", async () => {
    spyParams(COMPONENT_TYPES.LAB);
    renderScreen();
    const button = screen.getByTestId("handleAcceptMembersJoinRequest");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.MEMBER_MANAGEMENT}/lab/undefined/${ENDPOINTS.REQUEST}/${ENDPOINTS.ACCEPT}?language=en`,
        { email: "1" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.APPLICATION_JSON,
        },
        expect.any(Function)
      )
    );
  });
});
