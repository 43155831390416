import { payloadBool } from "helpers/utils/utilities";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { serializeObject } from "store/api/utils";
import { resolveResponse } from "store/api/utils";

const useCareers = () => {
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  const headers = {
    Authorization: "Bearer " + token,
  };

  //GET RELATED JOBS
  const relatedJobs = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.CAREER}/${ENDPOINTS.RELATED}/${
        ENDPOINTS.JOBS
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      const { list, total_pages } = res?.data?.data;
      return [list, total_pages];
    }
    return [];
  };

  //GET MY JOBS
  const myJobs = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.CAREER}/${ENDPOINTS.JOBS}/${
        ENDPOINTS.MY
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return [];
  };

  //GET JOB DETAILS
  const getJob = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.CAREER}/${ENDPOINTS.JOBS}/${slug}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  //ADD JOB
  const addJob = async (job_id) => {
    if (!job_id) return;
    const payload = {
      job_id,
      language,
    };
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    await client.post(
      `${ENDPOINTS.CAREER}/${ENDPOINTS.ADD}?language=${language}`,
      payload,
      headers,
      onSuccess
    );
  };

  //PIN JOB
  const pinJob = async (job_id, unPin = false) => {
    if (!job_id) return;
    const payload = {
      job_id,
      pinned: payloadBool(!unPin),
      language,
    };
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    await client.post(
      `${ENDPOINTS.CAREER}/${ENDPOINTS.JOBS}/${ENDPOINTS.PIN}?language=${language}`,
      payload,
      headers,
      onSuccess
    );
  };

  //DELETE JOB
  const deleteJob = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.CAREER}/${slug}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      onSuccess
    );
  };

  return {
    relatedJobs,
    myJobs,
    getJob,
    addJob,
    pinJob,
    deleteJob,
  };
};

export default useCareers;
