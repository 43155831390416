import { OpenInNew } from "@mui/icons-material";
import ListingContainer from "components/UI/organisms/ListingContainer";
import useRedirect from "helpers/hooks/useRedirect";
import useChallenges from "store/challenges/service-hook";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import SecureImage from "components/UI/atoms/SecureImage";
import React, { useEffect, useState } from "react";
import Button from "components/Button";
import ProjectAssessmentDetailsModal from "../../report/challenge/components/ProjectAssessmentDetailsModal";
import { getDefaultImageByType } from "components/Cards/MainProductCard";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import IframePreview from "components/UI/atoms/IframePreview";

const AssessedSubmissions = ({ assessment }) => {
  const [assessed, setAssessed] = useState(null);
  const { fetchProjectAssessedSubmission } = useChallenges();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isViewProjectModalOpen, setIsViewProjectModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const { id } = useParams();
  const { navigateProject } = useRedirect();
  useEffect(() => {
    handleFetchDetail();
  }, [id, page]); // Add 'id' as dependency to ensure data fetch when id changes

  const handleFetchDetail = async () => {
    setLoading(true);
    try {
      const res = await fetchProjectAssessedSubmission(id, {
        page,
      });
      setAssessed(res);
    } catch (error) {
      console.error("Error fetching assessed submissions:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ListingContainer
        count={assessed?.total_pages}
        emptyText="No projects assessed yet!"
        isEmpty={assessed?.list?.length === 0}
        loading={loading}
        classes="my-6"
        columnSpacing={2}
        rowSpacing={2}
        page={page}
        setPage={setPage}
      >
        {assessed?.list?.map((item, index) => (
          <Grid container item xs={12} columnGap={2} spacing={2} key={item?.id}>
            <Grid item xs={3}>
              {item?.media_type?.includes("embedded") ? (
                <IframePreview
                  media={item?.media}
                  placeholder={getDefaultImageByType(COMPONENT_TYPES.CHALLENGE)}
                  title={item?.title}
                  height="100%"
                />
              ) : (
                <SecureImage
                  src={item?.media}
                  classes="w-full h-full"
                  placeholder={getDefaultImageByType(COMPONENT_TYPES.CHALLENGE)}
                  title={item?.title}
                />
              )}
            </Grid>
            <Grid item xs={8}>
              <div className="flex flex-column justify-center">
                <div className="flex items-start justify-between gap-30">
                  <h3
                    onClick={() => navigateProject(item?.slug)}
                    className="link mb-1 tracking-tight fw-500 capitalize text-truncate fs-20 fs-16--mob"
                  >
                    {item?.title}
                  </h3>
                  <Button
                    onClick={() => {
                      setSelectedProject(item);
                      setIsViewProjectModalOpen(true);
                    }}
                    width={"300px"}
                  >
                    View Assessment
                  </Button>
                </div>
                <div>
                  <span className="fw-700">Team members:</span>{" "}
                  {item?.members.map((member, index) => (
                    <span key={member?.user_name}>
                      {member?.full_name}
                      {index < item?.members.length - 1 && ", "}
                    </span>
                  ))}
                </div>
                <div>
                  <span className="fw-700">Assessment Score:</span>{" "}
                  {item.assessment_data?.overall_score} /{" "}
                  {item.assessment_data?.outof_score}
                </div>
              </div>
            </Grid>
          </Grid>
        ))}
      </ListingContainer>
      <ProjectAssessmentDetailsModal
        open={isViewProjectModalOpen}
        onClose={() => {
          setSelectedProject(null);
          setIsViewProjectModalOpen(false);
        }}
        details={selectedProject}
        slug={id}
      />
    </>
  );
};

export default AssessedSubmissions;
