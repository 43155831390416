import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { COLORS } from "helpers/utils/constants";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const MainTabs = ({ classes, tabs, current, setCurrent }) => {
  const { t } = useLocalizedTranslation();
  const windowWidth = useWindowWidth();
  const onTab = windowWidth < 900;

  const handleChange = (event, newValue) => {
    setCurrent(newValue);
  };

  return (
    <Tabs
      value={current}
      onChange={handleChange}
      TabIndicatorProps={{ style: { background: 'transparent' } }}
      variant={onTab ? "scrollable" : "fullWidth"}
      allowScrollButtonsMobile
      className={`bg-white flex-center rounded-10 h-auto shadow ${classes}`}
      sx={{
        '& .MuiTab-root': {
          flex: 1,
          color: "#000",
          fontSize: "16px",
          '&:hover, &:focus': {
            transform: "scale(1)"
          },
          whiteSpace: "nowrap",
        },
        "& .MuiTab-root.Mui-selected": {
          backgroundColor: COLORS.BLUE,
          color: "white",
        },
      }}
    >
      {tabs.map((tab, i) => (
        <Tab key={i} label={
          typeof tab === 'string' ? t(tab) : tab
        }  className="capitalize fs-13--tab tracking-tight h-60 fw-500" />
      ))}
    </Tabs>
  );
};

export default MainTabs;
