import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import { mockRequestGet, mockRequestPost } from "../mocks/request";
import { dispatchMock, initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useGO1 from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api";
import ACTIONS from "./action-types";

let getSpy;
let postSpy;

let go1Id = "1";

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
});

const dispatch = dispatchMock(store);

const Go1ServicesMock = () => {
  const { topics, providers, list, create, play } = useGO1();

  const BUTTON_LINKS = [
    { name: "topics", onClick: () => topics() },
    { name: "providers", onClick: () => providers() },
    { name: "list", onClick: () => list({}) },
    { name: "create", onClick: () => create({}) },
    { name: "play", onClick: () => play(go1Id) },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Go1 Services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <Go1ServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
  });

  test("topics", async () => {
    const button = screen.getByTestId("topics");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.GO1}/${ENDPOINTS.FILTER}/${ENDPOINTS.TOPICS}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [
          {
            key: undefined,
            value: undefined,
            metadata: undefined,
          },
        ],
        type: `${ENDPOINTS.GO1}/${ACTIONS.TOPICS}`,
      })
    );
  });

  test("providers", async () => {
    const button = screen.getByTestId("providers");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.GO1}/${ENDPOINTS.FILTER}/${ENDPOINTS.PROVIDERS}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [
          {
            key: undefined,
            value: undefined,
            metadata: undefined,
          },
        ],
        type: `${ENDPOINTS.GO1}/${ACTIONS.PROVIDERS}`,
      })
    );
  });

  test("list", async () => {
    const button = screen.getByTestId("list");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.GO1}/${ENDPOINTS.RESOURCE}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("create", async () => {
    const button = screen.getByTestId("create");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.GO1}/${ENDPOINTS.RESOURCE}/${ENDPOINTS.CREATE}?language=en`,
        { go1_course: { language: "en" } },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.APPLICATION_JSON,
        },
        expect.any(Function)
      )
    );
  });

  test("play", async () => {
    const button = screen.getByTestId("play");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.GO1}/${ENDPOINTS.RESOURCE}/${go1Id}/${ENDPOINTS.PLAY}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });
});
