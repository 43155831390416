import { ArrowBack } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import useRedirect from "helpers/hooks/useRedirect";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ERROR_MAINTENANACE_TYPES } from "../error-data";
import { useTranslation } from "react-i18next";

const ErrrorInfoMainPage = ({ type: _type }) => {
  const { type = _type } = useParams();
  const [info, setInfo] = useState({});
  const { navigateExplore, navigateBack } = useRedirect();
  const { t } = useTranslation("common");

  useEffect(() => {
    let data = ERROR_MAINTENANACE_TYPES.find((d) => d.type === type);
    if (!data) {
      data = ERROR_MAINTENANACE_TYPES.find((d) => d.type === "404");
    }
    setInfo({ ...data });
  }, [type]);

  return (
    <Card
      centered
      width={`min(80vw,${type === "maintenance" ? "1000px" : "800px"})`}
      height="fit-content"
      classes="mt-20vh column-center mb-20"
    >
      <Grid
        container
        flexDirection="column"
        xs={12}
        md={10}
        className="column-center mx-auto relative"
      >
        <IconButton className="absolute left-0 mt-7" onClick={navigateBack}>
          <ArrowBack />
        </IconButton>
        <h1 className="mt-6 fs-28 text-center tracking-tight">
          {t(info.title)}
        </h1>
        <p className="mt-10 fs-15 c-title">{t(info.description)}</p>
        <Button
          onClick={navigateExplore}
          classes="mt-10 mb-6"
          width="100px"
          height="35px"
        >
          {t("Home")}
        </Button>
      </Grid>
    </Card>
  );
};

export default ErrrorInfoMainPage;
