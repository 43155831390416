import React, { useEffect, useState } from "react";
import ChallengeListing from "./ChallengeListing";
import { COLORS, COMPONENT_TYPES, ROLES } from "helpers/utils/constants";
import { challenge, challengePath } from "assets/svg/green";
import { openai } from "assets/svg/green";
import NuxLayout from "components/UI/molecules/NuxLayout";
import useChallenges from "store/challenges/service-hook";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import useRedirect from "helpers/hooks/useRedirect";
import ManageWrapper from "components/UI/organisms/ManageWrapper";
import useListingHeader from "helpers/hooks/useListingHeader";
import ChallengeTemplate from "../components/ChallengeTemplates";
import { notebook, edit } from "assets/svg/white";
import CreationOptionsModal from "components/Modal/CreateOptionsModal";
import ChallengeBuilder from "pages/resources/ResourceModule/forms/ChallengeBuilder";
import useAuth from "store/auth/service-hook";
import { hasFiltersApplied } from "helpers/utils/utilities";
import { useMemo } from "react";

export const browse_options = [
  { id: 1, title: "Challenges", pageTitle: "Manage Challenges" },
  { id: 2, title: "Challenge Paths", pageTitle: "Manage Challenge Paths" },
];

export const getChallengeNUX = (onClick) => ({
  0: {
    title: "You have no Challenges. Let’s create your first Challenge!",
    nuxCardData: {
      title: "Create Your First Challenge",
      icon: challenge,
      bgColor: COLORS.DARK_GREEN,
      onClick,
    },
  },
  1: {
    title: "You have no Challenge paths. Lets create your first Challenge Path",
    nuxCardData: {
      title: "Create Your First Challenge Path",
      icon: challengePath,
      bgColor: COLORS.DARK_GREEN,
      onClick,
    },
  },
  2: {
    title: "Challenge not found",
    nuxCardData: {
      title: "Challenge not found",
      icon: challenge,
      bgColor: COLORS.DARK_GREEN,
      onClick,
    },
  },
});

export const useChallengeOptions = (setModal) => {
  const { navigateCreateChallenge, navigateCreateChallengeAI } = useRedirect();
  return [
    {
      id: "templates",
      icon: notebook,
      title: "From Challenge Template",
      description:
        "Select from our template library to create your own Challenge",
      color: COLORS.BLUE,
      onClick: () => setModal("templates"),
    },
    {
      id: "ai",
      icon: openai,
      title: "From Challenge Builder AI",
      description:
        "Start your Challenge creation journey in only a few steps with the help of AI.",
      color: COLORS.GREEN,
      onClick: () => navigateCreateChallengeAI(),
    },
    /*{ See https://preprme.atlassian.net/browse/LLAI-2415.
      id: "resources",
      icon: notebook,
      title: "From Resource Modules",
      description:
        "Select from our Resource Module library to create your own Challenge",
      color: COLORS.PURPLE,
      onClick: () => setModal("resources"),
    },*/
    {
      id: "scratch",
      icon: edit,
      title: "From Scratch",
      description: "Want to proceed without a template or AI?",
      color: COLORS.YELLOW,
      onClick: () => navigateCreateChallenge(),
    },
  ];
};

const ManageChallenges = () => {
  const state = useListingHeader();
  const { fetchPrivateChallenges, deleteChallenge } = useChallenges();
  const [challenges, setChallenges] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { selected } = useFilters();
  const { navigateManageChallengePaths } = useRedirect();
  const [modal, setModal] = useState(null);
  const {
    state: { user },
  } = useAuth();
  useEffect(() => {
    if (!!modal) return;
    handleFetchChallenges();
  }, [page, selected, state.sort, state.search]);

  // useEffect(() => {
  //   setPage(1);
  // }, [selected]);

  const handleFetchChallenges = async () => {
    setLoading(true);
    const filters = {
      ...selected,
      page,
      sort_by: state.sort,
      search: state.search,
    };

    if (selected?.multi_type) filters.type = selected.multi_type;
    delete filters.multi_type;

    const [list, count] = await fetchPrivateChallenges({ ...filters });
    setChallenges(list || []);
    if (page > count) setPage(count);
    setPages(count || 0);
    setLoading(false);
  };

  const handleDelete = async (slug) => {
    setLoading(true);
    const res = await deleteChallenge(slug);
    if (!res.error) {
      handleFetchChallenges();
    }
    setLoading(false);
  };

  const handleModalOpen = () => setModal("create");
  const handleModalClose = () => setModal(null);
  const challengeOptions = useChallengeOptions(setModal);
  const noSearchValue = useMemo(() => {
    return !state.search || state.search.trim() === "";
  }, [state.search]);
  const isSuperAdmin =
    user?.roles === ROLES.SUPER_ADMIN ||
    user?.roles === ROLES.CUSTOMER_SUCCESS ||
    user?.roles === ROLES.DEVELOPER;

  return (
    <>
      <ManageWrapper
        {...state}
        browseMode={0}
        browseOptions={browse_options}
        setBrowseMode={(val) => val === 1 && navigateManageChallengePaths()}
        createActionTitle="Create"
        navigateCreate={handleModalOpen}
        component={COMPONENT_TYPES.CHALLENGE}
        createOptions={challengeOptions}
        filters={[
          FILTER_TYPES.STATUS,
          FILTER_TYPES.MULTI_TYPE,
          FILTER_TYPES.DURATION,
          FILTER_TYPES.LEVEL,
          FILTER_TYPES.PRIVACY,
          FILTER_TYPES.SOURCE,
          isSuperAdmin && FILTER_TYPES.TEMPLATE_STATUS,
        ].filter(Boolean)}
      >
        {!hasFiltersApplied(selected) &&
        noSearchValue &&
        !challenges?.length &&
        !loading ? (
          <NuxLayout
            browseMode={0}
            newUserData={getChallengeNUX(() => setModal("create"))}
          />
        ) : (
          <ChallengeListing
            browseMode={0}
            gridType={state.gridType}
            challenges={challenges}
            loading={loading}
            page={page}
            setPage={setPage}
            pageCount={pages}
            setChallenges={setChallenges}
            handleDelete={handleDelete}
            manage
          />
        )}
      </ManageWrapper>
      {modal === "create" ? (
        <CreationOptionsModal
          open={modal === "create"}
          title="How Do You Want To Create A New Challenge?"
          options={challengeOptions}
          onClose={handleModalClose}
        />
      ) : modal === "templates" ? (
        <ChallengeTemplate
          open={modal === "templates"}
          onClose={handleModalClose}
        />
      ) : modal === "resources" ? (
        <ChallengeBuilder
          open={modal === "resources"}
          onClose={handleModalClose}
        />
      ) : null}
    </>
  );
};

export default ManageChallenges;
