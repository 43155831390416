import Card from "components/Cards/Card";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const BrowseOptionsMenu = ({
  options,
  current,
  onChange,
  classes,
  clickDefined,
  counts = {},
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState(current);
  const browseMode = searchParams.get("browseMode");
  const { t } = useLocalizedTranslation("common");
  useEffect(() => {
    if (browseMode) {
      setSelected(+browseMode);
      onChange(+browseMode);
    }
  }, [browseMode]);

  const handleChangeBrowseMode = (i) => {
    searchParams.set("browseMode", i);
    setSearchParams(searchParams);
    onChange(i);
  };
  return (
    <Card data-testid="browse-options-menu" classes={`px-0 py-0 ${classes}`}>
      {options.map((title, i) => (
        <div
          onClick={() =>
            !!clickDefined
              ? clickDefined[i]?.onClick()
              : handleChangeBrowseMode(i)
          }
          key={i}
          className={`fs-15 fw-500 h-50 py-2 px-2 flex items-center w-full cursor-pointer ${
            i === selected && "bg-primary c-white"
          }`}
        >
          {t(title)} { !isNaN(counts[i]) ? `(${counts[i]})` : ""}
        </div>
      ))}
    </Card>
  );
};

export default BrowseOptionsMenu;
