import {
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tab,
  Tabs,
} from "@mui/material";
import ChatListItem from "pages/chats/components/ChatListItem";
import React, { useEffect, useState } from "react";
import useUserDashboard from "store/dashboard/user";
import SecureImage from "components/UI/atoms/SecureImage";
import user from "assets/svg/blue/user.svg";
import moment from "moment";
import { CancelOutlined, CheckCircleOutlined } from "@mui/icons-material";
import { ChallengeDeadlinesCardSkeleton } from "./ChallengeDeadlinesCard";
import { COLORS } from "helpers/utils/constants";
import useRedirect from "helpers/hooks/useRedirect";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useProfile from "../../../store/profile/service-hook";
const TABS = ["Inbox", "Friend Request"];

const Inbox = () => {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const { inboxFriendRequests } = useUserDashboard();
  const [list, setList] = useState([]);
  const { navigateChats, navigateNotifications } = useRedirect();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    fetchinboxFriendRequests();
  }, [value]);

  const fetchinboxFriendRequests = async () => {
    setLoading(true);
    const res = await inboxFriendRequests(value === 0 ? "inbox" : "friend");
    setList(res || []);
    setLoading(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        className="minh-30 ml-1 mb-2 mt-1 mx-4"
        sx={{
          "& .MuiTab-root": {
            color: COLORS.GRAY,
            marginRight: 1,
          },
          "& .MuiTabs-indicator": {
            backgroundColor: COLORS.BLUE,
            height: "4px",
          },
          "& .Mui-selected": {
            color: COLORS.BLUE,
          },
        }}
      >
        {TABS &&
          TABS?.map((tab, i) => (
            <Tab
              key={i}
              value={i}
              label={t(tab)}
              sx={{ textTransform: "capitalize" }}
              className="fs-14 tracking-tight minh-30 fw-500 fs-12--mob py-0 px-0"
            />
          ))}
      </Tabs>
      {loading ? (
        <ChallengeDeadlinesCardSkeleton count={5} />
      ) : list.length > 0 ? (
        <>
          <List disablePadding dense={true} className="mt-2 minh-270 mx-4">
            {list
              .slice(0, 5)
              .map((data, i) =>
                value === 0 ? (
                  <ChatListItem
                    key={i}
                    data={data}
                    selected={i === 1}
                    onChatSelect={() => {}}
                    id={i}
                    bordered
                    dense
                  />
                ) : (
                  <PendingRequests data={data} key={i} refetchRequests={fetchinboxFriendRequests}/>
                )
              )}
          </List>
          <p
            className="w-full mt-2 link text-center fs-14"
            onClick={() =>
              value === 0 ? navigateChats() : navigateNotifications()
            }
          >
            {t("View All")}
          </p>
        </>
      ) : (
        <div className="flex-center c-gray  justify-center w-100p h-250">
          <p>
            {value === 0
              ? t("You do have any message yet")
              : t("You do not have any friend request.")}
          </p>
        </div>
      )}
    </>
  );
};

export default Inbox;

const PendingRequests = ({ data, refetchRequests }) => {
  const { acceptRejectFriendRequest } = useProfile();

  const handleAcceptRejectReq = (id, isReject) => {
    acceptRejectFriendRequest(id, isReject)
        .then(() => {
          refetchRequests();
        });
  };

  return (
    <ListItem
      className="border-b py-1 mx-2"
      secondaryAction={
        <div className="flex flex-column items-center">
          <p className="fs-10 fw-300 opacity-50">
            {moment(data?.updated_at).fromNow()}
          </p>
          <div className="flex">
            <IconButton onClick={() => handleAcceptRejectReq(data?.id, false)}>
              <CheckCircleOutlined className="c-success" />
            </IconButton>
            <IconButton onClick={() => handleAcceptRejectReq(data?.id,true)}>
              <CancelOutlined className="c-error" />
            </IconButton>
          </div>
        </div>
      }
    >
      <ListItemAvatar>
        <SecureImage
          src={data?.profile_image}
          placeholder={user}
          classes="w-35 h-35 mt-2 rounded-100"
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <p className="fs-14 fw-500 fs-13--mob">
            {data?.full_name || "N/A"}{" "}
            <Chip
              className="rounded-4 ml-2 c-white bg-primary fs-10 h-20 capitalize"
              label={data?.role || "N/A"}
            />
          </p>
        }
        secondary={<p className="c-gray fs-13">@{data?.username || "N/A"}</p>}
      />
    </ListItem>
  );
};
