import React, { useEffect, useState } from "react";
import LabListing from "./LabListing";
import useRedirect from "helpers/hooks/useRedirect";
import useLabs from "store/labs/service-hook";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import BrowseWrapper from "components/UI/organisms/BrowseWrapper";
import useListingHeader from "helpers/hooks/useListingHeader";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const browse_options = [
  {
    id: 1,
    title: `Browse`,
    pageTitle: "Browse Labs and Lab Programs",
  },
  {
    id: 2,
    title: `My Labs & Lab Programs`,
    pageTitle: "My Labs & Lab Programs",
  },
  {
    id: 3,
    title: "Pending Invitations",
    pageTitle: "Browse Pending Lab & Lab Programs Invitations",
  },
  {
    id: 4,
    title: "Saved",
    pageTitle: "Browse Saved Labs & Lab Programs",
  },
];

const BrowseLabs = () => {
  const [browseMode, setBrowseMode] = useState(0);
  const state = useListingHeader();
  const [labs, setLabs] = useState(null);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pendingInvites, setPendingInvites] = useState(false);
  const [browseOptions, setBrowseOptions] = useState(browse_options);
  const [loading, setLoading] = useState(true);
  const { navigateManageLabs } = useRedirect();
  const { fetchPublicLabs } = useLabs();
  const { selected } = useFilters();
  const { t } = useLocalizedTranslation("common");

  useEffect(() => {
    const abortController = new AbortController();
    handleFetchLabs(abortController);
    return () => abortController.abort();
  }, [page, selected, state.sort, state.search, browseMode]);

  const handleFetchLabs = async (abortController) => {
    setLoading(true);
    const filters = {
      ...selected,
      page,
      sort_by: state.sort,
      search: state.search,
    };
    if (selected?.privacy)
      filters.privacy = selected?.privacy === "public" ? "no" : "yes";
    if (selected?.multi_type) filters.type = selected.multi_type;
    delete filters.multi_type;
    if (browseMode === 3) {
      filters.social_type = "favourites";
    }
    if (browseMode === 2) {
      filters.request_status = "pending";
    }
    if (browseMode === 1) {
      filters.request_status = "accepted";
    }
    const [list, count, total_count, pending_invites] = await fetchPublicLabs(
      filters
    );
    if (!abortController.signal.aborted) {
      setLabs(list || []);
      setPages(count || 0);
      if (page > count) setPage(count);
      setTotalCount(total_count || 0);
      setLoading(false);
      if (pending_invites !== pendingInvites) {
        setTimeout(() => {
          setPendingInvites(pending_invites);
          setBrowseOptions((prev) =>
            prev.map((d) => ({
              ...d,
              title:
                d?.id === 3 && Boolean(pending_invites)
                  ? `${t(d?.title)} (${pending_invites})`
                  : d?.title,
            }))
          );
        }, 1000);
      }
    }
  };

  return (
    <BrowseWrapper
      browseMode={browseMode}
      browseOptions={browseOptions}
      {...state}
      setBrowseMode={setBrowseMode}
      manageNavigate={navigateManageLabs}
      noAction
      filters={[
        FILTER_TYPES.COMPONENT_TYPE,
        FILTER_TYPES.MULTI_TYPE,
        FILTER_TYPES.DURATION,
        FILTER_TYPES.LEVEL,
        FILTER_TYPES.SKILLS,
        FILTER_TYPES.PRIVACY,
        // FILTER_TYPES.ORGANIZATION
      ]}
    >
      {totalCount > 0 && !loading && (
        <h3 className="mt-3 ml-1">
          {totalCount ?? 0} {t("Results", "common")}
        </h3>
      )}
      <LabListing
        browseMode={browseMode}
        gridType={state.gridType}
        labs={labs}
        loading={loading}
        pageCount={pages}
        page={page}
        setPage={setPage}
        setLabs={setLabs}
      />
    </BrowseWrapper>
  );
};

export default BrowseLabs;
