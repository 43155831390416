import ImageCard from "components/Cards/ImageCard";
import MainPageLayout from "../../layouts/MainPage";
import { COLORS, COMPONENT_TYPES } from "helpers/utils/constants";
import React, { useEffect, useState } from "react";
import ChallengeListing from "pages/challenges/listing/ChallengeListing";
import LabListing from "pages/labs/listing/LabListing";
import ResourceListing from "pages/resources/ResourceModule/listing/ResourceListing";
import MainTabs from "components/UI/organisms/MainTabs";
import jobDefaultImage from "assets/png/job-default.png";
import Card from "components/Cards/Card";
import {
  Chip,
  Box,
  Icon,
  LinearProgress,
  Stack,
  IconButton,
} from "@mui/material";
import Button from "components/Button";
import useCareers from "store/careers/service-hook";
import DashboardLayout from "pages/layouts/dashboard";
import { useParams } from "react-router-dom";
import logo from "assets/svg/logo.svg";
import ReadMore from "components/UI/atoms/ReadMore";
import RelatedJobs from "./RelatedJobs";
import { Bookmark, Work, Help, BookmarkBorder } from "@mui/icons-material";
import LiveJobPostings from "./LiveJobPostings";
import { isTrue } from "helpers/utils/validators";
import BarChart from "components/Graphs/BarChart";
import useRedirect from "helpers/hooks/useRedirect";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const jobs_tabs = ["Related Challenges", "Related Labs", "Related Resources"];

const JobDetailPage = () => {
  const [tab, setTab] = useState(0);
  const [job, setJob] = useState();
  const [loading, setLoading] = useState(false);
  const [showAllRelatedSkills, setShowAllRelatedSkills] = useState(false);
  const [showSkillsMatch, setShowSkillsMatch] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { addJob, deleteJob } = useCareers();
  const { getJob } = useCareers();
  const { id } = useParams();
  const { navigateExplore } = useRedirect();
  const { t } = useLocalizedTranslation("common");

  useEffect(() => {
    if (!id) return;
    fetchJob();
  }, [id]);

  const fetchJob = async () => {
    setLoading(true);
    const res = await getJob(id);
    setJob(res);
    setLoading(false);
  };

  const doUndoAction = async (id, property, doAction, undoAction) => {
    setSaveLoading(true);
    const did = !!job[property] && job[property]?.toLowerCase() !== "no";
    if (did) {
      await undoAction(id);
    } else {
      await doAction(id);
    }
    job[property] = did ? "no" : "yes";
    setJob(job);
    setSaveLoading(false);
  };

  const handleSaveUnsave = (id) => doUndoAction(id, "saved", addJob, deleteJob);
  const canDisplaySkillsPercentage =
    job?.skills?.length > 0 && job?.skills_percentage;
  return (
    <DashboardLayout
      isEmpty={!job}
      loading={loading}
      emptyMessage={t("Cannot fetch the details!")}
      isPrivate
    >
      <MainPageLayout
        classes="mt-5 pt-header"
        firstLgGridSize={4}
        secondLgGridSize={8}
      >
        <div className="left">
          <ImageCard
            image={jobDefaultImage}
            type={COMPONENT_TYPES.JOB}
            imgAbsoluteContent={
              <Box className="flex flex-column items-center justify-between absolute top-0 left-0 h-full w-full">
                <div className={`flex justify-between w-full c-white px-1`}>
                  <img src={logo} height={18} alt="logo" />
                  <div className="fs-10 flex mt-1">
                    <p>JOB</p>
                    <Icon component={Work} sx={{ fontSize: 13 }} />
                  </div>
                </div>
                <div className="h-40 text-center w-full flex items-center justify-center bg-gray">
                  <h2 className="uppercase fs-18 tracking-tight text-center mx-auto text-truncate maxw-90p">
                    {job?.title}
                  </h2>
                </div>
                <p className="text-center fs-10 mb-1 c-white">
                  CO-LEARN. CO-LAB. CO-SOLVE.®
                </p>
              </Box>
            }
          />
          <Card classes="mt-2 overflowX-hidden">
            <Stack direction="row" justifyContent="space-between">
              <h2 className="c-primary">{job?.title}</h2>
              <Button
                classes="mt-2 fs-13"
                bordered
                height="30px"
                onClick={() => handleSaveUnsave(id)}
              >
                <Icon
                  component={isTrue(job?.saved) ? Bookmark : BookmarkBorder}
                  className="c-primary"
                  fontSize="small"
                />
                {isTrue(job?.saved)
                  ? t("Unsave", "common")
                  : t("Save", "common")}
              </Button>
            </Stack>
            <h3 className="mt-4">{t("Description")}</h3>
            <ReadMore limit={6} text={t(job?.description, "common")} />
            <h3 className="mt-4">{t("Related Skills")}</h3>
            {(showAllRelatedSkills
              ? job?.related_skills
              : job?.related_skills?.slice(0, 10)
            )?.map(({ title, id }) => (
              <Chip
                key={id}
                label={title}
                variant="filled"
                className="mr-1 mt-1 bg-gray h-26 fs-13"
              />
            ))}
            <ShowMore
              condition={job?.related_skills?.length > 10}
              setter={setShowAllRelatedSkills}
              expanded={showAllRelatedSkills}
            />
            <Box className="px-2 py-2 my-4 bg-primary--opaque rounded-5">
              <h3 className="mr-2 flex items-center">
                {t("Your Skill Match")} <Help className="ml-1" />
              </h3>
              <div className="flex items-center mt-1">
                <LinearProgress
                  variant="determinate"
                  value={
                    canDisplaySkillsPercentage ? job?.skills_percentage : 0
                  }
                  sx={{
                    width: "80%",
                    height: "8px",
                    borderRadius: "50px",
                    mr: 1,
                  }}
                />
                <p className="fs-12">
                  {canDisplaySkillsPercentage ? job.skills_percentage : 0}%
                </p>
              </div>
              <p className="fs-12 mt-3">
                {t(
                  canDisplaySkillsPercentage
                    ? `Verify following Skills on PreprLabs to increase your skill match percentage.`
                    : `Start joining Challenges and Labs to verify job related Skills to increase your skill match percentage.`
                )}
              </p>

              <div className="mt-2">
                {(showSkillsMatch
                  ? job?.skills
                  : job?.skills?.slice(0, 6)
                )?.map(({ title, id }) => (
                  <Chip
                    key={id}
                    label={title}
                    variant="outlined"
                    className="mr-1 mb-1 h-26"
                  />
                ))}
              </div>
              <ShowMore
                condition={job?.skills?.length > 6}
                setter={setShowSkillsMatch}
                expanded={showSkillsMatch}
              />
            </Box>
            {Boolean(job?.job_trends?.length) && (
              <>
                <h4 className="mt-4 mb-2">{t("Job Trends")}</h4>
                <BarChart
                  color={COLORS.RED}
                  labelFontSize={10}
                  width="100%"
                  height={200}
                  rounded={2}
                  labels={job?.job_trends?.map((d) => d?.date) || []}
                  data={job?.job_trends?.map((d) => d?.trends) || []}
                />
              </>
            )}
          </Card>
          {job?.live_jobs && <LiveJobPostings list={job?.live_jobs} t={t} />}
        </div>
        <div className="right">
          <MainTabs current={tab} setCurrent={setTab} tabs={jobs_tabs} />
          {tab === 0 && (
            <ChallengeListing
              loading={loading}
              challenges={job?.challenges || []}
              emptyMessage={t("No challenges found!")}
              noPagination
              setChallenges={(value) => setJob({ ...job, challenges: value })}
            />
          )}
          {tab === 1 && (
            <LabListing
              labs={job?.labs || []}
              loading={loading}
              noPagination
              gridType={1}
              setLabs={(value) => setJob({ ...job, labs: value })}
            />
          )}
          {tab === 2 && (
            <ResourceListing
              resources={job?.resources}
              emptyMessage={t("No resources found!")}
              noPagination
              setResources={(value) => setJob({ ...job, resources: value })}
            />
          )}
          <div className="flex justify-center mt-2">
            <Button onClick={() => navigateExplore()} bordered>
              {t("Explore More")}{" "}
              {t(
                tab === 0 ? "Challenges" : tab === 1 ? "Labs" : "Resources",
                "common"
              )}
            </Button>
          </div>
          <h3 className="mb-2 ml-1">{t("Related Jobs")}</h3>
          <RelatedJobs jobs={job?.related_jobs} loading={loading} />
        </div>
      </MainPageLayout>
    </DashboardLayout>
  );
};

export default JobDetailPage;

export const ShowMore = ({ setter, condition, expanded }) => {
  const { t } = useLocalizedTranslation("common");
  if (!condition) return null;
  return (
    <p
      className="link text-right fs-12 mt-2"
      onClick={() => setter((val) => !val)}
    >
      {t(expanded ? "SHOW LESS" : "SHOW MORE", "common")}
    </p>
  );
};
