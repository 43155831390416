export const USAGE_TYPES = {
    ORGANIZATION_MEMBER_INVITE: 'organization_member_invite',
    CHALLENGE_MEMBER_INVITE: 'challenge_member_invite',
    LAB_MEMBER_INVITE: 'lab_member_invite'
}


export const UNIFIED_TYPE_USAGE_MAP = {
    "organization": "organization_member_invite",
    "challenge": "challenge_member_invite",
    "lab": "lab_member_invite",
    "lab-program":"lab_program_member_invite"
}

export const INVITATION_ALLOWED_TYPES = ["organization", "lab", "challenge", "lab-program"];
