const ACTIONS = {
  LOGIN: "login",
  REGISTER: "register",
  LOGOUT: "logout",
  SET_USER: "set-user",
  SET_LANGUAGE: "set-language",
  ORGANIZATIONS: "organizations",
  SET_ORGANIZATION: "set-organization",
  ORG_LIST: "orgListResponse",
};

export default ACTIONS;
