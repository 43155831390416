import React, { useEffect, useMemo, useState } from "react";
import { ChartColors, ComponentMappings } from "../data";
import Card from "../../../components/Cards/Card";
import { Grid } from "@mui/material";
import Select from "../../../components/Input/Select";
import useResourceReport from "../../../store/reports/resource/service-hooks";
import SearchableSelect from "../../../components/Input/SearchableSelect";
import DoughnutChartCard from "./chart/DoughnutChartCard";
import { formattedCount } from "../../../helpers/utils/utilities";
import {REPORT_PER_PAGE} from "../../../helpers/utils/constants";

const ProgressByResource = (props) => {
  /**
   * PROPS
   */
  const {
    fetchResources,
    fetchResourceCollections,
    fetchResourceGroups,
    slug,
    noDataUI,
    t = (d) => d,
  } = props;

  /**
   * HOOKS
   */
  const {
    resourceCollectionMemberProgress,
    resourceGroupMemberProgress,
    resourceModuleMemberProgress,
  } = useResourceReport();

  /**
   * STATE
   */
  const [activeState, setActiveState] = useState(
    ComponentMappings?.all_resources
  );
  const [components] = useState([
    {
      value: t("Resource Modules"),
      key: ComponentMappings?.all_resources,
    },
    {
      value: t("Resource Collections"),
      key: ComponentMappings?.all_resource_collections,
    },
    {
      value: t("Resource Groups"),
      key: ComponentMappings?.all_resource_groups,
    },
  ]);

  const [chartData, setChartData] = useState();
  const [loadingResources, setLoadingResources] = useState(true);
  const [loadingChartData, setLoadingChartData] = useState(false);
  const [activeResource, setActiveResource] = useState();
  const [activeResourceSlug, setActiveResourceSlug] = useState();

  /**
   *
   * @param search
   * @returns {Promise<*|*[]>}
   */
  const loadResourceModule = async (search = "") => {
    if (slug && activeState === ComponentMappings?.all_resources) {
      const response = await fetchResources(slug, 1, REPORT_PER_PAGE, search);
      if (response?.data?.length) {
        const first = response?.data[0];
        if (first && !activeResourceSlug) {
          setActiveResource({
            key: first?.slug,
            value: first?.title,
          });
          setActiveResourceSlug(first?.slug);
        }
      }
      setLoadingResources(false);
      return (
        response?.data?.map((item) => {
          return {
            id: item?.slug,
            title: item?.title,
          };
        }) ?? []
      );
    }
  };

  /**
   *
   * @param search
   * @returns {Promise<*|*[]>}
   */
  const loadResourceCollection = async (search = "") => {
    if (slug && activeState === ComponentMappings?.all_resource_collections) {
      const response = await fetchResourceCollections(slug, 1, REPORT_PER_PAGE, search);
      if (response?.data.length) {
        const first = response?.data[0];
        if (first && !activeResourceSlug) {
          setActiveResource({
            key: first?.slug,
            value: first?.title,
          });
          setActiveResourceSlug(first?.slug);
        }
      }
      setLoadingResources(false);
      return (
        response?.data?.map((item) => {
          return {
            id: item?.slug,
            title: item?.title,
          };
        }) ?? []
      );
    }
  };

  /**
   *
   * @param search
   * @returns {Promise<*|*[]>}
   */
  const loadResourceGroups = async (search = "") => {
    if (slug && activeState === ComponentMappings?.all_resource_groups) {
      const response = await fetchResourceGroups(slug, 1, REPORT_PER_PAGE, search);
      if (response?.data?.length) {
        const first = response?.data[0];
        if (first && !activeResourceSlug) {
          setActiveResource({
            key: first?.slug,
            value: first?.title,
          });
          setActiveResourceSlug(first?.slug);
        }
      }
      setLoadingResources(false);
      return (
        response?.data?.map((item) => {
          return {
            id: item?.slug,
            title: item?.title,
          };
        }) ?? []
      );
    }
  };

  /**
   *
   * @returns {Promise<void>}
   */
  const fetchChartData = async () => {
    const functionMap = {
      [ComponentMappings?.all_resources]: resourceModuleMemberProgress,
      [ComponentMappings?.all_resource_groups]: resourceGroupMemberProgress,
      [ComponentMappings?.all_resource_collections]:
        resourceCollectionMemberProgress,
    };
    setLoadingChartData(true);
    if (activeResourceSlug) {
      const response = await functionMap[activeState](activeResourceSlug);
      if (response) {
        setChartData(response);
      }
    }
    setLoadingChartData(false);
  };

  /**
   * MOUNTED STATE
   */
  useEffect(() => {
    fetchChartData();
  }, [activeResourceSlug]);

  /**
   * MEMORIZED DATA
   * @type {unknown}
   */
  const ChartData = useMemo(() => {
    if (chartData) {
      const labels = ["Completed", "In progress", "Not Started"];
      const statsData = [
        chartData?.completed ?? 0,
        chartData?.in_progress ?? 0,
        chartData?.not_started ?? 0,
      ];
      return {
        labels: labels,
        datasets: [
          {
            label: t("Member progress"),
            data: statsData,
            backgroundColor: ChartColors.resources,
          },
        ],
      };
    }
    return null;
  }, [chartData]);

  const legendData = useMemo(() => {
    return {
      data: [
        {
          color: ChartColors.resources[0],
          title: "Completed",
          count: chartData?.completed ?? 0,
        },
        {
          color: ChartColors.resources[1],
          title: "In Progress",
          count: chartData?.in_progress ?? 0,
        },
        {
          color: ChartColors.resources[2],
          title: "Not Started",
          count: chartData?.not_started ?? 0,
        },
        {
          title: t("Total"),
          renderer: (data) => (
            <p style={{ fontWeight: "bold" }} className={"mt-1"}>
              {data}
            </p>
          ),
          count:
            (chartData?.completed ?? 0) +
            (chartData?.in_progress ?? 0) +
            (chartData?.not_started ?? 0),
        },
      ],
      total:
        (chartData?.completed ?? 0) +
        (chartData?.in_progress ?? 0) +
        (chartData?.not_started ?? 0),
    };
  }, [chartData]);

  return (
    <>
      <Card classes={"mt-2"}>
        <h4 className="bold fs-15 mb-4">
          {t("Progress by Resource Module/Group/Collection")}
        </h4>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Select
              borderedIcon
              value={activeState}
              onChange={(e) => {
                setActiveState(e.target.value);
                setActiveResource(null);
                setActiveResourceSlug(null);
                setChartData(null);
                setLoadingResources(true);
              }}
              keyValues={components}
              width={"100%"}
              height={40}
              t={t}
            />
          </Grid>
          <Grid item xs={7}>
            {ComponentMappings?.all_resources === activeState ? (
              <SearchableSelect
                key={activeResourceSlug}
                width={"100%"}
                value={activeResource}
                placeholder={"Search for an option"}
                onChange={(event) => {
                  setActiveResource(event?.target?.value);
                  setActiveResourceSlug(event?.target?.value?.key);
                }}
                fetch={[loadResourceModule, ""]}
                t={t}
              />
            ) : (
              ""
            )}
            {ComponentMappings?.all_resource_groups === activeState ? (
              <SearchableSelect
                width={"100%"}
                value={activeResource}
                placeholder={"Select option"}
                onChange={(event) => {
                  setActiveResource(event?.target?.value);
                  setActiveResourceSlug(event?.target?.value?.key);
                }}
                fetch={[loadResourceGroups, ""]}
                t={t}
              />
            ) : (
              ""
            )}
            {ComponentMappings?.all_resource_collections === activeState ? (
              <SearchableSelect
                width={"100%"}
                value={activeResource}
                placeholder={"Select option"}
                onChange={(event) => {
                  setActiveResource(event?.target?.value);
                  setActiveResourceSlug(event?.target?.value?.key);
                }}
                fetch={[loadResourceCollection, ""]}
                t={t}
              />
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={12}>
            <DoughnutChartCard
              noCard
              chartId={"resource-member-progress"}
              data={ChartData ? ChartData : null}
              isLoading={loadingChartData || loadingResources}
              noDataUI={noDataUI}
              legend={
                <>
                  <ul
                    className={"flex flex-column w-full fs-12 p-0"}
                    style={{ gap: "5px" }}
                  >
                    {legendData?.data?.map((item) => (
                      <li
                        key={item?.title}
                        className={"flex w-full items-center"}
                      >
                        <div className={"flex items-center"}>
                          <div
                            className={"w-15 h-15"}
                            style={{ backgroundColor: item?.color ?? "" }}
                          ></div>
                          <span className={"ml-2"}>
                            {item?.renderer
                              ? item?.renderer(t(item?.title, "common"))
                              : t(item?.title,"common")}
                          </span>
                        </div>
                        <span className={"ml-auto mr-2"}>
                          {formattedCount(item?.count)}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              }
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ProgressByResource;
