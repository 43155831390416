import { Grid } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import React from "react";
import { Delete } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { isValidUrl } from "helpers/utils/utilities";
import MasterSelect from "components/UI/organisms/MasterSelect";

export const externalLinkValidate = (links, t = (d) => d) => {
  let error;
  if (links.some((link) => !link.type || !link.url)) {
    error = t("Please enter both the external link type and URL.");
    toast.error(error);
    return error;
  }
  if (links.some((link) => !isValidUrl(link.url))) {
    error = t("Please enter a valid URL.");
    toast.error(error);
    return error;
  }
};

const ExternalLink = ({ socialLinks, setSocialLinks, description }) => {
  const { t } = useLocalizedTranslation();
  const addSocialLink = () => {
    setSocialLinks((val) => [...val, { type: null, url: "" }]);
  };

  const onChangeLinks = (e, index) => {
    const { name, value } = e.target;
    const keys = {
      url: "url",
      socialLinks: "type",
    };
    socialLinks[index][keys[name]] = value;
    setSocialLinks([...socialLinks]);
  };

  const deleteSocialLink = (index) => {
    socialLinks.splice(index, 1);
    setSocialLinks([...socialLinks]);
  };
  return (
    <>
      <h3 className="mt-4 fs-16 fw-400">{t("External Links")}</h3>
      <p className="fs-12 opacity-60">{t(description)}</p>
      {socialLinks.map(({ url, type }, index) => (
        <Grid key={index} container spacing={2} mt={0.5} alignItems="center">
          <Grid item xs={12} lg={5}>
            <MasterSelect
              name="socialLinks"
              title="Select Link Type"
              borderedIcon
              classes="w-full"
              width="99%"
              value={type}
              onChange={(e) => onChangeLinks(e, index)}
              t={t}
            />
            <span></span>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Input
              title="Enter Url"
              classes="w-full"
              width="99%"
              name="url"
              value={url}
              onChange={(e) => onChangeLinks(e, index)}
              t={t}
            />
          </Grid>
          <Grid item xs={12} lg={1}>
            <Delete
              className="c-error cursor-pointer mt-5"
              onClick={() => deleteSocialLink(index)}
            />
          </Grid>
        </Grid>
      ))}
      <Button bordered onClick={addSocialLink} classes="mt-4 mb-3">
        {t("Add External Link")}
      </Button>
    </>
  );
};

export default ExternalLink;
