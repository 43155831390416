import {
  Message,
  Visibility,
  Group,
  AccessAlarm,
  Update,
  OpenInNew,
  ExpandMore,
  ChevronRight,
} from "@mui/icons-material";
import { Chip, Tooltip, Stack } from "@mui/material";
import Card from "components/Cards/Card";
import IconText from "components/UI/atoms/IconText";
import React, { useState } from "react";
import InfoCard from "components/Cards/InfoCard";
import { COMPONENT_TYPES, INFO_TYPES } from "helpers/utils/constants";
import OnlineAvatar from "components/UI/atoms/OnlineAvatar";
import { isTrue } from "helpers/utils/validators";
import moment from "moment";
import SecureImage from "components/UI/atoms/SecureImage";
import socialicon from "assets/png/socialicon.png";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import LikeShareSaveBtns from "components/UI/atoms/LikeShareSaveBtns";
import { org } from "assets/svg/blue";
import useRedirect from "helpers/hooks/useRedirect";
import { getDefaultImageByType } from "components/Cards/MainProductCard";
import { Link } from "react-router-dom";
import { linkByComponent } from "helpers/utils/utilities";
import parse from "html-react-parser";

const ProjectDescription = ({
  project,
  onLike = Function.prototype,
  onShare = Function.prototype,
}) => {
  const [hideExternalLinks, setHideExternalLinks] = useState(false);
  const toggleList = () => setHideExternalLinks(!hideExternalLinks);

  const { t } = useLocalizedTranslation();
  const {
    title,
    updated_at,
    member_count,
    members,
    external_links,
    privacy,
    requirement_status,
    challenge_details,
    skills,
    project_role,
    organization,
    access_level,
  } = project;
  const isDueDatePassed = moment().isAfter(moment(challenge_details?.due_date));

  const formattedRequirements = requirement_status.map((d) => ({
    title: d["Requirement Title"],
    status: d?.status,
  }));

  const allRequirementsCompleted = formattedRequirements.every(
    (req) => req.status === "completed"
  );

  return (
    <Card classes="mt-2 overflowX-hidden">
      <h2 className="tracking-tight">{title}</h2>
      <div className="flex flex-wrap gap-10">
        <IconText
          icon={<img className="w-20 h-25" src={org} alt="Organization Icon" />}
          text={`Organization: ${organization || "N/A"}`}
        />
        <IconText
          className=""
          icon={<Visibility />}
          text={isTrue(privacy) ? t("Private Project") : t("Public Project")}
        />
        <IconText icon={<Group />} text={`${member_count} Members`} />
        <IconText
          icon={<Update />}
          text={`${t("Updated")} ${moment(updated_at).fromNow()} `}
        />
        <IconText
          classes={`fs-14 ${isTrue(project?.is_submitted) ? "c-success" : ""} `}
          icon={<AccessAlarm />}
          text={
            isTrue(project?.is_submitted)
              ? t("Submitted")
              : project.challenge_details?.challenge_status === "closed"
              ? t("Challenge Closed for Submission")
              : !isDueDatePassed
              ? t("Challenge Open for Submission")
              : `${t("Due on")} ${moment(challenge_details?.due_date).format(
                  "MMMM DD YYYY"
                )}`
          }
        />
      </div>
      <LikeShareSaveBtns
        isLiked={isTrue(project?.favourite)}
        onLike={onLike}
        onShare={() => onShare(true)}
        hideSave
      />
      {project_role !== "assessor" && access_level !== "viewer" && (
        <InfoCard
          classes="mt-4"
          title={
            allRequirementsCompleted
              ? t("Your project is ready for submission.")
              : t("Complete all tasks before submission")
          }
          type={
            allRequirementsCompleted ? INFO_TYPES.SUCCESS : INFO_TYPES.ERROR
          }
          list={formattedRequirements}
          hasObjects
          t={t}
        />
      )}
      <h4 className="mt-4">{t("Members")}</h4>
      {members.map((member, i) => (
        <MemberRow key={i} member={member} t={t} />
      ))}
      <h4 className="mt-4">{t("Looking for Members with Skills")}</h4>
      <div className="flex items-center flex-wrap">
        {Object.values(skills)?.length > 0 ? (
          Object.values(skills)?.map((skill, i) => (
            <Chip label={skill} key={i} className="mr-1 mt-1" />
          ))
        ) : (
          <span className="fs-14">{t("N/A")}</span>
        )}
      </div>
      <h4 className="flex mt-4">
        {t("External Links")}{" "}
        {external_links?.length ? (
          <small
            className="flex fs-12 items-center cursor-pointer"
            onClick={toggleList}
          >
            {!hideExternalLinks ? <ExpandMore /> : <ChevronRight />}
          </small>
        ) : null}
      </h4>
      <Stack spacing={1}>
        {external_links?.length ? (
          !hideExternalLinks &&
          external_links.map(({ id, title, link, image }) => (
            <div className="flex-center mt-1" key={id}>
              <SecureImage
                src={image}
                width={30}
                height={30}
                placeholder={socialicon}
                classes="object-cover rounded-50 cursor-pointer"
                title={link}
              />
              <p
                onClick={() => window.open(link)}
                className="flex-center ml-4 link text-truncate"
              >
                {title} <OpenInNew className="ml-1" fontSize="small" />{" "}
              </p>
            </div>
          ))
        ) : (
          <span className="fs-14">{t("N/A")}</span>
        )}
      </Stack>
      {project?.challenge_details && (
        <>
          <h4 className="mt-4 mb-1">{t("Associated Challenge")}</h4>
          <AssociatedItem
            title={project?.challenge_details?.title}
            description={
              project?.challenge_details?.description_type === "text"
                ? project?.challenge_details?.description
                : t(
                    "Challenge descriptions and requirements are displayed via SCORM files. Click on the challenge title to view the full description.",
                    "project"
                  )
            }
            media={project?.challenge_details?.media}
            type={COMPONENT_TYPES.CHALLENGE}
            id={project?.challenge_details?.slug}
          />
        </>
      )}
      {project?.lab_details && (
        <>
          <h4 className="mt-4 mb-1">{t("Associated Lab")}</h4>
          <AssociatedItem
            title={project?.lab_details?.title}
            description={project?.lab_details?.description}
            media={project?.lab_details?.media}
            type={COMPONENT_TYPES.LAB}
            id={project?.lab_details?.slug}
          />
        </>
      )}
    </Card>
  );
};

export default ProjectDescription;

const MemberRow = ({ member, t }) => {
  const { full_name, access_level, user_name } = member;
  const { navigateChats } = useRedirect();

  return (
    <div className="flex mt-2 justify-between items-center">
      <div className="flex items-center mr-1">
        <OnlineAvatar />
        <Tooltip
          title={full_name}
          placement="top"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}
        >
          <p className="mx-1 fw-500 fs-14 text-truncate">{full_name}</p>
        </Tooltip>
        {access_level === "team_leader" && (
          <Chip
            label={t("Team Leader")}
            variant="filled"
            className="fs-12"
            size="small"
            sx={{ background: "#498CCE" }}
            color="primary"
          />
        )}
      </div>
      <Message
        onClick={() => navigateChats(`?username=${user_name}`)}
        className="c-primary"
      />
    </div>
  );
};

const AssociatedItem = ({
  title,
  description,
  media,
  type,
  onNavigate,
  id,
}) => (
  <div className="flex mt-2">
    <SecureImage
      src={media}
      placeholder={getDefaultImageByType(type)}
      classes="w-120 h-70"
      title={title}
      smaller
    />
    <div className="ml-1 flex-1 text-left tracking-tight">
      <Link
        to={linkByComponent(type, id)}
        className="c-primary text-truncate maxw-250 text-decoration-none"
      >
        <h5 className="fs-14">{title || "N/A"}</h5>
      </Link>
      <p className="fs-11 text-truncate-2 maxw-180">
        {parse(description) || "N/A"}
      </p>
    </div>
  </div>
);
export { AssociatedItem };
