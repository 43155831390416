import Input from "components/Input";
import Select from "components/Input/Select";
import React, { useState } from "react";
import NavTabs from "./NavTabs";

const TABS = ["Organization", "Lab", "Challenge"];
const TABLE_COLUMN_FILTER = [
  "Learning Points",
  "Learning Rank",
  "Achievements",
];
const LeaderBoardMobileFIlters = ({
  handleChange,
  ColumnFilter,
  lablevel = false,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const placeholder = lablevel
    ? "Search Challenges..."
    : currentTab === 0
    ? "Search Organization..."
    : currentTab === 1
    ? "Search Labs..."
    : "Search Challenges...";
  return (
    <div className="my-4">
      {lablevel ? (
        <div
          style={{ borderBottom: "2px solid #498CCE" }}
          className="maxw-100 py-1"
        >
          <h3 className="c-primary">Challenge</h3>
        </div>
      ) : (
        <NavTabs list={TABS} noBg value={currentTab} onChange={setCurrentTab} />
      )}
      <Input width="97%" placeholder={placeholder} classes="mt-4" />
      <Select
        borderedIcon
        classes="mt-4"
        value={ColumnFilter}
        width="100%"
        values={TABLE_COLUMN_FILTER}
        height={40}
        onChange={handleChange}
      />
    </div>
  );
};

export default LeaderBoardMobileFIlters;
