import {
  Bookmark,
  BookmarkBorder,
  Favorite,
  FavoriteBorder,
  Share,
  Done,
} from "@mui/icons-material";
import { Icon } from "@mui/material";
import Button from "components/Button";
import React from "react";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const LikeShareSaveBtns = ({
  onLike,
  isLiked,
  onShare,
  onSave,
  isSaved,
  classes,
  hideSave,
  hideLike,
  disabled,
  hideShare,
}) => {
  const { t } = useLocalizedTranslation();
  return (
    <div
      className={`flex mt-2 ${classes}`}
      data-testid="like-save-share-buttons"
    >
      {!hideLike && (
        <Button
          disabled={disabled}
          bordered
          classes="mr-1"
          width="33%"
          onClick={onLike}
        >
          <Icon
            fontSize="small"
            component={isLiked ? Favorite : FavoriteBorder}
          />{" "}
          {t("Like")}
        </Button>
      )}
      {!hideSave && (
        <Button
          disabled={disabled}
          bordered
          classes="mr-1"
          width="33%"
          onClick={onSave}
          color={isSaved ? "green" : ""}
        >
          <Icon component={isSaved ? Done : BookmarkBorder} fontSize="small" />{" "}
          {t(isSaved ? "Saved" : "Save")}
        </Button>
      )}
      {!hideShare && (
        <Button disabled={disabled} bordered onClick={onShare} width="33%">
          <Share fontSize="small" /> {t("Share", "common")}
        </Button>
      )}
    </div>
  );
};

export default LikeShareSaveBtns;
