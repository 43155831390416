import React from "react";
import { CHAT_MESSAGES } from "../data";
import ChatListItem from "pages/chats/components/ChatListItem";
import { List } from "@mui/material";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const Chat = () => {
  const { t } = useLocalizedTranslation();
  return (
    <div className="h-350 overflowY-auto">
      <List disablePadding dense={true} className="mt-2">
        {CHAT_MESSAGES.map((data, i) => (
          <ChatListItem
            key={i}
            data={data}
            selected={i === 1}
            onChatSelect={() => {}}
            id={i}
            bordered
            dense
          />
        ))}
      </List>
      <p className="w-full mt-2 link text-center fs-14">{t("View More")}</p>
    </div>
  );
};

export default Chat;
