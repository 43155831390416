import React from "react";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import DashboardWrapper from "./DashboardWrapper";
import DashboardTable from "./components/DashboardTable";
import LeaderBoardCard from "./components/LeaderboardCard";
import ChallengeDeadlinesCard from "./components/ChallengeDeadlinesCard";
import PlanDetailCard from "./components/PlanDetailCard";
import ReportCard from "./components/ReportCard";
import Inbox from "./components/Inbox";
import Recommendations from "./components/Recommendations";

const Organizationdashboard = ({
  items,
  setItems,
  isCustomizeMode,
  setInactiveById,
}) => {
  return (
    <DashboardWrapper
      items={items}
      setItems={setItems}
      isCustomizeMode={isCustomizeMode}
      setInactiveById={setInactiveById}
    />
  );
};

export default Organizationdashboard;

export const InfoItem = ({ title, icon, value }) => {
  return (
    <div className="flex items-center mt-2 mx-2">
      <img src={require("../../" + icon)} width="22px" alt="" />
      <p className="ml-2 w-150">{title}</p>
      <p className="text-end w-60 tracking-tight fs-14 fw-600 ml-1">{value}</p>
    </div>
  );
};

export const ORG_DASH_ITEMS = [
  {
    uuid: "1",
    size: "md",
    children: <ReportCard type="org" />,
    width: 1,
    id: "reports",
  },
  {
    uuid: "2",
    size: "md",
    children: <PlanDetailCard type="org" />,
    width: 1,
    id: "subscription",
  },
  {
    uuid: "3",
    size: "md",
    children: <ChallengeDeadlinesCard type="org" />,
    width: 1,
    id: "deadlines",
  },
  {
    uuid: "4",
    title: "Leaderboard",
    children: <LeaderBoardCard />,
    size: "md",
    width: 1,
    id: "leaderboard",
  },
  {
    uuid: "5",
    size: "md",
    children: (
      <DashboardTable
        tabs={["My Organizations"]}
        component={COMPONENT_TYPES.ORGANIZATION}
        type="org"
      />
    ),
    width: 1,
    id: "my-organizations",
  },
  {
    uuid: "6",
    size: "md",
    children: (
      <DashboardTable
        tabs={["My Challenges"]}
        component={COMPONENT_TYPES.CHALLENGE}
        type="org"
      />
    ),
    width: 1,
    id: "my-challenges",
  },
  {
    uuid: "7",
    size: "md",
    children: (
      <DashboardTable
        tabs={["My Labs"]}
        component={COMPONENT_TYPES.LAB}
        type="org"
      />
    ),
    width: 1,
    id: "my-labs",
  },
  {
    uuid: "8",
    size: "md",
    children: (
      <DashboardTable
        tabs={["Project Assessment", "Submissions"]}
        component={COMPONENT_TYPES.PROJECT}
        type="org"
      />
    ),
    width: 1,
    id: "my-projects",
  },
  {
    id: "my-resources",
    size: "md",
    children: (
      <DashboardTable
        type="user"
        tabs={["My Resources"]}
        component={COMPONENT_TYPES.RESOURCE}
      />
    ),
    width: 1,
    uuid: "9",
  },
  {
    id: "inbox-friends",
    children: <Inbox />,
    size: "md",
    width: 1,
    uuid: "10",
  },
  {
    children: <Recommendations classes="px-4" />,
    id: "recommendations",
    width: 1,
    size: "md",
    uuid: "11",
  },
];
