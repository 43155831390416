import { Group } from "@mui/icons-material";
import { labs_list } from "pages/organization/forms/data";
import React from "react";
import img from "assets/png/prebuiltLab.png";
import styles from "../dashboard.module.scss";
import { statusTitleClass } from "./ProjectCard";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const LabsTable = () => {
  const { t } = useLocalizedTranslation();
  return (
    <>
      <div className="flex items-center fs-14 opacity-60 pt-1 pb-1 border-b">
        <p className="w-60p">{t("Title")}</p>
        <p className="w-20p">{t("Members")}</p>
        <p className="w-20p ml-1 text-right mr-4--noMob">{t("Status")}</p>
      </div>

      <div className="w-full">
        {labs_list.map((lab, i) => (
          <LabRow lab={lab} key={i} />
        ))}
      </div>
    </>
  );
};

export default LabsTable;

const LabRow = ({ lab }) => {
  const { title, description, status, members } = lab;
  if (!title) return null;
  return (
    <div className="flex py-2 border-t">
      <div className="w-60p flex-colOnMob">
        <img src={img} alt={title} className="h-80 w-160" />
        <div className="fs-14--noMob ml-1 tracking-tight">
          <p>{title}</p>
          <p className="opacity-50 mt-2">{description}</p>
        </div>
      </div>
      <div className="w-20p flex fs-14--noMob opacity-80">
        <Group className="mr-1--noMob" /> {members}{" "}
      </div>
      <div className="w-20p">
        <p
          className={`${styles.statusChip} ${
            styles[statusTitleClass[status][1]]
          } float-right mr-2--noMob`}
        >
          {statusTitleClass[status][0]}
        </p>
      </div>
    </div>
  );
};
