import { Grid } from "@mui/material";
import Card from "components/Cards/Card";
import React from "react";
import cx from "classnames";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const CreateFormSection = ({ title, classes = "", children }) => {
  const {t} = useLocalizedTranslation();
  return (
    <Card
      data-testid="create-form-section"
      classes={cx(title ? "mt-6" : "mt-4", "w-full", classes)}
    >
      <Grid
        container
        px={{ xs: 0.5, sm: 1, md: 3 }}
        direction="column"
        alignItems="flex-start"
      >
        <h3>{t(title)}</h3>
        {children}
      </Grid>
    </Card>
  );
};

export default CreateFormSection;
