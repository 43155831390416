import React, { useEffect, useState } from "react";
import { NoDataView } from "components/UI/organisms/ListingContainer";
import useMaster from "store/master/service-hook";
import { Grid, Box } from "@mui/material";
import { COLORS } from "helpers/utils/constants";
import Spin from "components/Spin";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import CustomTaskForm from "./custom/CustomTaskForm";

const ChallengesTaskAndQuestionsListing = ({
  templateId,
  taskValues,
  setTaskValues,
  questionsValues,
  setQuestionsValues,
  taskAndQuestionError,
  setTaskAndQuestionError,
  isProjectAlreadyPublished,
  t = (d) => d,
}) => {
  const [pitchNTasks, setPitchNTasks] = useState();
  const { fetchChallengePitchTask } = useMaster();
  const [loading, setLoading] = useState(false);
  const [customTaskToggle, setCustomTaskToggle] = useState(false);
  useEffect(() => {
    if (templateId !== "custom") {
      handleFetchTasks();
      setCustomTaskToggle(false);
    } else {
      setCustomTaskToggle(true);
    }
  }, [templateId]);

  const handleFetchTasks = async () => {
    setLoading(true);
    const res = await fetchChallengePitchTask(templateId);
    setLoading(false);
    if (res) {
      setPitchNTasks(res[0] || undefined);
    }
  };
  if (customTaskToggle) {
    return (
      <div className="w-full">
        <CustomTaskForm
          values={taskValues}
          setValues={setTaskValues}
          setTaskAndQuestionError={setTaskAndQuestionError}
          title="Challenge Tasks"
          description="A list of tasks participants must complete before submission"
          fields={[
            {
              name: "task",
              title: "Task",
            },
          ]}
          rowHeight={{ lg: 70, sm: 70 }}
          buttonColor="blue"
          buttonLabel="Add Task"
          disabled={isProjectAlreadyPublished}
          t={t}
        />
        <br />
        <hr />
        <br />
        <CustomTaskForm
          values={questionsValues}
          setValues={setQuestionsValues}
          setTaskAndQuestionError={setTaskAndQuestionError}
          title="Challenge Questions"
          description="A set of questions participants must complete before submission"
          fields={[
            {
              name: "question",
              title: "Question Title",
            },
            {
              name: "prompt",
              title: "Prompt",
            },
          ]}
          rowHeight={{ lg: 160, sm: 160 }}
          buttonColor="green"
          buttonLabel="Add Question"
          disabled={isProjectAlreadyPublished}
          t={t}
        />
        <p className="c-error mt-2 fs-14">{taskAndQuestionError}</p>
      </div>
    );
  }
  return loading ? (
    <Box className="flex items-center justify-center w-full h-400">
      <Spin big blue />
    </Box>
  ) : pitchNTasks ? (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <h3 className="mt-2 fs-18 fw-500">{t("Challenge Tasks")}</h3>
        <p className="mb-2 fs-12 opacity-60">
          {t("A list of tasks participants must complete before submission")}
        </p>
        {pitchNTasks.challenge_task.length !== 0 ? (
          pitchNTasks.challenge_task.map(({ id, title, description }) => (
            <PitchTaskItem
              key={id}
              title={title}
              description={description}
              isTask
              t={t}
            />
          ))
        ) : (
          <NoDataView
            classes="py-5 fs-14"
            text={t("No tasks for this template!")}
          />
        )}
      </Grid>
      <hr className="w-full my-4" />
      <Grid item md={12} xs={12}>
        <h3 className="fs-18 fw-500">{t("Challenge Questions")}</h3>
        <p className="mb-2 fs-12 opacity-60">
          {t("A set of questions participants must complete before submission")}
        </p>
        {pitchNTasks.challenge_pitch.length !== 0 ? (
          pitchNTasks.challenge_pitch.map(({ id, title, description }) => (
            <PitchTaskItem
              key={id}
              title={title}
              description={description}
              t={t}
            />
          ))
        ) : (
          <NoDataView
            classes="py-5 fs-14"
            text={t("No pitch for this template!")}
          />
        )}
      </Grid>
    </Grid>
  ) : (
    <NoDataView text={t("No Pitch and Tasks available")} classes="py-5 fs-14" />
  );
};
export default ChallengesTaskAndQuestionsListing;

export const PitchTaskItem = ({
  id,
  title,
  description,
  isTask,
  t = (d) => d,
}) => {
  return (
    <div
      key={id}
      className="rounded-5 px-2 py-1 flex-column justify-center mb-1 h-50 "
      style={{ backgroundColor: COLORS.BG_GRAY }}
    >
      <p className="fs-14 fw-700 text-truncate ">{t(title)}</p>
      {!isTask && (
        <p className="fs-13 text-truncate">
          {!!description ? description : ""}
        </p>
      )}
    </div>
  );
};
