import React, { useEffect, useState } from "react";
import { capitalize, sum } from "lodash";
import { Avatar } from "@mui/material";
import useOrganizationReport from "store/reports/organization/service-hook";
import useOrganizationReportContext from "../hooks/useOrganizationReportContext";
import useRedirect from "helpers/hooks/useRedirect";
import Card from "components/Cards/Card";
import DataTable from "pages/report/components/Datatable";
import UserIcon from "../../../../assets/svg/purple/profile.svg";
import EmptyText from "components/UI/atoms/EmptyText";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { COMPONENT_TYPES } from "helpers/utils/constants";

const OrganizationMembers = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { organizationMembers } = useOrganizationReport();
  const { organizationSlug } = useOrganizationReportContext();
  const { navigateMemberManagement } = useRedirect();
  const { navigateProfile } = useRedirect();

  /**
   * STATE
   */
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  /**
   *
   * @returns {Promise<void>}
   */
  const fetchData = async () => {
    setLoading(true);
    if (organizationSlug) {
      const response = await organizationMembers(organizationSlug, page);
      if (response) {
        setData(response);
      }
    }
    setLoading(false);
  };

  /**
   * COMPONENT MOUNTED AND WATCHERS
   */
  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <Card>
      <DataTable
        data={data?.data ?? []}
        loading={loading}
        page={data?.meta?.current_page ?? 1}
        perPage={data?.meta?.per_page ?? 10}
        totalCount={data?.meta?.total}
        totalPages={data?.meta?.total_pages ?? 1}
        onPageChange={(page) => setPage(page)}
        emptyText={
          <EmptyText
            isButtonPresent={true}
            onClick={() =>
              navigateMemberManagement(
                COMPONENT_TYPES.ORGANIZATION,
                organizationSlug
              )
            }
            buttonLabel="Manage Members"
            icon={ManageAccountsIcon}
          >
            {t("There is no member in this Organization now")}
          </EmptyText>
        }
        columns={[
          {
            title: t("Name", "common"),
            dataIndex: "name",
            render(name, _, row) {
              return (
                <div className={"flex items-center"} style={{ gap: "10px" }}>
                  {row?.profile_image ? (
                    <Avatar src={row?.profile_image} size={15} />
                  ) : (
                    <Avatar src={UserIcon} size={15} />
                  )}
                  <p
                    className={`c-primary ${
                      row?.name ? "cursor-pointer" : ""
                    } text-nowrap`}
                    onClick={() => {
                      if (row?.name) {
                        navigateProfile(row?.username);
                      }
                    }}
                  >
                    {name}
                  </p>
                </div>
              );
            },
          },
          {
            title: t("Username", "common"),
            dataIndex: "username",
          },
          {
            title: t("Role", "common"),
            dataIndex: "role",
          },
          {
            title: t("Email", "common"),
            dataIndex: "email",
            render(email) {
              return (
                <a href={`mailto:${email}`} className="underline c-primary">
                  {email}
                </a>
              );
            },
          },
          {
            title: t("Invitation status", "common"),
            dataIndex: "invitation_status",
            render(status) {
              return (
                <p className={status === "rejected" ? "text-danger" : ""}>
                  {t(capitalize(status), "common")}
                </p>
              );
            },
          },
          {
            title: t("Account Activity", "common"),
            dataIndex: "account_activity",
          },
          {
            title: t("Learning Points", "common"),
            dataIndex: "learning_points",
          },
          {
            title: t("Learning Rank", "common"),
            dataIndex: "learning_rank",
          },
          {
            title: t("Achievement", "common"),
            dataIndex: "achievement_count",
          },
          {
            title: t("Completed Challenges", "common"),
            dataIndex: "completion_count_by_module",
            render(item) {
              return <>{item?.total_challenge_completed ?? "-"}</>;
            },
          },
          {
            title: t("Completed Labs", "common"),
            dataIndex: "completion_count_by_module",
            render(item) {
              return <>{item?.total_lab_completed ?? "-"}</>;
            },
          },
          {
            title: t("Completed Resources", "common"),
            dataIndex: "completion_count_by_module",
            render(item) {
              return (
                <>
                  {sum([
                      item?.total_resource_module_completed ?? 0,
                    item?.total_resource_group_completed ?? 0,
                    item?.total_resource_collection_completed ?? 0,
                  ]) ?? "-"}
                </>
              );
            },
          },
        ]}
      />
    </Card>
  );
};

export default OrganizationMembers;
