import React, { useState } from "react";
import DragWrapper from "components/DragNDrop/DragWrapper";
import { Delete, Menu, Search, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Checkbox,
  Container,
  Switch,
} from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import NavTabs from "../molecules/NavTabs";
import SecureImage from "../atoms/SecureImage";
import { getDefaultImageByType } from "components/Cards/MainProductCard";
import EmptyText from "components/UI/atoms/EmptyText";
import styles from "../ui.module.scss";
import { useTranslation } from "react-i18next";
import Spin from "components/Spin";
import { COMPONENT_TYPES } from "helpers/utils/constants";
const AssociatedListing = ({
  list = {},
  setList,
  title,
  subTitle,
  tabs = ["All"],
  component = "Labs",
  id = "uuid",
  filterByTab = () => true,
  type,
  emptyView,
  onSearch,
  isLoading = false,
  isSequential,
  setSequential = Function.prototype,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [selected, setSelected] = useState(list.selected || []);
  const [searchText, setSearchText] = useState("");
  const { t } = useTranslation("common");

  const { all = [] } = list;

  const handleSelect = (item) => {
    if (selected?.some((d) => d[id] === item[id])) {
      const index = selected.findIndex((d) => d[id] === item[id]);
      selected.splice(index, 1);
    } else {
      selected.push(item);
    }
    list.selected = selected;
    setList({ ...list });
    setSelected([...selected]);
  };

  const handleRemove = (event, uuid) => {
    event.stopPropagation();
    const index = selected.findIndex((d) => d[id] === uuid);
    selected.splice(index, 1);
    list.selected = selected;
    setList({ ...list });
    setSelected([...selected]);
  };

  return (
    <Container md={12} lg={10} disableGutters>
      <h3 className="mt-2">1. {t(title)}</h3>
      <p>{t(subTitle)}</p>
      <Input
        classes="mt-4"
        width="100%"
        icon={<Search style={{ color: "black" }} />}
        onChange={(e) => {
          setSearchText(e.target.value);
          if (typeof onSearch === "function") {
            onSearch(e.target.value, tabValue); // Call only if onSearch is a function
          }
        }}
        value={searchText}
        iconLeft
        placeholder={t("Search for ${component} by name", {
          component: t(component),
        })}
      />
      <NavTabs list={tabs} onChange={setTabValue} value={tabValue} />

      <div className={styles.associatedLabs__listContainer}>
        {isLoading ? (
          <div className="flex justify-center items-center h-100">
            <Spin blue />
          </div>
        ) : (
          <>
            {all?.length ? (
              (onSearch && searchText
                ? all.filter((d) => filterByTab(d, tabValue))
                : all.filter(
                    (d) =>
                      (!searchText ||
                        d.title
                          .toLowerCase()
                          .includes(searchText.trim().toLowerCase())) &&
                      filterByTab(d, tabValue)
                  )
              ).map(({ title, media, ...d }, i) => (
                <Accordion key={d[id]} className="mt-4">
                  <AccordionSummary disableGutters expandIcon={<ExpandMore />}>
                    <div className="flex items-center">
                      <Checkbox
                        checked={selected.some((e) => e[id] === d[id])}
                        onClick={(e) =>
                          handleSelect({
                            [id]: d[id],
                            media,
                            title,
                            type: d.type,
                          })
                        }
                      />
                      <SecureImage
                        small
                        title={title}
                        src={media}
                        alt="title"
                        className="h-100 w-150"
                        containerClasses="mr-3 c-title"
                        placeholder={getDefaultImageByType(
                          COMPONENT_TYPES[d.type] ? d.type : type
                        )}
                      />
                      <h3 className="tracking-tight">{title}</h3>
                    </div>
                  </AccordionSummary>
                </Accordion>
              ))
            ) : (
              <EmptyText classes="c-gray py-2">
                {t("No ${component} found.", { component: t(component) })}
              </EmptyText>
            )}
            {searchText &&
              !onSearch &&
              !all.some(
                (d) =>
                  d.title
                    .toLowerCase()
                    .includes(searchText.trim().toLowerCase()) &&
                  filterByTab(d, tabValue)
              ) && (
                <EmptyText classes="c-gray py-2">
                  {t("No ${component} found.", { component: t(component) })}
                </EmptyText>
              )}
          </>
        )}
      </div>

      <h3 className="mt-5">
        2. {t("Review and Reorder your")} {t(component)}
      </h3>
      <div className="flex items-center">
        <Switch
          defaultChecked={Boolean(isSequential)}
          onChange={(e) => setSequential(e.target.checked)}
        />
        <p className="ml-2">
          {t("Require")} {t(component)} {t("to be completed sequentially.")}
        </p>
      </div>
      <NavTabs
        list={[`${t("Selected")}(${selected.length})`]}
        onChange={() => {}}
        value={0}
      />
      <div
        className={`${styles.associatedLabs__listContainer} overflowX-hidden`}
      >
        {selected?.length ? (
          <DragWrapper
            items={selected}
            setItems={setSelected}
            isListing
            draggable
            rowHeight={{ lg: 124, sm: 94 }}
            id={id}
          >
            {selected.map(({ title, media, ...d }) => (
              <Accordion key={d[id]} className="mt-4">
                <AccordionSummary disableGutters className="h-100p w-100p">
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <Menu className="drag-handle" />{" "}
                      {/* Add the drag-handle class here */}
                      <SecureImage
                        small
                        title={title}
                        src={media}
                        alt="title"
                        className="h-100 w-150"
                        containerClasses="mr-3 c-title"
                        placeholder={getDefaultImageByType(
                          COMPONENT_TYPES[d.type] ? d.type : type
                        )}
                      />
                      <h3 className="tracking-tight">{title}</h3>
                    </div>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleRemove(e, d[id]);
                      }}
                      bordered
                      color="red"
                      classes="fs-12--mob"
                    >
                      <Delete className="fs-14--mob" />
                      {t("Remove")}
                    </Button>
                  </div>
                </AccordionSummary>
              </Accordion>
            ))}
          </DragWrapper>
        ) : emptyView ? (
          emptyView
        ) : (
          <EmptyText classes="c-gray py-2">
            {t("Please select ${component} from the list above.", {
              component: t(component),
            })}
          </EmptyText>
        )}
      </div>
    </Container>
  );
};

export default AssociatedListing;
