import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {mockRequestGet, mockRequestPost, mockRequestDelete} from "../mocks/request";
import { dispatchMock, initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useSettings from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api";
import ACTIONS from "./action-types";

let getSpy;
let postSpy;
let deleteSpy;

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
  [REDUCER_TYPES.SETTINGS]: {settings: null}
});

const dispatch = dispatchMock(store);

const SettingsServicesMock = () => {
  const {
    details,
    updateAccount,
    updatePrivacy,
    updateNotifications,
    updatePassword,
    removeImage,
    deactivateAccount,
  } = useSettings();

  const BUTTON_LINKS = [
    { name: "details", onClick: () => details() },
    { name: "updateAccount", onClick: () => updateAccount({}) },
    { name: "updatePrivacy", onClick: () => updatePrivacy({}) },
    { name: "updateNotifications", onClick: () => updateNotifications({}) },
    { name: "updatePassword", onClick: () => updatePassword({}) },
    { name: "removeImage", onClick: () => removeImage() },
    { name: "deactivateAccount", onClick: () => deactivateAccount() },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Settings services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <SettingsServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
    deleteSpy = mockRequestDelete();
  });

  test("details", async () => {
    const button = screen.getByTestId("details");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.USER}/${ENDPOINTS.LOGGED_IN}/${ENDPOINTS.DETAILS}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
        expect(dispatch).toHaveBeenCalledWith({
            payload: [{}],
            type: `${ENDPOINTS.SETTINGS}/${ACTIONS.SETTINGS}`,
        })
    );
  });

  test("updateAccount", async () => {
    const button = screen.getByTestId("updateAccount");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.SETTINGS}/${ENDPOINTS.ACCOUNT}/${ENDPOINTS.UPDATE}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("updatePrivacy", async () => {
    const button = screen.getByTestId("updatePrivacy");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.SETTINGS}/${ENDPOINTS.PRIVACY}/${ENDPOINTS.UPDATE}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
        expect(dispatch).toHaveBeenCalledWith({
            payload: {},
            type: `${ENDPOINTS.SETTINGS}/${ACTIONS.SETTINGS}`,
        })
    );
  });

  test("updateNotifications", async () => {
    const button = screen.getByTestId("updateNotifications");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.SETTINGS}/${ENDPOINTS.NOTIFICATION}/${ENDPOINTS.UPDATE}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
        expect(dispatch).toHaveBeenCalledWith({
            payload: {},
            type: `${ENDPOINTS.SETTINGS}/${ACTIONS.SETTINGS}`,
        })
    );
  });

  test("updatePassword", async () => {
    const button = screen.getByTestId("updatePassword");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.SETTINGS}/${ENDPOINTS.PASSWORD}/${ENDPOINTS.UPDATE}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("removeImage", async () => {
    const button = screen.getByTestId("removeImage");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.SETTINGS}/${ENDPOINTS.IMAGE}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
        expect(dispatch).toHaveBeenCalledWith({
            payload: {},
            type: `${ENDPOINTS.SETTINGS}/${ACTIONS.SETTINGS}`,
        })
    );
  });

  test("deactivateAccount", async () => {
    const button = screen.getByTestId("deactivateAccount");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.SETTINGS}/${ENDPOINTS.ACCOUNT}/${ENDPOINTS.DEACTIVATE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });
});
