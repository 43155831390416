import React from "react";
import useAdvanceSearchFilter from "../../../hooks/useAdvanceSearchFilter";
import { AS_REDUCER } from "../../../../../store/advance-search/reducer";
import useAdvanceSearch from "../../../../../store/advance-search/service-hook";
import { useAdvanceSearchContext } from "../../../hooks/useAdvanceSearchContext";
import QuickSearchListing from "../../QuickSearch";
import { ADVANCE_SEARCH_COMPONENTS } from "../../../data";

const QuickSearchResourceModule = ({ t = (d) => d }) => {
  const { quickKeyword } = useAdvanceSearchContext();
  const { searchResourceModules } = useAdvanceSearch();
  const { data } = useAdvanceSearchFilter(
    ADVANCE_SEARCH_COMPONENTS.RESOURCE_MODULES,
    quickKeyword,
    null,
    searchResourceModules,
    AS_REDUCER.setResourceModules,
    true
  );

  return (
        <QuickSearchListing
          data={data?.data?.slice(0, 3) ?? []}
          componentName={"Resource Modules"}
          component={ADVANCE_SEARCH_COMPONENTS.RESOURCE_MODULES}
          loading={data?.loading}
          t={t}
        />
  );
};

export default QuickSearchResourceModule;
