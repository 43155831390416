import { SCHEMA_TYPES } from "helpers/utils/constants";
import { REQUIRED_VALIDATOR } from "helpers/utils/validators";

export const schema = [
  {
    title: "Skills",
    name: "skills",
    type: SCHEMA_TYPES.MASTER,
    lg: 12,
    multi: true,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Level",
    name: "level_id",
    multi: true,
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Duration",
    name: "duration_id",
    multi: true,
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Content Types",
    name: "openai_resource_module_types",
    type: SCHEMA_TYPES.SELECT,
    lg: 12,
    multi: true,
    options: [
      { value: "Links", key: "links" },
      { value: "Videos", key: "videos" },
    ],
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Additional Information",
    name: "additional_information",
    type: SCHEMA_TYPES.TEXTBOX,
    lg: 12,
    classes: "h-100",
    maxLength: 100,
  },
];

export const getSchemaByStep = () => {
  return [0, schema.findIndex((d) => d.name === "additional_information") + 1];
};

export const stepSchema = () => {
  return schema.slice(...getSchemaByStep());
};
