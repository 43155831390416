import { Container } from "@mui/material";
import React from "react";
import { NOTIFICATIONS_DATA } from "../data";
import { SettingItem } from "./AccountSettings";

const Notifications = ({ notifications, onChange, t = (d) => d }) => {
  return (
    <Container px={{ xs: 0, md: 2 }} my={2}>
      <h2>{t("Notifications")}</h2>
      {NOTIFICATIONS_DATA?.map(({ title, name, description, options }, i) => (
        <SettingItem
          name={name}
          onChange={onChange}
          value={notifications[name]}
          options={options}
          title={title}
          description={description}
          classes="my-6"
          key={i}
          t={t}
        />
      ))}
    </Container>
  );
};

export default Notifications;
