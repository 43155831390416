import { createSlice } from "@reduxjs/toolkit";
import ACTIONS from "./action-types";

const initialState = {
  startPage: null,
};

const SLICE = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    [ACTIONS.START_PAGE]: (state, { payload }) => {
      state.startPage = payload;
    },
  },
});

export const REDUCER = SLICE.actions;
export default SLICE.reducer;
