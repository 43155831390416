import { Close, EditNote, Search } from "@mui/icons-material";
import { Grid, IconButton, List, Pagination, Box } from "@mui/material";
import Input from "components/Input";
import NavTabs from "components/UI/molecules/NavTabs";
import React, { useEffect } from "react";
import ChatListItem from "./ChatListItem";
import styles from "../chats.module.scss";
import Spin from "components/Spin";
import useAuth from "store/auth/service-hook";

const ANNOUNCEMENT_TABS = [
  {
    title: "Sent",
    value: "send",
  },
  {
    title: "Draft",
    value: "draft",
  },
  {
    title: "Scheduled",
    value: "scheduled",
  },
];

const ChatList = ({
  current,
  setCurrent,
  isAnnouncements,
  chats = [],
  title = "N/A",
  setChat,
  chat,
  pages,
  page,
  setPage,
  total,
  search,
  setSearch,
  loading,
  tab,
  setTab,
  inboxCount,
  archiveCount,
  showUser,
  t = (d) => d,
  announcementsCounts = [],
}) => {
  const {
    state: { user },
  } = useAuth();

  useEffect(() => {
    if (showUser) {
      setCurrent("new");
      setChat(new_message_data);
    }
  }, [showUser]);

  const new_message_data = {
    subject: t("New Announcement"),
    name: t("New Message"),
    id: "new",
  };

  const getEmptyMessage = () => {
    if (search) return t("No search results found");
    if (!isAnnouncements) {
      return t(
        `You do not have any messages. Click 'create' icon to start a conversation.`
      );
    } else {
      return t(
        "You do not have any ${type} announcement yet. Please try again back later!",
        "inbox",
        {
          type: t(ANNOUNCEMENT_TABS[tab]?.title),
        }
      );
    }
  };

  const getProfileImage = (_chat) => {
    if (isAnnouncements) return null;
    const members = _chat?.members?.filter((d) => d?.email !== user?.email);
    if (!members?.length) return null;
    let profile;
    if (_chat?.type === "direct_message") profile = members[0]?.profile_image;
    else profile = [members[0]?.profile_image, members[1]?.profile_image];
    return profile;
  };

  const filterByTab = (data) => {
    if (tab === 0 && data.status === "send") return true;
    if (tab === 1 && data.status === "draft") return true;
    if (tab === 2 && data.status === "scheduled") return true;
    return false;
  };

  return (
    <Grid
      item
      xs={0}
      md={4}
      direction="column"
      className={`${styles.chatList} bg-lightgray h-100p items-stretch relative`}
    >
      <div className="mx-2 h-120">
        {!!isAnnouncements && (
          <div className="flex mt-2 items-center justify-between">
            <p className="fs-16 fw-500">{title}</p>
            <IconButton
              onClick={() => {
                setChat(new_message_data);
                setCurrent("new");
              }}
              className="h-30 w-30"
            >
              <EditNote className="c-primary" />
            </IconButton>
          </div>
        )}
        <div className="flex justify-between items-center">
          <NavTabs
            noBg
            top={isAnnouncements ? "0" : "2"}
            list={
              isAnnouncements
                ? ANNOUNCEMENT_TABS.map(
                    (d, i) => `${t(d.title)} (${announcementsCounts[i] ?? "0"})`
                  )
                : [
                    `${t("Inbox")} (${inboxCount})`,
                    `${t("Archives")} (${archiveCount})`,
                  ]
            }
            value={tab}
            onChange={setTab}
            classes={isAnnouncements && "minh-40 h-40 w-full"}
            tabClasses={`fs-14 tracking-tight ${
              isAnnouncements && "minh-40 maxh-40"
            }`}
          />
          {!isAnnouncements && (
            <IconButton
              onClick={() => {
                setChat(new_message_data);
                setCurrent("new");
              }}
              className="mt-2"
            >
              <EditNote className="c-primary" />
            </IconButton>
          )}
        </div>
        <Input
          placeholder="Search"
          icon={
            search ? (
              <Close
                fontSize="small"
                className="cursor-pointer ml-2"
                onClick={() => setSearch("")}
              />
            ) : (
              <Search />
            )
          }
          iconLeft={!search}
          height="30px"
          value={search}
          classes={isAnnouncements ? "mt-2" : "mt-3"}
          width="100%"
          onChange={(e) => setSearch(e.target.value)}
          t={t}
        />
      </div>
      <div
        style={{
          height: "calc(100% - 120px)",
        }}
        className="overflowY-auto"
      >
        {loading ? (
          <Box className="flex h-100p items-center justify-center">
            <Spin blue />
          </Box>
        ) : (
          <List disablePadding dense={true} className="pb-4">
            {!!chat && (
              <ChatListItem
                data={chat}
                newMessageCancel={() => {
                  setCurrent(chats[0]?.id);
                  setChat(null);
                }}
                isAnnouncements={isAnnouncements}
                newChat
                current={current === "new"}
                onChatSelect={current !== "new" && setCurrent}
                t={t}
              />
            )}
            {!chats?.length && !chat ? (
              <p className="fs-13 text-center w-80p c-gray mt-4 mx-auto">
                {getEmptyMessage()}{" "}
              </p>
            ) : (
              (!isAnnouncements
                ? chats
                : chats
                    .filter(
                      (d) =>
                        !search ||
                        d.subject
                          .toLowerCase()
                          .includes(search.trim().toLowerCase())
                    )
                    .filter(filterByTab)
              ).map((data) => (
                <ChatListItem
                  key={data?.id}
                  data={data}
                  current={data?.id === current}
                  onChatSelect={setCurrent}
                  id={data?.id}
                  isAnnouncements={isAnnouncements}
                  profileImage={getProfileImage(data)}
                  tab={tab}
                  t={t}
                  isAnnouncement={
                    !isAnnouncements && data?.type === "announcement"
                  }
                />
              ))
            )}
          </List>
        )}
      </div>

      {Boolean(chats?.length) ? (
        <div className="pb-2 h-30 z-10 w-100p absolute bottom-0 bg-gray">
          <div className="flex-center justify-between w-100p pr-1">
            <p className="fw-500 fs-14 opacity-70 ml-2 mt-1">
              {(page - 1) * 10 + 1} -{" "}
              {Math.min((page - 1) * 10 + chats.length, total || chats.length)}{" "}
              {t("of")} {total || chats.length}
            </p>
            <Pagination
              count={pages}
              page={page}
              onChange={(e, _val) => setPage(_val)}
              sx={{
                ".MuiPaginationItem-root": {
                  minWidth: "10px",
                  height: "20px",
                  margin: "0px",
                },
                ".MuiPaginationItem-page": {
                  color: "black",
                  "&.Mui-selected": {
                    color: "black",
                    fontWeight: "bold",
                    backgroundColor: "transparent",
                  },
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                },
              }}
            />
          </div>
        </div>
      ) : (
        <div className="pb-2 h-30 z-10 w-100p absolute bottom-0 bg-gray">
          <div className="flex-center justify-between w-100p pr-1">
            <p className="fw-500 fs-14 opacity-70 ml-2 mt-1">
              0 - 0 {t("of")} 0
            </p>
          </div>
        </div>
      )}
    </Grid>
  );
};

export default ChatList;
