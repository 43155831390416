import { Grid, Skeleton } from "@mui/material";

const ExploreFeatureCard = () => {
    return (
        <Grid
            container
            my={4}
            className="bg-white px-6 flex items-center h-180--noMob py-3--mob c-white rounded-10 darkShadow"
        >
            <Grid item sm={7} xs={12} pr={0.5}>
                <Skeleton variant="text" width="80%" height={30} />
                <Skeleton variant="text" width="70%" />
                <Skeleton variant="text" width="40%" className="mt-10" />
            </Grid>
            <Grid item sm={5} xs={12}>
                <Skeleton variant="rectangular" width="100%" height={130} />
            </Grid>
        </Grid>
    );
};

export default ExploreFeatureCard