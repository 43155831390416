import { Bookmark } from "@mui/icons-material";
import React from "react";
import Card from "./Card";
import defaultImage from "../../assets/png/placeholder.png";
import Button from "components/Button";
import { Chip, Grid, Rating, Icon } from "@mui/material";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import cx from "classnames";
import { isManageListing } from "store/api/utils";
import { skills } from "assets/svg/yellow";
import {
  challenge,
  challengePath,
  labProgram,
  lab,
  project,
  user,
  resource,
  resourceCollection,
  resourceGroup,
  organization,
} from "assets/png/placeholders";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import SecureImage from "components/UI/atoms/SecureImage";
import { extractText, linkByComponent } from "helpers/utils/utilities";
import IframePreview from "components/UI/atoms/IframePreview";
import { Link } from "react-router-dom";

export const getDefaultImageByType = (type) => {
  let dImage = defaultImage;
  // eslint-disable-next-line default-case
  switch (type) {
    case COMPONENT_TYPES.ORGANIZATION:
      dImage = organization;
      break;
    case COMPONENT_TYPES.CHALLENGE:
    case COMPONENT_TYPES.CHALLENGE_TEMPLATE:
      dImage = challenge;
      break;
    case COMPONENT_TYPES.LAB_PROGRAMS:
      dImage = labProgram;
      break;
    case COMPONENT_TYPES.RESOURCE:
      dImage = resource;
      break;
    case COMPONENT_TYPES.RESOURCE_COLLECTION:
      dImage = resourceCollection;
      break;
    case COMPONENT_TYPES.RESOURCE_GROUP:
      dImage = resourceGroup;
      break;
    case COMPONENT_TYPES.CHALLENGE_PATH:
      dImage = challengePath;
      break;
    case COMPONENT_TYPES.LAB:
    case COMPONENT_TYPES.LAB_MARKETPLACE:
      dImage = lab;
      break;
    case COMPONENT_TYPES.SKILL:
      dImage = skills;
      break;
    case COMPONENT_TYPES.PROJECT:
      dImage = project;
      break;
    case COMPONENT_TYPES.USER:
      dImage = user;
      break;
  }
  return dImage;
};

const MainProductCard = ({
  id,
  image,
  title,
  subTitle,
  col,
  description,
  iconsAndValuesList = [],
  rightAction,
  bottomAction,
  classes,
  rating,
  page,
  content,
  noSave,
  small,
  titleChip,
  type,
  onSave = Function.prototype,
  bgColor = "white",
  bottomContent,
  colBottomContent,
  imgAbsoluteContent,
  hideDescription,
  titleString,
  mediaType = "image",
  noShadow,
}) => {
  const { t } = useLocalizedTranslation();

  return (
    <Card
      data-testid="main-product-card"
      classes={`mainProduct__card ${classes} flex items-stetch px-2 py-2 overflowX-hidden ${
        small && "small"
      } ${col && "flex-column col"}`}
      bgColor={bgColor}
      noShadow={noShadow}
    >
      <div
        className={`${
          col && "mx-auto w-full mb-2 minh-220"
        } overflow-hidden imageContainer mr-3 relative ${
          type === COMPONENT_TYPES.USER && "text-center"
        }`}
        style={{ marginRight: col && "auto" }}
      >
        {!mediaType?.includes("embedded") ? (
          <SecureImage
            src={
              image ||
              (type === "user"
                ? "https://prepr.org/wp-content/uploads/2021/07/matt-300x300.jpg"
                : null)
            }
            classes={`object-contain ${
              type === "user"
                ? col
                  ? "w-210 h-210  rounded-100 mx-auto"
                  : "rounded-100 w-170 h-170 mx-auto"
                : " w-full h-full "
            }`}
            placeholder={getDefaultImageByType(type)}
            title={
              ![COMPONENT_TYPES.SKILL].includes(type)
                ? titleString ?? extractText(title)
                : null
            }
            type={type}
            small={small}
          />
        ) : (
          <IframePreview
            media={image}
            height={"100%"}
            placeholder={getDefaultImageByType(type)}
            title={
              ![COMPONENT_TYPES.SKILL].includes(type)
                ? titleString ?? extractText(title)
                : null
            }
          />
        )}

        {imgAbsoluteContent ? (
          <div className="absolute top-0 left-0 h-full w-full">
            {imgAbsoluteContent}
          </div>
        ) : null}
      </div>
      <div
        className={`overflow-hidden mb-1 content flex-1 ${
          col ? "flex-column justify-between" : ""
        }`}
      >
        <div>
          <div
            className={cx(
              "flex items-center justify-between w-full",
              (!!rightAction || !noSave) && col && "mb-1"
            )}
          >
            <Link
              to={linkByComponent(type, id, isManageListing())}
              className="c-primary text-truncate text-decoration-none"
            >
              <h2 className="fs-22 fs-18--mob">
                {title}
                {titleChip && (
                  <Chip
                    label={titleChip}
                    sx={{
                      height: "24px",
                      borderRadius: "8px",
                      color: "#777986",
                      border: "1px solid #C8C8C8",
                      fontWeight: 400,
                    }}
                  />
                )}
              </h2>
            </Link>
            {!!rightAction && !col && rightAction}
            {page !== "profile" && !noSave && !col && (
              <Button
                classes="ml-1 fs-13 minw-70"
                onClick={onSave}
                height={30}
                bordered
              >
                <Bookmark fontSize="small" /> {t("Save", "common")}
              </Button>
            )}
          </div>
          {(!small || hideDescription) && (
            <div className="flex-center">
              {!!rating && (
                <Rating
                  value={rating}
                  readOnly
                  className="mr-1"
                  precision={0.5}
                />
              )}
              <p className="fs-15--mob text-truncate">{subTitle}</p>
            </div>
          )}
          {!hideDescription ? (
            <p
              className={`${
                small ? "fs-11" : "fs-13"
              } description mt-1 maxw-100p overflow-hidden`}
              dangerouslySetInnerHTML={{ __html: description ?? "N/A" }}
            ></p>
          ) : (
            ""
          )}

          {!!content && content}
          {Boolean(bottomAction || iconsAndValuesList) && (
            <Grid
              container
              className={` ${
                col ? "flex-column" : "flex items-right justify-between"
              } `}
            >
              <Grid
                container
                item
                xs={12}
                md={col || !bottomAction ? 12 : 10}
                flexWrap={!col && bottomAction && "nowrap"}
                className={`flex items-center ${small ? "fs-12 mt-1" : "mt-3"}`}
                columnGap={col ? 3 : 1}
                rowGap={1}
              >
                {iconsAndValuesList
                  ?.filter((d) => Boolean(d))
                  ?.map(
                    (
                      {
                        icon,
                        value,
                        onClick = () => {},
                        sm = "auto",
                        iconProps = {},
                        classes: c,
                        img,
                      },
                      i
                    ) => (
                      <Grid
                        key={i}
                        sm={col && sm}
                        className={cx(
                          c,
                          "flex items-center tracking-tight",
                          col ? "mr-0" : "mr-2"
                        )}
                      >
                        {!!icon ? (
                          <Icon
                            component={icon}
                            fontSize="small"
                            className="c-primary cursor-pointer"
                            {...iconProps}
                            onClick={onClick}
                          />
                        ) : !!img ? (
                          <img
                            src={img}
                            className="object-cover w-20 h-20"
                            alt=""
                          />
                        ) : null}

                        <p className="ml-1 fs-12">{value} </p>
                      </Grid>
                    )
                  )}
              </Grid>
              {!!bottomAction && (
                <Grid
                  item
                  xs={12}
                  md={col ? 12 : 2}
                  className={` flex ${
                    col ? "justify-start mt-2" : "justify-end"
                  } `}
                >
                  {bottomAction}
                </Grid>
              )}
            </Grid>
          )}
          {!small && !!bottomContent && bottomContent}
        </div>
        {col && (
          <div className={`flex items-center justify-between `}>
            {!small && !!colBottomContent && colBottomContent}
            {(!!rightAction || !noSave) && (
              <div className="flex justify-end w-full">
                {Boolean(rightAction) && rightAction}
                {page !== "profile" && !noSave && (
                  <Button
                    classes="ml-1 fs-13 minw-70"
                    onClick={onSave}
                    height={24}
                    bordered
                  >
                    <Bookmark fontSize="small" /> {t("Save", "common")}
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

export default MainProductCard;
