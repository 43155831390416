import Card from "components/Cards/Card";
import React from "react";
import ListingContainer from "components/UI/organisms/ListingContainer";
import { Grid } from "@mui/material";
import Audio from "components/media/Audio";
import { isTrue } from "helpers/utils/validators";

const ResourceAudios = ({
  audios = [],
  embeddedAudios = [],
  setAssetComplete = Function.prototype,
  onDelete,
  isPrivate,
  t = (d) => d,
}) => {
  const isEmbeddedTypeAudio = (audio) => audio?.type === "embedded_audio";

  let embeddedAudioCount = 0;

  const allAudios = [
    ...audios,
    ...embeddedAudios.map((audio) => ({
      ...audio,
      count: embeddedAudioCount + 1,
    })),
  ];

  return (
    <Card classes="mt-4">
      <ListingContainer
        noPagination
        emptyText={t("No audios found!")}
        isEmpty={
          (!audios || !audios.length) &&
          (!embeddedAudios || !embeddedAudios.length)
        }
        classes="minh-400"
      >
        <Grid
          container
          item
          xs={12}
          rowSpacing={2}
          columnSpacing={3}
          className="h-auto"
        >
          {allAudios?.map((audio, i) => (
            <Grid key={i} item lg={12}>
              <Audio
                readOnly={!isPrivate}
                title={
                  isEmbeddedTypeAudio(audio)
                    ? `${t("Embedded Audio")} ${audio?.count}`
                    : audio?.title
                }
                audio={audio?.path}
                isCompleted={isTrue(audio?.completed)}
                onDelete={() => onDelete(audio?.id, isEmbeddedTypeAudio(audio)? "embedded_audio" : "audios")}
                onPlay={() =>
                  setAssetComplete(
                    audio?.id,
                    isEmbeddedTypeAudio(audio) ? "embedded_audio" : "audios"
                  )
                }
                isEmbedded={isEmbeddedTypeAudio(audio)}
              />
            </Grid>
          ))}
        </Grid>
      </ListingContainer>
    </Card>
  );
};

export default ResourceAudios;
