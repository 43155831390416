import Button from "../../../components/Button";
import useLabs from "../../../store/labs/service-hook";
import { useState } from "react";

const LiveEventButton = ({ lab, t = (d) => d }) => {
  const { fetchEventUrl } = useLabs();
  const [loadingEventUrl, setLoadingEventUrl] = useState(false);

  const fetchMeetingUrl = async () => {
    if (lab) {
      setLoadingEventUrl(true);
      const response = await fetchEventUrl(lab?.slug);
      setLoadingEventUrl(false);
      if (response?.event_url) {
        window.open(response?.event_url, "_blank", "noreferrer");
      }
    }
  };

  return (
    <>
      <Button
        classes="w-full mt-2"
        height="40px"
        color="yellow"
        onClick={fetchMeetingUrl}
        loading={loadingEventUrl}
      >
        {t("Live Event")}
      </Button>
    </>
  );
};

export default LiveEventButton;
