import { Typography } from "@mui/material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const TipsCard = ({ title, description, image, btnTitle, onClick }) => {
  const { t } = useLocalizedTranslation();

  return (
    <Card classes="shadow flex-column items-center h-100p overflow-hidden">
      <img src={image} alt="tips_img" className="w-100p h-150" />
      <h3 className="text-center mt-3 tracking-tight text-nowrap">
        {t(title)}
      </h3>
      <Typography
        className="fs-13 tracking-tight my-1 h-140--noMob"
        lineHeight={1.1}
      >
        {t(description)}
      </Typography>
      <Button classes="rounded-50" onClick={onClick}>
        {t(btnTitle)}
      </Button>
    </Card>
  );
};

export default TipsCard;
