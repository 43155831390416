import { Icon } from "@mui/material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import React from "react";

const PageTitleCard = ({
  title,
  actionTitle,
  actionIcon,
  onClickAction,
  noAction,
  children,
  noContainer,
  disabledAction,
  actionColor,
  customAction,
  titleCentered,
  classes,
  leftAction,
}) => {
  const { t } = useLocalizedTranslation();
  return (
    <Card
      data-testid="page-title-card"
      classes={`${noContainer ? "" : "container"} mt-header px-0 ${
        classes ?? ""
      }`}
    >
      <div
        className={`${customAction ? "flex-colOnMob" : "flex"} justify-between`}
      >
        {leftAction && leftAction}
        {title && <h2 className="ml-4 fs-23 text-nowrap">{t(title)}</h2>}

        {!noAction &&
          (customAction ? (
            customAction
          ) : (
            <Button
              onClick={onClickAction}
              color={actionColor}
              classes="mr-4"
              disabled={disabledAction}
            >
              {actionIcon && <Icon className="mr-1" component={actionIcon} />}
              {t(actionTitle)}
            </Button>
          ))}
      </div>
      {children && <div className="mt-2 mx-4">{children}</div>}
    </Card>
  );
};

export default PageTitleCard;
