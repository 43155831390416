import { Grid } from "@mui/material";
import Card from "components/Cards/Card";
import EditCreateHeaderCard from "components/Cards/EditCreateHeaderCard";
import DashboardLayout from "pages/layouts/dashboard";
import { useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import { schema, stepSchema } from "./data";
import useForms from "helpers/hooks/useForms";
import PageTitle from "components/UI/atoms/PageTitle";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import useResources from "store/resources/service-hook";
import {
  deleteValues,
  keyVal,
  payloadFormatArray,
  payloadKeyVal,
  payloadKeyVals,
  payloadToArrayKeyValues,
  scrollToTop,
} from "helpers/utils/utilities";
import { isTrue } from "helpers/utils/validators";
import CreateFormFooter from "components/UI/atoms/CreateFormFooter";
import UploadResources from "./UploadResources";
import useRedirect from "helpers/hooks/useRedirect";
import { toast } from "react-toastify";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { resourceTypeMap } from "../components/ResourceModuleMainPage";
import useSettings from "store/settings/service-hook";
import ExpertReferences from "components/UI/organisms/ExpertReferences";

const initValues = {
  is_global: true,
};

const STEPS = ["Resource Details", "Upload Resources"];
const CreateEditResource = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [embedMedia, setEmbedMedia] = useState([]);
  const [links, setLinks] = useState([]);
  const [experts, setExperts] = useState([]);
  const [resources, setResources] = useState({
    files: [],
    documents: [],
    audios: [],
    videos: [],
  });
  const { generatePresignedUrls } = useSettings();
  const [scormDetails, setScormDetails] = useState(null);
  const { id } = useParams();
  const editMode = !!id;
  const [loading, setLoading] = useState(false);
  const [resourceId, setResourceId] = useState();
  const [initLoading, setInitLoading] = useState(Boolean(editMode));
  const [scormLoading, setScormLoading] = useState(false);
  const {
    edit,
    create,
    fetchMyResource,
    uploadFiles,
    addLinks,
    deleteMedia,
    uploadScormFile,
  } = useResources();
  const { navigateResource } = useRedirect();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isGo1 = searchParams.get("go1") === "true";
  const { t } = useLocalizedTranslation();

  const sliceSchema = (_schema) => stepSchema(currentStep);

  const { data, errors, validate, onChange, dirty, setData } = useForms(
    schema,
    initValues,
    sliceSchema
  );
  const { deleteScorm } = useResources();

  useEffect(() => {
    if (editMode) handleFetchDetail();
    else {
      setData(initValues);
      setEmbedMedia([]);
      setLinks([]);
      setResources([]);
      setExperts([]);
      scrollToTop();
    }
  }, [id, editMode]);

  const handleFetchDetail = async () => {
    const res = await fetchMyResource(id);
    if (res) {
      const {
        title,
        description,
        skills = {},
        skill_groups = {},
        skill_stacks = {},
        level_id,
        duration_id,
        id,
        privacy,
        cover_image,
        is_global,
        level,
        duration,
        files = [],
        audios = [],
        videos = [],
        documents = [],
        mode,
        type,
        media_type,
        expert_references,
      } = res;
      setData({
        title,
        description,
        level_id: keyVal(level, level_id),
        duration_id: keyVal(duration, duration_id),
        skills: payloadToArrayKeyValues(skills),
        skill_groups: payloadToArrayKeyValues(skill_groups),
        skill_stacks: payloadToArrayKeyValues(skill_stacks),
        id,
        privacy: isTrue(privacy) ? "private" : "public",
        cover_image: media_type === "image" ? cover_image : null,
        is_global: isTrue(is_global),
        mode,
        type,
        media_type,
        iframe_code: media_type === "embedded" ? cover_image : null,
        expert_references,
      });
      setResourceId(res?.slug);
      setExperts(expert_references);
      setLinks(
        res?.links?.map((d) => ({
          ...d,
          social_link_id: keyVal(d?.social_link_title, +d?.social_link_id),
        })) || []
      );
      setEmbedMedia(res?.embedded_media || []);
      setResources({
        files,
        audios,
        videos,
        documents,
      });
      setInitLoading(false);
      setScormDetails(res?.scorm);
    }
  };
  const validateEmbedMedia = (embedMedia) => {
    if (!embedMedia || !embedMedia?.length) return false;
    if (embedMedia.some((media) => !media.type || !media.path)) {
      toast.error(t("Please enter the embed media information properly"));
      return true;
    }
    return false;
  };

  const validateLinks = (links) => {
    if (!links || !links?.length) return false;
    if (
      links.some((link) => !link.social_link_id || !link.title || !link.path)
    ) {
      toast.error(t("Please enter the links properly"));
      return true;
    }
    return false;
  };

  /**
   * VALIDATE SCORM FILE
   * @param file
   */
  const validateScormFile = (file) => {
    if (file) {
      const max = 150 * 1024 * 1024;
      if (file?.size > max) {
        toast.error(t("Scorm file must not exceed 500 MB."));
        return false;
      }
    }
    return true;
  };
  const handleUploadFilesLinks = async () => {
    if (validateEmbedMedia(embedMedia)) return;
    if (validateLinks(links)) return;
    if (!validateScormFile(data?.scorm_file_upload)) return;
    setLoading(true);

    /** SCORM FILE UPLOAD  */
    let scormResp;
    if (data?.scorm_file_upload) {
      const scormPayload = {
        scorm_uuid: data?.scorm_uuid,
      };
      scormResp = await uploadScormFile(resourceId, scormPayload);
    }
    /** SCORM FILE UPLOAD END */

    if (data.files?.length) {
      const filesToUpload = await generatePresignedUrls(
        data?.files,
        "resource_file"
      );
      let filePayload = {
        file_upload: filesToUpload?.map((d) => d?.response.path),
        filename: filesToUpload?.map((d) => d?.response.filename),
      };
      const res = await uploadFiles(resourceId, filePayload);
      if (!res?.success) {
        setLoading(false);
        return;
      }
    }
    let resp;
    const emToUpload = embedMedia.filter((d) => !d?.id);
    const lToUpload = links
      .filter((d) => !d?.key)
      .map((link) => {
        const { social_link_title, ...rest } = link;
        return {
          ...rest,
          social_link_id: link?.social_link_id?.key,
        };
      });

    if (emToUpload?.length || lToUpload?.length) {
      const payload = {
        links: lToUpload,
        embed_media: embedMedia,
      };

      resp = await addLinks(resourceId, payload);
    }

    setLoading(false);
    if (
      (!emToUpload?.length && !lToUpload?.length) ||
      (!!resp && resp.success && !resp?.error) ||
      (!!scormResp && scormResp?.success && !scormResp?.error) // CHECK FOR ERRORS IN SCORM FILE UPLOAD
    ) {
      navigateResource(id || resourceId, true);
    }
  };
  const handleEditCreate = async () => {
    setLoading(true);
    const payload = {
      ...data,
      is_global: isGo1 || !data?.is_global ? "no" : "yes",
      privacy: isGo1 ? "yes" : data?.privacy,
      type: data?.type ?? [],
      mode: data?.mode ?? [],
      media_type: data?.iframe_code ? "embedded" : "image",
      cover_image: data?.iframe_code ? data?.iframe_code : data?.cover_image,
      expert_references: payloadFormatArray(experts, "email"),
    };
    const deleteVals = ["iframe_code"];
    if (editMode) {
      if (
        typeof payload?.cover_image === "string" &&
        payload.media_type === "image"
      )
        deleteVals.push(...["cover_image", "media_type"]);
      else if (data.cover_image instanceof File) {
        payload.media_type = "image";
        payload.cover_image = data.cover_image;
      }
    }
    payloadKeyVal(payload, ["level_id", "duration_id"]);
    payloadKeyVals(payload, ["skills", "skill_groups", "skill_stacks"]);

    if (!data?.skill_groups || !data?.skill_groups.length) {
      deleteVals.push("skill_groups");
    }
    if (!data?.skill_stacks || !data?.skill_stacks.length) {
      deleteVals.push("skill_stacks");
    }
    if (!payload.cover_image) deleteVals.push(...["cover_image", "media_type"]);

    deleteValues(payload, deleteVals);
    const res = editMode ? await edit(id, payload) : await create(payload);
    setLoading(false);
    if (res?.slug) setResourceId(res?.slug);
    if (!!res && !res?.error && res?.slug) setCurrentStep(1);
  };

  const onCancel = () => currentStep === 1 && setCurrentStep(currentStep - 1);

  const isExpertsClean = () =>
    data?.expert_references?.length === experts?.length &&
    data?.expert_references?.every((d) =>
      experts.some((f) => d?.email === f?.email)
    );

  const onContinue = async () => {
    const _errors = await validate();
    if (_errors.size) return;
    if (currentStep === 0) {
      editMode && !dirty && isExpertsClean()
        ? setCurrentStep(1)
        : handleEditCreate();
    }
    currentStep === 1 && handleUploadFilesLinks();
  };

  /**
   * DELETE SCORM FILE
   */
  const deleteScormFile = async () => {
    const res = await deleteScorm(id);
    if (res) {
      setScormDetails(null);
    }
  };

  const handleDeleteMedia = async (assetId, type, index) => {
    const resourceApiType = resourceTypeMap[type];
    if (!assetId && typeof index === "number") {
      if (type.includes("embedded")) {
        embedMedia.splice(index, 1);
        setEmbedMedia([...embedMedia]);
      } else if (resourceApiType === "url") {
        links.splice(index, 1);
        setLinks([...links]);
      }
    } else {
      const res = await deleteMedia(resourceId, assetId, resourceApiType);
      if (res) {
        if (
          resourceApiType === "embedded_video" ||
          resourceApiType === "embedded_audio"
        ) {
          setEmbedMedia((prevMedia) => {
            return prevMedia.filter((item) => item.id !== assetId);
          });
        } else if (resourceApiType === "url") {
          setLinks((prevLinks) => {
            return prevLinks?.filter((link) => link.id !== assetId);
          });
        } else
          setResources((prevResources) => ({
            ...prevResources,
            [type]: prevResources[type]?.filter((item) => item.id !== assetId),
          }));
      }
    }
  };

  return (
    <DashboardLayout loading={initLoading}>
      <EditCreateHeaderCard
        current={currentStep}
        length={2}
        title={`${editMode ? "EDIT" : "CREATE A"} RESOURCE MODULE`}
        subtitles={STEPS}
        classes="mt-header"
      />
      <Card
        width="min(1300px,90%)"
        centered
        classes="mt-6 mb-4 mx-auto coulmn-center"
      >
        {currentStep === 0 && (
          <ResourceModuleDetails
            data={data}
            errors={errors}
            onChange={onChange}
            step={currentStep}
            isGo1={isGo1}
            t={t}
            experts={experts}
            setExperts={setExperts}
          />
        )}
        {currentStep === 1 && (
          <UploadResources
            data={data}
            errors={errors}
            onChange={onChange}
            step={currentStep}
            embedMedia={embedMedia}
            setEmbedMedia={setEmbedMedia}
            links={links}
            setLinks={setLinks}
            onDelete={handleDeleteMedia}
            slug={id}
            resources={resources}
            editMode={!!editMode}
            scormDetails={scormDetails}
            deleteScormFile={deleteScormFile}
            setScormLoading={setScormLoading}
          />
        )}
        <CreateFormFooter
          step={currentStep}
          stepsLength={STEPS.length}
          onCancel={onCancel}
          disabled={!!errors.size || scormLoading}
          loading={loading}
          onOk={onContinue}
          editMode={editMode}
          backButtonColor="blue"
          okButtonColor="blue"
        />
      </Card>
    </DashboardLayout>
  );
};

export default CreateEditResource;

const ResourceModuleDetails = ({
  data,
  errors,
  onChange,
  step,
  isGo1,
  experts = [],
  setExperts = Function.prototype,
  t = (d) => d,
}) => {
  return (
    <Grid container px={{ xs: 0, md: 4 }}>
      <PageTitle>{t("Resource Module Details")}</PageTitle>
      <CreateFormSection classes="mt-5">
        <SchemaUIExtractor
          schema={stepSchema(step).slice(0, 1)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
      </CreateFormSection>
      <CreateFormSection classes="mt-5">
        <h3 className="mt-4">{t("Resource Module Information")}</h3>
        <SchemaUIExtractor
          schema={stepSchema(step)
            .slice(1, 9)
            .map((d) => {
              if ((d?.name === "is_global" || d?.name === "privacy") && isGo1)
                d.disabled = true;
              return d;
            })}
          data={data}
          errors={errors}
          onChange={onChange}
        />
        <h3 className="mt-4">{t("Skills & Tags")}</h3>
        <SchemaUIExtractor
          schema={stepSchema(step).slice(9, 12)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
        <h3 className="mt-4">{t("Add Experts")}</h3>
        <p>{t("Feature experts for your resource module")}</p>
        <ExpertReferences experts={experts} setExperts={setExperts} />
      </CreateFormSection>
    </Grid>
  );
};
