import { EditNote } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";

const TopRightEditIcon = ({ onEdit, top = 0, right = 0 }) => {
  return (
    <IconButton
      onClick={onEdit}
      className={`absolute top-${top} right-${right} cursor-pointer`}
    >
      <EditNote fontSize="medium" className="c-black" />
    </IconButton>
  );
};

export default TopRightEditIcon;
