import { Grid } from "@mui/material";
import React from "react";
import "./mainPage.scss";

const MainPageLayout = ({
  children,
  classes,
  firstMdGridSize = 3,
  secondMdGridSize = 9,
  firstLgGridSize,
  secondLgGridSize,
}) => {
  return (
    <div style={{ minHeight: "82dvh" }} className={`container ${classes}`}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={firstMdGridSize}
          lg={firstLgGridSize ? firstLgGridSize : firstMdGridSize}
        >
          {children[0]}
        </Grid>
        <Grid
          item
          xs={12}
          md={secondMdGridSize}
          lg={secondLgGridSize ? secondLgGridSize : secondMdGridSize}
        >
          {children[1]}
        </Grid>
      </Grid>
    </div>
  );
};

export default MainPageLayout;
