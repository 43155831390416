import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
} from "@mui/material";
import CreateFormTitle from "components/UI/atoms/CreateFormTitle";
import BlueAccordion from "components/UI/molecules/BlueAccordion";
import ListingContainer from "components/UI/organisms/ListingContainer";
import MasterSelect from "components/UI/organisms/MasterSelect";
import { keyVal, payloadBool } from "helpers/utils/utilities";
import { isTrue } from "helpers/utils/validators";
import React, { useEffect, useState } from "react";
import RichTextEditor from "react-rte";
import useMaster from "store/master/service-hook";
import { COLORS } from "helpers/utils/constants";
import { CheckCircle } from "@mui/icons-material";
import { LockIcon } from "components/Statics";
import moment from "moment";
import { payloadFormatArray } from "helpers/utils/utilities";
const STYLES = {
  ACCORDIAN: {
    boxShadow: "none !important",
    "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "50px",
    },
  },
};

const getTitleWithIcon = (isCompleted, titleText) => (
  <span className="flex-center">
    {isCompleted && <CheckCircle className="mr-2 c-success" />}
    {titleText}
  </span>
);

const ProjectPitch = ({
  templateId = 0,
  templateTitle,
  pitchNTasks,
  setPitchNTasks = Function.prototype,
  isDetailPage,
  pitchCompleted,
  taskCompleted,
  editPitch,
  setEditPitch,
  challenge_details,
  disablePitch,
  quickEdit = true,
  t = (d) => d,
}) => {
  const [loading, setLoading] = useState(false);
  const [tId, setTId] = useState(
    templateId ? keyVal(templateTitle, templateId) : {}
  );
  const { fetchChallengePitchTask } = useMaster();

  console.log(templateId,pitchNTasks);

  useEffect(() => {
    if (!templateId) handleFetchTasks();
  }, [tId?.key]);

  const handleFetchTasks = async () => {
    setLoading(true);
    const res = await fetchChallengePitchTask(tId?.key);
    console.log(res);
    setLoading(false);
    if (res) {
      setPitchNTasks(res[0] || undefined);
    }
  };
  const handleChange = (id, value, isTask = false) => {
    if (!editPitch && !isDetailPage) setEditPitch(true);
    const { challenge_pitch, challenge_task, ...rest } = pitchNTasks;
    let index;
    const updateList = (list) => {
      index = list.findIndex((d) => d?.id === id);
      list[index] = {
        ...list[index],
        answer: isTask ? payloadBool(value) : value,
      };
    };
    if (isTask) updateList(challenge_task);
    else updateList(challenge_pitch);
    setPitchNTasks({
      challenge_pitch: [...challenge_pitch],
      challenge_task: [...challenge_task],
      ...rest,
    });
  };

  return (
    <Container xs={12} md={10} disableGutters>
      {!isDetailPage && (
        <>
          <CreateFormTitle
            title="Project Details"
            classes="mt-6"
          />
          <Box
            className={`h-50 bg-primary mt-4 items-center justify-between px-3 flex-colOnMob`}
          >
            <p className="c-white flex-1 fw-500 flex items-center">
              {t("Project Template")} <LockIcon />
            </p>
            <MasterSelect
              name="template_id"
              required
              placeholder="Please choose a template"
              disabled={!!templateId && templateId !== 0}
              value={tId}
              classes="flex-1 maxh-25 h-25"
              onChange={(e) => {
                setTId(e.target.value);
                if (!editPitch && !isDetailPage) setEditPitch(true);
              }}
              width="100%"
              t={t}
            />
          </Box>
        </>
      )}

      <BlueAccordion
        title={getTitleWithIcon(taskCompleted, t("Challenge Tasks"))}
        sx={STYLES.ACCORDIAN}
        locked
        classes="mt-4"
        color={!tId?.key && !isDetailPage && COLORS.GRAY}
        disabled={!tId?.key && !isDetailPage}
      >
        <ListingContainer
          emptySmall
          isEmpty={!pitchNTasks || !pitchNTasks?.challenge_task?.length}
          emptyText={t("No Project Pitch Task")}
          noPagination
          spinLoader
          loading={loading}
          classes="mt-3"
        >
          {pitchNTasks?.challenge_task?.map((task) => (
            <Grid
              item
              key={task?.id}
              className="flex-center justify-between fs-14"
              xs={12}
              my={0.1}
            >
              <FormControlLabel
                control={<Checkbox checked={isTrue(task?.answer)} />}
                label={<p className="fs-15">{task?.title}</p>}
                size="small"
                onChange={(e) => handleChange(task?.id, e.target.checked, true)}
                disabled={disablePitch || !quickEdit}
              />
              <p
                className={`${
                  task?.task_completed_at ? "c-success" : "c-orange"
                } tracking-tight text-nowrap`}
              >
                {task?.task_completed_at
                  ? `${t("Completed on")} ${moment(
                      task?.task_completed_at
                    ).format("MMM D, YYYY")}`
                  : `${t("Due on")} ${moment(
                      challenge_details?.due_date
                    ).format("MMM DD YYYY ha")}`}
              </p>
            </Grid>
          ))}
        </ListingContainer>
      </BlueAccordion>
      <BlueAccordion
        title={getTitleWithIcon(pitchCompleted, t("Challenge Questions"))}
        sx={STYLES.ACCORDIAN}
        locked
        color={!tId?.key && !isDetailPage && COLORS.GRAY}
        disabled={!tId?.key && !isDetailPage}
        classes="mt-1"
      >
        <ListingContainer
          emptySmall
          isEmpty={!pitchNTasks || !pitchNTasks?.challenge_pitch?.length}
          emptyText={t("No Project Pitch Summary")}
          noPagination
          spinLoader
          loading={loading}
        >
          {pitchNTasks?.challenge_pitch?.map((task, i) => (
            <Grid item xs={12} key={task?.id}>
              <h4 className="mt-3 mb-1">
                {i + 1}. {task.title}
              </h4>
              <p className="py-2">
                {!quickEdit ? (
                  task?.answer ? (
                    <span
                      className=""
                      dangerouslySetInnerHTML={{
                        __html:
                          typeof task.answer === "string"
                            ? task.answer
                            : task.answer.toString("html"),
                      }}
                    ></span>
                  ) : (
                    <span className="fs-12 c-gray">
                      {t("No answer provided")}
                    </span>
                  )
                ) : null}
              </p>
              {quickEdit && (
                <RichTextEditor
                  editorClassName="minh-100 fs-14"
                  placeholder={task?.description}
                  value={
                    !task?.answer
                      ? RichTextEditor.createEmptyValue()
                      : typeof task?.answer === "string"
                      ? RichTextEditor.createValueFromString(
                          task?.answer,
                          "html"
                        )
                      : task?.answer
                  }
                  onChange={(value) => handleChange(task?.id, value)}
                  disabled={disablePitch}
                />
              )}
            </Grid>
          ))}
        </ListingContainer>
      </BlueAccordion>
    </Container>
  );
};

export default ProjectPitch;
