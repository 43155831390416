import {createSlice} from "@reduxjs/toolkit";
import {ADVANCE_SEARCH_COMPONENTS} from "../../pages/advanceSearch/data";

/**
 * Initial state of the store
 */
const initialState = {
    [ADVANCE_SEARCH_COMPONENTS.LABS]: {},
    [ADVANCE_SEARCH_COMPONENTS.LAB_PROGRAMS]: {},
    [ADVANCE_SEARCH_COMPONENTS.LAB_MARKET_PLACE]: {},
    [ADVANCE_SEARCH_COMPONENTS.CHALLENGES]: {},
    [ADVANCE_SEARCH_COMPONENTS.CHALLENGE_TEMPLATES]: {},
    [ADVANCE_SEARCH_COMPONENTS.CHALLENGE_PATHS]: {},
    [ADVANCE_SEARCH_COMPONENTS.RESOURCE_MODULES]: {},
    [ADVANCE_SEARCH_COMPONENTS.RESOURCE_COLLECTION]: {},
    [ADVANCE_SEARCH_COMPONENTS.RESOURCE_GROUPS]: {},
    [ADVANCE_SEARCH_COMPONENTS.PROJECTS]: {},
    [ADVANCE_SEARCH_COMPONENTS.ORGANIZATIONS]: {},
    [ADVANCE_SEARCH_COMPONENTS.USERS]: {},
}


const AdvanceSearchSlice = createSlice({
    initialState,
    name: 'advanceSearch',
    reducers: {
        setChallenges(state, action) {
            state.challenges = {
                ...state?.challenges,
                ...action?.payload
            }
        },

        setChallengePaths(state, action) {
            state.challengePaths = {
                ...state?.challengePaths,
                ...action?.payload
            }
        },

        setChallengeTemplates(state, action) {
            state.challengeTemplates = {
                ...state?.challengeTemplates,
                ...action?.payload
            }
        },

        setLabs(state, action) {
            state.labs = {
                ...state?.labs,
                ...action?.payload
            }
        },

        setLabPrograms(state, action) {
            state.labPrograms = {
                ...state?.labPrograms,
                ...action?.payload
            }
        },

        setLabMarketplace(state, action) {
            state.labMarketPlace = {
                ...state?.labMarketPlace,
                ...action?.payload
            }
        },

        setResourceModules(state, action) {
            state.resourceModules = {
                ...state?.resourceModules,
                ...action?.payload
            }
        },

        setResourceGroups(state, action) {
            state.resourceGroups = {
                ...state?.resourceGroups,
                ...action?.payload
            }
        },

        setResourceCollection(state, action) {
            state.resourceCollection = {
                ...state?.resourceCollection,
                ...action?.payload
            }
        },

        setProjects(state, action) {
            state.projects = {
                ...state?.projects,
                ...action?.payload
            }
        },

        setUsers(state, action) {
            state.users = {
                ...state?.users,
                ...action?.payload
            }
        },

        setOrganizations(state, action) {
            state.organizations = {
                ...state?.organizations,
                ...action?.payload
            }
        },

        clearState(state, action) {
            state[action.payload] = []
        }

    }
})

export const AS_REDUCER = AdvanceSearchSlice.actions;

export default AdvanceSearchSlice.reducer
