import ModalLayout from "components/Modal";
import React from "react";
import keyIcon from "assets/svg/yellow/key.svg";
import Button from "components/Button";
import useRedirect from "helpers/hooks/useRedirect";

const UpgradePlanPopup = ({ open, onClose }) => {
  const { navigateUpgradePlan } = useRedirect();
  return (
    <ModalLayout open={open} width="min(95%,700px)" onClose={onClose}>
      <div className="flex flex-column item-center justify-center my-4 px-6 text-center c-title">
        <img src={keyIcon} alt="key icon" height={150} />
        <h2 className="mt-4 fs-26">Upgrade Your Account</h2>
        <p className="fs-16 fw-500 my-2">
          The winner achievement feature is not offered under your plan. Upgrade
          your account now to get the access of premium features.
        </p>
        <div className="flex items-center justify-between mt-4">
          <Button bordered onClick={onClose}>
            Close
          </Button>
          <Button onClick={navigateUpgradePlan}>View Plans</Button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default UpgradePlanPopup;
