import { Security, Spa, Work, Done } from "@mui/icons-material";
import { Box, Grid, Icon } from "@mui/material";
import MainProductCard from "components/Cards/MainProductCard";
import ListingContainer from "components/UI/organisms/ListingContainer";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import React, { useState } from "react";
import { isTrue } from "helpers/utils/validators";
import jobDefaultImage from "assets/png/job-default.png";
import { challenge } from "assets/svg/green";
import { resource } from "assets/svg/purple";
import { lab } from "assets/svg/blue";
import { skills as skillImg } from "assets/svg/yellow";
import skillDefaultImage from "assets/png/skill-default.png";
import logo from "assets/svg/logo.svg";
import ChipsPreview from "components/UI/atoms/ChipsPreview";
import useCareers from "store/careers/service-hook";
import useAuth from "store/auth/service-hook";
import { loginToPreceed } from "helpers/utils/utilities";
import Button from "components/Button";
import useProfile from "store/profile/service-hook";
import useSkills from "store/skills/service-hook";
import useRedirect from "helpers/hooks/useRedirect";

const SkillsJobsListing = ({
  gridType,
  list = [],
  setList = Function.prototype,
  loading,
  page = 1,
  setPage = Function.prototype,
  pageCount = 1,
  isSkills,
  t,
  searchVal,
}) => {
  const [showAllSkills, setShowAllSkills] = useState(false);
  const { addJob, deleteJob } = useCareers();
  const { deleteSkills } = useProfile();
  const { add } = useSkills();
  const { navigateProfile } = useRedirect();
  const {
    state: { isLoggedIn, user },
  } = useAuth();
  const doUndoAction = async (id, property, doAction, undoAction) => {
    if (!isLoggedIn) {
      loginToPreceed();
      return;
    }
    const item = list.find((d) => d.id === id);
    if (!item) return;
    const did = !!item[property] && item[property]?.toLowerCase() !== "no";
    if (did) {
      await undoAction(id);
    } else {
      await doAction(id);
    }
    item[property] = did ? "no" : "yes";
    setList([...list]);
  };

  const handleSaveUnsaveJobs = (id) =>
    doUndoAction(id, "saved", addJob, deleteJob);

  const handleSaveUnsaveSkills = (id) =>
    doUndoAction(id, "saved", add, deleteSkills);

  const handleSaveUnsave = (id) => () =>
    isSkills ? handleSaveUnsaveSkills(id) : handleSaveUnsaveJobs(id);
  return (
    <ListingContainer
      loading={loading}
      isEmpty={!list?.length}
      count={pageCount}
      page={page}
      setPage={setPage}
      classes="mt-4"
      emptyView={
        searchVal ? (
          false
        ) : (
          <Box className="h-250 flex-column items-center justify-center px-4">
            <p className="text-center fs-14">
              {t(
                isSkills
                  ? "These recommendations are fine-tuned based on the skills you've added to your profile. The more you enrich your profile with additional skills, the more accurate and relevant the results become."
                  : "These recommendations are fine-tuned based on the jobs you've added to your profile. The more you enrich your profile with additional jobs, the more accurate and relevant the results become."
              )}
            </p>
            <Button
              classes="mt-6"
              onClick={() => navigateProfile(user?.username)}
            >
              {t(isSkills ? "Add Your Skills" : "Add Your Work Experience")}
            </Button>
          </Box>
        )
      }
      emptyText={t(isSkills ? "No skills found!" : "No jobs found!")}
      noPagination={isSkills}
    >
      {list
        .slice(0, !isSkills || showAllSkills ? list.length : 6)
        ?.map((d, i) => {
          const {
            title,
            description,
            privacy,
            id,
            related_labs,
            related_challenges,
            related_resources,
            skills,
            related_skills,
          } = d;
          return (
            <Grid item key={i} xs={gridType === 1 ? 12 : 4}>
              <MainProductCard
                classes="mb-3"
                title={
                  <div className="flex items-center">
                    <p className="text-nowrap overflow-hidden maxw-85p textOverflow-ellipsis">
                      {title}
                    </p>
                    <Security
                      fontSize="small"
                      style={{
                        visibility: isTrue(privacy) ? "visible" : "hidden",
                      }}
                    />
                  </div>
                }
                description={description || "N/A"}
                col={gridType !== 1}
                noSave={isTrue(d?.saved)}
                onSave={handleSaveUnsave(id)}
                rightAction={
                  isTrue(d?.saved) && (
                    <Button
                      bordered
                      color="green"
                      height="30px"
                      classes="fs-13"
                      onClick={handleSaveUnsave(id)}
                    >
                      <Done fontSize="small" />
                      {t("Saved")}
                    </Button>
                  )
                }
                iconsAndValuesList={[
                  related_challenges > 0 && {
                    img: challenge,
                    value: `${related_challenges} ${t("Related Challenges")}`,
                  },
                  related_labs > 0 && {
                    img: lab,
                    value: `${related_labs} ${t("Related Labs")}`,
                  },
                  related_resources > 0 && {
                    img: resource,
                    value: `${related_resources} ${t("Related Resources")}`,
                  },
                  skills?.length > 0 && {
                    img: skillImg,
                    value: `${skills.length} ${t("Related Skills")}`,
                  },
                ].filter(Boolean)}
                type={isSkills ? COMPONENT_TYPES.SKILL : COMPONENT_TYPES.JOB}
                image={isSkills ? skillDefaultImage : jobDefaultImage}
                id={id}
                imgAbsoluteContent={
                  <Box className="flex flex-column items-center justify-between h-full w-full">
                    <div
                      className={`flex justify-between w-full ${
                        isSkills ? "c-orange fw-500" : "c-white"
                      } px-1`}
                    >
                      <img src={logo} height={18} alt="logo" />
                      <div className="fs-10 flex mt-1">
                        <p>{isSkills ? "SKILL" : "JOB"}</p>
                        <Icon
                          component={isSkills ? Spa : Work}
                          sx={{ fontSize: 13 }}
                        />
                      </div>
                    </div>
                    <div className="h-40 text-center w-full flex items-center justify-center bg-gray">
                      <h2 className="uppercase fs-18 tracking-tight text-center mx-auto text-truncate maxw-90p">
                        {title}
                      </h2>
                    </div>
                    <p className="text-center fs-10 mb-1 c-white">
                      CO-LEARN. CO-LAB. CO-SOLVE.®
                    </p>
                  </Box>
                }
                bottomContent={
                  <div className="flex mt-2 mb-1">
                    <ChipsPreview
                      items={isSkills ? related_skills : skills}
                      limit={gridType === 1 ? 3 : 2}
                    />
                  </div>
                }
              />
            </Grid>
          );
        })}
      {isSkills && list.length > 3 && (
        <div className="flex w-100p justify-center mt-2">
          <Button
            sx={{ textDecoration: "underline", textTransform: "capitalize" }}
            onClick={() => setShowAllSkills(!showAllSkills)}
          >
            {t(showAllSkills ? "Show Less" : "Browse All Skills")}
          </Button>
        </div>
      )}
    </ListingContainer>
  );
};

export default SkillsJobsListing;
