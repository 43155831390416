import React from "react";
import useMaster from "../../../../store/master/service-hook";
import SubFilter from "./SubFilter";
import { FilterComponentsList } from "../../data";
import { useAdvanceSearchContext } from "../../hooks/useAdvanceSearchContext";

const Duration = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { fetchDurations } = useMaster();
  const { duration, setDuration, ActiveFilterComponents } =
    useAdvanceSearchContext();

  return (
    <SubFilter
      title={t("DURATION")}
      fetchData={fetchDurations}
      values={duration}
      onChange={(checked, value) => {
        let temp = [...duration];
        if (checked) {
          temp.push(value);
        } else {
          temp = temp.filter((item) => item !== value);
        }
        setDuration(temp);
      }}
      valueKey={"id"}
      labelKey={"title"}
      show={ActiveFilterComponents.includes(FilterComponentsList.duration)}
      t={t}
    />
  );
};

export default Duration;
