import DashboardLayout from 'pages/layouts/dashboard'
import React from 'react'
import AdvancedSearch from './components/AdvancedSearch'

const SearchModule = () => {
  return (
   <DashboardLayout>
    <AdvancedSearch/>
   </DashboardLayout>
  )
}

export default SearchModule