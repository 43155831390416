import React, { useEffect, useState } from "react";
import {
  CalendarMonth,
  Extension,
  Visibility,
  Info,
  BarChart,
  LocationOn,
  LockOpen,
  AccessTime,
  EditCalendarOutlined,
  CrisisAlertOutlined,
  ChevronRight,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { Avatar, Chip, Grid, Icon, IconButton, Stack } from "@mui/material";
import Card from "components/Cards/Card";
import IconText from "components/UI/atoms/IconText";
import HostedBySection from "components/UI/atoms/HostedBySection";
import InfoCard from "components/Cards/InfoCard";
import { COMPONENT_TYPES, INFO_TYPES } from "helpers/utils/constants";
import { isTrue } from "helpers/utils/validators";
import moment from "moment";
import useMaster from "store/master/service-hook";
import SecureImage from "components/UI/atoms/SecureImage";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { resource, resourceGroup } from "assets/svg/purple";
import { user, users } from "assets/svg/blue";
import { org } from "assets/svg";
import AchievementsAndSkillsCard from "../molecules/AchievementsAndSkillsCard";
import socialicon from "assets/png/socialicon.png";
import useRedirect from "helpers/hooks/useRedirect";
import { extractChallengeDate } from "helpers/utils/utilities";
import { AssociatedItem } from "pages/projects/components/ProjectDescription";
import userImage from "../../../assets/svg/user.svg";
import { capitalize } from "lodash";

const PublicDescription = ({
  data,
  component,
  hideSocialLinks,
  hideRequirements,
  showTags,
  children,
  isCreatedBy,
  hideHostedBy,
  hideDescription = true,
  hideDateValue,
  isPrivate = false,
  hideProgress,
  hideChallengeRequirementIcon = false,
}) => {
  const { t } = useLocalizedTranslation("common");
  const [expertsExpanded, setExpertsExpanded] = useState(false);
  const {
    state: { socialLinks = [] },
    fetchSocialLinks,
  } = useMaster();

  useEffect(() => {
    if (!socialLinks.length) fetchSocialLinks();
  }, []);

  const infos = {
    [COMPONENT_TYPES.CHALLENGE]: {
      title: "Challenge",
      // date_title: "Open Until",
      showOrganization: true,
      showDuration: true,
      showLevel: true,
      showSubmission: !hideDateValue && true,
      showLabs: true,
      showSponsors: true,
      date_value:
        data?.challenge_status === "Closed"
          ? "Closed Challenge"
          : extractChallengeDate(data),
      // contains_value: [{ title: `${data?.submissions_count} Projects` }],
      social_links: data?.external_links || [],
      requirements: Object.values(
        data?.challenge_requirements?.project_submission_requirement_ids || {}
      ).map((value) => ({ title: value })),
      submission: data?.challenge_status,
      showType: true,
      showMode: true,
    },
    [COMPONENT_TYPES.LAB]: {
      title: "Lab",
      date_title: false,
      date_value: moment(new Date(data?.last_updated)).fromNow(),
      contains_value: [
        { title: `${data?.challenge_count || 0} ${t("Challenges")}` },
        {
          icon: resource,
          title: `${data?.resource_module_count || 0} ${t("Resources")}`,
        },
      ],
      social_links: data?.external_links || [],
      requirements: [],
      showDuration: true,
      showOrganization: true,
      showMembers: true,
      showLocation: true,
      showType: true,
      showMode: true,
      showLevel: true,
    },
    [COMPONENT_TYPES.LAB_MARKETPLACE]: {
      title: "Lab",
      date_title: false,
      date_value: moment(new Date(data?.last_updated)).fromNow(),
      contains_value: [
        { title: `${data?.challenge_count || 0} ${t("Challenges")}` },
        {
          icon: resource,
          title: `${data?.resource_module_count || 0} ${t("Resources")}`,
        },
      ],
      social_links: data?.external_links || [],
      requirements: [],
      showDuration: true,
      showOrganization: true,
      showMembers: false,
      showLocation: true,
      showType: true,
      showMode: true,
      showLevel: true,
    },
    [COMPONENT_TYPES.LAB_PROGRAMS]: {
      title: "Lab Program",
      showOrganization: true,
      showDuration: true,
      showMembers: true,
      showLevel: true,
      contains_value: [{ title: `${data?.lab_count || 0} Labs` }],
      social_links: [],
      requirements: [],
    },
    [COMPONENT_TYPES.CHALLENGE_PATH]: {
      title: "Challenge Path",
      date_title: false,
      // contains_value: [{ title: `${data?.challenges?.length} Challenges` }],
      social_links: [],
      requirements: [],
      showLevel: true,
      showOrganization: true,
      showDuration: true,
      showChallenge: true,
      showType: true,
      showMode: true,
    },
    [COMPONENT_TYPES.RESOURCE]: {
      title: "Resource",
      date_title: false,
      contains_value: [],
      showOrganization: true,
      showLevel: true,
      showMode: true,
      showDuration: true,
      showType: true,
    },
    [COMPONENT_TYPES.RESOURCE_COLLECTION]: {
      title: "Resource Collection",
      date_title: false,
      showOrganization: true,
      contains_value: [
        { title: `${data?.resource_modules?.length} ${t("Resources")}` },
      ],
      showLevel: true,
      showDuration: true,
      showType: true,
      showMode: true,
    },
    [COMPONENT_TYPES.RESOURCE_GROUP]: {
      title: "Resource Group",
      date_title: false,
      showOrganization: true,
      contains_value: [
        {
          icon: resource,
          title: `${data?.resource_modules?.length} ${t("Resource Modules")}`,
        },
        {
          icon: resourceGroup,
          title: `${data?.resource_collection?.length} ${t(
            "Resource Collections"
          )}`,
        },
      ],
      showLevel: true,
      showDuration: true,
      showType: true,
      showMode: true,
    },
  };

  const {
    title,
    date_title,
    date_value,
    contains_value,
    social_links,
    requirements,
    showDuration,
    showLevel,
    showOrganization,
    showMembers,
    showLocation,
    submission,
    showLabs,
    showSubmission,
    showSponsors,
    showType,
    showMode,
    showChallenge,
  } = infos[component];

  const filteredLabs = data?.labs?.filter((item) => item !== null) || [];
  const filteredLabProgram =
    data?.lab_programs?.filter((item) => item !== null) || [];

  const { navigateOrg, navigateProfile } = useRedirect();

  return (
    <Card classes="mt-2 overflowX-hidden">
      <h2>{data?.title}</h2>
      <div className="flex flex-wrap gap-10">
        {showOrganization && (
          <>
            <div
              className="flex-center mt-1 fs-26 c-primary"
              data-testid="icon-text"
            >
              <img className="w-20 h-25" src={org} alt="Organization Icon" />
              <span className="ml-1 fs-14 fw-500 tracking-tight c-black">
                {t("Organization", "common")}:{" "}
                <span
                  className="c-primary cursor-pointer"
                  onClick={() => navigateOrg(data.organization_slug, isPrivate)}
                >
                  {" "}
                  {data?.organization || "N/A"}
                </span>
              </span>
            </div>
          </>
        )}

        {!!date_title && (
          <IconText
            icon={<AccessTime className="c-black" />}
            text={`${t(date_title, "common")} ${date_value}`}
          />
        )}

        <IconText
          icon={<Visibility className="c-black" />}
          text={
            isTrue(data?.privacy)
              ? `${t("Private", "common")} ${t(title, "common")}`
              : `${t("Public", "common")} ${t(title, "common")}`
          }
        />

        {showDuration && (
          <IconText
            icon={<CalendarMonth className="c-black" />}
            text={`${t("Duration", "common")}: ${
              data?.duration ? data?.duration : t("Not Set")
            }`}
          />
        )}
        {showLevel && data?.level && (
          <IconText
            icon={<BarChart className="c-black" />}
            text={data?.level}
          />
        )}

        {showChallenge && (
          <IconText
            icon={<BarChart className="c-black" />}
            text={`${data?.challenge_count} ${t("Challenges")}`}
          />
        )}

        {showSubmission && (
          <IconText
            icon={<LockOpen className="c-black" />}
            text={`${t(
              submission === "Active"
                ? "Open for Submission"
                : submission === "Closed"
                ? "Closed for Submission"
                : submission,
              "common"
            )} `}
          />
        )}

        {contains_value &&
          contains_value.length > 0 &&
          contains_value.map((item, index) => (
            <IconText
              key={index}
              icon={
                item.icon ? (
                  <img className="w-20 h-25" src={item.icon} alt="" />
                ) : (
                  <Extension />
                )
              }
              text={`${t("Contains", "common")} ${t(item.title, "common")}`}
            />
          ))}

        {showMembers && (
          <IconText
            icon={<img className="w-20 h-25" src={users} alt="" />}
            text={`${data?.member_count || 0} ${t("Members", "common")}`}
          />
        )}
        {showLocation && (data?.address?.city || data?.address?.country) && (
          <IconText
            icon={<LocationOn />}
            text={`${data?.address?.city || ""} ${
              data?.address?.country || ""
            }`}
          />
        )}

        {showType && Array.isArray(data?.type) && data?.type?.length > 0 && (
          <IconText
            icon={<CrisisAlertOutlined className="c-black" />}
            text={data?.type?.map((d) => capitalize(t(d, "common"))).join(", ")}
          />
        )}

        {showMode && data?.mode?.length > 0 && (
          <IconText
            icon={<EditCalendarOutlined className="c-black" />}
            text={data.mode.map((d) => capitalize(t(d, "common"))).join(", ")}
          />
        )}
      </div>

      {!hideDescription && (
        <>
          <h3 className="mt-4">{t("Description", "common")}</h3>
          <div
            className="w-90p fs-12 mt-2 ml-1"
            dangerouslySetInnerHTML={{ __html: data?.description }}
          />
        </>
      )}

      {children}

      {!hideRequirements && (
        <InfoCard
          title={
            <div className="flex items-center fw-700 ">
              <Info className="mr-1 c-primary fs-35" />{" "}
              {`${t(title)} ${t("Requirements", "common")}`}{" "}
            </div>
          }
          list={requirements}
          type={INFO_TYPES.PRIMARY}
          hasObjects
          classes="w-full mt-3 "
          hideIcon={hideChallengeRequirementIcon}
          hideToggleBtn
          t={t}
        >
          <div className="flex fs-14 pt-2 items-center fw-700 ">
            {`${t(title)} ${t("Expiry Date", "common")}`}{" "}
          </div>
          <p className="fs-13">{infos[component].date_value}</p>
        </InfoCard>
      )}

      {showTags && (
        <>
          <h3 className="mt-2">{t("Tags", "common")}</h3>
          <div className="flex items-center flex-wrap">
            {data.tags && !Array.isArray(data.tags) ? (
              Object.values(data.tags).map((title, index) => (
                <Chip
                  key={index}
                  label={title}
                  variant="outlined"
                  className="mr-1 mt-1"
                />
              ))
            ) : (
              <h3 className="opacity-60">{t("N/A", "common")}</h3>
            )}
          </div>
        </>
      )}

      <AchievementsAndSkillsCard
        data={data}
        component={title}
        hideProgress={hideProgress}
      />
      {!hideHostedBy && <HostedBySection isCreatedBy={isCreatedBy} />}
      {data?.expert_references?.length ? (
        <div>
          <h3 className="mt-4">
            {t("Experts", "common")}
            {data?.expert_references?.length > 6 && (
              <IconButton
                className="h-30 w-30 ml-1 c-black"
                onClick={() => setExpertsExpanded((prev) => !prev)}
              >
                <Icon
                  component={expertsExpanded ? KeyboardArrowDown : ChevronRight}
                />
              </IconButton>
            )}
          </h3>
          <Grid container spacing={1} mt={1}>
            {data?.expert_references
              ?.slice(0, expertsExpanded ? data?.expert_references?.length : 6)
              ?.map((d) => (
                <Grid
                  item
                  xs={4}
                  className="fs-12 flex flex-column items-center text-center cursor-pointer"
                  onClick={() => navigateProfile(d?.username)}
                >
                  <Avatar src={d?.profile_image} className="h-60 w-60 bg-white">
                    <img src={user} className="h-60 w-60" />
                  </Avatar>
                  <p className="fw-500 mt-1 text-truncate maxw-140">
                    {d?.full_name}
                  </p>
                  <p className="capitalize">
                    {t(
                      (d?.role || "")?.split("_")?.join(" ") || "User",
                      "common"
                    )}
                  </p>
                </Grid>
              ))}
          </Grid>
        </div>
      ) : null}
      {!hideSocialLinks && social_links?.length > 0 && (
        <div>
          <h3 className="mt-4">{t("Social Links", "common")}</h3>
          <Stack direction="row" spacing={1}>
            {social_links.map(({ id, social_media_link, link, image }) => (
              <SecureImage
                key={id}
                src={image}
                placeholder={socialicon}
                height={30}
                classes="cursor-pointer"
                onClick={() => window.open(social_media_link || link)}
              />
            ))}
          </Stack>
        </div>
      )}
      {showLabs && (
        <>
          {filteredLabs.length > 0 && (
            <>
              <h3 className="mt-4">{t("Associated Labs", "common")}</h3>
              {filteredLabs.map((lab) => (
                <AssociatedItem
                  key={lab.uuid}
                  title={lab?.title}
                  description={lab.description}
                  media={lab?.media}
                  type={COMPONENT_TYPES.LAB}
                  id={lab?.slug}
                />
              ))}
            </>
          )}
          {filteredLabProgram.length > 0 && (
            <>
              <h3 className="mt-4">{t("Associated Lab Programs", "common")}</h3>
              {filteredLabProgram.map((labProgram) => (
                <AssociatedItem
                  key={labProgram.uuid}
                  title={labProgram?.title}
                  description={labProgram.description}
                  media={labProgram?.media}
                  type={COMPONENT_TYPES.LAB_PROGRAMS}
                  id={labProgram?.slug}
                />
              ))}
            </>
          )}
        </>
      )}

      {showSponsors && (
        <>
          {data?.sponsors && data?.sponsors?.length > 0 && (
            <>
              <h3 className="mt-4 mb-2">{t("Sponsored By", "common")}</h3>
              <div className="flex items-center gap-10 overflowX-auto pb-1">
                {data.sponsors.map((sponsor) => (
                  <div
                    key={sponsor?.title}
                    title={sponsor.title}
                    className="flex items-center"
                  >
                    <SecureImage
                      classes="w-40 h-40 rounded-100"
                      alt={sponsor?.title}
                      src={sponsor?.image}
                      placeholder={userImage}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default PublicDescription;
