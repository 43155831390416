import Select from "../../components/Input/Select";
import {Checkbox, FormControlLabel} from "@mui/material";

export const getImportContactsColumns = (selected, remove, onSelect, showRole, roles, updateUser, enableGlobalRole, globalRole, errors,removeError) => {
    return [
        {
            title: "Name",
            render: (rowData) => {
                return (
                    <div className="flex items-center fs-14">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(event) => {
                                        if (event?.target?.checked) {
                                            onSelect(rowData)
                                        } else {
                                            remove(rowData, 'email')
                                        }
                                    }}
                                    sx={{
                                        '&.Mui-checked': {
                                            color: '#27AE60',
                                        },
                                    }}
                                    checked={selected.some(item => item?.email === rowData?.email)}
                                    inputProps={{'aria-label': 'controlled'}}
                                />
                            }
                            label={(
                                <p className="text-nowrap">{rowData?.name || "-"}</p>
                            )}
                        />


                    </div>
                );
            },
        },
        {
            title: "Email",
            render: (rowData) => {
                return (
                    <div className="flex items-center fs-14 ">
                        <div className="ml-2">
                            <p className="text-nowrap">{rowData?.email || "-"}</p>
                        </div>
                    </div>
                );
            },
        },
        ...(showRole ? [{
            title: "Role",
            render: (rowData) => {
                return <div className={"flex"} style={{flexDirection: 'column', gap:"4px"}}><Select
                    borderedIcon
                    disabled={enableGlobalRole}
                    value={selected.some(item => item?.email === rowData?.email) && enableGlobalRole && globalRole ? globalRole || '' : rowData.role}
                    width={160}
                    height={40}
                    values={roles || []}
                    placeholder={'Select Role'}
                    name="role"
                    classes="fs-14"
                    onChange={(e) => {
                        updateUser({...rowData, role: e.target.value})
                        removeError(`roles-members-${rowData?.id}`)
                    }}
                />
                    <p className={'text-danger'} >{errors[`roles-members-${rowData?.id}`] || ''}</p>
                </div>
            },
        }] : [])
    ];
}
