import React from "react";
import NetworkUsersSelect from "../molecules/NetworkUsersSelect";
import { useTranslation } from "react-i18next";
import { Box, Grid, IconButton } from "@mui/material";
import SecureImage from "../atoms/SecureImage";
import { Delete } from "@mui/icons-material";
import { user } from "assets/svg/blue";

const ExpertReferences = ({
  experts = [],
  setExperts = Function.prototype,
}) => {
  const { t } = useTranslation("common");

  return (
    <>
      <NetworkUsersSelect
        placeholder="Search for a user on our platform"
        setUsers={setExperts}
        t={t}
        users={experts}
        classes="w-full mt-2"
      />
      <Grid container spacing={1} className="mt-2">
        {experts?.map((d) => (
          <Grid item xs={12} md={4}>
            <Box className="gap-2 flex items-center w-full py-2 px-2 rounded-10 bg-gray">
              <SecureImage
                classes="h-50 w-50 rounded-10"
                src={d?.profile_image}
                placeholder={user}
                containerClasses="flex items-center justify-center"
              />
              <Box className="fs-14 c-title flex-1 px-2 overflow-hidden">
                <p className="fw-500">{d?.full_name}</p>
                <p className="fs-13 capitalize">
                  {t((d?.role || "")?.split("_")?.join(" ") || "User")}
                </p>
              </Box>
              <IconButton
                onClick={() =>
                  setExperts((prev) => {
                    return prev.filter((f) => f?.email !== d?.email);
                  })
                }
              >
                <Delete color="error" fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ExpertReferences;
