import { Close, CancelOutlined } from "@mui/icons-material";
import Button from "components/Button";
import React from "react";
import ModalLayout from "components/Modal";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { schema } from "./data";
const ErrorModal = ({ open, onClose, errors }) => {
  const { t } = useLocalizedTranslation("common", true);
  let errorKeys = Array.from(errors.keys());
  return (
    <ModalLayout
      open={open}
      onClose={onClose}
      width="min(90%,550px)"
      top="mt-24"
      wrapperClasses="rounded-10"
    >
      <div className="flex items-center justify-center">
        <div className="text-center">
          <CancelOutlined sx={{ fontSize: 150 }} className="c-error" />
          <h2 className="text-center">{t("Error!")}</h2>
          <h3>{t("Missing required information in...")}</h3>
          <div className="flex flex-wrap gap-10 capitalize justify-center pb-5 pt-3">
            {errorKeys.map((key) => (
              <div key={key}>
                <span className="fs-14 c-error fw-600">
                  {t(schema.find((item) => item.name === key).errorTitle) ??
                    t(schema.find((item) => item.name === key).title) ??
                    t(key)}
                </span>
              </div>
            ))}
          </div>
          <div className="flex justify-center py-2">
            <Button
              onClick={onClose}
              variant="outlined"
              className="w-full"
              startIcon={<Close />}
            >
              {t("Go Back")}
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ErrorModal;
