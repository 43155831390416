import { Checkbox } from '@mui/material'
import Button from 'components/Button'
import Card from 'components/Cards/Card'
import Divider from 'components/Divider'
import Input from 'components/Input'
import Select from 'components/Input/Select'
import { RedCross } from 'components/Statics'
import React from 'react'

const CheckoutLab = ({ errors, data, onChange }) => {

    return (
        <>
            <Divider />
            <div className='org__checkoutCardsContainer'>
                <Card centered classes="my-4 pt-4 checkout__left" >
                    <h3>Review your Subscription</h3>
                    <div className='flex items-center justify-between px-4 mt-6'>
                        <div>
                            {["IFC Lab", "Free Lab", "Free Lab", 'Free Lab'].map((item, i) =>
                                <div key={i} className='flex items-center mt-1'><RedCross classes={`mr-1 ${i !== 0 && 'opacity-0'}`} />{item}<span className='ml-1'>{">"}</span></div>
                            )}
                        </div>
                        <div className='text-right'>
                            {["$2,000", "$0", "$0", '$0'].map((item, i) => <p key={i} className="mt-1">{item}</p>)}
                        </div>
                    </div>
                    <Divider classes="mt-15" />
                    <div className='text-right mt-2 px-4'>
                        <p><b className='mr-10'>Total:</b> $2000</p>
                    </div>
                </Card>
                <CheckoutCard errors={errors} data={data} onChange={onChange} />
            </div>
        </>
    )
}

export default CheckoutLab



export const CheckoutCard = ({ errors, data, onChange }) => {
    return (
        <Card width="35%" centered classes="my-4 pt-4 checkout__right" >
            <h3>Checkout</h3>
            <Divider classes="mt-1 mb-2" />
            <p><b>Card</b></p>
            <Input placeholder="1234 1234 1234 1234" title="Card Number" error={errors?.card_number} value={data?.card_number} onChange={e => onChange(e, "card_number")} height={30} width="100%" gray />
            <div className='flex items-center mt-1'>
                <Input placeholder="MM/YY" title="Expiry" gray height={30} width="90%" error={errors?.expiry} value={data?.expiry} onChange={e => onChange(e, "expiry")} />
                <Input placeholder="CVC" title="CVC" gray height={30} width="100%" error={errors?.cvc} value={data?.cvc} onChange={e => onChange(e, "cvc")} />
            </div>
            <div className='flex items-center justify-between mt-1'>
                <Select
                    title="Country"
                    values={["Canada", "India"]}
                    placeholder="Canada"
                    value="Canada"
                    width={150}
                    height={30}
                    gray
                />
                <Input placeholder="MST 1T4" title="Postal Code" gray height={30} width="100%" error={errors?.postal_code} value={data?.postal_code} onChange={e => onChange(e, "postal_code")} />
            </div>
            <div className='text-right flex items-center justify-end mt-2'>
                <p className="mr-1">Save Payments details</p><Checkbox />
            </div>
            <Divider classes="my-1" />
            <p><b>Code</b></p>
            <div className='flex items-center mt-2'>
                <Button height={35} gray width="100%" bordered>Use a Code</Button>
                <Button height={35} bordered classes="ml-1">Apply</Button>
            </div>
        </Card>
    )
}