import Button from "components/Button";
import SimpleLine from "components/Progress/SimpleLine";
import DashboardLayout from "pages/layouts/dashboard";
import MainPageLayout from "pages/layouts/MainPage";
import React, { useEffect, useState } from "react";
import AchievementCard from "./components/AchievementCard";
import FilterCard from "../../components/UI/organisms/FilterCard";
import ReportHeaderLayout from "pages/reporting/components/ReportHeaderLayout";
import useAchievements from "store/achievements/service-hook";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import ListingContainer from "components/UI/organisms/ListingContainer";
import ListingPageHeader from "components/UI/organisms/ListingPageHeader";
import useListingHeader from "helpers/hooks/useListingHeader";
import { Grid } from "@mui/material";
import AchievementShareModal from "./components/AchievementShareModal";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import useRedirect from "helpers/hooks/useRedirect";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import LearningPointRules from "pages/leaderBoard/components/LearningPointRules";

const AcheivementsScreen = () => {
  const [achievements, setAchievements] = useState([]);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const { search, setSearch, gridType, setGridType, sort, setSort } =
    useListingHeader(2);
  const { list, setFeatured } = useAchievements();
  const { navigateAchievement, navigateLabs } = useRedirect();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const { selected = {} } = useFilters();
  const [modal, setModal] = useState(null);
  const windowWidth = useWindowWidth();
  const onMobile = windowWidth < 700;
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    if (onMobile && gridType === 1) setGridType(2);
  }, [onMobile]);

  useEffect(() => {
    fetchAchievements();
  }, [selected, search, sort, page]);

  const fetchAchievements = async () => {
    setLoading(true);
    const filters = {
      search: search,
      sort_by: sort,
      page: page,
    };
    if (selected?.award_type?.length) filters.type = selected.award_type;
    if (selected?.achievement_level?.length)
      filters.level = selected.achievement_level;
    if (selected?.achievement_placement?.length)
      filters.placement = selected.achievement_placement;

    const res = await list(filters);
    setAchievements(res?.list || []);
    setUserData(res?.user_data || []);
    setPage(res?.current_page || 1);
    setPages(res?.total_pages || 1);
    setLoading(false);
  };
  const handleSetFeatured = async (id, unset = false) => {
    await setFeatured(id, Boolean(unset));

    setAchievements((prevAchievements) => {
      const index = prevAchievements.findIndex(
        (achievement) => achievement.id === id
      );
      if (index !== -1) {
        const newAchievements = [...prevAchievements];
        const newIsFeatured = unset
          ? "no"
          : newAchievements[index].is_featured === "yes"
          ? "no"
          : "yes";
        newAchievements[index] = {
          ...newAchievements[index],
          is_featured: newIsFeatured,
        };

        return newAchievements;
      }
      return prevAchievements;
    });
  };
  const closeShareModal = () => setModal(null);

  return (
    <DashboardLayout>
      <LearningPointRules
        open={modal === "learning_point_rules"}
        onClose={() => setModal(false)}
      />
      <ReportHeaderLayout
        title="Achievements"
        hideBackButton
        classes="mt-18 container flex-column justify-center items-center px-0"
        rightContent={<LearningRuleHistory setModal={setModal} t={t} />}
      >
        <SimpleLine
          points={
            userData
              ? parseInt(userData.total_points, 10) -
                parseInt(userData.remaining_points, 10)
              : 0
          }
          totalPoints={userData?.total_points || 0}
          classes="mt-4"
          width="95%"
          description={`${userData?.remaining_points || 0} ${t(
            "points to LEVEL"
          )} ${userData?.learnrank ? parseInt(userData.learnrank, 10) + 1 : 1}`}
          titles={[
            `${t("LEARN RANK")} : ${
              userData?.learnrank ? userData?.learnrank : 0
            }`,
            `${
              userData && userData?.total_points
                ? userData?.total_points + " " + t("Points")
                : "0 " + t("Points")
            }`,
          ]}
        />
      </ReportHeaderLayout>
      <MainPageLayout classes="my-5">
        <div className="left">
          {/* <Grid container spacing={1} className="mb-3 w-full">
            <Grid item xs={6}>
              <Button width="100%" height="40px" color="green">
                {t("Earn")}{" "}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button width="100%" height="40px" color="green">
                {t("Redeem")}
              </Button>
            </Grid>
          </Grid> */}
          <FilterCard
            title="Filter Achievements"
            types={[
              FILTER_TYPES.AWARD_TYPE,
              FILTER_TYPES.ACHIEVEMENT_LEVEL,
              FILTER_TYPES.ACHIEVEMENT_PLACEMENT,
            ]}
          />
        </div>
        <div className="right">
          <ListingPageHeader
            gridType={gridType}
            setGridType={setGridType}
            search={search}
            setSearch={setSearch}
            setSort={setSort}
            sort={sort}
            sortOptions={[
              {
                value: "Awarded Date",
                key: "creation_date",
              },
              {
                value: "Name A to Z",
                key: "name-a-to-z",
              },
              {
                value: "Name Z to A",
                key: "name-z-to-a",
              },
              {
                value: "Relevance",
                key: "relevance",
              },
            ]}
          />
          {!loading && !achievements?.length ? (
            <div className="column-center">
              <p
                className="fs-18 mt-15  text-center fw-600"
                style={{ color: " #8B8B8B" }}
              >
                {t("Start getting Achievements today!")}
              </p>
              <Button classes="mt-3" onClick={navigateLabs}>
                {t("Explore Labs")}
              </Button>
            </div>
          ) : (
            <ListingContainer
              loading={loading}
              isEmpty={!achievements.length}
              count={pages}
              page={page}
              setPage={setPage}
              classes="mt-5"
            >
              <Grid container item xs={12} spacing={1}>
                {achievements.map((d, i) => (
                  <Grid
                    key={d?.id}
                    item
                    xs={gridType === 1 ? 12 : 6}
                    sm={gridType === 1 ? 12 : 4}
                  >
                    <AchievementCard
                      data={d}
                      gridType={gridType}
                      setModal={setModal}
                      onClickView={navigateAchievement}
                      setFeatured={handleSetFeatured}
                    />
                  </Grid>
                ))}
              </Grid>
            </ListingContainer>
          )}
        </div>
      </MainPageLayout>
      <AchievementShareModal
        achievement={modal}
        open={!!modal && modal !== "learning_point_rules"}
        onClose={closeShareModal}
        setFeatured={handleSetFeatured}
        t={t}
      />
    </DashboardLayout>
  );
};

export default AcheivementsScreen;

export const LearningRuleHistory = ({ setModal, t = (d) => d }) => {
  const { navigateNotifications } = useRedirect();
  return (
    <div className="flex justify-between">
      <Button classes="mr-2" onClick={() => setModal("learning_point_rules")}>
        {t("Learning Point Rules")}
      </Button>
      <Button classes="mr-1" onClick={navigateNotifications}>
        {t("Learning Point History")}
      </Button>
    </div>
  );
};
