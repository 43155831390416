import DonutChart from "components/Progress/DonutChart";
import React from "react";
import DashboardCard from "../../pages/dashboards/components/DashboardCard";

export const chart_sizes = {
  0: { 0: 1, 1: 12, 2: -18, 3: -20 },
  1: { 0: 1, 1: 12, 2: -18, 3: -20 },
  2: { 0: 16, 1: 26, 2: 2, 3: 2 },
  3: { 0: 8, 1: 18, 2: 15, 3: 16 },
};
export const org_stats = [
  {
    title: "challenges",
    total: 1233,
    chartData: [
      {
        title: "Completed",
        value: 4321,
        color: "#08714E",
        icon: "assets/svg/green/leaf1.svg",
      },
      {
        title: "Started",
        value: 312,
        color: "#13A859",
        icon: "assets/svg/green/leaf2.svg",
      },
      {
        title: "Not Started",
        value: 12,
        color: "#4DDB73",
        icon: "assets/svg/green/leaf3.svg",
      },
      {
        title: "Closed",
        value: 12,
        color: "#A3F9A9",
        icon: "assets/svg/green/leaf4.svg",
      },
    ],
    participants: 1254,
    userIcon: "assets/svg/green/users.svg",
  },
  {
    title: "labs",
    total: 1233,
    chartData: [
      {
        title: "Completed",
        value: 4321,
        color: "#113D90",
        icon: "assets/svg/blue/leaf1.svg",
      },
      {
        title: "Started",
        value: 312,
        color: "#3698F9",
        icon: "assets/svg/blue/leaf2.svg",
      },
      {
        title: "Not Started",
        value: 12,
        color: "#67B9FB",
        icon: "assets/svg/blue/leaf3.svg",
      },
      {
        title: "Closed",
        value: 12,
        color: "#AEE2FE",
        icon: "assets/svg/blue/leaf4.svg",
      },
    ],
    participants: 1254,
    userIcon: "assets/svg/blue/users.svg",
  },
  {
    title: "projects",
    total: 2142,
    chartData: [
      {
        title: "Completed",
        value: 4321,
        color: "#B76200",
        icon: "assets/svg/yellow/leaf1.svg",
      },
      {
        title: "Started",
        value: 312,
        color: "#FF9D00",
        icon: "assets/svg/yellow/leaf2.svg",
      },
      {
        title: "Not Started",
        value: 12,
        color: "#FFD066",
        icon: "assets/svg/yellow/leaf3.svg",
      },
    ],
    participants: 1254,
    userIcon: "assets/svg/yellow/users.svg",
  },
  {
    title: "resources",
    total: 1233,
    chartData: [
      {
        title: "Completed",
        value: 345,
        color: "#6229D1",
        icon: "assets/svg/purple/leaf1.svg",
      },
      {
        title: "Started",
        value: 312,
        color: "#A071FF",
        icon: "assets/svg/purple/leaf2.svg",
      },
      {
        title: "Not Started",
        value: 12,
        color: "#CCB2FF",
        icon: "assets/svg/purple/leaf3.svg",
      },
    ],
    participants: 1254,
    userIcon: "assets/svg/purple/users.svg",
  },
];

const DonutInfo = ({ title, stats,row }) => {
  return (
    <DashboardCard title={title} tabs={[]}  classes="mt-2">
      <div className="flex flex-wrap">
        {org_stats.map((data, i) => (
          <div key={i} className={`${row ? 'w-25p' : 'w-50p'}`}>
            <DonutChart
              classes="mx-auto w-200 h-200"
              hasSizeClasses
              height="200px"
              width="200px"
              totalValue={data?.total}
              data={data?.chartData}
              title={data.title}
              plusOne={chart_sizes[i][0]}
              plusTwo={chart_sizes[i][1]}
              minusOne={chart_sizes[i][2]}
              minusTwo={chart_sizes[i][3]}
            />
            <InfoItem
              icon={data?.userIcon}
              title="Participants"
              value={data.participants}
            />
            {data.chartData.map((item, i) => (
              <InfoItem
                icon={item.icon}
                title={item.title}
                value={item.value}
                key={i}
              />
            ))}
          </div>
        ))}
      </div>
    </DashboardCard>
  );
};

export default DonutInfo;

export const InfoItem = ({ title, icon, value }) => {
  return (
    <div className="flex items-center mt-2 mx-2">
      <img src={require("../../" + icon)} width="22px" alt={title} />
      <p className="ml-2 w-150">{title}</p>
      <p className="text-end w-60 tracking-tight fs-14 fw-600 ml-1">{value}</p>
    </div>
  );
};
