import { useEffect, useState } from "react";
import Datatable from "../../../components/Datatable";
import moment from "moment";
import useRedirect from "../../../../../helpers/hooks/useRedirect";
import { ReactComponent as RG } from "assets/svg/purple/resource-group.svg";
import useChallengeReport from "../../../../../store/reports/challenges/service-hooks";
import useChallengeReportContext from "../../hooks/useChallengeReportContext";
import EmptyText from "components/UI/atoms/EmptyText";

const ResourceGroupsList = ({ t = (d) => d }) => {
  const { challengeResourceGroup } = useChallengeReport();
  const { challengeSlug } = useChallengeReportContext();
  const { navigateResourceG } = useRedirect();
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    if (challengeSlug) {
      const response = await challengeResourceGroup(challengeSlug, page, 10);
      if (response) {
        setData(response);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <Datatable
      data={data?.data ?? []}
      loading={loading}
      page={data?.meta?.current_page ?? 1}
      perPage={data?.meta?.per_page ?? 15}
      totalPages={data?.meta?.total_pages ?? 1}
      totalCount={data?.meta?.total}
      onPageChange={(page) => setPage(page)}
      emptyText={
        <EmptyText>
          {t(
            "There is no resource group associated to this Challenge, create now with 'Create' button from navigation bar"
          )}
        </EmptyText>
      }
      columns={[
        {
          title: t("Component"),
          dataIndex: "id",
          render: () => {
            return (
              <>
                <RG className={"w-20 h-20"} />
              </>
            );
          },
        },
        {
          title: t("Title"),
          dataIndex: "title",
          render(item, _, row) {
            return (
              <p
                className={"c-primary cursor-pointer"}
                onClick={() => navigateResourceG(row?.slug)}
              >
                {item}
              </p>
            );
          },
        },
        {
          title: t("Members", "common"),
          dataIndex: "members_count",
        },
        {
          title: t("Last Updated", "common"),
          dataIndex: "updated_at",
          render(item) {
            return <>{item ? moment(item).fromNow() : "-"}</>;
          },
        },
      ]}
    />
  );
};

export default ResourceGroupsList;
