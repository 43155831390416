import React, { useEffect, useMemo, useState } from "react";
import NuxLayout from "components/UI/molecules/NuxLayout";
import useFilters from "helpers/hooks/useFilters";
import useRedirect from "helpers/hooks/useRedirect";
import ManageWrapper from "components/UI/organisms/ManageWrapper";
import useListingHeader from "helpers/hooks/useListingHeader";
import {
  browse_options,
  getChallengeNUX,
  useChallengeOptions,
} from "pages/challenges/listing/ManageChallenges";
import ChallengeListing from "pages/challenges/listing/ChallengeListing";
import useChallengePaths from "store/challenge-paths/service-hook";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import CreationOptionsModal from "components/Modal/CreateOptionsModal";
import ChallengeBuilder from "pages/resources/ResourceModule/forms/ChallengeBuilder";
import { hasFiltersApplied } from "helpers/utils/utilities";
import { FILTER_TYPES } from "helpers/hooks/useFilters";
import useAuth from "store/auth/service-hook";
import { ROLES } from "helpers/utils/constants";
import ChallengeTemplate from "pages/challenges/components/ChallengeTemplates";

const ManageChallengePath = () => {
    const {
      state: { user },
    } = useAuth();
  const state = useListingHeader();
  const [modal, setModal] = useState(null);
  const { fetchPrivateChallengePaths, deleteChallengePath } =
    useChallengePaths();
  const [challengePaths, setChallengePaths] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { selected } = useFilters();
  const { navigateCreateChallengePath, navigateManageChallenges } =
    useRedirect();
  const challengeOptions = useChallengeOptions(setModal);
  const noSearchValue = useMemo(() => {
    return !state.search || state.search.trim() === "";
  }, [state.search]);

  useEffect(() => {
    const abortController = new AbortController();
    handleFetchChallenges(abortController);
    return () => abortController.abort();
  }, [page, selected, state.sort, state.search]);

  const handleFetchChallenges = async (abortController) => {
    setLoading(true);
    const filters = {
      ...selected,
      page,
      sort_by: state.sort,
      search: state.search,
    };

    if (selected?.challenge_path_source) filters.source = selected.challenge_path_source;
    delete filters.challenge_path_source;

    const [list, count] = await fetchPrivateChallengePaths({ ...filters });
    if (!abortController.signal.aborted) {
      setChallengePaths(list || []);
      setPages(count || 0);
      setLoading(false);
    }
  };

  const handleDelete = async (slug) => {
    setLoading(true);
    deleteChallengePath(slug);
    setChallengePaths((val) => val.filter((d) => d.slug !== slug));
    setLoading(false);
  };

  const isSuperAdmin =
    user?.roles === ROLES.SUPER_ADMIN ||
    user?.roles === ROLES.CUSTOMER_SUCCESS ||
    user?.roles === ROLES.DEVELOPER;
        
  return (
    <>
      <ManageWrapper
        {...state}
        browseMode={1}
        browseOptions={browse_options}
        setBrowseMode={(val) => val === 0 && navigateManageChallenges()}
        createActionTitle="Create"
        navigateCreate={navigateCreateChallengePath}
        component={COMPONENT_TYPES.CHALLENGE_PATH}
        createOptions={challengeOptions}
        filters={[
          FILTER_TYPES.CHALLENGE_PATH_STATUS,
          FILTER_TYPES.TYPE,
          FILTER_TYPES.DURATION,
          FILTER_TYPES.LEVEL,
          FILTER_TYPES.PRIVACY,
          FILTER_TYPES.CHALLENGE_PATH_SOURCE,
          isSuperAdmin && FILTER_TYPES.TEMPLATE_STATUS,
        ].filter(Boolean)}
      >
        {!hasFiltersApplied && noSearchValue && !challengePaths?.length && !loading ? (
          <NuxLayout
            browseMode={1}
            newUserData={getChallengeNUX(() => setModal("create"))}
          />
        ) : (
          <ChallengeListing
            browseMode={1}
            gridType={state.gridType}
            challenges={challengePaths}
            loading={loading}
            page={page}
            setPage={setPage}
            pageCount={pages}
            setChallenges={setChallengePaths}
            handleDelete={handleDelete}
            manage
            isChallengePath
          />
        )}
      </ManageWrapper>
      {modal === "create" ? (
        <CreationOptionsModal
          open={modal === "create"}
          title="How Do You Want To Create A New Challenge?"
          options={challengeOptions}
          onClose={() => setModal(null)}
        />
      ) : modal === "templates" ? (
        <ChallengeTemplate
          open={modal === "templates"}
          onClose={() => setModal(null)}
        />
      ) : modal === "resources" ? (
        <ChallengeBuilder
          open={modal === "resources"}
          onClose={() => setModal(null)}
        />
      ) : null}
    </>
  );
};

export default ManageChallengePath;
