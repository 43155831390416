import { useEffect, useState } from "react";
import useLabReport from "../../../../../store/reports/lab/service-hooks";
import useLabReportContext from "../../hooks/useLabReportContext";
import Datatable from "../../../components/Datatable";
import moment from "moment";
import useRedirect from "../../../../../helpers/hooks/useRedirect";
import { ReactComponent as ChallengeIcon } from "assets/svg/green/challenge.svg";
import EmptyText from "components/UI/atoms/EmptyText";

const ChallengesPathList = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { labChallengePaths } = useLabReport();
  const { labSlug } = useLabReportContext();
  const { navigateChallengePath } = useRedirect();

  /**
   * STATE
   */
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  /**
   *
   * @returns {Promise<void>}
   */
  const fetchData = async () => {
    setLoading(true);
    if (labSlug) {
      const response = await labChallengePaths(labSlug, page, 10);
      if (response) {
        setData(response);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page]);
  return (
    <Datatable
      data={data?.data ?? []}
      loading={loading}
      page={data?.meta?.current_page ?? 1}
      perPage={data?.meta?.per_page ?? 15}
      totalPages={data?.meta?.total_pages ?? 1}
      totalCount={data?.meta?.total}
      onPageChange={(page) => setPage(page)}
      emptyText={
        <EmptyText>
          {t(
            "There is no challenge path associated to this Lab, create now with 'Create' button from navigation bar"
          )}
        </EmptyText>
      }
      columns={[
        {
          title: t("Component"),
          dataIndex: "id",
          render: () => {
            return (
              <>
                <ChallengeIcon className={"w-20 h-20"} />
              </>
            );
          },
        },
        {
          title: t("Title"),
          dataIndex: "title",
          render(item, _, row) {
            return (
              <p
                className={"c-primary cursor-pointer"}
                onClick={() => navigateChallengePath(row?.slug)}
              >
                {item}
              </p>
            );
          },
        },
        {
          title: t("Members", "common"),
          dataIndex: "members_count",
        },
        {
          title: t("Last Updated", "common"),
          dataIndex: "updated_at",
          render(item) {
            return <>{item ? moment(item).fromNow() : "-"}</>;
          },
        },
      ]}
    />
  );
};

export default ChallengesPathList;
