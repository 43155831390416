import SecureImage from "components/UI/atoms/SecureImage";
import { getIconForFile } from "pages/resources/ResourceModule/components/ResourceFiles";
import React from "react";
import { Box, IconButton } from "@mui/material";
import { CheckCircle, Delete, Visibility, Download } from "@mui/icons-material";

const Document = ({
  title,
  path,
  onDelete,
  readOnly,
  isCompleted,
  onClick,
  onDownload,
  isViewAccessEnabled,
  hideDelete,
}) => {
  return (
    <Box
      sx={{
        "& .imageOverlay": { visibility: "hidden" },
        "&:hover .imageOverlay": { visibility: "visible" },
      }}
      className="w-120 h-140 border rounded-5 px-2 flex-column items-center justify-center relative cursor-pointer"
    >
      <SecureImage
        classes="object-cover h-100"
        containerClasses="maxh-100"
        src={getIconForFile(path)}
        alt="File Icon"
      />
      <div
        className={`fs-12 flex-center text-center my-2 link ${
          isCompleted ? "c-success" : "c-gray"
        }`}
      >
        {isCompleted && (
          <CheckCircle fontSize="small" className="mr-1 c-success" />
        )}
        <p className="text-truncate maxw-70">{title}</p>
      </div>
      <div
        className="imageOverlay flex justify-center items-center w-100p h-100p absolute rounded-5 cursor-pointer"
        style={{ backgroundColor: "#222222b2", gap: "10px" }}
      >
        <IconButton
          sx={{ border: "1px solid #dadada50" }}
          onClick={() => {
            if (onClick) onClick();
            window.open(path);
          }}
          className="opacity-100 h-30 w-30 c-white"
        >
          <Visibility fontSize="small" />
        </IconButton>
        {!readOnly && !hideDelete && (
          <IconButton
            onClick={onDelete}
            sx={{ border: "1px solid #dadada50" }}
            className="opacity-100 h-30 w-30 border"
          >
            <Delete color="error" fontSize="small" />
          </IconButton>
        )}
        {isViewAccessEnabled && (
          <IconButton
            onClick={onDownload}
            sx={{ border: "1px solid #dadada50" }}
            className="opacity-100 border"
          >
            <Download color="primary" />
          </IconButton>
        )}
      </div>
    </Box>
  );
};
export default Document;
