import React from "react";
import LineProgress from "components/Progress/LineProgress";
import Input from "components/Input";
import PasswordValidator from "components/PasswordValidator";
import { guidance_steps } from "../../auth-data";
import LanguageSelector from "components/UI/atoms/LanguageSelector";
import Select from "components/Input/Select";
import AuthCard from "components/UI/organisms/AuthCard";
import RecaptchaV2 from "../../../../components/Input/RecaptchaV2";
import PassowordInput from "components/UI/atoms/PassowordInput";
import useRedirect from "helpers/hooks/useRedirect";

export const getSliceValues = (step, userType) => {
  const isUser = userType === "user";
  const slices = {
    0: [0, 2],
    1: isUser ? [3, 5] : [2, 5],
    2: [5, 8],
  };
  return slices[step];
};

const GuidanceSteps = ({
  onChange,
  errors,
  formData,
  steps,
  handleBack,
  handleNext,
  loading,
  userType,
  current,
  isSSO,
  recaptchaError,
  setRecaptchaValue,
  setRecaptchaError,
  t = (d) => d,
}) => {
  const { navigatePrivacyPolicy, navigateTerms } = useRedirect();
  return (
    <AuthCard
      handleBack={handleBack}
      disableNext={loading}
      handleNext={handleNext}
      loading={loading}
      nextButtonTitle={
        (isSSO ? current === 1 : current === 2) ? "Create Account" : "Next"
      }
    >
      <div className="mt-4">
        <LineProgress
          curr={current}
          length={guidance_steps.length - (isSSO ? 1 : 0)}
          numbered
          rounded
        />
        <div className="flex mt-1">
          {guidance_steps
            .filter((d, i) => (!isSSO ? true : i !== guidance_steps.length - 1))
            .map((title) => (
              <p className="fs-12 opacity-70 w-80 mx-3 text-center" key={title}>
                {t(title)}
              </p>
            ))}
        </div>
      </div>
      <div className="w-70p w-80p--mob">
        {steps
          .slice(...getSliceValues(current, userType))
          .map(({ title, placeholder, name, type, keyValues }, index) => (
            <>
              {type === "select" ? (
                <Select
                  classes="mt-4 w-full"
                  title={title}
                  name={name}
                  borderedIcon
                  error={errors.get(name)}
                  value={formData[name]}
                  width="100%"
                  keyValues={keyValues}
                  onChange={onChange}
                  t={t}
                />
              ) : type === "lang" ? (
                <LanguageSelector
                  classes="mt-2"
                  value={formData[name]}
                  onChange={onChange}
                  t={t}
                />
              ) : type === "password" ? (
                <PassowordInput
                  placeholder={placeholder}
                  title={title}
                  name={name}
                  classes="w-full"
                  key={name}
                  error={errors.get(name)}
                  top={current === 2 ? 10 : 20}
                  value={formData[name]}
                  onChange={onChange}
                  t={t}
                />
              ) : (
                <Input
                  placeholder={placeholder}
                  title={title}
                  disabled={name === "email" && isSSO}
                  name={name}
                  width="97%"
                  classes="w-full"
                  key={name}
                  error={errors.get(name)}
                  top={current === 2 ? 10 : 20}
                  value={formData[name]}
                  type={type}
                  onChange={onChange}
                  t={t}
                />
              )}

              {name === "password" && (
                <PasswordValidator password={formData.password} />
              )}
            </>
          ))}

        {current === 2 ? (
          <div className="mt-4">
            <p className="fs-14 c-title mb-2">
              {t("By registering, you agree to the Prepr")}{" "}
              <span className="link" onClick={navigateTerms}>
                {" "}
                {t("Terms of use")}{" "}
              </span>{" "}
              {t("and")}{" "}
              <span className="link" onClick={navigatePrivacyPolicy}>
                {t("Privacy policy")}
              </span>
            </p>
            <RecaptchaV2
              error={recaptchaError}
              onChange={(value) => {
                if (value) {
                  setRecaptchaValue(value);
                  setRecaptchaError(false);
                }
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </AuthCard>
  );
};

export default GuidanceSteps;
