import { Box, Grid, Skeleton } from "@mui/material";

const PlanDetailsCard = ({ isPopup }) => {
  return (
    <Grid container spacing={4} mt={1}>
      {[...Array(3)].map((_, index) => (
        <Grid item xs={12} md={6} lg={isPopup ? 6 :4} key={index} px={1}>
          <Box mb={2}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={20}
              className="rounded-5"
            />
          </Box>
          {[...Array(3)].map((_, pIndex) => (
            <Box mb={1} key={pIndex}>
              <Skeleton
                variant="text"
                width="100%"
                height={10}
                className="rounded-5"
              />
            </Box>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};
export default PlanDetailsCard;
