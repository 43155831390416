import { Grid } from "@mui/material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import { getDefaultImageByType } from "components/Cards/MainProductCard";
import SecureImage from "components/UI/atoms/SecureImage";
import ListingContainer from "components/UI/organisms/ListingContainer";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useRedirect from "helpers/hooks/useRedirect";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { linkByComponent } from "helpers/utils/utilities";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ENDPOINTS } from "store/api/endpoints";
import useAssociation from "store/association";
import useAuth from "store/auth/service-hook";

const RelatedContent = ({ component, slug, title, classes }) => {
  const [list, setList] = useState([]);
  const { fetchRelated } = useAssociation();
  const [loading, setLoading] = useState(false);
  const width = useWindowWidth();
  const { t } = useLocalizedTranslation("common", true);
  const {
    state: { isLoggedIn },
  } = useAuth();

  useEffect(() => {
    if (slug) handleFetchRelated();
  }, [slug]);

  const handleFetchRelated = async () => {
    setLoading(true);
    const res = await fetchRelated(
      component === COMPONENT_TYPES.RESOURCE ? "resource-module" : component,
      slug
    );
    setList(res || []);
    setLoading(false);
  };

  const onTab = useMemo(() => width < 900, []);

  const {
    navigateLabs,
    navigateLabPrograms,
    navigateChallenges,
    navigateChallengePaths,
    navigateResources,
  } = useRedirect();

  const handleBrowseListingNavigate = () => {
    if (!component) return;
    switch (component) {
      case ENDPOINTS.LAB:
        navigateLabs();
        break;
      case ENDPOINTS.CHALLENGE:
        navigateChallenges();
        break;
      case COMPONENT_TYPES.RESOURCE:
        navigateResources();
        break;

      case ENDPOINTS.CHALLENGE_PATH:
        navigateChallengePaths();
        break;
      case ENDPOINTS.LAB_PROGRAM:
        navigateLabPrograms();
        break;
      default:
        break;
    }
  };
  if (!isLoggedIn) return null;
  return (
    <Card classes={classes}>
      <h3 className="capitalize">{title}</h3>
      <ListingContainer
        list={list}
        noPagination
        classes="mt-4"
        isEmpty={!list?.length}
        loading={loading}
        spinLoader
        emptyText={t("No related content available!", "common")}
        emptySmall
      >
        {list?.map((item) => (
          <Grid container item xs={12} spacing={1.5} key={item?.id}>
            <Grid item xs={4}>
              <SecureImage
                src={item?.media}
                placeholder={getDefaultImageByType(component)}
                classes={`${
                  onTab ? "w-full h-100" : "w-260 h-150 minw-260"
                } border`}
                title={item?.title ?? component}
              />
            </Grid>
            <Grid item xs={8} className="flex flex-column justify-center">
              <Link
                to={linkByComponent(component, slug)}
                className="c-primary mb-1 tracking-tight text-truncate text-decoration-none"
              >
                <h3 className="fs-20 fs-16--mob fw-500 capitalize">
                  {item?.title}
                </h3>
              </Link>
              <p
                className="description flex-wrap fs-14"
                dangerouslySetInnerHTML={{
                  __html: item?.description ?? "N/A",
                }}
              ></p>
            </Grid>
          </Grid>
        ))}

        <div className="flex justify-center w-100p mt-4">
          <Button
            bordered
            width="120px"
            height="33px"
            classes="fs-14"
            onClick={handleBrowseListingNavigate}
          >
            {t("Load More")}
          </Button>
        </div>
      </ListingContainer>
    </Card>
  );
};

export default RelatedContent;
