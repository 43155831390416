import { CloseOutlined, Mail, Message } from "@mui/icons-material";
import { ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Dot } from "components/Statics";
import OnlineAvatar from "components/UI/atoms/OnlineAvatar";
import { isTrue } from "helpers/utils/validators";
import moment from "moment";
import React from "react";
import user from "assets/svg/blue/user.svg";
import user2 from "assets/svg/green/user.svg";

const ChatListItem = ({
  data = {},
  newMessageCancel,
  newChat,
  current,
  onChatSelect = Function.prototype,
  bordered,
  dense,
  isAnnouncements,
  profileImage,
  tab,
  isAnnouncement,
  t = (d) => d,
}) => {
  const {
    sent_by,
    description,
    last_message,
    subject,
    schedule_at,
    created_at,
    seen,
    online,
    type,
    name,
  } = data;
  const htmlToPlainText = (description) => {
    if (!description) return "";
    return description?.replace(/<[^>]*>|&nbsp;/g, "");
  };

  return (
    <ListItem
      onClick={() => !!onChatSelect && onChatSelect(data?.id)}
      className={`${dense ? "py-1" : "py-2"} cursor-pointer ${
        bordered && "border-b"
      }`}
      selected={current}
      secondaryAction={
        <div className="">
          {newChat ? (
            <CloseOutlined
              color="disabled"
              className="mt-1"
              onClick={newMessageCancel}
            />
          ) : (
            <>
              <p className="fs-10 fw-300 opacity-50">
                {moment(
                  isAnnouncements
                    ? schedule_at
                    : last_message?.sent_at || created_at
                ).fromNow(true)}{" "}
                {t("ago")}
              </p>
              {isAnnouncements ? (
                <span className="pt-4 c-primary float-right">
                  {(sent_by === "inbox" || sent_by === "both") && (
                    <Message sx={{ fontSize: "19px" }} />
                  )}
                  {(sent_by === "email" || sent_by === "both") && (
                    <Mail sx={{ fontSize: "20px" }} />
                  )}
                </span>
              ) : (
                !isTrue(seen) && <Dot classes="float-right mt-2" />
              )}
            </>
          )}
        </div>
      }
    >
      {isAnnouncements ? null : (
        <ListItemAvatar>
          <OnlineAvatar
            notOnline={!isTrue(online)}
            isGroup={type === "group"}
            src={
              type !== "group"
                ? profileImage || user
                : profileImage ||
                  Array(2)
                    .fill(1)
                    .map((_, index) => (index === 0 ? user : user2))
            }
            placeholder={user}
          />
        </ListItemAvatar>
      )}
      <ListItemText
        primary={
          <p className="fw-500 opacity-90">
            <div
              className={`flex item-center w-full ${
                isAnnouncements ? "maxw-260" : "maxw-220"
              }`}
            >
              {isAnnouncements && (
                <>
                  {tab === 1 && (
                    <span className="c-error mr-1">{`[${t("DRAFT")}]`}</span>
                  )}
                  {tab === 2 && (
                    <span className="c-success mr-1">{`[${t(
                      "SCHEDULED"
                    )}]`}</span>
                  )}
                </>
              )}
              <p
                className={`text-truncate ${
                  isAnnouncements && "tracking-tight"
                }`}
              >
                {isAnnouncements
                  ? Object.values(data?.to_recipient_ids || {})?.length
                    ? Object.values(data?.to_recipient_ids || {})?.join(", ")
                    : subject
                  : name || "N/A"}
              </p>
              {isAnnouncement && (
                <span className="c-white bg-primary fs-12 rounded-5 tracking-tight ml-1 px-1">
                  {t("Announcement")}
                </span>
              )}
            </div>
            {!!isAnnouncements ? (
              <span className="fs-12 fw-400">
                {moment(schedule_at).format("MMM DD, YYYY h:m A") || "-"}
              </span>
            ) : null}
          </p>
        }
        secondary={
          <p className="fs-12 c-gray w-70p text-truncate">
            {htmlToPlainText(
              isAnnouncements ? description : last_message?.message
            )}
          </p>
        }
      />
    </ListItem>
  );
};

export default ChatListItem;
