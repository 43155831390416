import { Done, KeyboardTab } from "@mui/icons-material";
import Button from "components/Button";
import React from "react";
import { Box } from "@mui/material";
import { isTrue } from "helpers/utils/validators";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const DrawerContent = ({ items, setActiveById, setDrawerOpen }) => {
  const { t } = useLocalizedTranslation();

  return (
    <div style={{ width: "min(100vw,560px)" }} className="min-h-500 bg-gray">
      <div className="px-4 py-4">
        <div className="flex justify-between items-center">
          <h2 className="tracking-tight">{t("Customize your dashboard")}</h2>
          <KeyboardTab
            className="c-primary cursor-pointer"
            fontSize="large"
            onClick={() => setDrawerOpen(false)}
          />
        </div>
        <p className="mt-2 fs-15 tracking-tight">
          {t(
            "Your current account role is User. You will only be able to add modules according to your role."
          )}
        </p>
        {!items?.length ? (
          <p className="mx-3 my-20 c-title opacity-60 text-center fs-15">
            {t("No item left to be added!")}
          </p>
        ) : (
          items?.map((item) => {
            const { id, title, description } =
              CONTENTS.find((d) => d?.id === item?.id) || {};
            if (!id) return null;
            return (
              <>
                <h3 className="mt-4">{t(title)}</h3>
                <div className="flex justify-between items-center">
                  <p className="fs-14">{t(description)}</p>
                  <Button
                    color={isTrue(item?.is_active) ? "green" : "blue"}
                    bordered
                    onClick={() => setActiveById(id)}
                    classes="minw-70 ml-1"
                  >
                    {isTrue(item?.is_active) ? (
                      <>
                        <Done fontSize="small" />
                        {t("Added")}
                      </>
                    ) : (
                      t("Add")
                    )}
                  </Button>
                </div>
                <Box
                  className="overflowY-auto w-100p h-auto bg-white rounded-10 min-h-300 shadow py-3 mt-2"
                  sx={{
                    "& .MuiTableFooter-root .MuiTableRow-root:last-child td": {
                      border: 0,
                    },
                  }}
                >
                  {item?.children}
                </Box>
              </>
            );
          })
        )}
      </div>
    </div>
  );
};

export default DrawerContent;

export const CONTENTS = [
  {
    id: "my-resources",
    title: "My Resources",
    description: "Show your resource progress and saved resources",
  },
  {
    id: "inbox-friends",
    title: "Inbox & Friend Request",
    description: "Shows your messages and friend requests.",
  },
  {
    id: "recommendations",
    title: "Recommendations",
    description:
      "Based on your profile, we will recommend Challenges, Labs and Resources you may be interested in",
  },
  {
    id: "achievement",
    title: "Achievement",
    description: "Your latest achievement!",
  },
];
