import { CloseOutlined, Send } from "@mui/icons-material";
import {
  IconButton,
  CircularProgress,
  Box,
  ClickAwayListener,
} from "@mui/material";
import FileButton from "components/Input/FileButton";
import Picker from "emoji-picker-react";
import React, { useState } from "react";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { emojiSmile, attactmentTilt } from "assets/svg/blue";

const SendMessageBox = ({
  onSend,
  loading,
  message,
  setMessage,
  attachments,
  setAttachments,
  current,
  users,
  t = (d) => d,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const onEmojiSelect = (e, obj) => {
    const addedEmoji = message + obj?.emoji;
    setMessage(addedEmoji);
  };
  const pickerStyles = useMemo(
    () => ({
      position: "absolute",
      top: "-330px",
      right: "0px",
      display: showEmojiPicker ? "block" : "none",
    }),
    [showEmojiPicker]
  );

  const handleAttachmentChange = (newFiles) => {
    const maxSize = 2 * 1024 * 1024;
    const validFiles = newFiles.filter((file) => {
      if (file.size > maxSize) {
        toast.error(`Maximum file size is 2MB.`);
        return false;
      }
      return true;
    });
    setAttachments(validFiles);
  };

  const handleEnterToSend = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onSend();
    }
  };

  return (
    <div className="bottom-0 absolute bg-white w-100p flex flex-column justify-between pb-2 relative">
      {attachments && attachments.length > 0 && (
        <Box className="w-100p flex flex-wrap mx-2">
          {attachments.map((file, index) => (
            <Box key={index} className="flex items-center c-success fs-14 mr-2">
              <p className="fs-12 link">{file.name}</p>
              <CloseOutlined
                className="ml-1 cursor-pointer"
                onClick={() => {
                  const filteredFiles = attachments.filter(
                    (_, i) => i !== index
                  );
                  setAttachments(filteredFiles);
                }}
                fontSize="small"
                color="error"
              />
            </Box>
          ))}
        </Box>
      )}
      <div className="flex w-100p h-50 items-center justify-between">
        <div className="border rounded-4 h-40 flex-1 flex items-center justify-between mx-2">
          <input
            placeholder={t("Type a message...")}
            className="w-100p ghostInput"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleEnterToSend}
            disabled={loading}
          />
          <div className="flex items-center">
            <IconButton
              size="medium"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            >
              <img
                className="w-24 h-24"
                src={emojiSmile}
                alt=""
              />
            </IconButton>
            <FileButton size="medium" onChange={handleAttachmentChange} multi>
              <img
                className="w-24 h-24"
                src={attactmentTilt}
                alt=""
              />
            </FileButton>
          </div>
        </div>
        <IconButton
          size="medium"
          onClick={onSend}
          disabled={
            loading ||
            (!message && !attachments?.length) ||
            (current === "new" && users.length === 0)
          }
          className="mr-2"
        >
          {loading === "send" ? (
            <CircularProgress size={24} />
          ) : (
            <Send className="c-primary fs-26" />
          )}
        </IconButton>
      </div>
      {showEmojiPicker && (
        <ClickAwayListener onClickAway={() => setShowEmojiPicker(false)}>
          <Box>
            <Picker
              onEmojiClick={onEmojiSelect}
              pickerStyle={pickerStyles}
              preload
              native
            />
          </Box>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default SendMessageBox;
