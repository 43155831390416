import { ChevronRight, Close, Done, ExpandMore } from "@mui/icons-material";
import React, { useState } from "react";
import img from "assets/png/lab-default.png";
import styles from "../dashboard.module.scss";

export const statusTitleClass = {
  0: ["Not Started", "one"],
  1: ["In Progress", "two"],
  2: ["Completed", "three"],
};

const ProjectCard = ({ project }) => {
  const [open, setOpen] = useState(true);
  const { title, tasks, deadline, associated_challenges, status } =
    project;

  const toggleOpen = () => setOpen((prev) => !prev);

  return (
    <div className="flex justify-between fs-14 pt-1 pb-2 border-t">
      <div className="mr-1">
        {open ? (
          <ExpandMore
            onClick={toggleOpen}
            className="text-black fs-30 opacity-100 cursor-pointer"
          />
        ) : (
          <ChevronRight
            onClick={toggleOpen}
            className="text-black fs-30 opacity-100 cursor-pointer"
          />
        )}
      </div>
      <div className={`flex-1 w-50p pt-1 ${styles.projectCardText}`}>
        <p>{title}</p>
        {open ? (
          <>
            <img src={img} alt="project" className="h-80 w-160 mt-1"/>
            <p className="w-300 text-truncate">
              Associated Challenge:{" "}
              <span className="c-success">{associated_challenges}</span>
            </p>
            <p>{deadline}</p>
            <p>
              <b>Tasks</b>
            </p>
            {tasks.map((task, i) => (
              <div key={i} className="flex items-center">
                {i === 0 ? (
                  <Done fontSize="small" className="c-success" />
                ) : (
                  <Close fontSize="small" className="c-warning" />
                )}
                <p className="ml-1">{task}</p>
              </div>
            ))}
          </>
        ) : null}
      </div>
      <div className={`w-20p pt-1 mr-2`}>
        <p
          className={`${styles.statusChip} ${
            styles[statusTitleClass[status][1]]
          }`}
        >
          {statusTitleClass[status][0]}
        </p>
      </div>
    </div>
  );
};

export default ProjectCard;
