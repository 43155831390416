import { useState, useEffect } from "react";
import "components/AI/ai.scss";
import DashboardLayout from "pages/layouts/dashboard";
import EditCreateHeaderCard from "components/Cards/EditCreateHeaderCard";
import MainPageLayout from "pages/layouts/MainPage";
import Card from "components/Cards/Card";
import { toast } from 'react-toastify';
import { aiBackground1 } from "assets/svg/ai";
import { aiLoading } from "assets/gif/ai";
import useResources from "store/resources/service-hook";
import { scrollToTop } from "helpers/utils/utilities";
import useForms from "helpers/hooks/useForms";
import { schema, stepSchema } from "./data";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import useOrganization from "store/organization/service-hook";
import useRedirect from "helpers/hooks/useRedirect";
import AIForm from "components/AI/AIForm";
import ResourceModulePreview from "components/AI/ResourceModulePreview";

const ResourceModuleBuilderAI = () => {

    const sliceSchema = (_schema) => stepSchema(step);

    const { data, errors, validate, onChange } = useForms(
        schema,
        {},
        sliceSchema
    );

    const [step, setStep] = useState(0);

    const { aiCreateResourceModule, aiCreateResourceModulePreviews } = useResources();
    const { organizationId } = useOrganization();
    const { navigateResource } = useRedirect();

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [confirmationModalDescription, setConfirmationModalDescription] = useState("Are you sure?");
    const [loading, setLoading] = useState(false);

    const [resources, setResources] = useState([]);
    const [resourcesState, setResourcesState] = useState([]);
    const [resourceExpanded, setResourceExpanded] = useState('resourcePanel0');
    const [resourceSelected, setResourceSelected] = useState([]);
    const [resourceChosen, setResourceChosen] = useState(null);

    // Initial Page Steps  
    const [createResourceModuleAISteps, setCreateResourceModuleAISteps] = useState([
        "Resource Module Creation",
        "Choose your Resource Module",
    ]);

    // Validating the forms
    const stepValidate = (errors) => {
        let _errors = errors;

        return _errors;
    };

    // Handle continuing in steps
    const onContinue = async () => {
        switch (step) {
            case 0:
                {
                    let error = stepValidate(await validate());
                    if (error.size) return;
                    createResourceModulePreviews();
                }
                break;
            case 1:
                if (resourceSelected?.length === 1) {
                    setResourceChosen([resources[resourceSelected[0]]]);
                }
                break;
            default:
                // eslint-disable-next-line no-console
                console.error('Error in Steps!');
                break;
        }
    };

    // Handle backing in steps
    const onCancel = () => {
        if (step === 1) {
            setConfirmationModalDescription("You will lose all the current Generated Resource Modules! Are you sure?");
            setOpenConfirmationModal(true);
        }
    };

    // Handle confirming confirmation modal
    const confirmConfirmationModal = () => {
        if (step === 1) {
            if (confirmationModalDescription === "You will lose all the current Generated Resource Modules! Are you sure?") {
                setResources([]);
                setResourcesState([]);
                setResourceSelected([]);
                setResourceExpanded('resourcePanel0');
                setResourceChosen(null);
                setStep(step - 1);
            }
        }

        setOpenConfirmationModal(false);
    };

    // Generates the Resource Module Previews
    const createResourceModulePreviews = async () => {
        setLoading(true);

        try {
            // Extracting IDs and values for level and duration
            const levelId = data.level_id?.[0]?.key;
            const durationId = data.duration_id?.[0]?.key;

            // Extracting IDs and values for skills
            const skillIds = data.skills?.map(skill => skill.key);
            const skillTitles = data.skills?.map(skill => skill.value);

            const payload = {
                ...data,
                level_id: levelId,
                duration_id: durationId,
                skills: skillIds,
                skill_titles: skillTitles,
                is_ai_created: true,
                resource_modules: true,
                resource_module_openai: true,
                organization_id: organizationId,
            };

            const res = await aiCreateResourceModulePreviews(payload);
            if (res?.data?.data) {
                setResources(Object.values(res?.data?.data));
                setStep(step + 1);
            } else {
                setResources([]);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Error in resource module generating API call: ", e);
        } finally {
            scrollToTop();
            setLoading(false);
        }
    };

    useEffect(() => {
        if (resourceChosen && step === 1) {
            handleFinalCreate();
        }
    }, [resourceChosen]);

    useEffect(() => {
        if (resources?.length > 0) {
            if (resources?.resource_modules?.length > 0 && step === 0) {
                setStep(step + 1);
                scrollToTop();
                setLoading(false);
            }

            setResourcesState(resources.map(resource => ({
                ...resource
            })));
        }
    }, [resources]);

    const handleResourcePanelChange = (panel) => (event, isExpanded) => {
        setResourceExpanded(isExpanded ? panel : false);
    };

    const handleFinalCreate = async () => {
        setLoading(true);
        let createdResourceModuleResponse = [];
        try {
            if (resourceChosen) {
                try {
                    const resourcesPayload = {
                        ...resourceChosen[0],
                        organization_id: organizationId,
                    };

                    createdResourceModuleResponse = await aiCreateResourceModule(resourcesPayload);
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error("Failed to create resource module: ", error);
                }
            }

            if (createdResourceModuleResponse.id) {
                navigateResource(createdResourceModuleResponse.slug, true);
                toast.success("Success");
            } else {
                toast.error("Something went wrong!");
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error("Unexpected error in handleFinalCreate:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setNumResourcesToShow(4);
        setLoadMoreResourcesClickCount(0);
    }, [step]);

    const [numResourcesToShow, setNumResourcesToShow] = useState(4);
    const [loadMoreResourcesClickCount, setLoadMoreResourcesClickCount] = useState(0);

    return (
        <div className="ai-creation-form ai-scoped">
            <ConfirmationModal
                open={openConfirmationModal}
                onClose={() => { setOpenConfirmationModal(false) }}
                desc={confirmationModalDescription}
                okayButtonTitle="Confirm"
                onOkay={confirmConfirmationModal}
            />
            <DashboardLayout>
                <EditCreateHeaderCard
                    width="min(1600px,90%)"
                    title="Prepr Resource Module Builder AI"
                    classes="mt-header flex-row"
                />
                <MainPageLayout classes="mb-6 mt-2 container-medium" firstMdGridSize={12} secondMdGridSize={12} firstLgGridSize={4} secondLgGridSize={8}>
                    <AIForm
                        step={step}
                        loading={loading}
                        data={data}
                        errors={errors}
                        onChange={onChange}
                        stepSchema={stepSchema}
                        onCancel={onCancel}
                        onContinue={onContinue}
                        stepValidate={stepValidate}
                        optionSelected={resourceSelected}
                        createAISteps={createResourceModuleAISteps}
                        setCreateAISteps={setCreateResourceModuleAISteps}
                    />
                    <div className={`right`}>
                        <Card centered classes={`column-center`} width="min(1600px,100%)">
                            <EditCreateHeaderCard
                                current={step}
                                length={createResourceModuleAISteps.length}
                                subtitles={createResourceModuleAISteps}
                                rounded
                                noShadow
                                noPadding
                            />
                            {step === 0 && (
                                <div className={`intro-box ${loading ? 'flashing' : ''}`}>
                                    <p className="intro-text">{loading ? "Generating your Resource Modules... (it may take up to 2 minutes)" : "Our Resource Module Builder AI can help you generate Resource Modules!"}</p>
                                    <img width="100%" src={aiBackground1} alt="" />
                                </div>
                            )}
                            {step === 1 && (
                                loading ?
                                    <div className='intro-box'>
                                        <p className="intro-text">Working on it...</p>
                                        <img width="100%" src={aiLoading} alt="" />
                                    </div> :
                                    <ResourceModulePreview
                                        resourcesState={resourcesState}
                                        resourceExpanded={resourceExpanded}
                                        handleResourcePanelChange={handleResourcePanelChange}
                                        resourcesSelected={resourceSelected}
                                        setResourcesSelected={setResourceSelected}
                                        numResourcesToShow={numResourcesToShow}
                                        setNumResourcesToShow={setNumResourcesToShow}
                                        loadMoreResourcesClickCount={loadMoreResourcesClickCount}
                                        setLoadMoreResourcesClickCount={setLoadMoreResourcesClickCount}
                                        maxSelect={1}
                                    />
                            )}
                        </Card>
                    </div>
                </MainPageLayout>
            </DashboardLayout >
        </div>
    )
}

export default ResourceModuleBuilderAI;