import { render, waitFor } from "@testing-library/react";
import { mockRequestDelete, mockRequestGet, mockRequestPost } from "../mocks/request";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { initStore } from "../mocks/store";
import userEvent from "@testing-library/user-event";
import { ENDPOINTS } from "store/api/endpoints";
import useNotifications from "./service-hook";
import { REDUCER_TYPES } from "store";

let getSpy,deleteSpy,postSpy;

const payload = {
    notification_ids: [1,2],
};

const id = '1';

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com" },
  },
  [REDUCER_TYPES.MASTER]: {},
});

const NotificationsServicesMock = () => {
  const {
    fetchNotifications,
    readNotifications,
    deleteNotifications
  } = useNotifications();

  const BUTTON_LINKS = [
    {
      name: "fetchNotifications",
      onClick: () => fetchNotifications(),
    },
    {
      name: "readNotifications",
      onClick: () => readNotifications(payload),
    },
    {
      name: "deleteNotifications",
      onClick: () => deleteNotifications(id),
    },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Notifications services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <NotificationsServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    deleteSpy = mockRequestDelete();
    postSpy = mockRequestPost();
  });

  test("fetchNotifications", async () => {
    const button = screen.getByTestId("fetchNotifications");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.NOTIFICATIONS}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("readNotifications", async () => {
    const button = screen.getByTestId("readNotifications");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.NOTIFICATIONS}/${ENDPOINTS.MARK_AS_READ}`,
        payload,
        headers,
        expect.any(Function)
      )
    );
  });

  test("deleteNotifications", async () => {
    const button = screen.getByTestId("deleteNotifications");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.NOTIFICATIONS}/${id}`,
        headers,
        expect.any(Function)
      )
    );
  });

 
});
