import { Grid, Icon, IconButton } from "@mui/material"
import Card from "components/Cards/Card"
import pinned from "assets/svg/pinned.svg";
import ChipsPreview from "components/UI/atoms/ChipsPreview";
import useRedirect from "helpers/hooks/useRedirect";

const JobCard = ({ id, title, description, classes, iconsAndValuesList, skills = [], unPin }) => {
    const { navigateJob } = useRedirect();
    return (
        <Card noShadow classes={`${classes} border flex-column items-stetch px-2 py-2 overflowX-hidden `}>
            <div className="flex justify-between">
                <h3 title={title} onClick={() => navigateJob(id)} className="link fw-600 fs-18 text-truncate" >{title}</h3>
                <IconButton
                    className="c-primary cursor-pointer"
                    onClick={() => unPin(id, true)}
                >
                    <img src={pinned} alt="pinned icon" width="18px" height="18px" className=" object-cover" />
                </IconButton>
            </div>
            <p className="fw-500 fs-14 fs-12--mob text-truncate">{description}</p>
            {!!iconsAndValuesList ?
                <Grid container mt={1} >
                    {
                        iconsAndValuesList?.map(({ icon, img, value, onClick, iconProps = {} }, i) => (
                            <Grid key={i} xs={6} className="flex items-center tracking-tight mb-1 px-1">
                                {
                                    !!icon ?
                                        <Icon
                                            component={icon}
                                            fontSize="small"
                                            className="c-primary cursor-pointer"
                                            {...iconProps}
                                            onClick={onClick}
                                        /> : !!img ? <img src={img} className="object-cover w-20 h-20" alt="" /> : null
                                }

                                {value ? <p className="ml-2 fs-12 text-truncate maxw-200">
                                    {value}{" "}
                                </p> : null}
                            </Grid>
                        ))
                    }
                </Grid>

                : null}


            <div className="flex mt-2 mb-1">
                <ChipsPreview items={skills} maxWidth={90} limit={2} />
            </div>
        </Card>
    )
}
export default JobCard
