import React from "react";
import { Checkbox as CheckBtn } from "@mui/material";
import styles from "./input.module.scss";

const Checkbox = ({
  option,
  name,
  error,
  onChange,
  checked,
  multi,
  currentValue,
  selectedValue,
  multiSelect,
  image,
  imageBG,
  color,
  disabled,
  size = "medium",
  classes,
  t = (d) => d,
}) => {
  const onChangeHandler = (e) => {
    if (multiSelect) {
      const selected = selectedValue || [];
      const newVal = selected.includes(currentValue)
        ? selected.filter((val) => val !== currentValue)
        : [...selected, currentValue];
      onChange(newVal);
    } else {
      const val = multi ? currentValue : e.target.checked;
      onChange(val);
    }
  };
  return (
    <>
      <div className={`flex items-center ${classes}`}>
        <CheckBtn
          name={name}
          checked={checked}
          onChange={onChangeHandler}
          color={color}
          disabled={disabled}
          size={size}
        />
        {image && (
          <div
            style={{
              minWidth: "24px",
              width: "24px",
              height: "24px",
              borderRadius: imageBG ? "15%" : "0",
              background: imageBG || "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
              boxShadow: imageBG ? "0 4px 8px 0 rgba(0, 0, 0, 0.1)" : "none",
            }}
          >
            <img
              src={image}
              alt=""
              style={{
                maxWidth: imageBG ? "80%" : "100%",
                maxHeight: imageBG ? "80%" : "100%",
                objectFit: "cover",
              }}
            />
          </div>
        )}
        <span className="ml-1 fs-14">{t(option)}</span>
      </div>
      {error && <p className={styles.error}>{t(error, "common")}</p>}
    </>
  );
};
export default Checkbox;

export const CheckboxGroup = ({
  options = [],
  name,
  error,
  onChange,
  value: selectedValue,
  multiSelect,
  size,
  t = (d) => d,
}) => {
  return options.map(({ title, value, image, imageBG }, i) => (
    <Checkbox
      key={i}
      name={name}
      option={title}
      error={error}
      currentValue={value}
      selectedValue={selectedValue}
      checked={
        multiSelect ? selectedValue?.includes(value) : selectedValue === value
      }
      multi={options.length > 0 && !multiSelect}
      multiSelect={multiSelect}
      onChange={onChange}
      image={image}
      size={size}
      imageBG={imageBG}
      t={t}
    />
  ));
};
