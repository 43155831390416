import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {
  mockRequestDelete,
  mockRequestGet,
  mockRequestPost,
} from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useOrganization from "./service-hook";
import { BrowserRouter } from "react-router-dom";

let postSpy;
let getSpy;
let deleteSpy;

const organizationId = 1;
let plan_name = 1;
let customUrl = 1;
const payload = {
  latitude: 43.467517,
  longitude: -79.6876659,
  language: "en",
  status: "publish",
  user_id: 1,
};
const headers = {
  Authorization: "Bearer 1",
  "Content-Type": "multipart/form-data",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1 },
  },
  [REDUCER_TYPES.MASTER]: {},
  [REDUCER_TYPES.ORGANIZATION]: {
    organizations: [{ key: "Prepr", value: 1 }],
  },
});

const OrganizationHookMock = () => {
  const {
    getOrganizationList,
    createOrganization,
    deleteOrganization,
    updateOrganization,
    getOrganization,
    getOrganizationSelectList,
    getPublicOrgList,
    viewPublicOrganization,
    checkSlug,
    follow,
    unFollow,
    like,
    unLike,
    favorite,
    unFavorite,
    planDetails,
    upgradePlan,
    customize,
    getCustomization,
    comparePlans,
  } = useOrganization();

  const BUTTON_LINKS = [
    { name: "getOrganizationList", onClick: () => getOrganizationList() },
    { name: "createOrganization", onClick: () => createOrganization(payload) },
    {
      name: "deleteOrganization",
      onClick: () => deleteOrganization(organizationId),
    },
    {
      name: "updateOrganization",
      onClick: () => updateOrganization(organizationId, payload),
    },
    { name: "getOrganization", onClick: () => getOrganization(organizationId) },
    { name: "checkSlug", onClick: () => checkSlug(organizationId) },
    {
      name: "like",
      onClick: () => like(organizationId),
    },
    {
      name: "unLike",
      onClick: () => unLike(organizationId),
    },
    {
      name: "follow",
      onClick: () => follow(organizationId),
    },
    {
      name: "unFollow",
      onClick: () => unFollow(organizationId),
    },
    {
      name: "favorite",
      onClick: () => favorite(organizationId),
    },
    {
      name: "unFavorite",
      onClick: () => unFavorite(organizationId),
    },
    {
      name: "getOrganizationSelectList",
      onClick: () => getOrganizationSelectList("prepr"),
    },
    {
      name: "getPublicOrgList",
      onClick: () => getPublicOrgList(),
    },
    {
      name: "viewPublicOrganization",
      onClick: () => viewPublicOrganization(organizationId),
    },
    {
      name: "planDetails",
      onClick: () => planDetails(organizationId),
    },
    {
      name: "upgradePlan",
      onClick: () => upgradePlan(organizationId, plan_name),
    },
    {
      name: "customize",
      onClick: () => customize(organizationId, {}),
    },
    {
      name: "getCustomization",
      onClick: () => getCustomization(customUrl),
    },
    { name: "comparePlans", onClick: () => comparePlans() },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("organization services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <OrganizationHookMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
    deleteSpy = mockRequestDelete();
  });

  test("getOrganizationList", async () => {
    const button = screen.getByTestId("getOrganizationList");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}?language=en&`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("getOrganizationSelectList", async () => {
    const button = screen.getByTestId("getOrganizationSelectList");
    userEvent.click(button);
    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.GET_LIST}?language=en&search=prepr`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function),
        true
      );
    });
  });

  test("getPublicOrgList", async () => {
    const button = screen.getByTestId("getPublicOrgList");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}?language=en&`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("viewPublicOrganization", async () => {
    const button = screen.getByTestId("viewPublicOrganization");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${organizationId}?language=en`,
        {},
        { Authorization: "Bearer 1" },
        expect.any(Function),
        null,
        true
      )
    );
  });

  test("createOrganization", async () => {
    const button = screen.getByTestId("createOrganization");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.CREATE}?language=en`,
        payload,
        headers,
        expect.any(Function)
      )
    );
  });

  test("deleteOrganization", async () => {
    const button = screen.getByTestId("deleteOrganization");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${organizationId}/${ENDPOINTS.DELETE}?language=en`,
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });

  test("updateOrganization", async () => {
    const button = screen.getByTestId("updateOrganization");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${organizationId}/${ENDPOINTS.UPDATE}?language=en`,
        { ...payload, status: "publish", _method: "PUT" },
        headers,
        expect.any(Function)
      )
    );
  });

  test("getOrganization", async () => {
    const button = screen.getByTestId("getOrganization");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${organizationId}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        null,
        true
      )
    );
  });

  test("checkSlug", async () => {
    const button = screen.getByTestId("checkSlug");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.CHECK_SLUG}/${organizationId}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("like", async () => {
    const button = screen.getByTestId("like");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${organizationId}/${ENDPOINTS.LIKE}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
  test("unLike", async () => {
    const button = screen.getByTestId("unLike");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${organizationId}/${ENDPOINTS.UNLIKE}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
  test("follow", async () => {
    const button = screen.getByTestId("follow");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${organizationId}/${ENDPOINTS.FOLLOW}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
  test("unFollow", async () => {
    const button = screen.getByTestId("unFollow");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${organizationId}/${ENDPOINTS.UNFOLLOW}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
  test("favorite", async () => {
    const button = screen.getByTestId("favorite");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${organizationId}/${ENDPOINTS.FAVOURITE}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
  test("unFavorite", async () => {
    const button = screen.getByTestId("unFavorite");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${organizationId}/${ENDPOINTS.UNFAVOURITE}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });

  test("planDetails", async () => {
    const button = screen.getByTestId("planDetails");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${organizationId}/${ENDPOINTS.SUBSCRIPTION_DETAILS}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("upgradePlan", async () => {
    const button = screen.getByTestId("upgradePlan");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${organizationId}/${ENDPOINTS.SELECT_PLAN}?language=en&plan_name=1`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });

  test("customize", async () => {
    const button = screen.getByTestId("customize");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.ORGANIZATION}/${organizationId}/${ENDPOINTS.CUSTOMIZATION}?language=en`,
        { language: "en" },
        headers,
        expect.any(Function)
      )
    );
  });

  test("comparePlans", async () => {
    const button = screen.getByTestId("comparePlans");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.COMPARE_PLANS}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
});
