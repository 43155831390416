import {useContext} from "react";
import {AdvanceSearchContext} from "../container/AdvanceSearchContext";

/**
 * ADVANCE SEARCH CONTEXT HOOK
 */
export const useAdvanceSearchContext = () => {
    return useContext(AdvanceSearchContext)
}


