import { Grid } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import React from "react";
import MasterSelect from "../organisms/MasterSelect";
import { AddBox, RemoveCircleOutline } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { isValidUrl } from "helpers/utils/utilities";
import { LockIcon } from "components/Statics";

export const validateExternalLinks = (links, t = (d) => d) => {
  if (links.some((link) => !link.type || !link.url)) {
    toast.error(t("Please enter both the external link type and URL."));
    return true;
  }
  if (links.some((link) => !isValidUrl(link.url))) {
    toast.error(t("Please enter a valid URL."));
    return true;
  }
  return false;
};

const SocialLinks = ({ socialLinks, setSocialLinks, description, locked }) => {
  const { t } = useLocalizedTranslation("common", true);
  const addSocialLink = () => {
    setSocialLinks((val) => [...val, { type: null, url: "" }]);
  };
  const onChangeLinks = (e, index) => {
    const { name, value } = e.target;
    const keys = {
      url: "url",
      socialLinks: "type",
    };
    socialLinks[index][keys[name]] = value;
    setSocialLinks([...socialLinks]);
  };

  const deleteSocialLink = (index) => {
    socialLinks.splice(index, 1);
    setSocialLinks([...socialLinks]);
  };
  return (
    <>
      <div className="flex" style={{ alignItems: "baseline" }}>
        <h3 className="mt-4">{t("External Links")}</h3>
        {locked && <LockIcon />}
      </div>
      <p>{t(description)}</p>
      {socialLinks.map(({ url, type }, index) => (
        <Grid key={index} container spacing={2} mt={0.5} alignItems="center">
          <Grid item xs={12} lg={5}>
            <MasterSelect
              name="socialLinks"
              title="Select Link Type"
              borderedIcon
              classes="w-full"
              width="99%"
              value={type}
              onChange={(e) => onChangeLinks(e, index)}
              t={t}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Input
              title="Enter Url"
              classes="w-full"
              width="99%"
              name="url"
              value={url}
              onChange={(e) => onChangeLinks(e, index)}
              t={t}
            />
          </Grid>
          <Grid item xs={12} lg={1}>
            <RemoveCircleOutline
              className="c-error cursor-pointer mt-5"
              onClick={() => deleteSocialLink(index)}
            />
          </Grid>
        </Grid>
      ))}
      <Button bordered onClick={addSocialLink} classes="mt-4 mb-3">
        <AddBox className="mr-1" />
        {t("Add Link")}
      </Button>
    </>
  );
};

export default SocialLinks;
