import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SelectedList from "./SelectedList";
import Checkbox from "components/Input/Checkbox";
import AssociateList from "./AssociateList";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import useLabs from "store/labs/service-hook";
import useLabPrograms from "store/lab-programs/service-hook";
import useResources from "store/resources/service-hook";
import NavTabs from "components/UI/molecules/NavTabs";
import { payloadBool } from "helpers/utils/utilities";
const AssociatedLists = ({
  tabs,
  id = "uuid",
  selectedValue,
  setSelectedValue,
  organizationCheckboxLabel,
  t = (d) => d,
  type,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [organizationOnly, setOrganizationOnly] = useState(false);
  const { fetchLabsSelectList } = useLabs();
  const { fetchLabsProgramsSelectList } = useLabPrograms();
  const { fetchResourceSelectList, fetchResourceGroupSelectList } = useResources();

  
  const fetchAssociatedLabs = async ({search}) => {
    return await fetchLabsSelectList(search || "");
  }
  
  const fetchAssociatedResources = async (filter) => {
    return await fetchResourceSelectList(false, {
      ...filter,
      allow_global: payloadBool(!organizationOnly),
    });
  }

  const fetchAssociatedLabsResourcesCollections = async (filter) => {
    return await fetchResourceSelectList(true, {
      ...filter,
    });
  }

  const fetchAssociatedLabsResourcesGroups = async (filter) => {
    return await fetchResourceGroupSelectList({
      ...filter,
    });
  }
  return (
    <div className="w-full mt-4">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <h4>
            {t("Browse ${component} to add", null, {
              component: t(
                type === COMPONENT_TYPES.LAB
                  ? "Labs and Lab Programs"
                  : "Resources"
              ),
            })}
          </h4>
          {type === COMPONENT_TYPES.RESOURCE && tabValue === 0 ? (
            <Checkbox
              checked={organizationOnly}
              value={organizationOnly}
              onChange={(e) => setOrganizationOnly(e)}
              option={t(organizationCheckboxLabel)}
              name="organization-only"
              currentValue={organizationOnly}
            />
          ) : (
            ""
          )}
          <div className="w-full">
            <NavTabs list={tabs} onChange={setTabValue} value={tabValue} />
            {tabValue === 0 && (
              <>
                {type === COMPONENT_TYPES.LAB && (
                  <AssociateList
                    setSelected={setSelectedValue}
                    type={COMPONENT_TYPES.LAB}
                    selected={selectedValue}
                    t={t}
                    fetcher={fetchAssociatedLabs}
                  />
                )}
                {type === COMPONENT_TYPES.RESOURCE && (
                  <AssociateList
                    setSelected={setSelectedValue}
                    type={COMPONENT_TYPES.RESOURCE}
                    selected={selectedValue}
                    t={t}
                    fetcher={fetchAssociatedResources}
                  />
                )}
              </>
            )}
            {tabValue === 1 && (
              <>
                {type === COMPONENT_TYPES.LAB && (
                  <AssociateList
                    setSelected={setSelectedValue}
                    type={COMPONENT_TYPES.LAB_PROGRAMS}
                    selected={selectedValue}
                    fetcher={fetchLabsProgramsSelectList}
                    t={t}
                  />
                )}
                {type === COMPONENT_TYPES.RESOURCE && (
                  <AssociateList
                    setSelected={setSelectedValue}
                    type={COMPONENT_TYPES.RESOURCE_COLLECTION}
                    selected={selectedValue}
                    fetcher={fetchAssociatedLabsResourcesCollections}
                    t={t}
                  />
                )}
              </>
            )}
            {tabValue === 2 && (
              <>
                {type === COMPONENT_TYPES.RESOURCE && (
                  <AssociateList
                    setSelected={setSelectedValue}
                    type={COMPONENT_TYPES.RESOURCE_GROUP}
                    selected={selectedValue}
                    fetcher={fetchAssociatedLabsResourcesGroups}
                    t={t}
                  />
                )}
              </>
            )}
          </div>
        </Grid>
        <SelectedList
          selected={selectedValue}
          setSelected={setSelectedValue}
          id={id}
          type={type}
          t={t}
        />
      </Grid>
    </div>
  );
};

export default AssociatedLists;
