import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { serializeObject } from "store/api/utils";

const useLabDashboard = () => {
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  const headers = {
    Authorization: "Bearer " + token,
  };

  const recommendations = async (type = "challenges") => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.LAB}/${ENDPOINTS.MY_RECOMMENDATIONS}?language=${language}&type=${type}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const myResources = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.LAB}/${
        ENDPOINTS.MY_RESOURCE_MODULE
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const myLabs = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.LAB}/${
        ENDPOINTS.MY_LABS
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const myChallenges = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.LAB}/${
        ENDPOINTS.MY_CHALLENGES
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const myProjects = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.LAB}/${
        ENDPOINTS.GET_PROJECTS_LIST
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const inboxFriendRequests = async (type = "inbox") => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.LAB}/${ENDPOINTS.INBOX_FRIEND_REQUEST}?language=${language}&type=${type}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const layout = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.LAB}/${ENDPOINTS.FETCH_LAYOUT}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const updateLayout = async (params = {}) => {
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.LAB}/${
        ENDPOINTS.UPDATE_LAYOUT
      }?language=${language}&${serializeObject(params)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const getReports = async (type = "challenges") => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.LAB}/${ENDPOINTS.REPORTS}?language=${language}&type=${type}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const getSubscriptionDetails = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.LAB}/${ENDPOINTS.SUBSCRIPTION_DETAILS}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const upcomingChallengeDeadlines = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.LAB}/${ENDPOINTS.UPCOMING_CHALLENGE_DEADLINES}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  return {
    recommendations,
    myChallenges,
    myLabs,
    myResources,
    myProjects,
    inboxFriendRequests,
    layout,
    updateLayout,
    getReports,
    getSubscriptionDetails,
    upcomingChallengeDeadlines,
  };
};

export default useLabDashboard;
