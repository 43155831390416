import React, { forwardRef } from "react";
import styles from "./input.module.scss";
import cx from "classnames";
import { createEvent } from "helpers/utils/utilities";
import { LockIcon } from "components/Statics";

const Input = forwardRef(
  (
    {
      value,
      name,
      placeholder,
      onChange = Function.prototype,
      top,
      error,
      height,
      width,
      title,
      classes,
      required,
      icon,
      gray,
      preInput,
      iconLeft,
      disabled,
      type,
      onFocus,
      limit,
      description,
      locked,
      hideAsterisk,
      t = (d) => d,
      noWrapDecsription,
      ...rest
    },
    ref
  ) => {
    const onChangeHandler = (e) => {
      if (!onChange) return;
      if (!limit) onChange(e);
      else {
        let newValue = e.target.value;
        newValue = newValue.substr(0, limit);
        onChange(createEvent(name, newValue));
      }
    };

    return (
      <div
        data-testid="input"
        style={{ marginTop: top }}
        className={cx(styles.inputContainer, classes)}
      >
        {!!title && (
          <p className={`${styles.title} flex items-center} text-nowrap`}>
            {typeof title === "string" ? t(title) : title}
            {!!required && !hideAsterisk && <span>*</span>}
            {locked && <LockIcon />}
            {/* THE BELOW ICON IS BREAKING THE DESIGN */}
            {/* {disabled && 
            <img src="/images/icons/lock.svg" className="px-2" alt="" />
          } */}
          </p>
        )}
        {!!description && (
          <p
            className={`${styles.description} ${
              noWrapDecsription ? "text-nowrap" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: t(description) }}
          />
        )}
        {!!preInput ? (
          <div
            style={{ width: !!width && width }}
            className="flex items-center"
          >
            <div className={cx(styles.preInput, styles.input, "mr-1")}>
              {preInput}
            </div>
            <input
              name={name}
              type={!!type ? type : undefined}
              style={{ height: !!height && height, width: "100%" }}
              className={cx(
                styles.input,
                { [styles.gray]: gray },
                { [styles.error]: !!error }
              )}
              placeholder={t(placeholder)}
              value={value}
              onChange={onChangeHandler}
              disabled={disabled}
              onFocus={onFocus}
              {...rest}
            />
          </div>
        ) : (
          <input
            name={name}
            type={!!type ? type : undefined}
            style={{
              height: !!height && height,
              width: !!width && width,
              paddingLeft: !!iconLeft && "30px",
              paddingRight: !!icon && !iconLeft && "28px",
              border: "1px solid black",
            }}
            className={cx(
              styles.input,
              { [styles.gray]: gray },
              { [styles.error]: !!error }
            )}
            placeholder={t(placeholder)}
            value={value}
            onChange={onChangeHandler}
            disabled={disabled}
            onFocus={onFocus}
            ref={ref}
            {...rest}
          />
        )}
        {error && <p className={styles.error}>{t(error, "common")}</p>}
        {limit && value?.length >= limit && (
          <p className={styles.helperText}>
            {t("You can only enter up to ${limit} characters", "common", {
              limit: limit,
            })}
          </p>
        )}
        <span
          style={{ top: error ? "40%" : title ? "50%" : undefined }}
          className={`${
            !!icon
              ? cx(styles.icon, { [styles.left]: iconLeft })
              : "display-none"
          }`}
        >
          {!!icon && icon}
        </span>
      </div>
    );
  }
);

export default Input;
