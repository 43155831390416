import { ReactComponent as Airmeet } from "../../assets/svg/airmeet.svg";
import Button from "../Button";
import moment from "moment";

const LiveEventCard = ({ eventDetails, unlink, t = (d) => d }) => {
  return (
    <>
      <div className="live-event-details">
        <div className="logo">
          <Airmeet />
        </div>
        <div className="details">
          <h3 className="mb-1">{eventDetails.name}</h3>
          <ul>
            <li>
              <span className="mr-2">{t("From")}</span>
              <span>
                {eventDetails?.start_time
                  ? moment(eventDetails?.start_time).format("Y-MM-DD")
                  : ""}
              </span>
            </li>
            <li>
              <span className="mr-2">{t("To")}</span>
              <span>
                {eventDetails?.end_time
                  ? moment(eventDetails?.end_time).format("Y-MM-DD")
                  : ""}
              </span>
            </li>
          </ul>
        </div>
        <div className="action">
          <Button color={"red"} onClick={unlink}>
            {t("Unlink")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default LiveEventCard;
