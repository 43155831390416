import React from "react";
import left from "../../assets/png/arrow-left.png";
import right from "../../assets/png/arrow-right.png";
import SwipableView from "components/UI/organisms/SwipableView";
import "./slider.scss";
import { useTranslation } from "react-i18next";

const Slider = ({ slides, classes }) => {
  const { t } = useTranslation("auth");
  return (
    <SwipableView slidesPerView={1} classes={classes} small>
      {slides.map((slide, i) => (
        <div
          key={i}
          style={{
            backgroundImage: `url(${typeof slide === "string" ? slide : require("../../" + slide.image)})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
          }}
          className="slides flex-column justify-center items-center rounded-40 my-4 mx-0 text-center py-0 px-1"
        >
          {typeof slide !== "string" ? <> <div className="flex gap-20">
            {slide.slide_icons.map((icon, i) => (
              <img
                src={require("../../" + icon)}
                key={i}
                alt="icon"
                className="h-100"
              />
            ))}
          </div>
            <h1 className="c-white mt-10 text-center fs-32">{t(slide.title)}</h1>
            <p className="c-white text-center">{t(slide.description)}</p>
          </> : null}
        </div>
      ))}
    </SwipableView>
  );
};

export default Slider;

export const SliderDots = ({
  length,
  curr,
  setCurr = () => { },
  classes,
  noArrows,
  white,
}) => {
  const onDotClick = (i) => setCurr(i);
  const onArrowClick = (type) => {
    if (type === 0) {
      curr === 0 ? setCurr(length - 1) : setCurr(curr - 1);
    } else {
      curr === length - 1 ? setCurr(0) : setCurr(curr + 1);
    }
  };

  return (
    <div className={`slider-dots w-full flex justify-center items-center mt-6 ${classes}`}>
      {!noArrows && (
        <img
          src={left}
          className="hover-scale mr-4"
          onClick={() => onArrowClick(0)}
          alt="left arrow icon"
        />
      )}
      {Array(length)
        .fill(0)
        .map((d, i) => (
          <div
            key={i}
            onClick={() => onDotClick(i)}
            className={`dot rounded-50 my-0 mx-1 cursor-pointer ${curr === i && "dot-selected bg-primary"} ${white && "white bg-white"
              }`}
          />
        ))}
      {!noArrows && (
        <img
          src={right}
          style={{ marginLeft: 20 }}
          className="hover-scale"
          onClick={() => onArrowClick(1)}
          alt="right arrow icon"
        />
      )}
    </div>
  );
};
