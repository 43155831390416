import { Chat, Close, Search } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Card from "components/Cards/Card";
import FriendCard from "components/Cards/FriendCard";
import Input from "components/Input";
import React, { useState, useEffect } from "react";
import ListingContainer from "components/UI/organisms/ListingContainer";
import useProfile from "store/profile/service-hook";
import useRedirect from "helpers/hooks/useRedirect";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import EmptyText from "components/UI/atoms/EmptyText";
import { useParams } from "react-router-dom";
import {REDUCER} from "../../../store/profile/reducer";
import ACTIONS from "../../../store/profile/action-types";
import { useDispatch } from "react-redux";

const PER_PAGE = 6;

const Friends = ({ itsMe, profile, t = (d) => d }) => {
  const { navigateChats } = useRedirect();
  const [friends, setFriends] = useState();
  const { getFriendsList, unfriend } = useProfile();
  const [searchText, setSearchText] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchFriends();
  }, []);
  const fetchFriends = async () => {
    const res = await getFriendsList(id);
    if (res) setFriends(res || []);
  };

  const handleUnfriend = (userId) => {
    unfriend(userId);
    setFriends((prev) => {
      const updatedFriends = prev.filter((user) => user?.id !== userId);
      dispatch(REDUCER[ACTIONS.FRIENDS](updatedFriends));
      return updatedFriends;
    });
  };

  const onUnfriend = () => {
    if (selectedFriend) {
      handleUnfriend(selectedFriend.id);
      setSelectedFriend(null);
    }
  };

  const cancelExitConfirmationModal = () => {
    setSelectedFriend(null);
  };

  const friendsList = itsMe ? friends : profile?.friends || [];

  const filteredFriends = friendsList
    ?.filter(
      (d) =>
        d &&
        Object.keys(d).every((key) =>
          Array.isArray(d[key]) ? d[key]?.length > 0 : true
        )
    )
    ?.filter(
      (d) =>
        !searchText ||
        d?.full_name?.toLowerCase()?.includes(searchText.trim().toLowerCase())
    );

  return (
    <Card classes="mt-4">
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={6}>
          <h2>{t("Friends")}</h2>
        </Grid>
        {friendsList?.length > 0 && (
          <Grid item xs={12} sm={5}>
            <Input
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setCurrentPage(1);
              }}
              icon={<Search />}
              height={40}
              width="100%"
            />
          </Grid>
        )}
      </Grid>

      <ListingContainer
        isEmpty={!filteredFriends?.length || !friendsList?.length}
        loading={!friendsList}
        classes="mt-5"
        emptyView={
          <EmptyText>
            {t(friendsList?.length > 0 && filteredFriends?.length === 0
              ? "No result found, please try different keywords"
              : itsMe
              ? "Add friends to display them here"
              : "This user has not added any friends")}
          </EmptyText>
        }
        spinLoader
        page={currentPage}
        setPage={setCurrentPage}
        count={Math.ceil(filteredFriends?.length / PER_PAGE)}
      >
        <Grid container item xs={12} spacing={1}>
          {filteredFriends
            ?.slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE)
            ?.map((d, i) => (
              <Grid item xs={12} sm={6} key={i}>
                <FriendCard
                  name={d?.full_name}
                  image={d?.profile_image}
                  rank={d?.learnrank}
                  role={t(d?.role)}
                  username={d?.username}
                  actionButtons={[
                    !itsMe && { hide: true },
                    itsMe && {
                      text: "Remove",
                      color: "red",
                      icon: <Close />,
                      onClick: () => setSelectedFriend(d),
                    },
                    {
                      text: "Message",
                      icon: <Chat />,
                      onClick: () => navigateChats(`?username=${d?.username}`),
                    },
                  ].filter(Boolean)}
                  t={t}
                />
              </Grid>
            ))}
        </Grid>
      </ListingContainer>
      <ConfirmationModal
        open={!!selectedFriend}
        onClose={cancelExitConfirmationModal}
        hideHeading
        desc={`${t("Are you sure you want to unfriend")} ${selectedFriend?.full_name}?`}
        okayButtonTitle={t("Yes")}
        onOkay={onUnfriend}
      />
    </Card>
  );
};

export default Friends;
