import React from "react";
import { styled } from "@mui/material/styles";
import { Avatar, AvatarGroup, Badge } from "@mui/material";
import user from "assets/svg/blue/user.svg";
import user2 from "assets/svg/green/user.svg";

export const StyledBadge = styled(Badge)(({ isGroup }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    right: isGroup ? "15%" : "14%",
    bottom: isGroup ? "33%" : "14%",
    zIndex: 9999,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      content: '""',
    },
  },
}));

const Styled = ({ src, classes, isGroup, placeholder }) => {
  return (
    <StyledBadge
      isGroup={isGroup}
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant="dot"
    >
      <Avatar src={src} className={classes}>
        <img
          src={placeholder ?? user}
          alt="user"
          height={30}
          width={30}
          className="bg-white"
        />
      </Avatar>
    </StyledBadge>
  );
};

const OnlineAvatar = ({ src, notOnline, isGroup, style, placeholder }) => {
  if (notOnline && !isGroup) {
    return (
      <Avatar
        src={src ?? placeholder ?? user}
        sx={{ width: 30, height: 30, ...style }}
      >
        <img
          src={placeholder ?? user}
          alt="user"
          height={30}
          width={30}
          style={style}
          className="bg-white"
        />
      </Avatar>
    );
  }

  if (isGroup) {
    const array = Array.isArray(src) ? src : Array(2);
    return (
      <AvatarGroup
        max={2}
        spacing="small"
        data-testid="online-avatar"
        className="mr-3"
      >
        {array.map((src, i) =>
          i === 0 && !notOnline ? (
            <Styled
              isGroup={isGroup}
              src={src}
              key={i}
              classes={`w-25 h-25  ${i === 0 && "z-10"}`}
            />
          ) : (
            <Avatar
              key={i}
              src={src}
              sx={{
                width: 25,
                height: 25,
                border: i === 0 ? "2px solid white" : "none",
                zIndex: i === 0 ? 2 : 1,
                marginTop: i > 0 && "-10px",
              }}
            >
              <img
                src={i === 0 ? user : user2}
                alt="user"
                height={25}
                width={25}
                className="bg-white"
              />
            </Avatar>
          )
        )}
      </AvatarGroup>
    );
  }

  return <Styled src={src} classes="w-30 h-30" placeholder={placeholder} />;
};

export default OnlineAvatar;
