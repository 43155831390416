import React from "react";
import styles from "./input.module.scss";
import cx from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createEvent } from "helpers/utils/utilities";
import moment from "moment";
import { AccessTime } from "@mui/icons-material";

const DateTimeInput = ({
  value,
  placeholder = "-- : --  AM",
  onChange,
  top,
  error,
  height = "46px",
  width,
  title,
  classes,
  required,
  disabled,
  disablePast,
  name,
  description,
  t = (d) => d,
  ...rest
}) => {
  // Filter function to disable past dates if required
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div
      data-testid="date-input"
      style={{ marginTop: top }}
      className={cx(styles.inputContainer, classes)}
    >
      {!!title && (
        <p className={styles.title}>
          {t(title)}
          {!!required && <span>*</span>}
        </p>
      )}
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: t(description) }}
        style={{ minHeight: "16px" }}
      />
      <div className="relative">
        <div
          className="left-0 flex-center justify-center z-10 border-r absolute"
          style={{ height: height, width: height, bottom: "0px" }}
        >
          <AccessTime className="c-primary" />
        </div>
        <DatePicker
          calendarClassName={styles.calenderBox}
          style={{ height, width }}
          className="w-full pl-10 maxw-250"
          placeholderText={placeholder}
          selected={value ? new Date(value) : null}
          onChange={(e) =>
            onChange(
              createEvent(
                name,
                e ? moment(e).format("YYYY-MM-DD HH:mm:ss") : null
              )
            )
          }
          dateFormat="yyyy-MM-dd HH:mm:ss"
          disabled={disabled}
          filterTime={disablePast ? filterPassedTime : undefined}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15} // Adjust this value according to your requirements
          {...rest}
        />
      </div>
      {error && <p className={styles.error}>{t(error, "common")}</p>}
    </div>
  );
};

export default DateTimeInput;
