import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {mockRequestGet,} from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useAssociation from "./index";
import { BrowserRouter } from "react-router-dom";

let getSpy;

let parent = "1";
let parentSlug = "1";
let child = "1";
let component = "1";
let associatedId = "1";

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
});

const AssociationServicesMock = () => {
    const { fetchAssociation, fetchRelated, share } = useAssociation();
  
    const BUTTON_LINKS = [
      { name: "fetchAssociation", onClick: () => fetchAssociation(parent, parentSlug, child, {})},
      { name: "fetchRelated", onClick: () => fetchRelated(component, associatedId, {})},
      { name: "share", onClick: () => share(associatedId, component)},
    ];
  
    return (
      <div>
        {BUTTON_LINKS.map((item) => (
          <button data-testid={item.name} key={item.name} onClick={item.onClick}>
            Call {item.name}
          </button>
        ))}
      </div>
    );
  };
  
  let screen;

  describe("Associated Listings Services", () => {
    beforeEach(() => {
      screen = render(
        <Provider store={store}>
          <BrowserRouter>
            <AssociationServicesMock />
          </BrowserRouter>
        </Provider>
      );
      getSpy = mockRequestGet();
    });
  
    test("fetchAssociation", async () => {
      const button = screen.getByTestId("fetchAssociation");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.COMPONENT_ASSOCIATION}/${parent}/${parentSlug}/${child}?language=en&`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });

    test("fetchRelated", async () => {
      const button = screen.getByTestId("fetchRelated");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.COMPONENT_ASSOCIATION}/${component}/${associatedId}?language=en&`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });

    test("share", async () => {
      const button = screen.getByTestId("share");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.COMPONENT_ASSOCIATION}/${component}/${associatedId}/share?language=en`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });
  });  