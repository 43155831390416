import TopRightEditIcon from "components/UI/atoms/TopRightEditIcon";
import React from "react";
import Card from "./Card";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const AboutCard = ({ about, hideEdit, onEdit, isPrivateView }) => {
  const { t } = useLocalizedTranslation();
  return (
    <Card classes="my-4">
      <div className="flex justify-between relative">
        <h2>{t("About Us")} </h2>
        {!hideEdit && <TopRightEditIcon onEdit={onEdit} />}
      </div>
      {!about ? (
        isPrivateView ? (
          <p className="minh-250 c-gray mt-20 fs-16 text-center items-center">
            Click on the pencil to edit this section and write description <br />about your Organization.
          </p>
        ) : (
          <p>-</p>
        )
      ) : (
        <p className="mt-2 fs-14" dangerouslySetInnerHTML={{ __html: about }}></p>
      )}
    </Card>
  );
};

export default AboutCard;
