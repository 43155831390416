import { Search } from "@mui/icons-material";
import Input from "components/Input";
import Radio from "components/Input/Radio";
import Banner from "components/UI/molecules/Banner";
import React, { useMemo } from "react";
import BrowseWrapper from "components/UI/organisms/BrowseWrapper";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import useListingHeader from "helpers/hooks/useListingHeader";
import { useEffect, useState } from "react";
import useCareers from "store/careers/service-hook";
import useExplore from "store/explore/service-hook";
import SkillsJobsListing from "./SkillsJobsListing";
import MainPageLayout from "pages/layouts/MainPage";
import BrowseOptionsMenu from "components/UI/molecules/BrowseOptionsMenu";
import Card from "components/Cards/Card";
import JobCard from "../components/JobCard";
import SwipableView from "components/UI/organisms/SwipableView";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import { challenge } from "assets/svg/green";
import { resource } from "assets/svg/purple";
import { lab } from "assets/svg/blue";
import { skills as skillImg } from "assets/svg/yellow";
import ListingPageHeader from "components/UI/organisms/ListingPageHeader";
import TableComponent from "components/Table";
import { getJobAndSkillJSXCols } from "../data";
import pinned from "assets/svg/pinned.svg";
import { Grid, IconButton, Skeleton } from "@mui/material";
import useSkills from "store/skills/service-hook";
import { isTrue } from "helpers/utils/validators";
import useProfile from "store/profile/service-hook";
import { payloadBool } from "helpers/utils/utilities";
import { useSearchParams } from "react-router-dom";
import PinnedJobCard from "components/Loaders/PinnedJobCard";
import useRedirect from "helpers/hooks/useRedirect";
import { COLORS } from "helpers/utils/constants";
import useDebounce from "helpers/hooks/useDebounce";

const browse_options = [
  {
    id: 1,
    title: `Browse`,
    pageTitle: "Browse",
  },
  {
    id: 2,
    title: `Saved Jobs`,
    pageTitle: "Saved Jobs",
  },
  {
    id: 3,
    title: "Saved Skills",
    pageTitle: "Saved Skills",
  },
];

const CareersMainPage = ({ t, isAddonEnabled }) => {
  const [search, setSearch] = useState("job");
  const [browseMode, setBrowseMode] = useState(0);
  const [jobs, setJobs] = useState([]);
  const [myJobs, setMyJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingBrowse, setLoadingBrowse] = useState(true);
  const [loadingPinned, setLoadingPinned] = useState(true);
  const [loadingPin, setLoadingPin] = useState(false);
  const [page, setPage] = useState(0);
  const [jobPage, setJobPage] = useState(0);
  const [pages, setPages] = useState(1);
  const [jobPages, setJobPages] = useState(1);
  const [skills, setSkills] = useState([]);
  const { recommendedSkills } = useExplore();
  const state = useListingHeader();
  const jobsState = useListingHeader();
  const skillsState = useListingHeader();
  const [mySkills, setMySkills] = useState([]);
  const [myPinnedSkills, setMyPinnedSkills] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { relatedJobs, myJobs: fetchMyJobs, pinJob, deleteJob } = useCareers();
  const { mySkills: savedSkills, pinUnpin } = useSkills();
  const { deleteSkills } = useProfile();
  const { selected } = useFilters();
  const width = useWindowWidth();
  const [deletingItemId, setDeletingItemId] = useState(null);
  const { navigateSkill, navigateJob } = useRedirect();
  const navigateToDetail = browseMode === 1 ? navigateJob : navigateSkill;
  const [recommendedJobsCount, setRecommendedJobsCount] = useState([]);
  useEffect(() => {
    if (!isAddonEnabled) return;
    searchParams.set("filters", JSON.stringify({ careers: "recommendations" }));
    setSearchParams(searchParams);
  }, []);

  const debouncedSearchTerm = useDebounce(state.search, 500);

  useEffect(() => {
    if (browseMode !== 0) return;
    init();
  }, [
    page,
    jobPage,
    state.sort,
    browseMode,
    selected?.careers_status,
    debouncedSearchTerm,
  ]);

  useEffect(() => {
    if (browseMode === 0) return;
    fetch();
  }, [
    page,
    jobPage,
    jobsState.search,
    jobsState.sort,
    skillsState.search,
    skillsState.sort,
    browseMode,
  ]);

  useEffect(() => {}, [jobPages]);

  const init = async () => {
    setLoadingBrowse(true);

    const filters = {
      page: browseMode === 1 ? jobPage + 1 : page,
      sort_by: state.sort,
      search: state.search,
    };
    if (selected.careers_status) {
      filters.saved = selected.careers_status === "saved" ? "yes" : "no";
    }
    if (search === "skill") delete filters.search;
    const [list, count] = await relatedJobs(filters);
    if (!skills?.length) {
      const res = await recommendedSkills();
      setSkills(res);
    }
    setJobs(list || []);
    setRecommendedJobsCount(count || 0);
    setLoadingBrowse(false);
  };

  const fetch = async () => {
    let filters;
    let res;
    let resPinned;
    let resJobs;
    setLoadingBrowse(true);

    if (browseMode === 1) {
      filters = {
        sort_by: jobsState.sort,
        search: jobsState.search,
        page: jobPage + 1,
      };
      setLoading("jobs");
      resJobs = await fetchMyJobs(filters);
      setJobPages(resJobs.length || 0);

      setMyJobs(resJobs || []);
    }
    if (browseMode === 2) {
      filters = {
        sort_by: skillsState.sort,
        search: skillsState.search,
        page: page + 1,
      };
      setLoading("skills");

      resPinned = await savedSkills({ pinned: "yes" });
      setMyPinnedSkills(resPinned[0] || []);
      setLoadingPinned(false);

      res = await savedSkills(filters);
      const totalPages = res[1];
      setPages(totalPages || 0);

      setMySkills(res[0] || []);
    }
    setLoading(false);
    setLoadingBrowse(true);
    setLoadingPinned(false);
  };

  const slidesPerView = useMemo(
    () => (width > 1200 ? 3 : width > 900 ? 2 : 1),
    []
  );

  function groupSkillsIntoSlides(skills) {
    const groupedSkills = [];
    for (let i = 0; i < skills.length; i += 9) {
      groupedSkills.push(skills.slice(i, i + 9));
    }
    return groupedSkills;
  }

  const onClickPin = async (id, pinned) => {
    if (loadingPin) return;
    setLoadingPin(true);

    let alreadyPinned = isTrue(pinned);
    const action = browseMode === 1 ? pinJob : pinUnpin;
    await action(id, alreadyPinned);

    if (browseMode === 1) {
      setMyJobs((prev) =>
        prev?.map((d) => ({
          ...d,
          pinned: d?.id === id ? payloadBool(!alreadyPinned) : d?.pinned,
        }))
      );

      setPinnedListJob((prev) =>
        prev?.map((d) => ({
          ...d,
          pinned: d?.id === id ? payloadBool(!alreadyPinned) : d?.pinned,
        }))
      );
    } else {
      const updatedSkills = mySkills?.map((d) => ({
        ...d,
        pinned: d?.id === id ? payloadBool(!alreadyPinned) : d?.pinned,
      }));

      setMySkills(updatedSkills);

      setMyPinnedSkills((prevPinnedSkills) => {
        return updatedSkills.filter((d) => Boolean(d?.pinned));
      });
    }

    setLoadingPin(false);
  };

  const handleDeleteItem = async (id) => {
    setDeletingItemId(id);
    let resJobs;
    if (browseMode === 1) {
      await deleteJob(id);
      setMyJobs((prevJobs) => prevJobs.filter((job) => job.id !== id));
      await fetchPinnedJob();
      resJobs = await fetchMyJobs();
      setJobPages(resJobs.length || 0);
    } else {
      await deleteSkills(id);
      setMySkills((prevSkills) =>
        prevSkills.filter((skill) => skill.id !== id)
      );

      setMyPinnedSkills((prevSkills) =>
        prevSkills.filter((skill) => skill.id !== id)
      );
    }
    setDeletingItemId(null);
  };

  const [pinnedListJob, setPinnedListJob] = useState([]);

  useEffect(() => {
    fetchPinnedJob();
  }, []);

  const fetchPinnedJob = async () => {
    let res = await fetchMyJobs();
    setPinnedListJob(res);
  };
  const filteredPinned = (list) => {
    return list?.filter((d) => d?.pinned === "yes");
  };

  return (
    <>
      <Banner mobHeight="350px">
        <div className="container flex-column items-center text-center pt-2 mt-18 mt-14--mob">
          <h2 className="mx-auto text-center">{t("Career Explorer")}</h2>
          <p className="w-60p w-90p--mob mx-auto fs-15 mt-2">
            {t(
              "We'll recommend content based on your interests we've listed here. Feel free to add or remove topics to customize your experience!"
            )}
          </p>
          {browseMode === 0 && (
            <>
              <Input
                classes="mx-auto mt-4 maxw-90p"
                height="40px"
                width="500px"
                placeholder={t(
                  "Search for interested job or skill and save to your own list"
                )}
                icon={<Search className="c-gray" />}
                iconLeft
                value={state.search}
                onChange={(e) => state.setSearch(e.target.value)}
              />
              <div className="mt-3">
                <Radio
                  labelClasses="fs-15"
                  size="small"
                  name="search"
                  options={[
                    { key: "job", value: "Search for a job" },
                    { key: "skill", value: "Search for a skill" },
                  ]}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  t={t}
                />
              </div>
            </>
          )}
        </div>
      </Banner>
      {browseMode === 0 ? (
        <BrowseWrapper
          noHeaderFooter
          filters={[FILTER_TYPES.CAREERS, FILTER_TYPES.CAREERS_STATUS]}
          browseMode={browseMode}
          browseOptions={browse_options}
          {...state}
          setBrowseMode={setBrowseMode}
          hidePageTitle
          listingTitle={t(
            selected.careers &&
              (selected.careers.includes("skills") ||
                selected.careers.includes("jobs"))
              ? "Browse Jobs and Skills"
              : "Recommendations based on your profile"
          )}
        >
          {selected.careers !== "skills" && search !== "skill" && (
            <>
              <h3 className="mt-4 ml-2">{t("Recommended Jobs")}</h3>
              <SkillsJobsListing
                browseMode={browseMode}
                gridType={state.gridType}
                list={jobs}
                loading={loadingBrowse}
                pageCount={recommendedJobsCount}
                page={page}
                setPage={setPage}
                setList={setJobs}
                t={t}
                searchVal={state.search}
              />
            </>
          )}
          {selected.careers !== "jobs" && (
            <>
              <h3 className="mt-4 ml-2">{t("Recommended Skills")}</h3>
              <SkillsJobsListing
                browseMode={browseMode}
                gridType={state.gridType}
                list={skills?.filter(
                  (d) =>
                    search !== "skill" ||
                    !state.search ||
                    d?.title
                      ?.toLowerCase()
                      ?.includes(state?.search?.toLowerCase())
                )}
                loading={loadingBrowse}
                setList={setSkills}
                isSkills
                t={t}
                searchVal={state.search}
              />
            </>
          )}
        </BrowseWrapper>
      ) : (
        <MainPageLayout classes="mt-5 mb-6">
          <div className="left">
            <BrowseOptionsMenu
              options={browse_options.map((d) => d.title)}
              onChange={setBrowseMode}
              current={browseMode}
              classes="mb-4"
            />
          </div>
          <div className={`right`}>
            {browseMode !== 0 && (
              <>
                {browseMode === 1 &&
                  ((!loading && filteredPinned(pinnedListJob)?.length > 0) ||
                    loading === "jobs") && (
                    <Card classes="mb-3">
                      <h3>Pinned Jobs</h3>
                      {loadingPinned === true ? (
                        <Grid container spacing={2} mt={1} mb={6}>
                          {Array.from({ length: 3 }, (_d, i) => (
                            <Grid item xs={4} key={i}>
                              <PinnedJobCard />
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        filteredPinned(pinnedListJob)?.length > 0 && (
                          <SwipableView
                            dynamicBullets
                            slidesPerView={slidesPerView}
                            classes="mt-5"
                          >
                            {filteredPinned(pinnedListJob)?.map(
                              (
                                {
                                  id,
                                  title,
                                  description,
                                  related_challenges,
                                  related_labs,
                                  related_resources,
                                  skills,
                                },
                                i
                              ) => (
                                <JobCard
                                  key={i}
                                  id={id}
                                  title={title}
                                  classes="mb-5"
                                  description={description}
                                  iconsAndValuesList={[
                                    {
                                      img: challenge,
                                      value: `${related_challenges || "0"} ${t(
                                        "Related Challenges"
                                      )}`,
                                    },
                                    {
                                      img: lab,
                                      value: `${related_labs || "0"} ${t(
                                        "Related Labs"
                                      )}`,
                                    },
                                    {
                                      img: resource,
                                      value: `${related_resources || "0"} ${t(
                                        "Related Resources"
                                      )}`,
                                    },
                                    {
                                      img: skillImg,
                                      value: `${skills?.length || "0"} ${t(
                                        "Related Skills"
                                      )}`,
                                    },
                                  ]}
                                  skills={skills}
                                  unPin={onClickPin}
                                />
                              )
                            )}
                          </SwipableView>
                        )
                      )}
                    </Card>
                  )}

                {browseMode === 2 &&
                  ((!loading && filteredPinned(myPinnedSkills)?.length > 0) ||
                    loading === "skills") && (
                    <Card classes="mb-3">
                      <h3>{t("Pinned Skills")}</h3>
                      {loadingPinned === true ? (
                        <Grid container spacing={1} mt={1} mb={6}>
                          {Array(9)
                            .fill(1)
                            .map((_d, i) => (
                              <Grid item xs={4} key={i}>
                                <Skeleton
                                  variant="rectangular"
                                  height={50}
                                  className="w-full rounded-5"
                                />
                              </Grid>
                            ))}
                        </Grid>
                      ) : (
                        <SwipableView slidesPerView={1} classes="mt-3">
                          {groupSkillsIntoSlides(
                            filteredPinned(myPinnedSkills)
                          ).map((skillChunk, index) => (
                            <Grid
                              key={index}
                              container
                              mb={3}
                              columnSpacing={4}
                            >
                              {skillChunk.map((skill, i) => (
                                <Grid key={i} item xs={4} className="">
                                  <div className="border-b py-2 flex-center justify-between">
                                    <p
                                      className="link text-truncate maxw-80p"
                                      onClick={() => navigateSkill(skill?.id)}
                                    >
                                      {skill?.title}
                                    </p>
                                    <IconButton
                                      className="c-primary cursor-pointer"
                                      onClick={() =>
                                        onClickPin(skill?.id, true)
                                      }
                                    >
                                      <img
                                        src={pinned}
                                        width="18px"
                                        height="18px"
                                        className=" object-cover"
                                        alt="pinned icon"
                                      />
                                    </IconButton>
                                  </div>
                                </Grid>
                              ))}
                            </Grid>
                          ))}
                        </SwipableView>
                      )}
                    </Card>
                  )}

                {((browseMode === 1 && !myJobs.length && !jobsState?.search) ||
                  (browseMode === 2 &&
                    !mySkills.length &&
                    !skillsState?.search)) &&
                !loading ? (
                  <Card>
                    <h3>
                      {t(`Saved ${browseMode === 1 ? "Jobs" : "Skills"}`)}
                    </h3>
                    <p
                      className="fs-18 my-24 text-center fw-600"
                      style={{ color: " #8B8B8B" }}
                    >
                      {t(
                        `Add interested ${
                          browseMode === 1 ? "jobs" : "skills"
                        } to your list so they show up here.`
                      )}
                    </p>
                  </Card>
                ) : (
                  <Card>
                    <h3>
                      {t(`Saved ${browseMode === 1 ? "Jobs" : "Skills"}`)}
                    </h3>
                    <ListingPageHeader
                      noCard
                      noGrid
                      {...(browseMode === 1 ? jobsState : skillsState)}
                      classes="mt-3"
                    />
                    <TableComponent
                      rows={browseMode === 1 ? myJobs : mySkills}
                      columns={getJobAndSkillJSXCols(
                        onClickPin,
                        handleDeleteItem,
                        deletingItemId,
                        navigateToDetail,
                        t
                      )}
                      dense
                      rowCellClasses="fs-13 text-nowrap"
                      titleCellClasses="c-gray fs-13 tracking-tight"
                      tableClasses="mt-3"
                      loading={
                        browseMode === 1
                          ? loading === "jobs"
                          : loading === "skills"
                      }
                      paginator
                      page={browseMode === 1 ? jobPage : page}
                      setPage={browseMode === 1 ? setJobPage : setPage}
                      totalCount={browseMode === 1 ? jobPages : pages}
                      rowsPerPage={10}
                      rowSx={() => ({
                        "&:hover": {
                          backgroundColor: COLORS.BLUE_OPAQUE,
                          cursor: "pointer",
                        },
                      })}
                    />
                  </Card>
                )}
              </>
            )}
          </div>
        </MainPageLayout>
      )}
    </>
  );
};

export default CareersMainPage;
