import { Grid } from "@mui/material";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import PageTitle from "components/UI/atoms/PageTitle";
import React from "react";
import { stepSchema } from "./data";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import ExpertReferences from "components/UI/organisms/ExpertReferences";

const LabProgramDetails = ({
  data,
  onChange,
  errors,
  step,
  t = (d) => d,
  experts = [],
  setExperts = Function.prototype,
}) => {
  return (
    <Grid container px={{ xs: 0, md: 4 }}>
      <PageTitle>{t("Lab Program Details")}</PageTitle>
      <CreateFormSection>
        <SchemaUIExtractor
          schema={stepSchema(step).slice(0, 1)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
      </CreateFormSection>
      <CreateFormSection title={t("Lab Program Information")}>
        <SchemaUIExtractor
          schema={stepSchema(step).slice(1, 9)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
        <h3 className="mt-4">{t("Skills & Tags")}</h3>
        <SchemaUIExtractor
          schema={stepSchema(step).slice(9, 13)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
        <h3 className="mt-4">{t("Add Experts")}</h3>
        <p>{t("Feature experts for your lab program")}</p>
        <ExpertReferences experts={experts} setExperts={setExperts} />
      </CreateFormSection>
    </Grid>
  );
};

export default LabProgramDetails;
