import React from "react";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import DashboardWrapper from "./DashboardWrapper";
import ReportCard from "./components/ReportCard";
import ChallengeDeadlinesCard from "./components/ChallengeDeadlinesCard";
import LeaderBoardCard from "./components/LeaderboardCard";
import DashboardTable from "./components/DashboardTable";
import PlanDetailCard from "./components/PlanDetailCard";
import Inbox from "./components/Inbox";
import Recommendations from "./components/Recommendations";

const LabDashboard = ({ setInactiveById, items, setItems, isCustomizeMode }) => {
  return (
    <DashboardWrapper
      items={items}
      setItems={setItems}
      isCustomizeMode={isCustomizeMode}
      setInactiveById={setInactiveById}
    />
  );
};

export default LabDashboard;

export const LAB_DASH_ITEMS = [
  {
    uuid: "1",
    size: "md",
    id: "reports",
    children: <ReportCard type="lab" />,
    width: 1,
  },
  {
    uuid: "2",
    size: "md",
    children: <PlanDetailCard type="lab" />,
    width: 1,
    id: "subscription",
  },
  {
    uuid: "3",
    size: "md",
    children: <ChallengeDeadlinesCard type="lab" />,
    width: 1,
    id: "deadlines",
  },
  {
    uuid: "4",
    title: "Leaderboard",
    children: <LeaderBoardCard />,
    size: "md",
    width: 1,
    id: "leaderboard",
  },
  {
    uuid: "5",
    size: "md",
    children: (
      <DashboardTable
        tabs={["My Challenges"]}
        component={COMPONENT_TYPES.CHALLENGE}
        type="lab"
      />
    ),
    id: "my-challenges",
    width: 1,
  },

  {
    uuid: "6",
    size: "md",
    children: (
      <DashboardTable
        tabs={["My Labs"]}
        component={COMPONENT_TYPES.LAB}
        type="lab"
      />
    ),
    width: 1,
    id: "my-labs",
  },
  {
    uuid: "7",
    size: "md",
    children: (
      <DashboardTable
        tabs={["Project Assessment", "Submissions"]}
        component={COMPONENT_TYPES.PROJECT}
        type="lab"
      />
    ),
    width: 1,
    id: "my-projects",
  },
  {
    id: "my-resources",
    size: "md",
    children: (
      <DashboardTable
        type="user"
        tabs={["My Resources"]}
        component={COMPONENT_TYPES.RESOURCE}
      />
    ),
    width: 1,
    uuid: "8",
  },
  {
    id: "inbox-friends",
    children: <Inbox />,
    size: "md",
    width: 1,
    uuid: "9",
  },
  {
    children: <Recommendations classes="px-4" />,
    id: "recommendations",
    width: 1,
    size: "md",
    uuid: "10",
  },
];
