import Button from "components/Button";
import Input from "components/Input";
import AuthCard from "components/UI/organisms/AuthCard";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useRedirect from "helpers/hooks/useRedirect";
import AuthLayout from "pages/layouts/AuthLayout";
import React, { useState } from "react";
import useAuth from "store/auth/service-hook";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { forgotPassword } = useAuth();
  const { navigateBack } = useRedirect();
  const { t } = useLocalizedTranslation();
  const handleChangeEmail = (e) => {
    if (error) setError(false);
    setEmail(e.target.value);
  };
  const handleForgetPassword = async () => {
    setLoading(true);
    const res = await forgotPassword(email);
    if (res?.error) {
      setError(res?.message);
    }
    setLoading(false);
  };
  return (
    <AuthLayout sliderHidden>
      <AuthCard
        title="Forgot Password"
        description="Enter the email address you use on PreprLabs. We'll send you a OTP to reset your password."
        handleBack={navigateBack}
        showBack
        hideNextBack
      >
        <div className="w-70p mx-auto  mt-6">
          <Input
            placeholder="enter your email"
            title="Email or Username"
            width="97%"
            value={email}
            onChange={handleChangeEmail}
            classes="w-full"
            error={error}
            t={t}
          />
        </div>
        <div className="flex flex-column items-center mt-10">
          <Button
            width="120px"
            height="40px"
            onClick={handleForgetPassword}
            disabled={!email}
            loading={loading}
          >
            {t("Continue", "common")}
          </Button>
          <p className="my-10 fs-13 tracking-tight">
            {t("If you need assistance please contact")}{" "}
            <span className="link">support@prepr.org</span>
          </p>
        </div>
      </AuthCard>
    </AuthLayout>
  );
};

export default ForgotPassword;
