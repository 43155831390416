import { seed, sprout, budd, bloom, tree } from "assets/svg/plans/exports";

export const plan_features = [
  {
    title: "Organization Management",
    description:
      "Manage and update a page for your organization that showcases your people and your public Labs and Challenges.",
    all: true,
    feature_id: "organisation-manager-invite",
    has_numeric_limits: false,
  },
  {
    title: "Lab Management",
    description: "Manage and update your own Labs.",
    all: true,
    feature_id: "lab-creation",
    has_numeric_limits: false,
  },
  {
    title: "Challenge Management",
    description: "Manage and update your own Challenges.",
    all: true,
    feature_id: "challenge-creation",
    has_numeric_limits: false,
  },
  {
    title: "Resources Module Management",
    description:
      "Manage and update your own Resource Modules (eg. Individual Resources).",
    all: true,
    feature_id: "resource-creation",
    has_numeric_limits: false,
  },
  {
    title: "SSO Login",
    description:
      "Manage and update your own Resource Modules (eg. Individual Resources).",
    all: true,
    feature_id: "resource-creation",
    has_numeric_limits: false,
  },
  {
    title: "Two Factor Authentication",
    description:
      "Manage and update your own Resource Modules (eg. Individual Resources).",
    all: true,
    feature_id: "resource-creation",
    has_numeric_limits: false,
  },
  {
    title: "User Limit",
    description:
      "The number of participants you can invite to your Labs and Challenges.",
    seed: 10,
    sprout: 25,
    budd: 50,
    bloom: 150,
    feature_id: "user-invite",
    has_numeric_limits: true,
  },
  {
    title: "Manager Limit",
    description:
      "The number of managers you can running your Labs and Challenges.",
    seed: 4,
    sprout: 4,
    budd: 8,
    bloom: 16,
    feature_id: "organisation-manager-invite",
    has_numeric_limits: true,
  },

  {
    title: "Lab Program Management",
    description:
      "Manage and update your own Lab Programs (eg. Connected series of Labs).",
    onlySeedForbid: true,
    feature_id: "lab-program-creation",
    has_numeric_limits: false,
  },
  {
    title: "Lab Achievement",
    description:
      "Award achievement badges to particpants who complete your Labs.",
    onlySeedForbid: true,
    feature_id: "lab-creation",
    has_numeric_limits: false,
  },
  {
    title: "Lab Program Achievement",
    description:
      "Award achievement badges to particpants who complete your Lab Programs.",
    onlySeedForbid: true,
    feature_id: "lab-program-creation",
    has_numeric_limits: false,
  },
  {
    title: "Pre-built Labs Credits",
    description: "The number of credits you will have for pre-built Labs",
    seed: 2,
    sprout: "Unlimited",
    budd: "Unlimited",
    bloom: "Unlimited",
    feature_id: "pre-built-labs",
    has_numeric_limits: true,
  },
  {
    title: "Lab Limit",
    description:
      "The number of Labs you can have active on the PreprLabs Network.",
    seed: 1,
    sprout: 10,
    budd: 20,
    bloom: 40,
    feature_id: "lab-creation",
    has_numeric_limits: true,
  },
  {
    title: "Lab Program Limit",
    description:
      "The number of Lab Programs you can have active on the PreprLabs Network.",
    seed: 1,
    sprout: 2,
    budd: 4,
    bloom: 8,
    feature_id: "lab-program-creation",
    has_numeric_limits: true,
  },
  {
    title: "Challenge Path Management",
    description: "Manage and update your own Challenge Path.",
    onlySeedForbid: true,
    feature_id: "challenge-path-creation",
    has_numeric_limits: false,
  },
  {
    title: "Challenge Achievement",
    description: "Create and manage your own Challenge achievement badges.",
    onlySeedForbid: true,
    feature_id: "challenge-creation",
    has_numeric_limits: false,
  },
  {
    title: "Challenge Path Achievement",
    description:
      "Create and manage your own Challenge Path achievement badges.",
    onlySeedForbid: true,
    feature_id: "challenge-path-creation",
    has_numeric_limits: false,
  },
  {
    title: "Challenge Limit",
    description:
      "The number of Challenges you can have active on the PreprLabs Network.",
    seed: 4,
    sprout: 40,
    budd: 80,
    bloom: 160,
    feature_id: "challenge-creation",
    has_numeric_limits: true,
  },
  {
    title: "Challenge Path Limit",
    description:
      "The number of Challenge Paths you can have active on the PreprLabs Network.",
    seed: 2,
    sprout: 4,
    budd: 8,
    bloom: 16,
    feature_id: "challenge-path-creation",
    has_numeric_limits: true,
  },

  {
    title: "Resource Group & Collection Management",
    description:
      "Manage and update your own Resource Groups (eg. Groups of Resources).",
    onlySeedForbid: true,
    feature_id: "resource-group-creation",
    has_numeric_limits: false,
  },
  {
    title: "Resource Group Achievement",
    description:
      "Manage and update your own Resource Group Achievements (eg. Achievement badges for completing your Resource Groups).",
    onlySeedForbid: true,
    feature_id: "resource-group-creation",
    has_numeric_limits: false,
  },
  // {
  //   title: "SCORM File Capatability",
  //   description:
  //     "The ability to import existing learning resources in SCORM format from your learning management system to our learning experience platform.",
  //   seed: true,
  //   sprout: true,
  //   budd: true,
  //   bloom: true,
  //   feature_id: "resource-creation",
  //   has_numeric_limits: false,
  // },
  {
    title: "Resource Module Limit",
    description:
      "The number of Resource Modules you can have active on the PreprLabs Network.",
    seed: 1,
    sprout: 40,
    budd: 80,
    bloom: 160,
    feature_id: "resource-creation",
    has_numeric_limits: true,
  },
  {
    title: "Resource Group Limit",
    description:
      "The number of Resource Groups you can have active on the PreprLabs Network.",
    seed: 0,
    sprout: 5,
    budd: 10,
    bloom: 20,
    feature_id: "resource-group-creation",
    has_numeric_limits: true,
  },
  {
    title: "Resource Collection Limit",
    description:
      "The number of Resource Collections you can have active on the PreprLabs Network.",
    seed: 0,
    sprout: 10,
    budd: 20,
    bloom: 40,
    feature_id: "resource-collection-creation",
    has_numeric_limits: true,
  },
  {
    title: "User Activity Achievement",
    description:
      "The ability to award achievement badges to users who complete specific activity milestones on the PreprLabs Network.",
    onlySeedForbid: true,
    feature_id: "user-invite",
    has_numeric_limits: false,
  },
];

export const plansList = [
  {
    title: "Seed Plan",
    pricePerYearPerUser: "Free",
    totalUsers: 10,
    totalPrice: " ",
    haveTrialPeriod: false,
    description: "Great for getting to know the platform.",
    isPopular: false,
    icon: seed,
    type: "seed",
    id: "seed_plan_yearly",
    trial: "-",
  },
  {
    title: "Sprout Plan",
    pricePerYearPerUser: 179,
    totalUsers: 25,
    totalPrice: "total: $4475/yr",
    haveTrialPeriod: true,
    description: "Great for small teams and short-term initiatives.",
    isPopular: false,
    icon: sprout,
    type: "sprout",
    id: "sprout_plan_yearly",
    trial: "14 days trial available",
  },
  {
    title: "Budd Plan",
    pricePerYearPerUser: 159,
    totalUsers: 50,
    totalPrice: "total: $7950/yr",
    haveTrialPeriod: true,
    description:
      "Great for small-to-medium-sized businesses and organizations.",
    isPopular: true,
    icon: budd,
    type: "budd",
    id: "budd_plan_yearly",
    trial: "14 days trial available",
  },
  {
    title: "Bloom Plan",
    pricePerYearPerUser: 129,
    totalUsers: 150,
    totalPrice: "total: $19350/yr",
    haveTrialPeriod: true,
    description:
      "Great for large organizations, enterprises, and educational institutions.",
    isPopular: false,
    icon: bloom,
    type: "bloom",
    id: "bloom_plan_yearly",
    trial: "14 days trial available",
  },
  {
    title: "Enterprise Plan",
    pricePerYearPerUser: "Want customizations?",
    totalUsers: "150+",
    totalPrice: " ",
    haveTrialPeriod: false,
    description:
      "Great for large organizations, enterprises, and educational institutions.",
    isPopular: false,
    icon: tree,
    type: "enterprise",
    id: "unlimited_plan",
    price: null,
    trial: "Contact us for a free 14-day trial!",
  },
];

export const PLAN_NAMES = {
  seed_plan_yearly: "Seed Plan",
  sprout_plan_yearly: "Sprout Plan",
  budd_plan_yearly: "Budd Plan",
  bloom_plan_yearly: "Bloom Plan",
  unlimited_plan: "Enterprise Plan",
};
