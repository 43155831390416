import { Container } from "@mui/material";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import CreateFormTitle from "components/UI/atoms/CreateFormTitle";
import React from "react";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import { additional_information_schema } from "./data";

const AdditionalInformation = ({ data, onChange}) => {
  return (
    <Container xs={12} md={10} disableGutters>
      <CreateFormTitle title="Additional Information" classes="mt-2" />
      <CreateFormSection title="Additional Project Details">
        <SchemaUIExtractor
          schema={additional_information_schema}
          data={data}
          onChange={onChange}
          errors={new Map()}
        />
      </CreateFormSection>
    </Container>
  );
};

export default AdditionalInformation;
