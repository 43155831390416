import {
  Delete,
  Add,
  Visibility,
  VisibilityOff,
  Done,
} from "@mui/icons-material";
import { Chip, Grid } from "@mui/material";
import Button from "components/Button";
import MainProductCard from "components/Cards/MainProductCard";
import AcceptDeclineButtons from "components/UI/atoms/AcceptDeclineButtons";
import ListingContainer from "components/UI/organisms/ListingContainer";
import { COMPONENT_TYPES, ROLES } from "helpers/utils/constants";
import React, { useState } from "react";
import GroupIcon from "@mui/icons-material/Group";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { ReactComponent as LabIcon } from "assets/svg/blue/lab.svg";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import UpdateIcon from "@mui/icons-material/Update";
import useLabs from "store/labs/service-hook";
import { loginToPreceed } from "helpers/utils/utilities";
import useAuth from "store/auth/service-hook";
import { isTrue } from "helpers/utils/validators";
import moment from "moment";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ProgressTag from "components/UI/atoms/ProgressTag";
import ChipsPreview from "components/UI/atoms/ChipsPreview";
import { lab, labProgram } from "assets/svg/blue";
import LabManageOptions from "../components/LabManageOptions";
import useLabMarketplace from "store/lab-marketplace/service-hook";

const LabListing = ({
  browseMode,
  gridType,
  manage,
  labs = [],
  setLabs = Function.prototype,
  loading,
  handleDelete = Function.prototype,
  page = 1,
  setPage = Function.prototype,
  pageCount = 1,
  isLP,
  noSave,
  noPagination,
  smallView,
  emptyView,
  isAssociation,
}) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { join, unJoin, favorite, unFavorite, acceptLab, declineLab } =
    useLabs();
  const { add, remove } = useLabMarketplace();
  const {
    state: { isLoggedIn, user },
  } = useAuth();

  const { t } = useLocalizedTranslation("labs");

  const doUndoAction = async (slug, property, doAction, undoAction) => {
    if (!isLoggedIn) {
      loginToPreceed();
      return;
    }
    const itemIndex = labs.findIndex((d) => d.slug === slug);
    if (itemIndex === -1) return;

    const item = { ...labs[itemIndex] };
    const did = !!item[property] && item[property]?.toLowerCase() !== "no";
    if (did) {
      await undoAction(slug, isLP);
      item[property] = "no";
      if (property === "liked") {
        item.likes = Math.max(0, item.likes - 1);
      }
    } else {
      await doAction(slug, isLP);
      item[property] = "yes";
      if (property === "liked") {
        item.likes = (item.likes || 0) + 1;
      }
    }
    const updatedList = [...labs];
    updatedList[itemIndex] = item;
    setLabs(updatedList);
  };
  const handleJoinUnjoin = (slug) => doUndoAction(slug, "joined", join, unJoin);

  const handleFavoriteUnFav = (slug) =>
    doUndoAction(slug, "favourite", favorite, unFavorite);

  const onDeleteLab = () => {
    handleDelete(openConfirmationModal);
    setOpenConfirmationModal(false);
  };

  const addToMarketplace = (slug) =>
    doUndoAction(slug, "is_pre_build", add, remove);

  const cancelExitConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const shouldShowProgressTag = (browseMode, joined, isAssociation) =>
    (isAssociation && isTrue(joined)) ||
    (!manage &&
      Boolean(browseMode === 1 || (browseMode !== 2 && isTrue(joined))));

  const isSuperAdmin =
    user?.roles === ROLES.SUPER_ADMIN ||
    user?.roles === ROLES.CUSTOMER_SUCCESS ||
    user?.roles === ROLES.DEVELOPER;

  return (
    <ListingContainer
      loading={loading}
      isEmpty={!labs?.length}
      count={pageCount}
      page={page}
      setPage={setPage}
      classes="mt-4"
      emptyText={t(
        !manage && browseMode === 2
          ? "No pending invitations!"
          : isLP
          ? t("No Lab programs found!")
          : t("No labs found!")
      )}
      noPagination={noPagination}
      emptyView={emptyView}
    >
      {labs?.map((d, i) => {
        const { title, description, privacy, media, slug, media_type } = d;
        return (
          <Grid item key={i} xs={12} sm={6} lg={gridType === 1 ? 12 : 6}>
            <MainProductCard
              classes="mb-3"
              title={
                <div className="flex items-center">
                  {isAssociation && (
                    <img
                      src={isLP ? labProgram : lab}
                      alt="lab"
                      className="w-20 h-20 mr-1"
                    />
                  )}
                  <p
                    className={`text-nowrap overflow-hidden ${
                      gridType === 1 && "maxw-75p"
                    } textOverflow-ellipsis`}
                  >
                    {title}
                  </p>
                  {shouldShowProgressTag(
                    browseMode,
                    isLP ? d?._is_joined : d?.joined,
                    isAssociation
                  ) && (
                    <ProgressTag classes="ml-1" progress={d?.module_progress} />
                  )}
                </div>
              }
              small={smallView}
              subTitle={
                <div className="flex justify-between">
                  <div className="flex-center fs-16 fw-500">
                    <p>{d?.organization ?? "N/A"}</p>
                    {Array.isArray(d?.type) && d?.type.length > 0 && (
                      <>
                        <span className="vertical-align-middle mx-1">
                          &middot;
                        </span>
                        <p className="capitalize">
                          {d?.type.map((item) => t(item)).join("/")}
                        </p>
                      </>
                    )}
                  </div>
                  {manage && !isAssociation && d?.hosted_by?.title && !isLP && (
                    <Chip
                      className="fs-12 c-primary h-20 capitalize mt-1"
                      label={t(
                        d?.source ? d?.source?.split("_")?.join(" ") : "N/A"
                      )}
                    />
                  )}
                </div>
              }
              description={description}
              col={gridType === 2}
              iconsAndValuesList={[
                {
                  icon: LabIcon,
                  value: `${
                    isLP ? d?.lab_count || 0 : d.challenge_count || 0
                  } ${t(isLP ? "Labs" : "Challenges", "common")}`,
                },
                {
                  icon: GroupIcon,
                  value: `${d?.member_count || 0} ${t("Members", "common")}`,
                },
                {
                  icon: EventNoteIcon,
                  value: d?.duration || "N/A",
                },
                {
                  icon: SignalCellularAltIcon,
                  value: d?.level || "N/A",
                },
                {
                  icon: UpdateIcon,
                  value: d?.last_updated
                    ? `${t("Updated")} ${moment(
                        new Date(d?.last_updated)
                      ).fromNow()}`
                    : "N/A",
                },
                {
                  icon: isTrue(privacy) ? VisibilityOff : Visibility,
                  value: t(isTrue(privacy) ? "Private" : "Public", "common"),
                },
              ]}
              type={isLP ? COMPONENT_TYPES.LAB_PROGRAMS : COMPONENT_TYPES.LAB}
              image={media}
              mediaType={media_type ?? "image"}
              id={slug}
              rightAction={
                !!manage && !isAssociation ? (
                  <LabManageOptions
                    data={d}
                    isLP={isLP}
                    onDelete={setOpenConfirmationModal}
                    addToMarketplace={addToMarketplace}
                    isSuperAdmin={isSuperAdmin}
                  />
                ) : browseMode === 3 && isTrue(d?.favourite) ? (
                  <Button
                    bordered
                    color="red"
                    height="30px"
                    classes="fs-14"
                    onClick={() => handleFavoriteUnFav(slug)}
                  >
                    <Delete /> {t("Remove", "common")}
                  </Button>
                ) : browseMode === 0 && !isLP ? (
                  <JoinUnjoinButton
                    handleJoinUnjoin={handleJoinUnjoin}
                    joined={d?.joined}
                    privacy={d?.privacy}
                    slug={d?.slug}
                    t={t}
                  />
                ) : null
              }
              bottomContent={
                <>
                  <div className="flex justify-between mt-1">
                    {!isAssociation && !manage && (
                      <ChipsPreview
                        items={Object.values(d?.skills || {})}
                        limit={3}
                      />
                    )}
                    {!manage && browseMode === 2 ? (
                      <div className="flex justify-end mt-1">
                        <AcceptDeclineButtons
                          onAccept={acceptLab}
                          onDecline={declineLab}
                          id={slug}
                          hasAlternativeComponent={isLP}
                        />
                      </div>
                    ) : null}
                  </div>
                </>
              }
              noSave={
                manage ||
                browseMode === 1 ||
                browseMode === 2 ||
                isTrue(d?.favourite) ||
                noSave ||
                (isLP && isAssociation)
              }
              onSave={() => handleFavoriteUnFav(d?.slug)}
            />
          </Grid>
        );
      })}
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={cancelExitConfirmationModal}
        desc={t(
          `You will delete this ${
            isLP ? "lab program" : "lab"
          } permanently, are you sure?`
        )}
        okayButtonTitle="Delete"
        onOkay={onDeleteLab}
      />
    </ListingContainer>
  );
};

export default LabListing;

const JoinUnjoinButton = ({ privacy, handleJoinUnjoin, slug, joined, t }) => {
  const [isPending, setIsPending] = useState(false);
  const joinUnjoin = () => {
    if (isTrue(privacy) && joined?.toLowerCase() !== "yes") setIsPending(true);
    handleJoinUnjoin(slug);
  };
  return (
    <Button
      onClick={joinUnjoin}
      height={30}
      disabled={isPending || joined === "Pending"}
      bordered
      color={
        isPending || joined === "Pending"
          ? "green"
          : joined?.toLowerCase() === "yes"
          ? "red"
          : "blue"
      }
      width="auto"
      classes={`${
        isPending || joined === "Pending" ? "fs-12 minw-100 ml-1" : "fs-13 minw-70"
      }`}
    >
      {joined === "Pending" || isPending ? (
        <>
          <Done fontSize="small" /> {t("Request Sent")}
        </>
      ) : joined?.toLowerCase() === "yes" ? (
        <>{t("Unjoin", "common")}</>
      ) : (
        <>
          <Add fontSize="small" /> {t("Join", "common")}
        </>
      )}
    </Button>
  );
};
