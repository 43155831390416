import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import useLeaderboard from "store/leaderboard/service-hook";
import useListingHeader from "helpers/hooks/useListingHeader";
import Button from "components/Button";
import Card from "components/Cards/Card";
import MainPageLayout from "pages/layouts/MainPage";
import FilterCard from "components/UI/organisms/FilterCard";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import LeaderBoardTable from "components/UI/organisms/LeaderBoardTable";
import LearningPointRules from "./components/LearningPointRules";
import useRedirect from "helpers/hooks/useRedirect";
import useAuth from "store/auth/service-hook";
import { getLeaderboardJSXCols } from "./data";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { addSpecialFilters } from "helpers/utils/utilities";

const LeaderBoard = () => {
  const { list } = useLeaderboard();
  const [items, setItems] = useState();
  const [totalCount, setTotalCount] = useState();
  const [pages, setPages] = useState(1);
  const { sort, setSort } = useListingHeader(null, "learning_rank");
  const { selected } = useFilters();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(1);
  const { navigateNotifications } = useRedirect();
  const {
    state: { user },
  } = useAuth();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    fetchList();
  }, [selected, sort, page]);

  const fetchList = async () => {
    setLoading(true);
    const filters = {
      sort_by: sort,
      page,
    };
    addSpecialFilters(filters, selected);
    Object.entries(selected).forEach(([key, value]) => {
      filters[key.replace("l_", "")] = value;
    });
    const [_list, _pages, _count] = await list(filters);
    setItems(_list || []);
    setPages(_pages || 1);
    setTotalCount(_count || 0);
    if (page > _pages) {
      setPage(_pages || 1);
    }
    setLoading(false);
  };

  return (
    <DashboardLayout loading={!items}>
      <LearningPointRules open={modal} onClose={() => setModal(false)} />
      <Card data-testid="page-title-card" classes="container mt-header px-0">
        <div className="flex items-center justify-between">
          <h2 className="ml-4 fs-23">{t("Leaderboard")}</h2>
          <div className="flex mr-4">
            <Button classes="mr-2" onClick={() => setModal(true)}>
              {t("Learning Point Rules")}
            </Button>
            <Button onClick={navigateNotifications}>
              {t("Learning Point History")}
            </Button>
          </div>
        </div>
      </Card>
      <MainPageLayout classes="mt-5 mb-6">
        <div className="left">
          <FilterCard
            title="Filter By"
            types={[
              FILTER_TYPES.LEADERBOARD_ORGS,
              FILTER_TYPES.LEADERBOARD_LABS,
              FILTER_TYPES.LEADERBOARD_CHALLENGES,
              FILTER_TYPES.LEADERBOARD_PROJECTS,
            ]}
          />
        </div>
        <div className={`right`}>
          <LeaderBoardTable
            sort={sort}
            username={user?.username}
            totalCount={totalCount}
            setSort={setSort}
            page={page}
            setPage={setPage}
            pageCount={pages}
            rows={items}
            columns={getLeaderboardJSXCols(t)}
            loading={loading}
          />
        </div>
      </MainPageLayout>
    </DashboardLayout>
  );
};

export default LeaderBoard;
