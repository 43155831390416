import Button from "components/Button";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import React from "react";
import styles from "../ui.module.scss";

const CreateFormTitle = ({
  title,
  classes,
  button,
  onButtonClick,
  btnColor,
  bordered,
  titleLeft,
}) => {
  const { t } = useLocalizedTranslation();
  return (
    <div
      data-testid="create-form-title"
      className={`w-full text-center relative ${classes}`}
    >
      <h2
        className={`flex-1 ${
          titleLeft ? "text-left" : "text-center"
        } capitalize`}
      >
        {t(title)}
      </h2>
      {!!button && (
        <Button
          classes={styles.title__btn}
          color={btnColor}
          bordered={bordered}
          onClick={onButtonClick}
        >
          {button}
        </Button>
      )}
    </div>
  );
};

export default CreateFormTitle;
