import { Chip, Grid, IconButton } from "@mui/material";
import Card from "components/Cards/Card";
import React, { useState, useEffect } from "react";
import ListingContainer from "components/UI/organisms/ListingContainer";
import moment from "moment";
import SecureImage from "components/UI/atoms/SecureImage";
import SearchAndSort from "./SearchAndSort";
import useRedirect from "helpers/hooks/useRedirect";
import { isTrue } from "helpers/utils/validators";
import EmptyText from "components/UI/atoms/EmptyText";
import { getAchievementTypeKey } from "pages/achievements/helpers";
import trophy from "assets/png/goldTrophy.png";
import PinButton from "components/UI/atoms/PinButton";

const Achievements = ({
  achievements,
  itsMe,
  handleSetFeatured,
  profileVisibility,
  t = (d) => d,
}) => {
  const [list, setList] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [sortOption, setSortOption] = useState("Alphabetical");
  const [currentPage, setCurrentPage] = useState(1);
  const { navigateAchievement } = useRedirect();
  const itemsPerPage = 8;

  useEffect(() => {
    if (!achievements) return;
    setList([...achievements]);
  }, [achievements]);

  const getSortedAchievements = () => {
    const sortedList = [...list];
    sortedList.sort((a, b) => {
      switch (sortOption) {
        case "Alphabetical":
          return a?.microcopy?.localeCompare(b?.microcopy);
        case "Most Recent":
          const dateA = new Date(a.issue_date);
          const dateB = new Date(b.issue_date);
          return dateB - dateA;
        default:
          return 0;
      }
    });
    return sortedList;
  };

  return (
    <Card classes="mt-4">
      {!itsMe && profileVisibility === "private" ? (
        <EmptyText>This user'profile is private.</EmptyText>
      ) : (
        <>
          <div className="flex items-center justify-between mb-3">
            <h2>{t("Achievements")}</h2>
          </div>
          {list && list?.length > 0 && (
            <SearchAndSort
              searchText={searchText}
              setSearchText={setSearchText}
              sortOption={sortOption}
              setSortOption={setSortOption}
              t={t}
            />
          )}
          <ListingContainer
            isEmpty={
              !list ||
              !list.length ||
              getSortedAchievements().filter(
                (d) =>
                  !searchText ||
                  d.title
                    .toLowerCase()
                    .includes(searchText.trim().toLowerCase())
              ).length === 0
            }
            page={currentPage}
            setPage={setCurrentPage}
            count={Math.ceil(list?.length / itemsPerPage)}
            classes="mt-5"
            noPagination={list?.length <= 8}
            emptyView={
              <EmptyText>
                {t(
                  list &&
                    list?.length > 0 &&
                    getSortedAchievements().filter(
                      (d) =>
                        !searchText ||
                        d.microcopy
                          .toLowerCase()
                          .includes(searchText.trim().toLowerCase())
                    ).length === 0
                    ? "No result found, please try different keywords"
                    : itsMe
                    ? "Join Challenges or Labs to obtain Achievements"
                    : "This user has not obtained any Achievements"
                )}
              </EmptyText>
            }
          >
            <Grid container xs={12} item spacing={1}>
              {getSortedAchievements()
                .filter(
                  (d) =>
                    !searchText ||
                    d.microcopy
                      .toLowerCase()
                      .includes(searchText.trim().toLowerCase())
                )
                ?.slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((d, i) => (
                  <Grid item xs={12} sm={6} key={i}>
                    <AcheivementCard
                      title={d?.microcopy}
                      type={getAchievementTypeKey(d?.achievement_type)}
                      project={d?.project_title}
                      awardedOn={d?.issue_date}
                      image={d?.image}
                      onClick={() => navigateAchievement(d?.id)}
                      isFeatured={isTrue(d?.is_featured)}
                      onSetFeatured={() =>
                        handleSetFeatured(d?.id, isTrue(d?.is_featured))
                      }
                      itsMe={itsMe}
                      t={t}
                    />
                  </Grid>
                ))}
            </Grid>
          </ListingContainer>
        </>
      )}
    </Card>
  );
};

export default Achievements;

const AcheivementCard = ({
  title,
  type,
  awardedOn,
  project,
  image,
  onClick,
  isFeatured,
  onSetFeatured,
  itsMe,
  t = (d) => d,
}) => {
  return (
    <Card classes="border flex items-center relative overflowX-hidden" noShadow>
      <SecureImage
        placeholder={trophy}
        src={image}
        classes="h-80 w-80 rounded-100 object-cover"
      />
      <div className="ml-2 flex-1 maxw-320">
        <h3 className="link fs-18 text-truncate w-300" onClick={onClick}>
          {title || type}
        </h3>
        <h4 className="fs-15 text-truncate">{project}</h4>
        <p className="fs-13 opacity-90">
          {t("Awarded on")} {moment(awardedOn).format("MMM DD, YYYY")}
        </p>
        <Chip className="fw-500 px-1 py-1 mt-1" size="small" label={type} />
        {itsMe && (
          <PinButton
            classes="absolute top-2 right-2"
            onClick={onSetFeatured}
            isPinned={isFeatured}
          />
        )}
      </div>
    </Card>
  );
};
