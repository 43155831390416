import { Search } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import Input from "components/Input";
import Select from "components/Input/Select";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import { SORT } from "helpers/utils/constants";
import React, { useEffect, useState } from "react";
import GridSwitch from "../atoms/GridSwitch";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import cx from "classnames";

const Wrapper = ({ noCard, children, classes }) => {
  if (noCard) return children;
  return <Card classes={classes}>{children}</Card>;
};

const ListingPageHeader = ({
  gridType,
  setGridType,
  search = "",
  sort = "",
  setSearch = Function.prototype,
  setSort = Function.prototype,
  title,
  noCard,
  noGrid,
  classes = "",
  sortOptions,
}) => {
  const [text, setText] = useState("");
  const windowWidth = useWindowWidth();
  const onMobile = windowWidth < 700;
  const { t } = useLocalizedTranslation("common", true);

  useEffect(() => {
    if (!text.trim().length && search.length) setSearch("");
  }, [text]);

  return (
    <Wrapper noCard={noCard} classes={classes}>
      <Grid
        data-testid="listing-page-header"
        className={cx(noCard && classes)}
        container
      >
        <Grid item xs={12} sm={6} md={6} className="flex items-center">
          {!!title ? (
            <h3>{title}</h3>
          ) : (
            <>
              <Input
                width="96%"
                placeholder="Search"
                height={38}
                icon={<Search />}
                classes={onMobile ? "w-80p" : "w-60p"}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && setSearch(text)}
                t={t}
              />
              <Button
                classes="ml-1"
                height={37}
                onClick={() => setSearch(text)}
              >
                {t("Search", "common")}
              </Button>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className={`flex items-center ${
            !onMobile ? "justify-end mt-1" : "justify-start mt-3"
          }`}
        >
          <p className="fs-14 opacity-50 mr-1 text-nowrap ">
            {t("Sort By", "common")}
          </p>
          <Select
            borderedIcon
            value={sort}
            onChange={(e) => setSort(e.target.value)}
            width="100%"
            keyValues={sortOptions || SORT}
            height={40}
            classes={onMobile ? "w-80p" : "w-60p"}
            t={t}
          />
          {!noGrid && (
            <GridSwitch gridType={gridType} setGridType={setGridType} />
          )}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ListingPageHeader;
