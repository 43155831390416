import { Settings, Edit, DeleteForever } from "@mui/icons-material";
import ImageCard from "components/Cards/ImageCard";
import MainPageLayout from "pages/layouts/MainPage";
import MainTabs from "components/UI/organisms/MainTabs";
import React, { useEffect, useState } from "react";
import PublicDescription from "components/UI/organisms/PublicDescription";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import useRedirect from "helpers/hooks/useRedirect";
import Discussions from "components/UI/organisms/Discussions";
import Card from "components/Cards/Card";
import Members from "components/UI/molecules/Members";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { isTrue } from "helpers/utils/validators";
import AssociateChallengeListing from "pages/associateListings/AssociateChallengeListing";
import RelatedContent from "pages/associateListings/RelatedContent";
import { ENDPOINTS } from "store/api/endpoints";
import { useParams } from "react-router-dom";
import MenuButton from "components/Button/MenuButton";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import useChallengePaths from "store/challenge-paths/service-hook";
import useAuth from "store/auth/service-hook";
import { NotLoggedInTabs } from "pages/organization";
import { convertToKeyValueArray } from "helpers/utils/utilities";
import useAssociation from "store/association";

const challengepath_tabs = ["Challenges", "Discussion", "Members"];

const ChallengePathMainPage = ({
  isPrivate,
  challengePath,
  setChallengePath,
}) => {
  const { t } = useLocalizedTranslation();
  const [tab, setTab] = useState(0);
  const { navigateEditChallengePath, navigateManageChallengePaths } =
    useRedirect();
  const { id } = useParams();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { deleteChallengePath } = useChallengePaths();
  const { fetchAssociation } = useAssociation();
  const [associatedChallenges, setAssociatedChallenges] = useState([]);
  const {
    state: { isLoggedIn },
  } = useAuth();

  const onDeleteChallengePath = async (slug) => {
    await deleteChallengePath(slug);
    navigateManageChallengePaths();
    setOpenConfirmationModal(false);
  };

  useEffect(() => {
    if (tab === 1 || tab === 2) {
      fetchAssociatedChallenge();
    }
  }, [tab]);

  const fetchAssociatedChallenge = async () => {
    const [challengesModule] = await fetchAssociation(
      COMPONENT_TYPES.CHALLENGE_PATH,
      id,
      COMPONENT_TYPES.CHALLENGE
    );
    setAssociatedChallenges(challengesModule);
  };

  return (
    <>
      <div className="flex-reverse container mt-header pt-1">
        {isPrivate && (
          <MenuButton
            listWithTitleonClick={[
              {
                divider: true,
              },
              {
                title: "Edit",
                onClick: () => navigateEditChallengePath(challengePath?.slug),
                icon: Edit,
              },
              {
                title: "Delete",
                onClick: () => setOpenConfirmationModal(true),
                icon: DeleteForever,
                iconClasses: "c-error",
                classes: "c-error",
              },
            ]}
            classes="fs-1 fs-13"
            height="30px"
            bordered
          >
            <Settings fontSize="small" /> {t("Manage", "common")}
          </MenuButton>
        )}
      </div>
      <MainPageLayout classes="my-3" firstLgGridSize={4} secondLgGridSize={8}>
        <div className="left">
          <ImageCard
            image={challengePath?.media}
            height="250px"
            type={COMPONENT_TYPES.CHALLENGE_PATH}
            title={challengePath?.title}
          />
          <PublicDescription
            data={challengePath}
            component={COMPONENT_TYPES.CHALLENGE_PATH}
            hideHostedBy
            hideRequirements
            showProgress={isTrue(challengePath?.joined)}
            hideSocialLinks
            hideDescription
            isPrivate={isPrivate}
          />
        </div>
        <div className="right">
          <MainTabs
            current={tab}
            setCurrent={setTab}
            tabs={challengepath_tabs}
          />
          {!isLoggedIn ? (
            <NotLoggedInTabs title={`Login to view more details`} />
          ) : (
            <>
              {tab === 0 && (
                <AssociateChallengeListing
                  parent={COMPONENT_TYPES.CHALLENGE_PATH}
                  slug={challengePath?.slug}
                  isPrivateView={isPrivate}
                  isChallengePathDetail
                  isSequential={isTrue(challengePath?.is_sequential)}
                />
              )}
              {tab === 1 && (
                <Card classes="my-4 px-6">
                  <h2 className="mb-2">{t("Discussion")}</h2>
                  <Discussions
                    component={COMPONENT_TYPES.CHALLENGE_PATH}
                    slug={id}
                    selectOptions={[
                      {
                        value: challengePath?.title,
                        key: challengePath?.slug,
                      },
                      ...convertToKeyValueArray(
                        associatedChallenges,
                        "slug",
                        "title"
                      ),
                    ]}
                    showSelect
                  />
                </Card>
              )}
              {tab === 2 && (
                <Members
                  component={COMPONENT_TYPES.CHALLENGE_PATH}
                  dataList={associatedChallenges}
                />
              )}
            </>
          )}

          <RelatedContent
            component={ENDPOINTS.CHALLENGE_PATH}
            title={t("More Challenge Paths")}
            classes="mt-4"
            slug={challengePath?.slug}
          />
        </div>
      </MainPageLayout>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        desc={t("Are you sure you want to delete this Challenge path?")}
        okayButtonTitle={t("Delete Challenge path")}
        onOkay={() => onDeleteChallengePath(challengePath?.slug)}
      />
    </>
  );
};

export default ChallengePathMainPage;
