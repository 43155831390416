import React, { useEffect } from "react";
import Card from "components/Cards/Card";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import CreateFormFooter from "components/UI/atoms/CreateFormFooter";
import { toast } from 'react-toastify';
import { isTrue } from "helpers/utils/validators";

const AIForm = ({
    step,
    loading,
    data,
    errors,
    onChange,
    stepSchema,
    onCancel,
    onContinue,
    stepValidate,
    optionSelected,
    createAISteps,
    setCreateAISteps,
}) => {

    const openai_schema = data?.resource_module_openai && [7, 8];
    const go1_schema = data?.resource_module_go1 && [9, 10];

    // Limiting form fields
    const limitedOnChange = (e) => {
        if ((e?.target.name === "skills" || e?.target.name === "jobs") && e?.target.value.length > 3) {
            toast.warn('You can only select up to 3 items.');
        } else if ((e?.target.name === "duration_id" || e?.target.name === "level_id") && e?.target.value.length > 1) {
            toast.warn('You can only select up to 1 item.');
        } else if (e?.target.name === "go1_resource_module_types" && e?.target.value.length > 3) {
            toast.warn('You can only select up to 3 items.');
        } else {
            onChange(e);
        }
    };

    // Add & Remove resource module step
    useEffect(() => {
        const newSteps = [...createAISteps];
        const stepToAdd = "Choose your Resource Modules";

        if ((data?.resource_module_prepr || data?.resource_module_openai || data?.resource_module_go1) && data?.resource_modules === "yes") {
            if (!newSteps.includes(stepToAdd)) {
                newSteps.push(stepToAdd);
            }
        } else {
            const index = newSteps.indexOf(stepToAdd);
            if (index !== -1) {
                newSteps.splice(index, 1);
            }
        }

        setCreateAISteps(newSteps);
    }, [data?.resource_module_prepr, data?.resource_module_openai, data?.resource_module_go1, data?.resource_modules]);

    return (
        <div className="left relative">
            <div className={(step === 1 || step === 2 || loading) ? 'gray-cloud' : ''} />
            <Card centered width="min(1600px,100%)">
                <div className={`form-field ${(loading || step !== 0) ? 'no-select-cloud' : ''}`}>
                    <SchemaUIExtractor
                        schema={stepSchema().slice(0, 5)}
                        data={data}
                        errors={errors}
                        onChange={limitedOnChange}
                    />
                    {isTrue(data?.resource_modules) &&
                        <>
                            <SchemaUIExtractor
                                schema={stepSchema().slice(5, 6)}
                                data={data}
                                errors={errors}
                                onChange={limitedOnChange}
                            />
                            <div className="text-and-select">
                                <SchemaUIExtractor
                                    schema={stepSchema().slice(6, 7)}
                                    data={data}
                                    errors={errors}
                                    onChange={limitedOnChange}
                                />
                                {openai_schema &&
                                    <SchemaUIExtractor
                                        schema={stepSchema().slice(...openai_schema)}
                                        data={data}
                                        errors={errors}
                                        onChange={limitedOnChange}
                                    />
                                }
                            </div>
                            <div className="text-and-select">
                                <SchemaUIExtractor
                                    schema={stepSchema().slice(8, 9)}
                                    data={data}
                                    errors={errors}
                                    onChange={limitedOnChange}
                                />
                                {go1_schema &&
                                    <SchemaUIExtractor
                                        schema={stepSchema().slice(...go1_schema)}
                                        data={data}
                                        errors={errors}
                                        onChange={limitedOnChange}
                                    />
                                }
                            </div>
                        </>
                    }
                    <SchemaUIExtractor
                        schema={stepSchema().slice(10, 11)}
                        data={data}
                        errors={errors}
                        maxlength={1}
                        onChange={limitedOnChange}
                    />
                </div>
                <div className="relative z-10">
                    <CreateFormFooter
                        step={step}
                        stepsLength={createAISteps.length - 1}
                        onCancel={onCancel}
                        onOk={onContinue}
                        loading={loading}
                        disabled={!!stepValidate(errors).size || loading || (step === 1 && optionSelected.length === 0)}
                        okButtonColor={(!!stepValidate(errors).size || loading || (step === 1 && optionSelected.length === 0)) ? "gray" : "blue"}
                        backButtonColor={loading ? "gray" : "blue"}
                        disableBack={loading}
                        backgroundBack="#FFF"
                    />
                </div>
            </Card>
        </div>
    );
};

export default AIForm;

