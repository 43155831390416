import RingAvatar from "components/UI/atoms/RingAvatar";
import { COLORS } from "helpers/utils/constants";
import msIcon from "assets/png/icons/ms-icon.png";
import linkedinIcon from "assets/png/icons/linkedin-icon.png";
import googleIcon from "assets/png/icons/google-icon.png";
import appleIcon from "assets/png/icons/apple-icon.png";
import mIcon from "assets/png/icons/m-icon.png";
import project from "assets/svg/yellow/project.svg";
import { users, user, lab, chatForum } from "assets/svg/blue";
import { heart } from "assets/svg";
import { isTrue } from "helpers/utils/validators";

const getColor = (rowData) => {
  if (isTrue(rowData?.ownerProfile)) {
    return COLORS.BLUE;
  } else if (rowData.position === 1) {
    return "#FFD066";
  } else if (rowData.position === 2) {
    return COLORS.DARK_GRAY;
  } else if (rowData.position === 3) {
    return COLORS.DARK_RED;
  } else {
    return COLORS.GRAY;
  }
};

export const getLeaderboardJSXCols = (t = (d) => d) => [
  {
    id: 1,
    title: "#",
    width: "5%",
    render: (rowData) => (
      <div
        className={`w-30 h-30 flex justify-center items-center c-white rounded-100`}
        style={{ backgroundColor: getColor(rowData) }}
      >
        <p className="fs-12 fw-500 tracking-tight">{rowData.position}</p>
      </div>
    ),
  },
  {
    id: 2,
    title: t("User name"),
    width: window.innerWidth > 500 ? "35%" : "150px",
    render: (rowData) => {
      return (
        <div className="flex items-center">
          <RingAvatar img={rowData.profile_image} color={getColor(rowData)} />
          <div className="ml-2">
            <p
              className={`text-nowrap fs-14 capitalize ${
                isTrue(rowData?.ownerProfile) && "fw-500"
              }`}
            >
              {rowData?.full_name} {isTrue(rowData?.ownerProfile) && "(You)"}
            </p>
          </div>
        </div>
      );
    },
  },
  {
    id: 3,
    title: <p className="text-center">{t("Learning Points")}</p>,
    width: "20%",
    render: (rowData) => (
      <p className={isTrue(rowData?.ownerProfile) && "fw-500"}>
        {rowData.learning_points || "0"}
      </p>
    ),
  },
  {
    id: 4,
    title: <p className="text-center">{t("Learning Rank")}</p>,
    width: "20%",
    render: (rowData) => (
      <p className={isTrue(rowData?.ownerProfile) && "fw-500"}>
        {rowData.learning_rank}
      </p>
    ),
  },
  {
    id: 5,
    title: <p className="text-center">{t("Achievements")}</p>,
    width: "20%",
    render: (rowData) => (
      <p className={isTrue(rowData?.ownerProfile) && "fw-500"}>
        {rowData.achievement_points || "0"}
      </p>
    ),
  },
];

export const LEARNING_POINT_RULES = {
  "Daily Activity": [
    {
      points: 10,
      icon: user,
      description: "Daily Login",
    },
  ],
  "Other Activities": [
    {
      points: 50,
      icon: lab,
      description: "Join a Lab",
    },
    {
      points: 20,
      icon: project,
      description: "Create a Project",
    },
    {
      points: 50,
      icon: users,
      description: "Invite members to a Project",
    },
    {
      points: 50,
      icon: project,
      description: "Submit a Project",
    },
    {
      points: 150,
      icon: project,
      description: "Successful Project submission",
    },
    {
      points: 20,
      icon: heart,
      description: "Like a Project",
    },
    {
      points: 20,
      icon: chatForum,
      description: "Post a comment",
    },
    {
      points: 20,
      icon: chatForum,
      description: "Reply to a comment",
    },
  ],
  "Authentication Integrations": [
    {
      points: 100,
      icon: msIcon,
      description: "Microsoft Account",
      id: "microsoft",
    },
    {
      points: 100,
      icon: linkedinIcon,
      description: "LinkedIn Account",
      id: "linked-in",
    },
    {
      points: 100,
      icon: googleIcon,
      description: "Google Account",
      id: "google",
    },
    {
      points: 100,
      icon: appleIcon,
      description: "Apple Account",
      id: "apple",
    },
    {
      points: 100,
      icon: mIcon,
      description: "Magnet Account",
      id: "magnet",
    },
  ],
};
