const IconText = ({ icon, text, classes }) => {
  return (
    <div className="flex-center mt-1 fs-26 c-primary" data-testid="icon-text">
      {icon}
      <span className={`ml-1 fs-14 fw-500 c-black tracking-tight ${classes}`}>{text}</span>
    </div>
  );
};

export default IconText;
