import { Warning } from "@mui/icons-material";
import Button from "components/Button";
import React from "react";
import ModalLayout from ".";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { COLORS } from "helpers/utils/constants";
import { Icon } from "@mui/material";

const ConfirmationModal = ({
  open,
  onClose,
  heading,
  desc,
  onOkay,
  hideHeading = false,
  okayButtonTitle,
  color,
  icon,
}) => {
  const { t } = useLocalizedTranslation("common", true);
  return (
    <ModalLayout
      open={open}
      onClose={onClose}
      width="min(90%,600px)"
      top="mt-24"
      wrapperClasses="rounded-10"
    >
      <div
        data-testid="confirmation-modal"
        className="w-full column-center py-6"
      >
        <Icon
          component={icon || Warning}
          className="rounded-30 my-6"
          sx={{ fontSize: 120, color: color || COLORS.ORANGE }}
        />
        {!hideHeading && (
          <h1 className="text-center mt-2 fs-26">
            {heading ? heading : t("Are you sure?")}
          </h1>
        )}
        <p className="mt-1 fs-16 opacity-60 fw-500 w-90p text-center">
          {t(desc)}
        </p>
        <div className="w-full flex justify-around mt-6">
          <Button
            height="40px"
            color="gray"
            width="150px"
            bordered
            onClick={onClose}
          >
            {t("Cancel")}
          </Button>
          <Button
            color={!!color ? color === COLORS.BLUE && "blue" : "yellow"}
            height="40px"
            classes="minw-180"
            onClick={onOkay}
          >
            {t(okayButtonTitle) || t("Okay")}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ConfirmationModal;
