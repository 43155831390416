import Card from "components/Cards/Card";
import Select from "components/Input/Select";
import CreationOptionsModal from "components/Modal/CreateOptionsModal";
import EmptyText from "components/UI/atoms/EmptyText";
import NuxLayout from "components/UI/molecules/NuxLayout";
import { useTranslation } from "react-i18next";
import ChallengeListing from "pages/challenges/listing/ChallengeListing";
import {
  getChallengeNUX,
  useChallengeOptions,
} from "pages/challenges/listing/ManageChallenges";
import { NotLoggedInTabs } from "pages/organization";
import ChallengeBuilder from "pages/resources/ResourceModule/forms/ChallengeBuilder";
import React, { useEffect, useState } from "react";
import { ENDPOINTS } from "store/api/endpoints";
import useAssociation from "store/association";
import useAuth from "store/auth/service-hook";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import ChallengeTemplate from "pages/challenges/components/ChallengeTemplates";

const AssociateChallengeListing = ({
  isPrivateView,
  slug,
  smallView,
  parent,
  emptyView,
  challengesList,
  isChallengePathDetail = false,
  showSwitch,
  isResourceCollDetail,
  defaultSelected = "challenge",
  isTemplate,
  isSequential,
}) => {
  const [challenges, setChallenges] = useState([]);
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState(defaultSelected);
  const [sequentialProgressIndex, setSequentialProgressIndex] = useState(0);
  const { t } = useTranslation("common");
  const {
    state: { isLoggedIn },
  } = useAuth();
  const { fetchAssociation } = useAssociation();
  const challengeOptions = useChallengeOptions(setModal);
  const { navigateCreateChallengePath } = useRedirect();
  useEffect(() => {
    handleFetchAssociation();
  }, [page, slug, selected]);

  const handleFetchAssociation = async () => {
    if (challengesList) {
      setChallenges(challengesList);
      return;
    }
    setLoading(true);
    const res = await fetchAssociation(parent, slug, selected, {
      page,
    });
    const [list, totalPages, rest = {}] = res;
    setChallenges(list || []);
    setPages(totalPages || 1);
    setLoading(false);
    if (isSequential)
      setSequentialProgressIndex(rest?.sequential_progress_index ?? 0);
  };

  const OPTIONS = [
    { value: "Challenges", key: defaultSelected },
    { value: "Challenge Paths", key: "challenge-path" },
  ];

  const handleModalClose = () => setModal(null);
  return (
    <>
      {!isLoggedIn ? (
        <NotLoggedInTabs
          title={`Login to view challenges in this ${parent
            .split("-")
            .join(" ")}`}
        />
      ) : (
        <>
          {showSwitch && (
            <div className="flex-center justify-between w-100p mt-3">
              <h3 className="ml-1">
                {t(
                  OPTIONS.find((d) => d?.key === selected)?.value ||
                    "Challenges"
                )}
              </h3>
              <Select
                borderedIcon
                placeholder={t("Challenges")}
                width="100%"
                value={selected}
                keyValues={OPTIONS}
                onChange={(e) => setSelected(e.target.value)}
                height={40}
                classes="w-20p w-50p--mob"
                t={(v) => t(v, { ns: "common" })}
              />
            </div>
          )}

          {isPrivateView &&
          !challenges?.length &&
          !loading &&
          parent === COMPONENT_TYPES.ORGANIZATION ? (
            <NuxLayout
              browseMode={selected === COMPONENT_TYPES.CHALLENGE ? 0 : 1}
              newUserData={getChallengeNUX(() =>
                selected === COMPONENT_TYPES.CHALLENGE
                  ? setModal("create")
                  : navigateCreateChallengePath()
              )}
            />
          ) : (
            <ChallengeListing
              isAssociation
              challenges={challenges || []}
              loading={loading}
              gridType={1}
              setChallenges={setChallenges}
              manage={isPrivateView}
              smallView={smallView}
              page={page}
              setPage={setPage}
              pageCount={pages}
              isChallengePath={selected === ENDPOINTS.CHALLENGE_PATH}
              isChallengePathDetail={isChallengePathDetail}
              isResourceCollDetail={isResourceCollDetail}
              isTemplate={isTemplate}
              isSequential={isSequential}
              sequentialProgressIndex={sequentialProgressIndex}
              emptyView={
                emptyView || (
                  <Card classes="flex items-center justify-center w-full text-center ml-4">
                    <EmptyText classes="c-gray w-70p opacity-80">
                      {t(
                        `There is no ${
                          selected === COMPONENT_TYPES.CHALLENGE_TEMPLATE
                            ? "Challenge template"
                            : selected === ENDPOINTS.CHALLENGE_PATH
                            ? "Challenge path"
                            : "Challenge"
                        } associated with this ${parent
                          .split("-")
                          .join(" ")} now, please check back later.`
                      )}
                    </EmptyText>
                  </Card>
                )
              }
            />
          )}
        </>
      )}

      {modal === "create" && (
        <CreationOptionsModal
          open={modal === "create"}
          onClose={handleModalClose}
          title="How Do You Want To Create A New Challenge?"
          options={challengeOptions}
        />
      )}
      {modal === "templates" && (
        <ChallengeTemplate
          open={modal === "templates"}
          onClose={handleModalClose}
        />
      )}
      {modal === "resources" && (
        <ChallengeBuilder
          open={modal === "resources"}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

export default AssociateChallengeListing;
