import { Stack } from "@mui/material";
import React, { useState } from "react";
import Button from "components/Button";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import useRedirect from "helpers/hooks/useRedirect";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const CreateFormFooter = ({
  classes = "",
  step,
  editMode,
  stepsLength,
  middleButtonTitle,
  displayMiddleButton,
  okButtonTitle,
  disabled,
  loading,
  draftLoading,
  middleButtonLoading,
  onCancel = Function.prototype,
  onMiddleButtonClick = Function.prototype,
  onOk = Function.prototype,
  onDraft = Function.prototype,
  disableBack,
  okButtonColor,
  backButtonColor,
  backgroundBack,
  cancelButtonTitle,
  displayDraftButton,
  hideOkButton,
  saveBtnText,
  confirmationOkBtnTitle,
}) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { navigateBack } = useRedirect();
  const { t } = useLocalizedTranslation("common", true);

  const onExit = () => {
    setOpenConfirmationModal(true);
  };

  const cancelExitConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const exitForm = () => {
    navigateBack();
  };

  const buttonText = () => {
    let buttonText;

    if (okButtonTitle) {
      buttonText = t(okButtonTitle, "common");
    } else {
      if (step === stepsLength - 1) {
        if (saveBtnText) {
          buttonText = t(`${saveBtnText}`, "common");
        } else {
          buttonText = t(editMode ? "Save" : "Create", "common");
        }
      } else {
        buttonText = t("Continue", "common");
      }
    }

    return buttonText;
  };

  return (
    <>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={cancelExitConfirmationModal}
        heading={t("Are you sure you want to leave this page?")}
        desc="Any unsaved changes will be lost"
        okayButtonTitle={confirmationOkBtnTitle ?? "Leave Page"}
        onOkay={exitForm}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={`container mb-3 ${
          !classes?.includes("mt-") && "mt-12"
        } ${classes}`}
        width={{ xs: "100%", md: "min(92%,1300px)" }}
      >
        <Button
          disabled={disableBack}
          background={backgroundBack}
          color={backButtonColor || "green"}
          onClick={step === 0 ? onExit : onCancel}
          bordered
          height={42}
          width={150}
          classes="mr-1"
        >
          {!!cancelButtonTitle
            ? t(cancelButtonTitle, "common")
            : step === 0
            ? t("Cancel", "common")
            : t("Back", "common")}
        </Button>
        {displayMiddleButton ? (
          <Button
            onClick={onMiddleButtonClick}
            color="green"
            loading={middleButtonLoading}
            height={42}
            width={150}
          >
            {!!middleButtonTitle
              ? t(middleButtonTitle, "common")
              : t("Save & Exit", "common")}
          </Button>
        ) : null}
        {!hideOkButton && (
          <div className={"flex items-center"}>
            {displayDraftButton && (
              <Button
                onClick={onDraft}
                height={42}
                width={160}
                color={"blue"}
                classes={"mx-2"}
                loading={draftLoading}
              >
                {t("Save Draft")}
              </Button>
            )}

            <Button
              onClick={onOk}
              color={okButtonColor || "green"}
              disabled={disabled}
              loading={loading}
              height={42}
              classes="minw-150"
            >
              {buttonText()}
            </Button>
          </div>
        )}
      </Stack>
    </>
  );
};

export default CreateFormFooter;
