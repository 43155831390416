import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import Card from "components/Cards/Card";
import ProfileCard from "components/Cards/ProfileCard";
import MainPageLayout from "pages/layouts/MainPage";
import {
  Extension,
  Science,
  Factory,
  Settings,
  ExpandMore,
  ChevronRight,
  OpenInNew,
  Bookmark,
} from "@mui/icons-material";
import IconText from "components/UI/atoms/IconText";
import Banner from "components/UI/molecules/Banner";
import MainTabs from "components/UI/organisms/MainTabs";
import { org } from "assets/svg/blue";
import AboutCard from "components/Cards/AboutCard";
import useOrganization from "store/organization/service-hook";
import { useParams, useSearchParams } from "react-router-dom";
import useRedirect from "helpers/hooks/useRedirect";
import UserCard from "components/UI/atoms/UserCard";

import { isTrue } from "helpers/utils/validators";
import useAuth from "store/auth/service-hook";
import { formatAddress } from "components/UI/organisms/GooglePlacesSelect";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { Chip, Stack } from "@mui/material";
import MenuButton from "components/Button/MenuButton";
import Button from "components/Button";
import { PlanUsagePopUp } from "components/UI/molecules/PlanDetails";
import { manageOrgMenuItems } from "./forms/data";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import SecureImage from "components/UI/atoms/SecureImage";
import socialicon from "assets/png/socialicon.png";
import MiniOnboarding from "components/UI/organisms/MiniOnboarding";
import useOnboarding from "store/onboarding/service-hook";
import AssociateLabListing from "pages/associateListings/AssociateLabListing";
import { ENDPOINTS } from "store/api/endpoints";
import AssociateChallengeListing from "pages/associateListings/AssociateChallengeListing";
import AssociateResourceListing from "pages/associateListings/AssociateResourceListing";
import { updateLastLocation } from "store/api/utils";
import { isValidUrl } from "helpers/utils/utilities";

const tabs = ["About Us", "Labs", "Challenges", "Resources"];

const OrganizationMainPage = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [completeOnboarding, setCompleteOnboarding] = useState(false);
  const [openPlanUsage, setOpenPlanUsage] = useState(false);
  const [organization, setOrganization] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [params] = useSearchParams();
  const [hideExternalLinks, setHideExternalLinks] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const {
    getOrganization,
    viewPublicOrganization,
    favorite,
    unFavorite,
    deleteOrganization,
  } = useOrganization();
  const { completeOrgMiniOnboarding } = useOnboarding();
  const {
    state: { isLoggedIn, user },
  } = useAuth();
  const {
    navigateEditOrg,
    navigateMemberManagement,
    navigateManageOrgs,
    navigateOrganizationReports,
    navigateOrgIntegrationSettings,
    navigateOrg,
  } = useRedirect();

  useEffect(() => {
    if (!isLoggedIn) {
      if (params.has("private")) {
        navigateOrg(id, false);
      }
    }
  }, [id, isLoggedIn, params]);

  const { t } = useLocalizedTranslation();

  const handleDelete = async (slug) => {
    await deleteOrganization(slug);
    navigateManageOrgs();
  };
  const toggleList = () => setHideExternalLinks(!hideExternalLinks);

  const doUndoAction = async (property, doAction, undoAction) => {
    const slug = organization?.slug;
    if (!slug || !isLoggedIn) return;
    const did = isTrue(organization[property]);
    if (did) {
      await undoAction(slug);
    } else {
      await doAction(slug);
    }
    organization[property] = did ? "no" : "yes";
    setOrganization({ ...organization });
  };

  const handleFavouriteUnFavourite = () =>
    doUndoAction("favourite", favorite, unFavorite);

  const isPrivateView = params.get("private") === "true";

  useEffect(() => {
    fetchOrganization();
    if (isLoggedIn && !isTrue(user?.is_organization_onboarding))
      setShowOnboarding(true);
  }, []);

  const fetchOrganization = async () => {
    const res =
      isPrivateView && isLoggedIn
        ? await getOrganization(id)
        : await viewPublicOrganization(id);
    if (res) setOrganization(res);
    setLoading(false);
  };

  const handleCloseOnboarding = () => {
    if (completeOnboarding) completeOrgMiniOnboarding();
    setShowOnboarding(false);
  };

  const handleFetchPlanDetails = () => {
    fetchOrganization();
    setOpenPlanUsage(null);
  };

  return (
    <DashboardLayout
      loading={loading}
      isEmpty={!Object.keys(organization).length}
      emptyMessage={t("No organization Found!")}
      show404OnError
    >
      <Banner img={organization?.cover_image} />
      <MainPageLayout>
        <div className="left">
          <ProfileCard
            onEdit={() => navigateEditOrg(id)}
            image={organization.profile_image || org}
            defaultImage={org}
            title={
              organization.title ||
              t("Click on the pencil to edit your Organization profile")
            }
            subTitle={
              isPrivateView && (
                <Chip
                  label={t(
                    organization?.organization_details?.plan_name || "N/A",
                    "common"
                  )}
                  className="mt-2 fs-13"
                />
              )
            }
            shiftTop
            buttons={
              isPrivateView || !isLoggedIn
                ? []
                : [
                    {
                      title: t(
                        isTrue(organization?.favourite) ? "Remove" : "Save"
                      ),
                      onClick: handleFavouriteUnFavourite,
                      icon: Bookmark,
                      bordered: true,
                      props: {
                        color: isTrue(organization?.favourite) ? "red" : "blue",
                      },
                    },
                  ]
            }
            noEdit={!isPrivateView}
          >
            {" "}
            {isPrivateView ? (
              <Stack
                direction="row"
                alignItems="center"
                mt={2}
                className="w-full"
              >
                <Button classes="w-full" onClick={() => setOpenPlanUsage(true)}>
                  {t("View Plan Usage")}
                </Button>
                <MenuButton
                  listWithTitleonClick={manageOrgMenuItems(
                    organization,
                    navigateOrganizationReports,
                    navigateMemberManagement,
                    navigateEditOrg,
                    navigateOrgIntegrationSettings,
                    handleDelete
                  )}
                  classes="fs-13 ml-1"
                  height="35px"
                  width="100%"
                >
                  <Settings className="mr-1" /> {t("Manage", "common")}
                </MenuButton>
              </Stack>
            ) : null}
          </ProfileCard>
          <Card classes="my-3">
            {organization?.website ? (
              <>
                <p>@{organization?.slug}</p>
                <p
                  onClick={() =>
                    window.open(isValidUrl(organization?.website) ? organization?.website : `https://${organization?.website}`)
                  }
                  className="link mt-1 mb-2 small text-truncate cursor-pointer"
                >
                  {organization?.website}
                </p>
              </>
            ) : (
              <p className="c-black fs-14">
                {t("Website info not yet entered")}
              </p>
            )}
            <IconText
              icon={<Extension />}
              text={`${organization?.challenge_count || 0} ${t(
                "Challenges",
                "common"
              )}`}
            />
            <IconText
              icon={<Science />}
              text={`${organization?.lab_count || 0} ${t("Labs", "common")}`}
            />
            <IconText
              icon={<Factory />}
              text={`${organization?.resource_count || 0} ${t(
                "Resources",
                "common"
              )}`}
            />
            <h3 className="mt-2">{t("Employees")}</h3>
            <p>{organization?.total_employees || "-"}</p>
            <h3 className="mt-2">{t("Location")}</h3>
            <p>
              {organization?.organization_address &&
              organization?.organization_address[0]?.latitude &&
              organization?.organization_address[0]?.longitude
                ? formatAddress(organization?.organization_address[0])
                : "-"}
            </p>

            {organization?.external_links?.length > 0 && (
              <>
                <h4 className="flex mt-4">
                  {t("External Links", "common")}
                  <small
                    className="flex fs-12 items-center cursor-pointer"
                    onClick={toggleList}
                  >
                    {!hideExternalLinks ? <ExpandMore /> : <ChevronRight />}
                  </small>
                </h4>
                <Stack spacing={1}>
                  {organization?.external_links?.length &&
                    !hideExternalLinks &&
                    organization?.external_links.map(
                      ({ id, title, link, image }) => (
                        <div className="flex-center mt-1" key={id}>
                          <SecureImage
                            src={image}
                            width={30}
                            height={30}
                            placeholder={socialicon}
                            classes="object-cover rounded-50 cursor-pointer"
                            title={link}
                          />
                          <p
                            onClick={() => window.open(link)}
                            className="flex-center ml-4 link text-truncate"
                          >
                            {title}{" "}
                            <OpenInNew className="ml-1" fontSize="small" />{" "}
                          </p>
                        </div>
                      )
                    )}
                </Stack>
              </>
            )}

            <div className="flex-column mt-1">
              <h3 className="my-2">{t("People")}</h3>
              {organization?.organization_members?.length
                ? organization?.organization_members?.map((p, i) => (
                    <UserCard
                      classes="mt-1"
                      hideGiveAwardButton
                      member={p}
                      key={i}
                    />
                  ))
                : "-"}
            </div>
          </Card>
        </div>
        <div className="right">
          <MainTabs
            classes="mt-4"
            tabs={tabs}
            current={currentTab}
            setCurrent={setCurrentTab}
          />

          {currentTab === 0 && (
            <AboutCard
              about={organization?.about}
              onEdit={() => navigateEditOrg(id)}
              hideEdit={!isPrivateView}
              isPrivateView={isPrivateView}
            />
          )}

          {currentTab === 1 && (
            <AssociateLabListing
              parent={ENDPOINTS.ORGANIZATION}
              slug={organization?.slug}
              smallView
              isPrivateView={isPrivateView}
              showSwitch
            />
          )}

          {currentTab === 2 && (
            <AssociateChallengeListing
              parent={ENDPOINTS.ORGANIZATION}
              slug={organization?.slug}
              smallView
              isPrivateView={isPrivateView}
              showSwitch
            />
          )}
          {currentTab === 3 && (
            <AssociateResourceListing
              parent={ENDPOINTS.ORGANIZATION}
              slug={organization?.slug}
              smallView
              isPrivateView={isPrivateView}
              showSwitch
            />
          )}

          <PlanUsagePopUp
            details={organization}
            open={openPlanUsage}
            onClose={() => setOpenPlanUsage(false)}
            fetchPlanDetails={handleFetchPlanDetails}
          />
        </div>
      </MainPageLayout>
      <MiniOnboarding
        open={showOnboarding}
        onClose={handleCloseOnboarding}
        data={organization}
        component={COMPONENT_TYPES.ORGANIZATION}
        setCompleted={setCompleteOnboarding}
      />
    </DashboardLayout>
  );
};

export default OrganizationMainPage;

export const NotLoggedInTabs = ({ title }) => {
  const { navigateLogin, navigateRegister } = useRedirect();
  const { t } = useLocalizedTranslation("common", true);
  const cacheLocationRedirect = (isRegister) => {
    updateLastLocation(window.location.pathname);
    isRegister ? navigateRegister() : navigateLogin();
  };
  return (
    <Card classes="mt-4 minh-300 flex flex-column items-center justify-center">
      <h4 className="text-center fs-20" style={{ color: "#404252" }}>
        {t(title)}
      </h4>
      <div className="flex mt-6 text-center justify-center">
        <Button
          width="100px"
          height="35px"
          classes="mr-2"
          onClick={() => cacheLocationRedirect()}
        >
          {t("Login")}
        </Button>
        <Button
          width="100px"
          height="35px"
          onClick={() => cacheLocationRedirect(true)}
        >
          {t("Register")}
        </Button>
      </div>
    </Card>
  );
};
