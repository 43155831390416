import React from "react";
import useMaster from "../../../../store/master/service-hook";
import { useAdvanceSearchContext } from "../../hooks/useAdvanceSearchContext";
import SubFilter from "./SubFilter";
import { FilterComponentsList } from "../../data";

const Category = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { fetchCategories } = useMaster();
  const { category, setCategory, ActiveFilterComponents } =
    useAdvanceSearchContext();

  return (
    <SubFilter
      title={t("CATEGORY")}
      fetchData={fetchCategories}
      values={category}
      select
      onChange={(event) => {
        setCategory(
          event?.target?.value && event?.target?.value.length > 0
            ? event?.target?.value
            : []
        );
      }}
      valueKey={"id"}
      labelKey={"title"}
      show={ActiveFilterComponents.includes(FilterComponentsList.category)}
      t={t}
    />
  );
};

export default Category;
