import REQUEST from "../api";

export const mockRequestGet = () => {
  return jest
    .spyOn(REQUEST.prototype, "get")
    .mockResolvedValue({ success: true, data: { data: [{}] }, status: 200 });
};

export const mockRequestPost = (data = {}) => {
  return jest
    .spyOn(REQUEST.prototype, "post")
    .mockImplementation((_endpoint, _body, _headers, onSuccess) => {
      const res = {
        success: true,
        data: { data: { ...data }, success: true },
        status: 200,
      };
      if (onSuccess) onSuccess(res);
      return res;
    });
};

export const mockRequestPut = (data = {}) => {
  return jest
    .spyOn(REQUEST.prototype, "put")
    .mockImplementation((_endpoint, _body, _headers, onSuccess) => {
      const res = {
        success: true,
        data: { data: { ...data }, success: true },
        status: 200,
      };
      if (onSuccess) onSuccess(res);
      return res;
    });
};

export const mockRequestDelete = (data = {}) => {
  return jest
    .spyOn(REQUEST.prototype, "delete")
    .mockImplementation((_endpoint, _body, _headers, onSuccess) => {
      const res = {
        success: true,
        data: { data: { ...data }, success: true },
        status: 200,
      };
      if (onSuccess) onSuccess(res);
      return res;
    });
};
