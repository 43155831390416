import Card from "components/Cards/Card";
import SwipableView from "components/UI/organisms/SwipableView";
import React from "react";

const LiveJobPostings = ({ list, t }) => {
  return (
    <Card classes="my-3 px-2 py-2">
      <h3 className="mt-2">{t("Live Job Postings")}</h3>
      <SwipableView slidesPerView={2} classes="mt-3" xs small>
        {list?.map((job, i) => (
          <Card
            key={i}
            classes="mb-3 border px-1 py-1"
            maxHeight="50p"
            noShadow
          >
            <h5
              className="link mb-1 text-truncate text-nowrap"
              onClick={() => window.open(job?.url)}
            >
              {job?.name}
            </h5>
            <h5 className="text-truncate text-nowrap"> {job?.company}</h5>
            <p className="fs-12 c-gray mt-1 text-truncate text-nowrap">
              {" "}
              {job?.location}
            </p>
            <p className="fs-11 c-gray mt-3 text-truncate text-nowrap">
              {" "}
              {job?.datePosted}
            </p>
          </Card>
        ))}
      </SwipableView>
    </Card>
  );
};

export default LiveJobPostings;
