import { FormControlLabel } from "@material-ui/core";
import { createEvent } from "helpers/utils/utilities";
import styles from "./input.module.scss";
import React from "react";
import { Switch as MaterialSwitch } from "@mui/material";

const Switch = ({
  name,
  onChange,
  value,
  label,
  error,
  labelPlacement,
  disabled,
  title,
  t = (d) => d,
}) => {
  return (
    <div>
      {title && <p className="fs-14 fw-500 opacity-90 mb-1">{t(title)}</p>}
      <FormControlLabel
        labelPlacement={labelPlacement ?? "end"}
        control={
          <MaterialSwitch
            onChange={(e) => onChange(createEvent(name, e.target.checked))}
            name={name}
            required
            defaultChecked={value}
            disabled={disabled}
          />
        }
        label={typeof label === "string" ? t(label) : label}
      />

      {error && <p className={styles.error}>{t(error, "common")}</p>}
    </div>
  );
};

export default Switch;
