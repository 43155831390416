import { Chip } from "@mui/material";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { COLORS } from "helpers/utils/constants";
import React from "react";

const STATUS_TYPES = {
  NOT_STARTED: "not_started",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  SUBMITTED: "submitted",
  ONGOING: "ongoing",
};
const ProgressTag = ({ progress, classes }) => {
  const { t } = useLocalizedTranslation("common", true);
  if (!progress || !progress.status) return null;
  return (
    <>
      <Chip
        className={`capitalize fw-400 fs-12 tracking-tight ${classes}`}
        label={`${t(progress?.status.split("_").join(" "))} ${
          progress?.status === STATUS_TYPES.IN_PROGRESS && progress?.percentage
            ? progress?.percentage + "%"
            : ""
        }`}
        sx={{
          px: 0,
          height: "22px",
          backgroundColor:
            progress?.status === STATUS_TYPES.IN_PROGRESS
              ? COLORS.BLUE_OPAQUE
              : progress?.status === STATUS_TYPES.COMPLETED
              ? COLORS.GREEN_OPAQUE
              : progress.status.toLowerCase() === STATUS_TYPES.SUBMITTED
              ? COLORS.GREEN_OPAQUE
              : progress.status.toLowerCase() === STATUS_TYPES.ONGOING
              ? COLORS.BLUE_OPAQUE
              : COLORS.ORANGE_OPAQUE,
          color:
            progress?.status === STATUS_TYPES.IN_PROGRESS
              ? COLORS.BLUE
              : progress?.status === STATUS_TYPES.COMPLETED
              ? COLORS.GREEN
              : progress.status.toLowerCase() === STATUS_TYPES.SUBMITTED
              ? COLORS.GREEN
              : progress.status.toLowerCase() === STATUS_TYPES.ONGOING
              ? COLORS.BLUE
              : COLORS.ORANGE,
        }}
      />
    </>
  );
};

export default ProgressTag;
