import React from "react";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import Background from "assets/png/bg.png";

const ResourceCourseCard = ({ resource, t = (d) => d }) => {
  const openInNewTab = (url) => {
    const origin = window.origin;
    window.open(`${origin}/${url}`);
  };

  return (
    <div
      className={"resource-course-card"}
      style={{ background: `url(${resource?.cover_image ?? Background})` }}
    >
      <div className={"resource-course-card--overlay"}>
        <h2 className="resource-course-card--overlay--title">
          {resource?.scorm?.title}
        </h2>
        <p
          className="resource-course-card--overlay--play-button"
          onClick={() => openInNewTab(`resource/play-course/${resource?.slug}`)}
        >
          <PlayCircleFilledIcon /> <span>{t("PLAY COURSE")}</span>
        </p>
      </div>
    </div>
  );
};

export default ResourceCourseCard;
