import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useLabs from "store/labs/service-hook";
import LabMainPage from "./components/LabMainPage";

const LabPublicView = () => {
  const [lab, setLab] = useState(null);
  const { id } = useParams();
  const { fetchLab, fetchPublicLab } = useLabs();
  const [params] = useSearchParams();
  const isPrivateView = params.get("private") === "true";

  useEffect(() => {
    handleFetchDetail();
  }, [id]);

  const handleFetchDetail = async () => {
    const res = isPrivateView ? await fetchLab(id) : await fetchPublicLab(id);
    if (!res) {
      setLab({ error: true });
      return;
    }
    setLab(res);
  };

  return (
    <DashboardLayout
      loading={!lab}
      isEmpty={!!lab?.error}
      emptyMessage="Not able to access the lab!"
      show404OnError={lab?.statusCode === 404}
      show403={lab?.statusCode === 403}
    >
      <LabMainPage lab={lab} isPrivate={isPrivateView} setLab={setLab} />
    </DashboardLayout>
  );
};

export default LabPublicView;
