import React from "react";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import DashboardWrapper from "./DashboardWrapper";
import DashboardTable from "./components/DashboardTable";
import ProgressCard from "./components/ProgressCard";
import LeaderboardCard from "./components/LeaderboardCard";
import LeftOffCard from "./components/LeftOffCard";
import ChallengeDeadlinesCard from "./components/ChallengeDeadlinesCard";
import AchievementCard from "./components/AchievementCard";
import Recommendations from "./components/Recommendations";
import Inbox from "./components/Inbox";

const UserDashboard = ({ items, setItems, setInactiveById, isCustomizeMode }) => {
  return (
    <DashboardWrapper
      items={items}
      setItems={setItems}
      isCustomizeMode={isCustomizeMode}
      setInactiveById={setInactiveById}
    />
  );
};

export default UserDashboard;

export const USER_DASH_ITEMS = [
  {
    children: <ProgressCard />,
    size: "sm",
    width: 1,
    id: "reports",
    uuid: "1",
  },
  {
    children: <LeftOffCard />,
    size: "sm",
    width: 1,
    id: "continue-left",
    uuid: "2",
  },
  {
    size: "sm",
    children: <ChallengeDeadlinesCard type="user" />,
    width: 1,
    id: "deadlines",
    uuid: "3",
  },
  {
    title: "Leaderboard",
    children: <LeaderboardCard />,
    size: "md",
    width: 2,
    id: "leaderboard",
    uuid: "4",
  },
  {
    size: "md",
    children: <AchievementCard />,
    width: 1,
    id: "achievement",
    uuid: "5",
  },
  {
    size: "md",
    children: (
      <DashboardTable
        tabs={["My Challenges", "Invited", "Saved"]}
        component={COMPONENT_TYPES.CHALLENGE}
        type="user"
      />
    ),
    width: 1,
    id: "my-challenges",
    uuid: "6",
  },

  {
    size: "md",
    children: (
      <DashboardTable
        tabs={["My Projects", "Invited", "Saved"]}
        component={COMPONENT_TYPES.PROJECT}
        type="user"
      />
    ),
    width: 1,
    id: "my-projects",
    uuid: "7",
  },
  {
    uuid: "8",
    size: "md",
    children: (
      <DashboardTable
        tabs={["My Labs", "Invited", "Saved"]}
        component={COMPONENT_TYPES.LAB}
        type="user"
      />
    ),
    width: 1,
    id: "my-labs",
  },
  {
    id: "my-resources",
    size: "md",
    children: (
      <DashboardTable
        type="user"
        tabs={["My Resources", "Saved"]}
        component={COMPONENT_TYPES.RESOURCE}
      />
    ),
    width: 1,
    uuid: "9",
  },
  {
    id: "inbox-friends",
    children: <Inbox />,
    size: "md",
    width: 1,
    uuid: "10",
  },
  {
    children: <Recommendations classes="px-4" />,
    id: "recommendations",
    width: 1,
    size: "md",
    uuid: "11",
  },
];
