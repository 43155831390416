import React, { useState } from "react";
import ListingContainer from "components/UI/organisms/ListingContainer";
import Card from "components/Cards/Card";
import { Grid } from "@mui/material";
import { CheckCircle, Delete } from "@mui/icons-material";
import { isTrue } from "helpers/utils/validators";

const ResourceLinks = ({
  links = [],
  setAssetComplete,
  onDelete,
  isPrivate,
  t = (d) => d,
}) => {
  const [hoveredItemId, setHoveredItemId] = useState(null);

  return (
    <Card classes="mt-4">
      <ListingContainer
        noPagination
        emptyText="No links found!"
        isEmpty={!links || !links.length}
        classes="minh-300"
        emptySmall
      >
        <Grid
          container
          item
          xs={12}
          rowSpacing={2}
          columnSpacing={3}
          className="h-auto"
        >
          {links?.map((link, i) => (
            <Grid
              key={i}
              item
              xs={12}
              onClick={() => setAssetComplete(link?.id, "links")}
            >
              <div
                className="px-1 flex justify-between h-25"
                onMouseEnter={() => setHoveredItemId(link?.id)}
                onMouseLeave={() => setHoveredItemId(null)}
              >
                <h3
                  onClick={() => window.open(link?.path)}
                  className={`flex-center cursor-pointer fs-16 text-truncate  ${
                    isTrue(link?.completed) ? "c-success" : "c-primary"
                  }`}
                >
                  {isTrue(link?.completed) && (
                    <CheckCircle fontSize="small" className="mr-1 c-success" />
                  )}
                  {link.title}
                </h3>
                {isPrivate && hoveredItemId === link?.id && (
                  <div className="ml-auto cursor-pointer">
                    <Delete
                      color="error"
                      onClick={() => onDelete(link?.id, "links")}
                    />
                  </div>
                )}
              </div>
            </Grid>
          ))}
        </Grid>
      </ListingContainer>
    </Card>
  );
};

export default ResourceLinks;
