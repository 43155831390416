/* eslint-disable no-useless-escape */
/* eslint-disable default-case */
import { isValidUrl } from "helpers/utils/utilities";
export const EMAIL_VALIDATOR = "EMAIL_VALIDATOR";
export const PASSWORD_VALIDATOR = "PASSWORD_VALIDATOR";
export const REQUIRED_VALIDATOR = "REQUIRED_VALIDATOR";
export const CARD_VALIDATOR = "CARD_VALIDATOR";
export const CUSTOM_VALIDATOR = "CUSTOM_VALIDATOR";
export const NUMBER_VALIDATOR = "NUMBER_VALIDATOR";
export const BOOLEAN_VALIDATOR = "BOOLEAN_VALIDATOR";
export const LOCATION_VALIDATOR = "LOCATION_VALIDATOR";
export const URL_VALIDATOR = "URL_VALIDATOR";
export const PHONE_VALIDATOR = "PHONE_VALIDATOR";
export const CHARACTER_VALIDATOR = "CHARACTER_VALIDATOR";
export const TITLE_VALIDATOR = "TITLE_VALIDATOR";
export const USERNAME_EMAIL_VALIDATOR = "USERNAME_EMAIL_VALIDATOR";
export const EDITOR_VALIDATOR = "EDITOR_VALIDATOR";

const validate_email = (email) => {
  const format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return email.match(format);
};

const validate_username = (username) => {
  const format = /^[A-Za-z0-9_]{4,30}$/;
  return format.test(username);
};

const validate_phone = (phone) => {
  if (!phone || (typeof phone === "string" && !phone.trim()?.length)) {
    return true;
  }
  const format = /^\d{10}$/;
  return format.test(phone);
};

const validate_characters = (str) => {
  const format = /^[A-Za-z]+$/;
  return format.test(str);
};

export const hasCapital = (str) => /[A-Z]/.test(str);
export const hasNumber = (str) => /[0-9]/.test(str);
export const minLength = (str, len) => str?.length >= len;
export const lengthValidator = (str, length) => str?.length === length;
export const hasSpecialChar = (str) =>
  /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);

export const Validate = async (type, value, opt = false) => {
  switch (type) {
    case EMAIL_VALIDATOR: {
      if (!value || !value.trim()?.length) return "Email is required";
      else if (!validate_email(value)) return "Email is invalid";
      else return false;
    }
    case NUMBER_VALIDATOR: {
      if ((typeof value === "string" && !value.trim()?.length) || isNaN(value))
        return "This field should be a valid number";
      return false;
    }
    case PASSWORD_VALIDATOR: {
      if (!value || !value.trim()?.length) return "password is required";
      else if (value.trim()?.length < 8)
        return "password should be of minimum 8 characters";
      else if (!hasCapital(value))
        return "password must include a capital letter!";
      else if (!hasNumber(value)) return "password must include a digit!";
      else if (!hasSpecialChar(value))
        return "password must include a special character!";
      else if (opt !== false && value !== opt)
        return "password do not match confirm password!";
      else return false;
    }
    case REQUIRED_VALIDATOR: {
      if (!value || (typeof value === "string" && !value.trim()?.length)) {
        return "This field is required";
      } else if (Array.isArray(value) && value.length === 0) {
        return "select at least one option";
      } else return false;
    }
    case LOCATION_VALIDATOR: {
      if (
        !value ||
        (Array.isArray(value) && !value?.length) ||
        (typeof value === "string" && !value.trim()?.length)
      ) {
        return "Please select a location from the dropdown.";
      } else return false;
    }
    case CARD_VALIDATOR: {
      if (!value || (typeof value === "string" && !value.trim()?.length))
        return "Card number is required";
      if (!lengthValidator(value, 16))
        return "Card number must contain 16 digits only!";
      else return false;
    }
    case CUSTOM_VALIDATOR: {
      if (opt) {
        const error = await opt(value);
        return error;
      }
      return false;
    }
    case BOOLEAN_VALIDATOR: {
      if (typeof value !== "boolean") return "This field is required";
      return false;
    }
    case URL_VALIDATOR: {
      if (!value || !value.trim()?.length || !isValidUrl(value)) {
        return "Please enter a valid URL";
      } else {
        return false;
      }
    }
    case PHONE_VALIDATOR: {
      if (!validate_phone(value)) return "Phone number must be 10 digits";
      return false;
    }
    case CHARACTER_VALIDATOR: {
      if (!value || !value.trim()?.length) return "This field is required";
      else if (!validate_characters(value))
        return "This field should contain only alphabets";
      else return false;
    }
    case TITLE_VALIDATOR: {
      if (!value || !value.trim().length) return "This field is required";
      if (value.length > 100) return "Title must be less than 100 characters";
      if (value.length < 4) return "Title must be more than 4 characters";
      // if (/[^a-zA-Z0-9 ]/.test(value)) return "No special characters allowed";
      return false;
    }
    case EDITOR_VALIDATOR: {
      if (!value || !value?.trim()?.length || value === "<p><br></p>")
        return "This field is required";
      return false;
    }
    case USERNAME_EMAIL_VALIDATOR: {
      if (!value || !value.trim()?.length) return "This field is required";
      if (validate_email(value)) return false;
      if (validate_username(value)) return false;
      return "Please enter a valid email or username";
    }
  }
};

export const newUser = () => {
  return Boolean(window.location.search.includes("new-user=true"));
};

export const isTrue = (value) =>
  typeof value === "boolean"
    ? value
    : Boolean(!!value && value?.toLowerCase() === "yes");

export const dateHasPassed = (date) => {
  const currentDate = new Date().toISOString();
  return date < currentDate;
};
