import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import { CANADA_PROVINCES } from "pages/labs/forms/canada-province";
import Switch from "components/Input/Switch";
import { useTranslation } from "react-i18next";

const CampusConnectIntegration = ({
  data,
  errors,
  onChange,
  formSchema,
  component,
}) => {
  const { t } = useTranslation("labs");
  let schema = formSchema;
  const campusConnectProvinceIndex = schema.findIndex(
    (item) => item.name === "campus_connect_province"
  );

  schema[campusConnectProvinceIndex].options = Object.entries(
    CANADA_PROVINCES
  ).map((item) => {
    return {
      key: item[1].name,
      value: item[1].name,
    };
  });

  const province = useMemo(() => {
    return data?.campus_connect_province
      ? Object.entries(CANADA_PROVINCES).find(
          (item) => item[1].name === data?.campus_connect_province
        )
      : null;
  }, [data?.campus_connect_province]);

  const citySchema = useMemo(() => {
    const city = province ? province[1]?.city : [];
    let obj = [...schema].find((item) => item.name === "campus_connect_city");
    if (obj) {
      const cityarr = city.map((item) => {
        return { key: item, value: item };
      });
      const cityExists = city.includes(data?.campus_connect_city);

      if (province && !cityExists) {
        data.campus_connect_city = null;
      }

      obj.options = cityarr;

      obj.disabled = city.length ? false : true;
    }
    return obj;
  }, [province]);

  const isCampusConnectJob = useMemo(() => {
    return (
      ["job", "both"].includes(data?.integrate_campus_connect) &&
      data?.campus_connect
    );
  }, [data]);

  const isCampusConnectStory = useMemo(() => {
    return (
      ["story", "both"].includes(data?.integrate_campus_connect) &&
      data?.campus_connect
    );
  }, [data]);

  return (
    <div>
      <div className="w-full">
        <div className="flex items-center ">
          <h3 className="pr-20">{t("Campus Connect Integration")}</h3>
          <Switch
            name={schema[0].name}
            value={data[schema[0].name]}
            label={<b>{t("Enabled")}</b>}
            error={errors.get(schema[0].name)}
            onChange={onChange}
          />
        </div>
        <SchemaUIExtractor
          schema={data?.campus_connect ? schema.slice(1, 2) : []}
          data={data}
          errors={errors}
          onChange={onChange}
        />
      </div>

      {isCampusConnectJob && (
        <>
          <div className="mt-4">
            <h3>{t("Job Details")}</h3>
            <p>
              {t(
                "Users can post this ${component} to Campus Connect if this feature is enabled",
                {
                  component: t(component),
                }
              )}
            </p>
            <SchemaUIExtractor
              schema={[...schema.slice(2, 8), schema[8], citySchema]}
              data={data}
              errors={errors}
              onChange={onChange}
            />

            <SchemaUIExtractor
              schema={schema.slice(10, 11)}
              data={data}
              errors={errors}
              onChange={onChange}
            />
          </div>
          <div className="mt-4">
            <h3>{t("Job Qualification")}</h3>
            <p>
              {t(
                "Users can post this ${component} to Campus Connect if this feature is enabled",
                {
                  component: t(component),
                }
              )}
            </p>
            <SchemaUIExtractor
              schema={schema.slice(11, 15)}
              data={data}
              errors={errors}
              onChange={onChange}
            />
          </div>
          <div className="mt-4">
            <h3>{t("Application Details")}</h3>
            <p>
              {t(
                "Users can post this ${component} to Campus Connect if this feature is enabled",
                {
                  component: t(component),
                }
              )}
            </p>
            <SchemaUIExtractor
              schema={
                data?.campus_connect_preferred_response !== "Via Email"
                  ? schema
                      .slice(15, 20)
                      .filter(
                        (item) =>
                          item.name !== "campus_connect_application_email"
                      )
                  : schema.slice(15, 20)
              }
              data={data}
              errors={errors}
              onChange={onChange}
            />
          </div>
        </>
      )}

      {isCampusConnectStory && (
        <>
          <div className="mt-4">
            <h3>{t("Story Details")}</h3>
            <p>
              {t(
                "Users can post this ${component} to Campus Connect if this feature is enabled",
                {
                  component: t(component),
                }
              )}
            </p>
            {!isCampusConnectJob ? (
              <SchemaUIExtractor
                schema={[schema[20], schema[18], schema[21]]}
                data={data}
                errors={errors}
                onChange={onChange}
              />
            ) : (
              <SchemaUIExtractor
                schema={schema.slice(20, 22)}
                data={data}
                errors={errors}
                onChange={onChange}
              />
            )}
          </div>
          <div className="mt-4">
            <h3>{t("Add Media to Story")}</h3>
            <p>
              {t("Users can add media to the story and post to Campus Connect")}
            </p>
            <SchemaUIExtractor
              schema={schema.slice(22, 23)}
              data={data}
              errors={errors}
              onChange={onChange}
            />
            <Grid container columnSpacing={3}>
              <Grid item xs={4}>
                <SchemaUIExtractor
                  schema={schema.slice(23, 24)}
                  data={data}
                  errors={errors}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={6}>
                {data?.campus_connect_story_media_type === "IMAGE" && (
                  <>
                    <SchemaUIExtractor
                      schema={schema.slice(24, 26)}
                      data={data}
                      errors={errors}
                      onChange={onChange}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </div>
          {data?.campus_connect_story_media_type === "VIDEO" && (
            <>
              <SchemaUIExtractor
                schema={schema.slice(26, 27)}
                data={data}
                errors={errors}
                onChange={onChange}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CampusConnectIntegration;
