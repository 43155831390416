import { CheckCircle } from "@mui/icons-material";
import PasswordValidator from "components/PasswordValidator";
import PassowordInput from "components/UI/atoms/PassowordInput";
import AuthCard from "components/UI/organisms/AuthCard";
import useForms from "helpers/hooks/useForms";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useRedirect from "helpers/hooks/useRedirect";
import {
  PASSWORD_VALIDATOR,
  REQUIRED_VALIDATOR,
} from "helpers/utils/validators";
import AuthLayout from "pages/layouts/AuthLayout";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "store/auth/service-hook";

const PASSWORD_INPUTS = [
  {
    name: "newPassword",
    title: "New Password",
    validator: PASSWORD_VALIDATOR,
    optinalValidatorKey: "confirmPassword",
  },
  {
    type: "validator",
  },
  {
    name: "confirmPassword",
    title: "Confirm New Password",
    validator: REQUIRED_VALIDATOR,
  },
];

const VerifyOTP = () => {
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams, setParams] = useSearchParams();
  const [isResetPassword, setResetPassword] = useState(false);
  const { verifyOtp, resetPassword, resendOtp, resetPassVerifyOtp } = useAuth();
  const [step, setStep] = useState(1);
  const [isMFA, setIsMFA] = useState(false);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [verifyError, setVerifyError] = useState(null);
  const { navigateBack, navigateLogin } = useRedirect();
  const { t } = useLocalizedTranslation();

  const { data, errors, validate, onChange } = useForms(PASSWORD_INPUTS);

  const token = searchParams.get("token");

  useEffect(() => {
    const paramEmail = decodeURIComponent(searchParams.get("email"));
    const paramMfa = searchParams.get("mfa");
    const paramResetPassword = searchParams.get("reset-password");
    const paramRegisterSuccess = searchParams.get("registerSuccess");
    if (paramResetPassword) setResetPassword(true);
    setEmail(paramEmail);
    setIsMFA(paramMfa === "true");
    setIsRegisterSuccess(paramRegisterSuccess === "true");
  }, []);

  const handleVerifyOtp = async () => {
    if (isResetPassword && step === 1) {
      setLoading(true);
      const res = await resetPassVerifyOtp(otp, email);
      if (res?.status === 200 && res?.data?.success === true) {
        searchParams.set("token", res?.data?.data?.token);
        setParams(searchParams);
        toast.success(res?.data?.message);
        setStep(2);
      } else {
        setVerifyError(
          res?.message ||
            res?.data?.message ||
            "Could not verify OTP, Please try again!"
        );
      }
      setLoading(false);
      return;
    }
    if (isResetPassword) {
      const newErrors = await validate();
      if (newErrors.size) return;
    }
    setLoading(true);
    isResetPassword
      ? await resetPassword(email, data, token)
      : await verifyOtp(otp, email, isMFA);
    setLoading(false);
  };

  const disabledSubmit = () => {
    return Boolean(step === 1 && (!otp || otp.length < 4));
  };

  const handleBack = () => {
    if (step === 1) {
      navigateBack();
    } else {
      setStep(1);
    }
  };

  return (
    <AuthLayout sliderHidden>
      <AuthCard
        icon={
          isRegisterSuccess ? (
            <CheckCircle className="c-success mt-4" sx={{ fontSize: 65 }} />
          ) : null
        }
        title={t(
          isRegisterSuccess
            ? "Account created!"
            : step === 1 && isResetPassword
            ? "Email sent!"
            : step === 1
            ? "Enter Verification Code"
            : "Reset Password"
        )}
        description={t(
          isRegisterSuccess
            ? "We have sent a verification email to the your email address. Please enter the verification code before you login."
            : step === 1
            ? verifyError
              ? verifyError
              : "Please enter the verification code sent to your registered email."
            : null
        )}
        descriptionClasses={!!verifyError && "c-error"}
        handleBack={handleBack}
        disableNext={disabledSubmit()}
        handleNext={handleVerifyOtp}
        loading={loading}
        nextButtonTitle={t(
          isRegisterSuccess ? "Sign In" : step === 1 ? "Verify" : "Submit"
        )}
        hideBackButton
        showBack={isResetPassword && step === 2}
        endContent={
          <div className="mt-3 mb-6 flex-center justify-center tracking-tight flex-column">
            {!isRegisterSuccess ? (
              <>
                {step === 1 && (
                  <p className="fs-13 mb-2">
                    {t("Didn’t get a code?")}{" "}
                    <span
                      className="link"
                      onClick={() => resendOtp(email, isResetPassword, isMFA)}
                    >
                      {t("Send a new code")}
                    </span>
                  </p>
                )}
                <p className="fs-13">
                  {t("If you need assistance please contact")}{" "}
                  <span className="link">support@prepr.org</span>
                </p>
                {step === 2 && (
                  <p className="fs-13 mt-4">
                    {t("Back to")}{" "}
                    <span className="link" onClick={navigateLogin}>
                      {t("Login")}
                    </span>
                  </p>
                )}
              </>
            ) : (
              <p className="mt-10 fs-15">
                {t("Did not receive verification email?")}{" "}
                <span className="link" onClick={() => resendOtp(email)}>
                  {t("Resend")}
                </span>
              </p>
            )}
          </div>
        }
      >
        
        <div>
          {step === 1 ? (
            <OtpInput
              value={otp}
              onChange={(_otp) => {
                if (verifyError) setVerifyError(null);
                setOtp(_otp);
              }}
              numInputs={4}
              inputStyle={`otp-input ${
                !!verifyError && "bg-red--opaque border-error"
              }`}
              renderSeparator={<span className="mx-2"></span>}
              renderInput={(props) => <input {...props} />}
            />
          ) : (
            <>
              {isResetPassword &&
                PASSWORD_INPUTS.map(({ name, title, type }, i) =>
                  type === "validator" ? (
                    <PasswordValidator key={i} password={data.newPassword} />
                  ) : (
                    <PassowordInput
                      key={i}
                      title={title}
                      name={name}
                      value={data[name]}
                      onChange={onChange}
                      error={errors.get(name)}
                      classes={i === 0 ? "mt-8" : "mt-2"}
                      t={t}
                    />
                  )
                )}
            </>
          )}
        </div>
      </AuthCard>
    </AuthLayout>
  );
};

export default VerifyOTP;
