import React from "react";
import "./ai.scss";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { resource as resourceImage } from "assets/svg/purple";
import { openai } from "assets/svg/green";
import { leaf4 } from "assets/svg/green";
import { go1 } from "assets/svg"
import { videoPlaceholder } from "assets/png";
import { calendar, chart } from "assets/svg/blue";
import AIText from "./AIText";
import Button from "components/Button";
import { Chip } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { toast } from 'react-toastify';
import { COLORS } from "helpers/utils/constants";

const ResourceModulePreview = ({
    resourcesState,
    resourceExpanded,
    handleResourcePanelChange,
    resourcesSelected,
    setResourcesSelected,
    numResourcesToShow,
    setNumResourcesToShow,
    loadMoreResourcesClickCount,
    setLoadMoreResourcesClickCount,
    maxSelect,
}) => {
    const handleSelectResource = (index) => {
        setResourcesSelected(prevSelected => {
            const isSelected = prevSelected.includes(index);

            if (isSelected) {
                // If already selected, deselect it
                return prevSelected.filter(item => item !== index);
            } else if (maxSelect === 1) {
                // If maxSelect is 1, replace the current selection with the new one
                return [index];
            } else if (prevSelected.length < maxSelect) {
                // If maxSelect allows more and not reached the limit, add to selection
                return [...prevSelected, index];
            } else {
                // Warn if trying to select more than allowed
                toast.warn(`You can only select up to ${maxSelect} Resource Modules.`);
            }
            return prevSelected;
        });
    };

    const handleLoadMoreResources = () => {
        setNumResourcesToShow(numResourcesToShow + 2);
        setLoadMoreResourcesClickCount(loadMoreResourcesClickCount + 1);
    };

    return (
        <div className="w-full ai-scoped">
            {resourcesState.length > 0 ? (
                <>
                    {resourcesState?.slice(0, numResourcesToShow).map((rm, index) => (
                        <Accordion
                            key={index}
                            expanded={resourceExpanded === `resourcePanel${index}`}
                            onChange={handleResourcePanelChange(`resourcePanel${index}`)}
                            className="resource-panel relative"
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`resourcePanel${index}bh-content`}
                                id={`resourcePanel${index}bh-header`}
                            >
                                <Typography className="panel-main">
                                    <div className="image-and-title">
                                        <img className="panel-icon" src={resourceImage} alt="" />
                                        <b className="mx-2">{rm?.title || "Resource Module"}</b>
                                    </div>

                                    {rm?.is_ai_created && !rm?.from_go1 &&
                                        <div className="panel-icon panel-icon-box mr-1" style={{ backgroundColor: COLORS.GREEN }}>
                                            <img
                                                title="Generated by AI"
                                                src={openai}
                                                alt=""
                                            />
                                        </div>
                                    }
                                    {rm?.from_prepr &&
                                        <div className="panel-icon panel-icon-box mr-1" style={{ backgroundColor: "#FFF" }}>
                                            <img
                                                title="Collected from Prepr Resource Modules"
                                                src={leaf4}
                                                alt=""
                                            />
                                        </div>
                                    }
                                    {rm?.from_go1 &&
                                        <div className="panel-icon panel-icon-box mr-1" style={{ backgroundColor: "#D3FF00" }}>
                                            <img
                                                title="Collected from Go1 Resource Modules"
                                                src={go1}
                                                alt=""
                                            />
                                        </div>
                                    }
                                </Typography>
                            </AccordionSummary>
                            <Button
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleSelectResource(index)
                                }}
                                color={resourcesSelected.includes(index) ? 'green' : 'blue'}
                                bordered
                                classes="panel-button sm-panel-button"
                                height={35}
                            >
                                {resourcesSelected.includes(index) ? (
                                    <>
                                        <CheckCircleIcon className="icon-margin" /> Selected</>) : 'Select'}
                            </Button>
                            <AccordionDetails>
                                <div className="image-and-title">
                                    {rm?.level && <>
                                        <img className="desc-icon" src={chart} alt="" />
                                        <Typography dangerouslySetInnerHTML={{ __html: rm?.level }} />
                                    </>}
                                    
                                    {rm?.duration && <>
                                        <img className="desc-icon ml-4" src={calendar} alt="" />
                                        <Typography dangerouslySetInnerHTML={{ __html: rm?.duration }} />
                                    </>}
                                </div>
                                <br />
                                {rm?.from_prepr && rm?.cover_image &&
                                    <>
                                        <div className="rm-image">
                                            <img
                                                src={rm?.cover_image}
                                                alt=""
                                            />
                                        </div>
                                        <br />
                                    </>
                                }
                                {rm?.from_go1 && rm?.image &&
                                    <>
                                        <div className="rm-image">
                                            <img
                                                src={rm?.image}
                                                alt=""
                                            />
                                        </div>
                                        <br />
                                    </>
                                }
                                <AIText className="mb-1">Description</AIText>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: (rm?.description && rm.description.replace(/&nbsp;/g, '').trim().length > 0) ? rm.description : 'No description available.'
                                    }}
                                />
                                <br />
                                {rm?.is_ai_created &&
                                    <>
                                        {rm?.resource_module_items?.some(item => item.type === 'link') && (
                                            <div className="w-full">
                                                <AIText className="mb-1">Links</AIText>
                                                <ul className="link-list">
                                                    {rm?.resource_module_items?.filter(item => item.type === 'link').map((item, index) => (
                                                        <li key={index}>
                                                            <div className="link-container">
                                                                <div className="link-title-box">
                                                                    <img className="h-25 mr-1" src={`https://www.google.com/s2/favicons?domain=${item?.url}&sz=64`} alt="Website Icon" />
                                                                    <div className="link-title" title={item?.title || 'Unnamed Link'}>
                                                                        {item?.title || 'Unnamed Link'}
                                                                    </div>
                                                                </div>
                                                                <div className="link-url" title={item?.url}>
                                                                    <a href={item?.url} target="_blank" rel="noopener noreferrer">{item?.url}</a>
                                                                </div>
                                                            </div>
                                                            <hr className="hr-style" />
                                                        </li>
                                                    ))}
                                                </ul>
                                                <br />
                                            </div>
                                        )}
                                        {rm?.resource_module_items?.some(item => item.type === 'video') && (
                                            <div className="w-full">
                                                <AIText className="mb-1">Videos</AIText>
                                                <div className="video-container">
                                                    {rm?.resource_module_items?.filter(item => item.type === 'video').map((item, index) => {
                                                        // Remove width and height attributes from the iframe
                                                        const cleanedEmbedHTML = item?.embedHTML?.replace(/(width="[^"]*")|(height="[^"]*")/g, '');

                                                        return (
                                                            <div key={index} className="video-box">
                                                                {cleanedEmbedHTML ? (
                                                                    <div dangerouslySetInnerHTML={{ __html: cleanedEmbedHTML }} />
                                                                ) : (
                                                                    <a className="placeholder-video" href={item?.url} title={item?.title + " | " + item?.url || 'Unnamed Video'} target="_blank" rel="noopener noreferrer"><img src={videoPlaceholder} alt="Video Placeholder" /></a>
                                                                )}
                                                                <a href={item?.url} title={item?.title + " | " + item?.url || 'Unnamed Video'} className="video-title" target="_blank" rel="noopener noreferrer">{item?.title || 'Unnamed Video'}</a>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                }
                                {(rm?.skills?.length || rm?.skill_titles?.length) &&
                                    <AIText className="mb-1">Skills</AIText>
                                }

                                {rm?.skill_titles?.map((skillTitle) => (
                                    <Chip
                                        key={skillTitle}
                                        label={skillTitle}
                                        className="py-2 mr-1 mb-1 fs-12"
                                        sx={{ height: "26px" }}
                                    />
                                ))}

                                {rm?.from_go1 && rm?.skills && rm?.skills?.map((skill, index) => (
                                    <Chip
                                        key={index}
                                        label={skill.name}
                                        className="py-2 mr-1 mb-1 fs-12"
                                        sx={{ height: "26px" }}
                                    />
                                ))}

                                {rm?.from_prepr &&
                                    <>
                                        <br />
                                        <br />
                                        <AIText><a href={"/resource/" + rm?.slug} target="_blank" rel="noopener noreferrer">Preview Resource Module</a></AIText>
                                    </>
                                }
                            </AccordionDetails>
                            <Button
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleSelectResource(index);
                                }}
                                color={resourcesSelected?.includes(index) ? 'green' : 'blue'}
                                bordered
                                classes={`panel-button md-panel-button`}
                                height={35}
                            >
                                {resourcesSelected?.includes(index) ? (<><CheckCircleIcon className="icon-margin" /> Selected</>) : 'Select'}
                            </Button>
                        </Accordion>
                    ))}
                    <br />
                    {resourcesState?.length > numResourcesToShow && loadMoreResourcesClickCount < 30 && (
                        <AIText className="text-right">Don't think these are good fit? <span className="no-good-fit" onClick={handleLoadMoreResources}>Load More</span></AIText>
                    )}
                </>
            ) : (
                <AIText className="text-center my-10">Unfortunately, we were unable to generate and/or find you resource modules.</AIText>
            )}
        </div>
    );
};

export default ResourceModulePreview;