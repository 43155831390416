import React, { useState } from "react";
import useLabs from "../../../store/labs/service-hook";
import Button from "../../../components/Button";

const SendEventInvitation = ({ lab, t = (d) => d }) => {
  const { sendLiveEventInvitation } = useLabs();
  const [sendingLiveEventInvitation, setSendingLiveEventInvitation] =
    useState(false);
  const invite = async () => {
    if (lab) {
      setSendingLiveEventInvitation(true);
      await sendLiveEventInvitation(lab?.slug);
      setSendingLiveEventInvitation(false);
    }
  };

  return (
    <>
      <Button
        classes="w-full mt-2"
        height="40px"
        color="blue"
        onClick={invite}
        loading={sendingLiveEventInvitation}
      >
        {t("Send Event Invitation")}
      </Button>
    </>
  );
};

export default SendEventInvitation;
