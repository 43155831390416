export const FilterComponentsList = {
  privacy: "privacy",
  category: "category",
  skill: "skill",
  level: "level",
  duration: "duration",
  status: "status",
  project_status: "project_status",
  project_category: "project_category",
  project_type: "project_type",
  lab_type: "lab_type",
  job: "job",
  sort_by: "sort_by",
};

export const FilterMappings = {
  labs: [
    FilterComponentsList.privacy,
    FilterComponentsList.duration,
    FilterComponentsList.level,
    FilterComponentsList.skill,
    FilterComponentsList.category,
    FilterComponentsList.lab_type,
    FilterComponentsList.sort_by,
  ],
  labPrograms: [
    FilterComponentsList.privacy,
    FilterComponentsList.duration,
    FilterComponentsList.level,
    FilterComponentsList.skill,
    FilterComponentsList.category,
    FilterComponentsList.sort_by,
  ],
  labMarketPlace: [
    FilterComponentsList.privacy,
    FilterComponentsList.duration,
    FilterComponentsList.level,
    FilterComponentsList.skill,
    FilterComponentsList.category,
    FilterComponentsList.sort_by,
  ],
  challenges: [
    FilterComponentsList.privacy,
    FilterComponentsList.duration,
    FilterComponentsList.level,
    FilterComponentsList.skill,
    FilterComponentsList.category,
    FilterComponentsList.job,
    FilterComponentsList.sort_by,
  ],
  challengeTemplates: [
    FilterComponentsList.privacy,
    FilterComponentsList.duration,
    FilterComponentsList.level,
    FilterComponentsList.skill,
    FilterComponentsList.category,
    FilterComponentsList.sort_by,
  ],
  challengePaths: [
    FilterComponentsList.privacy,
    FilterComponentsList.duration,
    FilterComponentsList.level,
    FilterComponentsList.skill,
    FilterComponentsList.category,
    FilterComponentsList.sort_by,
  ],
  resourceModules: [
    FilterComponentsList.privacy,
    FilterComponentsList.duration,
    FilterComponentsList.level,
    FilterComponentsList.skill,
    FilterComponentsList.sort_by,
  ],
  resourceCollection: [
    FilterComponentsList.privacy,
    FilterComponentsList.duration,
    FilterComponentsList.level,
    FilterComponentsList.skill,
    FilterComponentsList.sort_by,
  ],
  resourceGroups: [
    FilterComponentsList.privacy,
    FilterComponentsList.duration,
    FilterComponentsList.level,
    FilterComponentsList.skill,
    FilterComponentsList.sort_by,
  ],
  projects: [
    FilterComponentsList.privacy,
    FilterComponentsList.project_category,
    FilterComponentsList.project_type,
    FilterComponentsList.sort_by,
  ],
  organizations: [FilterComponentsList.sort_by],
  users: [FilterComponentsList.sort_by],
};

export const ADVANCE_SEARCH_COMPONENTS = {
  LABS: "labs",
  LAB_PROGRAMS: "labPrograms",
  LAB_MARKET_PLACE: "labMarketPlace",
  CHALLENGES: "challenges",
  CHALLENGE_TEMPLATES: "challengeTemplates",
  CHALLENGE_PATHS: "challengePaths",
  RESOURCE_MODULES: "resourceModules",
  RESOURCE_COLLECTION: "resourceCollection",
  RESOURCE_GROUPS: "resourceGroups",
  PROJECTS: "projects",
  ORGANIZATIONS: "organizations",
  USERS: "users",
};
