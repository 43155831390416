import {createContext, useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import useUnified from "../../../store/unified/service-hook";
import useArrayObj from "../../../helpers/hooks/useArray";
import useComputed from "../../../helpers/hooks/useComputed";
import {INVITATION_ALLOWED_TYPES} from "../../../store/unified/constants";

export const UnifiedImportMemberContext = createContext({})


const UnifiedImportMemberContextWrapper = ({children}) => {

    /**
     * STATE
     */
    const {data: employees, setData: setEmployees, update: updateEmployee} = useArrayObj([])
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [perPage] = useState(10)
    const [enableGlobalRole, setEnableGlobalRole] = useState(false)
    const [globalRole, setGlobalRole] = useState()

    /**
     * HOOKS
     */
    const [searchParams] = useSearchParams()
    const {type} = useParams()
    const {loadingEmployees, listEmployees} = useUnified()
    const {
        data: selectedEmployees,
        setData,
        append: selectEmployee,
        deleteItem: removeSelectedEmployee,
        update: updateSelectedEmployee
    } = useArrayObj([])


    /**
     * FETCH EMPLOYEE
     * @returns {Promise<void>}
     */
    const fetchEmployees = async () => {
        const id = searchParams.get('id')
        const state = searchParams.get('state')
        if (id && state) {
            const data = await listEmployees({
                connection_id: id,
                state
            })
            setEmployees(data, 'email')
        }
    }


    /**
     * SELECT ALL FROM THE CURRENT PAGE
     */
    const selectAllFormCurrentPage = () => {
        setData([...selectedEmployees, ...employeePaginated], 'email')
    }

    /**
     * SELECT ALL EMPLOYEES
     */
    const selectAll = () => {
        setData([...employees], 'email')
    }

    /**
     * REMOVE ALL SELECTED EMPLOYEES
     */
    const removeAll = () => {
        setData([], 'email')
    }
    /**
     * FILTERED EMPLOYEES
     */
    const filtered = useComputed(() => {
        if (!search) {
            return employees;
        }

        return [...employees].filter(item => {
            return item?.email?.toLowerCase()?.includes(search?.toLowerCase()) || item?.name?.toLowerCase()?.includes(search?.toLowerCase())
        })
    }, [search, employees])


    /**
     * EMPLOYEES SPLICED FOR PAGINATION
     */
    const employeePaginated = useComputed(() => {
        return [...filtered]?.splice((page - 1) * perPage, page * perPage) || []
    }, [page, employees, filtered])


    /**
     * CHECK IF WE ARE INVITING TO AN ORGANIZATION
     */
    const isOrganizationInvite = useComputed(() => type === INVITATION_ALLOWED_TYPES[0], [type])

    /**
     * IS LAST PAGE
     */
    const isLastPage = useComputed(() => {
        const ratio = filtered.length / perPage;
        const ratioFloor = Math.floor(ratio);
        const lastPage = ratio > ratioFloor ? ratioFloor + 1 : ratioFloor
        return page === lastPage;
    }, [filtered, page])


    /**
     * HANDLE NEXT PAGE
     */
    const handleNextPage = () => {
        if (!isLastPage) {
            setPage(page + 1)
        }
    }

    /**
     * HANDLE PREVIOUS PAGE
     */
    const handlePreviousPage = () => {
        if (page === 1) {
            return;
        }
        setPage(page - 1)
    }


    /**
     * COMPONENT ON MOUNTED
     */
    useEffect(() => {
        fetchEmployees()
    }, [])


    return <UnifiedImportMemberContext.Provider value={{
        employees: employeePaginated,
        total: filtered?.length || 0,
        loadingEmployees,
        onSearch: setSearch,
        selectedEmployees,
        selectEmployee,
        removeSelectedEmployee,
        isOrganizationInvite,
        page,
        perPage,
        type,
        searchParams,
        selectAll,
        removeAll,
        selectAllFormCurrentPage,
        isLastPage,
        handleNextPage,
        handlePreviousPage,
        updateEmployee,
        updateSelectedEmployee,
        enableGlobalRole,
        setEnableGlobalRole,
        globalRole,
        setGlobalRole
    }}>
        {children}
    </UnifiedImportMemberContext.Provider>
}

export default UnifiedImportMemberContextWrapper
