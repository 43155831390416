import { Delete, PushPin } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import Spin from "components/Spin";
import { isTrue } from "helpers/utils/validators";
import moment from "moment";

export const getJobAndSkillJSXCols = (
  onClickPin,
  handleDeleteItem,
  deletingItemId,
  navigateToDetail,
  t
) => {
  return [
    {
      title: t("Title"),
      render: (rowData) => {
        return (
          <p
            className="w-140 text-truncate fw-500 tracking-tight link"
            onClick={() => navigateToDetail(rowData?.id)}
          >
            {" "}
            {rowData?.title}
          </p>
        );
      },
    },
    {
      title: t("Related Skills"),
      render: (rowData) => {
        const skillsToShow = rowData?.related_skills || rowData?.skills;
        return (
          <p className="w-220 text-truncate tracking-tight">
            {skillsToShow?.length > 0
              ? skillsToShow?.map((skill) => skill?.title || skill).join(", ")
              : "N/A"}
          </p>
        );
      },
    },
    {
      title: t("Challenges"),
      render: (rowData) => {
        return (
          <p>
            {rowData.related_challenges ||
              rowData?.related_challenge_count ||
              "0"}
          </p>
        );
      },
    },
    {
      title: t("Labs"),
      render: (rowData) => {
        return (
          <p className="w-30">
            {(typeof rowData.related_labs === "number"
              ? rowData?.related_labs
              : rowData?.related_labs_count) || "0"}
          </p>
        );
      },
    },
    {
      title: t("Resources"),
      render: (rowData) => {
        return (
          <p>
            {rowData.related_resources ||
              rowData?.related_resources_count ||
              "0"}
          </p>
        );
      },
    },
    {
      title: t("Saved on"),
      render: (rowData) => (
        <p className="tracking-tight">
          {rowData?.saved_on
            ? moment(new Date(rowData?.saved_on)).format("MMMM DD, YYYY")
            : "N/A"}
        </p>
      ),
    },
    {
      render: (rowData) => {
        return (
          <Stack direction="row" alignItems="center">
            <IconButton
              className="h-30 w-30"
              onClick={() => onClickPin(rowData?.id, rowData?.pinned)}
            >
              <PushPin
                className={`${isTrue(rowData.pinned) ? "c-orange" : "c-gray"}`}
                fontSize="small"
              />
            </IconButton>
            {deletingItemId === rowData.id ? (
              <Spin blue />
            ) : (
              <IconButton
                className="h-30 w-30"
                onClick={() => handleDeleteItem(rowData?.id)}
              >
                <Delete color="error" fontSize="small" />
              </IconButton>
            )}
          </Stack>
        );
      },
    },
  ];
};
