import React, { useState } from "react";
import { Grid } from "@mui/material";
import Button from "components/Button";
import SchemaUIExtractor from "../../../components/UI/organisms/SchemaUIExtractor";
import { educationSchema } from "pages/profile/forms/data";
import { toast } from "react-toastify";

export const validateEducation = (education,t) => {
  if (!education || !education?.length) return true;
  for (const d of education) {
    if (!d.university || !d.degree) {
      toast.error(t("Please enter the education details properly!"));
      return true;
    }
  }
  return false;
};

const Education = ({
  education,
  setEducation,
  errors,
  onDelete,
  t = (d) => d,
}) => {
  const [loading, setLoading] = useState(false);
  const addEducation = () => {
    setEducation((prevState) => [...prevState, {}]);
  };

  const onChangeEducation = (e, index) => {
    const { name, value } = e.target;
    education[index][name] = value;
    setEducation([...education]);
  };

  const removeEducation = async (indexToRemove) => {
    if (education[indexToRemove]?.id) {
      setLoading(indexToRemove);
      await onDelete(education[indexToRemove]?.id);
      setLoading(false);
    }
    education.splice(indexToRemove, 1);
    setEducation([...education]);
  };

  return (
    <div>
      <div className="flex items-center justify-between mt-4">
        <h3>{t("Edit Education")}</h3>
        <Button bordered onClick={addEducation}>
          {" "}
          {t("Add Entry")}
        </Button>
      </div>
      {education.map((d, i) => (
        <div key={i}>
          <h3 className="mt-3">
            {t("Entry")} #{i + 1}
          </h3>
          <Grid container xs={12} spacing={4}>
            <Grid item xs={12} sm={6}>
              <SchemaUIExtractor
                schema={educationSchema.slice(0, 5)}
                data={d}
                onChange={(e) => onChangeEducation(e, i)}
                errors={errors}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              direction="column"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <SchemaUIExtractor
                schema={educationSchema.slice(5, 6)}
                data={d}
                onChange={(e) => onChangeEducation(e, i)}
                errors={errors}
              />
              <Button
                classes="mt-2"
                color="red"
                width="100px"
                height="32px"
                loading={loading === i}
                bordered
                onClick={() => removeEducation(i)}
              >
                {t("Remove")}
              </Button>
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default Education;
