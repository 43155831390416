import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import { resetAuth } from "store/api/utils";
import {
  Loader,
  ListingLoader,
  DetailLoader,
} from "./components/UI/molecules/LazyLoader";
import { COMPONENT_TYPES } from "./helpers/utils/constants";
import OrganizationReport from "./pages/report/organization";
import useNotifications from "store/notifications/service-hook";
import OrgIntegrationSettings from "pages/settings/OrgIntegrationSettings";
import moment from "moment";
import i18n from "./i18n";
import "moment/locale/fr";
import "moment/locale/fr-ca";
import Offline from "pages/errorInfos/components/Offline";

const MagicLinkInvite = React.lazy(() =>
  import("./pages/memberManagement/containers/MagicLinkInvite")
);

const ChallengeWinnerSelection = React.lazy(() =>
  import("./pages/challenges/WinnerSelection")
);
const Login = React.lazy(() => import("./pages/auth/components/Login"));
const CustomLogin = React.lazy(() =>
  import("./pages/auth/components/CustomLogin")
);
const Register = React.lazy(() => import("pages/auth/components/Register"));
const CollectUserData = React.lazy(() =>
  import("pages/auth/components/CollectUserData")
);
const UpgradePlan = React.lazy(() =>
  import("pages/organization/components/upgradePlan")
);
const GettingStartedOrg = React.lazy(() =>
  import("pages/organization/components/GettingStarted")
);
const EditOrganization = React.lazy(() =>
  import("pages/organization/forms/index")
);
const OrganizationMainPage = React.lazy(() => import("pages/organization"));
const LabProgramPublicView = React.lazy(() => import("pages/labPrograms"));
const CreateLabProgram = React.lazy(() => import("pages/labPrograms/forms"));
const UserProfile = React.lazy(() => import("pages/profile"));
const AcheivementsScreen = React.lazy(() => import("pages/achievements"));
const AchievementDetailView = React.lazy(() =>
  import("pages/achievements/components/AchievementDetailView")
);
const SettingsPage = React.lazy(() => import("pages/settings"));
const ResourceModule = React.lazy(() =>
  import("pages/resources/ResourceModule")
);
const ResourceGroup = React.lazy(() => import("pages/resources/ResourceGroup"));
const LabPublicView = React.lazy(() => import("pages/labs"));
const CreateEditLab = React.lazy(() => import("pages/labs/forms/index"));
const ResourceCollection = React.lazy(() =>
  import("pages/resources/ResourceCollection")
);
const Challenges = React.lazy(() => import("pages/challenges"));
const AddEditChallenge = React.lazy(() => import("pages/challenges/forms"));

const ProjectsSection = React.lazy(() => import("pages/projects"));
const AddEditProject = React.lazy(() => import("pages/projects/forms"));
const ChallengePaths = React.lazy(() => import("pages/challengePaths"));
const AddEditChallengePath = React.lazy(() =>
  import("pages/challengePaths/forms")
);
const ChatsSection = React.lazy(() => import("pages/chats"));
const BrowseSkills = React.lazy(() =>
  import("pages/skills/listing/BrowseSkills")
);
const SkillDetailPage = React.lazy(() =>
  import("pages/skills/components/SkillDetailPage")
);
const Dashboards = React.lazy(() => import("pages/dashboards"));
const Explore = React.lazy(() => import("pages/explore"));
const Notifications = React.lazy(() => import("pages/notifications"));
const TeamMatching = React.lazy(() => import("pages/teamMatching"));
const ErrorInfo = React.lazy(() => import("pages/errorInfos"));
const Assessment = React.lazy(() => import("pages/projects/assessment"));
const BrowseProjects = React.lazy(() =>
  import("pages/projects/listing/Browse")
);
const BrowseChallenges = React.lazy(() =>
  import("pages/challenges/listing/BrowseChallenges")
);
const ManageChallenges = React.lazy(() =>
  import("pages/challenges/listing/ManageChallenges")
);
const BrowseOrganizations = React.lazy(() =>
  import("pages/organization/listing/BrowseOrganizations")
);
const BrowseLabs = React.lazy(() => import("pages/labs/listing/BrowseLabs"));
const ManageLabs = React.lazy(() => import("pages/labs/listing/ManageLabs"));
const LabMarketplace = React.lazy(() => import("pages/labMarketplace"));
const LabMarketplaceDetail = React.lazy(() =>
  import("pages/labMarketplace/LabMarketplaceDetailView")
);
const ChallengeTemplateDetail = React.lazy(() =>
  import("pages/labMarketplace/ChallengeTemplateDetailView")
);
const MemberManagement = React.lazy(() => import("pages/memberManagement"));
const UnifiedInviteMember = React.lazy(() => import("pages/unified"));

const ProjectSubmission = React.lazy(() =>
  import("pages/projects/browseProjects/ProjectSubmission")
);
const SearchModule = React.lazy(() => import("pages/search"));
const LeaderBoard = React.lazy(() => import("pages/leaderBoard"));
const VerifyOTP = React.lazy(() => import("pages/auth/components/VerifyOTP"));
const ForgotPassword = React.lazy(() =>
  import("pages/auth/components/ForgotPassword")
);
const MagnetCallback = React.lazy(() => import("pages/auth/components/Magnet"));
const ManageOrganizations = React.lazy(() =>
  import("pages/organization/listing/ManageOrganizations")
);
const BrowseLabPrograms = React.lazy(() =>
  import("pages/labPrograms/listing/Browse")
);
const ManageLabPrograms = React.lazy(() =>
  import("pages/labPrograms/listing/Manage")
);
const BrowseChallengesPaths = React.lazy(() =>
  import("pages/challengePaths/listing/Browse")
);
const ManageChallengePath = React.lazy(() =>
  import("pages/challengePaths/listing/Manage")
);
const BrowseResources = React.lazy(() =>
  import("pages/resources/ResourceModule/listing/Browse")
);
const ManageResources = React.lazy(() =>
  import("pages/resources/ResourceModule/listing/Manage")
);
const CreateEditResource = React.lazy(() =>
  import("pages/resources/ResourceModule/forms")
);
const BrowseResourceCollections = React.lazy(() =>
  import("pages/resources/ResourceCollection/listing/Browse")
);
const ManageResourcesCollection = React.lazy(() =>
  import("pages/resources/ResourceCollection/listing/Manage")
);
const CreateEditResourceCollection = React.lazy(() =>
  import("pages/resources/ResourceCollection/forms")
);
const BrowseResourceGroups = React.lazy(() =>
  import("pages/resources/ResourceGroup/listing/Browse")
);
const ManageResourceGroups = React.lazy(() =>
  import("pages/resources/ResourceGroup/listing/Manage")
);
const CreateEditResourceGroup = React.lazy(() =>
  import("pages/resources/ResourceGroup/forms")
);

const CareerExplore = React.lazy(() => import("pages/careers"));
const JobDetailPage = React.lazy(() =>
  import("pages/careers/components/JobDetailPage")
);

const CreateLabAI = React.lazy(() => import("pages/labs/ai"));
const CreateChallengeAI = React.lazy(() => import("pages/challenges/ai"));
const CreateResourceModuleAI = React.lazy(() =>
  import("pages/resources/ResourceModule/ai")
);
const PlayCourse = React.lazy(() =>
  import("./pages/resources/ResourceModule/components/PlayCourse")
);
const StartPage = React.lazy(() => import("./pages/auth/components/StartPage"));
const UserOnboarding = React.lazy(() =>
  import("./pages/onboarding/UserOnboarding")
);
const OrganizationOnboarding = React.lazy(() =>
  import("./pages/onboarding/OrganizationOnboarding")
);
const AdvanceSearchPage = React.lazy(() => import("pages/advanceSearch"));
const IntercomComponent = React.lazy(() => import("pages/intercom"));
const AccessibeComponent = React.lazy(() => import("./pages/accessibe"));
const Terms = React.lazy(() =>
  import("pages/layouts/dashboard/components/Terms")
);
const PrivacyPolicy = React.lazy(() =>
  import("pages/layouts/dashboard/components/PrivacyPolicy")
);

/** REPORTS RELATED ROUTES */
const LabReport = React.lazy(() => import("./pages/report/lab"));
const ChallengeReport = React.lazy(() => import("./pages/report/challenge"));

/** END REPORT RELATED ROUTES */
const ChallengeProjectSubmission = React.lazy(() =>
  import("pages/challenges/project/ProjectSubmission")
);

const legacy_navigates = [
  {
    old: "/challengeManager/:slug",
    current: `/${COMPONENT_TYPES.CHALLENGE}/:slug`,
    param: "slug",
  },
  {
    old: "/challengeManager/userShow/:slug",
    current: `/${COMPONENT_TYPES.CHALLENGE}/:slug?private=true`,
    param: "slug",
  },
  {
    old: "/resource-module/:slug/view",
    current: `/${COMPONENT_TYPES.RESOURCE}/:slug`,
    param: "slug",
  },
  {
    old: "/resource/:slug/view",
    current: `/${COMPONENT_TYPES.RESOURCE}/:slug`,
    param: "slug",
  },
  {
    old: "/resourcegroup/:slug/show",
    current: `/${COMPONENT_TYPES.RESOURCE_COLLECTION}/:slug`,
    param: "slug",
  },
  {
    old: "/labs/:slug",
    current: `/${COMPONENT_TYPES.LAB}/:slug`,
    param: "slug",
  },
  {
    old: "/userlab/:slug",
    current: `/${COMPONENT_TYPES.LAB}/:slug`,
    param: "slug",
  },
  {
    old: "/group-details/:slug/lab",
    current: `/${COMPONENT_TYPES.LAB_PROGRAMS}/:slug`,
    param: "slug",
  },
  {
    old: "/group-details/:slug/challenge",
    current: `/${COMPONENT_TYPES.CHALLENGE_PATH}/:slug`,
    param: "slug",
  },
  {
    old: "/group-details/:slug/resource",
    current: `/${COMPONENT_TYPES.RESOURCE_GROUP}/:slug`,
    param: "slug",
  },
  {
    old: "/user/home",
    current: "/explore",
  },
  {
    old: "/projects/:slug",
    current: `/${COMPONENT_TYPES.PROJECT}/:slug`,
    param: "slug",
  },
  {
    old: "/organization-details/:slug",
    current: `/${COMPONENT_TYPES.ORGANIZATION}/:slug`,
    param: "slug",
  },
];

const DynamicRedirect = ({ path, param = "" }) => {
  const params = useParams();
  return <Navigate to={path.replace(`:${param}`, params[param])} replace />;
};

const EmailRedirects = ({}) => {
  const [search] = useSearchParams();
  const link = search.get("l");

  if (!link) {
    return <ErrorInfo type="404" />;
  }
  const { pathname } = new URL(link);
  return <Navigate to={pathname} replace />;
};

const routes = [
  {
    path: "/login",
    element: <Login />,
    loader: <Loader />,
  },
  {
    path: `/cl/:customUrl`,
    element: <CustomLogin />,
    loader: <Loader />,
  },
  {
    path: "/register",
    element: <Register />,
    loader: <Loader />,
  },
  {
    path: "/verify-otp",
    element: <VerifyOTP />,
    loader: <Loader />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    loader: <Loader />,
  },
  {
    path: "login/magnet/callback",
    element: <MagnetCallback />,
    loader: <Loader />,
  },
  {
    path: "/collect-user-data",
    element: <CollectUserData />,
    loader: <Loader />,
  },
  {
    path: "/upgrade-plan",
    element: <UpgradePlan />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.ORGANIZATION}/getting-started`,
    element: <GettingStartedOrg />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.ORGANIZATION}/create`,
    element: <EditOrganization />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.ORGANIZATION}/update/:slug`,
    element: <EditOrganization />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.ORGANIZATION}`,
    element: <BrowseOrganizations />,
    loader: <Loader />,
  },
  {
    path: `/manage/${COMPONENT_TYPES.ORGANIZATION}`,
    element: <ManageOrganizations />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.ORGANIZATION}/:id`,
    element: <OrganizationMainPage />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.ORGANIZATION}/:id/integration-settings`,
    element: <OrgIntegrationSettings />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.LAB_PROGRAMS}`,
    element: <BrowseLabPrograms />,
    loader: <ListingLoader />,
  },
  {
    path: `/manage/${COMPONENT_TYPES.LAB_PROGRAMS}`,
    element: <ManageLabPrograms />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.LAB_PROGRAMS}/:id`,
    element: <LabProgramPublicView />,
    loader: <DetailLoader />,
  },
  {
    path: `/${COMPONENT_TYPES.LAB_PROGRAMS}/create`,
    element: <CreateLabProgram />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.LAB_PROGRAMS}/edit/:id`,
    element: <CreateLabProgram />,
    loader: <Loader />,
  },
  {
    path: "/profile/:id",
    element: <UserProfile />,
    loader: <Loader />,
  },
  {
    path: "/achievements",
    element: <AcheivementsScreen />,
    loader: <Loader />,
  },
  {
    path: "/achievements/:id",
    element: <AchievementDetailView />,
    loader: <Loader />,
  },
  {
    path: "/settings",
    element: <SettingsPage />,
    loader: <Loader />,
  },
  {
    path: "/invitation-link/:component/:id",
    element: <MagicLinkInvite />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.RESOURCE}/play-course/:id`,
    element: <PlayCourse />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.RESOURCE}/`,
    element: <BrowseResources />,
    loader: <Loader />,
  },
  {
    path: `/manage/${COMPONENT_TYPES.RESOURCE}`,
    element: <ManageResources />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.RESOURCE}/:id`,
    element: <ResourceModule />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.RESOURCE}/create`,
    element: <CreateEditResource />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.RESOURCE}/edit/:id`,
    element: <CreateEditResource />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.RESOURCE_GROUP}`,
    element: <BrowseResourceGroups />,
    loader: <ListingLoader />,
  },
  {
    path: `/manage/${COMPONENT_TYPES.RESOURCE_GROUP}`,
    element: <ManageResourceGroups />,
    loader: <ListingLoader />,
  },
  {
    path: `/${COMPONENT_TYPES.RESOURCE_GROUP}/create`,
    element: <CreateEditResourceGroup />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.RESOURCE_GROUP}/edit/:id`,
    element: <CreateEditResourceGroup />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.RESOURCE_GROUP}/:id`,
    element: <ResourceGroup />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.LAB}`,
    element: <BrowseLabs />,
    loader: <ListingLoader />,
  },
  {
    path: `/manage/${COMPONENT_TYPES.LAB}`,
    element: <ManageLabs />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.LAB}/:id`,
    element: <LabPublicView />,
    loader: <DetailLoader />,
  },
  {
    path: `/${COMPONENT_TYPES.LAB}/create`,
    element: <CreateEditLab />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.LAB}/ai/create`,
    element: <CreateLabAI />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.LAB}/edit/:id`,
    element: <CreateEditLab />,
    loader: <Loader />,
  },
  {
    path: "/" + COMPONENT_TYPES.LAB_MARKETPLACE,
    element: <LabMarketplace />,
    loader: <ListingLoader />,
  },
  {
    path: `/${COMPONENT_TYPES.LAB_MARKETPLACE}/:id`,
    element: <LabMarketplaceDetail />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.CHALLENGE_TEMPLATE}/:id`,
    element: <ChallengeTemplateDetail />,
    loader: <Loader />,
  },
  {
    path: "/resourceModule/ai/create",
    element: <CreateResourceModuleAI />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.RESOURCE_COLLECTION}`,
    element: <BrowseResourceCollections />,
    loader: <ListingLoader />,
  },
  {
    path: `/manage/${COMPONENT_TYPES.RESOURCE_COLLECTION}`,
    element: <ManageResourcesCollection />,
    loader: <ListingLoader />,
  },
  {
    path: `/${COMPONENT_TYPES.RESOURCE_COLLECTION}/:id`,
    element: <ResourceCollection />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.RESOURCE_COLLECTION}/create`,
    element: <CreateEditResourceCollection />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.RESOURCE_COLLECTION}/edit/:id`,
    element: <CreateEditResourceCollection />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.CHALLENGE}`,
    element: <BrowseChallenges />,
    loader: <ListingLoader />,
  },
  {
    path: `/manage/${COMPONENT_TYPES.CHALLENGE}`,
    element: <ManageChallenges />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.CHALLENGE}/:id`,
    element: <Challenges />,
    loader: <DetailLoader />,
  },
  {
    path: `/${COMPONENT_TYPES.CHALLENGE}/:slug/project-submissions`,
    element: <ChallengeProjectSubmission />,
    loader: <DetailLoader />,
  },
  {
    path: `/${COMPONENT_TYPES.CHALLENGE}/create`,
    element: <AddEditChallenge />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.CHALLENGE}/ai/create`,
    element: <CreateChallengeAI />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.CHALLENGE}/:slug/winner-selection`,
    element: <ChallengeWinnerSelection />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.CHALLENGE}/edit/:id`,
    element: <AddEditChallenge />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.PROJECT}`,
    element: <BrowseProjects />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.PROJECT}/create`,
    element: <AddEditProject />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.PROJECT}/edit/:id`,
    element: <AddEditProject />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.PROJECT}/:id`,
    element: <ProjectsSection />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.CHALLENGE_PATH}`,
    element: <BrowseChallengesPaths />,
    loader: <ListingLoader />,
  },
  {
    path: `/manage/${COMPONENT_TYPES.CHALLENGE_PATH}`,
    element: <ManageChallengePath />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.CHALLENGE_PATH}/:id`,
    element: <ChallengePaths />,
    loader: <DetailLoader />,
  },
  {
    path: `/${COMPONENT_TYPES.CHALLENGE_PATH}/create`,
    element: <AddEditChallengePath />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.CHALLENGE_PATH}/edit/:id`,
    element: <AddEditChallengePath />,
    loader: <Loader />,
  },
  {
    path: "/chats",
    element: <ChatsSection />,
    loader: <Loader />,
  },
  {
    path: "/announcements/:id",
    element: <ChatsSection isAnnouncements />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.SKILL}`,
    element: <BrowseSkills />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.SKILL}/:id`,
    element: <SkillDetailPage />,
    loader: <Loader />,
  },
  {
    path: "/dashboard/*",
    element: <Dashboards />,
    loader: <Loader />,
  },
  {
    path: "/explore",
    element: <Explore />,
    loader: <Loader />,
  },
  {
    path: "/notifications",
    element: <Notifications />,
    loader: <Loader />,
  },
  {
    path: `/${COMPONENT_TYPES.TEAM_MATCHING}`,
    element: <TeamMatching />,
    loader: <Loader />,
  },
  {
    path: "/error/:type",
    element: <ErrorInfo />,
    loader: <Loader />,
  },
  {
    path: "/:type/assessment/:id",
    element: <Assessment />,
    loader: <Loader />,
  },
  {
    path: "/member-management/:type/:id",
    element: <MemberManagement />,
    loader: <Loader />,
  },
  {
    path: "/unified/:type/:id/invite-members",
    element: <UnifiedInviteMember />,
    loader: <Loader />,
  },
  {
    path: "/project-submissions",
    element: <ProjectSubmission />,
    loader: <Loader />,
  },
  {
    path: "/advanced-search/sample",
    element: <SearchModule />,
    loader: <Loader />,
  },
  {
    path: "/leaderboard",
    element: <LeaderBoard />,
    loader: <Loader />,
  },
  {
    path: "/careers",
    element: <CareerExplore />,
    loader: <Loader />,
  },
  {
    path: "/jobs/:id",
    element: <JobDetailPage />,
    loader: <Loader />,
  },
  {
    path: "/",
    element: <StartPage />,
    loader: <Loader />,
  },
  {
    path: "/user-onboarding",
    element: <UserOnboarding />,
    loader: <Loader />,
  },
  {
    path: "/organization-onboarding",
    element: <OrganizationOnboarding />,
    loader: <Loader />,
  },
  {
    path: "/advanced-search",
    element: <AdvanceSearchPage />,
    loader: <Loader />,
  },
  {
    path: "/terms",
    element: <Terms />,
    loader: <Loader />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    loader: <Loader />,
  },

  // REPORT RELATED ROUTES
  {
    path: "/report/lab/:id",
    element: <LabReport />,
    loader: <Loader />,
  },

  // REPORT RELATED ROUTES
  {
    path: "/report/lab/:id",
    element: <LabReport />,
    loader: <Loader />,
  },
  {
    path: "/report/challenge/:id",
    element: <ChallengeReport />,
    loader: <Loader />,
  },
  {
    path: "/report/organization/:id",
    element: <OrganizationReport />,
    loader: <Loader />,
  },
  // END REPORT RELATED ROUTES
  {
    path: "/email/*",
    element: <EmailRedirects />,
    loader: <Loader />,
  },
];

const AllRoutes = () => {
  const dispatch = useDispatch();
  const [hydrated, setHydrated] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const state = useSelector((state) => state[REDUCER_TYPES.AUTH]);
  const { fetchNotifications } = useNotifications();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [hasPrompted, setHasPrompted] = useState(false);

  const showPrompt = (e) => {
    console.log("beforeinstallprompt event received", e);
    if (deferredPrompt === false || hasPrompted) return;
    e.preventDefault();
    setDeferredPrompt(e);
  };

  useEffect(() => {
    resetAuth(dispatch, state);
    setHydrated(true);
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    window.addEventListener("beforeinstallprompt", showPrompt);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("beforeinstallprompt", showPrompt);
    };
  }, []);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (state?.isLoggedIn) fetchNotifications();
  }, [state?.isLoggedIn]);

  const handleInstallClick = () => {
    if (
      !deferredPrompt ||
      window.location.pathname !== "/explore" ||
      hasPrompted
    )
      return;
    deferredPrompt.prompt();
    setDeferredPrompt(false);
    setHasPrompted(true);
    window.removeEventListener("beforeinstallprompt", showPrompt);
  };

  if (!hydrated) {
    return null;
  }

  return (
    <Router>
      <IntercomComponent />
      <AccessibeComponent />
      {isOnline ? (
        <div onClick={handleInstallClick}>
          <Routes>
            {routes.map(({ path, element, loader }) => (
              <Route
                key={path}
                path={path}
                element={
                  <React.Suspense fallback={loader}>{element}</React.Suspense>
                }
              />
            ))}
            {legacy_navigates.map(({ old, current, param }) => (
              <Route
                key={old}
                path={old}
                element={<DynamicRedirect path={current} param={param} />}
              />
            ))}
            <Route
              path="*"
              element={
                <React.Suspense fallback={<Loader />}>
                  <ErrorInfo type="404" />
                </React.Suspense>
              }
            />
          </Routes>
        </div>
      ) : (
        <Offline />
      )}
    </Router>
  );
};

export default AllRoutes;
