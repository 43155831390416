import { ArrowBack } from "@mui/icons-material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import NavTabs from "components/UI/molecules/NavTabs";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AssesmentGuidelines from "./AssesmentGuidelines";
import AssessedSubmissions from "./AssessedSubmissions";
import useRedirect from "helpers/hooks/useRedirect";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { Tab, Tabs } from "@mui/material";

const AssesmentMainPage = ({ assessment, setAssessment, challenge }) => {
  const { t } = useLocalizedTranslation();
  const [isSubmission, setIsSubmission] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const { id, type } = useParams();
  const { navigateBack } = useRedirect();
  const handleBack = () => navigateBack();

  const isChallengeSubmitted =
    (assessment?.project_submitted &&
      assessment?.project_submitted.length > 0) ||
    challenge?.submissions_count > 0;

  return (
    <Card centered classes="mt-20 mb-10 container">
      <div className="flex">
        <Button bordered onClick={handleBack}>
          <ArrowBack /> {t("Back")}
        </Button>
        <h1 className="flex1 w-full text-center">{t("Project Assessment")}</h1>
      </div>
      <div className={"mb-8 mx-8 mt-3"}>
        <Tabs
          value={isSubmission}
          onChange={(event, value) => {
            setIsSubmission(value);
          }}
        >
          <Tab
            label={
              <p style={{ textTransform: "none" }}>
                {t("Assessment Guideline")}
              </p>
            }
            value={0}
          />
          <Tab
            label={
              <p style={{ textTransform: "none" }}>
                {t("Assessed Submissions")}
              </p>
            }
            value={1}
          />
        </Tabs>

        <div className={"mt-3"}>
          {isSubmission === 0 ? (
            <AssesmentGuidelines
              setAssessment={setAssessment}
              assessment={assessment}
              id={id}
              isChallengeSubmitted={isChallengeSubmitted}
            />
          ) : (
            ""
          )}

          {isSubmission === 1 ? (
            <AssessedSubmissions assessment={assessment} />
          ) : (
            ""
          )}
        </div>
      </div>
    </Card>
  );
};

export default AssesmentMainPage;
