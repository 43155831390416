import React, { useEffect, useState } from "react";
import { LOGIN_FORM_SCHEMA, login_response_data } from "../auth-data";
import logo from "../../../assets/svg/logo.svg";
import Divider from "../../../components/Divider";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import AuthLayout from "../../layouts/AuthLayout";
import useAuth from "store/auth/service-hook";
import useRedirect from "helpers/hooks/useRedirect";
import { useAuth0 } from "@auth0/auth0-react";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import useForms from "helpers/hooks/useForms";
import PassowordInput from "components/UI/atoms/PassowordInput";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isGoogleCaptchaDisabled } from "helpers/utils/utilities";
import RecaptchaV2 from "../../../components/Input/RecaptchaV2";
import { BASE_URL } from "store/api";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const getSSOFromSub = (sub) => {
  if (sub.includes("google")) return "google";
  if (sub.includes("linkedin")) return "linkedin";
  if (sub.includes("windowslive")) return "microsoft";
  if (sub.includes("apple")) return "apple";
  if (sub.includes("magnet")) return "magnet";
};

const Login = () => {
  const { data } = login_response_data;
  const width = useWindowWidth();
  const { navigateRegister, navigateForgetPassword, navigateExplore } =
    useRedirect();
  const { t } = useLocalizedTranslation();
  const [params] = useSearchParams();
  const [loading, setloading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(
    isGoogleCaptchaDisabled()
  );
  const [recaptchaError, setRecaptchaError] = useState(false);
  const { login, state, checkEmail, ssoLogin } = useAuth();
  const { loginWithPopup, user, isAuthenticated, getAccessTokenSilently } =
    useAuth0();
  // eslint-disable-next-line no-unused-vars
  const code = params.get("code");

  const {
    data: formData,
    errors,
    onChange,
    validate,
  } = useForms(LOGIN_FORM_SCHEMA);

  useEffect(() => {
    async function checkLoginStatus() {
      if (state.isLoggedIn) navigateExplore();
    }

    checkLoginStatus();
  }, []);

  useEffect(() => {
    if (isAuthenticated && !!user) {
      handleSSOLoginRegister();
    }
  }, [isAuthenticated]);

  const handleSSOLoginRegister = async () => {
    const email = user?.email;
    const { error } = await checkEmail(email);
    delete user.picture;
    if (!error) {
      navigateRegister(`sso=true`);
    } else {
      const token = await getAccessTokenSilently();
      const payload = {
        email: user?.email,
        sub: user?.sub,
        sso_type: getSSOFromSub(user?.sub),
        access_token: token,
      };
      await ssoLogin(payload);
    }
  };

  const loginWithSSO = async (connection) => {
    if (!connection) return;
    if (connection === "magnet") {
      const url = `${BASE_URL.replace("/api/v1/", "")}/magnet/sso`;
      window.location.replace(url);
      return;
    }

    await loginWithPopup({
      authorizationParams: {
        connection: connection,
        redirectUri: window.location.origin + "/login",
        max_age: 60,
      },
    });
  };

  const loginHandler = async () => {
    const newErrors = await validate();
    if (!recaptchaValue) {
      setRecaptchaError(true);
      return;
    }
    if (newErrors.size) return;
    setloading(true);
    const { email, password } = formData;
    const body = { email, password, "g-recaptcha-response": recaptchaValue };
    await login(body);
    setloading(false);
  };

  return (
    <AuthLayout sliderHidden={width < 1000}>
      <div className="auth-card scroll">
        <img
          src={require("../../../" + data?.logo)}
          alt={logo}
          className="mt-4 h-70--mob"
        />
        <h1 className="mt-6 mb-3 fs-26">{t("Sign In")}</h1>
        <div className="flex-column items-center">
          {data?.input.map(({ title, placeholder, name, type }, index) => (
            <>
              {type === "email" ? (
                <Input
                  placeholder={placeholder}
                  title={title}
                  key={name}
                  name={name}
                  top={index * 5}
                  error={errors.get(name)}
                  value={formData[name]}
                  classes="w-full mt-2"
                  type={type}
                  onChange={onChange}
                  t={t}
                />
              ) : (
                <PassowordInput
                  title={title}
                  key={name}
                  name={name}
                  top={index * 5}
                  error={errors.get(name)}
                  value={formData[name]}
                  classes="w-full mt-2"
                  onChange={onChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") loginHandler();
                  }}
                  t={t}
                />
              )}
            </>
          ))}

          <div className={"mt-3"}>
            <RecaptchaV2
              error={recaptchaError}
              onChange={(value) => {
                if (value) {
                  setRecaptchaValue(value);
                  setRecaptchaError(false);
                }
              }}
            />
          </div>

          <p
            className="link w-full mt-1 fs-14"
            onClick={navigateForgetPassword}
          >
            {t("Forgot Password")}?
          </p>
          <Button
            color="orange"
            top={20}
            height="35px"
            classes="minw-90"
            onClick={loginHandler}
            disabled={loading}
            loading={loading}
          >
            {t(data?.submit_button?.title)}
          </Button>
        </div>
        <Divider
          text={t("OR LOG IN WITH")}
          classes="mt-6 mb-3 tracking-tight maxw-70p"
        />
        <div className="flex items-center mt-3">
          {data?.sso.map(({ title, icon, url, connection }, index) => (
            <img
              src={require("../../../" + icon)}
              className="mx-2 cursor-pointer"
              key={title}
              height={40}
              onClick={() => loginWithSSO(connection)}
              alt={`social icon of ${title}`}
            />
          ))}
        </div>
        <div className="text-center my-5 flex fw-500 fs-14">
          <p className="m-0">{t(data?.register_text)}</p>
          <p onClick={navigateRegister} className="link ml-1">
            {t("Register here")}
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
