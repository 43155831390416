import React from "react";

const GettingStartedCard = ({ children }) => {
  return (
    <div data-testid="getting-started-card" className="gettingStarted__card rounded-10 flex-column items-center bg-primary--opaque my-0 mx-1 h-200 w-360 ">
      {children}
    </div>
  );
};

export default GettingStartedCard;
