import { Container, Grid, IconButton } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import AnnouncementBox from "./AnnouncementBox";
import ChatBox from "./ChatBox";
import ChatList from "./ChatList";
import useChallenges from "store/challenges/service-hook";
import styles from "../chats.module.scss";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import { ArrowBackIos } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import useMembers from "store/member-management/service-hook";
import { toast } from "react-toastify";

const ChatsMainContainer = ({
  isAnnouncements,
  setAnnouncements,
  title,
  id,
  chats,
  createChat,
  pages,
  page,
  setPage,
  total,
  search = "",
  setSearch = Function.prototype,
  loading,
  setChats,
  tab,
  setTab,
  inboxCount,
  archiveCount,
  setInboxCount = Function.prototype,
  setArchiveCount = Function.prototype,
  booleanUpdateCurrent,
  setBooleanUpdateCurrent,
  t = (d) => d,
  announcementsCounts = [],
  setAnnouncementCounts = Function.prototype,
}) => {
  const [current, setCurrent] = useState();
  const [chat, setChat] = useState();
  const [showUser, setShowUser] = useState(false);
  const { createAnnouncement, deleteAnnouncement } = useChallenges();
  const width = useWindowWidth();
  const isTab = useMemo(() => width < 900, []);
  const location = useLocation();
  const { getNetworkMembers } = useMembers();

  const showChatList = isTab ? !current : true;
  const showChatBox = isTab ? current : true;

  const handleBack = () => setCurrent(null);

  useEffect(() => {
    if (!booleanUpdateCurrent) return;
    if (chats?.length) {
      isTab ? setCurrent(null) : setCurrent(chats[0]?.id);
      setBooleanUpdateCurrent(false);
    } else setCurrent("new");
  }, [chats]);

  useEffect(() => {
    const fetchAndSetUsers = async (username) => {
      const res = await getNetworkMembers(username);
      if (!res || res.length === 0) {
        toast.error(`Cannot find user with username ${username}`);
        setShowUser(false);
        return;
      }
      setShowUser(res[0]);
    };

    const params = new URLSearchParams(location.search);
    const username = params.get("username");

    if (username) {
      fetchAndSetUsers(username);
    }
  }, []);

  const handleCreateAnnouncement = async (payload) => {
    const res = await createAnnouncement(id, payload);
    if (res?.data?.data?.challenge_announcement) {
      setAnnouncements((val) => [
        res?.data?.data?.challenge_announcement,
        ...val,
      ]);
      const index = {
        send: 0,
        draft: 1,
        scheduled: 2,
      }[res?.data?.data?.challenge_announcement?.status];
      if (index !== undefined) {
        setAnnouncementCounts((prev) => {
          const newCounts = [...prev];
          newCounts[index] += 1;
          return newCounts;
        });
      }
      setCurrent(res?.data?.data?.challenge_announcement?.id);
      setChat();
    }
  };

  const handleDelete = async (aId) => {
    await deleteAnnouncement(id, aId);
    setAnnouncements((val) => {
      const newVal = val.filter((d) => d.id !== aId);
      setCurrent(newVal[0]?.id);
      return newVal;
    });
  };

  return (
    <Container
      disableGutters
      className={`${!isTab && "container"} mt-header ${styles["py-chats"]}`}
    >
      <ChatWrapper isTab={isTab}>
        {showChatList && (
          <ChatList
            current={current}
            setCurrent={setCurrent}
            chats={chats}
            isAnnouncements={isAnnouncements}
            title={title}
            chat={chat}
            setChat={setChat}
            pages={pages}
            page={page}
            setPage={setPage}
            total={total}
            search={search}
            setSearch={setSearch}
            loading={loading}
            setTab={setTab}
            tab={tab}
            inboxCount={inboxCount}
            archiveCount={archiveCount}
            showUser={showUser}
            t={t}
            announcementsCounts={announcementsCounts}
          />
        )}
        {isTab && current && (
          <IconButton
            onClick={handleBack}
            sx={{
              position: "absolute",
              top: 69,
              left: isTab ? 3 : 10,
              zIndex: 1200,
            }}
          >
            <ArrowBackIos fontSize="small" />
          </IconButton>
        )}
        {showChatBox && (
          <>
            {isAnnouncements ? (
              <AnnouncementBox
                announcement={
                  current === "new"
                    ? chat
                    : chats.find((d) => d?.id === current)
                }
                setAnnouncement={setChat}
                onSubmit={handleCreateAnnouncement}
                onDelete={handleDelete}
                t={t}
              />
            ) : (
              <ChatBox
                current={current}
                setCurrent={setCurrent}
                data={
                  current === "new"
                    ? chat
                    : chats.find((d) => d?.id === current)
                }
                create={createChat}
                isChatListEmpty={
                  chats.length < 1 && (!current || current !== "new")
                }
                setAllChats={setChats}
                clearNew={() => setChat()}
                tab={tab}
                usersCount={chats.find((d) => d?.id === current)?.users_count}
                isTab={isTab}
                setInboxCount={setInboxCount}
                setArchiveCount={setArchiveCount}
                showUser={showUser}
                t={t}
              />
            )}
          </>
        )}
      </ChatWrapper>
    </Container>
  );
};

export default ChatsMainContainer;

export const ChatWrapper = ({ children, isTab }) => {
  return (
    <Grid
      container={!isTab}
      className={`${styles.chatContainer} mx-auto shadow rounded-10 bg-white overflow-hidden`}
    >
      {children}
    </Grid>
  );
};
