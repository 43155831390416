import { Grid, LinearProgress } from "@mui/material";
import Button from "components/Button";
import DashboardCard from "./components/DashboardCard";
import { projects_list } from "pages/organization/forms/data";
import React from "react";
import { useNavigate } from "react-router-dom";
import ChallengesTable from "./components/ChallengesTable";
import LabsTable from "./components/LabsTable";
import ProjectCard from "./components/ProjectCard";
import styles from "./dashboard.module.scss";
import cImage from 'assets/svg/green/blurChallenge.svg';
import lImage from 'assets/svg/blue/blurLab.svg';
import pImage from 'assets/svg/yellow/projectBlur.svg';


const banner_list = [
  {
    id: "lab",
    title: "You joined a Lab!",
    name: "UX Designer Lab",
    status: "Completed",
    percent: 100,
    color: "rgba(10, 43, 119, 1)",
  },
  {
    id: "challenge",
    title: "You started your first Challenge!",
    name: "UX Research Challenge - 1",
    status: "In progress",
    percent: 19,
    color: "rgba(5, 94, 73, 1)",
  },
  {
    id: "project",
    title: "You created your first Project!",
    name: "UX Project 1 - Anna",
    status: "Completed",
    percent: 100,
    color: "rgba(147, 73, 0, 1)",
  },
];


const bgStyles ={
  "challenge":{
    backgroundImage:`url(${cImage})`,
    backgroundSize:"210px 250px",
    backgroundRepeat:"no-repeat",
    backgroundPosition:"10px",
    backgroundBlendMode:"lighten",
  },
  "lab":{
    backgroundImage:`url(${lImage})`,
    backgroundSize:"200px 240px",
    backgroundRepeat:"no-repeat",
    backgroundPosition:"10px",
    backgroundBlendMode:"lighten",
  },
  "project":{
    backgroundImage:`url(${pImage})`,
    backgroundSize:"160px 240px",
    backgroundRepeat:"no-repeat",
    backgroundPosition:"10px",
    backgroundBlendMode:"lighten",
  },
}

const UserDashboard2 = () => {
  const navigate = useNavigate();

  const toCreateProject = () => navigate("/projects/create");

  return (
    <Grid container className="w-full" rowGap={3}>
      <h2 className="mt-2">Good Afternoon, Anna!</h2>
      <Grid container spacing={2}>
        {banner_list.map((item, i) => (
          <Grid item xs={12} lg={4} key={i}>
            <div
              className="w-full fw-400 rounded-20 flex flex-column items-center justify-center h-260 c-white text-center"
              style={{ background: item.color,...bgStyles[item.id] }}
            >
              <h3 className="pt-3">{item.title}</h3>
              <h3 className="mt-10">{item.name}</h3>
              <div className="mt-10 w-60p mx-auto flex items-center justify-center">
                <p className="fs-14">{item.status}</p>
                <LinearProgress
                  variant="determinate"
                  value={item.percent}
                  className="mx-2"
                  sx={{ flex: 1, color: "pink" }}
                />
                <p className="fs-14">{item.percent}%</p>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <div className={styles.dashboardLeft}>
            <DashboardCard
              tabs={["My Projects", "Invited", "Following"]}
              projects
              size="auto2"
              list={[]}
              hidePaginator
              rightButton={
                <Button
                  height="30x"
                  classes="fs-12 tracking-right"
                  width="100px"
                  onClick={toCreateProject}
                >
                  Create Project
                </Button>
              }
            >
              <div className="w-full flex opacity-60 fs-14 py-1 mt-2 border-b">
                <p className="w-80p">Title</p>
                <p>Status</p>
              </div>
              {projects_list.map((project, i) => (
                <ProjectCard key={i} project={project} />
              ))}
            </DashboardCard>
          </div>
        </Grid>
        <Grid item xs={12} lg={8}>
          <div className={styles.dashboardRight}>
            <DashboardCard
              tabs={["My Labs", "Invited", "Following"]}
              labs
              size="auto2"
              list={[]}
              hidePaginator
              rightButton={
                <Button
                  height="30x"
                  classes="fs-12 tracking-right"
                  width="100px"
                >
                  Browse Labs
                </Button>
              }
            >
              <LabsTable />
            </DashboardCard>
            <DashboardCard
              tabs={["My Challenges", "Invited", "Following"]}
              challenges
              size="auto2"
              list={[]}
              hidePaginator
              classes="mt-6"
              rightButton={
                <Button
                  height="30x"
                  classes="fs-12 tracking-right"
                  width="120px"
                >
                  Browse Challenges
                </Button>
              }
            >
              <ChallengesTable />
              <Button classes="mx-auto mt-4">Load More</Button>
            </DashboardCard>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserDashboard2;
