import Card from "components/Cards/Card";
import EditCreateHeaderCard from "components/Cards/EditCreateHeaderCard";
import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ChallengePathDetails from "./ChallengePathDetails";
import { Grid } from "@mui/material";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import PageTitle from "components/UI/atoms/PageTitle";
import AddAchievementSection from "components/UI/molecules/AddAchievementSection";
import useForms from "helpers/hooks/useForms";
import CreateFormFooter from "components/UI/atoms/CreateFormFooter";
import AssociateChallenges from "./AssociateChallenges";
import {
  deleteValues,
  keyVal,
  payloadFormatArray,
  payloadKeyVal,
  payloadKeyVals,
  payloadToArrayKeyValues,
  scrollToTop,
} from "helpers/utils/utilities";
import useChallengePaths from "store/challenge-paths/service-hook";
import useChallenges from "store/challenges/service-hook";
import { schema, stepSchema } from "./data";
import { toast } from "react-toastify";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useOrgDashboard from "store/dashboard/org";
import useAssociation from "store/association";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import {isTrue} from "../../../helpers/utils/validators";
const CREATE_CHALLENGEPATH_STEPS = [
  "Challenge Path Program Details",
  "Associated Challenges",
  "Achievements",
];

const AddEditChallengePath = () => {
  const { t } = useLocalizedTranslation();
  const [step, setStep] = useState(0);
  const { id } = useParams();
  const editMode = !!id;
  const { getSubscriptionDetails } = useOrgDashboard();
  const { fetchAssociation } = useAssociation();
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [challenges, setChallenges] = useState({ all: [], selected: [] });
  const [loading, setLoading] = useState(false);
  const [experts, setExperts] = useState([]);
  const [initLoading, setInitLoading] = useState(Boolean(editMode));
  const { create, edit, fetchChallengePath } = useChallengePaths();
  const { fetchChallengeSelectList } = useChallenges();
  const { data, errors, validate, onChange, setData, setErrors } = useForms(
    removeValidatorsFromAchievementsIfSeedPlan(schema),
    {},
    () => removeValidatorsFromAchievementsIfSeedPlan(stepSchema(step))
  );

  useEffect(() => {
    if (editMode) handleFetchChallengePath();
    else {
      resetState();
    }
  }, [id]);

  const resetState = () => {
    setData({});
    setChallenges({ ...challenges, selected: [] });
    setStep(0);
    setLoading(false);
    setData({}); // Use initialData to reset the form data
    setErrors(new Map()); // Reset form errors
  };

  useEffect(() => {
    setChallenges(prev => ({
      all: [],
      selected: []
    }));
    handleFetchChallenges();
  }, [data.privacy]);

  const handleFetchChallenges = async () => {
    const res = await fetchChallengeSelectList({ privacy: isTrue(data?.privacy) ? "private" : "public" });
    setChallenges((val) => ({
      all: res,
      selected: [],
    }));
  };

  const handleFetchChallengePath = async () => {
    const res = await fetchChallengePath(id);
    if (res) {
      const {
        skills,
        level,
        duration,
        category,
        skill_groups,
        skill_stacks,
        media,
        title,
        description,
        category_id,
        privacy,
        level_id,
        duration_id,
        status,
        achievement,
        mode,
        type,
        expert_references,
      } = res;

      const [_challenges] = await fetchAssociation(
        COMPONENT_TYPES.CHALLENGE_PATH,
        id,
        COMPONENT_TYPES.CHALLENGE
      );

      const newChallenge = _challenges?.map((d) => ({
        ...d,
        uuid: d?.id,
      }));

      setData({
        skills: payloadToArrayKeyValues(skills),
        skill_groups: payloadToArrayKeyValues(skill_groups),
        skill_stacks: payloadToArrayKeyValues(skill_stacks),

        media,
        title,
        description,
        privacy,
        category_id: keyVal(category, category_id),
        level_id: keyVal(level, level_id),
        duration_id: keyVal(duration, duration_id),
        challenge_ids: newChallenge?.map((d) => d?.uuid),
        status: status === "published" ? "publish" : "draft",
        type,
        mode,
        ...achievement,
      });
      setExperts(expert_references || []);
      setChallenges((val) => ({
        all: val.all,
        selected: newChallenge?.length ? [...newChallenge] : val.selected || [],
      }));
      setInitLoading(false);
    }
  };

  const onCancel = () => {
    if (step !== 0) setStep(step - 1);
  };

  const handleEditCreate = async () => {
    const payload = {
      ...data,
      is_auto_created: "no",
      is_achievement_enabled: data?.achievement_name ? "yes" : "no",
      challenge_ids: challenges?.selected?.map((d) => d?.uuid),
      expert_references: payloadFormatArray(experts, "email"),
    };
    payloadKeyVal(payload, ["level_id", "category_id", "duration_id"]);
    payloadKeyVals(payload, [
      "skills",
      "tags",
      "skill_groups",
      "skill_stacks",
      "tag_groups",
    ]);

    const deleteVals = [];
    if (editMode) {
      if (!data?.media || typeof data?.media === "string")
        deleteVals.push("media");
      if (typeof data?.achievement_image === "string")
        deleteVals.push("achievement_image");
    }
    deleteValues(payload, deleteVals);
    setLoading(true);
    editMode ? await edit(id, payload) : await create(payload);
    setLoading(false);
  };

  const onContinue = async () => {
    let error = await validate();
    if (step === 1 && !challenges.selected.length) {
      toast.error(t("Please select at least one challenge!"));
      return;
    }
    if (error.size) return;
    if (step !== CREATE_CHALLENGEPATH_STEPS.length - 1) {
      scrollToTop();
      setStep(step + 1);
    } else {
      handleEditCreate();
    }
  };

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  const fetchPlanDetails = async () => {
    const res = await getSubscriptionDetails();
    if (res) {
      setSubscriptionDetails(res);
    }
  };
  function removeValidatorsFromAchievementsIfSeedPlan(schema) {
    if (subscriptionDetails?.plan !== "seed_plan_yearly") {
      return schema;
    } else {
      return schema.map((item) => {
        if (
          item.name === "achievement_points" ||
          item.name === "achievement_name" ||
          item.name === "achievement_image"
        ) {
          const { validator, ...rest } = item;
          return rest;
        }
        return item;
      });
    }
  }

  return (
    <DashboardLayout loading={initLoading}>
      <EditCreateHeaderCard
        current={step}
        length={CREATE_CHALLENGEPATH_STEPS.length}
        subtitles={CREATE_CHALLENGEPATH_STEPS}
        title={`${editMode ? "EDIT" : "CREATE A"} CHALLENGE PATH`}
        classes="mt-header"
        numbered
        rounded
      />
      <Card classes="my-5 container" width="min(1300px,90%)" centered>
        {step === 0 && (
          <ChallengePathDetails
            data={data}
            errors={errors}
            onChange={onChange}
            step={step}
            experts={experts}
            setExperts={setExperts}
            editMode={editMode}
          />
        )}
        {step === 1 && (
          <AssociateChallenges
            challenges={challenges}
            setChallenges={setChallenges}
            t={t}
            data={data}
            onChange={onChange}
          />
        )}
        {step === 2 && (
          <Grid container px={{ xs: 0, md: 4 }}>
            <PageTitle>{t("Achievements")}</PageTitle>
            <CreateFormSection
              classes={`w-full mt-4 ${
                subscriptionDetails?.plan === "seed_plan_yearly"
                  ? "opacity-50"
                  : ""
              }`}
            >
              <AddAchievementSection
                data={data}
                errors={errors}
                onChange={onChange}
                disabled={subscriptionDetails?.plan === "seed_plan_yearly"}
              />
            </CreateFormSection>
          </Grid>
        )}
        <CreateFormFooter
          step={step}
          stepsLength={CREATE_CHALLENGEPATH_STEPS.length}
          onCancel={onCancel}
          onOk={onContinue}
          loading={loading}
          editMode={editMode}
        />
      </Card>
    </DashboardLayout>
  );
};

export default AddEditChallengePath;
