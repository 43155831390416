import {resolveResponse} from "../../api/utils";
import REQUEST from "../../api";
import {ENDPOINTS} from "../../api/endpoints";
import {useSelector} from "react-redux";
import {REDUCER_TYPES} from "../../index";

const useResourceReport = () => {

    /**
     * REDUX STATE
     */
    const {language, token} = useSelector(
        (state) => state[REDUCER_TYPES.AUTH]
    );

    /**
     * HEADER
     * @type {{Authorization: string}}
     */
    const headers = {
        Authorization: "Bearer " + token,
    };

    /**
     *
     * @param slug
     * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
     */
    const resourceModuleMemberProgress = async (slug) => {

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.RESOURCE_MODULE}/${slug}/${ENDPOINTS.MEMBER_PROGRESS}?language=${language}`,
            {},
            headers,
            onSuccess
        );

        if (res?.data?.data) {
            return res?.data?.data
        }

        return null
    }

    const resourceGroupMemberProgress = async (slug) => {

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.RESOURCE_GROUP}/${slug}/${ENDPOINTS.MEMBER_PROGRESS}?language=${language}`,
            {},
            headers,
            onSuccess
        );

        if (res?.data?.data) {
            return res?.data?.data
        }

        return null
    }

    const resourceCollectionMemberProgress = async (slug) => {

        const onSuccess = (res) => resolveResponse(res, null, null, null, true);

        const client = new REQUEST();
        const res = await client.get(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.RESOURCE_COLLECTION}/${slug}/${ENDPOINTS.MEMBER_PROGRESS}?language=${language}`,
            {},
            headers,
            onSuccess
        );

        if (res?.data?.data) {
            return res?.data?.data
        }

        return null
    }

    return {
        resourceModuleMemberProgress,
        resourceGroupMemberProgress,
        resourceCollectionMemberProgress
    }
}

export default useResourceReport
