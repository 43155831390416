export  const images = [
    {
      id: 609,
      title: "Gallery1",
      path: "https://picsum.photos/id/609/367/267"
    },
    {
      id: 1080,
      title: "Gallery2",
      path: "https://picsum.photos/id/1080/367/267"
    },
    {
      id: 1036,
      title: "Gallery3",
      path: "https://picsum.photos/id/1036/367/267"
    },
    {
      id: 1055,
      title: "Gallery3",
      path: "https://picsum.photos/id/1055/367/267"
    },
    {
      id: 1056,
      title: "Gallery3",
      path: "https://picsum.photos/id/1056/367/267"
    },
    {
      id: 1080,
      title: "Gallery2",
      path: "https://picsum.photos/id/1080/367/267"
    },
    {
      id: 1036,
      title: "Gallery3",
      path: "https://picsum.photos/id/1036/367/267"
    },
    {
      id: 1055,
      title: "Gallery3",
      path: "https://picsum.photos/id/1055/367/267"
    },
    {
      id: 1056,
      title: "Gallery3",
      path: "https://picsum.photos/id/1056/367/267"
    },
  ];