import { Done, Info } from "@mui/icons-material";
import Button from "components/Button";
import ImageCard from "components/Cards/ImageCard";
import { InfoCard2 } from "components/Cards/InfoCard";
import PageTitleCard from "components/UI/molecules/PageTitleCard";
import PlanDetails from "components/UI/molecules/PlanDetails";
import MainTabs from "components/UI/organisms/MainTabs";
import PublicDescription from "components/UI/organisms/PublicDescription";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { isTrue } from "helpers/utils/validators";
import AssociateChallengeListing from "pages/associateListings/AssociateChallengeListing";
import AssociateResourceListing from "pages/associateListings/AssociateResourceListing";
import RadeemLabPopup from "pages/labMarketplace/components/RadeemLabPopup";
import MainPageLayout from "pages/layouts/MainPage";
import DashboardLayout from "pages/layouts/dashboard";
import { NotLoggedInTabs } from "pages/organization";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { REDUCER_TYPES } from "store";
import useLabMarketplace from "store/lab-marketplace/service-hook";

const LabMarketplaceDetailView = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redeemLoading, setRedeemLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const { show, redeem } = useLabMarketplace();
  const { t } = useTranslation("labs");
  const [redeemModalOpen, setRedeemModalOpen] = useState(false);
  const [plan, setPlan] = useState(null);
  const { isLoggedIn, user } = useSelector(
    (state) => state[REDUCER_TYPES.AUTH]
  );

  useEffect(() => {
    if (!id) return;
    handleFetchLMDetails();
  }, [id]);

  const handleFetchLMDetails = async () => {
    setLoading(true);
    const res = await show(id);
    setDetail(res);
    setLoading(false);
  };
  const handleSelectPlan = () => {
    const instance = window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_STIE,
      publishableKey: process.env.REACT_APP_CHARGEBEE_KEY,
    });
    let cbPortal = instance.createChargebeePortal();

    cbPortal.open({
      close() {
        window.location.reload();
      },
    });
  };

  const handleRadeemLab = async () => {
    setRedeemLoading(true);
    const res = await redeem(id, user?.organization_details?.id);
    if (res?.data?.data) {
      setDetail((prev) => ({ ...prev, is_redeemed: "yes" }));
      setRedeemModalOpen({ success: true, radeemSlug: res?.data?.data?.slug });
    }
    setRedeemLoading(false);
  };

  const handleOpenRadeemModal = () => {
    if (!plan) return;
    if (
      !plan?.pre_build_lab_limit ||
      ((plan?.pre_build_lab_limit?.toString() || "")?.toLowerCase() !==
        "unlimited" &&
        plan?.pre_build_lab_count === plan?.pre_build_lab_limit)
    ) {
      setRedeemModalOpen("all_credits_exhausted");
    } else {
      setRedeemModalOpen(true);
    }
  };

  return (
    <DashboardLayout loading={loading}>
      <PageTitleCard
        actionTitle="Purchase Credits"
        title="Lab Marketplace"
        actionColor="yellow"
        onClickAction={handleSelectPlan}
      >
        <PlanDetails component={COMPONENT_TYPES.LAB} setMyPlan={setPlan} />
      </PageTitleCard>
      <MainPageLayout classes="my-3" firstLgGridSize={4} secondLgGridSize={8}>
        <div className="left">
          <ImageCard
            image={detail?.media}
            height="250px"
            type={COMPONENT_TYPES.LAB_MARKETPLACE}
            isEmbedded={detail?.media_type === "embedded"}
            title={detail?.title}
          />
          <Button
            color={isTrue(detail?.is_redeemed) ? "green" : "blue"}
            bordered={isTrue(detail?.is_redeemed)}
            onClick={handleOpenRadeemModal}
            classes="mt-2 w-full"
          >
            {isTrue(detail?.is_redeemed) ? (
              <>
                <Done /> {t("Redeemed")}
              </>
            ) : (
              t("Redeem")
            )}
          </Button>
          <PublicDescription
            data={detail}
            component={COMPONENT_TYPES.LAB_MARKETPLACE}
            hideHostedBy
            hideRequirements
            hideProgress
          >
            {detail?.achievement?.achievement_condition && (
              <InfoCard2
                list={Object.values(detail?.achievement?.achievement_condition)}
                classes="border-primary  bg-primary--opaque"
                icon={<Info sx={{ fontSize: "48px" }} color="primary" />}
              />
            )}
          </PublicDescription>
        </div>
        <div className="right">
          <MainTabs
            current={tab}
            setCurrent={setTab}
            tabs={["Challenges", "Resources"]}
          />
          {!isLoggedIn ? (
            <NotLoggedInTabs title="Login to view more details" />
          ) : (
            <>
              {tab === 0 && (
                <AssociateChallengeListing
                  key="challenges"
                  parent={COMPONENT_TYPES.LAB_MARKETPLACE}
                  slug={detail?.slug}
                  defaultSelected="challenge-template"
                  isTemplate
                  smallView
                  showSwitch
                />
              )}
              {tab === 1 && (
                <AssociateResourceListing
                  parent={COMPONENT_TYPES.LAB_MARKETPLACE}
                  showSwitch
                  slug={detail?.slug}
                />
              )}
            </>
          )}
        </div>
      </MainPageLayout>
      <RadeemLabPopup
        handleRadeemLab={handleRadeemLab}
        open={redeemModalOpen}
        onClose={() => setRedeemModalOpen(false)}
        lab={
          redeemModalOpen === "all_credits_exhausted" ? redeemModalOpen : detail
        }
        loading={redeemLoading}
        t={t}
        showSuccess={redeemModalOpen?.success}
        radeemSlug={redeemModalOpen?.radeemSlug}
        plan={plan}
      />
    </DashboardLayout>
  );
};

export default LabMarketplaceDetailView;
