import useRedirect from "helpers/hooks/useRedirect";
import { useSelector, useDispatch } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST, { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { serializeObject, resolveResponse } from "store/api/utils";
import { REDUCER } from "./reducer";
import ACTIONS from "./action-types";

const useChallenges = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state[REDUCER_TYPES.CHALLENGES]);
  const { language, token, user } = useSelector(
    (state) => state[REDUCER_TYPES.AUTH]
  );
  const { navigateChallenge } = useRedirect();

  const headers = {
    Authorization: "Bearer " + token,
  };

  //FETCH PUBLIC CHALLENGES
  const fetchChallenges = async (filters = {}) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${
        ENDPOINTS.CHALLENGE
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      const { list, total_pages, total_count } = res?.data?.data;
      return [list, total_pages, total_count];
    }
    return [];
  };

  //FETCH CHALLENGE PUBLIC DETAILS API
  const fetchPublicChallenge = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE}/${slug}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }

    return res;
  };

  //FETCH MANAGE CHALLENGE LISTING
  const fetchPrivateChallenges = async (filters) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${
        ENDPOINTS.CHALLENGE
      }?language=${language}&${serializeObject(filters)}`,
      {},
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    if (res?.data?.data) {
      const { list, total_pages } = res?.data?.data;
      return [list, total_pages];
    }
    return [];
  };

  //FETCH CHALLENGE PRIVATE DETAILS API
  const fetchChallenge = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return res;
  };

  //DELETE CHALLENGE API
  const deleteChallenge = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);

    const client = new REQUEST();
    const res = await client.delete(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      onSuccess
    );

    return res;
  };

  //FAVORITE CHALLENGE API
  const favorite = async (slug, isCP) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isCP ? ENDPOINTS.CHALLENGE_PATH : ENDPOINTS.CHALLENGE
      }/${slug}/${ENDPOINTS.FAVOURITE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  //UNFAVORITE CHALLENGE API
  const unFavorite = async (slug, isCP) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isCP ? ENDPOINTS.CHALLENGE_PATH : ENDPOINTS.CHALLENGE
      }/${slug}/${ENDPOINTS.UNFAVOURITE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  //LIKE CHALLENGE API
  const like = async (slug, isCP) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isCP ? ENDPOINTS.CHALLENGE_PATH : ENDPOINTS.CHALLENGE
      }/${slug}/${ENDPOINTS.LIKE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  //UNLIKE CHALLENGE API
  const unLike = async (slug, isCP) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isCP ? ENDPOINTS.CHALLENGE_PATH : ENDPOINTS.CHALLENGE
      }/${slug}/${ENDPOINTS.UNLIKE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  //SHARE CHALLENGE API
  const share = async (slug, isCP) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isCP ? ENDPOINTS.CHALLENGE_PATH : ENDPOINTS.CHALLENGE
      }/${slug}/${ENDPOINTS.SHARE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  //CHECK SLUG CHALLENGE API
  const checkSlug = async (slug, isCP) => {
    if (!slug) return;
    const client = new REQUEST();
    const response = await client.get(
      `${ENDPOINTS.MANAGE}/${
        isCP ? ENDPOINTS.CHALLENGE_PATH : ENDPOINTS.CHALLENGE
      }/${ENDPOINTS.CHECK_SLUG}/${slug}?language=${language}`,
      {},
      headers,
      () => {},
      () => {},
      true
    );
    if (response?.data) return response?.data;
    return response;
  };

  //CHECK TITLE CHALLENGE API
  const checkTitle = async (title, isCP) => {
    if (!title) return;
    const client = new REQUEST();
    const response = await client.get(
      `${ENDPOINTS.MANAGE}/${
        isCP ? ENDPOINTS.CHALLENGE_PATH : ENDPOINTS.CHALLENGE
      }/${ENDPOINTS.CHECK_TITLE}/${title}?language=${language}`,
      {},
      headers,
      () => {},
      () => {},
      true
    );
    if (response?.data) return response?.data;
    return response;
  };

  //CREATE CHALLENGE API
  const create = async (payload) => {
    const body = {
      ...payload,
      language,
    };

    const onSuccess = (res) =>
      resolveResponse(
        res,
        (data) => navigateChallenge(data?.slug, true),
        "Challenge created successfully!"
      );

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${ENDPOINTS.CREATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );

    return res;
  };

  // CREATE CHALLENGE PREVIEW USING AI API
  const aiCreateChallengesPreview = async (payload) => {
    const body = {
      ...payload,
      language,
    };

    const onSuccess = (res) =>
      resolveResponse(res, null, (data) => data?.message, null, false);

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}/${ENDPOINTS.PREVIEW}?language=${language}`,
      JSON.parse(JSON.stringify(body)),
      {
        ...headers,
        "Content-Type": "application/json",
      },
      onSuccess
    );
    return res;
  };

  // CREATE CHALLENGE PREVIEW FROM RESOURCE MODULES USING AI API
  const aiCreateChallengesFromResourcesPreview = async (payload) => {
    const body = {
      ...payload,
      language,
    };

    const onSuccess = (res) =>
      resolveResponse(res, null, (data) => data?.message, null, false);

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}/${ENDPOINTS.RESOURCE}/${ENDPOINTS.PREVIEW}?language=${language}`,
      JSON.parse(JSON.stringify(body)),
      {
        ...headers,
        "Content-Type": "application/json",
      },
      onSuccess
    );
    return res;
  };

  // CREATE CHALLENGE USING AI API
  const aiCreateChallenge = async (payload) => {
    const body = {
      ...payload,
      language,
    };

    const onSuccess = (res) => {
      resolveResponse(res, null, (data) => data?.message, null, true);
    };

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}?language=${language}`,
      JSON.parse(JSON.stringify(body)),
      {
        ...headers,
        "Content-Type": "application/json",
      },
      onSuccess
    );
    return res;
  };

  //EDIT CHALLENGE API
  const edit = async (slug, payload) => {
    if (!slug || !payload) return;
    const body = {
      ...payload,
      language,
    };

    const onSuccess = (res) =>
      resolveResponse(
        res,
        () => navigateChallenge(slug, true),
        "Challenge edited successfully!"
      );

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.UPDATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    return res;
  };

  //FETCH CHALLENGE ASSESSMENT
  const fetchAssessment = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${ENDPOINTS.ASSESSMENT}/${slug}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  //UPDATE CHALLENGE ASSESSMENT
  const updateAssessment = async (slug, payload) => {
    if (!slug || !payload) return;
    const body = {
      ...payload,
      language,
    };
    const onSuccess = (res) =>
      resolveResponse(res, null, "Assessment updated successfully!");
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.ASSESSMENT}/${ENDPOINTS.UPDATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    return res;
  };

  //CLONE CHALLENGE API
  const clone = async (slug, organizationId) => {
    if (!slug || !organizationId) return;
    const body = {
      organization_id: organizationId,
      language,
    };
    const onSuccess = (res) =>
      resolveResponse(
        res,
        (data) => navigateChallenge(data?.slug, true),
        "Challenge cloned successfully!"
      );
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.CLONE}?language=${language}`,
      body,
      headers,
      onSuccess
    );
    return res;
  };

  //FETCH CHALLENGE LISTING WITH NAME AND ID
  const fetchChallengeSelectList = async (filters = {}, isCP) => {
    if (
      (!filters || Object.entries(filters || {})?.length === 0) &&
      state?.challenges?.length &&
      !isCP
    )
      return state.challenges;
    const client = new REQUEST();
    const url = filters?.active
      ? `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE}/active-challenge/${
          ENDPOINTS.LIST
        }?language=${language}${
          typeof filters === "string" && !!filters
            ? `&search=${filters}`
            : filters?.search
            ? `&search=${filters.search}`
            : ""
        }${
          filters?.challenge_uuid
            ? `&challenge_uuid=${filters.challenge_uuid}`
            : ""
        }`
      : `${ENDPOINTS.MANAGE}/${
          isCP ? ENDPOINTS.CHALLENGE_PATH : ENDPOINTS.CHALLENGE
        }/${ENDPOINTS.GET_LIST}?language=${language}&${
          typeof filters === "string"
            ? `search=${filters}`
            : serializeObject(filters)
        }`;
    const res = await client.get(
      url,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data?.length) {
      if (!filters || (Object.entries(filters)?.length === 0 && !isCP))
        dispatch(REDUCER[ACTIONS.CHALLENGE_LIST](res?.data?.data));
      return res?.data?.data;
    }
    return [];
  };

  //FETCH ALL CHALLENGE ANNOUNCEMENTS
  const fetchAnnouncements = async (slug, filters = {}) => {
    if (!slug) return [];
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}/${
        ENDPOINTS.ANNOUNCEMENT
      }/${ENDPOINTS.LIST}?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data || {};
    }
    return {};
  };

  //CREATE CHALLENGE ANNOUNCEMENT
  const createAnnouncement = async (slug, payload) => {
    if (!slug || !payload) return;
    const body = {
      ...payload,
      language,
    };
    const onSuccess = (res) =>
      resolveResponse(res, null, "Announcement Created successfully!");
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.ANNOUNCEMENT}/${ENDPOINTS.CREATE}?language=${language}`,
      body,
      headers,
      onSuccess
    );
    return res;
  };

  //DELETE CHALLENGE ANNOUNCEMENT
  const deleteAnnouncement = async (slug, announcementId) => {
    if (!slug || !announcementId) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.delete(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.ANNOUNCEMENT}/${ENDPOINTS.DELETE}?language=${language}&announcement_id=${announcementId}`,
      headers,
      onSuccess
    );
    return res;
  };

  //FETCH CHALLENGE TEMPLATES
  const fetchTemplates = async (filters = {}) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${
        ENDPOINTS.CHALLENGE_TEMPLATE
      }?language=${language}&${serializeObject(filters)}`,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  //SHOW/VIEW CHALLENGE TEMPLATES
  const showTemplate = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_TEMPLATE}/${slug}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  //ADD CHALLENGE TEMPLATES
  const addTemplate = async (slug) => {
    if (!slug) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_TEMPLATE}/${slug}/${ENDPOINTS.ADD}?language=${language}`,
      {},
      headers,
      resolveResponse
    );
    return res;
  };

  //REMOVE/DELETE CHALLENGE TEMPLATES
  const removeTemplate = async (slug) => {
    if (!slug) return;
    const client = new REQUEST();
    const res = await client.delete(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_TEMPLATE}/${slug}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      resolveResponse
    );
    return res;
  };

  //REDEEM CHALLENGE TEMPLATES
  const redeemTemplate = async (
    slug,
    noSuccessToast = false,
    redirect = false
  ) => {
    if (!slug) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_TEMPLATE}/${slug}/${ENDPOINTS.REDEEM}?language=${language}`,
      {
        language,
      },
      headers,
      (r) =>
        resolveResponse(
          r,
          (data) => redirect && navigateChallenge(data?.slug, true),
          null,
          null,
          noSuccessToast
        )
    );
    return res;
  };

  const acceptChallenge = async (slug) => {
    if (!slug || !user || !user?.email) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.INVITATION_MANAGEMENT}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.REQUEST}/${ENDPOINTS.ACCEPT}?language=${language}`,
      { email: user?.email },
      headers,
      onSuccess
    );
    return res;
  };

  const declineChallenge = async (slug) => {
    if (!slug || !user || !user?.email) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.INVITATION_MANAGEMENT}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.REQUEST}/${ENDPOINTS.DECLINE}?language=${language}`,
      { email: user?.email },
      headers,
      onSuccess
    );
    return res;
  };

  const fetchPublicSubmissionList = async (slug, filters = {}) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE}/${slug}/${
        ENDPOINTS.SUBMISSION
      }?language=${language}&${serializeObject(filters)}`,
      {},
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return [];
  };

  const fetchManageSubmissionList = async (slug, filters = {}) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}/${
        ENDPOINTS.SUBMISSION
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return [];
  };

  const fetchProjectAssessedSubmission = async (slug, filters = {}) => {
    if (!slug) return [];
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}/${
        ENDPOINTS.PROJECT_ASSESSED
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data || {};
    }
    return {};
  };

  const fetchWinnerAchievements = async (slug) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}/winner-achievement-list?language=${language}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data?.incentive_achievement?.length
        ? res?.data?.data?.incentive_achievement?.map((d) => ({
            key: d?.achievement_id,
            value: d?.achievement_name,
          }))
        : [];
    }
    return [];
  };

  const fetchWinnerSubmissions = async (slug, filters = {}) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${
        ENDPOINTS.CHALLENGE
      }/${slug}/project-list?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return [];
  };

  const fetchWinnerExport = async (slug) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}/winner-export?language=${language}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      window.open(res?.data?.data);
    }
  };

  const selectWinner = async (slug, payload = {}) => {
    if (!slug || !payload) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}/select-winner?language=${language}`,
      payload,
      headers,
      onSuccess
    );
    return res;
  };

  //Un-submit PROJECT API
    const unSubmitProject = async (slug) => {
      if (!slug) return;
      const body = {
        language,
      };
      const client = new REQUEST();
      const res = await client.post(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE}/${slug}/un-submit-project?language=${language}`,
        body,
        headers,
        resolveResponse
      );
      return res;
    };
  return {
    fetchChallenges,
    fetchChallenge,
    fetchPublicChallenge,
    fetchPrivateChallenges,
    deleteChallenge,
    like,
    unLike,
    favorite,
    unFavorite,
    share,
    checkSlug,
    checkTitle,
    create,
    aiCreateChallengesPreview,
    aiCreateChallengesFromResourcesPreview,
    aiCreateChallenge,
    edit,
    fetchAssessment,
    updateAssessment,
    clone,
    fetchChallengeSelectList,
    fetchAnnouncements,
    createAnnouncement,
    deleteAnnouncement,
    fetchTemplates,
    addTemplate,
    removeTemplate,
    redeemTemplate,
    showTemplate,
    acceptChallenge,
    declineChallenge,
    state,
    fetchPublicSubmissionList,
    fetchManageSubmissionList,
    fetchProjectAssessedSubmission,
    fetchWinnerAchievements,
    fetchWinnerSubmissions,
    fetchWinnerExport,
    selectWinner,
    unSubmitProject,
  };
};

export default useChallenges;
