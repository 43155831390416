import Card from "../../../../components/Cards/Card";
import { ComponentMappings } from "../../data";
import Select from "../../../../components/Input/Select";
import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import ResourceCollectionList from "./partials/ResourceCollectionList";
import ResourceGroupList from "./partials/ResourceGroupList";
import ResourceModulesList from "./partials/ResourceModulesList";
import LabProgramList from "./partials/LabProgramsList";
import LabList from "./partials/LabList";
import useChallengeReportContext from "../hooks/useChallengeReportContext";

const ComponentLists = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { components, activeComponent, setActiveComponent, challengeDetails } =
    useChallengeReportContext();

  /**
   * MEMORIZED
   */
  const ComponentStats = useMemo(() => {
    return [
      {
        title: t("Lab(s)"),
        count: challengeDetails?.component_counts?.labs_count ?? 0,
      },
      {
        title: t("Lab Programs(s)"),
        count: challengeDetails?.component_counts?.lab_programs_count ?? 0,
      },
      {
        title: t("Resource Module(s)"),
        count: challengeDetails?.component_counts?.resource_modules_count ?? 0,
      },
      {
        title: t("Resource Collection(s)"),
        count:
          challengeDetails?.component_counts?.resource_collections_count ?? 0,
      },
      {
        title: t("Resource Group(s)"),
        count: challengeDetails?.component_counts?.resource_groups_count ?? 0,
      },
    ];
  }, [challengeDetails]);

  return (
    <Card title={t("Component Overview")}>
      <div
        className="flex mb-4 items-center"
        style={{ justifyContent: "space-between" }}
      >
        <h4 className="bold fs-16">{t("Component Overview")}</h4>
        <div>
          <Select
            borderedIcon
            value={activeComponent}
            onChange={(e) => setActiveComponent(e.target.value)}
            keyValues={components}
            width={"180px"}
            height={40}
            t={t}
          />
        </div>
      </div>
      <Grid
        container
        spacing={2}
        className="mb-4"
        style={{ justifyContent: "space-between" }}
      >
        {ComponentStats?.map((item) => (
          <Grid item xs={12} md={2} key={item?.title}>
            <p className={"bold text-center fs-15"}>{item?.count}</p>
            <p className={"text-center fs-11"}>{item?.title}</p>
          </Grid>
        ))}
      </Grid>
      {activeComponent === ComponentMappings.all_lab_programs && (
        <LabProgramList t={t} />
      )}
      {activeComponent === ComponentMappings.all_labs && <LabList t={t} />}
      {activeComponent === ComponentMappings.all_resource_collections && (
        <ResourceCollectionList t={t} />
      )}
      {activeComponent === ComponentMappings.all_resource_groups && (
        <ResourceGroupList t={t} />
      )}
      {activeComponent === ComponentMappings.all_resources && (
        <ResourceModulesList t={t}/>
      )}
    </Card>
  );
};

export default ComponentLists;
