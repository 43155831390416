import Input from "components/Input";
import React from "react";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Icon } from "@mui/material";

const PassowordInput = ({
  placeholder,
  title,
  name,
  top,
  error,
  value,
  onChange = Function.prototype,
  classes,
  hideAsterisk,
  t = (d) => d,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);

  const iconProps = {
    component: visible ? VisibilityIcon : VisibilityOffIcon,
    className: "opacity-40 cursor-pointer",
    onClick: () => setVisible(!visible),
  };

  return (
    <Input
      placeholder={placeholder || "Enter password"}
      title={title}
      name={name}
      top={top}
      error={error}
      value={value}
      classes={classes}
      width="100%"
      type={visible ? "text" : "password"}
      onChange={onChange}
      icon={<Icon {...iconProps} />}
      hideAsterisk={hideAsterisk}
      t={t}
      {...rest}
    />
  );
};

export default PassowordInput;
