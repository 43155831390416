import LabReportContextWrapper from "./container/LabReportContext";
import LabReportDetails from "./components/LabReportDetails";
import DashboardLayout from "../../layouts/dashboard";
import LabMembers from "./components/LabMembers";
import LabOverview from "./components/LabOverview";
import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const LabReport = () => {
  const { t } = useLocalizedTranslation();
  const [activeTab, setActiveTab] = useState("overview");
  return (
    <DashboardLayout>
      <LabReportContextWrapper>
        <div className="mt-header mb-5 container-big">
          <LabReportDetails t={t} />
          <div className={"mb-8 mx-8 mt-3"}>
            <Tabs
              value={activeTab}
              onChange={(event, value) => {
                setActiveTab(value);
              }}
            >
              <Tab label={t("Lab Details")} value="overview" />
              <Tab label={t("Lab Members")} value="members" />
            </Tabs>

            <div className={"mt-3"}>
              {/*COMPONENT OVERVIEW*/}
              {activeTab === "overview" ? <LabOverview t={t} /> : ""}

              {/*LAB MEMBERS*/}
              {activeTab === "members" ? <LabMembers t={t} /> : ""}
            </div>
          </div>
        </div>
      </LabReportContextWrapper>
    </DashboardLayout>
  );
};

export default LabReport;
