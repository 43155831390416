import { createSlice } from "@reduxjs/toolkit";
import ACTIONS from "./action-types";

const initialState = {
    settings: null
};

const SLICE = createSlice({
    name: "setting",
    initialState,
    reducers: {
        [ACTIONS.SETTINGS] : (state, { payload }) => {
            state.settings = payload;
        },
    },
});

export const REDUCER = SLICE.actions;
export default SLICE.reducer;