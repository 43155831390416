import { Container, Grid } from "@mui/material";
import DropBox from "components/Input/DropBox";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import CreateFormTitle from "components/UI/atoms/CreateFormTitle";
import React, { useEffect, useState } from "react";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import { schema } from "./data";
import Card from "components/Cards/Card";

const ProjectDetails = ({
  data,
  onChange,
  errors,
  editMode,
  t = (d) => d,
}) => {
  const [projectSchema, setProjectSchema] = useState([...schema]);

  useEffect(() => {
    setProjectSchema((prev) =>
      prev.map((d) => ({
        ...d,
        disabled:
          (d?.name === "challenge_id" && Boolean(editMode)) ||
          (d?.name === "active_labs" && !data?.challenge_id?.key),
        filters:
          d?.name === "active_labs"
            ? { challenge_id: data?.challenge_id?.key }
            : undefined,
      }))
    );
  }, [editMode, data?.challenge_id?.key]);

  return (
    <Container xs={12} md={10} disableGutters>
      <CreateFormTitle title="Overview" classes="mt-2" />
      <CreateFormSection title="Project Overview">
        <SchemaUIExtractor
          schema={projectSchema.slice(0, 6)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
      </CreateFormSection>
    
      <Card classes={"mt-6"}>
          <SchemaUIExtractor
            schema={projectSchema.slice(6, 7)}
            data={data}
            errors={errors}
            onChange={onChange}
          />
      </Card>
    </Container>
  );
};

export default ProjectDetails;
