import { Avatar, Chip, Grid } from "@mui/material";
import Button from "components/Button";
import React from "react";
import Card from "./Card";
import { user } from "assets/svg/gradient/exports";
import useRedirect from "helpers/hooks/useRedirect";

const FriendCard = ({
  image,
  name,
  rank,
  role,
  username,
  actionButtons,
  t = (d) => d,
}) => {
  const { navigateProfile } = useRedirect();

  const handleUsernameClick = () => {
    navigateProfile(username);
  };
  return (
    <Card classes="border overflow-hidden" noShadow>
      <div data-testid="friend-card" className="flex mr-2">
        <Avatar
          src={image || user}
          variant="rounded"
          sx={{ width: 80, height: 80 }}
        />
        <div className="ml-2">
          <div className="flex-center ">
            <p
              onClick={handleUsernameClick}
              className="fs-18 fw-600 link maxw-160 text-nowrap overflowX-hidden textOverflow-ellipsis"
            >
              {name}
            </p>
            <p className="ml-1 opacity-50 fw-500 fs-13 maxw-100 text-nowrap overflowX-hidden textOverflow-ellipsis">
              <i>@{username}</i>
            </p>
          </div>
          <p className="fs-16 capitalize"> {t(role)}</p>
          <Chip
            label={`${t("Rank")} ${rank || "0"}`}
            className="h-20 py-2 "
            color="primary"
            variant="filled"
          />
        </div>
      </div>
      <Grid container spacing={1} mt={1}>
        {actionButtons.map((d, index) =>
          d?.hide ? (
            <Grid item key={index} xs={6}></Grid>
          ) : (
            <Grid item xs={6} key={index}>
              <Button
                bordered={d.color === "red"}
                color={d.color}
                classes="w-full fs-14"
                height={35}
                onClick={d.onClick}
              >
                {t(d.text)}
              </Button>
            </Grid>
          )
        )}
      </Grid>
    </Card>
  );
};

export default FriendCard;
