import {SCHEMA_TYPES} from "../../../helpers/utils/constants";
import {REQUIRED_VALIDATOR} from "../../../helpers/utils/validators";

export const schema = [
    {
        title: "Subject",
        name: "subject_line",
        type: SCHEMA_TYPES.INPUT,
        validator: REQUIRED_VALIDATOR,
        lg: 12,
    },
    {
        title: "Body",
        name: "email_body",
        type: SCHEMA_TYPES.TEXTBOX,
        lg: 12,
        validator: REQUIRED_VALIDATOR,
    },
]
