import { Icon } from "@mui/material";
import { AccessTime, Archive, CalendarMonth, } from "@mui/icons-material";
import { logo } from "assets/svg";

export const getDashboardJSXCols = () => [
    {
        title: "Status",
        width: "5%",
        render: (rowData) => {
            return (
                <div className="flex justify-center">
                    <Icon sx={{ color: "#FFBC3F" }} fontSize="small" component={rowData.status} />
                </div>
            );
        },
    },
    {
        title: "Title",
        width: "60%",
        render: (rowData) => {
            return (
                <p className="fs-14 fw-500 fs-12--mob ml-1"> {rowData.description}</p>
            );
        },
    },
    {
        title: "Deadline",
        width: "30%",
        render: (rowData) => {
            return (
                <div className="flex">
                    <small className="c-lightgray fs-13 mr-4">{rowData?.deadline}</small>
                </div>
            );
        },
    },
    {
        width: "5%",
        render: (rowData) => {
            return (
                <div className="flex items-center">
                    {rowData.active && <span className="blue__dot bg-primary rounded-50" style={{ width: '8px', height: '8px' }}></span>}
                </div>
            );
        },
    },
];


export const CHAT_MESSAGES = [
    {
        senderName: "Caitlin Randle",
        message: "Introduction: Systems thinking ...",
        isSenderOnline: true,
        sentAt: "5 minutes ago",
        read: false,
        isGroup: false,
    },
    {
        senderName: "Caitlin Randle",
        message: "Introduction: Systems thinking ...",
        isSenderOnline: true,
        sentAt: "2 minutes ago",
        read: false,
    },
    {
        senderName: "Caitlin Randle",
        message: "Introduction: Systems thinking ...",
        isSenderOnline: false,
        sentAt: "5 minutes ago",
        read: false,
    },
    {
        senderName: "Caitlin Randle",
        message: "Introduction: Systems thinking ...",
        isSenderOnline: true,
        sentAt: "2 minutes ago",
        read: false,
    },
    {
        senderName: "Caitlin Randle",
        message: "Introduction: Systems thinking ...",
        isSenderOnline: false,
        sentAt: "5 minutes ago",
        read: false,
    }
];

export const dummyListing = [
 {
    id: "1",
   status: AccessTime,
   description: "Beyond Covid-19 Challenge: Food Challenge Food",
   deadline: "Dec 30, 2021",
   active: true,
   associated_challenge: "Beyond Covid-19: Future of Food Challenge",
   logo: logo,
   members: [
     {
       full_name: "Caitlin Randle",
     },
   ],
 },
 {
    id: "2",
   status: AccessTime,
   description: "Beyond Covid-19 Challenge: Food Challenge Food",
   deadline: "Dec 30, 2021",
   active: true,
   associated_challenge: "Beyond Covid-19: Future of Food Challenge",
   logo: logo,
   members: [
     {
       full_name: "Caitlin Randle",
     },
     {
       full_name: "Salar Chagpar",
     },
   ],
 },
 {
    id: "3",
   status: CalendarMonth,
   description: "Beyond Covid-19 Challenge: Food Challenge Food",
   deadline: "Dec 30, 2021",
   active: false,
   associated_challenge: "Beyond Covid-19: Future of Food Challenge",
   logo: logo,
   members: [
     {
       full_name: "Caitlin Randle",
     },
     {
       full_name: "Salar Chagpar",
     },
   ],
 },
 {
    id: "4",
   status: Archive,
   description: "Beyond Covid-19 Challenge: Food Challenge Food",
   deadline: "Dec 30, 2021",
   active: false,
   associated_challenge: "Beyond Covid-19: Future of Food Challenge",
   logo: logo,
   members: [
     {
       full_name: "Caitlin Randle",
     },
     {
       full_name: "Salar Chagpar",
     },
   ],
 },
 {
    id: "5",
   status: Archive,
   description: "Beyond Covid-19 Challenge: Food Challenge Food",
   deadline: "Dec 30, 2021",
   active: false,
   associated_challenge: "Beyond Covid-19: Future of Food Challenge",
   logo: logo,
   members: [],
 },
];


export const news_feed = [
    {
        message: "Beyond COVID-19: Global Open Innovation Challenge",
        sentAt: "20 mins ago",
    },
    {
        message: "Beyond COVID-19: Global Open Innovation Challenge",
        sentAt: "1 hour ago",
    },
    {
        message: "Beyond COVID-19: Global Open Innovation Challenge",
        sentAt: "2 hous ago",
    },
]

export const donut_chart_data = [
    { title: "Completed", value: 145, color: "#1ABC9C" },
    { title: "Started", value: 82, color: "#FEC144" },
    { title: "Not Started", value: 72, color: "#5065A1" },
    { title: "Closed", value: 174, color: "#9496A1" },
];
