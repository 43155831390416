import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import { mockRequestGet, mockRequestPost, mockRequestDelete } from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useResourceCollections from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api"

let getSpy;
let postSpy;
let deleteSpy;

let resourceCollectionId = "1";

const payload = {
    language: "en",
};

const headers = {
    Authorization: "Bearer 1",
};

const store = initStore({
    [REDUCER_TYPES.AUTH]: {
        language: "en",
        token: 1,
        user: { id: 1, email: "test@gmail.com" },
    },
    [REDUCER_TYPES.MASTER]: {},
});

const ResourceCollectionServicesMock = () => {
    const { fetchMyResourceCollection,
        fetchMyResourceCollections,
        fetchResourceCollection,
        fetchResourceCollections,
        deleteResourceCollection, 
    create, 
edit } = useResourceCollections();

    const BUTTON_LINKS = [
        { name: "fetchMyResourceCollection", onClick: () => fetchMyResourceCollection(resourceCollectionId) },
        { name: "fetchMyResourceCollections", onClick: () => fetchMyResourceCollections({}) },
        { name: "fetchResourceCollection", onClick: () => fetchResourceCollection(resourceCollectionId) },
        { name: "fetchResourceCollections", onClick: () => fetchResourceCollections({}) },
        { name: "deleteResourceCollection", onClick: () => deleteResourceCollection(resourceCollectionId) },
        { name: "create", onClick: () => create({}) },
        { name: "edit", onClick: () => edit(resourceCollectionId, {}) },
    ];


    return (
        <div>
            {BUTTON_LINKS.map((item) => (
                <button data-testid={item.name} key={item.name} onClick={item.onClick}>
                    Call {item.name}
                </button>
            ))}
        </div>
    );
};

let screen;

describe("Resource Collection services", () => {
    beforeEach(() => {
        screen = render(
            <Provider store={store}>
                <BrowserRouter>
                    <ResourceCollectionServicesMock />
                </BrowserRouter>
            </Provider>
        );
        getSpy = mockRequestGet();
        postSpy = mockRequestPost();
        deleteSpy = mockRequestDelete();
    });

    test("fetchResourceCollections", async () => {
        const button = screen.getByTestId("fetchResourceCollections");
        userEvent.click(button);

        await waitFor(() =>
            expect(getSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_COLLECTION}?language=en&`,
                {},
                headers,
                expect.any(Function)
            )
        );
    });

    test("fetchResourceCollection", async () => {
        const button = screen.getByTestId("fetchResourceCollection");
        userEvent.click(button);

        await waitFor(() =>
            expect(getSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_COLLECTION}/${resourceCollectionId}?language=en`,
                {},
                headers,
                expect.any(Function)
            )
        );
    });

    test("fetchMyResourceCollections", async () => {
        const button = screen.getByTestId("fetchMyResourceCollections");
        userEvent.click(button);

        await waitFor(() =>
            expect(getSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_COLLECTION}?language=en&`,
                {},
                {
                    ...headers,
                    "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
                },
                expect.any(Function)
            )
        );
    });

    test("fetchMyResourceCollection", async () => {
        const button = screen.getByTestId("fetchMyResourceCollection");
        userEvent.click(button);

        await waitFor(() =>
            expect(getSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_COLLECTION}/${resourceCollectionId}?language=en`,
                {},
                headers,
                expect.any(Function)
            )
        );
    });

    test("deleteResourceCollection", async () => {
        const button = screen.getByTestId("deleteResourceCollection");
        userEvent.click(button);

        await waitFor(() =>
            expect(deleteSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_COLLECTION}/${resourceCollectionId}/${ENDPOINTS.DELETE}?language=en`,
                headers,
                expect.any(Function)
            )
        );
    });
    
    test("create", async () => {
        const button = screen.getByTestId("create");
        userEvent.click(button);

        await waitFor(() =>
            expect(postSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_COLLECTION}/${ENDPOINTS.CREATE}?language=en`,
                {
                    ...payload,
                    status: "publish",
                },
                {
                    ...headers,
                    "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
                },
                expect.any(Function)
            )
        );
    });

    test("edit", async () => {
        const button = screen.getByTestId("edit");
        userEvent.click(button);

        await waitFor(() =>
            expect(postSpy).toHaveBeenCalledWith(
                `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_COLLECTION}/${resourceCollectionId}/${ENDPOINTS.UPDATE}?language=en`,
                {
                    ...payload,
                    status: "publish",
                    _method: "put",
                    language: "en",
                },
                {
                    ...headers,
                    "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
                },
                expect.any(Function)
            )
        );
    });
    
});
