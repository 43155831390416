import EmptyText from "components/UI/atoms/EmptyText";
import NuxLayout from "components/UI/molecules/NuxLayout";
import ManageWrapper from "components/UI/organisms/ManageWrapper";
import useBrowseOptions from "helpers/hooks/useBrowseOptions";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import useListingHeader from "helpers/hooks/useListingHeader";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { getResourceNUX } from "pages/resources/ResourceModule/listing/Manage";
import ResourceListing from "pages/resources/ResourceModule/listing/ResourceListing";
import React, { useEffect, useState } from "react";
import useResourceCollections from "store/resource-collections/service-hook";
import { useResourceCreateOptions } from "pages/resources/ResourceModule/listing/Manage";
import GO1ResourceBuilder from "pages/resources/ResourceModule/go1/ResourceBuilder";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const ManageResourcesCollection = () => {
  const state = useListingHeader();
  const { navigateCreateResourceC } = useRedirect();
  const [resources, setResources] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const { fetchMyResourceCollections, deleteResourceCollection } =
    useResourceCollections();
  const { selected, clearAll } = useFilters();
  const [modal, setModal] = useState(null);
  const browseOptions = useBrowseOptions(COMPONENT_TYPES.RESOURCE);
  const { t } = useLocalizedTranslation();
  const resourceCreateOptions = useResourceCreateOptions(setModal);

  useEffect(() => {
    const abortController = new AbortController();
    handleFetchResources(abortController);
    return () => abortController.abort();
  }, [page, selected, state.sort, state.search]);

  const handleFetchResources = async (abortController) => {
    setLoading(true);
    const filters = {
      ...selected,
      page,
      sort_by: state.sort,
    };
    if (state.search) {
      filters.search = state.search;
    }
    if (filters.resource_source) {
      filters.source = selected.resource_source;
      delete filters.resource_source;
    }
    if (selected.social_activity)
      filters.social_type = selected?.social_activity;

    if (selected?.multi_type) filters.type = selected.multi_type;
    delete filters.multi_type;

    delete filters.social_activity;
    const [list, count, totalCount] = await fetchMyResourceCollections({
      ...filters,
    });
    if (!abortController.signal.aborted) {
      setResources(list || []);
      setPages(count || 0);
      setTotalCount(totalCount || 0);
      setLoading(false);
    }
  };

  const handleDelete = async (slug) => {
    setLoading(true);
    await deleteResourceCollection(slug);
    setResources((val) => val.filter((d) => d.slug !== slug));
    setLoading(false);
  };

  const handleModalClose = () => {
    if (modal === "go1") {
      clearAll();
    }
    setModal(null);
  };
  return (
    <>
      <ManageWrapper
        {...state}
        browseMode={1}
        setBrowseMode={(val) => {}}
        browseOptions={browseOptions}
        createActionTitle="Create"
        navigateCreate={navigateCreateResourceC}
        component={COMPONENT_TYPES.RESOURCE_COLLECTION}
        filters={[
          FILTER_TYPES.MULTI_TYPE,
          FILTER_TYPES.DURATION,
          FILTER_TYPES.LEVEL,
          FILTER_TYPES.SKILLS,
          FILTER_TYPES.PRIVACY,
          FILTER_TYPES.RATING,
          FILTER_TYPES.PROGRESS,
          FILTER_TYPES.RESOURCE_SOURCE,
        ]}
        createOptions={resourceCreateOptions}
      >
        {!resources?.length &&
        !loading &&
        !Object.values(selected).some((d) => d?.length) ? (
          !state.search ? (
            <NuxLayout
              browseMode={1}
              newUserData={getResourceNUX(navigateCreateResourceC)}
            />
          ) : (
            <EmptyText>{t("No results found for this search!")}</EmptyText>
          )
        ) : (
          <>
            {totalCount > 0 && (
              <h3 className="mt-3 ml-1">
                {totalCount ?? 0} {t("Results", "common")}
              </h3>
            )}
            <ResourceListing
              browseMode={2}
              gridType={state.gridType}
              resources={resources}
              setResources={setResources}
              loading={loading}
              page={page}
              pageCount={pages}
              setPage={setPage}
              handleDelete={handleDelete}
              emptyMessage={t("No result found")}
              manage
              isCollection
            />
          </>
        )}
      </ManageWrapper>
      {modal === "go1" ? (
        <GO1ResourceBuilder open={modal} onClose={handleModalClose} />
      ) : null}
    </>
  );
};

export default ManageResourcesCollection;
