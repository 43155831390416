import React from "react";
import useMaster from "../../../../store/master/service-hook";
import SubFilter from "./SubFilter";
import { useAdvanceSearchContext } from "../../hooks/useAdvanceSearchContext";
import { FilterComponentsList } from "../../data";

const Level = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { fetchLevel } = useMaster();
  const { level, setLevel, ActiveFilterComponents } = useAdvanceSearchContext();

  return (
    <SubFilter
      title={t("LEVEL")}
      fetchData={fetchLevel}
      values={level}
      onChange={(checked, value) => {
        let temp = [...level];
        if (checked) {
          temp.push(value);
        } else {
          temp = temp.filter((item) => item !== value);
        }
        setLevel(temp);
      }}
      valueKey={"id"}
      labelKey={"title"}
      show={ActiveFilterComponents.includes(FilterComponentsList.level)}
      t={t}
    />
  );
};

export default Level;
