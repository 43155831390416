import ImageCard from "components/Cards/ImageCard";
import React, { useState } from "react";
import MainPageLayout from "pages/layouts/MainPage";
import MainTabs from "components/UI/organisms/MainTabs";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import useRedirect from "helpers/hooks/useRedirect";
import { Settings, Edit, Delete, CopyAll } from "@mui/icons-material";
import PublicDescription from "components/UI/organisms/PublicDescription";
import { loginToPreceed } from "helpers/utils/utilities";
import useAuth from "store/auth/service-hook";
import useResources from "store/resources/service-hook";
import LikeShareSaveBtns from "components/UI/atoms/LikeShareSaveBtns";
import { isTrue } from "helpers/utils/validators";
import AssociateResourceListing from "pages/associateListings/AssociateResourceListing";
import MenuButton from "components/Button/MenuButton";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import Card from "components/Cards/Card";
import EmptyText from "components/UI/atoms/EmptyText";
import ShareModal from "components/UI/organisms/ShareModal";
import { ENDPOINTS } from "store/api/endpoints";

const resource_tabs = ["Resource Modules", "Resource Collections"];

const ResourceGroupMainPage = ({
  resourceG,
  setResourceG,
  isPrivate,
  onDelete,
  t = (d) => d,
}) => {
  const [tab, setTab] = useState(0);
  const { navigateEditResourceG, navigateManageResources, navigateResourceG } = useRedirect();
  const [loading, setLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [ResourceToDelete, setResourceToDelete] = useState(null);
  const { favorite, unFavorite, like, unLike, share, clone } = useResources();
  const {
    state: { isLoggedIn },
  } = useAuth();
  const [shareOpen, setShareOpen] = useState(false);

  const doUndoAction = async (property, doAction, undoAction) => {
    if (!isLoggedIn) {
      loginToPreceed();
      return;
    }
    if (!resourceG) return;
    const did =
      !!resourceG[property] && resourceG[property]?.toLowerCase() !== "no";
    if (did) {
      await undoAction(resourceG?.slug, false, true);
    } else {
      await doAction(resourceG?.slug, false, true);
    }
    resourceG[property] = did ? "no" : "yes";
    setResourceG({ ...resourceG });
  };

  const handleFavoriteUnFav = () =>
    doUndoAction("favourite", favorite, unFavorite);

  const handleLikeUnlike = () => {
    doUndoAction("liked", like, unLike);
  };

  const handleCloneRG = async () => {
    setLoading(true);
    const res = await clone(resourceG?.slug, false, true);
    if (!res.error) {
      navigateResourceG(res?.data.data.slug, true)
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    await onDelete(resourceG?.slug);
    setLoading(false);
    navigateManageResources();
  };
  const openExitConfirmationModal = (slug) => {
    setResourceToDelete(slug);
    setOpenConfirmationModal(true);
  };

  const cancelExitConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setResourceToDelete(null);
  };

  return (
    <>
      <div className="container flex-reverse mt-header">
        {isPrivate ? (
          <MenuButton
            listWithTitleonClick={[
              {
                title: "Edit",
                onClick: () => navigateEditResourceG(resourceG?.slug),
                icon: Edit,
              },
              {
                title: "Duplicate",
                onClick: handleCloneRG,
                icon: CopyAll,
              },
              {
                title: "Delete",
                onClick: () => openExitConfirmationModal(resourceG?.slug),
                icon: Delete,
                classes: "c-error",
              },
            ]}
            classes="fs-1 fs-13"
            height="30px"
          >
            <Settings fontSize="small" className="mr-1" /> {t("Manage")}
          </MenuButton>
        ) : // <Button
        //   width="320px"
        //   loading={loading}
        //   color={isCloned ? "green" : "primary"}
        //   bordered
        //   onClick={handleCloneRG}
        // >
        //   {isCloned ? <Done /> : <AddBox className="mr-1" />}
        //   {isCloned
        //     ? "Added to My Resource Groups"
        //     : "Add a Copy to My Resource Groups"}
        // </Button>
        null}
      </div>
      <MainPageLayout classes="mt-3" firstLgGridSize={4} secondLgGridSize={8}>
        <div className="left">
          <ImageCard
            image={resourceG?.cover_image}
            type={COMPONENT_TYPES.RESOURCE_GROUP}
            height="250px"
            isEmbedded={resourceG?.media_type === "embedded"}
            title={resourceG?.title}
          />
          <PublicDescription
            component={COMPONENT_TYPES.RESOURCE_GROUP}
            data={resourceG}
            hideRequirements
            hideSocialLinks
            isCreatedby
            showProgress={!isPrivate}
            hideHostedBy
            isPrivate={isPrivate}
          >
            <LikeShareSaveBtns
              isLiked={isTrue(resourceG?.liked)}
              isSaved={isTrue(resourceG?.favourite)}
              onLike={handleLikeUnlike}
              onSave={handleFavoriteUnFav}
              onShare={() => setShareOpen(true)}
              classes="mt-3"
              hideLike
              hideShare={false}
            />
          </PublicDescription>
        </div>
        <div className="right">
          <MainTabs current={tab} setCurrent={setTab} tabs={resource_tabs} />
         {tab===0 && <AssociateResourceListing
            parent={COMPONENT_TYPES.RESOURCE_GROUP}
            slug={resourceG?.slug}
            isPrivateView={isPrivate}
            isGroup={true}
          />}

          {tab===1 && <AssociateResourceListing
            parent={COMPONENT_TYPES.RESOURCE_GROUP}
            slug={resourceG?.slug}
            isPrivateView={isPrivate}
            isGroup={true}
            defaultSelected={ENDPOINTS.RESOURCE_COLLECTION}
          />}

        </div>
      </MainPageLayout>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={cancelExitConfirmationModal}
        desc={t("Are you sure you want to delete this Resource Group?")}
        okayButtonTitle={t("Delete Resource Group")}
        onOkay={() => {
          handleDelete(ResourceToDelete);
          cancelExitConfirmationModal();
        }}
      />
      <ShareModal
        open={shareOpen}
        onClose={() => setShareOpen(false)}
        component={COMPONENT_TYPES.RESOURCE_GROUP}
        slug={resourceG?.slug}
      />
    </>
  );
};

export default ResourceGroupMainPage;
