import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { resolveResponse } from "store/api/utils";
import { serializeObject } from "store/api/utils";

const useDiscussions = () => {
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  const headers = {
    Authorization: "Bearer " + token,
  };

  const get = async (component, slug, filters = {}) => {
    if (!component || !slug) return;
    const client = new REQUEST();
    const res = await client.get(
      `${
        ENDPOINTS.DISCUSSIONS
      }/${component}/${slug}?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return [];
  };

  const add = async (component, slug, payload) => {
    if (!component || !slug || !payload) return;
    const body = {
      ...payload,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.DISCUSSIONS}/${component}/${slug}/${ENDPOINTS.COMMENT}/${ENDPOINTS.ADD}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  const deleteComment = async (component, slug, commentId) => {
    if (!component || !slug || !commentId) return;
    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.DISCUSSIONS}/${component}/${slug}/${ENDPOINTS.COMMENT}/${commentId}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      resolveResponse
    );
  };

  const like = async (component, slug, commentId) => {
    if (!component || !slug || !commentId) return;
    const body = {
      comment_id: commentId,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.DISCUSSIONS}/${component}/${slug}/${ENDPOINTS.COMMENT}/${commentId}/${ENDPOINTS.LIKE}?language=${language}`,
      body,
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  const dislike = async (component, slug, commentId) => {
    if (!component || !slug || !commentId) return;
    const body = {
      comment_id: commentId,
      language,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.DISCUSSIONS}/${component}/${slug}/${ENDPOINTS.COMMENT}/${commentId}/${ENDPOINTS.DISLIKE}?language=${language}`,
      body,
      headers,
      resolveResponse
    );
    if (res?.data?.data) return res?.data?.data;
  };

  return {
    get,
    add,
    deleteComment,
    like,
    dislike,
  };
};

export default useDiscussions;
