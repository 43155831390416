import { resolveResponse, serializeObject } from "../../api/utils";
import REQUEST from "../../api";
import { ENDPOINTS } from "../../api/endpoints";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "../../index";

const useChallengeReport = () => {
  /**
   * REDUX STATE
   */
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  /**
   * HEADER
   * @type {{Authorization: string}}
   */
  const headers = {
    Authorization: "Bearer " + token,
  };

  /**
   *
   * @param slug
   * @returns {Promise<*>}
   */
  const challengeReportDetails = async (slug) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  /**
   *
   * @param slug
   * @param page
   * @param per_page
   * @param search
   * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
   */
  const challengeLabs = async (slug, page, per_page, search = "") => {
    const params = {
      search,
      page,
      per_page,
    };
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${
        ENDPOINTS.LABS
      }?language=${language}&${serializeObject(params)}`,
      {},
      headers,
      onSuccess
    );

    return {
      data: res?.data?.data?.list ?? [],
      meta: res?.data?.data
        ? {
            total: res?.data?.data?.total_count,
            per_page: res?.data?.data?.per_page,
            current_page: res?.data?.data?.current_page,
            search: res?.data?.data?.search,
            total_pages: res?.data?.data?.total_pages,
          }
        : false,
    };
  };

  /**
   *
   * @param slug
   * @param page
   * @param per_page
   * @param search
   * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
   */
  const challengeLabPrograms = async (slug, page, per_page, search = "") => {
    const params = {
      search,
      page,
      per_page,
    };
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${
        ENDPOINTS.LAB_PROGRAMS
      }?language=${language}&${serializeObject(params)}`,
      {},
      headers,
      onSuccess
    );

    return {
      data: res?.data?.data?.list ?? [],
      meta: res?.data?.data
        ? {
            total: res?.data?.data?.total_count,
            per_page: res?.data?.data?.per_page,
            current_page: res?.data?.data?.current_page,
            search: res?.data?.data?.search,
            total_pages: res?.data?.data?.total_pages,
          }
        : false,
    };
  };

  /**
   *
   * @param slug
   * @param page
   * @param per_page
   * @param search
   * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
   */
  const challengeResourceModules = async (
    slug,
    page,
    per_page,
    search = ""
  ) => {
    const params = {
      search,
      page,
      per_page,
    };
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${
        ENDPOINTS.RESOURCE_MODULES
      }?language=${language}&${serializeObject(params)}`,
      {},
      headers,
      onSuccess
    );

    return {
      data: res?.data?.data?.list ?? [],
      meta: res?.data?.data
        ? {
            total: res?.data?.data?.total_count,
            per_page: res?.data?.data?.per_page,
            current_page: res?.data?.data?.current_page,
            search: res?.data?.data?.search,
            total_pages: res?.data?.data?.total_pages,
          }
        : false,
    };
  };

  /**
   *
   * @param slug
   * @param page
   * @param per_page
   * @param search
   * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
   */
  const challengeResourceGroup = async (slug, page, per_page, search = "") => {
    const params = {
      search,
      page,
      per_page,
    };
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${
        ENDPOINTS.RESOURCE_GROUPS
      }?language=${language}&${serializeObject(params)}`,
      {},
      headers,
      onSuccess
    );

    return {
      data: res?.data?.data?.list ?? [],
      meta: res?.data?.data
        ? {
            total: res?.data?.data?.total_count,
            per_page: res?.data?.data?.per_page,
            current_page: res?.data?.data?.current_page,
            search: res?.data?.data?.search,
            total_pages: res?.data?.data?.total_pages,
          }
        : false,
    };
  };

  /**
   *
   * @param slug
   * @param page
   * @param per_page
   * @param search
   * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
   */
  const challengeResourceCollection = async (
    slug,
    page,
    per_page,
    search = ""
  ) => {
    const params = {
      search,
      page,
      per_page,
    };
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${
        ENDPOINTS.RESOURCE_COLLECTIONS
      }?language=${language}&${serializeObject(params)}`,
      {},
      headers,
      onSuccess
    );

    return {
      data: res?.data?.data?.list ?? [],
      meta: res?.data?.data
        ? {
            total: res?.data?.data?.total_count,
            per_page: res?.data?.data?.per_page,
            current_page: res?.data?.data?.current_page,
            search: res?.data?.data?.search,
            total_pages: res?.data?.data?.total_pages,
          }
        : false,
    };
  };

  /**
   *
   * @param slug
   * @returns {Promise<{data: (*|*[]), meta: ({per_page: *, total: *, total_pages: *, current_page: *}|boolean)}>}
   */
  const challengeMemberProgress = async (slug) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.MEMBER_PROGRESS}?language=${language}`,
      {},
      headers,
      onSuccess
    );

    if (res?.data?.data) {
      return res?.data?.data;
    }

    return null;
  };

  const challengePathMemberProgress = async (slug) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE_PATH}/${slug}/${ENDPOINTS.MEMBER_PROGRESS}?language=${language}`,
      {},
      headers,
      onSuccess
    );

    if (res?.data?.data) {
      return res?.data?.data;
    }

    return null;
  };

  const challengeAssociatedProjectStats = async (slug) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.ASSOCIATED_PROJECTS}?language=${language}`,
      {},
      headers,
      onSuccess
    );

    if (res?.data?.data) {
      return res?.data?.data;
    }

    return null;
  };

  const challengeEngagement = async (slug) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.ENGAGEMENT}?language=${language}`,
      {},
      headers,
      onSuccess
    );

    if (res?.data?.data) {
      return res?.data?.data;
    }

    return null;
  };

  const challengeMembers = async (slug, page) => {
    const params = {
      page,
    };

    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${
        ENDPOINTS.MEMBERS
      }?language=${language}&${serializeObject(params)}`,
      {},
      headers,
      onSuccess
    );

    return {
      data: res?.data?.data?.list ?? [],
      meta: res?.data?.data
        ? {
            total: res?.data?.data?.total_count,
            per_page: res?.data?.data?.per_page,
            current_page: res?.data?.data?.current_page,
            total_pages: res?.data?.data?.total_pages,
          }
        : false,
    };
  };

  const ChallengeAchievementDetails = async (slug, page, assessment_type) => {
    const params = {
      page,
    };

    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${
        ENDPOINTS.ACHIEVEMENTS
      }?language=${language}&${serializeObject(params)}`,
      {},
      headers,
      onSuccess
    );

    return {
      data: res?.data?.data?.list ?? [],
      meta: res?.data?.data
        ? {
            total: res?.data?.data?.total_count,
            per_page: res?.data?.data?.per_page,
            current_page: res?.data?.data?.current_page,
            total_pages: res?.data?.data?.total_pages,
            total_points: res?.data?.data?.total_points,
          }
        : false,
    };
  };
  const ChallengeAssessmentReports = async (slug, page, assessment_type) => {
    const params = {
      page,
    };

    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${
        ENDPOINTS.ASSESSMENTS
      }?language=${language}&assessment_type=${assessment_type}&${serializeObject(
        params
      )}`,
      {},
      headers,
      onSuccess
    );

    return {
      data: res?.data?.data?.list ?? [],
      meta: res?.data?.data
        ? {
            total: res?.data?.data?.total_count,
            per_page: res?.data?.data?.per_page,
            current_page: res?.data?.data?.current_page,
            total_pages: res?.data?.data?.total_pages,
            assessor: res?.data?.data?.assessor,
            project_assessed: res?.data?.data?.project_assessed,
            project_pending_assignment:
              res?.data?.data?.project_pending_assignment,
            winner_selected: res?.data?.data?.winner_selected,
          }
        : false,
    };
  };
  const challengeAssessmentProjectDetails = async (slug, project_slug) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.ASSESSMENTS}/${project_slug}?language=${language}`,
      {},
      headers,
      onSuccess
    );

    if (res?.data?.data) {
      return res?.data?.data;
    }

    return null;
  };
  const challengeMemberActivity = async (slug) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.MEMBER_ACTIVITY}?language=${language}`,
      {},
      headers,
      onSuccess
    );

    if (res?.data?.data) {
      return res?.data?.data;
    }

    return null;
  };

  /**
   *
   * @param slug
   * @returns {Promise<void>}
   */
  const downloadChallengeReport = async (slug) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.CHALLENGE}/${slug}/${ENDPOINTS.EXPORT}?language=${language}`,
      {},
      {
        ...headers,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      Function.prototype,
      Function.prototype,
      false,
      "blob"
    );
    if (res?.data) {
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${slug}.xlsx`;
      link.click();
    }
  };

  return {
    challengeMemberProgress,
    challengePathMemberProgress,
    challengeReportDetails,
    challengeLabs,
    challengeLabPrograms,
    challengeResourceModules,
    challengeResourceGroup,
    challengeResourceCollection,
    challengeAssociatedProjectStats,
    challengeEngagement,
    challengeMembers,
    ChallengeAchievementDetails,
    ChallengeAssessmentReports,
    challengeAssessmentProjectDetails,
    challengeMemberActivity,
    downloadChallengeReport,
  };
};

export default useChallengeReport;
