import Button from "components/Button";
import React from "react";
import FormLogo from "../atoms/FormLogo";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";

const AuthCard = ({
  title,
  description,
  disableNext,
  handleNext,
  handleBack,
  loading,
  backButtonTitle,
  nextButtonTitle,
  children,
  icon,
  hideNextBack,
  hideBackButton,
  showBack,
  endContent = null,
  descriptionClasses = "",
}) => {
  const { t } = useTranslation("auth");

  return (
    <div className="auth-card relative">
      {showBack && (
        <IconButton onClick={handleBack} className="absolute top-5 left-10">
          <ChevronLeft className="c-primary fs-30" />
        </IconButton>
      )}
      {icon ? icon : <FormLogo />}
      <h2 className="mt-4  text-center">{t(title)}</h2>
      {description && (
        <p className={`px-8 mt-2 mb-10 fs-14 text-center ${descriptionClasses}`}>{t(description)}</p>
      )}
      {children}
      {!hideNextBack && (
        <div
          className={`nextback__buttons mt-10 ${
            hideBackButton && "flex-center justify-center"
          }`}
        >
          {!hideBackButton && (
            <Button bordered height="40px" onClick={handleBack} width="120px">
              {backButtonTitle ? t(backButtonTitle) : t("Back")}
            </Button>
          )}
          <Button
            height="40px"
            width="120px"
            onClick={handleNext}
            loading={loading}
            disabled={disableNext}
            classes="px-2"
          >
            {t(nextButtonTitle)}
          </Button>
        </div>
      )}
      {endContent}
    </div>
  );
};

export default AuthCard;
