import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {
  mockRequestGet,
  mockRequestPost,
  mockRequestDelete,
} from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useChats from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

let getSpy;
let postSpy;
let deleteSpy;

jest.mock("laravel-echo");
jest.mock("pusher-js");

let type = "1";
let uuid = "1";

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
});

const ChatsServicesMock = () => {
  const {
    list,
    create,
    markSeen,
    archive,
    unarchive,
    deleteChat,
    setOnline,
    setOffline,
    getChat,
    sendMessage,
    deleteMessage,
    socket,
  } = useChats();

  const BUTTON_LINKS = [
    { name: "list", onClick: () => list(type, {}) },
    { name: "create", onClick: () => create({}) },
    { name: "markSeen", onClick: () => markSeen(uuid) },
    { name: "archive", onClick: () => archive(uuid) },
    { name: "unarchive", onClick: () => unarchive(uuid) },
    { name: "deleteChat", onClick: () => deleteChat(uuid) },
    { name: "setOnline", onClick: () => setOnline() },
    { name: "setOffline", onClick: () => setOffline(true) },
    { name: "getChat", onClick: () => getChat(uuid) },
    { name: "sendMessage", onClick: () => sendMessage(uuid, {}) },
    { name: "deleteMessage", onClick: () => deleteMessage(uuid) },
    { name: "socket", onClick: () => socket() },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Chats Services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <ChatsServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
    deleteSpy = mockRequestDelete();
  });

  test("list", async () => {
    const button = screen.getByTestId("list");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${type}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("create", async () => {
    const button = screen.getByTestId("create");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${ENDPOINTS.CREATE}?language=en`,
        { type: "message", language: "en" },
        headers,
        expect.any(Function)
      )
    );
  });

  test("markSeen", async () => {
    const button = screen.getByTestId("markSeen");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${uuid}/seen?language=en`,
        {},
        headers,
        undefined
      )
    );
  });

  test("archive", async () => {
    const button = screen.getByTestId("archive");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${uuid}/archive?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("unarchive", async () => {
    const button = screen.getByTestId("unarchive");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${uuid}/un-archive?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("deleteChat", async () => {
    const button = screen.getByTestId("deleteChat");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${uuid}/delete?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("setOnline", async () => {
    const button = screen.getByTestId("setOnline");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${ENDPOINTS.USER}/1/${ENDPOINTS.ONLINE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("setOffline", async () => {
    const button = screen.getByTestId("setOffline");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${ENDPOINTS.USER}/1/${ENDPOINTS.OFFLINE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("getChat", async () => {
    const button = screen.getByTestId("getChat");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${uuid}/${ENDPOINTS.MESSAGE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("sendMessage", async () => {
    const button = screen.getByTestId("sendMessage");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${uuid}/${ENDPOINTS.MESSAGE}/${ENDPOINTS.CREATE}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("deleteMessage", async () => {
    const button = screen.getByTestId("deleteMessage");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.CHAT}/${ENDPOINTS.CONVERSATION}/${ENDPOINTS.MESSAGE}/${uuid}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("invoke socket", () => {
    const expectedConfig = {
      broadcaster: "pusher",
      key: "preprwebsocket",
      authEndpoint: "undefinedbroadcasting/auth",
      bearerToken: 1,
      cluster: "mt1",
      wsHost: "betaapis.learnlab.ai",
      wssPort: "6001",
      forceTLS: true,
      encrypted: false,
    };

    const mockEchoInstance = { something: "mocked" };
    Echo.mockImplementationOnce(() => mockEchoInstance);

    const button = screen.getByTestId("socket");
    userEvent.click(button);

    expect(Echo).toHaveBeenCalledTimes(1);
    expect(Echo).toHaveBeenCalledWith(expectedConfig);
  });
});
