import { Info, Done } from "@mui/icons-material";
import Button from "components/Button";
import ImageCard from "components/Cards/ImageCard";
import MainPageLayout from "pages/layouts/MainPage";
import MainTabs from "components/UI/organisms/MainTabs";
import React, { useEffect, useState } from "react";
import ActivityFeed from "./ActivityFeed";
import { getLeaderboardJSXCols } from "pages/leaderBoard/data";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES, ROLES } from "helpers/utils/constants";
import PublicDescription from "../../../components/UI/organisms/PublicDescription";
import Members from "components/UI/molecules/Members";
import Discussions from "components/UI/organisms/Discussions";
import Card from "components/Cards/Card";
import useLabMarketplace from "store/lab-marketplace/service-hook";
import { isTrue } from "helpers/utils/validators";
import LiveEventSchedule from "./LiveEventSchedule";
import LiveEventButton from "./LiveEventButton";
import SendEventInvitation from "./SendEventInvitation";
import { Grid, Stack } from "@mui/material";
import LeaderBoardTable from "components/UI/organisms/LeaderBoardTable";
import useLeaderboard from "store/leaderboard/service-hook";
import useAuth from "store/auth/service-hook";
import MiniOnboarding from "components/UI/organisms/MiniOnboarding";
import useOnboarding from "store/onboarding/service-hook";
import useLabs from "store/labs/service-hook";
import AssociateChallengeListing from "pages/associateListings/AssociateChallengeListing";
import { ENDPOINTS } from "store/api/endpoints";
import AssociateResourceListing from "pages/associateListings/AssociateResourceListing";
import RelatedContent from "pages/associateListings/RelatedContent";
import { InfoCard2 } from "components/Cards/InfoCard";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { NotLoggedInTabs } from "pages/organization";
import AssociateProjectListing from "pages/associateListings/AssociateProjectListing";
import ShareModal from "components/UI/organisms/ShareModal";
import LikeShareSaveBtns from "components/UI/atoms/LikeShareSaveBtns";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { payloadFormatArray } from "helpers/utils/utilities";
import LabManageOptions from "./LabManageOptions";
import { cachedLoginNavigate } from "store/api/utils";

const getLabTabs = (isLiveEvent) =>
  [
    "Challenges",
    "Events",
    "Projects",
    "Resources",
    "Discussion",
    "Members",
    "Activity Feed",
    "Leaderboard",
  ].filter((d) => isLiveEvent || d !== "Events");

const LabMainPage = ({ lab, isPrivate, setLab }) => {
  const [tab, setTab] = useState(0);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [completeOnboarding, setCompleteOnboarding] = useState(false);
  const { navigateManageLabs } = useRedirect();
  const { add, remove } = useLabMarketplace();
  const { labList } = useLeaderboard();
  const [leaderboardItems, setLeaderboardItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [challengeIdFilter, setChallengeIdFilter] = useState();
  const { completeLabMiniOnboarding } = useOnboarding();
  const { join, deleteLab, unJoin, getActivity, favorite, unFavorite } =
    useLabs();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [activity, setActivity] = useState([]);
  const [isRequestJoinVisible, setIsRequestJoinVisible] = useState(false);
  const { t } = useLocalizedTranslation("achievements");

  const {
    state: { user, isLoggedIn },
  } = useAuth();

  useEffect(() => {
    if (isLoggedIn && !isTrue(user?.is_lab_onboarding)) setShowOnboarding(true);
  }, []);

  useEffect(() => {
    if (!lab?.slug || !isLoggedIn || !isTrue(lab?.joined)) return;
    fetchLeaderboardList(lab?.slug);
  }, [lab?.slug, page, challengeIdFilter]);

  useEffect(() => {
    fetchActivity();
  }, [lab?.slug]);

  const fetchActivity = async () => {
    const res = await getActivity(lab?.slug);
    setActivity(res);
  };

  const fetchLeaderboardList = async (slug) => {
    setLoading(true);
    const filters = { page };
    if (challengeIdFilter?.length)
      filters.challenge_id = payloadFormatArray(challengeIdFilter, "key");
    const [_list, _pages] = await labList(slug, filters);
    setLeaderboardItems(_list || []);
    setPages(_pages || 1);
    setLoading(false);
  };

  const addToMarketplace = async () => {
    if (isTrue(lab?.is_pre_build)) {
      await remove(lab?.slug);
      setLab((prev) => ({ ...prev, is_pre_build: "no" }));
    } else {
      await add(lab?.slug);
      setLab((prev) => ({ ...prev, is_pre_build: "yes" }));
    }
  };

  // const handleCloneLab = async () => {
  //   setLoading(true);
  //   const res = await clone(lab?.slug);
  //   if (!res.error) {
  //     setIsCloned(true);
  //   }
  //   setLoading(false);
  // };

  const handleCloseOnboarding = () => {
    if (completeOnboarding) completeLabMiniOnboarding();
    setShowOnboarding(false);
  };

  /**
   * COMPUTED PROPERTIES
   */
  const isLiveEventEnabled = isTrue(lab?.is_live_event_enabled);

  const doUndoAction = async (property, doAction, undoAction) => {
    if (!lab) return;
    const did = !!lab[property] && lab[property]?.toLowerCase() !== "no";
    if (did) {
      await undoAction(lab?.slug);
      setIsPending(false);
      if (property === "joined" && isTrue(lab.privacy)) {
        setIsRequestJoinVisible(true);
      }
    } else {
      await doAction(lab?.slug);
      if (property === "joined") {
        if (isTrue(lab.privacy)) setIsPending(true);
        setIsRequestJoinVisible(false);
      }
    }
    lab[property] =
      property === "joined" && !did
        ? isTrue(lab?.privacy)
          ? "Pending"
          : "yes"
        : did
        ? "no"
        : "yes";
    setLab({ ...lab });
  };

  const handleJoinUnjoin = () => doUndoAction("joined", join, unJoin);
  const handleFavoriteUnFav = () =>
    doUndoAction("favourite", favorite, unFavorite);
  const handleDeleteLab = async (slug) => {
    setLoading(true);
    await deleteLab(slug);
    navigateManageLabs();
    setLoading(false);
  };

  const onDeleteLab = () => {
    handleDeleteLab(openConfirmationModal);
    setOpenConfirmationModal(false);
  };

  const isSuperAdmin =
    user?.roles === ROLES.SUPER_ADMIN ||
    user?.roles === ROLES.CUSTOMER_SUCCESS ||
    user?.roles === ROLES.DEVELOPER;

  return (
    <>
      <div className="flex-reverse container mt-header">
        {isPrivate && (
          <LabManageOptions
            data={lab}
            onDelete={onDeleteLab}
            addToMarketplace={addToMarketplace}
            isSuperAdmin={isSuperAdmin}
          />
        )}
      </div>
      <MainPageLayout classes="my-3" firstLgGridSize={4} secondLgGridSize={8}>
        <div className="left">
          <ImageCard
            image={lab?.media}
            height="250px"
            type="lab"
            isEmbedded={lab?.media_type === "embedded"}
            title={lab?.title}
          />

          {isLiveEventEnabled && tab === 1 && isTrue(lab?.is_user_invited) ? (
            <Grid container spacing={1}>
              <Grid item xs={user.roles === "user" ? 12 : 6}>
                <LiveEventButton lab={lab} t={t} />
              </Grid>
              {user.roles !== "user" && (
                <Grid item xs={6}>
                  <SendEventInvitation lab={lab} t={t} />
                </Grid>
              )}
            </Grid>
          ) : null}
          <Stack direction="row" alignItems="center" gap={1} className="mt-2">
            <Button
              onClick={!isLoggedIn ? cachedLoginNavigate : handleJoinUnjoin}
              bordered={
                isTrue(lab.joined) ||
                lab.joined?.toLowerCase() === "pending" ||
                isPending
              }
              color={
                lab.joined?.toLowerCase() === "pending" || isPending
                  ? "green"
                  : (isTrue(lab.privacy) &&
                      !isTrue(lab?.joined) &&
                      isLoggedIn) ||
                    isRequestJoinVisible
                  ? "blue"
                  : isTrue(lab.joined)
                  ? "red"
                  : "green"
              }
              disabled={lab.joined?.toLowerCase() === "pending" || isPending}
              classes="flex-1"
            >
              {lab.joined?.toLowerCase() === "pending" || isPending ? (
                <>
                  <Done fontSize="small" className="mr-1" /> {t("Request Sent")}
                </>
              ) : isTrue(lab.joined) ? (
                t("Unjoin Lab")
              ) : (isTrue(lab.privacy) && !isTrue(lab.joined) && isLoggedIn) ||
                isRequestJoinVisible ? (
                t("Request to Join")
              ) : (
                t("Join Lab")
              )}
            </Button>
            {isTrue(lab?.is_manage_enable) ? (
              <LabManageOptions
                classes="flex-1 h-35"
                data={lab}
                onDelete={onDeleteLab}
                addToMarketplace={addToMarketplace}
                isSuperAdmin={isSuperAdmin}
              />
            ) : null}
          </Stack>

          <PublicDescription
            data={lab}
            component={COMPONENT_TYPES.LAB}
            hideHostedBy
            hideRequirements
            hideProgress={!isTrue(lab?.joined)}
            isPrivate={isPrivate}
          >
            <LikeShareSaveBtns
              hideLike
              isSaved={isTrue(lab?.favourite)}
              onSave={handleFavoriteUnFav}
              onShare={() => setShareOpen(true)}
            />

            {lab?.achievement?.achievement_condition && (
              <InfoCard2
                list={Object.values(lab?.achievement?.achievement_condition)}
                classes="border-primary  bg-primary--opaque"
                icon={<Info sx={{ fontSize: "48px" }} color="primary" />}
              />
            )}
          </PublicDescription>
        </div>
        <div className="right">
          <MainTabs
            current={tab}
            setCurrent={setTab}
            tabs={getLabTabs(isLiveEventEnabled)}
          />
          {!isLoggedIn ? (
            <NotLoggedInTabs title="Login to view more details" />
          ) : isTrue(lab?.privacy) && !isTrue(lab?.joined) && !isPrivate ? (
            <Card classes="mt-4 minh-300  flex flex-column items-center justify-center">
              <h4
                className="text-center c-gray maxw-50p fs-20"
                style={{ color: "#404252" }}
              >
                {isTrue(lab?.privacy) && isLoggedIn
                  ? t(
                      "This Lab is private, send a request to join to view all the details"
                    )
                  : t("This Lab is public, join to view all the details")}
              </h4>
              <div className="flex mt-6 text-center justify-center">
                <Button
                  disabled={lab?.joined === "Pending" || isPending}
                  bordered={lab?.joined === "Pending" || isPending}
                  color={
                    lab?.joined === "Pending" || isPending ? "green" : "blue"
                  }
                  width="160px"
                  height="35px"
                  onClick={handleJoinUnjoin}
                >
                  {isPending || lab?.joined === "Pending" ? (
                    <>
                      <Done fontSize="small" className="mr-1" />{" "}
                      {t("Request Sent")}
                    </>
                  ) : isTrue(lab?.joined) ? (
                    t("Unjoin Lab")
                  ) : isRequestJoinVisible ||
                    (isTrue(lab?.privacy) && isLoggedIn) ? (
                    t("Request to Join")
                  ) : (
                    t("Join Lab")
                  )}
                </Button>
              </div>
            </Card>
          ) : (
            <>
              {
                [
                  <AssociateChallengeListing
                    key="challenges"
                    parent={ENDPOINTS.LAB}
                    slug={lab?.slug}
                    isPrivateView={isPrivate}
                    showSwitch
                    isSequential={isTrue(lab?.is_sequential)}
                  />,

                  isLiveEventEnabled && (
                    <LiveEventSchedule
                      key="events"
                      isLiveEventEnabled={isLiveEventEnabled}
                      t={t}
                    />
                  ),

                  <AssociateProjectListing
                    key="projects"
                    parent={COMPONENT_TYPES.LAB}
                    slug={lab?.slug}
                    isPrivateView={isPrivate}
                  />,

                  <AssociateResourceListing
                    key="resources"
                    parent={COMPONENT_TYPES.LAB}
                    slug={lab?.slug}
                    isPrivateView={isPrivate}
                    showSwitch
                  />,

                  <Card key="discussions" classes="my-4">
                    <Discussions
                      component={COMPONENT_TYPES.LAB}
                      slug={lab?.slug}
                    />
                  </Card>,

                  <Members
                    key="members"
                    component={COMPONENT_TYPES.LAB}
                    createdBy={lab?.created_by}
                  />,

                  <ActivityFeed key="activity" list={activity} />,

                  <LeaderBoardTable
                    key="leaderboard"
                    isLab
                    rows={leaderboardItems}
                    loading={loading}
                    columns={getLeaderboardJSXCols((v) => t(v, "achievements"))}
                    page={page}
                    setPage={setPage}
                    pageCount={pages}
                    filter={challengeIdFilter}
                    setFilter={setChallengeIdFilter}
                    username={user?.username}
                    apiFilters={{
                      slug: lab?.slug,
                    }}
                    masterSelectName="l_lab_challenge_id"
                  />,
                ].filter(Boolean)[tab]
              }

              {tab !== 8 && (
                <RelatedContent
                  component={ENDPOINTS.LAB}
                  classes="mt-4"
                  title={t("Related Labs")}
                  slug={lab?.slug}
                />
              )}
            </>
          )}
        </div>
      </MainPageLayout>
      <MiniOnboarding
        open={showOnboarding}
        onClose={handleCloseOnboarding}
        data={lab}
        component={COMPONENT_TYPES.LAB}
        setCompleted={setCompleteOnboarding}
      />
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        desc="You will delete this lab permanently, are you sure?"
        okayButtonTitle="Delete"
        onOkay={onDeleteLab}
      />
      <ShareModal
        open={shareOpen}
        onClose={() => setShareOpen(false)}
        component={COMPONENT_TYPES.LAB}
        slug={lab?.slug}
      />
    </>
  );
};

export default LabMainPage;
