import React from "react";
import NotificationList from "./NotificationList";
import styles from "../../chats/chats.module.scss";
import { useTranslation } from "react-i18next";

const NotificationsMainPage = () => {
  const { t } = useTranslation("common");
  return (
    <div className={`${styles["py-chats"]} container mt-header`}>
      <div
        className={`${styles.chatContainer} mx-auto shadow rounded-10 bg-white overflow-hidden`}
      >
        <NotificationList t={t} />
      </div>
    </div>
  );
};

export default NotificationsMainPage;
