import { Share, Visibility } from "@mui/icons-material";
import { Avatar, Checkbox, Chip, Grid } from "@mui/material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import React from "react";
import cx from "classnames";
import { isTrue } from "helpers/utils/validators";
import moment from "moment";
import goldCupTrophy from "assets/png/goldTrophy.png";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { getAchievementTypeKey } from "../helpers";

const AchievementCard = ({
  data,
  gridType,
  setModal,
  onClickView,
  setFeatured,
}) => {
  const { t } = useLocalizedTranslation();
  const openModal = () => setModal(data);
  const redirect = () => onClickView(data?.id);

  return (
    <Card classes={`relative py-2 ${gridType === 2 && "minh-270"}`}>
      {gridType === 2 && (
        <Share
          color="primary"
          className="absolute top-2 right-2 cursor-pointer"
          onClick={openModal}
        />
      )}
      <Grid container className="my-1">
        <Grid
          item
          xs={gridType === 1 ? 2 : 12}
          className={cx(gridType === 2 && "flex items-center justify-center")}
        >
          <Avatar src={data?.image} className="border w-120 h-120">
            <img
              className="object-cover bg-lightgray px-1 py-1 border w-full h-full"
              src={goldCupTrophy}
              alt="achievement_image"
            />
          </Avatar>
        </Grid>
        <Grid
          item
          xs={gridType === 1 ? 10 : 12}
          className={`flex-column ${
            gridType === 1 ? "items-left" : "items-center text-center"
          }`}
        >
          <div
            className={`flex ${
              gridType === 1 ? "justify-between" : "justify-center"
            } items-center w-full`}
          >
            <h3
              className={cx(
                "link fs-14--mob text-truncate capitalize w-60p",
                gridType === 2 && "mt-2"
              )}
              onClick={redirect}
            >
              {data?.microcopy || getAchievementTypeKey(data?.achievement_type)}
            </h3>
            {gridType === 1 && (
              <p className="fs-14 fw-500 fs-12--mob">
                {t("Awarded on")}{" "}
                {moment(data?.issue_date).format("MMM DD, YYYY")}
              </p>
            )}
          </div>
          {gridType === 1 && (
            <div>
              <p className="fs-14 fw-500 fs-12--mob text-truncate opacity-60 pb-1">
                {data?.project_title ?? "N/A"}
              </p>
              <p className="fs-14 opacity-80 tracking-tight pb-1">
                Award Description
              </p>
              <p className="fs-14 opacity-80 tracking-tight description">
                {data?.description ?? t("Not Provided")}
              </p>
            </div>
          )}
          {gridType === 2 && (
            <>
              <p className="fs-14 mb-1 fw-500 fs-12--mob text-truncate opacity-60">
                {data?.project_title}
              </p>
              <p className="fs-14 fw-500 fs-12--mob text-truncate">
                {t("Awarded on")}{" "}
                {moment(data?.issue_date).format("MMM DD, YYYY")}
              </p>
            </>
          )}
          <div
            className={`flex ${
              gridType === 1 ? "justify-between" : "justify-center"
            } items-center w-full`}
          >
            <Chip
              className="fw-500 text-truncate fs-12--mob mt-2 capitalize rounded-10"
              sx={{ width: "fit-content", height: 25 }}
              label={t(getAchievementTypeKey(data?.achievement_type), "common")}
            />
            {gridType === 1 && (
              <div className="flex justify-end items-center">
                <Checkbox
                  size="small"
                  defaultChecked={isTrue(data?.is_featured)}
                  onChange={(e) => setFeatured(data?.id, !e.target.checked)}
                />
                <small className="tracking-tight fw-500 mr-2">
                  {t("Feature on my profile")}
                </small>
                <Button bordered classes="mr-1" onClick={openModal}>
                  <Share /> {t("Share")}
                </Button>
                <Button bordered onClick={redirect}>
                  <Visibility /> {t("View")}
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default AchievementCard;
