import { Delete, Visibility, VisibilityOff } from "@mui/icons-material";
import { Chip, Grid, Rating } from "@mui/material";
import Button from "components/Button";
import MainProductCard from "components/Cards/MainProductCard";
import ListingContainer from "components/UI/organisms/ListingContainer";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import React, { useState } from "react";
import EventNoteIcon from "@mui/icons-material/EventNote";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { loginToPreceed } from "helpers/utils/utilities";
import useAuth from "store/auth/service-hook";
import { isTrue } from "helpers/utils/validators";
import useResources from "store/resources/service-hook";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { resource, resourceCollection } from "assets/svg/purple";
import ChipsPreview from "components/UI/atoms/ChipsPreview";
import {
  resource as resourceIcon,
  resourceCollection as resourceCollectionIcon,
  resourceGroup as resourceGroupIcon,
} from "assets/svg/purple";
import ProgressTag from "components/UI/atoms/ProgressTag";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ResourceManageOptions from "../components/ResourceManageOptions";
const ResourceListing = ({
  browseMode,
  gridType,
  manage,
  resources = [],
  setResources = Function.prototype,
  loading,
  handleDelete = Function.prototype,
  page = 1,
  setPage = Function.prototype,
  pageCount = 1,
  isGroup,
  isCollection,
  isResourceCollDetail,
  noSave,
  emptyMessage,
  noPagination,
  smallView,
  emptyView,
  isAssociation,
  showRating = true,
}) => {
  const { t } = useLocalizedTranslation("resource", true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [ResourceToDelete, setResourceToDelete] = useState(null);
  const { navigateResource, navigateResourceG, navigateResourceC } =
    useRedirect();
  const { favorite, unFavorite, addRating, clone } = useResources();
  const {
    state: { isLoggedIn },
  } = useAuth();

  const doUndoAction = async (slug, property, doAction, undoAction) => {
    if (!isLoggedIn) {
      loginToPreceed();
      return;
    }
    const item = resources.find((d) => d.slug === slug);
    if (!item) return;
    const did = !!item[property] && item[property]?.toLowerCase() !== "no";
    if (did) {
      await undoAction(slug, Boolean(isCollection), Boolean(isGroup));
      item[property] = "no";
      if (property === "liked") {
        item.likes = Math.max(0, (item.likes ?? 0) - 1);
      }
    } else {
      await doAction(slug, Boolean(isCollection), Boolean(isGroup));
      item[property] = "yes";
      if (property === "liked") {
        item.likes = (item.likes || 0) + 1;
      }
    }
    const updatedList = [...resources];
    updatedList[item] = item;
    setResources(updatedList);
  };

  const handleFavoriteUnFav = (slug) =>
    doUndoAction(slug, "favourite", favorite, unFavorite);

  const handleAddRating = (slug, rating) => {
    if (!rating) return;
    addRating(slug, rating, isCollection, isGroup);
    const item = resources.find((d) => d.slug === slug);
    if (!item) return;
    item.rating = rating;
    setResources([...resources]);
  };

  const handleCloneResource = async (slug) => {
    const res = await clone(slug, isCollection, isGroup);
    if (res.data.data) {
      isCollection
        ? navigateResourceC(res.data.data?.slug, true)
        : isGroup
        ? navigateResourceG(res.data.data?.slug, true)
        : navigateResource(res.data.data.slug, true);
    }
  };

  const openExitConfirmationModal = (slug) => {
    setResourceToDelete(slug);
    setOpenConfirmationModal(true);
  };

  const cancelExitConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setResourceToDelete(null);
  };

  return (
    <>
      <ListingContainer
        loading={loading}
        emptyText={emptyMessage}
        isEmpty={!resources?.length}
        count={pageCount}
        page={page}
        setPage={setPage}
        classes="mt-4"
        noPagination={noPagination}
        emptyView={!!emptyView && emptyView}
      >
        {resources?.map((d, i) => {
          const {
            title,
            description,
            cover_image,
            slug,
            privacy,
            rating = 0,
            media_type,
          } = d;
          return (
            <Grid item key={i} xs={gridType === 2 ? 6 : 12}>
              <MainProductCard
                classes="mb-3"
                title={
                  <div className="flex items-center">
                    {isAssociation && (
                      <img
                        className="w-20 h-20 mr-1"
                        src={
                          isCollection
                            ? resourceCollectionIcon
                            : isGroup
                            ? resourceGroupIcon
                            : resourceIcon
                        }
                        alt="resource_icon"
                      />
                    )}
                    <p
                      className={`text-nowrap overflow-hidden ${
                        gridType === 1 && "maxw-70p"
                      } textOverflow-ellipsis`}
                    >
                      {title}
                    </p>
                    {gridType === 1 && (browseMode === 1 || !!manage) && (
                      <ProgressTag
                        progress={d?.module_progress}
                        classes="ml-1"
                      />
                    )}
                  </div>
                }
                titleString={title}
                small={smallView}
                subTitle={
                  <div className="flex justify-between">
                    <div
                      className={`flex-center ${
                        gridType === 2 && "flex-wrap leading-snug"
                      } fs-16 fw-500`}
                    >
                      <p>{d?.organization || "N/A"}</p>
                      {d?.type?.length > 0 && (
                        <>
                          <span className="vertical-align-middle mx-1">
                            &middot;
                          </span>
                          <span
                            className={
                              gridType !== 2 && "text-truncate maxw-200"
                            }
                          >
                            {d?.type.map((item) => t(item, "common")).join("/")}
                          </span>
                        </>
                      )}
                    </div>
                    {!!manage && !isAssociation && (
                      <Chip
                        className="fs-12 c-primary h-20 capitalize mt-1"
                        label={t(
                          d?.source ? d?.source?.split("_")?.join(" ") : "N/A"
                        )}
                      />
                    )}
                  </div>
                }
                description={description}
                col={gridType === 2}
                iconsAndValuesList={[
                  d?.resource_collection?.length && {
                    img: resourceCollection,
                    value: `${d?.resource_collection?.length || 0} ${t(
                      "Collection"
                    )}`,
                  },
                  d?.resource_modules?.length && {
                    img: resource,
                    value: `${d?.resource_modules?.length || 0} ${t(
                      "Modules"
                    )}`,
                  },
                  {
                    icon: EventNoteIcon,
                    value: d?.duration || "N/A",
                  },
                  {
                    icon: SignalCellularAltIcon,
                    value: d?.level || "N/A",
                  },
                  {
                    icon: privacy === "private" ? VisibilityOff : Visibility,
                    value: t(isTrue(privacy) ? "Private" : "Public"),
                  },
                  showRating && {
                    value: (
                      <Rating
                        value={+rating}
                        readOnly={!isLoggedIn}
                        onChange={(_e, v) => handleAddRating(slug, v)}
                      />
                    ),
                  },
                ]}
                type={
                  isResourceCollDetail
                    ? COMPONENT_TYPES.RESOURCE
                    : isCollection
                    ? COMPONENT_TYPES.RESOURCE_COLLECTION
                    : isGroup
                    ? COMPONENT_TYPES.RESOURCE_GROUP
                    : COMPONENT_TYPES.RESOURCE
                }
                image={cover_image}
                mediaType={media_type}
                id={slug}
                rightAction={
                  !!manage && !isAssociation ? (
                    <ResourceManageOptions
                      data={d}
                      handleClone={handleCloneResource}
                      isCollection={isCollection}
                      isGroup={isGroup}
                      onDelete={openExitConfirmationModal}
                    />
                  ) : browseMode === 2 && isTrue(d?.favourite) ? (
                    <Button
                      bordered
                      color="red"
                      height="20px"
                      classes="fs-12"
                      onClick={() =>
                        handleFavoriteUnFav(
                          slug,
                          Boolean(isCollection),
                          Boolean(isGroup)
                        )
                      }
                    >
                      <Delete fontSize="small" /> {t("Remove")}
                    </Button>
                  ) : null
                }
                noSave={
                  manage || browseMode === 2 || isTrue(d?.favourite) || noSave
                }
                onSave={() =>
                  handleFavoriteUnFav(
                    d?.slug,
                    Boolean(isCollection),
                    Boolean(isGroup)
                  )
                }
                colBottomContent={
                  gridType === 1 &&
                  (browseMode === 1 || !!manage) && (
                    <ProgressTag
                      progress={d?.module_progress}
                      classes="ml-1 mt-1"
                    />
                  )
                }
                bottomContent={
                  !isAssociation && (
                    <ChipsPreview
                      items={Object.values(d?.skills || {})}
                      limit={3}
                    />
                  )
                }
              />
            </Grid>
          );
        })}
      </ListingContainer>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={cancelExitConfirmationModal}
        desc={t(
          isGroup
            ? "Are you sure you want to delete this Resource Group?"
            : isCollection
            ? "Are you sure you want to delete this Resource Collection?"
            : "Are you sure you want to delete this Resource Module?"
        )}
        okayButtonTitle={t(
          isGroup
            ? "Delete Resource Group"
            : isCollection
            ? "Delete Resource Collection"
            : "Delete Resource Module"
        )}
        onOkay={() => {
          handleDelete(ResourceToDelete);
          cancelExitConfirmationModal();
        }}
      />
    </>
  );
};

export default ResourceListing;
