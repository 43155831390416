import { Grid } from "@mui/material";
import React from "react";
import Card from "components/Cards/Card";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Button from "components/Button";
import { user_types, organization_types } from "../../auth-data";

const UserType = ({ onClick, type, setStep, t = (d) => d }) => {
  const dataList = type === "user" ? user_types : organization_types;
  return (
    <Grid
      xs={12}
      lg={8}
      container
      height="100%"
      alignContent="center"
      justifyContent="center"
      direction="column"
      mt={10}
      className="mx-auto"
    >
      <Grid
        item
        xs={12}
        md={10}
        lg={8}
        px={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        <Button onClick={() => setStep(0)} bordered classes="mb-2--mob">
          <ArrowBackIosNewIcon fontSize="small" />
          {t("Back")}
        </Button>
        <h1 className="w-full text-center flex1">
          {t("Which best describes you?")}
        </h1>
      </Grid>
      <Grid item container xs={12} md={10} lg={8} spacing={2} px={2} mt={2}>
        {dataList.map(({ id, title, icon }) => (
          <Grid item xs={6} lg={4} key={id}>
            <Card
              classes="text-center cursor-pointer column-center py-6 overflow-hidden"
              onClick={() => onClick(id)}
              hoverable
            >
              <p className="c-primary">{icon}</p>
              <h3 className="mt-4 text-nowrap">{t(title)}</h3>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid
        xs={12}
        className="text-center w-full flex my-6 justify-center"
        alignSelf="center"
      ></Grid>
    </Grid>
  );
};
export default UserType;
