import Button from "components/Button";
import Input from "components/Input";
import React, { useState } from "react";
import { CopyAll, Upload } from "@mui/icons-material";
import Checkbox from "components/Input/Checkbox";
import FileButton from "components/Input/FileButton";
import { Box, Grid, IconButton, Popover, Stack } from "@mui/material";
import { slide1, loginImage, registrationImage } from "assets/png";
import { logo } from "assets/svg";
import { HexColorPicker } from "react-colorful";
import { isTrue } from "helpers/utils/validators";
import { createEvent, getCurrOrigin } from "helpers/utils/utilities";
import SecureImage from "components/UI/atoms/SecureImage";
import Card from "components/Cards/Card";
import NavTabs from "components/UI/molecules/NavTabs";
import { toast } from "react-toastify";

const LoginCustomization = ({
  setStep,
  data,
  locked,
  setData,
  t = (d) => d,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const {
    profile_image,
    enable_custom_login_and_registration,
    custom_background_color,
    custom_hero_image,
    custom_logo_image,
    use_main_org_logo,
    custom_url,
  } = data;

  const onChange = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (val) => {
    if (val && val.size > 5 * 1024 * 1024) {
      toast.error(t("File size exceeds the limit of 5MB."));
      return;
    }
    onChange(createEvent("custom_hero_image", val));
  };

  return (
    <div className="mx-6 mx-0--mob loginCustomization__org py-6">
      <div className="text-center mb-5 fw-700 fs-24 opacity-70">
        {t("Login Customization")}
      </div>
      <Checkbox
        classes="ml-6 ml-1--mob"
        name="enable_custom_login_and_registration"
        option="Enable a customized registration & login page."
        onChange={(val) =>
          onChange(createEvent("enable_custom_login_and_registration", val))
        }
        checked={isTrue(enable_custom_login_and_registration)}
        size="large"
        t={t}
      />
      <Grid container spacing={2} mx={{ xs: 0, md: 2 }} mt={2}>
        <Grid item xs={12} sm={7} className="">
          <h3 className="c-title">{t("Logo")}</h3>
          <Checkbox
            disabled={!isTrue(enable_custom_login_and_registration)}
            name="use_main_org_logo"
            option="Use your Organization logo from step 1"
            onChange={(val) => onChange(createEvent("use_main_org_logo", val))}
            checked={isTrue(use_main_org_logo)}
            size="large"
            t={t}
          />
          <FileButton
            classes="flex-reverse  gap-10"
            onChange={(val) => onChange(createEvent("custom_logo_image", val))}
            disabled={
              !isTrue(enable_custom_login_and_registration) ||
              isTrue(use_main_org_logo)
            }
            value={custom_logo_image}
          >
            <Button
              classes="relative"
              height="40px"
              bordered
              color={
                !isTrue(enable_custom_login_and_registration) ||
                isTrue(use_main_org_logo)
                  ? "gray"
                  : "primary"
              }
            >
              <Upload />
              {t("Upload Logo")}
            </Button>
          </FileButton>
        </Grid>
        <Grid item xs={12} sm={5}>
          <h3 className="c-title">{t("Background Image")}</h3>
          <p className="mt-2 fs-15">
            {t("Upload a hero image to welcome your users.")}
          </p>
          <FileButton
            classes="flex-reverse gap-10 mt-4"
            onChange={handleFileChange}
            disabled={!isTrue(enable_custom_login_and_registration)}
            value={custom_hero_image}
          >
            <Button
              classes="relative"
              height="40px"
              bordered
              color={
                !isTrue(enable_custom_login_and_registration)
                  ? "gray"
                  : "primary"
              }
            >
              <Upload />
              {t("Upload Image")}
            </Button>
          </FileButton>
        </Grid>
      </Grid>
      <Grid container spacing={2} mx={{ xs: 0, md: 2 }} mt={2}>
        <Grid item xs={12} sm={7}>
          <h3 className="c-title">{t("Custom Login Page URL")}</h3>
          <Stack direction="row" alignItems="center">
            <Input
              name="custom_url"
              onChange={onChange}
              value={custom_url}
              width="100%"
              preInput={`${getCurrOrigin()}/cl/`}
              classes="mt-2"
            />
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  `${getCurrOrigin()}/cl/${custom_url}`
                );
                toast.success("link copied!");
              }}
              className="h-40 w-40 ml-1 mt-1 c-primary border-1"
            >
              <CopyAll />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={5}>
          <h3 className="c-title">{t("Background Color")}</h3>
          <Button
            color="gray"
            bordered
            classes={`c-title mt-2 w-80p w-90p--mob h-40 ${
              data?.custom_background_color ? "justify-center" : "justify-start"
            }`}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            disabled={!isTrue(enable_custom_login_and_registration)}
          >
            {data?.custom_background_color ?? t("Choose the color here")}
          </Button>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="w-390 h-300 overflowY-hidden">
              <HexColorPicker
                className="w-full h-full"
                color={data?.custom_background_color ?? "#ffffff"}
                onChange={(color) => {
                  onChange(createEvent("custom_background_color", color));
                }}
              />
            </div>
          </Popover>
        </Grid>
      </Grid>
      <h3 className="mt-6 mb-4 ml-6 ml-1--mob c-title">{t("Preview")}</h3>
      <NavTabs
        list={["Preview Sign In Page", "Preview Registration page"]}
        value={activeTab}
        onChange={setActiveTab}
        classes="mx-6 mx-1--tab"
      />
      <Box
        className="rounded-5 mx-6 overflow-hidden mx-1--tab flex items-center justify-center"
        sx={{
          backgroundColor: custom_background_color,
          height: { xs: "fit-content", md: "600px" },
          py: { xs: 2, md: 0 },
        }}
      >
        <Grid
          container
          columnSpacing={{ xs: 0, md: 4 }}
          rowSpacing={4}
          alignItems="center"
          justifyContent="center"
          xs={12}
          lg={10}
        >
          <Grid item sm={12} md={8}>
            <SecureImage
              src={
                !!custom_hero_image
                  ? typeof custom_hero_image === "string"
                    ? custom_hero_image
                    : URL.createObjectURL(custom_hero_image)
                  : slide1
              }
              placeholder={slide1}
              alt="hero_image"
              classes="h-450--noMob w-full object-cover rounded-20"
            />
          </Grid>
          <Grid
            item
            sm={12}
            md={4}
            className="w-full justify-start-center-tab items-center"
          >
            <div className="flex-column items-center relative">
              <img
                src={activeTab === 0 ? loginImage : registrationImage}
                alt="login_image"
                className="w-full object-contain maxh-500"
              />
              <div className="flex justify-center items-center w-90p mx-auto h-60 absolute top-0 bg-white">
                <SecureImage
                  classes={activeTab === 0 ? "maxh-40 mt-5" : "maxh-35 my-3"}
                  src={
                    use_main_org_logo
                      ? profile_image
                        ? typeof profile_image === "string"
                          ? profile_image
                          : URL.createObjectURL(profile_image)
                        : logo
                      : custom_logo_image
                      ? typeof custom_logo_image === "string"
                        ? custom_logo_image
                        : URL.createObjectURL(custom_logo_image)
                      : logo
                  }
                  placeholder={logo}
                  alt="login_image"
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
      {!!locked && (
        <div className="locked__overlay">
          <Card width="max(250px,40%)" classes="py-3 column-center">
            <p className="text-center fs-18 px-4 px-1--mob fw-600 mt-4">
              {t(
                `You are on ${
                  locked?.includes("sprout") ? "Sprout" : "Seed"
                } plan, please subscribe to a premium plan in the previous step to unlock this feature.`
              )}
              <br />
            </p>
            <Button
              color="blue"
              onClick={() => setStep(1)}
              width="100px"
              classes="mt-4 rounded-20  px-6"
            >
              {t("Back")}
            </Button>
          </Card>
        </div>
      )}
    </div>
  );
};
export default LoginCustomization;
