import { ShoppingCartCheckout } from "@mui/icons-material";
import Button from "components/Button";
import React from "react";
import useAuth from "store/auth/service-hook";
import { Icon, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

const GetAddonsButton = ({
  onClose,
  icon,
  iconProps = {},
  buttonTitle = "",
}) => {
  const {
    state: { user },
  } = useAuth();
  const { t } = useTranslation("common");

  const handleSelectPlan = () => {
    const instance = window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_STIE,
      publishableKey: process.env.REACT_APP_CHARGEBEE_KEY,
    });
    let cbPortal = instance.createChargebeePortal({
      email: user?.email,
    });

    cbPortal.open({
      close() {
        if (onClose) {
          onClose();
          return;
        }
        window.location.reload();
      },
    });
  };

  return icon ? (
    <IconButton onClick={handleSelectPlan} className="h-30 w-30">
      <Icon component={icon} {...iconProps} />
    </IconButton>
  ) : (
    <Button color="orange" height="35px" onClick={handleSelectPlan}>
      <ShoppingCartCheckout className="mr-1" fontSize="small" />{" "}
      {buttonTitle || t("Get Add-on")}
    </Button>
  );
};

export default GetAddonsButton;
