import React, { useState } from "react";
import styles from "components/Input/input.module.scss";
import cx from "classnames";
import { createEvent } from "helpers/utils/utilities";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

export const formatAddress = (data, noStreet) => {
  if (typeof data === "string") return data;
  if (!data || !Object.keys(data)?.length) return null;
  const { address_1, city, state, zip_code, country } = data;
  return (
    (!noStreet ? (address_1 ? address_1 + ", " : "") : "") +
    (city ? city + ", " : "") +
    (state ? state + ", " : "") +
    (country ? country + " " : "") +
    (zip_code ? ", " + zip_code : "")
  );
};

function GooglePlacesSelect({
  setAddress,
  placeholder,
  required,
  value,
  disabled,
  classes,
  name,
  error,
  title,
}) {
  const [query, setQuery] = useState(value ? formatAddress(value) : "");
  const { t } = useLocalizedTranslation();

  return (
    <div className={styles.inputContainer}>
      {!!title && (
        <p className={styles.title}>
          {t(title)}
          {!!required && <span>*</span>}
        </p>
      )}
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
        selectProps={{
          controlShouldRenderValue: true,
          placeholder: t(placeholder) || t("Select a location"),
          disabled: disabled,
          value: { label: query, value: value || {} },
          onChange: ({ label, value }) => {
            if (!value) return;
            const { structured_formatting } = value;
            const [streetAddress, ...localityParts] =
              structured_formatting.main_text.split(", ");
            const locality = localityParts.join(", ");
            const secondaryParts =
              structured_formatting.secondary_text.split(", ");
            const country = secondaryParts.pop();
            const state =
              secondaryParts.length === 2 ? secondaryParts.pop() : "";
            const city = secondaryParts.pop() || "";
            const address = {
              address_1: streetAddress || "",
              locality: locality || "",
              state: state || "",
              city: city || "",
              country: country || "",
            };
            setAddress(createEvent(name, address));
            setQuery(label);
          },
          className: cx(styles.input, classes, { [styles.error]: !!error }),
          styles: {
            input: (provided) => ({
              ...provided,
              border: "none",
              outline: "none",
            }),
            control: (provided) => ({
              ...provided,
              border: "none",
              outline: "none",
              borderColor: "transparent !important",
              borderWidth: "0px",
              boxShadow: "none",
            }),
            indicatorSeparator: () => ({
              display: "none",
            }),
          },
        }}
        debounce={500}
        apiOptions={{ language: "en" }}
        onLoadFailed={(e) => {
          console.error(e.message);
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["us", "ca", "in"],
          },
          types: [
            "postal_code",
            "route",
            "locality",
            "street_address",
            "country",
          ],
        }}
      />
      {error && <p className={styles.error}>{t(error, "common")}</p>}
    </div>
  );
}

export default GooglePlacesSelect;
