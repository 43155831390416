import EngagementsReport from "pages/report/components/EngagementsReport";
import { useEffect, useMemo, useState } from "react";
import useOrganizationReportContext from "../hooks/useOrganizationReportContext";
import useOrganizationReport from "store/reports/organization/service-hook";

const LabEngagement = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { organizationSlug } = useOrganizationReportContext();
  const { organizationEngagements } = useOrganizationReport();
  /**
   * STATE
   */
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  /**
   *
   * @returns {Promise<void>}
   */
  const fetchEngagement = async () => {
    setLoading(true);
    if (organizationSlug) {
      const response = await organizationEngagements(organizationSlug);
      if (response) {
        setData(response);
      }
    }
    setLoading(false);
  };

  const formattedData = useMemo(() => {
    return [
      {
        title: t("Views"),
        count: data?.views ?? 0,
      },
      {
        title: t("Save"),
        count: data?.saves ?? 0,
      },
      {
        title: t("Invitation Accepted"),
        count: data?.invitation_accepted ?? 0,
      },
      {
        title: t("Invitation Declined"),
        count: data?.invitation_declined ?? 0,
      },
    ];
  }, [data]);

  /**
   * MOUNTED LIFECYCLE
   */
  useEffect(() => {
    fetchEngagement();
  }, []);

  return (
    <>
      <EngagementsReport
        data={formattedData}
        isLoading={loading}
        title={t("Organization Engagement")}
        t={t}
      />
    </>
  );
};

export default LabEngagement;
