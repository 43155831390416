import Card from "components/Cards/Card";
import React from "react";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import ListingContainer from "components/UI/organisms/ListingContainer";
import { ItemCard } from "./ProjectListing";
import { isTrue } from "helpers/utils/validators";
import EmptyText from "components/UI/atoms/EmptyText";
import moment from "moment";
import SearchAndSort from "./SearchAndSort";
import useRedirect from "helpers/hooks/useRedirect";
import { extractChallengeDate } from "helpers/utils/utilities";

const ChallengeHistory = ({
  itsMe,
  challenges,
  page,
  setPage,
  isLoading,
  pages,
  search,
  setSearch,
  sort,
  setSort,
  t = (d) => d,
}) => {
  const { navigateAchievements } = useRedirect();

  const filteredChallenges = () => {
    const searchLower = search?.toLowerCase(); 
  
    if (!searchLower) {
      return challenges; 
    }
  
    return challenges?.filter(challenge => 
      challenge?.title?.toLowerCase().includes(searchLower) || 
      challenge?.description?.toLowerCase().includes(searchLower)
    );
  };

  return (
    <Card classes="mt-4">
      <div className="my-2 flex items-center justify-between">
        <h2>{t("Challenge History")}</h2>
      </div>
        <SearchAndSort
          searchText={search}
          setSearchText={setSearch}
          sortOption={sort}
          setSortOption={setSort}
          t={t}
        />
      <ListingContainer
        classes="mt-4"
        isEmpty={(!challenges?.length && !search) || (search && !filteredChallenges().length)}
        loading={isLoading}
        emptySmall
        page={page}
        count={pages}
        setPage={setPage}
        emptyView={
          <EmptyText>
            {t(
              !!search
                ? "No result found, please try different keywords"
                : itsMe
                ? "You have not completed any Challenge"
                : "This user has not completed any Challenge"
            )}
          </EmptyText>
        }
      >
        {challenges?.map((d, i) => (
          <ItemCard
            key={i}
            title={d?.title}
            description={d?.description}
            media={d?.media}
            slug={d?.slug}
            subTitle={
              <div className="flex items-center text-nowrap w-full tracking-tight fs-16">
                {t(
                  isTrue(d?.privacy) ? "Private Challenge" : "Public Challenge"
                )}
                <span className="vertical-align-middle mx-1">&middot;</span>
                <span className="ml-1">
                  {d?.challenge_status === "Closed"
                    ? t("Closed Challenge")
                    : t("Open Until ") + extractChallengeDate(d)}
                </span>
              </div>
            }
            component={COMPONENT_TYPES.CHALLENGE}
            bottomContent={
              <div className="flex">
                <p className="fw-500">{t("Achievement")}:</p>
                <p className=" ml-1">
                  {d?.participation_achievement?.achievement_name ? (
                    <span
                      onClick={() => navigateAchievements()}
                      className="link"
                    >
                      {d?.participation_achievement?.achievement_name}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
            }
          />
        ))}
      </ListingContainer>
    </Card>
  );
};

export default ChallengeHistory;
