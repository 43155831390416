import { Grid } from "@mui/material";
import Button from "components/Button";
import SchemaUIExtractor from "../../../components/UI/organisms/SchemaUIExtractor";
import { certificationSchema } from "pages/profile/forms/data";
import { useState } from "react";
import { toast } from "react-toastify";

export const validateCertificates = (certificates,t) => {
  if (!certificates || !certificates?.length) return true;
  for (const d of certificates) {
    if (!d.company || !d.name) {
      toast.error(t("Please enter the certificate details properly!"));
      return true;
    }
  }
  return false;
};

const Certificates = ({
  certificates,
  setCertificates,
  errors,
  onDelete,
  t = (d) => d,
}) => {
  const [loading, setLoading] = useState(false);
  const addCertificate = () => {
    setCertificates((val) => [...val, {}]);
  };

  const deleteCertificate = async (indexToRemove) => {
    if (certificates[indexToRemove]?.id) {
      setLoading(indexToRemove);
      await onDelete(certificates[indexToRemove]?.id);
      setLoading(false);
    }
    certificates.splice(indexToRemove, 1);
    setCertificates([...certificates]);
  };

  const onChangeCertificates = (e, index) => {
    const { name, value } = e.target;
    certificates[index][name] = value;
    setCertificates([...certificates]);
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-between mt-1">
        <h2 className="my-2">{t("Edit Certifications")}</h2>
        <Button bordered onClick={addCertificate}>
          {" "}
          {t("Add Entry")}
        </Button>
      </div>
      {certificates.map((d, i) => (
        <>
          <h3 className="mt-3">
            {t("Entry")} #{i + 1}
          </h3>
          <Grid container xs={12} spacing={4}>
            <Grid item xs={12} sm={6}>
              <SchemaUIExtractor
                schema={certificationSchema.slice(0, 4)}
                data={d}
                errors={errors}
                onChange={(e) => onChangeCertificates(e, i)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              direction="column"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <SchemaUIExtractor
                schema={certificationSchema.slice(4, 5)}
                data={d}
                errors={errors}
                onChange={(e) => onChangeCertificates(e, i)}
              />
              <Button
                classes="mt-2"
                width="100px"
                height="32px"
                loading={loading === i}
                color="red"
                bordered
                onClick={() => deleteCertificate(i)}
              >
                {t("Remove")}
              </Button>
            </Grid>
          </Grid>
        </>
      ))}
    </div>
  );
};

export default Certificates;
