import Intercom from "@intercom/messenger-js-sdk";
import useAuth from "store/auth/service-hook";

const IntercomComponent = () => {
  /**
  * COMPONENT MOUNTED
  */
  
  const {
    state: { isLoggedIn, user },
  } = useAuth();
  
  if (isLoggedIn) {
    Intercom({
      app_id: process.env.REACT_APP_INTERCOM_ID,
      user_id: user?.id,
      name: user?.full_name,
      email: user?.email,
      created_at: user?.created_at,
      language_override: user?.preferred_language?.substring(0, 2) || "en",
        //Using substring() above since Intercom only accepts two-letter ISO 639 language codes
      user_role: user?.roles,
      user_type: user?.user_personal?.user_type
    });
  } else {
    Intercom({
      app_id: process.env.REACT_APP_INTERCOM_ID,
    });
  }
};

export default IntercomComponent;
