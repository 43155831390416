import {
  CancelOutlined,
  Check,
  CheckCircleOutlined,
  Close,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Button from "components/Button";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { isTrue } from "helpers/utils/validators";
import React, { useState } from "react";

const AcceptDeclineButtons = ({
  onAccept,
  onDecline,
  classes,
  id,
  hasAlternativeComponent = false,
  joined,
  small,
}) => {
  const { t } = useLocalizedTranslation();

  const [acceptDeclineLoading, setAcceptDeclineLoading] = useState(null);
  const [actionCompleted, setActionCompleted] = useState(null);

  const handleAcceptOrDecline = async (slug, isDecline) => {
    setAcceptDeclineLoading(isDecline ? "decline" : "accept");
    isDecline
      ? await onDecline(slug, hasAlternativeComponent)
      : await onAccept(slug, hasAlternativeComponent);
    setAcceptDeclineLoading(false);
    setActionCompleted(isDecline ? "Declined" : "Accepted");
  };

  if (small)
    return (
      <div className={`flex gap-1 ${classes}`}>
        {joined?.toLowerCase() !== "no" && (
          <IconButton
            className="h-30 w-30"
            onClick={() => handleAcceptOrDecline(id)}
          >
            <CheckCircleOutlined className="c-success" />
          </IconButton>
        )}
        {joined?.toLowerCase() !== "yes" && (
          <IconButton
            className="h-30 w-30"
            onClick={() => handleAcceptOrDecline(id, true)}
          >
            <CancelOutlined className="c-error" />
          </IconButton>
        )}
      </div>
    );

  return (
    <div
      data-testid="accpect-decline-buttons"
      className={`flex items-center ${classes}`}
    >
      {!joined || joined?.toLowerCase() === "pending" ? (
        <AcceptDecline
          handleAcceptOrDecline={handleAcceptOrDecline}
          id={id}
          acceptDeclineLoading={acceptDeclineLoading}
          t={t}
          actionCompleted={actionCompleted}
        />
      ) : (
        <Button
          width="120px"
          height="26px"
          classes="fs-12"
          color="gray"
          disabled
          bordered={joined !== "Yes"}
        >
          {isTrue(joined) ? (
            <Check fontSize="small" />
          ) : (
            <Close fontSize="small" />
          )}{" "}
          {t(isTrue(joined) ? "Accepted" : "Declined", "common")}
        </Button>
      )}
    </div>
  );
};

const AcceptDecline = ({
  handleAcceptOrDecline,
  id,
  acceptDeclineLoading,
  actionCompleted,
  t,
}) => {
  return (
    <div className="flex items-center flex-1 justify-end">
      {actionCompleted ? (
        <Button
          width="120px"
          height="26px"
          classes="fs-12"
          color="gray"
          disabled
          bordered={actionCompleted === "Declined"}
        >
          {actionCompleted === "Declined" ? (
            <Close fontSize="small" />
          ) : (
            <Check fontSize="small" />
          )}
          {t(actionCompleted, "common")}
        </Button>
      ) : (
        <>
          <Button
            onClick={() => handleAcceptOrDecline(id, true)}
            width="85px"
            height="26px"
            classes="fs-12 mr-1"
            color="red"
            bordered
            loading={acceptDeclineLoading === "decline"}
          >
            <Close fontSize="small" /> {t("Decline", "common")}
          </Button>
          <Button
            onClick={() => handleAcceptOrDecline(id)}
            width="85px"
            height="26px"
            classes="fs-12"
            color="green"
            loading={acceptDeclineLoading === "accept"}
          >
            <Check fontSize="small" /> {t("Accept", "common")}
          </Button>
        </>
      )}
    </div>
  );
};

export default AcceptDeclineButtons;
