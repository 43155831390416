import React, { useEffect, useState } from "react";
import NuxLayout from "components/UI/molecules/NuxLayout";
import useRedirect from "helpers/hooks/useRedirect";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import ManageWrapper from "components/UI/organisms/ManageWrapper";
import useListingHeader from "helpers/hooks/useListingHeader";
import { browse_options, getLabNUX } from "pages/labs/listing/ManageLabs";
import useLabPrograms from "store/lab-programs/service-hook";
import LabListing from "pages/labs/listing/LabListing";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { useLabOptions } from "pages/labs/listing/ManageLabs";
import { hasFiltersApplied } from "helpers/utils/utilities";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const ManageLabPrograms = () => {
  const state = useListingHeader();
  const { navigateCreateLabProgram, navigateManageLabs } = useRedirect();
  const [labPrograms, setLabPrograms] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const { deleteLP, fetchPrivateLPs } = useLabPrograms();
  const { selected } = useFilters();
  const labCreateOptions = useLabOptions();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    handleFetchLabs();
  }, [page, selected, state.sort, state.search]);

  const handleFetchLabs = async () => {
    setLoading(true);
    const filters = {
      ...selected,
      page,
      sort_by: state.sort,
    };
    if (state.search) {
      filters.search = state.search;
    }
    if (selected?.multi_type) filters.type = selected.multi_type;
    delete filters.multi_type;
    const [list, count, total] = await fetchPrivateLPs({ ...filters });
    setLabPrograms(list || []);
    setPages(count || 0);
    setTotalCount(total || 0);
    setLoading(false);
  };
  const handleDeleteLab = async (slug) => {
    setLoading(true);
    await deleteLP(slug);
    setLabPrograms((val) => val.filter((d) => d.slug !== slug));
    setLoading(false);
  };

  const noSearchValue = !state.search || state.search.trim() === "";

  return (
    <ManageWrapper
      {...state}
      browseMode={1}
      setBrowseMode={(val) => val === 0 && navigateManageLabs()}
      browseOptions={browse_options}
      createActionTitle="Create"
      navigateCreate={navigateCreateLabProgram}
      component={COMPONENT_TYPES.LAB_PROGRAMS}
      createOptions={labCreateOptions}
      filters={[
        FILTER_TYPES.MULTI_TYPE,
        FILTER_TYPES.DURATION,
        FILTER_TYPES.LEVEL,
        FILTER_TYPES.SKILLS,
        FILTER_TYPES.PRIVACY,
      ]}
    >
      {!hasFiltersApplied(selected) &&
      noSearchValue &&
      !labPrograms?.length &&
      !loading ? (
        <NuxLayout
          browseMode={1}
          newUserData={getLabNUX(navigateCreateLabProgram)}
        />
      ) : (
        <>
          {totalCount > 0 && (
            <h3 className="mt-3 ml-1">
              {totalCount ?? 0} {t("Results", "common")}
            </h3>
          )}
          <LabListing
            browseMode={1}
            gridType={state.gridType}
            labs={labPrograms}
            loading={loading}
            page={page}
            pageCount={pages}
            setPage={setPage}
            handleDelete={handleDeleteLab}
            manage
            isLP
          />
        </>
      )}
    </ManageWrapper>
  );
};

export default ManageLabPrograms;
