import { Loader } from "components/UI/molecules/LazyLoader";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useAuth from "store/auth/service-hook";
const MagnetCallback = () => {
  const { magnetSSOLogin } = useAuth();
  const [query] = useSearchParams();
  const code = query.get("code");

  useEffect(() => {
    magnetSSOLogin({ code });
  }, []);
  return (
    <>
      <Loader />
    </>
  );
};

export default MagnetCallback;
