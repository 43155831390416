import React, { useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import logo from "../../../assets/svg/logo.svg";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const user_types = [
  {
    name: "Learner",
    value: "learner",
  },
  {
    name: "Job Seeker",
    value: "job_seeker",
  },
  {
    name: "Employee",
    value: "employee",
  },
  {
    name: "Mentor",
    value: "mentor",
  },
  {
    name: "Startup",
    value: "startup",
  },
  {
    name: "Educator",
    value: "educator",
  },
];

const user_work_types = [
  {
    title: "I’m part of a program.",
    path: "/join-your-program",
  },
  {
    title: "I’m part of an existing organization.",
    path: "/join-your-organization",
  },
  {
    title: "I want to set up my organization.",
    path: "/organization-manager-onboarding",
  },
  {
    title: "I’m just checking out PreprLabs.",
    path: "/regular-onboarding",
  },
];

const CollectUserData = () => {
  const [hideSlider, setHideSlider] = useState(false);
  const [collectState, setCollectState] = useState(0);
  const [userData, setUserData] = useState({
    work_type: 0,
  });
  const navigate = useNavigate();
  const { t } = useTranslation("auth");

  const handleNext = () => {
    navigate("/organization-manager-onboarding");
  };

  const handleBack = () => {
    if (collectState === 0) {
      registerNavigate();
    } else {
      setHideSlider(false);
      setCollectState(0);
    }
  };

  const registerNavigate = () => navigate("/register");

  return (
    <AuthLayout sliderHidden={hideSlider}>
      <div className="auth-card justify-start">
        <img src={logo} alt="logo" className="mt-4" />
        {collectState === 0 && (
          <>
            <h1 className="my-4">{t("Create Account")}</h1>
            <span className="text-center mx-1 mb-6 fw-400">
              {t("Which of the following best describes you?")}
            </span>
            <RadioGroup defaultValue="learner" className="radio-grid">
              {user_types.map(({ value, name }) => (
                <FormControlLabel
                  value={value}
                  key={value}
                  control={<Radio />}
                  label={t(name)}
                />
              ))}
            </RadioGroup>
          </>
        )}
        {collectState === 1 && (
          <>
            <div className="pl-2">
              <h2 className="mt-14">First Steps</h2>
              <b>Your account has been successfully created!</b>
              <br />
              <span>Let’s point you in the right direction.</span>
            </div>
            <div className="onboarding__btnGrid">
              {user_work_types.map(({ title, path }, i) => (
                <button
                  key={i}
                  onClick={() => setUserData({ ...userData, work_type: i })}
                  className={`${i === userData?.work_type && "selected"}`}
                >
                  {title}
                </button>
              ))}
            </div>
          </>
        )}
        <div className="nextback__buttons">
          <button className="back" onClick={handleBack}>
            {t("Back")}
          </button>
          <button className="next" onClick={handleNext}>
            {t("Next")}
          </button>
        </div>
        <p className="mt-2">
          {t("Dont have an Account?")}{" "}
          <span className="link" onClick={registerNavigate}>
            {t("Register")}
          </span>
        </p>
      </div>
    </AuthLayout>
  );
};

export default CollectUserData;
