import React from "react";
import useMaster from "../../../../store/master/service-hook";
import { useAdvanceSearchContext } from "../../hooks/useAdvanceSearchContext";
import SubFilter from "./SubFilter";
import { FilterComponentsList } from "../../data";

const ProjectStatus = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { fetchProjectStatus } = useMaster();
  const { projectStatus, setProjectStatus, ActiveFilterComponents } =
    useAdvanceSearchContext();

  return (
    <SubFilter
      title={t("STATUS")}
      fetchData={fetchProjectStatus}
      values={projectStatus}
      onChange={(checked, value) => {
        let temp = [...projectStatus];
        if (checked) {
          temp.push(value);
        } else {
          temp = temp.filter((item) => item !== value);
        }
        setProjectStatus(temp);
      }}
      valueKey={"id"}
      labelKey={"title"}
      show={ActiveFilterComponents.includes(
        FilterComponentsList.project_status
      )}
      t={t}
    />
  );
};

export default ProjectStatus;
