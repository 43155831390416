import { Container, Grid } from "@mui/material";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import React, { useState } from "react";
import DropBox from "components/Input/DropBox";
import Button from "components/Button";
import { LANGUAGES } from "helpers/utils/constants";
import Select from "components/Input/Select";
import { REQUIRED_VALIDATOR } from "helpers/utils/validators";
import { user } from "assets/svg/blue";
const additional_info = [
  {
    title: "Preferred Language",
    name: "preferred_language",
    options: LANGUAGES,
    required: true,
    description:
      "Customize your website and communication language settings for a seamless, personalized experience. Select to ensure every interaction resonates with you.",
    validator: REQUIRED_VALIDATOR,
  },

  {
    title: "Two Factor Authentication",
    name: "two_factor_verification",
    options: [
      { value: "Enable", key: "yes" },
      { value: "Disable", key: "no" },
    ],
    description:
      "Double up on security with 2-Factor Authentication. A simple yet powerful layer that ensures your account stays safe.",
  },
];

const AccountSettings = ({
  data,
  onChange,
  errors,
  schema,
  image,
  removeImage,
  setFile,
  setSettings = Function.prototype,
  t = (d) => d,
}) => {
  const [removeImageLoading, setRemoveImageLoading] = useState(false);
  const handleRemoveImage = async () => {
    setRemoveImageLoading(true);
    await removeImage();
    setSettings((prev) => ({ ...prev, profile_image: user }));
    setRemoveImageLoading(false);
  };
  return (
    <Container px={{ xs: 0, md: 2 }} my={2}>
      <h2>{t("Upload Profile Image")}</h2>
      <p className="c-gray">
        {t(
          "The recommended image dimension is 100 x 100 pixels, and you can crop the image after the upload."
        )}
      </p>
      <div className="w-full flex-colOnMob my-4 justify-center items-center">
        <DropBox
          classes="mt-3 mr-8--noMob"
          rounded
          name="profile_image"
          file={image ?? user}
          setFile={setFile}
          hideDelete
        />
        {image && (
          <Button
            classes="my-2--mob"
            color="red"
            bordered
            width="200px"
            height="40px"
            onClick={handleRemoveImage}
            loading={removeImageLoading}
          >
            {t("Remove Profile Image")}
          </Button>
        )}
      </div>
      <SchemaUIExtractor
        schema={schema.slice(0, 6)}
        data={data}
        errors={errors}
        onChange={onChange}
      />
      {additional_info?.map(
        ({ title, name, description, options, required }, i) => (
          <SettingItem
            name={name}
            onChange={onChange}
            options={options}
            title={title}
            value={data[name]}
            description={description}
            key={i}
            required={required}
            error={errors.get(name)}
            t={t}
          />
        )
      )}
    </Container>
  );
};

export default AccountSettings;

export const SettingItem = ({
  title,
  name,
  options,
  onChange,
  description,
  required,
  classes = "",
  value = "",
  error,
  t = (d) => d,
}) => {
  return (
    <Grid container className={classes} spacing={1} mt={1} alignItems="center">
      <Grid item xs={12} md={6} lg={8}>
        <h4>
          {t(title)}
          {required && <span className="c-error">*</span>}
        </h4>
        <p className="fs-14 opacity-70">{t(description)}</p>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Select
          keyValues={options}
          name={name}
          onChange={onChange}
          width="99%"
          value={value}
          required={required}
          error={error}
          t={t}
        />
      </Grid>
    </Grid>
  );
};
