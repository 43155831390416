import {
  Group,
  Delete,
  AccessAlarm,
  Update,
  Done,
  BookmarkOutlined,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import Button from "components/Button";
import MainProductCard from "components/Cards/MainProductCard";
import AcceptDeclineButtons from "components/UI/atoms/AcceptDeclineButtons";
import ListingContainer from "components/UI/organisms/ListingContainer";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { loginToPreceed } from "helpers/utils/utilities";
import { isTrue } from "helpers/utils/validators";
import moment from "moment";
import React, { useState } from "react";
import useAuth from "store/auth/service-hook";
import useProjects from "store/projects/service-hook";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ProjectManageOptions from "../components/ProjectManageOptions";

const emptyMessages = {
  0: "No projects found.",
  1: "You are not in any team projects.",
  2: "You don't have any pending project invitations.",
  3: "Like your favourite projects so they show up here.",
  4: "No projects pending assessment.",
  5: "You have not assessed any projects.",
};

const ProjectListing = ({
  browseMode,
  gridType,
  projects = [],
  setProjects = Function.prototype,
  loading,
  page = 1,
  setPage = Function.prototype,
  pageCount = 1,
  noSave,
  onAssessProject,
  emptyView,
}) => {
  const { navigateChallenge } = useRedirect();
  const { t } = useLocalizedTranslation("project");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { favUnfav, acceptDeclineJoinRequest, deleteProject, pinUnpin } =
    useProjects();
  const {
    state: { isLoggedIn, user },
  } = useAuth();
  const doUndoAction = async (
    slug,
    property,
    doAction,
    undoAction,
    dontClearItem
  ) => {
    if (!isLoggedIn) {
      loginToPreceed();
      return;
    }
    const item = projects.find((d) => d.slug === slug);
    if (!item) return;
    const did = !!item[property] && item[property]?.toLowerCase() !== "no";
    let res;
    if (did) {
      res = await undoAction(slug, true);
    } else {
      res = await doAction(slug);
    }
    if (!res) return;
    item[property] = did ? "no" : "yes";

    let newProjectList = [...projects];

    if (did && !dontClearItem) {
      newProjectList = [...newProjectList].filter(
        (project) => project?.slug !== slug
      );
    }
    setProjects(newProjectList);
  };

  const handleFavoriteUnFav = (slug) =>
    doUndoAction(slug, "favourite", favUnfav, favUnfav);
  const handlePinUnpin = (slug) =>
    doUndoAction(slug, "is_project_pinned", pinUnpin, pinUnpin, true);

  const handleJoinAcceptDecline = async (slug, isDecline) => {
    if (!isLoggedIn) {
      loginToPreceed();
      return;
    }
    await acceptDeclineJoinRequest(slug, user?.email, Boolean(isDecline));
    const item = projects.find((d) => d.slug === slug);
    item["joinedStatus"] = isDecline ? "declined" : "accepted";
    setProjects([...projects]);
  };

  const handleDelete = async () => {
    const slug = openConfirmationModal;
    setProjects((prevProjects) =>
      prevProjects.filter((project) => project?.slug !== slug)
    );
    setOpenConfirmationModal(false);
    await deleteProject(slug);
  };
  return (
    <ListingContainer
      loading={loading}
      isEmpty={!projects?.length}
      count={pageCount}
      page={page}
      setPage={setPage}
      classes="mt-4"
      emptyView={
        emptyView ?? (
          <h3 className="text-center mt-10 items-center mx-auto">
            {t(emptyMessages[browseMode])}
          </h3>
        )
      }
    >
      {projects?.map((d) => {
        return (
          <Grid item key={d?.id} xs={gridType === 1 ? 12 : 6}>
            <MainProductCard
              classes="mb-4"
              title={
                <div className="flex items-center">
                  <p
                    className={`text-nowrap overflow-hidden ${
                      gridType === 1 && "maxw-85p"
                    } textOverflow-ellipsis`}
                  >
                    {d?.title}
                  </p>
                </div>
              }
              image={d?.media}
              id={d?.slug}
              subTitle={
                <div className="flex items-center w-full tracking-tight fs-16">
                  {t(isTrue(d?.privacy) ? "Private Project" : "Public Project")}
                  <span className="vertical-align-middle mx-1">&middot;</span>
                  {t("Associated Challenge")}:{" "}
                  <span
                    className="link text-truncate w-80p ml-1"
                    onClick={() =>
                      navigateChallenge(d?.challenge_details?.slug)
                    }
                  >
                    {d?.challenge_details?.title}
                  </span>
                </div>
              }
              description={d?.description}
              mediaType={d?.media_type}
              noSave={noSave}
              col={gridType !== 1}
              rightAction={
                browseMode === 0 || browseMode === 1 || browseMode === 3 ? (
                  <div className="flex items-center">
                    {browseMode === 3 && (
                      <Button
                        bordered
                        color={isTrue(d?.favourite) ? "red" : "blue"}
                        height="30px"
                        classes="fs-14"
                        onClick={() => handleFavoriteUnFav(d?.slug)}
                      >
                        {isTrue(d?.favourite) ? (
                          <>
                            {" "}
                            <Delete className="mr-1" /> {t("Remove", "common")}
                          </>
                        ) : (
                          <>
                            {" "}
                            <BookmarkOutlined className="mr-1" />{" "}
                            {t("Save", "common")}
                          </>
                        )}
                      </Button>
                    )}
                    {(browseMode === 0 || browseMode === 1) &&
                      (d?.assessment_data?.assessment_status === "published" ? (
                        <Button
                          classes="fs-12 minh-25"
                          height="25px"
                          width="130px"
                          color="orange"
                          onClick={() => onAssessProject(d)}
                        >
                          {t("Assessment Details")}
                        </Button>
                      ) : (
                        (d?.access_level === "team_leader" ||
                          d?.access_level === "editor") && (
                          <ProjectManageOptions
                            data={d}
                            onDelete={setOpenConfirmationModal}
                            pinUnpin={handlePinUnpin}
                          />
                        )
                      ))}
                  </div>
                ) : browseMode === 4 ? (
                  <Button
                    onClick={() => onAssessProject(d)}
                    color="orange"
                    classes="fs-12 minh-25"
                    height="25px"
                    width="120px"
                  >
                    {t("Assess Project")}
                  </Button>
                ) : (
                  browseMode === 5 && (
                    <Button
                      color="orange"
                      classes="fs-12 minh-25"
                      height="25px"
                      width="175px"
                      bordered
                      onClick={() => onAssessProject(d)}
                    >
                      {t("Submitted Assessment")}
                    </Button>
                  )
                )
              }
              type={COMPONENT_TYPES.PROJECT}
              iconsAndValuesList={[
                {
                  icon: Group,
                  value: `${d?.member_count || 0} ${t("Members")}`,
                },
                {
                  icon: Update,
                  value: `${t("Updated")} ${moment(d?.updated_at).fromNow()}`,
                },
                (isTrue(d?.is_submitted) ||
                  d?.challenge_details?.challenge_status === "closed" ||
                  Boolean(d?.challenge_details?.due_date)) && {
                  icon: AccessAlarm,
                  classes: isTrue(d?.is_submitted) ? "c-success" : "",
                  value: isTrue(d?.is_submitted)
                    ? t("Submitted")
                    : d?.challenge_details?.challenge_status === "closed"
                    ? t("Challenge Closed for Submission")
                    : `${t("Due on")} ${moment(
                        d?.challenge_details?.due_date
                      ).format("MMM DD YYYY")}`,
                },
              ].filter(Boolean)}
              bottomAction={
                browseMode === 2 &&
                (d.joinedStatus === "accepted" ||
                d.joinedStatus === "declined" ? (
                  <Button
                    bordered
                    color={d.joinedStatus === "accepted" ? "green" : "red"}
                    width="90px"
                    height="26px"
                    classes="fs-12"
                  >
                    <Done fontSize="small" />{" "}
                    {t(d.joinedStatus === "accepted" ? "Accepted" : "Declined")}
                  </Button>
                ) : (
                  <div className={`${gridType === 2 && "w-full"}`}>
                    <AcceptDeclineButtons
                      onAccept={() => handleJoinAcceptDecline(d.slug, false)}
                      onDecline={() => handleJoinAcceptDecline(d.slug, true)}
                    />
                  </div>
                ))
              }
            />
          </Grid>
        );
      })}
      <ConfirmationModal
        open={Boolean(openConfirmationModal)}
        onClose={() => setOpenConfirmationModal(false)}
        desc={`You will delete this Project permanently, are you sure?`}
        okayButtonTitle="Delete"
        onOkay={handleDelete}
      />
    </ListingContainer>
  );
};

export default ProjectListing;
