import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import { mockRequestGet, mockRequestDelete, mockRequestPost } from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api";
import useChallengePaths from "./service-hook";

let getSpy;
let deleteSpy;
let postSpy;

let challengePathId = "1";

const payload = {
  language: "en",
}

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com" },
  },
  [REDUCER_TYPES.MASTER]: {},
});

const ChallengePathServicesMock = () => {
  const { fetchChallengePaths,
    fetchPublicChallengePath,
    fetchChallengePath,
    deleteChallengePath,
    fetchPrivateChallengePaths,
    create, edit } = useChallengePaths();

  const BUTTON_LINKS = [
    { name: "fetchChallengePaths", onClick: () => fetchChallengePaths({}) },
    { name: "fetchPublicChallengePath", onClick: () => fetchPublicChallengePath(challengePathId) },
    { name: "fetchChallengePath", onClick: () => fetchChallengePath(challengePathId) },
    { name: "deleteChallengePath", onClick: () => deleteChallengePath(challengePathId) },
    { name: "fetchPrivateChallengePaths", onClick: () => fetchPrivateChallengePaths({}) },
    { name: "create", onClick: () => create({}) },
    { name: "edit", onClick: () => edit(challengePathId, {}) }
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
}

let screen;

describe("Challenge Paths Services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <ChallengePathServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    deleteSpy = mockRequestDelete();
    postSpy = mockRequestPost();
  });

  test("fetchChallengePaths", async () => {
    const button = screen.getByTestId("fetchChallengePaths");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE_PATH}?language=en&`,
        {},
        headers,
        expect.any(Function)
      );
    });
  });

  test("fetchPublicChallengePath", async () => {
    const button = screen.getByTestId("fetchPublicChallengePath");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.CHALLENGE_PATH}/${challengePathId}?language=en`,
        {},
        headers,
        expect.any(Function)
      );
    });
  })

  test("fetchChallengePath", async () => {
    const button = screen.getByTestId("fetchChallengePath");
    userEvent.click(button);

    await waitFor(() => {
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_PATH}/${challengePathId}?language=en`,
        {},
        headers,
        expect.any(Function)
      );
    });
  })

  test("deleteChallengePath", async () => {
    const button = screen.getByTestId("deleteChallengePath");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_PATH}/${challengePathId}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("fetchPrivateChallengePaths", async () => {
    const button = screen.getByTestId("fetchPrivateChallengePaths");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_PATH}?language=en&`,
        {},
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });


  test("edit", async () => {
    const button = screen.getByTestId("edit");
    userEvent.click(button);
  
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_PATH}/${challengePathId}/${ENDPOINTS.UPDATE}?language=en`,
        {
          ...payload,
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("create", async () => {
    const button = screen.getByTestId("create");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CHALLENGE_PATH}/${ENDPOINTS.CREATE}?language=en`,
        {
          ...payload,
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
        expect.any(Function)
      )
    );
  });

});