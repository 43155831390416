import Card from "components/Cards/Card";
import EditCreateHeaderCard from "components/Cards/EditCreateHeaderCard";
import CreateFormFooter from "components/UI/atoms/CreateFormFooter";
import AssociatedListing from "components/UI/organisms/AssociatedListing";
import useForms from "helpers/hooks/useForms";
import {
  deleteValues,
  keyVal,
  payloadFormatArray,
  payloadKeyVal,
  payloadKeyVals,
  payloadToArrayKeyValues,
} from "helpers/utils/utilities";
import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useLabPrograms from "store/lab-programs/service-hook";
import useLabs from "store/labs/service-hook";
import AddAcheivement from "./AddAcheivement";
import { schema, stepSchema } from "./data";
import LabProgramDetails from "./LabProgramDetails";
import { toast } from "react-toastify";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import useAssociation from "store/association";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useOrgDashboard from "store/dashboard/org";

const STEPS = [
  "Lab Program Details",
  "Associated Labs",
  "Achievements (optional)",
];
const CreateLabProgram = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [experts, setExperts] = useState([]);
  const { create, edit, fetchLP } = useLabPrograms();
  const { fetchLabsSelectList } = useLabs();
  const [labs, setLabs] = useState({ all: [], selected: [] });

  const { id } = useParams();
  const { fetchAssociation } = useAssociation();
  const editMode = !!id;
  const [initLoading, setInitLoading] = useState(Boolean(editMode));
  const sliceSchema = (_schema) => stepSchema(currentStep);
  const { t } = useLocalizedTranslation();

  const { data, errors, validate, onChange, setData } = useForms(
    schema,
    {
      privacy: "no",
      is_achievement_enabled: false,
    },
    sliceSchema
  );

  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const { getSubscriptionDetails } = useOrgDashboard();

  useEffect(() => {
    if (editMode) handleFetchDetail();
    else {
      setData({});
      setCurrentStep(0);
      setLabs({ all: [], selected: [] });
    }
  }, [id, editMode]);

  useEffect(() => {
    handleFetchLabs();
  }, [editMode]);

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  const fetchPlanDetails = async () => {
    const res = await getSubscriptionDetails();
    if (res) {
      setSubscriptionDetails(res);
    }
  };

  const handleFetchLabs = async () => {
    const res = await fetchLabsSelectList();
    setLabs({
      all: res,
      selected:
        editMode && data?.labs?.length
          ? res.filter((d) => data?.labs?.some((f) => f?.uuid === d?.uuid))
          : labs.selected || [],
    });
  };

  const debounceLabSearch = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const onSearchLabs = async (search) => {
    let labs = [];
    labs = await fetchLabsSelectList(search);
    setLabs((prev) => ({
      all: [
        ...labs?.map((item) => ({
          ...item,
          type: COMPONENT_TYPES.LAB,
        })),
      ],
      selected: prev.selected,
    }));
  };
  const debouncedSearchLabs = debounceLabSearch(onSearchLabs, 1000);

  const handleFetchDetail = async () => {
    const res = await fetchLP(id);
    if (res) {
      const {
        title,
        description,
        skills = {},
        skill_groups = {},
        skill_stacks = {},
        category_id,
        level_id,
        duration_id,
        id: _id,
        media,
        achievement,
        labs,
        privacy,
        category,
        level,
        duration,
        type,
        mode,
        is_achievement_enabled,
        expert_references,
      } = res;

      setData({
        title,
        description,
        skills: payloadToArrayKeyValues(skills),
        skill_groups: payloadToArrayKeyValues(skill_groups),
        skill_stacks: payloadToArrayKeyValues(skill_stacks),
        category_id: keyVal(category, category_id),
        level_id: keyVal(level, level_id),
        duration_id: keyVal(duration, duration_id),
        is_achievement_enabled: is_achievement_enabled === "yes" ? true : false,
        id: _id,
        media,
        labs,
        privacy,
        ...achievement,
        type,
        mode,
      });

      setExperts(expert_references || []);

      const [_labs] = await fetchAssociation(
        COMPONENT_TYPES.LAB_PROGRAMS,
        id,
        COMPONENT_TYPES.LAB
      );
      setLabs((val) => ({
        all: val.all,
        selected: [
          ...(_labs || [])?.map((item) => ({
            uuid: item.id,
            title: item.title,
            media: item.media,
          })),
        ],
      }));

      setInitLoading(false);
    }
  };

  const handleSaveOrEdit = async (isDraft) => {
    let payload = {
      ...data,
      lab_ids: labs.selected.map((d) => d.uuid),
      status: isDraft === true ? "draft" : "publish",
      media_type: data?.iframe_code ? "embedded" : "image",
      media: data?.iframe_code ? data?.iframe_code : data?.media,
      is_achievement_enabled: data?.is_achievement_enabled ? "yes" : "no",
      expert_references: payloadFormatArray(experts, "email"),
    };

    payloadKeyVal(payload, ["level_id", "duration_id", "category_id"]);
    payloadKeyVals(payload, ["skills", "skill_groups", "skill_stacks"]);
    const deleteVals = ["lab", "labs", "id", "iframe_code"];

    if (editMode) {
      if (typeof data.media === "string") {
        deleteVals.push("media");
      }

      if (typeof data.achievement_image === "string") {
        deleteVals.push("achievement_image");
      }
    }
    if (!data?.skill_groups || !data?.skill_groups.length) {
      deleteVals.push("skill_groups");
    }
    if (!data?.skill_stacks || !data?.skill_stacks.length) {
      deleteVals.push("skill_stacks");
    }

    if (!data?.skills || !data?.skills.length) {
      deleteVals.push("skills");
    }

    if (!data?.is_achievement_enabled) {
      deleteVals.push("achievement_image");
      deleteVals.push("achievement_name");
      deleteVals.push("achievement_points");
    }

    if (data.is_achievement_enabled) {
      payload = {
        ...payload,
        old_winner_achievement_image: data?.achievement_image,
      };
    }

    deleteValues(payload, deleteVals);
    setLoading(true);
    editMode ? await edit(id, payload) : await create(payload);
    setLoading(false);
  };

  const stepValidate = async (errors) => {
    if (
      currentStep === 1 ||
      (currentStep === 2 && !data?.is_achievement_enabled)
    ) {
      ["achievement_image", "achievement_name", "achievement_points"].forEach(
        (d) => errors.delete(d)
      );
    }
    return errors;
  };

  const handleNext = async (isDraft) => {
    if (currentStep === 1 && !labs.selected.length) {
      toast.error(t("Please select at least one lab!"));
      return;
    }
    const _errors = stepValidate(await validate());
    if (!_errors.size) {
      if (currentStep === 2) {
        handleSaveOrEdit(isDraft);
      } else {
        setCurrentStep((val) => val + 1);
      }
    }
  };

  const handleBack = () => {
    setCurrentStep((val) => val - 1);
  };

  return (
    <DashboardLayout loading={initLoading}>
      <EditCreateHeaderCard
        current={currentStep}
        length={STEPS.length}
        title={`${editMode ? "EDIT A" : "CREATE A"} LAB PROGRAM`}
        classes="mt-header"
        subtitles={STEPS}
      />
      <Card centered classes="my-10 column-center container">
        {currentStep === 0 && (
          <LabProgramDetails
            data={data}
            errors={errors}
            onChange={onChange}
            step={currentStep}
            experts={experts}
            setExperts={setExperts}
            t={t}
          />
        )}

        {currentStep === 1 && (
          <AssociatedListing
            onSearch={debouncedSearchLabs}
            list={labs}
            setList={setLabs}
            type={COMPONENT_TYPES.LAB}
            title={t("Browse Labs to add to your Lab Program")}
            subTitle={t(
              "You may only associate Labs with the same visibility as the Lab Program."
            )}
            component="Labs"
            tabs={["All"]}
          />
        )}
        {currentStep === 2 && (
          <AddAcheivement
            data={data}
            errors={errors}
            onChange={onChange}
            disabled={subscriptionDetails?.plan === "seed_plan_yearly"}
            t={t}
          />
        )}

        <CreateFormFooter
          step={currentStep}
          stepsLength={STEPS.length}
          onCancel={handleBack}
          disabled={stepValidate(errors).size}
          loading={loading}
          onOk={handleNext}
          editMode={editMode}
          okButtonTitle={
            editMode && currentStep === 2
              ? "Save & Exit"
              : !editMode && currentStep === 2
              ? "Create Lab Program"
              : "Continue"
          }
          okButtonColor="blue"
          backButtonColor="blue"
          confirmationOkBtnTitle="Exit Now"
        ></CreateFormFooter>
      </Card>
    </DashboardLayout>
  );
};

export default CreateLabProgram;
