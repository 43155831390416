import React, { useState } from "react";
import { Grid } from "@mui/material";
import Button from "components/Button";
import SchemaUIExtractor from "../../../components/UI/organisms/SchemaUIExtractor";
import { patentSchema } from "pages/profile/forms/data";
import { toast } from "react-toastify";

export const validatePatent = (patent,t) => {
  if (!patent || !patent?.length) return true;
  for (const d of patent) {
    if (!d.name || !d.company) {
      toast.error(t("Please enter the patent details properly!"));
      return true;
    }
  }
  return false;
};

const Patent = ({ patent, setPatent, errors, onDelete, t = (d) => d }) => {
  const [loading, setLoading] = useState(false);
  const addPatent = () => {
    setPatent((prevState) => [...prevState, {}]);
  };

  const onChangePatent = (e, index) => {
    const { name, value } = e.target;
    patent[index][name] = value;
    setPatent([...patent]);
  };

  const removePatent = async (indexToRemove) => {
    if (patent[indexToRemove]?.id) {
      setLoading(indexToRemove);
      await onDelete(patent[indexToRemove]?.id);
      setLoading(false);
    }
    patent.splice(indexToRemove, 1);
    setPatent([...patent]);
  };
  return (
    <div>
      <div className="flex items-center justify-between mt-3">
        <h2>{t("Edit Patent")}</h2>
        <Button bordered onClick={addPatent}>
          {" "}
          {t("Add Entry")}
        </Button>
      </div>
      {patent.map((d, i) => (
        <div key={i}>
          <h3 className="mt-3">
            {t("Entry")} #{i + 1}
          </h3>
          <Grid container xs={12} spacing={4}>
            <Grid item xs={12} sm={6}>
              <SchemaUIExtractor
                schema={patentSchema.slice(0, 3)}
                data={d}
                onChange={(e) => onChangePatent(e, i)}
                errors={errors}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              direction="column"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <SchemaUIExtractor
                schema={patentSchema.slice(3, 4)}
                data={d}
                onChange={(e) => onChangePatent(e, i)}
                errors={errors}
              />
              <Button
                classes="mt-2"
                width="100px"
                height="32px"
                loading={loading === i}
                color="red"
                bordered
                onClick={() => removePatent(i)}
              >
                {t("Remove")}
              </Button>
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default Patent;
