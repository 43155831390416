import React from "react";
import smallLogo from 'assets/svg/small-logo.svg';
import { Avatar } from "@mui/material";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
const HostedBySection = ({isCreatedBy}) => {
  const { t } = useLocalizedTranslation("common");
  return (
    <>
      <h3 className="mt-4">{isCreatedBy ? t("Created by", "common") :  t("Hosted by", "common")}</h3>
      <div className="flex mt-2">
        <Avatar src={smallLogo} sx={{ height: 56, width: 56 }} />
        <div className="ml-2">
          <p>
            <b>Prepr</b>
          </p>
          <p className="fs-12 opacity-90">
            Prepr connects students and start-ups to employees and employers in
            order to build skills, create verifiable portfolios and tackle
            challenges. We do this by creating dedicated labs for individuals
            and teams to co-learn and co-lab together in order to co-solve
            organizational challenges.
          </p>

          <p className="link fs-14 mt-3"> {t("More events by Prepr","common")}</p>
        </div>
      </div>
    </>
  );
};

export default HostedBySection;
