import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/reducer";
import masterReducer from "./master/reducer";
import membersReducer from "./member-management/reducer";
import labsReducer from "./labs/reducer";
import challengesReducer from "./challenges/reducer";
import profileReducer from "./profile/reducer";
import settingsReducer from "./settings/reducer";
import go1Reducer from "./go1/reducer";
import advanceSearchReducer from "./advance-search/reducer";
import notificationReducer from "./notifications/reducer";
import onboardingReducer from "./onboarding/reducer";

export const REDUCER_TYPES = {
  AUTH: "auth",
  MASTER: "master",
  MEMBERS: "members",
  LABS: "labs",
  CHALLENGES: "challenges",
  PROFILE: "profile",
  SETTINGS: "settings",
  GO1: "go1",
  ADVANCE_SEARCH: "advanceSearch",
  NOTIFICATION: "notification",
  ONBOARDING: "onboarding",
};

const store = configureStore({
  reducer: {
    [REDUCER_TYPES.AUTH]: authReducer,
    [REDUCER_TYPES.MASTER]: masterReducer,
    [REDUCER_TYPES.MEMBERS]: membersReducer,
    [REDUCER_TYPES.LABS]: labsReducer,
    [REDUCER_TYPES.CHALLENGES]: challengesReducer,
    [REDUCER_TYPES.PROFILE]: profileReducer,
    [REDUCER_TYPES.SETTINGS]: settingsReducer,
    [REDUCER_TYPES.GO1]: go1Reducer,
    [REDUCER_TYPES.ADVANCE_SEARCH]: advanceSearchReducer,
    [REDUCER_TYPES.NOTIFICATION]: notificationReducer,
    [REDUCER_TYPES.ONBOARDING]: onboardingReducer,
  },
});

export default store;
