import { Search } from "@mui/icons-material";
import { Grid, Box } from "@mui/material";
import Input from "components/Input";
import FilterCard from "components/UI/organisms/FilterCard";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import Button from "components/Button/";
import ModalLayout from "components/Modal";
import ListingContainer from "components/UI/organisms/ListingContainer";
import useGO1 from "store/go1/service-hook";
import ResourceCard from "./ResourceCard";
import ResourcePreview from "./ResourcePreview";
import Select from "components/Input/Select";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import useAuth from "store/auth/service-hook";

export const SORT = [
  {
    value: "Popularity",
    key: "popularity",
  },
  {
    value: "Title",
    key: "title",
  },
  {
    value: "Created",
    key: "created",
  },
  {
    value: "Relevance",
    key: "relevance",
  },
  {
    value: "Price",
    key: "price",
  },
];

const GO1ResourceBuilder = ({ open, onClose }) => {
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const { list, create } = useGO1();
  const [resources, setResources] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [isPreview, setIsPreview] = useState(false);
  const [selectedResource, setSelectedResource] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [searchType, setSearchType] = useState("");
  const { selected } = useFilters();
  const [sort, setSort] = useState("popularity");
  const windowWidth = useWindowWidth();
  const onMobile = useMemo(() => windowWidth < 700, []);
  const {state: { user }} = useAuth();
  useEffect(() => {
    handleFetchResources();
  }, [page, search, selected, sort]);

  const handleFetchResources = async () => {
    setLoading(true);
    const filters = {
      search: search,
      page: page,
      sort: sort,
    };
    if (selected.content_type) filters["type"] = selected.content_type;
    if (selected.go1_topics) filters["topics"] = selected.go1_topics;
    if (selected.go1_providers) filters["provider"] = selected.go1_providers;
    if (selected.go1_duration)
      filters["duration"] = JSON.parse(selected.go1_duration);
    if (selected.language) filters["language"] = selected.language;
    const [go1list, total_pages, total_count, current_page] = await list(
      filters
    );

    setResources(go1list);
    setPages(total_pages);
    setPage(current_page);
    setTotalCount(total_count);
    setLoading(false);
  };

  const switchPreviewMode = (resourceId) => {
    if (isPreview) {
      setIsPreview(false);
      setSelectedResource(null);
    } else {
      setIsPreview(true);
      const res = resources.find((res) => res?.id === resourceId);
      setSelectedResource(res);
    }
  };

  const handleCreateCourse = async (resource) => {
    await create(resource);
  };

  const handleSearchClick = () => setSearch(searchType);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchType(inputValue);
    if (inputValue === "") setSearch("");
  };

  return (
    <ModalLayout open={open} onClose={onClose}>
      {onMobile && <h3>Resource Builder</h3>}
      <div className="flex-center mt-2">
        <Input
          icon={<Search />}
          classes="w-500"
          width="100%"
          iconLeft
          value={searchType}
          onChange={handleInputChange}
          placeholder="Search for a Course or topic from Go1"
          disabled={isPreview}
        />
        <Button classes="ml-2" disabled={isPreview} onClick={handleSearchClick}>
          Search
        </Button>
      </div>
      <Grid
        container
        mt={2}
        spacing={1}
        className="overflow-hidden border-t pt-2"
        sx={{ height: { xs: "auto", md: "78vh" } }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          lg={3}
          pb={2}
          className="h-full overflowY-auto"
        >
          <Box sx={{ width: { xs: "100%", sm: "93%" } }}>
            <FilterCard
              title="Filters"
              clearFilters
              types={[
                FILTER_TYPES.GO1_DURATIONS,
                FILTER_TYPES.GO1_TOPICS,
                FILTER_TYPES.GO1_PROVIDERS,
                FILTER_TYPES.CONTENT_TYPE,
                FILTER_TYPES.LANGUAGE,
              ]}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          lg={9}
          pb={2}
          px={1}
          className="h-full border-l overflowY-auto"
        >
          {isPreview ? (
            <ResourcePreview
              switchPreviewMode={switchPreviewMode}
              loading={loading}
              resource={selectedResource}
              handleCreateCourse={handleCreateCourse}
              canCreateCourse={user?.go1?.can_create_resource}
            />
          ) : (
            <>
              <div className="flex-center justify-between">
                <h3 className="fs-16--mob">{totalCount || "0"} Results</h3>
                <Select
                  borderedIcon
                  value={sort}
                  onChange={(e) => setSort(e.target.value)}
                  width="100%"
                  keyValues={SORT}
                  height={40}
                  classes="w-40p w-60p--mob"
                />
              </div>
              <ListingContainer
                rowLoader
                rowSpacing={2}
                isEmpty={!resources || !resources.length}
                loading={loading}
                count={pages}
                page={page}
                setPage={setPage}
                emptyText="No resources found"
                classes="mt-1"
              >
                {resources?.map((d) => (
                  <ResourceCard
                    key={d?.slug}
                    hoveredCardIndex={hoveredCardIndex}
                    i={d?.id}
                    setHoveredCardIndex={setHoveredCardIndex}
                    resource={d}
                    switchPreviewMode={switchPreviewMode}
                    handleCreateCourse={handleCreateCourse}
                    canCreateCourse={user?.go1?.can_create_resource}
                  />
                ))}
              </ListingContainer>
            </>
          )}
        </Grid>
      </Grid>
    </ModalLayout>
  );
};

export default GO1ResourceBuilder;
