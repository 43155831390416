import React, { useMemo, useState } from "react";
import useAdvanceSearch from "store/advance-search/service-hook";
import useAdvanceSearchFilter from "../hooks/useAdvanceSearchFilter";
import { AS_REDUCER } from "store/advance-search/reducer";
import { useAdvanceSearchContext } from "../hooks/useAdvanceSearchContext";
import { ADVANCE_SEARCH_COMPONENTS } from "../data";
import ChallengeListing from "pages/challenges/listing/ChallengeListing";

const AdvanceSearchChallenge = () => {
  const { gridType, keyword, filters, getFiltersViaType } =
    useAdvanceSearchContext();
  const { searchChallenges } = useAdvanceSearch();

  const [page, setPage] = useState(1);

  const challengeFilters = useMemo(() => {
    return getFiltersViaType(ADVANCE_SEARCH_COMPONENTS.CHALLENGES);
  }, [filters]);

  const { data } = useAdvanceSearchFilter(
    ADVANCE_SEARCH_COMPONENTS.CHALLENGES,
    keyword,
    { page, ...challengeFilters },
    searchChallenges,
    AS_REDUCER.setChallenges,
    false,
    0
  );

  return (
    <ChallengeListing
      browseMode={0}
      challenges={data?.data || []}
      gridType={gridType}
      noPagination={!Boolean(data?.meta ?? false)}
      page={data?.meta?.current_page ?? 1}
      setPage={setPage}
      pageCount={data?.meta?.total_pages ?? 1}
      loading={data?.loading}
    />
  );
};

export default AdvanceSearchChallenge;
