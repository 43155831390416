import { Chip, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

const SendToUsers = ({
  users,
  added,
  setAdded,
  noScroll,
  classes,
  view,
  t = (d) => d,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClose = () => setShowMenu(null);

  const handleAddUsers = (user) => {
    if (!added.some((addedUser) => addedUser.id === user.id))
      setAdded((val) => [...val, user]);
  };

  const onRemoveUser = (id) => {
    if (view) return;
    setAdded((val) => val.filter((d) => d?.id !== id));
  };

  return (
    <div className="flex items-center w-full mt-2">
      <b className={classes}>{t("To")}:</b>
      <div
        className="px-2"
        style={{
          overflowY: "auto",
          height: !noScroll && "40px",
        }}
      >
        {added.map((user) => (
          <Chip
            key={user?.id}
            label={user?.title}
            className="mx-1 mt-1 fw-500"
            onDelete={() => onRemoveUser(user?.id)}
          />
        ))}
      </div>
      <input
        className="ghostInput w-80"
        onClick={(e) => setShowMenu(e.currentTarget)}
      />
      <Menu open={showMenu} onClose={handleMenuClose} anchorEl={showMenu}>
        {users.map(({ id, title }) => (
          <MenuItem key={id} onClick={() => handleAddUsers({ id, title })}>
            <div className="fs-14 fw-500 tracking-tight flex">
              <p className="w-210 mr-1 text-truncate">{title}</p>{" "}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SendToUsers;
