import React, { useState } from "react";
import { Drawer } from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
  Mail,
  Message,
  Search,
  ShoppingCartCheckout,
} from "@mui/icons-material";
import { lab } from "assets/svg/blue";
import { challenge, challengePath } from "assets/svg/green";
import { resource, resourceCollection, resourceGroup } from "assets/svg/purple";
import { team } from "assets/svg/yellow";
import { settings } from "assets/svg/gradient/exports";
import {
  Avatar,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Input from "components/Input";
import { useNavigate } from "react-router";
import useAuth from "store/auth/service-hook";
import userImage from "assets/svg/gradient/user.svg";
import { isNormalUser } from "helpers/utils/utilities";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import { useChallengeOptions } from "pages/challenges/listing/ManageChallenges";
import { useResourceCreateOptions } from "pages/resources/ResourceModule/listing/Manage";
import { useLabOptions } from "pages/labs/listing/ManageLabs";
import { labProgram } from "assets/svg/blue";
import { org } from "assets/svg/yellow";
import CreationOptionsModal from "components/Modal/CreateOptionsModal";

const MobileMenu = ({ open, onClose, plan, t }) => {
  const [collapsed, setCollapsed] = useState(new Set());
  const [modal, setModal] = useState(null);
  const [createOptions, setCreateOptions] = useState([]);

  const toggleCollapsed = (title) => {
    collapsed.has(title) ? collapsed.delete(title) : collapsed.add(title);
    setCollapsed(new Set(collapsed));
  };

  const { profile } = useSelector((state) => state[REDUCER_TYPES.PROFILE]);
  const navigate = useNavigate();
  const {
    logout,
    state: { isLoggedIn, user },
  } = useAuth();
  const isNormalUserView = isNormalUser(user);

  const handleModalOpen = (option, options) => {
    setModal(option);
    setCreateOptions(options);
  };

  const handleModalClose = () => {
    setModal(null);
    setCreateOptions([]);
  };

  const challengeOptions = useChallengeOptions(setModal);
  const resourceOptions = useResourceCreateOptions(setModal);
  const labOptions = useLabOptions();

  const menu_items = [
    {
      title: null,
      items: [
        {
          title: "Careers",
          path: "/careers",
        },
      ],
    },
    {
      title: null,
      items: [
        {
          title: "Explore",
          path: "/explore",
        },
      ],
    },

    isNormalUserView && {
      title: null,
      items: [
        {
          title: "Dashboard",
          path: "/dashboard",
        },
      ],
    },
    !isNormalUserView && {
      title: "Dashboard",
      items: [
        {
          title: "User Dashboard",
          path: "/dashboard",
        },
        {
          title: "Lab Dashboard",
          path: "/dashboard/lab",
        },
        {
          title: "Organization Dashboard",
          path: "/dashboard/org",
        },
      ],
    },

    !isNormalUserView && {
      title: "Manage",
      items: [
        {
          title: "Manage Labs",
          icon: lab,
          path: "/manage/lab",
        },
        {
          title: "Manage Challenges",
          icon: challenge,
          path: "/manage/challenge",
        },
        {
          title: "Manage Resources",
          icon: resource,
          path: "/manage/resource",
        },
        {
          title: "Manage Organizations",
          icon: org,
          path: "/manage/organization",
        },
      ],
    },
    isNormalUserView && {
      title: "Learn",
      items: [
        {
          title: "My Labs",
          icon: lab,
          path: "/lab?browseMode=1",
        },
        {
          title: "My Challenges",
          icon: challenge,
          path: "/challenge?browseMode=3",
        },
        {
          title: "My Resources",
          icon: resource,
          path: "/resource?browseMode=1",
        },
        {
          title: "Team Matching",
          icon: team,
          path: "/team-matching?browseMode=2",
        },
      ],
    },
    !isNormalUserView && {
      title: "Create",
      items: [
        {
          title: "Create a Challenge",
          icon: challenge,
          onClick: () => handleModalOpen("challenge", challengeOptions),
        },
        {
          title: "Create a Challenge Path",
          icon: challengePath,
          onClick: () => navigate("/challenge-path/create"),
        },
        {
          title: "Create a Lab",
          icon: lab,
          onClick: () => handleModalOpen("lab", labOptions),
        },
        {
          title: "Create a Lab Program",
          icon: labProgram,
          onClick: () => navigate("/lab-program/create"),
        },
        {
          title: "Create an Organization",
          icon: org,
          onClick: () => navigate("/organization/create"),
        },
        {
          title: "Create a Resource Module",
          icon: resource,
          onClick: () => handleModalOpen("resource", resourceOptions),
        },
        {
          title: "Create a Resource Collection",
          icon: resourceCollection,
          onClick: () => navigate("/resource-collection/create"),
        },
        {
          title: "Create a Resource Group",
          icon: resourceGroup,
          onClick: () => navigate("/resource-group/create"),
        },
      ],
    },

    {
      title: null,
      items: [
        {
          title: "Inbox",
          muiIcon: true,
          icon: <Mail />,
          path: "/chats",
        },
      ],
    },
    {
      title: null,
      items: [
        {
          title: "Upgrade Plan",
          muiIcon: true,
          icon: <ShoppingCartCheckout />,
          path: "/upgrade-plan",
        },
      ],
    },
    {
      title: null,
      items: [
        {
          title: "Notifications",
          muiIcon: true,
          icon: <Message />,
          path: "/notifications",
        },
      ],
    },
    {
      title: null,
      items: [
        {
          title: "Settings",
          icon: settings,
          path: "/settings",
        },
      ],
    },

    {
      title: null,
      items: [
        {
          title: "Logout",
          onClick: logout,
        },
      ],
    },
  ].filter(Boolean);
  if (!isLoggedIn) return null;

  return (
    <>
      <Drawer
        className="drawer dark"
        open={open}
        anchor="left"
        onClose={onClose}
      >
        <Input
          classes="mx-2 my-2"
          placeholder="Search PreprLabs"
          icon={<Search style={{ color: "#1D3349" }} />}
          iconLeft
        />
        <ListItem className="mx-1">
          <ListItemIcon>
            <Avatar
              src={
                profile
                  ? profile?.profile_image
                  : user?.profile_image || userImage
              }
              sx={{ height: 56, width: 56 }}
            />
          </ListItemIcon>
          <ListItemText
            className="ml-2 c-white"
            primary={user?.full_name}
            secondary={
              <p
                onClick={() => navigate(`/profile/${user?.username}`)}
                className="link c-white"
              >
                VIEW PROFILE
              </p>
            }
            secondaryTypographyProps={{ color: "white" }}
            primaryTypographyProps={{ fontSize: 18 }}
          />
        </ListItem>
        <List className="c-white">
          {menu_items.map(({ items, title }) => (
            <React.Fragment key={title}>
              {title && (
                <ListItemButton onClick={() => toggleCollapsed(title)}>
                  <ListItemText
                    primary={t(title)}
                    primaryTypographyProps={{ fontWeight: 600 }}
                  />
                  {collapsed.has(title) ? <ExpandMore /> : <ExpandLess />}
                </ListItemButton>
              )}
              <Collapse in={!collapsed.has(title)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {items.map(({ title, path, icon, muiIcon, onClick }, i) => (
                    <ListItemButton
                      key={i}
                      sx={{ pl: 4 }}
                      onClick={() => (path ? navigate(path) : onClick())}
                    >
                      {icon && (
                        <ListItemIcon className="c-white">
                          {muiIcon ? (
                            icon
                          ) : (
                            <img src={icon} alt={title} height="24px" />
                          )}
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={t(title)}
                        primaryTypographyProps={{ fontWeight: 400 }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Drawer>
      {modal && (
        <CreationOptionsModal
          open={Boolean(modal)}
          onClose={handleModalClose}
          options={createOptions}
          plan={plan}
          title={`How Do You Want To Create A New ${
            modal?.charAt(0).toUpperCase() + modal?.slice(1)
          }?`}
        />
      )}
    </>
  );
};

export default MobileMenu;
