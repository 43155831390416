import ModalLayout from "components/Modal";
import React, {useEffect, useState} from "react";
import Input from "components/Input";
import Select from "components/Input/Select";
import Button from "components/Button";
import {Grid} from "@mui/material";
import TextBox from "components/Input/Textbox";
import {Mail} from "@mui/icons-material";
import AutoComplete from "components/Input/AutoComplete";
import NetworkUsersSelect from "components/UI/molecules/NetworkUsersSelect";
import {EMAIL_VALIDATOR, Validate} from "helpers/utils/validators";
import DropBox from "components/Input/DropBox";
import {createEvent} from "helpers/utils/utilities";
import Checkbox from "components/Input/Checkbox";
import HrIntegrations from "./HrIntegrations";
import Radio from "../../../components/Input/Radio";


const AddMemberModal = ({
                            open,
                            onClose,
                            handleInvite,
                            roles,
                            emails,
                            setEmails,
                            data,
                            onChange,
                            errors,
                            disablePermissions,
                            networkUsers,
                            setNetworkUsers,
                            csv,
                            setCsv,
                            type
                        }) => {
    const [formErrors, setFormErrors] = useState(new Map());
    const [invitationType, setInvitationType] = useState('by-email')
    const [invitationTypes] = useState([
        {value: "By email", key: "by-email"},
        {value: "From HR Integrations", key: "from-hr-integration"}
    ])

    useEffect(() => {
        if (!!csv && !csv?.type?.includes('csv')) {
            formErrors.set('csv', 'File should be a valid CSV file!');
        } else {
            formErrors.delete('csv');
        }
        setFormErrors(new Map(formErrors));
    }, [csv]);

    const handleSelectEmail = async (email) => {
        if (!!email && !emails.includes(email)) {
            const emailValidationResult = await Validate(EMAIL_VALIDATOR, email);
            setEmails([...emails, email]);
            if (emailValidationResult !== false) {
                formErrors.set('email', emailValidationResult);
            } else {
                const updatedEmails = [...emails, email];
                const areAllEmailsValid = await validateAllEmails(updatedEmails);
                if (areAllEmailsValid) {
                    formErrors.delete('email');
                }
            }
            setFormErrors(new Map(formErrors));
        }
    };

    const validateAllEmails = async (emailList) => {
        let areAllEmailsValid = true;
        await emailList.forEach(async (email) => {
            const result = await Validate(EMAIL_VALIDATOR, email);
            if (result !== false) {
                areAllEmailsValid = false;
            }
        });
        return areAllEmailsValid;
    };

    const handleRemoveEmail = async (email) => {
        await setEmails((prev) => {
            const newEmails = prev.filter((e) => e !== email);
            validateAllEmails(newEmails).then((areAllEmailsValid) => {
                if (areAllEmailsValid) {
                    formErrors.delete('email');
                    setFormErrors(new Map(formErrors));
                }
            });
            return newEmails;
        });
    };


    return (
        <ModalLayout onClose={onClose} open={open} width="min(90%,1030px)">
            <Grid container mb={2} px={3} py={3}>
                <Grid item xs={12}>
                    <div className="mb-4">
                        <h3>Invitation type</h3>
                        <Radio
                            name={"invitation_type"}
                            value={invitationType}
                            onChange={(event) => {
                                setInvitationType(event?.target?.value)
                            }}
                            options={invitationTypes}
                        />
                    </div>
                </Grid>


                {
                    invitationType === 'by-email' ?
                        <>
                            <h3>Search for Users on PreprLabs Network</h3>
                            <Grid item xs={12}>
                                <NetworkUsersSelect users={networkUsers} setUsers={setNetworkUsers}/>
                            </Grid>
                            <h3 className="mt-2">Invite via Email</h3>
                            <Grid item xs={12}>
                                <AutoComplete
                                    prefix={Mail}
                                    keyValueOptions={emails}
                                    selectedOptions={emails}
                                    onSelectOption={handleSelectEmail}
                                    noOptions
                                    freeText
                                    onRemoveChip={handleRemoveEmail}
                                    error={formErrors.get('email')}
                                />
                            </Grid>
                            <h3 className="mt-4">Invite via CSV</h3>
                            <Grid item xs={12} className="mt-1">
                                <DropBox file={csv} setFile={setCsv} width="100%" error={formErrors.get('csv')}/>
                            </Grid>
                            {formErrors.get('csv') &&
                                <p className="fs-13 c-error mt-1 fw-500">{formErrors.get('csv')}</p>}
                            <Grid item xs={12} md={10} mt={4}>
                                <h3>User Permission</h3>
                                <Select
                                    borderedIcon
                                    value={data.role}
                                    width={348}
                                    height={49}
                                    values={roles}
                                    name="role"
                                    onChange={onChange}
                                    error={errors.get("role")}
                                    disabled={!!disablePermissions}
                                />
                                {type === "project" && (
                                    <div className="flex items-center justify-start">
                                        <Checkbox
                                            name="currently_recruitng"
                                            checked={data?.currently_recruitng}
                                            onChange={(val) => onChange(createEvent("currently_recruitng", val))}
                                            value={data?.currently_recruitng}
                                        /> Currently Recruiting
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <h3 className="mt-2">Invitation Message (Optional)</h3>
                                <Input
                                    title="Subject"
                                    name="subject_line"
                                    onChange={onChange}
                                    classes="w-full mt-2"
                                    width="99%"
                                    value={data?.subject_line}
                                />
                                <TextBox
                                    title="Description"
                                    name="email_body"
                                    onChange={onChange}
                                    classes="w-full mt-2"
                                    width="99%"
                                    value={data?.email_body}
                                    height={112}
                                />
                            </Grid>
                            <Grid item xs={12} mt={5}>
                                <div className="flex justify-between">
                                    <Button bordered={"none"} onClick={onClose}>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleInvite} disabled={!!formErrors?.size}>Send
                                        Invitation</Button>
                                </div>
                            </Grid>
                        </> : ""
                }


                <HrIntegrations show={invitationType === "from-hr-integration"}/>
            </Grid>
        </ModalLayout>
    );
};

export default AddMemberModal;
