import { Archive, Delete, Unarchive } from "@mui/icons-material";
import { Icon, IconButton, Stack } from "@mui/material";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { StyledBadge } from "components/UI/atoms/OnlineAvatar";
import NetworkUsersSelect from "components/UI/molecules/NetworkUsersSelect";
import React, { useState } from "react";

const ChatHeader = ({
  isNew,
  title,
  users,
  setUsers,
  onDelete = Function.prototype,
  onArchive = Function.prototype,
  isArchive,
  isTab,
  isOnline,
  isGroup,
  t = (d) => d,
}) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  return isNew ? (
    <NetworkUsersSelect
      data={users}
      setUsers={setUsers}
      users={users}
      dataLabelAttr="username"
      prefixText={`${t("To")}:`}
      hideSearchIcon
      classes={isTab && "ml-6"}
      dontSelectMe
    />
  ) : (
    <>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        heading={t(`You will remove this chat from the list, are you sure?`)}
        okayButtonTitle={t("Yes, delete it")}
        onOkay={onDelete}
      />

      <div className="w-full text-center fs-80 fw-500 py-2 border-b flex items-center justify-center relative">
        <p className="text-truncate w-185--mob">
          {title || "Inbox"}
          {isOnline && !isGroup && (
            <StyledBadge
              className="ml-1 mb-2"
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              variant="dot"
            />
          )}
        </p>
        <Stack direction="row-reverse" className="absolute right-0" mr={1}>
          <IconButton
            className="w-35 h-35"
            onClick={() => setOpenConfirmationModal(true)}
          >
            <Delete color="error" />
          </IconButton>
          <IconButton className="w-35 h-35" onClick={onArchive}>
            <Icon
              component={isArchive ? Unarchive : Archive}
              color="disabled"
            />
          </IconButton>
        </Stack>
      </div>
    </>
  );
};

export default ChatHeader;
