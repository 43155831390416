import useForms from "../../../helpers/hooks/useForms";
import {schema} from "./data";
import PageTitle from "../../../components/UI/atoms/PageTitle";
import React, {useContext} from "react";
import {Grid} from "@mui/material";
import SchemaUIExtractor from "../../../components/UI/organisms/SchemaUIExtractor";
import Button from "../../../components/Button";
import {UnifiedImportMemberContext} from "../container/UnifiedImportMemberContext";
import {useNavigate, useParams} from "react-router-dom";
import useUnified from "../../../store/unified/service-hook";

const ImportMessageForm = ({show, setStep}) => {

    /**
     * HOOKS
     */
    const {data, errors, validate, onChange} = useForms(
        schema,
        {
            subject_line: "Invitation to organization.",
            email_body: "You have been invited to join our organization.",
        },
    );
    const {
        selectedEmployees,
        enableGlobalRole,
        globalRole,
        isOrganizationInvite,
        searchParams
    } = useContext(UnifiedImportMemberContext)
    const navigate = useNavigate()
    const {id, type} = useParams()
    const {inviteMembers, invitingMembers} = useUnified()


    const handleInviteMembers = async () => {
        const valid = await validate();
        if (valid.size === 0) {
            const formattedMembers = selectedEmployees.map((item) => {
                return {
                    name: item?.name,
                    email: item?.email,
                    ...(isOrganizationInvite ? {
                        role: enableGlobalRole ? globalRole : item?.role
                    } : {})
                }
            })

            const res = await inviteMembers({
                members: formattedMembers,
                ...data
            }, {state: searchParams.get('state')})

            if (!res.error) {
                navigate(`/member-management/${type}/${id}`)
            }
        }
    }

    return (
        <>
            {show ? <>
                <Grid container px={{xs: 0, md: 4}}>
                    <PageTitle>{"Customize Invitation Email(Optional)"}</PageTitle>
                    <Grid item xs={12} className="email-form-unified">
                        <SchemaUIExtractor
                            schema={schema}
                            data={data}
                            errors={errors}
                            onChange={onChange}
                        />
                    </Grid>
                    <div className="mt-8 w-full">
                        <div className="flex">
                            <div>
                                <Button bordered background={'white'} color={'green'} onClick={() => {
                                    setStep(0)
                                }}>
                                    Back
                                </Button>
                            </div>
                            <div className="ml-auto">
                                <Button color={'green'}
                                        loading={invitingMembers}
                                        onClick={handleInviteMembers}>
                                    Invite members
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>


            </> : ""}
        </>
    )
}


export default ImportMessageForm
