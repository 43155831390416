const ACTIONS = {
  SOCIAL_LINKS: "socialLinks",
  LAB_CONDITIONS: "labConditions",
  PITCH_TEMPLATE: "pitchTemplate",
  FLEXIBLE_DATE_DURATION: "flexibleDateDuration",
  HOST: "host",
  ACHEIVEMENT_CONDITIONS: "achievementConditions",
  PROJECT_SUBMISSION: "projectSubmission",
  CHALLENGE_PITCH_TASK: "challengePitchTask",
  SKILL_GROUPS: "skillGroups",
  RANKS: "ranks",
  SKILL_SETS: "skillSets",
  CATEGORIES: "categories",
  SKILLS: "skills",
  TAGS: "tags",
  INDUSTRIES: "industries",
  TYPES: "types",
  STAGES: "stages",
  VERTICALS: "verticals",
  PROJECT_STATUS: "projectStatus",
  SOCIAL_CONNECT: "socialConnent",
  DURATION: "duration",
  LEVEL: "level",
  TAG_GROUPS: "tag_groups",
  ANNOUNCEMENT_RECIPIENT: "challengeAnnouncementRecipient",
  JOB_TITLES: "job_titles",
  BUSINESS_CHALLENGE_TACKLINGS: "business_challenge_tacklings",
};

export default ACTIONS;
