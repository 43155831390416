import { CreditCard, Help, ShoppingCart } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import Button from "components/Button";
import Divider from "components/Divider";
import { InfoRow, PlanUsagePopUp } from "components/UI/molecules/PlanDetails";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { COLORS } from "helpers/utils/constants";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useLabDashboard from "store/dashboard/lab";
import useOrgDashboard from "store/dashboard/org";

const PlanDetailCard = ({ type }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const useDashboard = type === "lab" ? useLabDashboard : useOrgDashboard;
  const { getSubscriptionDetails } = useDashboard();
  const [openPlanUsage, setOpenPlanUsage] = useState(false);
  const { t = (d) => d } = useLocalizedTranslation();

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  const fetchPlanDetails = async () => {
    setLoading(true);
    const res = await getSubscriptionDetails();
    setData(res);
    setLoading(false);
  };

  const handleFetchPlanDetails = () => {
    fetchPlanDetails();
    setOpenPlanUsage(false);
  };
  const cancelPlanUsageModal = () => setOpenPlanUsage(false);

  return (
    <div className="px-4">
      <div className="flex justify-between">
        <h3 className="flex-center">
          {t("Subscriptions")} <Help className="c-primary ml-2" />
        </h3>
        <Button
          width="160px"
          height="32px"
          onClick={() => setOpenPlanUsage(true)}
        >
          {t("View Plan Details")}
        </Button>
      </div>

      <div className="flex-center mt-3">
        <ShoppingCart
          sx={{ color: COLORS.ORANGE, width: "30px", height: "30px" }}
        />

        <h4 className="ml-2">{t("Plan Overview")}</h4>
      </div>
      {loading ? (
        <PlanDetailCardSkeleton />
      ) : (
        [
          {
            label: "Plan Name :",
            value: data?.plan_name,
          },
          {
            label: "Plan Status :",
            value: `${t("Valid until")} ${moment(data?.plan_end_date).format(
              "MMM DD, YYYY"
            )}`,
          },
        ].map(({ label, value }, i) => (
          <div
            className="flex-center fs-14 fs-12--mob justify-between mt-2"
            key={i}
          >
            <p className="text-nowrap tracking-tight">{t(label)}</p>
            <p className="text-nowrap c-gray text-truncate tracking-tight maxw-200 text-right">
              {value}
            </p>
          </div>
        ))
      )}
      <Divider classes="w-full my-2" />
      <div className="flex-center mt-3">
        <CreditCard
          sx={{ color: COLORS.BLUE, width: "35px", height: "35px" }}
        />
        <h4 className="ml-2">{t("Plan Limit")}</h4>
      </div>
      {loading ? (
        <PlanDetailCardSkeleton />
      ) : (
        [
          { title: "Challenge", key: "challenge" },
          { title: "Lab", key: "lab" },
          { title: "Pre-built Lab", key: "pre_build_lab" },
          { title: "Resource Module", key: "resource_module" },
          { title: "User", key: "user_invite" },
          { title: "Manager", key: "manager" },
        ].map(({ title, key }) => (
          <div key={key} className="fs-13 mt-1">
            <InfoRow value={key} title={t(title)} details={data} />
          </div>
        ))
      )}
      <PlanUsagePopUp
        details={data}
        open={openPlanUsage}
        onClose={cancelPlanUsageModal}
        fetchPlanDetails={handleFetchPlanDetails}
      />
    </div>
  );
};

export default PlanDetailCard;

const PlanDetailCardSkeleton = () => (
  <div className="px-4 w-full my-5">
    {Array(5)
      .fill(1)
      .map((_, i) => (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={4}
          key={i}
          className="mt-3 rounded-10"
        />
      ))}
  </div>
);
