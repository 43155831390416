import { schema } from "./data";

export const removeLiveEventValidation = (errors, data) =>  {
  if(!data?.is_live_event_enabled){
    errors.delete('live_event_url')
  }
  return errors
}

export const removeHiddenFieldValidationError = (errors, data) => {
  const campusConnectStoryFieldName = schema
    .filter((item) => item.name.includes("_story"))
    .map((item) => item.name);

  const campusConnectJobFieldName = schema
    .filter((item) => {
      return (
        item.name.includes("campus_connect") &&
        !campusConnectStoryFieldName.includes(item.name)
      );
    })
    .map((item) => item.name);

  if (!data?.campus_connect) {
    const campusConnect = schema
      .filter((item) => item.name.includes("campus_connect"))
      .map((item) => item.name);
    campusConnect.forEach((item) => {
      errors.delete(item);
    });
  }

  if (data?.integrate_campus_connect === "job") {
    campusConnectStoryFieldName.forEach((item) => {
      if (item !== "campus_connect_schools") {
        errors.delete(item);
      }
    });
  }

  if (data?.integrate_campus_connect === "story") {
    campusConnectJobFieldName.forEach((item) => {
      if (item !== "campus_connect_schools") {
        errors.delete(item);
      }
    });
  }

  if (
    ["both", "job"].includes(data?.integrate_campus_connect) &&
    [undefined, "Via Employer Website"].includes(
      data?.campus_connect_preferred_response
    )
  ) {
    errors.delete("campus_connect_application_email");
  }

  if (
    ["both", "story"].includes(data?.integrate_campus_connect) &&
    data?.campus_connect_story_media_type === "VIDEO"
  ) {
    errors.delete("campus_connect_story_image");
    errors.delete("campus_connect_story_image_title");
    errors.delete("campus_connect_story_image_description");
  }

  if (
    ["both", "story"].includes(data?.integrate_campus_connect) &&
    data?.campus_connect_story_media_type === "IMAGE"
  ) {
    errors.delete("campus_connect_story_video_youtube_url");
  }

  return errors;
};

export const populateCampusConnectInitialValue = (jobs, story, status) => {
  const campusConnectStatus = status !== "no" ? true : false;
  const salaryPaymentFrequencyMapping = ["zero", "one", "two", "three"];
  const school = jobs?.schools ?? story?.requirements?.schools;
  return {
    integrate_campus_connect: status === "no" ? "job" : status,
    campus_connect: campusConnectStatus,
    campus_connect_job_title: jobs?.position,
    campus_connect_job_type: jobs?.type,
    campus_connect_no_of_position: jobs?.no_of_position,
    campus_connect_hours_per_week: jobs?.hours_per_week,
    campus_connect_salary_amount: jobs?.salary_amount,
    campus_connect_salary_payment_frequency:
      salaryPaymentFrequencyMapping[jobs?.salary_payment_frequency],
    campus_connect_province: jobs?.province,
    campus_connect_city: jobs?.city,
    campus_connect_description: jobs?.description,
    campus_connect_education_level: jobs?.education?.level,
    campus_connect_education_study_years: jobs?.education?.study_years,
    campus_connect_education_subject_areas: jobs?.education?.subject_areas,
    campus_connect_qualification: jobs?.qualifications,
    campus_connect_deadline: jobs?.deadline,
    campus_connect_preferred_response: jobs?.preferred_response,
    campus_connect_schools: school?.map((item) => ({
      key: item,
      value: item,
    })),
    campus_connect_application_email:
      jobs?.application_email !== "null" ? jobs?.application_email : "",
    campus_connect_application_instructions: jobs?.application_instructions,
    campus_connect_story_title: story?.title,
    campus_connect_story_body: story?.body,
    campus_connect_story_media_type: story?.media_type ?? "IMAGE",
    campus_connect_story_image_title: story?.image?.title ?? '',
    campus_connect_story_image_description: story?.image?.description ?? '',
    campus_connect_story_image: story?.image?.file_url,
    campus_connect_story_video_youtube_url:
      story?.video?.youtube_url !== "null" ? story?.video?.youtube_url : "",
  };
};
