import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { serializeObject } from "store/api/utils";

const useUserDashboard = () => {
  const { language, token, user } = useSelector(
    (state) => state[REDUCER_TYPES.AUTH]
  );

  const headers = {
    Authorization: "Bearer " + token,
  };

  const myLatestAcievement = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.MY_LATEST_ACHIEVEMENT}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const recommendations = async (type = "challenges") => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.MY_RECOMMENDATIONS}?language=${language}&type=${type}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const myResources = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${
        ENDPOINTS.MY_RESOURCE_MODULES
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const myLabs = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${
        ENDPOINTS.MY_LABS
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const myChallenges = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${
        ENDPOINTS.MY_CHALLENGES
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const myProjects = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${
        ENDPOINTS.MY_PROJECTS
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const progress = async (type = "challenges") => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.MY_PROGRESS}?language=${language}&type=${type}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const upcomingChallengeDeadlines = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.UPCOMING_CHALLENGE_DEADLINES}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const inboxFriendRequests = async (type = "inbox") => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.INBOX_FRIEND_REQUEST}?language=${language}&type=${type}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const leftOff = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.LAST_VISITED_MODULE}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const layout = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${ENDPOINTS.FETCH_LAYOUT}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  const updateLayout = async (params = {}) => {
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.USER}/${
        ENDPOINTS.UPDATE_LAYOUT
      }?language=${language}&${serializeObject(params)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  return {
    myLatestAcievement,
    recommendations,
    myChallenges,
    myLabs,
    myResources,
    myProjects,
    progress,
    inboxFriendRequests,
    layout,
    leftOff,
    updateLayout,
    upcomingChallengeDeadlines,
  };
};

export default useUserDashboard;
