import { BookmarkBorder } from "@mui/icons-material";
import { Tab, Tabs } from "@mui/material";
import TableComponent from "components/Table";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useRedirect from "helpers/hooks/useRedirect";
import { extractChallengeDate } from "helpers/utils/utilities";
import React, { useEffect, useState } from "react";
import useUserDashboard from "store/dashboard/user";

const TABS = ["Challenges", "Labs", "Resources"];

const Recommendations = ({ classes }) => {
  const [tab, setTab] = useState(0);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { recommendations } = useUserDashboard();
  const { navigateChallenge, navigateLab, navigateResource } = useRedirect();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    fetchRecommendations();
  }, [tab]);

  const fetchRecommendations = async () => {
    setLoading(true);
    const res = await recommendations(
      tab === 2 ? "resource_modules" : TABS[tab].toLowerCase()
    );
    setList(res || []);
    setLoading(false);
  };
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const getDashboardJSXCols = () => [
    {
      title: t("Title"),
      width: "80%",
      render: (rowData) => {
        return (
          <div className="flex-center">
            <BookmarkBorder className="c-gray" />
            <p
              className="maxw-250 fs-14 fw-500 fs-12--mob ml-1 text-truncate tracking-tight link"
              onClick={
                tab === 0
                  ? () => navigateChallenge(rowData?.slug)
                  : tab === 1
                  ? () => navigateLab(rowData?.slug)
                  : () => navigateResource(rowData?.slug)
              }
            >
              {rowData?.title}
            </p>
          </div>
        );
      },
    },
    {
      title: t(tab === 0 ? "Deadline" : tab === 1 ? "Members" : "Saved By"),
      width: "25%",
      render: (rowData) => {
        return (
          <p className="flex c-lightgray fs-13 ml-2">
            {tab === 0
              ? extractChallengeDate(rowData)
              : tab === 1
              ? rowData?.member_count
              : rowData?.likes}
          </p>
        );
      },
    },
  ];

  return (
    <div className={classes}>
      <div className="flex items-center justify-between ">
        <h3 className="text-nowrap fs-16">{t("Recommendations")}</h3>
        <Tabs
          aria-label="dashboard tabs"
          value={tab}
          onChange={handleChange}
          className="minh-30 ml-1"
        >
          {TABS &&
            TABS.map((tab, i) => (
              <Tab
                key={i}
                value={i}
                label={t(tab)}
                sx={{ textTransform: "capitalize" }}
                className="fs-14 tracking-tight minh-30 fw-500 fs-12--mob py-0 px-0"
              />
            ))}
        </Tabs>
      </div>
      <TableComponent
        rows={list}
        paginator={false}
        columns={getDashboardJSXCols()}
        minWidth="400"
        rowsPerPage={5}
        tableClasses="mt-1"
        titleCellClasses="px-1 fw-400 tracking-tight"
        dense
        rowClasses="h-55 minh-55"
        rowCellClasses="px-0 tracking-tight "
        loading={loading}
        loadingContainerClasses="h-270"
        emptyView={
          <div className="flex-column items-center">
            <p className="c-gray">{t("No Recommendations found.")}</p>
          </div>
        }
        emptyViewClasses="h-260"
        hideLastRowBorder
      />
    </div>
  );
};

export default Recommendations;
