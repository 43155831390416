import Card from "components/Cards/Card";
import ImageCard from "components/Cards/ImageCard";
import DashboardLayout from "pages/layouts/dashboard";
import MainPageLayout from "pages/layouts/MainPage";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useLabPrograms from "store/lab-programs/service-hook";
import Button from "components/Button";
import {
  DeleteForever,
  Edit,
  ManageAccounts,
  Settings,
  Done,
} from "@mui/icons-material";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import PublicDescription from "../../components/UI/organisms/PublicDescription";
import useRedirect from "helpers/hooks/useRedirect";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { isTrue } from "helpers/utils/validators";
import AssociateLabListing from "pages/associateListings/AssociateLabListing";
import { ENDPOINTS } from "store/api/endpoints";
import RelatedContent from "pages/associateListings/RelatedContent";
import useAuth from "store/auth/service-hook";
import useLabs from "store/labs/service-hook";
import MenuButton from "components/Button/MenuButton";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import LikeShareSaveBtns from "components/UI/atoms/LikeShareSaveBtns";
import ShareModal from "components/UI/organisms/ShareModal";

const LabProgramPublicView = () => {
  const [labP, setLabP] = useState(null);
  const { id } = useParams();
  const { fetchPublicLP, fetchLP, deleteLP } = useLabPrograms();
  const [params] = useSearchParams();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const isPrivateView = params.get("private") === "true";
  const {
    navigateEditLP,
    navigateMemberManagement,
    navigateManageLabPrograms,
    navigateLogin,
    navigateLabP,
  } = useRedirect();
  const { t } = useLocalizedTranslation();
  const { join, unJoin, favorite, unFavorite } = useLabs();
  const {
    state: { isLoggedIn },
  } = useAuth();
  const isRequestJoinVisible = isTrue(labP?.privacy) && !isTrue(labP?.is_joined);

  useEffect(() => {
    if (!isLoggedIn) {
      if (params.has("private")) {
        navigateLabP(id);
      }
    }
    handleFetchDetail();
  }, [id, isPrivateView]);

  const handleFetchDetail = async () => {
    const res = isPrivateView ? await fetchLP(id) : await fetchPublicLP(id);
    setLoading(false);
    if (!res) {
      setLabP({ error: true, statusCode: res?.statusCode });
      return;
    }
    setLabP(res);
  };

  const doUndoAction = async (property, doAction, undoAction) => {
    if (!labP) return;
    const did = !!labP[property] && labP[property]?.toLowerCase() !== "no";
    if (did) {
      await undoAction(labP?.slug, true);
      setIsPending(false);
    } else {
      await doAction(labP?.slug, true);
      if (property === "is_joined" && isTrue(labP?.privacy)) {
        setIsPending(true);
      } 
    }
    labP[property] = did ? "no" : "yes";
    setLabP({ ...labP });
  };

  const handleJoinUnjoin = () => doUndoAction("is_joined", join, unJoin);
  const handleFavoriteUnFav = () =>
    doUndoAction("favourite", favorite, unFavorite);

  const handleDeleteLabP = async (slug) => {
    setLoading(true);
    await deleteLP(slug);
    navigateManageLabPrograms();
    setLoading(false);
  };

  const onDeleteLabP = () => {
    handleDeleteLabP(openConfirmationModal);
    setOpenConfirmationModal(false);
  };

  return (
    <DashboardLayout
      loading={!labP}
      isEmpty={!!labP?.error}
      emptyMessage={t("Not able to access the lab program!")}
      show404OnError={labP?.statusCode === 404}
    >
      <div className="flex-reverse container mt-header">
        {isPrivateView && (
          <MenuButton
            listWithTitleonClick={[
              {
                title: "Manage Members",
                onClick: () =>
                  navigateMemberManagement(
                    COMPONENT_TYPES.LAB_PROGRAMS,
                    labP?.slug
                  ),
                icon: ManageAccounts,
                iconClasses: "c-primary",
              },
              {
                divider: true,
              },
              {
                title: "Edit",
                onClick: () => navigateEditLP(labP?.slug),
                icon: Edit,
              },
              {
                title: "Delete",
                onClick: () => setOpenConfirmationModal(labP?.slug),
                icon: DeleteForever,
                iconClasses: "c-error",
                classes: "c-error",
              },
            ]}
            classes="fs-1 fs-13"
            height="30px"
            bordered
          >
            <Settings fontSize="small" /> {t("Manage", "common")}
          </MenuButton>
        )}
      </div>
      <MainPageLayout classes=" my-4" firstLgGridSize={4} secondLgGridSize={8}>
        <div className="left">
          <ImageCard
            image={labP?.media}
            height="250px"
            type={COMPONENT_TYPES.LAB_PROGRAMS}
            isEmbedded={labP?.media_type === "embedded"}
            title={labP?.title}
          />

          {!isPrivateView && (
            <Button
              onClick={!isLoggedIn ? navigateLogin : handleJoinUnjoin}
              bordered={
                labP?.is_joined?.toLowerCase() === "yes" ||
                labP?.is_joined === "Pending" ||
                isPending
              }
              color={
                labP?.is_joined === "Pending" || isPending
                  ? "green"
                  : (isTrue(labP?.privacy) && labP?.is_joined?.toLowerCase() === "no" && isLoggedIn) ||
                    isRequestJoinVisible
                  ? "blue"
                  : isTrue(labP?.is_joined)
                  ? "red"
                  : "green"
              }
              disabled={labP?.is_joined === "Pending" || isPending}
              classes="mt-2 w-full"
            >
              {labP?.is_joined === "Pending" || isPending ? (
                <>
                  <Done fontSize="small" className="mr-1" /> {t("Request Sent")}
                </>
              ) : isTrue(labP?.is_joined) ? (
                t("Unjoin Lab Program")
              ) : (isTrue(labP?.privacy) && labP?.is_joined?.toLowerCase() === "no" && isLoggedIn) ||
                isRequestJoinVisible ? (
                t("Request to Join")
              ) : (
                t("Join Lab Program")
              )}
            </Button>
          )}

          <PublicDescription
            data={labP}
            component={COMPONENT_TYPES.LAB_PROGRAMS}
            hideRequirements
            hideSocialLinks
            hideHostedBy
            hideProgress={!isTrue(labP?.is_joined)}
            isPrivate={isPrivateView}
          >
            <LikeShareSaveBtns
              isSaved={isTrue(labP?.favourite)}
              onSave={handleFavoriteUnFav}
              onShare={() => setShareOpen(true)}
              hideLike
            />
          </PublicDescription>
        </div>
        <div className="right">
          {isLoggedIn && (
            <Card classes="py-3 pl-3">
              <h3>{t("Labs", "common")}</h3>
            </Card>
          )}
          <AssociateLabListing
            parent={ENDPOINTS.LAB_PROGRAM}
            slug={labP?.slug}
            isPrivateView={isPrivateView}
            emptyMessageNotLoggedIn="Login to view more details"
            isLP
          />
          <RelatedContent
            component={ENDPOINTS.LAB_PROGRAM}
            slug={labP?.slug}
            classes="mt-2"
            title={t("Related Lab Programs")}
          />
        </div>
      </MainPageLayout>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        desc={t("You will delete this lab program permanently, are you sure?")}
        okayButtonTitle="Delete"
        onOkay={onDeleteLabP}
      />
      <ShareModal
        open={shareOpen}
        onClose={() => setShareOpen(false)}
        component={COMPONENT_TYPES.LAB_PROGRAMS}
        slug={labP?.slug}
      />
    </DashboardLayout>
  );
};

export default LabProgramPublicView;
