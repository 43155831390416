import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Loader} from "../../../../components/UI/molecules/LazyLoader";
import {Grid} from "@mui/material";
import useResources from "../../../../store/resources/service-hook";

const PlayCourse = () => {
    /**
     * HOOKS
     */
    const {id} = useParams()
    const {fetchPlayerUrl} = useResources()
    /**
     * STATE
     */
    const [loadingPlayerUrl, setLoadingPlayerUrl] = useState(false)
    const [playerUrl, setPlayerUrl] = useState(null)

    /**
     * METHODS
     * @returns {Promise<void>}
     */
    const fetchScormPlayerUrl = async () => {
        if (id) {
            setLoadingPlayerUrl(true)
            const res = await fetchPlayerUrl(id)
            setPlayerUrl(res?.url)
            setLoadingPlayerUrl(false)
        }
    }

    useEffect(() => {
        fetchScormPlayerUrl()
    }, [])

    return (<>
        {
            loadingPlayerUrl ? <>
                <Loader/>
            </> : ''
        }

        {playerUrl ? <>
            <iframe src={playerUrl ?? ''} frameBorder={0} className={'h-screen w-full'} title={'Scorm-Iframe'}>
            </iframe>
        </> : <Grid className={'h-screen'} container justifyContent={'center'} alignItems={'center'}>
            {"Course not found !"}
        </Grid>}
    </>);
}

export default PlayCourse
