import { useState, useEffect } from "react";
import "components/AI/ai.scss";
import DashboardLayout from "pages/layouts/dashboard";
import EditCreateHeaderCard from "components/Cards/EditCreateHeaderCard";
import MainPageLayout from "pages/layouts/MainPage";
import Card from "components/Cards/Card";
import { toast } from 'react-toastify';
import { aiBackground1 } from "assets/svg/ai";
import { aiLoading } from "assets/gif/ai";
import { challenge as challengeImage } from "assets/svg/green";
import { calendar, chart } from "assets/svg/blue";
import useChallenges from "store/challenges/service-hook";
import useResources from "store/resources/service-hook";
import { scrollToTop } from "helpers/utils/utilities";
import useForms from "helpers/hooks/useForms";
import { schema, stepSchema } from "./data";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Button from "components/Button";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useOrganization from "store/organization/service-hook";
import useRedirect from "helpers/hooks/useRedirect";
import { Chip } from "@mui/material";
import AIForm from "components/AI/AIForm";
import ResourceModulePreview from "components/AI/ResourceModulePreview";
import AIText from "components/AI/AIText";
import { isTrue } from "helpers/utils/validators";

const ChallengeBuilderAI = () => {

    const sliceSchema = (_schema) => stepSchema(step);

    const { data, errors, validate, onChange } = useForms(
        schema,
        {},
        sliceSchema
    );

    const [step, setStep] = useState(0);

    const { aiCreateChallenge, aiCreateChallengesPreview } = useChallenges();
    const { aiCreateResourceModule, aiCreateResourceModulePreviews, createResourceModuleGo1 } = useResources();
    const { organizationId } = useOrganization();
    const { navigateChallenge } = useRedirect();

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [confirmationModalDescription, setConfirmationModalDescription] = useState("Are you sure?");
    const [loading, setLoading] = useState(false);

    const [challenges, setChallenges] = useState([]);
    const [challengesState, setChallengesState] = useState([]);
    const [challengeExpanded, setChallengeExpanded] = useState('challengePanel0');
    const [challengeSelected, setChallengeSelected] = useState([]);
    const [challengeChosen, setChallengeChosen] = useState(null);

    const [resources, setResources] = useState([]);
    const [resourcesState, setResourcesState] = useState([]);
    const [resourceExpanded, setResourceExpanded] = useState('resourcePanel0');
    const [resourcesSelected, setResourcesSelected] = useState([]);
    const [resourcesChosen, setResourcesChosen] = useState(null);

    // Initial Page Steps  
    const [createChallengeAISteps, setCreateChallengeAISteps] = useState([
        "Challenge & Resource Module Creation",
        "Choose your Challenge",
    ]);

    // Validating the forms
    const stepValidate = (errors) => {
        let _errors = errors;

        if (step === 0 && data?.resource_modules) {
            let current_schema = stepSchema(step);
            let sliced_schema = [];
            let itemsToRemove = null;
            let itemsToAdd = null;

            if (data?.resource_modules === "no") {
                sliced_schema = [...sliced_schema, ...current_schema.slice(5, 11)];
            } else if (isTrue(data?.resource_modules)) {
                sliced_schema = [...sliced_schema, ...current_schema.slice(7, 8)];
                sliced_schema = [...sliced_schema, ...current_schema.slice(9, 10)];
            }

            if (data?.resource_module_prepr || data?.resource_module_openai || data?.resource_module_go1) {
                itemsToAdd = [5, 6, 8];

                itemsToAdd.forEach(index => {
                    const itemToAdd = current_schema[index];
                    if (!sliced_schema.includes(itemToAdd)) {
                        sliced_schema.push(itemToAdd);
                    }
                });
            }

            if (data?.resource_module_openai) {
                itemsToRemove = current_schema[7];
                sliced_schema = sliced_schema.filter(item => item !== itemsToRemove);
            }

            if (data?.resource_module_go1) {
                itemsToRemove = current_schema[9];
                sliced_schema = sliced_schema.filter(item => item !== itemsToRemove);
            }

            sliced_schema.length > 0 && sliced_schema.forEach(({ name }) => {
                _errors.delete(name);
            });
        }

        return _errors;
    };

    // Handle continuing in steps
    const onContinue = async () => {
        switch (step) {
            case 0:
                let error = stepValidate(await validate());
                if (error.size) return;
                createChallengesPreviews();
                break;
            case 1:
                if (challengeChosen === challengesState[challengeSelected]) {
                    createResourceModulesPreviews();
                } else {
                    setChallengeChosen(challengesState[challengeSelected]);
                }
                break;
            case 2:
                if (resourcesSelected?.length > 0) {
                    setResourcesChosen(resourcesSelected.map(index => resources[index]));
                } else {
                    setConfirmationModalDescription("You haven't chosen any Resource Module! Are you sure?");
                    setOpenConfirmationModal(true);
                }
                break;
            default:
                // eslint-disable-next-line no-console
                console.error('Error in Steps!');
                break;
        }
    };

    // Handle backing in steps
    const onCancel = () => {
        if (step === 1) {
            setConfirmationModalDescription("You will lose all the current Generated Challenges! Are you sure?");
            setOpenConfirmationModal(true);
        }
        if (step === 2) {
            setConfirmationModalDescription("You will lose all the current Generated Resource Modules! Are you sure?");
            setOpenConfirmationModal(true);
        }
    };

    // Handle confirming confirmation modal
    const confirmConfirmationModal = () => {
        if (confirmationModalDescription === "You will lose all the current Generated Challenges! Are you sure?") {
            if (step === 1) {
                setChallenges([]);
                setChallengesState([]);
                setChallengeSelected([]);
                setChallengeExpanded('challengePanel0');
                setChallengeChosen(null);
                setStep(step - 1);
            }
        } else if (step === 2) {
            if (confirmationModalDescription === "You will lose all the current Generated Resource Modules! Are you sure?") {
                setResources([]);
                setResourcesState([]);
                setResourcesSelected([]);
                setResourceExpanded('resourcePanel0');
                setResourcesChosen(null);
                setStep(step - 1);
            } else {
                handleFinalCreate();
            }
        }
        setOpenConfirmationModal(false);
    };

    // Generates challenges' previews
    const createChallengesPreviews = async () => {
        setLoading(true);
        let resource_modules = false;

        try {
            if (data?.resource_modules === "yes") {
                resource_modules = true
            }

            const payload = {
                ...data,
                resource_modules: resource_modules,
                is_ai_created: true,
                organization_id: organizationId,
            };

            const res = await aiCreateChallengesPreview(payload);
            if (res?.data) {
                setChallenges(Object.values(res?.data?.data));
                setStep(step + 1);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Error in challenge generating API call: ", e);

        } finally {
            scrollToTop();
            setLoading(false);
        }
    };

    // Generates the Resource Module Previews
    const createResourceModulesPreviews = async () => {
        if (challengeChosen) {
            if (data?.resource_module_prepr || data?.resource_module_openai || data?.resource_module_go1) {
                setLoading(true);

                try {
                    const { challengeTitle, challengeDescription, ...restOfChallenge } = challengeChosen;

                    const payload = {
                        ...restOfChallenge,
                        challengeTitle: challengeTitle,
                        challengeDescription: challengeDescription,
                        organization_id: organizationId,
                    };

                    const res = await aiCreateResourceModulePreviews(payload);

                    if (res?.data?.data) {
                        setResources(Object.values(res?.data?.data));
                        setStep(step + 1);
                    } else {
                        setResources([]);
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error("Error in resource module generating API call: ", e);
                } finally {
                    scrollToTop();
                    setLoading(false);
                }
            } else {
                handleFinalCreate();
            }
        }
    };

    useEffect(() => {
        if (challengeChosen && step === 1) {
            createResourceModulesPreviews();
        }
    }, [challengeChosen]);

    useEffect(() => {
        setChallengesState(challenges?.map(challenge => ({
            ...challenge,
        })));
    }, [challenges]);

    useEffect(() => {
        if (resourcesChosen && step === 2) {
            handleFinalCreate();
        }
    }, [resourcesChosen]);

    useEffect(() => {
        if (resources?.length > 0) {
            if (resources?.resource_modules?.length > 0 && step === 1) {
                setStep(step + 1);
                scrollToTop();
                setLoading(false);
            }

            setResourcesState(resources?.map(resource => ({
                ...resource
            })));
        }
    }, [resources]);

    const handleChallengePanelChange = (panel) => (event, isExpanded) => {
        setChallengeExpanded(isExpanded ? panel : false);
    };

    const handleResourcePanelChange = (panel) => (event, isExpanded) => {
        setResourceExpanded(isExpanded ? panel : false);
    };

    const handleFinalCreate = async () => {
        setLoading(true);
        let createdChallenge = null;
        let resourceModuleUUIDs = [];

        try {
            if (challengeChosen) {
                try {
                    if (resourcesChosen) {
                        await Promise.all(
                            resourcesChosen.map(async (resourceChosen) => {
                                try {
                                    if (resourceChosen?.from_prepr) {
                                        resourceModuleUUIDs.push(resourceChosen.uuid);
                                    } else if (resourceChosen?.from_go1) {
                                        const createdResourceModuleResponse = await createResourceModuleGo1({ go1_course: resourceChosen });
                                        const createdResourceModuleResponseId = createdResourceModuleResponse.id;
                                        resourceModuleUUIDs.push(createdResourceModuleResponseId);
                                    } else {
                                        const resourcesPayload = {
                                            ...resourceChosen,
                                            organization_id: organizationId,
                                        };

                                        const createdResourceModuleResponse = await aiCreateResourceModule(resourcesPayload);
                                        const createdResourceModuleResponseId = createdResourceModuleResponse.id;
                                        resourceModuleUUIDs.push(createdResourceModuleResponseId);
                                    }
                                } catch (error) {
                                    // eslint-disable-next-line no-console
                                    console.error("Failed to create resource module: ", error);
                                }
                            })
                        );
                    }

                    const challengePayload = {
                        ...challengeChosen,
                        organization_id: organizationId,
                        is_ai_created: true,
                        resource_modules: resourceModuleUUIDs,
                    };

                    createdChallenge = await aiCreateChallenge(challengePayload);
                    createdChallenge = createdChallenge?.data?.data;


                    if (createdChallenge && (!resourcesChosen || resourceModuleUUIDs.length === resourcesChosen.length)) {
                        navigateChallenge(createdChallenge.slug, true);
                        toast.success("Success");
                    } else {
                        toast.error("Something went wrong!");
                    }
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error("Failed to create challenge: ", error);
                }
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Unexpected error in handleFinalCreate:", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!challengeChosen) {
            setNumChallengesToShow(4);
            setLoadMoreChallengesClickCount(0);
        }
        setNumResourcesToShow(4);
        setLoadMoreResourcesClickCount(0);
    }, [step]);

    const [numResourcesToShow, setNumResourcesToShow] = useState(4);
    const [loadMoreResourcesClickCount, setLoadMoreResourcesClickCount] = useState(0);

    const [numChallengesToShow, setNumChallengesToShow] = useState(4);
    const [loadMoreChallengesClickCount, setLoadMoreChallengesClickCount] = useState(0);

    const handleLoadMoreChallenges = () => {
        setNumChallengesToShow(numChallengesToShow + 2);
        setLoadMoreChallengesClickCount(loadMoreChallengesClickCount + 1);
    };

    return (
        <div className="ai-creation-form ai-scoped">
            <ConfirmationModal
                open={openConfirmationModal}
                onClose={() => { setOpenConfirmationModal(false) }}
                desc={confirmationModalDescription}
                okayButtonTitle="Confirm"
                onOkay={confirmConfirmationModal}
            />
            <DashboardLayout>
                <EditCreateHeaderCard
                    width="min(1600px,90%)"
                    title="Prepr Challenge Builder AI"
                    classes="mt-header flex-row"
                />
                <MainPageLayout classes="mb-6 mt-2 container-medium" firstMdGridSize={12} secondMdGridSize={12} firstLgGridSize={4} secondLgGridSize={8}>
                    <AIForm
                        step={step}
                        loading={loading}
                        data={data}
                        errors={errors}
                        onChange={onChange}
                        stepSchema={stepSchema}
                        onCancel={onCancel}
                        onContinue={onContinue}
                        stepValidate={stepValidate}
                        optionSelected={challengeSelected}
                        createAISteps={createChallengeAISteps}
                        setCreateAISteps={setCreateChallengeAISteps}
                    />
                    <div className={`right`}>
                        <Card centered classes={`column-center`} width="min(1600px,100%)">
                            <EditCreateHeaderCard
                                current={step}
                                length={createChallengeAISteps.length}
                                subtitles={createChallengeAISteps}
                                rounded
                                noShadow
                                noPadding
                            />
                            {step === 0 && (
                                <div className={`intro-box ${loading ? 'flashing' : ''}`}>
                                    <p className="intro-text">{loading ? "Generating your Challenges... (it may take up to 2 minutes)" : "Our Challenge Builder AI can help you generate Challenges with Resource Modules!"}</p>
                                    <img width="100%" src={aiBackground1} alt="" />
                                </div>
                            )}
                            {step === 1 && (
                                loading ?
                                    createChallengeAISteps.length > 2 ? 
                                    <div className='intro-box'>
                                        <p className="intro-text">Generating your Resource Modules... (it may take up to 2 minutes)</p>
                                        <img width="100%" src={aiLoading} alt="" />
                                    </div> :
                                    <div className='intro-box'>
                                        <p className="intro-text">Working on it...</p>
                                        <img width="100%" src={aiLoading} alt="" />
                                    </div> :
                                    <div className="w-full">
                                        {challengesState?.slice(0, numChallengesToShow).map((challenge, index) => (
                                            <Accordion
                                                key={index}
                                                expanded={challengeExpanded === `challengePanel${index}`}
                                                onChange={handleChallengePanelChange(`challengePanel${index}`)}
                                                className="challenge-panel relative"
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`challengePanel${index}bh-content`}
                                                    id={`challengePanel${index}bh-header`}
                                                >
                                                    <Typography className="panel-main">
                                                        <div className="image-and-title">
                                                            <img className="panel-icon" src={challengeImage} alt="" />
                                                            <b className="mx-2">{challenge?.challengeTitle}</b>
                                                        </div>
                                                    </Typography>
                                                </AccordionSummary>
                                                <Button
                                                    onClick={() => setChallengeSelected(index)}
                                                    color={challengeSelected === index ? 'green' : 'blue'}
                                                    bordered
                                                    classes="panel-button sm-panel-button"
                                                    height={35}
                                                >
                                                    {challengeSelected === index ? (<><CheckCircleIcon className="icon-margin" /> Selected</>) : 'Select'}
                                                </Button>
                                                <AccordionDetails>
                                                    <div className="image-and-title">
                                                        <img className="desc-icon" src={chart} alt="" />
                                                        <Typography dangerouslySetInnerHTML={{ __html: challenge?.level }} />
                                                        <img className="desc-icon ml-4" src={calendar} alt="" />
                                                        <Typography dangerouslySetInnerHTML={{ __html: challenge?.duration }} />
                                                    </div>
                                                    <br />
                                                    <AIText className="mb-1">Description</AIText>
                                                    <Typography dangerouslySetInnerHTML={{ __html: challenge?.challengeDescription }} />
                                                    <br />
                                                    <AIText className="mb-1">Skills</AIText>
                                                    {challenge?.skill_titles?.map((skillTitle) => (
                                                        <Chip
                                                            key={skillTitle}
                                                            label={skillTitle}
                                                            className="py-2 mr-1 mb-1 fs-12"
                                                            sx={{ height: "26px" }}
                                                        />
                                                    ))}
                                                    <br />
                                                    <br />
                                                    <AIText className="mb-1">Jobs</AIText>
                                                    {challenge?.job_titles?.map((jobTitle) => (
                                                        <Chip
                                                            key={jobTitle}
                                                            label={jobTitle}
                                                            className="py-2 mr-1 mb-1 fs-12"
                                                            sx={{ height: "26px" }}
                                                        />
                                                    ))}
                                                </AccordionDetails>
                                                <Button
                                                    onClick={() => setChallengeSelected(index)}
                                                    color={challengeSelected === index ? 'green' : 'blue'}
                                                    bordered
                                                    classes="panel-button md-panel-button"
                                                    height={35}
                                                >
                                                    {challengeSelected === index ? (<><CheckCircleIcon className="icon-margin" /> Selected</>) : 'Select'}
                                                </Button>
                                            </Accordion>
                                        ))}
                                        <br />
                                        {challengesState.length > numChallengesToShow && loadMoreChallengesClickCount < 3 && (
                                            <AIText className="text-right">Don't think these are good fit? <span className="no-good-fit" onClick={handleLoadMoreChallenges}>Load More</span></AIText>
                                        )}
                                    </div>
                            )}
                            {step === 2 && (
                                loading ?
                                    <div className='intro-box'>
                                        <p className="intro-text">Working on it...</p>
                                        <img width="100%" src={aiLoading} alt="" />
                                    </div> :
                                    <ResourceModulePreview
                                        resourcesState={resourcesState}
                                        resourceExpanded={resourceExpanded}
                                        handleResourcePanelChange={handleResourcePanelChange}
                                        resourcesSelected={resourcesSelected}
                                        setResourcesSelected={setResourcesSelected}
                                        numResourcesToShow={numResourcesToShow}
                                        setNumResourcesToShow={setNumResourcesToShow}
                                        loadMoreResourcesClickCount={loadMoreResourcesClickCount}
                                        setLoadMoreResourcesClickCount={setLoadMoreResourcesClickCount}
                                        maxSelect={4}
                                    />
                            )}
                        </Card>
                    </div>
                </MainPageLayout>
            </DashboardLayout >
        </div>
    )
}

export default ChallengeBuilderAI;