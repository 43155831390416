import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import CreateFormTitle from "../../../components/UI/atoms/CreateFormTitle";
import Switch from "../../../components/Input/Switch";
import CreateFormSection from "../../../components/UI/atoms/CreateFormSection";
import { toast } from "react-toastify";
import SchemaUIExtractor from "../../../components/UI/organisms/SchemaUIExtractor";
import Button from "../../../components/Button";
import useLabs from "../../../store/labs/service-hook";
import useComputed from "../../../helpers/hooks/useComputed";
import LiveEventCard from "../../../components/Cards/LiveEventCard";

const LiveEvent = ({
  formSchema: schema,
  data,
  errors,
  onChange,
  validate,
  show,
  setLiveEventVerified,
  isLiveEventVerifed,
  editMode,
  t,
}) => {
  const [eventDetails, setEventDetails] = useState(null);
  const [verifyingEvent, setVerifyingEvent] = useState(false);

  const { verifyLiveEvent } = useLabs();

  const isValidEventUrl = (url) => {
    const pattern =
      /^https:\/\/www\.airmeet\.com\/(e|event)\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
    return pattern.test(url);
  };

  const extractEventId = () => {
    const pattern = /\/(?:event|e)\/([a-f0-9-]+)/;
    if (data?.live_event_url) {
      const matches = data?.live_event_url.match(pattern);
      if (matches && matches[1]) {
        return matches[1];
      }
    }
    return null;
  };

  const verify = async () => {
    await validate();
    if (!isValidEventUrl(data?.live_event_url)) {
      toast.error(t("Invalid event URL!"));
      return;
    }
    if (eventId) {
      setVerifyingEvent(true);
      try {
        const response = await verifyLiveEvent({
          event_id: eventId,
        });
        setEventDetails(response);
        setLiveEventVerified(true);
      } catch (_) {
        setEventDetails(null);
        setLiveEventVerified(false);
        toast.error(t("Invalid Event!"));
      } finally {
        setVerifyingEvent(false);
      }
    }
  };

  const eventId = useComputed(() => {
    return extractEventId();
  }, [data?.live_event_url]);

  useEffect(() => {
    if (show && editMode && !isLiveEventVerifed && data[schema[0].name]) {
      verify();
    }
  }, [show]);

  return (
    <>
      {show ? (
        <Grid container px={{ xs: 0, md: 4 }}>
          <CreateFormTitle title="Live Event" />
          <Grid container className="mt-4">
            <CreateFormSection title={"Airmeet Live Event"} classes="mt-4">
              <Grid item lg={12}>
                <Grid container spacing={1} className="mt-2">
                  <Grid item xs={12} lg={10}>
                    <p>
                      {t(
                        "Enable this option and enter the event link. Your Lab participants are able to access the event directly from Lab detail page."
                      )}
                    </p>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Switch
                      labelPlacement={"end"}
                      name={schema[0].name}
                      value={data[schema[0].name]}
                      label={t("Enabled")}
                      error={errors.get(schema[0].name)}
                      onChange={onChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CreateFormSection>
            {data?.is_live_event_enabled ? (
              <Grid item xs={12}>
                <CreateFormSection title={"Link Airmeet Event"} classes="mt-4">
                  <p className={"mt-1"}>
                    {t(
                      "Paste your Airmeet event link below to link your event to this Lab."
                    )}
                  </p>
                  <Grid container spacing={2} alignItems={"end"}>
                    <Grid item xs={6}>
                      <SchemaUIExtractor
                        schema={[schema[1]]}
                        data={data}
                        errors={errors}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        classes={"mb-4"}
                        onClick={verify}
                        loading={verifyingEvent}
                        disabled={!data?.live_event_url}
                        height="35px"
                        width="140px"
                      >
                        {t("Link Event")}
                      </Button>
                    </Grid>
                  </Grid>
                  {eventDetails ? (
                    <Grid container className={"mt-4"}>
                      <Grid item xs={12}>
                        <h3>{t("Linked Event")}</h3>
                        <p className="mt-1">
                          {t(
                            "You can only link one event at a time. Please check the event detail before saving your changes."
                          )}
                        </p>
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <LiveEventCard
                          eventDetails={eventDetails}
                          unlink={() => {
                            setEventDetails(null);
                            setLiveEventVerified(false);
                          }}
                          t={t}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </CreateFormSection>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default LiveEvent;
