import React, { useMemo, useState } from "react";
import useAdvanceSearch from "store/advance-search/service-hook";
import useAdvanceSearchFilter from "../hooks/useAdvanceSearchFilter";
import { AS_REDUCER } from "store/advance-search/reducer";
import { useAdvanceSearchContext } from "../hooks/useAdvanceSearchContext";
import { ADVANCE_SEARCH_COMPONENTS } from "../data";
import ResourceListing from "pages/resources/ResourceModule/listing/ResourceListing";

const AdvanceSearchResourceCollection = () => {
  const { gridType, keyword, getFiltersViaType, filters } =
    useAdvanceSearchContext();
  const { searchResourceCollection } = useAdvanceSearch();
  const [page, setPage] = useState(1);

  const Filters = useMemo(() => {
    return getFiltersViaType(ADVANCE_SEARCH_COMPONENTS.RESOURCE_COLLECTION);
  }, [filters]);

  const { data } = useAdvanceSearchFilter(
    ADVANCE_SEARCH_COMPONENTS.RESOURCE_COLLECTION,
    keyword,
    { page, ...Filters },
    searchResourceCollection,
    AS_REDUCER.setResourceCollection,
    false,
    0
  );

  return (
    <ResourceListing
      browseMode={0}
      resources={data?.data || []}
      gridType={gridType}
      noPagination={!Boolean(data?.meta ?? false)}
      page={data?.meta?.current_page ?? 1}
      setPage={setPage}
      pageCount={data?.meta?.total_pages ?? 1}
      loading={data?.loading}
      isCollection
    />
  );
};

export default AdvanceSearchResourceCollection;
