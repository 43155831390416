import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { serializeObject } from "store/api/utils";

const useAssociation = () => {
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  const headers = {
    Authorization: "Bearer " + token,
  };

  const fetchAssociation = async (parent, parentSlug, child, filters = {}) => {
    if ((!parent || !parentSlug || !child) && typeof parent !== "object")
      return;

    const isParentObject = typeof parent === "object";

    let parentName = parent,
      parentSlugName = parentSlug,
      childName = child,
      filtersList = { ...filters };

    if (isParentObject) {
      const { component, slug, type, ...rest } = parent;

      parentName = component;
      parentSlugName = slug;
      childName = type;
      filtersList = { ...filtersList, ...rest };
    }

    const client = new REQUEST();
    const res = await client.get(
      `${
        ENDPOINTS.COMPONENT_ASSOCIATION
      }/${parentName}/${parentSlugName}/${childName}?language=${language}&${serializeObject(
        filtersList
      )}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      const { list, total_pages, ...rest } = res?.data?.data;

      if (isParentObject) return list;
      return [list, total_pages, rest];
    }
    return [];
  };

  const fetchRelated = async (component, slug, filters = {}) => {
    if (!component || !slug) return;
    const client = new REQUEST();
    const res = await client.get(
      `${
        ENDPOINTS.COMPONENT_ASSOCIATION
      }/${component}/${slug}?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return [];
  };

  const share = async (slug, component) => {
    if (!component || !slug) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.COMPONENT_ASSOCIATION}/${component}/${slug}/share?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  return {
    fetchAssociation,
    fetchRelated,
    share,
  };
};

export default useAssociation;
