import { Grid, Icon } from "@mui/material";
import DonutChart from "components/Progress/DonutChart";
import React, { useEffect, useRef, useState } from "react";
import {
  AccessAlarm,
  AccessTime,
  CheckBox,
  Group,
  Inventory,
  Task,
} from "@mui/icons-material";
import { COLORS, COMPONENT_TYPES } from "helpers/utils/constants";
import Button from "components/Button";
import Select from "components/Input/Select";
import { challenge } from "assets/svg/green";
import { lab, labProgram } from "assets/svg/blue";
import { resource, resourceCollection, resourceGroup } from "assets/svg/purple";
import useLabDashboard from "store/dashboard/lab";
import useOrgDashboard from "store/dashboard/org";
import { ProgressCardSkeleton } from "./ProgressCard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { capitalize } from "lodash";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";

const getReportDetails = (data, type) => {
  const mappings = {
    [COMPONENT_TYPES.CHALLENGE]: [
      {
        img: challenge,
        title: "Total Challenges",
        value: data?.totalChallenges,
      },
      {
        icon: AccessAlarm,
        title: "Active Challenges",
        value: data?.totalActiveChallenges ?? 0,
        color: COLORS.YELLOW,
      },
      {
        icon: CheckBox,
        title: "Completed Challenges",
        value: data?.totalCompletedChallenges ?? 0,
        color: COLORS.GREEN,
      },
      {
        icon: Inventory,
        title: "Closed Challenges",
        value: data?.totalCloseChallenges ?? 0,
        color: COLORS.DARK_GRAY,
      },
      {
        icon: Group,
        title: "Total Members",
        value: data?.totalActiveMembers ?? 0,
        iconColor: COLORS.GREEN,
      },
    ],
    [COMPONENT_TYPES.LAB]: [
      {
        img: lab,
        title: "Total Labs",
        value: data?.totalLabs || 0,
        color: COLORS.BLUE,
      },
      {
        img: labProgram,
        title: "Total Lab Programs",
        value: data?.totalLabPrograms ?? 0,
        color: COLORS.BLUE_OPAQUE,
      },
      {
        icon: Group,
        title: "Total Members",
        value: data?.totalActiveMembers ?? 0,
        color: COLORS.BLUE,
      },
    ],
    [COMPONENT_TYPES.RESOURCE]: [
      {
        img: resource,
        title: "Total Resource Modules",
        value: data?.totalResourceModule ?? 0,
        color: COLORS.PURPLE_OPAQUE,
      },
      {
        img: resourceCollection,
        title: "Total Resource Collections",
        value: data?.totalResourceCollection ?? 0,
        color: COLORS.PURPLE,
      },
      {
        img: resourceGroup,
        title: "Total Resource Groups",
        value: data?.totalResourceGroup ?? 0,
      },
      {
        icon: Group,
        title: "Total Viewers",
        value: data?.totalViewers ?? 0,
        color: COLORS.PURPLE,
      },
    ],
    [COMPONENT_TYPES.PROJECT]: [
      {
        icon: AccessTime,
        title: "Project in Progress",
        value: data?.totalInProgressProjects ?? 0,
        color: COLORS.YELLOW,
      },
      {
        icon: CheckBox,
        title: "Projects Submitted",
        value: data?.totalSubmittedProjects ?? 0,
        color: COLORS.GREEN,
      },
      {
        icon: Task,
        title: "Assessment Done",
        value: data?.totalAssessedProjects ?? 0,
        color: COLORS.RED,
      },
      {
        icon: Task,
        title: "Assessment Pending",
        value: data?.totalNonAssessedProjects ?? 0,
        color: COLORS.BLUE,
      },
    ],
  };
  return mappings[type] || [];
};

const getDonutValues = (data, type) => {
  let filteredDetails = [];
  let totalValue = 0;
  let completedValue = 0;
  let title = "";

  if (type === COMPONENT_TYPES.CHALLENGE) {
    filteredDetails = [
      { value: data?.totalActiveChallenges ?? 0, color: COLORS.YELLOW },
      { value: data?.totalCompletedChallenges ?? 0, color: COLORS.GREEN },
      { value: data?.totalCloseChallenges ?? 0, color: COLORS.DARK_GRAY },
    ];
    totalValue = data?.totalChallenges ?? 0;
    completedValue = data?.totalActiveChallenges ?? 0;
    title = "Active";
  } else if (type === COMPONENT_TYPES.LAB) {
    filteredDetails = [
      { value: data?.totalLabs ?? 0, color: COLORS.BLUE },
      { value: data?.totalLabPrograms ?? 0, color: COLORS.BLUE_OPAQUE },
    ];
    totalValue = (data?.totalLabs ?? 0) + (data?.totalLabPrograms ?? 0);
    completedValue = data?.totalLabs ?? 0;
    title = "Labs";
  } else if (type === COMPONENT_TYPES.RESOURCE) {
    filteredDetails = [
      { value: data?.totalResourceModule ?? 0, color: COLORS.PURPLE },
      {
        value: data?.totalResourceCollection ?? 0,
        color: COLORS.PURPLE_OPAQUE,
      },
      { value: data?.totalResourceGroup ?? 0, color: COLORS.PURPLE_OPAQUE },
    ];
    totalValue =
      (data?.totalResourceModule ?? 0) +
      (data?.totalResourceCollection ?? 0) +
      (data?.totalResourceGroup ?? 0);
    completedValue = data?.totalResourceModule ?? 0;
    title = "Modules";
  } else if (type === COMPONENT_TYPES.PROJECT) {
    filteredDetails = [
      { value: data?.totalInProgressProjects ?? 0, color: COLORS.YELLOW },
      { value: data?.totalSubmittedProjects ?? 0, color: COLORS.GREEN },
      { value: data?.totalAssessedProjects, color: COLORS.RED },
      { value: data?.totalNonAssessedProjects, color: COLORS.BLUE },
    ];
    totalValue =
      (data?.totalInProgressProjects ?? 0) +
      (data?.totalSubmittedProjects ?? 0) +
      (data?.totalAssessedProjects ?? 0) +
      (data?.totalNonAssessedProjects ?? 0);
    completedValue = data?.totalSubmittedProjects ?? 0;
    title = "Submitted";
  }

  return { filteredDetails, totalValue, completedValue, title };
};

const OPTIONS = [
  { value: "Filter by Challenges", key: COMPONENT_TYPES.CHALLENGE },
  { value: "Filter by Labs", key: COMPONENT_TYPES.LAB },
  { value: "Filter by Resource", key: COMPONENT_TYPES.RESOURCE },
  { value: "Filter by Projects", key: COMPONENT_TYPES.PROJECT },
];

const ReportCard = ({ type }) => {
  const [filterBy, setFilterBy] = useState(COMPONENT_TYPES.CHALLENGE);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const useDashboard = type === "lab" ? useLabDashboard : useOrgDashboard;
  const { getReports } = useDashboard();
  const [downloading] = useState(false);
  const chartRefs = useRef([]);
  const { t } = useLocalizedTranslation();
  const { user } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  useEffect(() => {
    if (data) return;
    fetchReports();
  }, []);

  const fetchReports = async () => {
    setLoading(true);
    const res = await getReports("challenges");
    const res2 = await getReports("labs");
    const res3 = await getReports("resources");
    const res4 = await getReports("projects");
    setData({
      challenges: res,
      labs: res2,
      resources: res3,
      projects: res4,
    });
    setLoading(false);
  };
  const reportDetails = getReportDetails(data?.[filterBy + "s"], filterBy);
  const { filteredDetails, totalValue, completedValue, title } = getDonutValues(
    data?.[filterBy + "s"],
    filterBy
  );

  const handleDownloadReport = async () => {
    const pdf = new jsPDF({ orientation: "l" });
    const titles = ["Challenges", "Labs", "Resources", "Projects"];

    for (let i = 0; i < OPTIONS.length; i++) {
      setFilterBy(OPTIONS[i]?.key);

      await new Promise((resolve) => setTimeout(resolve, 500));

      const chartElement = chartRefs.current;
      const canvas = await html2canvas(chartElement);
      const imgData = canvas.toDataURL("image/png");

      // Calculate positions to place two images per row
      const xPos = i % 2 === 0 ? 30 : 160;
      const yPos = Math.floor(i / 2) * 90 + 20;

      pdf.text(titles[i], xPos + 50, yPos - 5);
      pdf.addImage(imgData, "WEBP", xPos, yPos, 120, 60);
    }
    pdf.save("report.pdf");
  };

  return (
    <div className="px-4">
      <div className="flex items-center justify-between">
        <h3 className="text-nowrap">
          {t(capitalize(filterBy))}
          <span className="ml-1">{t("Report")}</span>
        </h3>
        <Select
          borderedIcon
          width="100%"
          height={40}
          value={filterBy}
          onChange={(e) =>
            Boolean(e.target.value) && setFilterBy(e.target.value)
          }
          keyValues={OPTIONS}
          placeholder={t("Filter by Challenges")}
          classes="w-45p"
        />
      </div>
      <p className="mt-3 fs-15">
        {t("You joined PreprLabs on")}{" "}
        {user
          ? moment(new Date(user?.member_since)).format("MMM D, YYYY")
          : "-"}
        , {t("here is your")} {t(capitalize(filterBy))}{" "}
        {t("Management")} {t("journey")}.
      </p>
      {loading ? (
        <ProgressCardSkeleton />
      ) : (
        <Grid
          item
          container
          justifyContent="space-between"
          className="items-center"
          mt={3}
          ref={chartRefs}
        >
          <Grid item xs={12} md={7}>
            {reportDetails?.length ? (
              reportDetails.map((d, i) => (
                <div key={i} className="flex justify-between fs-15 mb-2 mr-4">
                  <div className="flex">
                    {!!d.img ? (
                      <img src={d.img} className="w-20 h-20" alt="" />
                    ) : (
                      <Icon
                        sx={{ color: d.color || d?.iconColor }}
                        fontSize="small"
                        component={d.icon}
                      />
                    )}
                    <p
                      className="ml-2 tracking-tight"
                      style={{
                        color:
                          filterBy === COMPONENT_TYPES.CHALLENGE ||
                          filterBy === COMPONENT_TYPES.PROJECT
                            ? d.color
                            : null,
                      }}
                    >
                      {t(d.title)}
                    </p>
                  </div>
                  <p
                    className="tracking-tight"
                    style={{
                      color:
                        filterBy === COMPONENT_TYPES.CHALLENGE ||
                        filterBy === COMPONENT_TYPES.PROJECT
                          ? d.color
                          : null,
                    }}
                  >
                    {d.value}
                  </p>
                </div>
              ))
            ) : (
              <div className="flex-column items-center">
                <p className="text-center mb-4 c-gray tracking-tight">
                  Join {capitalize(filterBy)}s and track your progress here.
                </p>
                <Button width="140px" height="40px">
                  {t("Continue")}
                </Button>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={5} className="flex justify-center">
            <DonutChart
              classes="mx-auto"
              totalValue={totalValue}
              height="200px"
              width="200px"
              data={filteredDetails}
              hasSizeClasses
              completedValue={completedValue}
              title={title}
            />
          </Grid>
        </Grid>
      )}
      <div className={`flex justify-end ${loading ? "mt-12" : "mt-4"}`}>
        <Button
          bordered
          loading={downloading}
          onClick={handleDownloadReport}
          classes="minw-140"
        >
          {t("Download Report")}
        </Button>
      </div>
    </div>
  );
};

export default ReportCard;
