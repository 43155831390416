import useLabReportContext from "../hooks/useLabReportContext";
import useLabReport from "../../../../store/reports/lab/service-hooks";
import { useEffect, useMemo, useState } from "react";
import EngagementsReport from "../../components/EngagementsReport";

const LabEngagement = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { labSlug } = useLabReportContext();
  const { labEngagements } = useLabReport();
  /**
   * STATE
   */
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  /**
   *
   * @returns {Promise<void>}
   */
  const fetchEngagement = async () => {
    setLoading(true);
    if (labSlug) {
      const response = await labEngagements(labSlug);
      if (response) {
        setData(response);
      }
    }
    setLoading(false);
  };

  const formattedData = useMemo(() => {
    return [
      {
        title: t("Views"),
        count: data?.views ?? 0,
      },
      {
        title: t("Discussion Posts"),
        count: data?.discussion_posts ?? 0,
      },
      {
        title: t("Saves"),
        count: data?.saves ?? 0,
      },
      {
        title: t("Share"),
        count: data?.share ?? 0,
      },
      {
        title: t("Saved & Started"),
        count: data?.saved_started ?? 0,
      },
      {
        title: t("Shared Started"),
        count: data?.shared_started ?? 0,
      },
      {
        title: t("Skills verified"),
        count: data?.skills_verified ?? 0,
      },
    ];
  }, [data]);

  /**
   * MOUNTED LIFECYCLE
   */
  useEffect(() => {
    fetchEngagement();
  }, []);

  return (
    <>
      <EngagementsReport
        data={formattedData}
        isLoading={loading}
        title={t("Lab Engagements")}
      />
    </>
  );
};

export default LabEngagement;
