import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import { mockRequestGet } from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useExplore from "./service-hook";
import { BrowserRouter } from "react-router-dom";

let getSpy;

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
});

const ExploreServicesMock = () => {
  const { recommended, featured, recommendedSkills, teams } = useExplore();

  const BUTTON_LINKS = [
    { name: "recommended", onClick: () => recommended() },
    { name: "featured", onClick: () => featured() },
    { name: "recommendedSkills", onClick: () => recommendedSkills() },
    { name: "teams", onClick: () => teams() },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Explore services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <ExploreServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
  });

  test("recommended", async () => {
    const button = screen.getByTestId("recommended");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.EXPLORE}/${ENDPOINTS.RECOMMENDED}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("featured", async () => {
    const button = screen.getByTestId("featured");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.EXPLORE}/${ENDPOINTS.FEATURED}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("recommendedSkills", async () => {
    const button = screen.getByTestId("recommendedSkills");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.EXPLORE}/${ENDPOINTS.RECOMMENDED}/${ENDPOINTS.SKILLS}?language=en&`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("teams", async () => {
    const button = screen.getByTestId("teams");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.EXPLORE}/${ENDPOINTS.TEAMS}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });
});
