import { SCHEMA_TYPES } from "helpers/utils/constants";
import { REQUIRED_VALIDATOR } from "helpers/utils/validators";
import { USER_STATUS_NUMBER } from "pages/auth/auth-data";

export const schema = [
  {
    title: "Name",
    name: "name",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  // {
  //   title: "Gender",
  //   name: "gender",
  //   type: SCHEMA_TYPES.SELECT,
  //   options: GENDER,
  //   validator: REQUIRED_VALIDATOR,
  //   lg: 12,
  // },
  {
    title: "Status",
    name: "purpose",
    type: SCHEMA_TYPES.SELECT,
    options: USER_STATUS_NUMBER,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Date of birth",
    name: "date_of_birth",
    type: SCHEMA_TYPES.DATE,
    lg: 12,
  },
  {
    title: "Location",
    name: "location",
    type: SCHEMA_TYPES.GOOGLE_PLACES,
    lg: 12,
  },
  {
    title: "About Me",
    name: "about",
    type: SCHEMA_TYPES.TEXTBOX,
    classes: "h-200",
    lg: 12,
  },
  {
    title: "I self-identify as (check all that apply):",
    name: "identification",
    options: [
      { title: "Recent Immigrant", value: "recent_immigrant" },
      { title: "Indigenous", value: "indigenous_group" },
      { title: "Person with Disability", value: "disability" },
      { title: "Visible Minority", value: "visible_minority" },
    ],
    type: SCHEMA_TYPES.CHECKBOX,
    multiSelect: true,
    lg: 12,
  },
];

export const experienceSchema = [
  {
    title: "Company Name",
    name: "company",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Title",
    name: "position",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
    limit:50,
  },
  {
    title: "Location",
    name: "location",
    type: SCHEMA_TYPES.GOOGLE_PLACES,
    lg: 12,
  },
  {
    title: "Start Date",
    name: "start_date",
    type: SCHEMA_TYPES.DATE,
    lg: 12,
    disableFuture:true
  },
  {
    title: "End Date",
    name: "end_date",
    type: SCHEMA_TYPES.DATE,
    lg: 12,
  },
  {
    title: "Description",
    name: "description",
    type: SCHEMA_TYPES.TEXTBOX,
    lg: 12,
    maxLength: 500
  },
];

export const educationSchema = [
  {
    title: "School Name",
    name: "university",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
    limit:50,
  },
  {
    title: "Field of study",
    name: "degree",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Location",
    name: "location",
    type: SCHEMA_TYPES.GOOGLE_PLACES,
    lg: 12,
  },
  {
    title: "Start Date",
    name: "start_date",
    type: SCHEMA_TYPES.DATE,
    lg: 12,
    disableFuture:true
  },
  {
    title: "End Date",
    name: "end_date",
    type: SCHEMA_TYPES.DATE,
    lg: 12,
  },
  {
    title: "Description",
    name: "description",
    type: SCHEMA_TYPES.TEXTBOX,
    lg: 12,
    maxLength: 500
  },
];

export const certificationSchema = [
  {
    title: "Company Name",
    name: "company",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Title",
    name: "name",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
    limit:50,
  },
  {
    title: "Start Date",
    name: "start_date",
    type: SCHEMA_TYPES.DATE,
    lg: 12,
    disableFuture:true
  },
  {
    title: "End Date",
    name: "end_date",
    type: SCHEMA_TYPES.DATE,
    lg: 12,
  },
  {
    title: "Description",
    name: "description",
    type: SCHEMA_TYPES.TEXTBOX,
    lg: 12,
    maxLength: 500
  },
];

export const patentSchema = [
  {
    title: "Patent Name",
    name: "name",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
    limit:50,
  },
  {
    title: "Company",
    name: "company",
    type: SCHEMA_TYPES.INPUT,
    validator: REQUIRED_VALIDATOR,
    lg: 12,
  },
  {
    title: "Patent Date",
    name: "patent_date",
    type: SCHEMA_TYPES.DATE,
    lg: 12,
    disableFuture:true
  },
  {
    title: "Description",
    name: "description",
    type: SCHEMA_TYPES.TEXTBOX,
    lg: 12,
    maxLength: 500
  },
];

export const PROFILE_DATA = {
  name: "Matthew Almeria",
  role: "Digital Producer at Prepr",
  image: "https://prepr.org/wp-content/uploads/2021/07/matt-300x300.jpg",
  handle: "@raiinaire",
  genderDesc: "he/him",
  website: "matthewalmeria.com",
  summary:
    "Hi, I’m Matthew. I’m currently looking for collaborators on a virtual reality project! I like coffee and long walks on the beach.",
  learnRank: 34,
  projectsCreated: 24,
  labsJoined: 14,
  acheivementsCount: 54,
  skillsCount: 25,
  featuredSkills: ["Graphic Design", "Development"],
  featuredAcheivements: [
    { title: "Participation Prize", desc: "Cultured Worker", preprPoints: 100 },
  ],
  files: [
    "MATTHEW-resume-master.pdf",
    "MATTHEW-resume-master.pdf",
    "MATTHEW-resume-master.pdf",
    "MATTHEW-resume-master.pdf",
  ],
  experience: [
    {
      organization: "SAGE Real Estate",
      title: "Design Studio Assistant",
      duration: "December 2019 - April 2020",
      location: "Ontario, Canada",
      summary:
        "Provided valuable support to in-house graphic design team in administrative and design roles. Maintained peak productivity in executing real estate marketing campaigns on various platforms. Ensured consistent quality assurance in print production projects for marketing materials.",
    },
    {
      organization: "T-Shirt Elephant",
      title: "Production Artist",
      duration: "June 2019 - December 2019",
      location: "Ontario, Canada",
      summary:
        "Maintained peak efficiency in print production via direct-to-garment printing methods. Demonstrated consistent quality control in fixing and improving client artwork for print.",
    },
    {
      organization: "T-Shirt Elephant",
      title: "Production Artist",
      duration: "June 2019 - December 2019",
      location: "Ontario, Canada",
      summary:
        "Maintained peak efficiency in print production via direct-to-garment printing methods. Demonstrated consistent quality control in fixing and improving client artwork for print.",
    },
    {
      organization: "Scotiabank Theatre",
      title: "VR Attendant",
      duration: "June 2019 - December 2019",
      location: "Ontario, Canada",
      summary:
        "Maintained peak efficiency in print production via direct-to-garment printing methods. Demonstrated consistent quality control in fixing and improving client artwork for print.",
    },
  ],
  education: [
    {
      organization: "OCAD University",
      title: "Graphic Design (BDes.)",
      duration: "September 2014 - April 2019",
      location: "Ontario, Canada",
      summary:
        "Ontario College of Art & Design University, commonly known as OCAD University, is a public university of art and design located in Toronto, Ontario, Canada.",
    },
  ],
  challengeHistory: [
    {
      title: "FUN - Industry Future Challenge to Support...",
      isPrivate: "true",
      endDate: "12/03/2021",
      description:
        "***You will be able to officially start the challenge on PreprLabs later this week, for today, November 1st, just focus on getting familiar with the details and starting to define an area of focus.*** How might we better support businesses arou...",
      acheivement: "Participation Prize",
    },
    {
      title: "FUN - Industry Future Challenge to Support...",
      isPrivate: "true",
      endDate: "12/03/2021",
      description:
        "***You will be able to officially start the challenge on PreprLabs later this week, for today, November 1st, just focus on getting familiar with the details and starting to define an area of focus.*** How might we better support businesses arou...",
      acheivement: "Participation Prize",
    },
    {
      title: "FUN - Industry Future Challenge to Support...",
      isPrivate: "true",
      endDate: "12/03/2021",
      description:
        "***You will be able to officially start the challenge on PreprLabs later this week, for today, November 1st, just focus on getting familiar with the details and starting to define an area of focus.*** How might we better support businesses arou...",
      acheivement: "Participation Prize",
    },
    {
      title: "FUN - Industry Future Challenge to Support...",
      isPrivate: "true",
      endDate: "12/03/2021",
      description:
        "***You will be able to officially start the challenge on PreprLabs later this week, for today, November 1st, just focus on getting familiar with the details and starting to define an area of focus.*** How might we better support businesses arou...",
      acheivement: "Participation Prize",
    },
    {
      title: "FUN - Industry Future Challenge to Support...",
      isPrivate: "true",
      endDate: "12/03/2021",
      description:
        "***You will be able to officially start the challenge on PreprLabs later this week, for today, November 1st, just focus on getting familiar with the details and starting to define an area of focus.*** How might we better support businesses arou...",
      acheivement: "Participation Prize",
    },
  ],
  projectHistory: [
    {
      title: "CollieQ",
      image: "https://collieq.netlify.app/assets/og-images/og-image.png",
      description:
        "CollieQ is a crowd management system that aims to solve the problem of overcrowded lines and lack of social distancing by providing a virtual way to queue. The service is designed to be versatile and easy to use, while also providing business owners real-time analytics for their stores.",
      associatedChallenge: "FUN Program",
    },
  ],
  friends: [
    {
      name: "Salar Chagpar",
      image:
        "https://prepr.org/wp-content/uploads/2017/10/ca1a90ad-salar-1-crop_046047046047000000.jpg",
      handle: "@salarchagpar",
      role: "CEO & Chief Visioneer, Prepr",
      rank: 34,
    },
  ],
  acheivements: [
    {
      title: "Achievement Name",
      project: "Project Example",
      awardedOn: "May 27, 2021",
      awardType: "Appreciation Award",
    },
  ],
};
