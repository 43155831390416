import {useDispatch, useSelector} from "react-redux";
import useComputed from "../../../helpers/hooks/useComputed";
import {useCallback, useEffect} from "react";
import {
    useAdvanceSearchContext
} from "./useAdvanceSearchContext";
import moment from "moment";

/*
    |--------------------------------------------------------------------------
    | THINGS TO CONSIDER
    |--------------------------------------------------------------------------
    |
    | 1. WE HAVE GENERATED AN FILTER-KEY THAT WILL BE UNIQUE BASED ON THE FILTER AND KEYWORD
    | 2. DATA IN THE REDUX IS STORED BASED ON THE FILTER KEY - LOADING, DATA, META AND LAST FETCHED TIME
    |
    |--------------------------------------------------------------------------
    | WE CONSIDER 3 CASES BEFORE DATA FETCHING / MAKING AN API REQUEST
    |--------------------------------------------------------------------------
    |
    | 1. STATE ALREADY HAS DATA FOR THE GIVEN KEY - EXAMPLE FOR LAB STATE[LABS][FILTER-KEY][DATA] - IF THERE IS NO DATA WE FETCH
    | 2. EVEN IF THE STATE HAS DATA HE HAVE A CACHE TIME - 5 MINUTE DEFAULT -> IF CACHE TIME EXCEEDS 5 MINUTES WE FETCH
    | 3. IN CASE OF EXCESSIVE CACHE -> DATA ARE PUSHED INTO THE STATE WITH A UNIQUE KEY BASED ON THE FILTER AND KEYWORD
    |    SO OUR CACHE BUCKET MIGHT GET HEAVIER - AFTER CERTAIN THRESHOLD IS EXCEEDED WE CLEAR THE CACHE AND FETCH
    |
    |--------------------------------------------------------------------------
    | WE FOLLOW THIS APPROACH TO ADDRESS FOLLOWING CONCERNS
    |--------------------------------------------------------------------------
    |
    | 1. TO REDUCE THE NUMBER OF BACKEND API CALLS.
    | 2. BETTER USER EXPERIENCE
    | 3. BETTER RESULT WHEN THE LATER API CALLS ARE FAST THEN THE PREVIOUS API - WITH MAY OVERRIDE THE ACTUAL RESULT
    |
 */
const useAdvanceSearchFilter = (reduxState, keyword, filter, fetchFunction, dispatchFunction, quickSearch = false, cacheTime = 5) => {
    /**
     * HOOKS
     */
    const {advanceSearch} = useSelector(state => state)
    const dispatch = useDispatch()
    const {
        setTotalCounts,
        setTotalCountsQuickSearch
    } = useAdvanceSearchContext()

    /**
     * COMPUTED PROPERTIES
     */

    /**
     * A UNIQUE HASH KEY GENERATED ON EVERY FILTER AND KEYWORD CHANGE FOR CACHING THE DATA
     */
    const filterUniqueHashKey = useComputed(() => {
        return filter ? `${reduxState}-${keyword}-${JSON.stringify(filter)}` : `${reduxState}-${keyword}`
    }, [filter, keyword, reduxState]);


    /**
     * METHODS
     */

    const dispatchLoadingState = useCallback(
        (loading) => {
            dispatch(dispatchFunction({
                [filterUniqueHashKey]: {loading}
            }));
        },
        [dispatch, dispatchFunction, filterUniqueHashKey]
    );

    /**
     * FETCH DATA FORM ADVANCE SEARCH
     * @type {(function(): Promise<void>)|*}
     */
    const fetchData = useCallback(async () => {
        dispatchLoadingState(true)
        const resData = await fetchFunction(keyword, filter)
        if (resData?.data && resData?.meta) {
            // SETTING THE TOTAL COUNT TO THE CONTEXT API AFTER API SUCCESS
            if (!quickSearch) {
                setTotalCounts(prev => {
                    return {
                        ...prev,
                        [reduxState]: resData?.meta?.total ?? 0
                    }
                })
            } else {
                setTotalCountsQuickSearch(prev => {
                    return {
                        ...prev,
                        [reduxState]: resData?.meta?.total ?? 0
                    }
                })
            }

            dispatch(dispatchFunction({
                [filterUniqueHashKey]: {
                    loading: false, ...resData,
                    lastFetchedAt: moment(new Date()).toISOString()
                },
            }))
        } else {
            dispatchLoadingState(false)
        }
    }, [dispatchLoadingState, fetchFunction, filter, keyword, reduxState, setTotalCounts])


    /**
     * CHECK IF THE CACHE IS VALID
     * @type {(function(): (boolean|boolean))|*}
     */
    const isCacheValid = useCallback(() => {
        const state = advanceSearch[reduxState];
        if (!state || !state[filterUniqueHashKey] || !state[filterUniqueHashKey]?.lastFetchedAt) {
            return false;
        }

        const lastFetched = moment(state[filterUniqueHashKey].lastFetchedAt).add(cacheTime, 'minutes');
        return lastFetched.isAfter(moment());
    }, [advanceSearch, filterUniqueHashKey, reduxState, cacheTime]);

    // /**
    //  * CHECK EXCESSIVE CACHE
    //  * @type {Function}
    //  */
    // const isExcessiveCache = useCallback(() => {
    //     const state = advanceSearch[reduxState];
    //     return state && Object.keys(state).length > 100;
    // }, [advanceSearch, reduxState]);


    /**
     * INITIALIZATION FUNCTION
     * @type {(function(): Promise<void>)|*}
     */
    const init = useCallback(async () => {

        const state = advanceSearch[reduxState];
        const hasData = state && state[filterUniqueHashKey]?.data?.length > 0;

        // if (isExcessiveCache()) {
        //     dispatch(AS_REDUCER.clearState(reduxState));
        // }

        if (!hasData || !isCacheValid()
            // || isExcessiveCache()
        ) {
            await fetchData();
        } else {
            if (!quickSearch) {
                setTotalCounts((totalCounts) => {
                    return {
                        ...totalCounts,
                        [reduxState]: state[filterUniqueHashKey]?.meta?.total ?? 0
                    }
                })
            } else {
                setTotalCountsQuickSearch((totalCounts) => {
                    return {
                        ...totalCounts,
                        [reduxState]: state[filterUniqueHashKey]?.meta?.total ?? 0
                    }
                })
            }
        }
    }, [advanceSearch, fetchData, filterUniqueHashKey, isCacheValid,
        // isExcessiveCache,
        reduxState, dispatch]);

    /**
     * COMPONENT MOUNTED FUNCTION
     */
    useEffect(() => {
        init()
    }, [filterUniqueHashKey])

    return {
        data: advanceSearch[reduxState]?.[filterUniqueHashKey] || null,
        filterUniqueHashKey
    }
}


export default useAdvanceSearchFilter
