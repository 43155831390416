import useRedirect from "helpers/hooks/useRedirect";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { serializeObject } from "store/api/utils";
import { resolveResponse } from "store/api/utils";

const useLabPrograms = () => {
  const { language, token } = useSelector(
    (state) => state[REDUCER_TYPES.AUTH]
  );

  const { navigateLabP } = useRedirect();

  const headers = {
    Authorization: "Bearer " + token,
  };

  const checkSlug = async (slug) => {
    if (!slug) return;
    const client = new REQUEST();
    const response = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${ENDPOINTS.CHECK_SLUG}/${slug}?language=${language}`,
      {},
      headers,
      () => { },
      () => { },
      true
    );
    if (response?.data) return response?.data;
    return response;
  };

  const checkTitle = async (title) => {
    if (!title) return;
    const client = new REQUEST();
    const response = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${ENDPOINTS.CHECK_TITLE}/${title}?language=${language}`,
      {},
      headers,
      () => { },
      () => { },
      true
    );
    if (response?.data) return response?.data;
    return response;
  };

  //FETCH PUBLIC LAB PROGRAMS
  const fetchLabPrograms = async (filters = {}) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${
        ENDPOINTS.LAB_PROGRAM
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      const { list, total_pages, total_count } = res?.data?.data;
      return [list, total_pages, total_count];
    }
    return [];
  };

  //FETCH LAB PROGRAM PUBLIC DETAILS API
  const fetchPublicLP = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB_PROGRAM}/${slug}?language=${language}`,
      {},
      headers,
      onSuccess,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return res;
  };

  //FETCH MANAGE LAB PROGRAMS LISTING
  const fetchPrivateLPs = async (filters) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${
        ENDPOINTS.LAB_PROGRAM
      }?language=${language}&${serializeObject(filters)}`,
      {},
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    if (res?.data?.data) {
      const { list, total_pages, total_count } = res?.data?.data;
      return [list, total_pages, total_count];
    }
    return [];
  };

  //FETCH LAB PROGRAM PRIVATE DETAILS API
  const fetchLP = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${slug}?language=${language}`,
      {},
      headers,
      onSuccess,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return res;
  };

  //CREATE LAB PROGRAM API
  const create = async (payload) => {
    if (!payload) return;
    const body = {
      ...payload,
      language,
    };

    const onSuccess = (res) =>
      resolveResponse(
        res,
        (data) => navigateLabP(data.slug),
        "Lab program created successfully!"
      );

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${ENDPOINTS.CREATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    return res;
  };

  //EDIT LAB PROGRAM API
  const edit = async (slug, payload) => {
    if (!slug || !payload) return;
    const body = {
      ...payload,
      language,
      _method: "PUT",
    };

    const onSuccess = (res) =>
      resolveResponse(
        res,
        () => navigateLabP(slug, true),
        "Lab program edited successfully!"
      );

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${slug}/${ENDPOINTS.UPDATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    return res;
  };

  //DELETE LAB PROGRAM API
  const deleteLP = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);

    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${slug}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      onSuccess
    );
  };

    //FETCH LAB LISTING WITH NAME AND ID
    const fetchLabsProgramsSelectList = async (filters = {}) => {
      const onSuccess = (res) => resolveResponse(res, null, null, null, true);
      const client = new REQUEST();
      const res = await client.get(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${
          ENDPOINTS.GET_LIST
        }?language=${language}&${serializeObject(filters)}`,
        {},
        headers,
        onSuccess
      );
      if (res?.data?.data?.length) {
  
        return res?.data?.data;
      }
      return [];
    };
  return {
    fetchLabPrograms,
    fetchPublicLP,
    fetchLP,
    fetchPrivateLPs,
    create,
    checkSlug,
    checkTitle,
    edit,
    deleteLP,
    fetchLabsProgramsSelectList
  };
};

export default useLabPrograms;
