import Button from "components/Button";
import Card from "components/Cards/Card";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { useTranslation } from "react-i18next";

const MoreResourcesCard = () => {
    const { t } = useTranslation(`${COMPONENT_TYPES.RESOURCE}`);
    return (
        <Card classes="mt-4">
            <h3>{t("More Resources")}</h3>
            {
                Array(2).fill(1).map((d, i) =>
                    <div key={i} className='flex mt-6 ml-4'>
                        <img src="https://repository-images.githubusercontent.com/444145014/00444c61-5f60-48fb-a99a-58021d1f319c" alt="" height="150px" />
                        <div className='ml-2'>
                            <p className='link fs-18 fw-600'>Frontend Developer Challenge 1: Web Page Creation</p>
                            <p className='mt-2 fs-14'>Overview: The objective of designing a site is to ensure that when the users open up the site, they see the information in a format that is easy to read and relevant. This is further complicated..</p>

                        </div>
                    </div>
                )
            }
            <div className='flex justify-center mt-2'>
                <Button bordered>{t("Load More")}</Button>
            </div>
        </Card>
    )
}

export default MoreResourcesCard;