import Card from 'components/Cards/Card';
import Divider from 'components/Divider';
import Input from 'components/Input';
import { plan_addons, subscription_plans } from 'pages/organization/forms/data';
import React, { useState } from 'react';


const SubscriptionPlansCard = () => {

    const [selectedPlan, setSelectedPlan] = useState(1);
    const [planAddons, setPlanAddons] = useState({});

    const onPlanClick = (id) => setSelectedPlan(id);
    const onChangeAddon = (id, value) => {
        planAddons[id] = +value;
        setPlanAddons({ ...planAddons });
    }

    return (
        <>
            <Divider />
            <Card width="90%" centered classes="mt-4">
                <h3 className='opacity-70'>Choose your subscription plan</h3>
                <div className='organization__subscriptionPlansGrid'>
                    {
                        subscription_plans.map(({ id, title, costPerMonth, featureList }) =>
                            <div onClick={() => onPlanClick(id)} className={`subscriptionPlan ${id === selectedPlan && 'selected'}`} key={id}>
                                <h3>{title}</h3>
                                <p>$<span>{costPerMonth}</span> / Month</p>
                                <Divider />
                                <h3>Feature list</h3>
                                {
                                    featureList?.map((feature, i) => <li key={i}>{feature}</li>)
                                }
                            </div>
                        )
                    }
                </div>
            </Card>
            <Card width="90%" centered classes="mt-4 mb-4">
                <h3>Manage your add-ons</h3>
                <p>Add additional slots to your Tier 1 Subscription.</p>
                <div className='mt-6 organization__Addons'>
                    {
                        plan_addons.map((addon, i) => <PlanAddonRow key={i} addon={addon} onChange={onChangeAddon} addons={planAddons} />)
                    }
                </div>
            </Card>
        </>
    )
}

export default SubscriptionPlansCard

const PlanAddonRow = ({ addon, onChange, addons }) => {

    const value = addons[addon?.id] === undefined ? 0 : addons[addon?.id];
    return (
        <div className='flex items-center mt-2 fs-14 addonRow'>
            <p className='w-250'>{addon?.title}</p>
            <p className='ml-3 opacity-50 w-150'>${addon?.eachCost}/each</p>
            <div className='flex ml-5 items-center'>
                <b className='cursor-pointer user-select-none' onClick={() => onChange(addon?.id, value - 1)}>-</b>
                <Input height={30} width={100} classes="mx-2" value={value} onChange={e => onChange(addon?.id, e.target.value)} type="number" />
                <b className='cursor-pointer user-select-none' onClick={() => onChange(addon?.id, value + 1)}>+</b>
            </div>
        </div>
    )
}