import SecureImage from "components/UI/atoms/SecureImage";
import React, { useEffect, useState } from "react";
import goldTrophy from "assets/png/goldTrophy.png";
import Button from "components/Button";
import { Share } from "@mui/icons-material";
import useRedirect from "helpers/hooks/useRedirect";
import { achievement as achievementTrophy } from "assets/svg/yellow";
import useUserDashboard from "store/dashboard/user";
import { Skeleton } from "@mui/material";
import AchievementShareModal from "pages/achievements/components/AchievementShareModal";
import useAchievements from "store/achievements/service-hook";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const AchievementCard = () => {
  const { navigateExplore, navigateAchievement } = useRedirect();
  const { myLatestAcievement } = useUserDashboard();
  const [achievement, setAchievement] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const { setFeatured } = useAchievements();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    fetchLatestAchievement();
  }, []);

  const fetchLatestAchievement = async () => {
    setLoading(true);
    const res = await myLatestAcievement();
    setAchievement(res);
    setLoading(false);
  };

  const closeShareModal = () => setModal(null);

  const handleSetFeatured = async (id, unset = false) => {
    await setFeatured(id, Boolean(unset));
    setAchievement((prevAchievement) => {
      if (prevAchievement.id === id) {
        return {
          ...prevAchievement,
          is_featured: unset ? "no" : "yes",
        };
      }
      return prevAchievement;
    });
  };

  return (
    <div className="px-4">
      <h3>{t("Latest Achievement")}</h3>
      {loading ? (
        <AchievementCardSkeleton />
      ) : (
        <div className="flex flex-column items-center mt-4">
          {!achievement ? (
            <>
              <img
                src={achievementTrophy}
                className="w-140 h-140 mt-2"
                alt="default pic of achievement"
              />
              <p className="text-center my-3 c-gray">
                {t(
                  "Join Challenges and Labs, get awarded with micro-credentials."
                )}
              </p>
              <Button onClick={navigateExplore} width="140px" height="40px">
                {t("Explore")}
              </Button>
            </>
          ) : (
            <>
              <SecureImage
                style={{ border: "3px solid #dadada" }}
                classes="w-180 h-180 rounded-100"
                placeholder={goldTrophy}
                src={achievement?.image}
              />
              <h3
                className="mt-6 link"
                onClick={() => navigateAchievement(achievement?.id)}
              >
                {achievement?.award_name ?? "N/A"}
              </h3>
              <p className="text-center my-3 px-4 text-truncate-2 fs-14 c-gray">
                {achievement?.description ?? "N/A"}
              </p>
              <div className="flex">
                <Button
                  classes="mr-2"
                  width="140px"
                  onClick={() => navigateAchievement(achievement?.id)}
                >
                  {t("View Details")}
                </Button>
                <Button
                  bordered
                  width="100px"
                  onClick={() => setModal(achievement)}
                >
                  <Share className="mr-1" />
                  {t("Share")}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
      <AchievementShareModal
        achievement={modal}
        open={!!modal}
        onClose={closeShareModal}
        setFeatured={handleSetFeatured}
      />
    </div>
  );
};

export default AchievementCard;

const AchievementCardSkeleton = () => {
  return (
    <div className="px-4">
      <div className="flex-column items-center mt-4 justify-center">
        <Skeleton
          variant="circular"
          width={160}
          height={160}
          className="mb-4"
        />
        <Skeleton variant="text" width={200} height={10} className="mb-1" />
        <Skeleton variant="text" width={200} height={10} className="mb-2" />
        <Skeleton
          variant="text"
          width="100%"
          height={20}
          className="my-4 rounded-10"
        />
        <div className="flex">
          <Skeleton
            variant="rectangular"
            width={100}
            height={40}
            className="mr-2 rounded-10"
          />
          <Skeleton
            variant="rectangular"
            className="rounded-10"
            width={100}
            height={40}
          />
        </div>
      </div>
    </div>
  );
};
