import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {
  mockRequestGet,
  mockRequestPost,
  mockRequestDelete,
} from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useResources from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api";

let getSpy;
let postSpy;
let deleteSpy;

let resourcesId = "1";
let rating = "1";
let id = "1";
let type = "1";
let assetId = "1";
let isC;
let isG;

const payload = {
  language: "en",
};

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com" },
  },
  [REDUCER_TYPES.MASTER]: {},
});

const ResourcesServicesMock = () => {
  const {
    fetchMyResource,
    fetchMyResources,
    fetchResource,
    fetchResources,
    deleteResource,
    like,
    unLike,
    favorite,
    unFavorite,
    checkTitle,
    checkSlug,
    share,
    addRating,
    create,
    edit,
    addLinks,
    uploadFiles,
    deleteMedia,
    fetchResourceSelectList,
    aiCreateResourceModulePreviews,
    aiCreateResourceModule,
    createResourceModuleGo1,
    uploadScormFile,
    deleteScorm,
    fetchPlayerUrl,
    moduleVisit,
    clone,
    fetchResourceGroupSelectList,
  } = useResources();

  const BUTTON_LINKS = [
    { name: "fetchMyResource", onClick: () => fetchMyResource(resourcesId) },
    { name: "fetchMyResources", onClick: () => fetchMyResources({}) },
    { name: "fetchResource", onClick: () => fetchResource(resourcesId) },
    { name: "fetchResources", onClick: () => fetchResources({}) },
    { name: "deleteResource", onClick: () => deleteResource(resourcesId) },
    { name: "like", onClick: () => like(resourcesId) },
    { name: "unLike", onClick: () => unLike(resourcesId) },
    { name: "favorite", onClick: () => favorite(resourcesId) },
    { name: "unFavorite", onClick: () => unFavorite(resourcesId) },
    { name: "checkSlug", onClick: () => checkSlug(resourcesId) },
    { name: "checkTitle", onClick: () => checkTitle("title") },
    {
      name: "share",
      onClick: () => share(resourcesId, ENDPOINTS.RESOURCE_MODULE),
    },
    { name: "addRating", onClick: () => addRating(resourcesId, rating) },
    { name: "create", onClick: () => create({}) },
    { name: "edit", onClick: () => edit(resourcesId, {}) },
    { name: "addLinks", onClick: () => addLinks(resourcesId, {}) },
    { name: "uploadFiles", onClick: () => uploadFiles(resourcesId, {}) },
    { name: "deleteMedia", onClick: () => deleteMedia(resourcesId, id, type) },
    {
      name: "fetchResourceSelectList",
      onClick: () => fetchResourceSelectList(isC),
    },
    {
      name: "aiCreateResourceModulePreviews",
      onClick: () => aiCreateResourceModulePreviews({}),
    },
    {
      name: "aiCreateResourceModule",
      onClick: () => aiCreateResourceModule({}),
    },
    {
      name: "createResourceModuleGo1",
      onClick: () => createResourceModuleGo1({}),
    },
    {
      name: "uploadScormFile",
      onClick: () => uploadScormFile(resourcesId, {}),
    },
    { name: "deleteScorm", onClick: () => deleteScorm(resourcesId) },
    { name: "fetchPlayerUrl", onClick: () => fetchPlayerUrl(id) },
    { name: "moduleVisit", onClick: () => moduleVisit(resourcesId, assetId) },
    { name: "clone", onClick: () => clone(resourcesId, isC, isG) },
    {
      name: "fetchResourceGroupSelectList",
      onClick: () => fetchResourceGroupSelectList({}),
    },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Resources services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <ResourcesServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
    deleteSpy = mockRequestDelete();
  });

  test("fetchMyResource", async () => {
    const button = screen.getByTestId("fetchMyResource");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("fetchMyResources", async () => {
    const button = screen.getByTestId("fetchMyResources");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}?language=en&`,
        {},
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("fetchResource", async () => {
    const button = screen.getByTestId("fetchResource");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("fetchResources", async () => {
    const button = screen.getByTestId("fetchResources");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_MODULE}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("deleteResource", async () => {
    const button = screen.getByTestId("deleteResource");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("like", async () => {
    const button = screen.getByTestId("like");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}/${ENDPOINTS.LIKE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("unLike", async () => {
    const button = screen.getByTestId("unLike");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}/${ENDPOINTS.UNLIKE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("favorite", async () => {
    const button = screen.getByTestId("favorite");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}/${ENDPOINTS.FAVOURITE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("unFavorite", async () => {
    const button = screen.getByTestId("unFavorite");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}/${ENDPOINTS.UNFAVOURITE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("checkSlug", async () => {
    const button = screen.getByTestId("checkSlug");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.CHECK_SLUG}/${resourcesId}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("checkTitle", async () => {
    const button = screen.getByTestId("checkTitle");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.CHECK_TITLE}/title?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("share", async () => {
    const button = screen.getByTestId("share");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}/${ENDPOINTS.SHARE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("addRating", async () => {
    const button = screen.getByTestId("addRating");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}/${ENDPOINTS.ADD_RATING}?language=en&rating=1`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function)
      )
    );
  });

  test("create", async () => {
    const button = screen.getByTestId("create");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.CREATE}?language=en`,
        {
          ...payload,
          status: "publish",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("edit", async () => {
    const button = screen.getByTestId("edit");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}/${ENDPOINTS.UPDATE}?language=en`,
        {
          ...payload,
          status: "publish",
          _method: "put",
          language: "en",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("addLinks", async () => {
    const button = screen.getByTestId("addLinks");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}/${ENDPOINTS.ADD_LINKS}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("uploadFiles", async () => {
    const button = screen.getByTestId("uploadFiles");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}/${ENDPOINTS.UPLOAD}?language=en`,
        {},
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("deleteMedia", async () => {
    const button = screen.getByTestId("deleteMedia");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}/${ENDPOINTS.MEDIA}?language=en&media_id=1&type=1`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("fetchResourceSelectList", async () => {
    const button = screen.getByTestId("fetchResourceSelectList");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${
          isC ? ENDPOINTS.RESOURCE_COLLECTION : ENDPOINTS.RESOURCE_MODULE
        }/${ENDPOINTS.GET_LIST}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("aiCreateResourceModulePreviews", async () => {
    const button = screen.getByTestId("aiCreateResourceModulePreviews");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}/${ENDPOINTS.PREVIEW}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": "application/json",
        },
        expect.any(Function)
      )
    );
  });

  test("aiCreateResourceModule", async () => {
    const button = screen.getByTestId("aiCreateResourceModule");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}?language=en`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": "application/json",
        },
        expect.any(Function)
      )
    );
  });

  test("createResourceModuleGo1", async () => {
    const button = screen.getByTestId("createResourceModuleGo1");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.GO1}/${ENDPOINTS.RESOURCE}/${ENDPOINTS.CREATE}?language=en`,
        {},
        {
          ...headers,
          "Content-Type": "application/json",
        },
        expect.any(Function)
      )
    );
  });

  test("uploadScormFile", async () => {
    const button = screen.getByTestId("uploadScormFile");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.SCORM}/${ENDPOINTS.SCORM_UPLOAD}/${resourcesId}`,
        { language: "en" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("deleteScorm", async () => {
    const button = screen.getByTestId("deleteScorm");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.SCORM}/${resourcesId}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("fetchPlayerUrl", async () => {
    const button = screen.getByTestId("fetchPlayerUrl");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.RESOURCE_MODULE}/${ENDPOINTS.SCORM}/${ENDPOINTS.SCORM_PLAYER_URL}/${id}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("moduleVisit", async () => {
    const button = screen.getByTestId("moduleVisit");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_MODULE}/${resourcesId}/${ENDPOINTS.MODULE_VISIT}/${assetId}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("fetchResourceGroupSelectList", async () => {
    const button = screen.getByTestId("fetchResourceGroupSelectList");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_GROUP}/${ENDPOINTS.GET_LIST}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("clone", async () => {
    const button = screen.getByTestId("clone");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${
          isC
            ? ENDPOINTS.RESOURCE_COLLECTION
            : isG
            ? ENDPOINTS.RESOURCE_GROUP
            : ENDPOINTS.RESOURCE_MODULE
        }/${resourcesId}/${ENDPOINTS.CLONE}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });
});
