import { Chip, Grid, Icon } from "@mui/material";
import Card from "components/Cards/Card";
import SecureImage from "components/UI/atoms/SecureImage";
import useRedirect from "helpers/hooks/useRedirect";
import { getDefaultImageByType } from "components/Cards/MainProductCard";
import { COMPONENT_TYPES } from "helpers/utils/constants";

const ProductCard = ({
  title,
  subTitle,
  iconsAndValuesList,
  skills,
  image,
  classes,
  type,
  slug,
  t = (d) => d,
}) => {
  const { navigateLab, navigateChallenge, navigateProject } = useRedirect();
  return (
    <Card
      classes={`${classes} flex-column items-stetch px-2 py-2 overflowX-hidden `}
    >
      <div className="mx-auto w-full mb-2 h-160 overflow-hidden imageContainer mr-3">
        <SecureImage
          src={image}
          placeholder={getDefaultImageByType(
            type === "lab" ? COMPONENT_TYPES.LAB : COMPONENT_TYPES.CHALLENGE
          )}
          classes="w-full h-full border rounded-5"
          title={title}
        />
      </div>
      <h3
        title={title}
        className="link fw-600 fs-19 text-truncate"
        onClick={
          type === "lab"
            ? () => navigateLab(slug)
            : type === "challenge"
            ? () => navigateChallenge(slug)
            : () => navigateProject(slug)
        }
      >
        {title}
      </h3>
      <p className="fw-500 fs-16 fs-14--mob text-truncate">{subTitle}</p>
      {!!iconsAndValuesList ? (
        <Grid
          container
          mt={1}
          display="flex"
          flexWrap="wrap"
          alignItems="flex-start"
          columnGap={2}
          className="minh-50"
        >
          {iconsAndValuesList?.map(
            ({ icon, img, value, onClick, iconProps = {} }, i) => (
              <Grid key={i} className="flex items-center tracking-tight mb-1">
                {!!icon ? (
                  <Icon
                    component={icon}
                    fontSize="small"
                    className="c-primary cursor-pointer"
                    {...iconProps}
                    onClick={onClick}
                  />
                ) : !!img ? (
                  <img src={img} className="object-cover w-20 h-20" alt="" />
                ) : null}

                {value ? <p className="ml-2 fs-12">{value} </p> : null}
              </Grid>
            )
          )}
        </Grid>
      ) : null}

      <div className="flex mt-2 mb-1">
        {skills.length > 0 ? (
          <>
            {skills.slice(0, 2).map((skill, i) => (
              <Chip
                sx={{
                  maxWidth: 110,
                  ".MuiChip-label": {
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  },
                }}
                key={i}
                variant="filled"
                label={skill}
                fontSize="small"
                className="mr-1 mt-1 fs-10 h-24"
              />
            ))}
            {skills.length > 2 && (
              <Chip
                variant="filled"
                label={`+${skills.length - 2} ${t("more", "common")}`}
                fontSize="small"
                className="ml-1 mt-1 fs-10 h-24"
              />
            )}
          </>
        ) : (
          <Chip
            variant="filled"
            label="N/A"
            fontSize="small"
            className="w-60 mt-1 fs-10 h-24"
          />
        )}
      </div>
    </Card>
  );
};
export default ProductCard;
