import DashboardLayout from "pages/layouts/dashboard";
import React from "react";
import BrowseTeams from "./listing/BrowseTeams";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const TeamMatching = () => {
  const { t } = useLocalizedTranslation();

  return (
    <DashboardLayout>
      <BrowseTeams t={t}/>
    </DashboardLayout>
  );
};

export default TeamMatching;
