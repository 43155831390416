import { Grid, Pagination } from "@mui/material";
import Card from "components/Cards/Card";
import Divider from "components/Divider";
import React, { useEffect, useState } from "react";
import Spin from "components/Spin";
import SecureImage from "components/UI/atoms/SecureImage";
import goldCupTrophy from "assets/png/goldTrophy.png";

function AchievementDetails({
  slug,
  fetchAchievementDetails,
  noDataUi = "No data found!",
  t = (d) => d,
}) {
  const [page, setPage] = useState(1);

  const [data, setData] = useState();
  const [isLoading, setIsloading] = useState(true);

  const fetchAchievements = async () => {
    if (slug) {
      const response = await fetchAchievementDetails(slug, page);
      if (response) {
        setData(response);
      }
    }
    setIsloading(false);
  };

  useEffect(() => {
    fetchAchievements();
  }, [page]);

  return (
    <Card title="Achievement Details">
      <div
        className="flex mb-4 items-center"
        style={{ justifyContent: "space-between" }}
      >
        <h4 className="bold fs-16">{t("Achievement Details")}</h4>
      </div>
      {isLoading ? (
        <Grid item xs={12}>
          <div className={"h-200 w-full flex justify-center items-center"}>
            <Spin blue big />
          </div>
        </Grid>
      ) : (
        ""
      )}
      {!isLoading && (
        <Grid
          container
          spacing={2}
          className="mb-4"
          justifyContent="center"
          columnGap={15}
        >
          <Grid item xs={12} md={2}>
            <p className={"bold text-center fs-16"}>{data?.meta?.total ?? 0}</p>
            <p className={"text-center fs-12"}>{t("Badge(s)")}</p>
          </Grid>
          <Grid item xs={12} md={2}>
            <p className={"bold text-center fs-16"}>
              {data?.meta?.total_points ?? "0"}
            </p>
            <p className={"text-center fs-12"}>{t("Point(s)")}</p>
          </Grid>
        </Grid>
      )}
      {!isLoading && data?.data?.length ? (
        <>
          {data?.data?.map((item) => (
            <div key={item.id} className="flex gap-10 border-b py-2">
              <SecureImage
                src={item?.achievement_image ?? ""}
                width={70}
                height={70}
                alt="Participant"
                classes="rounded-100 border"
                placeholder={goldCupTrophy}
              />
              <div>
                <p className="fw-600 fs-16 mb-1">{item?.achievement_name}</p>
                <p className="fs-14">
                  {item?.achievement_prize && <>{item?.achievement_prize}</>}

                  {item?.achievement_prize && item?.achievement_points && (
                    <span>&nbsp; . &nbsp;</span>
                  )}

                  {item?.achievement_points && (
                    <>
                      {item?.achievement_points} {t("Prepr points", "common")}
                    </>
                  )}
                </p>
              </div>
            </div>
          ))}
          <Pagination
            className={"mt-3"}
            classes={{
              ul: "justify-end",
            }}
            size={"small"}
            count={data?.meta?.total_pages}
            onChange={(e, newValue) => setPage(newValue)}
            page={page}
          />
        </>
      ) : null}
      {!isLoading && !data?.data?.length && (
        <Grid item xs={12} className={"flex justify-center my-2"}>
          {noDataUi ?? t("No data found!")}
        </Grid>
      )}
    </Card>
  );
}

export default AchievementDetails;
