import { facebook, linkedin, twitter } from "assets/svg/social";
import { getCurrOrigin } from "helpers/utils/utilities";

export const footer = {
  labs: [
    {
      title: "Digital Capability Labs",
      path: "https://prod.learnlab.ai/lab-program/digital-capability-labs",
    },
    {
      title: "Sustainable Innovation",
      path: "https://prod.learnlab.ai/lab/sustainability-lab",
    },
    {
      title: "Future of Industries",
      path: "https://prod.learnlab.ai/lab/industry-future-challenge-20202021-lab",
    },
    {
      title: "Diversity and Inclusion",
      path: "https://prod.learnlab.ai/lab/diversity-and-inclusion-lab",
    },
    {
      title: "P.I.E. - Project Leadership",
      path: "https://prod.learnlab.ai/lab/pie-project-leadership-lab",
    },
    {
      title: "P.I.E. - Innovation",
      path: "https://prod.learnlab.ai/lab/pie-innovation-lab",
    },
    {
      title: "P.I.E. - Entrepreneurship",
      path: "https://prod.learnlab.ai/lab/pie-entrepreneurship-lab",
    },
    {
      title: "Career Planning",
      path: "https://prod.learnlab.ai/lab/career-planning-lab",
    },
  ],
  quickLinks: [
    { title: "Help Center", path: "https://intercom.help/preprme/en/" },
    {
      title: "Partner with Prepr",
      path: "https://prepr.org/strategic-partners/",
    },
    { title: "Get Involved", path: "https://prepr.org/get-involved/" },
    {
      title: "Volunteer Opportunities",
      path: "https://prepr.org/get-involved/volunteer/",
    },
    {
      title: "Corporate Social Responsibility",
      path: "https://prepr.org/get-involved/csr/",
    },
  ],
  socialLinks: [
    {
      icon: linkedin,
      path: "https://www.linkedin.com/company/prepr-foundation",
    },
    { icon: twitter, path: "https://twitter.com/preprme" },
    { icon: facebook, path: "https://www.facebook.com/preprme" },
  ],
  partners: [
    {
      icon: "https://preprlabs.org/assets/images/orgs/logoAIMS.jpg",
      path: "#",
    },

    {
      icon: "https://preprlabs.org/assets/images/orgs/aws.svg",
      path: "#",
    },
    {
      icon: "https://preprlabs.org/assets/images/orgs/magnet.svg",
      path: "#",
    },

    {
      icon: "https://preprlabs.org/assets/images/orgs/linux.svg",
      path: "#",
    },
    {
      icon: "https://preprlabs.org/assets/images/orgs/LaurierUniversity.jpg",
      path: "#",
    },

    {
      icon: "https://preprlabs.org/assets/images/orgs/ontario.svg",
      path: "#",
    },
    {
      icon: "https://preprlabs.org/assets/images/orgs/CareerFoundation.jpg",
      path: "#",
    },

    {
      icon: "https://preprlabs.org/assets/images/orgs/ua_logo.png",
      path: "#",
    },
  ],
};

export const POLICY = {
  lastRevisedDate: "September 13, 2019",
  title: "Privacy policy",
  subTitle: "PREPR FOUNDATION Privacy Statement",
  overview: {
    p: [
      "At PREPR Foundation ('<b>PREPR</b>'), we are committed to protecting your privacy and safeguarding your personal information.",
      "The purpose of this Privacy Statement is to inform you about the types of Personal Information PREPR (as follows, <b>“we”</b> or <b>“us”</b>) collects, uses and discloses. It explains how we use and disclose that information, the choices you have regarding such use and disclosure, and how you may correct that information.",
      "Your privacy is one of our priorities.  We are proud to demonstrate our commitment to your privacy, by complying with the laws and regulations under applicable privacy laws in Canada. As we are a national organization, this Privacy Statement is designed to meet the standards prescribed by the Personal Information Protection and Electronic Documents Act and the regulations thereunder (<b>“PIPEDA”</b>).",
      "From time to time, we may make changes to this Privacy Statement. The Privacy Statement is current as of the “last revised” date which appears at the top of this page. We will treat Personal Information in a manner consistent with the Privacy Statement under which it was collected, unless we have your consent to treat it differently. This Privacy Statement applies to any information we collect or receive about you, from any source.",
    ],
    list: {
      description:
        "The following topics will be covered in this Privacy Statement:",
      items: [
        "To which companies does this Privacy Statement apply?",
        "What is Personal Information?",
        "How do we collect your Personal Information (and how do we use cookies)?",
        "Where do we store your Personal Information?",
        "How do we use your Personal Information?",
        "To whom do we provide your Personal Information?",
        "When and how do we obtain your consent?",
        "How do we ensure the privacy of your Personal Information when dealing with our affiliates and other third parties?",
        "How long will we utilize, disclose or retain your Personal Information?",
        "How can you review your Personal Information that we have collected, utilized or disclosed?",
        "How do you know that the Personal Information we have on you is accurate?",
        "What if the Personal Information we have on you is inaccurate?",
        "How fast will we respond to your written requests?",
        "Are there any costs to you for requesting information about your Personal Information or our privacy practices?",
        "How do we know that it is really you requesting your Personal Information?",
        "What safeguards have we implemented to protect your Personal Information?",
        "How do you contact us regarding access to your Personal Information or our privacy practices?",
      ],
    },
  },
  questions: [
    {
      question: "To which companies does this Privacy Statement apply?",
      answer:
        "This Privacy Statement applies to the collection, use and disclosure of Personal Information by PREPR FOUNDATION AND PREPR INC.",
    },
    {
      question: "What is Personal Information?",
      answer:
        '"Personal Information" is any information that is identifiable with you, as an individual. This information may include (among other information) your name, mailing address, e-mail address, billing address and credit card information. Personal Information, however, does not include any information that is solely used to communicate with you in relation to your employment, business or profession, such as your name, position name or title, work address, work telephone number, or work electronic address.',
    },
    {
      question:
        "How do we collect your Personal Information (and how do we use cookies)?",
      answer:
        "We will always collect your Personal Information by fair and lawful means (for example, when you register with us to use the platform, obtain our products or services and/or participate in our events, including hackathons and challenges). We may collect Personal Information from you directly and/or from third parties, where we have obtained your consent to do so or as otherwise required or permitted by law. We may collect standard internet log information about how and when you use our website/platform. This information may include but is not limited to your IP address and location, data, weblogs, time, date and browser used, referring web addresses, other communication data, searches conducted and pages visited. In addition, to ensure that our website is optimized for ease of use, we or our service provider(s) may use cookies to collect standard information about your use of our site, including your language preferences, your browser type and version, and similar information. These cookies are intended to optimize your experience of our website.",
    },
    {
      question: "Where do we store your Personal Information?",
      answer:
        "We will keep the Personal Information that we collect at the PREPR offices or at the offices of our third-party service providers, both in Canada and the United States as applicable.",
    },
    {
      question: "How do we use your Personal Information?",
      answer:
        "Generally, we identify the purposes for which we use your Personal Information at the time we collect such information from you and obtain your consent, in any case, prior to such use. We generally use your Personal Information for purposes such as providing requested products and services, managing your account with PREPR, responding to questions, and using Google Analytics to understand web traffic.",
    },
    {
      question: "To whom do we provide your Personal Information?",
      answer:
        "We may transfer your Personal Information to third-party service providers with whom we have a contractual agreement that includes appropriate privacy standards. These providers assist us with purposes such as telephone support, data storage, or hosting. Information sent outside the country is subject to the laws of that country.",
    },
    {
      question: "When and how do we obtain your consent?",
      answer:
        "We generally obtain your consent prior to collecting, using, or disclosing your Personal Information for any purpose. You may provide consent orally, electronically, or in writing. The form of consent depends on the sensitivity of the information and the reasonable expectations in the circumstances.",
    },
    {
      question:
        "How do we ensure the privacy of your Personal Information when dealing with our affiliates and other third parties?",
      answer:
        "We ensure that all affiliates and third parties engaged to perform services on our behalf and provided with Personal Information are contractually required to observe this Privacy Statement and our privacy practices.",
    },
    {
      question:
        "How long will we utilize, disclose or retain your Personal Information?",
      answer:
        "We will utilize, disclose or retain your Personal Information for as long as necessary to fulfill the purposes for which it was collected and as permitted or required by law.",
    },
    {
      question:
        "How can you review your Personal Information that we have collected, utilized or disclosed?",
      answer:
        "If you make a written request to review any Personal Information about you that we have collected, utilized or disclosed, we will provide it to the extent required by law. We will make it available in a generally understandable form and explain any abbreviations or codes.",
    },
    {
      question:
        "How do you know that the Personal Information we have on you is accurate?",
      answer:
        "We ensure your Personal Information is kept as accurate, complete, and up-to-date as possible. We rely on you to supply updates when required.",
    },
    {
      question:
        "What if the Personal Information we have on you is inaccurate?",
      answer:
        "You can challenge the accuracy or completeness of your Personal Information in our records. If proven inaccurate, we will amend the information and, where appropriate, transmit the amendments to third parties.",
    },
    {
      question: "How fast will we respond to your written requests?",
      answer:
        "We will attempt to respond to written requests within thirty (30) days. If we cannot meet this timeline, we will notify you in writing. You can make a complaint to the Privacy Commissioner of Canada or applicable provincial privacy commissioner regarding the time limit.",
    },
    {
      question:
        "Are there any costs to you for requesting information about your Personal Information or our privacy practices?",
      answer:
        "We will not charge costs for accessing your Personal Information without first providing an estimate of the approximate costs, if any.",
    },
    {
      question:
        "How do we know that it is really you requesting your Personal Information?",
      answer:
        "We may request sufficient identification to permit access to the existence, use, or disclosure of your Personal Information. Identifying information will only be used for this purpose.",
    },
    {
      question:
        "What safeguards have we implemented to protect your Personal Information?",
      answer:
        "We have implemented physical, organizational, contractual, and technological security measures to protect your Personal Information from loss, theft, unauthorized access, disclosure, copying, use, or modification. Only employees and contractors with a business 'need-to-know' are granted access.",
    },
  ],
};

export const TERMS = {
  lastRevisedDate: "January 31, 2019",
  title: "Terms and Conditions",
  content: (
    <>
      <p>
        PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. THESE TERMS AND
        CONDITIONS GOVERN YOUR USE OF AND ACCESS TO THIS PREPR FOUNDATION
        WEBSITE (THE "<b>PLATFORM</b>") AND THE GOODS AND SERVICES PROVIDED
        THROUGH THIS PLATFORM (THE "<b>SERVICES</b>") AND THE MESSAGES,
        INFORMATION, DATA, TEXT, SOFTWARE OR IMAGES, OR OTHER CONTENT FROM THIS
        PLATFORM (COLLECTIVELY THE "<b>CONTENT</b>"). IT EXEMPTS PREPR
        FOUNDATION ("<b>PREPR</b>") AND OTHERS FROM LIABILITY AND/OR LIMITS
        THEIR LIABILITY AND CONTAINS OTHER IMPORTANT PROVISIONS THAT YOU MUST
        READ AND ACCEPT BEFORE USING THIS PLATFORM. THIS PLATFORM IS PROVIDED BY
        PREPR SOLELY FOR THE LIMITED PURPOSES IDENTIFIED IN THESE TERMS OF USE
        AND ITS USE BY YOU IS CONDITIONAL ON YOUR ACCEPTANCE OF THE TERMS AND
        CONDITIONS OF USE SET FORTH BELOW (THE "<b>TERMS AND CONDITIONS</b>").
      </p>
      <br />
      <p>
        <b>1. Your Access to this Platform</b>
        <br />
        By visiting or using this Platform, including without limitation to (i)
        access, use, and/or download Content, (ii) send messages, information,
        data, text, software or images, or other Content to this Platform, (iii)
        otherwise use, access or purchase any of the Services, or (iv) purchase
        any of the products available for sale through this Platform (the "
        <b>Products</b>") you agree on your own behalf and on behalf of any
        entity on whose behalf you may act, to accept and abide by these Terms
        and Conditions for each use of and each visit to this Platform. If you
        do not agree to abide by these Terms and Conditions, do not use this
        Platform.
      </p>
      <br />
      <p>
        Prepr has the right, in its sole discretion, to add to, remove, modify
        or otherwise change any part of these Terms and Conditions, in whole or
        in part, at any time. Changes will be effective when notice of such
        change is posted at this Platform.  It is your responsibility to check
        these Terms and Conditions regularly to determine whether any changes
        have been made, please check these; Terms and Conditions frequently for
        updates by checking the date of the “Last Update” at the top of this
        document.  If any term, condition or any change to these Terms and
        Conditions is not acceptable to you, you must discontinue your use of
        this Platform immediately.  Your continued use of this Platform after
        any such changes are posted will constitute acceptance of those
        changes.  These Terms and Conditions apply exclusively to your use of
        this Platform and do not alter the terms or conditions of any other
        agreement you may have with Prepr (including your Prepr Account
        Agreement, if any).
      </p>
      <br />
      <p>
        Prepr may, at any time without notice or liability, and for any reason
        whatsoever, terminate, change, suspend or discontinue any aspect of this
        Platform, including (i) changing the availability of, restricting access
        to, or imposing limits on any or all features or Services on, or links
        to, this Platform (ii) adding, removing or changing any fees or charges
        for use of this Platform or any features of this Platform; (iii)
        removing, adding, modifying or otherwise changing any Content or
        Services on this Platform.  Prepr reserves the right, in its sole
        discretion, to correct any errors or omissions in any portion of this
        Platform at any time without notice, but confirms that it has no duty to
        do so.  Prepr and its service providers will also have the rights to
        terminate your use of this site that are set out below under the heading
        “Termination of Use”.
      </p>
      <br />
      <p>
        You agree to have these Terms and Conditions and any related information
        made available to you, and to otherwise have communications between you
        and Prepr occur, electronically.
      </p>
      <br />
      <p>
        <b>2. Limited License</b>
        <br />
        You are granted a non-exclusive, non-transferable, non-sub-licensable,
        revocable, limited license to display on your computer, print and
        download and use the Content of this Platform only for informational
        purposes and for the purposes identified in the description and/or
        specific terms and conditions of use, if any, of each of the Services
        made available through this Platform and solely for your own personal or
        internal company use. You may not modify any Content that you use. You
        must include with any display or copy of the Content its copyright
        notice (that is, the line at the bottom of this page).  No other use is
        permitted.
      </p>
      <br />
      <p>
        All rights not expressly granted by these Terms and Conditions are
        reserved to Prepr.
      </p>
      <br />
      <p>
        <b>3. Restrictions:</b>
        <br />
        You may not:
      </p>
      <p>
        (a)&nbsp;&nbsp;resell any Content (excluding Products and Services that
        are purchased or licensed from Prepr pursuant to a Transaction Agreement
        (as described below));
      </p>
      <p>
        (b) &nbsp;&nbsp;include any Content in or with any product that you
        create or distribute;
      </p>
      <p>
        (c) &nbsp;&nbsp;copy any Content onto your own or any other website;
      </p>
      <p>
        (d) &nbsp;&nbsp;use this Platform or the Services in any manner that
        could damage, disable, overburden, impair, interfere with the security
        of, negatively affect the functioning of, or otherwise abuse this
        Platform or any services, system resources, accounts, servers, networks,
        affiliated or linked sites, connected to or accessible through this
        Platform (including without limitation uploading, posting or otherwise
        transmitting on this Platform computer viruses, Trojan horses, worms or
        other files or computer programs which are potentially harmful,
        disruptive or destructive or that may impose an unreasonable or
        disproportionately large load on this Platform's infrastructure; or
        using any robot, spider or other automatic program or device, or manual
        process to monitor, copy, summarize, or otherwise extract information
        from this Platform or the Content in whole or in part);
      </p>
      <p>
        (e)&nbsp;&nbsp;use this Platform or the Services in a manner that
        infringes upon the legal rights of any other person (including privacy
        and personality rights, copyright, moral rights, and other intellectual
        property rights);
      </p>
      <p>
        (f)&nbsp;&nbsp;use this Platform or the Services in any data matching or
        data mining, including without limitation the collection or use of
        information about other users of the Services (including their email
        addresses) without their consent;
      </p>
      <p>
        (g)&nbsp;&nbsp;use this Platform or the Services in any manner that is
        unlawful (including by accessing this Platform or the Services from any
        location where such access may be illegal or prohibited), unethical,
        indecent, defame, derogatory, fraudulent, deceptive, harassing, abusive,
        invasive of the privacy rights of others (this includes the posting of
        any materials that depict, encourage, indicate, advocate or tend to
        incite any such conduct), or is otherwise objectionable or which does
        not respect the legal rights and interests of others;
      </p>
      <p>
        (h)&nbsp;&nbsp;post any materials to this Platform or the Services which
        would violate any of the restrictions set out in these Terms and
        Conditions;
      </p>
      <p>
        (i)&nbsp;&nbsp;use this Platform for commercial purposes or activities,
        including, without limitation:
      </p>
      <p>(i)&nbsp;&nbsp;selling or offering to sell any goods or services;</p>
      <p>(ii) &nbsp;&nbsp;soliciting for advertisers or sponsors;</p>
      <p>
        (iii)&nbsp;&nbsp;conducting contests, gaming or gambling or offering
        prizes, awards or any other incentives to any person;
      </p>
      <p>
        (iv)&nbsp;&nbsp;displaying advertising or sponsorship banners, including
        those generated by banner or link exchange services;
      </p>
      <p>(v)&nbsp;&nbsp;soliciting for donations; or</p>
      <p>
        (vi)&nbsp;&nbsp;use of unauthorized or unsolicited junk mail, spam,
        chain letters, pyramid schemes or any other form of solicitation;
      </p>
      <p>
        (k) use this Platform or Services in any manner that may:
        <ul>
          <li>
            harm, threaten, harass, abuse or intimidate any other person in any
            way or involve materials that depict, promote, encourage, indicate,
            advocate or tend to incite the commission of a crime or other
            unlawful activities, violence, hatred, cruelty or discrimination
            against any individuals or groups, for any reason, or any act of
            cruelty to animals;
          </li>
          <li>
            dilute or depreciate the name and reputation of Prepr, its Marks (as
            defined below) or its affiliates or associates;
          </li>
          <li>
            interfere with any other persons' use and enjoyment of this
            Platform, the Services or of the Internet generally;
          </li>
          <li>
            result in the circumvention or breach of any user authentication,
            password, security or control measures regarding this Platform or
            the Services or any other internet resource or computer system;
          </li>
          <li>
            result in the unauthorized use of other persons' Prepr Accounts and
            materials (including materials posted to this Platform or in
            connection with any Services) or interfere with any other persons'
            use and enjoyment of this Platform or any Services;
          </li>
          <li>
            conceal or misrepresent the author or origin of any messages or
            communication, or that impersonate any person, or that falsely state
            or otherwise misrepresent an affiliation with any other person,
            including without limitation any Prepr representative;
          </li>
          <li>
            disclose images of any person or private information about any
            person (such as names, telephone numbers, email addresses, postal
            addresses, social insurance number) without that person’s
            permission.
          </li>
        </ul>
      </p>
      <br />
      <p>
        <b>4. Proprietary Rights</b>
        <br />
        This site and the Content are protected by Canadian and worldwide
        copyright laws and treaty provisions. Any unauthorized copying,
        redistribution, reproduction or modification of this Platform or the
        Content by any person may be a violation of trade-mark and/or copyright
        laws and could subject such person to legal action.  You agree to comply
        with all copyright laws worldwide in your use of this Platform and the
        Services and to prevent any unauthorized copying of the Content.
        <br />
        <br />
        Certain names, graphics, logos, icons, designs, words, titles and
        phrases on this Platform, including without limitation “Prepr” and
        “PIE”, constitute trade-marks, trade-names, trade dress and/or
        associated products and services of Prepr or its affiliates (the
        “Marks”), and are protected in Canada and internationally and their
        display on this Platform does not convey or create any licence or other
        rights in the Marks.  Any use of any of the Marks, in whole or in part,
        or of the trade-marks, trade-names, trade dress and/or associated
        products and services of any Prepr supplier or other third party that
        are accessible via the Platform without prior written authorization of
        Prepr or such third party is strictly prohibited.
        <br />
        <br />
        Other trade-marks, trade names, trade dress and associated products and
        services mentioned on this Platform may be the trademarks of their
        respective owners.  The display of these trade-marks, trade names, trade
        dress and associated products and services on this Platform does not
        convey or create any licence or other rights in these trademarks or
        trade names.  Any unauthorized use of them is strictly prohibited.
      </p>
      <br />
      <p>
        <b>5. Linked Platforms and Framing</b>
        <br />
        Certain links on this Platform may take you to other Platforms. Prepr
        provides these links only as a convenience.  Prepr is not responsible
        for the content of any such linked pages. Prepr makes no representation
        or warranty regarding, and does not endorse, any linked Platforms, the
        information appearing thereon or any of the products or services
        described.  If you use these sites, you will leave this Platform.  If
        you decide to visit any linked site, you do so at your own risk and it
        is your responsibility to take all protective measures to guard against
        viruses and other destructive elements.
        <br />
        <br />
        Prepr welcomes links to this Platform.  However, you agree that if you
        link to this Platform your website shall not:
        <br />
        <ul>
          <li>
            create frames around any part of this Platform or use other
            techniques that alter the visual presentation of this Platform;
          </li>
          <li>
            imply that Prepr is endorsing you or any other person, or your or
            such other person’s products or services;
          </li>
          <li>
            imply an affiliation between you or any other person, or your or
            such other person’s products or services, and Prepr without the
            prior written consent of Prepr;
          </li>
          <li>
            misrepresent the relationship of you or any other person with Prepr
            or present false, misleading or otherwise damaging information or
            impressions about Prepr or any of its products or services; or
          </li>
          <li>
            contain materials that may be interpreted as distasteful, harmful,
            offensive, or inaccurate.
          </li>
        </ul>
      </p>
      <br />
      <p>
        <b>6. Privacy Policy</b>
        <br />
        Any of your personal information which Prepr collects via this Platform
        or the Services is subject to the Prepr Foundation Privacy Statement,
        which is incorporated by reference into these Terms and Conditions.
      </p>
      <br />
      <p>
        <b>
          7. User Communications and User Submissions (solicited and
          unsolicited)
        </b>
        <br />
        All information that you provide through this Platform or in connection
        with any Services, including your registration information (legal name
        and email addresses) must be true, accurate, current and complete. You
        must also contact Prepr with updated registration information promptly
        upon the occurrence of any change in such information.
      </p>
      <br />
      <p>
        Prepr and its service providers will rely on the information you
        provide. You will be responsible for any and all losses, damage, or
        additional costs that you, Prepr or its service providers or others may
        incur as a result of your submission of any false, incorrect or
        incomplete information or your failure to update your registration
        information promptly upon the occurrence of any change in such
        information. Prepr may require a copy of a government-issued form of
        identification before making any changes to your registration
        information or payment information.
      </p>
      <br />
      <p>
        Subject to any applicable law, the requirements of the Prepr Foundation
        &nbsp;
        <a
          className="c-primary"
          style={{ textDecoration: "none" }}
          href={`${getCurrOrigin()}/privacy-policy`}
          target="blank"
        >
          Privacy Statement
        </a>
        &nbsp;, and any applicable contest rules and Transaction Agreements:
      </p>
      <br />
      <p>
        any communications sent by or to you via this Platform or otherwise to
        Prepr, whether solicited by Prepr or otherwise, are on a
        non-confidential basis (other than personal information which is covered
        under the Prepr Foundation Privacy Policy), and Prepr is under no
        obligation to refrain from reproducing, publishing or otherwise using
        them in any way or for any purpose. Prepr shall be free to use and
        disclose the content of any such communication, including without
        limitation any ideas, inventions, concepts, techniques or know-how
        disclosed therein, for any purpose including without limitation
        developing, manufacturing and/or marketing goods and services. You agree
        to not assert any ownership right of any kind in such communications
        (including without limitation copyright, trade-mark, patent, unfair
        competition, moral rights, or implied contract) and you hereby waive
        such moral rights in favour of Prepr as well as the right to receive any
        financial or other consideration in connection with such communication.
      </p>
      <br />
      <p>
        Prepr does not accept or consider unsolicited ideas, including ideas for
        new advertising campaigns, new promotions, new or improved goods,
        services or technologies, product enhancements, processes, materials,
        marketing plans, or new product names. The purpose of this Prepr policy
        is to avoid potential misunderstandings or disputes. Accordingly, please
        do not send any unsolicited ideas, suggestions or other materials
        ("Submissions") to Prepr.
      </p>
      <br />
      <p>
        However, if you do send Submissions to Prepr or this Platform you
        automatically grant to Prepr and its successors and assigns a perpetual,
        royalty-free, irrevocable, unrestricted, non-exclusive, world-wide,
        right and license to use, copy, reproduce, modify, adapt, publish,
        translate, create derivative works from, distribute, perform, display
        and otherwise exploit the Submissions or any ideas, concepts, know-how
        or techniques associated with the Submissions for any purpose
        whatsoever, commercial or otherwise, using any form, media or technology
        now known or later developed, without providing compensation to you or
        any anyone else, without any liability whatsoever, and free from any
        obligation of confidence or other duties on the part of Prepr or its
        successors or assigns, and you agree, represent and warrant that you
        shall waive all moral rights (including the right to be associated with
        the Submission) in the Submissions upon their submission to Prepr or
        this website.
      </p>
      <br />
      <p>
        <b>8. Additional Terms</b>
        <br />
        Prepr is not obliged to monitor, screen, police or edit the use of the
        Platform or the Services, including postings of materials to this
        Platform, although Prepr reserves the right to do so in its sole
        discretion. Prepr will respond as it considers appropriate, in its sole
        discretion, if Prepr becomes aware of any inappropriate uses of the
        Platform or Services, including without limitation uses that constitute
        copyright infringement.
      </p>
      <br />
      <p>
        1. &nbsp;&nbsp;a) Fees and Charges, Availability, Prices, Misprints and
        Errors, and Orders
        <br />
        You are responsible for paying all fees and charges associated with
        using this Platform and the Services, including any applicable taxes.
        All amounts appearing on this Platform are quoted in Canadian dollars.
      </p>
      <p>
        Prepr reserves the right to change the prices, fees and charges
        associated with this Platform and the Services and the products
        available through this Platform and the Services at any time and from
        time to time without any notice or liability to you or any other person.
        Also, due to the popularity of some Products or Services, Prepr cannot
        guarantee that goods or services advertised on this Platform will be
        available when ordered or thereafter. Prepr also reserves the right at
        any time to reject, correct, cancel or terminate any order. If you order
        Products or Services for which the price was incorrectly displayed,
        Prepr will provide you with an opportunity to cancel your order. If your
        order goods or services that are not available, Prepr will notify you by
        email. Prepr reserves the right to limit quantities licensed or sold.
      </p>
      <br />
      <p>
        1. &nbsp;&nbsp;b) Specials/Promotions/ Sweepstakes/Contests
        <br />
        From time to time Prepr may make contests available to the public or to
        registered users of this Platform (including Prepr Account holders). All
        contests are governed by these Terms and Conditions, to the extent that
        they are applicable, and the specific applicable contest rules. By
        participating in a contest through this Platform, you signify your
        agreement and acceptance of these Terms and Conditions and the
        applicable contest rules.
      </p>
      <br />
      <p>
        1. &nbsp;&nbsp;c) Software Agreements
        <br />
        Any use by you of Prepr's PIE Education System will be subject to the
        proprietary software and will be governed by the PIE General &nbsp;
        <a
          className="c-primary"
          style={{ textDecoration: "none" }}
          href={`${getCurrOrigin()}/terms`}
          target="blank"
        >
          Terms and Conditions
        </a>
        &nbsp; and such other parameters as agreed between you and Prepr
        concerning the scope of permitted use.
      </p>
      <br />
      <p>
        As a convenience to Prepr members, Prepr may make third-party software
        available for download from or through the Platform. All such software
        available for download from or through the Platform is owned or licensed
        by third parties, and the downloading and use of the software is subject
        to a third-party software license agreement. Prepr has no control over
        or responsibility or liability for any software downloaded from or
        through the Platform, and Prepr makes no representations, warranties or
        conditions regarding the software. Your downloading and use of the
        software and your dealings with the owners and providers of the software
        is at your own risk, and you may not make any claim against Prepr
        arising out of, connected with, or relating to your download and use of
        any such software.
      </p>
      <br />
      <p>
        1. &nbsp;&nbsp;d) Transaction Agreements
        <br />
        All transactions through the Website are governed by these Terms and
        Conditions and any specific applicable Transaction Agreement. By
        participating in a transaction through this Platform, you signify your
        agreement and acceptance of these Terms and Conditions and the
        applicable Transaction Agreement.
      </p>
      <br />
      <p>
        <b>9. Disclaimer</b>
        <br />
        Prepr makes reasonable efforts to ensure that the content is accurate at
        the time it is posted on this platform, however Prepr makes no
        representation or warranty of any kind regarding this platform. In this
        section 9, the "platform" shall include the content and the services and
        you acknowledge and agree that this platform (including without
        limitation the content and the services) is provided on an "as is" and
        as available basis; and that any use of or reliance on this platform
        shall be at your sole risk.
      </p>
      <br />
      <p>
        To the fullest extent permitted by law, prepr and its affiliates,
        agents, service providers, employees, officers, directors or
        representatives ("Prepr representatives"), do not make any, and
        expressly disclaim all representations, warranties, covenants and
        conditions, express or implied, by operation of law or otherwise,
        including without limitation: (i) any implied warranties and conditions
        of merchantability, merchantable quality, fitness for any particular
        purpose, or non-infringement; or any implied representations or
        warranties arising out of course of performance, course of dealing or
        usage of trade; (ii) that this platform will meet your requirements,
        will be compatible with your computer or related equipment, photograph
        equipment or software; (iii) that the content is accurate, reliable,
        authentic, current, or complete; or (iv) that this platform will
        continue to operate, operate without interruptions or be error-free.
      </p>
      <br />
      <p>
        In addition, prepr makes no representation or warranty that content at
        this platform is appropriate or available for use at any locations
        outside Canada. accessing the content from locations where their
        contents are illegal is prohibited. those who choose to access this
        platform from other locations do so on their own initiative and are
        responsible for compliance with local laws.
      </p>
      <br />
      <p>
        Your use of this platform including any content, materials, data or
        information posted, submitted, downloaded or otherwise obtained through
        this platform, is at your own risk. you should keep your own backup
        copies of all materials you may use, or allow others to use, in
        connection with this platform, including content and materials posted or
        submitted to this platform. you are solely responsible for provisioning,
        configuring and maintaining all equipment and software you use or allow
        others to use in connection with this platform and/or the services,
        including all computers and related equipment, photographic equipment,
        and software. you are solely responsible for, and bear all risks and
        liabilities associated with, all content and materials you may use or
        allow others to use in connection with this platform, including
        materials posted or submitted to this platform. you are solely liable
        and responsible for any and all claims and demands made by any other
        person arising out of, in connection with, or relating to your use of
        this platform, your breach of these terms and conditions, your violation
        or infringement of the rights of others, or your violation of any
        applicable civil or criminal law. you are solely responsible for the
        manner in which the materials you may use or allow others to use in
        connection with this platform, including content and materials posted or
        submitted to this platform, are used by other users of this platform.
        prepr disclaims any and all responsibility and liability regarding all
        such matters.
      </p>
      <br />
      <p>
        Prepr representatives are under no obligation to verify the identity of
        users of this platform or the services. prepr and its service providers
        do not control the materials posted or submitted to this platform and/or
        the services by persons other than themselves and do not monitor,
        screen, police or edit those materials for compliance with applicable
        laws or these terms and conditions. you may find some of the materials
        posted by other users to be offensive, harmful, inaccurate or deceptive.
        you should use caution and common sense when using this platform.
      </p>
      <br />
      <p>
        Through your use of this platform and/or the services, you may have the
        opportunity to engage in commercial transactions with third parties. all
        such transactions are at your own risk. prepr representatives are not
        parties to any such transactions and disclaim any and all liability
        regarding all such transactions.
      </p>
      <br />
      <p>
        <b>10. Limitation of Right Liability</b>
        <br />
        You agree that in no event will Prepr or any Prepr representatives have
        any responsibility or liability in connection with this platform to you
        or any other person or entity, for any loss or damages whatsoever,
        indirect, special, punitive, exemplary or consequential damages or other
        damages (including without limitation damages for harm to business, loss
        of information or programs or data, loss of profit, loss of savings,
        loss of revenue, loss of goodwill), arising from or in connection with
        the use of or access to, or any inconvenience, delay or loss of use of
        or access to, this platform, the services, the content, any content of
        any linked site, or failure of such sites, (including without limitation
        any damages suffered as a result of omissions or inaccuracies in such
        sites or content, or the transmission of confidential or sensitive
        information to or from such sites) even if Prepr or any Prepr
        representatives have been advised of the possibility of such damage or
        loss or if such damage or loss was foreseeable.
      </p>
      <br />
      <p>
        <b>11. Your Liability</b>
        <br />
        In addition, you agree that you are solely responsible for actions and
        communications undertaken or transmitted in the course of your usage of
        this Platform and the Services, and that you will comply with all laws
        that apply or may apply to your use of or activities on this Platform
        and the Services or in respect of the Content.  Prepr may investigate
        occurrences which may involve violations of such laws, and may involve,
        and co-operate with, law enforcement authorities in prosecuting users
        who are involved in such violations.  Prepr reserves the right at all
        times to disclose any information (including your personal information,
        account information payment information and materials posted to the
        Platform) regarding your usage of this Platform or the Services
        (including any perceived violations of applicable law) as necessary to
        satisfy any law, regulation or governmental request.
      </p>
      <br />
      <p>
        <b>12. Choice of law</b>
        <br />
        This Platform is controlled, operated and administered by Prepr from its
        offices within the Province of Ontario, Canada.  By accessing this
        Platform, you agree that all matters relating to the access to, or use
        of, this Platform shall be governed by the laws of the Province of
        Ontario and the federal laws of Canada applicable therein, without
        reference to its conflict of laws principles, and that you will comply
        with all such applicable laws.
      </p>
      <br />
      <p>
        Any dispute between Prepr and you or any other person arising from, in
        connection with or relating to this Platform, the Content or the
        Services, these Terms and Conditions, any transaction through the
        Platform or any related matters must be resolved before the Courts of
        the Province of Ontario, Canada sitting in the City of Toronto, and you
        hereby irrevocably submit and attorn to the exclusive jurisdiction of
        those Courts in respect of any such dispute.
      </p>
      <br />
      <p>
        Any claim or cause of action you may have arising from, in connection
        with, or relating to your use of this Website or any Services, these
        Terms and Conditions, any transaction through the Website or any related
        matters must be commenced in a court of competent jurisdiction in
        Toronto, Ontario, Canada within two (2) years after the claim or cause
        of action arises, after which time the claim or cause of action is
        forever banned, regardless of any statute or law to the contrary.
      </p>
      <br />
      <p>
        <b>13. Termination of Your Use</b>
        <br />
        If you breach any provision of these terms and conditions, then you may
        no longer use this Platform or the applicable Contents or Services that
        are made available through this Platform.  Prepr, in its discretion,
        shall determine whether these Terms and Conditions have been violated.
      </p>
      <br />
      <p>
        Prepr or its service providers may at any time and for any reason, with
        or without cause, and in their sole discretion, immediately:  (i)
        suspend or terminate (in whole or in part) your Prepr account or your
        authorization to use this Platform and/or the Services; (ii) suspend or
        terminate and permanently delete and destroy any user ID, password, URL,
        IP address or domain name, or materials that you or others may have
        posted or submitted to this Platform and/or the Services; (iii) remove
        from this Platform and/or the Services and permanently delete and
        destroy any materials that you or others may have posted or submitted;
        (iv) restrict access to your account and the materials posted or
        submitted to this Platform and/or the Services; and (v) bar you from any
        future use of this Platform and/or the Services; all without any prior
        notice or any liability to you or any other person.  You agree that upon
        the occurrence of any such event or if your use of the Platform, Service
        or Content is otherwise terminated, then you shall have no right to
        receive a refund of any fees and charges previously paid by you for
        unused services, and that these terms and conditions will continue to
        apply and be binding upon you in respect of your prior use of this
        Platform and/or the Services.
      </p>
      <br />
      <p>
        Prepr and its service providers are not required to assess or otherwise
        determine the validity or legitimacy of any complaints or demands that
        they may receive regarding any materials that you may use or allow
        others to use in connection with this Platform and/or the Services
        (including materials posted or submitted to this Platform and/or the
        Services) before Prepr and its service providers take any remedial
        action that they consider, in their sole discretion, to be appropriate.
      </p>
      <br />
      <p>
        <b>14. General</b>
        <br />
        These Terms and Conditions, along with each applicable Transaction
        Agreement, constitute the entire agreement between Prepr and you with
        respect to the subject matter hereof, and supersede all communications,
        representations or agreements, either oral or written, between Prepr or
        its affiliates and you with respect to this subject matter. If for any
        reason a court of competent jurisdiction finds any provision of these
        Terms and Conditions or portion thereof to be unlawful, void, or for any
        reason unenforceable, then that provision shall be enforced to the
        maximum extent permissible so as to effect the intent of these Terms and
        Conditions, and the remainder of these Terms and Conditions shall
        continue in full force and effect. Prepr’s failure to insist upon or
        enforce strict performance of any provision of these Terms and
        Conditions or any right shall not be construed as a waiver of any such
        provision or right.  You and Prepr are independent contractors, and no
        agency, partnership, joint venture, employment or franchise relationship
        is intended or created by these terms and conditions or your use of this
        Platform or any Services or Content.
      </p>
      <br />
      <p>
        The provisions of these terms and conditions will enure to the benefit
        of and be binding upon Prepr and its service providers and their
        respective successors and assigns, and you and your heirs, executors,
        administrators, successors and personal representatives.  You may not
        assign these terms and conditions or your rights and obligations under
        these terms and conditions without the express written consent of Prepr,
        which may be withheld in Prepr sole discretion. Prepr and its service
        providers may assign these terms and conditions and their respective
        rights and obligations under these terms and conditions without your
        consent.
      </p>
      <br />
      <p>
        <b>15. Notices</b>
        <br />
        Notices or communications to Prepr in connection with these Terms and
        Conditions must be in writing and must be sent to Prepr by courier or
        regular pre-paid post to Prepr Foundation, 720 Bathurst Street, Toronto,
        Ontario, M9A 4Z5 Attention: Salar Chagpar.  Notices to you from Prepr
        may be sent by either email to the email address supplied for your
        account or by courier or regular pre-paid post to the postal address
        supplied for your account.  Notices will be deemed to be received upon
        the earlier of: (a) two business days after deposit with a nationally
        recognized overnight courier; (b) ten business days after deposit in the
        U.S. or Canadian postal mail, provided that if there is a slow-down or
        other labour dispute which affects the delivery of a notice by mail then
        it will be deemed to be received by the receiving party at the actual
        time of delivery; or (c) in the case of e-mail notices to you, upon
        Prepr receipt of your express acknowledgement of receipt of email
        transmission.
      </p>
      <br />
      <p>
        <b>16. Language</b>
        <br />
        The parties to these terms and conditions have expressly requested and
        required that these terms and conditions, and all other related
        documents, be drawn up in the English language. Les parties convenient
        et exigent expressément que ce Contrat et tous les documents qui s’y
        rapportent soient rédigés en anglais.
      </p>
      <br />
      <p>
        If you have any questions regarding these terms and conditions, please
        contact us at salar.chagpar@prepr.org.
      </p>
    </>
  ),
  content_fr: (
    <>
      <p>
        VEUILLEZ LIRE ATTENTIVEMENT LES PRÉSENTES CONDITIONS GÉNÉRALES. LES
        PRÉSENTES CONDITIONS GÉNÉRALES RÉGISSENT VOTRE UTILISATION ET VOTRE
        ACCÈS À CE SITE WEB DE LA FONDATION PREPR (LA <b>PLATEFORME</b>), AINSI
        QUE LES BIENS ET SERVICES FOURNIS PAR L'INTERMÉDIAIRE DE CETTE
        PLATEFORME (LES <b>SERVICES</b>) ET LES MESSAGES, INFORMATIONS, DONNÉES,
        TEXTES, LOGICIELS OU IMAGES, OU TOUT AUTRE CONTENU DE CETTE PLATEFORME
        (COLLECTIVEMENT, LE <b>CONTENU</b>). IL EXEMPTE LA FONDATION PREPR (
        <b>PREPR</b>) ET D'AUTRES DE TOUTE RESPONSABILITÉ ET/OU LIMITE LEUR
        RESPONSABILITÉ ET CONTIENT D'AUTRES DISPOSITIONS IMPORTANTES QUE VOUS
        DEVEZ LIRE ET ACCEPTER AVANT D'UTILISER CETTE PLATEFORME. CETTE
        PLATEFORME EST FOURNIE PAR PREPR UNIQUEMENT AUX FINS LIMITÉES
        IDENTIFIÉES DANS LES PRÉSENTES CONDITIONS D'UTILISATION ET SON
        UTILISATION PAR VOUS EST SUBORDONNÉE À VOTRE ACCEPTATION DES CONDITIONS
        D'UTILISATION ÉNONCÉES CI-DESSOUS (LES
        <b>CONDITIONS D'UTILISATION</b>).
      </p>
      <br />
      <p>
        <b>1 . Votre accès à la plate-forme</b>
        <br />
        En visitant ou en utilisant la présente plate-forme, notamment pour (i)
        accéder au contenu, l'utiliser et/ou le télécharger, (ii) envoyer des
        messages, des informations, des données, du texte, des logiciels ou des
        images, ou tout autre contenu à la présente plate-forme, (iii) utiliser,
        accéder ou acheter tout autre service, ou (iv) acheter tout produit
        disponible à la vente sur la présente plate-forme (les <b>produits</b>),
        vous acceptez, en votre nom propre et au nom de toute entité au nom de
        laquelle vous pouvez agir, d'accepter et de respecter les présentes
        conditions générales pour chaque utilisation et chaque visite de la
        présente plate-forme. Si vous n'acceptez pas de vous conformer aux
        présentes conditions générales, n'utilisez pas cette plate-forme.
        <br />
        <br />
        Prepr a le droit, à sa seule discrétion, d'ajouter, de supprimer, de
        modifier ou de changer toute partie des présentes conditions générales,
        en tout ou en partie, à tout moment. Les modifications entreront en
        vigueur à la date de publication de l'avis de modification sur la
        présente plate-forme. Il vous incombe de vérifier régulièrement les
        présentes conditions générales afin de déterminer si des modifications
        ont été apportées. Veuillez vérifier fréquemment les présentes
        conditions générales en consultant la date de la{" "}
        <b>dernière mise à jour</b> figurant en haut du présent document. Si
        l'une des conditions ou l'un des changements apportés aux présentes
        conditions ne vous convient pas, vous devez cesser immédiatement
        d'utiliser la plate-forme. La poursuite de l'utilisation de la
        plate-forme après la publication de ces modifications vaut acceptation
        de celles-ci. Les présentes conditions générales s'appliquent
        exclusivement à votre utilisation de la présente plate-forme et ne
        modifient pas les conditions générales de tout autre accord que vous
        pourriez avoir avec Prepr (y compris votre accord de compte Prepr, le
        cas échéant).
        <br />
        <br />
        Prepr peut, à tout moment, sans préavis ni responsabilité, et pour
        quelque raison que ce soit, résilier, modifier, suspendre ou interrompre
        tout aspect de la présente plate-forme, y compris (i) modifier la
        disponibilité de, restreindre l'accès à, ou imposer des limites à tout
        ou partie des fonctions ou services de, ou des liens vers, la présente
        plate-forme (ii) ajouter, supprimer ou modifier toute redevance ou frais
        pour l'utilisation de la présente plate-forme ou de toute fonction de la
        présente plate-forme ; (iii) supprimer, ajouter, modifier ou changer de
        toute autre manière tout contenu ou service de la présente plate-forme.
        Prepr se réserve le droit, à sa seule discrétion, de corriger toute
        erreur ou omission dans toute partie de la présente plate-forme, à tout
        moment et sans préavis, mais confirme qu'elle n'a pas l'obligation de le
        faire. Prepr et ses prestataires de services auront également le droit
        de mettre fin à votre utilisation de ce site, comme indiqué ci-dessous
        sous le titre « Résiliation de l'utilisation ».
        <br />
        <br />
        Vous acceptez que les présentes conditions générales et toute
        information connexe soient mises à votre disposition et que les
        communications entre vous et Prepr se fassent par voie électronique.
      </p>
      <br />
      <br />
      <p>
        <b>2 . Licence limitée</b>
        <br />
        Vous bénéficiez d'une licence limitée non exclusive, non transférable,
        non sous-licenciable et révocable pour afficher sur votre ordinateur,
        imprimer, télécharger et utiliser le contenu de cette plate-forme
        uniquement à des fins d'information et aux fins identifiées dans la
        description et/ou les conditions d'utilisation spécifiques, le cas
        échéant, de chacun des services mis à disposition par l'intermédiaire de
        cette plate-forme et uniquement pour votre usage personnel ou interne à
        l'entreprise. Vous ne pouvez pas modifier le contenu que vous utilisez.
        Vous devez inclure dans tout affichage ou copie du contenu son avis de
        copyright (c'est-à-dire la ligne au bas de cette page). Aucune autre
        utilisation n'est autorisée.
        <br />
        <br />
        Tous les droits qui ne sont pas expressément accordés par les présentes
        conditions générales sont réservés à Prepr.
      </p>
      <br />
      <p>
        <b>3 . Restrictions :</b>
        <br />
        Vous ne pouvez pas :
        <br />
        (a)&nbsp;&nbsp; revendre tout contenu (à l'exception des produits et
        services achetés ou concédés sous licence à Prepr en vertu d'un accord
        de transaction (tel que décrit ci-dessous) ;
        <br />
        (b)&nbsp;&nbsp; inclure tout contenu dans ou avec tout produit que vous
        créez ou distribuez ;
        <br />
        (c)&nbsp;&nbsp; copier tout Contenu sur votre propre site web ou sur
        tout autre site web ;
        <br />
        (d)&nbsp;&nbsp; utiliser cette Plateforme ou les Services d'une manière
        qui pourrait endommager, désactiver, surcharger, altérer, interférer
        avec la sécurité, affecter négativement le fonctionnement ou abuser de
        cette Plateforme ou des Services, des ressources du système, des
        comptes, des serveurs, des réseaux, des sites affiliés ou liés,
        connectés ou accessibles par l'intermédiaire de cette plate-forme (y
        compris, sans s'y limiter, le téléchargement, l'affichage ou toute autre
        transmission sur cette plate-forme de virus informatiques, de chevaux de
        Troie, de vers ou d'autres fichiers ou programmes informatiques
        potentiellement nuisibles, perturbateurs ou destructeurs ou susceptibles
        d'imposer une charge déraisonnable ou disproportionnée à
        l'infrastructure de cette plate-forme) ; ou l'utilisation d'un robot,
        d'une araignée ou d'un autre programme ou dispositif automatique, ou
        d'un processus manuel pour surveiller, copier, résumer ou extraire de
        toute autre manière des informations de cette plate-forme ou du contenu,
        en tout ou en partie) ;
        <br />
        (e)&nbsp;&nbsp; utiliser cette plate-forme ou les services d'une manière
        qui porte atteinte aux droits légaux de toute autre personne (y compris
        les droits à la vie privée et à la personnalité, les droits d'auteur,
        les droits moraux et autres droits de propriété intellectuelle) ;
        <br />
        (f)&nbsp;&nbsp; utiliser cette Plateforme ou les Services dans le cadre
        d'un rapprochement ou d'une exploration de données, y compris, sans s'y
        limiter, la collecte ou l'utilisation d'informations sur d'autres
        utilisateurs des Services (y compris leurs adresses électroniques) sans
        leur consentement ;
        <br />
        (g)&nbsp;&nbsp; utiliser cette Plateforme ou les Services d'une manière
        illégale (y compris en accédant à cette Plateforme ou aux Services à
        partir de tout endroit où un tel accès peut être illégal ou interdit),
        contraire à l'éthique, indécente, offensante, diffamatoire, dérogatoire,
        frauduleuse, trompeuse, nuisible, abusive, menaçante, vulgaire, profane,
        pornographique, obscène, sexuellement explicite, sexiste, raciste,
        haineux, offensant, harcelant, portant atteinte à la vie privée d'autrui
        (ceci inclut la publication de tout matériel qui dépeint, encourage,
        indique, préconise ou tend à inciter à une telle conduite), ou qui est
        autrement répréhensible ou qui ne respecte pas les droits et les
        intérêts légaux d'autrui ;
        <br />
        (h)&nbsp;&nbsp; publier sur cette plate-forme ou sur les services des
        éléments qui violeraient l'une des restrictions énoncées dans les
        présentes conditions générales ;
        <br />
        (i)&nbsp;&nbsp; utiliser cette Plateforme à des fins ou activités
        commerciales, y compris, sans s'y limiter :
        <br />
        (i)&nbsp;&nbsp; la vente ou l'offre de vente de biens ou de services ;
        <br />
        (ii)&nbsp;&nbsp; la sollicitation d'annonceurs ou de sponsors ;
        <br />
        (iii)&nbsp;&nbsp; l'organisation de concours, de jeux d'argent ou de
        hasard ou l'offre de prix, de récompenses ou d'autres incitations à
        toute personne ;
        <br />
        (iv)&nbsp;&nbsp; l'affichage de bannières publicitaires ou de
        parrainage, y compris celles générées par des services d'échange de
        bannières ou de liens ;
        <br />
        (v)&nbsp;&nbsp; la sollicitation de dons; ou
        <br />
        (vi)&nbsp;&nbsp; l'utilisation non autorisée ou non sollicitée de
        courrier indésirable, de spam, de chaînes de lettres, de systèmes
        pyramidaux ou de toute autre forme de sollicitation ;
        <br />
        (k)&nbsp;&nbsp; utiliser cette plate-forme ou ces services d'une manière
        qui pourrait :
        <ul className="pl-1">
          <li>
            porter atteinte, menacer, harceler, abuser ou intimider toute autre
            personne de quelque manière que ce soit ou impliquer du matériel qui
            représente, promeut, encourage, indique, préconise ou tend à inciter
            à la commission d'un crime ou d'autres activités illégales, à la
            violence, à la haine, à la cruauté ou à la discrimination à
            l'encontre d'individus ou de groupes, pour quelque raison que ce
            soit, ou à tout acte de cruauté envers les animaux ;
          </li>
          <li>
            diluer ou déprécier le nom et la réputation de Prepr, de ses marques
            (telles que définies ci-dessous) ou de ses sociétés affiliées ou
            associées ;
          </li>
          <li>
            interférer avec l'utilisation et la jouissance par d'autres
            personnes de cette Plateforme, des Services ou de l'Internet en
            général ;
          </li>
          <li>
            entraîner le contournement ou la violation de toute mesure
            d'authentification de l'utilisateur, de mot de passe, de sécurité ou
            de contrôle concernant cette plate-forme ou les services ou toute
            autre ressource Internet ou système informatique ;
          </li>
          <li>
            entraîner l'accès non autorisé aux comptes Prepr et aux documents
            d'autres personnes (y compris les documents publiés sur cette
            plate-forme ou en relation avec les services) ou interférer avec
            l'utilisation et la jouissance par d'autres personnes de cette
            plate-forme ou des services ;
          </li>
          <li>
            dissimuler ou déformer l'auteur ou l'origine de tout message ou
            communication, ou qui usurpe l'identité de toute personne, ou qui
            déclare faussement ou déforme de toute autre manière une affiliation
            avec toute autre personne, y compris, mais sans s'y limiter, tout
            représentant de Prepr ;
          </li>
          <li>
            divulguer des images de toute personne ou des informations privées
            sur toute personne (telles que les noms, les numéros de téléphone,
            les adresses électroniques, les adresses postales, les numéros
            d'assurance sociale) sans l'autorisation de cette personne.
          </li>
        </ul>
      </p>
      <br />
      <p>
        <b>4 . Droits de propriété</b>
        <br />
        Ce site et son contenu sont protégés par les lois canadiennes et
        mondiales sur le droit d'auteur et les dispositions des traités. Toute
        copie, redistribution, reproduction ou modification non autorisée de
        cette plate-forme ou du contenu par une personne peut constituer une
        violation des lois sur les marques de commerce ou les droits d'auteur et
        pourrait donner lieu à des poursuites judiciaires. Vous acceptez de vous
        conformer à toutes les lois sur les droits d'auteur dans le monde entier
        lors de votre utilisation de cette plate-forme et des services et
        d'empêcher toute copie non autorisée du contenu.
        <br />
        <br />
        Certains noms, graphiques, logos, icônes, dessins, mots, titres et
        phrases sur cette Plateforme, y compris, mais sans s'y limiter, « Prepr
        “ et ” PIE “, constituent des marques de commerce, des noms commerciaux,
        des présentations commerciales et/ou des produits et services associés
        de Prepr ou de ses sociétés affiliées (les ” Marques »), et sont
        protégés au Canada et à l'échelle internationale, et leur affichage sur
        cette Plateforme ne transmet ni ne crée aucune licence ni aucun autre
        droit sur les Marques. Toute utilisation des Marques, en tout ou en
        partie, ou des marques de commerce, des noms commerciaux, de l'habillage
        commercial et/ou des produits et services associés d'un fournisseur de
        Prepr ou d'un autre tiers accessible par l'entremise de la Plateforme,
        sans l'autorisation écrite préalable de Prepr ou du tiers en question,
        est strictement interdite.
        <br />
        <br />
        D'autres marques de commerce, noms commerciaux, habillages commerciaux
        et produits et services associés mentionnés sur cette Plateforme peuvent
        être des marques de commerce de leurs propriétaires respectifs.
        L'affichage de ces marques de commerce, noms commerciaux, habillages
        commerciaux et produits et services associés sur cette Plateforme ne
        confère ni ne crée aucune licence ni aucun autre droit sur ces marques
        de commerce ou noms commerciaux. Toute utilisation non autorisée est
        strictement interdite.
      </p>
      <br />
      <p>
        <b>5 . Plateformes liées et encadrement</b>
        <br />
        Certains liens figurant sur la présente plate-forme peuvent vous
        conduire à d'autres plates-formes. Prepr ne fournit ces liens que pour
        des raisons de commodité. Prepr n'est pas responsable du contenu de ces
        pages liées. Prepr ne fait aucune déclaration ni ne donne aucune
        garantie concernant les plates-formes liées, les informations qui y
        figurent ou les produits ou services qui y sont décrits, et ne les
        approuve pas. Si vous utilisez ces sites, vous quitterez la présente
        plate-forme. Si vous décidez de visiter un site lié, vous le faites à
        vos propres risques et il vous incombe de prendre toutes les mesures de
        protection contre les virus et autres éléments destructeurs.
        <br />
        <br />
        Prepr est favorable à l'établissement de liens vers cette plate-forme.
        Toutefois, vous acceptez que si vous créez un lien vers cette
        plate-forme, votre site web ne doit pas :
        <br />
        <ul>
          <li>
            créer des cadres autour de toute partie de la plate-forme ou
            utiliser d'autres techniques qui modifient la présentation visuelle
            de la plate-forme ;
          </li>
          <li>
            laisser entendre que Prepr vous soutient ou soutient toute autre
            personne, ou vos produits ou services ou ceux d'une autre personne ;
          </li>
          <li>
            laisser entendre qu'il existe une affiliation entre vous ou toute
            autre personne, ou vos produits ou services ou ceux d'une autre
            personne, et Prepr sans l'accord écrit préalable de Prepr ;
          </li>
          <li>
            représenter de manière erronée la relation entre vous ou toute autre
            personne et Prepr ou présenter des informations ou impressions
            fausses, trompeuses ou autrement préjudiciables concernant Prepr ou
            l'un quelconque de ses produits ou services ; ou
          </li>
          <li>
            contenir des éléments susceptibles d'être interprétés comme
            déplaisants, nuisibles, offensants ou inexacts.
          </li>
        </ul>
      </p>
      <br />
      <p>
        <b>6 . Politique de confidentialité</b>
        <br />
        Toutes les informations personnelles que Prepr recueille par
        l'intermédiaire de cette Plateforme ou des Services sont soumises à la
        Déclaration de confidentialité de la Fondation Prepr, qui est incorporée
        par référence dans les présentes Conditions générales.
      </p>
      <br />
      <p>
        <b>
          7 . Communications de l'utilisateur et soumissions de l'utilisateur
          (sollicitées et non sollicitées)
        </b>
        <br />
        Toutes les informations que vous fournissez par l'intermédiaire de cette
        Plateforme ou en relation avec les Services, y compris vos informations
        d'enregistrement (nom légal et adresses électroniques) doivent être
        véridiques, exactes, actuelles et complètes. Vous devez également
        contacter Prepr avec des informations d'enregistrement mises à jour sans
        délai en cas de modification de ces informations.
      </p>
      <br />
      <p>
        Prepr et ses prestataires de services s'appuieront sur les informations
        que vous aurez fournies. Vous serez responsable de toute perte, de tout
        dommage ou de tout coût supplémentaire que vous, Prepr ou ses
        prestataires de services ou d'autres personnes pourraient encourir du
        fait que vous avez fourni des informations fausses, incorrectes ou
        incomplètes ou que vous n'avez pas mis à jour vos informations
        d'enregistrement dans les plus brefs délais en cas de modification de
        ces informations. Prepr peut exiger une copie d'une pièce d'identité
        délivrée par le gouvernement avant d'apporter des modifications à vos
        informations d'enregistrement ou de paiement.
      </p>
      <br />
      <p>
        Sous réserve de toute loi applicable, les exigences de la Fondation
        <a
          className="c-primary mx-1"
          style={{ textDecoration: "none" }}
          target="_blank"
          href="https://preprlabs.org/privacy-policy"
        >
          Prepr la déclaration sur la protection de la vie privée
        </a>
        , ainsi que tout règlement de concours et accord de transaction
        applicable :
      </p>
      <br />
      <p>
        toute communication envoyée par ou à vous via cette Plateforme ou
        autrement à Prepr, qu'elle soit sollicitée par Prepr ou autrement, est
        non confidentielle (à l'exception des informations personnelles qui sont
        couvertes par la politique de confidentialité de la Fondation Prepr), et
        Prepr n'a aucune obligation de s'abstenir de les reproduire, de les
        publier ou de les utiliser de quelque manière que ce soit ou à quelque
        fin que ce soit. Prepr est libre d'utiliser et de divulguer le contenu
        de toute communication de ce type, y compris, sans s'y limiter, les
        idées, inventions, concepts, techniques ou savoir-faire qui y sont
        divulgués, à quelque fin que ce soit, y compris, sans s'y limiter, le
        développement, la fabrication et/ou la commercialisation de biens et de
        services. Vous acceptez de ne revendiquer aucun droit de propriété de
        quelque nature que ce soit sur ces communications (y compris, mais sans
        s'y limiter, les droits d'auteur, les marques commerciales, les brevets,
        la concurrence déloyale, les droits moraux ou les contrats implicites)
        et vous renoncez par la présente à ces droits moraux en faveur de Prepr
        ainsi qu'au droit de recevoir une contrepartie financière ou autre en
        rapport avec ces communications.
      </p>
      <br />
      <p>
        Prepr n'accepte ni ne prend en considération les idées non sollicitées,
        y compris les idées de nouvelles campagnes publicitaires, de nouvelles
        promotions, de biens, de services ou de technologies nouveaux ou
        améliorés, d'améliorations de produits, de processus, de matériaux, de
        plans de commercialisation ou de nouveaux noms de produits. L'objectif
        de cette politique Prepr est d'éviter les malentendus ou les litiges
        potentiels. Par conséquent, veuillez ne pas envoyer d'idées, de
        suggestions ou d'autres documents non sollicités (« soumissions ») à
        Prepr.
      </p>
      <br />
      <p>
        Toutefois, si vous envoyez des Soumissions à Prepr ou à cette
        Plateforme, vous accordez automatiquement à Prepr et à ses successeurs
        et ayants droit un droit et une licence perpétuels, libres de
        redevances, irrévocables, illimités, non exclusifs et mondiaux
        d'utiliser, de copier, de reproduire, de modifier, d'adapter, de
        publier, de traduire, de créer des œuvres dérivées, de distribuer,
        d'exécuter, d'afficher et d'exploiter de toute autre manière les
        Soumissions ou les idées, les concepts, le savoir-faire ou les
        techniques associés aux Soumissions à quelque fin que ce soit, à quelque
        fin que ce soit, commerciale ou autre, en utilisant toute forme, média
        ou technologie connue à ce jour ou développée ultérieurement, sans vous
        rémunérer ou rémunérer quelqu'un d'autre, sans aucune responsabilité et
        sans aucune obligation de confidentialité ou autre devoir de la part de
        Prepr ou de ses successeurs ou ayants droit, et vous acceptez, déclarez
        et garantissez que vous renoncez à tous les droits moraux (y compris le
        droit d'être associé à la soumission) sur les soumissions lors de leur
        soumission à Prepr ou à ce site web.
      </p>
      <br />
      <p>
        <b>8 . Conditions supplémentaires</b>
        <br />
        Prepr n'est pas tenue de surveiller, de filtrer, de contrôler ou de
        modifier l'utilisation de la plate-forme ou des services, y compris la
        publication de documents sur cette plate-forme, bien qu'elle se réserve
        le droit de le faire à sa seule discrétion. Prepr réagira de manière
        appropriée, à sa seule discrétion, si Prepr a connaissance d'une
        utilisation inappropriée de la plate-forme ou des services, y compris,
        mais sans s'y limiter, d'une utilisation constituant une violation des
        droits d'auteur.
      </p>
      <br />
      <p>
        1. &nbsp;&nbsp;a) Frais et charges, disponibilité, prix, fautes
        d'impression et erreurs, et commandes
        <br />
        Il vous incombe de payer tous les frais et charges liés à l'utilisation
        de la plate-forme et des services, y compris les taxes applicables. Tous
        les montants figurant sur la Plateforme sont exprimés en dollars
        canadiens.
      </p>
      <p>
        Prepr se réserve le droit de modifier les prix, les frais et les charges
        associés à cette Plateforme et aux Services, ainsi que les produits
        disponibles par le biais de cette Plateforme et des Services, à tout
        moment et de temps à autre, sans préavis et sans engager sa
        responsabilité envers vous ou toute autre personne. En outre, en raison
        de la popularité de certains produits ou services, Prepr ne peut
        garantir que les biens ou services annoncés sur la présente plate-forme
        seront disponibles au moment de la commande ou par la suite. Prepr se
        réserve également le droit, à tout moment, de rejeter, de corriger,
        d'annuler ou de résilier toute commande. Si vous commandez des produits
        ou services dont le prix a été incorrectement affiché, Prepr vous
        donnera la possibilité d'annuler votre commande. Si vous commandez des
        produits ou des services qui ne sont pas disponibles, Prepr vous en
        informera par courrier électronique. Prepr se réserve le droit de
        limiter les quantités concédées ou vendues.
      </p>
      <br />
      <p>
        1. &nbsp;&nbsp;b) Offres spéciales/promotions/tirages au sort/concours
        <br />
        De temps à autre, Prepr peut organiser des concours à l'intention du
        public ou des utilisateurs enregistrés de cette plate-forme (y compris
        les titulaires de comptes Prepr). Tous les concours sont régis par les
        présentes conditions générales, dans la mesure où elles sont
        applicables, et par le règlement spécifique du concours. En participant
        à un concours par l'intermédiaire de cette plate-forme, vous signifiez
        votre accord et votre acceptation des présentes conditions générales et
        du règlement du concours applicable.
      </p>
      <br />
      <p>
        1. &nbsp;&nbsp;c) Accords sur les logiciels
        <br />
        c) Accords sur les logicielsaToute utilisation par vous du système
        éducatif PIE de Prepr sera soumise au logiciel propriétaire et sera
        régie par les{" "}
        <a
          className="c-primary mx-1"
          style={{ textDecoration: "none" }}
          target="_blank"
          href="https://preprlabs.org/terms"
        >
          conditions générales
        </a>{" "}
        PIE et par d'autres paramètres convenus entre vous et Prepr concernant
        l'étendue de l'utilisation autorisée.
      </p>
      <br />
      <p>
        Pour la commodité des membres de Prepr, Prepr peut mettre à disposition
        des logiciels de tiers à télécharger à partir de ou par l'intermédiaire
        de la Plateforme. Tous ces logiciels téléchargeables à partir ou par
        l'intermédiaire de la plate-forme appartiennent à des tiers ou sont
        concédés sous licence par des tiers, et le téléchargement et
        l'utilisation du logiciel sont soumis à un accord de licence de logiciel
        de tiers. Prepr n'a aucun contrôle sur les logiciels téléchargés à
        partir de la plate-forme ou par son intermédiaire, ni aucune
        responsabilité à cet égard, et Prepr n'émet aucune déclaration, garantie
        ou condition concernant les logiciels. Le téléchargement et
        l'utilisation des logiciels, ainsi que vos relations avec les
        propriétaires et les fournisseurs des logiciels, se font à vos propres
        risques, et vous ne pouvez formuler aucune réclamation à l'encontre de
        la Prepr découlant du téléchargement et de l'utilisation de tout
        logiciel, ou s'y rapportant.
      </p>
      <br />
      <p>
        1. &nbsp;&nbsp;d) Accords de transaction
        <br />
        Toutes les transactions effectuées par l'intermédiaire du site web sont
        régies par les présentes conditions générales et par tout accord de
        transaction spécifique applicable. En participant à une transaction par
        l'intermédiaire de cette plate-forme, vous signifiez votre accord et
        votre acceptation des présentes conditions et de l'accord de transaction
        applicable.
      </p>
      <br />
      <p>
        <b>9 . Clause de non-responsabilité</b>
        <br />
        Prepr fait des efforts raisonnables pour s'assurer que le contenu est
        exact au moment de sa publication sur cette plateforme. Cependant, Prepr
        ne fait aucune déclaration ni garantie de quelque nature que ce soit
        concernant cette plateforme (dans cette section 9, le terme "plateforme"
        inclut le contenu et les services), et vous reconnaissez et acceptez que
        cette plateforme (y compris, sans limitation, le contenu et les
        services) est fournie "telle quelle" et selon sa disponibilité ; et que
        toute utilisation ou dépendance envers cette plateforme sera à vos
        propres risques.
      </p>
      <br />
      <p>
        Dans les limites maximales autorisées par la loi, Prepr et ses filiales,
        agents, fournisseurs de services, employés, dirigeants, administrateurs
        ou représentants (<b>"représentants de Prepr"</b>), ne font aucune
        garantie et déclinent expressément toutes les déclarations, garanties,
        engagements et conditions, exprimés ou implicites, en droit ou
        autrement, y compris, sans limitation : (i) toutes garanties implicites
        de qualité marchande, de qualité marchande, d'adéquation à un usage
        particulier ou de non-violation, ou toute garantie implicite découlant
        de l'exécution ou de l'usage du commerce ; (ii) que cette plateforme
        répondra à vos exigences ou sera compatible avec votre ordinateur ou vos
        équipements/logiciels liés, y compris équipements photographiques ;
        (iii) que le contenu est exact, fiable, authentique, à jour ou complet ;
        ou (iv) que cette plateforme continuera de fonctionner, fonctionnera
        sans interruption ou sera exempte d'erreurs.
      </p>
      <br />
      <p>
        En outre, Prepr ne fait aucune déclaration ou garantie que le contenu de
        cette plateforme est approprié ou disponible pour une utilisation hors
        du Canada. L'accès au contenu depuis des emplacements où il serait
        illégal est interdit. Ceux qui choisissent d'accéder à cette plateforme
        depuis d'autres emplacements le font de leur propre initiative et sont
        responsables de se conformer aux lois locales.
      </p>
      <br />
      <p>
        Votre utilisation de cette plateforme, y compris tout contenu, matériel,
        données ou informations publiés, soumis, téléchargés ou autrement
        obtenus par le biais de cette plateforme, est à vos propres risques.
        Vous devriez conserver vos propres copies de sauvegarde de tous les
        matériels que vous pourriez utiliser ou permettre à d'autres d'utiliser
        en relation avec cette plateforme, y compris le contenu et les matériels
        publiés ou soumis sur cette plateforme. Vous êtes seul responsable de la
        fourniture, de la configuration et de l'entretien de tous les
        équipements et logiciels que vous utilisez ou permettez à d'autres
        d'utiliser en relation avec cette plateforme et/ou les services, y
        compris tous les ordinateurs et équipements connexes, équipements
        photographiques et logiciels. Vous êtes seul responsable, et assumez
        tous les risques et responsabilités associés à tout contenu et matériel
        que vous pourriez utiliser ou permettre à d'autres d'utiliser en
        relation avec cette plateforme, y compris les matériels publiés ou
        soumis sur cette plateforme. Vous êtes seul responsable de toute
        réclamation ou demande émanant de toute autre personne résultant de, en
        relation avec, ou liée à votre utilisation de cette plateforme, votre
        violation de ces termes et conditions, votre violation ou atteinte aux
        droits d'autrui, ou votre violation de toute loi civile ou pénale
        applicable. Vous êtes seul responsable de la manière dont les matériels
        que vous pourriez utiliser ou permettre à d'autres d'utiliser en
        relation avec cette plateforme, y compris le contenu et les matériels
        publiés ou soumis sur cette plateforme, sont utilisés par d'autres
        utilisateurs de cette plateforme. Prepr décline toute responsabilité
        concernant toutes ces questions.
      </p>
      <br />
      <p>
        Les représentants de Prepr ne sont soumis à aucune obligation de
        vérifier l'identité des utilisateurs de cette plateforme ou des
        services. Prepr et ses fournisseurs de services ne contrôlent pas les
        matériels publiés ou soumis sur cette plateforme et/ou les services par
        des personnes autres qu'eux-mêmes et ne surveillent pas, ne vérifient
        pas, ne régulent pas ni n'éditent ces matériels pour vérifier leur
        conformité aux lois applicables ou à ces termes et conditions. Vous
        pouvez trouver certains des matériels publiés par d'autres utilisateurs
        offensants, nuisibles, inexacts ou trompeurs. Vous devriez faire preuve
        de prudence et de bon sens lorsque vous utilisez cette plateforme.
      </p>
      <br />
      <p>
        Par votre utilisation de cette plateforme et/ou des services, vous
        pourriez avoir l'opportunité de conclure des transactions commerciales
        avec des tiers. Toutes ces transactions sont à vos propres risques. Les
        représentants de Prepr ne sont pas parties à de telles transactions et
        déclinent toute responsabilité concernant ces transactions.
      </p>
      <br />
      <p>
        <b>
          10 . Limitation de la responsabilité de Prepr Vous acceptez qu'en
          aucun cas
        </b>
        <br />
        Prepr ou l'un des représentants de Prepr n'auront de responsabilité
        envers vous ou toute autre personne ou entité en lien avec cette
        plateforme pour toute perte ou tout dommage, direct, spécial, punitif,
        exemplaire ou consécutif, ou d'autres dommages (y compris, sans
        limitation, des dommages pour préjudice commercial, perte d'informations
        ou de programmes ou de données, perte de profits, perte d'économies,
        perte de revenus, perte de clientèle), découlant de ou en lien avec
        l'utilisation ou l'accès à cette plateforme, les services, le contenu,
        tout contenu de tout site lié ou l'échec de ces sites (y compris, sans
        limitation, les dommages subis en raison d'omissions ou d'inexactitudes
        dans ces sites ou contenus, ou la transmission d'informations
        confidentielles ou sensibles vers ou depuis ces sites), même si Prepr ou
        l'un des représentants de Prepr ont été informés de la possibilité de
        tels dommages ou pertes ou si ces dommages ou pertes étaient
        prévisibles.
      </p>
      <br />
      <p>
        <b>11 . Votre responsabilité</b>
        <br />
        En outre, vous acceptez d'être seul responsable des actions et des
        communications entreprises ou transmises dans le cadre de votre
        utilisation de la présente plate-forme et des services, et de vous
        conformer à toutes les lois qui s'appliquent ou peuvent s'appliquer à
        votre utilisation de la présente plate-forme et des services ou à vos
        activités sur ceux-ci, ou en ce qui concerne le contenu. Prepr peut
        enquêter sur des événements susceptibles d'impliquer des violations de
        ces lois, et peut impliquer les autorités chargées de l'application de
        la loi, et coopérer avec elles, pour poursuivre les utilisateurs
        impliqués dans de telles violations. Prepr se réserve le droit de
        divulguer à tout moment toute information (y compris, notamment, vos
        informations personnelles, les informations relatives à votre compte,
        les informations de paiement et les documents publiés sur la
        plate-forme) concernant votre utilisation de cette plate-forme ou des
        services (y compris toute violation perçue de la législation
        applicable), si cela s'avère nécessaire pour satisfaire à toute loi,
        réglementation ou demande gouvernementale.
      </p>
      <br />
      <p>
        <b>12 . Choix de la législation</b>
        <br />
        La présente plate-forme est contrôlée, exploitée et administrée par
        Prepr à partir de ses bureaux situés dans la province de l'Ontario, au
        Canada. En accédant à la présente plate-forme, vous acceptez que toutes
        les questions relatives à l'accès à la plate-forme ou à son utilisation
        soient régies par les lois de la province de l'Ontario et les lois
        fédérales du Canada qui y sont applicables, sans référence aux principes
        de conflit de lois, et que vous vous conformiez à toutes les lois
        applicables.
      </p>
      <br />
      <p>
        Tout litige entre Prepr et vous ou toute autre personne découlant de, en
        rapport avec ou lié à cette Plateforme, le Contenu ou les Services, les
        présentes Conditions générales, toute transaction par le biais de la
        Plateforme ou toute question connexe doit être résolu devant les
        tribunaux de la province de l'Ontario, Canada, siégeant dans la ville de
        Toronto, et vous vous soumettez irrévocablement à la juridiction
        exclusive de ces tribunaux à l'égard de tout litige de ce type.
      </p>
      <br />
      <p>
        Toute réclamation ou cause d'action que vous pourriez avoir découlant
        de, en relation avec, ou liée à votre utilisation de ce site Web ou de
        tout service, de ces conditions générales, de toute transaction par
        l'intermédiaire du site Web ou de toute question connexe doit être
        intentée devant un tribunal compétent à Toronto, Ontario, Canada, dans
        les deux (2) ans suivant la naissance de la réclamation ou de la cause
        d'action, après quoi la réclamation ou la cause d'action est à jamais
        interdite, indépendamment de tout statut ou de toute loi contraire.
      </p>
      <br />
      <p>
        <b>13 . Résiliation de votre utilisation</b>
        <br />
        Si vous ne respectez pas l'une des dispositions des présentes conditions
        générales, vous ne pouvez plus utiliser la présente plate-forme ou les
        contenus ou services applicables qui sont mis à disposition par
        l'intermédiaire de la présente plate-forme. Prepr déterminera, à sa
        discrétion, si les présentes conditions générales ont été violées.
      </p>
      <br />
      <p>
        Prepr ou ses prestataires de services peuvent à tout moment et pour
        quelque raison que ce soit, avec ou sans motif, et à leur seule
        discrétion, immédiatement : (i) suspendre ou résilier (en tout ou en
        partie) votre compte Prepr ou votre autorisation d'utiliser cette
        Plateforme et/ou les Services ; (ii) suspendre ou résilier et supprimer
        définitivement et détruire tout identifiant d'utilisateur, mot de passe,
        URL, adresse IP ou nom de domaine, ou tout matériel que vous ou d'autres
        pouvez avoir posté ou soumis à cette Plateforme et/ou aux Services ;
        (iii) retirer de cette plate-forme et/ou des services et supprimer et
        détruire de manière permanente tout matériel que vous ou d'autres avez
        publié ou soumis ; (iv) restreindre l'accès à votre compte et au
        matériel publié ou soumis à cette plate-forme et/ou aux services ; et
        (v) vous interdire toute utilisation future de cette plate-forme et/ou
        des services, le tout sans préavis et sans aucune responsabilité envers
        vous ou toute autre personne. Vous acceptez qu'en cas de survenance d'un
        tel événement ou si votre utilisation de la plate-forme, du service ou
        du contenu est autrement interrompue, vous n'aurez pas le droit de
        recevoir un remboursement des frais et charges que vous avez payés
        précédemment pour des services non utilisés, et que les présentes
        conditions générales continueront à s'appliquer et à vous lier en ce qui
        concerne votre utilisation antérieure de la plate-forme et/ou des
        services.
      </p>
      <br />
      <p>
        Prepr et ses prestataires de services ne sont pas tenus d'évaluer ou de
        déterminer la validité ou la légitimité des plaintes ou des demandes
        qu'ils peuvent recevoir concernant tout matériel que vous pouvez
        utiliser ou permettre à d'autres d'utiliser en relation avec cette
        plate-forme et/ou les services (y compris le matériel affiché ou soumis
        à cette plate-forme et/ou aux services) avant que Prepr et ses
        prestataires de services ne prennent toute mesure corrective qu'ils
        considèrent, à leur seule discrétion, comme appropriée.
      </p>
      <br />
      <p>
        <b>14 . Généralités</b>
        <br />
        Les présentes conditions générales, ainsi que chaque accord de
        transaction applicable, constituent l'intégralité de l'accord entre
        Prepr et vous en ce qui concerne l'objet des présentes, et remplacent
        toutes les communications, déclarations ou accords, oraux ou écrits,
        entre Prepr ou ses sociétés affiliées et vous en ce qui concerne l'objet
        des présentes. Si, pour quelque raison que ce soit, un tribunal
        compétent estime qu'une disposition des présentes conditions générales
        ou une partie de celles-ci est illégale, nulle ou inapplicable pour
        quelque raison que ce soit, cette disposition sera appliquée dans toute
        la mesure du possible afin de respecter l'intention des présentes
        conditions générales, et le reste des présentes conditions générales
        demeurera pleinement en vigueur. Le fait que Prepr n'insiste pas sur la
        stricte exécution d'une disposition des présentes conditions générales
        ou d'un droit, ou qu'elle ne l'applique pas, ne saurait être interprété
        comme une renonciation à cette disposition ou à ce droit. Vous et Prepr
        êtes des entrepreneurs indépendants, et aucune relation d'agence, de
        partenariat, de coentreprise, d'emploi ou de franchise n'est prévue ou
        créée par les présentes conditions générales ou par votre utilisation de
        la présente plate-forme ou de tout service ou contenu.
      </p>
      <br />
      <p>
        Les dispositions des présentes conditions générales s'appliqueront au
        bénéfice de Prepr et de ses prestataires de services, de leurs
        successeurs et ayants droit respectifs, ainsi que de vous-même et de vos
        héritiers, exécuteurs, administrateurs, successeurs et représentants
        personnels, et les lieront. Vous n'êtes pas autorisé à céder les
        présentes conditions générales ou les droits et obligations qui en
        découlent sans l'autorisation écrite expresse de Prepr, laquelle peut
        être refusée à la seule discrétion de Prepr. Prepr et ses prestataires
        de services peuvent céder les présentes conditions générales et leurs
        droits et obligations respectifs au titre des présentes conditions
        générales sans votre consentement.
      </p>
      <br />
      <p>
        <b>15 . Notifications</b>
        <br />
        Les notifications ou communications adressées à Prepr dans le cadre des
        présentes conditions générales doivent être faites par écrit et envoyées
        à Prepr par service de messagerie ou par courrier ordinaire prépayé à
        Prepr Foundation, 720 Bathurst Street, Toronto, Ontario, M9A 4Z5, à
        l'attention de Salar Chagpar : Salar Chagpar. Les notifications de Prepr
        peuvent vous être envoyées soit par courrier électronique à l'adresse
        électronique fournie pour votre compte, soit par service de messagerie
        ou par courrier ordinaire prépayé à l'adresse postale fournie pour votre
        compte. Les notifications seront réputées avoir été reçues à la première
        des deux dates suivantes (a) deux jours ouvrables après leur dépôt
        auprès d'un service de messagerie de nuit reconnu au niveau national ;
        (b) dix jours ouvrables après leur dépôt dans le courrier postal
        américain ou canadien, étant entendu qu'en cas de ralentissement ou
        d'autre conflit de travail affectant l'envoi d'une notification par
        courrier, celle-ci sera réputée reçue par la partie destinataire au
        moment de l'envoi ; ou (c) dans le cas de notifications par courrier
        électronique, à la réception par Prepr de votre accusé de réception
        exprès de la transmission du courrier électronique.
      </p>
      <br />
      <p>
        <b>16 . Langue</b>
        <br />
        Les parties aux présentes conditions générales ont expressément demandé
        et exigé que ces conditions générales et tous les autres documents qui
        s'y rapportent soient rédigés en anglais. Les parties convenient et
        exigent expressément que ce Contrat et tous les documents qui s'y
        rapportent soient rédigés en anglais.
      </p>
      <br />
      <p>
        Si vous avez des questions concernant ces conditions générales, veuillez
        contacter notre responsable de la protection de la vie privée à
        l'adresse suivante : salar.chagpar@prepr.org.
      </p>
    </>
  ),
};
