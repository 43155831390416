import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { CircularProgress, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "components/Cards/Card";
import AddCalendarIcon from "../../../../assets/svg/calendar-add.svg";
import SecureImage from "../../../../components/UI/atoms/SecureImage";
import useComputed from "../../../../helpers/hooks/useComputed";
import useLabs from "../../../../store/labs/service-hook";
import "./index.scss";
import SwipableView from "../../../../components/UI/organisms/SwipableView";
import { ReactComponent as ArrowLeft } from "assets/svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/svg/arrow-right.svg";

const LiveEventSchedule = ({ t, isLiveEventEnabled }) => {
  const { fetchLabEventSchedule } = useLabs();
  const { id } = useParams();

  const [sessions, setSessions] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const [loadingLiveEventSchedule, setLoadingLiveEventSchedule] =
    useState(false);

  const fetchSchedule = async () => {
    if (id) {
      setLoadingLiveEventSchedule(true);
      const response = await fetchLabEventSchedule(id);
      const sessions = response?.sessions
        ? Object.keys(response?.sessions).map((key) => key)
        : [];
      setActiveTab(sessions?.length ? sessions[0] : "");
      setSessions(response?.sessions ?? {});
      setLoadingLiveEventSchedule(false);
    }
  };

  const getTime = (data) => {
    const startTime = data?.start_time;
    const duration = data?.duration || 0;
    if (startTime) {
      const momentInstance = moment(startTime);
      const format = "h:mm a";
      return `${momentInstance.format(format)} - ${momentInstance
        .add(duration, "minutes")
        .format(format)}`;
    }
    return "";
  };

  const canBeAddedToCalendar = (data) => {
    const startTime = data?.start_time;
    return moment.now() < moment(startTime);
  };

  const generateGoogleCalendarUrl = (data) => {
    const startTime = data?.start_time;
    const momentInstance = moment(startTime);
    const duration = data?.duration || 0;
    const format = "YYYYMMDDTHHmmssZ";
    return `https://www.google.com/calendar/render?action=TEMPLATE&text=${
      data.title
    }&dates=${momentInstance.format(format)}/${momentInstance
      .add(duration, "minutes")
      .format(format)}`;
  };

  const isActive = (key) => key === activeTab;

  const tabKeys = useComputed(() => {
    return sessions
      ? Object.entries(sessions).map((item) => {
          const dateInstance = moment(item[0]);
          return {
            key: item[0],
            month: dateInstance.format("MMM"),
            date: dateInstance.format("D"),
            day: dateInstance.format("ddd"),
            data: item[1],
          };
        })
      : null;
  }, [sessions]);

  useEffect(() => {
    if (isLiveEventEnabled) {
      if (!Object.entries(sessions)?.length) {
        fetchSchedule();
      }
    }
  }, []);

  return (
    <div className="airmeet">
      <Card classes="lab-live-event-schedule mt-4">
        {isLiveEventEnabled ? (
          <>
            <h3 className={"mb-2"}>{t("Schedule")}</h3>
            {tabKeys && tabKeys?.length > 0 ? (
              <>
                <div
                  className="lab-live-event-schedule--tab"
                  data-qa="live-event-schedule-tab-header"
                >
                  <div className={"relative"}>
                    <SwipableView
                      slidesPerView={5}
                      noPagination
                      noLoop
                      navigation={{
                        nextEl: ".schedule-button-next",
                        prevEl: ".schedule-button-prev",
                      }}
                      children={
                        tabKeys?.length
                          ? tabKeys?.map((tab) => (
                              <div key={tab?.key}>
                                <div
                                  className={`lab-live-event-schedule-tab--list ${
                                    isActive(tab?.key) ? "active" : ""
                                  }`}
                                  onClick={() => {
                                    setActiveTab(tab?.key);
                                  }}
                                >
                                  <p
                                    className={
                                      "lab-live-event-schedule-tab--list-month"
                                    }
                                  >
                                    {tab?.month}
                                  </p>
                                  <div>
                                    <span
                                      className={
                                        "lab-live-event-schedule-tab--list-date"
                                      }
                                    >
                                      {tab?.date}
                                    </span>
                                    <span
                                      className={
                                        "lab-live-event-schedule-tab--list-day"
                                      }
                                    >
                                      {tab?.day}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))
                          : []
                      }
                    />
                    <div className={"schedule-button-prev"}>
                      <ArrowLeft />
                    </div>
                    <div className={"schedule-button-next"}>
                      <ArrowRight />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {tabKeys?.length ? (
              tabKeys?.map((tab) => (
                <div key={tab?.key}>
                  {isActive(tab?.key) ? (
                    <div
                      className="lab-live-event-schedule--sessions-preview"
                      data-qa="live-event-schedule-preview"
                    >
                      {tab?.data?.length ? (
                        tab?.data?.map((session, index) => (
                          <div key={index}>
                            <Grid container className={"mb-4"} spacing={1}>
                              <Grid item xs={12} md={2}>
                                <div className="lab-live-event-schedule--sessions-preview--timing">
                                  {getTime(session)}
                                </div>
                              </Grid>
                              <Grid item xs={12} md={10}>
                                <div className="lab-live-event-schedule--sessions-preview--details">
                                  <div>
                                    <p className="lab-live-event-schedule--sessions-preview--details--title mb-1">
                                      {session?.title}
                                    </p>
                                    {session?.speakers?.length ? (
                                      <>
                                        {session?.speakers?.map(
                                          (speaker, index) => (
                                            <div className="user" key={index}>
                                              <div className="avatar">
                                                <SecureImage
                                                  src={speaker.image}
                                                  alt={speaker.name}
                                                />
                                              </div>
                                              <p>{speaker.name}</p>
                                            </div>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {canBeAddedToCalendar(session) ? (
                                    <div className="ml-auto">
                                      <a
                                        href={generateGoogleCalendarUrl(
                                          session
                                        )}
                                        rel="noreferrer"
                                        target="_blank"
                                        title="Add to calendar"
                                      >
                                        <img
                                          src={AddCalendarIcon}
                                          alt={"Add to calendar"}
                                          className={"add-calendar"}
                                        />
                                      </a>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        ))
                      ) : (
                        <p className={"text-center"}>No data found.</p>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))
            ) : !loadingLiveEventSchedule ? (
              <p>No schedule found.</p>
            ) : (
              ""
            )}
            {loadingLiveEventSchedule ? (
              <Box className="flex items-center justify-center">
                <CircularProgress size={70} />
              </Box>
            ) : (
              ""
            )}
          </>
        ) : (
          <h3>{t("Live event has not been enabled for this lab.")}</h3>
        )}
      </Card>
    </div>
  );
};

export default LiveEventSchedule;
