import { Grid } from "@mui/material";
import ComponentLists from "./ComponentList";
import useChallengeReport from "../../../../store/reports/challenges/service-hooks";
import ChallengeAssociatedProjects from "./ChallengeAssociatedProjects";
import ChallengeEngagement from "./ChallengeEngagement";
import useChallengeReportContext from "../hooks/useChallengeReportContext";
import AchievementDetails from "pages/report/components/AchievementDetails";
import ChallengeMemberActivity from "./ChallengeMemberActivity";
import EditIcon from "@mui/icons-material/Edit";
import EmptyText from "components/UI/atoms/EmptyText";
import useRedirect from "helpers/hooks/useRedirect";

const ChallengeOverView = ({ t = (d) => d }) => {
  const { challengeSlug } = useChallengeReportContext();
  const { ChallengeAchievementDetails } = useChallengeReport();
  const { navigateEditChallenge } = useRedirect();

  return (
    <>
      <Grid container spacing={1} className={"ml-0"}>
        <Grid item xs={12} className={"pl-0 grid-lg"}>
          <ComponentLists t={t} />
        </Grid>
        <Grid item xs={12} className={"grid-md"}>
          <AchievementDetails
            slug={challengeSlug}
            fetchAchievementDetails={ChallengeAchievementDetails}
            t={t}
            noDataUi={
              <EmptyText
                buttonLabel={t("Edit", "common")}
                text={t(
                  "There is no achievement added to this Challenge yet, go to Challenge edit page to add it"
                )}
                icon={EditIcon}
                isButtonPresent
                onClick={() => navigateEditChallenge(challengeSlug)}
              />
            }
          />
          <ChallengeAssociatedProjects t={t}/>
        </Grid>
        <Grid item xs={12} className={"grid-md"}>
          <ChallengeEngagement t={t}/>
          <ChallengeMemberActivity t={t} />
        </Grid>
      </Grid>
    </>
  );
};

export default ChallengeOverView;
