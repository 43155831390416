import Card from "../../../components/Cards/Card";
import React from "react";
import {Grid} from "@mui/material";
import Spin from "../../../components/Spin";
import { COLORS } from "helpers/utils/constants";

const EngagementsReport = (props) =>{

    /**
     * PROPS
     */
    const {data, title, isLoading} = props


    return <>
        <Card>
            <h4 className="bold fs-16 mb-4">{title}</h4>
            <Grid container spacing={2}>
                {
                    isLoading ?
                        <Grid item xs={12}>
                            <div className={'h-200 w-full flex justify-center items-center'}>
                                <Spin blue big/>
                            </div>
                        </Grid>
                        : ""
                }
                {
                    !isLoading && data?.length ? data?.map((item, index)=>{
                        return (
                            <Grid item xs={6} key={index}>
                                <div className={`rounded-5 px-2 py-1 fs-13 h-full fw-500`} style={{background:COLORS.BG_GRAY}}>
                                    <p className="opacity-50" >{item?.title}</p>
                                    <p style={{color:COLORS.DARK_SKY_BLUE}}>{item?.count || 0}</p>
                                </div>
                            </Grid>
                        )
                    }) :''
                }
            </Grid>
        </Card>
    </>
}

export default EngagementsReport
