import ChallengesList from "./partials/ChallengesList";
import Card from "../../../../components/Cards/Card";
import useLabReportContext from "../hooks/useLabReportContext";
import { ComponentMappings } from "../../data";
import Select from "../../../../components/Input/Select";
import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import ResourceCollectionList from "./partials/ResourceCollectionList";
import ResourceGroupList from "./partials/ResourceGroupList";
import ResourceModulesList from "./partials/ResourceModulesList";
import ChallengesPathList from "./partials/ChallengePathsList";

const ComponentLists = ({ t = (d) => d }) => {
  const { components, activeComponent, setActiveComponent, labDetails } =
    useLabReportContext();

  const ComponentStats = useMemo(() => {
    return [
      {
        title: t("Challenge(s)"),
        count: labDetails?.challenges_count,
      },
      {
        title: t("Challenge Path(s)"),
        count: labDetails?.challenge_paths_count,
      },
      {
        title: t("Resource Module(s)"),
        count: labDetails?.resource_modules_count,
      },
      {
        title: t("Resource Collection(s)"),
        count: labDetails?.resource_collections_count,
      },
      {
        title: t("Resource Group(s)"),
        count: labDetails?.resource_groups_count,
      },
    ];
  }, [labDetails]);

  return (
    <Card title="Component Overview">
      <div
        className="flex mb-4 items-center"
        style={{ justifyContent: "space-between" }}
      >
        <h4 className="bold fs-16">{t("Component Overview")}</h4>
        <div>
          <Select
            borderedIcon
            value={activeComponent}
            onChange={(e) => setActiveComponent(e.target.value)}
            keyValues={components}
            width={"180px"}
            height={40}
            t={t}
          />
        </div>
      </div>
      <Grid
        container
        spacing={2}
        className="mb-4"
        style={{ justifyContent: "space-between" }}
      >
        {ComponentStats?.map((item) => (
          <Grid item xs={12} md={2} key={item?.title}>
            <p className={"bold text-center fs-15"}>{item?.count}</p>
            <p className={"text-center fs-11"}>{item?.title}</p>
          </Grid>
        ))}
      </Grid>
      {activeComponent === ComponentMappings.all_challenges && (
        <ChallengesList t={t}/>
      )}
      {activeComponent === ComponentMappings.all_challenge_paths && (
        <ChallengesPathList t={t}/>
      )}
      {activeComponent === ComponentMappings.all_resource_collections && (
        <ResourceCollectionList t={t}/>
      )}
      {activeComponent === ComponentMappings.all_resource_groups && (
        <ResourceGroupList t={t}/>
      )}
      {activeComponent === ComponentMappings.all_resources && (
        <ResourceModulesList t={t}/>
      )}
    </Card>
  );
};

export default ComponentLists;
