import DashboardLayout from 'pages/layouts/dashboard'
import React from 'react'
import BrowseSkills from './listing/BrowseSkills'

const Skills = () => {
  return (
    <DashboardLayout>
      <BrowseSkills />
    </DashboardLayout>
  )
}

export default Skills