import { Grid, Icon, Skeleton, Tab, Tabs } from "@mui/material";
import DonutChart from "components/Progress/DonutChart";
import React, { useEffect, useMemo, useState } from "react";
import { AccessAlarm, CalendarMonth, Cancel, Done } from "@mui/icons-material";
import { COLORS } from "helpers/utils/constants";
import Button from "components/Button";
import useRedirect from "helpers/hooks/useRedirect";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import useUserDashboard from "store/dashboard/user";
import moment from "moment";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const tabs = ["Challenges", "Labs", "Resources"];

const getProgressInfo = (data) => {
  return [
    {
      icon: Done,
      title: "Completed",
      value: data?.completedCount || 0,
      color: COLORS.GREEN,
    },
    {
      icon: AccessAlarm,
      title: "In Progress",
      value: data?.inProgressCount || 0,
      color: COLORS.YELLOW,
    },
    {
      icon: CalendarMonth,
      title: "Not Started",
      value: data?.notStartedCount || 0,
      color: COLORS.BLUE,
    },
    {
      icon: Cancel,
      title: "Deadline Missed",
      value: data?.deadlineMissedCount || 0,
      color: COLORS.RED,
    },
  ];
};

const ProgressCard = () => {
  const [tab, setTab] = useState(0);
  const { progress } = useUserDashboard();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { navigateChallenges, navigateResources, navigateLabs } = useRedirect();
  const width = useWindowWidth();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    fetchProgress();
  }, [tab]);

  const fetchProgress = async () => {
    setLoading(true);
    const res = await progress(
      tab === 2 ? "resource_modules" : tabs[tab]?.toLowerCase()
    );
    setData(res || {});
    setLoading(false);
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const onMob = useMemo(() => width < 500, []);

  const progressInfo = getProgressInfo(data).slice(0, tab === 0 ? 4 : 3);

  const navigateByTab = () => {
    switch (tab) {
      case 0:
        navigateChallenges();
        break;
      case 1:
        navigateLabs();
        break;
      case 2:
        navigateResources();
        break;
      default:
        return;
    }
  };

  return (
    <div className="px-4">
      <div className="flex items-center justify-between">
        <h3 className="text-nowrap">{t("My Progress")}</h3>
        <Tabs
          aria-label="dashboard tabs"
          value={tab}
          onChange={handleChange}
          className="minh-30 ml-1"
          variant={onMob && "scrollable"}
          allowScrollButtonsMobile={onMob}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#000000",
            },
          }}
          sx={{
            "& .MuiTab-root": {
              color: COLORS.GRAY,
              marginRight: 1,
            },
            "& .Mui-selected": {
              color: "black",
            },
          }}
        >
          {tabs.map((tab, i) => (
            <Tab
              key={i}
              value={i}
              label={t(tab)}
              sx={{ textTransform: "capitalize" }}
              className="fs-15 minh-30 fw-500 fs-12--mob py-0 px-0"
            >
              {tab}
            </Tab>
          ))}
        </Tabs>
      </div>
      <p className="mt-3 fs-14 c-title tracking-tight">
        {t("You joined PreprLabs on")}{" "}
        {moment(new Date(data?.joined_date)).format("MMM D, YYYY")},{" "}
        {t("here is your challenge journey.")}
      </p>
      {loading ? (
        <ProgressCardSkeleton />
      ) : (
        <Grid
          item
          container
          justifyContent="space-between"
          className="items-center"
        >
          <Grid item xs={12} md={7}>
            {progressInfo?.reduce(
              (accumulator, current) => accumulator + +(current?.value ?? "0"),
              0
            ) > 0 ? (
              progressInfo?.map((d, i) => (
                <div key={i} className="flex justify-between mb-2 mr-4">
                  <div className="flex-center">
                    <Icon sx={{ color: d?.color }} component={d?.icon} />
                    <p className="ml-1 fs-14" style={{ color: d?.color }}>
                      {t(d?.title)}
                    </p>
                  </div>
                  <p style={{ color: d?.color }}>{d?.value}</p>
                </div>
              ))
            ) : (
              <div className="flex-column items-center">
                <p className="text-center mb-4 fs-15 c-gray tracking-tight">
                  Join {tabs[tab]} and track your progress here.
                </p>
                <Button width="140px" height="40px" onClick={navigateByTab}>
                  {t("Continue")}
                </Button>
              </div>
            )}
            {progressInfo?.reduce(
              (accumulator, current) => accumulator + +(current?.value ?? "0"),
              0
            ) > 0 && (
              <div className="flex justify-between mr-4 fs-14 ml-4">
                <p className="ml-2">{t("Overall Joined")}</p>
                <p>{data?.overAllJoined}</p>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={5} mt={1}>
            <DonutChart
              classes="mx-auto"
              totalValue={data?.overAllJoined}
              height="190px"
              width="190px"
              data={progressInfo.map(({ value, color }) => ({
                value,
                color,
              }))}
              completedValue={data?.completedCount}
              hasSizeClasses
              title={t("Completed")}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ProgressCard;

export const ProgressCardSkeleton = () => {
  return (
    <div className="px-4">
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        spacing={2}
        mt={1}
      >
        <Grid item xs={12} md={7}>
          {Array(5)
            .fill(1)
            .map((_d, i) => (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={6}
                className="rounded-10 mb-3"
              />
            ))}
        </Grid>
        <Grid item xs={12} className="flex justify-center" md={5}>
          <Skeleton variant="circular" width={150} height={150} />
        </Grid>
      </Grid>
    </div>
  );
};
