import { COLORS } from "helpers/utils/constants";
import React from "react";
import SecureImage from "./SecureImage";
import user from "assets/svg/gradient/user.svg";

const RingAvatar = ({ color, img }) => {
  return (
    <div
      style={{
        border: `3px solid ${color || COLORS.GRAY}`,
        borderRadius: "30px",
      }}
      className={`w-40 h-40 flex justify-center items-center`}
    >
      <SecureImage
        src={img}
        alt="profile"
        placeholder={user}
        className="w-full h-full rounded-20 object-cover"
      />
    </div>
  );
};

export default RingAvatar;
