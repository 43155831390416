export const NOTIFICATION_TYPES = { 0:'', 1:'challenge',2:'lab',3:'learning_point',4:'friend_request', 5:'organization' }

export const LEARNING_POINTS_TYPES = {

    login:{awardName:'Login Award',awardFor:'Logging In'},
    join_a_lab:{awardName:'Lab Award',awardFor:'Joining a Lab'},
    create_a_project:{awardName:'Project Award',awardFor:'Creating a Project'},
    invite_member_to_a_project:{awardName:'Invite Member Award',awardFor:'Inviting Member To A Project'},
    submit_a_project:{awardName:'Project Submit Award',awardFor:'Submitting A Project'},
    successful_project_submission:{awardName:'Project Submission Award',awardFor:'Successfully Submitting Project'},
    like_a_project:{awardName:'Project Like Award',awardFor:'Liking A Project'},
    post_a_comment:{awardName:'Comment Post Award',awardFor:'Posting A Comment'},
    reply_to_a_comment:{awardName:'Comment Reply Award',awardFor:'Replying To A Comment'},
    login_via_linkedin:{awardName:'Linkedin Login Award',awardFor:'Logging In Using LinkedIn'},
    login_via_microsoft:{awardName:'Microsoft Login Award',awardFor:'Logging In Using Microsoft'},
    login_via_google:{awardName:'Google Login Award',awardFor:'Logging In Using Google'},
    login_via_apple:{awardName:'Apple Login Award',awardFor:'Logging In Using Apple'},
    login_via_magnet:{awardName:'Magnet Login Award',awardFor:'Logging In Using Magnet'},



}