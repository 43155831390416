import { Delete } from "@mui/icons-material";
import { Checkbox, Divider, Grid, IconButton } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import DateInput from "components/Input/DateInput";
import React, { useEffect, useState } from "react";
import RichTextEditor from "react-rte";
import SendToUsers from "./SendToUsers";
import parse from "html-react-parser";
import useMaster from "store/master/service-hook";
import moment from "moment";
import { NoDataView } from "components/UI/organisms/ListingContainer";
import { toast } from "react-toastify";

const AnnouncementBox = ({
  announcement,
  setAnnouncement,
  onSubmit,
  onDelete,
  t = (d) => d,
}) => {
  const [addedUsers, setAddedUsers] = useState([]);
  const [message, setMessage] = useState(RichTextEditor.createEmptyValue());
  const [sendBy, setSendBy] = useState(["inbox", "email"]);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledTime, setScheduledTime] = useState();
  const [loading, setLoading] = useState(false);
  const {
    fetchChallengeRecipient,
    state: { challengeAnnouncementRecipient = [] },
  } = useMaster();
  const view = announcement?.id !== "new";

  useEffect(() => {
    fetchChallengeRecipient();
  }, []);

  const reset = () => {
    setAddedUsers([]);
    setMessage(RichTextEditor.createEmptyValue());
    setScheduledTime();
    setSendBy(["inbox", "email"]);
    setIsScheduled(false);
  };

  useEffect(() => {
    reset();
  }, [announcement?.id]);

  useEffect(() => {
    if (!announcement) return;
    if (view) {
      setAddedUsers(
        Object.entries(announcement?.to_recipient_ids || {}).map(
          ([key, value]) => ({ id: key, title: value })
        )
      );
      setMessage(
        RichTextEditor.createValueFromString(announcement?.description, "html")
      );
      setScheduledTime(
        moment(new Date(announcement?.schedule_at)).format("YYYY-MM-DD HH:mm")
      );
      setSendBy(
        announcement?.sent_by !== "both"
          ? [announcement?.sent_by]
          : ["inbox", "email"]
      );
    }
  }, [view, announcement]);

  const handleUpdateSendBy = (e) => {
    if (view) return;
    const key = e.target.id;
    if (sendBy?.includes(key)) {
      setSendBy((val) => val.filter((v) => v !== key));
    } else {
      setSendBy((val) => [...val, key]);
    }
  };

  const getTime = () => {
    if (!scheduledTime) return;
    return scheduledTime.split(" ")[1] || "00:00";
  };
  const setTime = (e) => {
    const val = (e.target.value || "00:00") + ":00";
    setScheduledTime((v) => v?.split(" ")[0] + " " + val);
  };

  const handlePayload = (status) => {
    if (!addedUsers.length) {
      toast.error(t("Please enter recipients!"));
      return false;
    }
    if (!sendBy.length) {
      toast.error(t("Please select a sending medium: Email or Inbox Message!"));
      return false;
    }
    if (message.toString("markdown").trim().length < 5) {
      toast.error(t("Message too short. Please enter at least 5 characters!"));
      return false;
    }
    if (status === "scheduled") {
      if (!scheduledTime) {
        toast.error(t("Scheduled time is mandatory!"));
        return false;
      }
      if (moment(scheduledTime).isBefore(moment())) {
        toast.error(t("Please enter a scheduled time after the current time!"));
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (isDraft) => {
    if (!handlePayload(isDraft ? "draft" : !isScheduled ? "send" : "scheduled"))
      return;
    const payload = {
      status: isDraft ? "draft" : !isScheduled ? "send" : "scheduled",
      to_recipient_ids: addedUsers?.map((d) => d.id),
      description: message.toString("html"),
      sent_by: sendBy?.length === 2 ? "both" : sendBy[0],
      subject: announcement.subject,
    };
    if (!payload.schedule_at && payload.status === "scheduled") {
      payload.schedule_at = moment(scheduledTime).format("YYYY-MM-DD HH:mm");
    }
    setLoading(isDraft ? "draft" : "send");
    await onSubmit(payload);
    setLoading(false);
  };

  return (
    <Grid
      item
      xs={12}
      md={8}
      direction="column"
      className="relative items-stretch justify-between h-full overflowY-auto"
    >
      {!announcement ? (
        <NoDataView text="Select a announcement/chat to view" classes="mt-30" />
      ) : (
        <div className="mx-4 mt-4">
          <div
            className={`flex items-center w-full fs-18 ${
              view && "justify-between"
            }`}
          >
            <h3 className="mr-1 text-nowrap">
              {view ? announcement?.subject : t("Subject")}
            </h3>
            {view ? (
              <p className="fs-15 c-title">
                {announcement?.schedule_at
                  ? moment(announcement?.schedule_at).format(
                      "MMM DD, YYYY h:m A"
                    )
                  : "-"}
              </p>
            ) : (
              <input
                className="ghostInput w-full fs-18 border-b"
                value={t(announcement?.subject)}
                onChange={(e) =>
                  !view &&
                  e.target.value.length < 60 &&
                  setAnnouncement((val) => ({
                    ...val,
                    subject: e.target.value,
                  }))
                }
              />
            )}
          </div>
          <SendToUsers
            added={addedUsers}
            setAdded={setAddedUsers}
            users={challengeAnnouncementRecipient}
            noScroll
            view={view}
            t={t}
          />
          <div className="flex items-center justify-between flex-wrap">
            <p className="mt-1 fs-14">
              <b>{t("Send As")}: </b>
              {view ? (
                <span className="ml-1 c-title fw-500">
                  {[
                    { title: "Inbox Message", key: "inbox" },
                    { title: "Email", key: "email" },
                  ]
                    .filter((d) => sendBy?.includes(d?.key))
                    .map((f) => f?.title)
                    ?.join(", ")}
                </span>
              ) : (
                [
                  { title: "Inbox Message", key: "inbox" },
                  { title: "Email", key: "email" },
                ].map((d) => (
                  <>
                    <Checkbox
                      id={d?.key}
                      disabled={view}
                      checked={sendBy.includes(d?.key)}
                      onChange={handleUpdateSendBy}
                    />{" "}
                    {t(d?.title)}
                  </>
                ))
              )}
            </p>
            {!!view && (
              <IconButton onClick={() => onDelete(announcement?.id)}>
                <Delete className="c-error justify-end" />
              </IconButton>
            )}
          </div>
          <Divider className="mt-2 mb-4" />
          {view ? (
            <p className="minh-300 mb-2">{parse(message.toString("html"))}</p>
          ) : (
            <RichTextEditor
              editorClassName="minh-300"
              value={message}
              placeholder={t("type the message...")}
              onChange={setMessage}
            />
          )}
          {!view && (
            <Grid
              container
              className="flex mb-4 items-center justify-between mt-4 w-full"
              spacing={1}
            >
              <Grid item xs={12} md={3} className="flex items-center">
                <Checkbox
                  disabled={view}
                  onChange={(e) => setIsScheduled(e.target.checked)}
                />
                <p className="fs-14">{t("Schedule to send at")}</p>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                className="flex items-center jusitfy-start"
              >
                <DateInput
                  width="80px"
                  disablePast
                  value={scheduledTime}
                  classes="ml-2"
                  disabled={!isScheduled || view}
                  onChange={(e) => setScheduledTime(e.target.value)}
                />
                <Input
                  type="time"
                  width="105px"
                  value={getTime()}
                  onChange={setTime}
                  height="43px"
                  classes="ml-2"
                  disabled={!isScheduled || view}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className="flex items-center justify-end"
              >
                <Button
                  bordered
                  loading={loading === "draft"}
                  disabled={view}
                  classes="minw-120"
                  onClick={() => handleSubmit(true)}
                >
                  {t("Save as Draft")}
                </Button>
                <Button
                  width="80px"
                  classes="ml-2"
                  loading={loading === "send"}
                  disabled={view}
                  onClick={() => handleSubmit()}
                >
                  {t("Send")}
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </Grid>
  );
};

export default AnnouncementBox;
