import { Chip, FormControlLabel, Grid, Radio } from "@mui/material";
import Button from "../../../../components/Button";
import React, { useMemo, useState } from "react";
import QuickSearchLab from "./components/LabResults";
import QuickSearchChallenge from "./components/ChallengeResults";
import QuickSearchChallengePath from "./components/ChallengePathResults";
import QuickSearchChallengeTemplate from "./components/ChallengeTemplateResults";
import QuickSearchLabMarketPlace from "./components/LabMarketplaceResults";
import QuickSearchLabProgram from "./components/LabProgramsResults";
import QuickSearchOrganization from "./components/OrganizationResults";
import QuickSearchProject from "./components/ProjectResults";
import QuickSearchResourceGroup from "./components/ResourceGroupResults";
import QuickSearchResourceCollection from "./components/ResourceCollectionResults";
import QuickSearchResourceModule from "./components/ResourceModuleResults";
import QuickSearchUser from "./components/UsersResults";
import { useAdvanceSearchContext } from "../../hooks/useAdvanceSearchContext";
import { useNavigate } from "react-router-dom";

const search_filters = [
  {
    id: 1,
    menu: [
      { label: "Labs", value: "labs" },
      { label: "Lab Programs", value: "labPrograms" },
      { label: "Lab Marketplace", value: "labMarketPlace" },
    ],
  },
  {
    id: 2,
    menu: [
      { label: "Challenges", value: "challenges" },
      { label: "Challenge Paths", value: "challengePaths" },
      { label: "Challenge Templates", value: "challengeTemplates" },
    ],
  },
  {
    id: 3,
    menu: [
      { label: "Resource Modules", value: "resourceModules" },
      { label: "Resource Collections", value: "resourceCollection" },
      { label: "Resource Groups", value: "resourceGroups" },
    ],
  },
  {
    id: 4,
    menu: [
      { label: "Projects", value: "projects" },
      { label: "Organization", value: "organizations" },
      { label: "Users", value: "users" },
    ],
  },
];

const QuickSearchResults = ({ t = (d) => d }) => {
  const navigate = useNavigate();
  const {
    totalCountsQuickSearch,
    formattedCount,
    quickKeyword,
    setKeyword,
    setActiveMenu,
    setSearchOpen,
    setTotalCounts
  } = useAdvanceSearchContext();

  const [selectedComponents, setSelectedComponents] = useState();

  const showNodata = useMemo(() => {
    const mappableCount = Object.entries(totalCountsQuickSearch);
    if (mappableCount.length === 12) {
      const anyHasData = mappableCount.find((item) => {
        return item[1] !== 0;
      });
      return !anyHasData;
    }
    return false;
  }, [totalCountsQuickSearch]);

  /**
   *
   * @param key
   * @returns {boolean}
   */
  const isActive = (key) => {
    return selectedComponents === key || !selectedComponents;
  };

  return (
    <>
      <Grid container className="px-2 pb-4 pt-2 h-full">
        <Grid
          item
          xs={12}
          md={7}
          className={"h-full"}
          style={{ overflowY: "auto" }}
        >
          {isActive("Labs") && <QuickSearchLab t={t} />}
          {isActive("Lab Programs") && <QuickSearchLabProgram t={t} />}
          {isActive("Lab Marketplace") && <QuickSearchLabMarketPlace t={t} />}
          {isActive("Challenges") && <QuickSearchChallenge t={t} />}
          {isActive("Challenge Paths") && <QuickSearchChallengePath t={t} />}
          {isActive("Challenge Templates") && (
            <QuickSearchChallengeTemplate t={t} />
          )}
          {isActive("Resource Modules") && <QuickSearchResourceModule t={t} />}
          {isActive("Resource Collections") && (
            <QuickSearchResourceCollection t={t} />
          )}
          {isActive("Resource Groups") && <QuickSearchResourceGroup t={t} />}
          {isActive("Projects") && <QuickSearchProject t={t} />}
          {isActive("Organization") && <QuickSearchOrganization t={t} />}
          {isActive("Users") && <QuickSearchUser t={t} />}
          {showNodata ? (
            <div className={"flex justify-center items-center h-full"}>
              <p className={"text-center c-black"}>
                {t("No result found. Please try changing the search keyword.")}
              </p>
            </div>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} md={5} className="border-l px-2 w-300">
          <p className="mt-2 fw-500 opacity-60">{t("FILTER BY TYPE")}</p>
          {search_filters.map(({ id, menu }) => (
            <div key={id} className="px-2 border-b py-1">
              {menu.map((d, i) => (
                <div key={i} className="flex items-center py-1">
                  <FormControlLabel
                    key={d?.value}
                    label={
                      <>
                        <div className="flex" style={{ gap: "5px" }}>
                          <span className="maxw-145 tracking-tight text-truncate fs-13">
                            {t(d?.label)}
                          </span>{" "}
                          {totalCountsQuickSearch[d?.value] ? (
                            <Chip
                              label={
                                <p className="fs-10">
                                  {formattedCount(
                                    totalCountsQuickSearch[d?.value] ?? 0
                                  )}
                                </p>
                              }
                              size="small"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    }
                    className={"fs-14--noMob"}
                    control={
                      <Radio
                        size={"small"}
                        className="py-0"
                        disabled={!totalCountsQuickSearch[d?.value]}
                        checked={selectedComponents === d?.label}
                        onChange={(event) => {
                          if (event?.target?.checked) {
                            setSelectedComponents(d?.label);
                          }
                        }}
                      />
                    }
                  />
                </div>
              ))}
            </div>
          ))}
          <Button
            onClick={() => {
              setKeyword(quickKeyword);
              setActiveMenu("labs");
              setSearchOpen(false);
              navigate(`/advanced-search?keyword=${quickKeyword}`);
              setTotalCounts(totalCountsQuickSearch);
            }}
            classes="mt-3 fs-14 mx-auto w-full"
            bordered
            disabled={showNodata}
          >
            {t("Advanced Search")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default QuickSearchResults;
