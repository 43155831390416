import React from "react";
import styles from "./input.module.scss";
import { MenuItem, Select as MuiSelect, Chip, Box } from "@mui/material";
import cx from "classnames";
import { createEvent } from "helpers/utils/utilities";

const Select = ({
  hide,
  value,
  multi,
  name,
  values,
  keyValues,
  placeholder,
  onChange,
  top,
  error,
  height,
  width,
  title,
  classes,
  required,
  borderedIcon,
  gray,
  disabled,
  description,
  t = (d) => d,
}) => {
  const handleDeleteValue = (newValue) => {
    const filteredSelectedOptions = value.filter(
      (option) => option !== newValue
    );
    onChange(createEvent(name, filteredSelectedOptions));
  };

  const getLabelByValue = (val) => {
    let _key = multi ? val : value;
    if (!_key) return t(placeholder) || "";
    if (!!values) return t(_key, "common");
    if (!!keyValues && Array.isArray(keyValues)) {
      const item = keyValues.find((d) => d.key == _key) || "";
      return item ? t(item?.value, "common") : "";
    }
  };
  const handleMenuItemClick = (e) => {
    let val = e.target.id;
    // if (!isNaN(val)) val = +val;
    onChange(createEvent(name, val == value ? undefined : val));
  };

  if (hide) return null;

  return (
    <div
      data-testid="select"
      style={{ marginTop: top }}
      className={cx(styles.inputContainer, classes)}
    >
      {!!title && (
        <p className={styles.title}>
          {t(title)}
          {!!required && <span>*</span>}
        </p>
      )}
      {!!description && <p className={styles.description}>{t(description)}</p>}
      <MuiSelect
        multiple={multi}
        sx={{
          height: !!height && height,
          width: !!width && width,
          background: !!gray && "#f5f5f5",
          "& .MuiSelect-select": {
            paddingLeft: "5px",
            paddingY: "9px",
            maxHeight: "110px",
            overflowY: "auto!important",
          },
        }}
        className={cx(
          styles.select,
          { bordered: borderedIcon },
          { [styles.error]: !!error }
        )}
        placeholder={t(placeholder)}
        displayEmpty={true}
        renderValue={
          multi
            ? () => (
                <Box sx={{ gap: 0.5 }} className={`flex flex-wrap`}>
                  {value?.map((selectedValue) => (
                    <Chip
                      key={selectedValue}
                      label={getLabelByValue(selectedValue)}
                      sx={{ fontSize: "0.7rem", height: "25px" }}
                      className="mr-1 tracking-tight fw-500"
                      onMouseDown={(event) => event.stopPropagation()}
                      onDelete={() => handleDeleteValue(selectedValue)}
                    />
                  ))}
                </Box>
              )
            : getLabelByValue
        }
        onChange={multi && onChange}
        name={name}
        value={multi ? (Array.isArray(value) ? value : []) : value}
        disabled={disabled}
      >
        {!!values &&
          values?.map((val, i) => (
            <MenuItem
              value={val}
              key={i}
              className={`${i === 0 && "selected"}`}
              id={val}
              onClick={!multi && handleMenuItemClick}
            >
              {t(val, "common")}
            </MenuItem>
          ))}
        {!!keyValues &&
          keyValues?.map(({ key, value }) => (
            <MenuItem
              key={key}
              value={key}
              id={key}
              onClick={!multi && handleMenuItemClick}
            >
              {t(value, "common")}
            </MenuItem>
          ))}
      </MuiSelect>
      {error && <p className={styles.error}>{t(error, "common")}</p>}
    </div>
  );
};

export default Select;
