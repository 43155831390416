import { CopyAll, Delete, Edit, Settings } from "@mui/icons-material";
import { Icon } from "@mui/material";
import MenuButton from "components/Button/MenuButton";
import useRedirect from "helpers/hooks/useRedirect";
import React from "react";
import { useTranslation } from "react-i18next";

const ResourceManageOptions = ({
  data,
  icon,
  handleClone,
  onDelete,
  isCollection,
  isGroup,
}) => {
  const { navigateEditResourceC, navigateEditResourceG, navigateEditResource } =
    useRedirect();
  const { t } = useTranslation("common");
  return (
    <MenuButton
      listWithTitleonClick={[
        {
          title: "Edit",
          onClick: () =>
            isCollection
              ? navigateEditResourceC(data?.slug)
              : isGroup
              ? navigateEditResourceG(data?.slug)
              : navigateEditResource(data?.slug),
          icon: Edit,
        },
        handleClone && {
          title: "Duplicate",
          onClick: () => {
            handleClone(data?.slug);
          },
          icon: CopyAll,
        },
        {
          title: "Delete",
          onClick: () => onDelete(data?.slug),
          icon: Delete,
          iconClasses: "c-error",
          classes: "c-error",
        },
      ].filter(Boolean)}
      classes="fs-14"
      height="32px"
      icon={Boolean(icon)}
    >
      <Icon component={icon || Settings} fontSize="small" />{" "}
      {!icon && t("Manage")}
    </MenuButton>
  );
};

export default ResourceManageOptions;
