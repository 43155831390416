import { Grid, Chip, Tooltip } from "@mui/material";
import MainProductCard from "components/Cards/MainProductCard";
import React, { useState } from "react";
import {
  Settings,
  Group,
  Delete,
  ShoppingCartCheckout,
  Done,
} from "@mui/icons-material";
import { COLORS, COMPONENT_TYPES } from "helpers/utils/constants";
import MenuButton from "components/Button/MenuButton";
import useRedirect from "helpers/hooks/useRedirect";
import ListingContainer from "components/UI/organisms/ListingContainer";
import useOrganization from "store/organization/service-hook";
import { isTrue } from "helpers/utils/validators";
import useAuth from "store/auth/service-hook";
import { loginToPreceed } from "helpers/utils/utilities";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { lab } from "assets/svg/blue";
import Button from "components/Button";
import { PlanUsagePopUp } from "components/UI/molecules/PlanDetails";
import { manageOrgMenuItems } from "../forms/data";
import { resource } from "assets/svg/purple";
import { challenge } from "assets/svg/green";
import AcceptDeclineButtons from "components/UI/atoms/AcceptDeclineButtons";
import VerifiedIcon from "@mui/icons-material/Verified";

const OrgListing = ({
  gridType,
  list = [],
  setList = Function.prototype,
  handleDelete,
  loading,
  page,
  setPage,
  pageCount,
  manage,
  browseMode,
  fetchOrganizations,
}) => {
  const {
    navigateEditOrg,
    navigateMemberManagement,
    navigateOrganizationReports,
    navigateOrgIntegrationSettings,
  } = useRedirect();
  const [openPlanUsage, setOpenPlanUsage] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const { favorite, unFavorite, acceptDeclineOrg } = useOrganization();
  const {
    state: { isLoggedIn },
  } = useAuth();
  const { t } = useLocalizedTranslation("organization");

  const openPlanUsageModal = (org) => {
    setSelectedOrg(org);
    setOpenPlanUsage(true);
  };
  const cancelPlanUsageModal = () => {
    setOpenPlanUsage(false);
    setSelectedOrg(null);
  };
  const handlefetchPlanDetails = () => {
    fetchOrganizations();
    setOpenPlanUsage(null);
  };
  const doUndoAction = async (slug, property, doAction, undoAction) => {
    if (!isLoggedIn) {
      loginToPreceed();
      return;
    }
    const itemIndex = list.findIndex((d) => d.slug === slug);
    if (itemIndex === -1) return;
    const item = { ...list[itemIndex] };
    const did = isTrue(item[property]);
    if (did) {
      await undoAction(slug);
      item[property] = "no";
      item[property === "liked" ? "likes" : "followers"]--;
    } else {
      await doAction(slug);
      item[property] = "yes";
      item[property === "liked" ? "likes" : "followers"]++;
    }

    const updatedList = [...list];
    updatedList[itemIndex] = item;
    setList(updatedList);
  };

  const handleFavoriteUnFav = (slug) =>
    doUndoAction(slug, "favourite", favorite, unFavorite);

  const acceptOrg = async (slug) => acceptDeclineOrg(slug);
  const declineOrg = async (slug) => acceptDeclineOrg(slug, true);

  return (
    <>
      <ListingContainer
        loading={loading}
        isEmpty={!list?.length}
        page={page}
        setPage={setPage}
        count={pageCount}
        classes="mt-4"
        small
        emptyText={t(
          !manage && browseMode === 2
            ? "No pending invitations!"
            : "No Organizations found!"
        )}
      >
        {list.map((d, i) => {
          return (
            <Grid item key={i} xs={gridType === 1 ? 12 : 4}>
              <MainProductCard
                classes="mb-3"
                titleString={d?.title}
                title={
                  <div className="flex items-center">
                    <p
                      className={`text-nowrap overflow-hidden ${
                        gridType === 1 && "maxw-65p"
                      } textOverflow-ellipsis`}
                    >
                      {d?.title}
                    </p>
                    {isTrue(d?.is_verified) && (
                      <Tooltip
                        title={t("Verified Organization")}
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: 14,
                              bgcolor: COLORS.BLUE_OPAQUE,
                              "& .MuiTooltip-arrow": {
                                color: COLORS.BLUE_OPAQUE,
                              },
                              color: "#404252",
                            },
                          },
                        }}
                      >
                        <VerifiedIcon />
                      </Tooltip>
                    )}
                    {isLoggedIn && manage && (
                      <Chip
                        label={t(
                          d?.organization_details?.plan_name || "N/A",
                          "common"
                        )}
                        className="ml-1 fs-12 h-24 fw-400 w-fit"
                      />
                    )}
                  </div>
                }
                subTitle={
                  <div className="flex items-center flex-wrap my-2">
                    <p
                      className={
                        gridType === 1
                          ? "text-nowrap overflow-hidden maxw-70p textOverflow-ellipsis"
                          : "fs-14 tracking-tight leading-tight"
                      }
                    >
                      {t("Organization Type")}:{" "}
                      <span className="fw-500">{d?.category ?? "N/A"}</span>
                    </p>
                    <span className="vertical-align-middle mx-1">&middot;</span>
                    <p
                      className={
                        gridType !== 1 && "fs-14 leading-tight tracking-tight"
                      }
                    >
                      {t("Created by")}:{" "}
                      <span className="fw-500">
                        {d?.created_by?.full_name || "N/A"}
                      </span>
                    </p>
                  </div>
                }
                small
                hideDescription
                type={COMPONENT_TYPES.ORGANIZATION}
                description={d?.about}
                col={gridType === 1 ? false : true}
                image={d?.cover_image}
                id={d?.slug}
                iconsAndValuesList={[
                  {
                    icon: Group,
                    value: `${d?.organization_users_count || 0} ${t(
                      "Members",
                      "common"
                    )}`,
                  },
                  {
                    img: challenge,
                    value: `${d?.challenge_count || 0} ${t(
                      "Challenges",
                      "common"
                    )}`,
                  },
                  {
                    img: lab,
                    value: `${d?.lab_count || 0} ${t("Labs", "common")}`,
                  },
                  {
                    img: resource,
                    value: `${d?.resource_count ?? "0"} ${t(
                      "Resource Modules",
                      "common"
                    )}`,
                  },
                ]}
                rightAction={
                  isLoggedIn ? (
                    !!manage ? (
                      <div className="flex ">
                        <Button
                          classes="fs-12 mr-1 minw-100"
                          height="14px"
                          color="orange"
                          onClick={() => openPlanUsageModal(d)}
                        >
                          <ShoppingCartCheckout fontSize="small" />{" "}
                          {t("Plan Usage")}
                        </Button>
                        <MenuButton
                          listWithTitleonClick={manageOrgMenuItems(
                            d,
                            navigateOrganizationReports,
                            navigateMemberManagement,
                            navigateEditOrg,
                            navigateOrgIntegrationSettings,
                            handleDelete
                          )}
                          classes="fs-12"
                          height="14px"
                          width="100px"
                        >
                          <Settings className="mr-1" fontSize="small" />{" "}
                          {t("Manage", "common")}
                        </MenuButton>
                      </div>
                    ) : browseMode === 2 ? (
                      <AcceptDeclineButtons
                        onAccept={acceptOrg}
                        onDecline={declineOrg}
                        id={d?.slug}
                      />
                    ) : browseMode === 3 && isTrue(d?.favourite) ? (
                      <Button
                        bordered
                        color="red"
                        height="20px"
                        classes="fs-13"
                        onClick={() => handleFavoriteUnFav(d?.slug)}
                      >
                        <Delete fontSize="small" /> {t("Remove", "common")}
                      </Button>
                    ) : (
                      browseMode === 0 &&
                      isTrue(d?.favourite) && (
                        <Button
                          bordered
                          color="green"
                          height="30px"
                          classes="fs-13"
                        >
                          <Done sx={{ fontSize: 16 }} />
                          {t("Saved", "common")}
                        </Button>
                      )
                    )
                  ) : null
                }
                noSave={
                  manage ||
                  browseMode === 2 ||
                  browseMode === 3 ||
                  isTrue(d?.favourite)
                }
                onSave={() => handleFavoriteUnFav(d?.slug)}
              />
            </Grid>
          );
        })}
      </ListingContainer>
      {selectedOrg && (
        <PlanUsagePopUp
          details={selectedOrg}
          open={openPlanUsage}
          onClose={cancelPlanUsageModal}
          fetchPlanDetails={handlefetchPlanDetails}
        />
      )}
    </>
  );
};

export default OrgListing;
