import React from "react";
import useMaster from "../../../../store/master/service-hook";
import { useAdvanceSearchContext } from "../../hooks/useAdvanceSearchContext";
import SubFilter from "./SubFilter";
import { FilterComponentsList } from "../../data";

const SkillFilter = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const { fetchSkills } = useMaster();
  const { skill, setSkill, ActiveFilterComponents } = useAdvanceSearchContext();

  return (
    <SubFilter
      title={t("SKILL")}
      fetchData={fetchSkills}
      values={skill}
      select
      onChange={(event) => {
        setSkill(
          event?.target?.value && event?.target?.value.length > 0
            ? event?.target?.value
            : []
        );
      }}
      valueKey={"id"}
      labelKey={"title"}
      show={ActiveFilterComponents.includes(FilterComponentsList.skill)}
      t={t}
    />
  );
};

export default SkillFilter;
