import { Rating } from "@mui/material";

export const INFO_TYPES = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  PRIMARY: "primary",
};

export const COLORS = {
  BLUE: "#498CCE",
  GREEN: "#27AE60",
  SUCCESS: "#1BC459",
  RED: "#FD5959",
  RED_OPAQUE: "#f89d9d09",
  GREEN_OPAQUE: "#E7FFED",
  BLUE_OPAQUE: "#D6F2FE",
  BG_GRAY: "#F3F7FC",
  GRAY: "#c4c4c4",
  YELLOW: "#FFBC3F",
  LIGHT_YELLOW: "#FFFCF3",
  DARK_BLUE: "#0A2B77",
  DARK_GREEN: "#055E49",
  DARK_RED: "#7A0D3F",
  MUD: "#934900",
  PURPLE: "#9E31E1",
  PURPLE_OPAQUE: "#F0D8FF",
  ERROR: "#FF7575",
  ORANGE: "#ffa841",
  WHITE: "#FFFFFF",
  DARK_GRAY: "#5B5D6B",
  ORANGE_OPAQUE: "#FFF0DE",
  LIGHT_GRAY: "#f3f4f8",
  DARK_SKY_BLUE: "#1B58B3",
};

export const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export const COMPONENT_TYPES = {
  LAB: "lab",
  CHALLENGE: "challenge",
  PROJECT: "project",
  RESOURCE: "resource",
  ORGANIZATION: "organization",
  USER: "user",
  CHALLENGE_PATH: "challenge-path",
  LAB_PROGRAMS: "lab-program",
  RESOURCE_COLLECTION: "resource-collection",
  RESOURCE_GROUP: "resource-group",
  SKILL: "skill",
  JOB: "job",
  PROFILE: "profile",
  ACHIEVEMENT: "achievements",
  TEAM_MATCHING: "team-matching",
  TERMS: "terms",
  PRIVACY_POLICY: "privacy-policy",
  REPORT: "report",
  RECOMMENDATIONS: "recommendations",
  LAB_MARKETPLACE: "lab-marketplace",
  CHALLENGE_TEMPLATE: "challenge-template",
};

export const USER_PERMISSIONS = [
  { value: "Challenge Manager", key: "challenge_manager" },
  { value: "Organzation Manager", key: "organization_manager" },
  { value: "Project Manager", key: "project_manager" },
  { value: "Associate", key: "associate" },
  { value: "Developer", key: "developer" },
];

export const LANGUAGES = [
  {
    value: "English",
    key: "en",
  },
  {
    value: "French",
    key: "fr-CA",
  },
];

export let STATUS = [
  { value: "Draft", key: "draft" },
  { value: "Active", key: "active" },
  { value: "Closed", key: "deactivated" },
  { value: "Completed", key: "archived" },
];

export const CHALLENGE_PATH_STATUS = [
  { value: "Draft", key: "draft" },
  { value: "Published", key: "published" },
];

export const SOCIAL_TYPE = [
  {
    value: "Liked",
    key: "liked",
  },
  {
    value: "Followed",
    key: "followed",
  },
  {
    value: "Saved",
    key: "favourites",
  },
];

export const ACHIEVEMENT_TYPES = [
  {
    value: "Awarded Achievements",
    isSubtitle: true,
  },
  {
    value: "Appreciation Award",
    key: "appreciation",
  },
  {
    value: "Community Award",
    key: "community",
  },
  {
    value: "System Achievements",
    isSubtitle: true,
  },
  {
    value: "Activity Award",
    key: "activity",
  },
  {
    value: "Skill Activity Award",
    key: "skill-activity",
  },
  // {
  //   value: "Micro-credential Award",
  //   key: "micro-credential",
  // },
  // {
  //   value: "Imported / Third Party Award",
  //   key: "imported",
  // }
];

export const ACHIEVEMENT_LEVEL = [
  {
    value: "Lab Program",
    key: "lab-program",
  },
  {
    value: "Lab",
    key: "lab",
  },
  {
    value: "Challenge Path",
    key: "challenge-path",
  },
  {
    value: "Challenge",
    key: "challenge",
  },
  {
    value: "Resource Group",
    key: "resource-group",
  },
];

export const ACHIEVEMENT_PLACEMENT = [
  {
    value: "Winner Award",
    key: "winner",
  },
  {
    value: "Participation Award",
    key: "participation",
  },
];

export const AWARD_STATUS = [
  {
    value: "Locked",
    key: "locked",
  },
  {
    value: "Unlocked",
    key: "unlocked",
  },
  {
    value: "In Progress",
    key: "in_progress",
  },
];

export const PRIVACY_STATUS = [
  {
    value: "Private",
    key: "private",
  },
  {
    value: "Public",
    key: "public",
  },
];

export const LAB_PROGRAMS_FILTER = [
  {
    value: "Labs",
    key: COMPONENT_TYPES.LAB,
    link: `/${COMPONENT_TYPES.LAB}`,
  },
  {
    value: "Lab Programs",
    key: COMPONENT_TYPES.LAB_PROGRAMS,
    link: `/${COMPONENT_TYPES.LAB_PROGRAMS}`,
  },
];

export const CHALLENGE_PATH_FILTER = [
  {
    value: "Challenges",
    key: COMPONENT_TYPES.CHALLENGE,
    link: `/${COMPONENT_TYPES.CHALLENGE}`,
  },
  {
    value: "Challenge Paths",
    key: COMPONENT_TYPES.CHALLENGE_PATH,
    link: `/${COMPONENT_TYPES.CHALLENGE_PATH}`,
  },
];

export const RESOURCE_FILTER = [
  {
    value: "Resource Module",
    key: COMPONENT_TYPES.RESOURCE,
    link: `/${COMPONENT_TYPES.RESOURCE}`,
  },
  {
    value: "Resource Collection",
    key: COMPONENT_TYPES.RESOURCE_COLLECTION,
    link: `/${COMPONENT_TYPES.RESOURCE_COLLECTION}`,
  },
  {
    value: "Resource Group",
    key: COMPONENT_TYPES.RESOURCE_GROUP,
    link: `/${COMPONENT_TYPES.RESOURCE_GROUP}`,
  },
];

export const SORT = [
  {
    value: "Name A to Z",
    key: "name-a-to-z",
  },
  {
    value: "Name Z to A",
    key: "name-z-to-a",
  },
  {
    value: "Created Date",
    key: "creation_date",
  },
];

export const ADVANCE_SEARCH_SORT = [
  {
    key: "relevance",
    value: "Relevance",
  },
  {
    key: "created_data_asc",
    value: "Oldest",
  },
  {
    key: "created_data_desc",
    value: "Newest",
  },
];

export const EMBED_MEDIA_TYPES = [
  { value: "Embed Audio", key: "embedded_audio" },
  { value: "Embed Video", key: "embedded_video" },
  // { value: "Embed URL", key: "url" },
];

export const GENDER = [
  { value: "Male", key: "male" },
  { value: "Female", key: "female" },
  { value: "Other", key: "other" },
  { value: "Decline to Answer", key: "decline_to_answer" },
];

export const LAB_REDEEM_STATUS = [
  { value: "Available to Redeem", key: "not_redeemed" },
  {
    value: "Redeemed",
    key: "redeemed",
  },
];

export const BASE_URLS = {
  LOCAL: "http://localhost:3000",
  BETA: "https://dev.learnlab.ai",
};

export const SCHEMA_TYPES = {
  INPUT: "input",
  FILE: "file",
  TEXTBOX: "textbox",
  MASTER: "master",
  SELECT: "select",
  RADIO: "radio",
  CHECKBOX: "checkbox",
  DATE: "date",
  TIME: "time",
  NETWORK_USERS: "network_users",
  FILE_BUTTON: "file-button",
  GOOGLE_PLACES: "google-places",
  UPPY: "uppy",
  SWITCH: "switch",
  EDITOR: "editor",
  PASSWORD_INPUT: "password_input",
};

export const TIMEZONES = [
  { value: "Eastern Time (Canada)", key: "EST" },
  { value: "India Standard Time", key: "IST" },
];

export const VISIBILITY = [
  { value: "Public (Anyone)", key: "public" },
  { value: "Visible to signed-in users", key: "signed-in" },
  { value: "Private(No one)", key: "private" },
];

export const PROJECT_STATUS = [
  { value: "In Progress", key: "in_progress" },
  { value: "Submitted", key: "submitted" },
  { value: "Challenge Closed", key: "challenge_closed" },
  {
    value: "Assessment details available",
    key: "assessment_details_available",
  },
];

export const REQUEST_STATUS = [
  { value: "Available to join", key: "available" },
  { value: "Request Sent", key: "request_sent" },
];

export const CAREERS_FILTER = [
  { value: "Recommendations", key: "recommendations" },
  { value: "Jobs", key: "jobs" },
  { value: "Skills", key: "skills" },
];

export const ONBOARDING_VIDEO = {
  en: "https://preprlocaldevelopmentnew.s3.ca-central-1.amazonaws.com/public/assets/onboarding/en.mp4",
  fr: "https://preprlocaldevelopmentnew.s3.ca-central-1.amazonaws.com/public/assets/onboarding/fr.mp4",
};

export const CONTENT_TYPES = [
  {
    value: "Course",
    key: "course",
  },
  {
    value: "Award",
    key: "award",
  },
  {
    value: "Playlist",
    key: "playlist",
  },
  {
    value: "Document",
    key: "document",
  },
  {
    value: "Link",
    key: "link",
  },
  {
    value: "Interactive",
    key: "interactive",
  },
  {
    value: "Text",
    key: "text",
  },
  {
    value: "Video",
    key: "video",
  },
  {
    value: "Audio",
    key: "audio",
  },
  {
    value: "Integration",
    key: "integration",
  },
];

export const GO1_DURATIONS = [
  {
    value: "< 15min",
    key: JSON.stringify({ max: 15 }),
  },
  {
    value: "15mins to 30mins",
    key: JSON.stringify({ min: 15, max: 30 }),
  },
  {
    value: "30mins to 60mins",
    key: JSON.stringify({ min: 30, max: 60 }),
  },
  {
    value: "> 60mins",
    key: JSON.stringify({ min: 60 }),
  },
];

export const SOCIAL_ACTIVITY = [
  {
    value: "Liked",
    key: "liked",
  },
  {
    value: "Favourites",
    key: "favourites",
  },
  {
    value: "Shared",
    key: "shared",
  },
];

export const CAREERS_STATUS = [
  { value: "Saved", key: "saved" },
  { value: "Not saved yet", key: "unsaved" },
];

export const VERIFICATION_STATUS = [
  {
    value: "Verified",
    key: "yes",
  },
  {
    value: "Not Verified",
    key: "no",
  },
];

export const AUTHOR = [
  {
    value: "Created by you",
    key: "my",
  },
  {
    value: "Invited to join",
    key: "invited",
  },
];

export const PLAN = [
  {
    value: "Seed Plan(free)",
    key: "seed_plan_yearly",
  },
  {
    value: "Sprout Plan",
    key: "sprout_plan_yearly",
  },
  {
    value: "Budd Plan",
    key: "budd_plan_yearly",
  },
  {
    value: "Bloom Plan",
    key: "bloom_plan_yearly",
  },
  {
    value: "Enterprise Plan",
    key: "unlimited_plan",
  },
];

export const TYPE = [
  { value: "Assess", key: "assess" },
  { value: "Onboard", key: "onboard" },
  { value: "Engage", key: "engage" },
  { value: "Grow", key: "grow" },
];

export const generateRatings = () => {
  return [1, 2, 3, 4, 5].map((value) => ({
    value: <Rating value={value} readOnly />,
    key: value,
  }));
};

export const PROGRESS = [
  { value: "Not Started", key: "not-started" },
  { value: "In Progress", key: "in-progress" },
  { value: "Completed", key: "complete" },
];

export const COMPANY_SIZE = [
  { value: "less than 50 employees", key: "1" },
  { value: "50-250 emplayees", key: "2" },
  { value: "250-1000 employees", key: "3" },
  { value: "over 1000 employees", key: "4" },
];

export const SOURCE = [
  { value: "Onboarding Challenges", key: "onboarding_challenges" },
  { value: "Created by you", key: "created_by_you" },
  { value: "Created by Organizations", key: "created_by_organizations" },
  { value: "Cloned by you", key: "cloned_by_you" },
];

export const LAB_SOURCE = [
  { value: "Onboarding Labs", key: "onboarding_labs" },
  { value: "Created by you", key: "created_by_you" },
  { value: "Created by Organization", key: "created_by_organization" },
  { value: "Redeemed from Marketplace", key: "redeemed_labs" },
];

export const RESOURCE_SOURCE = [
  { value: "Created by you", key: "created_by_you" },
  { value: "Created by Organization", key: "created_by_organization" },
  { value: "Cloned by you", key: "cloned_by_you" },
];

export const LAB_MARKETPLACE_STATUS = [
  { value: "All", key: "all" },
  { value: "Added to lab marketplace", key: "added" },
  {
    value: "Not added to Lab marketplace",
    key: "pending",
  },
];
export const CHALLENGE_PATH_SOURCE = [
  { value: "Created by you", key: "created_by_you" },
  { value: "Created by Organization", key: "created_by_organization" },
];

export const TEMPLATE_STATUS = [
  { value: "All", key: "all" },
  { value: "Added to Challenge library", key: "added" },
  {
    value: "Not Added to Challenge library",
    key: "pending",
  },
];

export const PROJECT_PROGRESS_STATUS = [
  { value: "Submitted", key: "submitted" },
  { value: "Not Submitted", key: "not_submitted" },
  { value: "Late Submitted", key: "late_submitted" },
  { value: "In Progress", key: "in_progress" },
  { value: "Not Started", key: "not_started" },
];

export const PROJECT_PERMISSIONS = [
  { value: "Team Leader", key: "team_leader" },
  { value: "Viewer", key: "viewer" },
  { value: "Editor", key: "editor" },
];

export const RECRUITING_STATUS = [
  {
    value: "Currently Recruiting",
    key: "yes",
  },
  {
    value: "Currently Not Recruiting",
    key: "no",
  },
];

export const LAB_PROGRESS = [
  { value: "Not Started", key: "not_started" },
  { value: "In Progress", key: "in_progress" },
  { value: "Completed", key: "completed" },
];

export const ROLES = {
  ORGANIZATION_OWNER: "organization_owner",
  SUPER_ADMIN: "super_admin",
  CUSTOMER_SUCCESS: "customer_success",
  DEVELOPER: "developer",
  ORGANIZATION_MANAGER: "organization_manager",
  LAB_MANAGER: "lab_manager",
  CHALLENGE_MANAGER: "challenge_manager",
  RESOURCE_MANAGER: "resource_manager",
  USER: "user",
};

export const SUBMISSION_STATUS = [
  { value: "All", key: "all" },
  { value: "Ongoing", key: "in_progress" },
  { value: "Submitted", key: "submitted" },
  { value: "Late Submitted", key: "late_submitted" },
];

export const REPORT_PER_PAGE = 10;
