import React from 'react'
import AdvanceSearchMain from "./listings";
import DashboardLayout from "../layouts/dashboard";

const AdvanceSearch = () => {
    return (
            <DashboardLayout>
                <AdvanceSearchMain/>
            </DashboardLayout>
    )
}

export default AdvanceSearch
