import { GroupAdd } from '@mui/icons-material'
import { Grid } from '@mui/material';
import ProductCard from 'components/Cards/ProductCard'
import Select from 'components/Input/Select'
import { prebuilt_labs } from 'pages/organization/forms/data'
import prebuiltLab_img from '../../../../assets/png/prebuiltLab.png';
import React from 'react'
import { Free } from 'components/Statics'

const LabList = ({ selectedLabs, setSelectedLabs }) => {
    return (
        <Grid container columnSpacing={2} rowSpacing={1} className='mt-4 px-2'>
            {
                prebuilt_labs.map((lab, i) =>
                    <ProductCard
                        key={i}
                        selected={selectedLabs}
                        setSelected={setSelectedLabs}
                        id={i}
                        tag={lab?.isPaid ? "Premium" : false} >
                        <img src={prebuiltLab_img} className='h-70p w-100p rounded-5' alt='img of prebuilt lab' />
                        <div className='flex items-center justify-between'>
                            <p>{lab?.name}</p>
                            {lab?.isPaid ? <p><b>${lab?.cost}</b></p> : <Free />}
                        </div>
                        <div className='flex items-center justify-between mt-2'>
                            <Select
                                height={30}
                                width={180}
                                value={lab?.seats}
                                values={[lab?.seats, 10, 100, 1000]}
                            />
                            <GroupAdd color="primary" fontSize='large' />
                        </div>
                    </ProductCard>
                )
            }
        </Grid>
    )
}

export default LabList