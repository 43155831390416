import Card from "components/Cards/Card"
import { challenge } from "assets/svg/green";
import { skills } from "assets/svg/yellow";
import { lab } from "assets/svg/blue";
import useRedirect from "helpers/hooks/useRedirect";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const SkillCard = ({ item }) => {
  const { t } = useLocalizedTranslation();
  const { navigateSkill } = useRedirect();
  return (
    <Card classes="flex justify-center-start-mob px-1 py-2">
      <img src={skills} alt="skill_icon" />
      <div className="ml-5">
        <h4 className="link" onClick={() => navigateSkill(item?.id)}>{item.title}</h4>
        <div className="flex justify-between mt-1">
          <div className="flex-center">
            <img src={challenge} className="w-20 h-20 object-cover" alt="challenge_icon" />
            <p className="fs-12 opacity-60 ml-1">{item?.related_challenges} {t("Challenges")}</p>
          </div>
          <div className="flex-center ml-3">
            <img src={lab} className="w-20 h-20 object-cover" alt="lab_icon" />
            <p className="fs-12 ml-1 opacity-60">{item?.related_labs} {t("Labs")}</p>
          </div>
        </div>
      </div>
    </Card>
  )
}
export default SkillCard
