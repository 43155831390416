import useChallengeReport from "../../../../store/reports/challenges/service-hooks";
import useChallengeReportContext from "../hooks/useChallengeReportContext";
import useRedirect from "../../../../helpers/hooks/useRedirect";
import React, { useEffect, useState } from "react";
import DataTable from "../../components/Datatable";
import { Avatar } from "@mui/material";
import { capitalize } from "lodash";
import moment from "moment";
import UserIcon from "assets/svg/purple/profile.svg";
import EmptyText from "components/UI/atoms/EmptyText";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import Card from "components/Cards/Card";

const translateActivity = (activity, t = (d) => d) => {
  if (activity?.includes("Inactive for")) {
    const number = activity?.split(" ")?.reverse()?.[1];
    return t("Inactive for ${number} days", "reports", {
      number: number,
    });
  } else {
    return t(activity);
  }
};

const ChallengeMembers = ({ t = (d) => d }) => {
  const { challengeMembers } = useChallengeReport();
  const { challengeSlug } = useChallengeReportContext();
  const { navigateProfile, navigateMemberManagement, navigateProject } =
    useRedirect();

  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    if (challengeSlug) {
      const response = await challengeMembers(challengeSlug, page);
      if (response) {
        setData(response);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <Card>
      <DataTable
        data={data?.data ?? []}
        loading={loading}
        page={data?.meta?.current_page ?? 1}
        perPage={data?.meta?.per_page ?? 15}
        totalPages={data?.meta?.total_pages ?? 1}
        totalCount={data?.meta?.total}
        onPageChange={(page) => setPage(page)}
        emptyText={
          <EmptyText
            classes="fs-14"
            onClick={() =>
              navigateMemberManagement(COMPONENT_TYPES.CHALLENGE, challengeSlug)
            }
            isButtonPresent={true}
            buttonLabel={t("Manage Members")}
            icon={ManageAccountsIcon}
          >
            {t("There is no member in this Challenge now")}
          </EmptyText>
        }
        columns={[
          {
            title: t("Name", "common"),
            dataIndex: "full_name",
            render(name, _, row) {
              return (
                <div className={"flex items-center"} style={{ gap: "10px" }}>
                  {row?.profile_image ? (
                    <Avatar src={row?.profile_image} size={15} />
                  ) : (
                    <Avatar src={UserIcon} size={15} />
                  )}
                  <p
                    className={`c-primary ${
                      row?.username ? "cursor-pointer" : ""
                    } text-nowrap`}
                    onClick={() => {
                      if (row?.username) {
                        navigateProfile(row?.username);
                      }
                    }}
                  >
                    {name}
                  </p>
                </div>
              );
            },
          },
          {
            title: t("Username", "common"),
            dataIndex: "username",
          },
          {
            title: t("Email", "common"),
            dataIndex: "email",
            render(email) {
              return (
                <a href={`mailto:${email}`} className="underline c-primary">
                  {email}
                </a>
              );
            },
          },
          {
            title: t("Invitation status", "common"),
            dataIndex: "invitation_status",
            render(status) {
              return (
                <p
                  className={
                    status === "rejected" || status === "declined"
                      ? "text-danger"
                      : ""
                  }
                >
                  {t(capitalize(status), "common")}
                </p>
              );
            },
          },
          {
            title: t("Account Activity", "common"),
            dataIndex: "activity",
            render(activity) {
              return (
                <p
                  className={
                    activity?.includes("In Active") ||
                    activity?.includes("Inactive")
                      ? "text-danger"
                      : ""
                  }
                >
                  {translateActivity(activity, t)}
                </p>
              );
            },
          },
          {
            title: t("Project Title", "common"),
            dataIndex: "project_title",
            render(title, _, row) {
              return (
                <p
                  className={`c-primary ${
                    row?.project_slug ? "cursor-pointer" : ""
                  } text-nowrap`}
                  onClick={() => {
                    if (row?.project_slug) {
                      navigateProject(row?.project_slug);
                    }
                  }}
                >
                  {title}
                </p>
              );
            },
          },
          {
            title: t("Project Start Date", "common"),
            dataIndex: "project_start_date",
            render(date) {
              return <p>{date ? moment(date).format("YYYY-MM-DD") : "-"}</p>;
            },
          },
          {
            title: t("Achievement", "common"),
            dataIndex: "achievement",
          },
          {
            title: t("Image/Videos", "common"),
            dataIndex: "project_item_count",
            render(date) {
              return <p>{date?.images_videos ?? ""}</p>;
            },
          },
          {
            title: t("Files", "common"),
            dataIndex: "files",
            render(date) {
              return <p>{date?.files ?? "0"}</p>;
            },
          },
          {
            title: t("Engagements", "common"),
            dataIndex: "engagements",
            render(date) {
              return <p>{date?.engagements ?? "0"}</p>;
            },
          },
        ]}
      />
    </Card>
  );
};

export default ChallengeMembers;
