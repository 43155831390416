import { Grid } from "@mui/material";
import Button from "components/Button";
import InfoCard from "components/Cards/InfoCard";
import ModalLayout from "components/Modal";
import IconText from "components/UI/atoms/IconText";
import SecureImage from "components/UI/atoms/SecureImage";
import { useState } from "react";
import { AccessAlarm } from "@mui/icons-material";
import { INFO_TYPES } from "helpers/utils/constants";
import moment from "moment";
import goldCupTrophy from "assets/png/goldTrophy.png";
import useRedirect from "helpers/hooks/useRedirect";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { project as projectIcon } from "assets/png/placeholders";
import TextBox from "components/Input/Textbox";

const ProjectSubmissionModal = ({ open, onClose, project, submit, lateSubmissionMessage, setLateSubmissionMessage, isDueDatePassed }) => {
  const { t } = useLocalizedTranslation();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { navigateAchievements } = useRedirect();
  const {
    media,
    requirement_status,
    title,
    challenge_details,
    challenge_achievement,
  } = project;

  const onCancel = () => {
    if (step === 0) onClose();
    else setStep(step - 1);
  };

  const onContinue = async () => {
    if (step === 0) setStep(1);
    if (step === 1) {
      setLoading(true);
      const res = await submit();
      if (!res) {
        setLoading(false);
        return;
      }

      setLoading(false);
      !!project?.challenge_achievement?.achievement_name
        ? setStep((prev) => prev + 1)
        : onClose();
    }
  };

  const formattedRequirements = requirement_status.map((d) => ({
    title: d["Requirement Title"],
    status: d.status,
  }));

  const allRequirementsCompleted = formattedRequirements.every(
    (req) => req.status === "completed"
  );

  const handleClose = () => {
    setStep(0);
    onClose();
  };

  return (
    <ModalLayout open={open} width="min(95%,900px)" onClose={handleClose}>
      <div className="px-4 py-4">
        {step === 0 && (
          <div>
            <h2>{t("Project Agreement")}</h2>
            <p className="mt-2">
              {t(
                "Before submitting your Project, please review the following agreement carefully"
              )}{" "}
            </p>
            <div className="fs-15 rounded-5 border my-6 px-2 py-2 h-260 overflowY-auto">
              <p
                className="text-italic c-title"
                dangerouslySetInnerHTML={{
                  __html: challenge_details?.agreement || t("N/A"),
                }}
              ></p>
            </div>
          </div>
        )}
        {step === 1 && (
          <div>
            <h2>{t("Confirm Your Project Submission")}</h2>
            <p className="my-2">
              {t(
                "Please review your project details carefully before submission."
              )}
            </p>
            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12} sm={5}>
                <SecureImage
                  src={media}
                  classes="object-cover w-100p h-200 rounded-5"
                  placeholder={projectIcon}
                  title={project?.title}
                />
              </Grid>
              <Grid item sm={7} xs={12}>
                <div>
                  <h2 className="link fw-700">{title}</h2>
                  <h3 className="my-2 fs-18">
                    {t("Submit to")} :{" "}
                    <span className="link">{challenge_details?.title}</span>
                  </h3>
                  <IconText
                    icon={<AccessAlarm />}
                    text={`Due on ${moment(challenge_details?.due_date).format(
                      "MMMM DD YYYY"
                    )}`}
                  />
                  <InfoCard
                    classes="mt-4"
                    title={
                      allRequirementsCompleted
                        ? t("Your project is ready for submission.")
                        : t("Complete all tasks before submission")
                    }
                    type={
                      allRequirementsCompleted
                        ? INFO_TYPES.SUCCESS
                        : INFO_TYPES.ERROR
                    }
                    list={formattedRequirements}
                    hasObjects
                  />
                </div>
              </Grid>
            </Grid>
            {isDueDatePassed && (
              <TextBox
                classes="my-4"
                multiline
                title={"Late Submission Reason"}
                required
                height={"100px"}
                value={lateSubmissionMessage}
                onChange={(e) => setLateSubmissionMessage(e.target.value)}
              />
            )}
          </div>
        )}
        {step === 2 && (
          <div className="w-70p w-90p--mob flex-column items-center mx-auto my-4">
            <SecureImage
              classes="object-cover rounded-100 border px-1 py-1 w-150 h-150 my-2"
              src={challenge_achievement?.achievement_image}
              placeholder={goldCupTrophy}
            />
            <h2 className="my-4 fs-18--mob text-center">
              {t(
                "Congratulations! You have submitted your Project and won a Challenge achievement."
              )}
            </h2>
            <p className="my-3 fs-14--mob text-center">
              {t(
                "Please go to achievement page to view the achievement you just gained."
              )}
            </p>
            <Button classes="mt-5" height="40px" onClick={navigateAchievements}>
              {t("Browse Achievements")}
            </Button>
          </div>
        )}

        {(step === 0 || step === 1) && (
          <div className="flex justify-between items-center mt-8 mb-3">
            <Button height={45} width={150} bordered onClick={onCancel}>
              {step === 0 ? t("Cancel") : t("Back")}
            </Button>
            <Button
              loading={loading}
              height={45}
              width={160}
              onClick={onContinue}
              color={step === 0 ? "blue" : "green"}
            >
              {step === 0 ? t("Accept & Continue") : t("Submit Project")}
            </Button>
          </div>
        )}
      </div>
    </ModalLayout>
  );
};
export default ProjectSubmissionModal;
