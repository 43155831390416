export const ENDPOINTS = {
  AUTH: "auth",
  LOGIN: "login",
  REGISTER: "register",
  SET_USER: "set-user",
  VERIFY_OTP: "verify-account",
  FORGOT_PASSWORD: "forget-password",
  RESET_PASSWORD: "reset-password",
  CHECK_EMAIL: "check-email",
  CHECK_USERNAME: "check-username",
  CHECK_PHONE: "check-phone",
  SSO_LOGIN: "sso-login",
  SSO: "sso",
  AUTHENTICATION: "authentication",
  GET_ORGANIZATION_LIST: "get-organization-list",
  ORGANIZATION_PREFRENCE: "organization-preference",

  //COMMON MASTER

  COMMON_MASTER: "master",
  SOCIAL_LINK: "links",
  LAB_CONDITION: "lab-conditions",
  PITCH_TEMPLATE: "pitch-templates",
  CHALLENGE_PITCH_TASK: "check-pitch-task",
  FLEXIBLE_DATE_DURATION: "flexible-date-duration",
  HOST: "host",
  ACHIEVEMENT_CONDITION: "achievement-condition-list",
  PROJECT_SUBMISSION: "project-submission-requirement",
  SUBMISSION: "project-submission",
  SKILL_GROUP: "skill-groups",
  RANKS: "ranks",
  SKILL_SET: "skill-stacks",
  CATEGORIES: "categories",
  SKILLS: "skills",
  TAGS: "tags",
  INDUSTRIES: "industries",
  TYPES: "types",
  STAGES: "stages",
  VERTICALS: "verticals",
  PROJECT_STATUS: "status",
  SOCIAL_CONNECT: "social-connect",
  DURATIONS: "durations",
  LEVELS: "levels",
  CREATE_SPONSER: "create-sponsor",
  TAG_GROUPS: "tag-groups",
  ANNOUNCEMENT_RECIPIENT: "challenge-announcement-recipient",
  JOB_TITLES: "job-titles",
  BUSINESS_CHALLENGE_TACKLINGS: "business-challenge-tacklings",
  AI: "ai",
  PREVIEW: "preview",
  SKILL: "skill",
  PROJECT_ASSESSED: "project-assessed",
  //ORGANIZATION

  ORGANIZATION: "organization",
  CREATE: "create",
  VIEW: "view",
  DELETE: "delete",
  UPDATE: "update",
  MANAGE: "manage",
  CHECK_SLUG: "check-slug",
  CHECK_TITLE: "check-title",
  GET_LIST: "get-list",
  PUBLIC: "public",
  FOLLOW: "follow",
  UNFOLLOW: "un-follow",
  SHARA: "share",
  FAVOURITE: "favourite",
  UNFAVOURITE: "un-favourite",
  UNLIKE: "un-like",
  LIKE: "like",
  SUBSCRIPTION_DETAILS: "subscription-details",
  SELECT_PLAN: "select-plan",
  CUSTOMIZATION: "customization",
  COMPARE_PLANS: "compare-plans",

  //MEMBER MANAGEMENT
  MEMBER_MANAGEMENT: "member-management",
  MEMBER_ACTIVITY: "member-activity",
  GET_ROLES: "get-roles",
  DOWNLOAD_SMAPLE: "download-sample",
  USER: "user",
  CHANGE_ROLE: "change-role",
  DELETE_ALL_MEMBERS: "delete-all-members",
  APPROVE_ALL_PENDING_REQUESTS: "approve-all-pending-join-requests",

  //LABS
  LAB: "lab",
  JOIN: "join",
  UNJOIN: "un-join",
  REQUEST: "request",
  ACCEPT: "accept",
  DECLINE: "decline",
  INVITATION_MANAGEMENT: "invitation-management",
  SHARE: "share",
  AIRMEET: "airmeet",

  //LAB PROGRAMS
  LAB_PROGRAM: "lab-program",

  //CHALLENGE
  CHALLENGE: "challenge",
  ASSESSMENT: "assessment",
  ASSESSMENTS: "assessments",
  CLONE: "clone",
  ANNOUNCEMENT: "announcement",
  LIST: "list",

  //CHALLENGE PATHS
  CHALLENGE_PATH: "challenge-path",

  //RESOURCE
  RESOURCE_MODULE: "resource-module",
  ADD_RATING: "add-rating",
  ADD_LINKS: "add-links",
  UPLOAD: "upload",
  MEDIA: "media",
  RESOURCE: "resource",
  SCORM: "scorm",
  SCORM_UPLOAD: "upload",
  SCORM_PLAYER_URL: "player-url",

  //RESOURCE-COLLECTIONS
  RESOURCE_COLLECTION: "resource-collection",

  //RESOURCE GROUP
  RESOURCE_GROUP: "resource-group",

  //PROFILE
  PROFILE: "profile",
  ADD: "add",
  PERSONAL_DETAILS: "personal-detail",
  FILE: "file",
  EDUCATION: "education",
  EXPERIENCE: "experience",
  CERTIFICATE: "certificate",
  PATENT: "patent",
  IMAGE: "image",

  //FRIENDS
  FRIENDS: "friends",
  SEND: "send",
  REJECT: "reject",
  PENDING: "pending",
  UN_FRIEND: "un-friend",
  UN_FOLLOW: "un-follow",

  //ACHIEVEMENT
  ACHIEVEMENT: "achievement",
  ACHIEVEMENTS: "achievements",
  DOWNLOAD: "download",
  PIN: "pin",
  UNPIN: "un-pin",

  //LAB MARKETPLACE
  LAB_MARKETPLACE: "lab-marketplace",
  REDEEM: "redeem",

  //CHALLENGE-TEMPLATES
  CHALLENGE_TEMPLATE: "challenge-template",

  //NOTIFICATIONS
  NOTIFICATIONS: "notifications",
  MARK_AS_READ: "mark-as-read",

  //SETTINGS
  SETTINGS: "setting",
  LOGGED_IN: "logged-in",
  DETAILS: "details",
  PRIVACY: "privacy",
  ACCOUNT: "account",
  PASSWORD: "password",
  NOTIFICATION: "notification",
  DEACTIVATE: "deactivate",

  MY_SKILLS: "my-skills",
  PINNED: "pinned",

  //DISCUSSIONS
  DISCUSSIONS: "discussion",
  COMMENT: "comment",
  DISLIKE: "dislike",

  //PROJECTS
  PROJECTS: "project",
  PITCH_TASK: "pitch-task",
  FILE_UPLOAD: "file-upload",
  REQUIREMENTS: "requirements",
  EXTERNAL_LINKS: "external-links",
  ADDITIONAL_INFO: "additional-info",
  SUBMIT: "submit",
  VOTE: "vote",
  UNVOTE: "remove-vote",
  PARTICIPANT_REQUEST: "participant-request",
  HISTORY: "history",
  ACTIVE: "active",
  PROJECT: "project",

  //EXPLORE
  EXPLORE: "explore",
  RECOMMENDED: "recommended",
  FEATURED: "featured",
  TEAMS: "teams",

  //CHATS
  CHAT: "chat",
  CONVERSATION: "conversation",
  ONLINE: "online",
  OFFLINE: "offline",
  MESSAGE: "message",
  SOCKET: "broadcasting/auth",

  //CAREERS
  CAREER: "career",
  RELATED: "related",
  JOBS: "jobs",
  MY: "my",

  //GO1
  GO1: "go1",
  FILTER: "filter",
  TOPICS: "topics",
  PROVIDERS: "providers",
  PLAY: "play",

  //UNIFIED
  UNIFIED: "unified",
  UNIFIED_INTEGRATIONS: "integrations",
  UNIFIED_LIST_EMPLOYEES: "list-employees",
  UNIFIED_INVITE_MEMBER: "invite-members",
  // CAMPUS CONNECT
  CAMPUS_CONNECT: "campus-connect",
  SCHOOLS: "schools",
  //AIRMEET
  VERIFY_EVENT: "verify-event",
  LIVE_EVENT_DETAILS: "live-event-details",
  LIVE_EVENT_URL: "live-event-url",
  LIVE_EVENT_INVITATION: "send-live-event-invitations",

  //LEADERBOARD
  LEADERBOARD: "leaderboard",

  //TEAM MATCHING
  TEAM_MATCHING: "team-matching",
  SEND_REQUEST: "send-request",
  PROFILE_CHECK: "profile-check",
  COUNT: "count",

  //ONBOARDING
  RESUME: "resume",
  COMPLETE_ONBOARDING: "complete-onboarding",
  COMPLETE_MINI_ONBOARDING: "complete-mini-onboarding",
  START_PAGE: "start-page",

  // ADVANCE SEARCH
  ADVANCE_SEARCH: "advance-search",
  CHALLENGES: "challenges",
  CHALLENGE_PATHS: "challenge-paths",
  CHALLENGE_TEMPLATES: "challenge-templates",
  LABS: "labs",
  LAB_PROGRAMS: "lab-programs",
  LAB_MARKETPLACES: "lab-marketplaces",
  RESOURCE_MODULES: "resource-modules",
  RESOURCE_GROUPS: "resource-groups",
  RESOURCE_COLLECTIONS: "resource-collections",
  ADVANCE_SEARCH_PROJECTS: "projects",
  USERS: "users",
  ORGANIZATIONS: "organizations",

  //USER PROGRESS TRACKING
  MODULE_VISIT: "module-visit",

  //ASSOCIATION
  COMPONENT_ASSOCIATION: "component-association",

  // REPORT
  REPORT: "report",
  MEMBER_PROGRESS: "member-progress",
  ENGAGEMENT: "engagement",
  MEMBERS: "members",
  ORGANIZATION_MEMBERS: "organization-members",
  ASSOCIATED_PROJECTS: "associated-projects",
  UPDATE_PRIVACY: "update-privacy",
  EXPORT: "export",

  // DASHBOARD
  DASHBOARD: "dashboard",
  MY_LATEST_ACHIEVEMENT: "my-latest-achievement",
  MY_RECOMMENDATIONS: "my-recommendations",
  MY_RESOURCE_MODULES: "my-resource-modules",
  MY_RESOURCE_MODULE: "my-resource-module",
  MY_PROGRESS: "my-progress",
  UPCOMING_CHALLENGE_DEADLINES: "upcoming-challenge-deadlines",
  INBOX_FRIEND_REQUEST: "inbox-friend-request",
  LAST_VISITED_MODULE: "last-visited-module",
  UPDATE_LAYOUT: "update-layout",
  FETCH_LAYOUT: "fetch-layout",
  MY_LABS: "my-labs",
  MY_CHALLENGES: "my-challenges",
  MY_PROJECTS: "my-projects",
  GET_PROJECTS_LIST: "get-projects-list",
  REPORTS: "reports",
  MY_ORGANIZATION: "my-organization",
};
