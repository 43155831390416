import Card from "../../../../components/Cards/Card";
import React, { useMemo } from "react";
import {
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import Spin from "../../../../components/Spin";
import { Grid } from "@mui/material";
import { Line } from "react-chartjs-2";

Chart.register(
  LineElement,
  PointElement,
  CategoryScale,
  Title,
  Tooltip,
  LinearScale
);

const LineChartCard = (props) => {
  /**
   * COMPONENT PROPS
   */
  const { data, title, chartId, isLoading, noDataUI, noCard, legendUi } = props;

  const isDataEmpty = data.datasets.every((a) => a.data?.length === 0);
  /**
   *
   * @type {unknown}
   */
  const chart = useMemo(() => {
    return (
      <>
        {title ? <h4 className="bold fs-16  mb-4">{title}</h4> : ""}
        <Grid
          container
          spacing={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {isLoading ? (
            <Grid item xs={12}>
              <div className={"h-200 w-full flex justify-center items-center"}>
                <Spin blue big />
              </div>
            </Grid>
          ) : (
            ""
          )}
          {!isLoading && !isDataEmpty ? (
            <>
              {legendUi ? (
                <Grid item xs={12}>
                  {legendUi}
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={12}>
                <Line
                  data={data}
                  id={chartId}
                  options={{
                    plugins: {
                      tooltip: {
                        enabled: true,
                        callbacks: {
                          label: function (tooltipItem) {
                            return `${tooltipItem.raw}`;
                          },
                        },
                      },
                    },
                    scales: {
                      y: {
                        precision: true,
                        min: 0,
                        ticks: {
                          callback: function (value) {
                            if (Math.floor(value) === value) {
                              return value;
                            }
                          },
                        },
                        grid: {
                          display: false,
                        },
                      },
                      x: {
                        grid: {
                          display: false,
                        },
                      },
                    },
                  }}
                />
              </Grid>
            </>
          ) : (
            <>
              {!isLoading ? (
                <Grid item xs={12} className={"flex justify-center my-2"}>
                  {noDataUI ?? "No data found!"}
                </Grid>
              ) : (
                ""
              )}
            </>
          )}
        </Grid>
      </>
    );
  }, [data, title, chartId, isLoading, noDataUI]);

  return <>{!noCard ? <Card>{chart}</Card> : <>{chart}</>}</>;
};

export default LineChartCard;
