import { AddBox, FileUpload, RemoveCircleOutline } from "@mui/icons-material";
import { Avatar, Container, Grid, Stack } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import FileButton from "components/Input/FileButton";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import CreateFormTitle from "components/UI/atoms/CreateFormTitle";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import { createEvent } from "helpers/utils/utilities";
import { profile } from "../../../assets/svg/purple";
import {
  getInfoSchema,
  getLocationSchema,
  getMemberSchema,
} from "pages/organization/forms/data";
import React, { useState } from "react";
import { toast } from "react-toastify";
import SocialLinks from "components/UI/molecules/SocialLinks";

export const validateAddMembers = (members) => {
  if (!members || !members?.length) return false;
  if (members.some((member) => !member.name || !member.position)) {
    toast.error("Please enter name and position for all members!");
    return true;
  }
  return false;
};

const OverviewCard = ({
  data,
  errors,
  onChange,
  setMembers,
  members,
  socialLinks,
  setSocialLinks,
  t = (d) => d,
}) => {
  const [memberErrors, setMemberErrors] = useState(new Map());
  const validateMemberErrors = () => {
    // eslint-disable-next-line array-callback-return
    members?.map((member, i) => {
      if (!member?.name) memberErrors.set(i, "This Field is required");
      if (!member?.position) memberErrors.set(i, "This Field is required");
    });
    setMemberErrors(memberErrors);
  };
  const addNewMember = () => {
    validateMemberErrors();
    members.push({ name: "", position: "" });
    setMembers([...members]);
  };
  const onDeleteMember = (index) => {
    members.splice(index, 1);
    setMembers([...members]);
  };
  const onEditMember = (event, index) => {
    const { value, name } = event.target;
    members[index][name] = value;
    setMembers([...members]);
  };

  return (
    <Container xs={12} md={10} disableGutters>
      <CreateFormTitle title="Overview" classes="mt-2" />
      <CreateFormSection>
        <SchemaUIExtractor
          schema={getInfoSchema().slice(0, 2)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
      </CreateFormSection>
      <CreateFormSection title="Add Organization Info">
        <SchemaUIExtractor
          schema={getInfoSchema().slice(2, 9)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
        <h3 className="mt-5">{t("Location")}</h3>
        <SchemaUIExtractor
          schema={getLocationSchema()}
          data={data}
          errors={errors}
          onChange={onChange}
          t={t}
        />
      </CreateFormSection>
      <CreateFormSection>
        <SocialLinks
          setSocialLinks={setSocialLinks}
          socialLinks={socialLinks}
          description={t(
            "You may add additional links related to this organization."
          )}
        />
        <h3 className="mt-6">{t("Add Organization Contacts")}</h3>
        {members?.map((d, i) => (
          <AddMemberRow
            key={i}
            index={i}
            member={d}
            onDelete={onDeleteMember}
            onEdit={onEditMember}
            error={memberErrors}
            t={t}
          />
        ))}
        <Button bordered classes="mt-4" onClick={addNewMember}>
          <AddBox className="mr-1" /> {t("Add")}
        </Button>
      </CreateFormSection>
    </Container>
  );
};

export default OverviewCard;

const AddMemberRow = ({ member, onDelete, onEdit, index, error, t }) => {
  const isDisabled = !!member?.id;
  return (
    <Grid container spacing={2} mt={1} mb={2}>
      {getMemberSchema().map(({ title, name, type, validator }) => (
        <Grid item xs={12} lg={4} key={index}>
          {type === "input" ? (
            <Input
              title={title}
              required={!!validator}
              name={name}
              classes="w-full"
              width="99%"
              value={member[name]}
              onChange={(e) => onEdit(e, index)}
              error={error.get(index)}
              disabled={isDisabled}
              t={t}
            />
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <FileButton
                disabled={isDisabled}
                onChange={(file) => onEdit(createEvent(name, file), index)}
              >
                <Button bordered classes="mx-1 mt-5" disabled={isDisabled}>
                  <FileUpload className="mr-1" />
                  {t("Upload Image")}
                </Button>
              </FileButton>
              <Avatar
                src={
                  !member[name]
                    ? profile
                    : typeof member[name] === "string"
                    ? member[name]
                    : URL.createObjectURL(member[name])
                }
                sx={{ width: 60, height: 60 }}
              />
              <RemoveCircleOutline
                className="c-error ml-2 cursor-pointer"
                onClick={() => onDelete(index)}
              />
            </Stack>
          )}
        </Grid>
      ))}
    </Grid>
  );
};
