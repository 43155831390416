import {
  CheckCircle,
  Settings,
  Edit,
  Delete,
  CopyAll,
} from "@mui/icons-material";
import Button from "components/Button";
import ImageCard from "components/Cards/ImageCard";
import React, { useEffect, useMemo, useState } from "react";
import MainPageLayout from "../../../layouts/MainPage";
import MainTabs from "components/UI/organisms/MainTabs";
import ResourceAudios from "./ResourceAudios";
import ResourceLinks from "./ResourceLinks";
import { COMPONENT_TYPES, INFO_TYPES } from "helpers/utils/constants";
import PublicDescription from "components/UI/organisms/PublicDescription";
import LikeShareSaveBtns from "components/UI/atoms/LikeShareSaveBtns";
import { loginToPreceed } from "helpers/utils/utilities";
import { isTrue } from "helpers/utils/validators";
import useResources from "store/resources/service-hook";
import ResourceFiles from "./ResourceFiles";
import useRedirect from "helpers/hooks/useRedirect";
import VideoList from "components/UI/organisms/VideoList";
import useGO1 from "store/go1/service-hook";
import { Tooltip } from "@mui/material";
import Card from "components/Cards/Card";
import Course from "./Course";
import InfoCard from "components/Cards/InfoCard";
import RelatedContent from "pages/associateListings/RelatedContent";
import MenuButton from "components/Button/MenuButton";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { Warning } from "@mui/icons-material";
import ShareModal from "components/UI/organisms/ShareModal";
import { NotLoggedInTabs } from "pages/organization";
import EmptyText from "components/UI/atoms/EmptyText";
const resource_tabs = ["Course", "Files", "Links", "Audio", "Video"];

const allAssetsCompleted = (items) =>
  items?.length > 0 && items.every((item) => isTrue(item?.completed));

const getInfoCardType = (assets) => {
  const allCompleted = assets.every((d) => d.status === "completed");
  const partiallyCompleted = assets.some((d) => d.status === "completed");

  if (allCompleted) {
    return INFO_TYPES.SUCCESS;
  } else if (partiallyCompleted) {
    return INFO_TYPES.WARNING;
  } else {
    return INFO_TYPES.ERROR;
  }
};

export const resourceTypeMap = {
  audios: "audio",
  documents: "document",
  files: "image",
  links: "url",
  videos: "video",
  embedded_video: "embedded_video",
  embedded_audio: "embedded_audio",
};

const ResourceModuleMainPage = ({
  resource,
  setResource,
  isPrivate,
  t = (d) => d,
  user,
  isLoggedIn,
}) => {
    const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [ResourceToDelete, setResourceToDelete] = useState(null);
  const {
    favorite,
    unFavorite,
    like,
    unLike,
    moduleVisit,
    deleteMedia,
    deleteResource,
    clone,
  } = useResources();
  const { play } = useGO1();

  const { navigateEditResource, navigateManageResources, navigateResource } = useRedirect();

  const handlePlay = async (slug) => {
    setLoading(true);
    const res = await play(slug);
    setLoading(false);
    if (res?.url) window.open(res.url);
  };

  const doUndoAction = async (property, doAction, undoAction) => {
    if (!isLoggedIn) {
      loginToPreceed();
      return;
    }
    if (!resource) return;
    const did =
      !!resource[property] && resource[property]?.toLowerCase() !== "no";
    if (did) {
      await undoAction(resource?.slug);
    } else {
      await doAction(resource?.slug);
    }
    resource[property] = did ? "no" : "yes";
    setResource({ ...resource });
  };

  const handleFavoriteUnFav = () =>
    doUndoAction("favourite", favorite, unFavorite);

  const handleLikeUnlike = () => {
    doUndoAction("liked", like, unLike);
  };

  const handleDeleteResource = async (slug) => {
    setLoading(true);
    await deleteResource(resource?.slug);
    navigateManageResources();
    setLoading(false);
  };

  const setAssetComplete = async (assetId, type) => {
    await moduleVisit(resource?.slug, assetId);

    if (type === "embedded_audio" || type === "embedded_video") {
      const updatedEmbeddedMedia = resource.embedded_media.map((item) => {
        if (item.id === assetId) {
          return { ...item, completed: "yes" };
        }
        return item;
      });
      setResource({ ...resource, embedded_media: updatedEmbeddedMedia });
    } else {
      const updatedResources = resource[type]?.map((item) => {
        if (item.id === assetId) {
          return { ...item, completed: "yes" };
        }
        return item;
      });
      setResource({ ...resource, [type]: updatedResources });
    }
  };

  const embeddedVideos = resource?.embedded_media.filter(
    (media) => media?.type === "embedded_video"
  );
  const embeddedAudios = resource?.embedded_media.filter(
    (media) => media?.type === "embedded_audio"
  );

  const tabsWithIcons = useMemo(
    () =>
      resource_tabs
        .filter((tab) => {
          if (tab === "Audio" && !resource?.audios?.length) {
            return false;
          }
          if (tab === "Video" && !resource?.videos?.length && !embeddedVideos?.length) {
            return false;
          }
          if (tab === "Files" && (!resource?.files?.length && !resource?.documents?.length)) {
            return false;
          }
          if (tab === "Links" && (!resource?.links?.length)) {
            return false;
          }
          if (tab === "Course" && (!resource?.scorm)) {
            return false;
          }
          return true;
        })
        .map((tab, index) => {
          let completed = false;
  
          switch (tab) {
            case "Course":
              completed = isTrue(resource?.scorm?.completed);
              break;
            case "Files":
              completed = allAssetsCompleted([
                ...(resource?.files || []),
                ...(resource?.documents || []),
              ]);
              break;
            case "Audio":
              completed = allAssetsCompleted([
                ...(resource?.audios || []),
                ...(embeddedAudios || []),
              ]);
              break;
            case "Links":
              completed = allAssetsCompleted(resource?.links);
              break;
            case "Video":
              completed = allAssetsCompleted([
                ...(resource?.videos || []),
                ...(embeddedVideos || []),
              ]);
              break;
            default:
              break;
          }
          
          if (completed) {
            return (
                <div key={index} class="flex flex-center gap-2">
                  <CheckCircle className="mr-2 c-success"/>
                  {t(tab)}
              </div>
            );
          }
          return t(tab)
        }),
    [resource, resource_tabs ]
  );
    

  const handleDeleteMedia = async (assetId, type) => {
    if (!isPrivate) return;
    const resourceApiType = resourceTypeMap[type];
    const res = await deleteMedia(resource?.slug, assetId, resourceApiType);
    if (res) {
      const newType = type.includes("embedded") ? "embedded_media" : type;
      const updatedResources = resource[newType]?.filter(
        (item) => item.id !== assetId
      );
      setResource({ ...resource, [newType]: updatedResources });
    }
  };

  const allAssets = [
    { title: "Course", items: [resource?.scorm ?? null].filter(Boolean) },
    {
      title: "File",
      items: [...(resource?.files ?? []), ...(resource?.documents ?? [])],
    },
    { title: "Link", items: resource?.links },
    {
      title: "Audio",
      items: [...(resource?.audios ?? []), ...(embeddedAudios ?? [])],
    },
    {
      title: "Video",
      items: [...(resource?.videos ?? []), ...(embeddedVideos ?? [])],
    },
  ]
    .filter((asset) => asset.items.length > 0)
    .map((asset) => ({
      title: asset.title,
      status: allAssetsCompleted(asset.items) ? "completed" : "pending",
    }));

  const infoCardType = getInfoCardType(allAssets);

  const toggleTexts = { hide: "Hide List", show: "Show List" };

  const handleCloneResource = async () => {
    setLoading(true);
   const res = await clone(resource?.slug);
    if(res.data.data){
      navigateResource(res.data.data.slug);
    }
    setLoading(false);
  };
  const openExitConfirmationModal = (slug) => {
    setResourceToDelete(slug);
    setOpenConfirmationModal(true);
  };

  const cancelExitConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setResourceToDelete(null);
  };


  return (
    <>
      <div className="container flex-reverse mt-header">
        <div className="flex items-center w-100p">
          <div
            className="px-3 border-orange bg-orange py-2 fs-14 flex items-center rounded-10 shadow w-full">
              <Warning color="warning" className="mr-1" />
              {t("Please note, your web browser cookies must be enabled for you to view this course. To modify this, visit the settings page of your web browser.")}
          </div>
          {isPrivate ? (
            <MenuButton
              listWithTitleonClick={[
                {
                  title: "Edit",
                  onClick: () =>
                    navigateEditResource(
                      resource?.slug,
                      isTrue(resource?.is_go1_resource)
                    ),
                  icon: Edit,
                },
                {
                  title: "Duplicate",
                  onClick: handleCloneResource,
                  icon: CopyAll,
                },
                {
                  title: "Delete",
                  onClick: () => openExitConfirmationModal(resource?.slug),
                  icon: Delete,
                  classes: "c-error",
                },
              ]}
              classes="fs-1 fs-13"
              
              height="30px"
            >
              <Settings fontSize="small" className="mr-1" /> {t("Manage")}
            </MenuButton>
          ) : // <Button
          //   width="250px"
          //   loading={loading}
          //   color={isCloned ? "green" : "primary"}
          //   bordered
          //   onClick={handleCloneResource}
          // >
          //   {isCloned ? <Done /> : <AddBox className="mr-1" />}
          //   {isCloned ? "Added to My Resources" : "Add a Copy to My Resource"}
          // </Button>
          null}
        </div>
      </div>
      <MainPageLayout classes="mt-3" firstLgGridSize={4} secondLgGridSize={8}>
        <div className="left">
          <ImageCard
            image={resource?.cover_image}
            type={COMPONENT_TYPES.RESOURCE}
            isEmbedded={resource?.media_type === "embedded"}
            title={resource?.title}
          />
          {isTrue(resource?.is_go1_resource) &&
            (!user?.go1?.can_play_resource ? (
              <Tooltip
                title={t("You don't have permission to play GO1 courses!")}
                placement="top"
                arrow
              >
                <span>
                  <Button
                    classes="my-2"
                    width="100%"
                    color="green"
                    height="35px"
                    disabled
                  >
                    {t("Play with Go1")}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                onClick={() => handlePlay(resource?.slug)}
                classes="my-2"
                width="100%"
                color="green"
                height="35px"
                loading={loading}
              >
                {t("Play with Go1")}
              </Button>
            ))}

          <PublicDescription
            component={COMPONENT_TYPES.RESOURCE}
            data={resource}
            hideRequirements
            hideSocialLinks
            showProgress={!isPrivate}
            hideHostedBy
            isPrivate={isPrivate}
            hideProgress={tabsWithIcons.length === 0}
          >
            <LikeShareSaveBtns
              isLiked={isTrue(resource?.liked)}
              isSaved={isTrue(resource?.favourite)}
              onLike={handleLikeUnlike}
              onSave={handleFavoriteUnFav}
              onShare={() => setShareOpen(true)}
              hideLike={true}
            />
            {
              tabsWithIcons.length !== 0 &&
                <InfoCard
                  title="You need to complete following sections to complete this Resource Module."
                  list={allAssets}
                  type={infoCardType}
                  hasObjects
                  classes="w-full mt-3"
                  toggleTexts={toggleTexts}
                  t={t}
                />
            }
           
          </PublicDescription>
        </div>
        <div className="right">
          <MainTabs current={tab} setCurrent={setTab}  tabs={tabsWithIcons} />
            {
              !isLoggedIn ? 
              <NotLoggedInTabs title="Login to view more details" />
              : tabsWithIcons.length === 0 ?
                <Card>
                  <EmptyText>
                   {t("No content has been added to this resource yet. Please check back later.")}
                  </EmptyText>
                </Card>
              : <>
                {
                  [
                    resource?.scorm && (
                      <Course key='course' resource={resource} t={t} />
                    ),
                    (resource?.files?.length || resource?.documents?.length) && 
                      <ResourceFiles
                        key="resourceFiles"
                        isPrivate={isPrivate}
                        files={[
                          ...(resource?.files ?? []).map((file) => ({
                            ...file,
                            type: "files",
                          })),
                          ...(resource?.documents ?? []).map((doc) => ({
                            ...doc,
                            type: "documents",
                          })),
                        ]}
                        setAssetComplete={setAssetComplete}
                        onDelete={handleDeleteMedia}
                        t={t}
                      />,
                    resource?.links?.length && (
                      <ResourceLinks
                        key="resourceLink"
                        isPrivate={isPrivate}
                        links={resource?.links}
                        setAssetComplete={setAssetComplete}
                        onDelete={handleDeleteMedia}
                        t={t}
                      />
                    ),

                    resource?.audios?.length && (
                      <ResourceAudios
                        key="resourceAudio"
                        isPrivate={isPrivate}
                        audios={resource?.audios ?? []}
                        embeddedAudios={embeddedAudios ?? []}
                        setAssetComplete={setAssetComplete}
                        onDelete={handleDeleteMedia}
                        t={t}
                      />),

                    resource?.videos?.length && (
                      <Card  key="resourceVideo" classes="mt-4">
                        <VideoList
                          readOnly={!isPrivate}
                          videos={resource?.videos ?? []}
                          embeddedVideos={embeddedVideos ?? []}
                          setAssetComplete={setAssetComplete}
                          onDelete={(d, type) =>{handleDeleteMedia(d.id, type);}}
                          t={t}
                        />
                      </Card>
                      )
                  ].filter(Boolean)[tab]
                }
              </>
            }
       

          <RelatedContent
            component={COMPONENT_TYPES.RESOURCE}
            slug={resource?.slug}
            classes="mt-4"
            title={t("More Resources")}
          />

        </div>
      </MainPageLayout>
      
      <ShareModal
        open={shareOpen}
        onClose={() => setShareOpen(false)}
        component={COMPONENT_TYPES.RESOURCE}
        slug={resource?.slug}
      />
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={cancelExitConfirmationModal}
        desc={t("Are you sure you want to delete this Resource Module?")}
        okayButtonTitle={t("Delete Resource Module")}
        onOkay={() => {
          handleDeleteResource(ResourceToDelete);
          cancelExitConfirmationModal();
        }}
      />
    </>
  );
};

export default ResourceModuleMainPage;
