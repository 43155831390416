import React, { createContext, useCallback, useState } from "react";
import useComputed from "../../../helpers/hooks/useComputed";
import { ADVANCE_SEARCH_COMPONENTS, FilterMappings } from "../data";

/**
 *
 * @type {React.Context<{}>}
 */
export const AdvanceSearchContext = createContext({});
const AdvanceSearchContextWrapper = (props) => {
  /**
   * COMPONENT PROPS
   */
  const { children } = props;

  /**
   * COMPONENT STATE
   */
  const [activeMenu, setActiveMenu] = useState("labs");
  const [keyword, setKeyword] = useState("");
  const [quickKeyword, setQuickKeyword] = useState("");

  const [gridType, setGridType] = useState(1);
  const [totalCounts, setTotalCounts] = useState({});
  const [totalCountsQuickSearch, setTotalCountsQuickSearch] = useState({});

  /** START FILTER STATES  */
  const [privacy, setPrivacy] = useState([]);
  const [level, setLevel] = useState([]);
  const [duration, setDuration] = useState([]);
  const [category, setCategory] = useState([]);
  const [job, setJob] = useState([]);
  const [projectCategory, setProjectCategory] = useState([]);
  const [projectType, setProjectType] = useState([]);
  const [labType, setLabType] = useState([]);
  const [skill, setSkill] = useState([]);
  const [sort, setSort] = useState("relevance");
  /** END FILTER STATE */

  const [searchOpen, setSearchOpen] = useState(false);

  const [componentLists] = useState([
    {
      value: ADVANCE_SEARCH_COMPONENTS.LABS,
      label: "Labs",
    },
    {
      value: ADVANCE_SEARCH_COMPONENTS.LAB_PROGRAMS,
      label: "Lab Programs",
    },
    {
      value: ADVANCE_SEARCH_COMPONENTS.LAB_MARKET_PLACE,
      label: "Lab Marketplace",
    },
    {
      value: ADVANCE_SEARCH_COMPONENTS.CHALLENGES,
      label: "Challenges",
    },
    {
      value: ADVANCE_SEARCH_COMPONENTS.CHALLENGE_PATHS,
      label: "Challenge Paths",
    },
    {
      value: ADVANCE_SEARCH_COMPONENTS.CHALLENGE_TEMPLATES,
      label: "Challenge Templates",
    },
    {
      value: ADVANCE_SEARCH_COMPONENTS.RESOURCE_MODULES,
      label: "Resource Modules",
    },
    {
      value: ADVANCE_SEARCH_COMPONENTS.RESOURCE_GROUPS,
      label: "Resource Groups",
    },
    {
      value: ADVANCE_SEARCH_COMPONENTS.RESOURCE_COLLECTION,
      label: "Resource Collection",
    },
    {
      value: ADVANCE_SEARCH_COMPONENTS.PROJECTS,
      label: "Projects",
    },
    {
      value: ADVANCE_SEARCH_COMPONENTS.USERS,
      label: "Users",
    },
    {
      value: ADVANCE_SEARCH_COMPONENTS.ORGANIZATIONS,
      label: "Organizations",
    },
  ]);

  /**
   * COMPUTED
   */
  const ActiveFilterComponents = useComputed(() => {
    return FilterMappings[activeMenu] ?? [];
  }, [activeMenu]);

  /**
   * FILTERS
   */
  const filters = useComputed(() => {
    return {
      privacy,
      level,
      duration,
      category: category?.map((item) => item?.key ?? item),
      job: job?.map((item) => item?.key ?? item),
      project_category: projectCategory,
      project_type: projectType,
      lab_type: labType,
      skill: skill?.map((item) => item?.key ?? item),
      ...(["created_data_asc", "created_data_desc"].includes(sort)
        ? { sort_by: sort }
        : {}),
    };
  }, [
    privacy,
    level,
    duration,
    category,
    job,
    projectCategory,
    projectType,
    labType,
    skill,
    sort,
  ]);

  /**
   * GET FILTERS VIA COMPONENT TYPE
   * @param type
   */
  const getFiltersViaType = useCallback(
    (type) => {
      const filterList = FilterMappings[type];
      if (filterList) {
        return filterList.reduce((formatted, item) => {
          if (
            item in filters &&
            filters[item] &&
            Array.isArray(filters[item]) &&
            filters[item].length > 0
          ) {
            formatted[item] = filters[item].sort();
          } else {
            if (filters[item]) {
              formatted[item] = filters[item];
            }
          }
          return formatted;
        }, {});
      }
      return {};
    },
    [filters]
  );

  /**
   *
   * @param obj
   * @param keys
   * @returns {*}
   */
  const removeKeys = (obj, keys) => {
    let newObj = { ...obj };
    keys.forEach((key) => {
      if (newObj[key]) {
        delete newObj[key];
      }
    });
    return newObj;
  };

  const clearFilters = () => {
    setPrivacy([]);
    setLevel([]);
    setDuration([]);
    setCategory([]);
    setJob([]);
    setProjectCategory([]);
    setProjectType([]);
    setLabType([]);
    setSkill([]);
  };

  const formattedCount = useCallback((value) => {
    if (!value) {
      return 0;
    }
    const thplace = Math.floor(value / 1000);
    if (thplace > 0) {
      return `${thplace}k+`;
    }
    return value;
  }, []);

  return (
    <AdvanceSearchContext.Provider
      value={{
        filters,
        activeMenu,
        setActiveMenu,
        ActiveFilterComponents,
        gridType,
        setGridType,
        keyword,
        setKeyword,
        totalCounts,
        setTotalCounts,
        componentLists,
        privacy,
        setPrivacy,
        getFiltersViaType,
        removeKeys,
        level,
        duration,
        category,
        job,
        projectCategory,
        projectType,
        labType,
        skill,
        setLevel,
        setDuration,
        setCategory,
        setJob,
        setProjectCategory,
        setProjectType,
        setLabType,
        setSkill,
        clearFilters,
        totalCountsQuickSearch,
        setTotalCountsQuickSearch,
        quickKeyword,
        setQuickKeyword,
        formattedCount,
        searchOpen,
        setSearchOpen,
        sort,
        setSort,
      }}
    >
      {children}
    </AdvanceSearchContext.Provider>
  );
};

export default AdvanceSearchContextWrapper;
