import React, { useMemo } from 'react';
import styles from '../ui.module.scss';

const TimeLine = ({ challenge }) => {
  // Using useMemo to compute timeLineValue only when challengeTimeline changes
  const timeLineValue = useMemo(() => {
    const timeLine = [];
    const challengeTimeline =  challenge?.challenge_timelines || {};


    if (challengeTimeline?.timeline_type === "restricted") {
    
      if (challengeTimeline.start_date && challengeTimeline.start_date_description) {
        timeLine.push({
          title: challengeTimeline.start_date,
          description: challengeTimeline?.start_date_description ?? "Start Date",
        });
      }

      if(challengeTimeline?.registration_deadline_date) {
        timeLine.push({
          title: challengeTimeline.registration_deadline_date,
          description: challengeTimeline?.registration_deadline_date_description ?? "Registration Deadline",
        });
      }

      if (challengeTimeline.submission_deadline_date && challengeTimeline.submission_deadline_date_description) {
        timeLine.push({
          title: challengeTimeline.submission_deadline_date,
          description: challengeTimeline?.submission_deadline_date_description ?? "Submission Deadline",
        });
      }
    }

    if(challengeTimeline?.timeline_type === "flexible" && challenge.challenge_custom_timelines && challenge.challenge_custom_timelines.length > 0) {
      challenge.challenge_custom_timelines.forEach((item) => {
        timeLine.push({
          title: `After ${item.custom_timelines_number} ${item.custom_timelines_duration}`,
          description: item.custom_timelines_title,
        });
      });
    }

    return timeLine;
  }, [challenge?.challenge_timelines]);

  return (
      timeLineValue.length > 0 && (
        <div className={`pt-8`}>
          <div className={`${styles.timeline} flex items-left justify-between relative`}>
            {timeLineValue.map((item, index) => (
              <div className={`${styles.timeline__point} flex-1`} key={index}>
                <p
                  className={`rounded-full timeline__point absolute top-0 h-20 w-20 z-10 flex items-center justify-center -translate-y-1/2 fs-14 bg-primary rounded-95`}
                >
                  {index + 1}
                </p>
                <div className="opacity-60 py-3 pr-6">
                  <p className="fs-14 fw-700" dangerouslySetInnerHTML={{ __html: item.title }} />
                  <p className="fs-12" dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
            ))}
          </div>
        
        </div>
      )
  );
};

export default TimeLine;
