import React, { useState } from "react";
import { Grid } from "@mui/material";
import Button from "components/Button";
import SchemaUIExtractor from "../../../components/UI/organisms/SchemaUIExtractor";
import { experienceSchema } from "pages/profile/forms/data";
import { toast } from "react-toastify";

export const validateExperience = (experience, t) => {
  if (!experience || !experience?.length) return true;
  for (const d of experience) {
    if (!d.company || !d.position) {
      toast.error(t("Please enter the experience details properly!")); 
      return true;
    }
  }
  return false;
};

const Experience = ({
  experience,
  setExperience,
  errors,
  onDelete,
  t = (d) => d,
}) => {
  const [loading, setLoading] = useState(false);

  const addExperience = () => {
    setExperience((prevState) => [...prevState, {}]);
  };

  const onChangeExp = (e, index) => {
    const { name, value } = e.target;
    experience[index][name] = value;
    setExperience([...experience]);
  };

  const removeExperience = async (indexToRemove) => {
    if (experience[indexToRemove]?.id) {
      setLoading(indexToRemove);
      await onDelete(experience[indexToRemove]?.id);
      setLoading(false);
    }
    experience.splice(indexToRemove, 1);
    setExperience([...experience]);
  };
  return (
    <div>
      <div className="flex items-center justify-between mt-4">
        <h3>{t("Manually Add Experience")}</h3>
        <Button bordered onClick={addExperience}>
          {" "}
          {t("Add Entry")}
        </Button>
      </div>
      {experience.map((d, i) => (
        <div key={i}>
          <h3 className="mt-3">
            {" "}
            {t("Entry")} #{i + 1}
          </h3>
          <Grid container xs={12} spacing={4}>
            <Grid item xs={12} sm={6}>
              <SchemaUIExtractor
                schema={experienceSchema.slice(0, 5)}
                data={d}
                onChange={(e) => onChangeExp(e, i)}
                errors={errors}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              direction="column"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <SchemaUIExtractor
                schema={experienceSchema.slice(5, 6)}
                data={d}
                onChange={(e) => onChangeExp(e, i)}
                errors={errors}
              />
              <Button
                classes="mt-2"
                width="100px"
                height="32px"
                loading={loading === i}
                color="red"
                bordered
                onClick={() => removeExperience(i)}
              >
                {t("Remove")}
              </Button>
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default Experience;
