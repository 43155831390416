import Button from "components/Button";
import Input from "components/Input";
import React from "react";
import CreateFormSection from "../atoms/CreateFormSection";
import appleIcon from "assets/png/icons/apple-icon.png";
import googleIcon from "assets/png/icons/google-icon.png";
import linkedInIcon from "assets/png/icons/linkedin-icon.png";
import mIcon from "assets/png/icons/m-icon.png";
import msIcon from "assets/png/icons/ms-icon.png";

const ThirdPartyInvitaionSection = () => {
  return (
    <CreateFormSection
      data-testid="third-party-invitations"
      classes="mt-4 py-5"
    >
      <h4>Third Party Invitation</h4>
      <div className="flex items-center mt-4 mb-2">
        {[appleIcon, googleIcon, linkedInIcon, mIcon, msIcon].map(
          (img, index) => (
            <img src={img} className="mr-4" key={index} height={45} alt=""/>
          )
        )}
      </div>
      <h4 className="mt-2">Invite Link</h4>
      <div className="flex items-right w-full">
        <Input
          classes="mt-1 flex-1 w-full mx-0"
          value="preprlabs.org/invitelink?=#12903810398098"
          width="95%"
          disabled={true}
        />
        <Button height={40} bordered classes="ml-1">
          Copy Link
        </Button>
      </div>
    </CreateFormSection>
  );
};

export default ThirdPartyInvitaionSection;
