import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import * as axios from "axios";
import { REDUCER_TYPES } from "../index";
import ACTIONS from "./action-types";
import { mockRequestPost, mockRequestGet } from "../mocks/request";
import { dispatchMock, initStore } from "../mocks/store";
import useAuth from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { ENDPOINTS } from "store/api/endpoints";
import { CONTENT_TYPES } from "store/api";
import * as Auth0 from "@auth0/auth0-react";

let postSpy;
let getSpy;

const token = 1;
const user = {};
const email = "test@gmail.com";
const otp = "1234";
const language = "en";
const passwords = {
  newPassword: "1234",
  confirmPassword: "1234",
};

const magnetCode = "random_magnet_oauth_code";
const payload = { email };

jest.spyOn(axios, "get").mockResolvedValue({ data: { data: true } });
jest.spyOn(axios, "post").mockResolvedValue({ data: { data: true } });

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
  },
});

const dispatch = dispatchMock(store);

const MasterHookCompMock = () => {
  const {
    login,
    ssoLogin,
    register,
    verifyOtp,
    forgotPassword,
    resetPassword,
    logout,
    checkEmail,
    checkUsername,
    checkPhone,
    languageChange,
    organizationChange,
    getOrganizationList,
    magnetSSOLogin,
    getOrganization,
  } = useAuth();

  const BUTTON_LINKS = [
    { name: "login", func: () => login({}) },
    { name: "ssoLogin", func: () => ssoLogin(payload) },
    {
      name: "magnetSSOLogin",
      func: () => magnetSSOLogin({ code: magnetCode }),
    },
    { name: "register", func: () => register({}) },
    { name: "verifyOtp", func: () => verifyOtp(otp, email) },
    { name: "forgotPassword", func: () => forgotPassword(email) },
    { name: "resetPassword", func: () => resetPassword(otp, email, passwords) },
    { name: "logout", func: () => logout() },
    { name: "checkEmail", func: () => checkEmail(email) },
    { name: "checkUsername", func: () => checkUsername("") },
    { name: "checkPhone", func: () => checkPhone("") },
    { name: "languageChange", func: () => languageChange(language) },
    { name: "organizationChange", func: () => organizationChange(1) },
    { name: "getOrganizationList", func: () => getOrganizationList() },
    { name: "getOrganization", func: () => getOrganization() },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button
          data-testid={item.name}
          key={item.name}
          onClick={() => item.func()}
        >
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("auth hook services", () => {
  beforeEach(() => {
    jest.spyOn(Auth0, "useAuth0").mockReturnValue({
      logout: jest.fn(),
    });
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <MasterHookCompMock />
        </BrowserRouter>
      </Provider>
    );
    postSpy = mockRequestPost({ token, user });
    getSpy = mockRequestGet({ token });
  });

  test("login", async () => {
    const button = screen.getByTestId("login");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.AUTH}/${ENDPOINTS.LOGIN}`,
        { device_platform: "andriod", language: language },
        {},
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: { token, user },
        type: `${ENDPOINTS.AUTH}/${ACTIONS.SET_USER}`,
      })
    );
  });

  test("ssoLogin", async () => {
    const button = screen.getByTestId("ssoLogin");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.AUTH}/${ENDPOINTS.SSO_LOGIN}?language=${language}`,
        { email: "test@gmail.com" },
        {
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        type: `${ENDPOINTS.AUTH}/${ACTIONS.SET_USER}`,
        payload: { token, user },
      })
    );
  });

  test("MagnetSSOLogin", async () => {
    const button = screen.getByTestId("magnetSSOLogin");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.AUTH}/${ENDPOINTS.SSO_LOGIN}/magnet?language=${language}`,
        { code: magnetCode },
        {
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function),
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
  });

  test("register", async () => {
    const button = screen.getByTestId("register");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.AUTH}/${ENDPOINTS.REGISTER}?language=en`,
        { device_platform: "web" },
        { "Content-Type": CONTENT_TYPES.MULTIPART_FORM },
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(0));
  });

  test("verifyOtp", async () => {
    const button = screen.getByTestId("verifyOtp");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.AUTH}/${ENDPOINTS.VERIFY_OTP}`,
        { email, otp, language },
        {},
        expect.any(Function)
      )
    );
  });

  test("forgotPassword", async () => {
    const button = screen.getByTestId("forgotPassword");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.AUTH}/${ENDPOINTS.FORGOT_PASSWORD}`,
        { email, language },
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(0));
  });

  test("resetPassword", async () => {
    const button = screen.getByTestId("resetPassword");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.AUTH}/${ENDPOINTS.RESET_PASSWORD}`,
        {
          email: "1234",
          language,
          password: undefined,
          password_confirmation: undefined,
        },
        {
          Authorization: "Bearer [object Object]",
        },
        expect.any(Function)
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(0));
  });

  test("logout", async () => {
    const button = screen.getByTestId("logout");
    userEvent.click(button);
    await waitFor(() => expect(postSpy).not.toHaveBeenCalled());
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        type: `${ENDPOINTS.AUTH}/${ACTIONS.LOGOUT}`,
      })
    );
  });

  test("checkEmail", async () => {
    const button = screen.getByTestId("checkEmail");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.AUTH}/${ENDPOINTS.CHECK_EMAIL}?language=en`,
        { email },
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(0));
  });
  test("checkPhone", async () => {
    const button = screen.getByTestId("checkPhone");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.AUTH}/${ENDPOINTS.CHECK_PHONE}?language=en`,
        { phone_number: "" },
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(0));
  });
  test("checkUsername", async () => {
    const button = screen.getByTestId("checkUsername");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.AUTH}/${ENDPOINTS.CHECK_USERNAME}?language=en`,
        { username: "" },
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(0));
  });

  test("languageChange", async () => {
    const button = screen.getByTestId("languageChange");
    userEvent.click(button);
    await waitFor(() => expect(postSpy).not.toHaveBeenCalled());
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: language,
        type: `${ENDPOINTS.AUTH}/${ACTIONS.SET_LANGUAGE}`,
      })
    );
  });

  test("organizationChange", async () => {
    const button = screen.getByTestId("organizationChange");
    userEvent.click(button);
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(2));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: 1,
        type: `${ENDPOINTS.AUTH}/${ACTIONS.SET_ORGANIZATION}`,
      })
    );
  });

  test("getOrganizationList", async () => {
    const button = screen.getByTestId("getOrganizationList");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.USER}/${ENDPOINTS.GET_ORGANIZATION_LIST}?language=en&page=1&search=`,
        {},
        {
          Authorization: "Bearer undefined",
        },
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [undefined, undefined, undefined],
        type: `${ENDPOINTS.AUTH}/${ACTIONS.ORG_LIST}`,
      })
    );
  });

  test("getOrganization", async () => {
    const button = screen.getByTestId("getOrganization");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.USER}/${ENDPOINTS.ORGANIZATION_PREFRENCE}?language=en`,
        {},
        {
          Authorization: "Bearer undefined",
        },
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.AUTH}/${ACTIONS.SET_ORGANIZATION}`,
      })
    );
  });
});
