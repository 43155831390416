import React, { createContext, useState, useEffect } from "react";
import useChallengeReport from "../../../../store/reports/challenges/service-hooks";
import { useParams } from "react-router-dom";
import { ComponentMappings } from "../../data";
import { Box } from "@mui/system";
import Spin from "../../../../components/Spin";
import { isTrue } from "helpers/utils/validators";
import useChallenges from "store/challenges/service-hook";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

/**
 *
 * @type {React.Context<{}>}
 */
export const ChallengeReportContext = createContext({});

/**
 *
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const ChallengeReportContextWrapper = ({ children }) => {
  /**
   * HOOKS
   */
  const { id } = useParams();
  const { challengeReportDetails } = useChallengeReport();
  const { addTemplate, removeTemplate } = useChallenges();

  /**
   * COMPONENT STATE
   */
  const [challengeDetails, setChallengeDetails] = useState();
  const [loadingChallengeReport, setLoadingChallengeReport] = useState(false);
  const [activeComponent, setActiveComponent] = useState(
    ComponentMappings.all_labs
  );
  const { t } = useLocalizedTranslation();

  const [components] = useState([
    {
      value: "All Labs",
      key: ComponentMappings.all_labs,
    },
    {
      value: "All Lab Programs",
      key: ComponentMappings.all_lab_programs,
    },
    {
      value: "All Resources",
      key: ComponentMappings.all_resources,
    },
    {
      value: "All Resource Collections",
      key: ComponentMappings.all_resource_collections,
    },
    {
      value: "All Resource Groups",
      key: ComponentMappings.all_resource_groups,
    },
  ]);

  /**
   * METHODS
   */
  const fetchChallengeReport = async () => {
    setLoadingChallengeReport(true);
    if (id) {
      const response = await challengeReportDetails(id);
      if (response) {
        setChallengeDetails(response);
      }
    }
    setLoadingChallengeReport(false);
  };

  /**
   * COMPONENT MOUNTED
   */
  useEffect(() => {
    fetchChallengeReport();
  }, []);

  const addToTemplate = async (val) => {
    if (isTrue(challengeDetails?.is_pre_build)) {
      const res = await removeTemplate(challengeDetails.slug);
      if (!res?.error) {
        setChallengeDetails((prev) => ({ ...prev, is_pre_build: "no" }));
      }
    } else {
      const res = await addTemplate(challengeDetails.slug);
      if (!res?.error) {
        setChallengeDetails((prev) => ({ ...prev, is_pre_build: "yes" }));
      }
    }
  };

  return (
    <>
      <ChallengeReportContext.Provider
        value={{
          challengeDetails: challengeDetails,
          challengeSlug: id,
          components,
          activeComponent: activeComponent,
          setActiveComponent: setActiveComponent,
          addToTemplate: addToTemplate,
        }}
      >
        {loadingChallengeReport ? (
          <Box className="h-screen w-screen flex items-center justify-center">
            <Spin big blue />
          </Box>
        ) : (
          ""
        )}
        {!loadingChallengeReport && challengeDetails ? (
          <section className={"prepr-report-module"}>{children}</section>
        ) : (
          <Box className="h-screen w-screen flex items-center justify-center">
            <p>{t("Failed to fetch challenge.")}</p>
          </Box>
        )}
      </ChallengeReportContext.Provider>
    </>
  );
};

export default ChallengeReportContextWrapper;
