import { Step, StepLabel, Stepper, Box } from "@mui/material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import React, { useState } from "react";
import moment from "moment";
import { NoDataView } from "../../../components/UI/organisms/ListingContainer";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const ProjectHistory = ({ history }) => {
  const [fullView, setFullView] = useState(false);
  const toggleView = () => setFullView((prevView) => !prevView);
  const { t } = useLocalizedTranslation();

  const StepIcon = ({ isLastStep }) => (
    <div className="flex-column items-center ml-10">
      <div className="w-24 h-24 rounded-50 bg-darkgray" />
      {!isLastStep && (
        <div className="w-5 h-110 bg-darkgray absolute w-1 h-110 mx-0 my-0 z-10" />
      )}
    </div>
  );

  return (
    <Card maxHeight="1000px" classes="mt-5 pb-6 overflowY-auto">
      {history && history.length > 0 ? (
        <Stepper orientation="vertical" className="my-3 " connector={null}>
          {history
            .slice(0, fullView ? history.length : 6)
            .map(({ updated_at, activity }, index) => (
              <Step
                key={index}
                expanded={true}
                active={false}
                className="h-80 ml-10 relative"
              >
                <StepLabel
                  icon={
                    <StepIcon
                      isLastStep={
                        index === history.length - 1 ||
                        (index === 5 && !fullView)
                      }
                    />
                  }
                  className="top-0 left-30 absolute"
                >
                  <p className="fs-16 c-primary ml-10">{activity}</p>
                </StepLabel>
                <Box className="top-2 left-0 absolute">
                  <p className="fw-500">
                    {moment(updated_at).format("MMMM DD, YYYY")}
                  </p>
                </Box>
              </Step>
            ))}
          {history.length > 6 && (
            <div
              className="flex justify-center items-center"
              style={{ zIndex: 99 }}
            >
              <Button classes="mx-auto mt-0 w-150 fs-15" onClick={toggleView}>
                {fullView ? t("Load Less") : t("Load More")}
              </Button>
            </div>
          )}
        </Stepper>
      ) : (
        <NoDataView text={t("No project history yet!")} />
      )}
    </Card>
  );
};

export default ProjectHistory;
