import React, { useMemo, useState } from "react";
import useAdvanceSearch from "store/advance-search/service-hook";
import useAdvanceSearchFilter from "../hooks/useAdvanceSearchFilter";
import { AS_REDUCER } from "store/advance-search/reducer";
import { useAdvanceSearchContext } from "../hooks/useAdvanceSearchContext";
import { ADVANCE_SEARCH_COMPONENTS } from "../data";
import { useTranslation } from "react-i18next";
import ChallengeListing from "pages/challenges/listing/ChallengeListing";

const AdvanceSearchChallengeTemplate = () => {
  const { gridType, keyword, getFiltersViaType, filters } =
    useAdvanceSearchContext();
  const { searchChallengeTemplates } = useAdvanceSearch();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const Filters = useMemo(() => {
    return getFiltersViaType(ADVANCE_SEARCH_COMPONENTS.CHALLENGE_TEMPLATES);
  }, [filters]);

  const { data } = useAdvanceSearchFilter(
    ADVANCE_SEARCH_COMPONENTS.CHALLENGE_TEMPLATES,
    keyword,
    { page, ...Filters },
    searchChallengeTemplates,
    AS_REDUCER.setChallengeTemplates
  );

  return (
    <ChallengeListing
      browseMode={0}
      challenges={data?.data || []}
      gridType={gridType}
      noPagination={!Boolean(data?.meta ?? false)}
      page={data?.meta?.current_page ?? 1}
      setPage={setPage}
      pageCount={data?.meta?.total_pages ?? 1}
      loading={data?.loading}
    />
  );
};

export default AdvanceSearchChallengeTemplate;
