import React from "react";
import DashboardLayout from "..";
import { TERMS } from "pages/layouts/data";
import Card from "components/Cards/Card";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Terms = () => {
  const { title, lastRevisedDate, content, content_fr } = TERMS;
  const { t } = useTranslation("legal");
  return (
    <DashboardLayout>
      <div className="container mt-20">
        <Card centered classes="text-center h-150 flex-column justify-center">
          <h2>{t(title)}</h2>
          <p className="mt-2 c-gray">
            {t("Last Update:")} {t(lastRevisedDate)}
          </p>
        </Card>
        <Card classes="mt-4 px-6 fs-15 py-6">
          {i18next.language === "en" ? content : content_fr}
          <div className="mt-4">
            <p>{t("In writing Attn")}:</p>
            <p className="fw-700">Salar Chagpar</p>
            <p>{t("Privacy Information Officer")}</p>
            <p className="mt-4">{t("Address")}:</p>
            <p className="fw-700">PREPR FOUNDATION</p>
            <p>
              500 King St. W <br />
              Toronto, ON
              <br />
              N2G 1C4{" "}
            </p>
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
};

export default Terms;
