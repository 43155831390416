import { Validate } from "helpers/utils/validators";
import { useState } from "react";

const defaultSlice = (schema) => schema.slice(0, schema.length);

const useForms = (schema = [], initValue = {}, sliceSchema = defaultSlice) => {
  const [data, setData] = useState(initValue);
  const [errors, setErrors] = useState(new Map());
  const [dirty, setDirty] = useState(false);

  const validate = async () => {
    const newErrors = new Map();
    for (const {
      name,
      validator,
      optinalValidatorFunc,
      optinalValidatorKey,
    } of sliceSchema(schema)) {
      const error = await Validate(
        validator,
        data[name],
        optinalValidatorKey ? data[optinalValidatorKey] : optinalValidatorFunc
      );
      if (error) newErrors.set(name, error);
    }

    setErrors(newErrors);
    return newErrors;
  };

  const onChange = (e) => {
    if(!dirty) setDirty(true);
    const { value, name } = e.target;
    const {
      validator,
      optinalValidatorFunc,
      optinalValidatorKey,
      immediateValidation,
    } = schema?.find((d) => d.name === name) || {};
    if (immediateValidation) {
      if (!validator) return;
      Validate(
        validator,
        value,
        optinalValidatorKey ? data[optinalValidatorKey] : optinalValidatorFunc
      ).then((err) => {
        setErrors(prevErrors => {
          const newErrors = new Map(prevErrors);
          if (err) {
            newErrors.set(name, err);
          } else {
            newErrors.delete(name);
          }
          return newErrors;
        });
      });
    } else {
      setErrors(prevErrors => {
        const newErrors = new Map(prevErrors);
        newErrors.delete(name);
        return newErrors;
      });
    }
    setData(prevData => ({ ...prevData, [name]: value }));
  };

  return {
    data,
    onChange,
    errors,
    validate,
    setData,
    dirty,
    setErrors,
    setDirty
  };
};

export default useForms;
