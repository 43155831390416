import { DoneOutlined } from "@mui/icons-material";
import {
  hasCapital,
  hasNumber,
  hasSpecialChar,
  minLength,
} from "helpers/utils/validators";
import React from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import classes from "./password-validator.module.css";
import { useTranslation } from "react-i18next";

const Check = ({ condition }) =>
  condition ? (
    <DoneOutlined color="success" fontSize="small" />
  ) : (
    <div className={classes.circle}></div>
  );

const PasswordValidator = ({ password }) => {
  const { t } = useTranslation("auth");
  return (
    <div
      data-testid="password-validator"
      className={classes["password-validator"]}
    >
      <PasswordStrengthBar
        password={password}
        style={{ width: "100%" }}
        scoreWordStyle={{
          textAlign: "left",
          fontSize: 14,
          color: "black",
          fontWeight: 500,
          textTransform: "capitalize"
        }}
      />
      <div className={`${classes.validationTexts} mb-1`}>
        <b className="small">{t("Password must include")}:</b>
        <div className="flex-center fs-14">
          <Check condition={minLength(password, 8)} />
          <small>{t("8 to 14 characters")}</small>
        </div>
        <div className="flex-center fs-14">
          <Check condition={hasCapital(password)} />
          <small>{t("1 capital letter")}</small>
        </div>
        <div className="flex-center fs-14">
          <Check condition={hasNumber(password)} />
          <small>{t("1 number")}</small>
        </div>
        <div className="flex-center fs-14">
          <Check condition={hasSpecialChar(password)} />
          <small>
            {" "}
            {t("1 special character")}:
            <br />
            {"@ # $ % ^ & * ? _ ~ - ( ) + = { } [ ] | ; : ' “ < >  / ,  ."}
          </small>
        </div>
      </div>
    </div>
  );
};

export default PasswordValidator;
