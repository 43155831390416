import Card from "components/Cards/Card";
import CreationOptionsModal from "components/Modal/CreateOptionsModal";
import EmptyText from "components/UI/atoms/EmptyText";
import NuxLayout from "components/UI/molecules/NuxLayout";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import LabListing from "pages/labs/listing/LabListing";
import { getLabNUX, useLabOptions } from "pages/labs/listing/ManageLabs";
import { NotLoggedInTabs } from "pages/organization";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ENDPOINTS } from "store/api/endpoints";
import useAssociation from "store/association";
import useAuth from "store/auth/service-hook";
import Select from "components/Input/Select";
import useRedirect from "helpers/hooks/useRedirect";

const OPTIONS = [
  { value: "Labs", key: "lab" },
  { value: "Lab Programs", key: "lab-program" },
];

const AssociateLabListing = ({
  isPrivateView,
  slug,
  smallView,
  parent,
  emptyView,
  emptyMessageNotLoggedIn,
  isLP,
  showSwitch,
}) => {
  const [labs, setLabs] = useState([]);
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState("lab");
  const {
    state: { isLoggedIn },
  } = useAuth();
  const { fetchAssociation } = useAssociation();
  const { t } = useTranslation("common");
  const { navigateCreateLabProgram } = useRedirect();

  const labOptions = useLabOptions();

  useEffect(() => {
    handleFetchAssociation();
  }, [page, selected]);

  const handleFetchAssociation = async () => {
    setLoading(true);
    const res = await fetchAssociation(
      parent,
      slug,
      selected || ENDPOINTS.LAB,
      {
        page,
      }
    );
    const [list, totalPages] = res;
    setLabs(list || []);
    setPages(totalPages || 1);
    setLoading(false);
  };

  const handleModalClose = () => setModal(null);

  return (
    <>
      {!isLoggedIn ? (
        <NotLoggedInTabs
          title={
            emptyMessageNotLoggedIn ||
            `Login to view labs in this ${parent.split("-").join(" ")}`
          }
        />
      ) : (
        <>
          {showSwitch && (
            <div className="flex-center justify-between w-100p mt-3">
              <h3 className="ml-1">
                {t(OPTIONS.find((d) => d?.key === selected)?.value || "Labs")}
              </h3>
              <Select
                borderedIcon
                placeholder={t("lab")}
                width="100%"
                value={selected}
                keyValues={OPTIONS}
                onChange={(e) => setSelected(e.target.value)}
                height={40}
                classes="w-25p w-50p--mob"
                t={(v) => t(v, { ns: "common" })}
              />
            </div>
          )}

          {isPrivateView &&
          !labs?.length &&
          !loading &&
          parent === COMPONENT_TYPES.ORGANIZATION ? (
            <NuxLayout
              browseMode={selected === COMPONENT_TYPES.LAB ? 0 : 1}
              newUserData={getLabNUX(() =>
                selected === COMPONENT_TYPES.LAB
                  ? setModal("create")
                  : navigateCreateLabProgram()
              )}
            />
          ) : (
            <LabListing
              labs={labs || []}
              loading={loading}
              gridType={1}
              setLabs={setLabs}
              manage={isPrivateView}
              smallView={smallView}
              page={page}
              setPage={setPage}
              pageCount={pages}
              isAssociation
              isLP={selected === ENDPOINTS.LAB_PROGRAM}
              emptyView={
                emptyView || (
                  <Card
                    style={{ border: "2px solid green" }}
                    classes="flex items-center justify-center w-full text-center ml-4"
                  >
                    <EmptyText classes="c-gray w-70p opacity-80">
                      {selected === ENDPOINTS.LAB_PROGRAM
                        ? t(
                            "There is no Lab Program associated with this organization now, please check back later."
                          )
                        : t(
                            "There is no Lab associated with this organization now, please check back later."
                          )}
                    </EmptyText>
                  </Card>
                )
              }
            />
          )}
        </>
      )}

      <CreationOptionsModal
        open={modal === "create"}
        onClose={handleModalClose}
        title="How Do You Want To Create A New Lab?"
        options={labOptions}
      />
    </>
  );
};

export default AssociateLabListing;
