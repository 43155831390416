import { Grid } from "@mui/material";
import Button from "components/Button";
import AlertMessage from "components/UI/atoms/AlertMessage";
import Banner from "components/UI/molecules/Banner";
import MasterSelect from "components/UI/organisms/MasterSelect";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import React, { useMemo } from "react";
import { MAX_CREDITS } from "../../labMarketplace";

const MarketplaceBanner = ({ organization, setOrganization, usedCredits }) => {
    const windowWidth = useWindowWidth();
    const onTab = windowWidth < 900;

    const allCreditsExhausted = useMemo(
        () => usedCredits === MAX_CREDITS,
        [usedCredits]
    );

    return (
        <Banner classes={!!onTab && allCreditsExhausted ? 'h-500' : 'h-400'}>
            <div
                className={`container pt-4 ${allCreditsExhausted ? "mt-10" : "mt-header"
                    } h-80p flex-column justify-center`}
            >
                {allCreditsExhausted && (
                    <AlertMessage
                        title="You have used all your Lab credits."
                        description="Please purchase more credits or upgrade your existing plan. If you do not have permission to change your plan, contact your organization administrator."
                        severity="error"
                        classes={!!onTab ? 'mt-7 mb-2' : 'my-t mb-4'}
                    />
                )}
                <h1 className="text-center fs-22--mob">Lab Marketplace</h1>
                <p
                    className={`fs-14 fs-13--mob mx-auto text-center ${onTab ? "w-90p mt-0" : "w-70p mt-2"
                        }`}
                >
                    Explore and redeem pre-built Labs now and start Lab creation with only
                    a few clicks, get started with selecting the Organization you are
                    managing from the dropdown below
                </p>
                <Grid className="mt-2" container rowSpacing={2}>
                    <Grid item xs={12} lg={6}>
                        <div
                            className={`flex items-center ${onTab ? "justify-between" : "justify-center"
                                } w-100p`}
                        >
                            <p className="fs-13--mob mr-2">
                                <b>Explore for</b>
                            </p>
                            <MasterSelect
                                name="organization"
                                value={organization}
                                onChange={(e) => setOrganization(e.target.value)}
                                width={`${!!onTab ? "250px" : "350px"}`}
                                placeholder="select organization..."
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <div
                            className={`flex items-center ${onTab ? "justify-between" : "justify-center"
                                } w-100p`}
                        >
                            <p className="fs-13--mob mr-2">
                                <b className="mr-1">Pre-built Lab credits:</b>
                                <span
                                    className={allCreditsExhausted && "c-error fw-500"}
                                >{`${usedCredits}/${MAX_CREDITS}`}</span>
                            </p>
                            <Button classes="fs-13--mob mt-1" color="orange">
                                Purshase Credits
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Banner>
    );
};

export default MarketplaceBanner;
