import Card from "components/Cards/Card";
import DashboardLayout from "pages/layouts/dashboard";
import MainPageLayout from "pages/layouts/MainPage";
import React, { useEffect, useState } from "react";
import useSkills from "store/skills/service-hook";
import { useParams } from "react-router-dom";
import { Bookmark, BookmarkBorder, Spa } from "@mui/icons-material";
import { Box, Chip, Icon } from "@mui/material";
import Button from "components/Button";
import { isTrue } from "helpers/utils/validators";
import useProfile from "store/profile/service-hook";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { loginToPreceed } from "helpers/utils/utilities";
import useAuth from "store/auth/service-hook";
import MainTabs from "components/UI/organisms/MainTabs";
import RelatedJobs from "pages/careers/components/RelatedJobs";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { ENDPOINTS } from "store/api/endpoints";
import AssociateChallengeListing from "pages/associateListings/AssociateChallengeListing";
import AssociateLabListing from "pages/associateListings/AssociateLabListing";
import AssociateResourceListing from "pages/associateListings/AssociateResourceListing";
import useRedirect from "helpers/hooks/useRedirect";
import ImageCard from "components/Cards/ImageCard";
import { logo } from "assets/svg";
import skillDefaultImage from "assets/png/skill-default.png";

const skills_tabs = ["Related Challenges", "Related Labs", "Related Resources"];

const SkillDetailPage = () => {
  const [skill, setSkill] = useState(null);
  const { view } = useSkills();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const { deleteSkills } = useProfile();
  const { add } = useSkills();
  const { navigateExplore } = useRedirect();
  const {
    state: { isLoggedIn },
  } = useAuth();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    if (!id) return;
    fetchSkill();
  }, [id]);

  const fetchSkill = async () => {
    setLoading(true);
    const res = await view(id);
    setSkill(res);
    setLoading(false);
  };

  const doUndoAction = async (id, property, doAction, undoAction) => {
    if (!isLoggedIn) {
      loginToPreceed();
      return;
    }
    const isPropertyTrue = skill[property] === "yes";
    if (isPropertyTrue) {
      await undoAction(id);
    } else {
      await doAction(id);
    }
    setSkill({
      ...skill,
      is_saved:
        property === "pinned"
          ? !isPropertyTrue
            ? "yes"
            : skill?.is_saved
          : isPropertyTrue
          ? "no"
          : "yes",
      pinned:
        property === "is_saved"
          ? isPropertyTrue
            ? "no"
            : skill?.pinned
          : isPropertyTrue
          ? "no"
          : "yes",
    });
  };

  const handleAddRemoveSkill = (id) =>
    doUndoAction(id, "is_saved", add, deleteSkills);

  return (
    <DashboardLayout loading={loading} isEmpty={!skill}>
      <MainPageLayout
        classes="mt-header pb-6"
        firstLgGridSize={4}
        secondLgGridSize={8}
      >
        <div className="left">
          <ImageCard
            image={skillDefaultImage}
            height="250px"
            classes="mt-4"
            type={COMPONENT_TYPES.SKILL}
            title={skill?.title}
            imgAbsoluteContent={
              <Box className="flex flex-column items-center justify-between h-full absolute top-0 left-0 w-full">
                <div className="flex justify-between w-full c-orange fw-500 px-1">
                  <img src={logo} height={18} alt="logo" />
                  <div className="fs-10 flex mt-1">
                    <p>SKILL</p>
                    <Icon component={Spa} sx={{ fontSize: 13 }} />
                  </div>
                </div>
                <div className="h-40 text-center w-full flex items-center justify-center bg-gray">
                  <h2 className="uppercase fs-18 tracking-tight text-center mx-auto text-truncate maxw-90p">
                    {skill?.title}
                  </h2>
                </div>
                <p className="text-center fs-10 mb-1 c-white">
                  CO-LEARN. CO-LAB. CO-SOLVE.®
                </p>
              </Box>
            }
          />
          <Card classes="mt-5">
            <h2 className="c-primary">{skill?.title}</h2>
            <Button
              classes="mt-2 fs-13"
              bordered
              height="30px"
              onClick={() => handleAddRemoveSkill(skill?.id)}
            >
              <Icon
                component={isTrue(skill?.is_saved) ? BookmarkBorder : Bookmark}
                className="c-primary"
                fontSize="small"
              />
              {isTrue(skill?.is_saved)
                ? t("Unsave", "common")
                : t("Save", "common")}
            </Button>
            <p className="fs-12 mt-4">{skill?.description}</p>
            {skill?.skill_url && (
              <p
                className="link fs-12 mt-1"
                onClick={() => window.open(skill.skill_url)}
              >
                {t("Read More on Wikipedia")}
              </p>
            )}
            <h3 className="mt-4">{t("Related Skills")}</h3>
            <div className="flex items-center mt-2 flex-wrap">
              {!!skill?.related_skills?.length
                ? skill.related_skills.map((title, i) => (
                    <Chip
                      key={i}
                      label={title}
                      variant="outlined"
                      className="mr-1 mt-1"
                    />
                  ))
                : t("N/A")}
            </div>
          </Card>
        </div>
        <div className="right mt-5">
          <MainTabs current={tab} setCurrent={setTab} tabs={skills_tabs} />
          {tab === 0 && (
            <AssociateChallengeListing
              parent={ENDPOINTS.SKILL}
              slug={skill?.id}
              emptyView={
                <Card classes="w-full ml-4">
                  <h3>{t("Related Challenges")}</h3>
                  <p className="c-gray fw-500 text-center my-8 mx-auto w-70p opacity-80 ">
                    {t(
                      "There is no Challenge related to this skill now, please check back later or explore other Challenges."
                    )}
                  </p>
                  <div className="flex justify-center mb-4">
                    <Button onClick={navigateExplore} bordered>
                      {t("Explore Challenges")}
                    </Button>
                  </div>
                </Card>
              }
            />
          )}
          {tab === 1 && (
            <AssociateLabListing
              parent={ENDPOINTS.SKILL}
              slug={skill?.id}
              emptyView={
                <Card classes="w-full ml-4">
                  <h3>{t("Related Labs")}</h3>
                  <p className="c-gray fw-500 text-center my-8 mx-auto w-70p opacity-80 ">
                    {t(
                      "There is no Lab related to this skill now, please check back later or explore other Labs."
                    )}
                  </p>
                  <div className="flex justify-center mb-4">
                    <Button onClick={navigateExplore} bordered>
                      {t("Explore Labs")}
                    </Button>
                  </div>
                </Card>
              }
            />
          )}
          {tab === 2 && (
            <AssociateResourceListing
              parent={ENDPOINTS.SKILL}
              slug={skill?.id}
              emptyView={
                <Card classes="w-full ml-4">
                  <h3>Related Resources</h3>
                  <p className="c-gray fw-500 text-center my-8 mx-auto w-70p opacity-80 ">
                    {t(
                      "There are no Resources related to this skill now, please check back later or explore other Resources."
                    )}
                  </p>
                  <div className="flex justify-center mb-4">
                    <Button onClick={navigateExplore} bordered>
                      {t("Explore Resources")}
                    </Button>
                  </div>
                </Card>
              }
            />
          )}
          <h3 className="mt-4 ml-1">{t("Related Jobs")}</h3>
          <RelatedJobs jobs={skill?.related_jobs} loading={loading} />
        </div>
      </MainPageLayout>
    </DashboardLayout>
  );
};

export default SkillDetailPage;
