import { Delete } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import moment from "moment";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { useState } from "react";

const ExperienceItem = ({
  title,
  subTitle,
  address,
  description,
  startDate,
  endDate,
  patentDate,
  state,
  country,
  onDelete,
  itsMe,
  t = (d) => d,
}) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const onDeleteExpItem = () => {
    onDelete();
    setOpenConfirmationModal(false);
  };

  const cancelExitConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  return (
    <Grid
      container
      xs={12}
      rowSpacing={1}
      columnSpacing={2}
      mb={2}
      sx={{
        pl: 2,
        "& .deleteIcon": { visibility: "hidden" },
        "&:hover .deleteIcon": { visibility: "visible" },
      }}
    >
      <Grid item xs={12} sm={3.5}>
        <h3 className="c-primary capitalize fs-17 tracking-tight maxw-250 text-truncate">
          {title || "N/A"}
        </h3>
      </Grid>
      <Grid item xs={12} sm={7.5}>
        <h3 className="capitalize fs-17">{subTitle || "N/A"}</h3>
        <p className="fs-14">
          {patentDate
            ? moment(patentDate).format("MMMM YYYY")
            : `${startDate ? ` ${moment(startDate).format("MMMM YYYY")}` : ""}${
                startDate && endDate
                  ? ` - ${moment(endDate).format("MMMM YYYY")}`
                  : endDate
                  ? `${moment(endDate).format("MMMM YYYY")}`
                  : ""
              }${
                (startDate || endDate) && address
                  ? ` | ${address}`
                  : address
                  ? ` ${address}`
                  : ""
              }${state ? `, ${state}` : ""}${country ? `, ${country}` : ""}`}
        </p>
        <p
          className="fs-15 tracking-tight opacity-60"
          style={{ whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{
            __html: Boolean(description) ? description : "N/A",
          }}
        ></p>
      </Grid>
      <Grid item xs={12} sm={1} className="flex items-center justify-end">
        {itsMe && (
          <IconButton
            onClick={() => setOpenConfirmationModal(true)}
            className="deleteIcon"
          >
            <Delete className="c-error" />
          </IconButton>
        )}
      </Grid>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={cancelExitConfirmationModal}
        desc={t("Are you sure you want to delete this?")}
        okayButtonTitle={t("Yes")}
        onOkay={onDeleteExpItem}
      />
    </Grid>
  );
};

export default ExperienceItem;
