import PageTitleCard from "components/UI/molecules/PageTitleCard";
import ListingPageHeader from "components/UI/organisms/ListingPageHeader";
import FilterCard from "components/UI/organisms/FilterCard";
import DashboardLayout from "pages/layouts/dashboard";
import MainPageLayout from "pages/layouts/MainPage";
import React, { useEffect, useState } from "react";
import OrgListing from "./OrgListing";
import useOrganization from "store/organization/service-hook";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import useRedirect from "helpers/hooks/useRedirect";
import PlanDetails from "components/UI/molecules/PlanDetails";
import { COMPONENT_TYPES } from "helpers/utils/constants";

const ManageOrganizations = () => {
  const [gridType, setGridType] = useState(1);
  const { getOrganizationList, deleteOrganization } = useOrganization();
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("creation_date");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const { selected } = useFilters();
  const { navigateCreateOrg } = useRedirect();

  useEffect(() => {
    const abortController = new AbortController();
    fetchOrganizations(abortController);
    return () => abortController.abort();
  }, [selected, sort, page, search]);

  const fetchOrganizations = async (abortController) => {
    setLoading(true);
    const filters = {
      ...selected,
      sort_by: sort,
      search,
      page,
      owner: selected.owner || 'all',
    };

    if (selected?.organization_type)
      filters.category = selected.organization_type;
    delete filters?.organization_type;
    const [list, count] = await getOrganizationList(filters);
    if (!abortController || !abortController.signal.aborted) {
      setOrganizations(list || []);
      setPageCount(count);
      setLoading(false);
    }
  };

  const handleDelete = async (slug) => {
    await deleteOrganization(slug);
    setOrganizations((prev) => prev.filter((d) => d.slug !== slug));
  };

  return (
    <DashboardLayout isPrivate managerAccessOnly component={COMPONENT_TYPES.ORGANIZATION}>
      <PageTitleCard
        title={"Manage Organizations"}
        actionTitle={"Create Organization"}
        onClickAction={navigateCreateOrg}
      >
        <PlanDetails component={COMPONENT_TYPES.ORGANIZATION} />
      </PageTitleCard>
      <MainPageLayout classes="mt-3 mb-6">
        <div className="left mt-2">
          <FilterCard
            title={"Advanced Search"}
            types={[
              FILTER_TYPES.AUTHOR,
              FILTER_TYPES.PLAN,
              FILTER_TYPES.ORGANIZATION_TYPE,
              FILTER_TYPES.VERIFICATION_STATUS,
            ]}
          />
        </div>
        <div className="right mt-2">
          <ListingPageHeader
            gridType={gridType}
            setGridType={setGridType}
            search={search}
            setSearch={setSearch}
            sort={sort}
            setSort={setSort}
          />
          <OrgListing
            gridType={gridType}
            list={organizations}
            handleDelete={handleDelete}
            loading={loading}
            page={page}
            setPage={setPage}
            pageCount={pageCount}
            manage
            fetchOrganizations={fetchOrganizations}
          />
        </div>
      </MainPageLayout>
    </DashboardLayout>
  );
};

export default ManageOrganizations;
