import Card from "components/Cards/Card";
import CreateFormFooter from "components/UI/atoms/CreateFormFooter";
import MainTabs from "components/UI/organisms/MainTabs";
import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState, useTransition } from "react";
import { useParams } from "react-router-dom";
import useOrganization from "store/organization/service-hook";
import { Box, Chip, Container, Grid } from "@mui/material";
import GetAddonsButton from "components/UI/molecules/GetAddonsButton";
import { isTrue } from "helpers/utils/validators";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import Button from "components/Button";
import useRedirect from "helpers/hooks/useRedirect";

const OrgIntegrationSettings = () => {
  const [tab, setTab] = useState(0);
  const { id } = useParams();
  const [plan, setPlan] = useState();
  const [loading, setLoading] = useState(false);
  const { planDetails } = useOrganization();
  const [integrations, setHRIntegrations] = useState([]);
  const [organization, setOrganization] = useState();
  const { navigateBack, navigateOrg } = useRedirect();
  const { getOrganization } = useOrganization();
  const [pending] = useTransition();

  useEffect(() => {
    if (!id) return;
    fetchInfo();
  }, [id]);

  const fetchInfo = async () => {
    setLoading(true);
    const res = await planDetails(id);
    const orgRes = await getOrganization(id);
    setPlan(res);
    if (orgRes && !orgRes?.error) {
      setOrganization(orgRes);
      setHRIntegrations(orgRes?.hr_integrations || []);
    }
    setLoading(false);
  };

  // const handleToggle = (type) => () => {
  //   setHRIntegrations((prev) => [
  //     ...prev.map((d) => ({
  //       ...d,
  //       isActive: type === d?.id ? !d?.isActive : d?.isActive,
  //     })),
  //   ]);
  // };

  const saveChanges = async () => {
    // const payload = {
    //   integration_type: payloadFormatArray(integrations, "id"),
    //   action: payloadFormatArray(integrations, "isActive").map((d) =>
    //     !d ? "deactivate" : "activate"
    //   ),
    // };
    // startTransition(async () => {
    //   await hrIntegrationsSetting(payload, id);
    // });
  };

  return (
    <DashboardLayout
      loading={loading}
      show404OnError
      isEmpty={organization?.error}
    >
      <Card centered classes="container mt-header px-10">
        <h2 className="text-center my-2">
          Organization Integration Settings:
          <span
            className="link ml-1 capitalize"
            onClick={() => navigateOrg(organization?.slug, true)}
          >
            {organization?.title}
          </span>
        </h2>
        <MainTabs
          tabs={["Third-party Integrations", "HR Integrations"]}
          current={tab}
          setCurrent={setTab}
          classes="mt-6"
        />
        <Card classes="mt-6 minh-260">
          {tab === 0 ? (
            <IntegrationTable
              disable
              list={[
                {
                  type: "go1",
                  name: "Go1",
                  logo_url:
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIzE71bQF-NYwCJP5X2JCeBa2AXVMlPe2TAA&s",
                  existing_connection: false,
                },
                // Disabling for current time being as mentioned in LLAI-4938
                // {
                //   type: "airmeet",
                //   name: "Airmeet",
                //   logo_url:
                //     "https://www.peakxv.com/wp-content/uploads/sites/2/2022/04/airmeet_logo.png",
                //   existing_connection: false,
                // },
              ]}
              title="Third-party Integrations"
            />
          ) : isTrue(plan?.hr_integration) ? (
            <IntegrationTable
              list={integrations}
              title="HR Integrations"
              toggle={(unified_login_url) => window.open(unified_login_url)}
              isHR
            />
          ) : (
            <Box className="flex-column items-center justify-center">
              <h3 className="mt-6">HR Integrations</h3>
              <p className="my-5">
                Please purchase the HR Integrations add on to unlock this
                feature.
              </p>
              <GetAddonsButton />
            </Box>
          )}
        </Card>
        <CreateFormFooter
          classes="w-full mt-6"
          backButtonColor="blue"
          okButtonColor="blue"
          okButtonTitle="Save Changes"
          onCancel={navigateBack}
          onOk={saveChanges}
          loading={pending}
          disabled={!isTrue(plan?.hr_integration)}
        />
      </Card>
    </DashboardLayout>
  );
};

export default OrgIntegrationSettings;

const IntegrationTable = ({
  list = [],
  disable,
  title: listTitle,
  toggle = Function.prototype,
  isHR,
}) => {
  const { t } = useLocalizedTranslation();
  return (
    <Container px={{ xs: 0, md: 2 }} my={2}>
      <h3 className="mt-2">{t(listTitle)}</h3>
      <Grid container className="fs-14 opacity-50 fs-12--mob mb-1" mt={4}>
        <Grid pl={1} item xs={6}>
          <p>{t("Application Name")}</p>
        </Grid>
        <Grid pl={1} item xs={3}>
          <p>{t("Integration Status")}</p>
        </Grid>
        <Grid pl={1} item xs={3}>
          <p>{t("Interaction")}</p>
        </Grid>
      </Grid>
      {list.map(
        (
          { name, type, logo_url, existing_connection, unified_login_url },
          i
        ) => {
          return (
            <Grid
              container
              key={i}
              py={1.5}
              className="border-t"
              alignItems="center"
            >
              <Grid item xs={8} sm={6}>
                <div className="flex items-center">
                  <img src={logo_url} height={40} alt={name} />
                  <p className="ml-2">{name}</p>
                </div>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Chip
                  className={`h-25 py-1 ${
                    existing_connection ? "c-primary bg-primary-light" : ""
                  }`}
                  label={existing_connection ? t("Activated") : t("Not Active")}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                sx={{
                  margin: { xs: "10px auto" },
                }}
              >
                <Button
                  classes={
                    existing_connection &&
                    `border-none ${isHR ? "c-success" : "c-error"} underline`
                  }
                  bordered
                  height="30px"
                  disabled={disable || (isHR && existing_connection)}
                  onClick={() =>
                    toggle(isHR ? unified_login_url : type, existing_connection)
                  }
                >
                  {existing_connection
                    ? t(isHR ? "Activated" : "Deactivate")
                    : t("Activate")}
                </Button>
              </Grid>
            </Grid>
          );
        }
      )}
    </Container>
  );
};
