import React from "react";
import { PieChart } from "react-minimal-pie-chart";

const DonutChart = ({
  title,
  totalValue,
  classes,
  data,
  height,
  width,
  hasSizeClasses,
  completedValue,
}) => {
  const valueSum = !data
    ? "0"
    : data.reduce((prev, curr) => prev + +curr?.value, 0);

  return (
    <div
      data-testid="donut-chart"
      className={`${!hasSizeClasses && "h-250 w-250"} ${classes}`}
    >
      <PieChart
        style={{
          height: height ? height : "250px",
          width: width ? width : "250px",
        }}
        startAngle={-90}
        background="#eee"
        totalValue={totalValue}
        lineWidth={17}
        radius={40}
        data={data}
        animate={true}
        labelPosition={0}
        label={({ x, y, dx, dy, dataIndex }) =>
          dataIndex === 0 && (
            <text
              x={x}
              y={y}
              dx={dx}
              dy={dy}
              text-anchor="middle"
              style={{
                fill: data[0]?.color,
                pointerEvents: "none",
                fontSize: "7px",
                fontWeight: "400",
                letterSpacing: "-0.5px",
              }}
            >
              <tspan
                x={x}
                y={y}
                dx={dx}
                dy={dy}
                style={{ fontSize: "10px", fontWeight: 700 }}
              >
                <tspan style={{ fontSize: "15px" }}>
                  {`${
                    typeof completedValue === "number"
                      ? completedValue
                      : valueSum
                  }`}
                </tspan>
                <tspan style={{ fill: "#101223" }}>/{totalValue}</tspan>
              </tspan>
              <tspan
                x={x}
                y={y}
                dx={dx}
                dy={10}
              >
                {title}
              </tspan>
            </text>
          )
        }
      />
    </div>
  );
};

export default DonutChart;
