import Card from "components/Cards/Card";
import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import PrivacySettings from "./components/PrivacySettings";
import IntegrationSettings from "./components/IntegrationSettings";
import PageTitle from "components/UI/atoms/PageTitle";
import MainTabs from "components/UI/organisms/MainTabs";
import AccountSettings from "./components/AccountSettings";
import useForms from "helpers/hooks/useForms";
import { ACCOUNT_SCHEMA, PRIVACY_DATA } from "./data";
import CreateFormFooter from "components/UI/atoms/CreateFormFooter";
import ChangePassword from "./components/ChangePassword";
import Notifications from "./components/Notifications";
import useSettings from "store/settings/service-hook";
import {
  PASSWORD_VALIDATOR,
  Validate,
  REQUIRED_VALIDATOR,
} from "helpers/utils/validators";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useProfile from "store/profile/service-hook";
import ImageCropper from "components/Modal/ImageCropper";
import useRedirect from "helpers/hooks/useRedirect";

const settings_tabs = [
  "Account",
  "Change Password",
  "Privacy",
  "Authentication Integrations",
  "Notifications",
];

const SettingsPage = () => {
  const [tab, setTab] = useState(0);
  const [settings, setSettings] = useState();
  const { navigateBack } = useRedirect();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState();
  const [loading, setLoading] = useState(false);
  const [privacyErrors, setPrivacyErrors] = useState({});
  const [newProfilePhoto, setNewProfilePhoto] = useState(null);
  const { t } = useLocalizedTranslation();
  const { addProfilePhoto } = useProfile();

  const {
    details,
    updateAccount,
    updatePrivacy,
    updateNotifications,
    removeImage,
    updatePassword,
    deactivateAccount,
    ssoAuthSetting,
  } = useSettings();
  const { data, errors, onChange, setData, validate } =
    useForms(ACCOUNT_SCHEMA);

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    const res = await details();
    if (!res) {
      setSettings({ error: true });
      return;
    }
    const privacySettings = res.privacy || {};
    const notificationSettings = res.notification || {};
    setSettings({
      ...res,
      privacy: privacySettings,
      notification: notificationSettings,
    });

    const newData = {};
    ACCOUNT_SCHEMA?.forEach(({ name }) => {
      newData[name] = res[name];
    });
    setData({ ...newData });
  };

  const submitProfilePhoto = async (image) => {
    const res = await addProfilePhoto(image);
    if (res) {
      setSettings({ ...settings, profile_image: image });
    }
  };

  const onCancel = () => {
    navigateBack();
  };
  const handleUpdateAccount = async () => {
    let error = await validate();
    if (error.size) return;
    if (!data.phone_number) delete data.phone_number;
    setLoading(true);
    await updateAccount(data);
    setLoading(false);
  };

  const handleUpdatePrivacy = async () => {
    const isValid = await validatePrivacySettings();
    if (!isValid) return;
    setLoading(true);
    await updatePrivacy(settings?.privacy);
    setPrivacyErrors({});
    setLoading(false);
  };

  const handleUpdateNotifications = async () => {
    setLoading(true);
    await updateNotifications(settings?.notification);
    setLoading(false);
  };

  const validatePrivacySettings = async () => {
    const newErrors = {};
    for (const { name, required } of PRIVACY_DATA) {
      if (required) {
        const error = await Validate(
          REQUIRED_VALIDATOR,
          settings?.privacy?.[name]
        );
        if (error) {
          newErrors[name] = error;
        }
      }
    }
    setPrivacyErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdatePassword = async () => {
    const validationError = await Validate(
      PASSWORD_VALIDATOR,
      password,
      confirmPassword
    );
    if (validationError) {
      setPasswordError(validationError);
      return;
    }
    setLoading(true);
    const res = await updatePassword({
      password,
      password_confirmation: confirmPassword,
    });
    if (res) {
      setPassword("");
      setConfirmPassword("");
      setPasswordError(null);
    }
    setLoading(false);
  };

  const handleSubmit = () => {
    switch (tab) {
      case 0:
        handleUpdateAccount();
        break;
      case 1:
        handleUpdatePassword();
        break;
      case 2:
        handleUpdatePrivacy();
        break;
    case 3:
        handleSSOActiveInactive();
        break;
      case 4:
        handleUpdateNotifications();
        break;
      default:
        break;
    }
  };

  const onChangeSettings = (type, e) => {
    const { name, value } = e.target;
    const updatedSettings = {
      ...settings,
      [type]: {
        ...(settings[type] || {}),
        [name]: value,
      },
    };
    setSettings(updatedSettings);
    setPrivacyErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const handleSSOActiveInactive = async () => {
    const payload = {
      sso_type: Object.keys(settings.sso_integrations),
      action: Object.values(settings.sso_integrations),
    };
    await ssoAuthSetting(payload);
  };

  const handleToggle = (type, isDeactivate) => () => {
    setSettings((prev) => ({
      ...prev,
      sso_integrations: {
        ...prev.sso_integrations,
        [type]: isDeactivate ? "inactive" : "active",
      },
    }));
  };

  return (
    <DashboardLayout
      loading={!settings}
      isEmpty={settings?.error}
      emptyMessage={t("Unable to fetch settings details for your account!")}
    >
      <Card
        width="min(1200px,90%)"
        centered
        classes="mt-20 mb-4 mx-auto column-center"
      >
        <PageTitle>{t("Settings")}</PageTitle>
        <MainTabs
          classes="mt-6 w-full my-2"
          tabs={settings_tabs}
          current={tab}
          setCurrent={setTab}
        />
        <Card classes="mt-3 w-full">
          {tab === 0 && (
            <AccountSettings
              data={data}
              onChange={onChange}
              errors={errors}
              schema={ACCOUNT_SCHEMA}
              image={settings?.profile_image}
              removeImage={removeImage}
              setFile={setNewProfilePhoto}
              setSettings={setSettings}
              t={t}
            />
          )}
          {tab === 1 && (
            <ChangePassword
              confirmPassword={confirmPassword}
              password={password}
              setConfirmPassword={setConfirmPassword}
              setPassword={setPassword}
              error={passwordError}
              setError={setPasswordError}
              t={t}
            />
          )}
          {tab === 2 && (
            <PrivacySettings
              privacy={settings?.privacy}
              onChange={(e) => onChangeSettings("privacy", e)}
              deactivate={deactivateAccount}
              errors={privacyErrors}
              t={t}
            />
          )}
          {tab === 3 && (
            <IntegrationSettings
              ssoIntegrations={settings?.sso_integrations}
              handleChange={handleSSOActiveInactive}
              toggle={handleToggle}
              t={t}
            />
          )}
          {tab === 4 && (
            <Notifications
              notifications={settings?.notification}
              onChange={(e) => onChangeSettings("notification", e)}
              t={t}
            />
          )}
        </Card>
        <ImageCropper
          open={!!newProfilePhoto}
          image={newProfilePhoto}
          onClose={() => setNewProfilePhoto(null)}
          aspect={1}
          onSubmit={submitProfilePhoto}
        />
        <CreateFormFooter
          loading={loading}
          okButtonTitle={t("Save Changes")}
          onOk={handleSubmit}
          onCancel={onCancel}
          cancelButtonTitle="Cancel"
          backButtonColor="blue"
          okButtonColor="blue"
        />
      </Card>
    </DashboardLayout>
  );
};

export default SettingsPage;
