import React, { useState, useMemo, useEffect } from "react";
import "./dashboard.scss";
import logo from "../../../assets/svg/logo.svg";
import Drawer from "@mui/material/Drawer";
import Button from "components/Button";
import Input from "components/Input";
import {
  AppBar,
  Avatar,
  Badge,
  Button as Btn,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Popover,
} from "@mui/material";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import { Apps, Help, Notifications, SearchOutlined } from "@mui/icons-material";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import MenuBar from "./components/MenuBar";
import ProfileMenu from "./components/ProfileMenu";
import useAuth from "store/auth/service-hook";
import OrganizationToggle from "./components/OrganizationToggle";
import MobileMenu from "./components/MobileMenu";
import { Box } from "@mui/system";
import { leaf4, challenge, challengePath } from "assets/svg/green";
import { resource, resourceCollection, resourceGroup } from "assets/svg/purple";
import { project } from "assets/svg/yellow";
import { lab, labProgram } from "assets/svg/blue";
import { org } from "assets/svg/yellow";
import { logo as loadingLogo } from "assets/gif";
import ErrrorInfoMainPage from "pages/errorInfos/components/ErrrorInfoMainPage";
import useRedirect from "helpers/hooks/useRedirect";
import AuthLayout from "../AuthLayout";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import userImage from "assets/svg/gradient/user.svg";
import { user as whiteUserIcon } from "assets/svg/white";
import SecureImage from "components/UI/atoms/SecureImage";
import {
  filterCreateOptionsByRole,
  filterDashboardOptionsByRole,
  filterManageOptionsByRole,
  isNormalUser,
  roleBasedPermissions,
} from "helpers/utils/utilities";
import QuickSearchInput from "../../advanceSearch/components/input";
import CreationOptionsModal from "components/Modal/CreateOptionsModal";
import ChallengeTemplate from "../../challenges/components/ChallengeTemplates";
import ChallengeBuilder from "pages/resources/ResourceModule/forms/ChallengeBuilder";
import GO1ResourceBuilder from "pages/resources/ResourceModule/go1/ResourceBuilder";
import { useChallengeOptions } from "pages/challenges/listing/ManageChallenges";
import { useResourceCreateOptions } from "pages/resources/ResourceModule/listing/Manage";
import { useLabOptions } from "pages/labs/listing/ManageLabs";
import Footer from "./components/Footer";
import { org as orgIcon } from "assets/svg/blue";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import useOrganization from "store/organization/service-hook";
import SubscriptionAlert from "./components/SubscriptionAlert";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import LanguageSelector from "./components/LanguageSelector";
import { cachedLoginNavigate } from "store/api/utils";

const DashboardLayout = ({
  children,
  loading,
  isEmpty,
  emptyMessage,
  show404OnError,
  isPrivate,
  managerAccessOnly,
  noHeaderFooter,
  component,
  show403,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {
    state: { isLoggedIn, user, organization, language },
    getOrganization,
    organizationChange,
    getOrganizationList,
    languageChange,
  } = useAuth();
  const isNormalUserView = isNormalUser(user);
  const [currentOrg, setCurrentOrg] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [orgCount, setOrgCount] = useState(0);
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(null);
  const [createOptions, setCreateOptions] = useState([]);
  const [myPlanDetails, setMyPlanDetails] = useState({});
  const { planDetails } = useOrganization();

  const windowWidth = useWindowWidth();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const {
    navigateCreateChallengePath,
    navigateCreateLabProgram,
    navigateCreateOrg,
    navigateCreateResourceG,
    navigateCreateResourceC,
    navigateManageChallenges,
    navigateManageLabs,
    navigateManageOrgs,
    navigateManageResources,
    navigateChallenges,
    navigateLabs,
    navigateProjects,
  } = useRedirect();

  const mobileView = windowWidth < 700;

  useEffect(() => {
    isPrivate && !isLoggedIn && cachedLoginNavigate();
    if (!isLoggedIn) return;
    setOrg();
  }, []);

  useEffect(() => {
    fetchPlanDetails();
  }, [currentOrg]);

  const getComponentKey = (component) => {
    const componentMap = {
      [COMPONENT_TYPES.LAB_PROGRAMS]: "lab_program",
      [COMPONENT_TYPES.LAB]: "lab",
      [COMPONENT_TYPES.CHALLENGE]: "challenge",
      [COMPONENT_TYPES.CHALLENGE_PATH]: "challenge_path",
      [COMPONENT_TYPES.RESOURCE]: "resource_module",
      [COMPONENT_TYPES.RESOURCE_COLLECTION]: "resource_collection",
      [COMPONENT_TYPES.RESOURCE_GROUP]: "resource_group",
      [COMPONENT_TYPES.ORGANIZATION]: "organization",
    };
    return componentMap[component] || component;
  };

  const fetchPlanDetails = async () => {
    try {
      const organizationSlug = user?.organization_details?.slug;
      if (!organizationSlug || !currentOrg) {
        return;
      }
      const response = await planDetails(
        currentOrg ? currentOrg?.slug : organizationSlug
      );
      if (response) {
        setMyPlanDetails(response);
      } else {
      }
    } catch (error) {}
  };

  const isAllowedCreate = (component) => {
    const key = getComponentKey(component);
    const count = myPlanDetails[`${key}_count`];
    const limit = myPlanDetails[`${key}_limit`];
    return (
      (typeof limit === "string" && limit?.toLowerCase() === "unlimited") ||
      +limit > +count
    );
  };

  const setOrg = async () => {
    if (organization) {
      setCurrentOrg(organization);
      return;
    }
    const res = await getOrganization();
    setCurrentOrg(res);
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    fetchOrganizations();
  }, [page, search]);

  const fetchOrganizations = async () => {
    const [list = [], totalPages, totalCount] = await getOrganizationList(
      page,
      search
    );
    setOrganizations(list || []);
    setPages(totalPages || 1);
    setOrgCount(totalCount || 0);
  };

  const handleModalOpen = (option, options) => {
    setModal(option);
    setCreateOptions(options);
  };

  const handleModalClose = () => {
    setModal(null);
    setCreateOptions([]);
  };

  const challengeOptions = useChallengeOptions(setModal);
  const resourceOptions = useResourceCreateOptions(setModal);
  const labOptions = useLabOptions();

  const dashboard_links = useMemo(() => {
    let links = [
      { title: "Explore", path: "/explore", onlyPrivate: true, isLink: true },
      {
        title: "Dashboard",
        onlyPrivate: true,
        isLink: false,
        path: "/dashboard",
        menuItems: filterDashboardOptionsByRole(
          [
            {
              title: "User Dashboard",
              onClick: () => navigate("/dashboard"),
              component: "user",
            },
            {
              title: "Lab Dashboard",
              onClick: () => navigate("/dashboard/lab"),
              component: "lab",
            },
            {
              title: "Organization Dashboard",
              onClick: () => navigate("/dashboard/org"),
              component: "organization",
            },
          ],
          user?.roles
        ),
      },
      { title: "Careers", path: "/careers", onlyPrivate: true, isLink: true },
      {
        title: "Manage",
        onlyPrivate: true,
        isLink: false,
        menuItems: filterManageOptionsByRole(
          [
            {
              title: "Manage Challenges",
              icon: challenge,
              onClick: navigateManageChallenges,
              component: COMPONENT_TYPES.CHALLENGE,
            },
            {
              title: "Manage Labs",
              icon: lab,
              onClick: navigateManageLabs,
              component: COMPONENT_TYPES.LAB,
            },
            {
              title: "Manage Resources",
              icon: resource,
              onClick: navigateManageResources,
              component: COMPONENT_TYPES.RESOURCE,
            },
            {
              title: "Manage Organizations",
              icon: org,
              onClick: navigateManageOrgs,
              component: COMPONENT_TYPES.ORGANIZATION,
            },
          ],
          user?.roles
        ),
      },
      {
        title: "Create",
        onlyPrivate: true,
        isLink: false,
        menuItems: filterCreateOptionsByRole(
          [
            {
              title: "Challenge",
              icon: challenge,
              onClick: () => handleModalOpen("challenge", challengeOptions),
              disabled: !isAllowedCreate(COMPONENT_TYPES.CHALLENGE),
              component: COMPONENT_TYPES.CHALLENGE,
            },
            {
              title: "Challenge Path",
              icon: challengePath,
              onClick: navigateCreateChallengePath,
              disabled: !isAllowedCreate(COMPONENT_TYPES.CHALLENGE_PATH),
              component: COMPONENT_TYPES.CHALLENGE_PATH,
            },
            {
              title: "Lab",
              icon: lab,
              onClick: () => handleModalOpen("lab", labOptions),
              disabled: !isAllowedCreate(COMPONENT_TYPES.LAB),
              component: COMPONENT_TYPES.LAB,
            },
            {
              title: "Lab Program",
              icon: labProgram,
              onClick: navigateCreateLabProgram,
              disabled: !isAllowedCreate(COMPONENT_TYPES.LAB_PROGRAMS),
              component: COMPONENT_TYPES.LAB_PROGRAMS,
            },
            {
              title: "Organization",
              icon: org,
              onClick: navigateCreateOrg,
              component: COMPONENT_TYPES.ORGANIZATION,
            },
            {
              title: "Resource Module",
              icon: resource,
              onClick: () => handleModalOpen("resource", resourceOptions),
              disabled: !isAllowedCreate(COMPONENT_TYPES.RESOURCE),
              component: COMPONENT_TYPES.RESOURCE,
            },
            {
              title: "Resource Collection",
              icon: resourceCollection,
              onClick: navigateCreateResourceC,
              disabled: !isAllowedCreate(COMPONENT_TYPES.RESOURCE_COLLECTION),
              component: COMPONENT_TYPES.RESOURCE_COLLECTION,
            },
            {
              title: "Resource Group",
              icon: resourceGroup,
              onClick: navigateCreateResourceG,
              disabled: !isAllowedCreate(COMPONENT_TYPES.RESOURCE_GROUP),
              component: COMPONENT_TYPES.RESOURCE_GROUP,
            },
          ],
          user?.roles
        ),
      },
    ];
    if (isNormalUserView) {
      links[1] = {
        title: "Dashboard",
        onlyPrivate: true,
        isLink: true,
        path: "/dashboard",
      };
      links[3] = {
        title: "Learn",
        onlyPrivate: true,
        isLink: false,
        menuItems: [
          {
            title: "My Challenges",
            icon: challenge,
            onClick: navigateChallenges,
          },
          { title: "My Labs", icon: lab, onClick: navigateLabs },
          {
            title: "My Projects",
            icon: project,
            onClick: navigateProjects,
          },
        ],
      };
      links = links.slice(0, -1);
    }
    return links;
  }, [myPlanDetails, user?.roles]);

  return (
    <div className="dashboard__layout" key={i18n.language}>
      {mobileView ? (
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <DrawerContent dashboardLinks={dashboard_links} />
        </Drawer>
      ) : null}
      {!noHeaderFooter && (
        <DashHeader
          mobileView={mobileView}
          toggleDrawer={() => setDrawerOpen(!drawerOpen)}
          dashboardLinks={dashboard_links}
          isNormalUserView={isNormalUserView}
          currentOrg={currentOrg}
          setCurrentOrg={setCurrentOrg}
          organizationChange={organizationChange}
          orgDetails={{
            count: orgCount,
            page,
            setPage,
            pages,
            setPages,
            search,
            setSearch,
          }}
          organizations={organizations}
          isLoggedIn={isLoggedIn}
          plan={myPlanDetails}
          language={language}
          languageChange={languageChange}
        />
      )}
      {isPrivate && !isLoggedIn ? null : isNormalUserView &&
        managerAccessOnly ? (
        <AuthLayout sliderHidden>
          <ErrrorInfoMainPage type="403" />
        </AuthLayout>
      ) : loading ? (
        <Box className="h-screen w-screen flex items-center justify-center">
          <img src={loadingLogo} alt="loader" height={120} />
        </Box>
      ) : isEmpty ? (
        show404OnError ? (
          <AuthLayout sliderHidden>
            <ErrrorInfoMainPage type="404" />
          </AuthLayout>
        ) : show403 ? (
          <AuthLayout sliderHidden>
            <ErrrorInfoMainPage type="403" />
          </AuthLayout>
        ) : (
          <Box className="h-screen w-screen flex flex-column items-center justify-center text-center">
            <img src={leaf4} height="350px" width="auto" alt="green leaf" />
            <h1 className="mt-6 opacity-30 tracking-wide">{t(emptyMessage)}</h1>
          </Box>
        )
      ) : (
        <>
          {!noHeaderFooter && COMPONENT_TYPES.ORGANIZATION === component && (
            <SubscriptionAlert plan={myPlanDetails} />
          )}
          {children}
        </>
      )}
      {!noHeaderFooter && <Footer isLoggedIn={isLoggedIn} t={t} />}
      {modal === "templates" ? (
        <ChallengeTemplate
          open={modal === "templates"}
          onClose={handleModalClose}
        />
      ) : modal === "resources" ? (
        <ChallengeBuilder
          open={modal === "resources"}
          onClose={handleModalClose}
        />
      ) : modal === "go1" ? (
        <GO1ResourceBuilder open={modal} onClose={handleModalClose} />
      ) : (
        Boolean(modal) && (
          <CreationOptionsModal
            open={!!modal}
            title={t("How Do You Want To Create A New ${component}?", {
              component: t(modal?.charAt(0).toUpperCase() + modal?.slice(1)),
            })}
            options={createOptions}
            onClose={handleModalClose}
            plan={myPlanDetails}
          />
        )
      )}
    </div>
  );
};

export default DashboardLayout;

const DashHeader = ({
  mobileView,
  dashboardLinks,
  currentOrg,
  setCurrentOrg,
  organizations,
  orgDetails,
  organizationChange,
  isLoggedIn,
  plan,
  language = "en",
  languageChange = Function.prototype,
}) => {
  const navigate = useNavigate();
  const [openMenu, setMenuOpen] = useState(null);
  const [profileOpen, setProfileOpen] = useState(null);
  const [openOrgModal, setOpenOrgModal] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [menuAnchorEls, setMenuAnchorEls] = useState({});
  const { t } = useTranslation("common");
  const notifications = useSelector(
    (state) => state[REDUCER_TYPES.NOTIFICATION]
  );
  const user = useSelector((state) => state[REDUCER_TYPES.AUTH]);
  const handleMenuClick = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const hideLanguageSelector = useMemo(() => {
    return Boolean(
      ["/organization/create", "/organization/update", "/report/"].some((d) =>
        window.location.pathname.includes(d)
      )
    );
  }, []);

  const handleLeftNavMenuClick = (event, title) => {
    setMenuAnchorEls((prev) => ({
      ...prev,
      [title]: event.currentTarget,
    }));
  };

  const handleLinkClick = (link, event) => {
    if (link.isLink) {
      navigate(link.path, {
        state: window.location.pathname,
      });
    } else {
      handleLeftNavMenuClick(event, link.title);
    }
  };

  const handleMenuClose = (title) => {
    setMenuAnchorEls({});
  };

  const handleProfileClick = (e) => setProfileOpen(e.currentTarget);
  const handleOpenOrgToggle = () => setOpenOrgModal(true);
  const handleOrgSave = (data) => async () => {
    setCurrentOrg(data);
    setOpenOrgModal(null);
    await organizationChange(data);
  };
  const handleMenuItemClick = (onClick) => {
    onClick();
    handleMenuClose();
  };

  const handleMobileMenuClick = () => setMobileMenuOpen((val) => !val);

  if (mobileView) {
    return (
      <AppBar
        className="dashboard__header"
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <div className="dashboard__headerLeft">
          <img
            className="logo mr-4 cursor-pointer"
            onClick={() => navigate("/explore")}
            src={logo}
            alt="logo"
            height={35}
          />
        </div>
        <div className="dashboard__headerRight">
          <Popover
            open={!!openMenu}
            anchorEl={openMenu}
            onClose={() => setMenuOpen(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuBar mobile />
          </Popover>
          {!isLoggedIn ? (
            <Button
              classes="fs-16 mr-2 rounded-10"
              onClick={cachedLoginNavigate}
            >
              <img src={whiteUserIcon} className="w-22 h-22 mr-2" alt="user" />{" "}
              {t("Sign In")}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <MobileMenu
          open={mobileMenuOpen}
          onClose={handleMobileMenuClick}
          plan={plan}
          t={t}
        />
        {isLoggedIn ? (
          <MenuIcon
            className="c-gray mt-2 mr-5"
            onClick={handleMobileMenuClick}
          />
        ) : (
          <></>
        )}
      </AppBar>
    );
  }

  return (
    <AppBar className="dashboard__header" sx={{ flexDirection: "row" }}>
      <div className="dashboard__headerLeft">
        {isLoggedIn && !!currentOrg?.slug ? (
          <>
            <SecureImage
              containerClasses="flex items-center justify-center mr-1"
              className="w-30 h-30 rounded-100"
              src={currentOrg?.profile_image}
              placeholder={orgIcon}
              alt="logo"
              height={35}
            />
            <h3 className="uppercase opacity-70 tracking-tight c-black mr-1 w-fit maxw-150 text-truncate">
              {currentOrg?.title}
            </h3>
            {organizations?.length > 1 && (
              <ArrowDropDownIcon
                className="cursor-pointer c-primary"
                onClick={handleOpenOrgToggle}
              />
            )}
          </>
        ) : (
          <Logo onClick={() => navigate("/explore")} />
        )}
        <OrganizationToggle
          openModal={openOrgModal}
          onModalClose={() => setOpenOrgModal(null)}
          onSave={handleOrgSave}
          organizations={organizations}
          current={currentOrg}
          hasCreateOrgPermissions={
            roleBasedPermissions(currentOrg?.roles)?.create?.includes(
              COMPONENT_TYPES.ORGANIZATION
            ) || roleBasedPermissions(currentOrg?.roles)?.create === "all"
          }
          {...orgDetails}
          t={t}
        />

        <Box className="flex">
          {dashboardLinks?.map(
            ({ title, path, onlyPrivate, isLink, menuItems }, i) =>
              !isLoggedIn && onlyPrivate ? null : (
                <div key={i}>
                  {!isLink && title === "Create" ? (
                    <Button
                      onClick={(e) =>
                        handleLinkClick({ title, path, isLink, menuItems }, e)
                      }
                      color="primary"
                      variant="contained"
                      classes="capitalize w-80 ml-2 tracking-tight"
                    >
                      {" "}
                      {t("Create")}
                    </Button>
                  ) : (
                    <Btn
                      className={`${
                        window.location.pathname.includes(path)
                          ? "c-primary"
                          : "c-gray"
                      } mx-2 tracking-tight bg-white text-nowrap`}
                      color="inherit"
                      variant="text"
                      onClick={(e) =>
                        handleLinkClick({ title, path, isLink, menuItems }, e)
                      }
                    >
                      {t(title)}
                    </Btn>
                  )}
                  {!isLink && (
                    <Menu
                      anchorEl={menuAnchorEls[title]}
                      open={Boolean(menuAnchorEls[title])}
                      onClose={() => handleMenuClose(title)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      className="mt-3"
                    >
                      {menuItems.map(
                        ({ title: menuTitle, icon, onClick, disabled }) => (
                          <MenuItem
                            key={menuTitle}
                            onClick={() => handleMenuItemClick(onClick)}
                            className="flex-center mt-1"
                            disabled={disabled}
                          >
                            {icon && (
                              <img
                                src={icon}
                                alt={`icon of ${menuTitle}`}
                                className="w-20 h-20 mr-1"
                              />
                            )}
                            <p className="fs-14">
                              {title === "Create"
                                ? t("Create a ${component}", {
                                    component: t(menuTitle),
                                  })
                                : t(menuTitle)}
                            </p>
                          </MenuItem>
                        )
                      )}
                    </Menu>
                  )}
                </div>
              )
          )}
        </Box>
      </div>
      <div className="dashboard__headerRight">
        {isLoggedIn ? (
          <>
            <QuickSearchInput t={t} />
            <Box className="flex">
              <IconButton
                color="inherit"
                className="mx-1"
                onClick={() => navigate("/notifications")}
              >
                <Badge
                  badgeContent={notifications?.count}
                  color="error"
                  className="h-20"
                >
                  <Notifications
                    sx={{ fontSize: 22 }}
                    className={
                      window.location.pathname === "/notifications"
                        ? "c-primary"
                        : "c-nav-icons"
                    }
                  />
                </Badge>
              </IconButton>
              <IconButton
                color="inherit"
                onClick={() => navigate("/chats")}
                className="ml-1"
              >
                <ChatBubbleIcon
                  sx={{ fontSize: 22 }}
                  className={
                    window.location.pathname === "/chats"
                      ? "c-primary"
                      : "c-nav-icons"
                  }
                />
              </IconButton>
              <IconButton
                color="inherit"
                className="ml-1"
                onClick={() =>
                  window.open("https://intercom.help/preprlabs/" + user?.user?.preferred_language?.substring(0, 2) || "en")
                    //Using substring() above as Intercom only accepts two-letter ISO 639 language codes
                }
              >
                <Help className="c-nav-icons" />
              </IconButton>
            </Box>
            <Avatar
              alt="Avatar"
              className="mx-3 h-30 w-30"
              onClick={handleProfileClick}
              src={user?.user?.profile_image ?? userImage}
            >
              <img
                src={userImage}
                alt="profile"
                className="h-30 w-30 bg-white"
              />
            </Avatar>
            <Popover
              open={!!profileOpen}
              anchorEl={profileOpen}
              onClose={() => setProfileOpen(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              slotProps={{ paper: { sx: { borderRadius: "10px", mt: 2 } } }}
            >
              <ProfileMenu t={t} />
            </Popover>
            <Apps
              className="c-nav-icons cursor-pointer mr-1"
              onClick={handleMenuClick}
              fontSize="large"
            />
            <Popover
              open={!!openMenu}
              anchorEl={openMenu}
              onClose={() => setMenuOpen(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              slotProps={{ paper: { sx: { borderRadius: "6px", mt: 2 } } }}
            >
              <MenuBar onClose={() => setMenuOpen(null)} />
            </Popover>
            {!hideLanguageSelector && (
              <LanguageSelector
                handleChange={languageChange}
                language={language}
              />
            )}
          </>
        ) : (
          <Button classes="fs-16 mr-2 rounded-10" onClick={cachedLoginNavigate}>
            <img src={whiteUserIcon} className="w-22 h-22 mr-2" alt="user" />{" "}
            {t("Sign In")}
          </Button>
        )}
      </div>
    </AppBar>
  );
};

const DrawerContent = ({ dashboardLinks }) => {
  const navigate = useNavigate();

  return (
    <Container className="px-4 pt-5">
      <Avatar
        alt="Remy Sharp"
        src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
      />
      <Input
        placeholder="Search..."
        height="35px"
        width="200px"
        classes="mt-2"
        icon={<SearchOutlined />}
      />
      {dashboardLinks.map(({ title, path }, i) => (
        <p key={i} className="mt-2" onClick={() => navigate(path)}>
          {title}
        </p>
      ))}
      <Button
        onClick={() => navigate(`/${COMPONENT_TYPES.PROJECT}/create`)}
        color="blue"
        classes="mt-2"
      >
        Create Project
      </Button>
    </Container>
  );
};

const Logo = ({ onClick }) => (
  <img
    className="logo ml-2 mr-4 cursor-pointer"
    onClick={onClick}
    src={logo}
    alt="logo"
    height={35}
  />
);
