import React, {useContext, useEffect, useState} from "react";
import {UnifiedImportMemberContext} from "../container/UnifiedImportMemberContext";
import TableComponent from "../../../components/Table";
import {getImportContactsColumns} from "../data";
import useMembers from "../../../store/member-management/service-hook";
import {Checkbox, FormControlLabel, Grid, IconButton, InputBase, Paper} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './index.scss';
import Select from "../../../components/Input/Select";
import Button from "../../../components/Button";
import {toast} from "react-toastify";
import useRedirect from "helpers/hooks/useRedirect";
import { useParams } from "react-router-dom";

const ImportContacts = ({setStep, show}) => {

    /**
     * HOOKS
     */
    const {
        total,
        employees,
        loadingEmployees,
        selectedEmployees,
        selectEmployee,
        removeSelectedEmployee,
        updateEmployee,
        updateSelectedEmployee,
        onSearch,
        isOrganizationInvite,
        isLastPage,
        page,
        perPage,
        handleNextPage,
        handlePreviousPage,
        selectAllFormCurrentPage,
        selectAll,
        enableGlobalRole,
        setEnableGlobalRole,
        globalRole,
        setGlobalRole
    } = useContext(UnifiedImportMemberContext)
    const {getRoles} = useMembers();
    const {navigateBack}=useRedirect();
    const {id, type} = useParams()


    /**
     * STATE
     */
    const [roles, setRoles] = useState([])
    const [errors, setError] = useState({})

    /**
     * METHODS
     */
    const handleGetRoles = async () => {
        const _roles = await getRoles();
        setRoles(_roles);
    };

    /**
     * VALIDATE RESPONSE
     * @returns {boolean}
     * @constructor
     */
    const Validate = () => {
        if (selectedEmployees?.length === 0) {
            toast.error("Select at-least one contact.");
            return false;
        } else if (isOrganizationInvite) {
            const errors = {};
            if (!enableGlobalRole) {
                selectedEmployees.forEach((item) => {
                    if (!item?.role) {
                        errors[`roles-members-${item?.id}`] = 'This field is required.'
                    }
                });
            } else {
                if (!globalRole) {
                    errors.globalRole = "This field is required."
                }
            }
            const valid = Object.entries(errors)?.length === 0

            if (!valid) {
                toast.error("Fill up all the required fields.");
            }

            setError(errors)

            return valid;
        }

        return true;
    }

    const removeError = (key) => {
        if (errors[key]) {
            const temp = {...errors}
            delete temp[key]
            setError(temp)
        }
    }


    /**
     * ON MOUNT
     */
    useEffect(() => {
        handleGetRoles()
    }, [])


    const removeAllSelected = () => {
        selectedEmployees.forEach((item) => {
            removeSelectedEmployee(item)
        })
    }

    return (<>
        {
            show ?
                <Grid container className={'mt-8'} justifyContent={'center'}>
                    <Grid item xs={12} md={11}>
                        <Paper
                            className={'unified-user-search'}
                        >
                            <IconButton sx={{p: '10px'}} aria-label="search icon">
                                <SearchIcon/>
                            </IconButton>
                            <InputBase
                                onChange={event => {
                                    onSearch(event?.target?.value)
                                }}
                                sx={{ml: 1, flex: 1}}
                                placeholder="Search for users"
                                inputProps={{'aria-label': 'Search for users'}}
                            />
                        </Paper>

                        <div className="unified-user-list-pagination">
                            <div className="flex">
                                <div 
                                    className={'cursor-pointer underline select-all'} 
                                    onClick={() => selectedEmployees.length > 0 ? removeAllSelected() : selectAllFormCurrentPage()}>
                                   {selectedEmployees.length > 0 ? "Deselect All" : "Select All"}
                                </div>
                                <div className={"ml-auto"}>
                                    <div className="flex gap-4">
                                        <ArrowBackIosIcon
                                            onClick={() => {
                                                handlePreviousPage()
                                            }}

                                            className={`${page === 1 ? 'icon-disabled' : 'cursor-pointer icon'} `}/>
                                        <ArrowForwardIosIcon
                                            onClick={() => {
                                                handleNextPage()
                                            }}
                                            className={`${isLastPage ? 'icon-disabled' : 'cursor-pointer icon'} `}/>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {
                            !loadingEmployees ?
                                <>
                                    <div className="unified-user-list-count-details">
                                        <div className="flex">
                                            <div>
                                                <p className={'fs-14'}>{total} contacts</p>
                                            </div>
                                            <div className="ml-auto">
                                                <p className="fs-14">{selectedEmployees?.length} selected</p>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        perPage === selectedEmployees?.length ?
                                            <div className="select-all-container">
                                                <p>All {perPage} contacts on this page are selected.
                                                    <span className={'underline cursor-pointer'} onClick={() => {
                                                        selectAll()
                                                    }}> Select all {total} contacts. </span>
                                                </p>
                                            </div> :
                                            ""
                                    }
                                </> : ''
                        }
                        <Grid container justifyContent={'center'}>
                            <Grid xs={12} item>
                                <TableComponent
                                    loading={loadingEmployees}
                                    rows={employees || []}
                                    columns={getImportContactsColumns(
                                        selectedEmployees,
                                        removeSelectedEmployee,
                                        selectEmployee,
                                        isOrganizationInvite,
                                        roles || [],
                                        (updated) => {
                                            updateEmployee(updated, 'email')
                                            updateSelectedEmployee(updated, 'email')
                                        },
                                        enableGlobalRole, globalRole, errors, removeError) || []}
                                    paginator={false}
                                    titleCellClasses="c-gray"
                                />
                            </Grid>
                        </Grid>

                        {
                            !loadingEmployees && total && isOrganizationInvite ?
                                <div className="mt-8">
                                    <div className="flex">
                                        <div>
                                            <FormControlLabel control={<Checkbox
                                                onChange={(event) => {
                                                    setEnableGlobalRole(event.target.checked)
                                                    setGlobalRole('')
                                                }}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#27AE60',
                                                    },
                                                }} checked={enableGlobalRole}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />} label={(
                                                <p className="text-nowrap fs-14">{"Invite all selected users as "}</p>)}
                                            />
                                        </div>
                                        <div className={"flex"} style={{flexDirection: 'column', gap: "4px"}}>
                                            <Select
                                                borderedIcon
                                                value={globalRole}
                                                width={160}
                                                disabled={!enableGlobalRole}
                                                height={40}
                                                values={roles || []}
                                                placeholder={'Select Role'}
                                                name="role"
                                                classes="fs-14"
                                                onChange={(e) => {
                                                    setError({})
                                                    setGlobalRole(e?.target?.value)
                                                }}
                                            />
                                            <p className={'text-danger'}>{errors[`globalRole`] || ''}</p>
                                        </div>
                                    </div>
                                </div> : ""
                        }

                        <div className="mt-8">
                            <div className="flex">
                                <div>
                                    <Button bordered background={'white'} color={'green'} onClick={() => {
                                        navigateBack()
                                    }}>
                                        Cancel
                                    </Button>
                                </div>
                                <div className="ml-auto">
                                    <Button color={'green'}
                                            disabled={selectedEmployees?.length === 0}
                                            onClick={() => {
                                                const valid = Validate()
                                                if (valid) {
                                                    setStep(1)
                                                }
                                            }}>
                                        Next
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                : ""
        }
    </>)
}

export default ImportContacts
