import { useState, useEffect } from "react";
import "components/AI/ai.scss";
import DashboardLayout from "pages/layouts/dashboard";
import EditCreateHeaderCard from "components/Cards/EditCreateHeaderCard";
import MainPageLayout from "pages/layouts/MainPage";
import Card from "components/Cards/Card";
import { toast } from 'react-toastify';
import { aiBackground1 } from "assets/svg/ai";
import { aiLoading } from "assets/gif/ai";
import { lab as labImage } from "assets/svg/blue";
import { challenge as challengeImage } from "assets/svg/green";
import { calendar, chart } from "assets/svg/blue";
import useLabs from "store/labs/service-hook";
import useChallenges from "store/challenges/service-hook";
import useResources from "store/resources/service-hook";
import { scrollToTop } from "helpers/utils/utilities";
import useForms from "helpers/hooks/useForms";
import { schema, stepSchema } from "./data";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Button from "components/Button";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useOrganization from "store/organization/service-hook";
import useRedirect from "helpers/hooks/useRedirect";
import { Chip } from "@mui/material";
import AIForm from "components/AI/AIForm";
import AIText from "components/AI/AIText";
import ResourceModulePreview from "components/AI/ResourceModulePreview";
import { isTrue } from "helpers/utils/validators";

const LabBuilderAI = () => {

    const sliceSchema = (_schema) => stepSchema(step);

    const { data, errors, validate, onChange } = useForms(
        schema,
        {},
        sliceSchema
    );

    const [step, setStep] = useState(0);

    const { aiCreateLab, aiCreateLabsPreview } = useLabs();
    const { aiCreateChallenge } = useChallenges();
    const { aiCreateResourceModule, aiCreateResourceModulePreviews, createResourceModuleGo1 } = useResources();
    const { organizationId } = useOrganization();
    const { navigateLab } = useRedirect();

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [confirmationModalDescription, setConfirmationModalDescription] = useState("Are you sure?");
    const [loading, setLoading] = useState(false);

    const [labs, setLabs] = useState([]);
    const [labsState, setLabsState] = useState([]);
    const [labExpanded, setLabExpanded] = useState('labPanel0');
    const [labSelected, setLabSelected] = useState([]);
    const [labChosen, setLabChosen] = useState(null);

    const [challengeExpanded, setChallengeExpanded] = useState('');

    const [resources, setResources] = useState([]);
    const [resourcesState, setResourcesState] = useState([]);
    const [resourceExpanded, setResourceExpanded] = useState('resourcePanel0');
    const [resourcesSelected, setResourcesSelected] = useState([]);
    const [resourcesChosen, setResourcesChosen] = useState(null);

    // Initial Page Steps  
    const [createLabAISteps, setCreateLabAISteps] = useState([
        "Lab & Resource Module Creation",
        "Choose your Lab",
    ]);

    // Validating the forms
    const stepValidate = (errors) => {
        let _errors = errors;

        if (step === 0 && data?.resource_modules) {
            let current_schema = stepSchema(step);
            let sliced_schema = [];
            let itemsToRemove = null;
            let itemsToAdd = null;

            if (data?.resource_modules === "no") {
                sliced_schema = [...sliced_schema, ...current_schema.slice(5, 11)];
            } else if (isTrue(data?.resource_modules)) {
                sliced_schema = [...sliced_schema, ...current_schema.slice(7, 8)];
                sliced_schema = [...sliced_schema, ...current_schema.slice(9, 10)];
            }

            if (data?.resource_module_prepr || data?.resource_module_openai || data?.resource_module_go1) {
                itemsToAdd = [5, 6, 8];

                itemsToAdd.forEach(index => {
                    const itemToAdd = current_schema[index];
                    if (!sliced_schema.includes(itemToAdd)) {
                        sliced_schema.push(itemToAdd);
                    }
                });
            }

            if (data?.resource_module_openai) {
                itemsToRemove = current_schema[7];
                sliced_schema = sliced_schema.filter(item => item !== itemsToRemove);
            }

            if (data?.resource_module_go1) {
                itemsToRemove = current_schema[9];
                sliced_schema = sliced_schema.filter(item => item !== itemsToRemove);
            }

            sliced_schema.length > 0 && sliced_schema.forEach(({ name }) => {
                _errors.delete(name);
            });
        }

        return _errors;
    };

    // Handle continuing in steps
    const onContinue = async () => {
        switch (step) {
            case 0:
                {
                    let error = stepValidate(await validate());
                    if (error.size) return;
                    createLabsPreviews();
                }
                break;
            case 1:
                if (labChosen === labsState[labSelected]) {
                    createResourceModulesPreviews();
                } else {
                    setLabChosen(labsState[labSelected]);
                }
                break;
            case 2:
                if (resourcesSelected?.length > 0) {
                    setResourcesChosen(resourcesSelected.map(index => resources[index]));
                } else {
                    setConfirmationModalDescription("You haven't chosen any Resource Module! Are you sure?");
                    setOpenConfirmationModal(true);
                }
                break;
            default:
                // eslint-disable-next-line no-console
                console.error('Error in Steps!');
                break;
        }
    };

    // Handle backing in steps
    const onCancel = () => {
        if (step === 1) {
            setConfirmationModalDescription("You will lose all the current Generated Labs! Are you sure?");
            setOpenConfirmationModal(true);
        }
        if (step === 2) {
            setConfirmationModalDescription("You will lose all the current Generated Resource Modules! Are you sure?");
            setOpenConfirmationModal(true);
        }
    };

    // Handle confirming confirmation modal
    const confirmConfirmationModal = () => {
        if (step === 1) {
            if (confirmationModalDescription === "You will lose all the current Generated Labs! Are you sure?") {
                setLabs([]);
                setLabSelected([]);
                setLabChosen(null);
                setLabsState([]);
                setLabExpanded('labPanel0');
                setChallengeExpanded('');
                setStep(step - 1);
            }
        } else if (step === 2) {
            if (confirmationModalDescription === "You will lose all the current Generated Resource Modules! Are you sure?") {
                setResources([]);
                setResourcesState([]);
                setResourcesSelected([]);
                setResourceExpanded('resourcePanel0');
                setResourcesChosen(null);
                setStep(step - 1);
            }
        }

        setOpenConfirmationModal(false);
    };

    // Generates labs' previews
    const createLabsPreviews = async () => {
        setLoading(true);
        let resource_modules = false;

        try {
            if (data?.resource_modules === "yes") {
                resource_modules = true
            }

            const payload = {
                ...data,
                resource_modules: resource_modules,
                is_ai_created: true,
                organization_id: organizationId,
            };

            const res = await aiCreateLabsPreview(payload);
            if (res?.data) {
                setLabs(Object.values(res?.data?.data));
                setStep(step + 1);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Error in lab generating API call: ", e);

        } finally {
            scrollToTop();
            setLoading(false);
        }
    };

    // Generates the Resource Module Previews
    const createResourceModulesPreviews = async () => {
        if (labChosen) {
            if (data?.resource_module_prepr || data?.resource_module_openai || data?.resource_module_go1) {
                setLoading(true);

                const { labTitle, labDescription, ...restOfLab } = labChosen;

                try {
                    const payload = {
                        ...restOfLab,
                        labTitle: labTitle,
                        labDesc: labDescription,
                        organization_id: organizationId,
                    };

                    const res = await aiCreateResourceModulePreviews(payload);
                    if (res?.data?.data) {
                        setResources(Object.values(res?.data?.data));
                        setStep(step + 1);
                    } else {
                        setResources([]);
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error("Error in resource module generating API call: ", e);
                } finally {
                    scrollToTop();
                    setLoading(false);
                }
            } else {
                handleFinalCreate();
            }
        }
    };

    useEffect(() => {
        if (labChosen && step === 1) {
            createResourceModulesPreviews();
        }
    }, [labChosen]);

    useEffect(() => {
        setLabsState(labs.map(lab => ({
            ...lab,
        })));

    }, [labs]);

    useEffect(() => {
        if (resourcesChosen && step === 2) {
            handleFinalCreate();
        }
    }, [resourcesChosen]);

    useEffect(() => {
        if (resources?.length > 0) {
            if (resources?.resource_modules?.length > 0 && step === 1) {
                setStep(step + 1);
                scrollToTop();
                setLoading(false);
            }

            setResourcesState(resources.map(resource => ({
                ...resource
            })));
        }
    }, [resources]);

    const handleLabPanelChange = (panel) => (event, isExpanded) => {
        setLabExpanded(isExpanded ? panel : false);
    };

    const handleChallengePanelChange = (panel) => (event, isExpanded) => {
        setChallengeExpanded(isExpanded ? panel : false);
    };

    const handleResourcePanelChange = (panel) => (event, isExpanded) => {
        setResourceExpanded(isExpanded ? panel : false);
    };

    const handleFinalCreate = async () => {
        setLoading(true);
        let createdLabResponse = null;
        let createdChallenges = [];
        let createdLab = [];
        let challengeUUIDs = [];
        let resourceModuleUUIDs = [];

        try {
            if (labChosen) {
                try {
                    await Promise.all(
                        labChosen?.challenges?.map(async (challenge) => {
                            if (challenge?.added) {
                                try {
                                    const challengePayload = {
                                        ...challenge,
                                        organization_id: organizationId,
                                        is_ai_created: true,
                                        resource_modules: [],
                                    };

                                    let createdChallengeResponse = await aiCreateChallenge(challengePayload);
                                    createdChallengeResponse = createdChallengeResponse?.data?.data;

                                    createdChallenges.push(createdChallengeResponse);
                                } catch (error) {
                                    // eslint-disable-next-line no-console
                                    console.error("Error creating challenge:", error);
                                }
                            }
                        })
                    );


                    challengeUUIDs = createdChallenges.map(challenge => challenge?.id);
                    const countAddedChallenges = labChosen.challenges.reduce((count, challenge) => {
                        return challenge.added ? count + 1 : count;
                    }, 0);

                    if (resourcesChosen) {
                        await Promise.all(
                            resourcesChosen.map(async (resourceChosen) => {
                                try {
                                    if (resourceChosen?.from_prepr) {
                                        resourceModuleUUIDs.push(resourceChosen.uuid);
                                    } else if (resourceChosen?.from_go1) {
                                        const createdResourceModuleResponse = await createResourceModuleGo1({ go1_course: resourceChosen });
                                        const createdResourceModuleResponseId = createdResourceModuleResponse.id;
                                        resourceModuleUUIDs.push(createdResourceModuleResponseId);
                                    } else {
                                        const resourcesPayload = {
                                            ...resourceChosen,
                                            organization_id: organizationId,
                                        };

                                        const createdResourceModuleResponse = await aiCreateResourceModule(resourcesPayload);
                                        const createdResourceModuleResponseId = createdResourceModuleResponse.id;
                                        resourceModuleUUIDs.push(createdResourceModuleResponseId);
                                    }
                                } catch (error) {
                                    // eslint-disable-next-line no-console
                                    console.error("Failed to create resource module: ", error);
                                }
                            })
                        );
                    }

                    try {
                        const labPayload = {
                            ...labChosen,
                            organization_id: organizationId,
                            is_ai_created: true,
                            challenges: challengeUUIDs,
                            resource_modules: resourceModuleUUIDs,
                        };

                        createdLabResponse = await aiCreateLab(labPayload);
                        createdLab = createdLabResponse?.data?.data;
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error("Error creating lab:", error);
                    }

                    if (createdLab &&
                        (countAddedChallenges === challengeUUIDs.length) &&
                        (!resourcesChosen || resourceModuleUUIDs.length === resourcesChosen.length)) {
                        navigateLab(createdLab.slug, true);
                        toast.success("Success");
                    } else {
                        toast.error("Something went wrong!");
                    }
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error("Failed to create lab: ", error);
                }
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error("Unexpected error in handleFinalCreate:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!labChosen) {
            setNumLabsToShow(4);
            setLoadMoreLabsClickCount(0);
        }
        setNumResourcesToShow(4);
        setLoadMoreResourcesClickCount(0);
    }, [step]);

    const [numLabsToShow, setNumLabsToShow] = useState(4);
    const [loadMoreLabsClickCount, setLoadMoreLabsClickCount] = useState(0);
    const [numResourcesToShow, setNumResourcesToShow] = useState(4);
    const [loadMoreResourcesClickCount, setLoadMoreResourcesClickCount] = useState(0);

    const handleLoadMoreLabs = () => {
        setNumLabsToShow(numLabsToShow + 2);
        setLoadMoreLabsClickCount(loadMoreLabsClickCount + 1);
    };

    const toggleChallengeAdded = (labIndex, challengeIndex) => {
        const updatedLabsState = [...labsState];
        const updatedLab = { ...updatedLabsState[labIndex] };
        const updatedChallenges = [...updatedLab.challenges];
        const selectedChallengesCount = updatedChallenges.filter(challenge => challenge?.added).length;

        if (!updatedChallenges[challengeIndex].added || selectedChallengesCount > 3) {
            updatedChallenges[challengeIndex] = {
                ...updatedChallenges[challengeIndex],
                added: !updatedChallenges[challengeIndex]?.added
            };

            updatedLab.challenges = updatedChallenges;
            updatedLabsState[labIndex] = updatedLab;
            setLabsState(updatedLabsState);
        } else {
            toast.warn('At least 3 challenges must remain selected.');
        }
    };

    return (
        <div className="ai-creation-form ai-scoped">
            <ConfirmationModal
                open={openConfirmationModal}
                onClose={() => { setOpenConfirmationModal(false) }}
                desc={confirmationModalDescription}
                okayButtonTitle="Confirm"
                onOkay={confirmConfirmationModal}
            />
            <DashboardLayout>
                <EditCreateHeaderCard
                    width="min(1600px,90%)"
                    title="Prepr Lab Builder AI"
                    classes="mt-header flex-row"
                />
                <MainPageLayout classes="mb-6 mt-2 container-medium" firstMdGridSize={12} secondMdGridSize={12} firstLgGridSize={4} secondLgGridSize={8}>
                    <AIForm
                        step={step}
                        loading={loading}
                        data={data}
                        errors={errors}
                        onChange={onChange}
                        stepSchema={stepSchema}
                        onCancel={onCancel}
                        onContinue={onContinue}
                        stepValidate={stepValidate}
                        optionSelected={labSelected}
                        createAISteps={createLabAISteps}
                        setCreateAISteps={setCreateLabAISteps}
                    />
                    <div className={`right`}>
                        <Card centered classes={`column-center`} width="min(1600px,100%)">
                            <EditCreateHeaderCard
                                current={step}
                                length={createLabAISteps.length}
                                subtitles={createLabAISteps}
                                rounded
                                noShadow
                                noPadding
                            />
                            {step === 0 && (
                                <div className={`intro-box ${loading ? 'flashing' : ''}`}>
                                    <p className="intro-text">{loading ? "Generating your Labs... (it may take up to 2 minutes)" : "Our Lab Builder AI can help you generate Labs & Resource Modules!"}</p>
                                    <img width="100%" src={aiBackground1} alt="" />
                                </div>
                            )}
                            {step === 1 && (
                                loading ?
                                    createLabAISteps.length > 2 ?
                                        <div className='intro-box'>
                                            <p className="intro-text">Generating your Resource Modules... (it may take up to 2 minutes)</p>
                                            <img width="100%" src={aiLoading} alt="" />
                                        </div> :
                                        <div className='intro-box'>
                                            <p className="intro-text">Working on it...</p>
                                            <img width="100%" src={aiLoading} alt="" />
                                        </div> :
                                    <div className="w-full">
                                        {labsState?.slice(0, numLabsToShow).map((lab, labIndex) => (
                                            <Accordion
                                                key={labIndex}
                                                expanded={labExpanded === `labPanel${labIndex}`}
                                                onChange={handleLabPanelChange(`labPanel${labIndex}`)}
                                                className="lab-panel relative"
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`labPanel${labIndex}bh-content`}
                                                    id={`labPanel${labIndex}bh-header`}
                                                >
                                                    <Typography className="panel-main">
                                                        <div className="image-and-title">
                                                            <img className="panel-icon" src={labImage} alt="" />
                                                            <b className="mx-2">{lab?.labTitle}</b>
                                                        </div>
                                                    </Typography>
                                                </AccordionSummary>
                                                <Button
                                                    onClick={() => setLabSelected(labIndex)}
                                                    color={labSelected === labIndex ? 'green' : 'blue'}
                                                    bordered
                                                    classes="panel-button sm-panel-button"
                                                    height={35}
                                                >
                                                    {labSelected === labIndex ? (<><CheckCircleIcon className="icon-margin" /> Selected</>) : 'Select'}
                                                </Button>
                                                <AccordionDetails>
                                                    <div className="image-and-title">
                                                        <img className="desc-icon" src={chart} alt="" />
                                                        <Typography dangerouslySetInnerHTML={{ __html: lab?.level }} />
                                                        <img className="desc-icon ml-4" src={calendar} alt="" />
                                                        <Typography dangerouslySetInnerHTML={{ __html: lab?.duration }} />
                                                    </div>
                                                    <br />
                                                    <AIText className="mb-1">Description</AIText>
                                                    <Typography dangerouslySetInnerHTML={{ __html: lab?.labDescription }} />
                                                    <br />
                                                    <AIText className="mb-1">Skills</AIText>
                                                    {lab?.skill_titles?.map((skillTitle) => (
                                                        <Chip
                                                            key={skillTitle}
                                                            label={skillTitle}
                                                            className="py-2 mr-1 mb-1 fs-12"
                                                            sx={{ height: "26px" }}
                                                        />
                                                    ))}
                                                    <br />
                                                    <br />
                                                    <AIText className="mb-1">Jobs</AIText>
                                                    {lab?.job_titles?.map((jobTitle) => (
                                                        <Chip
                                                            key={jobTitle}
                                                            label={jobTitle}
                                                            className="py-2 mr-1 mb-1 fs-12"
                                                            sx={{ height: "26px" }}
                                                        />
                                                    ))}
                                                    <br />
                                                    <br />
                                                    {lab?.challenges?.map((challenge, challengeIndex) => {
                                                        let displayIndex = 1 + lab?.challenges?.slice(0, challengeIndex).filter(challenge => challenge?.added).length;

                                                        return (
                                                            <Accordion
                                                                key={challengeIndex}
                                                                expanded={challengeExpanded === `challengePanel${challengeIndex}`}
                                                                onChange={handleChallengePanelChange(`challengePanel${challengeIndex}`)}
                                                                className="challenge-panel relative"
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls={`challengePanel${challengeIndex}bh-content`}
                                                                    id={`challengePanel${challengeIndex}bh-header`}
                                                                >
                                                                    <Typography className="panel-main">
                                                                        <div className="image-and-title">
                                                                            <img className="panel-icon" src={challengeImage} alt="" />

                                                                            <b className={`mx-2 ${challenge.added ? '' : 'strike-through'}`}>
                                                                                {challenge.added ? `${displayIndex}. ` : '- '}{challenge?.challengeTitle}
                                                                            </b>
                                                                        </div>
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <Button
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        toggleChallengeAdded(labIndex, challengeIndex);
                                                                    }}
                                                                    color={challenge.added === true ? 'green' : 'blue'}
                                                                    bordered
                                                                    classes="panel-button sm-panel-button"
                                                                    height={35}
                                                                >
                                                                    {challenge.added ? (<><CheckCircleIcon className="icon-margin" /> Added</>) : 'Add'}
                                                                </Button>
                                                                <AccordionDetails>
                                                                    <AIText className="mb-1">Description</AIText>
                                                                    <Typography dangerouslySetInnerHTML={{ __html: challenge?.challengeDescription }} />
                                                                </AccordionDetails>
                                                                <Button
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        toggleChallengeAdded(labIndex, challengeIndex);
                                                                    }}
                                                                    color={challenge.added === true ? 'green' : 'blue'}
                                                                    bordered
                                                                    classes="panel-button md-panel-button"
                                                                    height={35}
                                                                >
                                                                    {challenge.added ? (<><CheckCircleIcon className="icon-margin" /> Added</>) : 'Add'}
                                                                </Button>
                                                            </Accordion>
                                                        )
                                                    })}
                                                </AccordionDetails>
                                                <Button
                                                    onClick={() => setLabSelected(labIndex)}
                                                    color={labSelected === labIndex ? 'green' : 'blue'}
                                                    bordered
                                                    classes="panel-button md-panel-button"
                                                    height={35}
                                                >
                                                    {labSelected === labIndex ? (<><CheckCircleIcon className="icon-margin" /> Selected</>) : 'Select'}
                                                </Button>
                                            </Accordion>
                                        ))}
                                        <br />
                                        {labsState.length > numLabsToShow && loadMoreLabsClickCount < 3 && (
                                            <AIText className="text-right">Don't think these are good fit? <span className="no-good-fit" onClick={handleLoadMoreLabs}>Load More</span></AIText>
                                        )}
                                    </div>
                            )}
                            {step === 2 && (
                                loading ?
                                    <div className='intro-box'>
                                        <p className="intro-text">Working on it...</p>
                                        <img width="100%" src={aiLoading} alt="" />
                                    </div> :
                                    <ResourceModulePreview
                                        resourcesState={resourcesState}
                                        resourceExpanded={resourceExpanded}
                                        handleResourcePanelChange={handleResourcePanelChange}
                                        resourcesSelected={resourcesSelected}
                                        setResourcesSelected={setResourcesSelected}
                                        numResourcesToShow={numResourcesToShow}
                                        setNumResourcesToShow={setNumResourcesToShow}
                                        loadMoreResourcesClickCount={loadMoreResourcesClickCount}
                                        setLoadMoreResourcesClickCount={setLoadMoreResourcesClickCount}
                                        maxSelect={6}
                                    />
                            )}
                        </Card>
                    </div>
                </MainPageLayout>
            </DashboardLayout >
        </div>
    )
}

export default LabBuilderAI;