import { AddOutlined, Delete, Info, Search } from "@mui/icons-material";
import { Grid, Stack } from "@mui/material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import { getDefaultImageByType } from "components/Cards/MainProductCard";
import Input from "components/Input";
import Select from "components/Input/Select";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import SecureImage from "components/UI/atoms/SecureImage";
import PageTitleCard from "components/UI/molecules/PageTitleCard";
import ListingContainer from "components/UI/organisms/ListingContainer";
import useListingHeader from "helpers/hooks/useListingHeader";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useRedirect from "helpers/hooks/useRedirect";
import { COLORS, COMPONENT_TYPES, SORT } from "helpers/utils/constants";
import { payloadBool, payloadFormatArray } from "helpers/utils/utilities";
import DashboardLayout from "pages/layouts/dashboard";
import ProjectAssessModal from "pages/projects/components/ProjectAssessModal";
import React, { useEffect, useState, useTransition } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useChallenges from "store/challenges/service-hook";
import ProjectAssessmentDetailsModal from "pages/report/challenge/components/ProjectAssessmentDetailsModal";

const ChallengeWinnerSelection = () => {
  const { t } = useLocalizedTranslation();
  const {
    fetchWinnerSubmissions,
    fetchWinnerAchievements,
    fetchWinnerExport,
    selectWinner,
    fetchChallenge,
  } = useChallenges();
  const { slug } = useParams();
  const { search, setSearch, sort, setSort } = useListingHeader();
  const { navigateProject, navigateChallenge } = useRedirect();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [submissions, setSubmissions] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState([]);
  const [addedAchievements, setAddedAchievements] = useState({});
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [saveConfirmationOpen, setSavedConfirmationOpen] = useState(false);
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [isPending, startTransition] = useTransition();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [challenge, setChallenge] = useState();
  const isWinnerFinalized = challenge?.winner_selection_enabled === "finalized";
  const [selectedProject, setSelectedProject] = useState(null);
  const [isViewProjectModalOpen, setIsViewProjectModalOpen] = useState(false);

  useEffect(() => {
    if (!slug) return;
    fetchData();
  }, [sort, page, search]);

  const fetchData = async () => {
    setLoading(true);
    const res = await fetchWinnerAchievements(slug);
    const data = await fetchWinnerSubmissions(slug, {
      page: page,
      sort_by: sort,
      search: search,
    });
    if (data?.list?.length && res?.length) {
      const { list, total_pages } = data;
      setPages(total_pages);
      setSubmissions(list || []);
      const nestedSelection = list
        ?.filter((d) => d?.incentive_achievement?.length)
        .map((d) => [
          ...d.incentive_achievement?.map((a) => ({
            achievementId: a?.achievement_id,
            projectId: d?.id,
          })),
        ]);
      const _addedNested = {};
      for (const d of nestedSelection) {
        if (d?.length > 1) {
          _addedNested[d[0]?.projectId] = d?.length - 1;
        }
      }
      setAddedAchievements(_addedNested);
      setSelection(nestedSelection.flat());
    } else if (!search) {
      navigateChallenge(slug, true);
      setTimeout(
        () =>
          toast.warn(
            t(
              "No project submissions or incentive achievements available for this challenge!"
            )
          ),
        100
      );
      return;
    } else {
      setSubmissions([]);
    }
    setAchievements(res);
    setLoading(false);
    const _c = await fetchChallenge(slug);
    setChallenge(_c);
  };

const handleSelection = (projectId, achievementId, projectIndex, achievementIndex) => {
  if (!projectId || !achievementId) return;

  setSelection((prev) => {
    const exists = prev.some(
      (item) => item.projectId === projectId && item.achievementId === achievementId
    );
    if (exists) return prev;
    const index = prev.findIndex(
      (item) => item.projectId === projectId && item.achievementIndex === achievementIndex
    );
    if (index !== -1) {
      return prev.map((item, idx) =>
        idx === index ? { projectId, achievementId, projectIndex, achievementIndex } : item
      );
    }
    return [...prev, { projectId, achievementId, projectIndex, achievementIndex }];
  });
};

 const handleRemove = (projectId, projectIndex, achievementIndex) => {
   setSelection((prev) => {
     return prev.filter(
       (item) =>
         !(
           item.projectId === projectId &&
           item.projectIndex === projectIndex &&
           item.achievementIndex === achievementIndex
         )
     );
   });
   setAddedAchievements((prev) => {
     const _prev = { ...prev };
     if (_prev[projectId]) {
       _prev[projectId] -= 1;
     }
     return _prev;
   })
 };

  const handleFinalizeOrSave = async (allowModify = false) => {
    setConfirmModalOpen(false);
    setSavedConfirmationOpen(false);
    setSubmitLoading(allowModify ? "save" : "finalize");
    const payload = {
      is_published: payloadBool(!allowModify),
      project_id: payloadFormatArray(selection, "projectId"),
      winner_achievement_id: payloadFormatArray(selection, "achievementId"),
    };
    const res = await selectWinner(slug, payload);
    setSubmitLoading(false);
    if (res?.status === 200) {
      if (allowModify) {
        setSavedConfirmationOpen(true);
      } else {
        setTimeout(() => navigateChallenge(slug, true), 1000);
      }
    }
  };

  const handleExport = () => {
    startTransition(async () => {
      await fetchWinnerExport(slug);
    });
  };

  const getAchievementTitle = (projectId) => {
    let _title = "";
    const selectionItems = selection.filter((s) => s?.projectId == projectId);
    if (!selectionItems?.length) {
      _title = <span className="fs-15 c-gray">{t("No award issued")}</span>;
    } else {
      _title = selectionItems
        ?.map(
          (d) =>
            achievements.find((a) => a?.key == d?.achievementId)?.value || "N/A"
        )
        ?.join(", ");
    }
    return _title;
  };

  return (
    <DashboardLayout isPrivate managerAccessOnly>
      <div className="container">
        <PageTitleCard
          leftAction={
            <Button
              bordered
              classes="ml-4"
              loading={isPending}
              onClick={handleExport}
              height="35px"
            >
              {t("Export List")}
            </Button>
          }
          title="Winner Selection"
          customAction={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
              mx={2}
            >
              <Button
                color="orange"
                disabled={
                  isWinnerFinalized ||
                  selection?.length < achievements?.length ||
                  !selection?.length
                }
                onClick={() => setConfirmModalOpen(true)}
                loading={submitLoading === "finalize"}
                height="35px"
              >
                {t("Finalize Winners")}
              </Button>
              <Button
                disabled={isWinnerFinalized || !selection?.length}
                color="blue"
                onClick={handleFinalizeOrSave}
                loading={submitLoading === "save"}
                height="35px"
              >
                {t("Save")}
              </Button>
            </Stack>
          }
        />
        <Card classes="mt-4 container">
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            rowGap={0.5}
          >
            <Input
              name="search"
              icon={<Search />}
              width="min(90vw,500px)"
              iconLeft
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              t={t}
              placeholder="Search"
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <p className="fs-14 c-title">{t("Sort by", "common")}</p>
              <Select
                keyValues={SORT}
                width="min(90vw,300px)"
                value={sort}
                onChange={(e) => setSort(e.target.value)}
                t={t}
              />
            </Stack>
          </Stack>
          <ListingContainer
            isEmpty={!submissions?.length}
            loading={loading}
            page={page}
            count={pages}
            setPage={setPage}
            classes="mt-4"
            columnSpacing={0}
            rowSpacing={0}
            emptyText={
              !!search
                ? t("No submissions found!")
                : t("No project submissions!")
            }
          >
            {submissions?.map((d, i) => (
              <Grid
                item
                container
                rowSpacing={2}
                xs={11.5}
                className="border-b mx-auto"
                py={2}
                alignItems="start"
                key={d}
              >
                <Grid item xs={12} md={3}>
                  <SecureImage
                    title={d?.title}
                    placeholder={getDefaultImageByType(COMPONENT_TYPES.PROJECT)}
                    src={d?.media}
                    height="170px"
                    width="100%"
                    containerClasses="w-full"
                  />
                </Grid>
                <Grid item xs={12} md={9} pl={{ md: 4, xs: 0 }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <h3
                      className="link fw-600 w-80p"
                      onClick={() => navigateProject(d?.slug)}
                    >
                      {d?.title}
                    </h3>
                    {(d?.can_access_assessment || d?.assessment_data?.assessment_status === "published") && (
                      <Button
                        color={
                          d?.assessment_data?.assessment_status === "published"
                            ? "blue"
                            : "gray"
                        }
                        classes="fs-14"
                        height="30px"
                        onClick={() => {
                          if (
                            d?.assessment_data?.assessment_status ===
                            "published"
                          ) {
                            setIsViewProjectModalOpen(true);
                            setSelectedProject(d);
                          } else {
                            setAssessmentModalOpen(d);
                          }
                        }}
                        hide={d?.assessment_data?.assessment_type === "none"}
                      >
                        {d?.assessment_data?.assessment_status === "published"
                          ? t("View Assessment")
                          : t("Pending Assessment")}
                      </Button>
                    )}
                  </Stack>
                  <p className="text-truncate c-title mt-3">
                    <b>{t("Team Members")}: </b>
                    {d?.members?.map((d) => d?.full_name)?.join(", ")}
                  </p>
                  <p
                    className={`c-title mt-1 ${
                      d?.assessment_data?.assessment_type === "none" ||
                      !d?.assessment_data
                        ? "hidden"
                        : ""
                    }`}
                  >
                    <b>{t("Assessment Score")}: </b>
                    {d?.assessment_data?.assessment_status !== "published"
                      ? t("Pending")
                      : `${d?.assessment_data?.overall_score}/${
                          d?.assessment_data?.outof_score ?? "10"
                        }`}
                  </p>
                  {isWinnerFinalized ? (
                    <p className="c-title mt-1">
                      <b>{t("Achievement awarded")}: </b>
                      {getAchievementTitle(d?.id)}
                    </p>
                  ) : (
                    <>
                      <Stack direction="row" alignItems="center" gap={1} mt={2}>
                        <Select
                          keyValues={achievements}
                          borderedIcon
                          width="250px"
                          classes="maxh-30"
                          onChange={(e) =>
                            handleSelection(d?.id, e.target.value, i)
                          }
                          name="winner_achievement"
                          value={
                            selection.find((s) => s?.projectId == d?.id)
                              ?.achievementId || ""
                          }
                        />
                        <Button
                          bordered
                          height="33px"
                          disabled={
                            addedAchievements[d?.id] >= achievements?.length - 1
                          }
                          hide={achievements?.length === 1}
                          classes="fs-14"
                          onClick={() =>
                            setAddedAchievements((prev) => ({
                              ...prev,
                              [d?.id]: (prev[d?.id] || 0) + 1,
                            }))
                          }
                        >
                          <AddOutlined fontSize="small" />
                          {t("Add Achievement")}
                        </Button>
                      </Stack>
                      {Array(addedAchievements[d?.id] || 0)
                        .fill(1)
                        .map((_f, index) => (
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                            mt={1.5}
                            key={_f}
                          >
                            <Select
                              keyValues={achievements}
                              borderedIcon
                              width="250px"
                              classes="maxh-30"
                              onChange={(e) =>
                                handleSelection(d?.id, e.target.value, i , index)
                              }
                              name="winner_achievement"
                              value={
                                selection.filter((s) => s?.projectId === d?.id)[
                                  index + 1
                                ]?.achievementId || ""
                              }
                            />
                            <Button
                              bordered
                              height="33px"
                              classes="fs-14"
                              color="red"
                              onClick={() => handleRemove(d?.id, i, index)}
                            >
                              <Delete fontSize="small" />
                              {t("Remove")}
                            </Button>
                          </Stack>
                        ))}
                    </>
                  )}
                </Grid>
              </Grid>
            ))}
          </ListingContainer>
        </Card>
      </div>
      <ConfirmationModal
        heading={t("Do you want to finalize the winners now?")}
        desc={t("You can still update selected winners if you cancel now.")}
        okayButtonTitle={t("Yes, finalize it")}
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onOkay={() => handleFinalizeOrSave(false)}
      />
      <ConfirmationModal
        heading={t(
          "Saved! Just one step left—finalize the winners to notify them."
        )}
        desc={t(
          "You can still update selected winners if you cancel now, but they won’t be notified unless you finalize the list."
        )}
        okayButtonTitle={t("Finalize Now")}
        open={saveConfirmationOpen}
        onClose={() => setSavedConfirmationOpen(false)}
        onOkay={() => handleFinalizeOrSave(false)}
        color={COLORS.BLUE}
        icon={Info}
      />
      <ProjectAssessModal
        viewMode={
          assessmentModalOpen?.assessment_data?.assessment_status ===
          "published"
        }
        open={Boolean(assessmentModalOpen)}
        onClose={() => setAssessmentModalOpen(false)}
        project={assessmentModalOpen}
      />

      <ProjectAssessmentDetailsModal
        open={isViewProjectModalOpen}
        onClose={() => {
          setSelectedProject(null);
          setIsViewProjectModalOpen(false);
        }}
        details={selectedProject}
        slug={slug}
      />
    </DashboardLayout>
  );
};

export default ChallengeWinnerSelection;
