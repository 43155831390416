import ImageCard from "components/Cards/ImageCard";
import React, { useState } from "react";
import MainPageLayout from "pages/layouts/MainPage";
import MainTabs from "components/UI/organisms/MainTabs";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import useRedirect from "helpers/hooks/useRedirect";
import { Settings, Edit, CopyAll, Delete } from "@mui/icons-material";
import PublicDescription from "components/UI/organisms/PublicDescription";
import useResources from "store/resources/service-hook";
import LikeShareSaveBtns from "components/UI/atoms/LikeShareSaveBtns";
import useAuth from "store/auth/service-hook";
import { isTrue } from "helpers/utils/validators";
import Card from "components/Cards/Card";
import EmptyText from "components/UI/atoms/EmptyText";
import AssociateResourceListing from "pages/associateListings/AssociateResourceListing";
import AssociateLabListing from "pages/associateListings/AssociateLabListing";
import AssociateChallengeListing from "pages/associateListings/AssociateChallengeListing";
import { ENDPOINTS } from "store/api/endpoints";
import MenuButton from "components/Button/MenuButton";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ShareModal from "components/UI/organisms/ShareModal";

const resource_tabs = [
  "Resource Modules",
  "Associated Labs",
  "Associated Challenges",
];

const ResourceCollectionMainPage = ({
  resourceC,
  setResourceC,
  isPrivate,
  onDelete,
  t = (d) => d,
}) => {
  const [tab, setTab] = useState(0);
  const { navigateEditResourceC, navigateManageResources, navigateResourceC } = useRedirect();
  const [loading, setLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [ResourceToDelete, setResourceToDelete] = useState(null);
  const { clone, favorite, unFavorite } = useResources();
  const {
    state: { isLoggedIn },
  } = useAuth();
  const [shareOpen, setShareOpen] = useState(false);

  const handleCloneRC = async () => {
    setLoading(true);
    const res = await clone(resourceC?.slug, true, false);
    if (!res.error) {
      navigateResourceC(res?.data.data.slug, true)
    }
    setLoading(false);
  };
  const doUndoAction = async (property, doAction, undoAction) => {
    const slug = resourceC?.slug;
    if (!slug || !isLoggedIn) return;
    const did = isTrue(resourceC[property]);
    if (did) {
      await undoAction(slug, true, false);
    } else {
      await doAction(slug, true, false);
    }
    resourceC[property] = did ? "no" : "yes";
    setResourceC({ ...resourceC });
  };

  const handleFavoriteUnFav = () =>
    doUndoAction("favourite", favorite, unFavorite);

  const handleDelete = async () => {
    setLoading(true);
    await onDelete(resourceC?.slug);
    setLoading(false);
    navigateManageResources();
  };
  const openExitConfirmationModal = (slug) => {
    setResourceToDelete(slug);
    setOpenConfirmationModal(true);
  };

  const cancelExitConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setResourceToDelete(null);
  };

  return (
    <>
      <div className="container flex-reverse mt-header">
        {isPrivate ? (
          <MenuButton
            listWithTitleonClick={[
              {
                title: "Edit",
                onClick: () => navigateEditResourceC(resourceC?.slug),
                icon: Edit,
              },
              {
                title: "Duplicate",
                onClick: handleCloneRC,
                icon: CopyAll,
              },
              {
                title: "Delete",
                onClick: () => openExitConfirmationModal(resourceC?.slug),
                icon: Delete,
                classes: "c-error",
              },
            ]}
            classes="fs-1 fs-13"
            height="30px"
          >
            <Settings fontSize="small" className="mr-1" /> {t("Manage")}
          </MenuButton>
        ) : // <Button
        //   width="320px"
        //   loading={loading}
        //   color={isCloned ? "green" : "primary"}
        //   bordered
        //   onClick={handleCloneRC}
        // >
        //   {isCloned ? <Done /> : <AddBox className="mr-1" />}
        //   {isCloned
        //     ? "Added to My Resource Collections"
        //     : "Add a Copy to My Resource Collections"}
        // </Button>
        null}
      </div>
      <MainPageLayout classes="mt-3" firstLgGridSize={4} secondLgGridSize={8}>
        <div className="left">
          <ImageCard
            image={resourceC?.cover_image}
            type={COMPONENT_TYPES.RESOURCE_COLLECTION}
            height="250px"
            isEmbedded={resourceC?.media_type === "embedded"}
            title={resourceC?.title}
          />
          <PublicDescription
            component={COMPONENT_TYPES.RESOURCE_COLLECTION}
            data={resourceC}
            hideRequirements
            hideSocialLinks
            showOrganization
            showProgress={!isPrivate}
            hideHostedBy
            isPrivate={isPrivate}
          >
            <LikeShareSaveBtns
              hideLike
              classes="mt-1"
              onSave={handleFavoriteUnFav}
              isSaved={isTrue(resourceC?.favourite)}
              onShare={() => setShareOpen(true)}
            />
          </PublicDescription>
        </div>
        <div className="right">
          <MainTabs current={tab} setCurrent={setTab} tabs={resource_tabs} />
          {tab === 0 && (
            <AssociateResourceListing
              parent={ENDPOINTS.RESOURCE_COLLECTION}
              slug={resourceC?.slug}
              emptyView={
                <Card classes="flex items-center justify-center w-full text-center ml-4">
                  <EmptyText classes="c-gray w-70p opacity-80">
                    {t(
                      "There is no Resource associated with this Resource Collection now, please check back later."
                    )}
                  </EmptyText>
                </Card>
              }
              isResourceCollDetail
              isPrivateView={isPrivate}
            />
          )}

          {tab === 1 && (
            <AssociateLabListing
              parent={ENDPOINTS.RESOURCE_COLLECTION}
              slug={resourceC?.slug}
              isPrivateView={isPrivate}
              emptyView={
                <Card classes="flex items-center justify-center w-full text-center ml-4">
                  <EmptyText classes="c-gray w-70p opacity-80">
                    {t(
                      "There is no Lab associated with this Resource Collection now, please check back later."
                    )}
                  </EmptyText>
                </Card>
              }
            />
          )}

          {tab === 2 && (
            <AssociateChallengeListing
              parent={COMPONENT_TYPES.RESOURCE_COLLECTION}
              slug={resourceC?.slug}
              isResourceCollDetail
              emptyView={
                <Card classes="flex items-center justify-center w-full text-center ml-4">
                  <EmptyText classes="c-gray w-70p opacity-80">
                    {t(
                      "There is no Challenge associated with this Resource Collection now, please check back later."
                    )}
                  </EmptyText>
                </Card>
              }
            />
          )}
        </div>
      </MainPageLayout>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={cancelExitConfirmationModal}
        desc={t("Are you sure you want to delete this Resource Collection?")}
        okayButtonTitle={t("Delete Resource Collection")}
        onOkay={() => {
          handleDelete(ResourceToDelete);
          cancelExitConfirmationModal();
        }}
      />
      <ShareModal
        open={shareOpen}
        onClose={() => setShareOpen(false)}
        component={COMPONENT_TYPES.RESOURCE_COLLECTION}
        slug={resourceC?.slug}
      />
    </>
  );
};

export default ResourceCollectionMainPage;
