import { Grid } from "@mui/material";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import PageTitle from "components/UI/atoms/PageTitle";
import React, { useEffect, useState } from "react";
import { stepSchema } from "./data";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import ExpertReferences from "components/UI/organisms/ExpertReferences";
import { createEvent } from "helpers/utils/utilities";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import {isTrue} from "../../../helpers/utils/validators";

const ChallengePathDetails = ({
  data,
  errors,
  onChange,
  step,
  experts = [],
  setExperts = Function.prototype,
  editMode = false
}) => {
  const { t } = useLocalizedTranslation();
  const [previousPrivacy, setPreviousPrivacy] = useState(data.privacy);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    if (data.privacy !== previousPrivacy && editMode) {
      if (isTrue(data?.privacy)) {
        setOpenConfirmModal(true);
      } else {
        setPreviousPrivacy("no");
      }
    }
  }, [data.privacy]);

  return (
    <>
      <Grid container px={{ xs: 0, md: 4 }}>
        <PageTitle>{t("Challenge Path Details")}</PageTitle>
        <CreateFormSection classes="mt-5">
          <SchemaUIExtractor
            schema={stepSchema(step).slice(0, 10)}
            data={data}
            errors={errors}
            onChange={onChange}
          />
          <h3 className="mt-3">{t("Skills & Tags")}</h3>
          <SchemaUIExtractor
            schema={stepSchema(step).slice(10, 13)}
            data={data}
            errors={errors}
            onChange={onChange}
          />
          <h3 className="mt-4">{t("Add Experts")}</h3>
          <p>{t("Feature experts for your challenge path")}</p>
          <ExpertReferences experts={experts} setExperts={setExperts} />
        </CreateFormSection>
      </Grid>
      <ConfirmationModal
        open={openConfirmModal}
        onClose={() => {
            setOpenConfirmModal(false)
            onChange(createEvent("privacy", "no"))
          }}
        heading={t("Are you sure you want to change?")}
        desc={t("By changing now, you'll lose your current challenges. Do you still want to proceed?")}
        onOkay={() => {
          onChange(createEvent("privacy", "yes"));
          setOpenConfirmModal(false);
        }}
      />
    </>
  );
};

export default ChallengePathDetails;
