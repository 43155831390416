import { Grid } from "@mui/material";
import ProgressByLabProgram from "./ProgressByLabProgram";
import ProgressByChallenge from "pages/report/components/ProgressByChallenge";
import useOrganizationReport from "store/reports/organization/service-hook";
import useOrganizationReportContext from "../hooks/useOrganizationReportContext";
import ComponentLists from "./ComponentsLists";
import ProgressByResource from "pages/report/components/ProgressByResource";
import LabEngagement from "./OrganizationEngagement";
import OrganizationMemberActivity from "./OrganizationMemberActivity";
import EmptyText from "components/UI/atoms/EmptyText";

const OrganisationOverview = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const {
    organizationChallenges,
    organizationChallengePaths,
    organizationResourceModules,
    organizationResourceGroup,
    organizationResourceCollection,
  } = useOrganizationReport();
  const { organizationSlug } = useOrganizationReportContext();

  return (
    <>
      <Grid container spacing={1} className={"ml-0"}>
        <Grid item xs={12} className={"grid-lg pl-0"}>
          <ComponentLists t={t} />
          <ProgressByChallenge
            fetchChallenges={organizationChallenges}
            fetchChallengePaths={organizationChallengePaths}
            slug={organizationSlug}
            noDataUI={
              <EmptyText>
                {t(
                  "There is no Challenge or Challenge Path associated to this Organization, create now with 'Create' button from navigation bar"
                )}
              </EmptyText>
            }
            t={t}
          />
        </Grid>
        <Grid item xs={12} className={"grid-md"}>
          <ProgressByLabProgram slug={organizationSlug} t={t}/>
          <ProgressByResource
            fetchResources={organizationResourceModules}
            fetchResourceCollections={organizationResourceCollection}
            fetchResourceGroups={organizationResourceGroup}
            slug={organizationSlug}
            noDataUI={
              <EmptyText>
                {t(
                  "There is no Resource associated to this Organization, create now with 'Create' button from navigation bar"
                )}
              </EmptyText>
            }
            t={t}
          />
        </Grid>
        <Grid item xs={12} className={"grid-md"}>
          <LabEngagement t={t} />
          <OrganizationMemberActivity t={t} />
        </Grid>
      </Grid>
    </>
  );
};

export default OrganisationOverview;
