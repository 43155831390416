import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box } from "@mui/material";
ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChartGraph = ({
  labels,
  objWithColorsDataArray,
  height,
  aspectRatio,
  legendPostion,
  cutout,
  classes,
}) => {
  const data = {
    labels: labels,
    datasets: objWithColorsDataArray.map(({ data, colors }, i) => ({
      label: `Set ${i + 1}`,
      data: data,
      backgroundColor: colors
        ? colors
        : ["#1ABC9C", "#FEC144", "#5065A1", "#9496A1"],
    })),
  };

  const options = {
    cutout: cutout || 90,
    responsive: true,
    aspectRatio: aspectRatio || 1,
    plugins: {
      legend: {
        position: legendPostion || "top",
      },
    },
  };

  return (
    <Box
      data-testid="donut-chart-graph"
      sx={{ height: height && height }}
      className={classes}
    >
      <Doughnut data={data} options={options} />
    </Box>
  );
};

export default DonutChartGraph;
