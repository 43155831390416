import { createSlice } from "@reduxjs/toolkit";
import ACTIONS from "./action-types";

const initialState = {
  socialLinks: [],
  labCondition: [],
  pitchTemplate: [],
  flexibleDateDuration: [],
  host: [],
  achievementCondition: [],
  projectSubmission: [],
  skillGroups: [],
  ranks: [],
  skillSets: [],
  categories: [],
  skills: [],
  tags: [],
  industries: [],
  types: [],
  stages: [],
  verticals: [],
  projectStatus: [],
  socialConnect: [],
  level: [],
  duration: [],
  tag_groups: [],
  job_titles: [],
  business_challenge_tackings: [],
};

const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    [ACTIONS.SOCIAL_LINKS]: (state, { payload }) => {
      state.socialLinks = payload;
    },
    [ACTIONS.LAB_CONDITIONS]: (state, { payload }) => {
      state.labCondition = payload;
    },
    [ACTIONS.PITCH_TEMPLATE]: (state, { payload }) => {
      state.pitchTemplate = payload;
    },
    [ACTIONS.FLEXIBLE_DATE_DURATION]: (state, { payload }) => {
      state.flexibleDateDuration = payload;
    },
    [ACTIONS.HOST]: (state, { payload }) => {
      state.host = payload;
    },
    [ACTIONS.ACHEIVEMENT_CONDITIONS]: (state, { payload }) => {
      state.achievementCondition = payload;
    },
    [ACTIONS.PROJECT_SUBMISSION]: (state, { payload }) => {
      state.projectSubmission = payload;
    },
    [ACTIONS.SKILL_GROUPS]: (state, { payload }) => {
      state.skillGroups = payload;
    },
    [ACTIONS.RANKS]: (state, { payload }) => {
      state.ranks = payload;
    },
    [ACTIONS.SKILL_SETS]: (state, { payload }) => {
      state.skillSets = payload;
    },
    [ACTIONS.CATEGORIES]: (state, { payload }) => {
      state.categories = payload;
    },
    [ACTIONS.SKILLS]: (state, { payload }) => {
      state.skills = payload;
    },
    [ACTIONS.TAGS]: (state, { payload }) => {
      state.tags = payload;
    },
    [ACTIONS.INDUSTRIES]: (state, { payload }) => {
      state.industries = payload;
    },
    [ACTIONS.TYPES]: (state, { payload }) => {
      state.types = payload;
    },
    [ACTIONS.STAGES]: (state, { payload }) => {
      state.stages = payload;
    },
    [ACTIONS.VERTICALS]: (state, { payload }) => {
      state.verticals = payload;
    },
    [ACTIONS.PROJECT_STATUS]: (state, { payload }) => {
      state.projectStatus = payload;
    },
    [ACTIONS.SOCIAL_CONNECT]: (state, { payload }) => {
      state.socialConnect = payload;
    },
    [ACTIONS.DURATION]: (state, { payload }) => {
      state.duration = payload;
    },
    [ACTIONS.LEVEL]: (state, { payload }) => {
      state.level = payload;
    },
    [ACTIONS.TAG_GROUPS]: (state, { payload }) => {
      state.tag_groups = payload;
    },
    [ACTIONS.ANNOUNCEMENT_RECIPIENT]: (state, { payload }) => {
      state.challengeAnnouncementRecipient = payload;
    },
    [ACTIONS.JOB_TITLES]: (state, { payload }) => {
      state.job_titles = payload;
    },
    [ACTIONS.BUSINESS_CHALLENGE_TACKLINGS]: (state, { payload }) => {
      state.business_challenge_tackings = payload;
    },
  },
});
export const REDUCER = masterSlice.actions;

export default masterSlice.reducer;
