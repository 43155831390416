import Button from "components/Button";
import SecureImage from "components/UI/atoms/SecureImage";
import { useState } from "react";
import { resource as resourceDefaultImg } from "assets/svg/purple";
import moment from "moment";
import { Grid, Tooltip } from "@mui/material";

export function formatDuration(minutes) {
  const duration = moment.duration(minutes, "minutes");
  const hours = duration.hours();
  const mins = duration.minutes();
  let formatted = [];

  if (hours) formatted.push(`${hours}h`);
  if (mins) formatted.push(`${mins}min`);

  return formatted.join(" ");
}

const ResourceCard = ({
  setHoveredCardIndex,
  hoveredCardIndex,
  i,
  resource,
  switchPreviewMode,
  handleCreateCourse,
  canCreateCourse,
}) => {
  const [loading, setLoading] = useState(false);

  const onClickUse = async () => {
    setLoading(true);
    await handleCreateCourse(resource);
    setLoading(false);
  };

  const tagStyle = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    lineHeight: "13px",
    height: "26px"
  };

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <div
        onMouseEnter={() => setHoveredCardIndex(i)}
        onMouseLeave={() => setHoveredCardIndex(null)}
        className={`rounded-10 border relative cursor-pointer py-2 mx-auto px-2`}
      >
        <div className="relative h-150">
          {(hoveredCardIndex === i || loading) && (
            <div
              className="flex-column justify-center items-center w-100p h-100p absolute z-10 rounded-10"
              style={{ backgroundColor: "#222222b2" }}
            >
              <Button
                classes="bg-white c-primary w-130"
                onClick={() => switchPreviewMode(resource?.id)}
              >
                Preview
              </Button>
              {!canCreateCourse ? (
                <Tooltip
                  title="You don't have permission to use GO1 courses!"
                  placement="bottom"
                  arrow
                >
                  <span>
                    <Button classes="mt-3 w-130" disabled>
                      Use Course
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button
                  classes="mt-3 w-130"
                  loading={loading}
                  onClick={onClickUse}
                >
                  Use Course
                </Button>
              )}
            </div>
          )}
          <SecureImage
            src={resource?.image || resourceDefaultImg}
            placeholder={resourceDefaultImg}
            className="h-150 w-100p bg-gray rounded-10"
            alt={resource?.title}
          />
        </div>
        <p className="fs-12 tracking-tight mt-1 c-gray text-truncate">
          {`By ${resource?.provider?.name || "Unknown"}`}
        </p>

        <h4 className="text-truncate">
          {!!resource.title ? resource?.title : "N/A"}
        </h4>
        <p className="mr-2 fs-12 tracking-tight flex mt-1">
          <span className="fw-700">Topics: </span>
          <span className="c-gray ml-1 text-truncate">
            {resource?.attributes?.topics?.length > 0
              ? resource?.attributes?.topics
                ?.map((t) => t?.value || t)
                .join(", ")
              : "N/A"}
          </span>
        </p>
        <p className="fs-12 tracking-tight flex mt-1">
          <span className="fw-700">Tags: </span>
          <span className="c-gray ml-1 text-truncate" style={tagStyle}>
            {resource?.tags?.length > 0
              ? resource?.tags?.map((tag) => tag?.title || tag).join(", ")
              : "N/A"}
          </span>
        </p>

        <p className="fs-12 tracking-tight flex mt-2 c-gray text capitalize">
          {resource?.delivery?.duration === 0
            ? "Self-Paced"
            : formatDuration(resource?.delivery?.duration)}{" "}
          <span className="vertical-align-middle mx-1">&middot;</span>
          {resource?.type}{" "}
          <span className="vertical-align-middle mx-1">&middot;</span>
          {resource?.language}
        </p>
      </div>
    </Grid>
  );
};

export default ResourceCard;
