import React, { useEffect, useRef, useState } from "react";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import GoogleDrive from "@uppy/google-drive";
import Dropbox from "@uppy/dropbox";
import Webcam from "@uppy/webcam";
import Instagram from "@uppy/instagram";
import "@uppy/core/dist/style.min.css";
import "@uppy/drag-drop/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/webcam/dist/style.min.css";
import "./FileUploader.scss";

const FileUploader = ({
  files,
  setFiles,
  maxFileSize,
  classes,
  t = (d) => d,
}) => {
  const [uppy, setUppy] = useState(null);
  const filesRef = useRef(files);

  useEffect(() => {
    filesRef.current = files;
  }, [files]);

  useEffect(() => {
    if (uppy) return;
    const uppyInstance = new Uppy({
      inline: true,
      target: "#file-uploader",
      autoProceed: true,
      showProgressDetails: true,
      locale: {
        strings: {
          dropPasteImportFiles:
            "Drop files here, %{browseFiles} or import from:",
        },
      },
      restrictions: {
        maxFileSize: maxFileSize,
      },
    });

    if (files && files.length > 0) {
      files.forEach((file) => {
        if (!uppyInstance.getFile(file.id)) {
          uppyInstance.addFile({
            name: file.name,
            type: file.type,
            data: file.data,
            id: file.id,
            meta: file.meta || {},
          });
        }
      });
    }

    uppyInstance
      .use(Webcam)
      .use(Instagram, { companionUrl: "https://companion.uppy.io" })
      .use(GoogleDrive, { companionUrl: "https://companion.uppy.io" })
      .use(Dropbox, { companionUrl: "https://companion.uppy.io" });

    uppyInstance.on("complete", (result) => {
      setFiles(result.successful);
    });

    uppyInstance.on("file-removed", (file, reason) => {
      if (reason === "cancel-all") return;
      const updatedFiles = filesRef?.current?.filter((f) => f.id !== file.id);
      setFiles(updatedFiles);
    });

    setUppy(uppyInstance);

    return () => {
      uppyInstance.close();
    };
  }, []);

  return (
    <>
      <div id="file-uploader" className={classes}></div>
      {uppy && (
        <Dashboard
          hideUploadButton={true}
          uppy={uppy}
          defaultValue={files}
          plugins={[
            "Instagram",
            "GoogleDrive",
            "Dropbox",
            "Webcam",
            "Facebook",
          ]}
        />
      )}
    </>
  );
};

export default FileUploader;
