import ChallengeReportContextWrapper from "./container/ChallengeReportContext";
import ChallengeReportDetails from "./components/ChallengeReportDetails";
import DashboardLayout from "../../layouts/dashboard";
import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import ChallengeOverView from "./components/ChallengeOverview";
import ChallengeMembers from "./components/ChallengeMembers";
import ChallengeAssessmentReports from "./components/ChallengeAssessmentReports";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const ChallengeReport = () => {
  const { t } = useLocalizedTranslation();
  const [activeTab, setActiveTab] = useState("overview");
  
  return (
    <DashboardLayout>
      <ChallengeReportContextWrapper>
        <div className="mt-header mb-5 container-big">
          <ChallengeReportDetails t={t} />
          <div className={"mb-8 mx-8 mt-3"}>
            <Tabs
              value={activeTab}
              onChange={(event, value) => {
                setActiveTab(value);
              }}
            >
              {[
                { title: "Challenge Details", value: "overview" },
                { title: "Challenge Members", value: "members" },
                { title: "Assessment Reports", value: "assessment_reports" },
              ].map((d, i) => (
                <Tab key={d?.value} label={t(d?.title)} value={d?.value} />
              ))}
            </Tabs>
            <div className={"mt-3"}>
              {/*CHALLENGE COMPONTENTS OVERVIEW*/}
              {activeTab === "overview" ? <ChallengeOverView t={t} /> : ""}

              {/*CHALLENGE MEMBERS*/}
              {activeTab === "members" ? <ChallengeMembers t={t} /> : ""}

              {/*CHALLENGE ASSESSMENT REPORTS*/}
              {activeTab === "assessment_reports" ? (
                <ChallengeAssessmentReports t={t} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </ChallengeReportContextWrapper>
    </DashboardLayout>
  );
};

export default ChallengeReport;
