import Card from 'components/Cards/Card'
import Divider from 'components/Divider'
import Input from 'components/Input'
import Select from 'components/Input/Select'
import { subscription_plans } from 'pages/organization/forms/data'
import React from 'react'
import { CheckoutCard } from '../addPrebuiltLabs/CheckoutLab'

const CheckoutPlan = () => {
    return (
        <div className='org__checkoutCardsContainer'>
            <Card classes="my-4 pt-4 checkout__left">
                <h3>Review your Subscription</h3>
                <div className='flex items-center justify-between mt-2 fs-14'>
                    <Select height={30} width={100} values={subscription_plans.map(d => d?.title)} value={subscription_plans[1]?.title} />
                    <p>$99 / month</p>
                </div>
                {
                    Array(4).fill(1).map((d, i) =>
                        <div key={i} className='flex items-center justify-between fs-14 mt-1'>
                            <p className=''>Add-on 1</p>
                            <div className='flex mx-3 items-center'>
                                <b className='cursor-pointer user-select-none' >-</b>
                                <Input height={25} width={100} classes="mx-2" value={2} type="number" />
                                <b className='cursor-pointer user-select-none'>+</b>
                            </div>
                            <p> $500 / month</p>
                        </div>
                    )
                }
                <Divider classes="mt-15" />
                <div className='text-right mt-2 px-4'>
                    <p><b className='mr-10'>Total:</b> $2000</p>
                </div>
            </Card>
            <CheckoutCard data={{}} errors={{}} onChange={() => { }} />
        </div>
    )
}

export default CheckoutPlan