import {
  Favorite,
  FavoriteBorder,
  PushPin,
  PushPinOutlined,
} from "@mui/icons-material";
import { Chip, Grid, Icon, IconButton } from "@mui/material";
import MainProductCard from "components/Cards/MainProductCard";
import ListingContainer from "components/UI/organisms/ListingContainer";
import { COLORS, COMPONENT_TYPES } from "helpers/utils/constants";
import React from "react";
import { isTrue } from "helpers/utils/validators";
import useProfile from "store/profile/service-hook";
import useSkills from "store/skills/service-hook";
import { loginToPreceed } from "helpers/utils/utilities";

const SkillListing = ({
  browseMode,
  gridType,
  manage,
  skills = [],
  setSkills = Function.prototype,
  loading,
  page = 1,
  setPage = Function.prototype,
  pageCount = 1,
  isLoggedIn,
}) => {
  const { deleteSkills } = useProfile();
  const { add, pinUnpin } = useSkills();

  const doUndoAction = async (id, property, doAction, undoAction) => {
    if (!isLoggedIn) {
      loginToPreceed();
      return;
    }
    const item = skills.find((d) => d.id === id);
    if (!item) return;
    const did = isTrue(item[property]);
    if (did) {
      await undoAction(id);
    } else {
      await doAction(id);
    }
    item.is_saved =
      property === "pinned"
        ? !did
          ? "yes"
          : item?.is_saved
        : did
        ? "no"
        : "yes";
    item.pinned =
      property === "is_saved"
        ? did
          ? "no"
          : item?.pinned
        : did
        ? "no"
        : "yes";
    setSkills([...skills]);
  };
  const handleFavoriteUnFav = (id) =>
    doUndoAction(id, "is_saved", add, deleteSkills);
  const handlePinUnpin = (id) =>
    doUndoAction(id, "pinned", pinUnpin, (id) => pinUnpin(id, true));

  return (
    <ListingContainer
      loading={loading}
      isEmpty={!skills?.length}
      count={pageCount}
      page={page}
      setPage={setPage}
      classes="mt-4"
      emptyText="No skills found!"
      small
    >
      {skills?.map((d, i) => {
        const { title, description, id, category = "N/A" } = d;
        return (
          <Grid item key={i} xs={12} sm={6} lg={gridType === 1 ? 12 : 4}>
            <MainProductCard
              classes="mb-3"
              bgColor={
                manage && isTrue(d?.pinned) && `${COLORS.GREEN_OPAQUE}30`
              }
              small
              title={
                <div className="flex items-center">
                  <p className="text-nowrap overflow-hidden maxw-85p textOverflow-ellipsis">
                    {title}
                  </p>
                  <PushPin
                    fontSize="small"
                    style={{
                      visibility:
                        !manage && isTrue(d?.pinned) ? "visible" : "hidden",
                    }}
                  />
                </div>
              }
              subTitle={
                <Chip
                  label={category}
                  className="py-2 mr-1 fs-12"
                  sx={{ height: "24px" }}
                />
              }
              description={description}
              col={gridType === 1 ? false : true}
              type={COMPONENT_TYPES.SKILL}
              id={id}
              rightAction={
                <div className="flex">
                  {!!manage && (
                    <IconButton>
                      <Icon
                        onClick={() => handlePinUnpin(d?.id)}
                        component={
                          isTrue(d?.pinned) ? PushPin : PushPinOutlined
                        }
                        color={isTrue(d?.pinned) ? "primary" : "disabled"}
                      />
                    </IconButton>
                  )}
                  {browseMode !== 2 && (
                    <IconButton onClick={() => handleFavoriteUnFav(d?.id)}>
                      <Icon
                        component={
                          isTrue(d?.is_saved) ? Favorite : FavoriteBorder
                        }
                        color={isTrue(d?.is_saved) ? "error" : "disabled"}
                      />
                    </IconButton>
                  )}
                </div>
              }
              noSave
            />
          </Grid>
        );
      })}
    </ListingContainer>
  );
};

export default SkillListing;
