import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import CareersMainPage from "./listing/CareersMainPage";
import ModalLayout from "components/Modal";
import GetAddonsButton from "components/UI/molecules/GetAddonsButton";
import useOrganization from "store/organization/service-hook";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import { isTrue } from "helpers/utils/validators";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "components/Button";

const CareerExplore = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [plan, setPlan] = useState();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state[REDUCER_TYPES.AUTH]);
  const { planDetails } = useOrganization();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  const fetchPlanDetails = async () => {
    setLoading(true);
    const res = await planDetails(user?.organization_details?.slug);
    setPlan(res);
    setLoading(false);
  };

  const handleClosePopup = () => navigate(location.state ?? -1);

  return (
    <DashboardLayout isPrivate loading={loading}>
      <CareersMainPage t={t} isAddonEnabled={isTrue(plan?.career_explorer)} />
      <ModalLayout
        open={!isTrue(plan?.career_explorer)}
        width="min(500px, 95%)"
        top="300px"
        hideCloseIcon
      >
        <div className="px-6 py-6 text-center relative flex-column items-center justify-center">
          <h3 className="mb-6">
            {t(
              "Please purchase the Career Explorer Add On to unlock this feature."
            )}
          </h3>
          <div className="flex justify-between w-full">
            <Button bordered onClick={handleClosePopup}>
              {t("Back", "common")}
            </Button>
            <GetAddonsButton onClose={fetchPlanDetails} />
          </div>
        </div>
      </ModalLayout>
    </DashboardLayout>
  );
};

export default CareerExplore;
