import {
  DeleteForever,
  Edit,
  ManageAccounts,
  PushPin,
  PushPinOutlined,
  Settings,
} from "@mui/icons-material";
import MenuButton from "components/Button/MenuButton";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { isTrue } from "helpers/utils/validators";
import React from "react";
import { useTranslation } from "react-i18next";

const ProjectManageOptions = ({
  data,
  onDelete,
  pinUnpin = Function.prototype,
  classes = "",
}) => {
  const { navigateMemberManagement, navigateEditProject } = useRedirect();
  const { t } = useTranslation("common");

  return (
    <MenuButton
      listWithTitleonClick={[
        {
          title: "Manage Members",
          onClick: () =>
            navigateMemberManagement(COMPONENT_TYPES.PROJECT, data?.slug),
          icon: ManageAccounts,
          iconClasses: "c-primary",
          disabled:
            data?.challenge_details?.challenge_status === "closed" ||
            isTrue(data?.is_submitted),
        },
        {
          divider: true,
        },
        {
          title: "Edit",
          onClick: () => navigateEditProject(data?.slug),
          icon: Edit,
          disabled:
            data?.challenge_details?.challenge_status === "closed" ||
            isTrue(data?.is_submitted),
        },
        isTrue(data?.is_pin_allowed) && {
          title: isTrue(data?.is_project_pinned)
            ? "Remove from Featured"
            : "Feature on Profile",
          onClick: () => pinUnpin(data?.slug, isTrue(data?.is_project_pinned)),
          icon: isTrue(data?.is_project_pinned) ? PushPin : PushPinOutlined,
          classes: isTrue(data?.is_project_pinned) && "c-error",
        },
        {
          title: "Delete",
          onClick: () => onDelete(data?.slug),
          icon: DeleteForever,
          iconClasses: "c-error",
          classes: "c-error",
          disabled: isTrue(data?.is_submitted),
        },
      ].filter(Boolean)}
      classes={`fs-12 minh-25 ${classes}`}
      height="30px"
      width="75px"
    >
      <Settings sx={{ fontSize: 16 }} /> {t("Manage")}
    </MenuButton>
  );
};

export default ProjectManageOptions;
