import React, { useEffect, useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
// import { USER_STATUS } from "../auth-data";
import {
  CHARACTER_VALIDATOR,
  CUSTOM_VALIDATOR,
  PASSWORD_VALIDATOR,
  REQUIRED_VALIDATOR,
  Validate,
} from "helpers/utils/validators";
import AccountType from "./registrationSteps/AccountType";
import GuidanceSteps, { getSliceValues } from "./registrationSteps/GuidedSteps";
import UserType from "./registrationSteps/UserType";
import useAuth from "store/auth/service-hook";
import useForms from "helpers/hooks/useForms";
import { createEvent, isGoogleCaptchaDisabled } from "helpers/utils/utilities";
// import {LANGUAGES} from "helpers/utils/constants";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const Register = () => {
  const { register, state, checkEmail, checkUsername, checkOrgTitle } =
    useAuth();
  const initData = {
    language: state.language,
    purpose: "looking_mentor",
    sso_registration: "no",
  };
  const [registerStep, setRegisterStep] = useState(0);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { user, isAuthenticated } = useAuth0();
  const [recaptchaValue, setRecaptchaValue] = useState(
    isGoogleCaptchaDisabled()
  );
  const [recaptchaError, setRecaptchaError] = useState(false);
  const sso = searchParams.get("sso");
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    if (sso === "true" && isAuthenticated) {
      handleSSORegister();
    }
  }, [sso]);

  const handleSchemaSlice = (schema) => {
    return schema.slice(...getSliceValues(registerStep, formData.type));
  };

  const handleCustomValidate = async (value, call) => {
    const optError = await Validate(REQUIRED_VALIDATOR, value);
    if (optError) return optError;
    setLoading(true);
    const res = await call(value);
    setLoading(false);
    return res?.error ? res?.message : false;
  };

  const handleCheckEmail = async (value) =>
    await handleCustomValidate(value, checkEmail);
  const handleCheckUsername = async (value) =>
    await handleCustomValidate(value, checkUsername);
  const handleCheckOrgTitle = async (value) =>
    await handleCustomValidate(value, checkOrgTitle);

  const register_steps = [
    {
      title: "First Name",
      placeholder: "Enter First Name",
      name: "first_name",
      validator: CHARACTER_VALIDATOR,
    },
    {
      title: "Last Name",
      placeholder: "Enter Last Name",
      name: "last_name",
      validator: CHARACTER_VALIDATOR,
    },
    // {
    //   title: "Language",
    //   name: "language",
    //   keyValues: LANGUAGES,
    //   type: "lang",
    // },
    {
      title: "Organization Name",
      name: "organization_title",
      placeholder: "Enter organization name",
      type: "input",
      validator: CUSTOM_VALIDATOR,
      optinalValidatorFunc: handleCheckOrgTitle,
      validate: REQUIRED_VALIDATOR,
    },
    {
      title: "Email Address",
      placeholder: "Enter Email",
      name: "email",
      type: "email",
      validator: CUSTOM_VALIDATOR,
      optinalValidatorFunc: handleCheckEmail,
    },
    {
      title: "Username",
      placeholder: "Enter Username",
      name: "username",
      validator: CUSTOM_VALIDATOR,
      optinalValidatorFunc: handleCheckUsername,
    },
    // {
    //   title: "Status",
    //   name: "purpose",
    //   keyValues: USER_STATUS,
    //   type: "select",
    //   validator: REQUIRED_VALIDATOR,
    // },
    {
      title: "Password",
      placeholder: "Enter Password",
      name: "password",
      type: "password",
      validator: PASSWORD_VALIDATOR,
      optinalValidatorKey: "password_confirmation",
    },
    {
      title: "Confirm Password",
      placeholder: "Confirm Password",
      name: "password_confirmation",
      type: "password",
      validator: REQUIRED_VALIDATOR,
    },
  ];

  const {
    data: formData,
    errors,
    validate,
    onChange,
    setData,
  } = useForms(register_steps, initData, handleSchemaSlice);

  const handleSSORegister = () => {
    const createSSODetail = () => {
      try {
        let detail = {
          type: "user",
          email: user?.email || "",
          first_name: user?.given_name || user?.name?.split(" ")[0] || "",
          last_name: user?.family_name || user?.name?.split(" ")[1] || "",
          sso_registration: "yes",
        };
        return detail;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    };
    const detail = createSSODetail();
    setData({ ...initData, ...detail });
    setStep(1);
  };

  const handleRegister = async () => {
    const { type } = formData;
    setLoading(true);
    const body = {
      ...formData,
      register_type: type,
      "g-recaptcha-response": recaptchaValue,
    };
    if (!body.sso_registration) body["sso_registration"] = "no";
    delete body.type;
    await register(body);
    setLoading(false);
  };

  const onClickAccountType = (id) => {
    const value = id === 1 ? "user" : "organization";
    onChange(createEvent("type", value));
    setStep(1);
  };

  const onClickUserType = (data) => {
    onChange(createEvent("user_type", data));
    setStep(2);
  };

  const handleBack = () => {
    if (registerStep === 0) {
      setData({
        type: formData.type,
      });
      setStep(1);
    }
    registerStep !== 0 && setRegisterStep((val) => val - 1);
  };

  const handleNext = async () => {
    const errors = await validate();
    if (errors.size) return;
    if (sso === "true" ? registerStep === 1 : registerStep === 2) {
      if (!recaptchaValue) {
        setRecaptchaError(true);
        return;
      }
      handleRegister();
    } else {
      setRegisterStep((val) => val + 1);
    }
  };

  return (
    <>
      <AuthLayout sliderHidden={true}>
        {step === 0 && <AccountType onClick={onClickAccountType} t={t} />}
        {step === 1 && (
          <UserType
            onClick={onClickUserType}
            type={formData?.type}
            setStep={setStep}
            t={t}
          />
        )}
        {step === 2 && (
          <>
            <GuidanceSteps
              userType={formData?.type}
              onChange={onChange}
              errors={errors}
              formData={formData}
              handleBack={handleBack}
              handleNext={handleNext}
              loading={loading}
              steps={register_steps}
              current={registerStep}
              isSSO={sso === "true"}
              recaptchaError={recaptchaError}
              setRecaptchaValue={setRecaptchaValue}
              setRecaptchaError={setRecaptchaError}
              t={t}
            />
          </>
        )}
      </AuthLayout>
    </>
  );
};

export default Register;
