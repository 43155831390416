import { Chip } from "@mui/material";
import { COLORS, SCHEMA_TYPES } from "helpers/utils/constants";
import {
  BOOLEAN_VALIDATOR,
  EDITOR_VALIDATOR,
  REQUIRED_VALIDATOR,
  TITLE_VALIDATOR,
} from "helpers/utils/validators";

const PROJECT_PRIVACY_STATUS = [
  {
    value: "Public(viewable by all members in the Organization)",
    key: "public",
  },
  {
    value: "Private(viewable only by your team, managers and assessors)",
    key: "private",
  },
];

export const schema = [
  {
    title: "Project Title",
    name: "title",
    type: SCHEMA_TYPES.INPUT,
    validator: TITLE_VALIDATOR,
    locked: true,
  },
  {
    title: "Privacy",
    name: "privacy",
    type: SCHEMA_TYPES.SELECT,
    validator: REQUIRED_VALIDATOR,
    options: PROJECT_PRIVACY_STATUS,
  },
  {
    title: "Public Project Files Privacy",
    label:
      "When your Project is public, allow users outside your team to download Project files.",
    type: SCHEMA_TYPES.SWITCH,
    name: "is_download_enabled",
    validator: BOOLEAN_VALIDATOR,
    lg: 12,
  },
  {
    title: "Description",
    name: "description",
    type: SCHEMA_TYPES.EDITOR,
    validator: EDITOR_VALIDATOR,
    lg: 12,
    locked: true,
  },
  {
    title: "Active Challenge",
    name: "challenge_id",
    type: SCHEMA_TYPES.MASTER,
    filters: { active: true },
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Associated Lab",
    name: "active_labs",
    type: SCHEMA_TYPES.MASTER,
  },
  {
    title: "Upload Image",
    name: "cover_media",
    type: SCHEMA_TYPES.FILE,
    description:
      "The image(png, jpg, or jpeg) needs to be at least 625 x 355 pixels.",
    dropboxProps: {
      width: "95%",
      fileType: "image",
      height: "300px",
      video_code: "iframe_code",
      dimensions: [625, 355],
    },
    lg: 12,
  },
];

export const additional_information_schema = [
  {
    title: "Category",
    name: "category_id",
    type: SCHEMA_TYPES.MASTER,
    filters: {
      component: "project",
    },
  },
  {
    title: "Type",
    name: "type_id",
    type: SCHEMA_TYPES.MASTER,
  },
  {
    title: "Industry",
    name: "industry_id",
    type: SCHEMA_TYPES.MASTER,
  },
  {
    title: "Stage",
    name: "stage_id",
    type: SCHEMA_TYPES.MASTER,
  },
  {
    title: "Verticals",
    name: "verticals_id",
    type: SCHEMA_TYPES.MASTER,
  },
  {
    title: "Project Status",
    name: "status_id",
    type: SCHEMA_TYPES.MASTER,
  },
];

export const getPitchesJSXCols = () => [
  {
    title: "Status",
    render: (rowData) => {
      return (
        <Chip
          className="fs-12"
          label={rowData.status}
          variant="filled"
          sx={{
            color: rowData.status === "Complete" ? COLORS.SUCCESS : COLORS.BLUE,
          }}
        />
      );
    },
  },
  {
    title: "Title",
    render: (rowData) => {
      return <span>{rowData?.title}</span>;
    },
  },
  {
    title: "Challenge",
    render: (rowData) => {
      return <span>{rowData.challenge}</span>;
    },
  },
  {
    title: "Last Modified",
    render: (rowData) => {
      return <span>{rowData?.lastModified}</span>;
    },
  },
];
