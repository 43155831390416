import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { resolveResponse } from "store/api/utils";
import { REDUCER } from "./reducer";
import { REDUCER as USER_REDUCER } from "../auth/reducer";
import ACTIONS from "./action-types";
import USER_ACTIONS from "../auth/action-types";
import {
  arrayToObjectPayload,
  serializeObject,
  setStorage,
} from "../api/utils";
import { isTrue } from "helpers/utils/validators";

const useProfile = () => {
  const { language, token, user } = useSelector(
    (state) => state[REDUCER_TYPES.AUTH]
  );
  const { profile, friends, followers } = useSelector(
    (state) => state[REDUCER_TYPES.PROFILE]
  );
  const dispatch = useDispatch();

  const headers = {
    Authorization: "Bearer " + token,
  };

  // FETCHING PROFILE DETAILS
  const getProfile = async (username) => {
    if (!username) return;
    const itsMe = user?.username === username;
    if (itsMe && !!profile) return profile;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PROFILE}/${username}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      if (itsMe) dispatch(REDUCER[ACTIONS.PROFILE](res?.data?.data));
      return res?.data?.data;
    }
  };

  // ADDING PERSONAL DETAILS TO PROFILE
  const addDetails = async (payload) => {
    if (!payload) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.PERSONAL_DETAILS}/${ENDPOINTS.ADD}?language=${language}`,
      {
        language,
        ...payload,
      },
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  // ADD PROFILE PHOTO/IMAGE TO PROFILE
  const addProfilePhoto = async (image) => {
    if (!image) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.IMAGE}?language=${language}`,
      {
        language,
        profile_image: image,
      },
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) {
      dispatch(REDUCER[ACTIONS.PROFILE](res?.data?.data));
      const newUserData = {
        ...user,
        profile_image: res?.data?.data?.profile_image,
      };
      dispatch(USER_REDUCER[USER_ACTIONS.SET_USER]({ data: newUserData }));
      setStorage(newUserData);

      return res?.data?.data;
    }
  };

  // FILE UPLOAD
  const fileUpload = async (file) => {
    if (!file) return;
    const body = {
      file,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.FILE}/${ENDPOINTS.UPLOAD}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  // ADDING EDUCATION TO PROFILE
  const addEducation = async (payload) => {
    if (!payload || !Array.isArray(payload)) return;
    const body = {
      language,
      ...arrayToObjectPayload(payload),
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.EDUCATION}/${ENDPOINTS.ADD}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  // ADDING EXPERIENCE TO PROFILE
  const addExperience = async (payload) => {
    if (!payload || !Array.isArray(payload)) return;
    const body = {
      language,
      ...arrayToObjectPayload(payload),
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.EXPERIENCE}/${ENDPOINTS.ADD}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  // ADDING CERTIFICATE TO PROFILE
  const addCertificate = async (payload) => {
    if (!payload || !Array.isArray(payload)) return;
    const body = {
      language,
      ...arrayToObjectPayload(payload),
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.CERTIFICATE}/${ENDPOINTS.ADD}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  // ADDING PATENT TO PROFILE
  const addPatent = async (payload) => {
    if (!payload || !Array.isArray(payload)) return;
    const body = {
      language,
      ...arrayToObjectPayload(payload),
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.PATENT}/${ENDPOINTS.ADD}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  // ADDING SKILLS TO PROFILE
  const addSkills = async (skills, pinned = []) => {
    const payload = {
      language,
      skill_id: [...skills, ...pinned],
      pinned: [
        ...Array(skills?.length).fill(0),
        ...Array(pinned?.length).fill(1),
      ],
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.SKILLS}/${ENDPOINTS.ADD}?language=${language}`,
      payload,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  // ADDING TAGS TO PROFILE
  const addTags = async (tags) => {
    if (!tags || !tags?.length) return;
    const payload = {
      language,
      tag_id: tags,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.TAGS}/${ENDPOINTS.ADD}?language=${language}`,
      payload,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      resolveResponse
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  // DELETE EDUCATION FROM PROFILE
  const deleteEducation = async (id) => {
    if (!id) return;
    const onSuccess = (res) => resolveResponse(res);

    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.EDUCATION}/${id}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      onSuccess
    );
  };

  // DELETE EXPERIENCE FROM PROFILE
  const deleteExperience = async (id) => {
    if (!id) return;
    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.EXPERIENCE}/${id}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      resolveResponse
    );
  };

  // DELETE CERTIFICATE FROM PROFILE
  const deleteCertificate = async (id) => {
    if (!id) return;

    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.CERTIFICATE}/${id}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      resolveResponse
    );
  };

  // DELETE PATENT FROM PROFILE
  const deletePatent = async (id) => {
    if (!id) return;

    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.PATENT}/${id}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      resolveResponse
    );
  };

  // DELETE SKILLS FROM PROFILE
  const deleteSkills = async (id) => {
    if (!id) return;
    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.SKILLS}/${id}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      resolveResponse
    );
  };

  // DELETE TAGS FROM PROFILE
  const deleteTags = async (id) => {
    if (!id) return;

    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.TAGS}/${id}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      resolveResponse
    );
  };

  //SENDING FRIEND REQUEST FROM PROFILE
  const sendFriendRequest = async (userId) => {
    if (!userId) return;
    const payload = {
      language,
      user_id: userId,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.REQUEST}/${ENDPOINTS.SEND}?language=${language}`,
      payload,
      headers,
      resolveResponse
    );
    if (res) {
      return res;
    }
  };

  //SENDING FOLLOW REQUEST FROM PROFILE
  const sendFollowRequest = async (userId) => {
    if (!userId) return;
    const payload = {
      language,
      user_id: userId,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.REQUEST}/${ENDPOINTS.FOLLOW}?language=${language}`,
      payload,
      headers,
      resolveResponse
    );
    if (res) {
      return res;
    }
  };

  //ACCEPT AND REJECT FRIEND REQUEST FROM PROFILE
  const acceptRejectFriendRequest = async (userId, isReject) => {
    if (!userId) return;
    const payload = {
      language,
      user_id: userId,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.REQUEST}/${
        isReject ? ENDPOINTS.REJECT : ENDPOINTS.ACCEPT
      }?language=${language}`,
      payload,
      headers,
      resolveResponse
    );
    if (res) {
      return res;
    }
  };

  //GET FRIENDS LIST FROM PROFILE
  const getFriendsList = async (username = null) => {
    if (friends?.length) return friends;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PROFILE}/${username ?? user?.username}/${
        ENDPOINTS.FRIENDS
      }?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      dispatch(REDUCER[ACTIONS.FRIENDS](res?.data?.data));
      return res?.data?.data;
    }
  };

  //GET FOLLOWERS LIST FROM PROFILE
  const getFollowersList = async () => {
    if (followers?.length) return friends;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PROFILE}/${user?.username}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.FOLLOW}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      dispatch(REDUCER[ACTIONS.FOLLOWERS](res?.data?.data));
      return res?.data?.data;
    }
  };

  //PENDING FRIENDS REQUEST AND PENDING FOLLOW REQUEST
  const pendingRequests = async (isFollow) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PROFILE}/${user?.username}/${ENDPOINTS.FRIENDS}/${
        ENDPOINTS.PENDING
      }${isFollow === true ? "/" + ENDPOINTS.FOLLOW : ""}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  //UNFRIEND AND UNFOLLOW FROM PROFILE
  const unfriendUnfollow = async (userId, isFollow) => {
    if (!userId) return;
    const payload = {
      language,
      user_id: userId,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.FRIENDS}/${ENDPOINTS.REQUEST}/${
        isFollow ? ENDPOINTS.UN_FOLLOW : ENDPOINTS.UN_FRIEND
      }?language=${language}`,
      payload,
      headers,
      resolveResponse
    );
    if (res) {
      return res;
    }
  };

  const unfriend = async (userId) => await unfriendUnfollow(userId);
  const unfollow = async (userId) => await unfriendUnfollow(userId, true);

  // FETCHING USER PROJECTS
  const getProjects = async (userId, filters = {}) => {
    if (!userId) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PROFILE}/${userId}/${
        ENDPOINTS.ADVANCE_SEARCH_PROJECTS
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      const { list, total_pages } = res?.data?.data;
      return [list, total_pages];
    }
    return [];
  };

  // FETCHING USER CHALLENGES
  const getChallenges = async (userId, filters = {}) => {
    if (!userId) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PROFILE}/${userId}/${
        ENDPOINTS.CHALLENGES
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      const { list, total_pages } = res?.data?.data;
      return [list, total_pages];
    }
    return [];
  };

  //UPDATE FILE PRIVACY
  const updatePrivacy = async (fileId) => {
    if (!fileId) return;
    const payload = {
      language,
      id: fileId,
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PROFILE}/${fileId}/${ENDPOINTS.UPDATE_PRIVACY}?language=${language}`,
      payload,
      headers,
      resolveResponse
    );
    if (res) {
      return res;
    }
  };

  // DELETE FILES(RESUME)
  const deleteResume = async (id) => {
    if (!id) return;
    if (!id) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.PROFILE}/${ENDPOINTS.FILE}/${ENDPOINTS.DELETE}/${id}?language=${language}`,
      headers,
      onSuccess
    );
  };

  const getDownloadProfileList = async (userId) => {
    if (!userId) return;
    const client = new REQUEST();
    const labsRes = await client.get(
      `${ENDPOINTS.PROFILE}/${userId}/${ENDPOINTS.LABS}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    const projectsRes = await client.get(
      `${ENDPOINTS.PROFILE}/${userId}/submitted-projects?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (labsRes?.data?.data || projectsRes?.data?.data) {
      return {
        labs: labsRes?.data?.data?.list,
        projects: projectsRes?.data?.data?.list,
        hasMoreLabs: isTrue(labsRes?.data?.data?.has_more),
        hasMoreProjects: isTrue(projectsRes?.data?.data?.has_more),
      };
    }
    return {
      labs: [],
      projects: [],
    };
  };

  return {
    getProfile,
    addDetails,
    addEducation,
    fileUpload,
    addProfilePhoto,
    addCertificate,
    addExperience,
    addSkills,
    addTags,
    addPatent,
    deleteEducation,
    deleteExperience,
    deleteCertificate,
    deletePatent,
    deleteSkills,
    deleteTags,
    sendFriendRequest,
    acceptRejectFriendRequest,
    sendFollowRequest,
    getFriendsList,
    getFollowersList,
    pendingRequests,
    unfriend,
    unfollow,
    getProjects,
    getChallenges,
    updatePrivacy,
    deleteResume,
    getDownloadProfileList,
  };
};

export default useProfile;
