import React, { useEffect, useMemo, useState } from "react";
import { ChartColors, ComponentMappings } from "../data";
import Card from "../../../components/Cards/Card";
import { Grid } from "@mui/material";
import Select from "../../../components/Input/Select";
import SearchableSelect from "../../../components/Input/SearchableSelect";
import DoughnutChartCard from "./chart/DoughnutChartCard";
import useChallengeReport from "../../../store/reports/challenges/service-hooks";
import { formattedCount } from "../../../helpers/utils/utilities";
import Button from "components/Button";
import useRedirect from "helpers/hooks/useRedirect";
import {REPORT_PER_PAGE} from "../../../helpers/utils/constants";

const ProgressByChallenge = (props) => {
  /**
   * PROPS
   */
  const {
    fetchChallenges,
    fetchChallengePaths,
    slug,
    noDataUI,
    t = (d) => d,
  } = props;

  /**
   * HOOKS
   */
  const { challengeMemberProgress, challengePathMemberProgress } =
    useChallengeReport();
  const { navigateChallengeReports } = useRedirect();

  /**
   * STATE
   */
  const [activeState, setActiveState] = useState(
    ComponentMappings?.all_challenges
  );
  const [components] = useState([
    {
      value: t("Challenges"),
      key: ComponentMappings?.all_challenges,
    },
    {
      value: t("Challenge Paths"),
      key: ComponentMappings?.all_challenge_paths,
    },
  ]);

  const [chartData, setChartData] = useState();
  const [loadingChallenges, setLoadingChallenges] = useState(true);
  const [loadingChartData, setLoadingChartData] = useState(false);
  const [activeChallenge, setActiveChallenge] = useState();
  const [activeChallengeSlug, setActiveChallengeSlug] = useState();

  /**
   *
   * @param search
   * @returns {Promise<*|*[]>}
   */
  const loadChallenges = async (search = "") => {
    if (slug && activeState === ComponentMappings?.all_challenges) {
      const response = await fetchChallenges(slug, 1, REPORT_PER_PAGE, search);
      //console.log(response)
      if (response?.data?.length) {
        const first = response?.data[0];
        if (first && !activeChallengeSlug) {
          setActiveChallenge({
            key: first?.slug,
            value: first?.title,
          });
          setActiveChallengeSlug(first?.slug);
        }
      }
      setLoadingChallenges(false);
      return (
        response?.data?.map((item) => {
          return {
            id: item?.slug,
            title: item?.title,
          };
        }) ?? []
      );
    }
  };

  /**
   *
   * @param search
   * @returns {Promise<*|*[]>}
   */
  const loadChallengePaths = async (search = "") => {
    if (slug && activeState === ComponentMappings?.all_challenge_paths) {
      const response = await fetchChallengePaths(slug, 1, search);
      if (response?.data?.length) {
        const first = response?.data[0];
        if (first && !activeChallengeSlug) {
          setActiveChallenge({
            key: first?.slug,
            value: first?.title,
          });
          setActiveChallengeSlug(first?.slug);
        }
      }
      setLoadingChallenges(false);
      return (
        response?.data?.map((item) => {
          return {
            id: item?.slug,
            title: item?.title,
          };
        }) ?? []
      );
    }
  };

  /**
   *
   * @returns {Promise<void>}
   */
  const fetchChartData = async () => {
    const functionMap = {
      [ComponentMappings?.all_challenges]: challengeMemberProgress,
      [ComponentMappings?.all_challenge_paths]: challengePathMemberProgress,
    };
    setLoadingChartData(true);
    if (activeChallengeSlug) {
      const response = await functionMap[activeState](activeChallengeSlug);
      if (response) {
        setChartData(response);
      }
    }
    setLoadingChartData(false);
  };

  /**
   * MEMORIZED DATA
   * @type {unknown}
   */
  const ChartData = useMemo(() => {
    if (chartData) {
      const labels = ["Completed", "In progress", "Not Started"].map((d) =>
        t(d, "common")
      );
      const statsData = [
        chartData?.completed ?? 0,
        chartData?.in_progress ?? 0,
        chartData?.not_started ?? 0,
      ];
      return {
        labels: labels,
        datasets: [
          {
            label: t("Member progress"),
            data: statsData,
            backgroundColor: ChartColors.challenges,
          },
        ],
      };
    }
    return null;
  }, [chartData]);

  const legendData = useMemo(() => {
    return {
      data: [
        {
          color: ChartColors.challenges[0],
          title: "Completed",
          count: chartData?.completed ?? 0,
        },
        {
          color: ChartColors.challenges[1],
          title: "In Progress",
          count: chartData?.in_progress ?? 0,
        },
        {
          color: ChartColors.challenges[2],
          title: "Not Started",
          count: chartData?.not_started ?? 0,
        },
        {
          title: t("Total"),
          renderer: (data) => (
            <p style={{ fontWeight: "bold" }} className={"mt-1"}>
              {data}
            </p>
          ),
          count:
            (chartData?.completed ?? 0) +
            (chartData?.in_progress ?? 0) +
            (chartData?.not_started ?? 0),
        },
      ],
      ...(activeState === ComponentMappings.all_challenges
        ? {
            submission_deadline_data: [
              {
                color: ChartColors.challenges[3],
                title: t("Late Submissions"),
                count: 0,
              },
              {
                color: ChartColors.challenges[4],
                title: t("Deadline Missed"),
                count: 0,
              },
            ],
          }
        : {}),
      total:
        (chartData?.completed ?? 0) +
        (chartData?.in_progress ?? 0) +
        (chartData?.not_started ?? 0),
    };
  }, [chartData, activeState]);

  /**
   * MOUNTED STATE
   */
  useEffect(() => {
    fetchChartData();
  }, [activeChallengeSlug]);

  /**
   * LOAD INITIAL CHALLENGES
   */
  useEffect(() => {
    loadChallenges();
  }, []);

  return (
    <>
      <Card classes={"mt-2"}>
        <h4 className="bold fs-16 mb-4">
          {t("Progress by Challenge / Challenge Path")}
        </h4>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Select
              borderedIcon
              value={activeState}
              onChange={(e) => {
                setActiveState(e.target.value);
                setActiveChallenge(null);
                setActiveChallengeSlug(null);
                setChartData(null);
                setLoadingChallenges(true);
              }}
              keyValues={components}
              width={"100%"}
              height={40}
              t={t}
            />
          </Grid>
          <Grid item xs={7}>
            {ComponentMappings?.all_challenges === activeState ? (
              <SearchableSelect
                key={activeChallengeSlug}
                width={"100%"}
                value={activeChallenge}
                placeholder={"Search for an option"}
                onChange={(event) => {
                  setActiveChallenge(event?.target?.value);
                  setActiveChallengeSlug(event?.target?.value?.key);
                }}
                fetch={[loadChallenges, ""]}
                t={t}
              />
            ) : (
              ""
            )}
            {ComponentMappings?.all_challenge_paths === activeState ? (
              <SearchableSelect
                width={"100%"}
                value={activeChallenge}
                placeholder={"Search for an option"}
                onChange={(event) => {
                  setActiveChallenge(event?.target?.value);
                  setActiveChallengeSlug(event?.target?.value?.key);
                }}
                fetch={[loadChallengePaths, ""]}
                t={t}
              />
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={12}>
            <DoughnutChartCard
              gaps={[5, 7]}
              noCard
              chartId={"challenges-member-progress"}
              data={ChartData ? ChartData : null}
              isLoading={loadingChartData || loadingChallenges}
              noDataUI={noDataUI}
              legend={
                <Grid container spacing={1}>
                  <Grid item xs={legendData?.submission_deadline_data ? 6 : 12}>
                    <ul
                      className={"flex flex-column w-full fs-11 p-0"}
                      style={{ gap: "5px" }}
                    >
                      {legendData?.data?.map((item) => (
                        <li
                          key={item?.title}
                          className={"flex w-full items-center"}
                        >
                          <div className={"flex items-center"}>
                            <div
                              className={"w-15 h-15"}
                              style={{ backgroundColor: item?.color ?? "" }}
                            ></div>
                            <span className={"ml-2"}>
                              {item?.renderer
                                ? item?.renderer(t(item?.title, "common"))
                                : t(item?.title, "common")}
                            </span>
                          </div>
                          <span className={"ml-auto mr-1"}>
                            {formattedCount(item?.count)}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </Grid>

                  {legendData?.submission_deadline_data ? (
                    <Grid item xs={6}>
                      <ul
                        className={"flex flex-column w-full fs-11 p-0"}
                        style={{ gap: "5px" }}
                      >
                        {legendData?.submission_deadline_data?.map((item) => (
                          <li
                            key={item?.title}
                            className={"flex w-full items-center"}
                          >
                            <div className={"flex items-center"}>
                              <div
                                className={"w-15 h-15"}
                                style={{ backgroundColor: item?.color ?? "" }}
                              ></div>
                              <span className={"ml-2"}>{t(item?.title)}</span>
                            </div>
                            <span className={"ml-auto"}>
                              {formattedCount(item?.count)}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </Grid>
                  ) : (
                    ""
                  )}
                 {
                  activeState ===ComponentMappings?.all_challenges && <Grid item xs={12}>
                      <Button
                        onClick={() =>
                          navigateChallengeReports(activeChallengeSlug)
                        }
                      >
                        {t("View Challenge Report")}
                      </Button>
                    </Grid>
                  }
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ProgressByChallenge;
