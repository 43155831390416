import { ACHIEVEMENT_LEVEL, ACHIEVEMENT_TYPES } from "helpers/utils/constants";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { flushSync } from "react-dom";
import { isValidUrl } from "helpers/utils/utilities";
import { capitalize } from "lodash";

export const acheivementTitle = (
  name,
  awardName,
  type,
  projectName,
  data,
  t,
  achievement
) => {
  let title = "";
  const validName = name && name.trim() ? name : "User";
  switch (type) {
    case "lab":
      title = `${t("This is to certify that")} <b>${validName}</b> ${t(
        "has completed"
      )} <b>${achievement?.associated_data?.name || awardName}</b>`;
      return title;
    case "lab-program":
      title = `${t("This is to certify that")} <b>${validName}</b> ${t(
        "has completed"
      )} <b>${achievement?.associated_data?.name || "N/A"}</b>`;
      return title;
    case "challenge":
      title = `${t("This is to certify that")} <b>${validName}</b> ${t(
        "has participated in"
      )} <b>${achievement?.challenge_title}</b> ${t("with the project")} <b>${
        achievement?.project_title ?? "N/A"
      }</b>`;
      return title;
    case "challenge-path":
      title = `${t("This is to certify that")} <b>${validName}</b> ${t(
        "has completed all challenges in"
      )} <b>${achievement?.associated_data?.name}</b>`;
      return title;
    case "resource-group":
      title = `${t("This is to certify that")} <b>${validName}</b> ${t(
        "has completed engaged and completed the resources within"
      )}<b>${achievement?.associated_data?.name}</b>`;
      return title;
    case "activity":
    case "appreciation":
      title = `${t("This is to certify that")} <b>${validName}</b> ${t(
        "has been awarded with the"
      )} <b>${awardName}</b> ${t("Award")}`;
      return title;
    case "skill-activity":
      title = `${t("This is to certify that")} <b>${validName}</b> ${t(
        "has been awarded with the"
      )} <b>${awardName}</b> ${t("Award")}`;
      return title;
    case "imported":
      title = t("PULL FROM THIRD PARTY / INPUT FIELD");
      return title;
    case "challenge-winner":
      title = t(
        "This is to certify that <b>${username}</b> has won ${awardName} with the project <b>${projectName}</b> for the <b>${challengeName}</b>",
        "achievements",
        {
          username: validName,
          projectName: achievement?.project_title,
          awardName: awardName,
          challengeName: achievement?.challenge_title,
        }
      );
      return title;
    case "challenge-participation":
      title = `${t("This is to certify that")} <b>${validName}</b> ${t(
        "has participated in"
      )} <b>${data?.name}</b>  ${t("with the project")} <b>${projectName}</b>`;
      return title;
    default:
      return "";
  }
};

export const getDetailsList = (achievement) => {
  if (!achievement || !achievement?.achievement_type) return [];

  if (achievement?.achievement_type === "activity")
    return [
      {
        title: "Description",
        value: !!achievement.description
          ? achievement.description
          : "Not provided",
      },
    ];

  if (achievement?.achievement_type === "appreciation")
    return [
      {
        title: "Recipient",
        value: achievement?.issue_to?.trim() ?? "User",
      },
      {
        title: "Issuer",
        value: achievement?.issue_by || "N/A",
      },
      {
        title: "Achievement",
        value: achievement?.microcopy || achievement?.award_name || "N/A",
      },
      {
        title: "Description",
        value: !!achievement.description ? (
          achievement.description
        ) : (
          <i className="opacity-30">Not provided</i>
        ),
      },
      {
        title: "Issued on",
        value: moment(achievement?.issue_date).format("DD/MM/YYYY"),
      },
    ];

  return [
    {
      title: "Recipient",
      value: achievement?.issue_to?.trim() ?? "User",
    },
    {
      title: "Achievement",
      value: achievement?.microcopy || achievement?.award_name || "N/A",
    },
    {
      title: "Issued by",
      value: achievement?.issue_by || "N/A",
    },
    {
      title: "Issued on",
      value: moment(achievement?.issue_date).format("DD/MM/YYYY"),
    },
  ];
};
const component_titles = {
  lab: "Lab",
  challenge: "Challenge",
  "lab-program": "Lab Program",
  "challenge-path": "Challenge Path",
  "challenge-winner": "Challenge",
  'challenge-participation': "Challenge",
  "resource-group": "Resource Group",
};

export const getAddionalDetailsList = (achievement) =>
  achievement?.achievement_type === "activity"
    ? [
        [
          {
            title: "Issued By",
            // value: achievement?.issuer_username || "N/A",
            value: "",
          },
          {
            title: "Organization Name",
            value: achievement?.issue_by || "N/A",
          },
          {
            title: "Achievement",
            value: achievement?.award_name || "N/A",
          },
          {
            title: "Issued On",
            value: moment(achievement?.issue_date).format("DD/MM/YYYY"),
          },
        ],
        null,
      ]
    : [
        [
          {
            title: "Issued By",
            // value: achievement?.issuer_username || "N/A",
            value: "",
          },
          {
            title: "",
            value: achievement?.issue_by || "N/A",
          },
          {
            title: "",
            value: achievement?.issuer_link || "N/A",
            isLink: isValidUrl(achievement?.issuer_link),
            styles: "mt-5",
          },
        ],
        !achievement?.associated_data ||
        Array.isArray(achievement?.associated_data)
          ? null
          : [
              {
                title: "Associated Links",
                value: "",
                styles: "mb-5",
              },
              {
                title: "Project",
                value: achievement?.project_title || "Not Available",
                isAssociated: true,
                hidden: Boolean(!achievement?.project_title),
                isLink: Boolean(achievement.project_slug),
                link: achievement.project_slug,
              },
              {
                title: component_titles[achievement?.achievement_type],
                value: achievement?.associated_data?.name || "N/A",
                isAssociated: true,
                hidden: !component_titles[achievement?.achievement_type],
                isLink: !!achievement?.level,
                link: achievement?.associated_data?.associated_link,
              },
            ],
      ];

export const getAchievementTypeKey = (value) => {
  if (value?.includes("winner")) return "Winner Award";
  let type = ACHIEVEMENT_TYPES.find((type) => type.key === value)?.value;
  if (type) return type;
  type = ACHIEVEMENT_LEVEL.find((type) => type?.key === value)?.value;
  if (type) return `${type} Award`;
  if (value) return `${capitalize(value)} Award`;
};

export const getDownloadOptions = (id, download) => [
  {
    title: "Download PDF",
    onClick: () => download(id, "pdf"),
  },
  {
    title: "Download PNG",
    onClick: () => download(id, "image"),
  },
];

export const downloadPdf = async ({ certificateRef, awardName, setTab }) => {
  flushSync(() => {
    setTab(0);
  });
  const canvas = await html2canvas(certificateRef.current);
  const image = canvas.toDataURL("images/png");
  const pdf = new jsPDF("landscape", "pt", [
    certificateRef.current.offsetWidth,
    certificateRef.current.offsetHeight,
  ]);
  pdf.addImage(
    image,
    "PNG",
    0,
    0,
    certificateRef.current.offsetWidth,
    certificateRef.current.offsetHeight
  );
  pdf.save(`${awardName}.pdf`);
};

export const downloadPng = async ({ certificateRef, awardName, setTab }) => {
  flushSync(() => setTab(0));
  const canvas = await html2canvas(certificateRef.current);
  var link = document.createElement("a");
  document.body.appendChild(link);
  link.download = `${awardName}.png`;
  link.href = canvas.toDataURL("images/png");
  link.target = "_blank";
  link.click();
  document.body.removeChild(link);
};
