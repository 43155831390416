import { AddBox, Delete } from "@mui/icons-material";
import { Box, Grid, Popper } from "@mui/material";
import Button from "components/Button";
import React, { useState } from "react";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import { ReactComponent as TooltipIcon } from "assets/svg/gray/tooltip.svg";
import { LockIcon } from "components/Statics";
import { REQUIRED_VALIDATOR } from "helpers/utils/validators";
import UpgradePlanPopup from "./UpgradePlanPopup";

export const achievement_schema = (isRequired = false) => [
  {
    title: "",
    name: "achievement_image",
    description: "",
    type: "file",
    dropboxProps: {
      width: "98%",
    },
    lg: 12,
  },
  {
    title: "Achievement Name",
    name: "achievement_name",
    type: "input",
    lg: 12,
    ...(isRequired && { validator: REQUIRED_VALIDATOR }),
  },
  {
    title: "Participation prize",
    description: "If there is no prize, leave this field blank",
    name: "achievement_prize",
    type: "input",
    lg: 12,
  },
  {
    title: "Participation points",
    description:
      "The number of points participants will receive when they finish. Suggested: 100/250/500/1000/2500 depending on Challenge difficulty",
    name: "achievement_points",
    type: "input",
    limit: 4,
    inputProps: {
      type: "number",
    },
    lg: 12,
  },
  {
    title: "",
    name: "achievement_enabled",
    type: "switch",
    lg: 12,
  },
];

export const winner_achievement_schema = [
  {
    title: "",
    name: "winner_achievement_image",
    description: "",
    type: "file",
    dropboxProps: {
      width: "98%",
    },
    lg: 12,
  },
  {
    title: "Achievement Name winner",
    name: "winner_achievement_name",
    type: "input",
    lg: 12,
  },
  {
    title: "Prize",
    description: "If there is no prize, leave this field blank",
    name: "winner_achievement_prize",
    type: "input",
    lg: 12,
  },
  {
    title: "Points",
    description:
      "The number of points participants will receive when they finish. Suggested: 100/250/500/1000/2500 depending on Challenge difficulty",
    name: "winner_achievement_points",
    type: "input",
    inputProps: {
      type: "number",
    },
    limit: 4,
    lg: 12,
  },
];

export const achievementSlice = (schema = []) => [
  schema.findIndex((d) => d.name === "achievement_image"),
  schema.findIndex((d) => d.name === "achievement_points") + 1,
];

const AddAchievementSection = ({
  title,
  data,
  errors,
  onChange = Function.prototype,
  showWinnerAchievements,
  winnerAchievements,
  setWinnerAchievements,
  isProjectAlreadyPublished,
  noPlan,
  t = (d) => d,
}) => {
  const [planPopupOpen, setPlanPopupOpen] = useState(false);
  const addWinnerAchievement = () => {
    if (winnerAchievements.length >= 5) return;
    setWinnerAchievements((val) => [...val, {}]);
  };

  const onChangeWinnerAch = (e, index) => {
    const { name, value } = e.target;
    winnerAchievements[index][name] = value;
    setWinnerAchievements([...winnerAchievements]);
  };

  const deleteWinnerAchievement = (index) => {
    winnerAchievements.splice(index, 1);
    setWinnerAchievements([...winnerAchievements]);
  };

  const isSectionDisabled = noPlan || isProjectAlreadyPublished;

  return (
    <>
      <UpgradePlanPopup
        open={planPopupOpen}
        onClose={() => setPlanPopupOpen(false)}
      />
      <div
        className={`${
          isSectionDisabled ? "opacity-50" : "opacity-100"
        } divide-y-darkgray`}
        onClick={() => noPlan && setPlanPopupOpen(true)}
      >
        <div style={{ pointerEvents: isSectionDisabled && "none" }}>
          <h3>
            {title || t("Add Completion Achievement")}
            {isSectionDisabled && <LockIcon />}
          </h3>
          <p className="fs-12 pb-4 opacity-60 ">
            {t(
              "The achievement all participants receive upon completing the Challenge. The image dimension is at least 200 x 200 pixels."
            )}
          </p>
          {data.achievement_enabled && (
            <Achievement
              schema={achievement_schema(
                data.achievement_enabled ? true : false
              )}
              onChange={onChange}
              data={data}
              isRequired
              errors={errors}
              isProjectAlreadyPublished={
                isSectionDisabled || !data.achievement_enabled
              }
            />
          )}
          <div className="absolute top-0 right-0">
            <SchemaUIExtractor
              schema={achievement_schema().slice(4, 5)}
              data={data}
              errors={errors}
              onChange={onChange}
            />
          </div>
        </div>
        {data.achievement_enabled && (
          <div>
            <h3 className="mt-4">
              {t("Add winner achievement")}
              {isSectionDisabled && <LockIcon />}
            </h3>
            <p className="fs-12 opacity-60 mb-2">
              {t(
                "The achievement awarded to selected participants for meeting specific criteria (ex. First place, Second place, best design, etc)"
              )}
            </p>
            <div className="divide-y-darkgray">
              {winnerAchievements.map((a, i) => (
                <WinnerAchievement
                  schema={winner_achievement_schema}
                  key={i}
                  onChange={(val) => onChangeWinnerAch(val, i)}
                  data={a}
                  errors={errors}
                  index={i}
                  deleteWinnerAchievement={deleteWinnerAchievement}
                  isProjectAlreadyPublished={isSectionDisabled}
                  t={t}
                />
              ))}
            </div>

            {showWinnerAchievements && (
              <Button
                disabled={isSectionDisabled}
                onClick={addWinnerAchievement}
              >
                <AddBox />
                &nbsp;&nbsp;{t("Add Winner Achievement")}
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AddAchievementSection;

const Achievement = ({
  schema,
  onChange,
  data,
  errors,
  isProjectAlreadyPublished,
}) => {
  const names = [
    "achievement_image",
    "achievement_name",
    "achievement_prize",
    "achievement_points",
  ];
  const updatedSchema = schema.map((s) => {
    if (names.includes(s.name)) {
      return {
        ...s,
        disabled: isProjectAlreadyPublished,
      };
    }
    return s;
  });

  return (
    <Grid container columnSpacing={2} className="relative">
      <Grid item xs={12} lg={3}>
        <SchemaUIExtractor
          schema={updatedSchema.slice(0, 1)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} lg={9}>
        <SchemaUIExtractor
          schema={updatedSchema.slice(1, 4)}
          data={data}
          errors={errors}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

const WinnerAchievement = ({
  schema,
  onChange,
  data,
  errors,
  index,
  deleteWinnerAchievement,
  isProjectAlreadyPublished,
  t = (d) => d,
}) => {
  const updatedSchema = schema.map((s) => {
    if (
      s.name === "winner_achievement_image" ||
      s.name === "winner_achievement_name" ||
      s.name === "winner_achievement_prize" ||
      s.name === "winner_achievement_points" ||
      s.name === "winner_achievement_description"
    ) {
      return {
        ...s,
        disabled: isProjectAlreadyPublished,
      };
    }
    return s;
  });
  return (
    <div>
      <Grid container columnSpacing={2} className="relative">
        <Grid item xs={12} lg={3}>
          <SchemaUIExtractor
            schema={updatedSchema.slice(0, 1)}
            data={data}
            errors={errors}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} lg={9}>
          <SchemaUIExtractor
            schema={updatedSchema.slice(1, 4)}
            data={data}
            errors={errors}
            onChange={onChange}
          />
        </Grid>
      </Grid>
      {
        <div className="w-100p mb-3 bottom-0 flex justify-end items-right">
          <Button
            width="85px"
            height="34px"
            classes="fs-12 mr-1"
            color="red"
            bordered
            onClick={() => deleteWinnerAchievement(index)}
            disabled={isProjectAlreadyPublished}
          >
            {" "}
            <Delete fontSize="small" />
            {t("Delete")}
          </Button>
        </div>
      }
    </div>
  );
};

export const HelperTextPopup = ({ content }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  return (
    <div style={{ lineHeight: 1 }}>
      <TooltipIcon className="w-28 h-28" onClick={handleClick} />
      <Popper open={open} placement="top" id="helper" anchorEl={anchorEl}>
        <Box className="border bg-white w-350 shadow px-4 py-2 rounded-5">
          {content}
        </Box>
      </Popper>
    </div>
  );
};
