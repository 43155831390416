import { CheckCircle, PlayCircleFilled, PauseCircleFilled, VolumeUp, Download } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Slider from "@mui/material/Slider";
import ModalLayout from "components/Modal";
import useAudio from "helpers/hooks/useAudio";

const Audio = ({
  title,
  audio,
  onDelete,
  classes,
  isCompleted,
  onPlay,
  readOnly,
  isEmbedded,
  onDownload,
  isViewAccessEnabled
}) => {
  const [hover, setHover] = useState(false);
  const [openEmbedMediaModal, setOpenEmbedMediaModal] = useState(false);

  const { playing, progress, togglePlayPause, seek, audioRef } = useAudio(audio, onPlay);

    useEffect(() => {
      return () => {
        const audioElement = audioRef.current;
        if (audioElement) {
          audioElement.pause(); 
          audioElement.currentTime = 0;
        }
      };
    }, [audioRef]);
  
  return (
    <>
      <ModalLayout open={openEmbedMediaModal} onClose={() => setOpenEmbedMediaModal(false)}>
        <div dangerouslySetInnerHTML={{ __html: audio }} style={{ height: "100%", width: "100%", margin: "20px 0" }} />
      </ModalLayout>

      <div
        className={`${isEmbedded ? "flex items-center flex-wrap" : "flex items-center"} mt-1 h-30 ${classes}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <h3 className={`mx-3 flex-center fs-18 ${isCompleted ? "c-success" : "c-black"}`}>
          {isCompleted && <CheckCircle fontSize="small" className="mr-1 c-success" />}
          {title}
        </h3>

        {playing ? (
          <PauseCircleFilled className="cursor-pointer" onClick={togglePlayPause} />
        ) : (
          <PlayCircleFilled className="cursor-pointer" onClick={togglePlayPause} />
        )}

        <Slider
          size="medium"
          value={progress}
          onChange={(_, newValue) => seek(newValue)}
          aria-label="progress-slider"
          valueLabelDisplay="off"
          sx={{ width: "100%", ml: 2 }} // Adjust width as needed
        />
        <div className="ml-auto cursor-pointer">
          {!readOnly && hover && <DeleteIcon color="error" onClick={onDelete} />}
        </div>

        {isViewAccessEnabled && !readOnly && hover && (
          <div className="ml-auto cursor-pointer">
            <Download onClick={onDownload} />
          </div>
        )}
      </div>
    </>
  );
};

export default Audio;
