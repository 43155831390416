import React, { useEffect, useState } from "react";
import ChallengeListing from "./ChallengeListing";
import useChallenges from "store/challenges/service-hook";
import useFilters from "helpers/hooks/useFilters";
import BrowseWrapper from "components/UI/organisms/BrowseWrapper";
import useListingHeader from "helpers/hooks/useListingHeader";
import { FILTER_TYPES } from "helpers/hooks/useFilters";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

export const browse_options = [
  {
    id: 1,
    title: "All",
    pageTitle: "Browse Challenge & Challenge Paths",
  },
  { 
    id: 2,
    title: "My Challenges & Paths",
    pageTitle: "My Challenges & Paths"
  },
  {
    id: 3,
    title: "Pending Invitations",
    pageTitle: "Browse Pending Challenge Invitations",
  },
  {
    id: 4,
    title: "Saved",
    pageTitle: "Browse Saved Challenges",
  },
];

function getEmptyMessage(browseMode) {
  switch (browseMode) {
    case 0:
      return "No challenge found";
    case 1:
      return "No challenge found";
    case 2:
      return "No pending invitations!";
    default:
      return "No challenges found";
  }
}

const BrowseChallenges = () => {
  const [browseMode, setBrowseMode] = useState(0);
  const state = useListingHeader();
  const [challenges, setChallenges] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { fetchChallenges } = useChallenges();
  const { selected } = useFilters();
  const [pendingCount, setPendingCount] = useState(0);
  const { t } = useLocalizedTranslation("challenge");
  useEffect(() => {
    const abortController = new AbortController();
    handleFetchChallenges(abortController);
    return () => abortController.abort();
  }, [page, selected, state.sort, state.search, browseMode]);

  const handleFetchChallenges = async (abortController) => {
    setLoading(true);
    const filters = {
      ...selected,
      page,
      sort_by: state.sort,
      search: state.search,
    };
    if (selected?.multi_type) filters.type = selected.multi_type;
    delete filters.multi_type;
    if (browseMode === 3) {
      filters.social_type = "favourites";
    }
    if (browseMode === 2) {
      filters.request_status = "pending";
    }
    if (browseMode === 1) {
      filters.request_status = "accepted";
    }
    const [list, count] = await fetchChallenges(filters);
    if (!abortController.signal.aborted) {
      setChallenges(list || []);
      setPages(count || 0);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPending();
  }, [browseMode, page]);

  const fetchPending = async () => {
    const filters = {
      page: 1,
      request_status: "pending",
    };
    const [, , total_count] = await fetchChallenges(filters);
    setPendingCount(total_count || 0);
  };

  const newBrowseOptions = browse_options.map((d) => {
    if (d.id === 3) {
      return {
        ...d,
        title: `${t("Pending Invitations")} (${pendingCount})`,
      };
    }
    return d;
  });

  return (
    <BrowseWrapper
      browseMode={browseMode}
      {...state}
      browseOptions={newBrowseOptions}
      setBrowseMode={setBrowseMode}
      noAction
      filters={[
        FILTER_TYPES.COMPONENT_TYPE,
        FILTER_TYPES.MULTI_TYPE,
        FILTER_TYPES.DURATION,
        FILTER_TYPES.LEVEL,
        FILTER_TYPES.SKILLS,
        FILTER_TYPES.PRIVACY,
      ]}
    >
      <ChallengeListing
        browseMode={browseMode}
        gridType={state.gridType}
        challenges={challenges}
        loading={loading}
        page={page}
        setPage={setPage}
        pageCount={pages}
        setChallenges={setChallenges}
        emptyMessage={t(getEmptyMessage(browseMode))}
      />
    </BrowseWrapper>
  );
};

export default BrowseChallenges;
