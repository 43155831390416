import { render, waitFor } from "@testing-library/react";
import { mockRequestGet } from "../../mocks/request";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { initStore } from "../../mocks/store";
import { REDUCER_TYPES } from "../../index";
import userEvent from "@testing-library/user-event";
import { ENDPOINTS } from "store/api/endpoints";
import useOrganizationReport from "./service-hook";

let getSpy;

let challengeId = "advanced-technology-development-center-atdc";

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com" },
  },
  [REDUCER_TYPES.MASTER]: {},
});

const ReportsOrganizationServicesMock = () => {
  const {
    organizationMemberProgress,
    organizationReportDetails,
    organizationLabs,
    organizationLabPrograms,
    organizationChallenges,
    organizationChallengePaths,
    organizationResourceModules,
    organizationResourceGroup,
    organizationResourceCollection,
    organizationEngagements,
    organizationMembers,
    organizationMemberActivity,
    downloadOrganizationReport,
  } = useOrganizationReport();

  const BUTTON_LINKS = [
    {
      name: "organizationMemberProgress",
      onClick: () => organizationMemberProgress(challengeId),
    },
    {
      name: "organizationReportDetails",
      onClick: () => organizationReportDetails(challengeId),
    },
    {
      name: "organizationLabs",
      onClick: () => organizationLabs(challengeId, 1, 10, ""),
    },
    {
      name: "organizationLabPrograms",
      onClick: () => organizationLabPrograms(challengeId, 1, 10, ""),
    },
    {
      name: "organizationChallenges",
      onClick: () => organizationChallenges(challengeId, 1, 10, ""),
    },
    {
      name: "organizationChallengePaths",
      onClick: () => organizationChallengePaths(challengeId, 1, ""),
    },
    {
      name: "organizationResourceModules",
      onClick: () => organizationResourceModules(challengeId, 1, 10, ""),
    },
    {
      name: "organizationResourceGroup",
      onClick: () => organizationResourceGroup(challengeId, 1, 10, ""),
    },
    {
      name: "organizationResourceCollection",
      onClick: () => organizationResourceCollection(challengeId, 1, 10, ""),
    },
    {
      name: "organizationEngagements",
      onClick: () => organizationEngagements(challengeId),
    },
    {
      name: "organizationMembers",
      onClick: () => organizationMembers(challengeId, 1),
    },
    {
      name: "organizationMemberActivity",
      onClick: () => organizationMemberActivity(challengeId),
    },
    {
      name: "downloadOrganizationReport",
      onClick: () => downloadOrganizationReport(challengeId),
    },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Reports ORganization services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <ReportsOrganizationServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
  });

  test("organizationMemberProgress", async () => {
    const button = screen.getByTestId("organizationMemberProgress");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${challengeId}/${ENDPOINTS.MEMBER_PROGRESS}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("organizationReportDetails", async () => {
    const button = screen.getByTestId("organizationReportDetails");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${challengeId}/details?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("organizationLabs", async () => {
    const button = screen.getByTestId("organizationLabs");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${challengeId}/${ENDPOINTS.LABS}?language=en&keyword=&page=1&per_page=10`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("organizationLabPrograms", async () => {
    const button = screen.getByTestId("organizationLabPrograms");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${challengeId}/${ENDPOINTS.LAB_PROGRAMS}?language=en&keyword=&page=1&per_page=10`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("organizationResourceModules", async () => {
    const button = screen.getByTestId("organizationResourceModules");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${challengeId}/${ENDPOINTS.RESOURCE_MODULES}?language=en&keyword=&page=1&per_page=10`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("organizationResourceGroup", async () => {
    const button = screen.getByTestId("organizationResourceGroup");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${challengeId}/${ENDPOINTS.RESOURCE_GROUPS}?language=en&keyword=&page=1&per_page=10`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("organizationResourceCollection", async () => {
    const button = screen.getByTestId("organizationResourceCollection");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${challengeId}/${ENDPOINTS.RESOURCE_COLLECTIONS}?language=en&keyword=&page=1&per_page=10`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("organizationEngagements", async () => {
    const button = screen.getByTestId("organizationEngagements");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${challengeId}/${ENDPOINTS.ENGAGEMENT}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("organizationMembers", async () => {
    const button = screen.getByTestId("organizationMembers");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${challengeId}/${ENDPOINTS.ORGANIZATION_MEMBERS}?language=en&keyword=&page=1`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("organizationMemberActivity", async () => {
    const button = screen.getByTestId("organizationMemberActivity");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.ORGANIZATION}/${challengeId}/${ENDPOINTS.MEMBER_ACTIVITY}?language=en`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });
});
