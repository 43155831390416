import { Avatar, Checkbox, Chip, Stack } from "@mui/material";
import Button from "components/Button";
import ModalLayout from "components/Modal";
import useRedirect from "helpers/hooks/useRedirect";
import { isTrue } from "helpers/utils/validators";
import React from "react";
import "../achievements.module.scss";
import goldCupTrophy from "assets/png/goldTrophy.png";
import ShareModal from "components/UI/organisms/ShareModal";
import { COMPONENT_TYPES } from "helpers/utils/constants";

const AchievementShareModal = ({
  open,
  onClose,
  achievement: data,
  setFeatured,
  t = (d) => d,
}) => {
  const { navigateAchievement } = useRedirect();

  return (
    <ModalLayout light open={open} onClose={onClose} width="min(450px,96%)">
      <div className="column-center pb-6">
        <div className="w-full text-left">
          <Checkbox
            size="small"
            defaultChecked={isTrue(data?.is_featured)}
            onChange={(e) => setFeatured(data?.id, !e.target.checked)}
          />
          <small className="tracking-tight fw-500 mr-1">
            {t("Feature on my profile")}
          </small>
        </div>
        <Avatar src={data?.achievement_image} className="border w-160 h-160">
          <img
            className="object-cover border bg-lightgray px-1 py-1 w-full h-full"
            src={goldCupTrophy}
            alt="achievement_image"
          />
        </Avatar>
        <h3 className="link">{data?.title}</h3>
        <p className="fw-500">{data?.module_title}</p>
        <p className="fs-20 fw-600 c-primary">{data?.microcopy || "N/A"}</p>
        <Chip className="fw-500 mt-2" label={`${data?.award_name}`} />
        <h3 className="text-left w-90p fs-14 mt-2">{t("Share")}</h3>
        <ShareModal
          open
          component={COMPONENT_TYPES.ACHIEVEMENT}
          noModal
          slug={data?.id}
        />
        {window.location.pathname !== `/achievements/${data?.id}` && (
          <Button classes="mt-4" onClick={() => navigateAchievement(data?.id)}>
            {t("View Achievement Page")}
          </Button>
        )}
      </div>
    </ModalLayout>
  );
};

export default AchievementShareModal;
