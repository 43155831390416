import { Tab, Tabs } from "@mui/material";
import React from "react";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { COLORS } from "helpers/utils/constants";

const NavTabs = ({ value, list, onChange, noBg, top, classes, tabClasses }) => {
  const { t } = useLocalizedTranslation();

  return (
    <Tabs
      value={value}
      className={`${top ? `mt-${top}` : "mt-4"} ${classes}`}
      onChange={(e, val) => onChange(val)}
      sx={{
        backgroundColor: "transparent",
        mt: top ? top : 4,
        "& .MuiTabs-indicator": {
          display: !noBg && "none",
          borderColor: COLORS.BLUE,
        },
      }}
      textColor="primary"
      variant="fullWidth"
      data-testid="nav-tabs"
    >
      {list?.map((d) => (
        <Tab
          label={t(d)}
          key={d}
          className={`${tabClasses} text-nowrap`}
          sx={{
            backgroundColor: !noBg && COLORS.BG_GRAY,
            color: COLORS.DARK_GRAY,
            borderColor: COLORS.GRAY,

            "&.Mui-selected": {
              backgroundColor: !noBg && COLORS.BLUE_OPAQUE,
              color: COLORS.BLUE,
            },
          }}
        />
      ))}
    </Tabs>
  );
};

export default NavTabs;
