import {
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Pagination,
} from "@mui/material";
import OnlineAvatar from "components/UI/atoms/OnlineAvatar";
import NavTabs from "components/UI/molecules/NavTabs";
import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import Button from "components/Button";
import DoneIcon from "@mui/icons-material/Done";
import ExtensionIcon from "@mui/icons-material/Extension";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ScienceIcon from "@mui/icons-material/Science";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import useNotifications from "store/notifications/service-hook";
import { LEARNING_POINTS_TYPES, NOTIFICATION_TYPES } from "../data";
import useProfile from "store/profile/service-hook";
import moment from "moment";

const NotificationList = ({ t = (d) => d }) => {
  const [notificationType, setNotificationType] = useState(0);
  const [page, setPage] = useState(1);
  const [showDelete, setShowDelete] = useState({});
  const [allNotifications, setAllNotifications] = useState({
    total: 0,
    0: {
      Icon: NotificationsIcon,
      message: "You have no notifications to review",
      data: [],
    },
    1: {
      Icon: ExtensionIcon,
      message: "You have no challenge notifications to review",
      data: [],
    },
    2: {
      Icon: ScienceIcon,
      message: "You have no lab notifications to review",
      data: [],
    },
    3: {
      Icon: LocalPoliceIcon,
      message: "You have no Learning Point History to review",
      data: [],
    },
    4: {
      Icon: LocalPoliceIcon,
      message: "You have no Friend Requests to review",
      data: [],
    },
  });
  const { fetchNotifications, readNotifications, deleteNotifications } =
    useNotifications();
  const { acceptRejectFriendRequest } = useProfile();

  const calculateTimePassedOfNotification = (date) =>
    Math.floor((new Date() - new Date(date)) / 1000 / 60);

  const filterNotification = (data, type) =>
    data?.filter(
      (notification) => notification?.type === NOTIFICATION_TYPES[type]
    );

  const getNotifications = async () => {
    const [
      data,
      totalPages,
      totalCount,
      per_page,
      unread_notifications_count,
      unread_notifications,
    ] = await fetchNotifications({
      type: NOTIFICATION_TYPES[notificationType],
      page,
    });
    let notifications = { ...allNotifications },
      filteredNotifications = [];
    const showAllNotification = notificationType === 0;
    // Challenges
    if (notificationType === 1 || showAllNotification) {
      filteredNotifications = filterNotification(data, 1);
      const challenges = filteredNotifications?.map((challenge) => ({
        id: challenge?.id,
        userName: challenge?.data?.invitation_from?.full_name,
        message: `${t("has invited you to join")} ${
          challenge?.data?.challenge?.title
        } ${t("Challenge")}`,
        image: challenge?.data?.invitation_from?.profile_image,
        created_at: challenge?.created_at,
      }));
      notifications = {
        ...notifications,
        1: { ...notifications[1], data: challenges },
      };
      if (showAllNotification)
        notifications = {
          ...notifications,
          0: { ...notifications[0], data: [...challenges] },
        };
    }

    // Labs
    if (notificationType === 2 || showAllNotification) {
      filteredNotifications = filterNotification(data, 2);
      const lab = filteredNotifications?.map((lab) => ({
        id: lab?.id,
        userName: lab?.data?.invitation_from?.full_name,
        message: `${t("has invited you to join")} ${lab?.data?.lab?.title} ${t(
          "Lab"
        )}`,
        image: lab?.data?.invitation_from?.profile_image,
        created_at: lab?.created_at,
      }));
      notifications = {
        ...notifications,
        2: { ...notifications[2], data: lab },
      };
      if (showAllNotification)
        notifications = {
          ...notifications,
          0: { ...notifications[0], data: [...notifications[0]?.data, ...lab] },
        };
    }

    // Learning Points
    if (notificationType === 3 || showAllNotification) {
      filteredNotifications = filterNotification(data, 3);
      const learning = filteredNotifications?.map((learning) => ({
        id: learning?.id,
        userName:
          LEARNING_POINTS_TYPES[learning?.data?.learning_point_type]?.awardName,
        message: `${t("You received")} ${
          learning?.data?.learning_points_obtained
        } ${t("Points for")} ${
          LEARNING_POINTS_TYPES[learning?.data?.learning_point_type]?.awardFor
        }`,
        created_at: learning?.created_at,
      }));
      notifications = {
        ...notifications,
        3: { ...notifications[3], data: learning },
      };
      if (showAllNotification)
        notifications = {
          ...notifications,
          0: {
            ...notifications[0],
            data: [...notifications[0]?.data, ...learning],
          },
        };
    }

    // Friend Requests
    if (notificationType === 4 || showAllNotification) {
      filteredNotifications = filterNotification(data, 4);
      const request = filteredNotifications?.map((request) => ({
        id: request?.id,
        userName: request?.data?.request_from?.full_name,
        message: `${t("wants to add you as friend")}`,
        image: request?.data?.request_from?.profile_image,
        requestStatus: request?.data?.friend_request_status,
        type: "friend_request",
        userId: request?.data?.request_from?.id,
        created_at: request?.created_at,
      }));
      notifications = {
        ...notifications,
        4: { ...notifications[4], data: request },
      };
      if (showAllNotification)
        notifications = {
          ...notifications,
          0: {
            ...notifications[0],
            data: [...notifications[0]?.data, ...request],
          },
        };
    }

    // Organization
    if (showAllNotification) {
      filteredNotifications = filterNotification(data, 5);
      const organization = filteredNotifications?.map((organization) => ({
        id: organization?.id,
        userName: organization?.data?.invitation_from?.full_name,
        message: `${t("has invited you to join an organization as")} ${
          organization?.data?.role
        } ${t("on the")} ${organization?.data?.organization?.title}`,
        created_at: organization?.created_at,
      }));
      notifications = {
        ...notifications,
        0: {
          ...notifications[0],
          data: [...notifications[0]?.data, ...organization],
        },
        5: { ...notifications[5], data: organization },
      };
    }

    if (showAllNotification)
      handleReadNotifications(notifications[0]?.data?.map((n) => n?.id));

    setAllNotifications({
      ...notifications,
      unread_notifications: unread_notifications,
      totalPages,
      totalCount,
      per_page,
    });
  };

  const handleReadNotifications = async (ids) => {
    await readNotifications({ notification_ids: ids });
  };

  const handleFriendRequest = async (ids, isAccepted) => {
    await acceptRejectFriendRequest(ids, !isAccepted);
    getNotifications();
  };

  const handleDeleteNotification = async (id) => {
    await deleteNotifications(id);
    getNotifications();
  };

  useEffect(() => {
    getNotifications();
  }, [notificationType, page]);

  return (
    <Grid
      item
      xs={0}
      md={4}
      display="flex"
      direction="column"
      justifyContent="space-between"
      className="h-full items-stretch relative"
    >
      <div className="flex justify-between items-center mx-4">
        <NavTabs
          noBg
          list={[
            `${t("ALL NOTIFICATIONS")} (${
              allNotifications?.unread_notifications
                ?.total_unread_notifications || 0
            })`,
            `${t("CHALLENGES")} (${
              allNotifications?.unread_notifications
                ?.challenge_unread_notifications || 0
            })`,
            `${t("LABS")} (${
              allNotifications?.unread_notifications
                ?.lab_unread_notifications || 0
            })`,
            `${t("Learning Points")} (${
              allNotifications?.unread_notifications
                ?.learning_point_unread_notifications || 0
            })`,
            `${t("Friend Requests")} (${
              allNotifications?.unread_notifications
                ?.friend_request_unread_notifications || 0
            })`,
          ]}
          top={1}
          value={notificationType}
          onChange={setNotificationType}
          tabClasses="tracking-tight px-2 minw-200"
        />
      </div>
      <div className="overflowY-auto flex-1 pt-2 relative">
        {allNotifications[notificationType]?.data?.length === 0 ? (
          <div className="flex-column justify-center items-center absolute top-0 bottom-0 w-full">
            <div
              style={{
                width: "fit-content",
                borderRadius: "1000px",
                padding: 16,
                background: "#D9D9D9",
              }}
            >
              <Icon
                sx={{ fontSize: "60px", color: "white" }}
                component={allNotifications[notificationType]?.Icon}
              />
            </div>
            <p className="fs-12 opacity-50 mt-4">
              {t(allNotifications[notificationType]?.message)}
            </p>
          </div>
        ) : (
          <List disablePadding dense={true} className="pb-4 px-4">
            {allNotifications[notificationType]?.data?.map((notification, i) => (
              <ListItem
                key={i}
                className={`py-2 cursor-pointer border-b`}
                sx={{
                  "&:hover": {
                    background: "#F3F4F8",
                  },
                }}
                onMouseOver={() => setShowDelete({ [notification.id]: true })}
                onMouseOut={() => setShowDelete({})}
                secondaryAction={
                  notification?.type === "friend_request" && (
                    <div className="flex gap-10 items-center">
                      {notification?.requestStatus === "pending" ? (
                        <>
                          <Button
                            onClick={() =>
                              handleFriendRequest(notification?.userId, true)
                            }
                          >
                            {t("Accept")}
                          </Button>
                          <Button
                            bordered
                            onClick={() =>
                              handleFriendRequest(notification?.userId, false)
                            }
                          >
                            {t("Decline")}
                          </Button>
                        </>
                      ) : notification?.requestStatus === "accepted" ? (
                        <>
                          <DoneIcon className="c-success" fontSize="small" />
                          <p className="c-success fw-700">{t("Accepted")}</p>
                        </>
                      ) : (
                        <>
                          <CloseIcon fontSize="small" className="c-error" />
                          <p className="c-error fw-700">{t("Declined")}</p>
                        </>
                      )}
                    </div>
                  )
                }
              >
                <ListItemIcon
                  sx={{
                    minWidth: 40,
                    visibility: showDelete[notification.id]
                      ? "visible"
                      : "hidden",
                  }}
                  onClick={() => handleDeleteNotification(notification?.id)}
                >
                  <CloseIcon />
                </ListItemIcon>
                <ListItemAvatar>
                  <OnlineAvatar
                    style={{ width: 60, height: 60 }}
                    notOnline={true}
                    src={notification?.image ?? AccountCircleIcon}
                  />
                </ListItemAvatar>
                <ListItemText
                  className="ml-5"
                  primary={
                    <p className="fw-500 opacity-90 text-nowrap">
                      {notification?.userName}
                      <span className="fs-14 text-wrap fw-400 ml-1 opacity-50 text-truncate">
                        {notification?.message}
                      </span>
                    </p>
                  }
                  secondary={
                    <p className="fs-12 opacity-70 w-70p text-truncate pt-1">
                      {moment(notification?.created_at).fromNow()}
                    </p>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </div>
      <div className="flex items-center justify-between h-50 mx-4">
        <p className="fs-14">
        <b>
            {page === 1 ? 1 : (page - 1) * 10 + 1} -{" "}
            {page === allNotifications.totalPages
              ? allNotifications.totalCount
              : Math.min(page * 10, allNotifications.totalCount)}
          </b>{" "}
          of <b>{allNotifications?.totalCount}</b>
        </p>
        <Pagination
          classes={{
            ul: "justify-end",
          }}
          size={"small"}
          count={allNotifications?.totalPages}
          onChange={(e, newValue) => {
            setPage(newValue);
          }}
          page={page}
          perPage={8}
        />
      </div>
    </Grid>
  );
};

export default NotificationList;
