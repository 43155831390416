import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { resolveResponse } from "store/api/utils";
import { serializeObject } from "store/api/utils";

const useLabMarketplace = () => {
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  const headers = {
    Authorization: "Bearer " + token,
  };
  
  //LISTING OF LAB MARKETPLACE
  const list = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${
        ENDPOINTS.LAB_MARKETPLACE
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };
  
  //SHOW LAB MARKETPLACE
  const show = async (slug) => {
    if (!slug) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_MARKETPLACE}/${slug}?language=${language}`,
      {},
      headers,
      Function.prototype
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };
  
  //ADD LAB MARKETPLACE
  const add = async (slug) => {
    if (!slug) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_MARKETPLACE}/${slug}/${ENDPOINTS.ADD}?language=${language}`,
      {},
      headers,
      resolveResponse
    );
    return res;
  };
  
  //REDEEM LAB MARKETPLACE
  const redeem = async (slug, organizationId) => {
    if (!slug || !organizationId) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_MARKETPLACE}/${slug}/${ENDPOINTS.REDEEM}?language=${language}`,
      {
        language,
        organization_id: organizationId,
      },
      headers,
      resolveResponse
    );
    return res;
  };
  
  //REMOVE LAB MARKETPLACE
  const remove = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);

    const client = new REQUEST();
    await client.delete(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_MARKETPLACE}/${slug}/${ENDPOINTS.DELETE}?language=${language}`,
        headers,
        onSuccess
    );
};


  return {
    list,
    add,
    show,
    redeem,
    remove
  };
};

export default useLabMarketplace;
