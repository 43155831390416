import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Skeleton } from "@mui/material";
import {
  AttachFile,
  CloseOutlined,
  Send,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import FileButton from "components/Input/FileButton";
import useDiscussions from "store/discussions/service-hook";
import ListingContainer from "./ListingContainer";
import useAuth from "store/auth/service-hook";
import moment from "moment";
import { user } from "../../../assets/svg/gradient/exports";
import placeholder from "../../../assets/png/placeholder.png";
import SecureImage from "../atoms/SecureImage";
import Button from "components/Button";
import Select from "components/Input/Select";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { COMPONENT_TYPES } from "helpers/utils/constants";

const Discussions = ({ component, slug, selectOptions }) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const { get, add, like, dislike, deleteComment } = useDiscussions();
  const [activeReplyCommentId, setActiveReplyCommentId] = useState(null);
  const [sortBy, setSortBy] = useState("recent");
  const [commentCount, setCommentCount] = useState(0);
  const { t } = useLocalizedTranslation("common", true);
  const [selectedSlug, setSelectedSlug] = useState(selectOptions ? selectOptions[0]?.key  : slug);
  const [selectedComp, setSelectedComp] = useState(component);

  const {
    state: {
      user: { username },
    },
  } = useAuth();


  useEffect(() => {
    fetchDiscussions();
  }, [sortBy, selectedSlug]);

  const fetchDiscussions = async () => {
    setLoading(true);
    const res = await get(selectedComp, selectedSlug, { sort_by: sortBy });
    setComments(res.list || []);
    setCommentCount(res.total_discussion_count || 0);
    setLoading(false);
  };

  const handleAddComment = async (comment, attachment, cId) => {
    setLoading(true);
    const payload = { comment };
    if (activeReplyCommentId) payload["comment_id"] = activeReplyCommentId;
    if (attachment) payload["attachment"] = attachment;
    await add(selectedComp, selectedSlug, payload);
    await fetchDiscussions();
    setLoading(false);
    if (activeReplyCommentId === cId) setActiveReplyCommentId(false);
  };

  const updateComments = (isReply, parentIndex, replyIndex, action) => {
    setComments((prev) => {
      const comment = isReply
        ? prev[parentIndex].comment_replies[replyIndex]
        : prev[parentIndex];

      const oppositeAction = action === "like" ? "dislike" : "like";

      if (comment.by_me === action) {
        comment[action + "s"] -= 1;
        comment.by_me = "";
      } else {
        comment[action + "s"] += 1;
        if (comment.by_me === oppositeAction) {
          comment[oppositeAction + "s"] -= 1;
        }
        comment.by_me = action;
      }

      return [...prev];
    });
  };
  const handleLike = (id, isReply = false, indexes = []) => {
    const [parentIndex, replyIndex] = indexes;
    like(selectedComp, selectedSlug, id);
    updateComments(isReply, parentIndex, replyIndex, "like");
  };

  const handleDislike = (id, isReply = false, indexes = []) => {
    const [parentIndex, replyIndex] = indexes;
    dislike(selectedComp, selectedSlug, id);
    updateComments(isReply, parentIndex, replyIndex, "dislike");
  };

  const handleDeleteComment = async (id) => {
    setLoading(true);
    await deleteComment(selectedComp, selectedSlug, id);
    await fetchDiscussions();
    setLoading(false);
  };


  const checkSelectedValueIsFirstOption = (key) => {
    return key === selectOptions[0].key;
  }

  const onChange = (e) => {
    if(!e.target.value) return;
    setSelectedSlug(e.target.value); 
    setSelectedComp(checkSelectedValueIsFirstOption(e.target.value) ? component : COMPONENT_TYPES.CHALLENGE);
  }


  return (
    <Box className="py-2 px-2 minh-200">
      {
        COMPONENT_TYPES.CHALLENGE_PATH === component && 
        <Select
          name="sort"
          classes={"w-full"}
          value={selectedSlug}
          width={"100%"}
          placeholder={'Filter discussions'}
          onChange={(e) =>{
            onChange(e)
          }}
          keyValues={selectOptions}
          t={t}
        />
      }

      {!loading && !comments.length && (
        <div className="flex-center mt-5 mb-10">
          <SecureImage
            placeholder={user}
            src={user}
            classes="h-35 w-35 rounded-100 object-cover"
          />
          <p className="border py-2 px-6 rounded-4 ml-2 fs-14">
            {t("What do you think? Start a discussion!")}
          </p>
        </div>
      )}

 
    
      <SendMessageBox add={handleAddComment} t={t} />
      {comments?.length && !loading ? (
        <div className="pt-2 flex items-center gap-10">
          <p className="fw-700 fs-14">
            {commentCount} {t("Comments")}
          </p>
          <Select
            name="sort"
            classes={"w-full flex-1"}
            value={sortBy}
            width={"150px"}
            placeholder={"Sort By"}
            onChange={(e) => setSortBy(e.target.value)}
            keyValues={[
              { value: "Most Recent", key: "recent" },
              { value: "Most Likes", key: "top" },
              { value: "Most Replies", key: "most_replies" },
            ]}
            t={t}
          />
        </div>
      ) : null}
      <ListingContainer
        spinLoader
        loading={loading}
        loader={
          <Box className="w-full">
            {Array(Math.max(3, Math.min(7, comments?.length || 0)))
              .fill(1)
              .map((_d, i) => (
                <Box key={i} className="flex-center mt-3 w-full">
                  <Skeleton
                    variant="rounded"
                    className="w-40 h-40 rounded-100 mr-2"
                  />
                  <Box className="flex-1">
                    <Skeleton variant="text" height="15px" className="w-30p" />
                    <Skeleton variant="text" height="8px" className="w-60p" />
                  </Box>
                </Box>
              ))}
          </Box>
        }
        noPagination
        classes="overflowY-auto overflowX-hidden maxh-450 px-2 my-2"
      >
        <Grid item xs={12}>
          {comments?.map((d, i) => (
            <div key={d?.id}>
              <Comment
                comment={d}
                onReply={() => setActiveReplyCommentId(d.id)}
                like={() => handleLike(d?.id, false, [i])}
                dislike={() => handleDislike(d?.id, false, [i])}
                isMyC={username === d?.user_details?.username}
                onDelete={() => handleDeleteComment(d?.id)}
                t={t}
              />
              {d?.comment_replies?.map((reply, index) => (
                <Comment
                  key={index}
                  comment={reply}
                  like={() => handleLike(reply?.id, true, [i, index])}
                  dislike={() => handleDislike(reply?.id, true, [i, index])}
                  isReply
                  isMyC={username === reply?.user_details?.username}
                  onDelete={() => handleDeleteComment(reply?.id)}
                  t={t}
                />
              ))}
              {activeReplyCommentId === d?.id && (
                <div className="ml-8 flex-center opacity-70 mt-3">
                  <SendMessageBox
                    add={handleAddComment}
                    commentId={d?.id}
                    t={t}
                  />
                  <Button
                    background="transparent"
                    bordered
                    color="red"
                    classes="border-none fw-400"
                    onClick={() => setActiveReplyCommentId(false)}
                  >
                    {t("Delete")}
                  </Button>
                </div>
              )}
            </div>
          ))}
        </Grid>
      </ListingContainer>
    </Box>
  );
};

export default Discussions;

const exceedsLineLimit = (comment, charLimit = 300) =>
  comment.length > charLimit;

export const Comment = ({
  comment,
  isReply,
  onReply = Function.prototype,
  like,
  dislike,
  isMyC = false,
  onDelete = Function.prototype,
  t = Function.prototype,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);
  const commentExceedsLimit = exceedsLineLimit(comment?.comment);
  function isImage(url) {
    const imageExtensions = ["jpg", "jpeg", "webp", "png"];
    const extension = url.split(".").pop().toLowerCase();
    return imageExtensions.includes(extension);
  }

  return (
    <div className={`flex flex-1 ${isReply ? "ml-8 mt-1" : "mt-3"}`}>
      <SecureImage
        placeholder={user}
        src={comment?.user_details?.profile_image}
        classes="h-35 w-35 rounded-100 object-cover"
      />
      <div className="ml-2 w-600 flex-1">
        <div
          className={`${isReply ? "fs-13" : "fs-14"}  opacity-90`}
          style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
        >
          <div
            className={`${comment?.attachment ? "mr-5" : "mr-2"
              } text-nowrap relative`}
          >
            <b className="fs-16">{comment?.user_details?.full_name}</b>
          </div>
          <p className="fw-400 fs-14 my-1">
            {isExpanded || !commentExceedsLimit
              ? comment?.comment
              : `${comment?.comment.substring(0, 300)}... `}
            {commentExceedsLimit && (
              <span className="cursor-pointer link ml-2" onClick={toggleExpand}>
                {t(isExpanded ? "Read less" : "Read more")}
              </span>
            )}
          </p>
        </div>
        <div className="flex items-center gap-10">
          {comment?.attachment.length > 0 &&
            comment?.attachment.map((attachment, index) => (
              <div key={index}>
                {isImage(attachment?.link) ? (
                  <div className="w-150">
                    <SecureImage
                      placeholder={placeholder}
                      src={attachment?.link}
                      classes="w-full h-85"
                    />
                    <p
                      onClick={() => window.open(attachment.link)}
                      className="fs-12 py-1 link"
                    >
                      {attachment?.name}
                    </p>
                  </div>
                ) : (
                  <p
                    onClick={() => window.open(attachment.link)}
                    className="fs-12 py-1 link"
                  >
                    {attachment?.name}
                  </p>
                )}
              </div>
            ))}
        </div>
        <div className="flex c-gray items-center justify-between fs-13 opacity-70">
          <div className="flex items-center">
            <IconButton className="h-25 w-25 mr-1" onClick={like}>
              <ThumbUp
                fontSize="small"
                color={comment?.by_me === "like" ? "success" : "disabled"}
              />
            </IconButton>{" "}
            {comment?.likes || "0"}
            <IconButton className="h-25 w-25 mx-1" onClick={dislike}>
              <ThumbDown
                fontSize="small"
                color={comment?.by_me === "dislike" ? "error" : "disabled"}
              />
            </IconButton>
            {comment?.dislikes || "0"}
            {!isReply && (
              <p className="ml-4 cursor-pointer" onClick={onReply}>
                {t("REPLY")}
              </p>
            )}
            <p className="ml-4">
              {moment(new Date(comment?.created_at)).fromNow()}
            </p>
          </div>
          {isMyC && (
            <Button
              background="transparent"
              bordered
              color="red"
              classes="border-none fw-400"
              onClick={onDelete}
            >
              {t("Delete")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export const SendMessageBox = ({ add, commentId, t }) => {
  const [comment, setComment] = useState("");
  const [file, setFile] = useState([]);
  const [error, setError] = useState(null);

  const handleSend = () => {
    if (!comment || !comment?.trim()?.length) {
      setError(t("The comment message is required!"));
      return;
    }

    if (comment.length > 1000) {
      setError(t("You can only enter up to 1000 characters."));
      return;
    }

    add(comment, file, commentId);
    setComment("");
    setFile([]);
  };
  const handleChange = (e) => {
    setComment(e.target.value);
    setError(null);
  };

  const handleFileChange = (attachment) => {
    setFile([...file, ...attachment]);
  };

  return (
    <Box className="flex-column w-full relative">
      <Box className="flex items-center justify-between  relative w-full">
        <Box
          className={`border flex-1 flex items-center justify-between  h-35 rounded-4 ${
            error && "border-error"
            }`}
        >
          <input
            placeholder={t("Your comments")}
            className="ghostInput w-full"
            value={comment}
            onChange={handleChange}
          />
          <Box className="flex items-center">
            <FileButton multi onChange={handleFileChange}>
              <AttachFile className="c-primary fs-20 hover-scale mt-1" />
            </FileButton>
          </Box>
        </Box>
        <IconButton size="medium" className="ml-1" onClick={handleSend}>
          <Send className="c-primary fs-30" />
        </IconButton>
      </Box>
      <Box className="flex items-center gap-10 w-full">
        {!!file && (
          <>
            {file.map((f, index) => (
              <Box key={index} className="flex c-success fs-14">
                <p className="fs-12 link">{f?.name}</p>
                <CloseOutlined
                  className="cursor-pointer"
                  onClick={() => setFile(file.filter((_, i) => i !== index))}
                  sx={{ fontSize: 16 }}
                  color="error"
                />
              </Box>
            ))}
          </>
        )}
        {error && (
          <p className={`c-error fs-12 fw-500 mr-10 ${!file && "mr-0"}`}>
            {error}
          </p>
        )}
      </Box>
    </Box>
  );
};
