import MainPageLayout from "pages/layouts/MainPage";
import React, { useEffect, useState } from "react";
import ImageCard from "components/Cards/ImageCard";
import Button from "components/Button";
import ProjectDescription from "./ProjectDescription";
import MainTabs from "components/UI/organisms/MainTabs";
import ProjectOverview from "./ProjectOverview";
import ProjectFiles from "./ProjectFiles";
import ProjectHistory from "./ProjectHistory";
import Discussions from "components/UI/organisms/Discussions";
import Card from "components/Cards/Card";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { isTrue } from "helpers/utils/validators";
import { CheckCircle, Done } from "@mui/icons-material";
import ProjectAssessModal from "./ProjectAssessModal";
import ProjectSubmissionModal from "./ProjectSubmissionModal";
import useProjects from "store/projects/service-hook";
import useRedirect from "helpers/hooks/useRedirect";
import ProjectAssessmentAIModal from "./ProjectAssessmentAIModal";
import ShareModal from "components/UI/organisms/ShareModal";
import moment from "moment";
import Tooltip from "components/UI/atoms/Tooltip";
import useAuth from "store/auth/service-hook";
import ProjectManageOptions from "./ProjectManageOptions";
import ProjectAssessmentDetailsModal from "../../report/challenge/components/ProjectAssessmentDetailsModal";
import {NotLoggedInTabs} from "../../organization";

const project_tabs = ["Pitch", "Files", "Discussion", "History"];

const isRequirementCompleted = (requirements, types) => {
  const filteredRequirements = requirements.filter((req) =>
    types.includes(req.type)
  );
  return filteredRequirements.every((req) => req.status === "completed");
};

const ProjectsMainPage = ({ project, setProject, t }) => {
  const [tab, setTab] = useState(0);
  const [history, setHistory] = useState(false);
  const [assessmentOpen, setAssessmentOpen] = useState(false);
  const [assessmentAIOpen, setAssessmentAIOpen] = useState(false);
  const [submissionOpen, setSubmissionOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const isProjectSubmitted = isTrue(project?.is_submitted);
  const [lateSubmissionMessage, setLateSubmissionMessage] = useState("");
  const isProjectAssessed = project?.is_view_assess_enabled === "yes";
  const challengeStatusClosed =
    project?.challenge_details?.challenge_status === "closed";
  const submissionNotAllowed =
    project?.challenge_details?.submission_status === "not_allowed";
  const isDueDatePassed = moment().isAfter(
    moment(project?.challenge_details?.due_date)
  );
  const [assessmentModelOpen, setAssessmentModelOpen] = useState(false);
  const {
    deleteMedia,
    getHistory,
    deleteProject,
    submit,
    deleteAssessment,
    favUnfav,
    pinUnpin,
  } = useProjects();
  const { navigateProjects } = useRedirect();
  const {
    state: { isLoggedIn },
  } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) return;
    fetchHistory();
  }, [project?.slug]);

  const fetchHistory = async () => {
    const res = await getHistory(project?.slug);
    setHistory(res);
  };

  const handleDeleteProject = async (slug) => {
    await deleteProject(slug);
    navigateProjects();
  };

  const handleSubmit = async () => {
    const res = await submit(project?.slug, {
      late_submission_msg: lateSubmissionMessage,
    });
    if (res) setProject(res);
    return res;
  };

  const handleDeleteMedia = (id, type) => {
    setProject((prevProject) => {
      const updatedProject = { ...prevProject };
      let mediaKey;
      switch (type) {
        case "audio":
          mediaKey = "audios";
          break;
        case "video":
          mediaKey = "videos";
          break;
        case "docs":
          mediaKey = "docs";
          break;
        case "image":
          mediaKey = "images";
          break;
        default:
          return prevProject;
      }
      if (mediaKey && updatedProject[mediaKey]) {
        updatedProject[mediaKey] = updatedProject[mediaKey].filter(
          (item) => item.id !== id
        );
      }
      deleteMedia(project?.slug, id, type);
      return updatedProject;
    });
  };

  const handleDeleteAssessment = async () => {
    setLoading(true);
    await deleteAssessment(project?.slug);
    setProject((prevProject) => ({
      ...prevProject,
      assessment_data: {
        ...(prevProject?.assessment_data || {}),
        assessment_status: "draft",
      },
    }));
    setLoading(false);
  };

  const doUndoAction = async (property, doAction, undoAction) => {
    const did =
      !!project[property] && project[property]?.toLowerCase() !== "no";
    let res;
    if (did) {
      res = await undoAction(project?.slug, true);
    } else {
      res = await doAction(project?.slug);
    }
    if (!res) return;
    project[property] = did ? "no" : "yes";
    setProject({ ...project });
  };

  const handleFavoriteUnFav = () =>
    doUndoAction("favourite", favUnfav, favUnfav);
  const handlePinUnpin = () =>
    doUndoAction("is_project_pinned", pinUnpin, pinUnpin);

  const tabsWithIcons = project_tabs.map((tabName, index) => {
    if (
      index === 1 &&
      isRequirementCompleted(project.requirement_status, ["gallery", "file"]) && isLoggedIn
    ) {
      return (
        <div key={index} style={{ display: "flex", alignItems: "center" }}>
          <CheckCircle className="mr-2 c-success" />
          {tabName}
        </div>
      );
    }

    return tabName;
  });

  const pitchCompleted = isRequirementCompleted(project?.requirement_status, [
    "pitch",
  ]);
  const taskCompleted = isRequirementCompleted(project?.requirement_status, [
    "task",
  ]);
  return (
    <>
      <MainPageLayout
        classes="pt-header mt-8 mb-5"
        firstLgGridSize={4}
        secondLgGridSize={8}
      >
        <div className="left">
          <ImageCard
            image={project?.media}
            height="250px"
            isEmbedded={project?.media_type === "embedded"}
            type={COMPONENT_TYPES.PROJECT}
            title={project?.title}
          />
          <div className="flex my-2">
            {project?.project_role === "submitter" &&
            project?.joined_status !== "no" ? (
              project?.access_level !== "viewer" && (
                <>
                  <Tooltip
                    title={t(
                      isDueDatePassed && !isProjectSubmitted
                        ? "Late submission"
                        : challengeStatusClosed
                        ? "Challenge closed for submission"
                        : !isProjectSubmitted
                        ? "Project is not submitted yet."
                        : submissionNotAllowed
                        ? "Submission is not allowed."
                        : ""
                    )}
                    placement="bottom"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -10],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <span className="w-60p">
                      <Button
                        classes="fs-16"
                        color={
                          isProjectSubmitted &&
                          !isTrue(project?.is_assess_enabled)
                            ? "green"
                            : isProjectSubmitted && !isProjectAssessed
                            ? "orange"
                            : submissionNotAllowed ||
                              !isTrue(project?.submit_enabled) ||
                              challengeStatusClosed
                            ? "gray"
                            : "green"
                        }
                        width="100%"
                        height="50px"
                        disabled={
                          (isProjectSubmitted && !isProjectAssessed) ||
                          (submissionNotAllowed && !isProjectSubmitted) ||
                          !isTrue(project?.submit_enabled) ||
                          challengeStatusClosed
                        }
                        onClick={() => {
                          if (isTrue(isProjectSubmitted) && isProjectAssessed) {
                            setAssessmentModelOpen(true);
                          } else {
                            setSubmissionOpen(true);
                          }
                        }}
                      >
                        {isProjectAssessed &&
                        isProjectSubmitted &&
                        project?.joined_status === "accepted" ? (
                          <>
                            <Done /> {t("View Assessment")}
                          </>
                        ) : isProjectSubmitted &&
                          project?.project_role === "submitter" &&
                          project?.assessment_data?.assessment_type ===
                            "none" &&
                          project?.joined_status === "accepted" ? (
                          <>
                            <Done /> {t("Submitted")}
                          </>
                        ) : !isProjectAssessed &&
                          isTrue(project?.is_assess_enabled) &&
                          isProjectSubmitted &&
                          project?.project_role === "submitter" &&
                          project?.assessment_data?.assessment_type !==
                            "none" ? (
                          t("Pending Assessment")
                        ) : (!isProjectAssessed &&
                            !isProjectSubmitted &&
                            !isDueDatePassed) ||
                          submissionNotAllowed ? (
                          <>{t("Submit Project")}</>
                        ) : (
                          isDueDatePassed &&
                          !isProjectSubmitted &&
                          !isProjectAssessed &&
                          t("Late Submission")
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                  <ProjectManageOptions
                    data={project}
                    onDelete={handleDeleteProject}
                    classes="w-40p h-50 ml-1 fs-16"
                    pinUnpin={handlePinUnpin}
                  />
                </>
              )
            ) : project?.project_role === "assessor" ? (
              project?.assessment_data?.assessment_status === "published" ? (
                <>
                  <Button
                    width="50%"
                    height="50px"
                    color={challengeStatusClosed ? "blue" : "orange"}
                    onClick={() => setAssessmentOpen(true)}
                  >
                    {challengeStatusClosed
                      ? t("View Assessment")
                      : t("Edit Assessment")}
                  </Button>
                  <Button
                    width="50%"
                    classes="ml-1"
                    height="50px"
                    loading={loading}
                    disabled={challengeStatusClosed}
                    color={challengeStatusClosed ? "gray" : "red"}
                    bordered
                    onClick={handleDeleteAssessment}
                  >
                    {t("Delete Assessment")}
                  </Button>
                </>
              ) : (
                <Button
                  width="100%"
                  height="50px"
                  color="orange"
                  onClick={() => setAssessmentOpen(true)}
                >
                  {t("Assess Project")}
                </Button>
              )
            ) : null}
          </div>
          <ProjectDescription
            project={project}
            onShare={setShareOpen}
            onLike={handleFavoriteUnFav}
          />
        </div>
        <div className="right">
          <MainTabs tabs={tabsWithIcons} current={tab} setCurrent={setTab} />
          {isLoggedIn ? (
            <>
              {tab === 0 && (
                <ProjectOverview
                  project={project}
                  setProject={setProject}
                  pitchCompleted={pitchCompleted}
                  taskCompleted={taskCompleted}
                  t={t}
                />
              )}
              {tab === 1 && (
                <ProjectFiles
                  project={project}
                  onDeleteMedia={handleDeleteMedia}
                  t={t}
                  setProject={setProject}
                />
              )}
              {tab === 2 && (
                <Card classes="my-5">
                  <Discussions
                    component={COMPONENT_TYPES.PROJECT}
                    slug={project?.slug}
                  />
                </Card>
              )}
              {tab === 3 && <ProjectHistory history={history} />}
            </>
          ) : (
              <NotLoggedInTabs title="Login to view more details" />
          )}
        </div>
      </MainPageLayout>
      {assessmentOpen ? (
        <ProjectAssessModal
          open={Boolean(assessmentOpen)}
          onClose={() => setAssessmentOpen(false)}
          project={project}
          onSuccessCb={(res) =>
            setProject((prev) => ({ ...prev, assessment_data: res }))
          }
        />
      ) : null}
      {assessmentAIOpen ? (
        <ProjectAssessmentAIModal
          open={Boolean(assessmentAIOpen)}
          onClose={() => setAssessmentAIOpen(false)}
          project={project}
        />
      ) : null}
      <ProjectSubmissionModal
        open={Boolean(submissionOpen)}
        onClose={() => setSubmissionOpen(false)}
        project={project}
        submit={handleSubmit}
        lateSubmissionMessage={lateSubmissionMessage}
        setLateSubmissionMessage={setLateSubmissionMessage}
        isDueDatePassed={isDueDatePassed}
      />
      {assessmentModelOpen ? (
        <ProjectAssessmentDetailsModal
          open={Boolean(assessmentModelOpen)}
          onClose={() => setAssessmentModelOpen(false)}
          details={project}
          slug={project?.challenge_details?.slug}
        />
      ) : null}
      {shareOpen && (
        <ShareModal
          open={shareOpen}
          onClose={() => setShareOpen(false)}
          component={COMPONENT_TYPES.PROJECT}
          slug={project?.slug}
        />
      )}
    </>
  );
};

export default ProjectsMainPage;
