import { Grid, Skeleton, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { plan_features, plansList } from "./data";
import { Cancel, CheckCircle } from "@mui/icons-material";
import Button from "components/Button";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import useOrganization from "store/organization/service-hook";
import { useTranslation } from "react-i18next";

const ComparePlans = ({ activePlan, setActivePlan }) => {
  const width = useWindowWidth();
  const [planFeatures, setPlanFeatures] = useState([]);
  const [loading, setLoading] = useState(false);
  const { comparePlans } = useOrganization();
  const onTab = useMemo(() => width < 900, []);
  const { t } = useTranslation(["organization", "common"]);

  useEffect(() => {
    fetchComparePlans();
  }, []);

  const fetchComparePlans = async () => {
    setLoading(true);
    const plans = await comparePlans();
    const dynamicFeaturesData = populateDynamicFeatures(plans);
    setPlanFeatures(dynamicFeaturesData);
    setLoading(false);
  };
  const populateDynamicFeatures = (backendPlans) => {
    return plan_features.map((feature) => {
      let updatedFeature = feature;

      backendPlans.forEach((plan) => {
        if (plan.feature_id === feature.feature_id) {
          if (updatedFeature.has_numeric_limits === true) {
            updatedFeature.seed = plan.limits["free-plan"] || "0";
            updatedFeature.sprout = plan.limits["Sprout-Plan"] || "N/A";
            updatedFeature.budd = plan.limits["Budd-Plan"] || "N/A";
            updatedFeature.bloom = plan.limits["Bloom-Plan"] || "N/A";
          }
        }
      });
      return updatedFeature;
    });
  };
  return (
    <div className="my-12">
      <h1 className="text-center">
        {t("Compare features and credits between plans")}
      </h1>
      {loading ? (
        <Grid container mx="auto" mt={10} spacing={0.5} className="container">
          {Array(8)
            .fill(1)
            .map((_d, i) => (
              <Grid key={i} item xs={12} md={4} lg={3}>
                <div className={`flex-column w-full h-full minw-200 minh-200`}>
                  <Skeleton
                    variant="rectangular"
                    sx={{ minWidth: "100%", minHeight: "100%" }}
                  />
                </div>
              </Grid>
            ))}
        </Grid>
      ) : (
        <>
          <Grid container className="flex justify-center mt-12">
            {[{}, ...plansList.slice(0, -1)].map((plan, i) => (
              <Grid
                key={i}
                item
                xs={onTab ? (i === 0 ? 4 : 1.5) : 2}
                className={`flex justify-center ${
                  activePlan === plan?.id &&
                  "border-primary no-border-primary-bottom"
                } ${i !== 0 && "bg-white border-gray no-border-bottom"} ${
                  i < plansList.length - 1 && "no-border-right"
                }`}
              >
                <div
                  className={`flex-column  px-2 py-3 relative items-center justify-between  ${
                    onTab ? "minh-100" : "minh-200 "
                  } w-100p `}
                >
                  {!onTab && plan?.isPopular && (
                    <p
                      style={{ top: "-25px" }}
                      className="absolute bg-purple text-center c-white h-25 w-100p fs-12 py-1"
                    >
                      {t("Most Popular")}
                    </p>
                  )}
                  {i > 0 &&
                    (onTab ? (
                      <div className="flex items-center h-100p justify-center">
                        <h3 className="fs-14 text-center rotate-90 text-nowrap">
                          {t(plan?.title, { ns: "common" })}
                        </h3>
                      </div>
                    ) : (
                      <>
                        <div className="flex-center">
                          <img
                            src={plan?.icon}
                            className="w-30 h-25 mr-1"
                            alt={`icon of ${plan?.title}`}
                          />
                          <h3>{t(plan?.title, { ns: "common" })}</h3>
                        </div>

                        <p className={`fw-700 mt-2 fs-30`}>
                          {plan?.type === "seed" ||
                          plan?.type === "enterprise" ? (
                            plan?.pricePerYearPerUser
                          ) : (
                            <>
                              ${plan?.pricePerYearPerUser}
                              <span className="fs-10">{t("/year/user")}</span>
                            </>
                          )}
                        </p>
                        <p className="text-center w-70p my-2 fs-12">
                          {plan.haveTrialPeriod
                            ? t("You won't be charged in your 14-day trial")
                            : " "}
                        </p>
                        <Button
                          onClick={() => setActivePlan(plan?.id)}
                          bordered={activePlan === plan?.id}
                          classes="fs-12 w-130 h-30"
                          disabled={activePlan === plan?.id}
                        >
                          {t(
                            activePlan === plan?.id
                              ? "Current Plan"
                              : "Start Trial"
                          )}
                        </Button>
                      </>
                    ))}
                </div>
              </Grid>
            ))}
          </Grid>
          <Grid container className="flex justify-center">
            {planFeatures?.map((feature, index) => (
              <Grid item xs={10} key={index} className="bg-gray">
                <Grid container>
                  <Grid
                    item
                    xs={onTab ? 4.8 : 2.4}
                    className={`flex-column justify-center px-2 h-80 border-gray no-border-right ${
                      index < planFeatures?.length - 1 && "no-border-bottom"
                    }`}
                    sx={{ backgroundColor: getFeatureColor(feature?.title) }}
                  >
                    <Tooltip title={t(feature?.title)}>
                      <h5
                        className="text-truncate fs-13--mob"
                        title={t(feature?.title)}
                      >
                        {t(feature?.title)}
                      </h5>
                    </Tooltip>
                    <p
                      className="fs-12 c-gray text-truncate-2"
                      title={t(feature?.description)}
                    >
                      {t(feature?.description)}
                    </p>
                  </Grid>
                  {
                    // TO SHOW ALL COLUMN MERGED EXCEPT SEED
                    // feature?.onlySeedForbid ? (
                    //   [onTab ? 1.8 : 2.4, onTab ? 1.8 * 3 : 2.4 * 3].map(
                    //     (xs, _i) => (
                    //       <Grid
                    //         item
                    //         xs={xs}
                    //         className={`border-gray flex-center justify-center ${
                    //           _i === 0 && "no-border-right"
                    //         } ${
                    //           index !== planFeatures?.length - 1 &&
                    //           "no-border-bottom"
                    //         }`}
                    //         sx={{
                    //           backgroundColor: `${getFeatureColor(
                    //             feature?.title
                    //           )}60`,
                    //         }}
                    //       >
                    //         <p className="fw-500 fs-12--mob text-center text-truncate px-1">
                    //           {formatFeatureValue(_i !== 0)}
                    //         </p>
                    //       </Grid>
                    //     )
                    //   )
                    // TO SHOW ALL COLUMNS MERGED
                    // ) : feature?.all ? (
                    //   <Grid
                    //     item
                    //     xs={onTab ? 1.8 * 4 : 2.4 * 4}
                    //     className={`border-gray flex-center justify-center no-border-bottom`}
                    //     sx={{
                    //       backgroundColor: `${getFeatureColor(feature?.title)}60`,
                    //     }}
                    //   >
                    //     <p className="fw-500 fs-12--mob text-center text-truncate px-1">
                    //       {formatFeatureValue(true)}
                    //     </p>
                    //   </Grid>
                    // ) :
                    ["seed", "sprout", "budd", "bloom"].map((planType, i) => (
                      <Grid
                        item
                        xs={onTab ? 1.8 : 2.4}
                        key={i}
                        className={`border-gray ${
                          i < 3 && "no-border-right"
                        } no-border-bottom flex-center justify-center`}
                        sx={{
                          backgroundColor: `${getFeatureColor(
                            feature?.title
                          )}60`,
                        }}
                      >
                        <p className="fw-500 fs-12--mob text-center text-truncate px-1">
                          {formatFeatureValue(
                            feature.all
                              ? true
                              : feature?.onlySeedForbid
                              ? i !== 0
                              : feature[planType],
                            t
                          )}
                        </p>
                      </Grid>
                    ))
                  }
                </Grid>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  );
};
export default ComparePlans;

const getFeatureColor = (title) => {
  if (
    title?.toLowerCase()?.includes("organization") ||
    title?.toLowerCase()?.includes("manager") ||
    title?.toLowerCase()?.includes("user")
  ) {
    return "#FFF3CC";
  } else if (title?.toLowerCase()?.includes("resource")) {
    return "#F4E7FD";
  } else if (title?.toLowerCase()?.includes("challenge")) {
    return "#DBF4E8";
  } else if (title?.toLowerCase()?.includes("lab")) {
    return "#D6F2FE";
  } else {
    return "#F8F8F8";
  }
};

export const formatFeatureValue = (value, t = (d) => d) => {
  if (typeof value === "boolean") {
    return value ? (
      <CheckCircle className="c-success" />
    ) : (
      <Cancel className="c-error" />
    );
  }
  return t(value);
};
