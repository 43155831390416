import configureStore from "redux-mock-store";

export const initStore = (initialState) => {
  const mockStore = configureStore();
  const store = mockStore(initialState);
  return store;
};

export const dispatchMock = (store) => {
  const dispatch = jest.fn();
  store.dispatch = dispatch;
  return dispatch;
};
