import { Grid } from "@mui/material";
import AssociatedListing from "components/UI/organisms/AssociatedListing";
import PageTitle from "components/UI/atoms/PageTitle";
import React from "react";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import useChallenges from "../../../store/challenges/service-hook";
import { isTrue } from "helpers/utils/validators";
import { createEvent, payloadBool } from "helpers/utils/utilities";

const AssociateChallenges = ({
  challenges,
  setChallenges,
  t = (d) => d,
  data = {},
  onChange = Function.prototype,
}) => {
  const { fetchChallengeSelectList } = useChallenges();

  const debounceChallengeSearch = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const onSearchChallenges = async (search) => {
    let challenges = [];
    challenges = await fetchChallengeSelectList({ 
      search,
      privacy: isTrue(data?.privacy) ? "private" : "public"
    });
    setChallenges((prev) => ({
      all: [
        ...challenges?.map((item) => ({
          ...item,
          type: COMPONENT_TYPES.CHALLENGE,
        })),
      ],
      selected: prev.selected,
    }));
  };

  const debouncedSearchChallenges = debounceChallengeSearch(
    onSearchChallenges,
    1000
  );

  return (
    <Grid container px={{ xs: 0, md: 4 }}>
      <PageTitle>{t("Associated Challenges")}</PageTitle>
      <AssociatedListing
        type={COMPONENT_TYPES.CHALLENGE}
        title={t("Browse Challenges to add to your Challenge Path")}
        subTitle={t(
          "You may only associate Challenges with the same visibility as the Challenge Path."
        )}
        component="challenges"
        tabs={["Challenges"]}
        list={challenges}
        setList={setChallenges}
        onSearch={debouncedSearchChallenges}
        isSequential={isTrue(data?.is_sequential)}
        setSequential={(val) =>
          onChange(createEvent("is_sequential", payloadBool(val)))
        }
      />
    </Grid>
  );
};

export default AssociateChallenges;
