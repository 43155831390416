import React, { useEffect, useState } from "react";
import { NoDataView } from "components/UI/organisms/ListingContainer";
import useMaster from "store/master/service-hook";
import { Grid, Box } from "@mui/material";
import { COLORS } from "helpers/utils/constants";
import Spin from "components/Spin";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const PitchNTaskListing = ({ templateId }) => {
  const [pitchNTasks, setPitchNTasks] = useState();
  const { fetchChallengePitchTask } = useMaster();
  const [loading, setLoading] = useState(false);
  const [customTask, setCustomTask] = useState(false);
  useEffect(() => {
    if (templateId !=="custom") {
      handleFetchTasks();
      setCustomTask(false);
    }
    else {
      setCustomTask(true);
    }
  }, [templateId]);

  const handleFetchTasks = async () => {
    setLoading(true)
    const res = await fetchChallengePitchTask(templateId);
    setLoading(false);
    if (res) {
      setPitchNTasks(res[0] || undefined);
    }
  };
  if(customTask){
    return (
      <Box className="w-full h-400">
        hello i am custom
      </Box>
    )
  }
  return (
    loading ? <Box className="flex items-center justify-center w-full h-400">
      <Spin big blue />
    </Box> :
      pitchNTasks ? (
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <h3 className="my-2 fs-18 fw-500 opacity-60">Pitch List</h3>
            {pitchNTasks.challenge_pitch.length !== 0 ? (
              pitchNTasks.challenge_pitch.map(
                ({ id, title, description }) => (
                  <PitchTaskItem
                    key={id}
                    title={title}
                    description={description}
                  />
                )
              )
            ) : (
              <NoDataView
                classes="py-5 fs-14"
                text="No pitch for this template!"
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <h3 className="my-2 fs-18 fw-500 opacity-60">Task List</h3>
            {pitchNTasks.challenge_task.length !== 0 ? (
              pitchNTasks.challenge_task.map(({ id, title, description }) => (
                <PitchTaskItem
                  key={id}
                  title={title}
                  description={description}
                  isTask
                />
              ))
            ) : (
              <NoDataView
                classes="py-5 fs-14"
                text="No tasks for this template!"
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <NoDataView
          text="No Pitch and Tasks available"
          classes="py-5 fs-14"
        />
      )
  )
}
export default PitchNTaskListing;

export const PitchTaskItem = ({ id, title, description, isTask }) => {
  const {t} = useLocalizedTranslation();
  return (
    <div
      key={id}
      className="border rounded-5 px-2 py-1 flex-column justify-center mb-1 h-50"
      style={{ borderColor: isTask ? COLORS.GREEN : COLORS.ORANGE }}
    >
      <p className="fs-13 text-truncate ">{t(title)}</p>
      <p className="fs-12 opacity-60 text-truncate">
        {!!description ? description : "N/A"}
      </p>
    </div>
  );
};