import { Grid, Skeleton } from "@mui/material";
import Card from "components/Cards/Card";

const PinnedJobCard = () => {
  return (
    <Card
      noShadow
      classes="border flex-column items-stretch px-2 py-2 overflow-x-hidden"
    >
      <Skeleton variant="text" width="70%" height={20} />
      <Skeleton variant="text" width="90%" height={15} />

      <Grid container mt={3}>
        {[1, 2, 3, 4].map((item, i) => (
          <Grid
            key={i}
            xs={6}
            className="flex items-center tracking-tight mb-1 px-1"
          >
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton
              variant="text"
              width="80%"
              height={10}
              style={{ marginLeft: 8 }}
            />
          </Grid>
        ))}
      </Grid>

      <div className="flex mt-4 mb-1">
        {[1, 2].map((_d, i) => (
          <Skeleton
            key={i}
            variant="rectangular"
            className="rounded-10 mr-2"
            width={80}
            height={12}
          />
        ))}
      </div>
    </Card>
  );
};

export default PinnedJobCard;
