import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import AssesmentMainPage from "./AssesmentMainPage";
import useChallenges from "store/challenges/service-hook";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";

const Assessment = () => {
  const [assessment, setAssessment] = useState();
  const [challenge, setChallenge] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const { id, type } = useParams();
  const { fetchAssessment, fetchChallenge } = useChallenges();
  const [loading, setLoading] = useState(false);

  const {isLoggedIn} = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  useEffect(() => {
    if (id && isLoggedIn) handleFetchAssessment();
  }, []);

  const handleFetchAssessment = async () => {
    setLoading(true);
    const res = await fetchAssessment(id);
    const _challenge = await fetchChallenge(id);
    if (res) {
      setAssessment(res);
      setChallenge(_challenge);
    }
    setLoading(false);
  };

  return (
    <DashboardLayout
      loading={loading}
      isEmpty={assessment?.error}
      show404OnError
      isPrivate
    >
      <AssesmentMainPage
        assessment={assessment}
        setAssessment={setAssessment}
        challenge={challenge}
      />
    </DashboardLayout>
  );
};

export default Assessment;
