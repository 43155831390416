import React, { useEffect, useMemo, useState } from "react";
import useAdvanceSearch from "store/advance-search/service-hook";
import useAdvanceSearchFilter from "../hooks/useAdvanceSearchFilter";
import { AS_REDUCER } from "store/advance-search/reducer";
import { useAdvanceSearchContext } from "../hooks/useAdvanceSearchContext";
import { COMPONENT_TYPES } from "../../../helpers/utils/constants";
import { ReactComponent as Project } from "assets/svg/yellow/project.svg";
import { ReactComponent as Lab } from "assets/svg/blue/lab.svg";
import { ReactComponent as Achievement } from "assets/svg/yellow/achievement.svg";
import { ReactComponent as Skills } from "assets/svg/yellow/skills.svg";
import { Grid } from "@mui/material";
import MainProductCard from "components/Cards/MainProductCard";
import ListingContainer from "components/UI/organisms/ListingContainer";
import { isObject } from "lodash";
import Button from "components/Button";
import useProfile from "store/profile/service-hook";
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useTranslation } from "react-i18next";
import ChipsPreview from "components/UI/atoms/ChipsPreview";

const AdvanceSearchUsers = () => {
  const { gridType, keyword } = useAdvanceSearchContext();
  const { searchUsers } = useAdvanceSearch();
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const { unfriend, sendFriendRequest } = useProfile();
  const { t } = useTranslation("common");

  const { data } = useAdvanceSearchFilter(
    "users",
    keyword,
    { page },
    searchUsers,
    AS_REDUCER.setUsers
  );


  useEffect(()=>{
    if(data?.data){
      setUsers(data?.data)
    }
  }, [data])

  const handleUnfriend = async (userId) => {
    unfriend(userId);
    const res = await searchUsers(keyword,{page}); 
    setUsers(res?.data);
  };
    
  const handleAddFriend = async (userId) => {   
    sendFriendRequest(userId);
    const res = await searchUsers(keyword,{page}); 
    setUsers(res?.data);
  };

  const friendButtons = useMemo(() => {
    return (users || []).map((item) => {
      let title = "";
      let icon = null;
      let color = "";
  
      switch (item?.are_friends) {
        case "add":
        case "rejected":
          title = t("Add Friend");
          icon = <AddBoxIcon />;
          color = "blue";
          break;
        case "accepted":
          title = t("Unfriend");
          icon = <DeleteIcon />;
          color = "red";
          break;
        case "pending":
          title = t("Request Sent");
          icon = <DoneIcon />;
          color = "green";
          break;
        default:
          return null;
      }
  
      return {
        id: item.id,
        title,
        icon,
        color,
        onClick: () => {
          if (item.are_friends === "add" || item.are_friends === "rejected") {
            handleAddFriend(item.id);
          } else if (item.are_friends === "accepted") {
            handleUnfriend(item.id);
          }
        },
      };
    }).filter(Boolean);
  }, [users]);
  
  return (
    <ListingContainer
      classes="mt-3"
      loading={data?.loading}
      isEmpty={!(users && users?.length > 0)}
      noPagination={!data?.meta}
      page={data?.meta?.current_page ?? 1}
      setPage={setPage}
      count={data?.meta?.total_pages ?? 0}
    >
      {users?.map((item, index) => {
        const hasSkills =
          isObject(item?.skills) && Object.entries(item.skills).length > 0;
        const skills = hasSkills
          ? Object.entries(item.skills).length < 4
            ? Object.entries(item.skills)
            : [...Object.entries(item.skills)].splice(0, 4)
          : {};

        const friendButton = friendButtons.find((btn) => btn.id === item.id);

        return (
          <Grid
            item
            key={item?.username ?? index}
            xs={12}
            md={gridType === 2 ? 6 : 12}
            lg={gridType === 2 ? 4 : 12}
          >
            <MainProductCard
              col={gridType === 2}
              classes="mb-3"
              title={
                <div className="flex items-center">
                  <p className="text-nowrap c-primary overflow-hidden maxw-85p textOverflow-ellipsis">
                    {item?.full_name}
                  </p>
                </div>
              }
              subTitle={
                <>
                  <div className="flex items-center fs-16 fw-500">
                    <p>
                      LearnRank {item?.user_rank}{" "}
                      {item?.user_personal?.user_type
                        ? `- ${item?.user_personal?.user_type}`
                        : ""}
                    </p>
                    {item?.user_experiences?.find((experience) => experience.position) ? (
                      <>
                        <span className="vertical-align-middle mx-1">&middot;</span>
                        <span className="text-truncate maxw-200">
                          {item?.user_experiences.find((experience) => experience.position)
                            ?.position}
                        </span>
                      </>
                    ) : null}
                  </div>

                </>
              }
              type={COMPONENT_TYPES.USER}
              description={item?.user_personal?.about ?? ""}
              image={item?.profile_image}
              id={item?.username}
              iconsAndValuesList={[
                {
                  value: `${item?.user_projects_count ?? 0} ${t('Projects Created')}`,
                  icon: Project,
                },
                {
                  value: `${item?.user_labs_count ?? 0} ${t('Labs Joined')}`,
                  icon: Lab,
                },
                {
                  value: `${item?.user_achievements_count ?? 0} ${t('Achievements')}`,
                  icon: Achievement,
                },
                {
                  value: `${item?.user_skills_count ?? 0} ${t('Skills')}`,
                  icon: Skills,
                },
              ]}
              noSave
              rightAction={
                friendButton && item?.are_friends!== "unknown" ? (
                  <Button
                    classes='fs-14 mx-auto my-0'
                    bordered
                    color={friendButton.color}
                    onClick={() => {
                      friendButton.onClick();
                    }}
                  >
                    <div className="flex justify-between items-center gap-1 px-2">
                      <div className="flex">{friendButton.icon}</div>
                      <div className="flex">{friendButton.title}</div>
                    </div>
                  </Button>
                ) : null
              }
              bottomContent={
                item?.skills?.length > 0 ? (
                  <div
                    className={"mt-4 flex flex-wrap  box"}
                    style={{ gap: "6px" }}
                  >
                    <ChipsPreview 
                      items={item.skills.map(skill => skill.skill?.title)} 
                      limit={2} 
                    />
                  </div>
                ) : null
              }
            />
          </Grid>
        );
      })}
    </ListingContainer>
  );
};

export default AdvanceSearchUsers;
