import React, { useState } from "react";
import { useAdvanceSearchContext } from "../../hooks/useAdvanceSearchContext";
import classes from "../../../achievements/achievements.module.scss";
import { ExpandMore, KeyboardArrowRight } from "@mui/icons-material";
import Radio from "../../../../components/Input/Radio";
import { useSearchParams } from "react-router-dom";
import { Chip } from "@mui/material";

const Components = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const {
    activeMenu,
    setActiveMenu,
    componentLists,
    keyword,
    totalCounts,
    formattedCount,
  } = useAdvanceSearchContext();
  const [_, setSearchParams] = useSearchParams(); // eslint-disable-line

  /**
   * COMPONENT STATE
   */
  const [open, setOpen] = useState(true);

  return (
    <>
      <div className={classes.filterBox}>
        <p
          className={classes["filterBox--title"]}
          onClick={() => setOpen(!open)}
        >
          {!open ? (
            <KeyboardArrowRight className="cursor-pointer" />
          ) : (
            <ExpandMore className="cursor-pointer" />
          )}
          {t("COMPONENT")}
        </p>

        {open ? (
          <div className={classes["filterBox--content"]}>
            <Radio
              classes={"flex-column"}
              size={"small"}
              options={componentLists.map((item) => {
                return {
                  key: item?.value,
                  value: (
                    <>
                      <div className="flex fs-14" style={{ gap: "5px" }}>
                        <span>{t(item?.label)}</span>{" "}
                        {totalCounts[item?.value] ? (
                          <Chip
                            label={formattedCount(
                              totalCounts[item?.value] ?? 0
                            )}
                            size={"small"}
                            className={"fs-14--noMob "}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ),
                };
              })}
              value={activeMenu}
              name={"ads-filter-component"}
              onChange={(event) => {
                setActiveMenu(event?.target?.value);
                setSearchParams({
                  ...(keyword ? { keyword } : {}),
                  component: event?.target?.value,
                });
              }}
            />
          </div>
        ) : (
          <div className="my-2"></div>
        )}
      </div>
    </>
  );
};

export default Components;
