import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import { stepSchema } from "../data";
import Card from "components/Cards/Card";
import CustomDate from "./flexible/CustomDate";
import ToggleCard from "components/UI/molecules/ToggleCard";
import styles from "../../challenges.module.scss";
import { combineDateAndTime } from "helpers/utils/utilities";

const Timeline = ({
  data,
  errors,
  onChange,
  step,
  customDates,
  setCustomDates,
  t = (d) => d,
}) => {
  const [updatedSchema, setUpdatedSchema] = useState(stepSchema(step));
  const calculateDuration = () => {
    const start_date = new Date(
      combineDateAndTime(data?.start_date, data?.start_date_time)
    );

    const submission_deadline = new Date(
      combineDateAndTime(
        data?.submission_deadline_date,
        data?.submission_deadline_time
      )
    );

    const msDifference = Math.abs(
      submission_deadline.getTime() - start_date.getTime()
    );
    const days = Math.floor(msDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (msDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((msDifference % (1000 * 60 * 60)) / (1000 * 60));

    if (days === 0 && hours === 0 && minutes === 0) {
      return null;
    }
    let result = "";
    if (days > 0) {
      result += `${days} day${days > 1 ? "s" : ""}`;
    }
    if (hours > 0) {
      result += (result ? ", " : "") + `${hours} hour${hours > 1 ? "s" : ""}`;
    }
    if (minutes > 0) {
      result +=
        (result ? ", " : "") + `${minutes} minute${minutes > 1 ? "s" : ""}`;
    }
    return result;
  };

  useEffect(() => {
    if (data) {
      setUpdatedSchema((prevSchema) =>
        prevSchema.map((field) => {
          if (field.name === "start_date_time") {
            return { ...field, disablePast: false, disabled: !data.start_date };
          }
          if (field.name === "registration_deadline_time") {
            return {
              ...field,
              disablePast: false,
              disabled: !data.registration_deadline_date,
            };
          }
          if (field.name === "submission_deadline_time") {
            return {
              ...field,
              disablePast: false,
              disabled: !data.submission_deadline_date,
            };
          }
          return field;
        })
      );
    }
  }, [
    data?.start_date,
    data?.registration_deadline_date,
    data?.submission_deadline_date,
  ]);

  return (
    <>
      <Grid container px={{ xs: 0, md: 4 }}>
        <CreateFormSection title="Timeline" classes="container">
          <p className="c-error">
            {t("All announcements are based on")}
            <a
              href="https://dateful.com/time-zone-converter?tz2=Toronto-Canada"
              target="_blank"
              referrer
              rel="noreferrer"
              className="c-error ml-1"
            >
              {t("Eastern Standard Time (UTC-5)")}
            </a>
          </p>
          <Card classes="my-2 w-full" centered>
            <SchemaUIExtractor
              schema={stepSchema(step).slice(0, 1)}
              data={data}
              errors={errors}
              onChange={onChange}
            />
            <div
              className={`${styles.timeline_form} w-full timeline_container`}
            >
              {data?.timeline_type === "flexible" ? (
                <SchemaUIExtractor
                  schema={stepSchema(step).slice(10, 12)}
                  data={data}
                  errors={errors}
                  onChange={onChange}
                  align="end"
                />
              ) : (
                <>
                  <div
                    className={`${styles.timeline_form__restricted} timeline_restricted`}
                  >
                    <SchemaUIExtractor
                      schema={updatedSchema.slice(1, 4)}
                      data={data}
                      errors={errors}
                      onChange={onChange}
                    />
                  </div>
                  <div
                    className={`${styles.timeline_form__restricted} timeline_restricted`}
                  >
                    <SchemaUIExtractor
                      schema={updatedSchema.slice(4, 7)}
                      data={data}
                      errors={errors}
                      onChange={onChange}
                    />
                  </div>
                  <div
                    className={`${styles.timeline_form__restricted} timeline_restricted`}
                  >
                    <SchemaUIExtractor
                      schema={updatedSchema.slice(7, 10)}
                      data={data}
                      errors={errors}
                      onChange={onChange}
                    />
                  </div>
                  {calculateDuration() ? (
                    <span className="fw-700">
                      {t("Challenge Duration is")} {calculateDuration()}
                    </span>
                  ) : null}
                </>
              )}
            </div>
          </Card>
        </CreateFormSection>
        {data?.timeline_type === "flexible" && (
          <>
            <Grid
              item
              px={{ xs: 0, md: 4 }}
              container
              centered
              className={"py-5 px-0"}
            >
              <CustomDate
                customDates={customDates}
                setCustomDates={setCustomDates}
                data={data}
                t={t}
              />
            </Grid>
            <Grid
              item
              px={{ xs: 0, md: 4 }}
              container
              centered
              className={"py-5 px-0"}
            >
              <Card centered classes="w-full container">
                <h3 className="py-1">{t("Advance Options")}</h3>
                <ToggleCard
                  title="Timeline"
                  description="Add more context to specific dates"
                  t={t}
                >
                  <div className="custom_timeline">
                    <SchemaUIExtractor
                      classes="items-right"
                      schema={stepSchema(step).slice(12, 14)}
                      data={data}
                      errors={errors}
                      onChange={onChange}
                    />
                  </div>
                </ToggleCard>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Timeline;
