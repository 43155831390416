import React from "react";
import ListingContainer from "components/UI/organisms/ListingContainer";
import { Chip, Grid } from "@mui/material";
import MainProductCard from "components/Cards/MainProductCard";
import Button from "components/Button";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import { BarChart, CalendarMonth, Check, Update } from "@mui/icons-material";
import moment from "moment";
import ChipsPreview from "components/UI/atoms/ChipsPreview";
import { isTrue } from "helpers/utils/validators";
import { useTranslation } from "react-i18next";

const LabMarketplaceListing = ({
  labs,
  page,
  setPage,
  loading,
  handleRadeem = Function.prototype,
  pages,
  gridType,
  noPagination,
}) => {
  const { t } = useTranslation("labs");
  return (
    <ListingContainer
      isEmpty={!labs || !labs?.length}
      count={pages}
      loading={loading}
      page={page}
      setPage={setPage}
      classes="mt-4"
      noPagination={noPagination}
    >
      {labs?.map((lab) => (
        <Grid item key={lab?.slug} sm={gridType === 1 ? 12 : 6} xs={12}>
          <MainProductCard
            classes="mb-3"
            title={lab?.title}
            image={lab?.media}
            mediaType={lab?.media_type}
            type={COMPONENT_TYPES.LAB_MARKETPLACE}
            id={lab?.slug}
            description={lab?.description}
            col={gridType === 1 ? false : true}
            rightAction={
              gridType === 1 && (
                <RadeemButton handleRadeem={handleRadeem} lab={lab} t={t} />
              )
            }
            noSave
            subTitle={
              <div className="flex items-center">
                <p>{lab?.organization ?? "N/A"}</p>
                <span className="vertical-align-middle mx-1">&middot;</span>
                <p>
                  {t(lab?.privacy === "yes" ? "Private Lab" : "Public Lab")}
                </p>
              </div>
            }
            bottomContent={
              Object.keys(lab.skills).length > 0 ? (
                <ChipsPreview
                  items={Object.values(lab?.skills || {})}
                  limit={3}
                />
              ) : (
                <Chip label="N/A" className="mt-1 fs-12 h-24" />
              )
            }
            colBottomContent={
              <RadeemButton lab={lab} handleRadeem={handleRadeem} isCol t={t} />
            }
            iconsAndValuesList={[
              lab?.duration && {
                icon: CalendarMonth,
                value: lab?.duration,
              },
              lab?.level && {
                icon: BarChart,
                value: lab?.level,
                iconProps: {
                  fontSize: "medium",
                },
              },
              {
                icon: Update,
                value: lab?.last_updated
                  ? t("Updated") +
                    " " +
                    moment(new Date(lab?.last_updated)).fromNow()
                  : "N/A",
              },
            ].filter(Boolean)}
          />
        </Grid>
      ))}
    </ListingContainer>
  );
};

export default LabMarketplaceListing;

const RadeemButton = ({ lab, isCol, handleRadeem, t = (d) => d }) => {
  return (
    <Button
      classes={`mr-1 fs-13 ${isCol && "mt-2"}`}
      height={isCol ? "35px" : "30px"}
      width={isCol && "100%"}
      disabled={isTrue(lab?.is_redeemed)}
      color={isTrue(lab?.is_redeemed) ? "gray" : "blue"}
      onClick={() => !isTrue(lab?.is_redeemed) && handleRadeem(lab)}
    >
      {isTrue(lab?.is_redeemed) ? (
        <>
          <Check className="fs-15" fontSize="small" /> {t("Redeemed")}
        </>
      ) : (
        t("Redeem")
      )}
    </Button>
  );
};
