import React from "react";
import { Alert } from "@mui/material";
import { COLORS } from "helpers/utils/constants";
import { Info } from "@mui/icons-material";

const AlertMessage = ({ severity, title, description, classes }) => {
  return (
    <Alert
      severity={severity}
      sx={{ border: `1px solid ${getBorderColor(severity)}`, color: "black" }}
      icon={<Info className="mt-1" fontSize="large" />}
      className={`${classes} rounded-10`}
    >
      <h3 className="fs-15 fw-500 fs-13--mob">{title}</h3>
      <p className="fs-13 mt-1 fs-12--mob">{description}</p>
    </Alert>
  );
};

export default AlertMessage;

const getBorderColor = (severity) => {
  switch (severity) {
    case "error":
      return COLORS.RED;
    case "success":
      return COLORS.GREEN;
    case "warning":
      return COLORS.ORANGE;
    default:
      return "inherit";
  }
};