import React from "react";
import { Tooltip as Tooltp } from "@mui/material";

const Tooltip = ({ title, children, ...props }) => {
  return (
    <Tooltp title={title} {...props}>
      {children}
    </Tooltp>
  );
};

export default Tooltip;
