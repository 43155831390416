import React, { useEffect, useMemo, useState } from "react";
import {
  ArrowBack,
  Delete,
  Done,
  ExpandMore,
  KeyboardArrowRight,
  OpenInNew,
  SearchOutlined,
} from "@mui/icons-material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import Select from "components/Input/Select";
import TableComponent from "components/Table";
import IconText from "components/UI/atoms/IconText";
import { useParams, useSearchParams } from "react-router-dom";
import Input from "components/Input";
import {
  ADD_MEMBER_SCHEMA,
  PROJECT_STATUS,
  REQUEST_STATUS,
  STATUS_TYPES,
  getStatusColor,
} from "../data";
import {
  COLORS,
  COMPONENT_TYPES,
  LAB_PROGRESS,
  PROJECT_PROGRESS_STATUS,
} from "helpers/utils/constants";
import useRedirect from "helpers/hooks/useRedirect";
import useMembers from "store/member-management/service-hook";
import useForms from "helpers/hooks/useForms";
import Divider from "components/Divider";
import useOrganization from "store/organization/service-hook";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import AddMembers from "./AddMembers";
import useAuth from "store/auth/service-hook";
import { isTrue } from "helpers/utils/validators";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";
import { profile } from "assets/svg/purple";
import moment from "moment";

const PER_PAGE = 10;

const MemberManagementMainPage = () => {
  const [pageType, setPageType] = useState("");
  const [roles, setRoles] = useState([]);
  const [filters, setFilters] = useState({
    invitationStatus: "",
    projectStatus: "",
    labProgress: "",
    role: "",
    requestStatus: "",
    labProgramProgress: "",
  });

  const [csv, setCsv] = useState("");
  const [uploadedCSV, setUploadedCSV] = useState();
  const [data, setData] = useState({});
  const [selected, setSelected] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [reInviteLoading, setReInviteLoading] = useState();
  const [search, setSearch] = useState("");
  const [searchUpdated, setSearchUpdated] = useState(false);
  const [networkUsers, setNetworkUsers] = useState([]);
  const { type, id } = useParams();
  const [isExpanded, setIsExpanded] = useState(true);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const { planDetails } = useOrganization();
  const [formErrors, setFormErrors] = useState(new Map());
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [autoInvite, setAutoInvite] = useState("yes");
  const [allSelected, setAllSelected] = useState(false);
  const [page, setPage] = useState(0);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation("common");

  const {
    state: { user },
  } = useAuth();

  const {
    navigate404,
    navigateOrg,
    navigateLab,
    navigateChallenge,
    navigateProject,
    navigateBack,
    navigateLabP,
  } = useRedirect();
  const {
    getMembers,
    addMembers,
    deleteMembers,
    getRoles,
    downloadSampleCsv,
    changeRole,
    handleAcceptMembersJoinRequest,
    approveAllPendingRequests,
    deleteAllMembers,
  } = useMembers();
  const {
    data: addData,
    validate,
    onChange,
    errors,
    setData: setAddData,
  } = useForms(ADD_MEMBER_SCHEMA);

  useEffect(() => {
    if (searchUpdated) {
      setPage(0);
      setSearchUpdated(false);
    }
    handleSetUsers();
  }, [search, filters, page, roles]);

  useEffect(() => {
    handleGetRoles();
    handleGetCsv();
  }, []);

  useEffect(() => {
    fetchPlanDetails();
  }, [id]);

  const fetchPlanDetails = async () => {
    setLoading(true);
    const response = await planDetails(
      type === COMPONENT_TYPES.ORGANIZATION
        ? id
        : user?.organization_details?.slug
    );
    setSubscriptionDetails(response);
    setLoading(false);
  };

  if (pageType === undefined) {
    navigate404();
  }
  useEffect(() => {
    if (!Object.values(COMPONENT_TYPES).includes(type)) {
      setPageType();
      return;
    }
    setPageType(type);
  }, [type]);

  const handleSetUsers = async () => {
    setLoading(true);
    const filterOptions = {
      search,
      // invite_type: undefined,
      // email_status: undefined,
    };
    if (filters.invitationStatus) {
      filterOptions.invite_status = filters.invitationStatus.toLowerCase();
    }
    if (filters.projectStatus) {
      filterOptions.project_status = filters.projectStatus;
    }
    if (filters.labProgress) {
      filterOptions.progress_status = filters.labProgress;
    }
    if (filters.labProgramProgress) {
      filterOptions.progress_status = filters.labProgramProgress;
    }
    if (filters.role) {
      filterOptions.role = filters.role;
    }
    if (filters.requestStatus) {
      filterOptions.request_status = filters.requestStatus.toLowerCase();
    }
    filterOptions["access_level"] = "team_leader";
    const data = await getMembers(null, null, {
      ...filterOptions,
      page: page + 1,
    });
    setData(data);
    if (data?.invitation_email) {
      const { body_content, subject } = data?.invitation_email;
      setAddData({
        subject_line: subject,
        email_body: body_content.replace(/\$organization_name/g, data?.title),
        role: roles[roles?.length - 1],
      });
    }
    setLoading(false);
  };
  const handleGetRoles = async () => {
    const _roles = await getRoles();
    setRoles(_roles);
  };

  const handleGetCsv = async () => {
    const csvData = await downloadSampleCsv();
    setCsv(csvData);
  };

  const emails =
    Array.isArray(networkUsers) &&
    networkUsers.reduce((acc, item) => {
      if (typeof item === "string" && item.includes("@")) {
        acc.push(item);
      } else if (typeof item === "object" && item.email) {
        acc.push(item.email);
      }
      return acc;
    }, []);

  const handleInviteUser = async () => {
    let _errors = validate();
    if (_errors.size || (!networkUsers?.length && !uploadedCSV)) return;
    const _payload = {
      ...addData,
      type: "invite",
      invite_type: uploadedCSV ? "csv" : "email",
      invite_status: "pending",
      invite_email: uploadedCSV ? uploadedCSV : emails,
      auto_invite: autoInvite,
    };
    if (type !== COMPONENT_TYPES.LAB) {
      _payload["role"] = "User";
    }
    if (type === COMPONENT_TYPES.PROJECT) {
      _payload["role"] = Array([...networkUsers]?.length || 1).fill(
        addData?.role?.toLowerCase()
      );
      if (addData?.skills) {
        _payload["skills"] = addData?.skills?.map((d) => d?.key);
      }
      _payload["recruiting_status"] = addData?.recruiting_status || "no";
    }
    if (type === COMPONENT_TYPES.ORGANIZATION) {
      _payload["role"] = addData?.role?.toLowerCase();
    }
    setLoading(true);
    await addMembers(_payload);
    await handleSetUsers();
    reset();
    setLoading(false);
  };

  const handleReInviteUser = async (user) => {
    const _payload = {
      type: "invite",
      invite_type: "email",
      invite_status: "pending",
      invite_email: [user.email],
      auto_invite: autoInvite,
      role: user?.role ?? "User",
    };

    if (type === COMPONENT_TYPES.PROJECT) {
      _payload["role"] = [_payload?.role?.toLowerCase()];
      _payload["recruiting_status"] = "no";
      _payload["auto_invite"] = "no";
    }

    setReInviteLoading(user.id);
    await addMembers(_payload);
    await handleSetUsers();
    reset();
    setReInviteLoading(false);
  };
  const handleDeleteUsers = async () => {
    setLoading(true);
    if (!allSelected) {
      let usersToDelete = Array.from(selected);
      await deleteMembers(usersToDelete);
    } else {
      await deleteAllMembers();
    }
    await handleSetUsers();
    setLoading(false);
    setSelected(new Set());
    setAllSelected(false);
    setOpenConfirmationModal(false);
  };

  const onSelectUser = (user) => {
    if (selected.has(user.email)) {
      selected.delete(user.email);
    } else {
      selected.add(user.email);
    }
    setSelected(new Set(selected));
  };

  const toggleSelect = () => {
    if (selected.size) {
      setSelected(new Set());
      setAllSelected(false);
    } else {
      setSelected(new Set(data?.users?.map((d) => d?.email)));
    }
  };

  const goToComponentDetailPage = () => {
    switch (type) {
      case COMPONENT_TYPES.ORGANIZATION:
        navigateOrg(id);
        break;
      case COMPONENT_TYPES.LAB:
        navigateLab(id);
        break;
      case COMPONENT_TYPES.CHALLENGE:
        navigateChallenge(id);
        break;
      case COMPONENT_TYPES.PROJECT:
        navigateProject(id);
        break;
      case COMPONENT_TYPES.LAB_PROGRAMS:
        navigateLabP(id);
        break;
      default:
        return;
    }
  };

  const onHandleUserRoleChange = async (selectedRole, user) => {
    const payload = {
      uuid: user?.id,
      role: selectedRole,
    };
    setLoading(true);
    await changeRole(payload, id, type);
    await handleSetUsers();
    setLoading(false);
  };

  const handleAddMembersToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const cancelExitConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const reset = () => {
    setNetworkUsers([]);
    setUploadedCSV(null);
    setAutoInvite("yes");
  };

  const handleBackButtonClick = () => {
    if (
      type === COMPONENT_TYPES.ORGANIZATION &&
      searchParams.get("onboardingCompleted") === "true"
    )
      navigateOrg(id);
    else navigateBack();
  };

  const handleApproveRequest = async (email) => {
    if (!allSelected) {
      const selectedArray = Array.from(selected);
      await handleAcceptMembersJoinRequest(email ? email : selectedArray);
    } else {
      await approveAllPendingRequests();
    }
    handleSetUsers();
    setSelected(new Set());
    setAllSelected(false);
  };

  const clearAllFilters = () => {
    setFilters({
      invitationStatus: "",
      projectStatus: "",
      labProgress: "",
      role: "",
      requestStatus: "",
      labProgramProgress: "",
      projectPermission: "",
    });
  };

  const userJSXColumns = useMemo(
    () =>
      [
        {
          title: t("Name"),
          render: (rowData) => {
            return (
              <div className="flex items-center fs-15 ">
                <input
                  type="checkbox"
                  checked={selected.has(rowData.email)}
                  onClick={() => onSelectUser(rowData)}
                />
                <img src={profile} className="ml-1" alt={rowData.name} />
                <div className="ml-1">
                  <p className="text-nowrap tracking-tight">
                    {rowData.name || "User"}
                  </p>
                  <span style={{ color: "#777986" }} className="fs-12 ">
                    {rowData.username || "-"}
                  </span>
                </div>
              </div>
            );
          },
        },
        type === COMPONENT_TYPES.PROJECT && {
          title: t("Username"),
          render: (rowData) => {
            return (
              <p className="w-200 text-truncate tracking-tight fs-14">
                {rowData?.username ?? "N/A"}
              </p>
            );
          },
        },
        type !== COMPONENT_TYPES.CHALLENGE && {
          title: t("Email"),
          render: (rowData) => {
            return (
              <p className="w-200 text-truncate tracking-tight fs-14">
                {rowData.email}
              </p>
            );
          },
        },
        (type === COMPONENT_TYPES.ORGANIZATION ||
          type === COMPONENT_TYPES.PROJECT) && {
          title: t(
            type === COMPONENT_TYPES.PROJECT ? "Project Permission" : "Role"
          ),
          render: (rowData) => {
            return type === COMPONENT_TYPES.ORGANIZATION ||
              type === COMPONENT_TYPES.PROJECT ? (
              <Select
                borderedIcon
                value={rowData.role}
                width={160}
                height={40}
                values={roles}
                name="role"
                classes="fs-14"
                t={(v) => t(v, { ns: "common" })}
                onChange={(e) =>
                  e.target.value &&
                  onHandleUserRoleChange(e.target.value, rowData)
                }
              />
            ) : (
              <span>{rowData.role}</span>
            );
          },
        },
        {
          title: t(
            type === COMPONENT_TYPES.CHALLENGE ? "Status" : "Invitation Status"
          ),
          render: (rowData) => {
            return (
              <span
                style={{
                  color: getStatusColor(rowData?.invite_status),
                }}
              >
                {t(rowData.invite_status ?? "-")}
              </span>
            );
          },
        },
        {
          title: t("Invitation Type"),
          render: (rowData) => {
            return <span>{t(rowData.invite_type)}</span>;
          },
        },
        type === COMPONENT_TYPES.CHALLENGE && {
          title: t("Project title"),
          render: (rowData) => {
            return <span>{rowData?.module_progress?.title ?? "-"}</span>;
          },
        },
        type === COMPONENT_TYPES.CHALLENGE && {
          title: t("Project Status"),
          render: (rowData) => {
            return (
              <span
                style={{
                  color:
                    rowData?.module_progress?.status ===
                    PROJECT_STATUS.SUBMITTED
                      ? COLORS.SUCCESS
                      : rowData?.module_progress?.status ===
                        PROJECT_STATUS.IN_PROGESS
                      ? COLORS.YELLOW
                      : COLORS.GRAY,
                }}
              >
                {t(
                  rowData?.module_progress?.status ===
                    PROJECT_STATUS.NOT_SUBMITTED
                    ? "Not Submitted"
                    : rowData?.module_progress?.status ===
                      PROJECT_STATUS.SUBMITTED
                    ? "Submitted"
                    : rowData?.module_progress?.status ===
                      PROJECT_STATUS.IN_PROGESS
                    ? "In Progress"
                    : rowData?.module_progress?.status ===
                      PROJECT_STATUS.NOT_STARTED
                    ? "Not Started"
                    : "-"
                )}
              </span>
            );
          },
        },
        (type === COMPONENT_TYPES.LAB ||
          type === COMPONENT_TYPES.LAB_PROGRAMS) && {
          title: t(
            type === COMPONENT_TYPES.LAB_PROGRAMS
              ? "Lab Program progress"
              : "Lab Progress"
          ),
          render: (rowData) => {
            return (
              <div className="flex items-center justify-between">
                <div
                  className={`capitalize fw-400 fs-12 tracking-tight`}
                  style={{
                    color:
                      rowData?.module_progress?.status ===
                      STATUS_TYPES.IN_PROGRESS
                        ? COLORS.BLUE
                        : rowData?.module_progress?.status ===
                          STATUS_TYPES.COMPLETED
                        ? COLORS.GREEN
                        : COLORS.GRAY,
                  }}
                >
                  {rowData?.module_progress && rowData?.module_progress?.status
                    ? `${t(
                        capitalize(
                          rowData?.module_progress?.status?.split("_").join(" ")
                        )
                      )} ${
                        rowData?.module_progress?.status ===
                        STATUS_TYPES.IN_PROGRESS
                          ? rowData?.module_progress?.percentage + "%"
                          : ""
                      }`
                    : "-"}
                </div>
              </div>
            );
          },
        },
        type === COMPONENT_TYPES.LAB && {
          title: t("Request Status"),
          render: (rowData) => {
            return (
              <div className="flex items-center">
                <span
                  style={{
                    color: getStatusColor(rowData?.request_status),
                  }}
                  className="text-nowrap"
                >
                  {t(rowData?.request_status) || "-"}
                </span>
              </div>
            );
          },
        },
        {
          title: t("Date"),
          render: (rowData) => (
            <span className="tracking-tight text-nowrap fs-14">
              {moment(rowData.joined_at).format("MMM DD, YYYY h:mmA")}
            </span>
          ),
        },
        {
          title: "",
          render: (rowData) => {
            return rowData?.request_status === REQUEST_STATUS.PENDING ? (
              <Button
                bordered
                classes=" fs-14"
                width={80}
                loading={loading === "approve"}
                onClick={() => handleApproveRequest([rowData?.email])}
              >
                {t("Approve")}
              </Button>
            ) : handleReInviteUser &&
              rowData?.invite_status === REQUEST_STATUS.DECLINED ? (
              <Button
                loading={reInviteLoading === rowData.id}
                bordered
                classes=" fs-14"
                width={80}
                onClick={() => handleReInviteUser(rowData)}
              >
                {t("Re-invite")}
              </Button>
            ) : null;
          },
        },
      ].filter(Boolean),
    [selected, data, t]
  );

  return (
    <Card centered classes="mt-20 mb-10 px-4 container">
      <div className="flex mt-4 relative">
        <Button
          height="35px"
          classes="absolute"
          bordered
          onClick={handleBackButtonClick}
        >
          <ArrowBack className="mr-1" /> {t("Back")}
        </Button>
        <h1 className="flex1 w-full text-center fs-30">
          {t("Member Management")}
        </h1>
      </div>
      <div className="pt-3 mb-10">
        <div className="my-5">
          <IconText
            icon={
              <p className="fw-600 fs-22 tracking-tight capitalize c-black">
                {t(
                  pageType.charAt(0).toUpperCase() +
                    pageType.slice(1).split("-").join(" ")
                )}{" "}
                :
              </p>
            }
            text={
              <p
                className="link fs-22 flex-center"
                onClick={goToComponentDetailPage}
              >
                {data?.title} <OpenInNew fontSize="small" className="ml-1" />
              </p>
            }
          />
          <p className="c-error fs-13">
            {t(
              "NOTE: ALL INVITATIONS ARE SENT/ SCHEDULED AT EASTERN STANDARD TIME (UTC-5)."
            )}
          </p>
        </div>

        {pageType !== COMPONENT_TYPES.PROJECT && (
          <div className="flex" onClick={handleAddMembersToggle}>
            <p>
              {t("Invite Members")} ({t("User limit")}:{" "}
              {subscriptionDetails?.user_invite_count ?? "0"}/
              {subscriptionDetails?.user_invite_limit ?? "0"}{" "}
              {subscriptionDetails?.plan !== "unlimited_plan" && "used"}
              {pageType === COMPONENT_TYPES.ORGANIZATION && (
                <>
                  ,{t("Manager limit")}:
                  {subscriptionDetails?.manager_count ?? "0"}/
                  {subscriptionDetails?.manager_limit ?? "0"}{" "}
                  {subscriptionDetails?.plan !== "unlimited_plan" && "used"}
                </>
              )}
              )
            </p>
            {isExpanded ? (
              <ExpandMore className="cursor-pointer" />
            ) : (
              <KeyboardArrowRight className="cursor-pointer" />
            )}
          </div>
        )}

        {isExpanded && (
          <AddMembers
            onSend={handleInviteUser}
            roles={roles}
            data={addData}
            onChange={onChange}
            errors={errors}
            disablePermissions={type !== "organization" && type !== "project"}
            networkUsers={networkUsers}
            setNetworkUsers={setNetworkUsers}
            csv={uploadedCSV}
            setCsv={setUploadedCSV}
            type={type}
            sampleCsv={csv}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            autoInvite={autoInvite}
            setAutoInvite={setAutoInvite}
            onCancel={reset}
            loading={loading}
            hrIntegrationsEnabled={isTrue(subscriptionDetails?.hr_integration)}
            t={t}
            id={data?.id}
          />
        )}

        <Divider classes="w-full" />
        <div className="mt-4">
          <h2>{t("Members With Access")}</h2>
          <Input
            height={36}
            icon={<SearchOutlined />}
            width="100%"
            iconLeft
            value={search}
            onChange={(e) => {
              setSearchUpdated(true);
              setSearch(e.target.value);
            }}
            placeholder={t("Search for a member in this ${type} by name...", {
              type: t(type),
            })}
            classes="w-full my-3"
            t={t}
          />

          <div className="mb-3 flex-colOnMob items-center w-full justify-between">
            <div className="flex-center w-full">
              <Select
                borderedIcon
                value={filters.invitationStatus}
                placeholder="Invitation Status"
                width="100%"
                values={["Invited", "Accepted", "Pending", "Declined"]}
                onChange={(e) =>
                  setFilters((prevState) => ({
                    ...prevState,
                    invitationStatus: e.target.value,
                  }))
                }
                height={40}
                classes="w-20p w-50p--mob"
                t={(v) => t(v)}
              />

              {(type === COMPONENT_TYPES.ORGANIZATION ||
                type === COMPONENT_TYPES.PROJECT) && (
                <Select
                  borderedIcon
                  value={filters.role}
                  placeholder={
                    type === COMPONENT_TYPES.PROJECT
                      ? "Project Permission"
                      : "Filter by role"
                  }
                  width="100%"
                  onChange={(e) =>
                    setFilters((prevState) => ({
                      ...prevState,
                      role: e.target.value,
                    }))
                  }
                  values={roles}
                  height={40}
                  classes="w-20p w-50p--mob mr-2"
                  t={(v) => t(v)}
                />
              )}
              {(type === COMPONENT_TYPES.LAB ||
                type === COMPONENT_TYPES.LAB_PROGRAMS) && (
                <Select
                  borderedIcon
                  value={
                    type === COMPONENT_TYPES.LAB
                      ? filters.labProgress
                      : filters.labProgramProgress
                  }
                  placeholder={
                    type === COMPONENT_TYPES.LAB
                      ? "Lab Progress"
                      : "Lab Program Progress"
                  }
                  width="100%"
                  keyValues={LAB_PROGRESS}
                  onChange={(e) =>
                    setFilters((prevState) => ({
                      ...prevState,
                      [type === COMPONENT_TYPES.LAB
                        ? "labProgress"
                        : "labProgramProgress"]: e.target.value,
                    }))
                  }
                  height={40}
                  classes={`w-50p--mob ${
                    type === COMPONENT_TYPES.LAB ? "w-20p" : "w-25p"
                  }`}
                  t={(v) => t(v)}
                />
              )}
              {type === COMPONENT_TYPES.LAB && (
                <Select
                  borderedIcon
                  value={filters.requestStatus}
                  placeholder="Request Pending"
                  width="100%"
                  values={["Invited", "Accepted", "Pending", "Declined"]}
                  onChange={(e) =>
                    setFilters((prevState) => ({
                      ...prevState,
                      requestStatus: e.target.value,
                    }))
                  }
                  height={40}
                  classes="w-20p w-50p--mob"
                  t={(v) => t(v)}
                />
              )}
              {type === COMPONENT_TYPES.CHALLENGE && (
                <Select
                  borderedIcon
                  value={filters.projectStatus}
                  placeholder="Project Progress"
                  width="100%"
                  keyValues={PROJECT_PROGRESS_STATUS}
                  onChange={(e) =>
                    setFilters((prevState) => ({
                      ...prevState,
                      projectStatus: e.target.value,
                    }))
                  }
                  height={40}
                  classes="w-20p w-50p--mob"
                  t={(v) => t(v)}
                />
              )}

              <p
                className="underline c-primary fw-700 ml-3 cursor-pointer"
                onClick={clearAllFilters}
              >
                {t("Clear filters")}
              </p>
            </div>
            <div className="flex">
              {(type === COMPONENT_TYPES.LAB ||
                type === COMPONENT_TYPES.LAB_PROGRAMS) &&
                isTrue(data?.privacy) && (
                  <Button
                    classes="mt-2--mob mr-1"
                    height={40}
                    color={selected.size === 0 ? "gray" : "primary"}
                    disabled={selected.size === 0}
                    onClick={() => handleApproveRequest(false)}
                  >
                    {t("Approve Request")}
                  </Button>
                )}
              <Button
                onClick={() => setOpenConfirmationModal(true)}
                bordered
                classes="mt-2--mob"
                color={selected.size === 0 ? "gray" : "red"}
                height={40}
                disabled={selected.size === 0}
              >
                <Delete className="mr-1" fontSize="small" /> {t("Remove")}
              </Button>
            </div>
          </div>
          <p className="underline cursor-pointer fw-700" onClick={toggleSelect}>
            {t(selected.size ? "Deselect" : "Select")} {t("All")}{" "}
          </p>
          {selected.size === PER_PAGE && data?.total_user_count > PER_PAGE && (
            <div className="flex-center justify-center mt-2 py-2 px-2 fs-13 bg-primary--opaque rounded-10 border-primary">
              <p>{t("All 10 Users on this page are selected.")}</p>
              <p
                className="link ml-1 underline"
                onClick={() => setAllSelected((prev) => !prev)}
              >
                {allSelected ? (
                  <div className="flex-center c-success">
                    <Done fontSize="small" />
                    {t("All ${count} selected", {
                      count: data?.total_user_count,
                    })}
                  </div>
                ) : (
                  t("Select all ${count} Users", {
                    count: data?.total_user_count,
                  })
                )}
              </p>
            </div>
          )}
          <div className="mt-3 mx-1 flex-center justify-between">
            <p>
              {data?.total_user_count ?? 0} {t("Users")}
            </p>
            <p>
              {selected.size ?? 0} {t("Selected")}
            </p>
          </div>
        </div>

        <TableComponent
          rows={data?.users || []}
          rowsPerPage={PER_PAGE}
          columns={userJSXColumns}
          paginator={!!data?.users?.length}
          totalCount={data?.total_user_count}
          page={page}
          setPage={setPage}
          titleCellClasses="c-gray"
          loading={loading}
          emptyView={
            <p>
              {t("Invite members to join your")}{" "}
              {t(capitalize(type.split("-").join(" ")))}{" "}
              {t("via network or email.")}
            </p>
          }
        />
      </div>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={cancelExitConfirmationModal}
        desc={`${t("Do you want to remove selected users from")} ${
          data?.title
        }?`}
        okayButtonTitle={t("Yes, remove now")}
        onOkay={handleDeleteUsers}
      />
    </Card>
  );
};

export default MemberManagementMainPage;
