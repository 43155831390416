import { useEffect, useState } from "react";
import styles from "./input.module.scss";
import { Chip, Box, Autocomplete, TextField } from "@mui/material";
import cx from "classnames";
import {
  createEvent,
  keyValueFormat,
  keyValueFormatWithData,
} from "helpers/utils/utilities";
import Spin from "components/Spin";
import { COLORS } from "helpers/utils/constants";
import { leaf4 } from "assets/svg/green";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Search } from "@mui/icons-material";

const DEBOUNCE_DELAY = 500;
let debounceTimer;

const SearchableSelect = ({
  hide,
  multi,
  value = multi ? [] : null, // Default to an empty array
  name,
  keyValues = [],
  placeholder,
  onChange,
  top,
  error,
  height,
  width,
  title,
  description,
  classes,
  required,
  borderedIcon,
  gray,
  disabled,
  fetch = [Function.prototype, ""],
  limit,
  template,
  hasIcon,
  t = (d) => d,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(keyValues);
  const [inputValue, setInputValue] = useState("");

  const handleSelectionChange = (e, newValue) => {
    if (!newValue) return;
    if (multi && newValue?.length > limit) return;
    onChange(createEvent(name, multi ? newValue : newValue || ""));
    setInputValue("");
  };

  const fetchOptions = async () => {
    const [_fetch, args] = fetch;
    if (!_fetch) return;
    setLoading(true);
    const res = await _fetch(
      args && Object.entries(args)?.length
        ? { ...args, search: inputValue }
        : inputValue
    );
    setLoading(false);
    let _options = res?.length ? keyValueFormat(res) : [];
    if (multi)
      _options = _options.filter(
        (option) =>
          !value?.some(
            (selectedOption) =>
              String(selectedOption?.key) === String(option?.key)
          )
      );
    if (name === "host" || name === "host_id") {
      _options = res?.length ? keyValueFormatWithData(res) : [];
    }
    if (template === "task") {
      const newTask = { value: "Custom Tasks and Questions", key: "custom" };
      _options = [newTask, ..._options];
    }
    setOptions(_options);
  };

  const fetchOptionsDebounced = () => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      fetchOptions();
    }, DEBOUNCE_DELAY);
  };

  useEffect(() => {
    fetchOptionsDebounced();
  }, [inputValue]);

  if (hide) return null;

  return (
    <div
      data-testid="select"
      style={{ marginTop: top }}
      className={cx(styles.inputContainer, classes)}
    >
      {!!title && (
        <p className={styles.title}>
          {t(title)}
          {!!required && <span>*</span>}
        </p>
      )}
      {!!description && <p className={styles.description}>{t(description)}</p>}
      <Autocomplete
        disabled={Boolean(disabled)}
        multiple={Boolean(multi)}
        options={options || []}
        className={cx(styles.inputContainer, styles.select, {
          [styles.error]: !!error,
        })}
        onInputChange={(_a, _b, c) =>
          c === "clear" && onChange(createEvent(name, multi ? [] : null))
        }
        inputValue={multi && inputValue}
        getOptionLabel={(option) => option?.value || ""}
        value={value}
        loading={loading}
        onChange={handleSelectionChange}
        onOpen={fetchOptions}
        filterOptions={(options) => options}
        popupIcon={!value?.length ? <ArrowDropDown /> : null}
        loadingText={
          <Box className="my-10 h-140 flex items-center justify-center w-full">
            <Spin big blue />
          </Box>
        }
        sx={{
          height: !!height && height,
          width: !!width && width,
          background: !!gray && "#f5f5f5",
          "& .MuiInputBase-root": {
            maxHeight: "110px",
            paddingLeft: "5px",
            overflowY: "auto",
            fontSize: "14px",
            "::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          },
        }}
        renderTags={(_comp, props) =>
          value?.map((option, index) => (
            <Chip
              label={option?.value}
              onDelete={disabled ? null : props({ index }).onDelete}
              key={option.key}
              sx={{
                height: "24px",
                fontSize: "0.75rem",
                margin: "1px",
                "& .css-i4bv87-MuiSvgIcon-root": {
                  border: "none!important",
                },
              }}
            />
          ))
        }
        noOptionsText={
          (!loading && options?.length === 0 && (
            <Box className="my-10 w-full flex flex-column items-center justify-center text-center">
              <img src={leaf4} height="100px" width="auto" alt="green leaf" />
              <p className="mt-3 fs-14 opacity-30 tracking-wide">
                {t("No data found!", "common")}
              </p>
            </Box>
          )) ||
          ""
        }
        renderInput={(params) => (
          <div className={`${hasIcon ? "flex items-center px-1" : ""}`}>
            {hasIcon && <Search className={styles.searchIcon} />}
            <TextField
              {...params}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
              placeholder={
                (!value || !value?.length) && !inputValue && t(placeholder)
              }
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ":
                  {
                    border: error
                      ? `1px solid ${COLORS.RED}`
                      : "1px solid #eee",
                  },
                ".MuiAutocomplete-inputRoot": {
                  padding: "0px 50px 0px 0px!important",
                  background: "#fff",
                  minHeight: "40px!important",
                },
                "& .css-i4bv87-MuiSvgIcon-root": {
                  border: "2px solid black",
                  width: "0.8em",
                  height: "0.8em",
                  color: "black",
                  borderRadius: "100%",
                },
                "& .MuiAutocomplete-clearIndicator": {
                  visibility: "visible !important",
                  color: "#000000 !important",
                },
                "& .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator ":
                  {
                    padding: multi && value && "0px",
                  },
              }}
            />
          </div>
        )}
        renderOption={(props, option) => (
          <div component="li" {...props} className={`${props.className}`}>
            {option?.value}
          </div>
        )}
        {...props}
      />
      {error && <p className={styles.error}>{t(error, "common")}</p>}
    </div>
  );
};

export default SearchableSelect;
