import { Avatar, Icon, Box, Grid } from "@mui/material";
import Button from "components/Button";
import TopRightEditIcon from "components/UI/atoms/TopRightEditIcon";
import React from "react";
import Card from "./Card";
import FileButton from "components/Input/FileButton";
import { EditNote } from "@mui/icons-material";
import "../../App.scss";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const ProfileCard = ({
  image,
  title,
  onEdit,
  shiftTop,
  buttons,
  subTitle,
  noEdit,
  defaultImage,
  editProfileImage,
  changeProfilePhoto,
  children = null,
  privacy,
  itsMe,
}) => {
  const { t } = useLocalizedTranslation();
  return (
    <Card
      data-testid="profile-card"
      classes={`relative text-center column-center px-2 ${
        !!shiftTop && "shift__top"
      }`}
    >
      {!noEdit && <TopRightEditIcon onEdit={onEdit} top={1} right={1} />}
      <Box
        className="relative cursor-pointer"
        sx={{
          width: "120px",
          height: "120px",
          "& .profileOverlay": { visibility: "hidden" },
          "&:hover .profileOverlay": { visibility: "visible" },
        }}
      >
        {editProfileImage && (
          <div
            className="profileOverlay absolute flex-column c-white justify-center items-center cursor-pointer rounded-60 w-100p h-100p z-50 "
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <FileButton onChange={changeProfilePhoto}>
              <EditNote fontSize="medium" />
            </FileButton>
          </div>
        )}
        <Avatar
          src={image}
          alt="profile"
          sx={{ height: "100%", width: "100%" }}
          className="border"
        >
          <img
            className="object-cover bg-white"
            height="100%"
            width="100%"
            src={defaultImage}
            alt={title}
          />
        </Avatar>
      </Box>

      <h2 className="fw-600 mt-4">
        {!itsMe && privacy === "private" ? "PreprLabs User" : title}
      </h2>
      {(itsMe || privacy !== "private") && (
        <>
          <p className="fs-18 fw-500 tracking-tight capitalize">{subTitle}</p>
          {buttons.length ? (
            <Grid container spacing={0.5} mt={1}>
              {buttons.map(
                ({ title, onClick, icon, bordered, props, children }, i) => (
                  <Grid item xs={12 / buttons?.length} key={i}>
                    {children ? (
                      children
                    ) : (
                      <Button
                        key={i}
                        classes={`fs-14 ${ buttons.length > 1 ? 'w-full' : 'mx-auto my-0' }`}
                        onClick={onClick}
                        bordered={bordered}
                        {...props}
                      >
                        {icon && (
                          <Icon
                            component={icon}
                            fontSize="small"
                            className="mr-1"
                          />
                        )}
                        {t(title)}
                      </Button>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          ) : (
            children
          )}
        </>
      )}
    </Card>
  );
};

export default ProfileCard;
