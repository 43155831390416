import { render, waitFor } from "@testing-library/react";
import {
    mockRequestGet,
  } from "../../mocks/request";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { initStore } from "../../mocks/store";
import { REDUCER_TYPES } from "../../index";
import userEvent from "@testing-library/user-event";
import { ENDPOINTS } from "store/api/endpoints";
import useResourceReport from "./service-hooks";




let getSpy;


let challengeId = "workforce-readiness-with-work-integrated-learning";


const headers = {
    Authorization: "Bearer 1",
};

const store = initStore({
    [REDUCER_TYPES.AUTH]: {
      language: "en",
      token: 1,
      user: { id: 1, email: "test@gmail.com" },
    },
    [REDUCER_TYPES.MASTER]: {},
  });



const ReportsResourceServicesMock = () => {
    const {
        resourceModuleMemberProgress,
        resourceGroupMemberProgress,
        resourceCollectionMemberProgress
    } = useResourceReport();
  
    const BUTTON_LINKS = [
      { name: "resourceModuleMemberProgress", onClick: () => resourceModuleMemberProgress(challengeId) },
      { name: "resourceGroupMemberProgress", onClick: () => resourceGroupMemberProgress(challengeId) },
      { name: "resourceCollectionMemberProgress", onClick: () => resourceCollectionMemberProgress(challengeId) },
    ];
  
    return (
      <div>
        {BUTTON_LINKS.map((item) => (
          <button data-testid={item.name} key={item.name} onClick={item.onClick}>
            Call {item.name}
          </button>
        ))}
      </div>
    );
  };

  let screen;


describe("Reports Resource services", () => {
    beforeEach(() => {
      screen = render(
        <Provider store={store}>
          <BrowserRouter>
            <ReportsResourceServicesMock />
          </BrowserRouter>
        </Provider>
      );
      getSpy = mockRequestGet();
    });
  
    test("resourceModuleMemberProgress", async () => {
      const button = screen.getByTestId("resourceModuleMemberProgress");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.RESOURCE_MODULE}/${challengeId}/${ENDPOINTS.MEMBER_PROGRESS}?language=en`,
          {},
          headers,
          expect.any(Function)
        )
      );
    });

    test("resourceGroupMemberProgress", async () => {
        const button = screen.getByTestId("resourceGroupMemberProgress");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.RESOURCE_GROUP}/${challengeId}/${ENDPOINTS.MEMBER_PROGRESS}?language=en`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });

      test("resourceCollectionMemberProgress", async () => {
        const button = screen.getByTestId("resourceCollectionMemberProgress");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.MANAGE}/${ENDPOINTS.REPORT}/${ENDPOINTS.RESOURCE_COLLECTION}/${challengeId}/${ENDPOINTS.MEMBER_PROGRESS}?language=en`,
            {},
            headers,
            expect.any(Function)
          )
        );
      });
     
      
})