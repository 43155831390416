// import GroupsIcon from "@mui/icons-material/Groups";
import BadgeIcon from "@mui/icons-material/Badge";
// import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
// import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import {
  Rocket,
  School,
  VolunteerActivism,
  Search,
} from "@mui/icons-material";
import { SvgIcon } from '@mui/material';
import { ReactComponent as Educator } from "../../assets/svg/blue/Educator.svg";
import { ReactComponent as Employee } from "../../assets/svg/blue/Employee.svg";
import { ReactComponent as Entrepreneur } from "../../assets/svg/blue/Entrepreneur.svg";
import { ReactComponent as Mentor } from "../../assets/svg/blue/Mentor.svg";

import user from "assets/svg/blue/user.svg";
import org from "assets/svg/yellow/org.svg";
import {
  USERNAME_EMAIL_VALIDATOR,
  REQUIRED_VALIDATOR,
} from "helpers/utils/validators";

export const LOGIN_FORM_SCHEMA = [
  {
    name: "email",
    validator: USERNAME_EMAIL_VALIDATOR,
  },
  {
    name: "password",
    validator: REQUIRED_VALIDATOR,
  },
];

export const login_response_data = {
  status: true,
  code: 1,
  message: "success",
  data: {
    logo: "assets/svg/logo.svg",
    language: "English",
    lang_symbol: "en",
    page_heading: "Login",
    form_title: "Login",
    input: [
      {
        title: "Email or Username",
        placeholder: "Enter Username/Email",
        name: "email",
        type: "email",
      },
      {
        title: "Password",
        placeholder: "Enter Password",
        name: "password",
        type: "password",
      },
    ],
    submit_button: {
      title: "Sign In",
    },
    register_text: "Create a new account?",
    register_details: {
      text: "Register",
      url: "#",
    },
    sso: [
    //   {
    //     title: "linkedin",
    //     icon: "assets/png/icons/linkedin-icon.png",
    //     url: "#",
    //     connection: "linkedin",
    //   },
      {
        title: "google",
        icon: "assets/png/icons/google-icon.png",
        url: "#",
        connection: "google-oauth2",
      },
      {
        title: "ms",
        icon: "assets/png/icons/ms-icon.png",
        url: "#",
        connection: "windowslive",
      },
      {
        title: "apple",
        icon: "assets/png/icons/apple-icon.png",
        url: "#",
        connection: "apple",
      },
      {
        title: "m",
        icon: "assets/png/icons/m-icon.png",
        url: "#",
        connection: "magnet",
      },
    ],
    slides: [
      {
        image: "assets/png/slide1.png",
        title: "Join Challenges, Labs, and Projects",
        description:
          "Solve real-world problems through challenge-based learning.",
        slide_icons: [
          "assets/png/slider-icon-1.png",
          "assets/png/slider-icon-2.png",
          "assets/png/slider-icon-3.png",
        ],
      },
      {
        image: "assets/png/slide2.png",
        title: "Showcase Your Skills and Portfolio",
        description:
          "Real world employers can view your profile and track your projects.",
        slide_icons: [
          "assets/png/slider-icon-4.png",
          "assets/png/slider-icon-3.png",
        ],
      },
      {
        image: "assets/png/slide3.png",
        title: "Grow Your Network",
        description:
          "Join existing teams and match with new teammates to collaborate on projects together.",
        slide_icons: [
          "assets/png/slider-icon-4.png",
          "assets/png/slider-icon-5.png",
        ],
      },
      {
        image: "assets/png/slide4.png",
        title: "Get Recognized",
        description:
          "Earn microcredentials and achievements to verify your abilities.",
        slide_icons: ["assets/png/slider-icon-6.png"],
      },
    ],
  },
};

export const USER_STATUS_NUMBER = [
  {
    value: "Looking for employers",
    key: 1,
  },
  {
    value: "Looking for Teams",
    key: 2,
  },
  {
    value: "Looking for Teammates",
    key: 3,
  },

  {
    value: "Looking for employees",
    key: 4,
  },
  {
    value: "Looking to invest",
    key: 5,
  },
  {
    value: "Looking for mentors",
    key: 6,
  },
  {
    value: "Looking for investors",
    key: 7,
  },
  {
    value: "Looking to create social impact",
    key: 8,
  },
  {
    value: "Looking to build skills",
    key: 9,
  },
  {
    value: "Looking to learn",
    key: 10,
  },
  {
    value: "Looking to solve problems",
    key: 11,
  },
];

export const USER_STATUS = [
  {
    value: "Looking for mentors",
    key: "looking_mentor",
  },
  {
    value: "Looking for teams",
    key: "looking_teams",
  },
  {
    value: "Looking for teammates",
    key: "looking_teammates",
  },
  {
    value: "Looking to invest",
    key: "looking_invest",
  },
  {
    value: "Looking for employees",
    key: "looking_employees",
  },
  {
    value: "Looking for employers",
    key: "looking_employers",
  },
  {
    value: "Looking to build skills",
    key: "looking_to_build_skills",
  },
  {
    value: "Looking to learn",
    key: "looking_to_learn",
  },
  {
    value: "Looking to solve problems",
    key: "looking_to_solve_problems",
  },
  {
    value: "Looking to create social impact",
    key: "looking_to_create_social_impact",
  },
  {
    value: "Looking for investors",
    key: "looking_for_investors",
  },
];

export const account_types = [
  { id: 1, title: "Create User Account", icon: user },
  { id: 2, title: "Register Organization", icon: org },
];

const iconStyle = {
  fontSize: {
    xs: 80,
    md: 120,
    lg: 130,
  },
};

const CustomIcon = ({ component: Component, ...props }) => (
    <SvgIcon component={Component} inheritViewBox sx={props.sx} />
);

export const user_types = [
  { id: "learner", title: "Learner", icon: <School sx={iconStyle} /> },
  {
    id: "job_seeker",
    title: "Job Seeker",
    icon: <Search sx={iconStyle} />,
  },
  {
    id: "employee",
    title: "Employee",
    icon: <CustomIcon component={Employee} sx={iconStyle} />
  },
  {
    id: "mentor",
    title: "Mentor",
    icon: <CustomIcon component={Mentor} sx={iconStyle} />
  },
  {
    id: "entrepreneur",
    title: "Entrepreneur",
    icon: <CustomIcon component={Entrepreneur} sx={iconStyle} />
  },
  // { id: "startup", title: "Start-up", icon: <Rocket sx={iconStyle} /> },
  {
    id: "educator",
    title: "Educator",
    icon: <CustomIcon component={Educator} sx={iconStyle} />
  },
  // {
  //   id: "others",
  //   title: "others",
  //   icon: <PersonSharpIcon sx={iconStyle} />,
  // },
];

export const organization_types = [
  {
    id: "enterprise",
    title: "Enterprise",
    icon: <ApartmentIcon sx={iconStyle} />,
  },
  {
    id: "small_business",
    title: "Small Business",
    icon: <BusinessCenterIcon sx={iconStyle} />,
  },
  {
    id: "startup",
    title: "Start Up",
    icon: <Rocket sx={iconStyle} />,
  },
  // {
  //   id: "community",
  //   title: "Community",
  //   icon: <GroupsIcon sx={iconStyle} />,
  // },
  {
    id: "ngo_non_profit",
    title: "NGO/ Non-profit",
    icon: <VolunteerActivism sx={iconStyle} />,
  },
  {
    id: "government",
    title: "Government",
    icon: <BadgeIcon sx={iconStyle} />,
  },
  {
    id: "educational_institution",
    title: "Educational Institutions",
    icon: <CorporateFareIcon sx={iconStyle} />,
  },
  // {
  //   id: "others",
  //   title: "Others",
  //   icon: <PersonSharpIcon sx={iconStyle} />,
  // },
];

export const guidance_steps = ["Name", "Username & Email", "Password"];

export const STARTPAGE_BACKUP = {
  skills: [
    { id: 1, title: "UX Design" },
    { id: 2, title: "Communication" },
    { id: 3, title: "Empathy" },
    { id: 4, title: "Problem Solving" },
    { id: 5, title: "Creativity" },
    { id: 6, title: "Curiosity" },
    { id: 7, title: "Leadership" },
    { id: 8, title: "Sales" },
    { id: 9, title: "Marketing" },
    { id: 10, title: "Collaboration" },
    { id: 11, title: "Marketing Strategy" },
    { id: 78, title: "Critical Thinking" },
  ],
  testimonials: [
    {
      id: 29,
      description:
        "Being able to create a remote, Agile, environment is something I've never done. I gained so much from doing that.",
      full_name: "Taylor",
    },
    {
      id: 15,
      description:
        "Now, I feel I can go into a job interview and feel very confident about the skills that I have.",
      full_name: "Dayna",
    },
    {
      id: 11,
      description:
        "Really, enjoyed the group challenges, and working through them with my group. That was really nice.",
      full_name: "Jerry",
    },
  ],
};
