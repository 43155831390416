import { AssignmentTurnedIn } from '@mui/icons-material'
import Card from 'components/Cards/Card'
import Divider from 'components/Divider'
import React from 'react'

const ConfirmationSuccess = () => {
    return (
        <>
            <Divider classes="my-2" />
            <Card width="90%" centered classes="column-center mt-4">
                <AssignmentTurnedIn style={{ fontSize: 70, color: "#498CCE" }} />
                <h2>Order Confirmed</h2>
                <p>Thank you for your purchase!</p>
                <Divider />
                {
                    Object.entries({ "Starter Plan": "$99/month", "Addon 1": "$500/month", "Addon 2": "$500/month", "Addon 3": "$500/month", "Addon 4": "$500/month" })
                        .map(([keyTerm, value], i) =>
                            <div key={i} className="flex items-stretch w-90p justify-between mt-1">
                                <small>{keyTerm}</small>
                                <small>{value}</small>
                            </div>
                        )
                }
                <div className="flex items-stretch w-90p justify-between mt-3">
                    <b>Total</b>
                    <b>$2099/month</b>
                </div>
                <Divider classes="my-2" />
                <p className='my-4 fs-14'>You will be charged <b>$2099</b> monthly on your Visa ending in 1234 on the 15th of every month.</p>
            </Card>
        </>
    )
}

export default ConfirmationSuccess