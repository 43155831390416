import {
  achievementSlice,
  achievement_schema,
} from "components/UI/molecules/AddAchievementSection";
import { SCHEMA_TYPES } from "helpers/utils/constants";
import { REQUIRED_VALIDATOR, TITLE_VALIDATOR } from "helpers/utils/validators";

export const schema = [
  {
    title: "Upload Image",
    name: "media",
    type: SCHEMA_TYPES.FILE,
    description: "The dimension needs to be 625 x 355 pixels.",
    dropboxProps: {
      width: "95%",
      fileType: "image",
      dimensions: [625, 355],
    },
    lg: 12,
  },
  {
    title: "Title",
    name: "title",
    type: SCHEMA_TYPES.INPUT,
    lg: 12,
    validator: TITLE_VALIDATOR,
    required: true,
  },
  {
    title: "Description",
    name: "description",
    type: SCHEMA_TYPES.EDITOR,
    lg: 12,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Category",
    name: "category_id",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
    filters: {
      component: "challenge",
    },
  },
  {
    title: "Level",
    name: "level_id",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Duration",
    name: "duration_id",
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Privacy",
    name: "privacy",
    type: SCHEMA_TYPES.SELECT,
    validator: REQUIRED_VALIDATOR,
    options: [
      { value: "Public", key: "no" },
      { value: "Private", key: "yes" },
    ],
  },
  {
    title: "Type",
    name: "type",
    type: SCHEMA_TYPES.SELECT,
    multi: true,
    placeholder: "Type",
    options: [
      { value: "Assess", key: "assess" },
      { value: "Onboard", key: "onboard" },
      { value: "Engage", key: "engage" },
      { value: "Grow", key: "grow" },
    ],
  },

  {
    title: "Mode",
    name: "mode",
    type: SCHEMA_TYPES.SELECT,
    multi: true,
    placeholder: "Mode",
    options: [
      { value: "Individual", key: "individual" },
      { value: "Teams", key: "team" },
    ],
  },

  {
    title: "Publish Status",
    name: "status",
    type: SCHEMA_TYPES.RADIO,
    validator: REQUIRED_VALIDATOR,
    options: [
      { value: "Publish on Platform", key: "publish" },
      { value: "Save as draft", key: "draft" },
    ],
  },
  {
    title: "Challenge Path Skills",
    name: "skills",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Skill Sets",
    name: "skill_stacks",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
  },
  {
    title: "Skill Groups",
    name: "skill_groups",
    type: SCHEMA_TYPES.MASTER,
    multi: true,
  },
  ...achievement_schema(true),
];

export const getSchemaByStep = (step) => {
  switch (step) {
    case 0:
      return [0, schema.findIndex((d) => d.name === "skill_groups") + 1];
    case 1:
      return [0, 0];
    case 2:
      return achievementSlice(schema);
    default:
      return [0, 0];
  }
};

export const stepSchema = (step) => {
  return schema.slice(...getSchemaByStep(step));
};
