import React, { useMemo, useState } from "react";
import useAdvanceSearch from "store/advance-search/service-hook";
import useAdvanceSearchFilter from "../hooks/useAdvanceSearchFilter";
import { AS_REDUCER } from "store/advance-search/reducer";
import { useAdvanceSearchContext } from "../hooks/useAdvanceSearchContext";
import { ADVANCE_SEARCH_COMPONENTS } from "../data";
import ProjectListing from "pages/projects/listing/ProjectListing";

const AdvanceSearchProjects = () => {
  const { gridType, keyword, getFiltersViaType, filters, removeKeys } =
    useAdvanceSearchContext();
  const { searchProjects } = useAdvanceSearch();

  const [page, setPage] = useState(1);
  const projectFilter = useMemo(() => {
    const data = getFiltersViaType(ADVANCE_SEARCH_COMPONENTS.PROJECTS);
    const formatted = {
      ...data,
      ...(data?.project_status ? { status: data?.project_status } : {}),
    };
    return removeKeys(formatted, ["project_status"]);
  }, [filters]);

  const { data } = useAdvanceSearchFilter(
    ADVANCE_SEARCH_COMPONENTS.PROJECTS,
    keyword,
    { page, ...projectFilter },
    searchProjects,
    AS_REDUCER.setProjects
  );

  return (
    <ProjectListing
      browseMode={0}
      projects={data?.data || []}
      gridType={gridType}
      noPagination={!Boolean(data?.meta ?? false)}
      page={data?.meta?.current_page ?? 1}
      setPage={setPage}
      pageCount={data?.meta?.total_pages ?? 1}
      loading={data?.loading}
    />
  );
};

export default AdvanceSearchProjects;
