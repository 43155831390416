import { Grid } from "@mui/material";
import UnifiedIntegrationCard, {
  UnifiedIntegrationCardSkeleton,
} from "../../../components/Cards/UnifiedIntegrationCard";
import useUnified from "../../../store/unified/service-hook";
import { useParams } from "react-router-dom";
import { UNIFIED_TYPE_USAGE_MAP } from "../../../store/unified/constants";
import { useEffect, useState } from "react";
import { COMPONENT_TYPES } from "helpers/utils/constants";

const HrIntegrations = ({ show, t = (d) => d }) => {
  const { listIntegrations, loadingIntegrations } = useUnified();
  const { id, type } = useParams();
  const [integrations, setIntegrations] = useState([]);
  const fetchIntegrations = async () => {
    const integrations = await listIntegrations({
      component_slug: id,
      usage_type:
        UNIFIED_TYPE_USAGE_MAP[type ?? COMPONENT_TYPES.ORGANIZATION] || "",
    });
    setIntegrations(integrations);
  };
  useEffect(() => {
    fetchIntegrations();
  }, []);

  return (
    <>
      {show ? (
        <>
          <p className="fs-14">
            {t(
              "We provide following third-party application integrations, you are able to invite your contacts to this"
            )}{" "}
            {type || COMPONENT_TYPES.ORGANIZATION}
          </p>
          <Grid container spacing={2} mt={1}>
            {!loadingIntegrations && integrations?.length
              ? integrations?.map((integration) => (
                  <Grid xs={12} md={4} item key={integration?.type}>
                    <UnifiedIntegrationCard
                      redirectUrl={`unified/${
                        type || COMPONENT_TYPES.ORGANIZATION
                      }/${id}/invite-members`}
                      integration={integration}
                      t={t}
                    />
                  </Grid>
                ))
              : ""}

            {loadingIntegrations
              ? Array.from(Array(6), (_, i) => (
                  <Grid xs={12} md={4} item key={i}>
                    <UnifiedIntegrationCardSkeleton />
                  </Grid>
                ))
              : ""}
          </Grid>{" "}
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default HrIntegrations;
