import { ChevronLeft } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, Rating, Tooltip } from "@mui/material";
import Button from "components/Button";
import ChipsPreview from "components/UI/atoms/ChipsPreview";
import ReadMore from "components/UI/atoms/ReadMore";
import SecureImage from "components/UI/atoms/SecureImage";
import { resource as resourceDefaultImg } from "assets/svg/purple";
import { formatDuration } from "./ResourceCard";
import { useState } from "react";

const ResourcePreview = ({
  resource,
  switchPreviewMode,
  handleCreateCourse,
  canCreateCourse
}) => {
  const [loading, setLoading] = useState(false);

  const onClickUse = async () => {
    setLoading(true);
    await handleCreateCourse(resource);
    setLoading(false);
  };
  return (
    <Box className="px-2">
      <IconButton onClick={switchPreviewMode} className="flex items-center justify-center mb-2">
        <ChevronLeft color="disabled" />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <SecureImage
            src={resource.image || resourceDefaultImg}
            placeholder={resourceDefaultImg}
            className="h-220 w-100p rounded-5 bg-gray rounded-10"
            alt={resource?.title}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <p className="fs-12 tracking-tight my-1 opacity-80 text-truncate">
            {`By ${resource?.provider?.name || "Unknown"}`}
          </p>
          <h3 className="text-truncate opacity-90">{resource?.title}</h3>
          <Rating value={+resource?.ratings?.five_star_rating} readOnly />
          <p className="fs-12 tracking-tight flex mt-1 opacity-80 text capitalize">
            {resource?.delivery?.duration === 0
              ? "Self-Paced"
              : formatDuration(resource?.delivery?.duration)}{" "}
            <span className="vertical-align-middle mx-1">&middot;</span>
            {resource?.type}{" "}
            <span className="vertical-align-middle mx-1">&middot;</span>
            {resource?.language}
          </p>

          <div className="flex-center">
            <p className="fs-12 fw-500 mr-1 mt-1 text-italic">Skills: </p>
            <ChipsPreview items={resource?.skills} limit={4} />
          </div>
          <div className="flex-center">
            <p className="fs-12 fw-500 mr-1 mt-1 text-italic">Tags:</p>
            <ChipsPreview items={resource?.tags} limit={4} />
          </div>
          {!canCreateCourse ? (
            <Tooltip sx={{ backgroundColor: "white" }} title="You don't have permissions to create the course!" placement="top-start"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
            >
              <span>
                <Button
                  classes="mt-2"
                  height="30px"
                  width="100px"
                  color="green"
                  disabled
                >
                  Use Course
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              classes="mt-2"
              loading={loading}
              height="30px"
              width="100px"
              color="green"
              onClick={onClickUse}
            >
              Use Course
            </Button>
          )
          }
        </Grid>
      </Grid>
      <Divider variant="fullWidth" className="my-2" />
      <ReadMore text={resource?.description} limit={10} />
    </Box>
  );
};

export default ResourcePreview;
