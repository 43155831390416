import Select from "components/Input/Select";
import React from "react";
import { LANGUAGES } from "helpers/utils/constants";
import useAuth from "store/auth/service-hook";

const LanguageSelector = ({ classes, value, onChange }) => {
  const { state } = useAuth();
  return (
    <Select
      classes={"w-full " + classes}
      title="Language"
      name="language"
      borderedIcon
      value={value || state.language}
      keyValues={LANGUAGES}
      onChange={onChange}
      width="100%"
    />
  );
};

export default LanguageSelector;
