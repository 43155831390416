import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { COLORS } from "helpers/utils/constants";
import { numberFormatter } from "helpers/utils/utilities";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const baseOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Bar Chart",
    },
  },
};

const BarChart = ({
  labels,
  data,
  color,
  labelFontSize,
  height,
  rounded = 0,
  barWidth = 8,
}) => {
  return (
    <Bar
      height={height}
      options={{
        ...baseOptions,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: labelFontSize || 12,
              },
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: labelFontSize || 12,
              },
              callback: (d) => numberFormatter(d),
            },
          },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            data,
            backgroundColor: color || COLORS.BLUE,
            borderRadius: rounded,
            animation: true,
            barThickness: barWidth,
          },
        ],
      }}
    />
  );
};

export default BarChart;
