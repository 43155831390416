import Card from "../../../components/Cards/Card";
import { Grid } from "@mui/material";
import UserCard from "../../../components/UI/atoms/UserCard";
import { useEffect, useState } from "react";
import UserAwardsPopups from "../../../components/UI/molecules/UserAwardsPopups";
import useMembers from "store/member-management/service-hook";
import ListingContainer from "components/UI/organisms/ListingContainer";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { COMPONENT_TYPES } from "helpers/utils/constants";

const Members = ({ component, dataList }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [members, setMembers] = useState([]);
  const { getMembers } = useMembers();
  const { t } = useLocalizedTranslation("common", true);

  useEffect(() => {
    if (!component) return;
    handleSetMembers();
  }, [page]);

  const handleGiveAwardClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSetMembers = async () => {
    if (dataList) {
      setMembers([...members, ...dataList]);
      return;
    }
    setLoading(true);
    const data = await getMembers(null, component, {
      invite_status: "accepted",
      detail_view: "yes",
      page,
    });
    setLoading(false);
    setMembers(data?.users || []);
    setPages(data?.total_pages);
  };

  return (
    <Card classes="mt-4 px-6">
      <h2 className="mb-2">{t("Members", "common")}</h2>

      <ListingContainer
        page={page}
        setPage={setPage}
        count={pages}
        emptyText={t("No members found!", "common")}
        loading={loading}
        isEmpty={
          !members ||
          !members?.length ||
          members.reduce(
            (total, challenge) => total + challenge.members_count,
            0
          ) === 0
        }
        spinLoader
        classes="minh-400 mt-5 px-0"
      >
        {component === COMPONENT_TYPES.CHALLENGE_PATH ? (
          <>
            {members?.map(
              (challenge, index) =>
                challenge.members?.length > 0 && (
                  <div key={index}>
                    <h3 className="mb-2">{challenge?.title}</h3>
                    <Grid
                      container
                      item
                      xs={12}
                      rowSpacing={2}
                      columnSpacing={4}
                      className="h-auto"
                    >
                      {challenge?.members?.map((member, index) => (
                        <Grid item xs={12} sm={6} md={4} mb={2} key={index}>
                          <UserCard
                            member={member}
                            onGiveAwardClick={handleGiveAwardClick}
                            hideGiveAwardButton
                            hideRole
                            showRankAndMessageBox
                            t={t}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )
            )}
          </>
        ) : (
          <Grid
            container
            item
            xs={12}
            rowSpacing={2}
            columnSpacing={3}
            className="h-auto"
          >
            {members?.map((member, index) => (
              <Grid item xs={12} sm={6} md={4} mb={2} key={index}>
                <UserCard
                  member={member}
                  onGiveAwardClick={handleGiveAwardClick}
                  hideGiveAwardButton
                  hideRole
                  showRankAndMessageBox
                  t={t}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </ListingContainer>
      <UserAwardsPopups open={isModalOpen} onClose={handleCloseModal} />
    </Card>
  );
};

export default Members;
