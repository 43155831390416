import React, { useEffect, useState } from "react";
import { PushPin, Delete, AddBox, Close } from "@mui/icons-material";
import { Box, Chip, Grid, IconButton } from "@mui/material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ListingContainer from "components/UI/organisms/ListingContainer";
import SearchAndSort from "./SearchAndSort";
import ModalLayout from "components/Modal";
import MasterSelect from "components/UI/organisms/MasterSelect";
import { keyValueFormat } from "helpers/utils/utilities";
import { useDispatch } from "react-redux";
import ACTIONS from "store/profile/action-types";
import { REDUCER } from "store/profile/reducer";
import { COLORS } from "helpers/utils/constants";
import EmptyText from "components/UI/atoms/EmptyText";
import { toast } from "react-toastify";
import useRedirect from "helpers/hooks/useRedirect";

const SkillsAndTags = ({
  items = {},
  pinned = {},
  isSkill,
  deleteItem,
  setProfile,
  itsMe,
  onSubmit,
  masterList,
  verified_skills = [],
  t = (d) => d,
}) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [manageOpen, setManageOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [sortOption, setSortOption] = useState("creation_date");
  const [initState, setInitState] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const { navigateSkill } = useRedirect();

  useEffect(() => {
    const updatedList =
      Object.entries(items)
        .map(([key, value]) => ({ key: +key, value }))
        .filter((d) => !!d?.key) || [];

    let updatedFeatured = [];
    if (isSkill) {
      updatedFeatured =
        Object.entries(pinned || {})
          .map(([key, value]) => ({ key: +key, value, pinned: true }))
          .filter((d) => !!d?.key) || [];
    }
    setNewItems([...updatedFeatured, ...updatedList]);
    setInitState([...updatedFeatured, ...updatedList]);
  }, [items, isSkill]);

  const handleDelete = () => {
    const itemToDelete = initState.find((item) => item.key === selectedItemId);
    if (!itemToDelete) return;
    setNewItems((prev) => prev.filter((item) => item.key !== selectedItemId));
    setInitState((prev) => prev.filter((item) => item.key !== selectedItemId));
    deleteItem(Number(selectedItemId));
    const propertyToUpdate = !isSkill
      ? "tags"
      : itemToDelete.pinned
      ? "user_pinned_skills"
      : "user_skills";
    setProfile((prevProfile) => {
      const updatedProperty = { ...prevProfile[propertyToUpdate] };
      delete updatedProperty[selectedItemId];
      return {
        ...prevProfile,
        [propertyToUpdate]: updatedProperty,
      };
    });
    setOpenConfirmationModal(false);
    setSelectedItemId(null);
  };
  const showDeleteConfirmation = (itemId) => {
    setSelectedItemId(itemId);
    setOpenConfirmationModal(true);
  };

  const getSortedItems = () => {
    const sortedItems = initState?.filter(
      (item) =>
        !searchText ||
        item?.value?.toLowerCase()?.includes(searchText.trim().toLowerCase())
    );

    switch (sortOption) {
      case "name-a-to-z":
        return sortedItems.sort((a, b) => a.value.localeCompare(b.value));
      case "name-z-to-a":
        return sortedItems.sort((a, b) => b.value.localeCompare(a.value));
      case "creation_date":
        return sortedItems.sort((a, b) => b.created_date - a.created_date);
      default:
        return sortedItems;
    }
  };

  const handleOpenManageModal = () => {
    setManageOpen(true);
    setNewItems([...initState]);
  };

  const onClickPin = (id, unpin, localUpdate) => {
    if (checkPinnedCount(newItems, 3) && !unpin) {
      toast.warn("You have reached the maximum number of pinned skills.");
      return;
    }
    const items = initState.map((item) =>
      item.key === id ? { ...item, pinned: !unpin } : item
    );
    if (!localUpdate) {
      const _list = items?.filter((d) => !d?.pinned)?.map((f) => f?.key);
      const _pinned = items?.filter((d) => !!d?.pinned)?.map((f) => f?.key);
      onSubmit(_list, _pinned);
      updateProfile(items);
    }
    setInitState([...items]);
    setNewItems([...items]);
  };

  function checkPinnedCount(data, expectedCount = 3) {
    const pinnedCount = data.filter((item) => item.pinned === true).length;
    return pinnedCount === expectedCount;
  }

  const handleChange = (e) => {
    const newValues = e.target.value;
    setNewItems((prev) => [
      ...prev.filter((d) => newValues?.some((f) => f?.key === d?.key)),
      ...newValues
        .filter((d) => !prev.some((f) => f?.key === d?.key))
        ?.map((g) => masterList.find((h) => h?.key === g?.key)),
    ]);
  };

  const updateProfile = (_list) => {
    const _newItems = _list || newItems;
    const createObj = (items = []) => {
      let obj = {};
      [...items]?.forEach(({ value, key }) => {
        obj[key] = value;
      });
      return obj;
    };

    setProfile((prev) => {
      const updatedProfile = {
        ...prev,
        tags: !isSkill ? createObj(_newItems) : prev?.tags,
        user_pinned_skills: isSkill
          ? createObj(_newItems?.filter((d) => !!d?.pinned))
          : prev?.user_pinned_skills,
        user_skills: isSkill
          ? createObj(_newItems?.filter((d) => !d?.pinned))
          : prev?.user_skills,
      };
      dispatch(REDUCER[ACTIONS.PROFILE](updatedProfile));
      return updatedProfile;
    });
  };
  const handleSubmit = async () => {
    const _list = newItems
      ?.filter((d) => !isSkill || !d?.pinned)
      ?.map((f) => f?.key);
    const _pinned = isSkill
      ? newItems?.filter((d) => !!d?.pinned)?.map((f) => f?.key)
      : undefined;
    setLoading(true);
    await onSubmit(_list, _pinned);
    updateProfile();
    setLoading(false);
    setManageOpen(false);
  };

  return (
    <>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        desc={t(
          `You will lose this ${
            isSkill ? "skill" : "tag"
          } if you remove it now, are you sure?`
        )}
        okayButtonTitle={t("Remove")}
        onOkay={handleDelete}
      />
      <Card classes="mt-4">
        <div className="flex items-center mb-3 justify-between">
          <h2>{isSkill ? t("Skills") : t("Tags")}</h2>
          {itsMe && (
            <Button bordered onClick={handleOpenManageModal}>
              <AddBox />{" "}
              <span className="ml-1">
                {t(isSkill ? "Add Skill" : "Add Tag")}
              </span>
            </Button>
          )}
        </div>

        {initState && initState?.length > 0 && (
          <SearchAndSort
            searchText={searchText}
            setSearchText={setSearchText}
            sortOption={sortOption}
            placeholder={t(!!isSkill ? "search skills..." : "search tags...")}
            setSortOption={setSortOption}
            t={t}
          />
        )}

        {itsMe && initState && initState?.length > 0 && (
          <p className="opacity-60 mt-4 fs-14">
            {t("Select up to 3 skills to feature them on your profile.")}
          </p>
        )}
        <ListingContainer
          isEmpty={
            getSortedItems().length === 0 || !initState || !initState?.length
          }
          page={currentPage}
          setPage={setCurrentPage}
          count={Math.ceil(getSortedItems().length / itemsPerPage)}
          classes="mt-2"
          emptyView={
            <EmptyText>
              {t(
                getSortedItems().length === 0 && initState && initState?.length
                  ? `No result found, please try different keywords`
                  : itsMe
                  ? `Add ${isSkill ? "Skills" : "Tags"} to display them here`
                  : `This user has not included any ${
                      isSkill ? "Skills" : "Tags"
                    } on the profile`
              )}
            </EmptyText>
          }
        >
          <Grid container item xs={12} rowSpacing={1}>
            {getSortedItems()
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((item, index) => (
                <Grid
                  container
                  item
                  key={index}
                  sx={{
                    "& .deleteIcon": {
                      visibility: !isSkill ? "hidden" : "visible",
                    },
                    "&:hover .deleteIcon": { visibility: "visible" },
                    "& .pinned": { display: "flex" },
                    "&:hover .pinned": {
                      display: !!itsMe && !isSkill ? "none" : "flex",
                    },
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      "&:hover": {
                        backgroundColor: COLORS.LIGHT_GRAY,
                        cursor: "pointer",
                      },
                    }}
                  >
                    <div
                      className={`flex items-center rounded-5 fw-500 justify-between pl-4 py-2 c-primary border`}
                    >
                      <p onClick={() => navigateSkill(item?.key)}>
                        {item.value}
                      </p>
                      {!!itsMe && !isSkill && (
                        <IconButton
                          className="deleteIcon"
                          onClick={() => showDeleteConfirmation(item.key)}
                        >
                          <Delete className="c-error" />
                        </IconButton>
                      )}
                      {!!itsMe && isSkill && (
                        <PushPin
                          onClick={() => onClickPin(item.key, item.pinned)}
                          style={{
                            color: item?.pinned ? "#FFBC3F" : "#B3B5BD",
                            fill: item?.pinned ? "#FFBC3F" : "none",
                            stroke: item?.pinned ? "#FFBC3F" : "#B3B5BD",
                          }}
                          className="mr-2 cursor-pointer rotate-45"
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </ListingContainer>

        <div className="mt-4">
          <h2>{t("Verified Skills")}</h2>
          {itsMe && (
            <p className="fs-14 opacity-60 mt-1">
              {t(
                "After you complete a Challenges & Labs, the associated skills become verified."
              )}{" "}
            </p>
          )}
          <ListingContainer
            isEmpty={verified_skills.length === 0}
            classes="mt-5"
            noPagination
            emptyView={
              itsMe ? null : (
                <EmptyText>
                  {t("This user has not verified any Skills")}
                </EmptyText>
              )
            }
          >
            <div className="flex flex-wrap items-center ml-3">
              {verified_skills.map((d) => (
                <>
                  <Chip
                    key={d.id}
                    label={`${d?.title ?? "N/A"} +${
                      d?.verification_count ?? "0"
                    }`}
                    variant="filled"
                    sx={{
                      borderRadius: "10px",
                      color: COLORS.DARK_GRAY,
                      backgroundColor: COLORS.LIGHT_GRAY,
                      border: "1px solid #D2D4DA",
                      margin: "0 16px 16px 0",
                    }}
                  />
                </>
              ))}
            </div>
          </ListingContainer>
        </div>
      </Card>
      <ModalLayout
        open={manageOpen}
        width="min(95%,900px)"
        onClose={() => setManageOpen(false)}
      >
        <div className="px-2 py-2">
          <h2>
            {t("Add")} {isSkill ? t("Skills") : t("Tags")}
          </h2>
          <MasterSelect
            title={`Select ${isSkill ? "Skills" : "Tags"}`}
            borderedIcon
            name={isSkill ? "skills" : "tags"}
            value={keyValueFormat(newItems)}
            onChange={handleChange}
            classes="w-full mt-5"
            width="99%"
            multi
            hasIcon
            t={t}
          />
          <Box sx={{ gap: 0.5 }} className="flex flex-wrap mt-2">
            {newItems
              ?.sort((a, b) => a?.pinned > !b?.pinned)
              ?.map((item) => (
                <Chip
                  key={item?.key}
                  label={item?.value}
                  variant="filled"
                  className="mr-1 bg-primary rounded-10 c-white tracking-tight fw-500 fs-14 h-30"
                  onDelete={
                    isSkill
                      ? () =>
                          setNewItems((prev) =>
                            prev.filter((d) => d.key !== item?.key)
                          )
                      : null
                  }
                  deleteIcon={
                    <Close fontSize="small" className="c-white fs-18" />
                  }
                />
              ))}
          </Box>

          <div className="flex justify-between mt-6">
            <Button bordered onClick={() => setManageOpen(false)}>
              {t("Cancel")}
            </Button>
            <Button onClick={handleSubmit} loading={loading} width="100px">
              {t("Save")}
            </Button>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default SkillsAndTags;
