import Card from "components/Cards/Card";
import EditCreateHeaderCard from "components/Cards/EditCreateHeaderCard";
import DashboardLayout from "pages/layouts/dashboard";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { schema, stepSchema } from "./data";
import useForms from "helpers/hooks/useForms";
import {
  deleteValues,
  keyVal,
  payloadFormatArray,
  payloadKeyVal,
  payloadKeyVals,
  payloadToArrayKeyValues,
} from "helpers/utils/utilities";
import CreateFormFooter from "components/UI/atoms/CreateFormFooter";
import useResources from "store/resources/service-hook";
import useResourceGroups from "store/resource-groups/service-hook";
import ResourceGroupDetails from "./ResourceGroupDetails";
import { Grid } from "@mui/material";
import PageTitle from "components/UI/atoms/PageTitle";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import AddAchievementSection from "components/UI/molecules/AddAchievementSection";
import AssociatedListing from "components/UI/organisms/AssociatedListing";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { toast } from "react-toastify";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import useAssociation from "store/association";
import { ENDPOINTS } from "store/api/endpoints";
import { isTrue } from "helpers/utils/validators";
import useDebounce from "helpers/hooks/useDebounce";
const STEPS = [
  "Resource Group Details",
  "Achievements",
  "Associated Resources",
];

const CreateEditResourceGroup = () => {
  const { t } = useLocalizedTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const { id } = useParams();
  const editMode = !!id;
  const [loading, setLoading] = useState(false);
  const [experts, setExperts] = useState([]);
  const [initLoading, setInitLoading] = useState(Boolean(editMode));
  const [resources, setResources] = useState({ all: [], selected: [] });
  const { fetchMyResourceGroup, edit, create } = useResourceGroups();
  const { fetchResourceSelectList } = useResources();
  const { fetchAssociation } = useAssociation();
  const [resourceSearchText, setResourceSearchText] = useState("");
  const [fetchResourceLoading, setFetchResourceLoading] = useState(false);

  const sliceSchema = (_schema) => stepSchema(currentStep);

  const { data, errors, validate, onChange, setData } = useForms(
    schema,
    {},
    sliceSchema
  );

  useEffect(() => {
    if (editMode) handleFetchDetail();
    else {
      setData({});
      setCurrentStep(0);
      setResources({ all: [], selected: [] });
      setExperts([]);
    }
  }, [id, editMode]);

  useEffect(() => {
    handleFetchResources();
  }, [editMode]);

  const handleFetchResources = async (query) => {
    setFetchResourceLoading(true);
    const filters = query ? { search: query } : {};
    const res = await fetchResourceSelectList(false, filters);
    const res2 = await fetchResourceSelectList(true, filters);

    setResources({
      all: [
        ...res?.map((item) => ({ ...item, type: "module" })),
        ...res2?.map((item) => ({ ...item, type: "collection" })),
      ],
      selected: [],
    });

    setFetchResourceLoading(false);
  };

  const debouncedSearchText = useDebounce(resourceSearchText, 300); // Adjust delay as needed
  useEffect(() => {
    handleFetchResources(debouncedSearchText);
  }, [debouncedSearchText]);

  const handleSearch = (searchText) => {
    setResourceSearchText(searchText);
  };

  const handleFetchDetail = async () => {
    const res = await fetchMyResourceGroup(id);
    if (res) {
      const {
        title,
        description,
        skills = {},
        skill_groups = {},
        skill_stacks = {},
        category_id,
        level_id,
        level,
        duration,
        duration_id,
        id,
        privacy,
        cover_image,
        achievement = {},
        mode,
        type,
        category,
        media_type,
        slug,
        expert_references = [],
      } = res;
      setData({
        title,
        description,
        level: keyVal(level, level_id),
        duration: keyVal(duration, duration_id),
        skills: payloadToArrayKeyValues(skills),
        skill_groups: payloadToArrayKeyValues(skill_groups),
        skill_stacks: payloadToArrayKeyValues(skill_stacks),
        category_id: keyVal(category, category_id),
        id,
        slug,
        privacy: isTrue(privacy) ? "private" : "public",
        cover_image: media_type === "image" ? cover_image : null,
        ...achievement,
        mode,
        type,
        media_type,
        iframe_code: media_type === "embedded" ? cover_image : null,
      });

      setExperts(expert_references);
      const [resourceModules] = await fetchAssociation(
        COMPONENT_TYPES.RESOURCE_GROUP,
        slug,
        ENDPOINTS.RESOURCE_MODULE
      );
      const [resourceCollections] = await fetchAssociation(
        COMPONENT_TYPES.RESOURCE_GROUP,
        slug,
        COMPONENT_TYPES.RESOURCE_COLLECTION
      );

      setResources((val) => ({
        all: val.all,
        selected: [
          ...(resourceModules || []).map((item) => ({
            uuid: item.id,
            title: item.title,
            media: item.cover_image,
            type: "module",
          })),
          ...(resourceCollections || []).map((item) => ({
            uuid: item.id,
            title: item.title,
            media: item.cover_image,
            type: "collection",
          })),
        ],
      }));
      setInitLoading(false);
    }
  };
  const handleEditCreate = async () => {
    setLoading(true);
    const payload = {
      ...data,
      resource_ids: resources?.selected
        .filter((d) => d.type === "module")
        .map((d) => d.uuid),
      resource_collection_ids: resources?.selected
        .filter((d) => d.type === "collection")
        .map((d) => d.uuid),
      media_type: data?.iframe_code ? "embedded" : "image",
      cover_image: data?.iframe_code ? data?.iframe_code : data?.cover_image,
      expert_references: payloadFormatArray(experts, "email"),
    };
    if (
      !payload?.resource_ids?.length &&
      !payload?.resource_collection_ids?.length
    ) {
      toast.error(
        t(
          "Please select at least one resource module and one resource collection"
        )
      );
      setLoading(false);
      return;
    }
    payloadKeyVal(payload, ["level", "duration", "category_id"]);
    payloadKeyVals(payload, ["skills", "skill_groups", "skill_stacks"]);
    const deleteVals = ["iframe_code"];
    if (editMode) {
      if (
        typeof payload?.cover_image === "string" &&
        payload.media_type === "image"
      )
        deleteVals.push(...["cover_image", "media_type"]);
      if (typeof payload?.achievement_image === "string")
        deleteVals.push("achievement_image");
    }

    if (!data.skill_groups || !data.skill_groups.length) {
      deleteVals.push("skill_groups");
    }
    if (!data.skill_stacks || !data.skill_stacks.length) {
      deleteVals.push("skill_stacks");
    }
    if (!payload.cover_image) deleteVals.push(...["cover_image", "media_type"]);
    deleteValues(payload, deleteVals);
    editMode ? await edit(id, payload) : await create(payload);
    setLoading(false);
  };

  const onCancel = () => setCurrentStep((prev) => prev - 1);
  const onContinue = async () => {
    const _errors = await validate();
    if (_errors.size) return;
    if (currentStep === 2) {
      handleEditCreate();
      return;
    }
    setCurrentStep((c) => c + 1);
  };
  return (
    <DashboardLayout loading={initLoading}>
      <EditCreateHeaderCard
        current={currentStep}
        length={3}
        title={`${editMode ? "EDIT" : "CREATE A"} RESOURCE GROUP`}
        subtitles={STEPS}
        classes="mt-header"
      />
      <Card
        width="min(1300px,90%)"
        centered
        classes="mt-6 mb-4 mx-auto coulmn-center"
      >
        {currentStep === 0 && (
          <ResourceGroupDetails
            data={data}
            errors={errors}
            onChange={onChange}
            step={currentStep}
            experts={experts}
            setExperts={setExperts}
          />
        )}
        {currentStep === 1 && (
          <Grid container px={{ xs: 0, md: 4 }}>
            <PageTitle>{t("Achievements")}</PageTitle>
            <CreateFormSection classes="w-full mt-4">
              <AddAchievementSection
                data={data}
                errors={errors}
                onChange={onChange}
              />
            </CreateFormSection>
          </Grid>
        )}
        {currentStep === 2 && (
          <>
            <h2 className="w-full text-center mb-8">
              {t("Associated Resources")}
            </h2>
            <AssociatedListing
              title={t("Browse Resources to add to your Resource Group")}
              component="resources"
              tabs={["All", "RESOURCE MODULES", "RESOURCE COLLECTION"]}
              list={resources}
              setList={setResources}
              type={COMPONENT_TYPES.RESOURCE}
              filterByTab={(item, tab) =>
                tab === 0 ||
                (tab === 1 && item.type === "module") ||
                (tab === 2 && item.type === "collection")
              }
              onSearch={handleSearch}
              isLoading={fetchResourceLoading}
            />
          </>
        )}
        <CreateFormFooter
          step={currentStep}
          stepsLength={STEPS.length}
          onCancel={onCancel}
          disabled={!!errors.size}
          loading={loading}
          onOk={onContinue}
          editMode={editMode}
          backButtonColor="blue"
          okButtonColor="blue"
        />
      </Card>
    </DashboardLayout>
  );
};

export default CreateEditResourceGroup;
