import { Container } from "@mui/material";
import React, { useState } from "react";
import { PRIVACY_DATA } from "../data";
import { SettingItem } from "./AccountSettings";
import Button from "components/Button";
import useAuth from "store/auth/service-hook";
import ConfirmationModal from "components/Modal/ConfirmationModal";

const PrivacySettings = ({ privacy, onChange, deactivate, errors, t=d=>d }) => {
  const [loading, setLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { logout } = useAuth();
  const handleDeactivation = async () => {
    setLoading(true);
    await deactivate();
    setLoading(false);
    logout();
  };

  const openExitConfirmationModal = () => {
    setOpenConfirmationModal(true);
  };

  const cancelExitConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  return (
    <Container px={{ xs: 0, md: 2 }} my={2}>
      <h2>{t("Privacy")}</h2>
      {PRIVACY_DATA?.map(
        ({ title, name, description, required, options }, i) => (
          <SettingItem
            name={name}
            onChange={onChange}
            options={options}
            title={title}
            description={description}
            classes="my-6"
            key={i}
            error={errors[name]}
            required={required}
            value={privacy?.[name]}
            t={t}
          />
        )
      )}
      <div className="mt-10 mb-6 flex items-center justify-center">
        <Button
          bordered
          loading={loading}
          width="170px"
          onClick={openExitConfirmationModal}
        >
          {t("Deactivate Account")}
        </Button>
      </div>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={cancelExitConfirmationModal}
        desc="Are you sure you want to deactivate your account?"
        okayButtonTitle="Deactivate Account"
        onOkay={handleDeactivation}
      />
    </Container>
  );
};

export default PrivacySettings;
