import React, { useState } from "react";
import defaultImage from "assets/png/placeholder.png";

const SecureImage = ({
  src,
  placeholder,
  height,
  width,
  alt = "image",
  type,
  classes,
  containerClasses,
  title,
  small,
  imgAbsoluteContent,
  smaller,
  titleClasses = "",
  ...rest
}) => {
  const [isFallback, setIsFallback] = useState(false);
  return (
    <div className={`relative h-full ${containerClasses}`}>
      <img
        src={src ?? placeholder}
        alt={alt}
        className={classes}
        height={height}
        width={width}
        {...rest}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = placeholder ?? defaultImage;
          setIsFallback(true);
        }}
      />
      {imgAbsoluteContent
        ? imgAbsoluteContent
        : (isFallback || !src) && type !== 'user' &&
          title && (
            <div className="absolute flex items-center w-100p h-full top-0 left-0 justify-center">
              <h3
                className={`uppercase tracking-tight text-center mx-auto text-truncate maxw-90p fw-600 ${titleClasses} ${
                  smaller ? "fs-10 mb-1" : small ? "fs-13" : "fs-16"
                }`}
              >
                {title}
              </h3>
            </div>
          )}
    </div>
  );
};

export default SecureImage;
