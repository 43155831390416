import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { serializeObject } from "store/api/utils";

const useExplore = () => {
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  const headers = {
    Authorization: "Bearer " + token,
  };
  //EXPLORE RECOMMENDED API
  const recommended = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.EXPLORE}/${ENDPOINTS.RECOMMENDED}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return [];
  };

  //EXPLORE FEATURED API
  const featured = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.EXPLORE}/${ENDPOINTS.FEATURED}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return [];
  };

  //RECOMMENDED SKILLS API
  const recommendedSkills = async (filters = {}) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.EXPLORE}/${ENDPOINTS.RECOMMENDED}/${
        ENDPOINTS.SKILLS
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return [];
  };

  //EXPLORE TEAMS API
  const teams = async () => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.EXPLORE}/${ENDPOINTS.TEAMS}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return [];
  };

  return {
    recommended,
    featured,
    recommendedSkills,
    teams,
  };
};

export default useExplore;
