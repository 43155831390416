import DashboardLayout from "pages/layouts/dashboard";
import { useEffect, useState } from "react";
import ChallengeMainPage from "./components/ChallengeMainPage";
import useChallenges from "store/challenges/service-hook";
import { useParams, useSearchParams } from "react-router-dom";
import useAuth from "store/auth/service-hook";

const Challenges = () => {
  const { fetchPublicChallenge, fetchChallenge } = useChallenges();
  const [challenge, setChallenge] = useState();
  const [params, setSearchParams] = useSearchParams();
  const [isPrivateView, setIsPrivateView] = useState(null);
  const { id } = useParams();
  const isPreviewMode = params.get("preview");
  const [loading, setLoading] = useState(false);

  const {
    state: { isLoggedIn },
  } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      if (params.has("private")) {
        params.delete("private");
        setSearchParams(params);
        setIsPrivateView(false);
      }
    }
    setIsPrivateView(params.get("private") === "true");
  }, [isLoggedIn]);

  useEffect(() => {
    if (id && isPrivateView !== null) handleFetchDetail();
  }, [id, isPrivateView]);

  const handleFetchDetail = async () => {
    setLoading(true);
    const res = isPrivateView
      ? await fetchChallenge(id)
      : await fetchPublicChallenge(id);
    if (!res) {
      setChallenge({ error: true, statusCode: res?.statusCode});
      setLoading(false);
      return;
    }
    setChallenge(res);
    setLoading(false);
  };

  return (
    <DashboardLayout
      loading={loading || !challenge}
      isEmpty={!!challenge?.error}
      emptyMessage="Not able to access the challenge!"
      show403={challenge?.statusCode === 403}
      show404OnError={challenge?.statusCode === 404}
    >
      <ChallengeMainPage
        challenge={challenge}
        isPrivate={isPrivateView}
        setChallenge={setChallenge}
        isPreviewMode={isPreviewMode}
      />
    </DashboardLayout>
  );
};

export default Challenges;
