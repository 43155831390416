import React from "react";
import cx from "classnames";

const Card = ({
  width,
  height,
  children,
  centered,
  classes,
  onClick,
  bgColor,
  noShadow,
  maxHeight,
  hoverable,
  type = "primary", // primary, secondary
}) => {
  return (
    <div
      data-testid="card"
      onClick={onClick}
      style={{
        width: !!width && width,
        margin: !!centered && "0 auto",
        height: !!height && height,
        maxHeight: !!maxHeight && maxHeight,
        backgroundColor: !!bgColor && bgColor,
        boxShadow: noShadow && "none"
      }}
      className={cx("card rounded-10 overflowY-auto", type === "primary" ? `shadow` : "shadow-2 px-8", !bgColor && 'bg-white', classes, hoverable && "hoverable" )}
    >
      {children}
    </div>
  );
};

export default Card;
