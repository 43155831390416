import React from "react";
import Card from "components/Cards/Card";
import ResourceCourseCard from "../../../../components/Cards/ResourceCourseCard";
import { NoDataView } from "components/UI/organisms/ListingContainer";
import { isTrue } from "helpers/utils/validators";
import { CheckCircle } from "@mui/icons-material";

const Course = ({ resource, t = (d) => d }) => {
  return (
    <Card classes="mt-4">
      {isTrue(resource.scorm?.completed) && (
        <div className="flex items-center my-2">
          <CheckCircle fontSize="small" className="mr-1 c-success" />
          <h3 className="c-success">{resource?.scorm?.title}</h3>
        </div>
      )}
      {resource?.scorm ? (
        <ResourceCourseCard
          resource={resource}
          isCompleted={isTrue(resource.scorm?.completed)}
          t={t}
        />
      ) : (
        <NoDataView text={t("The resource doesnt have course.")} small />
      )}
    </Card>
  );
};

export default Course;
