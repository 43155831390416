import React from "react";
import { Grid } from "@mui/material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import ToggleCard from "components/UI/molecules/ToggleCard";
import CampusConnectIntegration from "../CampusConnectIntegration";
import { stepSchema } from "../data";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import Input from "components/Input";
import TaskAndChallenge from "./TaskAndChallenges";
import AddAchievementSection from "./AddAchievementSection";
import FormInformation from "components/UI/atoms/FormInformation";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import styles from "../../challenges.module.scss";

const Information = [
  "Challenge Tasks and Questions",
  "Submission Eligibility",
  "Challenge Agreement",
  "Achievements",
  "Assessment",
];

const SubmissionAndAcheivement = ({
  data,
  errors,
  onChange,
  step,
  criteriaError,
  setCriteriaError,
  taskAndQuestionError,
  setTaskAndQuestionError,
  criteria,
  setCriteria,
  winnerAchievements,
  setWinnerAchievements = Function.prototype,
  taskValues,
  setTaskValues,
  questionsValues,
  setQuestionsValues,
  isProjectAlreadyPublished,
  subscriptionDetails,
  t = (d) => d,
}) => {
  const addCriteria = () => {
    setCriteria((val) => [...val, {}]);
  };
  const onChangeCriteria = (e, index) => {
    const { name, value } = e.target;
    criteria[index][name] = value;
    setCriteria([...criteria]);
    setCriteriaError(null);
  };

  const deleteCriteria = (index) => {
    criteria.splice(index, 1);
    setCriteria([...criteria]);
  };

  const keyName = [
    "max_project_associated",
    "max_project_submission",
    "min_imported_badges",
    "min_achievement_counts",
    "min_imported_badges",
    "min_achievement_counts",
    "min_rank",
    "min_imported_badges",
    "min_achievement_counts",
    "min_points",
    "additional_requirements",
    "assessment_type",
    "visibility",
    "guidelines",
    "attachments",
    "members_email",
    "agreement",
    "allow_submit_project",
    "complete_education_program",
    "complete_experience",
    "project_submission_requirement_ids",
  ];
  const newSchema = stepSchema(step).map((schema) => {
    if (keyName.includes(schema.name)) {
      return {
        ...schema,
        disabled: isProjectAlreadyPublished,
      };
    }
    return schema;
  });

  return (
    <>
      <FormInformation
        header={
          isProjectAlreadyPublished
            ? "The following sections are locked due to submissions:"
            : "The following sections will be locked after the first submission:"
        }
        lists={Information}
        t={t}
      />

      <Grid container px={{ xs: 0, md: 4 }}>
        <Card
          classes="my-5 container"
          width="min(1300px,90%)"
          centered
          type="secondary"
        >
          <h2>{t("Required Information")}</h2>
          <TaskAndChallenge
            data={data}
            errors={errors}
            onChange={onChange}
            step={step}
            taskValues={taskValues}
            setTaskValues={setTaskValues}
            questionsValues={questionsValues}
            setQuestionsValues={setQuestionsValues}
            taskAndQuestionError={taskAndQuestionError}
            setTaskAndQuestionError={setTaskAndQuestionError}
            isProjectAlreadyPublished={isProjectAlreadyPublished}
            t={t}
          />
          <div className={`${isProjectAlreadyPublished ? "opacity-50" : ""}`}>
            <CreateFormSection>
              <h3 className="fs-22">{t("Submission Eligibility")}</h3>
              <SchemaUIExtractor
                schema={newSchema.slice(8, 12)}
                data={data}
                errors={errors}
                onChange={onChange}
              />
              {data.complete_experience && (
                <SchemaUIExtractor
                  schema={newSchema.slice(12, 13)}
                  data={data}
                  errors={errors}
                  onChange={onChange}
                />
              )}
            </CreateFormSection>
            <CreateFormSection>
              <h3 className="fs-22">{t("Challenge Agreement")}</h3>
              <p className="fs-16 mt-2 opacity-60">
                {t(
                  "In order to govern by the laws of Canada, whenever any competition takes place and prizing is involved, a legal agreement needs to be in place to protect participants. If you already have a Challenge Agreement or Idea Generation/ Hackathon Agreement in place, this is where we manage acknowledgement from participants to ensure all parties are involved based on the information provided to the participants and timely delivery of the stated prizes in question. Prepr will work with you to prepare a Challenge Agreement that works with your internal intellectual property and talent sourcing, procurement and/or acquisition needs."
                )}
              </p>
              <SchemaUIExtractor
                schema={newSchema.slice(13, 14)}
                data={data}
                errors={errors}
                onChange={onChange}
              />
            </CreateFormSection>
          </div>
        </Card>

        <CreateFormSection classes="my-5 container">
          <div className="relative w-full">
            <AddAchievementSection
              onChange={onChange}
              showWinnerAchievements={true}
              winnerAchievements={winnerAchievements}
              setWinnerAchievements={setWinnerAchievements}
              data={data}
              errors={errors}
              noPlan={subscriptionDetails.plan === "seed_plan_yearly"}
              isProjectAlreadyPublished={isProjectAlreadyPublished}
              t={t}
            />
          </div>
        </CreateFormSection>

        <Card classes="my-5 container" width="min(1300px,90%)" centered>
          <ToggleCard
            title="Submission Eligibility"
            description="Add additional eligibility requirements"
            locked={isProjectAlreadyPublished}
            disabled={isProjectAlreadyPublished}
            t={t}
          >
            <SchemaUIExtractor
              schema={newSchema.slice(14, 20)}
              data={data}
              errors={errors}
              onChange={onChange}
            />
          </ToggleCard>

          <ToggleCard
            title="Assessment"
            description="Select if project submissions will be assessed and add evaluation guidelines"
            locked={isProjectAlreadyPublished}
            disabled={isProjectAlreadyPublished}
            t={t}
          >
            <div>
              <SchemaUIExtractor
                schema={newSchema.slice(1, 2)}
                data={data}
                errors={errors}
                onChange={onChange}
              />
              {data.assessment_type === "open" && (
                <SchemaUIExtractor
                  schema={newSchema.slice(2, 3)}
                  data={data}
                  errors={errors}
                  onChange={onChange}
                />
              )}

              {data.assessment_type === "closed" && (
                <SchemaUIExtractor
                  schema={newSchema.slice(3, 4)}
                  data={data}
                  errors={errors}
                  onChange={onChange}
                />
              )}

              {data.assessment_type && data.assessment_type !== "none" && (
                <>
                  <div>
                    <SchemaUIExtractor
                      schema={newSchema.slice(4, 5)}
                      data={data}
                      errors={errors}
                      onChange={onChange}
                    />
                    <div className="guideline-container">
                      <SchemaUIExtractor
                        schema={newSchema.slice(5, 7)}
                        data={data}
                        errors={errors}
                        onChange={onChange}
                        dataKey={"attachments"}
                      />
                    </div>
                  </div>
                  <div title="Criteria">
                    <h4 className="fs-16 mt-2">
                      {t("Assessment Criteria")}
                      <span className="c-error">*</span>
                    </h4>
                    {criteria &&
                      criteria.map((d, i) => (
                        <CriteriaRow
                          key={i}
                          criteria={d}
                          onChange={onChangeCriteria}
                          i={i}
                          onDelete={deleteCriteria}
                          disabled={isProjectAlreadyPublished}
                          t={t}
                        />
                      ))}
                    <div className="mt-2">
                      <Button
                        onClick={addCriteria}
                        bordered
                        color="green"
                        classes={"fw-700"}
                        disabled={isProjectAlreadyPublished}
                      >
                        {t("Add Criteria")}
                      </Button>
                    </div>
                    <p className="c-error mt-2 fs-14">{t(criteriaError)}</p>
                  </div>
                </>
              )}
            </div>
          </ToggleCard>
          {/* CAMPUS CONNECT */}
          <ToggleCard
            title="Campus Connect Integration"
            description="Post the Challenge onto Campus Connect"
            t={t}
          >
            <CampusConnectIntegration
              data={data}
              errors={errors}
              onChange={onChange}
              formSchema={newSchema.filter((item) =>
                item.name.includes("campus_connect")
              )}
              component={COMPONENT_TYPES.CHALLENGE}
            />
          </ToggleCard>
        </Card>
      </Grid>
    </>
  );
};

export const CriteriaRow = ({
  i,
  criteria,
  onChange,
  onDelete,
  desc = false,
  disabled,
  t = (d) => d,
}) => {
  return (
    <Grid container spacing={2} mt={1} mb={2} alignItems="center">
      <Grid item xs={12} md={desc ? 3 : 4}>
        <Input
          title={`Criteria`}
          required
          classes="w-full"
          width="99%"
          name="title"
          value={criteria.title}
          onChange={(e) => onChange(e, i)}
          disabled={disabled}
          t={t}
        />
      </Grid>
      {desc && (
        <Grid item xs={12} md={3}>
          <Input
            title="Description"
            required
            name="description"
            classes="w-full"
            value={criteria.description}
            onChange={(e) => onChange(e, i)}
            width="99%"
            disabled={disabled}
            t={t}
          />
        </Grid>
      )}
      <Grid item xs={12} md={desc ? 3 : 4}>
        <Input
          title="Max Score"
          required
          name="score"
          classes="w-full"
          value={criteria.score}
          onChange={(e) => onChange(e, i)}
          width="99%"
          disabled={disabled}
          t={t}
        />
      </Grid>
      <Grid item xs={12} md={desc ? 2 : 3}>
        <Input
          title="Weight (%)"
          required
          name="weight"
          classes="w-full"
          value={criteria.weight}
          onChange={(e) => onChange(e, i)}
          width="99%"
          disabled={disabled}
          t={t}
        />
      </Grid>

      <Grid item xs={12} md={1}>
        <button
          disabled={disabled}
          className={`${styles.task_question__btn_delete}`}
          onClick={() => onDelete(i)}
        >
          -
        </button>
      </Grid>
    </Grid>
  );
};

export default SubmissionAndAcheivement;
