import React from "react";
import pin from "assets/svg/yellow/pin.svg";
import pinned from "assets/svg/pinned.svg";
import { IconButton } from "@mui/material";

const PinButton = ({ isPinned, onClick, classes }) => {
  return (
    <IconButton onClick={onClick} className={classes}>
      <img
        width="18px"
        height="18px"
        src={isPinned ? pin : pinned}
        alt="pinned icon"
      />
    </IconButton>
  );
};

export default PinButton;
