import { Grid, IconButton } from "@mui/material";
import Card from "components/Cards/Card";
import ImageGrid from "components/media/ImageGrid";
import ListingContainer from "components/UI/organisms/ListingContainer";
import React, { useState } from "react";
import Audio from "components/media/Audio";
import Document from "components/media/Document";
import Divider from "components/Divider";
import { Delete, Download, Visibility } from "@mui/icons-material";
import EmptyText from "components/UI/atoms/EmptyText";
import { isTrue } from "helpers/utils/validators";
import useAuth from "store/auth/service-hook";
import { downloadFile } from "helpers/utils/utilities";
import Button from "components/Button";
import useProjects from "store/projects/service-hook";
import ProjectFilesForm from "../forms/ProjectFiles";
import useSettings from "store/settings/service-hook";

const ProjectFiles = ({ project, onDeleteMedia, t = (d) => d, setProject}) => {
  const { state } = useAuth();
  const { fileUpload } = useProjects();
  const { audios, videos, images, docs } = project;

  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [files, setFiles] = useState( project?.audios || project?.videos || project?.images || project?.docs || []);
  const { generatePresignedUrls } = useSettings();

  const submitFile = async () => {
    if(isEditMode) {
      let payload;
      let res;
      if (!files.length) return;
      setLoading(true);
      const filesToUpload = await generatePresignedUrls(files?.filter((d) => !files?.includes(d?.id)), "project_file");

      payload = {
        file_upload: filesToUpload?.map((d) => d?.response.path),
        filename: filesToUpload?.map((d) => d?.response.filename),
      };

      res = await fileUpload(project?.slug, payload);
      setProject(res);
      setLoading(false);

      if (res) {
        setIsEditMode(false);
        setDirty(false);
        setFiles([]);
      }
    } else {
      setIsEditMode(true);
    }
  };
  
  const hasContent =
    images?.length || docs?.length || audios?.length || videos?.length;

  function checkIfItsMember(users, usernameToCheck) {
    return users.some((user) => user.user_name === usernameToCheck);
  }

  const disableUpload =
  project?.project_role === "assessor" ||
  project?.access_level === "viewer" ||
  !project?.access_level;
  
  const isViewAccessEnabled = () =>
    state.user.id === project?.user_id ||
    checkIfItsMember(project?.members, state.user.username) ||
    isTrue(project?.is_download_enabled);

    return !isViewAccessEnabled() ? (
      <EmptyText classes="opacity-60">
        You have no permission to view this Project files.
      </EmptyText>
    ) : (
      <Card classes="mt-5 pb-6 px-0 pt-2">
        <div className="pb-2 px-4 flex justify-between w-full relative">
          <Button
            loading={loading}
            hide={!project || disableUpload}
            color={isEditMode && !dirty ? "gray" : "blue"}
            height={35}
            onClick={submitFile}
            disabled={isTrue(project?.is_submitted) || (isEditMode && !dirty)}
            classes="absolute right-0 mr-4 mt-4 minw-110"
          >
            {t(isEditMode ? "Save" : "Quick Edit")}
          </Button>
        </div>
        {isEditMode ? (
          <div className="mt-6">
            <ProjectFilesForm
              isDetailPage
              files={files}
              setFiles={(val) => {
                if (!dirty) setDirty(true);
                setFiles(val);
              }}
            />
          </div>
        ) : !hasContent ? (
          <EmptyText classes="opacity-60">
            {t("No file has been uploaded to this Project.")}
          </EmptyText>
        ) : (
          <>
            {images?.length ? (
              <>
                <h3 className="mt-3 ml-2">{t("Images", "common")}</h3>
                <Divider classes="w-full mt-2" />
                <ListingContainer
                  rowSpacing={2}
                  classes="mt-4"
                  noPagination
                  small
                >
                  <ImageGrid
                    onDelete={(d) => onDeleteMedia(d?.id, d?.type)}
                    onDownload={(d) =>
                      downloadFile({ path: d?.path, name: d?.title })
                    }
                    isViewAccessEnabled={isViewAccessEnabled()}
                    files={images}
                    allowFullView
                    perRow={3}
                    hideDelete={
                      isTrue(project?.is_submitted) ||
                      (project?.access_level !== "editor" &&
                        project?.access_level !== "team_leader")
                    }
                  />
                </ListingContainer>
              </>
            ) : null}

            {docs?.length ? (
              <>
                <h3 className="mt-3 ml-2">{t("Documents")}</h3>
                <Divider classes="w-full mt-2" />
                <ListingContainer
                  rowSpacing={2}
                  classes="mt-4"
                  noPagination
                  small
                >
                  {docs.map((d, i) => (
                    <Grid
                      item
                      xs={6}
                      md={4}
                      lg={3}
                      key={i}
                      display="flex"
                      justifyContent="center"
                    >
                      <Document
                        title={d?.title}
                        path={d?.path}
                        id={d?.id}
                        isPrivate
                        onDelete={() => onDeleteMedia(d?.id, d?.type)}
                        onDownload={() =>
                          downloadFile({ path: d?.path, name: d?.title })
                        }
                        isViewAccessEnabled={isViewAccessEnabled()}
                        hideDelete={
                          isTrue(project?.is_submitted) ||
                          (project?.access_level !== "editor" &&
                            project?.access_level !== "team_leader")
                        }
                      />
                    </Grid>
                  ))}
                </ListingContainer>
              </>
            ) : null}

            {audios?.length ? (
              <>
                <h3 className="mt-3 ml-2">{t("Audios")}</h3>
                <Divider classes="w-full mt-2" />
                <ListingContainer rowSpacing={2} noPagination small>
                  {audios?.map(({ path, title, id, type }) => (
                    <Grid item xs={12} md={6} key={id}>
                      <Audio
                        title={title}
                        audio={path}
                        onDelete={() => onDeleteMedia(id, type)}
                        onDownload={() => downloadFile({ path, name: title })}
                        classes="ml-2"
                        isPrivate
                        isViewAccessEnabled={isViewAccessEnabled()}
                      />
                    </Grid>
                  ))}
                </ListingContainer>
              </>
            ) : null}

            {videos?.length ? (
              <>
                <h3 className="mt-3 ml-2">{t("Videos")}</h3>
                <Divider classes="w-full mt-2" />
                <ListingContainer
                  columnSpacing={1}
                  rowSpacing={1}
                  noPagination
                  small
                  classes="mt-2"
                >
                  {videos?.map((video, i) => (
                    <Grid
                      key={i}
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      sx={{
                        position: "relative",
                        "& .imageOverlay": { visibility: "hidden" },
                        "&:hover .imageOverlay": { visibility: "visible" },
                      }}
                      className="cursor-pointer ml-2"
                    >
                      <div
                        style={{
                          position: "relative",
                          paddingBottom: "56.25%",
                          height: 0,
                        }}
                      >
                        <video className="absolute top-0 left-0 w-full h-full object-cover">
                          <source src={video?.path} />
                        </video>

                        <div
                          className="imageOverlay flex justify-center w-full h-full items-center absolute top-0 left-0 cursor-pointer"
                          style={{
                            backgroundColor: "#222222b2",
                            gap: "10px",
                          }}
                        >
                          <IconButton
                            sx={{ border: "1px solid #dadada50" }}
                            onClick={() => window.open(video?.path)}
                            className="opacity-100 h-30 w-30 c-white"
                          >
                            <Visibility fontSize="small" />
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              onDeleteMedia(video?.id, video?.type)
                            }
                            sx={{ border: "1px solid #dadada50" }}
                            className="opacity-100 h-30 w-30 border"
                          >
                            <Delete color="error" fontSize="small" />
                          </IconButton>

                          {isViewAccessEnabled && (
                            <IconButton
                              onClick={() =>
                                downloadFile({
                                  path: video?.path,
                                  name: video?.title,
                                })
                              }
                              sx={{ border: "1px solid #dadada50" }}
                              className="opacity-100 border"
                            >
                              <Download color="primary" />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </Grid>
                  ))}
                </ListingContainer>
              </>
            ) : null}
          </>
        )}
      </Card>
    );
    };

export default ProjectFiles;
