import React, { useEffect, useState } from "react";
import useFilters from "helpers/hooks/useFilters";
import BrowseWrapper from "components/UI/organisms/BrowseWrapper";
import useListingHeader from "helpers/hooks/useListingHeader";
import ChallengeListing from "pages/challenges/listing/ChallengeListing";
import useChallengePaths from "store/challenge-paths/service-hook";
import { FILTER_TYPES } from "helpers/hooks/useFilters";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

function getEmptyMessage(browseMode) {
  switch (browseMode) {
    case 0:
      return "No challenge & challenge Paths found!";
    case 1:
      return "No Challenge Paths found!";
    case 2:
      return "No saved challenge paths found!";
    default:
      return "No data found!";
  }
}

export const browse_options = [
  {
    id: 1,
    title: "All",
    pageTitle: "Browse Challenge & Challenge Paths",
  },
  {
    id: 2,
    title: "My Challenges & Paths",
    pageTitle: "My Challenges & Paths"
  },
  {
    id: 3,
    title: "Saved",
    pageTitle: "Browse Saved Challenge Paths",
  },
];

const BrowseChallengesPaths = () => {
  const [browseMode, setBrowseMode] = useState(0);
  const state = useListingHeader();
  const [challenges, setChallenges] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { fetchChallengePaths } = useChallengePaths();
  const { selected } = useFilters();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    const abortController = new AbortController();
    handleFetchChallenges(abortController);
    return () => abortController.abort();
  }, [page, selected, state.sort, state.search, browseMode]);

  const handleFetchChallenges = async (abortController) => {
    setLoading(true);
    const filters = {
      ...selected,
      page,
      sort_by: state.sort,
      search: state.search,
    };
    if (selected?.multi_type) filters.type = selected.multi_type;
    delete filters.multi_type;
    if (browseMode === 2) {
      filters.social_type = "favourites";
    }
    if (browseMode === 1) {
        filters.my_paths = true;
    }
    const [list, count] = await fetchChallengePaths(filters);
    if (!abortController.signal.aborted) {
      setChallenges(list || []);
      setPages(count || 0);
      setLoading(false);
    }
  };
  const filtered_browse_options = browse_options;
  return (
    <BrowseWrapper
      browseMode={browseMode}
      {...state}
      browseOptions={filtered_browse_options}
      setBrowseMode={setBrowseMode}
      noAction
      filters={[
        FILTER_TYPES.COMPONENT_TYPE,
        FILTER_TYPES.MULTI_TYPE,
        FILTER_TYPES.DURATION,
        FILTER_TYPES.LEVEL,
        FILTER_TYPES.SKILLS,
        FILTER_TYPES.PRIVACY,
      ]}
    >
      <ChallengeListing
        browseMode={browseMode}
        gridType={state.gridType}
        challenges={challenges}
        loading={loading}
        page={page}
        setPage={setPage}
        pageCount={pages}
        setChallenges={setChallenges}
        isChallengePath
        emptyMessage={t(getEmptyMessage(browseMode))}
      />
    </BrowseWrapper>
  );
};

export default BrowseChallengesPaths;
