import { COLORS } from "helpers/utils/constants";
export const REQUEST_STATUS = {
  PENDING: "Pending",
  ACCEPTED: "Accepted",
  DECLINED: "Declined",
  INVITED: "Invited",
};

export const PROJECT_STATUS = {
  IN_PROGESS: "in_progress",
  NOT_STARTED: "not_started",
  SUBMITTED: "submitted",
  NOT_SUBMITTED: "not_submitted",
  LATE_SUBMITTED: "late_submitted",
};

export const STATUS_TYPES = {
  NOT_STARTED: "not_started",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
};

export const getStatusColor = (status) =>
  status === REQUEST_STATUS.ACCEPTED
    ? COLORS.SUCCESS
    : status === REQUEST_STATUS.PENDING || status === REQUEST_STATUS.INVITED
    ? COLORS.YELLOW
    : !status
    ? COLORS.DARK_GRAY
    : COLORS.RED;


export const ADD_MEMBER_SCHEMA = [
  {
    name: "subject_line",
  },
  {
    name: "email_body",
  },
  {
    name: "role",
  },
  {
    name: "currently_recruitng",
  },
];
