import React, { useEffect, useState } from "react";
import ProjectListing from "./ProjectListing";
import { COLORS, COMPONENT_TYPES } from "helpers/utils/constants";
import { project } from "assets/svg/yellow";
import NuxLayout from "components/UI/molecules/NuxLayout";
import useListingHeader from "helpers/hooks/useListingHeader";
import useProjects from "store/projects/service-hook";
import ManageWrapper from "components/UI/organisms/ManageWrapper";
import useRedirect from "helpers/hooks/useRedirect";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import ProjectAssessModal from "../components/ProjectAssessModal";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const BROWSE_OPTIONS = [
  { id: 0, slug: "my", title: "My Projects", pageTitle: "Browse My Projects" },
  {
    id: 1,
    slug: "team",
    title: "Team Projects",
    pageTitle: "Browse Team Projects",
  },
  {
    id: 2,
    slug: "invites",
    title: "Pending Invitations",
    pageTitle: "Browse Pending Project Invitations",
  },
  {
    id: 3,
    slug: "favourite",
    title: "Favourite Projects",
    pageTitle: "Browse Favourite Projects",
  },
  {
    id: 4,
    slug: "pending_assessment",
    title: "Pending Assessment",
    pageTitle: "Browse Pending Assessment",
  },
  {
    id: 5,
    slug: "assessed",
    title: "Assessed by Me",
    pageTitle: "Browse Assessed Projects",
  },
];

export const SORT = [
  {
    value: "Name A to Z",
    key: "name-a-to-z",
  },
  {
    value: "Name Z to A",
    key: "name-z-to-a",
  },
  {
    value: "Created Date",
    key: "creation_date",
  },
  {
    value: "Relevance",
    key: "relevance",
  }
];

const BrowseProjects = () => {
  const [browseMode, setBrowseMode] = useState(0);
  const [projects, setProjects] = useState([]);
  const state = useListingHeader();
  const { list } = useProjects();
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const { navigateCreateProject } = useRedirect();
  const { selected } = useFilters();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [counts,setCounts] = useState({2:0,4:0});

  const { t } = useLocalizedTranslation();

  useEffect(() => {
    const abortController = new AbortController();
    fetchProjects(abortController);
    return () => abortController.abort();
  }, [browseMode, state.search, state.sort, page, selected]);

  const NEW_USER_DATA = {
    0: {
      title: "You currently have no Projects. Lets create your first Project.",
      nuxCardData: {
        title: "Create Your First Project",
        icon: project,
        bgColor: COLORS.MUD,
        onClick: navigateCreateProject,
      },
    },
  };

  const fetchProjects = async (abortController) => {
    setLoading(true);
    const filters = {
      search: state.search,
      sort_by: state.sort,
      page,
    };
    const { privacy, project_status } = selected;
    if (privacy) filters["privacy"] = privacy;
    if (project_status) filters["status"] = project_status;
    const [items, total_pages,pending_assessment,pending_invites] = await list(
      BROWSE_OPTIONS[browseMode].slug,
      filters
    );
    if (!abortController.signal.aborted) {
      setProjects(items || []);
      setPages(total_pages || 1);
      setCounts({2:pending_invites,4:pending_assessment})
      setLoading(false);
    }
  };
  const filtersSelected = Object.values(selected).some((value) => value);

  const handleOpenAssessModal = (project) => {
    setSelectedProject(project);
    setModalOpen(true);
  };
  return (
    <ManageWrapper
      browseMode={browseMode}
      browseOptions={BROWSE_OPTIONS}
      counts={counts}
      createActionTitle={t("Create Project")}
      {...state}
      setBrowseMode={setBrowseMode}
      navigateCreate={navigateCreateProject}
      filters={[FILTER_TYPES.PROJECT_STATUS, FILTER_TYPES.PRIVACY]}
      component={COMPONENT_TYPES.PROJECT}
      sortOptions={SORT}
    >
      {!loading &&
      projects.length === 0 &&
      browseMode === 0 &&
      !filtersSelected &&
      !state.search ? (
        <NuxLayout browseMode={0} newUserData={NEW_USER_DATA} />
      ) : (
        <ProjectListing
          loading={loading}
          browseMode={browseMode}
          gridType={state.gridType}
          projects={projects}
          setProjects={setProjects}
          page={page}
          pageCount={pages}
          setPage={setPage}
          noSave
          onAssessProject={handleOpenAssessModal}
        />
      )}

      <ProjectAssessModal
        viewMode={browseMode === 5 || browseMode === 0}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        project={selectedProject}
      />
    </ManageWrapper>
  );
};

export default BrowseProjects;
