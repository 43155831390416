import {
  Announcement,
  CheckCircle,
  Close,
  ContentCopyOutlined,
  Delete,
  Description,
  Edit,
  Extension,
  InsertChart,
  Settings,
  WorkspacePremium,
} from "@mui/icons-material";
import { Icon } from "@mui/material";
import MenuButton from "components/Button/MenuButton";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES, ROLES } from "helpers/utils/constants";
import { isTrue } from "helpers/utils/validators";
import React from "react";

const ChalllengeManageOptions = ({
  slug,
  isChallengePath,
  handleCloneChallenge = Function.prototype,
  data = {},
  user = {},
  t = (d) => d,
  addIntoTemplates = Function.prototype,
  onDelete = Function.prototype,
  icon,
}) => {
  const {
    navigateChallengeProjectSubmission,
    navigateAnnouncements,
    navigateAssessChallenge,
    navigateChallengeReports,
    navigateMemberManagement,
    navigateEditChallengePath,
    navigateEditChallenge,
    navigateWinnerSelection,
  } = useRedirect();
  return (
    <MenuButton
      listWithTitleonClick={[
        {
          title: "Announcements",
          icon: Announcement,
          onClick: () => navigateAnnouncements(slug),
          hide: isChallengePath,
          iconClasses: "c-primary",
        },
        {
          title: "Project Submission",
          icon: Description,
          onClick: () => navigateChallengeProjectSubmission(slug),
          hide: isChallengePath,
          iconClasses: "c-primary",
        },
        {
          title: "Project Assessment",
          icon: CheckCircle,
          onClick: () => navigateAssessChallenge(slug),
          hide: isChallengePath,
          iconClasses: "c-primary",
        },
        {
          title: "Winner Selection",
          icon: WorkspacePremium,
          onClick: () => navigateWinnerSelection(slug),
          hide: isChallengePath || !data?.winner_selection_enabled,
          iconClasses: "c-primary",
        },
        {
          title: "View Report",
          icon: InsertChart,
          onClick: () => navigateChallengeReports(slug),
          hide: isChallengePath,
          iconClasses: "c-primary",
        },
        {
          title: "Manage Members",
          onClick: () =>
            navigateMemberManagement(COMPONENT_TYPES.CHALLENGE, slug),
          icon: Settings,
          hide: isChallengePath,
          iconClasses: "c-primary",
        },
        {
          divider: true,
        },
        {
          title: "Edit",
          onClick: () =>
            isChallengePath
              ? navigateEditChallengePath(slug)
              : navigateEditChallenge(slug),
          icon: Edit,
          disabled: data?.challenge_status === "Completed",
        },
        {
          title: "Duplicate",
          onClick: () => handleCloneChallenge(data),
          icon: ContentCopyOutlined,
          hide: isChallengePath,
        },

        {
          title: isTrue(data?.is_pre_build)
            ? "Remove from templates"
            : "Add to templates",
          onClick: () => addIntoTemplates(data),
          icon: isTrue(data?.is_pre_build) ? Close : Extension,
          iconClasses: isTrue(data?.is_pre_build) ? "c-error" : "",
          classes: isTrue(data?.is_pre_build) ? "c-error" : "",
          hide:
            (user?.roles !== ROLES.SUPER_ADMIN &&
              user?.roles !== ROLES.CUSTOMER_SUCCESS &&
              user?.roles !== ROLES.DEVELOPER &&
              user?.roles !== ROLES.ORGANIZATION_OWNER) ||
            isChallengePath,
        },

        {
          title: "Delete",
          onClick: () => onDelete(slug),
          icon: Delete,
          classes: "c-error",
          iconClasses: "c-error",
        },
      ]}
      classes="fs-13"
      height="30px"
      icon={Boolean(icon)}
    >
      <Icon component={icon || Settings} fontSize="small" />{" "}
      {!icon && t("Manage")}
    </MenuButton>
  );
};

export default ChalllengeManageOptions;
