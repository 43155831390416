import moment from "moment";
import { PROFILE_TYPES } from ".";
import { isTrue } from "helpers/utils/validators";
import { validatePatent } from "./forms/Patent";
import { validateEducation } from "./forms/Education";
import {
  deleteValues,
  payloadFormatArray,
  removeNullValues,
} from "helpers/utils/utilities";
import { validateExperience } from "./forms/Experience";
import { validateCertificates } from "./forms/Certificates";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { toast } from "react-toastify";
import { validateExternalLinks } from "components/UI/molecules/SocialLinks";

export const modalOpenHelper = (
  type,
  setModal,
  profile,
  setExperience,
  setEducation,
  setCertificates,
  setPatent,
  setData
) => {
  switch (type) {
    case PROFILE_TYPES.EXPERIENCE:
      setExperience(
        (profile?.user_experiences || [])?.map((d) => ({
          company: d?.company,
          start_date: d?.start_date
            ? moment(d?.start_date).format("YYYY-MM-DD hh:mm:ss")
            : null,
          end_date: d?.end_date
            ? moment(d?.end_date).format("YYYY-MM-DD hh:mm:ss")
            : null,
          position: d?.position,
          location: {
            address_1: d?.address,
            state: d?.state,
            country: d?.country,
          },
          description: d?.description ? d?.description : null,
          id: d?.id,
        }))
      );
      setModal(type);
      break;
    case PROFILE_TYPES.EDUCATION:
      if (profile?.user_educations?.length)
        setEducation(
          (profile?.user_educations || [])?.map((d) => ({
            university: d?.university,
            start_date: d?.start_date
              ? moment(d?.start_date).format("YYYY-MM-DD hh:mm:ss")
              : null,
            end_date: d?.end_date
              ? moment(d?.end_date).format("YYYY-MM-DD hh:mm:ss")
              : null,
            degree: d?.degree,
            location: {
              address_1: d?.address,
              state: d?.state,
              country: d?.country,
            },
            description: d?.description ? d?.description : null,
            id: d?.id,
          }))
        );
      setModal(type);
      break;
    case PROFILE_TYPES.CERTIFICATE:
      if (profile?.user_certificates?.length)
        setCertificates(
          (profile?.user_certificates || [])?.map((d) => ({
            company: d?.company,
            start_date: d?.start_date
              ? moment(d?.start_date).format("YYYY-MM-DD hh:mm:ss")
              : null,
            end_date: d?.end_date
              ? moment(d?.end_date).format("YYYY-MM-DD hh:mm:ss")
              : null,
            name: d?.name,
            description: d?.description ? d?.description : null,
            id: d?.id,
          }))
        );
      setModal(type);
      break;
    case PROFILE_TYPES.PATENT:
      if (profile?.user_patents?.length)
        setPatent(
          (profile?.user_patents || []).map((d) => ({
            ...d,
            patent_date: d?.patent_date
              ? moment(d?.patent_date).format("YYYY-MM-DD hh:mm:ss")
              : null,
            description: d?.description ? d?.description : null,
          }))
        );
      setModal(type);
      break;
    case PROFILE_TYPES.GENERAL:
      setData({
        name: profile?.full_name,
        gender: profile?.gender,
        purpose: +profile?.purpose,
        date_of_birth: profile?.date_of_birth,
        about: profile?.about,
        location: {
          address_1: profile?.address,
          city: profile?.city,
          state: profile?.state,
          country: profile?.country,
        },
        identification: [
          "recent_immigrant",
          "indigenous_group",
          "disability",
          "visible_minority",
        ].filter((d) => isTrue(profile[d])),
      });
      setModal(type);
      break;
    default:
      setModal(type);
  }
};

export const handleSubmitProfileDetails = async (
  validate,
  data,
  addDetails,
  setProfile,
  setLoading,
  setModal,
  links = [],
  t = (d) => d
) => {
  const _errors = await validate();
  if (_errors.size) return;
  if (links?.length && validateExternalLinks(links, t)) return;
  setLoading(true);
  const payload = {
    ...data,
    date_of_birth: data?.date_of_birth
      ? moment(data?.date_of_birth).format("DD-MM-YYYY")
      : "",
    ...data?.location,
    address: emptyCheck(data?.location?.address_1),
    city: emptyCheck(data?.location?.city),
    state: emptyCheck(data?.location?.state),
    zip_code: emptyCheck(data?.location?.zip_code),
    country: emptyCheck(data?.location?.country),
    about: emptyCheck(data?.about),
    external_link_ids: payloadFormatArray(
      payloadFormatArray(links, "type"),
      "key"
    ),
    external_links: payloadFormatArray(links, "url"),
  };
  data?.identification.forEach((item) => {
    payload[item] = true;
  });
  const delVals = [
    "address_1",
    "address_2",
    "zip",
    "zip_code",
    "identification",
    "location",
    "latitude",
    "longitude",
  ];
  deleteValues(payload, delVals);
  const res = await addDetails(payload);
  if (res) setProfile({ ...res });
  setLoading(false);
  setModal(false);
};

export const handleSubmitExperience = async (
  experience,
  setLoading,
  addExperience,
  setProfile,
  setModal,
  t
) => {
  const profileExperienceErrors = validateExperience(
    removeNullValues(experience),
    t
  );
  if (profileExperienceErrors) return;
  const payload = removeNullValues(experience).map((d) => {
    const item = {
      ...d,
      address: emptyCheck(d?.location?.address_1),
      city: emptyCheck(d?.location?.city),
      state: emptyCheck(d?.location?.state),
      country: emptyCheck(d?.location?.country),
    };
    delete item.location;
    return item;
  });
  setLoading(true);
  const res = await addExperience(payload);
  if (res) {
    setProfile((prev) => ({
      ...prev,
      user_experiences: res,
    }));
    setModal(null);
  }
  setLoading(false);
};

export const handleSubmitEducation = async (
  education,
  setLoading,
  setProfile,
  setModal,
  addEducation,
  t
) => {
  const profileEducationErrors = validateEducation(
    removeNullValues(education),
    t
  );
  if (profileEducationErrors) return;
  const payload = removeNullValues(education).map((d) => {
    const item = {
      ...d,
      address: emptyCheck(d?.location?.address_1),
      state: emptyCheck(d?.location?.state),
      country: emptyCheck(d?.location?.country),
    };
    delete item.location;
    return item;
  });
  setLoading(true);
  const res = await addEducation(payload);
  if (res) {
    setProfile((prev) => ({
      ...prev,
      user_educations: res,
    }));
    setModal(null);
  }
  setLoading(false);
};

export const handleSubmitCertificate = async (
  certificates,
  setLoading,
  setProfile,
  setModal,
  addCertificate,
  t
) => {
  const profileCertificateErrors = validateCertificates(
    removeNullValues(certificates),
    t
  );
  if (profileCertificateErrors) return;
  const payload = removeNullValues(certificates).map((d) => {
    delete d.location;
    return d;
  });
  setLoading(true);
  const res = await addCertificate(payload);
  if (res) {
    setProfile((prev) => ({
      ...prev,
      user_certificates: res,
    }));
    setModal(null);
  }
  setLoading(false);
};

export const handleSubmitPatent = async (
  patent,
  setLoading,
  setProfile,
  setModal,
  addPatent,
  t
) => {
  const profilePatentErrors = validatePatent(removeNullValues(patent), t);
  if (profilePatentErrors) return;
  setLoading(true);
  const res = await addPatent(removeNullValues(patent));
  if (res) {
    setProfile((prev) => ({
      ...prev,
      user_patents: res,
    }));
    setModal(null);
  }
  setLoading(false);
};

export const getProfileBtns = (
  profile,
  itsMe,
  handleFollowUnfollow,
  handleUnfriend,
  handleAcceptRejectReq,
  handleAddFriend,
  navigateChat,
  isLoggedIn,
  uploadResume,
  t = (d) => d
) => {
  if (!isLoggedIn && profile?.profile_privacy !== "public") return [];
  if (itsMe || (!isLoggedIn && profile?.profile_privacy === "public")) {
    const buttons = [
      {
        title: "Download Profile",
        disabled: true,
      },
    ];

    // Add the "Upload Resume" button only if the user is logged in
    if (isLoggedIn) {
      buttons.push({
        title: "Upload Resume",
        onClick: uploadResume,
      });
    }
    return buttons;
  }

  // if (isTrue(profile?.is_follower))
  //   return [
  //     {
  //       title: "Message",
  //       onClick: navigateChat,
  //       props: {},
  //     },
  //     {
  //       title: "Unfollow",
  //       onClick: () => handleFollowUnfollow(true),
  //       props: {
  //         color: "red",
  //         bordered: true,
  //       },
  //     },
  //   ];

  if (isTrue(profile?.is_friends))
    return [
      {
        title: "Unfriend",
        onClick: handleUnfriend,
        props: {
          color: "red",
          bordered: true,
        },
      },
      {
        title: "Message",
        onClick: navigateChat,
        props: {},
      },
    ];
  if (isTrue(profile?.request_sent))
    return [
      {
        title: "Message",
        onClick: () => {},
        props: {},
      },
      {
        title: "Friend Request Sent",
        onClick: () => {},
        props: {
          disabled: true,
          bordered: true,
          color: "green",
        },
      },
    ];
  if (isTrue(profile?.request_received))
    return [
      {
        title: "Reject Request",
        onClick: () => handleAcceptRejectReq(true),
        props: {
          bordered: true,
          color: "red",
        },
      },
      {
        title: "Accept Request",
        onClick: () => handleAcceptRejectReq(false),
      },
    ];
  if (!isTrue(profile?.is_friends)) {
    const btns = [
      {
        title: "Message",
        onClick: navigateChat,
        props: {},
      },
    ];

    if (!isTrue(profile?.friend_request_privacy)) {
      btns.push({
        title: "Add Friend",
        onClick: handleAddFriend,
        props: {},
      });
    }

    return btns;
  }
};

export const downloadPdfHelper = async (username, downloadProfileRef) => {
  const canvas = await html2canvas(downloadProfileRef.current, {
    scale: 1.2,
    useCORS: true,
    allowTaint: true,
    scrollY: 0,
  });

  const margin = 0.5;
  const imgWidth = 12;
  const pageHeight = 14;
  const innerPageWidth = imgWidth - margin * 2;

  const pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
  const nPages = Math.ceil(canvas.height / pxPageHeight);

  const pdf = new jsPDF("p", "in", [imgWidth, pageHeight]);
  const pageCanvas = document.createElement("canvas");
  const pageCtx = pageCanvas.getContext("2d");
  pageCanvas.width = canvas.width;

  for (let page = 0; page < nPages; page++) {
    let canvasHeight =
      page === nPages - 1 && canvas.height % pxPageHeight
        ? canvas.height % pxPageHeight
        : pxPageHeight;
    pageCanvas.height = canvasHeight;
    let scaledPageHeight = (canvasHeight * innerPageWidth) / pageCanvas.width;

    pageCtx.fillStyle = "white";
    pageCtx.fillRect(0, 0, pageCanvas.width, canvasHeight);
    pageCtx.drawImage(
      canvas,
      0,
      page * pxPageHeight,
      pageCanvas.width,
      canvasHeight,
      0,
      0,
      pageCanvas.width,
      canvasHeight
    );

    if (page > 0) pdf.addPage();
    let imgData = pageCanvas.toDataURL("image/jpeg", 0.9);
    pdf.addImage(
      imgData,
      "JPEG",
      margin,
      margin,
      innerPageWidth,
      scaledPageHeight
    );
  }

  pdf.save(`${username}.pdf`);
  toast.success("User profile downloaded successfully!");
};

export const emptyCheck = (value) => {
  if (value === "null" || value === "undefined" || !value) {
    return "";
  }
  return value;
};
