import Button from "components/Button";
import React from "react";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import { COLORS } from "helpers/utils/constants";
import Card from "components/Cards/Card";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const NuxLayout = ({ newUserData, browseMode }) => {
  const { t } = useLocalizedTranslation();
  return (
    <div className="flex-column justify-center items-center mb-10">
      <h3 className="my-10 text-center mx-10">
        {t(newUserData[browseMode]?.title)}
      </h3>
      {newUserData[browseMode]?.nuxCardData && (
        <NuxCard {...newUserData[browseMode]?.nuxCardData} t={t} />
      )}
      {newUserData[browseMode]?.btnData ? (
        <Button
          color={newUserData[browseMode]?.btnData?.color}
          classes="py-2 px-6"
        >
          {t(newUserData[browseMode]?.btnData?.title)}
        </Button>
      ) : null}
    </div>
  );
};

export default NuxLayout;

export const NuxCard = ({
  icon,
  bgColor,
  title,
  onClick = Function.prototype,
  t,
}) => {
  const windowSize = useWindowWidth();
  return (
    <Card
      onClick={onClick}
      bgColor={bgColor || COLORS.DARK_RED}
      height="449px"
      classes={`flex items-center flex-column justify-center px-6 c-white max-w-500 cursor-pointer ${
        windowSize > 800 ? "w-60p" : "w-90p"
      }`}
    >
      <img src={icon} className="mb-4 w-100 h-100" alt="" />
      <h1 className="fs-36 text-center ">{t(title)}</h1>
    </Card>
  );
};
