import Paginator from "components/UI/atoms/Paginator";
import PageTitleCard from "components/UI/molecules/PageTitleCard";
import ListingPageHeader from "components/UI/organisms/ListingPageHeader";
import FilterCard from "components/UI/organisms/FilterCard";
import MainPageLayout from "pages/layouts/MainPage";
import React, { useState } from "react";
import SearchListing from "./SearchListing";

const filter_types = {
  Type: {
    "": [
      "Labs",
      "Lab Programs",
      "Resource Modules",
      "Resource Collections",
      "Challenge",
      "Challenge Path",
      "Projects",
      "Users",
    ],
  },
  Privacy: {
    "": ["Public", "Private"],
  },
  OWNER: {
    "": ["Only My Challenges", "Prepr (My Organization)", "All Organizations"],
  },
  TAGS: {
    "": ["Common Tag #1", "Common Tag #2", "Common Tag #3"],
  },
  CATEGORY: {
    "": [
      "Small-Mid Sized Business",
      "Community Organization",
      "Association",
      "Not-for-profit",
      "Employment Service Organization",
      "District School Board",
      "Economic Development Office",
      "Board of Trade",
      "Chamber of Commerce",
      "Everyone",
    ],
  },
  AVAILABILITY: {
    "": ["Global", "Local"],
  },
};

const AdvancedSearch = () => {
  const [gridType, setGridType] = useState(1);

  return (
    <>
      <PageTitleCard
        title={
          <span>
            Search results for <span className="c-primary">“sample”</span>
          </span>
        }
        noAction
      />
      <MainPageLayout classes="mb-6">
        <div className="left mt-3">
          <FilterCard
            title="Advanced Search"
            filterTypes={filter_types}
            classes="fs-14"
          />
        </div>
        <div className="right mt-5">
          <ListingPageHeader gridType={gridType} setGridType={setGridType} />
          <SearchListing gridType={gridType} />
          <Paginator count={1000}/>
        </div>
      </MainPageLayout>
    </>
  );
};

export default AdvancedSearch;
