import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import { LockIcon } from "components/Statics";

const BlueAccordion = ({
  children,
  title,
  classes,
  defaultExpanded,
  disabled,
  sx,
  color,
  locked
}) => {
  return (
    <Accordion
      data-testid="blue-accordion"
      className={classes}
      defaultExpanded={!!defaultExpanded}
      disabled={disabled}
      sx={sx}
    >
      <AccordionSummary
        className="bg-primary c-white h-40"
        expandIcon={<ExpandMore className="c-white" />}
        sx={{ backgroundColor: color ? `${color} !important` : null }}
      >
        <p className="flex items-center">
          {title}
          {locked && <LockIcon />}
        </p>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default BlueAccordion;
