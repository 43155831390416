import React from "react";
import user from "../../../assets/png/gradient-user.png";
import moment from "moment";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Svg,
  Path,
  Image,
  Link,
} from "@react-pdf/renderer";
import { lab, project } from "assets/png/placeholders";
import { getBaseUrl } from "helpers/utils/utilities";
import { getAchievementTypeKey } from "pages/achievements/helpers";

function stripHTMLTags(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
}

// Font.register({
//   family: "Poppins",
//   src: "http://fonts.gstatic.com/s/poppins/v1/TDTjCH39JjVycIF24TlO-Q.ttf",
// });
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: "30px 20px",
    fontFamily: "Open Sans",
  },
  "page *": {
    fontFamily: "Open Sans",
  },
  text: {
    fontSize: "10px",
    fontFamily: "Open Sans",
  },
  flexBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  sociallLinks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    gap: "5px",
  },
});

const DownloadProfile = ({
  profile,
  labs = [],
  projects = [],
  hasMoreProjects,
  hasMoreLabs,
  t = (d) => d,
}) => {
  const {
    about,
    full_name,
    username,
    email,
    city,
    country,
    role,
    organization_title,
    user_experiences,
    user_educations,
    user_skills,
    user_pinned_skills,
    external_links,
  } = profile;

  const combinedSkills = [
    ...Object.entries(user_skills || {}).map(([id, name]) => ({ id, name })),
    // ...Object.entries(user_pinned_skills || {}).map(([id, name]) => ({
    //   id,
    //   name,
    // })),
  ];

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={false}>
        <View style={styles.flexBetween}>
          <View>
            <Text
              style={{
                fontSize: "20px",
                fontWeight: "extrabold",
                lineHeight: 1.3,
                textTransform: "capitalize",
              }}
            >
              {full_name}
            </Text>
            <Text
              style={{ lineHeight: 1.3, fontSize: "12px", fontWeight: 600 }}
            >
              {t(`${role?.split("_")?.join(" ")}`)} at {organization_title}
            </Text>
            <Text style={[styles.text, { marginTop: "20px" }]}>
              @{username}
            </Text>
            {Boolean(city || country) && (
              <Text style={styles.text}>
                {city ? `${city},` : ""} {!!country ? country : ""}
              </Text>
            )}
          </View>
          {external_links?.length ? (
            <View style={styles.sociallLinks}>
              {external_links?.map(({ id, link, image }) => (
                <View key={id}>
                  <Link src={link}>
                    <Image
                      src={image + "?not-from-cache-please"}
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "100%",
                        backgroundColor: "#eee",
                        border: "1px solid #dadada",
                      }}
                    />
                  </Link>
                </View>
              ))}
            </View>
          ) : null}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "10px 0px",
            gap: "70px",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              display: "flex",
              width: "20%",
              gap: "5px",
            }}
          >
            <Image
              src={user}
              source={user}
              style={{
                height: 20,
                width: 20,
                borderRadius: "100%",
                backgroundColor: "#ffffff",
              }}
            />
            <Text style={{ fontSize: "14px", fontWeight: 600 }}>
              {t("About Me")}
            </Text>
          </View>
          <View style={{ width: "80%" }}>
            <Text
              style={{
                fontSize: "10px",
                color: !about && "#777986",
                marginTop: "2px",
              }}
            >
              {about ?? t("This user has not added anything.")}
            </Text>
          </View>
        </View>
        <SectionHeader
          username={username}
          title="Labs Completed"
          svg={
            <Svg
              width={18}
              height={18}
              viewBox="0 0 89 92"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                d="M85.6958 74.5386L58.1863 41.1328L57.7891 11.0335L62.6809 10.9796C65.3705 10.9437 67.5366 8.6615 67.5005 5.89416C67.4644 3.14479 65.2261 0.898568 62.5546 0.934507L23.4383 1.45563C20.7487 1.4736 18.5826 3.77373 18.6187 6.5231C18.6548 9.29044 20.8751 11.5187 23.5647 11.4827L28.4564 11.4109L28.8536 41.5102L2.21046 75.6168C0.676136 77.5934 0.00825278 79.8217 0.0443546 82.014C0.15266 87.1893 4.17801 92.0052 9.95429 91.9333L78.3852 91.0348C84.1976 90.9629 88.0966 86.0572 88.0424 80.8819C88.0063 78.6716 87.2843 76.4793 85.6958 74.5386ZM64.847 60.8277C64.5221 61.0972 64.125 61.223 63.7279 61.223C63.1863 61.223 62.6629 60.9894 62.3199 60.5581L59.1791 56.6587C58.5653 55.868 58.6917 54.7539 59.4679 54.1249C60.2441 53.514 61.3813 53.6398 62.013 54.4125L65.1358 58.3119C65.7496 59.0846 65.6232 60.2167 64.847 60.8277ZM79.5946 79.2107C79.2697 79.4803 78.8726 79.606 78.4754 79.606C77.9339 79.606 77.4104 79.3724 77.0494 78.9232L66.7063 66.0389C66.0925 65.2662 66.2189 64.1341 66.9951 63.5051C67.7713 62.8942 68.9085 63.02 69.5403 63.7927L79.8834 76.677C80.4971 77.4676 80.3708 78.5997 79.5946 79.2107Z"
                fill="#268BD3"
              />
            </Svg>
          }
          hasMore={hasMoreLabs}
          isEmpty={!labs || !labs?.length}
          emptyText="This user has not completed a Lab, please check back later."
          t={t}
        />
        {labs?.length ? (
          <View>
            {labs.map((d) => (
              <ListItem
                key={d?.slug}
                item={d}
                skills={combinedSkills}
                banner={lab}
                isLab
                t={t}
              />
            ))}
          </View>
        ) : null}
        <SectionHeader
          username={username}
          title="Projects"
          svg={
            <Svg
              width={20}
              height={20}
              viewBox="0 0 37 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                d="M24.76 15.0098C22.97 15.0098 21.59 13.6698 21.59 12.0098V0.00976562H4.63C2.08 0.00976562 0 2.01977 0 4.50977V43.5098C0 45.9898 2.08 48.0098 4.63 48.0098H32.38C34.93 48.0098 37 45.9898 37 43.5098V15.0098H24.76ZM12.47 38.1498C10.56 35.6298 9.96999 32.3498 10.9 29.3298H11.2C12.28 29.3298 13.37 29.5098 14.4 29.8698L14.36 30.1698C14.32 30.6098 14.3 31.0398 14.3 31.4798C14.3 35.4698 16.04 39.2998 19.04 41.9198C16.46 41.5998 14.09 40.2498 12.47 38.1498ZM20.61 41.8198C17.32 39.4198 15.36 35.5798 15.36 31.4798C15.36 31.0798 15.36 30.6698 15.41 30.2798C16.81 30.9398 18.04 31.9398 18.99 33.1798C20.87 35.6398 21.47 38.8498 20.61 41.8198ZM21.87 41.0298C22.4 38.0598 21.67 34.9598 19.82 32.5398C18.78 31.1698 17.41 30.0598 15.86 29.3198L15.57 29.1798C16.16 25.9098 18.02 22.9798 20.75 21.0398C24.13 23.4398 26.15 27.3298 26.15 31.4798C26.15 35.1498 24.58 38.6198 21.87 41.0298Z"
                fill="#FF8E00"
              />
            </Svg>
          }
          hasMore={hasMoreProjects}
          isEmpty={!projects || !projects?.length}
          emptyText="This user has not submitted a Project, please check back later."
          t={t}
        />
        {projects?.length ? (
          <View>
            {projects.map((d) => (
              <ListItem
                key={d?.slug}
                item={d}
                skills={combinedSkills}
                banner={project}
                t={t}
              />
            ))}
          </View>
        ) : null}
        <SectionHeader
          username={username}
          title="Verified Achievements"
          svg={
            <Svg
              width={20}
              height={20}
              viewBox="0 0 37 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                d="M30.96 5.93011C27.56 2.53011 22.95 0.620117 18.14 0.620117C13.33 0.620117 8.71 2.53011 5.31 5.93011C1.91 9.33011 0 13.9501 0 18.7601C0 23.5701 1.91 28.1801 5.31 31.5901C8.71 34.9901 13.32 36.9001 18.14 36.9001C22.96 36.9001 27.56 34.9901 30.96 31.5901C34.37 28.1801 36.27 23.5701 36.27 18.7601C36.27 13.9501 34.37 9.33011 30.96 5.93011ZM27.79 28.4001C25.23 30.9601 21.76 32.4001 18.14 32.4001C14.52 32.4001 11.05 30.9601 8.48001 28.4001C5.92001 25.8501 4.48001 22.3701 4.48001 18.7501C4.48001 15.1301 5.92001 11.6601 8.48001 9.09012C11.04 6.53012 14.51 5.09012 18.14 5.09012C21.77 5.09012 25.22 6.53012 27.79 9.09012C30.35 11.6501 31.79 15.1301 31.79 18.7501C31.79 22.3701 30.35 25.8401 27.79 28.4001Z"
                fill="#FEBE10"
              />
              <Path
                d="M29.51 32.8901V48.6001C29.51 48.8001 29.46 49.0001 29.36 49.1701C29.26 49.3401 29.11 49.4801 28.94 49.5901C28.76 49.6901 28.56 49.7301 28.36 49.7301C28.16 49.7301 27.97 49.6701 27.79 49.5701L18.14 43.7801L8.48001 49.5701C8.31001 49.6701 8.11 49.7301 7.91 49.7301C7.71 49.7301 7.51 49.6801 7.34 49.5901C7.16 49.4901 7.02 49.3401 6.91 49.1701C6.81 48.9901 6.76001 48.8001 6.76001 48.5901V32.8801C9.96001 35.4701 13.97 36.9001 18.14 36.9001C22.31 36.9001 26.3 35.4701 29.51 32.8901Z"
                fill="#FEBE10"
              />
              <Path
                d="M18.14 27.8601C15.73 27.8601 13.41 26.9002 11.71 25.1902C10 23.4802 9.04001 21.1702 9.04001 18.7602C9.04001 16.3502 10 14.0301 11.71 12.3301C13.42 10.6201 15.73 9.66016 18.14 9.66016C20.55 9.66016 22.87 10.6201 24.57 12.3301C26.28 14.0401 27.24 16.3502 27.24 18.7602C27.24 21.1702 26.28 23.4902 24.57 25.1902C22.86 26.9002 20.55 27.8601 18.14 27.8601Z"
                fill="#FFD974"
              />
            </Svg>
          }
          hasMore={profile?.achievements_list?.length > 10}
          isEmpty={!profile?.achievements_list?.length}
          emptyText="This user has not obtained an Achievement, please check back later."
          t={t}
        />
        {profile?.achievements_list?.length ? (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "7px",
            }}
          >
            {profile?.achievements_list?.slice(0, 10)?.map((d) => (
              <View
                key={d?.id}
                style={{
                  border: "1px solid #dadada",
                  padding: "5px 10px",
                  minWidth: "49%",
                  maxWidth: "49%",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "5px",
                }}
              >
                <Image
                  src={d?.image + "?not-from-cache-please"}
                  style={{
                    height: "55px",
                    width: "55px",
                    borderRadius: "100%",
                    border: "1px solid #dadada",
                    backgroundColor: "#eee",
                    objectFit: "cover",
                  }}
                />
                <View style={{ width: "75%" }}>
                  <Link
                    style={{
                      overflow: "hidden",
                      fontSize: "13px",
                      fontWeight: 600,
                      color: "#498CCE",
                      whiteSpace: "nowrap",
                      maxHeight: "18px",
                      textDecoration: "none",
                    }}
                    src={`${getBaseUrl()}achievements/${d?.certificate_number}`}
                  >
                    {d?.microcopy || getAchievementTypeKey(d?.achievement_type)}
                  </Link>
                  <Text
                    style={{
                      overflow: "hidden",
                      fontSize: "11px",
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                      maxHeight: "18px",
                    }}
                  >
                    {d?.project_title}
                  </Text>
                  <Text style={{ fontSize: "8px", opacity: 0.6 }}>
                    {t("Awarded on")}{" "}
                    {moment(d?.issue_date).format("MMM DD, YYYY")}
                  </Text>
                  <View
                    style={{
                      marginTop: "5px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "8px",
                        border: "1px solid #c8c8c8",
                        padding: "2px 6px",
                        borderRadius: "5px",
                        color: "#777986",
                        backgroundColor: "#F3F4F8",
                        lineHeight: 1.2,
                        textAlign: "left",
                      }}
                    >
                      {getAchievementTypeKey(d?.achievement_type)}
                    </Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
        ) : null}
        <SectionHeader
          username={username}
          title="Verified Skills"
          svg={
            <Svg
              width={20}
              height={20}
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                d="M51.6301 24.8951V41.485C51.6301 42.335 51.4701 43.175 51.1401 43.955C50.8201 44.735 50.3401 45.4451 49.7401 46.0451C49.1401 46.6451 48.4301 47.1251 47.6501 47.4451C46.8601 47.7751 46.0201 47.9351 45.1801 47.9351H28.7401C27.8401 47.9351 26.9302 47.7951 26.1002 47.4451C23.6202 46.3951 22.1301 44.015 22.1301 41.485V36.7251C22.7601 36.7951 23.3902 36.8351 24.0302 36.8351C25.2802 36.8351 26.4902 36.695 27.6602 36.425V41.485C27.6602 41.995 28.0701 42.4051 28.5801 42.4051H45.1801C45.4201 42.4051 45.6601 42.3051 45.8301 42.1351C46.0001 41.9651 46.1002 41.725 46.1002 41.485V24.8951C46.1002 24.6451 46.0001 24.415 45.8301 24.235C45.6601 24.065 45.4201 23.9651 45.1801 23.9651H28.5801C28.3401 23.9651 28.1001 24.065 27.9301 24.235C27.7601 24.415 27.6602 24.6451 27.6602 24.8951V30.7251C26.5202 31.1151 25.3002 31.3351 24.0302 31.3351C23.3902 31.3351 22.7501 31.285 22.1301 31.175V24.8951C22.1301 21.3251 25.0201 18.4351 28.5801 18.4351H45.1801C48.7401 18.4351 51.6301 21.3251 51.6301 24.8951Z"
                fill="#FFE3BF"
              />
              <Path
                d="M40.1602 23.9648C38.7602 30.1548 33.8602 35.0448 27.6602 36.4248C26.4902 36.6948 25.2802 36.8348 24.0302 36.8348C23.3902 36.8348 22.7601 36.7948 22.1301 36.7248C21.6001 36.6648 21.0702 36.5748 20.5402 36.4648C19.0602 36.1448 18.1201 34.6848 18.4401 33.2048C18.7601 31.7148 20.2101 30.7648 21.7001 31.0948C21.8401 31.1248 21.9801 31.1548 22.1301 31.1748C22.7501 31.2848 23.3902 31.3348 24.0302 31.3348C25.3002 31.3348 26.5202 31.1148 27.6602 30.7248C30.8202 29.6248 33.3401 27.1148 34.4501 23.9648H40.1602Z"
                fill="#FF8E00"
              />
              <Path
                d="M40.4702 18.4349H34.9202C34.0402 13.2249 29.4902 9.25488 24.0302 9.25488C22.9302 9.25488 21.8501 9.41488 20.8001 9.73488C19.3501 10.1849 17.8101 9.36489 17.3701 7.90489C16.9301 6.45489 17.7402 4.91488 19.2002 4.47488C20.7602 3.99488 22.3902 3.75488 24.0302 3.75488C32.5302 3.75488 39.5502 10.1849 40.4702 18.4349Z"
                fill="#FF8E00"
              />
              <Path
                d="M2.85004 20.2549C1.11004 18.6049 0 15.9849 0 12.9149C0 11.7149 0.32001 10.2749 0.76001 8.95493C1.20001 7.62493 1.82004 6.25493 2.54004 5.14493C3.39004 3.70493 4.59002 2.50494 5.52002 1.68494C6.03002 1.23494 6.56005 0.814928 7.11005 0.424928L7.22003 0.354936L7.25 0.324937H7.27002C7.61002 0.0849371 8.01004 -0.0150702 8.42004 0.00492981C8.83004 0.0349298 9.21002 0.194937 9.52002 0.464937C9.83002 0.734937 10.04 1.09494 10.11 1.49494C10.19 1.89494 10.13 2.30493 9.95001 2.67493C9.92001 2.78493 9.90002 2.89493 9.90002 3.00493C9.90002 3.23493 9.96002 3.67493 10.52 4.23493C10.79 4.50493 11 4.69494 11.22 4.90494C11.4 5.07494 11.6 5.25494 11.85 5.49494C12.33 5.95494 12.83 6.49493 13.29 7.16493C14.23 8.57493 14.75 10.3449 14.75 12.9149C14.75 15.9849 13.64 18.6049 11.9 20.2649H2.84003L2.85004 20.2549Z"
                fill="#FFE3BF"
              />
              <Path
                d="M11.9101 5.49485C11.6601 5.25485 11.4701 5.07486 11.2801 4.90486C11.0601 4.69486 10.8501 4.50484 10.5801 4.23484C10.0201 3.68484 9.96008 3.23485 9.96008 3.00485C9.96008 2.89485 9.98007 2.78484 10.0101 2.67484C10.1901 2.31484 10.2401 1.89485 10.1701 1.49485C10.0901 1.09485 9.88008 0.734853 9.58008 0.464853C9.28008 0.194853 8.8901 0.0348464 8.4801 0.00484639C8.0701 -0.0251536 7.67008 0.0848439 7.33008 0.314844H7.31006L7.28009 0.354852L7.1701 0.424845C6.6201 0.814845 6.09008 1.24485 5.58008 1.68485C4.65008 2.49485 3.4501 3.70485 2.6001 5.14485C2.2801 5.64485 1.98008 6.19484 1.71008 6.76484H13.0401C12.6701 6.28484 12.2801 5.84485 11.9101 5.49485Z"
                fill="#FF8E00"
              />
              <Path
                d="M11.91 20.2649C12.99 21.3549 13.61 22.7849 14 24.2149C14.76 26.9849 14.76 30.4149 14.76 33.0649V33.2049C14.76 40.7949 13.34 45.3049 11.73 47.9849C11.12 49.0449 10.32 49.9749 9.38 50.7349C9 51.0249 8.60003 51.2749 8.16003 51.4749C8.16003 51.4749 7.69 51.6449 7.38 51.6449C7.11 51.6249 6.85004 51.5649 6.60004 51.4749C6.17004 51.2749 5.76 51.0349 5.38 50.7349C4.43001 49.9749 3.63003 49.0349 3.03003 47.9849C1.42003 45.3049 0 40.7949 0 33.2049V33.0749C0 30.4249 9.77516e-06 26.9949 0.76001 24.2149C1.15001 22.7949 1.77004 21.3649 2.85004 20.2649H11.91Z"
                fill="#FF8E00"
              />
            </Svg>
          }
          hasMore={profile?.user_verified_skills?.length > 10}
          isEmpty={!profile?.user_verified_skills?.length}
          emptyText="This user has not verified any skill, please check back later."
          t={t}
        />
        <View
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "7px",
          }}
        >
          {profile?.user_verified_skills?.slice(0, 10)?.map((d) => (
            <View
              style={{
                fontSize: "10px",
                border: "1px solid #c8c8c8",
                padding: "4px 8px",
                borderRadius: "5px",
                color: "#777986",
                backgroundColor: "#F3F4F8",
                margin: "0px 0px",
                lineHeight: 1.2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              key={d?.id}
            >
              <Text>{d?.title}</Text>
              {+d?.verification_count > 0 && (
                <Text style={{ marginLeft: "10px" }}>
                  +{d?.verification_count ?? "1"}
                </Text>
              )}
            </View>
          ))}
        </View>
        <SectionHeader
          username={username}
          title="Experience"
          svg={
            <Svg
              width={20}
              height={20}
              viewBox="0 0 34 27"
              fill="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                d="M21.2857 6.18182C21.2857 6.18182 21.2857 2 17 2C12.7143 2 12.7143 6.18182 12.7143 6.18182M8.42857 25V6.18182M25.5714 25V6.18182M32 6.18182H2V25H32V6.18182Z"
                stroke="black"
                stroke-width="2.625"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </Svg>
          }
          hasMore={user_experiences?.length > 5}
          isEmpty={!user_experiences?.length}
          emptyText="This user has not provided any work experience, please check back later."
          t={t}
        />
        {user_experiences?.length ? (
          <View>
            {user_experiences?.slice(0, 5)?.map((d) => (
              <ExperienceItem
                key={d?.id}
                title={d?.company}
                subTitle={d?.position}
                startDate={d?.start_date}
                endDate={d?.end_date}
                state={d?.state}
                country={d?.country}
                address={d?.address}
                description={d?.description}
              />
            ))}
          </View>
        ) : null}
        <SectionHeader
          username={username}
          title="Education"
          svg={
            <Svg
              width={20}
              height={20}
              viewBox="0 0 40 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                d="M17.2009 0.629709C18.0761 0.215081 19.0324 0 20.0008 0C20.9691 0 21.9255 0.215081 22.8006 0.629709L39.0464 8.32645C39.3294 8.46041 39.5689 8.67138 39.7376 8.93519C39.9062 9.19901 39.9972 9.505 39.9999 9.81811C40.0027 10.1312 39.9172 10.4388 39.7533 10.7056C39.5894 10.9723 39.3536 11.1875 39.073 11.3265L22.9073 19.3466C22.0039 19.7948 21.0092 20.028 20.0008 20.028C18.9923 20.028 17.9976 19.7948 17.0942 19.3466L3.335 12.5198V19.9999C3.335 20.4419 3.15941 20.8659 2.84687 21.1784C2.53433 21.491 2.11043 21.6666 1.66842 21.6666C1.22642 21.6666 0.80252 21.491 0.489977 21.1784C0.177433 20.8659 0.00184788 20.4419 0.00184788 19.9999V9.91647C-0.0136467 9.58698 0.0685756 9.26027 0.238184 8.97737C0.407792 8.69447 0.657221 8.46802 0.955129 8.32645L17.2009 0.626376V0.629709ZM6.66815 17.8965V23.3333C6.6682 23.5525 6.7115 23.7696 6.79558 23.9721C6.87966 24.1746 7.00286 24.3585 7.15812 24.5133L7.16479 24.5233L7.24145 24.5933L7.44144 24.7799C8.48601 25.711 9.61531 26.5424 10.8146 27.2633C13.0411 28.6 16.2576 30 20.0008 30C23.7439 30 26.9604 28.6 29.1903 27.2633C30.3895 26.5424 31.5188 25.711 32.5634 24.7799L32.7634 24.5933L32.8167 24.5399L32.8367 24.5199L32.8401 24.5166L32.8467 24.5133C33.0014 24.3582 33.124 24.1742 33.2075 23.9717C33.291 23.7692 33.3338 23.5523 33.3334 23.3333V17.8932L24.3872 22.3333C23.0238 23.0094 21.5226 23.3613 20.0008 23.3613C18.4789 23.3613 16.9777 23.0094 15.6143 22.3333L6.66815 17.8932V17.8965Z"
                fill="black"
              />
            </Svg>
          }
          hasMore={user_educations?.length > 5}
          isEmpty={!user_educations?.length}
          emptyText="This user has not provided any education background, please check back later."
          t={t}
        />
        {user_educations?.length ? (
          <View>
            {user_educations?.slice(0, 5)?.map((d) => (
              <ExperienceItem
                key={d?.id}
                title={d?.university}
                startDate={d?.start_date}
                endDate={d?.end_date}
                subTitle={d?.degree}
                address={d?.address}
                description={d?.description}
              />
            ))}
          </View>
        ) : null}
      </Page>
    </Document>
  );
};

export default DownloadProfile;

export const ExperienceItem = ({
  title,
  subTitle,
  address,
  description,
  startDate,
  endDate,
  state,
  country,
}) => {
  return (
    <View
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        marginTop: "10px",
        gap: "10px",
      }}
    >
      <View style={{ width: "20%" }}>
        <Text
          style={{
            color: "#498CCE",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          {title}
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={{ fontSize: "14px", fontWeight: 600 }}>{subTitle}</Text>
        <Text style={{ fontSize: "10px", opacity: 0.5, margin: "5px 0px" }}>
          {`${
            startDate ? ` ${moment(startDate).format("MMMM YYYY")}` : "N/A"
          } ${endDate ? ` - ${moment(endDate).format("MMMM YYYY")}` : "- N/A"}${
            address ? ` | ${address}` : ""
          }${state ? `, ${state}` : ""}${country ? `, ${country}` : ""}`}
        </Text>
        <Text style={{ fontSize: "10px" }}>{description}</Text>
      </View>
    </View>
  );
};

const SectionHeader = ({
  title,
  svg,
  hasMore,
  isEmpty,
  emptyText,
  username,
  t = (d) => d,
}) => {
  return (
    <View
      style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        margin: "20px 0px 10px 0px",
        justifyContent: "space-between",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {svg}
        <Text style={{ fontSize: "14px", marginLeft: "4px", fontWeight: 600 }}>
          {t(title)}
        </Text>
        {isEmpty && (
          <Text
            style={{ fontSize: "10px", color: "#777986", marginLeft: "20px" }}
          >
            {t(emptyText)}
          </Text>
        )}
      </View>
      {hasMore && !isEmpty && (
        <Link
          src={`${getBaseUrl()}profile/${username}`}
          style={{
            color: "#498CCE",
            fontSize: "10px",
            textDecoration: "none",
            borderBottom: "1px solid #498CCE",
            paddingBottom: "1px",
            lineHeight: 1.2,
            fontWeight: 600,
          }}
        >
          {t("View All")}
        </Link>
      )}
    </View>
  );
};

const ListItem = ({ item, skills, banner, isLab, t = (d) => d }) => {
  return (
    <View
      style={{
        padding: "10px",
        border: "1px splid #dadada",
        borderRadius: "5px",
        marginTop: "6px",
      }}
      wrap={false}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <View
          style={{
            width: "30%",
            position: "relative",
          }}
        >
          <Image
            src={banner}
            source={banner}
            style={{ height: "80px", width: "100%" }}
          />
          <View
            style={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80px",
              width: "100%",
              top: "0px",
              left: "0px",
            }}
          >
            <Text
              style={{
                textTransform: "uppercase",
                fontSize: "10px",
                fontWeight: 600,
                letterSpacing: "-0.5px",
                textAlign: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxHeight: "20px",
                margin: "0px 5px",
              }}
            >
              {item?.title}
            </Text>
          </View>
        </View>
        <View
          style={{
            width: "70%",
          }}
        >
          <Link
            style={{
              fontSize: "13px",
              color: "#498CCE",
              fontWeight: 600,
              textDecoration: "none",
              marginBottom: "0px",
              lineHeight: 1.3,
            }}
            src={`${getBaseUrl()}${isLab ? "lab" : "project"}/${item.slug}`}
          >
            {item?.title}
          </Link>
          {isLab && (
            <Text
              style={{
                fontSize: "11px",
                fontWeight: 600,
                marginBottom: "0px",
                lineHeight: 1.3,
              }}
            >
              {item?.organization ?? "N/A"}
            </Text>
          )}
          <Text
            style={{
              lineHeight: 1.1,
              maxHeight: isLab ? "40px" : "52px",
              minHeight: isLab ? "30px" : "42px",
              fontSize: "10px",
            }}
          >
            {stripHTMLTags(item?.description ?? "N/A")}
          </Text>
          {isLab ? (
            <View
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                flexDirection: "row",
                marginTop: "4px",
              }}
            >
              <Svg
                fill="#498CCE"
                viewBox="0 0 1024 1024"
                height={10}
                width={10}
                xmlns="http://www.w3.org/2000/svg"
                stroke="#498CCE"
              >
                <Path d="M960 95.888l-256.224.001V32.113c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76h-256v-63.76c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76H64c-35.344 0-64 28.656-64 64v800c0 35.343 28.656 64 64 64h896c35.344 0 64-28.657 64-64v-800c0-35.329-28.656-63.985-64-63.985zm0 863.985H64v-800h255.776v32.24c0 17.679 14.32 32 32 32s32-14.321 32-32v-32.224h256v32.24c0 17.68 14.32 32 32 32s32-14.32 32-32v-32.24H960v799.984zM736 511.888h64c17.664 0 32-14.336 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32zm0 255.984h64c17.664 0 32-14.32 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.696 14.336 32 32 32zm-192-128h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32zm0-255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm-256 0h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm0 255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32z"></Path>
              </Svg>
              <Text
                style={{ fontSize: "9px", marginLeft: "4px", color: "#5b5d6b" }}
              >
                {item?.duration ?? "N/A"}
              </Text>
              <Svg
                width={15}
                height={15}
                viewBox="0 0 24 24"
                fill="#498CCE"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#498cce"
              >
                <g>
                  {" "}
                  <Path
                    d="M10 9H14V19H10V9Z"
                    stroke=""
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></Path>{" "}
                  <Path
                    d="M17 5H21V19H17V5Z"
                    stroke=""
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></Path>{" "}
                  <Path
                    d="M3 14H7V19H3V14Z"
                    stroke=""
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></Path>{" "}
                </g>
              </Svg>
              <Text
                style={{ fontSize: "9px", marginLeft: "4px", color: "#5b5d6b" }}
              >
                {item?.level?.split(" ")[0] || "N/A"}
              </Text>
            </View>
          ) : (
            <View
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginTop: "4px",
              }}
            >
              <Text
                style={{ fontWeight: 600, fontSize: "11px", minWidth: "119px" }}
              >
                {t("Associated Challenge")}:{" "}
              </Text>
              <Link
                style={{
                  textDecoration: "none",
                  fontSize: "11px",
                  color: "#498CCE",
                  fontWeight: 600,
                  overflow: "hidden",
                  height: "17px",
                  whiteSpace: "nowrap",
                }}
                src={`${getBaseUrl()}challenge/${item?.challenge.slug}`}
              >
                {item?.challenge?.title}
              </Link>
            </View>
          )}
        </View>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          marginTop: "10px",
        }}
      >
        {item?.achievement?.achievement_name && (
          <View
            style={{
              width: "40%",
              position: "relative",
            }}
          >
            <View
              style={{
                backgroundColor: "#d6f2fe",
                padding: "10px 5px",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                borderRadius: "5px",
                gap: "5px",
              }}
            >
              <Image
                src={{
                  uri: `${item.achievement.achievement_image}?no-chache`,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
                // src={`${item?.achievement?.achievement_image}?not-from-cache-please`}
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "100%",
                  border: "2px solid #dadada",
                  padding: "5px",
                  backgroundColor: "#eee",
                }}
              />
              <View style={{ width: "70%" }}>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: 700,
                    lineHeight: 1.3,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    height: "15px",
                  }}
                >
                  {item?.achievement?.achievement_name}
                </Text>
                <Text style={{ fontSize: "9px", lineHeight: 1.3 }}>
                  {item?.achievement?.achievement_type ?? "-"}
                </Text>
                <Text
                  style={{ fontSize: "9px", fontWeight: 700, lineHeight: 1.3 }}
                >
                  {item?.achievement?.achievement_points} {t("Prepr Points")}
                </Text>
              </View>
            </View>
          </View>
        )}
        <View
          style={{
            width: !item?.achievement?.achievement_name ? "100%" : "60%",
          }}
        >
          {!!skills && skills.length ? (
            <View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    color: "#498CCE",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  {t("Verified Skills")}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "4px",
                  marginTop: "4px",
                }}
              >
                {(typeof item?.skills === "object" &&
                Object.values(item?.skills)?.length
                  ? Object.values(item?.skills)
                  : ["N/A"]
                ).map((d) => (
                  <Text
                    style={{
                      fontSize: "8px",
                      border: "1px solid #c8c8c8",
                      padding: "2px 6px",
                      borderRadius: "5px",
                      color: "#777986",
                      backgroundColor: "#F3F4F8",
                      margin: "0px 0px",
                      lineHeight: 1.2,
                    }}
                  >
                    {d}
                  </Text>
                ))}
              </View>
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
};
