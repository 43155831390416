import axios from "axios";
import { toast } from "react-toastify";
import { getFormData } from "./utils";

const baseUrls = {
  local: "http://localhost:8000/api/v1/",
  beta: "https://api.beta.learnlab.ai/api/v1/",
  pre: "https://api.pre.learnlab.ai/api/v1/",
  production: "https://api.learnlab.ai/api/v1/",
};

const getBaseConfig = () => {
  return process.env.REACT_APP_BASE_URL || baseUrls.beta;
};

export const BASE_URL = getBaseConfig();

export const CONTENT_TYPES = {
  APPLICATION_JSON: "application/json",
  MULTIPART_FORM: "multipart/form-data",
  URL_ENCODED: "application/x-www-form-urlencoded",
};

export const extractErrorMessage = (err) => {
  let message = err.message;
  // if (err?.request?.status === 403) {
  //   message = "Forbidden! User not allowed to perfrom the action.";
  //   return message;
  // }
  if (err?.response?.data?.message) {
    const errorData = err?.response?.data;
    message = errorData.message;
    if (errorData.errors) {
      message = Object.values(errorData.errors)[0][0];
    }
    if (errorData.data) {
      message = Object.values(errorData.data)[0][0];
    }
  }
  return message;
};

export default class REQUEST {
  async get(
    route,
    body = {},
    headers = {},
    onSuccess = Function.prototype,
    onError = Function.prototype,
    noToast,
    responseType = undefined
  ) {
    try {
      const response = await axios.get(BASE_URL + route, {
        headers: { Accept: "*/*", ...headers },
        ...(responseType ? { responseType: responseType } : {}),
        data: body,
      });
      onSuccess(response);

      return response;
    } catch (err) {
      let message = extractErrorMessage(err);
      !noToast && toast.error(message || "Something might went wrong!");
      const result = {
        error: true,
        message: message,
        statusCode: err.response?.status ?? 500,
        error_code: err?.response?.data?.error_code,
      };
      onError && onError(result);
      return result;
    }
  }

  async post(
    route,
    body,
    headers = {},
    onSuccess = () => {},
    onError = () => {},
    noToast
  ) {
    try {
      let formData = body;
      if (
        headers["Content-Type"] === CONTENT_TYPES.MULTIPART_FORM ||
        headers["Content-Type"] === CONTENT_TYPES.URL_ENCODED
      ) {
        formData = getFormData(body, headers["Content-Type"]);
      }

      const response = await axios.post(BASE_URL + route, formData, {
        headers: { Accept: "*/*", ...headers },
      });
      onSuccess(response);
      return response;
    } catch (err) {
      let message = extractErrorMessage(err);
      !noToast && toast.error(message || "Something might went wrong!");
      const result = {
        error: true,
        message: message,
        status: err?.response?.status || 500,
        error_code: err?.response?.data?.error_code,
      };
      onError(result);
      return result;
    }
  }

  async put(
    route,
    body,
    headers = {},
    onSuccess = () => {},
    onError = () => {},
    noToast
  ) {
    try {
      let formData = body;
      if (headers["Content-Type"] === CONTENT_TYPES.MULTIPART_FORM) {
        formData = getFormData(body);
      }
      const response = await axios.put(BASE_URL + route, formData, {
        headers: { Accept: "*/*", ...headers },
      });
      onSuccess(response);
      return response;
    } catch (err) {
      let message = extractErrorMessage(err);
      !noToast && toast.error(message || "Something might went wrong!");
      const result = { error: true, message: message };
      onError(result);
      return result;
    }
  }

  async delete(
    route,
    headers = {},
    onSuccess = () => {},
    onError = () => {},
    noToast
  ) {
    try {
      const response = await axios.delete(BASE_URL + route, {
        headers: { Accept: "*/*", ...headers },
      });
      onSuccess(response);
      return response;
    } catch (err) {
      let message = extractErrorMessage(err);
      !noToast && toast.error(message || "Something might went wrong!");
      const result = { error: true, message: message };
      onError(result);
      return result;
    }
  }
}
