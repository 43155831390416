import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "components/Button";
import Card from "components/Cards/Card";
import styles from "pages/challenges/challenges.module.scss";
import { LockIcon } from "components/Statics";

const AccordionUsage = ({
  title = "",
  description = "",
  children,
  locked,
  disabled,
  t = (d) => d,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Card classes="py-4 mt-3 border" noShadow>
      <div
        className={`${styles.toggle_card_wrapper} ${
          disabled ? "opacity-50" : ""
        } card_wrapper`}
      >
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          elevation={0}
        >
          <div className={`flex justify-between items-center px-4`}>
            <div className="flex flex-column items-center justify-center">
              <div>
                <h3 className="fs-20 fw-600">
                  {t(title)}
                  {locked && <LockIcon />}
                </h3>
                <p className="c-gray fs-15">{t(description)}</p>
              </div>
            </div>
            <AccordionSummary aria-controls="panel1-content" id="panel1-header">
              {expanded === "panel1" ? (
                <Button color={"red"} classes={"card_button"}>
                  {t("Close")}
                </Button>
              ) : (
                <Button color={"green"} classes={"card_button"}>
                  {t("Open")}
                </Button>
              )}
            </AccordionSummary>
          </div>
          <AccordionDetails classes={{ root: "px-0" }}>
            <div
              className={`px-4 ${expanded === "panel1" ? "closed" : "opened"}`}
            >
              {children}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Card>
  );
};

export default AccordionUsage;
