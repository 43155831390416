import { Step, StepLabel, Stepper, Box } from "@mui/material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import React, { useState } from "react";
import moment from "moment";
import { NoDataView } from "../../../components/UI/organisms/ListingContainer";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const ActivityFeed = ({ list }) => {
  const [fullView, setFullView] = useState(false);
  const toggleView = () => setFullView((prevView) => !prevView);
  const { t } = useLocalizedTranslation("common", true);

  const StepIcon = ({ isLastStep }) => (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div className="w-24 h-24 rounded-50 bg-darkgray" />
      {!isLastStep && (
        <div
          className="w-5 h-110 bg-darkgray"
          style={{
            position: "absolute",
            width: "5px",
            height: "110px",
            margin: "0 0 0",
            zIndex: 1,
          }}
        />
      )}
    </div>
  );

  return (
    <Card maxHeight="1000px" classes="mt-5 pb-6 overflowY-auto">
      {list && list?.length > 0 ? (
        <Stepper orientation="vertical" className="my-3 " connector={null}>
          {list
            ?.slice(0, fullView ? list?.length : 6)
            .map(({ created_at, activity }, index) => (
              <Step
                key={index}
                expanded={true}
                active={false}
                style={{ position: "relative" }}
                className="h-80 ml-10"
              >
                <StepLabel
                  icon={
                    <StepIcon
                      isLastStep={
                        index === list?.length - 1 || (index === 5 && !fullView)
                      }
                    />
                  }
                  style={{ position: "absolute", left: "170px" }}
                  className="top-0  "
                >
                  <p className="fs-16 c-primary ml-10">{activity}</p>
                </StepLabel>
                <Box style={{ position: "absolute" }} className="top-2  ">
                  <p className="fw-500">
                    {moment(created_at).format("MMMM DD, YYYY")}
                  </p>
                </Box>
              </Step>
            ))}
          {list?.length > 6 && (
            <div
              className="flex justify-center items-center"
              style={{ zIndex: 99 }}
            >
              <Button classes="mx-auto mt-0 w-150 fs-15" onClick={toggleView}>
                {fullView ? t("Load Less") : t("Load More")}
              </Button>
            </div>
          )}
        </Stepper>
      ) : (
        <NoDataView text={t("No Activity yet!")} />
      )}
    </Card>
  );
};

export default ActivityFeed;
