import React, { useState } from "react";
import ResourceListing from "pages/resources/ResourceModule/listing/ResourceListing";
import NavTabs from "components/UI/molecules/NavTabs";

const LabResources = ({
  resource_collection = [],
  resource_group = [],
  resource_module = [],
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const getCurrentTabList = () => {
    let list = [];
    if (activeTab === 0) list = resource_module || [];
    if (activeTab === 1) list = resource_group || [];
    if (activeTab === 2) list = resource_collection || [];
    return list.filter((d) => !!d);
  };

  const filteredList = getCurrentTabList();

  return (
    <>
      <NavTabs
        list={["Resource Module", "Resource Group", "Resource Collection"]}
        value={activeTab}
        onChange={setActiveTab}
        top={4}
      />
      <ResourceListing
        isGroup={activeTab === 1}
        isCollection={activeTab === 2}
        noPagination
        resources={filteredList || []}
      />
    </>
  );
};

export default LabResources;
