import { Grid } from "@mui/material";
import Card from "components/Cards/Card";
import React from "react";
import Button from "components/Button";
import DashboardLayout from "pages/layouts/dashboard";
const Offline = () => {
  return (
    <DashboardLayout>
      <Card
        centered
        width={`min(80vw,800px)`}
        height="fit-content"
        classes="mt-20vh mb-20 column-center"
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          xs={12}
          md={10}
          className="relative mx-auto"
        >
          <h1 className="mt-6 fs-24 text-center tracking-tight">
            You are offline
          </h1>
          <p className="mt-10 fs-15 c-title text-center">
            Please check your internet connection and try again.
          </p>

          <Button
            onClick={() => window.location.reload()}
            classes="mt-10 mb-6"
            width="100px"
            height="35px"
          >
            Retry
          </Button>
        </Grid>
      </Card>
    </DashboardLayout>
  );
};

export default Offline;
