import { warning } from "assets/svg";

const FormInformation = ({ header, lists = [], t = (d) => d }) => {
  return (
    <div
      style={{
        width: "min(1300px,90%)",
        margin: "0 auto",
        border: "1px solid #FF9D00",
        background: "#FFF3CC",
        padding: "14px",
        borderRadius: "10px",
      }}
    >
      <div className="flex items-left gap-10">
        <img src={warning} alt="" />
        <div>
          <h4>{t(header)}</h4>
          <ul
            style={{
              paddingLeft: "20px",
              paddingTop: "10px",
            }}
          >
            {lists.map((list, index) => (
              <li className="fs-14" key={index}>
                {t(list)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FormInformation;
