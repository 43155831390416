import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST, { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { resolveResponse, serializeObject } from "store/api/utils";

const useResources = () => {
  const { language, token } = useSelector((state) => state[REDUCER_TYPES.AUTH]);

  const headers = {
    Authorization: "Bearer " + token,
  };

  //FETCH PUBLIC RESOURCES
  const fetchResources = async (filters = {}) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${
        ENDPOINTS.RESOURCE_MODULE
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      const { list, total_pages, total_count } = res?.data?.data;
      return [list, total_pages, total_count];
    }
    return [];
  };

  //FETCH RESOURCE PUBLIC DETAILS API
  const fetchResource = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_MODULE}/${slug}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  //FETCH MANAGE RESOURCE LISTING
  const fetchMyResources = async (filters) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${
        ENDPOINTS.RESOURCE_MODULE
      }?language=${language}&${serializeObject(filters)}`,
      {},
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    if (res?.data?.data) {
      const { list, total_pages, total_count } = res?.data?.data;
      return [list, total_pages, total_count];
    }
    return [];
  };

  //FETCH RESOURCE PRIVATE DETAILS API
  const fetchMyResource = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${slug}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  //DELETE RESOURCE API
  const deleteResource = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);

    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${slug}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      onSuccess
    );
  };

  const favorite = async (slug, isC, isG) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isC
          ? ENDPOINTS.RESOURCE_COLLECTION
          : isG
          ? ENDPOINTS.RESOURCE_GROUP
          : ENDPOINTS.RESOURCE_MODULE
      }/${slug}/${ENDPOINTS.FAVOURITE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const unFavorite = async (slug, isC, isG) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isC
          ? ENDPOINTS.RESOURCE_COLLECTION
          : isG
          ? ENDPOINTS.RESOURCE_GROUP
          : ENDPOINTS.RESOURCE_MODULE
      }/${slug}/${ENDPOINTS.UNFAVOURITE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const like = async (slug, isC, isG) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isC
          ? ENDPOINTS.RESOURCE_COLLECTION
          : isG
          ? ENDPOINTS.RESOURCE_GROUP
          : ENDPOINTS.RESOURCE_MODULE
      }/${slug}/${ENDPOINTS.LIKE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const unLike = async (slug, isC, isG) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isC
          ? ENDPOINTS.RESOURCE_COLLECTION
          : isG
          ? ENDPOINTS.RESOURCE_GROUP
          : ENDPOINTS.RESOURCE_MODULE
      }/${slug}/${ENDPOINTS.UNLIKE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const share = async (slug, type) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${type}/${slug}/${ENDPOINTS.SHARE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const checkSlug = async (slug) => {
    if (!slug) return;
    const client = new REQUEST();
    const response = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.CHECK_SLUG}/${slug}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (response?.data) return response?.data;
    return response;
  };

  const checkTitle = async (title) => {
    if (!title) return;
    const client = new REQUEST();
    const response = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.CHECK_TITLE}/${title}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (response?.data) return response?.data;
    return response;
  };

  const addRating = async (slug, rating, isC, isG) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isC
          ? ENDPOINTS.RESOURCE_COLLECTION
          : isG
          ? ENDPOINTS.RESOURCE_GROUP
          : ENDPOINTS.RESOURCE_MODULE
      }/${slug}/${ENDPOINTS.ADD_RATING}?language=${language}&rating=${rating}`,
      {},
      headers,
      onSuccess,
      Function.prototype
    );
    if (response?.data) return response?.data;
    return response;
  };

  //CREATE RESOURCE API
  const create = async (payload) => {
    const body = {
      ...payload,
      status: "publish",
      language,
    };

    const onSuccess = (res) =>
      resolveResponse(res, null, "Resource created successfully!");

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.CREATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    if (res?.data?.data) return res?.data?.data;
    return res;
  };

  //EDIT RESOURCE API
  const edit = async (slug, payload) => {
    if (!slug || !payload) return;
    const body = {
      ...payload,
      status: "publish",
      language,
    };

    const onSuccess = (res) =>
      resolveResponse(
        res,
        null,
        "Resource edited successfully!",
        null,
        null,
        true
      );

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${slug}/${ENDPOINTS.UPDATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    if (res?.data?.data) return res?.data?.data;
    return res;
  };

  //ADD LINKS
  const addLinks = async (slug, payload) => {
    if (!slug || !payload) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${slug}/${ENDPOINTS.ADD_LINKS}?language=${language}`,
      payload,
      headers,
      onSuccess
    );
    if (response?.data) return response?.data;
    return response;
  };

  //FILE UPLOAD
  const uploadFiles = async (slug, payload) => {
    if (!slug || !payload) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${slug}/${ENDPOINTS.UPLOAD}?language=${language}`,
      payload,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    if (response?.data) return response?.data;
    return response;
  };

  //DELETE MEDIA
  const deleteMedia = async (slug, id, type) => {
    if (!slug || !id) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.delete(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${slug}/${ENDPOINTS.MEDIA}?language=${language}&media_id=${id}&type=${type}`,
      headers,
      onSuccess
    );
    return Boolean(res?.data?.success);
  };

  const moduleVisit = async (slug, assetId) => {
    if (!slug || !assetId) return;
    const client = new REQUEST();
    await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.RESOURCE_MODULE}/${slug}/${ENDPOINTS.MODULE_VISIT}/${assetId}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
  };

  //FETCH RESOURCE LISTING WITH NAME AND ID
  const fetchResourceSelectList = async (isC, filters = {}) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${
        isC ? ENDPOINTS.RESOURCE_COLLECTION : ENDPOINTS.RESOURCE_MODULE
      }/${ENDPOINTS.GET_LIST}?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data?.length) {
      return res?.data?.data;
    }
    return [];
  };

  // CREATE RESOURCE MODULES PREVIEWS FROM CHALLENGE USING AI API
  const aiCreateResourceModulePreviews = async (payload) => {
    const body = {
      ...payload,
      language,
    };

    const onSuccess = (res) =>
      resolveResponse(res, null, (data) => data?.message, null, false);

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}/${ENDPOINTS.PREVIEW}?language=${language}`,
      JSON.parse(JSON.stringify(body)),
      {
        ...headers,
        "Content-Type": "application/json",
      },
      onSuccess
    );

    return res;
  };

  // CREATE RESOURCE MODULES FROM CHALLENGE USING AI API
  const aiCreateResourceModule = async (payload) => {
    const body = {
      ...payload,
      language,
    };

    const onSuccess = (res) =>
      resolveResponse(res, null, (data) => data?.message, null, true);

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}?language=${language}`,
      JSON.parse(JSON.stringify(body)),
      {
        ...headers,
        "Content-Type": "application/json",
      },
      onSuccess
    );
    if (res?.data?.data) return res?.data?.data;
    return res;
  };

  // CREATE RESOURCE MODULES FROM CHALLENGE USING AI API
  const createResourceModuleGo1 = async (payload) => {
    const body = {
      ...payload,
    };

    const onSuccess = (res) =>
      resolveResponse(res, null, (data) => data?.message, null, true);

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.GO1}/${ENDPOINTS.RESOURCE}/${ENDPOINTS.CREATE}?language=${language}`,
      JSON.parse(JSON.stringify(body)),
      {
        ...headers,
        "Content-Type": "application/json",
      },
      onSuccess
    );
    if (res?.data?.data) return res?.data?.data;
    return res;
  };

  const uploadScormFile = async (slug, payload) => {
    const body = {
      ...payload,
      language,
    };

    const onSuccess = (res) =>
      resolveResponse(res, null, (data) => data?.message, null, true);

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.SCORM}/${ENDPOINTS.SCORM_UPLOAD}/${slug}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );

    if (res?.data) {
      return res?.data;
    }
    return res;
  };

  const deleteScorm = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.delete(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_MODULE}/${ENDPOINTS.SCORM}/${slug}?language=${language}`,
      headers,
      onSuccess
    );
    return Boolean(res?.data?.success);
  };

  /**
   *
   * @param id
   * @returns {Promise<*|null>}
   */
  const fetchPlayerUrl = async (id) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS?.RESOURCE_MODULE}/${ENDPOINTS.SCORM}/${ENDPOINTS.SCORM_PLAYER_URL}/${id}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return null;
  };

  const clone = async (slug, isC, isG) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${
        isC
          ? ENDPOINTS.RESOURCE_COLLECTION
          : isG
          ? ENDPOINTS.RESOURCE_GROUP
          : ENDPOINTS.RESOURCE_MODULE
      }/${slug}/${ENDPOINTS.CLONE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };
  
  const fetchResourceGroupSelectList = async (filters = {}) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.RESOURCE_GROUP}/${
        ENDPOINTS.GET_LIST
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data?.length) {
      return res?.data?.data;
    }
    return [];
  };

  return {
    fetchMyResource,
    fetchMyResources,
    fetchResource,
    fetchResources,
    deleteResource,
    like,
    unLike,
    favorite,
    unFavorite,
    checkTitle,
    checkSlug,
    share,
    addRating,
    create,
    edit,
    addLinks,
    uploadFiles,
    deleteMedia,
    fetchResourceSelectList,
    aiCreateResourceModulePreviews,
    aiCreateResourceModule,
    createResourceModuleGo1,
    uploadScormFile,
    deleteScorm,
    fetchPlayerUrl,
    moduleVisit,
    clone,
    fetchResourceGroupSelectList,
  };
};

export default useResources;
