import { Box, IconButton } from "@mui/material";
import React from "react";
import { DeleteForever, OpenWithOutlined } from "@mui/icons-material";

const DashboardCard = ({
  classes,
  children,
  isCustomizeMode,
  id,
  setInactiveById = Function.prototype,
}) => {
  return (
    <Box className="relative h-100p">
      {isCustomizeMode && (
        <div
          className="absolute w-100p h-100p flex justify-center items-center rounded-10 z-10"
          style={{ backgroundColor: "#00000050" }}
        >
          <OpenWithOutlined
            fontSize="large"
            className="c-white cursor-pointer"
          />
          {id === "my-resources" ||
          id === "recommendations" ||
          id === "inbox-friends" ? (
            <IconButton
              onPointerDown={() => setInactiveById(id)}
              className="z-90"
            >
              <DeleteForever fontSize="large" className="c-error" />
            </IconButton>
          ) : null}
        </div>
      )}
      <Box
        data-testid="feature-card"
        className={`${classes} overflow-hidden w-100p h-100p bg-white rounded-10 shadow pt-3`}
        sx={{
          "& .MuiTableFooter-root .MuiTableRow-root:last-child td": {
            border: 0,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DashboardCard;
