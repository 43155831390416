import { Grid } from "@mui/material";
import { logo10Year } from "assets/svg";
import Divider from "components/Divider";
import SwipableView from "components/UI/organisms/SwipableView";
import useRedirect from "helpers/hooks/useRedirect";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import { isValidUrl } from "helpers/utils/utilities";
import i18next from "i18next";
import moment from "moment";
import { footer } from "pages/layouts/data";
import React, { useEffect, useMemo, useState } from "react";
import useOnboarding from "store/onboarding/service-hook";

const mockHook = () => ({
  startPage: null,
});

const Footer = ({ isLoggedIn, t = (d) => d }) => {
  const { labs, quickLinks, socialLinks, partners } = footer;
  const { navigateTerms, navigatePrivacyPolicy } = useRedirect();
  const width = useWindowWidth();
  const hookFunc = !isLoggedIn ? useOnboarding : mockHook;
  const { startPage } = hookFunc();
  const [_labs, setLabs] = useState([]);

  const slidesPerView = useMemo(
    () => (width > 1200 ? 5 : width > 900 ? 4 : width > 500 ? 2 : 1),
    []
  );

  useEffect(() => {
    fetchLabDetails();
  }, []);

  const fetchLabDetails = async () => {
    if (isLoggedIn) return;
    const data = await startPage(i18next.language);
    setLabs(data?.labs || []);
  };

  return (
    <div
      className={`w-full bottom-0 bg-white mt-10 ${
        !isLoggedIn ? "pt-6 pb-4" : "py-1"
      }`}
    >
      {!isLoggedIn && (
        <div className="container mb-2">
          <Grid className="fs-14" container spacing={2}>
            <Grid item xs={6} md={3} lg={3}>
              <h3 className="mb-2">{t("Labs")}</h3>
              {(_labs?.length ? _labs : labs)?.map((d, i) => (
                <p
                  className="my-1 cursor-pointer"
                  onClick={() => window.open(d?.path)}
                  key={i}
                >
                  {d.title ?? "N/A"}
                </p>
              ))}
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <h3 className="mb-2">{t("Quick Links")}</h3>
              {quickLinks?.map((d, i) => (
                <p
                  className="my-1 cursor-pointer"
                  onClick={() =>
                    window.open(
                      isValidUrl(d?.slug) ? d?.slug : d?.path,
                      "_blank"
                    )
                  }
                  key={i}
                >
                  {t(d.title)}
                </p>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              alignItems={{ xs: "center", md: "end" }}
              flexDirection="column"
              display="flex"
            >
              <div className="flex my-1">
                {socialLinks?.map((d, i) => (
                  <img
                    key={i}
                    alt="social media links"
                    className="h-40 cursor-pointer mr-2"
                    src={d?.icon}
                    onClick={() => window.open(d?.path, "_blank")}
                  />
                ))}
              </div>
              <img src={logo10Year} alt="logo" className="mt-4 w-150" />
            </Grid>
          </Grid>

          <div className="flex-center justify-center mt-4">
            <SwipableView
              slidesPerView={slidesPerView}
              navigation={false}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
            >
              {partners?.map((d, i) => (
                <div className="mb-2 flex-center justify-center" key={i}>
                  <img src={d?.icon} alt="" className="h-40 cursor-pointer" />
                </div>
              ))}
            </SwipableView>
          </div>
          <Divider classes="w-full" />
        </div>
      )}
      <div className="container">
        <div className="flex-colOnMob justify-between fs-14">
          <p>
            &copy;{" "}
            {t("${year} Prepr Foundation. All rights reserved.", {
              year: moment().format("YYYY"),
            })}
          </p>
          <p className="fw-500 c-title">V2.0.1</p>
          <div className="flex-center">
            <p
              className="cursor-pointer mr-4 fw-500 c-title"
              onClick={navigateTerms}
            >
              {t("Terms")}
            </p>
            <p
              className="cursor-pointer fw-500 c-title"
              onClick={navigatePrivacyPolicy}
            >
              {t("Privacy Policy")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
