import SearchableSelect from "components/Input/SearchableSelect";
import React, { useEffect, useState } from "react";
import useAssociation from "store/association";
import useChallenges from "store/challenges/service-hook";
import useLabPrograms from "store/lab-programs/service-hook";
import useLabs from "store/labs/service-hook";
import useLeaderboard from "store/leaderboard/service-hook";
import useMaster from "store/master/service-hook";
import useOrganization from "store/organization/service-hook";
import useProjects from "store/projects/service-hook";

const MasterSelect = ({
  name,
  title,
  classes,
  onChange = Function.prototype,
  placeholder,
  required,
  filters = {},
  hasIcon = false,
  value,
  t = (d) => d,
  ...props
}) => {
  const [fetch, setFetch] = useState([]);
  const { getOrganizationSelectList } = useOrganization();
  const { fetchLabsSelectList } = useLabs();
  const { fetchLabsProgramsSelectList } = useLabPrograms();
  const { fetchAssociation } = useAssociation();
  const { fetchChallengeSelectList } = useChallenges();
  const { fetchActiveLabs } = useProjects();
  const { getLabChallenges } = useLeaderboard();
  const {
    fetchSocialLinks,
    fetchLabConditions,
    fetchPitchTemplates,
    fetchFlexibleDDs,
    fetchHosts,
    fetchRanks,
    fetchAchievementConditions,
    fetchProjectSubmissions,
    fetchSkillGroups,
    fetchSkillSets,
    fetchCategories,
    fetchSkills,
    fetchTags,
    fetchIndustries,
    fetchTypes,
    fetchStages,
    fetchVerticals,
    fetchProjectStatus,
    fetchSocialConnect,
    fetchLevel,
    fetchDurations,
    fetchTagGroups,
    fetchJobs,
    fetchCampusConnectSchools,
    fetchBusinessChallengeTacklings,
  } = useMaster();
  
  useEffect(() => {
    if (fetch && Array.isArray(fetch) && typeof fetch[0] === "function") {
      setFetch((prev) => [prev[0], { ...(prev[1] || {}), ...filters }]);
    }
  }, [Object.values(filters)[0]]);

  useEffect(() => {
    fetchBySelectName();
  }, []);

  const fetchBySelectName = () => {
    let fun;
    let args = { ...filters };
    switch (name) {
      case "skills":
        fun = fetchSkills;
        break;
      case "tag_groups":
        fun = fetchTagGroups;
        break;
      case "socialConnect":
        fun = fetchSocialConnect;
        break;
      case "labCondition":
        fun = fetchLabConditions;
        break;
      case "socialLinks":
        fun = fetchSocialLinks;
        break;
      case "pitchTemplate":
      case "template_id":
        fun = fetchPitchTemplates;
        break;
      case "host":
      case "host_id":
        fun = fetchHosts;
        break;
      case "flexibleDateDuration":
        fun = fetchFlexibleDDs;
        break;
      case "projectSubmission":
      case "project_submission_requirement_ids":
        fun = fetchProjectSubmissions;
        break;
      case "skillGroups":
      case "skill_groups":
        fun = fetchSkillGroups;
        break;
      case "categories":
      case "category":
      case "category_id":
        fun = fetchCategories;
        break;
      case "skillSets":
      case "skill_sets":
      case "skill_stacks":
        fun = fetchSkillSets;
        break;
      case "ranks":
        fun = fetchRanks;
        break;
      case "tags":
        fun = fetchTags;
        break;
      case "types":
      case "type_id":
        fun = fetchTypes;
        break;
      case "industries":
      case "industry_id":
        fun = fetchIndustries;
        break;
      case "stages":
      case "stage_id":
        fun = fetchStages;
        break;
      case "verticals":
      case "verticals_id":
        fun = fetchVerticals;
        break;
      case "projectStatus":
      case "status_id":
        fun = fetchProjectStatus;
        break;
      case "achievementCondition":
      case "achievement_conditions":
        fun = fetchAchievementConditions;
        break;
      case "level":
      case "level_id":
        fun = fetchLevel;
        break;
      case "duration":
      case "duration_id":
        fun = fetchDurations;
        break;
      case "organizations":
      case "organization":
      case "organization_id":
        fun = getOrganizationSelectList;
        break;
      case "active_labs":
        fun = fetchActiveLabs;
        break;
      case "labs":
      case "lab_ids":
      case "lab_id":
        fun = fetchLabsSelectList;
        break;
      case "lab_programs":
        fun = fetchLabsProgramsSelectList;
        break;
      case "challenges":
      case "challenge_ids":
      case "challenge_id":
        fun = fetchChallengeSelectList;
        break;
      case "lab_challenge_id":
        fun = fetchAssociation;
        break;
      case "jobs":
        fun = fetchJobs;
        break;
      case "campus_connect_schools":
        fun = fetchCampusConnectSchools;
        break;
      case "business_challenge_tacklings":
        fun = fetchBusinessChallengeTacklings;
        break;
      case "l_lab_challenge_id":
        fun = getLabChallenges;
        break;
      default:
        fun = null;
    }
    if (!Object.entries(args)?.length) args = undefined;
    setFetch([fun, args]);
  };

  return (
    <SearchableSelect
      name={name}
      title={title}
      classes={classes}
      onChange={onChange}
      placeholder={placeholder}
      fetch={fetch}
      required={required}
      hasIcon={hasIcon}
      value={value}
      t={t}
      {...props}
    />
  );
};

export default MasterSelect;
