import Card from "components/Cards/Card";
import React from "react";
import TableComponent from "components/Table";
import MasterSelect from "./MasterSelect";
import Paginator from "../atoms/Paginator";
import ListingPageHeader from "./ListingPageHeader";
import { COLORS } from "helpers/utils/constants";
import { useTranslation } from "react-i18next";

const SORT_OPTIONS = [
  {
    value: "Learning Rank",
    key: "learning_rank",
  },
  {
    value: "Achievements",
    key: "achievement",
  },
  {
    value: "Learning Points",
    key: "learning_points",
  },
];

const LeaderBoardTable = ({
  rows,
  columns,
  loading,
  isLab,
  page = 1,
  setPage = Function.prototype,
  pageCount = 1,
  sort,
  setSort = Function.prototype,
  filter,
  setFilter = Function.prototype,
  username,
  totalCount,
  isDashboard,
  masterSelectName = "challenge_id",
  apiFilters,
}) => {
  const { t } = useTranslation("achievements");

  return (
    <>
      {!isLab && !isDashboard && (
        <ListingPageHeader
          setSort={setSort}
          sort={sort}
          title={`${totalCount} ${t("Results")}`}
          noGrid
          sortOptions={SORT_OPTIONS}
        />
      )}
      {!isDashboard ? (
        <Card centered classes="mt-4">
          {isLab && (
            <div className="flex-colOnMob items-center justify-between mb-1">
              <h3>{t("Leaderboard")}</h3>
              <div className="w-40p w-80p--mob mt-1--mob">
                <MasterSelect
                  classes="w-full h-100p"
                  width="99%"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  name={masterSelectName}
                  placeholder="Filter by Challenge"
                  filters={apiFilters}
                  t={(val, ns) => t(val, { ns: ns })}
                  multi
                />
              </div>
            </div>
          )}

          <TableComponent
            minWidth="min(250px,100%)"
            rows={rows}
            columns={columns}
            rowCellClasses="text-center fs-14--mob"
            rowSx={(i, row) =>
              row?.username === username && {
                backgroundColor: COLORS.BLUE_OPAQUE,
              }
            }
            loading={loading}
            hideLastRowBorder
          />
          <Paginator page={page} setPage={setPage} count={pageCount} />
        </Card>
      ) : (
        <>
          <TableComponent
            minWidth="min(250px,100%)"
            rows={rows}
            columns={columns}
            rowCellClasses="text-center fs-14"
            rowSx={(i, row) =>
              row?.username === username && {
                backgroundColor: COLORS.BLUE_OPAQUE,
              }
            }
            loading={loading}
            dense
            emptyViewClasses="h-270"
            loadingContainerClasses="h-270"
          />
          {totalCount > 0 && (
            <Paginator
              page={page}
              setPage={setPage}
              count={pageCount}
              mt={2}
              leftContent={
                <p className="fw-500 fs-14 opacity-70 ml-2 mt-1">
                  {totalCount === 0
                    ? "0 - 0 of 0"
                    : `${(page - 1) * 5 + 1} - ${Math.min(
                        page * 5,
                        totalCount
                      )} ${t("of")} ${totalCount}`}
                </p>
              }
            />
          )}
        </>
      )}
    </>
  );
};
export default LeaderBoardTable;
