import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";
import { Alert, Grid } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import TextBox from "components/Input/Textbox";
import ModalLayout from "components/Modal";
import { payloadFormatArray, downloadFile } from "helpers/utils/utilities";
import { useEffect, useState } from "react";
import useProjects from "store/projects/service-hook";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
const ProjectAssessModal = ({
  open,
  onClose,
  project,
  viewMode,
  onSuccessCb = Function.prototype,
}) => {
  const { addAssessment } = useProjects();
  const { t } = useLocalizedTranslation();
  const [overall, setOverall] = useState({
    comment: project?.assessment_data?.assessment_over_all_comment || "",
    title: t("Overall"),
    weight: "-",
  });
  const [assessment, setAssessment] = useState(
    project?.assessment_data?.assessment_scoring_data || []
  );
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    calcOverall();
  }, [assessment]);

  useEffect(() => {
    if (!project) return;
    setAssessment(project.assessment_data?.assessment_scoring_data || []);
    setOverall({
      comment: project?.assessment_data?.assessment_over_all_comment || "",
      title: "Overall",
      weight: "-",
    });
  }, [project?.id]);

  const validateAssessment = () => {
    let errorMessage;
    const isScoreInvalid = assessment.some((item) => {
      return (
        !item.score_received ||
        isNaN(item.score_received) ||
        +item?.score_received > +item?.score ||
        +item?.score_received < 0
      );
    });
    const isCommentInvalid = !(overall.comment || "").trim();
    if (isScoreInvalid) {
      errorMessage = t(
        "All scores must be in between 0 to maximum score value!"
      );
    } else if (isCommentInvalid) {
      errorMessage = t("Overall Comment is required!");
    }
    setError(errorMessage);
    return !errorMessage;
  };

  const submitAssessment = async (isPublish = true) => {
    if (!validateAssessment()) return;
    setLoading(isPublish ? "published" : "draft");
    const payload = {
      score: payloadFormatArray(assessment, "score_received"),
      comment: payloadFormatArray(assessment, "comment"),
      criteria_id: payloadFormatArray(assessment, "id"),
      criteria_comment: overall?.comment,
      status: isPublish ? "published" : "draft",
    };
    const res = await addAssessment(project?.slug, payload);
    setLoading(false);
    onSuccessCb(res);
    onClose();
  };

  const changeAssessment = (e, index) => {
    if (error) setError(null);
    const { name, value } = e.target;
    setAssessment((prev) =>
      prev.map((item, idx) =>
        idx === index ? { ...item, [name]: value } : item
      )
    );
    if (name === "score_received") calcOverall();
  };

  const calcOverall = () => {
    setError();
    const overallObject = assessment.reduce(
      (accumulator, currentValue) => {
        accumulator.score += !currentValue.score
          ? 0
          : parseInt(currentValue.score);
        accumulator.score_received += !currentValue.score_received
          ? 0
          : parseInt(currentValue.score_received);
        return accumulator;
      },
      {
        title: "Overall",
        score: 0,
        score_received: 0,
        weight: "-",
      }
    );
    if (isNaN(overallObject?.score)) overallObject.score = "-";
    if (isNaN(overallObject?.score_received))
      overallObject.score_received = "-";

    setOverall((prev) => ({ ...overallObject, comment: prev?.comment }));
  };

  const extractFileNameFromUrl = (url) => {
    return url.split("/").pop();
  };

  const handleDownload = (attachments) => {
    if (!attachments) return;
    const name = extractFileNameFromUrl(attachments?.assessment_attachments);
    const path = attachments.assessment_attachments;
    downloadFile({
      path,
      name,
    });
  };

  return (
    <ModalLayout open={open} width="min(95%,800px)" onClose={onClose}>
      <div className="px-4 py-4">
        <h2>{viewMode ? t("View Assessment") : t("Assess This Project")}</h2>
        <p className="mt-3 fs-15">
          {t(
            "This is a public scorecard that allows for open evaluation of the project. When assessing, please respect the terms and conditions.Please note that after submission, you are not able to edit your submission again."
          )}
        </p>
        <h3 className="mt-6">
          {t("Assessment Guideline")}
          {!!project?.assessment_data?.assessment_attachments && (
            <span
              onClick={() => handleDownload(project?.assessment_data)}
              className="link ml-1 fs-16"
            >
              {t("Download Guideline")}
            </span>
          )}
        </h3>
        <p className="fs-15 mt-2">
          {t(
            "This is a public scorecard that allows for open evaluation of the project. When assessing, please respect the terms and conditions."
          )}
          "
        </p>
        {error ? (
          <Alert
            icon={<HighlightOff color="disabled" />}
            severity="error"
            className="c-error mt-3"
          >
            {error}
          </Alert>
        ) : (
          project?.assessment_data?.assessment_status === "draft" && (
            <Alert
              icon={<CheckCircleOutline color="disabled" />}
              severity="success"
              className="c-success mt-3"
            >
              {t("This assessment has been saved as draft.")}
            </Alert>
          )
        )}
        <Grid container className="fs-14 opacity-50 mb-1" mt={3}>
          <Grid item xs={3}>
            <p>{t("Critiria")}</p>
          </Grid>
          <Grid item xs={2}>
            <p>{t("Weight")}</p>
          </Grid>
          <Grid item xs={2}>
            <p>{t("Score")}</p>
          </Grid>
          <Grid item xs={5}>
            <p>{t("Comments")}</p>
          </Grid>
        </Grid>
        {assessment?.map((d, i) => (
          <AssessmentRow
            assessment={d}
            key={i}
            onChange={(e) => changeAssessment(e, i)}
            viewMode={viewMode}
            t={t}
          />
        ))}
        <AssessmentRow
          isOverall
          assessment={overall}
          onChange={(e) => setOverall({ ...overall, comment: e.target.value })}
          viewMode={viewMode}
          t={t}
        />
        {!viewMode && (
          <div className="flex my-2 justify-between">
            <Button
              width="120px"
              height="35"
              bordered
              loading={loading === "draft"}
              onClick={() => submitAssessment(false)}
            >
              {t("Save Draft")}
            </Button>
            <Button
              width="120px"
              height="35"
              loading={loading === "published"}
              onClick={submitAssessment}
            >
              {t("Submit")}
            </Button>
          </div>
        )}
      </div>
    </ModalLayout>
  );
};

export default ProjectAssessModal;

const AssessmentRow = ({
  assessment,
  isOverall,
  onChange = Function.prototype,
  viewMode,
  t = (d) => d,
}) => {
  return (
    <Grid container py={2} className={`${isOverall && "border-t"} fs-15`}>
      <Grid item xs={8} sm={3} className="fw-500 pr-2 fs-14">
       { t(assessment?.title)}
      </Grid>
      <Grid item xs={4} sm={2} pl={1}>
        {assessment?.weight}
        {!isOverall && "%"}
      </Grid>
      <Grid item xs={12} sm={2}>
        <div className="flex-center">
          {isOverall ? (
            <div>
              {assessment?.score_received}/{assessment?.score}
            </div>
          ) : (
            <>
              <Input
                width="50px"
                name="score_received"
                value={assessment?.score_received}
                onChange={onChange}
                disabled={!!viewMode}
              />{" "}
              <span className="ml-1">/{assessment?.score}</span>
            </>
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextBox
          height={isOverall ? "110px" : "70px"}
          textareaClasses={isOverall ? "minh-110 maxh-110" : "minh-70 maxh-70"}
          placeholder={t(
            `Add comments here ${isOverall ? "(required)" : "(optional)"}`
          )}
          name="comment"
          onChange={onChange}
          value={assessment?.comment}
          disabled={!!viewMode}
        />
      </Grid>
    </Grid>
  );
};
