import { Grid } from "@mui/material";
import Button from "components/Button";
import React, { useEffect, useState, useMemo } from "react";
import SwipableView from "components/UI/organisms/SwipableView";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import FeatureCard from "./FeatureCard";
import TipsCard from "./TipsCard";
import GroupIcon from "@mui/icons-material/Group";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { challenge } from "assets/svg/green";
import SkillCard from "./SkillCard";
import ProductCard from "./ProductCard";
import { resource } from "assets/svg/purple";
import useExplore from "store/explore/service-hook";
import * as _ from "lodash";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import useAuth from "store/auth/service-hook";
import useRedirect from "helpers/hooks/useRedirect";
import {
  resume,
  question,
  career,
  dashboard,
  achievement,
  business,
  challenges,
  labs,
} from "assets/svg/explore/exports";
import { COMPONENT_TYPES, ROLES } from "helpers/utils/constants";
import useOrganization from "store/organization/service-hook";
import useLabMarketplace from "store/lab-marketplace/service-hook";
import { NoDataView } from "components/UI/organisms/ListingContainer";
import { isTrue } from "helpers/utils/validators";
import moment from "moment";
import { AccessAlarm, Security } from "@mui/icons-material";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import ExploreFeatureCard from "components/Loaders/ExploreFeatureCard";
import ExploreTipsCard from "components/Loaders/ExploreTipsCard";
import ExploreProductCard from "components/Loaders/ExploreProductCard";
import ExploreSkillCard from "components/Loaders/ExploreSkillCard";

const ExploreMainPage = () => {
  const width = useWindowWidth();
  const { recommended, featured, recommendedSkills, teams } = useExplore();
  const [featuredItems, setFeaturedItems] = useState([]);
  const [recommendedItems, setRecommendedItems] = useState([]);
  const [recommendedSkillsItems, setRecommendedSkillsItems] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [showAllSkills, setShowAllSkills] = useState(false);
  const [loading, setLoading] = useState(false);
  const { list } = useLabMarketplace();
  const [preBuiltLabs, setPreBuiltLabs] = useState([]);
  const { organizationId } = useOrganization();
  const { t } = useLocalizedTranslation();
  const {
    state: { user, isLoggedIn },
  } = useAuth();
  const isManagerView = user?.roles !== ROLES.USER && Boolean(user?.roles);
  const {
    navigateProfile,
    navigateDashboard,
    navigateManageChallenges,
    navigateChallenge,
    navigateManageLabs,
    navigateLabMarketplace,
    navigateAchievements,
    navigateLeaderboard,
    navigateTeamMatching,
  } = useRedirect();

  useEffect(() => {
    if (isManagerView && organizationId && isLoggedIn) fetchPrebuiltLabsList();
  }, [organizationId]);

  useEffect(() => {
    if (!isLoggedIn) return;
    fetchRecommended();
    fetchFeatured();
    fetchRecommendedSkills();
    if (!isManagerView) fetchTeams();
  }, []);

  const fetchRecommended = async () => {
    setLoading(true);
    const res = await recommended();
    if (res) {
      const addType = (list, type) =>
        (list || [])?.map((d) => ({ ...d, type }));
      const items = _.shuffle([
        ...addType(res?.labs, "lab"),
        ...addType(res?.challenges, "challenge"),
      ]);
      setRecommendedItems(items);
    }
    setLoading(false);
  };
  const fetchFeatured = async () => {
    const res = await featured();
    setFeaturedItems(res);
  };
  const fetchRecommendedSkills = async () => {
    const res = await recommendedSkills();
    setRecommendedSkillsItems(res);
  };

  const fetchTeams = async () => {
    const res = await teams();
    setTeamList(res);
  };

  const fetchPrebuiltLabsList = async () => {
    const filters = {
      organization_id: organizationId,
    };
    const res = await list(filters);
    if (res) setPreBuiltLabs(res?.list || []);
  };

  const tips = useMemo(() => {
    let tipsArray = [
      {
        title: "Upload Your Resume",
        description:
          "Elevate your exploration and discover opportunities uniquely suited to your profile. Take the first step towards a tailored experience – upload your resume now for a personalized journey on our Explore Page!",
        image: resume,
        btnTitle: "Upload Resume",
        onClick: () => navigateProfile(user?.username),
      },
      {
        title: "Save Your Favourite Skills & Jobs",
        description:
          "Your preferences matter, and by curating your profile with the skills you love and the jobs you aspire to, we can deliver recommendations tailored just for you.",
        image: career,
        btnTitle: "Setup Profile",
        onClick: () => navigateProfile(user?.username),
      },
      {
        title: "Review Your Dashboard",
        description:
          "Your personalized dashboard is the command center for your journey, offering a real-time snapshot of achievements, deadlines, and ongoing progress. Head to your dashboard now and take control of your learning journey with ease!",
        image: dashboard,
        btnTitle: "Review Dashboard",
        onClick: () => navigateDashboard(),
      },
      {
        title: "Explore Our Knowledge Base",
        description:
          "Whether you're a newcomer or an experienced user, our extensive collection of articles is here to guide you. Find solutions, tips, and step-by-step instructions to enhance your experience.",
        image: question,
        onClick: () => window.open("https://intercom.help/preprlabs/" + user?.preferred_language?.substring(0, 2) || "en"),
          //Using substring() above as Intercom only accepts two-letter ISO 639 language codes
        btnTitle: "Explore Help Center",
      },
    ];

    if (isManagerView) {
      tipsArray[0] = {
        title: "Create a Challenge",
        description:
          "Ready to challenge the norm? Be the architect of your first Challenge on PreprLabs! Shape the narrative, invite collaborators, and spark innovation. Your ideas, your challenge—let's turn possibilities into achievements!",
        image: challenges,
        btnTitle: "Manage Challenges",
        onClick: () => navigateManageChallenges(),
      };
      tipsArray[1] = {
        title: "Create a Lab",
        description:
          "As a manager, 'Create Labs' empowers you to sculpt the future of innovation. This is your space to design, deploy, and lead experiments that drive success.",
        image: labs,
        btnTitle: "Manage Labs",
        onClick: () => navigateManageLabs(),
      };
    } else {
      if (recommendedSkillsItems.length > 0) {
        tipsArray[1] = {
          title: "Browse Leaderboard",
          description:
            "Curious about how you stack up against your peers? Dive into the excitement on our Leaderboard! Browse through and compare your Learn Rank, Learn Points, and the number of achievements with fellow learners.",
          image: business,
          btnTitle: "Browse Leaderboard",
          onClick: () => navigateLeaderboard(),
        };
      }
      if (isTrue(user?.resume)) {
        tipsArray[0] = {
          title: "Get New Achievements",
          description:
            "Ready to showcase your skills and earn achievements? Join our Challenges and Labs to not only level up your expertise but also unlock exclusive achievements along the way!",
          image: achievement,
          btnTitle: "View Achievements",
          onClick: () => navigateAchievements(),
        };
      }
    }

    return tipsArray;
  }, [recommendedSkillsItems.length, isManagerView]);

  const slidesPerView = useMemo(
    () => (width > 1200 ? 4 : width > 900 ? 3 : width > 500 ? 2 : 1),
    []
  );

  return (
    <>
      <div className="container mt-20">
        {loading ? (
          <SwipableView slidesPerView={width > 1000 ? 2 : 1}>
            {Array.from({ length: 2 }, (_, i) => (
              <ExploreFeatureCard key={i} />
            ))}
          </SwipableView>
        ) : (
          <div>
            <h2>{t("Featured By Prepr")}</h2>

            {featuredItems.length > 0 ? (
              <SwipableView slidesPerView={width > 1000 ? 2 : 1}>
                {featuredItems.map((d, i) => (
                  <FeatureCard
                    key={i}
                    title={d?.title}
                    description={d?.description}
                    image={d?.media}
                    slug={d?.module_slug}
                    type={
                      d?.module_type === "labs"
                        ? COMPONENT_TYPES.LAB
                        : COMPONENT_TYPES.CHALLENGE
                    }
                    buttonTitle={d?.button_text}
                  />
                ))}
              </SwipableView>
            ) : (
              <NoDataView
                classes="my-5"
                text={t("No featured labs found")}
                small
              />
            )}
          </div>
        )}
      </div>

      <div className="w-full pt-1  pb-2 bg-light-yellow mt-6">
        <div className="container my-5">
          <h2>{t("New to PreprLabs? Here are some tips.")}</h2>
          <p className="fs-14">
            {t(
              "Welcome to PreprLabs! If you're new to our platform, this section is your go-to guide for getting started. Discover essential tips and tricks to navigate PreprLabs seamlessly. From account setup to maximizing the features, we've curated a quick reference to ensure you make the most of your experience. Dive into the world of PreprLabs with confidence, armed with the insights you need to succeed!"
            )}
          </p>
          <Grid container mt={1} spacing={2}>
            {loading
              ? Array.from({ length: 4 }, (_d, i) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={i}
                    className="h-400--noMob"
                  >
                    <ExploreTipsCard />
                  </Grid>
                ))
              : tips.map((d, i) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={i}
                    className="h-400--noMob"
                  >
                    <TipsCard
                      title={d?.title}
                      description={d?.description}
                      image={d?.image}
                      btnTitle={d?.btnTitle}
                      onClick={d?.onClick}
                    />
                  </Grid>
                ))}
          </Grid>
        </div>
      </div>
      {recommendedItems.length > 0 && (
        <div className="container mt-6">
          <h2 className="mt-7">
            {recommendedSkillsItems.length > 0
              ? t("Recommended For You")
              : t("Trending on PreprLabs")}
          </h2>
          <p className="fs-14">
            {recommendedSkillsItems.length > 0
              ? t(
                  "Dive into Labs and Challenges meticulously chosen based on the skills and jobs you've added to your profile. Your journey just got personal, as we curate opportunities that align perfectly with your aspirations. Seize the chance to elevate your skills and thrive in areas that matter most to you."
                )
              : t(
                  "Explore the labs and challenges that have captured the spotlight, attracting the most new registrations in the past 30 days. Stay ahead of the curve, challenge yourself, and be part of the community excitement."
                )}
          </p>
          <SwipableView
            dynamicBullets
            slidesPerView={slidesPerView}
            classes="mt-5"
          >
            {loading
              ? Array.from({ length: 4 }, (_d, i) => (
                  <ExploreProductCard key={i} />
                ))
              : recommendedItems?.map(
                  (
                    {
                      title,
                      category,
                      slug,
                      media,
                      type,
                      challenge_count,
                      resource_module_count,
                      member_count,
                      level,
                      skills,
                      challenge_timelines,
                    },
                    i
                  ) => (
                    <ProductCard
                      key={i}
                      t={t}
                      title={title}
                      subTitle={category}
                      image={media}
                      type={type}
                      slug={slug}
                      iconsAndValuesList={
                        type === "lab"
                          ? [
                              {
                                img: challenge,
                                value: `${challenge_count || "0"} ${t(
                                  "Challenges",
                                  "common"
                                )}`,
                              },
                              {
                                img: resource,
                                value: `${resource_module_count || "0"} ${t(
                                  "Resources",
                                  "common"
                                )}`,
                              },
                              {
                                icon: GroupIcon,
                                value: `${member_count} ${t(
                                  "Members",
                                  "common"
                                )}`,
                              },
                              level && {
                                icon: SignalCellularAltIcon,
                                value: level,
                              },
                            ].filter(Boolean)
                          : [
                              {
                                icon: AccessTimeIcon,
                                value: `${t("Open Until")} ${
                                  challenge_timelines?.timeline_type ===
                                  "Flexible"
                                    ? challenge_timelines?.flexible_expire_deadline
                                      ? moment(
                                          challenge_timelines?.flexible_expire_deadline
                                        ).format("D MMM YYYY")
                                      : "Flexible"
                                    : moment(
                                        challenge_timelines?.application_deadline_date
                                      ).format("D MMM YYYY")
                                }`,
                              },
                              {},
                              {
                                icon: GroupIcon,
                                value: `${member_count} ${t(
                                  "Members",
                                  "common"
                                )}`,
                              },
                              level && {
                                icon: SignalCellularAltIcon,
                                value: level,
                              },
                            ].filter(Boolean)
                      }
                      skills={Object.values(skills)}
                      classes={recommendedItems.length > 4 ? "mb-6" : "mb-0"}
                    />
                  )
                )}
          </SwipableView>
        </div>
      )}
      {!isManagerView && (
        <div className="container mt-8">
          <h2>{t("Skills You May Be Interested In")}</h2>
          <p className="fs-14">
            {t(
              "These recommendations are fine-tuned based on the skills and jobs you've added to your profile. The more you enrich your profile with additional skills and jobs, the more accurate and relevant the results become. Supercharge your experience by expanding your profile, and let us guide you towards opportunities aligned with your aspirations."
            )}{" "}
          </p>
          {loading ? (
            <Grid container spacing={1} mt={2}>
              {Array.from({ length: 8 }, (_, i) => (
                <Grid item key={i} lg={3} md={4} sm={6} xs={12}>
                  <ExploreSkillCard />
                </Grid>
              ))}
            </Grid>
          ) : recommendedSkillsItems.length > 0 ? (
            <>
              <Grid container spacing={1} mt={2}>
                {recommendedSkillsItems
                  .slice(0, showAllSkills ? recommendedSkillsItems.length : 8)
                  .map((skill, i) => (
                    <Grid item key={i} lg={3} md={4} sm={6} xs={12}>
                      <SkillCard item={skill} />
                    </Grid>
                  ))}
              </Grid>
              {recommendedSkillsItems.length > 8 && (
                <div className="flex justify-center mt-4">
                  <Button onClick={() => setShowAllSkills(!showAllSkills)}>
                    {showAllSkills ? t("Show less") : t("Show more")}
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className="flex justify-center mt-8">
              <Button onClick={() => navigateProfile(user?.username, 1)}>
                {t("Set Up Your Profile")}
              </Button>
            </div>
          )}
        </div>
      )}
      <div className="container mt-12 mb-20">
        <h2>
          {isManagerView
            ? t("Pre-built Labs You May Want to Redeem")
            : t("These teams are looking for your skills")}
        </h2>
        <p className="fs-14">
          {isManagerView
            ? t(
                "Ready to level up your innovation game? Explore a curated selection of pre-built Labs, each designed to supercharge your projects. From discovery to redemption, it's your one-stop-shop for elevated experimentation and collaboration."
              )
            : t(
                "Want to be part of exciting projects crafted just for you? Add your skills to your project profile, and open the door to opportunities where your expertise is sought after. Join forces with peers, contribute to meaningful projects, and watch your skills shine in a community of innovators."
              )}
        </p>

        {loading ? (
          <SwipableView
            dynamicBullets
            slidesPerView={slidesPerView}
            classes="mt-5"
          >
            {Array.from({ length: 4 }, (_, i) => (
              <ExploreProductCard key={i} />
            ))}
          </SwipableView>
        ) : isManagerView ? (
          preBuiltLabs.length > 0 ? (
            <>
              <SwipableView
                dynamicBullets
                slidesPerView={slidesPerView}
                classes="mt-5"
              >
                {preBuiltLabs.map(
                  (
                    {
                      title,
                      challenge_count,
                      resource_module_count,
                      skills,
                      member_count,
                      level,
                      category,
                      media,
                      slug,
                    },
                    i
                  ) => (
                    <ProductCard
                      key={i}
                      title={title}
                      subTitle={category}
                      image={media}
                      type={COMPONENT_TYPES.LAB}
                      slug={slug}
                      iconsAndValuesList={[
                        {
                          img: challenge,
                          value: `${
                            challenge_count ? challenge_count : "0"
                          } ${t("Challenges", "common")}`,
                        },
                        {
                          img: resource,
                          value: `${resource_module_count || "0"} ${t(
                            "Resources",
                            "common"
                          )}`,
                        },
                        {
                          icon: GroupIcon,
                          value: `${member_count || "0"} ${t(
                            "Members",
                            "common"
                          )}`,
                        },
                        level && { icon: SignalCellularAltIcon, value: level },
                      ].filter(Boolean)}
                      skills={Object.values(skills)}
                      classes={
                        preBuiltLabs.length > 4 ? "mb-6" : "mb-1 mb-6--mob"
                      }
                    />
                  )
                )}
              </SwipableView>
              <div
                className={`flex justify-center  ${
                  preBuiltLabs.length > 4 ? "mt-5" : "mt-0 mt-5--mob"
                }`}
              >
                <Button onClick={navigateLabMarketplace}>
                  {t("Explore Lab Marketplace")}
                </Button>
              </div>
            </>
          ) : (
            <NoDataView
              classes="my-5"
              text={t("No prebuilts labs found!")}
              small
            />
          )
        ) : teamList.length > 0 ? (
          <>
            <SwipableView
              dynamicBullets
              slidesPerView={slidesPerView}
              classes="mt-5"
            >
              {teamList.map(
                (
                  {
                    title,
                    challenge_title,
                    challenge_slug,
                    privacy,
                    skills,
                    media,
                    project_slug,
                    challenge_status,
                    due_date,
                  },
                  i
                ) => (
                  <ProductCard
                    key={i}
                    title={
                      <div className="flex items-center">
                        <p className="text-nowrap overflow-hidden maxw-95p textOverflow-ellipsis">
                          {title}
                        </p>
                        <Security
                          fontSize="small"
                          className="ml-1"
                          style={{
                            visibility: isTrue(privacy) ? "visible" : "hidden",
                          }}
                        />
                      </div>
                    }
                    subTitle={
                      <>
                        Associated Challenge:{" "}
                        <span
                          className="link"
                          onClick={() => navigateChallenge(challenge_slug)}
                        >
                          {challenge_title}
                        </span>
                      </>
                    }
                    image={media}
                    slug={project_slug}
                    type="project"
                    privacy={privacy}
                    iconsAndValuesList={[
                      {
                        icon: AccessAlarm,
                        value:
                          challenge_status === "closed"
                            ? "Challenge Closed for Submission"
                            : `Due on ${moment(due_date).format(
                                "MMMM DD YYYY"
                              )}`,
                      },
                    ]}
                    skills={Object.values(skills)}
                    classes={teamList.length > 4 ? "mb-6" : "mb-1 mb-6--mob"}
                  />
                )
              )}
            </SwipableView>
            <div
              className={`flex justify-center ${
                teamList.length > 4 ? "mt-5" : "mt-0 mt-5--mob"
              }`}
            >
              <Button onClick={navigateTeamMatching}>
                {t("Explore Team Matching")}
              </Button>
            </div>
          </>
        ) : (
          <div className="flex justify-center mt-5">
            <Button classes="mr-5" onClick={navigateTeamMatching}>
              {t("Explore Team Matching")}
            </Button>
            <Button onClick={() => navigateProfile(user?.username)}>
              {t("Set Up Your Profile")}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default ExploreMainPage;
