import React from "react";
import Card from "components/Cards/Card";
import Button from "components/Button";
import Grid from "@mui/material/Grid";
import Input from "components/Input";
import TextBox from "components/Input/Textbox";
import Checkbox from "components/Input/Checkbox";
import Select from "components/Input/Select";
import { createEvent } from "helpers/utils/utilities";

const CustomDate = ({ data, customDates, setCustomDates, t = (d) => d }) => {
  const onChangeCustomDate = (e, index) => {
    const { name, value } = e.target;
    customDates[index][name] = value;
    setCustomDates([...customDates]);
  };

  const onDelete = (index) => {
    customDates.splice(index, 1);
    setCustomDates([...customDates]);
  };

  return (
    <>
      <Card centered classes={"w-full container"}>
        <h3 className="py-1">{t("Custom dates/deadlines")}</h3>
        <Card classes={"my-1"}>
          <h3>{t("Set custom dates")}</h3>
          <p className="c-gray fs-15">
            {t("Set important key milestone dates")}
          </p>
          {customDates &&
            customDates.length > 0 &&
            customDates.map((customDate, i) => (
              <CustomDateRow
                key={i}
                i={i}
                data={data}
                customDate={customDate}
                onChange={onChangeCustomDate}
                onDelete={onDelete}
                t={t}
              />
            ))}
          <Button
            onClick={() =>
              setCustomDates([
                ...customDates,
                {
                  duration: "days",
                  number: "",
                  title: "",
                  description: "",
                  notify: false,
                  custom_announcement_title: "",
                  custom_announcement_type: "email",
                  custom_announcement_number: "",
                  custom_announcement_duration: "days",
                  custom_announcement_description: "",
                },
              ])
            }
            color={"green"}
            classes={"w-full"}
          >
            {t("Add custom date/deadline")}
          </Button>
        </Card>
      </Card>
    </>
  );
};

export const CustomDateRow = ({
  i,
  customDate,
  onChange,
  onDelete,
  t = (d) => d,
}) => {
  const keyValues = [
    { value: "Days", key: "days" },
    { value: "Weeks", key: "weeks" },
    { value: "Months", key: "months" },
  ];

  const announceTypeKeyValues = [
    { value: "Email", key: "email" },
    { value: "PreprLabs Notification", key: "notification" },
  ];

  return (
    <Grid
      container
      spacing={2}
      mt={1}
      mb={2}
      alignItems="end"
      className="border-b py-2"
    >
      <Grid item xs={4}>
        <Input
          title="Custom Date Title"
          classes="w-full"
          width="99%"
          name="title"
          value={customDate.title}
          onChange={(e) => onChange(e, i)}
          t={t}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          title="Durations"
          description="Set how many days/weeks/months until this custom date"
          classes="w-full"
          width="99%"
          name="number"
          value={customDate.number}
          onChange={(e) => onChange(e, i)}
          type="number"
          t={t}
        />
      </Grid>
      <Grid item xs={4}>
        <p className="pt-5"></p>
        <Select
          value={customDate.duration}
          name="duration"
          keyValues={keyValues}
          onChange={(e) => onChange(e, i)}
          title=""
          multi={false}
          width={"99%"}
          t={t}
        />
      </Grid>
      <Grid item xs={12}>
        <TextBox
          title={"Description"}
          classes="w-full"
          width="99%"
          name="description"
          value={customDate.description}
          onChange={(e) => onChange(e, i)}
          height={250}
          t={t}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="flex justify-between">
          <Grid item xs={6}>
            <div className="flex items-center">
              <Checkbox
                label={"Important"}
                name="notify"
                checked={customDate.notify}
                onChange={(val) => onChange(createEvent("notify", val), i)}
              />
              <span>{t("Schedule announcement with custom date")}</span>
            </div>
          </Grid>
          {!customDate.notify && (
            <Button
              bordered
              color={"red"}
              className="c-error cursor-pointer"
              onClick={() => onDelete(i)}
            >
              {t("Remove")}
            </Button>
          )}
        </div>
      </Grid>
      {customDate.notify && (
        <>
          <Grid item xs={12}>
            <Select
              value={customDate.custom_announcement_type}
              name="custom_announcement_type"
              keyValues={announceTypeKeyValues}
              onChange={(e) => onChange(e, i)}
              title="Announcement type"
              multi={false}
              width={"99%"}
              t={t}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              title={`Announcement title`}
              classes="w-full"
              width="99%"
              name="custom_announcement_title"
              value={customDate.custom_announcement_title}
              onChange={(e) => onChange(e, i)}
              t={t}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              title={`Durations`}
              description={
                "Set how many days/weeks/months until this custom date"
              }
              classes="w-full"
              width="99%"
              name="custom_announcement_number"
              value={customDate.custom_announcement_number}
              onChange={(e) => onChange(e, i)}
              type="number"
              t={t}
            />
          </Grid>
          <Grid item xs={4}>
            <p className="pt-5"></p>
            <Select
              value={customDate.custom_announcement_duration}
              name="custom_announcement_duration"
              keyValues={keyValues}
              onChange={(e) => onChange(e, i)}
              title=""
              multi={false}
              width={"99%"}
              t={t}
            />
          </Grid>
          <Grid item xs={12}>
            <TextBox
              title={"Description"}
              classes="w-full"
              width="99%"
              name="custom_announcement_description"
              value={customDate.custom_announcement_description}
              onChange={(e) => onChange(e, i)}
              height={250}
              t={t}
            />
          </Grid>
          {customDate.notify && (
            <div className="py-3 flex justify-end w-full">
              <Button
                bordered
                color={"red"}
                className="c-error cursor-pointer"
                onClick={() => onDelete(i)}
              >
                {t("Remove")}
              </Button>
            </div>
          )}
        </>
      )}
    </Grid>
  );
};

export default CustomDate;
