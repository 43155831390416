import React, { useEffect, useState } from "react";
import useFilters, { FILTER_TYPES } from "helpers/hooks/useFilters";
import useLabPrograms from "store/lab-programs/service-hook";
import BrowseWrapper from "components/UI/organisms/BrowseWrapper";
import useListingHeader from "helpers/hooks/useListingHeader";
import LabListing from "pages/labs/listing/LabListing";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const browse_options = [
  {
    id: 1,
    title: `Browse`,
    pageTitle: "Browse Labs and Lab Programs",
  },
  {
    id: 2,
    title: `My Labs & Lab Programs`,
    pageTitle: "My Labs & Lab Programs",
  },
  {
    id: 3,
    title: "Pending Invitations",
    pageTitle: "Browse Pending Lab & Lab Programs Invitations",
  },
  {
    id: 4,
    title: "Saved",
    pageTitle: "Browse Saved Labs & Lab Programs",
  },
];

const BrowseLabPrograms = () => {
  const [browseMode, setBrowseMode] = useState(0);
  const [labPrograms, setLabPrograms] = useState(null);
  const state = useListingHeader();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { fetchLabPrograms } = useLabPrograms();
  const { selected } = useFilters();
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    const abortController = new AbortController();
    handleFetchLabs(abortController);
    return () => abortController.abort();
  }, [page, selected, state.sort, state.search, browseMode]);

  const handleFetchLabs = async (abortController) => {
    setLoading(true);
    const filters = {
      ...selected,
      page,
      sort_by: state.sort,
      search: state.search,
    };
    if (selected?.multi_type) filters.type = selected.multi_type;
    delete filters.multi_type;
    if (browseMode === 3) {
      filters.social_type = "favourites";
    }
    if (browseMode === 2) {
      filters.request_status = "pending";
    }
    if (browseMode === 1) {
      filters.request_status = "accepted";
    }
    const [list, count, total] = await fetchLabPrograms(filters);
    if (!abortController.signal.aborted) {
      setLabPrograms(list || []);
      setPages(count || 0);
      setTotalCount(total || 0);
      setLoading(false);
    }
  };

  return (
    <BrowseWrapper
      browseMode={browseMode}
      browseOptions={browse_options}
      setBrowseMode={setBrowseMode}
      noAction
      {...state}
      filters={[
        FILTER_TYPES.COMPONENT_TYPE,
        FILTER_TYPES.MULTI_TYPE,
        FILTER_TYPES.DURATION,
        FILTER_TYPES.LEVEL,
        FILTER_TYPES.SKILLS,
        FILTER_TYPES.PRIVACY,
        // FILTER_TYPES.ORGANIZATION
      ]}
    >
      {totalCount > 0 && (
        <h3 className="mt-3 ml-1">
          {totalCount ?? 0} {t("Results", "common")}
        </h3>
      )}
      <LabListing
        browseMode={browseMode}
        gridType={state.gridType}
        labs={labPrograms}
        loading={loading}
        pageCount={pages}
        page={page}
        setPage={setPage}
        setLabs={setLabPrograms}
        isLP
      />
    </BrowseWrapper>
  );
};

export default BrowseLabPrograms;
