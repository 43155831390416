import { SCHEMA_TYPES, COLORS } from "helpers/utils/constants";
import { REQUIRED_VALIDATOR } from "helpers/utils/validators";
import { leaf4, openai } from "assets/svg/green";

export const schema = [
  {
    title: "Jobs",
    name: "jobs",
    type: SCHEMA_TYPES.MASTER,
    lg: 12,
    multi: true,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Skills",
    name: "skills",
    type: SCHEMA_TYPES.MASTER,
    lg: 12,
    multi: true,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Level",
    name: "level_id",
    multi: true,
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Duration",
    name: "duration_id",
    multi: true,
    type: SCHEMA_TYPES.MASTER,
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Do you want to create Resource Modules for this Challenge?",
    name: "resource_modules",
    type: SCHEMA_TYPES.RADIO,
    options: [
      { title: "No", key: "no" },
      { title: "Yes", key: "yes" },
    ],
    lg: 12,
    validator: REQUIRED_VALIDATOR,
  },
  {
    option: "Import from PreprLabs resource library",
    type: SCHEMA_TYPES.CHECKBOX,
    name: "resource_module_prepr",
    lg: 12,
    validator: REQUIRED_VALIDATOR,
    image: leaf4,
    imageBG: "#FFF",
  },
  {
    option: "Generate with AI",
    type: SCHEMA_TYPES.CHECKBOX,
    name: "resource_module_openai",
    validator: REQUIRED_VALIDATOR,
    image: openai,
    imageBG: COLORS.GREEN,
  },
  {
    name: "openai_resource_module_types",
    type: SCHEMA_TYPES.SELECT,
    multi: true,
    options: [
      { value: "Links", key: "links" },
      { value: "Videos", key: "videos" },
    ],
    validator: REQUIRED_VALIDATOR,
  },
  {
    title: "Additional Information",
    name: "additional_information",
    type: SCHEMA_TYPES.TEXTBOX,
    lg: 12,
    classes: "h-100",
    maxLength: 100,
  },
];

export const getSchemaByStep = () => {
  return [0, schema.findIndex((d) => d.name === "additional_information") + 1];
};

export const stepSchema = () => {
  return schema.slice(...getSchemaByStep());
};
