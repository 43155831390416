import { Grid, IconButton } from "@mui/material";
import { lab, labProgram, labMarketplace } from "assets/svg/blue";
import { challenge, challengePath } from "assets/svg/green";
import { resource } from "assets/svg/purple";
import { achievement, org, project, skills, team } from "assets/svg/yellow";
import { leaderboard, careerExplorer, assessments } from "assets/svg";
import React from "react";
import { useNavigate } from "react-router-dom";

import { COMPONENT_TYPES, ROLES } from "helpers/utils/constants";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const generateMenuItems = (hideMarketplace) =>
  [
    { title: "Lab", icon: lab, path: `/${COMPONENT_TYPES.LAB}` },
    {
      title: "Challenge",
      icon: challenge,
      path: `/${COMPONENT_TYPES.CHALLENGE}`,
    },
    { title: "Project", icon: project, path: `/${COMPONENT_TYPES.PROJECT}` },
    {
      title: "Lab Program",
      icon: labProgram,
      path: `/${COMPONENT_TYPES.LAB_PROGRAMS}`,
    },
    {
      title: "Challenge Path",
      icon: challengePath,
      path: `/${COMPONENT_TYPES.CHALLENGE_PATH}`,
    },
    // { title: "Skills", icon: skills, path: `/${COMPONENT_TYPES.SKILL}` },
    {
      title: "Career Explorer",
      icon: careerExplorer,
      path: "/careers",
    },
    {
      title: "Resources",
      icon: resource,
      path: `/${COMPONENT_TYPES.RESOURCE}`,
    },
    {
      title: "Assessments",
      icon: assessments,
      path: "/project?browseMode=4",
    },
    { title: "Team Matching", icon: team, path: "/team-matching" },
    {
      title: "Organization",
      icon: org,
      path: `/${COMPONENT_TYPES.ORGANIZATION}`,
    },
    { title: "Achievements", icon: achievement, path: "/achievements" },
    { title: "Leaderboard", icon: leaderboard, path: "/leaderboard" },
    !hideMarketplace && {
      title: "Lab Marketplace",
      icon: labMarketplace,
      path: "/lab-marketplace",
    },
  ].filter(Boolean);

const MenuBar = ({ mobile, onClose }) => {
  const { user } = useSelector((state) => state[REDUCER_TYPES.AUTH]);
  const navigate = useNavigate();
  const { t } = useLocalizedTranslation("common", true);
  const handleNavigate = (path) => {
    navigate(path);
    onClose();
  };
  const menu_items = React.useMemo(
    () =>
      generateMenuItems(
        !user?.roles ||
          user?.roles === ROLES.USER ||
          user?.roles === ROLES.RESOURCE_MANAGER ||
          user?.roles === ROLES.CHALLENGE_MANAGER
      ),
    []
  );
  return (
    <Grid
      container
      className={`${mobile ? "py-4" : "w-400 py-4 px-2"}`}
      justifyContent="center"
      rowSpacing={1}
    >
      {menu_items.map(({ title, icon, path }) => (
        <Grid item xs={4} key={title}>
          <IconButton
            className="mx-auto w-full d-flex rounded-5 flex-column justify-center items-center text-center py-1"
            onClick={() => handleNavigate(path)}
          >
            <img src={icon} alt={title} height={45} width={45} />
            <p className={`fs-14 fw-500 mt-1 tracking-tight text-nowrap`}>
              {t(title)}
            </p>
          </IconButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default MenuBar;
