import { Search } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Input from "components/Input";
import Select from "components/Input/Select";
import React from "react";

const SearchAndSort = ({
  searchText,
  setSearchText,
  sortOption,
  setSortOption,
  placeholder,
  t = (d) => d,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Input
          value={searchText}
          placeholder={placeholder}
          onChange={(e) => setSearchText(e.target.value)}
          icon={<Search />}
          width="100%"
          iconLeft
        />
      </Grid>
      <Grid item xs={12} sm={2}></Grid>
      <Grid item xs={12} sm={4}>
        <Select
          keyValues={[
            { value: "Most Recent", key: "creation_date" },
            { value: "Alphabetical A-Z", key: "name-a-to-z" },
            { value: "Alphabetical Z-A", key: "name-z-to-a" },
          ]}
          width="100%"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          t={t}
        />
      </Grid>
    </Grid>
  );
};

export default SearchAndSort;
