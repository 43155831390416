import React from "react";
import DragWrapper from "components/DragNDrop/DragWrapper";
import { Menu } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import { NoDataView } from "components/UI/organisms/ListingContainer";
import Button from "components/Button";
import { getDefaultImageByType } from "components/Cards/MainProductCard";
import { getDefaultIconByType } from "helpers/utils/utilities";
import SecureImage from "components/UI/atoms/SecureImage";
import styles from "../../challenges.module.scss";

const SelectedList = ({ selected, setSelected, id, type, t = (d) => d }) => {
  const handleRemove = (uuid) => {
    setSelected((prevSelected) =>
      prevSelected.filter((item) => item[id] !== uuid)
    );
  };

  return (
    <Grid item xs={6}>
      <h4>{t("Selection")}</h4>
      {selected?.length ? (
        <div className={`${styles.associatedLabs__selectedContainer}`}>
          <DragWrapper
            items={selected}
            setItems={setSelected}
            draggable
            isListing
            rowHeight={{ lg: 96, sm: 96 }}
            id={id}
          >
            {selected.map(({ title, media, ...d }) => (
              <div
                key={d[id]}
                className="w-full flex items-center border-b pb-3"
              >
                <div
                  className={`${styles.associatedLabs__image_container} flex items-center `}
                >
                  <Menu className="drag-handle" />{" "}
                  {/* Add the drag-handle class here */}
                  <SecureImage
                    src={media}
                    alt={title}
                    classes="h-80 w-140 maxw-140 object-cover"
                    placeholder={getDefaultImageByType(d.type)}
                    title={title}
                    smaller
                  />
                </div>
                <div>
                  <div className="flex items-center">
                    <img
                      height={25}
                      width={25}
                      src={getDefaultIconByType(d.type)}
                      alt={type}
                    />

                    <p className="c-primary fw-600 maxw-350 tracking-tight  ml-2 pb-1 text-truncate-2 leading-snug">
                      {title}&nbsp;({d.type})
                    </p>
                  </div>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleRemove(d[id]);
                    }}
                    bordered
                    color="red"
                    classes="fs-12--mob mt-2"
                    height="25px"
                  >
                    {t("Remove")}
                  </Button>
                </div>
              </div>
            ))}
          </DragWrapper>
        </div>
      ) : (
        <NoDataView />
      )}
    </Grid>
  );
};

export default SelectedList;
