import Slider from "components/Silder";
import { login_response_data } from "../auth/auth-data";
import React from "react";
import "../auth/auth.scss";
import { Box } from "@mui/material";
import SecureImage from "components/UI/atoms/SecureImage";
import { placeholder } from "assets/png";
import { logo as loadingLogo } from "assets/gif";
import LanguageSelector from "./dashboard/components/LanguageSelector";
import useAuth from "store/auth/service-hook";
import i18next from "i18next";

const isUnauthenticatedAuthRoute = () =>
  ["/login", "/register", "/verify-otp", "/forgot-password"].some((d) =>
    window.location.pathname.includes(d)
  );

const AuthLayout = ({
  sliderHidden,
  children,
  onDash,
  containerClasses,
  autoHeight,
  customDetails,
  loading,
}) => {
  const { data } = login_response_data;
  const { languageChange } = useAuth();

  return loading ? (
    <Box className="h-screen w-screen flex items-center justify-center">
      <img src={loadingLogo} alt="loader" height={120} />
    </Box>
  ) : (
    <div
      className={`auth relative ${autoHeight && "auto"} ${
        onDash && "pt-header"
      } ${containerClasses}`}
      style={{
        background: customDetails && customDetails?.custom_background_color,
      }}
    >
      {isUnauthenticatedAuthRoute() && (
        <LanguageSelector
          handleChange={languageChange}
          language={i18next.language}
          classes="unauth-langSelector"
          onlyClient
        />
      )}
      <div className="auth-innerContainer">
        {!sliderHidden ? (
          customDetails ? (
            <Box
              sx={{ display: "grid", placeItems: "center" }}
              className="ml-10 my-10"
            >
              <SecureImage
                src={customDetails?.custom_hero_image}
                placeholder={placeholder}
                classes="h-500 w-full object-cover overflow-hidden"
                containerClasses="overflow-hidden maxh-500 h-500 mx-auto rounded-30 shadow"
              />
            </Box>
          ) : (
            <Slider slides={data?.slides} classes="slider-class" />
          )
        ) : null}
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
