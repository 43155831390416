import { createSlice } from "@reduxjs/toolkit";
import ACTIONS from "./action-types";

const initialState = {
  count: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    [ACTIONS.NOTIFICATION_COUNT]: (state,{payload}) => {
      state.count = payload.count;
    },
  },
});
export const REDUCER = notificationSlice.actions;

export default notificationSlice.reducer;
