import { useState } from 'react';
import { Grid, Box, Tabs, Tab, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ModalLayout from 'components/Modal/index';
import useWindowWidth from 'helpers/hooks/useWindowWidth';
import cx from 'classnames';
import { COLORS } from 'helpers/utils/constants';
import StarIcon from '@mui/icons-material/Star';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Button from 'components/Button';
import Input from 'components/Input';
import TextBox from 'components/Input/Textbox';

const UserAwardsPopups = ({ open, onClose }) => {
	const [activeModal, setActiveModal] = useState(1);
	const [currentTab, setCurrentTab] = useState('preset');
	const [activeCardIndex, setActiveCardIndex] = useState(0);
	const [currentAward, setCurrentAward] = useState({
		title: 'Award with a long name ',
		description:
			'Lorem ipsum dolor sit amet consectetur. Ac augue ut nec sem pretium in cum purus. Eget ultricies morbi morbi volutpat amet amet. Varius tincidunt morbi consequat purus tincidunt. Imperdiet pretium quis nunc imperdiet ut. ',
		criteria: 'Lorem Ipsum Expires on january 26, 2023',
		user: 'Matthew Almeria',
	});
	const windowWidth = useWindowWidth();
	const onTab = windowWidth < 900;

	const handleAwardClick = (index) => {
		setCurrentAward({
			title: 'Award with a long name ',
			description:
				'Lorem ipsum dolor sit amet consectetur. Ac augue ut nec sem pretium in cum purus. Eget ultricies morbi morbi volutpat amet amet. Varius tincidunt morbi consequat purus tincidunt. Imperdiet pretium quis nunc imperdiet ut. ',
			criteria: 'Lorem Ipsum Expires on january 26, 2023',
			user: 'Matthew Almeria',
		});
		setActiveCardIndex(index);
	};

	const handleCloseModal = () => {
		setActiveModal(1);
		onClose();
	};

	const handleTabChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const setActiveModalToUserAwarded = () => {
		setActiveModal(2);
	};

	if (!open) return null;

	return (
		<ModalLayout
			onClose={handleCloseModal}
			open={open}
			hideCloseIcon={activeModal === 1}
			wrapperClasses={`py-0 rounded-10 overflowY-auto ${
				activeModal === 1 ? 'h-80vh' : 'h-auto'
			}`}
			width='min(95%, 950px)'
			top='80px'
			crossIconClasses='right-3 fs-40 c-black'
		>
			{activeModal === 1 ? (
				<Grid container className='justify-between px-5 h-full'>
					<Grid item xs={12} md={8} className='flex-column my-4 h-auto'>
						<Box className='flex justify-between'>
							<Box>
								<h3 className='fw-700 fs-24'>Give Award</h3>
								<p className='fw-600 fs-14'>Give an award to another user.</p>
							</Box>
							<Box>
								<CloseIcon
									onClick={handleCloseModal}
									className={`cursor-pointer w-40 h-40 mr-2`}
								/>
							</Box>
						</Box>
						<Box className='w-full'>
							<Tabs
								value={currentTab}
								onChange={handleTabChange}
								sx={{
									'& .MuiTab-root': {
										color: 'gray',
										textTransform: 'none',
									},
									'& .Mui-selected': {
										color: 'black!important',
									},
									'& .MuiTabs-indicator': {
										backgroundColor: 'black',
										height: '1.5px',
										bottom: '10px',
									},
								}}
							>
								<Tab value='preset' label='Preset' />
								<Tab value='custom' label='Custom' />
							</Tabs>
							{currentTab === 'preset' ? (
								<Box className='flex flex-wrap justify-between h-auto px-1 '>
									{Array(12)
										.fill({
											title: 'Award with a long name ',
											description:
												'Lorem ipsum dolor sit amet consectetur. Ac augue ut nec sem pretium in cum purus. Eget ultricies morbi morbi volutpat amet amet. Varius tincidunt morbi consequat purus tincidunt. Imperdiet pretium quis nunc imperdiet ut. ',
											criteria: 'Lorem Ipsum Expires on january 26, 2023',
											user: 'Matthew Almeria',
										})
										.map((award, i) => (
											<UserAwardCard
												key={i}
												award={award}
												isActive={i === activeCardIndex}
												onClick={() => handleAwardClick(i)}
											/>
										))}
								</Box>
							) : (
								<CustomAward onTab={onTab} />
							)}
						</Box>
					</Grid>

					<Divider orientation={!onTab && `vertical`} flexItem />

					<Grid item xs={12} md={3.5} className='flex-column'>
						<UserAwardInfo
							onTab={onTab}
							awardTitle={currentAward.title}
							awardDescription={currentAward.description}
							awardCriteria={currentAward.criteria}
							awardUser={currentAward.user}
							setActiveModalToUserAwarded={setActiveModalToUserAwarded}
						/>
					</Grid>
				</Grid>
			) : (
				<Box className='flex-column items-center h-250 px-0 py-2 justify-evenly'>
					<h3 className='fw-700 fs-32 fs-24--mob'>User Awarded!</h3>
					<StarIcon sx={{ color: COLORS.ORANGE }} className='w-80 h-80' />
					<p>You've awarded Matthew Almeria with the Sample Award</p>
				</Box>
			)}
		</ModalLayout>
	);
};

export default UserAwardsPopups;

const UserAwardCard = ({ isActive, onClick, award }) => {
	return (
		<Box
			sx={{
				boxShadow: isActive && '0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
				border: isActive && '1px solid lightgray',
			}}
			className={cx(
				'flex-column justify-center items-center w-120 h-140 px-1 mt-2  c-gray cursor-pointer rounded-10'
			)}
			onClick={onClick}
		>
			<StarIcon
				className='w-60 h-60'
				sx={{
					color: COLORS.ORANGE,
				}}
			/>
			<h3
				className={`text-center fw-600 fs-16 mt-1 ${isActive && 'c-black fw-700'}`}
			>
				{award.title}
			</h3>
		</Box>
	);
};

const CustomAward = ({ onTab }) => {
	return (
		<Box className={`py-0 ${onTab ? 'px-0' : 'px-8'} `}>
			<Button bordered classes='mt-2'>
				<FileUploadIcon />
				&nbsp;Upload Image
			</Button>
			<Input
				title='Award Name'
				classes='w-full mt-3 '
				name='title'
				width={onTab ? '100%' : '80%'}
				required
			/>

			<TextBox
				name='description'
				title='Description'
				width={onTab ? '100%' : '80%'}
				height='100px'
				classes='w-full mt-3 '
				required
			/>
			<Input
				name='criteria'
				title='Crtieria'
				classes='w-full mt-3 '
				width={onTab ? '100%' : '80%'}
				required
			/>
			<Input
				name='expirationDate'
				title='Expiration Date'
				classes='w-full mt-3 mb-4'
				width={onTab ? '100%' : '80%'}
				required
			/>
		</Box>
	);
};

const UserAwardInfo = ({
	onTab,
	awardTitle,
	awardDescription,
	awardCriteria,
	awardUser,
	setActiveModalToUserAwarded,
}) => {
	return (
		<Box
			sx={{
				boxShadow: onTab && '0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
			}}
			className={`flex-column w-full justify-center px-4 ${
				onTab ? 'my-5 ml-0 py-4' : 'mt-8 mb-2 ml-2 py-0'
			}`}
		>
			<Box className='flex-column items-center'>
				<StarIcon
					sx={{
						color: COLORS.ORANGE,
					}}
					className='w-60 h-60 mb-5'
				/>

				<h3 className='fw-700 mb-2 text-center'>{awardTitle}</h3>
			</Box>
			<Box className={`flex-column my-4  ${onTab && 'items-center text-center'}`}>
				<p className='fw-400 my-1 fs-12'>{awardDescription}</p>
				<p className='fw-700 my-4 fs-12'>Criteria: {awardCriteria}</p>

				<p className='fw-700 my-4  fs-16'>
					To: <br /> {awardUser}
				</p>

				<Button width='170px' onClick={setActiveModalToUserAwarded}>
					Give Award
				</Button>
			</Box>
		</Box>
	);
};

export const UserAwardRecieved = ({ open, onClose }) => {
	if (!open) return null;

	return (
		<ModalLayout
			onClose={onClose}
			open={open}
			top='85px'
			borderRadius='10px'
			crossIconClasses='top-3 right-3 fs-40 c-black'
		>
			<Box className='flex-column items-center h-250 px-0 py-2 justify-evenly'>
				<h3 className='fw-700 fs-32 fs-24--mob'>A user has given you an award!</h3>
				<StarIcon sx={{ color: COLORS.ORANGE }} className='w-80 h-80' />
				<p>You've been awarded by Matthew Almeria with the Sample Award</p>
			</Box>
		</ModalLayout>
	);
};
