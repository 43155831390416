import { EditNote } from "@mui/icons-material";
import { Box, Chip, IconButton, Stack } from "@mui/material";
import Card from "components/Cards/Card";
import ProfileCard from "components/Cards/ProfileCard";
import Banner from "components/UI/molecules/Banner";
import IconText from "components/UI/atoms/IconText";
import TrophyChip from "components/UI/atoms/TrophyChip";
import DashboardLayout from "pages/layouts/dashboard";
import MainPageLayout from "pages/layouts/MainPage";
import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useTransition,
} from "react";
import { schema } from "./forms/data";
import MainTabs from "../../components/UI/organisms/MainTabs";
import ProfileDetails from "./components/ProfileDetails";
import ChallengeHistory from "./components/ChallengeHistory";
import Friends from "./components/Friends";
import Achievements from "./components/Achievements";
import useProfile from "store/profile/service-hook";
import { useParams, useLocation } from "react-router-dom";
import AddEditProfileModal from "./forms/AddEditProfileModal";
import useForms from "helpers/hooks/useForms";
import useAuth from "store/auth/service-hook";
import SkillsAndTags from "./components/SkillsAndTags";
import useMaster from "store/master/service-hook";
import DownloadProfile from "./components/DownloadProfile";
import moment from "moment";
import {
  getProfileBtns,
  handleSubmitCertificate,
  handleSubmitEducation,
  handleSubmitExperience,
  handleSubmitPatent,
  handleSubmitProfileDetails,
  modalOpenHelper,
} from "./helpers";
import {
  keyVal,
  keyValueFormat,
  payloadBool,
  snakeToCamel,
} from "helpers/utils/utilities";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import ImageCropper from "components/Modal/ImageCropper";
import useAchievements from "store/achievements/service-hook";
import useOnboarding from "store/onboarding/service-hook";
import { skills as skillImg, achievement, project } from "assets/svg/yellow";
import { lab, user as userImg } from "assets/svg/blue";
import Button from "components/Button";
import useRedirect from "helpers/hooks/useRedirect";
import ProjectListing from "./components/ProjectListing";
import SecureImage from "components/UI/atoms/SecureImage";
import EmptyText from "components/UI/atoms/EmptyText";
import { COLORS, COMPONENT_TYPES } from "helpers/utils/constants";
import { isTrue } from "helpers/utils/validators";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { defaultExternalLink } from "assets/png";
import { getAchievementTypeKey } from "pages/achievements/helpers";
import { toast } from "react-toastify";
import useProjects from "store/projects/service-hook";
import { AssociatedItem } from "pages/projects/components/ProjectDescription";
import { REDUCER } from "store/profile/reducer";
import ACTIONS from "store/profile/action-types";
import { useDispatch } from "react-redux";

const PROFILE_TABS = [
  { id: "userProfile", label: "User Profile" },
  { id: "skills", label: "Skills" },
  { id: "projects", label: "Projects" },
  { id: "friends", label: "Friends" },
  { id: "achievements", label: "Achievements" },
  // { id: "tags", label: "Tags" },
  { id: "challengeHistory", label: "Challenge History" },
];

export const PROFILE_TYPES = {
  GENERAL: "general",
  EXPERIENCE: "experience",
  EDUCATION: "education",
  CERTIFICATE: "certificate",
  PATENT: "patent",
};

export const PRONOUNS = {
  male: "he/him",
  female: "she/her",
  other: "they/them",
};

const UserProfile = () => {
  const { search } = useLocation();
  const initialTab = Number(new URLSearchParams(search).get("tab")) || 0;
  const [currentTab, setCurrentTab] = useState(initialTab);
  const [profile, setProfile] = useState(null);
  const [modal, setModal] = useState();
  const [loading, setLoading] = useState(false);
  const [experience, setExperience] = useState([{}]);
  const [education, setEducation] = useState([{}]);
  const [certificates, setCertificates] = useState([{}]);
  const [patent, setPatent] = useState([{}]);
  const [profileImage, setProfileImage] = useState(null);
  const [links, setLinks] = useState([]);
  const { setFeatured } = useAchievements();
  const fileButtonRef = useRef();
  const [profileDownloadInfo, setProfileDownloadInfo] = useState(null);

  //CHALLENGE HISTORY HOOKS
  const [isChallengeLoading, startChallengeTransition] = useTransition();
  const [challenges, setChallenges] = useState([]);
  const [challengePage, setChallengePage] = useState(1);
  const [challengePages, setChallengePages] = useState(1);
  const [challengeSearch, setChallengeSearch] = useState("");
  const [challengeSort, setChallengeSort] = useState("creation_date");

  //PROJECTS HOOKS
  const [isProjectLoading, startProjectTransition] = useTransition();
  const [projects, setProjects] = useState([]);
  const [projectPage, setProjectPage] = useState(1);
  const [projectPages, setProjectPages] = useState(1);
  const [projectSearch, setProjectSearch] = useState("");
  const [projectSort, setProjectSort] = useState("creation_date");

  const {
    getProfile,
    addDetails,
    addExperience,
    addCertificate,
    addPatent,
    addEducation,
    fileUpload,
    addSkills,
    deleteSkills,
    sendFriendRequest,
    addProfilePhoto,
    unfriend,
    unfollow,
    sendFollowRequest,
    acceptRejectFriendRequest,
    updatePrivacy,
    deleteResume,
    getChallenges,
    getProjects,
    getDownloadProfileList,
  } = useProfile();
  const { pinUnpin } = useProjects();
  const dispatch = useDispatch();
  const {
    state: { isLoggedIn, user },
  } = useAuth();
  const {
    state: { skills },
  } = useMaster();
  const { id } = useParams();
  const { data, onChange, errors, validate, setData } = useForms(schema, {
    identification: [],
  });
  const itsMe = id === user?.username;

  const profileVisibility = profile?.profile_privacy;
  const currentTabId = PROFILE_TABS?.[currentTab]?.id;
  const { t } = useLocalizedTranslation();
  const { resumeParser } = useOnboarding();
  const { navigateChats, navigateSettings } = useRedirect();
  useEffect(() => {
    setModal();
    if (!id) return;
    fetchProfile();
  }, [id]);

  const fetchProfile = async () => {
    setProfile(null);
    const res = await getProfile(id);
    setProfile(res);
    setLinks(
      res?.external_links?.length
        ? res?.external_links?.map(({ title, link }) => ({
            url: link,
            type: keyVal(title, keyValueFormat),
          }))
        : []
    );
    if (isLoggedIn && !profileDownloadInfo && res?.id) {
      const res2 = await getDownloadProfileList(res?.id);
      setProfileDownloadInfo(res2);
    }
  };

  useEffect(() => {
    if (!profile?.id) return;
    handleFetchChallenges();
  }, [profile?.id, challengePage, challengeSearch, challengeSort]);

  useEffect(() => {
    if (!profile?.id) return;
    handleFetchProjects();
  }, [profile?.id, projectPage, projectSearch, projectSort]);

  const handleFetchChallenges = () => {
    startChallengeTransition(async () => {
      const filters = {
        page: challengePage,
        sort_by: challengeSort,
      };
      if (challengeSearch) filters["search"] = challengeSearch;
      const [list, count] = await getChallenges(profile?.id, filters);
      setChallenges(list || []);
      setChallengePages(count || 1);
    });
  };

  const handleFetchProjects = () => {
    startProjectTransition(async () => {
      const filters = {
        page: projectPage,
        sort_by: projectSort,
      };
      if (projectSearch) filters["search"] = projectSearch;
      const [list, count] = await getProjects(profile?.id, filters);
      setProjects(list || []);
      setProjectPages(count || 1);
    });
  };

  const handleSubmit = () => {
    switch (modal) {
      case PROFILE_TYPES.GENERAL:
        handleSubmitProfileDetails(
          validate,
          data,
          addDetails,
          setProfile,
          setLoading,
          setModal,
          links,
          t
        );
        break;
      case PROFILE_TYPES.EXPERIENCE:
        handleSubmitExperience(
          experience,
          setLoading,
          addExperience,
          setProfile,
          setModal,
          t
        );
        break;
      case PROFILE_TYPES.EDUCATION:
        handleSubmitEducation(
          education,
          setLoading,
          setProfile,
          setModal,
          addEducation,
          t
        );
        break;
      case PROFILE_TYPES.CERTIFICATE:
        handleSubmitCertificate(
          certificates,
          setLoading,
          setProfile,
          setModal,
          addCertificate,
          t
        );
        break;
      case PROFILE_TYPES.PATENT:
        handleSubmitPatent(
          patent,
          setLoading,
          setProfile,
          setModal,
          addPatent,
          t
        );
        break;
      default:
        return;
    }
  };

  const handleOpenModal = (type) =>
    modalOpenHelper(
      type,
      setModal,
      profile,
      setExperience,
      setEducation,
      setCertificates,
      setPatent,
      setData
    );

  const handleUploadFile = async (file) => {
    if (!file) return;
    const allowedExtensions = ["pdf", "doc", "docx"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error(
        t(
          "Invalid file type. Please upload a file in PDF, DOC, or DOCX format."
        )
      );
      return;
    }
    setLoading("file");
    const res = await fileUpload(file);
    if (res) {
      const resExp = await resumeParser(file);
      if (resExp && resExp?.user_experiences) {
        const formattedExperiences = resExp.user_experiences.map((exp) => ({
          ...exp,
          start_date: moment(exp.start_date).format("YYYY-MM-DD"), // Formatting the start_date
          end_date: moment(exp.end_date).format("YYYY-MM-DD"),
          location: {
            address_1: exp.address,
            state: exp.state,
            country: exp.country,
          },
        }));

        setExperience(formattedExperiences);
      }

      setProfile((prev) => ({
        ...prev,
        user_personal_files: prev.user_personal_files.some(
          (file) => file.id === res.id
        )
          ? prev.user_personal_files
          : [...prev.user_personal_files, res],
      }));
    }
    setLoading(false);
  };

  const submitProfilePhoto = async (image) => {
    const res = await addProfilePhoto(image);
    if (res) {
      setProfile({ ...res });
    }
  };

  const handleAddFriend = () => {
    sendFriendRequest(profile?.id);
    setProfile((prev) => ({
      ...prev,
      request_sent: "Yes",
    }));
  };

  const handleUnfriend = () => {
    unfriend(profile?.id);
    setProfile((prev) => ({
      ...prev,
      is_friends: "No",
      request_received: "No",
      request_sent: "No",
    }));
  };

  const handleFollowUnfollow = (isUnfollow) => {
    isUnfollow ? unfollow(profile?.id) : sendFollowRequest(profile?.id);
    setProfile((prev) => ({
      ...prev,
      is_follower: isUnfollow ? "No" : "Yes",
    }));
  };

  const handleAcceptRejectReq = (isReject) => {
    acceptRejectFriendRequest(profile?.id, Boolean(isReject));
    setProfile((prev) => ({
      ...prev,
      is_friends: isReject ? "No" : "Yes",
      request_received: "No",
    }));
  };

  const handleSetFeatured = async (achievementId, isFeatured) => {
    const res = await setFeatured(achievementId, isFeatured);
    if (res) {
      setProfile((prev) => {
        let updatedFeatured = prev?.featured_achievement;
        if (isFeatured)
          updatedFeatured = updatedFeatured.filter(
            (d) => d?.id !== achievementId
          );
        else
          updatedFeatured = [
            ...updatedFeatured,
            prev.achievements_list?.find((d) => d?.id === achievementId),
          ];
        const updatedProfile = {
          ...prev,
          featured_achievement: updatedFeatured,
          achievements_list: prev?.achievements_list?.map((achievement) =>
            achievement.id === achievementId
              ? { ...achievement, is_featured: payloadBool(!isFeatured) }
              : achievement
          ),
        };
        dispatch(REDUCER[ACTIONS.PROFILE](updatedProfile));
        return updatedProfile;
      });
    }
  };
  const navigateChat = () => {
    navigateChats(`?username=${profile?.username}`);
  };

  const toggleFilePrivacy = async (fileId) => {
    const res = await updatePrivacy(fileId);
    if (res) {
      setProfile((prev) => ({
        ...prev,
        user_personal_files: prev?.user_personal_files?.map((d) =>
          d.id === fileId
            ? { ...d, public: d?.public === "yes" ? "no" : "yes" }
            : d
        ),
      }));
    }
  };

  const deleteUploadedResume = async (id) => {
    if (!id) return;
    await deleteResume(id);
    setProfile((prev) => ({
      ...prev,
      user_personal_files: prev?.user_personal_files?.filter(
        (d) => d?.id !== id
      ),
    }));
  };

  const handleProjectPinUnpin = async (slug, unpin) => {
    const res = await pinUnpin(slug, Boolean(unpin));
    if (res) {
      setProfile((prev) => {
        const project = projects?.find((d) => d?.slug === slug);
        let updatedProjects = prev?.user_pinned_projects || [];
        if (unpin)
          updatedProjects = updatedProjects.filter((d) => d?.slug !== slug);
        else updatedProjects = [...updatedProjects, project];
        const updatedProfile = {
          ...prev,
          user_pinned_projects: updatedProjects,
        };
        dispatch(REDUCER[ACTIONS.PROFILE](updatedProfile));
        return updatedProfile;
      });
      setProjects((prev) =>
        prev?.map((d) => ({
          ...d,
          is_project_pinned:
            slug === d?.slug ? payloadBool(!unpin) : d?.is_project_pinned,
        }))
      );
    }
  };

  const uploadResume = () => {
    setModal(PROFILE_TYPES.EXPERIENCE);
    // Use setTimeout to ensure the modal is rendered before triggering the upload
    setTimeout(() => {
      if (fileButtonRef.current) {
        fileButtonRef.current.triggerUpload();
      }
    }, 10);
  };

  const profileBtns = useMemo(() => {
    const btns = getProfileBtns(
      profile,
      itsMe,
      handleFollowUnfollow,
      handleUnfriend,
      handleAcceptRejectReq,
      handleAddFriend,
      navigateChat,
      isLoggedIn,
      uploadResume,
      t
    );
    let updatedBtns = btns;
    if (btns?.length && profileDownloadInfo) {
      updatedBtns = btns.map((d) => {
        if (d?.title === "Download Profile") {
          d["disabled"] = false;
          d["children"] = (
            <PDFDownloadLink
              document={
                <DownloadProfile
                  profile={profile}
                  labs={profileDownloadInfo?.labs}
                  projects={profileDownloadInfo?.projects}
                  hasMoreProjects={profileDownloadInfo?.hasMoreProjects}
                  hasMoreLabs={profileDownloadInfo?.hasMoreLabs}
                  t={t}
                />
              }
              fileName={`${profile?.username}.pdf`}
              className="text-decoration-none"
            >
              {({ loading, error }) => (
                <Button
                  classes="w-full fs-14"
                  height="35px"
                  loading={loading}
                  disabled={error}
                >
                  {t("Download Profile")}
                </Button>
              )}
            </PDFDownloadLink>
          );
        }
        return d;
      });
    }
    return updatedBtns;
  }, [profile, itsMe, isLoggedIn, profileDownloadInfo]);

  return (
    <DashboardLayout
      emptyMessage={t("Unable to fetch profile details!")}
      isEmpty={!profile}
      loading={profile === null}
    >
      <Banner />
      {isLoggedIn && itsMe && !isTrue(profile?.is_2fa_enabled) ? (
        <Box sx={{ zIndex: 999 }} className="absolute w-full top-20">
          <Box
            sx={{
              backgroundColor: "#FFF3CC",
              border: `1px solid ${COLORS.ORANGE}`,
            }}
            className="container mx-auto rounded-10 px-3 py-2 bg-orange-opaque flex items-center justify-between"
          >
            <Box>
              <p className="fs-14 fw-600">
                {t("Update your account’s security")}
              </p>
              <p className="fs-14 mt-2">
                {t(
                  "Enable 2 factor authentication (2FA) in your settings to secure your account."
                )}
              </p>
            </Box>
            <Button color="orange" onClick={navigateSettings}>
              {t("Enable 2FA")}
            </Button>
          </Box>
        </Box>
      ) : null}
      <MainPageLayout classes="" firstLgGridSize={3.5} secondLgGridSize={8.5}>
        <div className="left">
          <ProfileCard
            image={profile?.profile_image}
            title={profile?.full_name || "N/A"}
            subTitle={t(snakeToCamel(profile?.role), "common")}
            onEdit={() => handleOpenModal(PROFILE_TYPES.GENERAL)}
            shiftTop
            noEdit={!itsMe}
            buttons={profileBtns}
            editProfileImage={itsMe}
            changeProfilePhoto={setProfileImage}
            privacy={profileVisibility}
            defaultImage={userImg}
            itsMe={itsMe}
          />
          <ImageCropper
            image={profileImage}
            open={!!profileImage}
            onClose={() => setProfileImage(null)}
            onSubmit={submitProfilePhoto}
            aspect={1}
          />
          {(itsMe || profileVisibility !== "private") && (
            <Card classes="mt-4 overflowX-hidden">
              {!isLoggedIn && profile?.profile_privacy !== "public" ? null : (
                <>
                  <div className="flex justify-between">
                    <div className="flex items-center fs-12">
                      <p>@{profile?.username || id}</p>
                    </div>
                    {itsMe && (
                      <IconButton
                        onClick={() => handleOpenModal(PROFILE_TYPES.GENERAL)}
                      >
                        <EditNote fontSize="small" className="c-black" />
                      </IconButton>
                    )}
                  </div>

                  <p className="fs-12">{`${profile?.city || ""}${
                    profile?.city && profile?.country ? "," : ""
                  } ${profile?.country || ""}`}</p>
                  {!profile?.city && !profile?.country && itsMe && (
                    <p className="fs-11 opacity-50">
                      {t("Add location", "common")}
                    </p>
                  )}

                  {profile?.about ? (
                    <p
                      className="fs-12 mt-2 pr-4"
                      dangerouslySetInnerHTML={{ __html: profile?.about }}
                    ></p>
                  ) : (
                    itsMe && (
                    <span className="opacity-50 fs-11">
                      {t("Tell us about yourself here")}
                    </span>
                    )
                  )}
                </>
              )}
              <h3
                className={
                  !isLoggedIn && profile?.profile_privacy !== "public"
                    ? null
                    : "mt-4"
                }
              >
                {t("LearnRank")} {profile?.learnrank}
              </h3>
              <IconText
                text={`${profile?.project_count || "0"} ${t(
                  "Projects Created"
                )}`}
                icon={
                  <img src={project} alt="project icon" className="w-20 h-20" />
                }
              />
              <IconText
                text={`${profile?.lab_count || "0"} ${t("Labs Joined")}`}
                icon={<img src={lab} alt="lab icon" className="w-20 h-20" />}
              />
              <IconText
                text={`${profile?.achievements_list?.length || "0"}  ${t(
                  "Achievements"
                )}`}
                icon={
                  <img
                    src={achievement}
                    alt="achievement icon"
                    className="w-20 h-20"
                  />
                }
              />

              <IconText
                text={`${
                  Object.entries(profile?.user_skills || {})?.length +
                    Object.entries(profile?.user_pinned_skills || {})?.length ||
                  "0"
                } ${t("Skills")}`}
                icon={
                  <img src={skillImg} alt="skill icon" className="w-20 h-20" />
                }
              />
              {(!isLoggedIn && profile?.profile_privacy !== "public") ||
              !profile?.user_pinned_projects?.length ? null : (
                <>
                  <h3 className="mt-4">{t("Featured Projects")}</h3>
                  {(profile?.user_pinned_projects || []).map((d) => (
                    <AssociatedItem
                      media={d?.media}
                      description={d?.description}
                      title={d?.title}
                      type={COMPONENT_TYPES.PROJECT}
                      key={d?.slug}
                      id={d?.slug}
                    />
                  ))}
                </>
              )}
              {!isLoggedIn && profile?.profile_privacy !== "public" ? null : (
                <>
                  <h3 className="mt-4">{t("Featured Skills")}</h3>
                  {profile?.user_pinned_skills &&
                  Object.keys(profile?.user_pinned_skills).length > 0
                    ? Object.entries(profile?.user_pinned_skills)
                        .filter(([key, title]) => !isNaN(key) && title)
                        .map(([key, title]) => (
                          <Chip
                            key={key}
                            label={title}
                            variant="outlined"
                            className="mr-1 mt-1"
                          />
                        ))
                    : itsMe && (
                        <Chip
                          label={t("Add Skills", "common")}
                          variant="outlined"
                          className="mr-1 mt-1"
                          onClick={() => setCurrentTab(1)}
                        />
                      )}

                  <>
                    <h3 className="mt-4">{t("Featured Achievements")}</h3>
                    {profile?.featured_achievement?.length > 0
                      ? profile?.featured_achievement.map(
                          (achievement, index) => (
                            <TrophyChip
                              key={achievement?.id}
                              title={achievement?.microcopy}
                              subTitle={getAchievementTypeKey(
                                achievement?.achievement_type
                              )}
                              points={achievement?.points || 0}
                              image={achievement?.image}
                              t={t}
                            />
                          )
                        )
                      : itsMe && (
                          <Chip
                            label={t("Add Achievements", "common")}
                            variant="outlined"
                            className="mr-1 mt-1"
                            onClick={() => setCurrentTab(4)}
                          />
                        )}
                  </>
                  <div>
                    <div className="flex mt-4 items-center justify-between mb-1">
                      <h3>{t("External Links", "common")}</h3>
                      {itsMe && (
                        <IconButton
                          onClick={() => handleOpenModal(PROFILE_TYPES.GENERAL)}
                        >
                          <EditNote fontSize="small" className="c-black" />
                        </IconButton>
                      )}
                    </div>
                    {profile?.external_links?.length > 0 ? (
                      <>
                        {profile?.external_links
                          ?.filter((d) => d?.is_website)
                          ?.map((l) => (
                            <p
                              onClick={() => window.open(l?.link, "_blank")}
                              className="link fw-400 c-title fs-14 text-truncate"
                            >
                              {l?.link}
                            </p>
                          ))}
                        <Stack direction="row" spacing={1} mt={1}>
                          {profile?.external_links
                            ?.filter((d) => !d?.is_website)
                            ?.map((link) => (
                              <>
                                <SecureImage
                                  key={id}
                                  placeholder={defaultExternalLink}
                                  src={link?.image}
                                  height="35px"
                                  classes="cursor-pointer rounded-10"
                                  onClick={() =>
                                    window.open(link?.link, "_blank")
                                  }
                                />
                              </>
                            ))}
                        </Stack>
                      </>
                    ) : (
                      itsMe && (
                        <div className="fs-12 pt-1 opacity-60">
                          {t("Link your website")}
                          <br />
                          <p className="py-1">
                            {t(
                              "Connect your social media accounts to display them here"
                            )}
                          </p>
                        </div>
                      )
                    )}
                  </div>

                  {!itsMe && isLoggedIn && profileDownloadInfo && (
                    <div className="flex justify-center mt-4">
                      <PDFDownloadLink
                        document={
                          <DownloadProfile
                            profile={profile}
                            labs={profileDownloadInfo?.labs}
                            projects={profileDownloadInfo?.projects}
                            hasMoreProjects={
                              profileDownloadInfo?.hasMoreProjects
                            }
                            hasMoreLabs={profileDownloadInfo?.hasMoreLabs}
                            t={t}
                          />
                        }
                        fileName={`${profile?.username}.pdf`}
                        className="text-decoration-none"
                      >
                        {({ loading, error }) => (
                          <Button
                            classes="w-150 fs-14"
                            height="35px"
                            loading={loading}
                            disabled={!!error}
                          >
                            {t("Download Profile")}
                          </Button>
                        )}
                      </PDFDownloadLink>
                    </div>
                  )}
                </>
              )}
            </Card>
          )}
        </div>
        <div className="right mb-4">
          <MainTabs
            classes="mt-4"
            tabs={PROFILE_TABS.map((tab) => tab.label)}
            current={currentTab}
            setCurrent={setCurrentTab}
          />
          {(!itsMe && profileVisibility === "private") ||
          (!isLoggedIn && profile?.profile_privacy !== "public") ? (
            <Card classes="mt-4 py-7">
              <EmptyText classes="opacity-60">
                {profileVisibility === "private"
                  ? t("This user's profile is private.")
                  : !isLoggedIn
                  ? t("This user’s profile is visible to signed-in users only.")
                  : t("Failed to show user details")}
              </EmptyText>
            </Card>
          ) : (
            <>
              {currentTabId === "userProfile" && (
                <ProfileDetails
                  setModal={handleOpenModal}
                  profile={profile}
                  setProfile={setProfile}
                  itsMe={itsMe}
                  profileVisibility={profileVisibility}
                  isLoggedIn={isLoggedIn}
                  t={t}
                />
              )}
              {currentTabId === "skills" && (
                <SkillsAndTags
                  deleteItem={deleteSkills}
                  isSkill
                  itsMe={itsMe}
                  setProfile={setProfile}
                  items={profile?.user_skills}
                  pinned={profile?.user_pinned_skills}
                  verified_skills={profile?.user_verified_skills}
                  onSubmit={addSkills}
                  masterList={keyValueFormat(skills)}
                  t={t}
                />
              )}
              {currentTabId === "projects" && (
                <ProjectListing
                  itsMe={itsMe}
                  projects={projects}
                  isLoading={isProjectLoading}
                  page={projectPage}
                  setPage={setProjectPage}
                  pages={projectPages}
                  search={projectSearch}
                  sort={projectSort}
                  setSort={setProjectSort}
                  setSearch={setProjectSearch}
                  pinUnpin={handleProjectPinUnpin}
                  t={t}
                />
              )}
              {currentTabId === "friends" && (
                <Friends itsMe={itsMe} profile={profile} t={t} />
              )}
              {currentTabId === "achievements" && (
                <Achievements
                  achievements={profile?.achievements_list}
                  itsMe={itsMe}
                  loading={loading}
                  handleSetFeatured={handleSetFeatured}
                  profileVisibility={profileVisibility}
                  t={t}
                />
              )}
              {/* {currentTabId === "tags" && (
                <SkillsAndTags
                  deleteItem={deleteTags}
                  itsMe={itsMe}
                  setProfile={setProfile}
                  items={profile?.tags}
                  onSubmit={addTags}
                  masterList={keyValueFormat(tags)}
                  pinned={{}}
                />
              )} */}
              {currentTabId === "challengeHistory" && (
                <ChallengeHistory
                  itsMe={itsMe}
                  challenges={challenges}
                  isLoading={isChallengeLoading}
                  page={challengePage}
                  setPage={setChallengePage}
                  pages={challengePages}
                  search={challengeSearch}
                  sort={challengeSort}
                  setSort={setChallengeSort}
                  setSearch={setChallengeSearch}
                  t={t}
                />
              )}
            </>
          )}
        </div>
      </MainPageLayout>
      <AddEditProfileModal
        modal={modal}
        setModal={setModal}
        profile={profile}
        setProfile={setProfile}
        data={data}
        onChange={onChange}
        errors={errors}
        onSubmit={handleSubmit}
        loading={loading}
        certificates={certificates}
        experience={experience}
        patent={patent}
        setCertificates={setCertificates}
        setExperience={setExperience}
        setPatent={setPatent}
        education={education}
        setEducation={setEducation}
        handleUploadFile={handleUploadFile}
        links={links}
        setLinks={setLinks}
        toggleFilePrivacy={toggleFilePrivacy}
        deleteUploadedResume={deleteUploadedResume}
        fileButtonRef={fileButtonRef}
        t={t}
      />
    </DashboardLayout>
  );
};

export default UserProfile;
