import { LocalPolice, ShoppingCartCheckout } from "@mui/icons-material";
import { Avatar, Icon } from "@mui/material";
import Divider from "components/Divider";
import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "store/auth/service-hook";
import userImage from "assets/svg/gradient/user.svg";
import { isNormalUser } from "helpers/utils/utilities";
import { useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";

const unauth_links = [{ title: "Login", path: "/login" }];

const ProfileMenu = ({ t = (d) => d }) => {
  const { profile } = useSelector((state) => state[REDUCER_TYPES.PROFILE]);
  const navigate = useNavigate();
  const {
    logout,
    state: { isLoggedIn, user },
  } = useAuth();
  const isNormalUserView = isNormalUser(user);

  const links = [
    !isNormalUserView && {
      title: "Upgrade Plan",
      path: "/upgrade-plan",
      icon: ShoppingCartCheckout,
    },
    { title: "Profile", path: `/profile/${user?.username}` },
    { title: "Inbox", path: "/chats" },
    { title: "Settings", path: "/settings" },
  ];

  return (
    <div className={`w-270`}>
      {isLoggedIn && (
        <div className="flex bg-primary c-white py-4 pl-3">
          <Avatar
            src={
              profile
                ? profile?.profile_image
                : user?.profile_image || userImage
            }
            className="w-40 h-40 mt-1"
          >
            <img src={userImage} alt="profile" className="h-40 w040 bg-white" />
          </Avatar>
          <div className="flex-column ml-2">
            <p className="fs-20 fw-500 maxw-200 tracking-tight text-truncate w-full">
              {user?.first_name + " " + user?.last_name}
            </p>
            <div className="flex fs-14">
              <LocalPolice fontSize="small" className="mr-1" />{" "}
              {t("Learn Rank")} {user?.user_rank || 0}
            </div>
          </div>
        </div>
      )}
      <div className={`c-primary py-3 pl-4`}>
        {(isLoggedIn ? links : unauth_links).map(({ title, path, icon }) => (
          <div className="flex-center mt-1" key={title}>
            {!!icon && <Icon className="mr-1 c-orange" component={icon} />}
            <p
              key={title}
              className="fs-16 cursor-pointer"
              onClick={() => navigate(path)}
            >
              {t(title)}
            </p>
          </div>
        ))}
      </div>
      {isLoggedIn && (
        <>
          <Divider classes="mt-0 mb-0" />
          <p
            className="opacity-60 fs-16 pl-3 pt-2 mb-4 fw-500 cursor-pointer"
            onClick={logout}
          >
            {t("Logout")}
          </p>
        </>
      )}
    </div>
  );
};

export default ProfileMenu;
