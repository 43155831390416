import React from "react";
import { Skeleton } from "@mui/material";
import { useAdvanceSearchContext } from "../hooks/useAdvanceSearchContext";
import useComputed from "../../../helpers/hooks/useComputed";
import { Link } from "react-router-dom";
import useRedirect from "../../../helpers/hooks/useRedirect";
import { ADVANCE_SEARCH_COMPONENTS } from "../data";

const QuickSearchListing = ({
  componentName,
  data,
  loading,
  titleKey = "title",
  component,
  t = (d) => d,
}) => {
  /**
   * HOOKS
   */
  const {
    quickKeyword,
    totalCountsQuickSearch,
    setKeyword,
    setSearchOpen,
    setActiveMenu,
    setTotalCounts
  } = useAdvanceSearchContext();

  /**
   * HOOKS
   */
  const {
    navigateLab,
    navigateLabP,
    navigateChallenge,
    navigateChallengePath,
    navigateResource,
    navigateResourceC,
    navigateResourceG,
    navigateProject,
    navigateOrg,
    navigateProfile,
  } = useRedirect();

  /**
   * NAVIGATION FUNCTION MEMORIZATION
   * @type {unknown}
   */
  const componentNavigation = {
    [ADVANCE_SEARCH_COMPONENTS.LABS]: {
      navigate(data) {
        navigateLab(data?.slug);
      },
    },
    [ADVANCE_SEARCH_COMPONENTS.LAB_PROGRAMS]: {
      navigate(data) {
        navigateLabP(data?.slug);
      },
    },
    [ADVANCE_SEARCH_COMPONENTS.LAB_MARKET_PLACE]: {
      // navigate(data) {
      //
      // }
    },
    [ADVANCE_SEARCH_COMPONENTS.CHALLENGES]: {
      navigate(data) {
        navigateChallenge(data?.slug);
      },
    },
    [ADVANCE_SEARCH_COMPONENTS.CHALLENGE_TEMPLATES]: {
      // navigate(data) {
      //
      // }
    },
    [ADVANCE_SEARCH_COMPONENTS.CHALLENGE_PATHS]: {
      navigate(data) {
        navigateChallengePath(data?.slug);
      },
    },
    [ADVANCE_SEARCH_COMPONENTS.RESOURCE_MODULES]: {
      navigate(data) {
        navigateResource(data?.slug);
      },
    },
    [ADVANCE_SEARCH_COMPONENTS.RESOURCE_COLLECTION]: {
      navigate(data) {
        navigateResourceC(data?.slug);
      },
    },
    [ADVANCE_SEARCH_COMPONENTS.RESOURCE_GROUPS]: {
      navigate(data) {
        navigateResourceG(data?.slug);
      },
    },
    [ADVANCE_SEARCH_COMPONENTS.PROJECTS]: {
      navigate(data) {
        navigateProject(data?.slug);
      },
    },
    [ADVANCE_SEARCH_COMPONENTS.ORGANIZATIONS]: {
      navigate(data) {
        navigateOrg(data?.slug);
      },
    },
    [ADVANCE_SEARCH_COMPONENTS.USERS]: {
      navigate(data) {
        navigateProfile(data?.username);
      },
    },
  };

  /**
   * Total RESULT COUNT
   */
  const total = useComputed(() => {
    return totalCountsQuickSearch[component] ?? 0;
  }, [component, totalCountsQuickSearch]);

  return (
    <>
      {loading || (data && data?.length > 0) ? (
        <div className="ml-1 w-300">
          <div className="flex items-center mt-2 pr-2">
            <p className="c-primary">{t(componentName)}</p>
            {total > 3 ? (
              <Link
                to={`/advanced-search?keyword=${quickKeyword}&component=${component}`}
                onClick={() => {
                  setSearchOpen(false);
                  setKeyword(quickKeyword);
                  setActiveMenu(component);
                  setTotalCounts({ ...totalCountsQuickSearch, [component]: 0 });
                }}
                className="ml-auto link fs-12"
              >
                {t("More Results")}
              </Link>
            ) : (
              ""
            )}
          </div>
          {loading ? (
            <div className={"pr-2"}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </div>
          ) : (
            data.map((item) => (
              <p
                className="opacity-60 fs-14 mt-1 text-truncate cursor-pointer"
                key={item}
                onClick={() => {
                  const value = componentNavigation[component];
                  if (value && value?.navigate) {
                    value.navigate(item);
                    setSearchOpen(false);
                  }
                }}
              >
                {item[titleKey]}
              </p>
            ))
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default QuickSearchListing;
