const Text = ({ children }) => {
  return (
    <div className="mt-2" data-testid="new-line-text">
      {!!children
        ? children
            .toString()
            .split("\n")
            .map((text, i) => (
              <p className="fs-14 mt-1" key={i}>
                {text}
              </p>
            ))
        : null}
    </div>
  );
};

export default Text;
