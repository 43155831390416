// import ChallengesList from "./partials/ChallengesList";
import Card from "../../../../components/Cards/Card";
import useOrganizationReportContext from "../hooks/useOrganizationReportContext";
import Select from "components/Input/Select";
import { Grid } from "@mui/material";
import { useMemo } from "react";
import ChallengesList from "./partials/ChallengesList";
import { ComponentMappings } from "pages/report/data";
import ChallengesPathList from "./partials/ChallengePathsList";
import ResourceCollectionList from "./partials/ResourceCollectionList";
import ResourceGroupsList from "./partials/ResourceGroupList";
import ResourceModulesList from "./partials/ResourceModulesList";
import LabList from "./partials/LabList";
import LabProgramList from "./partials/LabProgramList";

const ComponentLists = ({ t = (d) => d }) => {
  /**
   * HOOKS
   */
  const {
    components,
    activeComponent,
    setActiveComponent,
    organizationDetails,
  } = useOrganizationReportContext();

  /**
   * MEMORIZED
   */
  const ComponentStats = useMemo(() => {
    return [
      {
        title: t("Challenge(s)"),
        count: organizationDetails?.challenges_count,
      },
      {
        title: t("Challenge Path(s)"),
        count: organizationDetails?.challenge_paths_count,
      },
      {
        title: t("Lab(s)"),
        count: organizationDetails?.labs_count,
      },
      {
        title: t("Lab Program(s)"),
        count: organizationDetails?.lab_programs_count,
      },
      {
        title: t("Resource Module(s)"),
        count: organizationDetails?.resource_modules_count,
      },
      {
        title: t("Resource Collection(s)"),
        count: organizationDetails?.resource_collections_count,
      },
      {
        title: t("Resource Group(s)"),
        count: organizationDetails?.resource_groups_count,
      },
    ];
  }, [organizationDetails]);

  return (
    <Card title="Component Overview">
      <div
        className="flex mb-4 items-center"
        style={{ justifyContent: "space-between" }}
      >
        <h4 className="bold fs-16">{t("Components Overview")}</h4>
        <div>
          <Select
            borderedIcon
            value={activeComponent}
            onChange={(e) => setActiveComponent(e.target.value)}
            keyValues={components}
            width={"180px"}
            height={40}
            t={t}
          />
        </div>
      </div>
      <Grid container className="mb-4" paddingX={2}>
        <Grid container spacing={2} justifyContent="space-between">
          {ComponentStats?.slice(0, 4).map((item) => (
            <Grid item xs={12} md={2} key={item?.title}>
              <p className={"bold text-center fs-15"}>{item?.count}</p>
              <p className={"text-center fs-11"}>{item?.title}</p>
            </Grid>
          ))}
        </Grid>
        <Grid container justifyContent="space-between" marginX={10}>
          {ComponentStats?.slice(4).map((item) => (
            <Grid item xs={12} md={2} key={item?.title}>
              <p className={"bold text-center fs-15"}>{item?.count}</p>
              <p className={"text-center fs-11"}>{item?.title}</p>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {activeComponent === ComponentMappings.all_labs && <LabList />}
      {activeComponent === ComponentMappings.all_lab_programs && (
        <LabProgramList t={t} />
      )}
      {activeComponent === ComponentMappings.all_challenges && (
        <ChallengesList t={t} />
      )}
      {activeComponent === ComponentMappings.all_challenge_paths && (
        <ChallengesPathList t={t} />
      )}
      {activeComponent === ComponentMappings.all_resource_collections && (
        <ResourceCollectionList t={t} />
      )}
      {activeComponent === ComponentMappings.all_resource_groups && (
        <ResourceGroupsList t={t} />
      )}
      {activeComponent === ComponentMappings.all_resources && (
        <ResourceModulesList t={t} />
      )}
    </Card>
  );
};

export default ComponentLists;
