import Button from 'components/Button'
import ModalLayout from 'components/Modal';
import React, { useMemo, useState } from 'react'
import LabList from './LabList';
import CheckoutLab from './CheckoutLab';
import ConfirmationSuccess from './ConfirmationSuccess';
import { CARD_VALIDATOR, REQUIRED_VALIDATOR, Validate } from 'helpers/utils/validators';

const AddPrebuitLabsModal = ({ open, onClose }) => {

    const [selectedLabs, setSelectedLabs] = useState([]);
    const [checkoutState, setCheckoutState] = useState(0);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [checkoutData, setCheckoutData] = useState({});

    const checkout_errors = useMemo(() => {
        if (!isFormDirty) return {};
        return {
            card_number: Validate(CARD_VALIDATOR, checkoutData.card_number),
            expiry: Validate(REQUIRED_VALIDATOR, checkoutData.expiry),
            cvc: Validate(REQUIRED_VALIDATOR, checkoutData.cvc),
            postal_code: Validate(REQUIRED_VALIDATOR, checkoutData.postal_code)
        }
    }, [isFormDirty, checkoutData]);


    const handleAddLabs = () => {
        // eslint-disable-next-line default-case
        switch (checkoutState) {
            case 0: {
                if (!selectedLabs?.length) return;
                setCheckoutState(1);
                return;
            }
            case 1: {
                if (!isFormDirty) {
                    setIsFormDirty(true);
                } else {
                    if (Object.values(checkout_errors).some(value => !!value)) return;
                    setIsFormDirty(false);
                    setCheckoutData({});
                    setCheckoutState(2);
                }
                return;
            }
            case 2: {
                setCheckoutState(0);
                onClose();
            }
        }
    }

    const onCheckoutFieldChange = (e, name) => {
        const value = e.target.value;
        checkoutData[name] = value;
        setCheckoutData({ ...checkoutData });
    }

    return (
        <ModalLayout
            open={open}
            onClose={onClose}
            width="max(350px,70%)"
        >
            <h2 className='mt-4 ml-2 fs-18--mob'>ENHANCE YOUR PREPRLABS EXPERIENCE</h2>
            {
                checkoutState === 0 && <LabList setSelectedLabs={setSelectedLabs} selectedLabs={selectedLabs} />
            }
            {
                checkoutState === 1 && <CheckoutLab errors={checkout_errors} data={checkoutData} onChange={onCheckoutFieldChange} />
            }
            {
                checkoutState === 2 && <ConfirmationSuccess />
            }
            <div className={`flex items-center ${checkoutState === 2 ? "justify-center mt-4" : "justify-between"} pt-2 px-10 mb-2 footer__btns`}>
                {checkoutState !== 2 && <Button height={45} width={200} bordered onClick={onClose}>Cancel</Button>}
                <Button height={45} width={250} classes="ml-2" color={checkoutState === 1 ? "green" : "blue"} onClick={handleAddLabs}>{checkoutState === 0 ? (!selectedLabs?.length ? "Add" : "Checkout") : (checkoutState === 1 ? "Confirm & Pay" : (checkoutState === 2 && "Okay"))}</Button>
            </div>
        </ModalLayout>
    )
}

export default AddPrebuitLabsModal