import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  ShoppingCart,
  ShoppingCartCheckout,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import { users } from "assets/svg/blue";
import { org } from "assets/svg/yellow";
import Button from "components/Button";
import PlanDetailsCard from "components/Loaders/PlanDetailsCard";
import ModalLayout from "components/Modal";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES, ROLES } from "helpers/utils/constants";
import { getDefaultIconByType } from "helpers/utils/utilities";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useAuth from "store/auth/service-hook";
import useOrganization from "store/organization/service-hook";

const isNearLimitWarning = (count, limit) => count >= limit;

const getPlanUsageDetails = (component) => {
  const defaultImage = getDefaultIconByType(component);

  const detailsMap = {
    [COMPONENT_TYPES.LAB]: {
      title: "Lab",
      list: [
        { title: "Lab", key: "lab" },
        { title: "Lab Program", key: "lab_program" },
        { title: "Pre-built Lab", key: "pre_build_lab" },
      ],
    },
    [COMPONENT_TYPES.LAB_PROGRAMS]: {
      title: "Lab Program",
      list: [
        { title: "Lab", key: "lab" },
        { title: "Lab Program", key: "lab_program" },
      ],
    },
    [COMPONENT_TYPES.CHALLENGE]: {
      title: "Challenge",
      list: [
        { title: "Challenge", key: "challenge" },
        { title: "Challenge Path", key: "challenge_path" },
      ],
    },
    [COMPONENT_TYPES.CHALLENGE_PATH]: {
      title: "Challenge Path",
      list: [
        { title: "Challenge", key: "challenge" },
        { title: "Challenge Path", key: "challenge_path" },
      ],
    },
    [COMPONENT_TYPES.RESOURCE]: {
      title: "Resource",
      list: [
        { title: "Resource Module", key: "resource_module" },
        { title: "Resource Group", key: "resource_group" },
        { title: "Resource Collection", key: "resource_collection" },
      ],
    },
    [COMPONENT_TYPES.RESOURCE_COLLECTION]: {
      title: "Resource Collection",
      list: [
        { title: "Resource Module", key: "resource_module" },
        { title: "Resource Group", key: "resource_group" },
        { title: "Resource Collection", key: "resource_collection" },
      ],
    },
    [COMPONENT_TYPES.RESOURCE_GROUP]: {
      title: "Resource Group",
      list: [
        { title: "Resource Module", key: "resource_module" },
        { title: "Resource Group", key: "resource_group" },
        { title: "Resource Collection", key: "resource_collection" },
      ],
    },
    [COMPONENT_TYPES.ORGANIZATION]: {
      title: "Plan",
      list: [
        { title: "Challenge", key: "challenge" },
        { title: "Lab", key: "lab" },
        { title: "Resource Module", key: "resource_module" },
      ],
    },
  };

  return { img: defaultImage, ...detailsMap[component] };
};

const PlanDetails = ({ details, isPopup, component, setMyPlan = () => {} }) => {
  const [hidden, setHidden] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const { planDetails } = useOrganization();
  const {
    state: { user },
    getOrganization,
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const { t } = useLocalizedTranslation("common");

  const roleAccess = [ROLES.ORGANIZATION_OWNER, ROLES.SUPER_ADMIN].includes(
    user.role
  );

  useEffect(() => {
    fetchPlanDetails(user?.organization_details?.slug);
  }, [user?.organization_details?.slug]);

  const fetchPlanDetails = async () => {
    setLoading(true);
    const res = await getOrganization();
    const response = await planDetails(
      res ? res?.slug : user?.organization_details?.slug
    );

    setLoading(false);
    if (response) {
      setSubscriptionDetails(response);
      setMyPlan(response);
    }
  };

  const { navigateUpgradePlan } = useRedirect();
  const toggleHidden = () => setHidden((val) => !val);

  const { title, img, list } = getPlanUsageDetails(component);

  return loading ? (
    <PlanDetailsCard isPopup={isPopup} />
  ) : subscriptionDetails ? (
    <>
      {!isPopup && (
        <div
          onClick={toggleHidden}
          className="flex items-center fs-14 cursor-pointer"
          style={{ color: "#5B5D6B" }}
        >
          {hidden ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
          {hidden
            ? `${t("Display", "common")} ${t(
                title === "Plan" ? "Organization" : title,
                "common"
              )} ${t("Limit", "common")}`
            : `${t("Hide", "common")} ${t(
                title === "Plan" ? "Organization" : title,
                "common"
              )} ${t("Limit", "common")}`}
        </div>
      )}
      {!hidden && (
        <Grid
          container
          columnSpacing={isPopup ? 8 : 4}
          rowSpacing={isPopup ? 4 : 1}
          px={1}
          mt={1}
        >
          <Grid item xs={12} md={6} lg={isPopup ? 6 : 4} px={1}>
            <div className="flex justify-between">
              <div className="flex-center">
                <ShoppingCart
                  sx={{ color: "#FF9D00", width: "35px", height: "35px" }}
                />
                <h3 className="fw-600 ml-2 fs-18 fs-16--mob">
                  {t("Plan Overview", "common")}
                </h3>
              </div>
              {!isPopup && !roleAccess && (
                <Button
                  bordered
                  color="orange"
                  height="35px"
                  onClick={navigateUpgradePlan}
                >
                  <ShoppingCartCheckout /> {t("Upgrade", "common")}
                </Button>
              )}
            </div>
            {[
              // {
              //   label: t("Organization Name", "common"),
              //   value: isPopup
              //     ? details?.title
              //     : subscriptionDetails?.title ??
              //       user?.organization_details?.title,
              // },
              {
                label: t("Plan Name", "common"),
                value: isPopup
                  ? details?.organization_details?.plan_name ??
                    details?.plan_name
                  : subscriptionDetails?.plan_name,
              },
              {
                label: t("Plan Status", "common"),

                value: (
                  isPopup
                    ? details?.organization_details?.plan_name ??
                      details?.plan_name
                    : subscriptionDetails?.plan === "seed_plan_yearly"
                )
                  ? "-"
                  : `${t("Valid until", "common")} ${moment(
                      new Date(
                        isPopup
                          ? details?.organization_details?.plan_end_date ??
                            details?.plan_end_date
                          : subscriptionDetails?.plan_end_date
                      )
                    ).format("MMM DD, YYYY")}`,
              },
            ]
              .filter(({ value }) => value !== false)
              .map(({ label, value }, i) => (
                <div
                  key={i}
                  className="flex-center fs-14 fs-12--mob justify-between mt-2"
                >
                  <p className={`text-nowrap`}>{label} :</p>
                  <p
                    className={`text-nowrap c-gray text-truncate maxw-200 text-right`}
                  >
                    {value}
                  </p>
                </div>
              ))}
          </Grid>
          <Grid item xs={12} md={6} lg={isPopup ? 6 : 4} px={1}>
            <div className="flex-center mb-3">
              <img
                src={isPopup ? org : img}
                className="w-35 h-35 mr-3"
                alt={`${isPopup ? details?.title : title} icon`}
              />
              <h3 className="fw-600 fs-18 fs-16--mob">
                {t(isPopup ? "Plan" : title, "common")} {t("Limit", "common")}
              </h3>
            </div>
            {isPopup
              ? [
                  { title: "Challenge", key: "challenge" },
                  { title: "Lab", key: "lab" },
                  { title: "Resource", key: "resource_module" },
                ].map(({ title, key }) => (
                  <div key={key}>
                    <InfoRow
                      value={key}
                      title={title}
                      details={details?.organization_details ?? details}
                      t={t}
                    />
                  </div>
                ))
              : list?.map(({ title, key }) => (
                  <div key={key}>
                    <InfoRow
                      value={key}
                      title={title}
                      details={subscriptionDetails}
                      t={t}
                    />
                  </div>
                ))}
          </Grid>
          <Grid item xs={12} md={6} lg={isPopup ? 6 : 4} px={1}>
            <div className="flex-center mb-3">
              <img src={users} className="w-35 h-35 mr-3" alt="group icon" />
              <h3 className="fw-600 fs-18 fs-16--mob">
                {t("User Limit", "common")}
              </h3>
            </div>
            {[
              { title: "User", key: "user_invite" },
              { title: "Manager", key: "manager" },
            ].map(({ title, key }) => (
              <div key={key}>
                <InfoRow
                  value={key}
                  title={title}
                  t={t}
                  details={
                    isPopup
                      ? details?.organization_details ?? details
                      : subscriptionDetails
                  }
                />
              </div>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  ) : null;
};

export default PlanDetails;

export const InfoRow = ({ title, value, details, t = (d) => d }) => (
  <div className="flex-center fs-14 fs-12--mob justify-between mt-2">
    <p
      className={`text-nowrap ${
        details?.plan !== "unlimited_plan" &&
        isNearLimitWarning(
          details?.[`${value}_count`],
          details?.[`${value}_limit`]
        )
          ? "c-error"
          : ""
      }`}
    >
      {`${t(title, "common")} ${t("Limit", "common")}`}:{" "}
      {t(details?.[`${value}_limit`], "common") || 0}
    </p>
    <p
      className={`text-nowrap ${
        details?.plan !== "unlimited_plan" &&
        isNearLimitWarning(
          details?.[`${value}_count`],
          details?.[`${value}_limit`]
        )
          ? "c-error"
          : "c-gray"
      }`}
    >
      {t("Used", "common")}: {details?.[`${value}_count`] || 0}
      {details?.plan !== "unlimited_plan"
        ? `/${details?.[`${value}_limit`] || 0}`
        : ""}
    </p>
  </div>
);

export const PlanUsagePopUp = ({
  open,
  onClose,
  details,
  fetchPlanDetails,
}) => {
  const { navigateUpgradePlan } = useRedirect();
  const { t = (d) => d } = useLocalizedTranslation("common");
  const {
    state: { user },
  } = useAuth();

  const handleSelectPlan = () => {
    const instance = window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_STIE,
      publishableKey: process.env.REACT_APP_CHARGEBEE_KEY,
    });
    let cbPortal = instance.createChargebeePortal({
      email: user?.email,
    });

    cbPortal.open({
      close() {
        fetchPlanDetails();
      },
    });
  };
  return (
    <ModalLayout
      open={open}
      onClose={onClose}
      width="min(98vw,900px)"
      wrapperClasses="rounded-10 px-10 py-8"
    >
      <div>
        <h3 className="text-center fs-24">
          {t("Plan Usage for", "common")}{" "}
          <span className="link">{details?.title}</span>
        </h3>
        <PlanDetails details={details} isPopup />
        <div className="flex mt-6">
          <Button classes="mr-3" onClick={handleSelectPlan} color="orange">
            <ShoppingCartCheckout />{" "}
            <span className="ml-1">{t("Upgrade Plan", "common")}</span>
          </Button>
          <Button onClick={navigateUpgradePlan}>
            {t("Compare Plans", "common")}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
