import { Done } from "@mui/icons-material";
import { Avatar, Grid } from "@mui/material";
import { lab } from "assets/svg/blue";
import { achievement, project, skills as skillIcon } from "assets/svg/yellow";
import Button from "components/Button";
import MainProductCard from "components/Cards/MainProductCard";
import AcceptDeclineButtons from "components/UI/atoms/AcceptDeclineButtons";
import ChipsPreview from "components/UI/atoms/ChipsPreview";
import ListingContainer from "components/UI/organisms/ListingContainer";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import React from "react";

const PendingRequestListing = ({
  gridType,
  loading,
  total,
  page,
  list,
  setPage,
  onAcceptDecline = Function.prototype,
  t = (d) => d,
}) => {
  const { navigateProfile, navigateProject } = useRedirect();
  return (
    <ListingContainer
      classes="mt-4"
      loading={loading}
      page={page}
      setPage={setPage}
      count={total}
      isEmpty={!list?.length}
    >
      {list?.map((d, i) => {
        const { title, media, slug, user_details } = d;
        return (
          <Grid item key={i} xs={12} sm={6} lg={gridType === 1 ? 12 : 6}>
            <MainProductCard
              classes="mb-4"
              title={
                <div
                  className="flex-center"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Avatar
                    className="w-25 h-25"
                    src={user_details?.profile_image}
                  />
                  <div className="fs-18 text-truncate">
                    <span
                      className="mx-1 link"
                      onClick={() => navigateProfile(user_details?.username)}
                    >
                      {user_details?.full_name}
                    </span>
                    <span className="c-black">requests to join</span>
                    <span
                      className="ml-1 link"
                      onClick={() => navigateProject(slug)}
                    >
                      {title}
                    </span>
                  </div>
                </div>
              }
              subTitle={
                <div className="flex-center mt-1 ">
                  <p className="fs-14 fw-700 text-nowrap overflow-hidden maxw-400 textOverflow-ellipsis capitalize">
                    {t("Learning Rank")}{" "}
                    {user_details?.user_rank ? user_details?.user_rank : "N/A"}
                    <span className="ml-1 vertical-align-middle">
                      &middot;
                    </span>{" "}
                    {user_details?.position ? user_details?.position : "N/A"}
                  </p>
                </div>
              }
              image={media}
              id={slug}
              description={user_details?.bio || "N/A"}
              noSave
              col={gridType !== 1}
              type={COMPONENT_TYPES.PROJECT}
              iconsAndValuesList={[
                {
                  img: project,
                  value: `${user_details?.project_count || 0} ${t(
                    "Projects Created"
                  )}`,
                },
                {
                  img: lab,
                  value: `${user_details?.lab_count || 0} ${t("Labs Joined")}`,
                },
                {
                  img: achievement,
                  value: `${user_details?.achievement_count || 0} ${t(
                    "Achievements"
                  )}`,
                },
                {
                  img: skillIcon,
                  value: `${user_details?.skill_count || 0} ${t("Skills")}`,
                },
              ]}
              bottomContent={
                <Grid container spacing={1} mt={1}>
                  <Grid item sm={12} lg={8} className="text-truncate">
                    <ChipsPreview
                      items={Object.values(d?.skills)}
                      limit={gridType === 2 ? 2 : 3}
                      maxWidth={130}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    className="mt-2--mob flex justify-end"
                  >
                    {d.invite_status === "accepted" ||
                    d.invite_status === "declined" ? (
                      <Button
                        bordered
                        color={d.invite_status === "accepted" ? "green" : "red"}
                        width="90px"
                        height="26px"
                        classes="fs-12"
                      >
                        <Done fontSize="small" />{" "}
                        {t(d.invite_status === "accepted"
                          ? "Accepted"
                          : "Declined")}
                      </Button>
                    ) : (
                      <AcceptDeclineButtons
                        onAccept={() =>
                          onAcceptDecline(d.slug, d?.user_details?.email, false)
                        }
                        onDecline={() =>
                          onAcceptDecline(d.slug, d?.user_details?.email, true)
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        );
      })}
    </ListingContainer>
  );
};

export default PendingRequestListing;
