import ModalLayout from "components/Modal";
import { Grid, Icon, IconButton } from "@mui/material";
import React from "react";
import Button from "components/Button";
import { PROFILE_TYPES } from "../index";
import Certificates from "pages/profile/forms/Certificates";
import Experience from "pages/profile/forms/Experience";
import Patent from "pages/profile/forms/Patent";
import SchemaUIExtractor from "components/UI/organisms/SchemaUIExtractor";
import { schema } from "./data";
import useProfile from "store/profile/service-hook";
import Education from "./Education";
import FileButton from "components/Input/FileButton";
import { logo } from "assets/gif";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Card from "components/Cards/Card";
import SocialLinks from "components/UI/molecules/SocialLinks";
import { isTrue } from "helpers/utils/validators";

const AddEditProfileModal = ({
  modal,
  setModal,
  data,
  errors,
  onChange,
  onSubmit,
  loading,
  experience,
  setExperience,
  education,
  setEducation,
  certificates,
  setCertificates,
  patent,
  setPatent,
  handleUploadFile,
  profile,
  setProfile,
  links,
  setLinks,
  toggleFilePrivacy,
  fileButtonRef,
  deleteUploadedResume,
  t = (d) => d,
}) => {
  const { deleteExperience, deleteCertificate, deletePatent, deleteEducation } =
    useProfile();
  const onModalClose = () => {
    setModal(false);
    setEducation([{}]);
    setExperience([{}]);
    setCertificates([{}]);
    setPatent([{}]);
  };

  const handleDeleteExperience = async (id) => await deleteExperience(id);
  const handleDeleteEducation = async (id) => await deleteEducation(id);
  const handleDeleteCertificate = async (id) => await deleteCertificate(id);
  const handleDeletePatent = async (id) => await deletePatent(id);

  return (
    <ModalLayout open={!!modal} width="min(95%,900px)" onClose={onModalClose}>
      <div className="px-2 py-2">
        {modal === PROFILE_TYPES.GENERAL && (
          <div className="w-full">
            <h2>{t("Edit Personal Information")}</h2>
            <Grid container xs={12} spacing={4}>
              <Grid item xs={12} sm={6}>
                <SchemaUIExtractor
                  schema={schema.slice(0, 4)}
                  data={data}
                  onChange={onChange}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SchemaUIExtractor
                  schema={schema.slice(4, 6)}
                  data={data}
                  onChange={onChange}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12}>
                <SocialLinks socialLinks={links} setSocialLinks={setLinks} />
              </Grid>
            </Grid>
          </div>
        )}
        {modal === PROFILE_TYPES.EXPERIENCE && (
          <div className="w-full">
            <h2>{t("Edit Experience")}</h2>
            <h3 className="mt-1">{t("Upload Files")}</h3>
            <small>
              {t(
                "Upload your resume, cover letter, and any other relevant files here. Files must be less than 2MB and in any of the following format: PDF, doc, or docx."
              )}
            </small>
            {profile.user_personal_files &&
              profile.user_personal_files.length > 0 && (
                <div className="mt-4">
                  <Grid container spacing={1.5}>
                    {profile.user_personal_files.map((file) => (
                      <Grid key={file.id} item xs={6} md={3} lg={2.4}>
                        <Card
                          noShadow
                          classes="h-120 relative px-1 py-1 border"
                        >
                          <IconButton
                            size="small"
                            className="fs-12 absolute top-1 right-1"
                            onClick={() => deleteUploadedResume(file.id)}
                          >
                            <CloseIcon fontSize="small" className="fs-16" />
                          </IconButton>
                          <div className="flex flex-column items-center justify-center mt-6">
                            <h4
                              className="text-truncate text-center link pt-2 px-2 fs-12 c-black w-37.5"
                              onClick={() => window.open(file.path)}
                            >
                              {file?.original_name ?? "No name"}
                            </h4>
                            <div
                              className="mt-1 flex items-center text-center cursor-pointer"
                              onClick={() => toggleFilePrivacy(file?.id)}
                            >
                              <Icon
                                component={
                                  isTrue(file?.public)
                                    ? VisibilityIcon
                                    : VisibilityOffIcon
                                }
                                className="c-gray fs-16"
                              />
                              <span className="ml-1 fs-12 c-gray">
                                {isTrue(file?.public)
                                  ? t("Public")
                                  : t("Hidden")}
                              </span>
                            </div>
                          </div>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}

            {!loading && (
              <FileButton
                ref={fileButtonRef}
                onChange={handleUploadFile}
                accept=".pdf, .doc, .docx"
              >
                <Button classes="mt-2 relative" bordered>
                  {t("Upload File")}
                </Button>
              </FileButton>
            )}

            {loading === "file" && (
              <div className="flex-column items-center my-10">
                <p className="mt-4 fs-15 fw-500 opacity-90 tracking-tight">
                  {t(
                    "We are processing your resume, please wait for a few seconds"
                  )}
                </p>
                <img
                  src={logo}
                  className="mt-2 w-150 h-150"
                  alt="loading logo"
                />
              </div>
            )}

            {loading !== "file" && (
              <Experience
                experience={experience}
                setExperience={setExperience}
                onDelete={handleDeleteExperience}
                errors={errors}
                t={t}
              />
            )}
          </div>
        )}
        {modal === PROFILE_TYPES.EDUCATION && (
          <div className="w-full">
            <Education
              education={education}
              setEducation={setEducation}
              onDelete={handleDeleteEducation}
              errors={errors}
              t={t}
            />
          </div>
        )}
        {modal === PROFILE_TYPES.CERTIFICATE && (
          <Certificates
            certificates={certificates}
            setCertificates={setCertificates}
            errors={errors}
            onDelete={handleDeleteCertificate}
            t={t}
          />
        )}
        {modal === PROFILE_TYPES.PATENT && (
          <Patent
            patent={patent}
            setPatent={setPatent}
            errors={errors}
            onDelete={handleDeletePatent}
            t={t}
          />
        )}
        <div className="flex justify-between mt-6">
          <Button bordered onClick={onModalClose}>
            {t("Cancel")}
          </Button>
          <Button
            onClick={onSubmit}
            loading={loading && loading !== "file"}
            width="100px"
            disabled={
              experience.length === 0 ||
              education.length === 0 ||
              certificates.length === 0 ||
              patent.length === 0
            }
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default AddEditProfileModal;
