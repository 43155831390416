export const ERROR_MAINTENANACE_TYPES = [
  {
    id: 1,
    type: "maintenance",
    title: "PreprLabs is currently under maintenance.",
    description:
      "The site is currently going under scheduled maintenance. We should be back shortly.",
  },
  {
    id: 2,
    type: "400",
    title: "Error 400: Bad Request",
    description:
      "The 400 Bad Request error is an HTTP status code that means that the request you sent to the website server - often something simple like a request to load a page - was somehow incorrect or corrupted and the server couldn’t understand it.",
  },
  {
    id: 3,
    type: "500",
    title: "Error 500: Server Error",
    description:
      "The HTTP status code 500 is a generic error response. It means that the server encountered an unexpected condition that prevented it from fulfilling the request. This error is usually returned by the server when no other error code is suitable.",
  },
  {
    id: 4,
    type: "403",
    title: "Access Denied",
    description:
      "Sorry for inconvenience, but your account is not authorized to visit this page. Please contact your organization admin for permissions.",
  },
  {
    id: 5,
    type: "404",
    title: "Error 404: Page Not Found",
    description:
      "The 404 Page Not Found error means that you have found a link to a page that has either been moved or deleted. There’s a chance that you mistyped the website addess that you were looking for, clicked on a broken link, or we moved the page somewhere else.",
  },
  {
    id: 6,
    type: "401",
    title: "Error 401: Unauthorized",
    description:
      "The 401 Unauthorized error means that the page you were trying to access cannot be loaded until you first log in with a valid user ID and password. If you’ve just logged in and received this error, it means that the credentials you entered were invalid for some reason.",
  },
];
