import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableFooter, TablePagination } from "@mui/material";
import { leaf4 } from "assets/svg/green";
import Spin from "components/Spin";
import { useTranslation } from "react-i18next";

const TableComponent = ({
  rows = [],
  columns = [],
  minWidth = 650,
  tableClasses,
  titleCellClasses,
  rowSx = () => {},
  rowClasses = "",
  rowCellClasses,
  rowsPerPage = 10,
  paginator = false,
  loading,
  dense,
  hideBorder = false,
  hideLastRowBorder = false,
  onRowClick = Function.prototype,
  emptyView,
  emptyViewClasses,
  page,
  setPage,
  totalCount,
  loadingContainerClasses,
  setHoverdRow = Function.prototype,
}) => {
  const { t } = useTranslation("common");
  const lastRowIndex = paginator
    ? Math.min(rows?.length, (page + 1) * rowsPerPage) - 1
    : rows?.length - 1;

  return (
    <TableContainer data-testid="table">
      <Table
        sx={{ minWidth: minWidth }}
        className={`${tableClasses}`}
        size={dense && "small"}
      >
        <TableHead>
          <TableRow>
            {columns.map((col, i) => (
              <TableCell
                style={{ width: col.width && col.width }}
                key={i}
                className={`${titleCellClasses} text-nowrap`}
              >
                {col.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell
                colSpan={12}
                className={`flex text-center ${
                  loadingContainerClasses ? loadingContainerClasses : "h-300"
                }`}
              >
                <Spin blue big />
              </TableCell>
            </TableRow>
          ) : rows && rows?.length ? (
            rows.map((row, i) => (
              <TableRow
                onMouseEnter={() => setHoverdRow(row)}
                onMouseLeave={() => setHoverdRow(null)}
                key={i}
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom:
                      hideBorder || (hideLastRowBorder && i === lastRowIndex)
                        ? "none"
                        : "true",
                  },
                  ...rowSx(i, row),
                }}
                className={`${rowClasses}`}
                onClick={() => onRowClick(row.id)}
              >
                {columns.map((col, index) => {
                  return (
                    <TableCell
                      key={index}
                      sx={{ width: col.width && col.width }}
                      className={rowCellClasses}
                      align="left"
                    >
                      {col.render(row)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={12}
                className={`text-center flex items-center ${
                  emptyViewClasses ? emptyViewClasses : "h-300 opacity-60"
                }`}
              >
                {!!emptyView ? (
                  emptyView
                ) : (
                  <>
                    <img src={leaf4} alt="leaf" height={100} />
                    <p>{t("No Records found!")}</p>
                  </>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {paginator && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                onPageChange={(e, newValue) => setPage(newValue)}
                page={page}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
