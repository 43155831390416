import Card from "../../../../components/Cards/Card";
import React, { useMemo } from "react";
import { ArcElement, Chart } from "chart.js";
import Spin from "../../../../components/Spin";
import { Grid } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import "./index.scss";

Chart.register(ArcElement);
const DoughnutChartCard = (props) => {
  /**
   * COMPONENT PROPS
   */
  const { data, title, gaps, chartId, isLoading, noDataUI, legend, noCard } =
    props;
  /**
   *
   * @type {unknown}
   */
  const chart = useMemo(() => {
    return (
      <>
        {title ? <h4 className="bold fs-16  mb-4">{title}</h4> : ""}
        <Grid
          container
          spacing={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {isLoading ? (
            <Grid item xs={12}>
              <div className={"h-200 w-full flex justify-center items-center"}>
                <Spin blue big />
              </div>
            </Grid>
          ) : (
            ""
          )}
          {!isLoading && data ? (
            <>
              <Grid item xs={12} md={6} lg={12} xl={gaps && gaps[0] ? gaps[0] : 6}>
                <div className={"report-doughnut-chart"}>
                  <Doughnut
                    data={data}
                    id={chartId}
                    options={{ cutout: 70, radius: 70 }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={12} xl={gaps && gaps[1] ? gaps[1] : 6}>
                {legend ?? ""}
              </Grid>
            </>
          ) : (
            <>
              {!isLoading ? (
                <Grid item xs={12} className={"flex justify-center my-2"}>
                  {noDataUI ?? "No data found!"}
                </Grid>
              ) : (
                ""
              )}
            </>
          )}
        </Grid>
      </>
    );
  }, [data, title, chartId, isLoading, noDataUI, legend, gaps]);

  return <>{!noCard ? <Card>{chart}</Card> : <>{chart}</>}</>;
};

export default DoughnutChartCard;
