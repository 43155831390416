import AuthLayout from "../layouts/AuthLayout";
import LineProgress from "../../components/Progress/LineProgress";
import FileButton from "components/Input/FileButton";
import { Close, Upload } from "@mui/icons-material";
import Button from "components/Button";
import { useEffect, useState } from "react";
import Experience from "./components/Experience";
import useRedirect from "helpers/hooks/useRedirect";
import MasterSelect from "components/UI/organisms/MasterSelect";
import { Box, Chip } from "@mui/material";
import useOnboarding from "store/onboarding/service-hook";
import useAuth from "store/auth/service-hook";
import useProfile from "store/profile/service-hook";
import moment from "moment";
import { toast } from "react-toastify";
import { logo } from "assets/gif";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const steps = ["Your Resume", "Your Career", "Your Skills"];

const handleEmptyFields = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (!obj[key]) {
      obj[key] = "";
    }
  });
  return obj;
};

const UserOnboarding = () => {
  const [step, setStep] = useState(0);
  const { navigateExplore, navigateLogin } = useRedirect();
  const [experience, setExperience] = useState();
  const [jobs, setJobs] = useState([]);
  const [skills, setSkills] = useState([]);
  const { resumeParser, completeOnboarding, addJobs } = useOnboarding();
  const { addExperience, addSkills } = useProfile();
  const [loading, setLoading] = useState(false);
  const { t } = useLocalizedTranslation();
  const {
    state: { isLoggedIn },
  } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) navigateLogin();
  }, []);

  const handleNext = async () => {
    if (step === 0) {
      if (experience?.length) {
        const isDatesValid = experience.every((d) => {
          const startDate = new Date(d.start_date);
          const endDate = new Date(d.end_date);

          let _error;

          if (!d.start_date || !d.end_date)
            _error = "Both start date and end date are required.";
          else if (startDate > new Date())
            _error = "Start date should be today or earlier.";
          else if (endDate < startDate)
            _error = "End date should be after the start date.";

          if (_error) {
            toast.error(_error);
            return false;
          }
          return true;
        });

        if (!isDatesValid) {
          return;
        }

        setLoading("add");
        const payload = experience.map((d) => {
          const item = {
            ...d,
            start_date: moment(d.start_date).format("YYYY-MM-DD HH:mm:ss"),
            end_date: moment(d.end_date).format("YYYY-MM-DD HH:mm:ss"),
          };
          delete item.editMode;
          return handleEmptyFields(item);
        });
        const res = await addExperience(payload);
        if (res) setExperience(res || []);
        setLoading(false);
      }
      setStep(1);
      return;
    }

    if (step === 1) {
      if (!jobs.length) {
        setLoading("add");
        await addJobs(jobs.map((d) => d?.key));
        setLoading(false);
      }
    }

    if (step === 2) {
      if (!skills.length) {
        toast.error("Please select atleast 1 skill");
        return;
      }
      setLoading("add");
      const _list = skills?.map((f) => f?.key);
      await addSkills(_list, []);
      await completeOnboarding();
      setLoading(false);
      navigateExplore();
    }
    setStep(step + 1);
  };

  const handleBack = () => setStep(step - 1);

  const handleParseResume = async (file) => {
    if (!file) return;
    setLoading("resume");
    const res = await resumeParser(file);
    if (res) setExperience(res?.user_experiences || []);
    setLoading(false);
  };

  return (
    <AuthLayout sliderHidden>
      <div>
        <LineProgress
          length={steps.length}
          curr={step}
          classes="mt-14"
          numbered
          rounded
        />
        <div className="flex justify-center mt-1">
          {steps.map((title, index) => (
            <p className="fs-12 opacity-70 w-80 mx-3 text-center" key={index}>
              {t(title)}
            </p>
          ))}
        </div>
        {step === 0 && !experience && (
          <h2 className="mt-8 fs-20--mob text-center">
            {t("Set Up Your Profile to Get Started")}
          </h2>
        )}
        <div className="auth-card px-4 mt-10 text-center">
          {step === 0 && (
            <>
              <h2 className="mt-5 fw-700 fs-20--mob text-center">
                {t("What's Your Experience?")}
              </h2>
              {loading !== "resume" && (
                <p className="my-3 fs-14 opacity-90 leading-tight mx-auto w-80p text-center">
                  {t(
                    "You job experience will be added to your profile section on the platform."
                  )}
                </p>
              )}
              <div className="flex-1 w-90p overflow-hidden">
                {loading === "resume" ? (
                  <>
                    <p className="mt-10 fs-15 fw-500 opacity-90 w-90p tracking-tight">
                      {t(
                        "We are processing your resume, please wait for a few seconds"
                      )}
                    </p>
                    <img
                      src={logo}
                      className="mt-2 w-100 h-100"
                      alt="loading logo"
                    />
                  </>
                ) : !experience || experience.length === 0 ? (
                  <div className="flex flex-column items-center">
                    <FileButton onChange={handleParseResume}>
                      <Button
                        classes="mt-6 relative"
                        height="40px"
                        width="230px"
                        bordered
                      >
                        <Upload />
                        {t("Upload Your Resume")}
                      </Button>
                    </FileButton>
                    <p className="c-gray mt-2 fs-12">
                      {t("Acceptable file types: PDF, doc, docx.")} <br />
                      {t("File size limit: 20MB")}
                    </p>
                  </div>
                ) : (
                  <div
                    className="py-1 overflowY-auto"
                    style={{ maxHeight: "100%" }}
                  >
                    {experience?.map((d, i) => (
                      <Experience
                        setExperience={setExperience}
                        data={d}
                        id={d?.id}
                        key={i}
                        editMode={d?.editMode}
                        t={t}
                      />
                    ))}
                  </div>
                )}
              </div>
            </>
          )}

          {step === 1 && (
            <JobsAndSkillsStep
              title="What career are you pursuing?"
              description="What is the career path you're interested in?"
              placeholder="Search for a career path from our list"
              items={jobs}
              setItems={setJobs}
              name="jobs"
              t={t}
            />
          )}
          {step === 2 && (
            <JobsAndSkillsStep
              title="Tell Us About Your Skills"
              description="We'll recommend content based on your interested skills we've listed here."
              placeholder="Search for a skill from our list"
              name="skills"
              items={skills}
              setItems={setSkills}
              t={t}
            />
          )}
          <div className="flex w-100p w-70p--mob mt-3 py-3 justify-between">
            <Button
              onClick={step === 0 ? navigateExplore : handleBack}
              bordered
            >
              {t(
                step === 0
                  ? !experience
                    ? "I'll upload later"
                    : "Skip"
                  : "Back"
              )}
            </Button>
            <Button
              loading={loading === "add"}
              width={step === 2 && "130px"}
              onClick={handleNext}
            >
              {t(step === 2 ? "You Are All Set!" : "Next")}
            </Button>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default UserOnboarding;

export const JobsAndSkillsStep = ({
  title,
  description,
  placeholder,
  name,
  items,
  setItems,
  t = (d) => d,
}) => {
  const handleOnChange = (e) => setItems(e.target.value);
  const handleDeleteItem = (item) =>
    setItems((prev) => prev.filter((i) => i.key !== item.key));

  return (
    <div className="flex-1 w-90p">
      <h2 className="mt-5 text-center">{t(title)}</h2>
      <p
        className="my-3 fs-13 mx-auto w-80p text-center"
        style={{ color: "#444444" }}
      >
        {t(description)}
      </p>
      <MasterSelect
        name={name}
        classes="w-full mt-2"
        multi
        width="99%"
        placeholder={placeholder}
        onChange={handleOnChange}
        value={items}
        t={t}
      />
      <Box
        sx={{ gap: 0.5 }}
        className="w-full flex flex-wrap mt-3 w-80p overflowY-auto maxh-220"
      >
        {items.map((item) => (
          <Chip
            key={item.key}
            label={item.value}
            onDelete={() => handleDeleteItem(item)}
            deleteIcon={<Close className="fs-16 c-white" />}
            variant="filled"
            className="rounded-10 tracking-tight fs-14 h-30 bg-primary c-white"
          />
        ))}
      </Box>
    </div>
  );
};
