import React from "react";
import styles from "./input.module.scss";
import { FormControlLabel, Radio as RadioBtn, RadioGroup } from "@mui/material";
import { keyValueFormat } from "helpers/utils/utilities";

const Radio = ({
  name,
  value,
  onChange,
  options,
  error,
  classes = "",
  size = "medium",
  labelClasses = "",
  disabled,
  t = (d) => d,
  ...rest
}) => {
  return (
    <>
      <RadioGroup name={name} row onChange={onChange} className={classes}>
        {keyValueFormat(options).map(({ key, value: _value, i }) => (
          <FormControlLabel
            key={i}
            value={key}
            checked={key?.toString() === value}
            control={<RadioBtn size={size} />}
            label={typeof _value === "string" ? t(_value) : _value}
            disableTypography
            className={labelClasses}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
      {error && <p className={styles.error}>{t(error, "common")}</p>}
    </>
  );
};

export default Radio;
