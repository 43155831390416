import Card from "components/Cards/Card";
import Select from "components/Input/Select";
import CreationOptionsModal from "components/Modal/CreateOptionsModal";
import EmptyText from "components/UI/atoms/EmptyText";
import NuxLayout from "components/UI/molecules/NuxLayout";
import { NotLoggedInTabs } from "pages/organization";
import GO1ResourceBuilder from "pages/resources/ResourceModule/go1/ResourceBuilder";
import { getResourceNUX, useResourceCreateOptions } from "pages/resources/ResourceModule/listing/Manage";
import ResourceListing from "pages/resources/ResourceModule/listing/ResourceListing";
import React, { useEffect, useState } from "react";
import { ENDPOINTS } from "store/api/endpoints";
import useAssociation from "store/association";
import useAuth from "store/auth/service-hook";
import useRedirect from "helpers/hooks/useRedirect";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { COMPONENT_TYPES } from "helpers/utils/constants";

const OPTIONS = [
  { value: "Resource Modules", key: ENDPOINTS.RESOURCE_MODULE },
  { value: "Resource Collections", key: ENDPOINTS.RESOURCE_COLLECTION },
  { value: "Resource Groups", key: ENDPOINTS.RESOURCE_GROUP },
];

const AssociateResourceListing = ({
  isPrivateView,
  slug,
  smallView,
  parent,
  emptyView,
  resourcesList,
  isResourceCollDetail = false,
  showSwitch,
  isGroup,
  defaultSelected
}) => {
  const [resources, setResources] = useState([]);
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState(defaultSelected || ENDPOINTS.RESOURCE_MODULE);
  const {
    state: { isLoggedIn },
  } = useAuth();
  const { fetchAssociation } = useAssociation();
  const resourceCreateOptions = useResourceCreateOptions(setModal);
  const { navigateCreateResourceC, navigateCreateResourceG } = useRedirect();
  const { t } = useLocalizedTranslation("resource");

  useEffect(() => {
    handleFetchAssociation();
  }, [page, slug, selected]);

  const handleFetchAssociation = async () => {
    if (resourcesList) {
      setResources(resourcesList);
      return;
    }
    setLoading(true);
    const res = await fetchAssociation(parent, slug, selected, {
      page,
    });
    const [list, totalPages] = res;
    setResources(list || []);
    setPages(totalPages || 1);
    setLoading(false);
  };

  const handleModalClose = () => setModal(null);

  return (
    <>
      {!isLoggedIn ? (
        <NotLoggedInTabs
          title={`Login to view resources in this ${parent
            .split("-")
            .join(" ")}`}
        />
      ) : (
        <>
          {showSwitch && (
            <div className="flex-center justify-between w-100p mt-3">
              <h3 className="ml-1">
                {t(
                  OPTIONS.find((d) => d?.key === selected)?.value ||
                    "Resources",
                  "resource"
                )}
              </h3>
              <Select
                borderedIcon
                placeholder="Resource Modules"
                width="100%"
                value={selected}
                keyValues={OPTIONS}
                onChange={(e) => setSelected(e.target.value)}
                height={40}
                classes="w-25p w-50p--mob"
                t={t}
              />
            </div>
          )}

          {isPrivateView &&
          !resources?.length &&
          !loading &&
          parent === COMPONENT_TYPES.ORGANIZATION ? (
            <NuxLayout
              browseMode={
                selected === COMPONENT_TYPES.RESOURCE_COLLECTION
                  ? 2
                  : selected === COMPONENT_TYPES.RESOURCE_GROUP
                  ? 1
                  : 0
              }
              newUserData={getResourceNUX(() =>
                selected === COMPONENT_TYPES.RESOURCE_COLLECTION
                  ? navigateCreateResourceC()
                  : selected === COMPONENT_TYPES.RESOURCE_GROUP
                  ? navigateCreateResourceG()
                  : setModal("create")
              )}
            />
          ) : (
            <ResourceListing
              isAssociation
              resources={resources || []}
              loading={loading}
              gridType={1}
              setResources={setResources}
              manage={isPrivateView}
              smallView={smallView}
              page={page}
              setPage={setPage}
              pageCount={pages}
              isCollection={selected === ENDPOINTS.RESOURCE_COLLECTION}
              isGroup={selected === ENDPOINTS.RESOURCE_GROUP}
              isResourceCollDetail={isResourceCollDetail}
              emptyView={
                emptyView || (
                  <Card classes="flex items-center justify-center w-full text-center ml-4">
                    <EmptyText classes="c-gray w-70p opacity-80">
                      {t(
                        "There are no ${childComponent} associated with this ${component} now, please check back later.",
                        "resource",
                        {
                          childComponent: t(
                            selected === ENDPOINTS.RESOURCE_MODULE
                              ? "Resource Modules"
                              : selected === ENDPOINTS.RESOURCE_COLLECTION
                              ? "Resource Collections"
                              : "Resource Groups"
                          ),
                          component: t(parent),
                        }
                      )}
                    </EmptyText>
                  </Card>
                )
              }
            />
          )}
        </>
      )}

      <CreationOptionsModal
        open={modal === "create"}
        onClose={handleModalClose}
        title="How Do You Want To Create A New Resource Module?"
        options={resourceCreateOptions}
      />

      <GO1ResourceBuilder open={modal === "go1"} onClose={handleModalClose} />
    </>
  );
};

export default AssociateResourceListing;
