import React, { useEffect, useState } from "react";
import MainPageLayout from "../../layouts/MainPage";
import { useAdvanceSearchContext } from "../hooks/useAdvanceSearchContext";
import AdvanceSearchUsers from "./UsersListing";
import AdvanceSearchOrganization from "./OrganizationASListing";
import Filters from "./filters";
import AdvanceSearchChallengePath from "./ChallengePathASListing";
import AdvanceSearchChallenge from "./ChallengesASListing";
import AdvanceSearchChallengeTemplate from "./ChallengeTemplatsASListing";
import AdvanceSearchLabMarketPlace from "./LabMarketplaceASListing";
import AdvanceSearchLab from "./LabsASListing";
import AdvanceSearchResourceCollection from "./ResourceCollASListing";
import AdvanceSearchProjects from "./ProjectASListing";
import AdvanceSearchResourceGroup from "./ResourceGroupASListing";
import AdvanceSearchResourceModule from "./ResourceModuleASListing";
import AdvanceSearchLabPrograms from "./LabProgramASListing";
import { useSearchParams } from "react-router-dom";
import ListingPageHeader from "../../../components/UI/organisms/ListingPageHeader";
import { ADVANCE_SEARCH_SORT } from "../../../helpers/utils/constants";
import { useTranslation } from "react-i18next";
import { ADVANCE_SEARCH_COMPONENTS } from "pages/advanceSearch/data";

const AdvanceSearchMain = () => {
  /**
   * HOOKS
   */
  const {
    setKeyword,
    gridType,
    setGridType,
    sort,
    keyword,
    setSort,
    setActiveMenu,
    activeMenu,
    componentLists,
  } = useAdvanceSearchContext();
  const [searchParams] = useSearchParams();
  const [booted, setBooted] = useState(false);
  const { t } = useTranslation("common");

  /**
   * INIT FUNCITON
   */
  const init = () => {
    const keywordFromParam = searchParams.get("keyword");
    const activeTabFromParam = searchParams.get("component");
    if (keywordFromParam) {
      setKeyword(keywordFromParam);
    }
    if (activeTabFromParam) {
      const component = componentLists?.find(
        (item) => item?.value === activeTabFromParam
      );
      if (component) {
        setActiveMenu(activeTabFromParam);
      }
    }
    setBooted(true);
  };

  /**
   * SET KEYWORD ON MOUNTED
   */
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {booted ? (
        <MainPageLayout classes="mb-6 mt-header">
          <div className="left mt-3">
            <Filters t={t} />
          </div>
          <div className="right mt-3">
            <ListingPageHeader
              gridType={gridType}
              setGridType={setGridType}
              sortOptions={ADVANCE_SEARCH_SORT}
              sort={sort}
              setSort={(value) => setSort(value)}
              title={
                <div className="flex items-center justify-between">
                  <h2 className="ml-2 fs-23">
                    {t("Search results for")}{" "}
                    <span className={"c-primary"}>{`"${keyword}"`}</span>
                  </h2>
                </div>
              }
            />
            {activeMenu === ADVANCE_SEARCH_COMPONENTS.CHALLENGE_PATHS && (
              <AdvanceSearchChallengePath />
            )}
            {activeMenu === ADVANCE_SEARCH_COMPONENTS.CHALLENGE_TEMPLATES && (
              <AdvanceSearchChallengeTemplate />
            )}
            {activeMenu === ADVANCE_SEARCH_COMPONENTS.CHALLENGES && (
              <AdvanceSearchChallenge />
            )}
            {activeMenu === ADVANCE_SEARCH_COMPONENTS.LAB_MARKET_PLACE && (
              <AdvanceSearchLabMarketPlace />
            )}
            {activeMenu === ADVANCE_SEARCH_COMPONENTS.LAB_PROGRAMS && (
              <AdvanceSearchLabPrograms />
            )}
            {activeMenu === ADVANCE_SEARCH_COMPONENTS.LABS && (
              <AdvanceSearchLab />
            )}
            {activeMenu === ADVANCE_SEARCH_COMPONENTS.RESOURCE_COLLECTION && (
              <AdvanceSearchResourceCollection />
            )}
            {activeMenu === ADVANCE_SEARCH_COMPONENTS.ORGANIZATIONS && (
              <AdvanceSearchOrganization />
            )}
            {activeMenu === ADVANCE_SEARCH_COMPONENTS.PROJECTS && (
              <AdvanceSearchProjects />
            )}
            {activeMenu === ADVANCE_SEARCH_COMPONENTS.RESOURCE_GROUPS && (
              <AdvanceSearchResourceGroup />
            )}
            {activeMenu === ADVANCE_SEARCH_COMPONENTS.RESOURCE_MODULES && (
              <AdvanceSearchResourceModule />
            )}
            {activeMenu === ADVANCE_SEARCH_COMPONENTS.USERS && (
              <AdvanceSearchUsers />
            )}
          </div>
        </MainPageLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default AdvanceSearchMain;
