import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { LANGUAGES } from "helpers/utils/constants";
import { ArrowDropDown } from "@mui/icons-material";

const LanguageSelector = ({
  handleChange,
  language,
  classes = "",
  onlyClient = false,
}) => {
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const handleLanguageClose = () => setLanguageAnchorEl(null);
  return (
    <div className={classes}>
      <Button
        className="c-nav-icons tracking-tight"
        onClick={(event) => setLanguageAnchorEl(event.currentTarget)}
        endIcon={<ArrowDropDown />}
      >
        {language}
      </Button>
      <Menu
        anchorEl={languageAnchorEl}
        open={Boolean(languageAnchorEl)}
        onClose={handleLanguageClose}
        className="mt-1"
      >
        {LANGUAGES.map(({ key, value }) => (
          <MenuItem
            key={key}
            onClick={() => {
              handleChange(key, onlyClient);
              handleLanguageClose();
            }}
            className="flex-center mt-1"
          >
            <p className="fs-14">{value.toUpperCase()}</p>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguageSelector;
