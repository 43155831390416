import React, { useEffect, useMemo, useState } from "react";
import DialogBox from "components/Modal/Dialog";
import cx from "classnames";
import { Grid, Chip, Icon, Box, Skeleton } from "@mui/material";
import { SliderDots } from "components/Silder";
import Button from "components/Button";
import Checkbox from "components/Input/Checkbox";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import challenge from "assets/svg/green/challenge.svg";
import challenge_path from "assets/svg/green/challenge_path.svg";
import styles from "../ui.module.scss";
import ImageCard from "components/Cards/ImageCard";
import { resource, resourceCollection, resourceGroup } from "assets/svg/purple";
import { lab } from "assets/svg/blue";
import { org as orgYellow } from "assets/svg/yellow";
import skillsIcon from "assets/svg/yellow/skills.svg";
import achievementIcon from "assets/svg/yellow/achievement.svg";
import lab_trophy from "assets/svg/lab-trophy.svg";
import CheckIcon from "@mui/icons-material/Check";
import {
  BarChart,
  CalendarMonth,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import ReadMore from "../atoms/ReadMore";
import SwipableView from "./SwipableView";
import useWindowWidth from "helpers/hooks/useWindowWidth";
import SecureImage from "../atoms/SecureImage";
import useAssociation from "store/association";
import { ENDPOINTS } from "store/api/endpoints";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const MINI_ONBOARDING_STEPS = {
  [COMPONENT_TYPES.ORGANIZATION]: [
    "welcome",
    COMPONENT_TYPES.LAB,
    COMPONENT_TYPES.CHALLENGE,
    COMPONENT_TYPES.RESOURCE,
  ],
  [COMPONENT_TYPES.LAB]: [
    "welcome",
    COMPONENT_TYPES.CHALLENGE,
    COMPONENT_TYPES.RESOURCE,
    "outcome",
  ],
  [COMPONENT_TYPES.CHALLENGE]: [
    "welcome",
    "guidelines",
    COMPONENT_TYPES.RESOURCE,
    "outcome",
  ],
};
const MiniOnboarding = ({
  open,
  onClose,
  component,
  data,
  setCompleted = Function.prototype,
}) => {
  const [step, setStep] = useState(0);
  const steps = useMemo(() => MINI_ONBOARDING_STEPS[component], [component]);
  const { t } = useLocalizedTranslation();

  const renderStep = (stepId) => {
    switch (stepId) {
      case "welcome":
        return <Welcome data={data} component={component} t={t} />;
      case "guidelines":
        return <ChallengeGuidelines t={t} />;
      case COMPONENT_TYPES.LAB:
      case COMPONENT_TYPES.CHALLENGE:
      case COMPONENT_TYPES.RESOURCE:
        return (
          <MiniOnboardingListing
            component={stepId}
            data={data}
            type={component}
            t={t}
          />
        );
      case "outcome":
        return <Outcome data={data} component={component} t={t} />;
      default:
        return null;
    }
  };

  const handleNext = () => {
    if (step === steps.length - 1) {
      onClose();
      return;
    }
    setStep(step + 1);
  };
  return (
    <DialogBox open={open} onClose={onClose}>
      <div
        className={cx(styles.miniOnboarding, styles[steps[step]], {
          [styles.organization]:
            step === 0 && component === COMPONENT_TYPES.ORGANIZATION,
          [styles.challenge]:
            step === 0 && component === COMPONENT_TYPES.CHALLENGE,
          [styles.lab]: step === 0 && component === COMPONENT_TYPES.LAB,
        })}
      >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={10}>
            <SliderDots
              curr={step}
              setCurr={setStep}
              length={steps.length}
              noArrows
              classes="mt-0"
            />
          </Grid>
          {renderStep(steps[step])}
          <Grid
            item
            xs={11}
            mt={3}
            className="flex justify-between items-center w-full my-6"
          >
            <>
              {step === 0 ? (
                <div className="flex items-center fs-12">
                  <Checkbox onChange={setCompleted} />{" "}
                  {t("Don’t show these tutorials again", "common")}
                </div>
              ) : (
                <Button
                  onClick={() => setStep((prev) => --prev)}
                  bordered
                  width="120px"
                  height="35px"
                >
                  {t("Back", "common")}
                </Button>
              )}
              <Button onClick={handleNext}>
                {t(
                  step === 0 && component !== COMPONENT_TYPES.ORGANIZATION
                    ? "Learn more"
                    : step === steps.length - 1
                    ? "Lets Get started"
                    : "Next",
                  "common"
                )}
              </Button>
            </>
          </Grid>
        </Grid>
      </div>
    </DialogBox>
  );
};

export default MiniOnboarding;

const getList = async (fetch, component, slug, child) => {
  const res = await fetch(component, slug, child);
  return res?.[0] || [];
};

const getDetails = (component) => {
  const detailsMap = {
    [COMPONENT_TYPES.CHALLENGE]: {
      title: "Challenges",
      description:
        "Unlock your potential! Join this lab to dive into exciting challenges. It's more than a task—it's an opportunity to innovate, collaborate, and elevate your skills.",
      sublist: [
        {
          title: "Challenges",
          description: "Challenges are tasks that you complete and learn from.",
          icon: challenge,
        },
        {
          title: "Challenge Paths",
          description: "Challenge paths are a series of Challenges.",
          icon: challenge_path,
        },
      ],
      listTitle: "Getting Started PreprLabs Network Challenges",
      list: (fetch, type, index, slug) => {
        switch (index) {
          case 0:
            return getList(fetch, type, slug, COMPONENT_TYPES.CHALLENGE);
          case 1:
            return getList(fetch, type, slug, ENDPOINTS.CHALLENGE_PATH);
          default:
            return [];
        }
      },
      type: "challenge",
    },
    [COMPONENT_TYPES.RESOURCE]: {
      title: "Resources",
      description:
        "Resources contain learning materials in forms of docs, articles, videos and more to help you to tackle the Challenges.",
      sublist: [
        { title: "Module", icon: resource },
        { title: "Collection", icon: resourceCollection },
        { title: "Group", icon: resourceGroup },
      ],
      listTitle: "Getting Started PreprLabs Network Resources",
      list: (fetch, type, index, slug) => {
        switch (index) {
          case 0:
            return getList(fetch, type, slug, ENDPOINTS.RESOURCE_MODULE);
          case 1:
            return getList(fetch, type, slug, ENDPOINTS.RESOURCE_COLLECTION);
          case 2:
            return getList(fetch, type, slug, ENDPOINTS.RESOURCE_GROUP);
          default:
            return [];
        }
      },
      type: "resource_module",
    },
    [COMPONENT_TYPES.LAB]: {
      title: "Labs",
      description:
        "Completing Labs will not only earn you an achievement but you will gain specific skills.",
      sublist: [
        {
          title: "Labs",
          description: "Labs contain Challenges that help you gain new skills.",
          icon: lab,
        },
        {
          title: "Lab Programs",
          description: "Lab Programs contain multiple Labs that guide you...",
          icon: lab,
        },
      ],
      listTitle: "All Labs",
      list: (fetch, type, index, slug) => {
        switch (index) {
          case 0:
            return getList(fetch, type, slug, COMPONENT_TYPES.LAB);
          case 1:
            return getList(fetch, type, slug, ENDPOINTS.LAB_PROGRAM);
          default:
            return [];
        }
      },
      type: "lab",
    },
  };

  return detailsMap[component];
};

const MiniOnboardingListing = ({
  component,
  data,
  type: parent,
  t = (d) => d,
}) => {
  const { title, description, sublist, list, type, listTitle } =
    getDetails(component);
  const { fetchAssociation } = useAssociation();
  const [index, setIndex] = useState(0);
  const [items, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const width = useWindowWidth();

  useEffect(() => {
    handleSetList();
  }, [index, component]);

  const handleSetList = async () => {
    setLoading(true);
    setList([]);
    const _list = await list(fetchAssociation, parent, index, data?.slug);
    setList([..._list]);
    setLoading(false);
  };

  const slidesPerView = useMemo(
    () => (width > 1200 ? 4 : width > 500 ? 3 : 2),
    []
  );

  return (
    <>
      <Grid item xs={11} mt={2}>
        <h2 className="fw-600">{t(title, "common")}</h2>
        <p
          className="fs-14 mt-1 tracking-tight"
          dangerouslySetInnerHTML={{ __html: t(description, "common") }}
        />
      </Grid>
      <Grid item container xs={11} mt={5} spacing={2}>
        {sublist?.map((d, i) => (
          <Grid item xs={12} md={sublist?.length > 2 ? 4 : 6} key={i}>
            <div
              className={cx(
                styles.card,
                styles[type],
                { [styles.selected]: i === index },
                `flex `
              )}
              onClick={() => setIndex(i)}
            >
              <img
                src={d?.icon}
                alt="challenge"
                height="40px"
                className="mt-2"
              />
              <div className="ml-2 flex-column justify-center">
                <h3 className="fs-16">{t(d?.title, "common")}</h3>
                <p className="fs-12">{t(d?.description, "common")}</p>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={11} mt={3}>
        {items.length > 0 && <p className="fw-500">{t(listTitle, "common")}</p>}
      </Grid>

      <Grid item container xs={11} my={2} pl={1} spacing={2}>
        {loading ? (
          <Grid
            container
            className="w-full"
            spacing={1}
            justifyContent="center"
          >
            {Array(4)
              .fill(1)
              .map((_d, i) => (
                <Grid item xs={3}>
                  <Skeleton
                    variant="rectangular"
                    className="h-120 w-full rounded-5"
                  />
                </Grid>
              ))}
          </Grid>
        ) : items.length > 0 ? (
          <SwipableView
            dynamicBullets
            noLoop
            slidesPerView={slidesPerView}
            classes="w-full"
            small
          >
            {items.map((d, i) => (
              <Grid item xs={12} key={i} pb={3}>
                <ImageCard
                  image={d?.media}
                  width="100%"
                  height="120px"
                  type={component}
                  title={d?.title}
                  small
                />
                <p className="fs-13 mt-1 fw-500 text-truncate">{d?.title}</p>
              </Grid>
            ))}
          </SwipableView>
        ) : (
          <Box className="py-10 text-center flex items-center justify-center w-full fs-14 opacity-60">
            {t(
              "No ${component} is added yet, please check back later",
              "common",
              {
                component: t(sublist[index]?.title, "common"),
              }
            )}
          </Box>
        )}
      </Grid>
    </>
  );
};

const Welcome = ({ data, component, t = (d) => d }) => {
  return (
    <Grid item xs={11} xl={10.5} mt={3}>
      <ImageCard
        width="max(40%,220px)"
        height="170px"
        classes="mx-auto"
        image={data?.media || data?.profile_image}
        type={component}
        isEmbedded={data?.media_type === "embedded"}
        title={data?.title}
      />
      <div className="flex mt-6">
        <img
          src={
            component === COMPONENT_TYPES.LAB
              ? lab
              : component === COMPONENT_TYPES.ORGANIZATION
              ? orgYellow
              : challenge
          }
          alt="lab"
          height="60px"
          className="mt-2"
        />
        <div className="ml-2 flex-1">
          <h3 className="fw-700">
            {t("Welcome to this ${component}", "common", {
              component: t(
                component === COMPONENT_TYPES.LAB
                  ? "Lab"
                  : component === COMPONENT_TYPES.ORGANIZATION
                  ? "Organization"
                  : "Challenge",
                "common"
              ),
            })}
            !
          </h3>
          <Box width="min(550px,100%)">
            <ReadMore
              text={
                component === COMPONENT_TYPES.CHALLENGE
                  ? (data?.description_type === 'text' ? data?.description : t("Challenge descriptions and requirements are displayed via SCORM files. Click on the challenge title to view the full description.", "challenge"))
                  : (data?.description || data?.about || "N/A")
              }
              limit={6}
              classes="fs-14 tracking-tight"
            />
          </Box>

          {component !== COMPONENT_TYPES.ORGANIZATION && (
            <div className="flex items-center mt-2 fs-13">
              {[
                { title: data?.duration, icon: CalendarMonth },
                { title: data?.level, icon: BarChart },
              ]
                .filter((d) => Boolean(d?.title))
                .map(({ title, icon }) => (
                  <>
                    <Icon component={icon} className="c-primary mr-1" />
                    <p className="mr-2 opacity-50">{title}</p>
                  </>
                ))}
            </div>
          )}
        </div>
      </div>
      {component !== COMPONENT_TYPES.CHALLENGE && (
        <>
          <h4 className="my-4 text-left">
            {t("Explore these Learning options", "common")}:
          </h4>
          <Grid container spacing={2} mb={1} justifyContent="center">
            {component === COMPONENT_TYPES.ORGANIZATION && (
              <CountCard
                count={data?.lab_count || 0}
                type={COMPONENT_TYPES.LAB}
                t={t}
              />
            )}
            {component !== COMPONENT_TYPES.CHALLENGE && (
              <CountCard
                count={data?.challenge_count || 0}
                type={COMPONENT_TYPES.CHALLENGE}
                t={t}
              />
            )}
            <CountCard
              count={data?.resource_module_count || 0}
              type={COMPONENT_TYPES.RESOURCE}
              t={t}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const CountCard = ({ count, type, t = (d) => d }) => {
  const config = {
    [COMPONENT_TYPES.LAB]: {
      title: "Labs",
      backgroundImage: lab,
      classes: "bg-primary-light shadow",
    },
    [COMPONENT_TYPES.CHALLENGE]: {
      title: "Challenges",
      backgroundImage: challenge,
      classes: "bg-green--opaque shadow",
    },
    [COMPONENT_TYPES.RESOURCE]: {
      title: "Resources",
      backgroundImage: resource,
      classes: "shadow bg-purple-opaque",
    },
  };

  const { title, backgroundImage, classes } = config[type];
  return (
    <Grid item xs={12} md={4} mb={2} height="120px">
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        className={`flex w-100p h-130 justify-center rounded-10 opacity-80 items-center ${classes}`}
      >
        <h3 className="fw-700 fs-16">
          {t(title, "common")}: {count}
        </h3>
      </div>
    </Grid>
  );
};

const Outcome = ({ data, component, t = (d) => d }) => {
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState(0);
  const [expandedSkills, setExpandedSkills] = useState(false);

  const handleSkillsToggle = () => setExpandedSkills(!expandedSkills);

  return (
    <>
      <Grid item xs={11} mt={2}>
        <h2 className="fw-600">{t("Outcome")}</h2>
        <p className="fs-14 mt-1 tracking-tight">
          {t(
            `Completing this ${
              component === COMPONENT_TYPES.LAB ? "Lab" : "Challenge"
            } will not only earn you an`
          )}{" "}
          <br /> {t("achievement but you will gain specific skills.")}
        </p>
      </Grid>
      <Grid item container xs={11} mb={6} mt={2} spacing={2}>
        {data?.participation_achievement?.achievement_name ||
        data?.achievement?.achievement_name ? (
          <>
            <Grid item xs={12} md={6} mt={4}>
              <div className="flex" onClick={() => setSelected(0)}>
                <div className="flex-1">
                  <img
                    src={achievementIcon}
                    alt="achievement"
                    width="50px"
                    className="mt-2"
                  />
                </div>
                <div className="ml-2 pr-4">
                  <h3 className="fs-16">{t("Achievements")}</h3>
                  <p className="fs-12">
                    {t(
                      `Completing this ${
                        component === COMPONENT_TYPES.LAB ? "Lab" : "Challenge"
                      } will earn you an achievement that you can show off on your profile.`
                    )}
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} mt={4}>
              <div
                className="flex bg-primary--opaque px-3 pb-1 pt-2 rounded-10"
                onClick={() => setSelected(1)}
              >
                <SecureImage
                  placeholder={lab_trophy}
                  classes="rounded-100 h-70 w-70"
                  src={
                    component === COMPONENT_TYPES.CHALLENGE
                      ? data?.participation_achievement?.achievement_image
                      : data?.achievement?.achievement_image
                  }
                  alt="challenge icon"
                />
                <div className="ml-1">
                  <h3 className="fs-16">
                    {component === COMPONENT_TYPES.CHALLENGE
                      ? data?.participation_achievement?.achievement_name
                      : data?.achievement?.achievement_name}
                  </h3>
                  <p className="fs-12 fw-600">
                    {component === COMPONENT_TYPES.CHALLENGE
                      ? data?.participation_achievement?.achievement_points
                      : component === COMPONENT_TYPES.LAB
                      ? data?.achievement?.achievement_points
                      : 0}{" "}
                    {t("Prepr Points", "common")}
                  </p>
                </div>
              </div>
            </Grid>
          </>
        ) : null}

        <Grid item xs={12} md={6} mt={4}>
          <div className="flex" onClick={() => setSelected(1)}>
            <div className="flex-1">
              <img
                src={skillsIcon}
                alt="chellenge"
                width="50px"
                className="mt-2"
              />
            </div>
            <div className="ml-2 pr-4">
              <h3 className="fs-16">{t("Skills")}</h3>
              <p className="fs-12">
                {t(
                  `Completing this ${
                    component === COMPONENT_TYPES.LAB ? "Lab" : "Challenge"
                  } will help you gain specific skills. Find the skills associated with this ${
                    component === COMPONENT_TYPES.LAB ? "lab" : "challenge"
                  } here.`
                )}
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} mt={4}>
          {!!data?.skills && !Array.isArray(data?.skills) ? (
            (expandedSkills
              ? Object.values(data.skills)
              : Object.values(data.skills).slice(0, 3)
            )?.map((skill, i) => (
              <Chip
                label={skill}
                key={i}
                variant="outlined"
                className="mr-1 mt-1 fw-600"
              />
            ))
          ) : (
            <p className="c-gray mt-4 fs-12">{`No Skill is added to this ${
              component === COMPONENT_TYPES.LAB ? "Lab" : "Challenge"
            } yet, please check back later`}</p>
          )}
          {data?.skills && Object.values(data.skills).length > 3 && (
            <Chip
              label={
                expandedSkills ? (
                  <ExpandLess className="mt-1" />
                ) : (
                  <ExpandMore className="mt-1" />
                )
              }
              onClick={handleSkillsToggle}
              variant="outlined"
              className="mr-1 mt-1 fw-600"
              clickable
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

const ChallengeGuidelines = ({ t = (d) => d }) => {
  const guidelines = [
    {
      title: "1 - Prepare for your Challenge",
      items: [
        "Read the Challenge description",
        "Explore the Resources",
        "What are the requirements?",
      ],
    },
    {
      title: "2 - Start Challenge - Create Project",
      items: [
        "Follow the steps & add information/details",
        "Check off the Challenge Tasks",
        "Fill in the Challenge Questions",
        "Upload images or files",
      ],
    },
  ];

  return (
    <>
      <Grid item xs={11} mt={2}>
        <h2 className="fw-600 mt-2">{t("How to start a Challenge")}</h2>
        <p className="fs-14 mt-1 tracking-tight">
          {t(
            "Here are a few guidelines that will help you start your Challenge."
          )}
        </p>
      </Grid>
      <div className="my-20 flex justify-center w-full">
        <Grid container xs={11} spacing={2}>
          {guidelines.map(({ title, items }, i) => (
            <Grid item xs={12} md={6} key={i}>
              <p className="fs-16 mb-3 fw-600" style={{ color: "#1E1E1E" }}>
                {t(title)}
              </p>
              <ul className="pl-0">
                {items.map((step, index) => (
                  <li key={index} className="flex mb-2">
                    <CheckIcon className="mr-1 c-success" />
                    <p className="fs-14">{t(step)}</p>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};
