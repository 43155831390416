import {useEffect} from 'react';
import { useSelector } from 'react-redux';
import { REDUCER_TYPES } from 'store';

const AccessibeComponent = () => {
    /**
     * COMPONENT MOUNTED
     */

    const {user} = useSelector((state) => state[REDUCER_TYPES.AUTH]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
        script.async = true;

        script.onload = () => {
            if (window.acsbJS) {
                window.acsbJS.init({
                    statementLink: '',
                    footerHtml: '',
                    hideMobile: false,
                    hideTrigger: false,
                    disableBgProcess: false,
                    language: user?.preferred_language?.substring(0, 2) || navigator.language.substring(0, 2) || "en",
                        //Using substring() above since Intercom only accepts two-letter ISO 639 language codes
                    position: 'right',
                    leadColor: '#13A859',
                    triggerColor: '#13A859',
                    triggerRadius: '50%',
                    triggerPositionX: 'right',
                    triggerPositionY: 'bottom',
                    triggerIcon: 'people',
                    triggerSize: 'bottom',
                    triggerOffsetX: 20,
                    triggerOffsetY: 20,
                    mobile: {
                        triggerSize: 'small',
                        triggerPositionX: 'right',
                        triggerPositionY: 'bottom',
                        triggerOffsetX: 10,
                        triggerOffsetY: 10,
                        triggerRadius: '20',
                    },
                });
            }
        };

        const headOrBody = document.querySelector('head') || document.body;
        headOrBody.appendChild(script);

        return () => {
            headOrBody.removeChild(script);
        };
    }, []);

    return null;
};

export default AccessibeComponent;
