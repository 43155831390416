import Card from "components/Cards/Card";
import EditCreateHeaderCard from "components/Cards/EditCreateHeaderCard";
import React, { useEffect, useState } from "react";
import "../labs.scss";
import LabDetails from "./LabDetails";
import { useParams } from "react-router";
import useLabs from "store/labs/service-hook";
import { create_lab_steps, schema, stepSchema } from "./data";
import useForms from "helpers/hooks/useForms";
import CreateFormFooter from "components/UI/atoms/CreateFormFooter";
import DashboardLayout from "pages/layouts/dashboard";
import {
  createEvent,
  deleteValues,
  keyVal,
  keyValueFormat,
  payloadBool,
  payloadFormatArray,
  payloadGetVal,
  payloadKeyVal,
  payloadKeyVals,
  payloadToArrayKeyValues,
} from "helpers/utils/utilities";
import { formatAddress } from "components/UI/organisms/GooglePlacesSelect";
import AssociatedListing from "components/UI/organisms/AssociatedListing";
import { toast } from "react-toastify";
import { validateExternalLinks } from "components/UI/molecules/SocialLinks";
import useResources from "store/resources/service-hook";
import useChallenges from "store/challenges/service-hook";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import CampusConnectIntegration from "../../challenges/forms/CampusConnectIntegration";
import {
  populateCampusConnectInitialValue,
  removeHiddenFieldValidationError,
  removeLiveEventValidation,
} from "./helpers";
import LiveEvent from "./LiveEvent";
import { Box, Grid } from "@mui/material";
import PageTitle from "components/UI/atoms/PageTitle";
import CreateFormSection from "components/UI/atoms/CreateFormSection";
import AddAchievementSection, {
  achievement_schema,
  validateAchievements,
} from "components/UI/molecules/AddAchievementSection";
import useAssociation from "store/association";
import { ENDPOINTS } from "store/api/endpoints";
import AlertMessage from "components/UI/atoms/AlertMessage";
import Switch from "components/Input/Switch";
import { isTrue } from "helpers/utils/validators";
import useOrgDashboard from "store/dashboard/org";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const initValues = {
  users: [],
  resource_collections: [],
  resource_groups: [],
  resource_modules: [],
  challenge_paths: [],
  challenges: [],
  lab_programs: [],
  integrate_campus_connect: "job",
  campus_connect_story_media_type: "IMAGE",
  privacy: "no",
  is_notification_enabled: "no",
};

const CreateEditLab = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [socialLinks, setSocialLinks] = useState([]);
  const [experts, setExperts] = useState([]);
  const [challenges, setChallenges] = useState({
    all: [],
    selected: [],
  });
  const [resources, setResources] = useState({ all: [], selected: [] });
  const [isLiveEventVerifed, setLiveEventVerified] = useState(false);
  const { fetchLab, editLab, createLab } = useLabs();
  const { fetchChallengeSelectList } = useChallenges();
  const { fetchResourceSelectList, fetchResourceGroupSelectList } =
    useResources();
  const { id } = useParams();
  const editMode = !!id;
  const [initLoading, setInitLoading] = useState(Boolean(editMode));
  const { fetchAssociation } = useAssociation();
  const sliceSchema = (_schema) => stepSchema(currentStep);
  const { getSubscriptionDetails } = useOrgDashboard();
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const { t } = useLocalizedTranslation();

  const { data, errors, validate, onChange, setData } = useForms(
    schema,
    initValues,
    sliceSchema
  );

  useEffect(() => {
    if (editMode) handleFetchDetail();
    else {
      setCurrentStep(0);
      setData(initValues);
      setSocialLinks([]);
      setChallenges({
        all: [],
        selected: [],
      });
      setResources({ all: [], selected: [] });
    }
  }, [id, editMode]);

  useEffect(() => {
    handleFetchChallenges();
    handleFetchResources();
  }, [editMode]);

  const handleFetchChallenges = async () => {
    const res = await fetchChallengeSelectList();
    const res2 = await fetchChallengeSelectList({}, true);
    setChallenges({
      all: [
        ...res?.map((d) => ({ ...d, type: COMPONENT_TYPES.CHALLENGE })),
        ...res2?.map((d) => ({
          ...d,
          uuid: d?.id,
          type: COMPONENT_TYPES.CHALLENGE_PATH,
        })),
      ],
      selected: [],
    });
  };

  const debounceChallenge = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const onSearchChallenges = async (search, tabs) => {
    let challenge = [];
    let path = [];

    if (tabs === 0 || tabs === 1) {
      challenge = await fetchChallengeSelectList({ search });
    }
    if (tabs === 0 || tabs === 2) {
      path = await fetchChallengeSelectList({ search }, true);
    }

    setChallenges((prev) => ({
      all: [
        ...challenge?.map((item) => ({
          ...item,
          type: COMPONENT_TYPES.CHALLENGE,
        })),
        ...path?.map((item) => ({
          ...item,
          uuid: item?.id,
          type: COMPONENT_TYPES.CHALLENGE_PATH,
        })),
      ],
      selected: prev.selected,
    }));
  };
  const debouncedSearchChallenge = debounceChallenge(onSearchChallenges, 1000);

  const handleFetchResources = async () => {
    const res = await fetchResourceSelectList();
    const res2 = await fetchResourceSelectList(true);
    const res3 = await fetchResourceGroupSelectList();
    setResources({
      all: [
        ...res?.map((item) => ({ ...item, type: "module" })),
        ...res2?.map((item) => ({ ...item, type: "collection" })),
        ...res3?.map((item) => ({ ...item, type: "group" })),
      ],
      selected: [],
    });
  };

  const debounceResource = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const onSearchResources = async (search, tab) => {
    let res = [];
    let res2 = [];
    let res3 = [];

    if (tab === 0 || tab === 1) {
      res = await fetchResourceSelectList(false, { search });
    }
    if (tab === 0 || tab === 2) {
      res2 = await fetchResourceSelectList(true, { search });
    }
    if (tab === 0 || tab === 3) {
      res3 = await fetchResourceGroupSelectList({ search });
    }

    setResources((prev) => ({
      all: [
        ...res?.map((item) => ({ ...item, type: "module" })),
        ...res2?.map((item) => ({ ...item, type: "collection" })),
        ...res3?.map((item) => ({ ...item, type: "group" })),
      ],
      selected: prev.selected,
    }));
  };
  const debouncedSearchResources = debounceResource(onSearchResources, 1000);

  const handleFetchDetail = async () => {
    const res = await fetchLab(id);
    if (res) {
      const {
        title,
        description,
        privacy,
        skills = {},
        skill_groups = {},
        skill_stacks = {},
        type,
        slug,
        category_id,
        level_id,
        duration_id,
        id: uuid,
        media,
        media_type,
        status,
        address,
        external_links,
        level,
        category,
        duration,
        achievement = {},
        campus_connect_opportunity,
        campus_connect_story,
        campus_connect_status,
        is_live_event_enabled,
        live_event,
        mode,
        is_notification_enabled,
        is_achievement_enabled,
        expert_references,
      } = res;
      setData({
        title,
        description,
        slug,
        skills: payloadToArrayKeyValues(skills),
        skill_groups: payloadToArrayKeyValues(skill_groups),
        skill_stacks: payloadToArrayKeyValues(skill_stacks),
        category_id: keyVal(category, category_id),
        level_id: keyVal(level, level_id),
        duration_id: keyVal(duration, duration_id),
        id: uuid,
        status,
        cover_image: media,
        media_type,
        location: address,
        type,
        mode,
        is_achievement_enabled: is_achievement_enabled === "yes" ? true : false,
        is_notification_enabled: payloadBool(is_notification_enabled),
        ...achievement,
        achievement_conditions: payloadToArrayKeyValues(
          achievement?.achievement_condition
        ),
        ...initValues,
        privacy,
        ...populateCampusConnectInitialValue(
          campus_connect_opportunity,
          campus_connect_story,
          campus_connect_status
        ),
        is_live_event_enabled: is_live_event_enabled === "yes",
        live_event_url: live_event?.url || "",
      });

      const [labPrograms] = await fetchAssociation(
        COMPONENT_TYPES.LAB,
        id,
        COMPONENT_TYPES.LAB_PROGRAMS
      );

      setData((prevData) => ({
        ...prevData,
        lab_programs: keyValueFormat(labPrograms) || [],
      }));

      setExperts(expert_references || []);

      const [challenges] = await fetchAssociation(
        COMPONENT_TYPES.LAB,
        id,
        COMPONENT_TYPES.CHALLENGE
      );
      const [challengePaths] = await fetchAssociation(
        COMPONENT_TYPES.LAB,
        id,
        COMPONENT_TYPES.CHALLENGE_PATH
      );
      const [resourceModules] = await fetchAssociation(
        COMPONENT_TYPES.LAB,
        id,
        ENDPOINTS.RESOURCE_MODULE
      );
      const [resourceCollections] = await fetchAssociation(
        COMPONENT_TYPES.LAB,
        id,
        COMPONENT_TYPES.RESOURCE_COLLECTION
      );
      const [resourcegroups] = await fetchAssociation(
        COMPONENT_TYPES.LAB,
        id,
        COMPONENT_TYPES.RESOURCE_GROUP
      );

      setResources((val) => ({
        all: val.all,
        selected: [
          ...(resourceModules || []).map((item) => ({
            uuid: item.id,
            title: item.title,
            media: item.cover_image,
            type: "module",
          })),
          ...(resourceCollections || []).map((item) => ({
            uuid: item.id,
            title: item.title,
            media: item.cover_image,
            type: "collection",
          })),
          ...(resourcegroups || []).map((item) => ({
            uuid: item.id,
            title: item.title,
            media: item.cover_image,
            type: "group",
          })),
        ],
      }));

      setChallenges((prevChallenges) => ({
        ...prevChallenges,
        selected: [
          ...(challenges || [])?.map((item) => ({
            uuid: item.id,
            title: item.title,
            media: item.media,
            type: COMPONENT_TYPES.CHALLENGE,
          })),
          ...(challengePaths || [])?.map((item) => ({
            uuid: item.id,
            title: item.title,
            media: item.media,
            type: COMPONENT_TYPES.CHALLENGE_PATH,
          })),
        ],
      }));

      setSocialLinks(
        external_links.map(({ link_id, link, title }) => ({
          url: link,
          type: keyVal(title, link_id),
        })) || []
      );
      setInitLoading(false);
    }
  };
  const handleSaveOrEdit = async (isSaveAndExit) => {
    if (editMode && isSaveAndExit) {
      const _errors = stepValidate(await validate());
      if (_errors.size) return;
    }

    if (editMode && isSaveAndExit && currentStep === 0) {
      if (isTrue(data?.is_achievement_enabled)) {
        const achievementErrors = validateAchievements(data, true, true, t);
        if (achievementErrors) {
          toast.warning(
            "Please fill all the required achievement details before saving!"
          );
          setCurrentStep(1);
          return;
        }
      }
    }
    const salaryPaymentFrequencyMapping = { zero: 0, one: 1, two: 2, three: 3 };

    const payload = {
      ...data,
      ...(data?.location || {}),
      location:
        typeof data?.location === "string"
          ? data?.location
          : formatAddress(data?.location),
      external_links: payloadFormatArray(socialLinks, "url"),
      external_link_ids: payloadFormatArray(socialLinks, "type"),
      challenges: challenges?.selected
        .filter((d) => d.type === COMPONENT_TYPES.CHALLENGE)
        .map((challenge) => challenge.uuid),
      challenge_paths: challenges?.selected
        .filter((d) => d.type === COMPONENT_TYPES.CHALLENGE_PATH)
        .map((challenge) => challenge.uuid),
      resource_modules: resources?.selected
        .filter((d) => d.type === "module")
        .map((d) => d.uuid),
      resource_collections: resources?.selected
        .filter((d) => d.type === "collection")
        .map((d) => d.uuid),
      resource_groups: resources?.selected
        .filter((d) => d.type === "group")
        .map((d) => d.uuid),
      integrate_campus_connect: data?.campus_connect
        ? data?.integrate_campus_connect
        : "no",
      campus_connect_salary_payment_frequency:
        salaryPaymentFrequencyMapping[
          data?.campus_connect_salary_payment_frequency
        ],
      campus_connect_has_image_file: Boolean(
        data?.campus_connect_story_image instanceof File
      ),
      is_live_event_enabled: data?.is_live_event_enabled ? "yes" : "no",
      ...(data?.is_live_event_enabled
        ? {
            "live_event[url]": data?.live_event_url,
            "live_event[is_verified]": isLiveEventVerifed ? "yes" : "no",
          }
        : {}),
      media_type:
        data?.iframe_code ||
        (typeof data?.cover_image === "string" &&
          data?.cover_image?.startsWith("<iframe"))
          ? "embedded"
          : "image",
      cover_image: data?.iframe_code ? data?.iframe_code : data?.cover_image,
      is_achievement_enabled: data?.is_achievement_enabled ? "yes" : "no",
      expert_references: experts?.map((d) => d?.email),
    };

    payloadKeyVal(payload, ["level_id", "duration_id", "category_id"]);
    payloadKeyVals(payload, [
      "skills",
      "skill_groups",
      "skill_stacks",
      "external_link_ids",
      "achievement_conditions",
      "lab_programs",
    ]);
    payloadGetVal(payload, ["campus_connect_schools"]);
    const deleteVals = [
      "showLab",
      "users",
      "associatedPrograms",
      "organization",
      "address_1",
      "zip_code",
      "zip",
      "campus_connect",
      "address",
      "iframe_code",
    ];
    if (!data?.category_id?.key) {
      deleteVals.push("category_id");
    }
    if (!data.skill_groups || !data.skill_groups.length) {
      deleteVals.push("skill_groups");
    }
    if (!data.skill_stacks || !data.skill_stacks.length) {
      deleteVals.push("skill_stacks");
    }
    if (!editMode && !payload.cover_image) {
      deleteVals.push(...["media_type", "cover_image"]);
    }
    if (editMode) {
      if (
        !payload.cover_image ||
        (payload.media_type !== "embedded" &&
          typeof payload.cover_image === "string")
      ) {
        deleteVals.push("cover_image");
      }
      if (typeof payload?.achievement_image === "string")
        deleteVals.push("achievement_image");
    }
    deleteValues(payload, deleteVals);
    setLoading(true);
    editMode ? await editLab(id, payload) : await createLab(payload);
    setLoading(false);
  };

  const onCancel = () => {
    setCurrentStep(currentStep - 1);
  };

  const stepValidate = (errors) => {
    if (currentStep === 4) {
      return removeHiddenFieldValidationError(errors, data);
    }
    if (currentStep === 5) {
      return removeLiveEventValidation(errors, data);
    }
    return errors;
  };
  const onContinue = async () => {
    const _errors = stepValidate(await validate());
    if (currentStep === 0 && socialLinks.length > 0) {
      const linkErrors = validateExternalLinks(socialLinks, t);
      if (linkErrors) return;
    }

    if (currentStep === 1 && data?.is_achievement_enabled) {
      const achievementErrors = validateAchievements(data, true, true, t);
      if (achievementErrors) return;
    }
    if (!_errors.size) {
      if (currentStep === 5) {
        if (data?.is_live_event_enabled && !isLiveEventVerifed) {
          toast.error(t("The event must be linked!"));
          return;
        }

        handleSaveOrEdit();
      } else {
        setCurrentStep((val) => val + 1);
      }
    }
  };

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  const fetchPlanDetails = async () => {
    const res = await getSubscriptionDetails();
    if (res) {
      setSubscriptionDetails(res);
    }
  };

  return (
    <DashboardLayout loading={initLoading}>
      <Box className="w-full">
        <EditCreateHeaderCard
          current={currentStep}
          subtitles={create_lab_steps}
          title={`${editMode ? "EDIT YOUR" : "CREATE A"} LAB`}
          classes="mt-header"
          length={create_lab_steps?.length}
          rounded
          numbered
        />
        {currentStep === 1 && (
          <div className="mx-auto" style={{ width: "min(1300px,90%)" }}>
            <AlertMessage
              severity="warning"
              title={t(
                "The following sections will be locked after the first submission:"
              )}
              description={<li>{t("Achievements")}</li>}
              classes="text-left mt-2"
            />
          </div>
        )}
        <Card classes="my-5 container" width="min(1300px,90%)" centered>
          {currentStep === 0 && (
            <LabDetails
              data={data}
              errors={errors}
              onChange={onChange}
              step={currentStep}
              socialLinks={socialLinks}
              setSocialLinks={setSocialLinks}
              editMode={editMode}
              experts={experts}
              setExperts={setExperts}
            />
          )}
          {currentStep === 1 && (
            <Grid
              className={
                subscriptionDetails?.plan === "seed_plan_yearly"
                  ? "opacity-50"
                  : ""
              }
              container
              px={{ xs: 0, md: 4 }}
            >
              <PageTitle>{t("Achievements")}</PageTitle>
              <Card classes={"w-full my-4"}>
                <div>
                  <h3>{t("Enable Achievement")}</h3>
                  <Switch
                    name={achievement_schema(false, true, true)[0].name}
                    value={data[achievement_schema(false, true, true)[0].name]}
                    error={errors.get(
                      achievement_schema(false, true, true)[0].name
                    )}
                    onChange={onChange}
                    label={
                      <span className="fs-12">
                        {t("Enable users to get trophies")}
                      </span>
                    }
                    disabled={subscriptionDetails?.plan === "seed_plan_yearly"}
                  />
                </div>
              </Card>
              {data?.is_achievement_enabled && (
                <CreateFormSection classes="w-full mt-4">
                  <AddAchievementSection
                    data={data}
                    errors={errors}
                    onChange={onChange}
                    showAchievementConditions
                    showAchivementEnabled
                    disabled={subscriptionDetails?.plan === "seed_plan_yearly"}
                  />
                </CreateFormSection>
              )}
            </Grid>
          )}
          {currentStep === 2 && (
            <AssociatedListing
              onSearch={debouncedSearchChallenge}
              title="Browse Challenges to add to your Lab"
              subTitle="You may only associate challenges with the same visibility as the Lab."
              component="Challenge or Challenge path"
              tabs={["All", "Challenges", "Challenge Paths"]}
              list={challenges}
              setList={setChallenges}
              type={COMPONENT_TYPES.CHALLENGE}
              filterByTab={(item, tab) =>
                tab === 0 ||
                (tab === 1 && item.type === COMPONENT_TYPES.CHALLENGE) ||
                (tab === 2 && item.type === COMPONENT_TYPES.CHALLENGE_PATH)
              }
              isSequential={isTrue(data?.is_sequential)}
              setSequential={(val) =>
                onChange(createEvent("is_sequential", payloadBool(val)))
              }
            />
          )}
          {currentStep === 3 && (
            <AssociatedListing
              onSearch={debouncedSearchResources}
              title="Browse Resources to add to your Lab"
              subTitle="You may only associate resources with the same visibility as the Lab."
              component="resources"
              tabs={["All", "Resource", "Collection", "Group"]}
              list={resources}
              setList={setResources}
              type={COMPONENT_TYPES.RESOURCE}
              filterByTab={(item, tab) =>
                tab === 0 ||
                (tab === 1 && item.type === "module") ||
                (tab === 2 && item.type === "collection") ||
                (tab === 3 && item.type === "group")
              }
              isSequential={isTrue(data?.is_resource_sequential)}
              setSequential={(val) =>
                onChange(
                  createEvent("is_resource_sequential", payloadBool(val))
                )
              }
            />
          )}

          {currentStep === 4 && (
            <CampusConnectIntegration
              data={data}
              errors={errors}
              onChange={onChange}
              formSchema={stepSchema(currentStep)}
              component={COMPONENT_TYPES.LAB}
            />
          )}

          <LiveEvent
            editMode={editMode}
            validate={validate}
            isLiveEventVerifed={isLiveEventVerifed}
            setLiveEventVerified={setLiveEventVerified}
            data={data}
            errors={errors}
            onChange={onChange}
            formSchema={stepSchema(currentStep)}
            show={currentStep === 5}
            t={t}
          />

          <CreateFormFooter
            step={currentStep}
            stepsLength={create_lab_steps?.length}
            onCancel={onCancel}
            displayMiddleButton={
              !!editMode && currentStep !== create_lab_steps?.length - 1
            }
            onMiddleButtonClick={handleSaveOrEdit}
            disabled={!!errors.size}
            loading={!editMode && loading}
            onOk={onContinue}
            editMode={editMode}
            middleButtonLoading={editMode && loading}
            okButtonColor="blue"
            backButtonColor="blue"
            confirmationOkBtnTitle="Exit Now"
            okButtonTitle={
              editMode &&
              currentStep === create_lab_steps?.length - 1 &&
              "Save & Exit"
            }
          />
        </Card>
      </Box>
    </DashboardLayout>
  );
};

export default CreateEditLab;
