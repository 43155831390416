import { Cancel, Lock } from "@mui/icons-material";
import { COLORS } from "helpers/utils/constants";

export const Free = () => {
  return (
    <span data-testid="free" className='fs-14 fw-500 rounded-5 py-0 px-1 c-primary' style={{border: `1px solid ${COLORS.BLUE}`}}>
      FREE
    </span>
  );
};

export const RedCross = ({ classes }) => {
  return <Cancel data-testid="cross" color="error" className={classes} />;
};

export const Dot = ({ color, classes }) => {
  return (
    <div
      data-testid="dot"
      style={{ background: color }}
      className={`h-10 w-10 rounded-100 bg-primary ${classes}`}
    ></div>
  );
};

export const LockIcon = ({classes}) => {
  return(
      <Lock data-testid="lock" fontSize="small" color="warning"  className={`mx-1 opacity-60 ${classes}`}/>
  );
}
