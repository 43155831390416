import DashboardLayout from "pages/layouts/dashboard";
import React, { useEffect, useState } from "react";
import ResourceGroupMainPage from "./components/ResourceGroupMainPage";
import { useParams, useSearchParams } from "react-router-dom";
import useResourceGroups from "store/resource-groups/service-hook";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";

const ResourceGroup = () => {
  const [resourceG, setResourceG] = useState(null);
  const { id } = useParams();
  const { fetchResourceGroup, fetchMyResourceGroup, deleteResourceGroup } =
    useResourceGroups();
  const [params] = useSearchParams();
  const isPrivateView = params.get("private") === "true";
  const { t } = useLocalizedTranslation();

  useEffect(() => {
    handleFetchDetail();
  }, []);

  const handleFetchDetail = async () => {
    const res = isPrivateView
      ? await fetchMyResourceGroup(id)
      : await fetchResourceGroup(id);
    if (!res) {
      setResourceG({ error: true });
      return;
    }
    setResourceG(res);
  };

  return (
    <DashboardLayout
      loading={!resourceG}
      isEmpty={!!resourceG?.error}
      emptyMessage={t("Not able to access the resource group!")}
    >
      <ResourceGroupMainPage
        resourceG={resourceG}
        isPrivate={isPrivateView}
        setResourceG={setResourceG}
        onDelete={deleteResourceGroup}
        t={t}
      />
    </DashboardLayout>
  );
};

export default ResourceGroup;
