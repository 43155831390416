import { render, screen, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import useMaster from "./service-hook";
import { Provider } from "react-redux";
import * as axios from "axios";
import { REDUCER_TYPES } from "../index";
import ACTIONS from "./action-types";
import { mockRequestGet, mockRequestPost } from "../mocks/request";
import { dispatchMock, initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import { CONTENT_TYPES } from "store/api";
let getSpy;
let postSpy;

jest.spyOn(axios, "get").mockResolvedValue({ data: { data: true } });

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
  },
  [REDUCER_TYPES.MASTER]: {},

});

const headers = {
  Authorization: "Bearer 1",
  "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
};
const dispatch = dispatchMock(store);

const MasterHookCompMock = () => {
  const {
    fetchSocialLinks,
    fetchLabConditions,
    fetchPitchTemplates,
    fetchFlexibleDDs,
    fetchHosts,
    fetchRanks,
    fetchAchievementConditions,
    fetchProjectSubmissions,
    fetchSkillGroups,
    fetchSkillSets,
    fetchCategories,
    fetchSkills,
    fetchTags,
    fetchIndustries,
    fetchTypes,
    fetchStages,
    fetchVerticals,
    fetchProjectStatus,
    fetchSocialConnect,
    fetchDurations,
    fetchLevel,
    fetchChallengePitchTask,
    createSponser,
    fetchTagGroups,
    fetchChallengeRecipient,
    fetchJobs,
    fetchBusinessChallengeTacklings,
    fetchCampusConnectSchools
  } = useMaster();

  const BUTTONS_LINKS = [
    { name: "fetchSocialLinks", onClick: () => fetchSocialLinks("") },
    { name: "fetchLabConditions", onClick: () => fetchLabConditions("") },
    { name: "fetchPitchTemplates", onClick: () => fetchPitchTemplates("") },
    { name: "fetchFlexibleDDs", onClick: () => fetchFlexibleDDs("") },
    { name: "fetchHosts", onClick: () => fetchHosts("") },
    { name: "fetchRanks", onClick: () => fetchRanks("") },
    {
      name: "fetchAchievementConditions",
      onClick: () => fetchAchievementConditions(""),
    },
    {
      name: "fetchProjectSubmissions",
      onClick: () => fetchProjectSubmissions(""),
    },
    { name: "fetchSkillGroups", onClick: () => fetchSkillGroups("") },
    { name: "fetchSkillSets", onClick: () => fetchSkillSets("") },
    { name: "fetchCategories", onClick: () => fetchCategories("") },
    { name: "fetchSkills", onClick: () => fetchSkills("") },
    { name: "fetchTags", onClick: () => fetchTags("") },
    { name: "fetchIndustries", onClick: () => fetchIndustries("") },
    { name: "fetchTypes", onClick: () => fetchTypes("") },
    { name: "fetchStages", onClick: () => fetchStages("") },
    { name: "fetchVerticals", onClick: () => fetchVerticals("") },
    { name: "fetchProjectStatus", onClick: () => fetchProjectStatus("") },
    { name: "fetchSocialConnect", onClick: () => fetchSocialConnect("") },
    { name: "fetchDurations", onClick: () => fetchDurations("") },
    { name: "fetchLevel", onClick: () => fetchLevel("") },
    { name: "fetchChallengePitchTask", onClick: () => fetchChallengePitchTask("templateId", "") },
    { name: "createSponser", onClick: () => createSponser("Sponser Title", "Sponser Link", "Sponser Image") },
    { name: "fetchTagGroups", onClick: () => fetchTagGroups("") },
    { name: "fetchChallengeRecipient", onClick: () => fetchChallengeRecipient("")},
    { name: "fetchJobs", onClick: () => fetchJobs("") },
    { name: "fetchBusinessChallengeTacklings", onClick: () => fetchBusinessChallengeTacklings("") },
    { name: "fetchCampusConnectSchools", onClick: () => fetchCampusConnectSchools() }
  ];

  return (
    <div>
      {BUTTONS_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

describe("test master hook services", () => {
  beforeEach(() => {
    render(
      <Provider store={store}>
        <MasterHookCompMock />
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
  });

  test("fetchSocialLinks", async () => {
    const button = screen.getByTestId("fetchSocialLinks");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.SOCIAL_LINK}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.SOCIAL_LINKS}`,
      })
    );
  });

  test("fetchLabConditions", async () => {
    const button = screen.getByTestId("fetchLabConditions");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.LAB_CONDITION}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.LAB_CONDITIONS}`,
      })
    );
  });

  test("fetchPitchTemplates", async () => {
    const button = screen.getByTestId("fetchPitchTemplates");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.PITCH_TEMPLATE}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.PITCH_TEMPLATE}`,
      })
    );
  });

  test("fetchFlexibleDDs", async () => {
    const button = screen.getByTestId("fetchFlexibleDDs");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.FLEXIBLE_DATE_DURATION}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.FLEXIBLE_DATE_DURATION}`,
      })
    );
  });

  test("fetchHosts", async () => {
    const button = screen.getByTestId("fetchHosts");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.HOST}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.HOST}`,
      })
    );
  });

  test("fetchAchievementConditions", async () => {
    const button = screen.getByTestId("fetchAchievementConditions");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.ACHIEVEMENT_CONDITION}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.ACHEIVEMENT_CONDITIONS}`,
      })
    );
  });

  test("fetchProjectSubmissions", async () => {
    const button = screen.getByTestId("fetchProjectSubmissions");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.PROJECT_SUBMISSION}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.PROJECT_SUBMISSION}`,
      })
    );
  });

  test("fetchSkillGroups", async () => {
    const button = screen.getByTestId("fetchSkillGroups");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.SKILL_GROUP}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.SKILL_GROUPS}`,
      })
    );
  });

  test("fetchRanks", async () => {
    const button = screen.getByTestId("fetchRanks");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.RANKS}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.RANKS}`,
      })
    );
  });

  test("fetchSkillSets", async () => {
    const button = screen.getByTestId("fetchSkillSets");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.SKILL_SET}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.SKILL_SETS}`,
      })
    );
  });

  test("fetchCategories", async () => {
    const button = screen.getByTestId("fetchCategories");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.CATEGORIES}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.CATEGORIES}`,
      })
    );
  });

  test("fetchSkills", async () => {
    const button = screen.getByTestId("fetchSkills");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.SKILLS}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.SKILLS}`,
      })
    );
  });

  test("fetchTags", async () => {
    const button = screen.getByTestId("fetchTags");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.TAGS}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.TAGS}`,
      })
    );
  });

  test("fetchIndustries", async () => {
    const button = screen.getByTestId("fetchIndustries");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.INDUSTRIES}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.INDUSTRIES}`,
      })
    );
  });

  test("fetchTypes", async () => {
    const button = screen.getByTestId("fetchTypes");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.TYPES}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.TYPES}`,
      })
    );
  });

  test("fetchStages", async () => {
    const button = screen.getByTestId("fetchStages");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.STAGES}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.STAGES}`,
      })
    );
  });

  test("fetchVerticals", async () => {
    const button = screen.getByTestId("fetchVerticals");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.VERTICALS}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.VERTICALS}`,
      })
    );
  });

  test("fetchProjectStatus", async () => {
    const button = screen.getByTestId("fetchProjectStatus");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.PROJECT_STATUS}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.PROJECT_STATUS}`,
      })
    );
  });

  test("fetchSocialConnect", async () => {
    const button = screen.getByTestId("fetchSocialConnect");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.SOCIAL_CONNECT}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.SOCIAL_CONNECT}`,
      })
    );
  });

  test("fetchDurations", async () => {
    const button = screen.getByTestId("fetchDurations");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.DURATIONS}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.DURATION}`,
      })
    );
  });

  test("fetchLevel", async () => {
    const button = screen.getByTestId("fetchLevel");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.LEVELS}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.LEVEL}`,
      })
    );
  });

  test("fetchChallengePitchTask", async () => {
    const button = screen.getByTestId("fetchChallengePitchTask");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.CHALLENGE_PITCH_TASK}?search=&template_id=templateId&language=en`
      )
    );
  });

  test("createSponser", async () => {
    const button = screen.getByTestId("createSponser");
    userEvent.click(button);
    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.CREATE_SPONSER}?&language=en`,
        { title: "Sponser Title", link: "Sponser Link", image: "Sponser Image" },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
      )
    );
  });

  test("fetchTagGroups", async () => {
    const button = screen.getByTestId("fetchTagGroups");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.TAG_GROUPS}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.TAG_GROUPS}`,
      })
    );
  });
  
  test("fetchChallengeRecipient", async () => {
    const button = screen.getByTestId("fetchChallengeRecipient");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.ANNOUNCEMENT_RECIPIENT}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.ANNOUNCEMENT_RECIPIENT}`,
      })
    );
  });

  test("fetchJobs", async () => {
    const button = screen.getByTestId("fetchJobs");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.JOB_TITLES}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.JOB_TITLES}`,
      })
    );
  });

  test("fetchBusinessChallengeTacklings", async () => {
    const button = screen.getByTestId("fetchBusinessChallengeTacklings");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.COMMON_MASTER}/${ENDPOINTS.BUSINESS_CHALLENGE_TACKLINGS}?search=&language=en`,
        {},
        {},
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
    await waitFor(() => expect(dispatch).toHaveBeenCalledTimes(1));
    await waitFor(() =>
      expect(dispatch).toHaveBeenCalledWith({
        payload: [{}],
        type: `${ENDPOINTS.COMMON_MASTER}/${ACTIONS.BUSINESS_CHALLENGE_TACKLINGS}`,
      })
    );
  });

  test("fetchCampusConnectSchools", async () => {
    const button = screen.getByTestId("fetchCampusConnectSchools");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.CAMPUS_CONNECT}/${ENDPOINTS.SCHOOLS}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        } 
      )
    );
  });
});
