import { Delete, Edit } from "@mui/icons-material";
import { Grid, Input } from "@mui/material";
import { org } from "assets/svg/yellow";
import moment from "moment";
import React, { useEffect, useState } from "react";

const Experience = ({
  data,
  id,
  setExperience,
  editMode: initialEditMode,
  t = (d) => d,
}) => {
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setEditMode(initialEditMode);
  }, [initialEditMode]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setExperience((prev) => {
      return prev.map((d) => {
        if (d?.id === id) {
          d[name] = value;
        }
        return d;
      });
    });
  };

  const handleDelete = (id) => {
    setExperience((prev) => {
      return prev.filter((d) => d?.id !== id);
    });
  };

  return editMode ? (
    <Grid container spacing={2} mb={2} className="text-left">
      <Grid item xs={1.1}>
        <img className="w-30 h-30 mt-1" alt="" src={org} />
      </Grid>
      <Grid item xs={2.3} className="fs-14 opacity-80">
        <p className="mt-1">{t("Position")}</p>
        <p className="mt-1">{t("Company")}</p>
        <p className="mt-3">{t("Duration")}</p>
      </Grid>
      <Grid item xs={8.6}>
        <Input
          name="position"
          onChange={handleOnChange}
          className="w-full fs-14 h-30 tracking-tight border-b c-gray"
          value={data?.position}
          disableUnderline
        />
        <Input
          name="company"
          onChange={handleOnChange}
          className="w-full fs-14 h-30 mb-1 border-b c-gray"
          value={data?.company}
          disableUnderline
        />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={1.2} className="fs-13 tracking-tight c-gray">
            {t("From")}
          </Grid>
          <Grid item xs={5}>
            <Input
              disableUnderline
              placeholder="Start Date"
              type="date"
              name="start_date"
              value={moment(data?.start_date).format("YYYY-MM-DD")}
              onChange={handleOnChange}
              className="fs-13 ml-1 w-full border c-gray pl-1 rounded-5"
              t={t}
            />
          </Grid>
          <Grid item xs={0.8} className="fs-13 tracking-tight c-gray">
            {t("To")}
          </Grid>
          <Grid item xs={5}>
            <Input
              type="date"
              disableUnderline
              name="end_date"
              placeholder="End Date"
              value={moment(data?.end_date).format("YYYY-MM-DD")}
              onChange={handleOnChange}
              className="fs-13 border c-gray pl-1 w-full rounded-5"
              t={t}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2} mb={2} className="text-left w-full">
      <Grid item xs={10} className="flex">
        <img src={org} alt="" className="w-30 h-30 mt-1" />
        <div className="flex-column ml-3">
          <h3>{data?.position}</h3>
          <p className="c-gray fs-13">{data?.company}</p>
          <p className="c-gray fs-13">
            {moment(data?.start_date).format("MMMM YYYY")} -{" "}
            {moment(data?.end_date).format("MMMM YYYY")}
          </p>
        </div>
      </Grid>
      <Grid item xs={2} className="flex justify-end cursor-pointer mt-2">
        <Edit
          fontSize="small"
          className="mr-2"
          onClick={() => setEditMode(true)}
        />
        <Delete
          fontSize="small"
          onClick={() => handleDelete(data?.id)}
          className="c-error"
        />
      </Grid>
    </Grid>
  );
};

export default Experience;
