import { Skeleton } from "@mui/material";
import Card from "components/Cards/Card";

const ExploreSkillCard = () => {
    return (
        <Card classes="flex justify-center-start-mob px-1 py-2">
          <Skeleton variant="circular" width={40} height={40} />
          <div className="ml-5">
            <Skeleton variant="text" width="70%" height={24} />
            <div className="flex justify-between mt-1">
              <div className="flex-center">
                <Skeleton variant="rectangular" width={20} height={20} />
                <Skeleton variant="text" width={100} height={20} style={{ marginLeft: 8 }} />
              </div>
              <div className="flex-center ml-3">
                <Skeleton variant="rectangular" width={20} height={20} />
                <Skeleton variant="text" width={60} height={20} style={{ marginLeft: 8 }} />
              </div>
            </div>
          </div>
        </Card>
      );
}
export default ExploreSkillCard