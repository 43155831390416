import SchemaUIExtractor from 'components/UI/organisms/SchemaUIExtractor';
import React from 'react'
import useSettings from 'store/settings/service-hook';
import { createEvent } from "helpers/utils/utilities";

const UploadScorm = ({data, errors, onChange, schema, setScormLoading}) => {
  const [updatedSchema, setUpdatedSchema] = React.useState([{ ...schema[0], loading: false }]);
  const {preSignedUrlScorm, handleFileUpload} = useSettings();

  /**
   * Handle file upload action.
   * @param {File} file the file that is uploaded
   */
  const handleFileChange = async (file, name) => {
    setScormLoading(true);
    setUpdatedSchema((prevSchema) => [{ ...prevSchema[0], loading: true }]);
    const { url, uuid } = await preSignedUrlScorm({
      filename: file.name,
      content_type: file.type,
    });

    if (url) {
      await handleFileUpload(url, file);
      onChange(createEvent(name, file));
      onChange(createEvent('scorm_uuid', uuid));
      setScormLoading(false);
      setUpdatedSchema((prevSchema) => [{ ...prevSchema[0], loading: false }]);
    }
  };

  /**
   * Handles file upload action
   * @param {{ target: HTMLInputElement }} event - upload event
   */
  const handleUploadFiles = ({ target: { name, value: file } }) => {
    try {
      if (file) {
        handleFileChange(file, name);
      }else{
        onChange(createEvent(name, null));
        onChange(createEvent('scorm_uuid', null));
      }
    } catch (error) {
      setScormLoading(false);
      setUpdatedSchema((prevSchema) => [{ ...prevSchema[0], loading: false }]);
    }
  };


  return (
    <SchemaUIExtractor
      schema={updatedSchema}
      data={data}
      errors={errors}
      onChange={(e) => handleUploadFiles(e)}
      dataKey="scorm"
    />
  )
}

export default UploadScorm;