import React from "react";
import SecureImage from "components/UI/atoms/SecureImage";
import { getIconForFile } from "../../pages/resources/ResourceModule/components/ResourceFiles";
import { IconButton, ImageList, ImageListItem } from "@mui/material";
import { Delete, Visibility, Download } from "@mui/icons-material";

const ImageGrid = ({
  files,
  perRow,
  imageHeight,
  allowFullView,
  onDelete = Function.prototype,
  showTitle,
  hidePreview,
  onDownload= Function.prototype,
  isViewAccessEnabled,
  hideDelete
}) => {
  return (
    <ImageList
      data-testid="image-grid"
      className="w-100p h-auto ml-5 overflowY-hidden"
      cols={perRow}
      rowHeight={imageHeight || 164}
      gap={1}
    >
      {files.map((d, i) => (
        <ImageListItem
          key={i}
          className="relative"
          sx={{
            "& .imageOverlay": { visibility: "hidden" },
            "&:hover .imageOverlay": { visibility: "visible" },
            height: imageHeight ? imageHeight : "164px",
          }}
        >
          <div
            className="imageOverlay flex justify-center items-center w-100p h-100p  absolute rounded-5 cursor-pointer"
            style={{ backgroundColor: "#222222b2", gap: "10px", zIndex: 9 }}
          >
            {!hidePreview ? (
              <IconButton
                sx={{ border: "1px solid #dadada50" }}
                onClick={() => !!allowFullView && window.open(d?.path)}
                className="opacity-100 c-white"
              >
                <Visibility />
              </IconButton>
            ) : (
              ""
            )}
            {!!onDelete && !hideDelete &&(
              <IconButton
                onClick={() => onDelete(d)}
                sx={{ border: "1px solid #dadada50" }}
                className="opacity-100 border"
              >
                <Delete color="error" />
              </IconButton>
            )}
             {isViewAccessEnabled && (
              <IconButton
                onClick={() => onDownload(d)}
                sx={{ border: "1px solid #dadada50" }}
                className="opacity-100 border"
              >
                <Download color="primary" />
              </IconButton>
            )}
          </div>
          <SecureImage
            width="100%"
            height="100%"
            classes="object-cover"
            src={d?.path}
            placeholder={getIconForFile(d.title)}
            alt="File Icon"
          />
          {!!showTitle && d?.title ? (
            <p className={"fs-14--noMob mt-2 text-center"}>{d?.title}</p>
          ) : (
            ""
          )}
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default ImageGrid;
