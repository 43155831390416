import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { REDUCER_TYPES } from "store";
import REQUEST from "store/api";
import { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { update, shutdown } from "@intercom/messenger-js-sdk";
import {
  setStorage,
  setToken,
  removeStorage,
  resolveResponse,
} from "store/api/utils";
import { REDUCER } from "./reducer";
import { REDUCER as USER_REDUCER } from "../auth/reducer";
import ACTIONS from "./action-types";
import USER_ACTIONS from "../auth/action-types";
import { useAuth0 } from "@auth0/auth0-react";
import { isTrue } from "helpers/utils/validators";
import { ROLES } from "helpers/utils/constants";
import { setLangStorage } from "store/api/utils";
import { getLastVisitedLocation } from "store/api/utils";
import { updateLastLocation } from "store/api/utils";

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state[REDUCER_TYPES.AUTH]);
  const { logout: l, isAuthenticated } = useAuth0();

  const handleAuthSucess = (data) => {
    setToken(data.token?.token || data?.token);
    setStorage(data.user);
    setLangStorage(data?.user?.preferred_language);
    update({
      user_id: data?.user?.id,
      name: data?.user?.full_name,
      email: data?.user?.email,
      created_at: data?.user?.created_at,
    });
    const lastLocation = getLastVisitedLocation();
    const route = Boolean(lastLocation)
      ? lastLocation
      : data?.user?.roles === ROLES.ORGANIZATION_OWNER
      ? isTrue(data?.user?.organization_details?.is_onboarding_completed)
        ? "/explore"
        : "/organization-onboarding"
      : isTrue(data?.user?.is_onboarding_completed)
      ? "/explore"
      : "/user-onboarding";

    dispatch(REDUCER[ACTIONS.SET_USER](data));
    updateLastLocation();
    navigate(route, { replace: true });
  };

  //USER LOGIN API
  const login = async (payload = {}) => {
    const body = {
      ...payload,
      device_platform: "andriod",
      language: state.language,
    };
    const onSuccess = (res) =>
      resolveResponse(
        res,
        (data) => {
          if (data?.code === 2 && data?.message?.includes("OTP")) {
            navigate(
              `/verify-otp?email=${encodeURIComponent(payload.email)}&mfa=true`,
              {
                replace: true,
              }
            );
            return;
          }
          handleAuthSucess(data);
        },
        "Successfully logged In!"
      );

    const onError = (err) => {
      if (
        Number(err?.status) === 401 &&
        err?.message?.toLowerCase()?.includes("verify")
      ) {
        navigate(
          `/verify-otp?email=${encodeURIComponent(
            payload.email
          )}&registerSuccess=true`,
          {
            replace: true,
          }
        );
      }
    };

    const client = new REQUEST();
    await client.post(
      `${ENDPOINTS.AUTH}/${ENDPOINTS.LOGIN}`,
      body,
      {},
      onSuccess,
      onError
    );
  };

  //SSO LOGIN API
  const ssoLogin = async (payload) => {
    if (!payload || !payload?.email) {
      toast.error("Cannot find user email with sso information!");
      return;
    }
    const onSuccess = (res) =>
      resolveResponse(
        res,
        (data) => {
          handleAuthSucess(data);
        },
        "Successfully logged In!"
      );

    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.AUTH}/${ENDPOINTS.SSO_LOGIN}?language=${state.language}`,
      payload,
      {
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    return response;
  };

  const magnetSSOLogin = async (payload) => {
    const client = new REQUEST();

    const onSuccess = (res) =>
      resolveResponse(
        res,
        (data) => {
          handleAuthSucess(data);
        },
        "Successfully logged In!"
      );

    const onError = (res) => {
      navigate("/login", { replace: true });
    };

    await client.post(
      `${ENDPOINTS.AUTH}/${ENDPOINTS.SSO_LOGIN}/magnet?language=${state.language}`,
      payload,
      {
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess,
      onError
    );
  };

  //USER REGISTER API
  const register = async (payload = {}) => {
    const body = {
      ...payload,
      device_platform: "web",
    };
    const onSuccess = (res) =>
      resolveResponse(
        res,
        (_data) =>
          navigate(
            `/verify-otp?email=${encodeURIComponent(
              payload.email
            )}&registerSuccess=true`,
            {
              replace: true,
            }
          ),
        "Successfully Registered!"
      );

    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.AUTH}/${ENDPOINTS.REGISTER}?language=${state.language}`,
      body,
      {
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    return response;
  };

  //VERIFY OTP API
  const verifyOtp = async (otp, email, isMFA) => {
    if (!email) {
      toast.error("Please provide an email!");
      return;
    }

    const body = { email, otp, language: state.language };
    const onSuccess = (res) =>
      resolveResponse(
        res,
        (data) => {
          handleAuthSucess(data);
        },
        "Verification success!"
      );

    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.AUTH}/${
        isMFA ? "two-factor-verification" : ENDPOINTS.VERIFY_OTP
      }`,
      body,
      {},
      onSuccess
    );
    return response;
  };

  //RESEND OTP API
  const resendOtp = async (email, isResetPass, isMFA) => {
    if (!email) {
      toast.error("Please provide an email!");
      return;
    }

    const body = { email, purpose: "verify_email" };
    if (isResetPass) body["purpose"] = "forget_password";
    if (isMFA) body["purpose"] = "two_factor_verification";

    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.AUTH}/send-otp?language=${state.language}`,
      body,
      {},
      resolveResponse
    );
    return response;
  };

  //FORGOT PASSWORD API
  const forgotPassword = async (email) => {
    if (!email) {
      toast.error("Please provide an email!");
      return;
    }
    const onSuccess = (resp) =>
      resolveResponse(resp, (_data) => {
        setTimeout(
          () =>
            navigate(
              `/verify-otp?email=${encodeURIComponent(
                email
              )}&reset-password=true`,
              {
                replace: true,
              }
            ),
          2000
        );
      });
    const body = { email, language: state.language };
    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.AUTH}/${ENDPOINTS.FORGOT_PASSWORD}`,
      body,
      {},
      onSuccess,
      Function.prototype,
      true
    );
    return response;
  };

  //RESET PASSWORD VERIFY OTP API
  const resetPassVerifyOtp = async (otp, email) => {
    const body = {
      otp,
      email,
      language: state.language,
    };
    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.AUTH}/verify-reset-code`,
      body,
      {},
      Function.prototype,
      true,
      true
    );
    return response;
  };

  //RESET PASSWORD API
  const resetPassword = async (email, passwords, token) => {
    const body = {
      email,
      password: passwords.newPassword,
      password_confirmation: passwords.confirmPassword,
      language: state.language,
    };

    const onSuccess = (resp) =>
      resolveResponse(
        resp,
        (_data) => {
          setTimeout(() => navigate(`/login`, { replace: true }), 2000);
        },
        "Password changed successfully!"
      );

    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.AUTH}/${ENDPOINTS.RESET_PASSWORD}`,
      body,
      {
        Authorization: "Bearer " + token,
      },
      onSuccess
    );
    return response;
  };

  //LOGOUT USER
  const logout = () => {
    shutdown();
    removeStorage();
    dispatch(REDUCER[ACTIONS.LOGOUT]());
    if (isAuthenticated)
      l({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    else window.location.replace("/");
  };

  //CHECK EMAIL API
  const checkEmail = async (email) => {
    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.AUTH}/${ENDPOINTS.CHECK_EMAIL}?language=${state.language}`,
      { email },
      {},
      () => {},
      () => {},
      true
    );
    return response;
  };

  //CHECK USERNAME API
  const checkUsername = async (username) => {
    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.AUTH}/${ENDPOINTS.CHECK_USERNAME}?language=${state.language}`,
      { username },
      {},
      () => {},
      () => {},
      true
    );
    return response;
  };

  //CHECK PHONE API
  const checkPhone = async (phone) => {
    const client = new REQUEST();
    const response = await client.post(
      `${ENDPOINTS.AUTH}/${ENDPOINTS.CHECK_PHONE}?language=${state.language}`,
      { phone_number: phone },
      {},
      () => {},
      () => {},
      true
    );
    return response;
  };

  //CHECK ORGANIZATION TITLE API
  const checkOrgTitle = async (title) => {
    const client = new REQUEST();
    const response = await client.get(
      `${ENDPOINTS.AUTH}/${ENDPOINTS.CHECK_TITLE}/${title}?language=${state.language}`,
      {},
      {},
      () => {},
      () => {},
      true
    );
    return response;
  };

  //CHANGE LANGUAGE
  const languageChange = async (lang, onlyClient) => {
    if (!lang) return;
    if (!onlyClient) {
      const client = new REQUEST();
      await client.post(
        `${ENDPOINTS.SETTINGS}/set-language`,
        {
          language: lang,
        },
        {
          Authorization: "Bearer " + state.token,
        },
        resolveResponse
      );
    }
    const updatedData = {
      ...state.user,
      preferred_language: lang,
    };
    setStorage(updatedData);
    setLangStorage(lang, Boolean(onlyClient));
    dispatch(REDUCER[ACTIONS.SET_LANGUAGE](lang));
  };

  //CHANGE ORGANIZATION
  const organizationChange = async (data) => {
    if (!data) return;
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.USER}/${ENDPOINTS.ORGANIZATION_PREFRENCE}/${data?.slug}?language=${state.language}`,
      {},
      {
        Authorization: "Bearer " + state.token,
      },
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data) {
      dispatch(REDUCER[ACTIONS.SET_ORGANIZATION](data));
      const updatedData = {
        ...state.user,
        organization_details: data,
        roles: data?.roles,
      };
      dispatch(USER_REDUCER[USER_ACTIONS.SET_USER]({ data: updatedData }));
      setStorage(updatedData);
      setTimeout(() => window.location.reload(), 100);
    }
  };

  const getOrganizationList = async (page = 1, search = "") => {
    if (page === 1 && !search && state.orgListResponse?.length) {
      return state.orgListResponse;
    }
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.USER}/${ENDPOINTS.GET_ORGANIZATION_LIST}?language=${state.language}&page=${page}&search=${search}`,
      {},
      {
        Authorization: "Bearer " + state.token,
      },
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      const { list, total_pages, total_count } = res.data?.data || {};
      if (page === 1 && !search) {
        dispatch(REDUCER[ACTIONS.ORG_LIST]([list, total_pages, total_count]));
      }
      return [list, total_pages, total_count];
    }
    return [];
  };

  const getOrganization = async () => {
    const handleError = (data) => {
      if (data?.statusCode === 401 && data?.error_code === "TOKEN_EXPIRED") {
        setTimeout(() => logout(), 2000);
      }
    };

    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.USER}/${ENDPOINTS.ORGANIZATION_PREFRENCE}?language=${state.language}`,
      {},
      {
        Authorization: "Bearer " + state.token,
      },
      Function.prototype,
      handleError,
      true
    );
    if (res?.data?.data) {
      dispatch(REDUCER[ACTIONS.SET_ORGANIZATION](res?.data?.data));
      if (
        res?.data?.data?.roles !== state?.user?.roles &&
        !!res?.data?.data?.roles
      ) {
        const updatedData = {
          ...state.user,
          organization_details: res?.data?.data,
          roles: res?.data?.data?.roles,
        };
        dispatch(USER_REDUCER[USER_ACTIONS.SET_USER]({ data: updatedData }));
        setStorage(updatedData);
        setTimeout(() => window.location.reload(), 100);
        return;
      }
      return res?.data?.data;
    }
  };

  const getCustomization = async (customUrl) => {
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.AUTH}/${ENDPOINTS.ORGANIZATION}/${customUrl}?language=${state.language}`,
      {},
      {
        Authorization: "Bearer " + state.token,
      },
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  return {
    state,
    login,
    ssoLogin,
    register,
    logout,
    verifyOtp,
    forgotPassword,
    resetPassword,
    checkEmail,
    checkUsername,
    checkPhone,
    languageChange,
    organizationChange,
    getOrganizationList,
    getOrganization,
    magnetSSOLogin,
    getCustomization,
    resendOtp,
    resetPassVerifyOtp,
    checkOrgTitle,
  };
};

export default useAuth;
