import BrowseOptionsMenu from "components/UI/molecules/BrowseOptionsMenu";
import PageTitleCard from "components/UI/molecules/PageTitleCard";
import ListingPageHeader from "components/UI/organisms/ListingPageHeader";
import FilterCard from "components/UI/organisms/FilterCard";
import DashboardLayout from "pages/layouts/dashboard";
import MainPageLayout from "pages/layouts/MainPage";
import React, { useState } from "react";
import { FILTER_TYPES } from "helpers/hooks/useFilters";
import PlanDetails from "../molecules/PlanDetails";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import useRedirect from "helpers/hooks/useRedirect";
import CreationOptionsModal from "components/Modal/CreateOptionsModal";
import { useLocalizedTranslation } from "helpers/hooks/useLocalizedTranslation";
import { Grid } from "@mui/material";
import Button from "components/Button";

const CustomActions = ({ list, t = (d) => d }) => (
  <Grid
    container
    justifyContent={{ xs: "center", md: "flex-end" }}
    width={{ md: "max-content", xs: "100%" }}
    columnSpacing={0}
    mx={2}
    rowSpacing={1}
    gap={1}
  >
    {list.map(({ title, onClick, disabled }, i) => (
      <Grid item key={i}>
        <Button
          height="35px"
          width="fit-content"
          classes="tracking-tight fs-14 mx-auto mr-2"
          onClick={onClick}
          disabled={disabled}
        >
          {t("Create")} {t(title)}
        </Button>
      </Grid>
    ))}
  </Grid>
);

const ManageWrapper = ({
  browseOptions,
  counts,
  createActionTitle = "Create",
  browseMode,
  setBrowseMode,
  gridType,
  setGridType,
  sort,
  setSort,
  search,
  setSearch,
  children,
  navigateCreate = Function.prototype,
  filters,
  component,
  createOptions = [],
  sortOptions,
}) => {
  const [createAllowed, setCreateAllowed] = useState(false);
  const [createModal, setCreateModal] = useState(null);
  const [myPlan, setMyPlan] = useState({});
  const { t } = useLocalizedTranslation();
  const {
    navigateCreateResourceC,
    navigateCreateResourceG,
    navigateCreateLabProgram,
    navigateCreateChallengePath,
    navigateCreateProject,
  } = useRedirect();

  const getComponentKey = (component) => {
    const componentMap = {
      [COMPONENT_TYPES.LAB_PROGRAMS]: "lab_program",
      [COMPONENT_TYPES.LAB]: "lab",
      [COMPONENT_TYPES.CHALLENGE]: "challenge",
      [COMPONENT_TYPES.CHALLENGE_PATH]: "challenge_path",
      [COMPONENT_TYPES.RESOURCE]: "resource_module",
      [COMPONENT_TYPES.RESOURCE_COLLECTION]: "resource_collection",
      [COMPONENT_TYPES.RESOURCE_GROUP]: "resource_group",
      [COMPONENT_TYPES.ORGANIZATION]: "organization",
    };
    return componentMap[component] || component;
  };

  const isAllowedCreate = (component) => {
    const key = getComponentKey(component);
    const count = myPlan[`${key}_count`];
    const limit = myPlan[`${key}_limit`];
    return (
      (typeof limit === "string" && limit?.toLowerCase() === "unlimited") ||
      +limit > +count
    );
  };

  const createResourcesList = [
    {
      title: "Module",
      onClick: () => setCreateModal(COMPONENT_TYPES.RESOURCE),
      width: "180px",
      disabled: !isAllowedCreate(COMPONENT_TYPES.RESOURCE),
    },
    {
      title: "Collection",
      onClick: navigateCreateResourceC,
      width: "190px",
      disabled: !isAllowedCreate(COMPONENT_TYPES.RESOURCE_COLLECTION),
    },
    {
      title: "Group",
      onClick: navigateCreateResourceG,
      width: "180px",
      disabled: !isAllowedCreate(COMPONENT_TYPES.RESOURCE_GROUP),
    },
  ];

  const createLabsList = [
    {
      title: "Lab Program",
      onClick: navigateCreateLabProgram,
      disabled: !isAllowedCreate(COMPONENT_TYPES.LAB_PROGRAMS),
    },
    {
      title: "Lab",
      onClick: () => setCreateModal(COMPONENT_TYPES.LAB),
      disabled: !isAllowedCreate(COMPONENT_TYPES.LAB),
    },
  ];

  const createChallengeList = [
    {
      title: "Challenge Path",
      onClick: navigateCreateChallengePath,
      disabled: !isAllowedCreate(COMPONENT_TYPES.CHALLENGE_PATH),
    },
    {
      title: "Challenge",
      onClick: () => setCreateModal(COMPONENT_TYPES.CHALLENGE),
      disabled: !isAllowedCreate(COMPONENT_TYPES.CHALLENGE),
    },
  ];

  return (
    <DashboardLayout
      isPrivate
      managerAccessOnly={component !== COMPONENT_TYPES.PROJECT}
    >
      {Boolean(createModal) ? (
        <CreationOptionsModal
          open={Boolean(createModal)}
          title={t("How Do You Want To Create A New ${component}?", "common", {
            component:
              createModal === COMPONENT_TYPES.RESOURCE
                ? t("Resource Module")
                : createModal === COMPONENT_TYPES.LAB
                ? t("Lab")
                : createModal === COMPONENT_TYPES.CHALLENGE
                ? t("Challenge")
                : "N/A",
          })}
          options={createOptions}
          onClose={() => setCreateModal(null)}
          plan={myPlan}
        />
      ) : null}
      <PageTitleCard
        title={browseOptions[browseMode].pageTitle}
        actionTitle={createActionTitle}
        customAction={
          component === COMPONENT_TYPES.PROJECT ? (
            <Button classes={"mr-2"} onClick={navigateCreateProject}>
              {createActionTitle}
            </Button>
          ) : (
            <CustomActions
              list={
                component === COMPONENT_TYPES.RESOURCE ||
                component === COMPONENT_TYPES.RESOURCE_GROUP ||
                component === COMPONENT_TYPES.RESOURCE_COLLECTION
                  ? createResourcesList
                  : component === COMPONENT_TYPES.LAB ||
                    component === COMPONENT_TYPES.LAB_PROGRAMS
                  ? createLabsList
                  : component === COMPONENT_TYPES.CHALLENGE ||
                    component === COMPONENT_TYPES.CHALLENGE_PATH
                  ? createChallengeList
                  : []
              }
              t={t}
            />
          )
        }
        onClickAction={navigateCreate}
        disabledAction={component !== COMPONENT_TYPES.PROJECT && !createAllowed}
      >
        {component !== COMPONENT_TYPES.PROJECT && Boolean(component) && (
          <PlanDetails
            component={component}
            setCreateAllowed={setCreateAllowed}
            setMyPlan={setMyPlan}
          />
        )}
      </PageTitleCard>
      <MainPageLayout classes="mt-5 mb-6">
        <div className="left">
          <BrowseOptionsMenu
            options={browseOptions.map((d) => d.title)}
            counts={counts}
            clickDefined={!!browseOptions[0]?.onClick ? browseOptions : false}
            onChange={setBrowseMode}
            current={browseMode}
            classes="mb-4"
          />
          <FilterCard
            title="Advanced Search"
            types={
              !filters
                ? [
                    FILTER_TYPES.STATUS,
                    FILTER_TYPES.TYPE,
                    FILTER_TYPES.DURATION,
                    FILTER_TYPES.LEVEL,
                    FILTER_TYPES.SKILLS,
                    FILTER_TYPES.PRIVACY,
                    FILTER_TYPES.SOURCE,
                    FILTER_TYPES.TEMPLATE_STATUS,
                  ]
                : [...filters]
            }
          />
        </div>
        <div className="right">
          <ListingPageHeader
            gridType={gridType}
            setGridType={setGridType}
            search={search}
            setSearch={setSearch}
            setSort={setSort}
            sort={sort}
            sortOptions={sortOptions}
          />
          {children}
        </div>
      </MainPageLayout>
    </DashboardLayout>
  );
};

export default ManageWrapper;
