import LineProgress from 'components/Progress/LineProgress'
import React from 'react'
import Card from './Card'
import { useLocalizedTranslation } from 'helpers/hooks/useLocalizedTranslation';

const EditCreateHeaderCard = ({ width = "min(1300px,90%)", length, current, title, subtitles, classes, rounded, numbered, noShadow = false, noPadding = false, children }) => {
    const { t } = useLocalizedTranslation();
    return (
        <Card centered classes={`column-center my-5 text-center ${classes} ${noPadding ? 'p-0' : ''}`} noShadow={noShadow} width={width}>
            <h1>{t(title)}</h1>
            {subtitles?.length ? <LineProgress curr={current} length={length} classes="mt-3" rounded={rounded} numbered={numbered} /> : null}
            {subtitles &&
                <div className='flex mt-1'>
                    {
                        subtitles?.map(title => <p className='fs-12 opacity-70 w-80 mx-3 text-center' key={title}>{t(title)}</p>)
                    }
                </div>
            }
            {children}
        </Card>
    )
}

export default EditCreateHeaderCard