import { ChevronRight, Close, Done, ExpandMore } from "@mui/icons-material";
import React, { useState } from "react";
import Card from "./Card";
import { Icon } from "@mui/material";

const InfoCard = ({
  classes,
  title,
  list,
  type,
  hasObjects,
  toggleTexts,
  children,
  hideIcon,
  hideToggleBtn = false,
  t = (d) => d,
}) => {
  const [hideList, setHideList] = useState(false);
  const toggleList = () => setHideList((prev) => !prev);

  return (
    <Card classes={`${classes} ${type} infoCard py-2`}>
      <div className="fw-500 fs-14 text-left flex items-center pb-2">
        {typeof title === "string" ? t(title) : title}
      </div>
      {!hideToggleBtn && (
        <small
          className="flex fs-12 items-center cursor-pointer"
          onClick={toggleList}
        >
          {!hideList ? (
            <>
              <ExpandMore /> {toggleTexts?.hide || t("hide criteria", "common")}
            </>
          ) : (
            <>
              <ChevronRight />{" "}
              {toggleTexts?.show || t("show criteria", "common")}
            </>
          )}
        </small>
      )}
      {!hideList && (
        <>
          {list?.length ? (
            hasObjects ? (
              <ul>
                {list.map(({ title, status }, index) => (
                  <li className={`${hideIcon ? "" : "flex"} fs-12`} key={index}>
                    {!hideIcon && (
                      <Icon
                        sx={{ fontSize: 15, margin: "0 4px 0 8px" }}
                        component={status === "completed" ? Done : Close}
                        style={{
                          color: status === "completed" ? "green" : "red",
                        }}
                      />
                    )}
                    <p>{t(title)}</p>
                  </li>
                ))}
              </ul>
            ) : (
              list.map((point) => (
                <p className="flex items-center fs-12" key={point}>
                  {point}
                </p>
              ))
            )
          ) : (
            <p className="fs-12 opacity-50 text-center py-10">
              {t("No data available")}
            </p>
          )}
          <div className="ml-2">{children}</div>
        </>
      )}
    </Card>
  );
};

export default InfoCard;

export const InfoCard2 = ({ classes, icon, list }) => {
  if (!list?.length) return null;
  return (
    <div className={`flex-center mt-3 px-3 rounded-10 py-2 ${classes}`}>
      {icon && icon}
      <div className="flex-column ml-2">
        <h4>Lab Requirements:</h4>
        <div className="mt-1">
          {list.map((req, i) => (
            <li className="fs-13" key={i}>
              {req}
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};
