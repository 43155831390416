import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {
  mockRequestGet,
  mockRequestPost,
} from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useOrgDashboard from "./org";
import { BrowserRouter } from "react-router-dom";

let getSpy;
let postSpy;

let type = "1";

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com", username: "test" },
  },
});

const OrgDashboardServicesMock = () => {
    const {
        recommendations,
        myChallenges,
        myLabs,
        myResources,
        myProjects,
        inboxFriendRequests,
        layout,
        updateLayout,
        getReports,
        getSubscriptionDetails,
        upcomingChallengeDeadlines,
        myOrganizations,
    } = useOrgDashboard();
  
    const BUTTON_LINKS = [
      { name: "recommendations", onClick: () => recommendations(type) },
      { name: "myChallenges", onClick: () => myChallenges({}) },
      { name: "myLabs", onClick: () => myLabs({}) },
      { name: "myResources", onClick: () => myResources({}) },
      { name: "myProjects", onClick: () => myProjects({}) },
      { name: "inboxFriendRequests", onClick: () => inboxFriendRequests(type) },
      { name: "layout", onClick: () => layout() },
      { name: "updateLayout", onClick: () => updateLayout({}) },
      { name: "getReports", onClick: () => getReports(type) },
      { name: "getSubscriptionDetails", onClick: () => getSubscriptionDetails()},
      { name: "upcomingChallengeDeadlines", onClick: () => upcomingChallengeDeadlines()},
      { name: "myOrganizations", onClick: () => myOrganizations()},
    ];
  
    return (
      <div>
        {BUTTON_LINKS.map((item) => (
          <button data-testid={item.name} key={item.name} onClick={item.onClick}>
            Call {item.name}
          </button>
        ))}
      </div>
    );
  };
  
  let screen;

  describe("Org Dashboard Services", () => {
    beforeEach(() => {
      screen = render(
        <Provider store={store}>
          <BrowserRouter>
            <OrgDashboardServicesMock />
          </BrowserRouter>
        </Provider>
      );
      getSpy = mockRequestGet();
      postSpy = mockRequestPost();
    });
  
    test("recommendations", async () => {
      const button = screen.getByTestId("recommendations");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.MY_RECOMMENDATIONS}?language=en&type=${type}`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });
  
    test("myResources", async () => {
      const button = screen.getByTestId("myResources");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.MY_RESOURCE_MODULE}?language=en&`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });
  
    test("myChallenges", async () => {
      const button = screen.getByTestId("myChallenges");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.MY_CHALLENGES}?language=en&`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });
  
    test("myLabs", async () => {
      const button = screen.getByTestId("myLabs");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.MY_LABS}?language=en&`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });
  
    test("myProjects", async () => {
      const button = screen.getByTestId("myProjects");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.GET_PROJECTS_LIST}?language=en&`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });
  
    test("inboxFriendRequests", async () => {
      const button = screen.getByTestId("inboxFriendRequests");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.INBOX_FRIEND_REQUEST}?language=en&type=${type}`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });
  
    test("layout", async () => {
      const button = screen.getByTestId("layout");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.FETCH_LAYOUT}?language=en`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });
  
    test("updateLayout", async () => {
      const button = screen.getByTestId("updateLayout");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(postSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.UPDATE_LAYOUT}?language=en&`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });
  
    test("getReports", async () => {
      const button = screen.getByTestId("getReports");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.REPORTS}?language=en&type=${type}`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });
  
    test("getSubscriptionDetails", async () => {
      const button = screen.getByTestId("getSubscriptionDetails");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SUBSCRIPTION_DETAILS}?language=en`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });
  
    test("upcomingChallengeDeadlines", async () => {
      const button = screen.getByTestId("upcomingChallengeDeadlines");
      userEvent.click(button);
  
      await waitFor(() =>
        expect(getSpy).toHaveBeenCalledWith(
          `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.UPCOMING_CHALLENGE_DEADLINES}?language=en`,
          {},
          headers,
          expect.any(Function),
          expect.any(Function),
          true
        )
      );
    });

    test("myOrganizations", async () => {
        const button = screen.getByTestId("myOrganizations");
        userEvent.click(button);
    
        await waitFor(() =>
          expect(getSpy).toHaveBeenCalledWith(
            `${ENDPOINTS.DASHBOARD}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.MY_ORGANIZATION}?language=en&`,
            {},
            headers,
            expect.any(Function),
            expect.any(Function),
            true
          )
        );
    });
  });  