import { Grid } from "@mui/material";
import Button from "components/Button";
import Card from "components/Cards/Card";
import Select from "components/Input/Select";
import { AssessmentFilters } from "pages/report/data";
import React, { useEffect, useMemo, useState } from "react";
import useChallengeReport from "store/reports/challenges/service-hooks";
import useChallengeReportContext from "../hooks/useChallengeReportContext";
import Spin from "components/Spin";
import ProjectAssessmentDetailsModal from "./ProjectAssessmentDetailsModal";
import EmptyText from "components/UI/atoms/EmptyText";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import useRedirect from "helpers/hooks/useRedirect";
import { COMPONENT_TYPES } from "helpers/utils/constants";
import Paginator from "components/UI/atoms/Paginator";
import ImageCard from "../../../../components/Cards/ImageCard";

function ChallengeAssessmentReports({ t = (d) => d }) {
  const [activeComponent, setActiveComponent] = useState(AssessmentFilters.all);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const { ChallengeAssessmentReports } = useChallengeReport();
  const { challengeSlug } = useChallengeReportContext();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [assessmentDetails, setAssessmentDetails] = useState({});
  const { navigateMemberManagement, navigateProject } = useRedirect();
  const [page, setPage] = useState(1);

  const [components] = useState([
    {
      value: t("All"),
      key: AssessmentFilters.all,
    },
    {
      value: t("Assessed"),
      key: AssessmentFilters.assessed,
    },
    {
      value: t("Assessment Pending"),
      key: AssessmentFilters.assessment_pending,
    },
  ]);

  /**
   * MEMORIZED
   */
  const ComponentStats = useMemo(() => {
    return [
      {
        title: t("Assessor(s)"),
        count: data?.meta?.assessor ?? 0,
      },
      {
        title: t("Project Assessed"),
        count: data?.meta?.project_assessed ?? 0,
      },
      {
        title: t("Project Pending Asessment"),
        count: data?.meta?.project_pending_assignment ?? 0,
      },
      {
        title: t("Winner Selected"),
        count: data?.meta?.winner_selected ?? 0,
      },
    ];
  }, [data]);

  const handleOpenAssessmentModal = (item) => {
    setIsOpenModal(true);
    setAssessmentDetails(item);
  };

  const handleCloseAssessmentModal = () => setIsOpenModal(false);

  const fetchAssessmentReports = async () => {
    setIsLoading(true);
    if (challengeSlug) {
      const response = await ChallengeAssessmentReports(
        challengeSlug,
        page,
        activeComponent
      );
      if (response) {
        setData(response);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAssessmentReports();
  }, [activeComponent, page]);
  return (
    <>
      <ProjectAssessmentDetailsModal
        open={isOpenModal}
        onClose={handleCloseAssessmentModal}
        details={assessmentDetails}
        slug={challengeSlug}
      />
      <Card>
        <div className="flex gap-20 mb-4 ml-20 items-center">
          <Grid
            container
            spacing={2}
            className="mb-4 w-full"
            style={{ justifyContent: "space-between" }}
          >
            {ComponentStats?.map((item) => (
              <Grid item xs={12} md={2} key={item?.title}>
                <p className={"bold text-center fs-15"}>{item?.count}</p>
                <p className={"text-center fs-11"}>{item?.title}</p>
              </Grid>
            ))}
          </Grid>
          <div>
            <Select
              borderedIcon
              placeholder="Filter by"
              value={activeComponent}
              onChange={(e) => {
                setActiveComponent(e.target.value);
                setPage(1);
              }}
              keyValues={components}
              width={"180px"}
              height={40}
              t={t}
            />
          </div>
        </div>
        <div>
          {isLoading ? (
            <Grid item xs={12}>
              <div className={"h-200 w-full flex justify-center items-center"}>
                <Spin blue big />
              </div>
            </Grid>
          ) : (
            ""
          )}
          {/** Grid Structures */}
          {!isLoading && data?.data?.length ? (
            <>
              <Grid
                container
                spacing={2}
                className="mt-5 mb-4 w-full mx-5"
                rowGap={2}
              >
                {data?.data?.map((item) => (
                  <Grid container xs={12} md={6} key={item?.id} gap={2}>
                    <Grid item xs={12} md={2} height={90}>
                      <ImageCard
                          image={item?.media}
                          type={COMPONENT_TYPES.PROJECT}
                          height="100%"
                          isEmbedded={item?.media_type === "1"}
                          title={item?.title}
                      />
                    </Grid>

                    <Grid item xs={12} md={9}>
                      <div className="flex items-center justify-between mb-3">
                        <p
                          className="c-primary bold text-center  fs-16 cursor-pointer"
                          onClick={() => navigateProject(item?.slug)}
                        >
                          {item?.title}
                        </p>
                        {item?.assessment === "assessed" ? (
                          <p className="c-primary fs-16 bold">
                            {item?.score}/{item?.weight}
                          </p>
                        ) : (
                          <p className="fs-11 bold">
                            {t("Assessment Pending")}
                          </p>
                        )}
                      </div>
                      <div className="flex fs-12">
                        <label className="bold mr-1">{t("Created by")}: </label>
                        <p>{item?.created_by}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex fs-12">
                          <label className="bold mr-1">
                            {t("Achievement Awarded")}:{" "}
                          </label>
                          <p>{item?.achievement}</p>
                        </div>
                        {item?.assessment === "assessed" && (
                          <Button
                            onClick={() => handleOpenAssessmentModal(item)}
                            height="25px"
                            classes="fs-13"
                          >
                            {t("Assessment Details")}
                          </Button>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <div className="flex items-center">
                <p className=" fs-14 mt-5 text-nowrap">
                  <b>
                    {page === 1 ? 1 : (page - 1) * 10 + 1} -{" "}
                    {page === data?.meta?.total_pages
                      ? data?.meta?.total
                      : page * 10}
                  </b>{" "}
                  of <b>{data?.meta?.total}</b>
                </p>
                <Paginator
                  classes="mt-0"
                  count={data?.meta?.total_pages}
                  page={page}
                  setPage={(newValue) => setPage(newValue)}
                  right
                />
              </div>
            </>
          ) : (
            ""
          )}
          {!isLoading && !data?.data?.length && (
            <Grid item xs={12} className={"flex justify-center my-2"}>
              <EmptyText
                classes="fs-14"
                isButtonPresent={true}
                onClick={() =>
                  navigateMemberManagement(
                    COMPONENT_TYPES.CHALLENGE,
                    challengeSlug
                  )
                }
                buttonLabel={t("Manage Members")}
                icon={ManageAccountsIcon}
              >
                {t(
                  "There is no project created for this Challenge, invite members and encourage them to start the Challenge", "common"
                )}
              </EmptyText>
            </Grid>
          )}
        </div>
      </Card>
    </>
  );
}

export default ChallengeAssessmentReports;
