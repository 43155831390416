import { render, waitFor } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import { Provider } from "react-redux";
import { REDUCER_TYPES } from "../index";
import {
  mockRequestGet,
  mockRequestPost,
  mockRequestDelete,
} from "../mocks/request";
import { initStore } from "../mocks/store";
import { ENDPOINTS } from "store/api/endpoints";
import useLabPrograms from "./service-hook";
import { BrowserRouter } from "react-router-dom";
import { CONTENT_TYPES } from "store/api";

let getSpy;
let postSpy;
let deleteSpy;

let labProgramId = "1";

const payload = {
  language: "en",
};

const headers = {
  Authorization: "Bearer 1",
};

const store = initStore({
  [REDUCER_TYPES.AUTH]: {
    language: "en",
    token: 1,
    user: { id: 1, email: "test@gmail.com" },
  },
  [REDUCER_TYPES.MASTER]: {},
});

const LabProgramsServicesMock = () => {
  const {
    fetchLabPrograms,
    fetchPublicLP,
    fetchLP,
    fetchPrivateLPs,
    create,
    edit,
    deleteLP,
    checkSlug,
    checkTitle,
    fetchLabsProgramsSelectList
  } = useLabPrograms();

  const BUTTON_LINKS = [
    { name: "fetchLabPrograms", onClick: () => fetchLabPrograms(1) },
    { name: "fetchPublicLP", onClick: () => fetchPublicLP(labProgramId) },
    { name: "fetchLP", onClick: () => fetchLP(labProgramId) },
    { name: "fetchPrivateLPs", onClick: () => fetchPrivateLPs({}) },
    { name: "create", onClick: () => create({}) },
    { name: "edit", onClick: () => edit(labProgramId, {}) },
    { name: "deleteLP", onClick: () => deleteLP(labProgramId) },
    { name: "checkSlug", onClick: () => checkSlug(labProgramId) },
    { name: "checkTitle", onClick: () => checkTitle(labProgramId) },
    { name: "fetchLabsProgramsSelectList", onClick: () => fetchLabsProgramsSelectList({}) },
  ];

  return (
    <div>
      {BUTTON_LINKS.map((item) => (
        <button data-testid={item.name} key={item.name} onClick={item.onClick}>
          Call {item.name}
        </button>
      ))}
    </div>
  );
};

let screen;

describe("Lab Programs services", () => {
  beforeEach(() => {
    screen = render(
      <Provider store={store}>
        <BrowserRouter>
          <LabProgramsServicesMock />
        </BrowserRouter>
      </Provider>
    );
    getSpy = mockRequestGet();
    postSpy = mockRequestPost();
    deleteSpy = mockRequestDelete();
  });

  test("fetchLabPrograms", async () => {
    const button = screen.getByTestId("fetchLabPrograms");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB_PROGRAM}?language=en&`,
        {},
        headers,
        expect.any(Function)
      )
    );
  });

  test("fetchPublicLP", async () => {
    const button = screen.getByTestId("fetchPublicLP");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB_PROGRAM}/${labProgramId}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("fetchLP", async () => {
    const button = screen.getByTestId("fetchLP");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${labProgramId}?language=en`,
        {},
        headers,
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("fetchPrivateLPs", async () => {
    const button = screen.getByTestId("fetchPrivateLPs");
    userEvent.click(button);

    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}?language=en&`,
        {},
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("create", async () => {
    const button = screen.getByTestId("create");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${ENDPOINTS.CREATE}?language=en`,
        {
          ...payload,
          language: "en",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("edit", async () => {
    const button = screen.getByTestId("edit");
    userEvent.click(button);

    await waitFor(() =>
      expect(postSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${labProgramId}/${ENDPOINTS.UPDATE}?language=en`,
        {
          ...payload,
          language: "en",
          _method: "PUT",
        },
        {
          ...headers,
          "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
        },
        expect.any(Function)
      )
    );
  });

  test("deleteLP", async () => {
    const button = screen.getByTestId("deleteLP");
    userEvent.click(button);

    await waitFor(() =>
      expect(deleteSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${labProgramId}/${ENDPOINTS.DELETE}?language=en`,
        headers,
        expect.any(Function)
      )
    );
  });

  test("checkSlug", async () => {
    const button = screen.getByTestId("checkSlug");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${ENDPOINTS.CHECK_SLUG}/${labProgramId}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("checkTitle", async () => {
    const button = screen.getByTestId("checkTitle");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${ENDPOINTS.CHECK_TITLE}/${labProgramId}?language=en`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function),
        expect.any(Function),
        true
      )
    );
  });

  test("fetchLabsProgramsSelectList", async () => {
    const button = screen.getByTestId("fetchLabsProgramsSelectList");
    userEvent.click(button);
    await waitFor(() =>
      expect(getSpy).toHaveBeenCalledWith(
        `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB_PROGRAM}/${ENDPOINTS.GET_LIST}?language=en&`,
        {},
        {
          Authorization: "Bearer 1",
        },
        expect.any(Function)
      )
    );
  });
});
