import { useDispatch, useSelector } from "react-redux";
import { REDUCER_TYPES } from "store";
import REQUEST, { CONTENT_TYPES } from "store/api";
import { ENDPOINTS } from "store/api/endpoints";
import { resolveResponse, serializeObject } from "store/api/utils";
import { REDUCER } from "./reducer";
import ACTIONS from "./action-types";
import { toast } from "react-toastify";
import useRedirect from "helpers/hooks/useRedirect";

const useLabs = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state[REDUCER_TYPES.LABS]);
  const { navigateLab } = useRedirect();
  const { language, token, user } = useSelector(
    (state) => state[REDUCER_TYPES.AUTH]
  );

  const headers = {
    Authorization: "Bearer " + token,
  };

  //CREATE LAB API
  const createLab = async (payload) => {
    const body = {
      ...payload,
      request_type: "publish",
      language,
    };

    const onSuccess = (res) =>
      resolveResponse(
        res,
        (data) => {
          navigateLab(data.slug, true);
        },
        "Lab created successfully!"
      );

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${ENDPOINTS.CREATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    return res;
  };

  //FETCH LAB LIST API
  const fetchLabs = async (filters = {}) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${
        ENDPOINTS.LAB
      }?language=${language}&${serializeObject(filters)}`,
      {},
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    if (res?.data?.data) {
      const { list, total_pages, total_count } = res?.data?.data;
      return [list, total_pages, total_count];
    }
    return [];
  };

  //FETCH PUBLIC LABS API
  const fetchPublicLabs = async (filters = {}) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${
        ENDPOINTS.LAB
      }?language=${language}&${serializeObject(filters)}`,
      {},
      headers,
      onSuccess,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      const { list, total_pages, total_count, pending_invites } =
        res?.data?.data;
      return [list, total_pages, total_count, pending_invites];
    }
    return [];
  };

  //FETCH LAB LISTING WITH NAME AND ID
  const fetchLabsSelectList = async (search = "") => {
    if (!search && state?.labs?.length) return state.labs;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${ENDPOINTS.GET_LIST}?language=${language}&search=${search}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data?.length) {
      !search && dispatch(REDUCER[ACTIONS.LAB_LIST](res?.data?.data));
      return res?.data?.data;
    }
    return [];
  };

  //FETCH LAB PRIVATE DETAILS API
  const fetchLab = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${slug}?language=${language}`,
      {},
      headers,
      onSuccess,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return res;
  };

  //FETCH LAB PUBLIC DETAILS API
  const fetchPublicLab = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${slug}?language=${language}`,
      {},
      headers,
      onSuccess,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return res;
  };

  //EDIT LAB API
  const editLab = async (slug, payload) => {
    if (!slug || !payload) return;
    const body = {
      ...payload,
      request_type: "publish",
      language,
      _method: "PUT",
    };

    const onSuccess = (res) =>
      resolveResponse(
        res,
        (data) => navigateLab(data.slug, true),
        "Lab edited successfully!"
      );

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${slug}/${ENDPOINTS.UPDATE}?language=${language}`,
      body,
      {
        ...headers,
        "Content-Type": CONTENT_TYPES.MULTIPART_FORM,
      },
      onSuccess
    );
    return res;
  };

  // DELETE LAB APIS
  const deleteLab = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);

    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${slug}/${ENDPOINTS.DELETE}?language=${language}`,
      headers,
      onSuccess
    );
  };

  const follow = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${slug}/${ENDPOINTS.FOLLOW}?language=${language}`,
      null,
      headers,
      onSuccess
    );
  };

  const unFollow = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${slug}/${ENDPOINTS.UNFOLLOW}?language=${language}`,
      null,
      headers,
      onSuccess
    );
  };

  const join = async (slug, isLP = false) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isLP ? ENDPOINTS.LAB_PROGRAM : ENDPOINTS.LAB
      }/${slug}/${ENDPOINTS.JOIN}?language=${language}`,
      null,
      headers,
      onSuccess
    );
  };

  const unJoin = async (slug, isLP = false) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    await client.delete(
      `${ENDPOINTS.PUBLIC}/${
        isLP ? ENDPOINTS.LAB_PROGRAM : ENDPOINTS.LAB
      }/${slug}/${ENDPOINTS.UNJOIN}?language=${language}`,
      headers,
      onSuccess
    );
  };

  const favorite = async (slug, isLP) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isLP ? ENDPOINTS.LAB_PROGRAM : ENDPOINTS.LAB
      }/${slug}/${ENDPOINTS.FAVOURITE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const unFavorite = async (slug, isLP) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isLP ? ENDPOINTS.LAB_PROGRAM : ENDPOINTS.LAB
      }/${slug}/${ENDPOINTS.UNFAVOURITE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const like = async (slug, isLP) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isLP ? ENDPOINTS.LAB_PROGRAM : ENDPOINTS.LAB
      }/${slug}/${ENDPOINTS.LIKE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const unLike = async (slug, isLP) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isLP ? ENDPOINTS.LAB_PROGRAM : ENDPOINTS.LAB
      }/${slug}/${ENDPOINTS.UNLIKE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const share = async (slug, isLP) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${
        isLP ? ENDPOINTS.LAB_PROGRAM : ENDPOINTS.LAB
      }/${slug}/${ENDPOINTS.SHARE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  const acceptLab = async (slug, isLP = false) => {
    if (!slug || !user || !user?.email) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.INVITATION_MANAGEMENT}/${
        isLP ? ENDPOINTS.LAB_PROGRAM : ENDPOINTS.LAB
      }/${slug}/${ENDPOINTS.REQUEST}/${ENDPOINTS.ACCEPT}?language=${language}`,
      { email: user?.email },
      headers,
      onSuccess
    );
    return res;
  };

  const declineLab = async (slug, isLP = false) => {
    if (!slug || !user || !user?.email) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.INVITATION_MANAGEMENT}/${
        isLP ? ENDPOINTS.LAB_PROGRAM : ENDPOINTS.LAB
      }/${slug}/${ENDPOINTS.REQUEST}/${ENDPOINTS.DECLINE}?language=${language}`,
      { email: user?.email },
      headers,
      onSuccess
    );
    return res;
  };

  // CREATE LAB PREVIEW USING AI API
  const aiCreateLabsPreview = async (payload) => {
    const body = {
      ...payload,
      language,
    };

    const onSuccess = (res) =>
      resolveResponse(res, null, (data) => data?.message, null, false);

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}/${ENDPOINTS.PREVIEW}?language=${language}`,
      JSON.parse(JSON.stringify(body)),
      {
        ...headers,
        "Content-Type": "application/json",
      },
      onSuccess
    );
    return res;
  };

  // CREATE LAB USING AI API
  const aiCreateLab = async (payload) => {
    const body = {
      ...payload,
      language,
    };

    const onSuccess = (res) => {
      resolveResponse(res, null, (data) => data?.message, null, true);
    };

    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${ENDPOINTS.AI}/${ENDPOINTS.CREATE}?language=${language}`,
      JSON.parse(JSON.stringify(body)),
      {
        ...headers,
        "Content-Type": "application/json",
      },
      onSuccess
    );
    return res;
  };

  // VERIFY LIVE EVENT
  const verifyLiveEvent = async (payload) => {
    const body = {
      ...payload,
      language,
    };
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.AIRMEET}/${ENDPOINTS.VERIFY_EVENT}?language=${language}`,
      JSON.parse(JSON.stringify(body)),
      {
        ...headers,
        "Content-Type": "application/json",
      },
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return null;
  };

  const fetchLabEventSchedule = async (slug) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${slug}/${ENDPOINTS.LIVE_EVENT_DETAILS}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return null;
  };

  const fetchEventUrl = async (slug) => {
    const onSuccess = (res) => resolveResponse(res, null, null, null, true);
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${slug}/${ENDPOINTS.LIVE_EVENT_URL}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
    return null;
  };

  const sendLiveEventInvitation = async (slug) => {
    const onSuccess = (res) => {
      toast.success("Live Event invitation has been sent.");
      return resolveResponse(res, null, null, null, true);
    };
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${slug}/${ENDPOINTS.LIVE_EVENT_INVITATION}`,
      JSON.parse(JSON.stringify({ language })),
      headers,
      onSuccess
    );
    if (res?.data?.data) {
      return res?.data;
    }
    return null;
  };

  const clone = async (slug) => {
    if (!slug) return;
    const onSuccess = (res) => resolveResponse(res);
    const client = new REQUEST();
    const res = await client.post(
      `${ENDPOINTS.MANAGE}/${ENDPOINTS.LAB}/${slug}/${ENDPOINTS.CLONE}?language=${language}`,
      {},
      headers,
      onSuccess
    );
    return res;
  };

  //VIEW ACTIVITY OF LAB
  const getActivity = async (id) => {
    if (!id) return;
    const client = new REQUEST();
    const res = await client.get(
      `${ENDPOINTS.PUBLIC}/${ENDPOINTS.LAB}/${id}/${ENDPOINTS.HISTORY}?language=${language}`,
      {},
      headers,
      Function.prototype,
      Function.prototype,
      true
    );
    if (res?.data?.data) {
      return res?.data?.data;
    }
  };

  return {
    createLab,
    fetchLabs,
    fetchLab,
    fetchPublicLab,
    editLab,
    deleteLab,
    follow,
    unFollow,
    join,
    unJoin,
    favorite,
    unFavorite,
    like,
    unLike,
    share,
    fetchPublicLabs,
    acceptLab,
    declineLab,
    fetchLabsSelectList,
    state,
    aiCreateLabsPreview,
    aiCreateLab,
    verifyLiveEvent,
    fetchLabEventSchedule,
    fetchEventUrl,
    sendLiveEventInvitation,
    clone,
    getActivity,
  };
};

export default useLabs;
